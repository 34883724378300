<template>
  <div id="employee-view" class="d-flex flex-column fill-height">
    <div>
      <v-toolbar class="toolbar3 text-capitalize px-2" flat short>
        <v-toolbar-title
          class="subtitle-1 font-weight-medium text-no-wrap overflow-visible"
          v-text="$t(leaveManagementTitle)"
        />
        <v-divider v-if="!isSmallAndDown" class="mx-4 ml-8" inset vertical />
        <v-tabs
          v-if="!isSmallAndDown"
          v-model="selectedTab"
          show-arrows
          slider-color="var(--v-ttPrimary-base)"
          class="px-2 mr-6"
        >
          <v-tab
            v-for="tab in tabs"
            :key="$t(tab.title)"
            active-class="active-icon"
            class="text-capitalize px-2 mr-6 ml-0"
            @click="closeSidePanel"
          >
            <v-icon small left v-text="tab.icon" />
            {{ $t(tab.title) }}
          </v-tab>
        </v-tabs>
        <template v-if="isSmallAndDown" #extension>
          <v-tabs
            v-model="selectedTab"
            slider-color="var(--v-ttPrimary-base)"
            show-arrows
            align-with-title
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="$t(tab.title)"
              active-class="active-icon"
              class="text-capitalize"
              @click="closeSidePanel"
            >
              <v-icon small left v-text="tab.icon" />
              {{ $t(tab.title) }}
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer />
        <LogEmployeeLeaveBtn
          v-if="allowActions && isAssignedToLeavePolicy"
          :employee="employeeId"
        />
      </v-toolbar>
    </div>
    <div class="fill-height d-flex flex-column">
      <v-tabs-items v-model="selectedTab" class="fill-height">
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.key"
          active-class="fill-height"
        >
          <component
            :is="tab.is"
            v-if="employeeInfo"
            :key="tab.key"
            :employee-id="employeeId"
            :employee-region="employeeRegion"
            :employee-info="employeeInfo"
            :current-leave-policy="currentLeavePolicy"
            @policy-update="loadEmployeeInfo"
          />
          <div v-else class="d-flex pa-6">
            <v-skeleton-loader min-width="320" type="image" />
            <v-skeleton-loader class="flex-grow-1" type="table" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { EmployeeTab } from '../types'
import LogEmployeeLeaveBtn from './LogEmployeeLeaveBtn.vue'
import EmployeeOverview from './overview/EmployeeOverview.vue'
import LeaveHistory from './leaveHistory/LeaveHistory.vue'
import TransactionActivity from './transactionActivity/TransactionActivity.vue'
import SettingsTab from './settings/SettingsTab.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { LayoutComponents } from '@/tt-app-layout'
import { LeavePolicyEmployeeInfo } from '@/tt-entity-design/src/components/leave-policies/types'
import { Resources } from '@/tt-entity-design/src/types'
import {
  overviewTab,
  leaveHistoryTab,
  transactionActivityTab,
  settingsTab,
} from './constants'
import { isNumber } from 'lodash'

export default (
  Vue as VueConstructor<Vue & { layoutManager: LayoutManager }>
).extend({
  name: 'EmployeeView',
  components: {
    LogEmployeeLeaveBtn,
    EmployeeOverview,
    LeaveHistory,
    TransactionActivity,
    SettingsTab,
  },
  inject: ['layoutManager'],
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    employeeRegion: {
      type: Number,
      required: true,
    },
    allowActions: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
      baseTranslationKey: 'tt-entity-design.leave-management',
      employeeInfo: null as LeavePolicyEmployeeInfo,
    }
  },
  computed: {
    isEmployeesOwnProfile(): boolean {
      return this.employeeId === this.$auth.getUserId()
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    isSmallAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    leaveManagementTitle(): string {
      return `${this.baseTranslationKey}.label`
    },
    tabs(): EmployeeTab[] {
      const restrictedAccessTabList = [overviewTab, leaveHistoryTab]
      const fullAccessTabList = [
        overviewTab,
        leaveHistoryTab,
        transactionActivityTab,
        settingsTab,
      ]

      return this.isEmployeesOwnProfile
        ? restrictedAccessTabList
        : fullAccessTabList
    },
    currentLeavePolicy(): number {
      return this.employeeInfo?.currentLeavePolicy?.id
    },
    isAssignedToLeavePolicy(): boolean {
      return isNumber(this.currentLeavePolicy)
    },
  },
  created() {
    this.loadEmployeeInfo()
  },
  methods: {
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
    getEmployeeInfo(): Promise<LeavePolicyEmployeeInfo> {
      return this.$auth.getApi().get(Resources.EMPLOYEES, this.employeeId, {
        include: [
          'currentLeavePolicy',
          'employmentProfile',
          'employmentProfile.employeeClass',
        ],
      })
    },
    async loadEmployeeInfo() {
      try {
        this.employeeInfo = await this.getEmployeeInfo()
      } catch (err) {
        this.$crash.captureException(err)
      }
    },
  },
})
</script>
<style scoped>
/* 
  Overriding default behaviour for flex items min-width: auto.
  This will allow the tabs to adapt the content properly, see: https://stackoverflow.com/a/36247448
*/
#employee-view {
  min-width: 0;
}
.active-icon .v-icon {
  color: var(--v-ttPrimary-base) !important;
}
</style>
