<template>
  <span v-if="showField">
    <slot v-bind="{ openPreview }" />
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'

import { EntityIntentTypes } from '../intents/types'
import { EntityItemHook } from '../EntityItemHook'
import {
  isAttributeDefined,
  isWhitelistedResourceInvalidField,
} from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'TtAttrRelationPreviewDispatcher',
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isAttributeDefined(): boolean {
      return isAttributeDefined(this.getItemHook().data, this.$props.name)
    },
    entityId(): number {
      return this.getItemHook().getRawValue(`${this.name}.id`)
    },
    isSideSheetChildren(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resourceName(): string {
      return this.getItemHook().getAttributeRelationResource(this.name)
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
  created() {
    this.getItemHook().addAttribute(`${this.name}.id`)
  },
  methods: {
    openPreview() {
      if (!this.isAttributeDefined || !this.entityId) return
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        activeWindow: this.activeWindow,
        resourceName: this.resourceName,
        entityId: this.entityId,
        hasToAddPages: this.isSideSheetChildren,
      })
    },
  },
})
</script>
