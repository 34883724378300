<template>
  <v-alert
    v-if="showMessage"
    type="warning"
    color="orange darken-2"
    icon="mdi-alert"
    class="mb-5"
    text
  >
    <p class="my-0">
      {{ cannotArchiveWarningMessage }}
    </p>

    <!-- list all the conflicting assets that cannot exist outside of a bundle -->
    <ul v-if="conflictingBundleItemsList.length" class="mt-2">
      <li v-for="item in conflictingBundleItemsList" :key="item.id">
        {{ item.name }}
      </li>
    </ul>
  </v-alert>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ErrorObject } from 'ajv'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { FormHookProvider } from '@/tt-widget-components'

import { isDifferent } from './helpers'
import { ATTR_BE_CONTAINED_INTO_BUNDLE } from './constants'
import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetsArchiveBundleWarning',
  inject: ['formHook'],
  props: {
    assetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showMessage: false,
      conflictingBundleItemsCount: 0,
      conflictingBundleItemsList: [],
    }
  },
  computed: {
    cannotArchiveWarningMessage(): string {
      return this.$t(
        'tt-entity-design.asset-hub.cannot-archive-warning-message',
        { conflictingBundleItemsCount: this.conflictingBundleItemsCount },
      )
    },
  },
  watch: {
    assetId: {
      immediate: true,
      handler(newValue, oldValue) {
        // if the assetId changes, we fetch again the bundle assets list
        if (isDifferent(newValue, oldValue)) {
          this.fetchBundleItems(newValue)
        }
      },
    },
  },
  created() {
    this.fetchBundleItems(this.assetId)
  },
  methods: {
    fetchBundleItems(assetId: number): void {
      this.hideWarningMessage()

      const api = this.$appContext.authModule.getApi()
      const filters = [
        {
          attribute: 'include',
          operator: FilterOperatorType.EQUAL,
          value: 'type',
        },
        {
          attribute: 'bundle',
          operator: FilterOperatorType.EQUAL,
          value: assetId,
        },
        {
          attribute: 'type.beContainedIntoBundle',
          operator: FilterOperatorType.EQUAL,
          value: 1,
        },
      ]

      api.getAll(Resources.ASSETS, { filters }).then((res) => {
        const { items, itemCount } = res
        const hasItems = itemCount > 0

        /**
         * conflictingBundleItems
         * The name is due the fact that if we have these items here, that means that this bundle
         * can't be archived, because such items can't existe outside of a bundle. This is why we use
         * these filters above: we check if this assetId contains assets whose type is flagged as
         * "beContainedIntoBundle"
         */
        this.conflictingBundleItemsList = items
        this.conflictingBundleItemsCount = itemCount

        /**
         * If hasItems is true, we display the warning message
         */
        if (hasItems) {
          this.showWarningMessage()
        }
      })
    },
    hideWarningMessage(): void {
      this.showMessage = false
      this.$emit('canArchive')
    },
    showWarningMessage(): void {
      const errors: ErrorObject[] = [
        {
          dataPath: `.${ATTR_BE_CONTAINED_INTO_BUNDLE}`,
          keyword: '',
          params: {},
          schemaPath: '',
        },
      ]

      this.showMessage = true
      this.$emit('cannotArchive', errors)
    },
  },
})
</script>

<style></style>
