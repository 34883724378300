import { AppContext } from '@/tt-app-context'
import { EventProviderInterface } from '@/tt-widget-entity-flow/types'
import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetComponentEvents } from '@/tt-widget-entity-flow/intents/types'
import { UserRegion } from '@/tt-widget-components/components/treeview/utils'
import { FilterWithValue } from '@/tt-widget-components'
import RegionFilterModal from '@/tt-widget-components/components/regions/RegionFilterModal.vue'

export interface RegionFilterPayload {
  filter: FilterWithValue
  userRegions?: UserRegion[]
  onUpdate: (ids: string[]) => void
  onClose: () => void
}

export class RegionFilterIntent implements EventProviderInterface {
  appContext: AppContext

  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  getEventName(): string {
    return WidgetComponentEvents.REGION_FILTER
  }

  run(payload: RegionFilterPayload): void {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: RegionFilterModal,
      title: i18n.tc('intents.region_filter.dialog_title'),
      props: { payload },
    })
  }
}
