<template>
  <div>
    <json-field
      name="sort"
      as="ReportTemplateFieldsSorter"
      :entity-id="entityId"
      :parent-type="resource"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components/types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportFlagTemplatesSortFields',
  inject: ['formHook'],
  computed: {
    entityId(): number {
      return this.itemHook.entityReference.entityId
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    resource(): string {
      return this.itemHook.entityReference.resourceName
    },
  },
})
</script>
