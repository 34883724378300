import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'customId',
  'account',
  'description',
  {
    attributeName: 'status',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'status',
      },
    },
  },
] as ColumnInputDefinition[]
