import { TreeviewItem } from '@/tt-widget-components/components/treeview/types'
import { UserRegion } from '@/tt-widget-components/components/treeview/utils'
import { RegionResponse } from 'tracktik-sdk/lib/entities/region'

/**
 * TTC Api always have a top region ID 1 named "Root" which should not be shown neither used.
 */
export const ROOT_REGION_ID = 1

export type RegionId = string

export type Item = TreeviewItem
export type ItemId = TreeviewItem['id']
export const MAIN_REGION_AND_ALL_SUBREGIONS_CONTEXT_TYPE = 'MainRegionContext'
export const USER_DEFINED_REGIONS_CONTEXT_TYPE = 'MultipleRegionContext'

export type ApiRegion = {
  id: RegionResponse['id']
  name: RegionResponse['name']
  status: RegionResponse['status']
  parentRegion: {
    id: RegionResponse['id']
    name: RegionResponse['name']
    status: RegionResponse['status']
    parentRegion: RegionResponse['id']
  }
}

/**
 * Stateful service that centralize the logic of handling the TTC regions.
 */
export type RegionManager = {
  /**
   * All regions (even outside context) that are `ACTIVE`.
   */
  getAllUserActiveRegions: () => UserRegion[]
  /**
   * All regions, including inactive regions and regions outside the current context.
   */
  getAllUserRegions: () => UserRegion[]

  /**
   * Regions not accessible by the user, but needed for the FE tree logic.
   */
  getMissingParentRegions: () => UserRegion[]

  /**
   * Checks if the user has access to this region ID
   */
  hasAccessToRegionId: (id: RegionId) => boolean

  /**
   * Returns the corresponding Region object, or `undefined` if not found.
   */
  getRegion: (id: RegionId) => UserRegion

  /**
   * Given a region ID, returns if this region is active
   */
  isActiveRegion: (id: RegionId) => boolean

  // ----------------------------
  // CONTEXT REGION
  // ----------------------------
  /**
   * Receives a region ID and set it as the current main context region.
   */
  setMainContextRegionId: (id: string | null) => void
  /**
   * Receives a list of regions and sets the states list
   */
  setContextRegionsList: (regionIds: RegionId[] | []) => void
  /**
   * The current main context region (if any).
   */
  getMainContextRegion: () => UserRegion | null
  /**
   * If a main context region ID is set
   */
  hasMainContextRegion: () => boolean
  /**
   * All region that are part of the current context region.
   */
  getContextRegions: () => UserRegion[]
  /**
   * All region IDs that are part of the current context region.
   */
  getContextRegionIds: () => RegionId[]
  /**
   * Tells if the current context region is handled by the FE.
   *
   * Returns `true` if the `RegionManager` has a context, but the `AuthModule` does not have it set in its HTTP headers.
   *
   * This means the front-end has to handle the scoping of regions.
   */
  isContextRegionHandledByFrontend: () => boolean
  // ----------------------------
}
