<template>
  <div>
    <InfoBox>
      {{ $t('widgets.tql.export_option_input.info_box.export_options') }}
    </InfoBox>
    <v-switch
      v-model="model.disableExport"
      :label="$t('widgets.tql.export_option_input.info_box.disable_export')"
    />
    <v-divider />
    <template v-if="!model.disableExport">
      <v-text-field
        v-model="model.fileName"
        outlined
        dense
        :label="$t('widgets.tql.export_option_input.info_box.file_name')"
      />

      <!-- CSV Options -->
      <CollapsibleHeader
        :title="
          $t(
            'widgets.tql.export_option_input.info_box.collapsible_header_title.csv',
          )
        "
      >
        <v-checkbox
          v-model="model.excludeHeaders"
          outlined
          :label="
            $t('widgets.tql.export_option_input.info_box.exclude_headers.label')
          "
          persistent-hint
          :hint="
            $t(
              'widgets.tql.export_option_input.info_box.exclude_headers.description',
            )
          "
        />
        <template v-if="model.csvOptions">
          <v-checkbox
            v-model="model.csvOptions.encloseAll"
            outlined
            :label="
              $t('widgets.tql.export_option_input.info_box.enclose_all.label')
            "
            persistent-hint
            :hint="
              $t(
                'widgets.tql.export_option_input.info_box.enclose_all.description',
              )
            "
          />
          <v-row class="mt-1">
            <v-col>
              <v-text-field
                v-model="model.csvOptions.delimiter"
                outlined
                dense
                :label="
                  $t('widgets.tql.export_option_input.info_box.delimiter')
                "
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="model.csvOptions.enclosure"
                outlined
                dense
                :label="
                  $t('widgets.tql.export_option_input.info_box.enclosure')
                "
              />
            </v-col>
          </v-row>
        </template>
      </CollapsibleHeader>
      <!-- / CSV Options -->

      <!-- PDF Options -->
      <CollapsibleHeader
        v-if="model.pdfOptions"
        :title="
          $t(
            'widgets.tql.export_option_input.info_box.collapsible_header_title.pdf',
          )
        "
      >
        <v-checkbox
          v-model="model.pdfOptions.includeCoverPage"
          outlined
          :label="
            $t(
              'widgets.tql.export_option_input.info_box.include_cover_page.label',
            )
          "
          persistent-hint
          :hint="
            $t(
              'widgets.tql.export_option_input.info_box.include_cover_page.description',
            )
          "
        />
      </CollapsibleHeader>
      <!-- / PDF Options -->
    </template>
  </div>
</template>
<script lang="ts">
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import { PdfOptions } from '@/tt-widget-components/schemas-types'

export default ObjectInput.extend({
  name: 'TQLQueryExportOptionsInput',
  methods: {
    onCreated(val) {
      if (!val) {
        return val
      }
      // Versioning
      if (val.AdditionalExportOptions) {
        val.csvOptions = val.AdditionalExportOptions
        delete val.AdditionalExportOptions
      }
      val.csvOptions = val.csvOptions ? val.csvOptions : { encloseAll: false }

      return val
    },
  },
  computed: {
    // Return null when it is
    emitNullOnDefault(): true {
      return true
    },
    defaultModel(): {
      disableExport: boolean
      fileName: any
      excludeHeaders: boolean
      csvOptions: {
        encloseAll: boolean
      }
      pdfOptions: PdfOptions
    } {
      return {
        disableExport: false,
        fileName: null,
        excludeHeaders: false,
        csvOptions: {
          encloseAll: false,
        },
        pdfOptions: {
          includeCoverPage: false,
        },
      }
    },
  },
})
</script>
