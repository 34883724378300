import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const SingleCounterWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'SingleCounterWidget',
  'Counter',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.NONE,
  } as DataSetWidgetSchemaConfig,
)

SingleCounterWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/SingleCounterOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadCsvPdf',
  },
  contextFilters: {
    $ref: '#/definitions/DefaultContextFilters',
  },
})

SingleCounterWidgetBuilder.addDefinitions({
  ConditionalColor: {
    type: 'object',
    properties: {
      operator: {
        title: 'Operator',
        enum: ['=', '>', '>=', '<', '<='],
        default: '>',
      },
      value: {
        title: 'Value',
        type: 'number',
        default: 0,
      },
      color: {
        $ref: '#/definitions/Color',
      },
      flashing: {
        title: 'Flashing',
        type: 'boolean',
        default: false,
      },
    },
    required: ['value', 'operator', 'color'],
    default: {
      flashing: false,
      operator: '>',
      value: 0,
      color: null,
    },
  },
  SingleCounterOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      icon: {
        title: 'Icon',
        $ref: '#/definitions/Icon',
      },
      color: {
        title: 'Counter Color',
        $ref: '#/definitions/Color',
      },
      conditions: {
        title: '',
        type: 'array',
        items: {
          $ref: '#/definitions/ConditionalColor',
        },
      },
      disableClick: {
        title: 'Disable Click',
        $ref: '#/definitions/DisableClick',
      },
    },
  },
})

export default SingleCounterWidgetBuilder.schema
