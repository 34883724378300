import hook from './ListWidgetHook'
import template from './ListWidget.vue'
import ListWidgetEditor from './ListWidgetEditor.vue'
import Vue from 'vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

Vue.component('ListWidgetEditor', ListWidgetEditor)

const ListWidget: WidgetTypeDefinition = {
  name: WidgetName.LIST_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    ListWidget: {
      view: {
        is: 'ListWidgetEditor',
      },
    },
  },
  config: {
    icon: 'list',
    color: '#22a7f0',
    thumbnail: require('./data-table.png'),
    print: false,
  },
}

export default ListWidget
