import {
  FormOptions,
  JSONSchema7,
  ViewOption,
} from '@tracktik/tt-json-schema-form'
import { Context } from '../type'
import { BaseField } from './types'

export const showField = (field: BaseField, context: Context): boolean =>
  !!field.toDisplayOn?.[context]?.show

export const isRequired = (field: BaseField, context: Context): boolean =>
  !!field.toDisplayOn?.[context]?.required

/**
 * Create JSON Schema field properties common to all fields
 */
export const getBaseFieldJsonSchema = (field: BaseField): JSONSchema7 => {
  return {
    title: field.name,
    description: field.description,
  }
}
