export enum UserActivityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  AWAY = 'AWAY',
}

export type PresenceTrackingManagerOption = {
  inactivityTime: number
  countdownTime: number
}

export type PresenceTrackingManager = {
  init: (option?: PresenceTrackingManagerOption) => void
  destroy: () => void
}

export const enum PresenceTrackingEvents {
  UPDATED_STATUS = 'presence-tracking-events.updated-status',
  GET_STATUS = 'presence-tracking-events.get-status',
}

export type PresenceTrackingPayload = {
  [PresenceTrackingEvents.UPDATED_STATUS]: { status: UserActivityStatus }
  [PresenceTrackingEvents.GET_STATUS]: void
}
