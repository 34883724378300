import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportConditionsForm from './ReportConditionsForm.vue'

export default {
  install(Vue) {
    Vue.component('ReportConditionsForm', ReportConditionsForm)
    registerResourceForm('report-conditions', 'ReportConditionsForm')
  },
}
