import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const PositionsPayRateColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'positionPayroll.position.account.name',
    headerText: 'res.client-users.attr.client.label',
  },
  {
    attributeName: 'positionPayroll.position.name',
    headerText: 'res.position-payroll-settings.attr.position.label',
  },
  {
    attributeName: 'positionPayroll.position.status',
    headerText: 'res.positions.attr.status.label',
  },
  { attributeName: 'hourlyRateType' },
  { attributeName: 'effectiveDate' },
  { attributeName: 'hourlyRate' },
]
