// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import GeoCountriesTile from './GeoCountriesTile.vue'
import GeoCountriesColumns from './GeoCountriesColumns'
import GeoCountriesDetail from './GeoCountriesDetail.vue'
import GeoCountriesReference from './GeoCountriesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('GeoCountriesTile', GeoCountriesTile)
    Vue.component('GeoCountriesDetail', GeoCountriesDetail)
    Vue.component('GeoCountriesReference', GeoCountriesReference)

    modularManager.addResourcePreset(
      Resources.GEO_COUNTRIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'GeoCountriesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: GeoCountriesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'GeoCountriesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'GeoCountriesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
