// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileSchedulesTile from './MobileSchedulesTile.vue'
import MobileSchedulesColumns from './MobileSchedulesColumns'
import MobileSchedulesDetail from './MobileSchedulesDetail.vue'
import MobileSchedulesMetaCard from './MobileSchedulesMetaCard.vue'
import MobileSchedulesReference from './MobileSchedulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileSchedulesTile', MobileSchedulesTile)
    Vue.component('MobileSchedulesDetail', MobileSchedulesDetail)
    Vue.component('MobileSchedulesMetaCard', MobileSchedulesMetaCard)
    Vue.component('MobileSchedulesReference', MobileSchedulesReference)

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileSchedulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileSchedulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileSchedulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileSchedulesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileSchedulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
