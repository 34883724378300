// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetContactsTile from './AssetContactsTile.vue'
import AssetContactsColumns from './AssetContactsColumns'
import AssetContactsDetail from './AssetContactsDetail.vue'
import AssetContactsMetaCard from './AssetContactsMetaCard.vue'
import AssetContactsReference from './AssetContactsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetContactsTile', AssetContactsTile)
    Vue.component('AssetContactsDetail', AssetContactsDetail)
    Vue.component('AssetContactsMetaCard', AssetContactsMetaCard)
    Vue.component('AssetContactsReference', AssetContactsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_CONTACTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetContactsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CONTACTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetContactsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CONTACTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetContactsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CONTACTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetContactsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CONTACTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetContactsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
