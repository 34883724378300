import { WidgetSchema } from '@/tt-widget-factory'

/**
 * Calendar Weekly Widget Schema
 *
 * End day of an event is calculated by adding 1 day to the start day if the event is set to end on the next day.
 * If no end time is set, the event will end at the end of the day (Midnight).
 */
const calendarWeekWidgetSchema: WidgetSchema = {
  type: 'object',
  title: 'Calendar Weekly',
  required: ['title', 'is', 'query', 'attributeMap'],
  properties: {
    is: {
      enum: ['CalendarWeekWidget'],
    },
    title: {
      title: 'Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
    },
    attributeMap: {
      $ref: '#/definitions/CalendarWeekAttributeMap',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    allowActions: {
      title: 'Allow Actions',
      type: 'boolean',
    },
    allowCreation: {
      title: 'Allow Creation',
      type: 'boolean',
    },
    eventSettings: {
      title: 'Event Settings',
      type: 'object',
      properties: {
        colorByAttribute: {
          title: 'Color By',
          description:
            'Will apply same color for events with same value for this attribute.',
          $ref: '#/definitions/ResourceAttributeName',
        },
      },
      additionalProperties: false,
    },
  },
  definitions: {
    CalendarWeekAttributeMap: {
      title: 'Attribute Mapping',
      type: 'object',
      required: ['weekdayStartAttribute'],
      properties: {
        titleAttribute: {
          title: 'Title Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        weekdayStartAttribute: {
          title: 'The weekday start Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        timeStartAttribute: {
          title: 'The time start Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        timeEndAttribute: {
          title: 'The time end Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
      },
    },
  },
}

export default calendarWeekWidgetSchema
