// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetPersonsTile from './AssetPersonsTile.vue'
import AssetPersonsColumns from './AssetPersonsColumns'
import AssetPersonsDetail from './AssetPersonsDetail.vue'
import AssetPersonsMetaCard from './AssetPersonsMetaCard.vue'
import AssetPersonsReference from './AssetPersonsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetPersonsTile', AssetPersonsTile)
    Vue.component('AssetPersonsDetail', AssetPersonsDetail)
    Vue.component('AssetPersonsMetaCard', AssetPersonsMetaCard)
    Vue.component('AssetPersonsReference', AssetPersonsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_PERSONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetPersonsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_PERSONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetPersonsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_PERSONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetPersonsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_PERSONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetPersonsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_PERSONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetPersonsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
