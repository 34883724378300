import { PropType } from 'vue'
import { ObjectInput } from '@/tt-widget-components/ObjectInput'

export default ObjectInput.extend({
  props: {
    name: String,
    label: String,
    placeholder: String,
    errorMessages: Array as PropType<string[]>,
  },
})
