<template>
  <div>
    <v-toolbar class="toolbar3 text-capitalize" flat short>
      <v-btn text class="px-2 text-capitalize" @click="$emit('back')">
        {{ $t(breadcrumbTitle) }}
      </v-btn>
      <v-icon size="14">keyboard_arrow_right</v-icon>
      <span class="px-2 font-weight-bold">{{ $t(breadcrumbTitle) }}</span>
    </v-toolbar>
    <div class="pa-6">
      <v-alert
        v-if="isFormReady"
        dense
        color="orange"
        text
        type="info"
        class="mb-6"
      >
        {{ createPolicyItemWarningMessage }}
      </v-alert>
      <v-skeleton-loader
        v-if="!isFormReady"
        style="max-width: 350px"
        type="text, text, sentences, sentences, button"
      />
      <json-form
        v-if="isFormReady"
        v-model="model"
        :schema="formSchema"
        :form-options="formOptions"
        :user-context="userContext"
        :name="rootName"
        style="max-width: 350px"
        @errors="errors = $event"
        @valid="errors = null"
        @debouncing="debouncing = $event"
      >
        <LeavePolicyUniqueField
          name="customId"
          :resource="resourceName"
          :max-length="9"
          :response-errors="responseErrors"
          :disabled="isEditModeRestricted"
        />
        <LeavePolicyUniqueField
          name="name"
          :resource="resourceName"
          :response-errors="responseErrors"
          :disabled="isEditModeRestricted"
        />
        <json-field name="description" max-length="255" />
        <json-field
          v-if="!hasValidRegion"
          name="region"
          :disabled="isEditModeRestricted"
        />
        <json-field name="leaveType" :disabled="isEditModeRestricted" />
        <json-field name="payCode" :disabled="isEditModeRestricted" />
        <LeavePolicyItemSelectorFields
          v-bind="{ responseErrors, isEditModeRestricted }"
          @isAccrued="(option) => (isAccrued = option)"
        />
        <LeavePolicyItemAccrualRateField
          :response-errors="responseErrors"
          :disabled="isEditModeRestricted"
          :placeholder="accrualRatePlaceholder"
        />
        <json-field
          v-show="!isAccrued"
          name="entitlementUnit"
          :disabled="isEditModeRestricted"
          :placeholder="entitlementUnitPlaceholder"
        />
        <json-field
          name="quantity"
          :disabled="isEditModeRestricted"
          :placeholder="quantityPlaceholder"
        />
        <json-field
          name="carryOver"
          :disabled="isEditModeRestricted"
          color="ttPrimary"
          :ripple="false"
          class="black-label mt-0"
        />
        <json-field
          v-show="isAccrued"
          name="entitlementUnit"
          :disabled="isEditModeRestricted"
          :placeholder="entitlementUnitPlaceholder"
        />
        <json-field
          v-if="isCarryOverItem"
          name="carryOverLimit"
          :disabled="isEditModeRestricted"
        />
      </json-form>
      <v-btn
        :disabled="!isValid"
        raised
        color="ttPrimary"
        style="text-transform: none"
        class="white--text"
        :loading="loading || isSubmitting || !isFormReady"
        @click="submit"
      >
        <v-icon left>mdi-check</v-icon>
        <span class="text-capitalize" v-text="saveButtonLabel"></span>
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import {
  FormOptions,
  OpenAPICompatibleSchema,
} from '@tracktik/tt-json-schema-form'
import { FormSchemaTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { getResourceDefinitions } from '@/tt-entity-forms/EntityViewDefinitions'
import { Resources } from '@/tt-entity-design/src/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import LeavePolicyUniqueField from '../../LeavePolicyUniqueField.vue'
import LeavePolicyItemAccrualRateField from './LeavePolicyItemAccrualRateField.vue'
import LeavePolicyItemSelectorFields from './LeavePolicyItemSelectorFields.vue'
import { LeavePolicy, LeavePolicyItem, PayRatePreference } from '../../types'

import { normalizeName } from '@/helpers/text'
import capitalize from 'lodash/capitalize'

export const BASE_LOCALE_KEY =
  'tt-entity-design.leave-management.leave-policy-items'

export default (Vue as VueConstructor<Vue>).extend({
  name: 'LeavePolicyItemsForm',
  components: {
    LeavePolicyUniqueField,
    LeavePolicyItemAccrualRateField,
    LeavePolicyItemSelectorFields,
  },
  props: {
    leavePolicyItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      debouncing: false,
      errors: null,
      formSchema: null as OpenAPICompatibleSchema,
      isSubmitting: false,
      model: {} as LeavePolicyItem,
      rootName: '',
      resourceName: Resources.LEAVE_POLICY_ITEMS,
      responseErrors: {},
      isEditModeRestricted: false,
      userContext: { resourceName: Resources.LEAVE_POLICY_ITEMS },
      isAccrued: false,
      loading: false,
    }
  },
  computed: {
    createdSuccessfullyMessage(): string {
      return this.$t(`${BASE_LOCALE_KEY}.create-success`)
    },
    editedSuccessfullyMessage(): string {
      return this.$t(`${BASE_LOCALE_KEY}.edit-success`)
    },
    breadcrumbTitle(): string {
      return `${BASE_LOCALE_KEY}.new-entity-default`
    },
    formOptions(): FormOptions {
      return {
        definitions: getResourceDefinitions(this.$appContext),
        locale: this.$i18n.locale,
        translateFunction: ResourceTranslator.getFormCallback(
          this.resourceName,
        ),
      }
    },
    isValid(): boolean {
      return !this.errors
    },
    regionID(): number {
      return parseInt(this.$appContext.authModule.getContextRegionId(), 10)
    },
    hasValidRegion(): boolean {
      return !isNaN(this.regionID)
    },
    isPolicyItemIdProvided(): boolean {
      return !!this.leavePolicyItemId
    },
    isFormReady(): boolean {
      const modelValues = Object.values(this.model).filter(
        (value) => value != null,
      )
      if (this.isPolicyItemIdProvided) {
        return Boolean(this.formSchema && modelValues.length)
      } else {
        return Boolean(this.formSchema)
      }
    },
    createPolicyItemWarningMessage(): string {
      return this.$t(`${BASE_LOCALE_KEY}.warning-message`)
    },
    isCarryOverItem(): boolean {
      return !!this.model?.carryOver
    },
    saveButtonLabel(): string {
      return this.$t(`${BASE_LOCALE_KEY}.save-button`)
    },
    entitlementUnitPlaceholder(): string {
      return this.$t(`${BASE_LOCALE_KEY}.entitlementUnit.placeholder`)
    },
    quantityPlaceholder(): string {
      return this.$t(`${BASE_LOCALE_KEY}.quantity.placeholder`)
    },
    accrualRatePlaceholder(): string {
      return this.$t(`${BASE_LOCALE_KEY}.accrualRate.placeholder`)
    },
  },
  watch: {
    isCarryOverItem(val: boolean) {
      if (!val && this.model?.carryOverLimit >= 0) {
        this.model.carryOverLimit = null
      }
    },
    'model.payCode': {
      handler(val: boolean) {
        if (val) {
          this.loading = true
          this.$auth
            .getApi()
            .get(Resources.PAYROLL_CODES, this.model.payCode)
            .then((payrollCode: { payable: boolean }) => {
              if (payrollCode?.payable) {
                this.setPayRatePreference(PayRatePreference.EMPLOYEE)
              } else {
                this.setPayRatePreference(null)
              }
            })
            .finally(() => (this.loading = false))
        } else {
          this.setPayRatePreference(null)
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.isPolicyItemIdProvided) {
      this.prepareEditForm()
    } else {
      this.prepareCreateForm()
    }
  },
  methods: {
    loadLeavePolicyItemData(): void {
      this.$appContext.entityServices.persister.api
        .get(this.resourceName, this.leavePolicyItemId, {
          include: ['leavePolicies', 'leavePolicies.employees'],
        })
        .then((loadedLeavePolicyItem: LeavePolicyItem) => {
          const { leavePolicies, ...leavePolicyItemInfo } =
            loadedLeavePolicyItem
          this.validatePolicyUsage(leavePolicies)
          this.model = { ...leavePolicyItemInfo }
          if (leavePolicyItemInfo.accrualRate) {
            this.isAccrued = true
          }
        })
        .catch((error) => {
          this.$crash.captureException(error)
          this.dispatchErrorMessage(error?.response?.data)
        })
    },
    validatePolicyUsage(leavePolicies: LeavePolicy[]) {
      if (!leavePolicies?.length) return
      this.isEditModeRestricted =
        this.isAssignedToUsedLeavePolicy(leavePolicies)
    },
    isAssignedToUsedLeavePolicy(leavePolicies: LeavePolicy[]): boolean {
      return leavePolicies.some((lp) => lp.employees.length > 0)
    },
    prepareEditForm(): void {
      this.setFormRootName(this.resourceName, FormSchemaTypes.EDIT)
      this.prepareFormSchema(FormSchemaTypes.EDIT)
      this.loadLeavePolicyItemData()
    },
    prepareCreateForm(): void {
      this.setFormRootName(this.resourceName, FormSchemaTypes.CREATE)
      this.prepareFormSchema(FormSchemaTypes.CREATE)
      this.prepareInitialModelData()
    },
    setFormRootName(resourceName: string, schemaMode: FormSchemaTypes): void {
      this.rootName = normalizeName(resourceName).concat(capitalize(schemaMode))
    },
    setPayRatePreference(payRatePreference: PayRatePreference | null): void {
      this.model = { ...this.model, payRatePreference }
    },
    prepareFormSchema(schemaName: FormSchemaTypes): void {
      this.formSchema =
        this.$appContext.widgetServices.resourceMetaManager.getFormSchema(
          Resources.LEAVE_POLICY_ITEMS,
          schemaName,
        )
    },
    prepareInitialModelData(): void {
      if (this.hasValidRegion) {
        this.model.region = this.regionID
      }
    },
    submit(): void {
      this.isSubmitting = true

      const operationResponse = this.isPolicyItemIdProvided
        ? this.editLeavePolicyItem()
        : this.createLeavePolicyItem()

      operationResponse
        .then(() => {
          this.dispatchSuccessMessage()
          this.dispatchResourceUpdatedEvent()
          this.$emit('back')
        })
        .catch(({ response }) => {
          this.$crash.captureException(response)
          if (response?.data?.message) {
            this.dispatchErrorMessage(response.data.message)
          } else {
            this.responseErrors = { ...response.data }
          }
        })
        .finally(() => (this.isSubmitting = false))
    },
    createLeavePolicyItem(): Promise<{ id: number }> {
      return this.$appContext.entityServices.persister.addEntity(
        this.resourceName,
        this.model,
      )
    },
    editLeavePolicyItem() {
      const editModel = this.model
      return this.$appContext.entityServices.persister.updateEntity(
        this.resourceName,
        editModel,
        this.leavePolicyItemId,
      )
    },
    dispatchSuccessMessage(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.isPolicyItemIdProvided
          ? this.editedSuccessfullyMessage
          : this.createdSuccessfullyMessage,
      })
    },
    dispatchErrorMessage(message: string) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: message || this.$t('common.error_message'),
      })
    },
    dispatchResourceUpdatedEvent(): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: this.resourceName,
      })
    },
  },
})
</script>
<style scoped>
.black-label >>> .v-label {
  color: black !important;
}
</style>
