<template>
  <v-text-field
    disabled
    v-bind="{ ...$props, ...$attrs }"
    error
    :error-messages="$t(`common.missing-view-permission-resource`)"
    prepend-inner-icon="mdi-lock"
    style="width: 100%"
    type="text"
    value="******"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'RelationFieldCannotView',
})
</script>
