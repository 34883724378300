<template>
  <WidgetPage :widget="widget" v-bind="$props" :show-context="true" />
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { CollectionQuery, WidgetModels } from '@/tt-widget-components'
import { Breakpoints } from '@/tt-widget-entity-flow/types'
import { getResourceLabelKey } from '@/tt-app-builder/helpers'
import VueI18n from 'vue-i18n'
export default Vue.extend({
  name: 'ResourceList',
  computed: {
    widget(): {
      title: VueI18n.TranslateResult
      is: string
      query: CollectionQuery
    } {
      return this.$vuetify.breakpoint[this.breakpoint]
        ? {
            title:
              this.title ?? this.$t(getResourceLabelKey(this.query.resource)),
            is: 'ListWidget',
            query: this.query,
          }
        : {
            title:
              this.title ?? this.$t(getResourceLabelKey(this.query.resource)),
            is: 'DataTableWidget',
            query: this.query,
          }
    },
  },
  props: {
    title: {
      type: String,
    },
    breakpoint: {
      type: String as PropType<Breakpoints>,
      default: Breakpoints.TABLET_AND_SMALLER,
      validator(value: Breakpoints): boolean {
        return [
          Breakpoints.PHONE,
          Breakpoints.TABLET_AND_LARGER,
          Breakpoints.TABLET_AND_SMALLER,
        ].includes(value)
      },
    },
    query: {
      type: Object as PropType<CollectionQuery>,
      required: true,
    },
  },
})
</script>
