// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SystemSettingsTile from './SystemSettingsTile.vue'
import SystemSettingsColumns from './SystemSettingsColumns'
import SystemSettingsDetail from './SystemSettingsDetail.vue'
import SystemSettingsMetaCard from './SystemSettingsMetaCard.vue'
import SystemSettingsReference from './SystemSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SystemSettingsTile', SystemSettingsTile)
    Vue.component('SystemSettingsDetail', SystemSettingsDetail)
    Vue.component('SystemSettingsMetaCard', SystemSettingsMetaCard)
    Vue.component('SystemSettingsReference', SystemSettingsReference)

    modularManager.addResourcePreset(
      Resources.SYSTEM_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SystemSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SystemSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SystemSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SystemSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SystemSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
