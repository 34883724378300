// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointToursTile from './CheckpointToursTile.vue'
import CheckpointToursColumns from './CheckpointToursColumns'
import CheckpointToursDetail from './CheckpointToursDetail.vue'
import CheckpointToursMetaCard from './CheckpointToursMetaCard.vue'
import CheckpointToursReference from './CheckpointToursReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CheckpointToursTile', CheckpointToursTile)
    Vue.component('CheckpointToursDetail', CheckpointToursDetail)
    Vue.component('CheckpointToursMetaCard', CheckpointToursMetaCard)
    Vue.component('CheckpointToursReference', CheckpointToursReference)

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOURS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointToursTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOURS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointToursColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOURS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointToursDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOURS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointToursMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOURS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointToursReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
