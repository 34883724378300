<template>
  <div>
    <AccountContextJsonField name="account" />
    <json-field name="name" prepend-inner-icon="mdi-office-building" />
    <v-tabs
      v-model="tab"
      slider-color="orange"
      class="small-tabs"
      dense
      small
      short
      :height="35"
    >
      <v-tab v-text="$t('common.label.main')" />
      <v-tab v-text="$t('common.label.contact')" />
      <v-tab v-text="$t('common.label.address')" />
    </v-tabs>

    <v-divider class="mt-6" />

    <div style="min-height: 350px">
      <div v-if="tab === 0">
        <json-field name="description" />
        <json-field name="website" />
      </div>

      <div v-else-if="tab === 1">
        <json-field name="contactName" prepend-inner-icon="person" />
        <json-field name="email" />
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pr-2">
            <json-field name="phoneNumber" prepend-inner-icon="phone" />
          </v-col>
          <v-col cols="12" sm="6">
            <json-field name="faxNumber" prepend-inner-icon="fax" />
          </v-col>
        </v-row>
      </div>

      <div v-else-if="tab === 2">
        <v-row no-gutters>
          <v-col>
            <json-field name="addressLine1" />
            <json-field name="addressLine2" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pr-2">
            <json-field name="city" />
          </v-col>
          <v-col cols="6" sm="3" class="pr-2">
            <json-field name="state" />
          </v-col>
          <v-col cols="6" sm="3">
            <json-field name="postalCode" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <json-field name="country" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetManufacturersForm',
  data() {
    return {
      tab: 0,
    }
  },
})
</script>
