import { VueConstructor } from 'vue'

import columnChart from './ColumnChart'
import componentsInstaller from '../components'
import dashboard from './Dashboard'
import dataTable from './DataTable'
import gaugeChart from './GaugeChart'
import heatMap from './HeatMap'
import horizontalChart from './HorizontalChart'
import lineChart from './LineChart'
import list from './List'
import map from './Map'
import metricTable from './MetricTable'
import pieChart from './PieChart'
import PieChartWidgetHook from './PieChart/PieChartWidgetHook'
import pivot from './Pivot'
import richText from './RichText'
import scheduler from './Scheduler'
import calendar from './Calendar'
import calendarWeek from './CalendarWeek'
import singleCounter from './SingleCounter'
import tqlQuery from './TQLQuery'
import treeMap from './TreeMap'
import TreeMapWidgetHook from './TreeMap/TreeMapWidgetHook'
import vimeo from './Vimeo'
import WidgetManager from '@/tt-widget-factory/WidgetManager'
import youtube from './Youtube'
import { getColumnChartSyncFusionComponent } from './ColumnChart/columnChartSyncFusionComponent'
import { getGaugeChartSyncFusionComponent } from './GaugeChart/gaugeChartSyncFusionComponent'
import { getHeatMapSyncFusionComponent } from './HeatMap/heatMapSyncFusionComponent'
import { getHorizontalChartSyncFusionComponent } from './HorizontalChart/horizontalChartSyncFusionComponent'
import { getLineChartSyncFusionComponent } from './LineChart/lineChartSyncFusionComponent'
import { getPieChartSyncFusionComponent } from './PieChart/pieChartSyncFusionComponent'
import { getTreeMapSyncFusionComponent } from './TreeMap/treeMapSyncFusionComponent'

import { MapMultiMarkersDefinition } from './MapMultiMarkers'

export {
  columnChart,
  dashboard,
  dataTable,
  gaugeChart,
  getColumnChartSyncFusionComponent,
  getGaugeChartSyncFusionComponent,
  getHeatMapSyncFusionComponent,
  getHorizontalChartSyncFusionComponent,
  getLineChartSyncFusionComponent,
  getPieChartSyncFusionComponent,
  getTreeMapSyncFusionComponent,
  heatMap,
  horizontalChart,
  lineChart,
  list,
  map,
  metricTable,
  pieChart,
  PieChartWidgetHook,
  pivot,
  richText,
  scheduler,
  singleCounter,
  tqlQuery,
  treeMap,
  TreeMapWidgetHook,
  vimeo,
  youtube,
  calendar,
  MapMultiMarkersDefinition,
}

export const registerWidgetSchemas = (widgetManager: WidgetManager) => {
  widgetManager.registerWidget(singleCounter)

  // Aggregators
  widgetManager.registerWidget(treeMap)
  widgetManager.registerWidget(pieChart)

  // Line / Horizontal
  widgetManager.registerWidget(columnChart)
  widgetManager.registerWidget(metricTable)
  widgetManager.registerWidget(horizontalChart)
  widgetManager.registerWidget(lineChart)
  widgetManager.registerWidget(gaugeChart)

  // Collections
  widgetManager.registerWidget(tqlQuery)
  widgetManager.registerWidget(dataTable)
  widgetManager.registerWidget(list)
  widgetManager.registerWidget(scheduler)
  widgetManager.registerWidget(calendar)
  widgetManager.registerWidget(calendarWeek)

  // Multi collections
  widgetManager.registerWidget(MapMultiMarkersDefinition)

  // Multi dimensions
  widgetManager.registerWidget(pivot)
  widgetManager.registerWidget(heatMap)

  // Other
  widgetManager.registerWidget(map)

  // Layouts
  widgetManager.registerWidget(dashboard)

  // Support
  widgetManager.registerWidget(vimeo)
  widgetManager.registerWidget(richText)
  widgetManager.registerWidget(youtube)
}

export default {
  install(widgetManager: WidgetManager, Vue: VueConstructor) {
    registerWidgetSchemas(widgetManager)
    Vue.use(componentsInstaller)
  },
}
