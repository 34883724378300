<template>
  <v-sheet
    ref="chart"
    class="fill-height d-flex flex-column dashboard-column"
    color="level1"
    :data-cy="`widget-column-${rowIndex}-${colIndex}`"
    :class="focused && editMode ? `elevation-8` : ''"
    style="position: relative; border-radius: 7px"
    @mouseover="focused = 1"
    @mouseout="focused = 0"
  >
    <DashboardColumnEditToolbar
      v-if="editMode"
      :row-index="rowIndex"
      :col-index="colIndex"
      :widget="currentWidget"
      :hook="hook"
      :tab="tab"
      @tab="tab = $event"
    />

    <DashboardWidgetTabs
      v-if="isTab || editMode"
      v-model="tab"
      v-bind="{
        widgets,
        hook,
        editMode,
        showInfoButton,
        rowIndex,
        colIndex,
      }"
      class="hide-in-print-view"
      @deleteTab="deleteTab"
      @infoClick="infoClick"
      @addTab="addTab"
    />

    <div v-intersect="onIntersect" class="fill-height" style="overflow: hidden">
      <template v-for="(widget, tabIndex) in widgets">
        <keep-alive :key="`tab-${tabIndex}`">
          <DashboardWidgetTabItem
            v-if="canShowTab(tabIndex)"
            :ref="`tab-${tabIndex}`"
            v-bind="{
              widget,
              hook,
              columnContainer,
              rowIndex,
              colIndex,
              tabIndex,
              editMode,
              isTab,
            }"
          />
        </keep-alive>
      </template>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import DashboardColumnEditToolbar from '@/tt-widget-components/widgets/Dashboard/DashboardColumnEditToolbar.vue'
import DashboardWidgetHook from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetHook'
import DashboardWidgetTabItem from './DashboardWidgetTabItem.vue'
import DashboardWidgetTabs from './DashboardWidgetTabs.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import {
  DashboardColumn,
  WidgetModels,
} from '@/tt-widget-components/schemas-types'
import { isEmpty } from '@/helpers/isEmpty'
import { LayoutComponents, PageInterface } from '@/tt-app-layout/types'
import {
  WidgetContainerInterface,
  WidgetContainerType,
  WidgetHookInterface,
} from '@/tt-widget-factory/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { ApplyContextFilters } from '@/plugins/o11n'
import { canDisplayWidgetDetails } from '../../components/WidgetDetails.utils'
import { ForceMountWidget, forceMountWidget } from './ForceMountWidget'
import { updateDOM } from '@/helpers/dom'

type VueWithInjections = VueConstructor<
  Vue & { layoutManager?: LayoutManager } & ForceMountWidget
>

export default (Vue as VueWithInjections).extend({
  name: 'DashboardWidgetColumn',
  components: {
    DashboardColumnEditToolbar,
    DashboardWidgetTabItem,
    DashboardWidgetTabs,
  },
  inject: {
    layoutManager: {
      default: undefined,
    },
    forceMountWidget,
  },
  props: {
    colIndex: {
      type: Number,
      required: true,
    },
    dashboardContainer: {
      type: Object as PropType<WidgetContainerInterface>,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
    },
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      focused: 0,
      tab: 0,
      isInViewPort: false,
    }
  },
  computed: {
    showInfoButton(): boolean | string {
      return this.isTab && this.canShowWidgetDetails && !this.editMode
    },
    canShowWidgetDetails(): boolean {
      return canDisplayWidgetDetails(this.currentWidget, this.$appContext)
    },
    column(): DashboardColumn {
      return this.hook.state.widget.rows[this.rowIndex].columns[this.colIndex]
    },
    columnContainer(): WidgetContainerInterface {
      return {
        ...this.dashboardContainer,
        type:
          this.widgets.length > 1
            ? WidgetContainerType.DASHBOARD_TAB
            : WidgetContainerType.DASHBOARD_CELL,
      }
    },
    currentWidget(): WidgetModels {
      return this.widgets[this.tab]
    },
    isEditing(): boolean {
      if (!this.layoutManager) return false
      const componentState = this.layoutManager.getComponentState(
        LayoutComponents.sideSheet,
      )
      return (
        this.sideSheetActivePage?.is === 'WidgetEditView' &&
        componentState?.state
      )
    },
    isTab(): boolean {
      return this.widgets.length > 1
    },
    widgets(): WidgetModels[] {
      return Array.isArray(this.column.widgetLookup)
        ? this.column.widgetLookup
        : [this.column.widgetLookup]
    },
    sideSheetActivePage(): PageInterface {
      return this.layoutManager.getActivePage(LayoutComponents.sideSheet)
    },
  },
  watch: {
    async tab() {
      await updateDOM()
      if (this.isEditing) {
        this.callCreateOrEditHook()
      } else if (
        this.canShowWidgetDetails &&
        this.sideSheetActivePage?.is === 'WidgetDetails'
      ) {
        this.infoClick()
      } else {
        this.closeSideSheet()
      }
      // Event dispatched to force RealTimeOverflowBox to recalculate overflow
      window.dispatchEvent(new Event('resize'))
    },
    widgets: {
      deep: true,
      handler(widgets: WidgetModels[]) {
        if (this.tab > widgets.length - 1) {
          this.tab = widgets.length - 1
        }
      },
    },
  },
  methods: {
    /**
     * https://vuetifyjs.com/en/api/v-intersect
     */
    onIntersect(_, __, isIntersecting: boolean) {
      this.isInViewPort = isIntersecting
    },
    canShowTab(tabIndex: number) {
      const isVisible = this.isInViewPort || this.forceMountWidget

      return isVisible && this.tab === tabIndex
    },
    closeSideSheet(): void {
      this.layoutManager.state.sideSheet.state = false
    },
    callCreateOrEditHook() {
      if (isEmpty(this.currentWidget)) {
        this.hook.create(this.rowIndex, this.colIndex, this.tab)
      } else {
        this.hook.edit(this.rowIndex, this.colIndex, this.tab)
      }
    },
    /**
     * Workaround to grab the children hook of a dashboard cell for the details page
     */
    getActiveWidgetHook(): WidgetHookInterface | null {
      return this.$refs[`tab-${this.tab}`]?.[0]?.widgetHook || null
    },
    infoClick() {
      if (this.canShowWidgetDetails) {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET, {
          is: 'WidgetDetails',
          title: this.$t('widgets.details.title'),
          props: {
            hook: this.getActiveWidgetHook(),
            isDashboardWrapper: true,
          },
        })
      }

      this.$analytics.track(
        ApplyContextFilters.create({
          label: this.currentWidget?.is,
        }),
      )
    },
    deleteTab(index: number): void {
      this.hook.deleteTab(this.rowIndex, this.colIndex, index)
    },
    addTab(): void {
      this.tab = this.hook.addTab(this.rowIndex, this.colIndex)
    },
  },
})
</script>

<style scoped>
@media print {
  .hide-in-print-view {
    display: none !important;
  }
}
</style>
