<template>
  <v-app id="hcm-leave-management-injected">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <HumanCapitalManagementEmployeeView :employee-id="employeeId" />
        <AppLayoutUtils :layout-configuration="layoutConfiguration" />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import HumanCapitalManagementEmployeeView from './employee-profile/HumanCapitalManagementEmployeeView.vue'
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'

export default Vue.extend({
  name: 'HumanCapitalManagementEmployeeInjected',
  components: {
    ProvideLayoutManager,
    HumanCapitalManagementEmployeeView,
  },
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager, this.layoutConfiguration),
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
