// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PayrollCodesTile from './PayrollCodesTile.vue'
import PayrollCodesColumns from './PayrollCodesColumns'
import PayrollCodesDetail from './PayrollCodesDetail.vue'
import PayrollCodesReference from './PayrollCodesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PayrollCodesTile', PayrollCodesTile)
    Vue.component('PayrollCodesDetail', PayrollCodesDetail)
    Vue.component('PayrollCodesReference', PayrollCodesReference)

    modularManager.addResourcePreset(
      Resources.PAYROLL_CODES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PayrollCodesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_CODES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PayrollCodesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_CODES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollCodesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_CODES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PayrollCodesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
