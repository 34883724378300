import { isSameDate } from '@/helpers/dates/isSameDate'
import moment from 'moment'
import { createDateTimeString } from '@/helpers/dates/createDateTimeString'

export class LeaveRequestsDatesManager {
  static readonly APIDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
  static readonly defaultStartTime = '00:00:00'

  static isSameOrBefore(date: string | Date, target: string | Date): boolean {
    return moment(date).isSameOrBefore(target)
  }

  static isSameOrAfter(date: string | Date, target: string | Date): boolean {
    return moment(date).isSameOrAfter(target)
  }

  static getStartOfTheMonth(date: string | Date) {
    return moment(date).startOf('month').format(this.APIDateTimeFormat)
  }

  static getEndOfTheMonth(date: string | Date) {
    return moment(date).endOf('month').format(this.APIDateTimeFormat)
  }

  static formatStartDate(date: string, time?: string) {
    const startDateTime: moment.Moment = time
      ? moment(createDateTimeString(date, time))
      : moment(date).startOf('day')

    return startDateTime.format(LeaveRequestsDatesManager.APIDateTimeFormat)
  }

  static formatEndDate(date: string, time?: string) {
    const endDateTime = time
      ? moment(createDateTimeString(date, time)).subtract(1, 'second')
      : moment(date).endOf('day')

    return endDateTime.format('YYYY-MM-DD HH:mm:ss')
  }

  static formatSameDay(date: string, startTime: string): string[] {
    return [
      LeaveRequestsDatesManager.formatStartDate(date, startTime),
      startTime
        ? LeaveRequestsDatesManager.generateEndDayWith24hrsGap(
            `${date} ${startTime}`,
          )
        : LeaveRequestsDatesManager.formatEndDate(date),
    ]
  }

  static formatDateRangeForApi(
    startDate: string,
    endDate: string,
    startTime?: string,
  ): string[] {
    const isMidnight = startTime === LeaveRequestsDatesManager.defaultStartTime
    const isSameDay = !endDate || isSameDate(moment(startDate), moment(endDate))
    const time = isMidnight ? null : startTime

    if (isSameDay) {
      return LeaveRequestsDatesManager.formatSameDay(startDate, time)
    }

    return [
      LeaveRequestsDatesManager.formatStartDate(startDate, time),
      LeaveRequestsDatesManager.formatEndDate(endDate, time),
    ]
  }
  static generateEndDayWith24hrsGap = (date: string): string => {
    return moment(date)
      .add(1, 'day')
      .subtract(1, 'second')
      .format('YYYY-MM-DD HH:mm:ss')
  }
}
