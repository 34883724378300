<template>
  <v-menu offset-y left>
    <template #activator="{ on: onMenu, attrs }">
      <v-btn
        small
        icon
        :disabled="disabled"
        :loading="hook.loading"
        v-on="onMenu"
      >
        <v-tooltip :nudge-left="30" bottom>
          <template #activator="{ on: onTooltip }">
            <v-icon
              v-bind="attrs"
              small
              v-on="onTooltip"
              v-text="`mdi-download`"
            />
          </template>

          <span v-text="$t('common.download.choose.format')" />
        </v-tooltip>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-if="showPdf" @click="$emit('exportPdf')">
        <v-list-item-title v-text="$t('common.download.pdf.btn')" />
      </v-list-item>

      <DownloadCSVButton v-if="showCsv" v-slot="{ exportCSV }" :hook="hook">
        <v-list-item @click="exportCSV">
          <v-list-item-title v-text="$t('common.download.csv.btn')" />
        </v-list-item>
      </DownloadCSVButton>

      <v-list-item v-if="showExcel" @click="$emit('exportExcel')">
        <v-list-item-title v-text="$t('common.download.xls.btn')" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { WidgetModels } from '@/tt-widget-components/types'
import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import { CollectionWidgetHook } from '@/tt-widget-components/base'
import DownloadCSVButton from '@/tt-widget-components/components/DownloadCSVButton.vue'

export default Vue.extend({
  name: 'WidgetDownloadButton',
  components: { DownloadCSVButton },
  props: {
    showExcel: {
      type: Boolean,
      default: false,
    },
    showPdf: {
      type: Boolean,
      default: false,
    },
    showCsv: {
      type: Boolean,
      default: false,
    },
    hook: {
      required: true,
      type: Object as PropType<
        DataSetWidgetHook<WidgetModels> | CollectionWidgetHook<WidgetModels>
      >,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
