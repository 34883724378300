import { compact, last } from 'lodash'

import i18n from '@/plugins/i18n'
import {
  FormLabelTypes,
  FormTranslateFunctionInterface,
} from '@tracktik/tt-json-schema-form'
import { isEmpty } from '@/helpers/isEmpty'

const WIDGET_FORM_TRANSLATE_REPLACE_MAP: Record<string, string> = {
  'dataSet.filters.label': 'advanced_filters',
  'dataSet.dimensions.label': 'dimensions.label',
}

export const widgetFormTranslateFunction: FormTranslateFunctionInterface = (
  text: string,
  path: string,
  type: FormLabelTypes,
  rootName: string,
  definitionName?: string,
  enumValue?: string,
) => {
  const name = last(path.split('.'))

  let partialKey = definitionName
    ? `${definitionName}.${name}.${type}`
    : `${rootName}.${path}.${type}`

  // Some translations are replaced by a constant
  const replaceKey = Object.keys(WIDGET_FORM_TRANSLATE_REPLACE_MAP).find(
    (key) => partialKey.includes(key),
  )
  if (replaceKey) {
    partialKey = WIDGET_FORM_TRANSLATE_REPLACE_MAP[replaceKey]
  }

  const key = compact(['widget_edit', partialKey, enumValue]).join('.')
  const translated = i18n.tc(key)
  return translated === key ? `ERR:${key}` : translated
}
