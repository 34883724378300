<template>
  <span class="info-button">
    <v-btn
      rounded
      icon
      small
      class="opacity-faded transparent"
      @click="infoClick"
    >
      <v-icon small v-text="`mdi-information`" />
    </v-btn>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ApplyContextFilters } from '@/plugins/o11n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import CollectionWidgetHook from '@/tt-widget-components/base/CollectionWidgetHook'
import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import { WidgetModels } from '@/tt-widget-components/schemas-types'

export default Vue.extend({
  name: 'WidgetInfoButton',
  props: {
    hook: {
      type: Object as PropType<
        DataSetWidgetHook<WidgetModels> | CollectionWidgetHook<WidgetModels>
      >,
      required: true,
    },
    isDashboardWrapper: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    infoClick() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET, {
        is: 'WidgetDetails',
        title: this.$t('widgets.details.title'),
        props: {
          hook: this.hook,
          isDashboardWrapper: this.isDashboardWrapper,
        },
      })
      this.$analytics.track(
        ApplyContextFilters.create({
          label: this.hook.widget.is,
        }),
      )
    },
  },
})
</script>

<style scoped>
.info-button {
  background-color: #0000 !important;
}
</style>
