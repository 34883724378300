import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { FieldMeta, FieldConverter, FieldUI } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import { NUMBER, NumberFieldType } from './types'
import schema from './schema.json'

const ui: FieldUI = {
  name: i18nFieldKey(NUMBER), // to be localized
  icon: 'mdi-numeric',
  color: 'yellow darken-2',
}

const converter: FieldConverter = (field: NumberFieldType) => ({
  getJsonSchema: () => {
    return { ...getBaseFieldJsonSchema(field), type: 'number' }
  },
  getViewOption: () => {
    return {}
  },
})

export const NumberMeta: FieldMeta = {
  field: NUMBER,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
