<template>
  <WidgetWrapper
    v-if="hook.isReadyToFetch"
    v-bind="$props"
    :has-header="false"
    :class="{ 'no-click': disableClick }"
    @rendered="onWidgetRendered"
  >
    <template #default>
      <VerticalStackCard v-slot="{ clientHeight }">
        <div class="counter-container">
          <WidgetInfoButton
            v-if="showInfoButton && !getEditMode()"
            :hook="hook"
            :is-dashboard-wrapper="isDashboardWrapper"
            class="info-button"
          />
          <v-card
            flat
            class="counter--card"
            :class="{ 'counter--flashing': flashing, pointer: !disableClick }"
            :dark="color ? true : $vuetify.theme.dark"
            @click="click"
          >
            <div
              class="counter--background"
              :style="{ backgroundColor: color || 'transparent' }"
            />
            <v-icon
              v-if="icon"
              class="counter--icon--watermarked"
              :size="clientHeight * 1.5"
              v-text="icon"
            />
            <v-row
              class="align-center text-center justify-center flex ma-0"
              style="height: 100%"
            >
              <v-col>
                <div :style="textStyle" style="opacity: 0.8">
                  <div style="font-size: 1.8em" v-text="hook.counterValue" />
                  <div
                    v-if="hook.widget.title || hook.widget.description"
                    style="padding-top: 10px"
                    v-text="hook.widget.title"
                  />
                  <div
                    v-if="hook.widget.description"
                    style="font-style: italic; font-size: 0.8em"
                    v-text="hook.widget.description"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </VerticalStackCard>
    </template>

    <template #loader>
      <WidgetLoader
        :widget="hook.widget"
        class="align-center text-center justify-center flex ma-0"
      >
        <v-row
          class="align-center text-center justify-center flex ma-0"
          style="height: 100%"
        >
          <v-col>
            <div :style="textStyle" style="opacity: 0.8">
              <div style="font-size: 1.8em; padding-bottom: 10px">
                {{ '...' }}
              </div>
              <div v-text="hook.widget.title" />
            </div>
          </v-col>
        </v-row>
      </WidgetLoader>
    </template>
  </WidgetWrapper>
</template>

<script lang="ts">
import { PropType, VueConstructor } from 'vue'

import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import SingleCounterWidgetHook from './SingleCounterWidgetHook'
import { DashboardWidgetRowSizes } from '../Dashboard/types'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { EditModeProvider } from '@/apps/app.tracktik.insights.studio/types'
import WidgetInfoButton from '@/tt-widget-components/components/WidgetInfoButton.vue'
import { usePresentationMode } from '@/apps/app.tracktik.insights.studio/lib/RouterUtils'

const { DASHBOARD_CELL } = WidgetContainerType
const { EXTRA_SMALL } = DashboardWidgetRowSizes

type VueWithEditMode = VueConstructor<
  InstanceType<typeof BaseWidget> & EditModeProvider
>

export default (BaseWidget as VueWithEditMode).extend({
  name: 'SingleCounterWidget',
  inject: {
    getEditMode: {
      default() {
        return () => false
      },
    },
  },
  props: {
    dimensions: { type: Object, default: null },
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: null,
    },
    hook: {
      type: Object as PropType<SingleCounterWidgetHook>,
      required: true,
    },
  },
  components: {
    WidgetInfoButton,
  },
  computed: {
    showInfoButton(): unknown {
      return (
        !this.disableClick &&
        this.hook?.widget?.dataSet?.resource &&
        this.container?.type === DASHBOARD_CELL &&
        !this.presentationMode.get()
      )
    },
    textStyle(): string {
      return this.container.size === EXTRA_SMALL
        ? 'line-height: 1em;'
        : 'line-height: 1.5em;'
    },
    color(): string | null {
      return this.hook.color
    },
    flashing(): boolean {
      return this.hook.flashing
    },
    icon(): string | null {
      return this.hook.widget.options?.icon ?? null
    },
    disableClick(): boolean {
      return this.hook.widget.options?.disableClick ?? false
    },
    isDashboardWrapper(): boolean {
      return this.container?.type === WidgetContainerType.DASHBOARD_CELL
    },
  },
  data() {
    return {
      presentationMode: usePresentationMode(this.$router),
    }
  },
  methods: {
    click(): void {
      if (this.hook.hasErrors) return

      if (this.hook.series?.length) {
        this.hook.showAggregationDetails(this.hook.series[0].rows[0])
      }
    },
    onWidgetRendered() {
      this.$emit('rendered')
    },
  },
  watch: {
    'hook.counterValue'() {
      if (this.hook.isDataFetched) {
        this.onWidgetRendered()
      }
    },
  },
})
</script>

<style scoped lang="scss">
.counter-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.counter-container:hover .info-button {
  display: block;
}
.info-button {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.counter--background {
  margin-bottom: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.counter--card {
  overflow: hidden;
  position: relative !important;
  width: 100%;
  height: 100%;

  &.pointer {
    cursor: pointer;
  }
}
.counter--icon--watermarked {
  left: -5%;
  opacity: 0.1;
  position: absolute !important;
  top: -10%;
}
.no-click {
  pointer-events: none;
}
.counter--flashing .counter--background {
  animation: flashing 1s linear infinite;
}
@keyframes flashing {
  50% {
    opacity: 50%;
  }
}
</style>
