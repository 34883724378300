// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchTaskLogsTile from './DispatchTaskLogsTile.vue'
import DispatchTaskLogsColumns from './DispatchTaskLogsColumns'
import DispatchTaskLogsDetail from './DispatchTaskLogsDetail.vue'
import DispatchTaskLogsReference from './DispatchTaskLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchTaskLogsTile', DispatchTaskLogsTile)
    Vue.component('DispatchTaskLogsDetail', DispatchTaskLogsDetail)
    Vue.component('DispatchTaskLogsReference', DispatchTaskLogsReference)

    modularManager.addResourcePreset(
      Resources.DISPATCH_TASK_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchTaskLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASK_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchTaskLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASK_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchTaskLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASK_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchTaskLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
