import {codemirror} from 'vue-codemirror'
import CodeMirror, {EditorConfiguration} from 'codemirror'
import {setEditorKeyword} from '../editor/x-type-tql'
import Vue from 'vue'
import {TQLValidator, TQLValidatorInput,} from '@/tt-tql-inputs/src/lang/validator'
import resourceMetaManager from '@/tt-widget-factory/services/resource-meta'
import {lang} from '@/tt-tql-inputs/src/lang/types'
import {cloneDeep, DebouncedFunc, isEqual} from 'lodash'
import debounce from 'lodash/debounce'
import {isEmpty} from '@/helpers/isEmpty'

export default Vue.extend({
  components: {
    codemirror,
  },
  props: {
    value: String,
    options: Object
  },
  data() {
    return {
      cm: null,
      model: null,
      valid: false,
      validator: null as TQLValidator | null,
      parserErrors: null,
      parsed: null,
      code: null,
      typing: false,
    }
  },
  computed: {
    cmOptions(): EditorConfiguration {
      return {
        tabSize: 4,
        mode: 'text/x-sql',
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
          'Ctrl-R': 'run',
          'Ctrl-S': 'save',
        },
        theme: this.options?.theme ?? 'material',
        lineNumbers: this.options?.lineNumbers ?? true,
        ...this.options || {}
      }
    },
    canClear(): boolean {
      return true
    },
    canApply(): boolean {
      return !this.isEmpty && this.isDirty && this.valid
    },
    errorsAsText(): string | undefined {
      if (!this.code) {
        return
      }
      if (!this.parserErrors) {
        return
      }
      this.isValidTQL()
      return this.parserErrors.join(',')
    },
    isDirty(): boolean {
      return !isEqual(this.code, this.model)
    },
    isEmpty(): boolean {
      return isEmpty((this.code ?? '').trim())
    },
    isValidTQL(): any {
      return debounce(() => {
        this.$emit('valid', this.parserErrors.filter(Boolean).length === 0)
      }, 600)
    },
    _validateStatement(): DebouncedFunc<() => void> {
      return debounce((value) => {
        this.validateStatement()
      }, 600)
    },
  },
  watch: {
    resourceAsTableName() {
      this.setup()
    },
    code() {
      this.typing = true
      this._validateStatement()
    },
  },
  created() {
    this.validator = new TQLValidator(
      resourceMetaManager,
      lang
    )
    this.$nextTick(() => {
      this.code = cloneDeep(this.value ?? null)
      this.model = cloneDeep(this.code)
      this.setup()
    })
  },
  methods: {
    formatQuery() {
      return this.code
    },
    clear() {
      this.code = ''
      this.$nextTick(() => {
        this.emit()
      })
    },
    emit() {
      // No code we clear
      if (this.isEmpty) {
        this.$emit('input', null)
        return
      }
      // Is equal, we do not emit
      if (isEqual(this.code, this.model)) {
        return
      }
      this.model = cloneDeep(this.code)
      this.$emit('input', cloneDeep(this.code))
    },
    onReady(cm) {
      cm.setSize('100%', 'auto')
    },
    setup() {
      /**
       * Commands are defined by adding properties to the CodeMirror.commands object.
       */
      // @ts-ignore -- not defined in type but is correct
      CodeMirror.commands.run = () => {
        this.$emit('run', this.code)
      }
      // @ts-ignore -- not defined in type but is correct
      CodeMirror.commands.save = () => {
        this.$emit('run', this.code)
      }
    },
    /**
     * Parse Statement
     */
    validateStatement() {
      console.log('validateStatement', this.code)
      const input = {
        tqlStatement: this.formatQuery(),
        output: {
          valid: false,
          errors: [],
        },
      } as TQLValidatorInput
      this.validator.validate(input)
      this.typing = false
      this.valid = input.output.valid
      this.parserErrors = input.output.errors
      setEditorKeyword(input.output.resourceName, input.output.attributeNames)
    },
  },
})
