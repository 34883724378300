// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionSkillsTile from './PositionSkillsTile.vue'
import PositionSkillsColumns from './PositionSkillsColumns'
import PositionSkillsDetail from './PositionSkillsDetail.vue'
import PositionSkillsReference from './PositionSkillsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionSkillsTile', PositionSkillsTile)
    Vue.component('PositionSkillsDetail', PositionSkillsDetail)
    Vue.component('PositionSkillsReference', PositionSkillsReference)

    modularManager.addResourcePreset(
      Resources.POSITION_SKILLS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionSkillsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_SKILLS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionSkillsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_SKILLS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionSkillsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_SKILLS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionSkillsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
