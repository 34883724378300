// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RoleAccessPoliciesTile from './RoleAccessPoliciesTile.vue'
import RoleAccessPoliciesColumns from './RoleAccessPoliciesColumns'
import RoleAccessPoliciesDetail from './RoleAccessPoliciesDetail.vue'
import RoleAccessPoliciesReference from './RoleAccessPoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('RoleAccessPoliciesTile', RoleAccessPoliciesTile)
    Vue.component('RoleAccessPoliciesDetail', RoleAccessPoliciesDetail)
    Vue.component('RoleAccessPoliciesReference', RoleAccessPoliciesReference)

    modularManager.addResourcePreset(
      Resources.ROLE_ACCESS_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'RoleAccessPoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_ACCESS_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: RoleAccessPoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_ACCESS_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RoleAccessPoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_ACCESS_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RoleAccessPoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
