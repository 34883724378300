// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import Oauth2RefreshTokensTile from './Oauth2RefreshTokensTile.vue'
import Oauth2RefreshTokensColumns from './Oauth2RefreshTokensColumns'
import Oauth2RefreshTokensDetail from './Oauth2RefreshTokensDetail.vue'
import Oauth2RefreshTokensReference from './Oauth2RefreshTokensReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('Oauth2RefreshTokensTile', Oauth2RefreshTokensTile)
    Vue.component('Oauth2RefreshTokensDetail', Oauth2RefreshTokensDetail)
    Vue.component('Oauth2RefreshTokensReference', Oauth2RefreshTokensReference)

    modularManager.addResourcePreset(
      Resources.OAUTH2_REFRESH_TOKENS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'Oauth2RefreshTokensTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_REFRESH_TOKENS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: Oauth2RefreshTokensColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_REFRESH_TOKENS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'Oauth2RefreshTokensDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_REFRESH_TOKENS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'Oauth2RefreshTokensReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
