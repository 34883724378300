<template>
  <div>
    <ReportFields
      v-if="form"
      v-model="formModel"
      :form="form"
      :saving="saving"
      @submit="submitForm"
    />
    <div v-else>
      <TLoadingWave />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { EntityRequestOptions } from 'tracktik-sdk/lib/common/entity'

import { DefinitionOption } from '@tracktik/tt-json-schema-form'
import { getReportDateTimeNullableDefinitionOption } from '@/tt-widget-views/helpers'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'

import ReportFields from './ReportFields.vue'
import { getReportEditFormBuilder } from '../helpers'

import { ReportExtensionResponse, ReportFormData } from '../types'

export default Vue.extend({
  name: 'ReportEditForm',
  components: {
    ReportFields,
  },
  props: {
    reportId: { type: Number, required: true },
    hasToOverwriteDateTimeDefinition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: null as ReportFormData,
      formBuilder: null,
      formModel: null as Record<string, unknown>,
      saving: false,
    }
  },
  computed: {
    resourceName(): string {
      return Resources.REPORTS
    },
  },
  created() {
    this.fetchReportValues(this.reportId)
  },
  methods: {
    fetchReportValues(reportId: number): void {
      const requestOptions: EntityRequestOptions = {
        extension: ['jsonFormSchema'],
      }
      this.$appContext.authModule
        .getApi()
        .get<number, ReportExtensionResponse>(
          this.resourceName,
          reportId,
          requestOptions,
        )
        .then((extensionResponse) => {
          this.setForm(extensionResponse)
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    handleError(err): void {
      this.$crash.captureException(err)
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t('common.error_message'),
      })
    },
    handleSuccess(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t('common.operation_successful'),
      })
      this.$emit('submit:success')
    },
    overwriteDateTimeDefinition(
      formData: ReportFormData,
      definitions: Record<string, DefinitionOption>,
    ): ReportFormData {
      return {
        ...formData,
        formOptions: {
          ...formData.formOptions,
          definitions: {
            ...formData.formOptions.definitions,
            ...definitions,
          },
        },
      }
    },
    setForm(extensionResponse: ReportExtensionResponse) {
      const definition = {
        DateTimeNullable: getReportDateTimeNullableDefinitionOption(
          extensionResponse.jsonFormSchema.formOptions.definitions,
          { timeZone: extensionResponse.timeZone },
        ),
      }
      this.form = this.hasToOverwriteDateTimeDefinition
        ? this.overwriteDateTimeDefinition(
            extensionResponse.jsonFormSchema,
            definition,
          )
        : extensionResponse.jsonFormSchema
      this.formModel = this.form.values
      this.formBuilder = getReportEditFormBuilder(
        this.reportId,
        this.form,
        this.$appContext,
      )
    },
    submitForm(): void {
      this.saving = true

      this.formBuilder
        ?.getState()
        .submit(this.formModel)
        .then(this.handleSuccess)
        .catch(this.handleError)
        .finally(() => (this.saving = false))
    },
  },
})
</script>
