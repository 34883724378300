import {
  DocumentInput,
  DocumentProviderInterface,
  DocumentProviders,
  DocumentServiceMethods,
} from '@/tt-entity-documents/types'

export interface ExternalUrlProviderDocumentInput extends DocumentInput {
  provider: DocumentProviders.EXTERNAL
  data: {
    url: string
  }
}

export class ExternalURLDocumentProvider implements DocumentProviderInterface {
  async getDocumentOptionsSchema(): Promise<null> {
    return null
  }

  getMethod(): DocumentServiceMethods {
    return DocumentServiceMethods.GET
  }

  async getDocumentUrl(
    input: ExternalUrlProviderDocumentInput,
  ): Promise<string> {
    return input.data.url
  }

  getProviderTag(): DocumentProviders {
    return DocumentProviders.EXTERNAL
  }
}
