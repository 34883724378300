<template>
  <div>
    <json-field name="language" as="LanguageSelectorField" />
    <json-field name="name" />
    <json-field name="description" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetCategoriesAddTranslationForm',
})
</script>
