<template>
  <div class="pt-3">
    <v-textarea
      :value="rawValue"
      :label="computedLabel"
      outlined
      dense
      hide-details
      no-resize
      disabled
      readonly
    />
    <TLoadingBar v-if="isLoading" class="loader" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'TextAreaAttr',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    label: { type: String, default: '' },
    name: { type: String, required: true },
    prefix: { type: String, default: '' },
    noValuePlaceholder: { type: String, default: '' },
  },
  computed: {
    resource(): string | null {
      return this.getItemHook().getResourceName()
    },

    computedLabel(): string | null {
      if (this.label !== '') {
        return this.$t(this.label)
      }

      const newName = this.relationPrefix
        ? `${this.relationPrefix}.${this.name}`
        : this.name

      const label = ResourceTranslator.translateAttribute(
        this.resource,
        newName,
        FormLabelTypes.LABEL,
      )

      const prefix = this.prefix && this.$t(this.prefix)

      return `${prefix}${label}`
    },

    isLoading(): boolean {
      return this.getItemHook().loading
    },

    rawValue(): string {
      const rawValue = this.getItemHook().getRawValue(this.name) as string

      return rawValue || (!this.isLoading && this.noValuePlaceholder) || '...'
    },
  },
  created() {
    this.getItemHook().addAttribute(this.name)
  },
})
</script>

<style scoped>
.loader {
  position: relative;
  height: 1px;
}

.v-textarea >>> textarea {
  font-size: smaller;
}
</style>
