<template>
  <app-window v-if="columnProvider" ref="window">
    <div class="flex--row">
      <!-- Available fields column -->
      <div class="flex--colum w-50">
        <TSearchInput
          v-model="search"
          :debounce-time="100"
          class="w-100 pr-3 pb-3"
        />

        <v-subheader class="editor--column-title">
          <span class="pr-4" v-text="$t('widgets.data_table.fields')" />
        </v-subheader>

        <div class="column-options-section">
          <VerticalStackCard v-slot="{ height }">
            <v-virtual-scroll
              v-slot="{ item, index }"
              :items="columnOptions"
              :height="height"
              item-height="30"
            >
              <draggable
                :group="columnGroup"
                :value="columnOptions"
                :sort="false"
                :put="false"
                :clone="cloneDog"
              >
                <div
                  :key="`${index}-${getLabel(item)}`"
                  @dragstart="startDrag(index)"
                  @dragend="stopDrag(index)"
                >
                  <v-tooltip nudge-right="5" left>
                    <template #activator="{ on }">
                      <div class="column-item d-flex level0" v-on="on">
                        <v-icon small v-text="'mdi-drag'" />
                        <span class="text-truncate" v-text="getLabel(item)" />
                      </div>
                    </template>

                    <span class="text-truncate" v-text="getLabel(item)" />
                  </v-tooltip>
                </div>
              </draggable>
            </v-virtual-scroll>
          </VerticalStackCard>
        </div>
      </div>

      <!-- Selected fields column -->
      <div class="flex--column w-50">
        <v-subheader class="editor--column-title">
          <span class="pr-4" v-text="$t('widgets.data_table.selected')" />
          <v-btn
            text
            x-small
            @click="loadDefault"
            v-text="$t('widgets.data_table.load_defaults')"
          />
        </v-subheader>

        <div class="column-options-section">
          <span
            v-if="!selectedColumns.length"
            style="font-size: 0.6rem"
            class="text-center"
            v-text="$t('widgets.data_table.use_default_columns')"
          />

          <draggable
            class="dropping-area"
            group="column"
            ghost-class="ghost-drag"
            :value="selectedColumns"
            @add="addToSelected"
          >
            <div v-for="(column, i) in selectedColumns" :key="i">
              <v-tooltip :key="i" nudge-right="5" left>
                <template #activator="{ on }">
                  <div
                    :key="`attr-${i}`"
                    class="column-item d-flex level0"
                    v-on="on"
                  >
                    <v-icon small v-text="'mdi-sort'" />
                    <span class="text-truncate" v-text="getLabel(column)" />
                    <v-icon
                      class="ml-auto"
                      small
                      @click="remove(i)"
                      v-text="'close'"
                    />
                  </div>
                </template>

                <span class="text-truncate" v-text="getLabel(column)" />
              </v-tooltip>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </app-window>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'

import { isEmpty } from '@/helpers/isEmpty'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'

import ResourceColumnProvider from './ResourceColumnProvider'
import { ColumnInputDefinition } from './DataTableWidgetHook'

export default Vue.extend({
  name: 'DataTableColumnsEditor',
  components: {
    draggable,
    TSearchInput,
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
    value: {
      type: Array as PropType<ColumnInputDefinition[]>,
      default: () => [],
    },
  },
  data() {
    return {
      search: null,
      selectedColumns: [] as ColumnInputDefinition[],
      columnGroup: {
        name: 'column',
        put: false,
        pull: 'clone',
      },
      currentDragIndex: null as null | number,
    }
  },
  computed: {
    columnOptions(): ColumnInputDefinition[] {
      return this.columnProvider
        .getFilteredBySelected(this.selectedColumns)
        .filter((item: ColumnInputDefinition) => {
          if (isEmpty(this.search)) {
            return true
          }
          return this.columnProvider
            ?.getColumnName(item)
            .toLowerCase()
            .includes(this.search.toLowerCase())
        })
    },
    defaultColumns(): ColumnInputDefinition[] {
      return cloneDeep(this.columnProvider.defaultColumns)
    },
    columnProvider(): ResourceColumnProvider {
      return new ResourceColumnProvider(this.resource, this.$appContext)
    },
  },
  watch: {
    resource() {
      this.initSelectedColumns()
    },
    selectedColumns: {
      handler(val = []) {
        this.emitUpdate([...val])
      },
      deep: true,
    },
  },
  mounted() {
    this.initSelectedColumns()
  },
  methods: {
    startDrag(index) {
      this.currentDragIndex = index
    },
    stopDrag(index) {
      this.currentDragIndex = null
    },
    addToSelected(draggableEvent) {
      if (this.currentDragIndex === null) {
        console.warn('No selected index. Cannot add to selected columns.')
      }

      const oldIndex = this.currentDragIndex
      const column = this.columnOptions[oldIndex]

      const newIndex = draggableEvent.newIndex

      const startPart = this.selectedColumns.slice(0, newIndex)
      const endPart = this.selectedColumns.slice(newIndex)

      this.selectedColumns = [...startPart, column, ...endPart]
    },
    initSelectedColumns() {
      if (this.resource) {
        if (this.value?.length > 0) {
          this.selectedColumns = [...this.value]
        } else {
          this.loadDefault()
        }
      }
    },
    getLabel(column: ColumnInputDefinition): string {
      return this.columnProvider.getColumnName(column)
    },
    loadDefault() {
      this.selectedColumns = cloneDeep(this.defaultColumns)
    },
    remove(i) {
      this.selectedColumns.splice(i, 1)
    },
    cloneDog(item) {
      return item
    },
    emitUpdate(columnItems: ColumnInputDefinition[] = []) {
      if (JSON.stringify(columnItems) === JSON.stringify(this.defaultColumns)) {
        this.$emit('input', [])
      } else {
        this.$emit('input', columnItems)
      }
    },
  },
})
</script>

<style scoped>
.column-item.ghost-drag {
  border: 1px dashed #000;
}

.column-item {
  border: 1px solid #ddd;
  margin-bottom: 2px;
  padding: 5px 13px 5px 4px;
  font-size: 11px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 2px;
  border-top-color: #e9e9e9;
}

.column-item div {
  margin-left: 4px;
}

.column-options-section {
  display: flex;
  flex-direction: column;
  height: 350px;
  padding: 4px;
  overflow: scroll;
}

.dropping-area {
  flex-grow: 1;
}

.editor--column-title {
  height: 30px;
  margin-top: 15px;
  margin: 0;
}
</style>
