<template>
  <div class="steps-breadcrumbs">
    <template v-for="(item, index) in items">
      <span
        v-if="index > 0"
        :key="`divider-${index}`"
        class="steps-breadcrumbs--divider"
      >
        <slot name="divider">
          <v-icon size="18" v-text="'keyboard_arrow_right'" />
        </slot>
      </span>

      <slot name="item" v-bind="{ index, isLast: isLast(index), item }">
        <span :key="`content-${JSON.stringify(item)}`">
          <v-skeleton-loader
            v-if="resolve(item.loading, false)"
            class="d-inline-block"
            type="table-cell"
          />
          <v-btn
            v-else-if="item.onClick"
            class="item text-none"
            text
            @click="item.onClick()"
          >
            <slot
              name="item-text"
              v-bind="{ index, isLast: isLast(index), item }"
            >
              <span :class="{ active: isLast(index) }" v-text="$t(item.text)" />
            </slot>
          </v-btn>
          <span v-else class="item">
            <slot
              name="item-text"
              v-bind="{ index, isLast: isLast(index), item }"
            >
              <span :class="{ active: isLast(index) }" v-text="$t(item.text)" />
            </slot>
          </span>
        </span>
      </slot>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { resolve } from '../helpers'
import { StepsBreadcrumbsItem } from '../types'

/**
 * Navigational component for items that trigger callbacks.
 * For a breadcrumbs of links, see Vuetify's v-breadcrumbs.
 */
export default Vue.extend({
  name: 'StepsBreadcrumbs',
  props: {
    /**
     * Items list options
     */
    items: {
      type: Array as PropType<StepsBreadcrumbsItem[]>,
      required: true,
    },
  },
  methods: {
    isLast(index: number): boolean {
      return index === this.items.length - 1
    },
    resolve,
  },
})
</script>

<style scoped>
.item {
  color: var(--v-level1Control-base);
  font-size: 14px;
  font-weight: 400 !important;
  height: 24px !important;
  padding: 0 10px !important;
}

.item >>> .active {
  color: var(--v-secondary-base);
  font-weight: bold !important;
}

.steps-breadcrumbs >>> .v-skeleton-loader__table-cell {
  height: 25px;
}
</style>
