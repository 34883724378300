// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetFilesTile from './AssetFilesTile.vue'
import AssetFilesColumns from './AssetFilesColumns'
import AssetFilesDetail from './AssetFilesDetail.vue'
import AssetFilesMetaCard from './AssetFilesMetaCard.vue'
import AssetFilesReference from './AssetFilesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetFilesTile', AssetFilesTile)
    Vue.component('AssetFilesDetail', AssetFilesDetail)
    Vue.component('AssetFilesMetaCard', AssetFilesMetaCard)
    Vue.component('AssetFilesReference', AssetFilesReference)

    modularManager.addResourcePreset(
      Resources.ASSET_FILES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetFilesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FILES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetFilesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FILES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetFilesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FILES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetFilesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FILES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetFilesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
