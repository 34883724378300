import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { LeaveRequestStatusColors } from '../../types'

export const LeaveRequestsCompletedColumns: ColumnInputDefinition[] = [
  { attributeName: 'employee' },
  { attributeName: 'leaveType.name' },
  {
    attributeName: 'startedOn',
    format: 'asDate',
    component: {
      is: 'LeaveRequestsDateTimeIconColumn',
      props: {
        dateName: 'startedOn',
        tooltipText:
          'tt-entity-design.leave-management.leave-requests.start-time',
      },
    },
  },
  { attributeName: 'endedOn', format: 'asDate' },
  {
    attributeName: 'requestedDays',
    component: {
      is: 'LeaveRequestsRequestedDays',
    },
  },
  {
    headerText: 'res.leave-requests.extensions.affectedShifts.labels.label',
    component: {
      is: 'LeaveRequestsAffectedShiftsCounter',
    },
  },
  {
    headerText: 'res.leave-requests.attr.status.label',
    component: {
      is: 'StatusChip',
      props: { statusColorOptions: LeaveRequestStatusColors },
    },
  },
]
