import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    headerText: 'tt-entity-design.asset-hub.custom-fields.custom-field-name',
    attributeName: 'name',
  },
  'description',
  {
    headerText: 'tt-entity-design.asset-hub.custom-fields.entity',
    attributeName: 'entity',
  },
  {
    headerText: 'tt-entity-design.asset-hub.custom-fields.checkout',
    attributeName: 'checkOut',
  },
  {
    headerText: 'tt-entity-design.asset-hub.custom-fields.checkin',
    attributeName: 'checkIn',
  },
  'status',
] as ColumnInputDefinition[]
