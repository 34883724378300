// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ZonesTile from './ZonesTile.vue'
import ZonesColumns from './ZonesColumns'
import ZonesDetail from './ZonesDetail.vue'
import ZonesMetaCard from './ZonesMetaCard.vue'
import ZonesReference from './ZonesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ZonesTile', ZonesTile)
    Vue.component('ZonesDetail', ZonesDetail)
    Vue.component('ZonesMetaCard', ZonesMetaCard)
    Vue.component('ZonesReference', ZonesReference)

    modularManager.addResourcePreset(Resources.ZONES, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'ZonesTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ZONES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ZonesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ZONES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ZonesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ZONES, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'ZonesMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ZONES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ZonesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
