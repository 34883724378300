// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftsTile from './ShiftsTile.vue'
import ShiftsColumns from './ShiftsColumns'
import ShiftsDetail from './ShiftsDetail.vue'
import ShiftsMetaCard from './ShiftsMetaCard.vue'
import ShiftsReference from './ShiftsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftsTile', ShiftsTile)
    Vue.component('ShiftsDetail', ShiftsDetail)
    Vue.component('ShiftsMetaCard', ShiftsMetaCard)
    Vue.component('ShiftsReference', ShiftsReference)

    modularManager.addResourcePreset(Resources.SHIFTS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'ShiftsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.SHIFTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ShiftsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.SHIFTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.SHIFTS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'ShiftsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.SHIFTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
