// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MessageBoardPostsTile from './MessageBoardPostsTile.vue'
import MessageBoardPostsColumns from './MessageBoardPostsColumns'
import MessageBoardPostsDetail from './MessageBoardPostsDetail.vue'
import MessageBoardPostsMetaCard from './MessageBoardPostsMetaCard.vue'
import MessageBoardPostsReference from './MessageBoardPostsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MessageBoardPostsTile', MessageBoardPostsTile)
    Vue.component('MessageBoardPostsDetail', MessageBoardPostsDetail)
    Vue.component('MessageBoardPostsMetaCard', MessageBoardPostsMetaCard)
    Vue.component('MessageBoardPostsReference', MessageBoardPostsReference)

    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POSTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POSTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MessageBoardPostsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POSTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POSTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POSTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
