import Vue from 'vue'

import { AggregationQuery, CustomFilter } from '@/tt-widget-factory/definitions'
import {
  ContextManagerInterface,
  ResourceDataManagerInterface,
  ResourceFiltersMap,
  ResourceMetaManagerInterface,
} from '@/tt-widget-factory/types'
import { Resource } from '@/tt-widget-factory/services/resource-meta/types'

import { AttributeFilter } from '../schemas-types'
import { attributeFilterToFilter } from '../helpers/attributeFilterToFilter'
import { CollectionQuery, Filter, FiltersMap } from '../types'
import { TQLQuery } from '../widgets/TQLQuery/schema'
import { ContextAttributeMap } from './contextAttributeMap'
import { isNullOperatorType } from '@/tt-entity-filter/util'
import { isTemporalFieldType } from '@/tt-entity-filter/temporal-filters/field-types-validator'
import { convertTemporalFilterToQueryFilter } from '@/tt-entity-filter/temporal-filters/convertTemporalFilterToQueryFilter'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import uniqBy from 'lodash/uniqBy'

/**
 * A Manager to handle queries
 * - Aggregation Queries
 * - TQL Queries
 * - Collection Queries
 *
 * Manages
 * - Context filters
 * - Custom filters
 * - Scopes
 *
 */

export type QueryTypes = CollectionQuery | AggregationQuery | TQLQuery

export interface BaseQueryManagerServices {
  resourceDataManager: ResourceDataManagerInterface
  resourceMetaManager: ResourceMetaManagerInterface
}

export type BaseQueryManagerOptions = {
  allowIncludeInactive?: boolean
  allowWhereQL?: boolean
  contextAttributeMap?: ContextAttributeMap
  defaultFilterValues?: AttributeFilter[]
  services: BaseQueryManagerServices
  customFilterValues?: CustomFilter[]
}

export type BaseQueryManagerState<T extends QueryTypes> = {
  customFilters: FiltersMap // @todo: review naming, customFilters here also means Attribute Filters
  initialQuery: T
  scope?: string[]
  search?: string
  whereQL?: string
}

/**
 * @TODO: create an interface for the three query types (Collection, Aggregation, TQL)
 *
 * https://tracktik.atlassian.net/browse/FE-1342
 */
export default abstract class BaseQueryManager<
  QueryType extends QueryTypes = QueryTypes,
> {
  /**
   * `allowIncludeInactive` and `allowWhereQL` used to be handle by the late
   * EntityFilterManager
   * @see https://github.com/TrackTik/tt-platform/pull/2945
   *
   * Keeping them here for clarity until we are 100% sure we don't need them
   * anymore. If so, we can also remove these options from the widget models'
   * schemas
   */
  allowIncludeInactive: boolean
  allowWhereQL: boolean

  contextAttributeMap: ContextAttributeMap
  includeInactive?: boolean
  isRunning = false
  originalFilters: Filter[] = []
  resetCallback: () => void | null
  resourceModel: Resource
  services: BaseQueryManagerServices
  state: BaseQueryManagerState<QueryType>

  protected contextManager: ContextManagerInterface

  protected defaultFilterValues: AttributeFilter[]

  protected customFilterValues: CustomFilter[]

  constructor(
    query: QueryType,
    contextManager: ContextManagerInterface,
    options: BaseQueryManagerOptions,
  ) {
    this.contextManager = contextManager
    this.contextAttributeMap = options.contextAttributeMap
    this.services = options.services
    this.allowIncludeInactive = options.allowIncludeInactive ?? true
    this.allowWhereQL = options.allowWhereQL ?? true
    this.originalFilters = [...(query.filters ?? [])]
    this.defaultFilterValues = [...(options.defaultFilterValues || [])]
    this.customFilterValues = [...(options.customFilterValues || [])]

    this.state = Vue.observable({
      customFilters: {},
      initialQuery: null,
      scope: null,
      search: null,
    })

    this.updateQuery(query)

    this.applyDefaultFilterValues(
      options.defaultFilterValues ?? [],
      options.customFilterValues ?? [],
    )
  }

  get customFilters(): FiltersMap {
    return this.state.customFilters
  }

  get filters(): Filter[] {
    // When uniqBy finds duplicates, it keeps the first entry it finds, so filters that take precedence should come first
    return uniqBy(
      [
        ...Object.values(this.customFilters),
        ...this.getContextResourceFilters(),
        ...this.getContextFilters(),
        ...this.originalFilters,
      ],
      'attribute',
    ).map((filter) => this.parseTemporalFilter(filter))
  }

  get initialQuery(): QueryType {
    return this.state.initialQuery
  }

  get modelContext(): ContextAttributeMap {
    return {
      ...this.resourceModel.modelContext,
      ...(this.contextAttributeMap ?? {}),
    }
  }

  get scope(): string[] | undefined {
    return this.state.scope
  }

  get search(): string | undefined {
    return this.state.search
  }

  get whereQL(): string | undefined {
    return this.state.whereQL
  }

  applyDefaultFilterValues(
    attrFilters: AttributeFilter[],
    customFilters?: CustomFilter[],
  ): void {
    const hasFilter = (attribute: string) =>
      this.filters.some((filter) => filter.attribute === attribute)

    const filtersWithDefaultValue = attrFilters
      .filter((f) => f.attributeName && f.defaultValue)
      .map(attributeFilterToFilter)

    const customFiltersWithDefaultValue: Filter[] = customFilters
      ?.filter((f) => f.filterName && f.value)
      .map((f) => ({
        attribute: f.filterName,
        operator: '',
        value: f.value,
      }))

    const allFilters = [
      ...filtersWithDefaultValue,
      ...customFiltersWithDefaultValue,
    ]

    allFilters.forEach((filter) => {
      if (!hasFilter(filter.attribute)) {
        this.setCustomFilter(filter)
      }
    })
  }

  updateQuery(query: QueryType): void {
    this.state.initialQuery = { ...query }
    this.state.scope = query.scope
    this.state.search = query.search
    this.state.whereQL = query.whereQL

    this.includeInactive = query.includeInactive
    this.resourceModel = this.services.resourceMetaManager.getResource(
      query.resource,
    )
  }

  resetAllFilters(): void {
    this.setCustomFilters([])
    this.setScope(this.initialQuery.scope ?? [])
    this.removeSearch()
    this.applyDefaultFilterValues(
      this.defaultFilterValues,
      this.customFilterValues,
    )
    if (this.resetCallback) {
      this.resetCallback()
    }
  }

  /**
   * Set a function to call when resetting the filters, so that the widget hook
   * handles additional logic when resetting
   * @param callback The callback to call when resetting the filters
   */
  setResetCallback(callback: () => void) {
    this.resetCallback = callback
  }

  setWhereQL(whereQL?: string): void {
    this.state.whereQL = whereQL
  }

  removeCustomFilter(attributeName: string): void {
    if (this.customFilters.hasOwnProperty(attributeName)) {
      const { [attributeName]: _, ...filters } = this.customFilters
      this.state.customFilters = filters
    }
  }

  setCustomFilter(newFilter: Filter): void {
    this.state.customFilters = {
      ...this.customFilters,
      [newFilter.attribute]: newFilter,
    }
  }

  setCustomFilters(filters: Filter[] = []): void {
    const entries = filters.map((filter) => [filter.attribute, filter])
    this.state.customFilters = Object.fromEntries(entries)
  }

  setScope(value: string[]): void {
    this.state.scope = value ? [...value] : []
  }

  addScope(value: string | string[]): void {
    const scope = this.state.scope ?? []
    const scopeToAdd = Array.isArray(value) ? value : [value]
    const uniqueScopes = new Set([...scopeToAdd, ...scope])
    this.state.scope = [...uniqueScopes]
  }

  removeScope(value: string | string[]): void {
    const scopes = Array.isArray(value) ? value : [value]
    this.state.scope = this.state.scope.filter(
      (scope) => !scopes.includes(scope),
    )
  }
  removeSearch(): void {
    this.setSearch(this.initialQuery.search ?? '')
  }

  setSearch(value: string): void {
    this.state.search = value
  }

  getContextResourceFilters(): Filter[] {
    if (!this.contextManager.context.filterLayers) {
      return []
    }
    let filters = []
    Object.values(this.contextManager.context.filterLayers)
      .filter((filterMap: ResourceFiltersMap) => {
        return filterMap[this.state.initialQuery.resource] ?? false
      })
      .forEach((filterMap: ResourceFiltersMap) => {
        filters = [...filters, ...filterMap[this.state.initialQuery.resource]]
      })

    return filters
  }

  getContextFilters(): Filter[] {
    const contextAttributeMap = this.modelContext

    // There is a value, or it has a "NULL" operator
    const isNotEmpty = ({ value, operator }: Filter) =>
      !!value || isNullOperatorType(operator)

    // Validate the attribute exists and that is is not FALSE
    const isValidResourceFilter = ({ attribute }: Filter) => {
      const contextAttribute = contextAttributeMap[attribute]

      // When the value is not provided it means it is disabled
      if (!contextAttribute) {
        return false
      }

      // Fetch the attribute from the object. Since it can be a relationship,
      // we need to use resourceMetaManager
      return !!this.services.resourceMetaManager.getAttribute(
        this.state.initialQuery.resource,
        contextAttribute,
      )
    }

    // Convert the context filter into the real attribute
    const createContextFilter = (filter: Filter): Filter => {
      const attribute = contextAttributeMap[filter.attribute]

      return {
        ...filter,
        attribute,
      }
    }

    const allContextFilters = this.contextManager.getContextFilters()

    return allContextFilters
      .filter(isNotEmpty)
      .filter(isValidResourceFilter)
      .map(createContextFilter)
  }

  /**
   * Converts the Temporal Filters before sending them to the API.
   */
  private parseTemporalFilter(filter: Filter): Filter {
    const type = this.services.resourceMetaManager.getAttribute(
      this.initialQuery.resource,
      filter.attribute,
    )?.type

    const needsToParse =
      isTemporalFieldType(type) &&
      filter.value &&
      !isNullOperatorType(filter.operator)

    return needsToParse
      ? convertTemporalFilterToQueryFilter(filter, type)
      : filter
  }
}
