<template>
  <v-tooltip top :disabled="disableTooltip">
    <template #activator="{ on }">
      <div class="h-100 w-100 text-truncate" v-on="on" @click="$emit('click')">
        <slot>
          <span :class="className" v-text="text" />
        </slot>
      </div>
    </template>

    <span v-text="tooltipLabel" />
  </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'TTooltipOnOverflow',
  props: {
    text: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: [String, null],
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    tooltipLabel(): string {
      return this.tooltipText || this.$t(this.text)
    },
  },
})
</script>

<style scoped>
.tooltip--container {
  display: flex;
  width: 100%;
}
</style>
