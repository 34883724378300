<template>
  <v-btn small icon v-bind="$attrs" @click.stop="view">
    <v-icon :color="color" v-text="icon" />
  </v-btn>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  DocumentPresetInterface,
  modularManager,
  PresetItem,
  PresetTypes,
} from '@/tt-app-modular'
import { ItemHookProvider } from '../types'
import { EntityItemHook } from '../EntityItemHook'
import { getResourceDocument } from '../helper'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityDocumentButton',
  inject: ['getItemHook'],
  props: {
    icon: {
      type: String,
      default: 'mdi-file-pdf',
    },
    color: {
      type: String,
      default: 'red',
    },
    // we only receive the `name` property from the API
    // so we grab the function `toDocument()` from the registered document preset for the resource
    document: {
      type: Object as PropType<Pick<DocumentPresetInterface, 'name'>>,
      required: true,
    },
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resource(): string {
      return this.getItemHook().getResourceName()
    },
  },
  methods: {
    async view() {
      const documentPreset = getResourceDocument(
        this.resource,
        this.document.name,
      )

      if (!documentPreset) {
        console.error(
          `No document preset registered for resource "${this.resource}" and document name "${this.document.name}"`,
        )
        return
      }

      const documentInput = await documentPreset.toDocument(
        this.$appContext,
        this.getItemHook().entityReference,
        i18n.locale,
      )

      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        documentInput,
      )
    },
  },
})
</script>
