import { WidgetSchema } from '@/tt-widget-factory'

const schema: WidgetSchema = {
  type: 'object',
  required: ['url', 'is', 'title'],
  properties: {
    is: {
      enum: ['YoutubeWidget'],
    },
    title: {
      title: 'Widget Title',
      description: 'Title that represents the widget',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    url: {
      title: 'The URL of the video',
      type: 'string',
      pattern: '^(http(s)?:\\/\\/)?((w){3}.)?youtu(be|.be)?(\\.com)?\\/.+',
    },
  },
}
export default schema
