// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EntityLabelAvailabilityTypesTile from './EntityLabelAvailabilityTypesTile.vue'
import EntityLabelAvailabilityTypesColumns from './EntityLabelAvailabilityTypesColumns'
import EntityLabelAvailabilityTypesDetail from './EntityLabelAvailabilityTypesDetail.vue'
import EntityLabelAvailabilityTypesReference from './EntityLabelAvailabilityTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EntityLabelAvailabilityTypesTile',
      EntityLabelAvailabilityTypesTile,
    )
    Vue.component(
      'EntityLabelAvailabilityTypesDetail',
      EntityLabelAvailabilityTypesDetail,
    )
    Vue.component(
      'EntityLabelAvailabilityTypesReference',
      EntityLabelAvailabilityTypesReference,
    )

    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_AVAILABILITY_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EntityLabelAvailabilityTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_AVAILABILITY_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EntityLabelAvailabilityTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_AVAILABILITY_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EntityLabelAvailabilityTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_AVAILABILITY_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EntityLabelAvailabilityTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
