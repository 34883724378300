import { JSONSchema7, validator } from '@tracktik/tt-json-schema-form'

import { WidgetManagerInterface, WidgetReference } from '@/tt-widget-factory'

import { getCommonSchemaDefinitions } from '../lib/common-definitions'
import { WidgetModels } from '../types'

export const isValidWidget = (
  widgetManager: WidgetManagerInterface,
  widget: WidgetModels | WidgetReference,
): boolean => {
  if (!widget.is) return false

  const widgetSchema = widgetManager.getSchemaByName(widget.is)
  if (!widgetSchema) return false

  const definitions: JSONSchema7['definitions'] = {
    ...getCommonSchemaDefinitions(),
    ...widgetSchema.definitions,
  }
  const schema: JSONSchema7 = { ...widgetSchema, definitions }
  const isWidgetValidAgainstSchema = validator.getValidator(schema)(widget)

  return !!isWidgetValidAgainstSchema
}

export const getWidgetConfigErrorMessages = (
  widgetManager: WidgetManagerInterface,
  widget: WidgetModels | WidgetReference,
): Record<string, string> => {
  if (!widget.is) return { MainConfig: 'No `widget.is`' }

  const widgetSchema = widgetManager.getSchemaByName(widget.is)
  if (!widgetSchema)
    return { MainConfig: `No widget schema defined for ${widget.is}` }

  const definitions: JSONSchema7['definitions'] = {
    ...getCommonSchemaDefinitions(),
    ...widgetSchema.definitions,
  }
  const schema: JSONSchema7 = { ...widgetSchema, definitions }

  const AjvErrors = validator.getErrors(schema, widget)

  const errors = Object.entries(AjvErrors).map(([key, errorObj]) => [
    key,
    errorObj.map((obj) => obj.message).join(' | '),
  ])

  return Object.fromEntries(errors)
}
