<template>
  <div class="fill-height">
    <div style="height: 550px">
      <TagInputViaRelationLists v-if="assetId" :entity-id="assetId" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components'
import TagInputViaRelationLists from './TagInputViaRelationLists.vue'

type VueWithinjetion = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithinjetion).extend({
  name: 'AddAssets',
  components: {
    TagInputViaRelationLists,
  },
  inject: ['formHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    assetId(): number {
      return this.itemHook.getRawValue('id')
    },
  },
})
</script>
