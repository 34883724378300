import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'

import BaseMapManager, { BaseMapOptions, MarkerModel } from './BaseMapManager'
import CollectionWidgetHook from '../../base/CollectionWidgetHook'
import HereMapManager from './HereMapManager'
import {
  CollectionQuery,
  DefaultToolbar,
  Field,
  MapWidgetModel,
} from '../../types'

export default class MapWidgetHook extends CollectionWidgetHook<MapWidgetModel> {
  mapManager: BaseMapManager

  getLatitude() {
    return this.widget?.geoMapOptions?.latitudeAttribute
  }

  /**
   * We want to keep MapWidget showing even on no result.
   * @todo: the template should be customized, not the hook's logic
   */
  get hasDataSource(): boolean {
    return !!this.entities
  }
  getLongitude() {
    return this.widget?.geoMapOptions?.longitudeAttribute
  }

  get isValid() {
    return super.isValid && !!this.getLatitude() && !!this.getLongitude()
  }

  setup() {
    // Override default tolbar
    if (!this.state?.widget?.toolbar) {
      this.state.widget.toolbar = {
        show: true,
        displayCounts: true,
        filterOptions: {
          allowSearch: true,
        },
      } as DefaultToolbar
    }
    const baseMapOptions: BaseMapOptions = {
      onClickHandler: (event: any, item: MarkerModel) => {
        event.preventDefault()
        this.services.eventManager.dispatchEvent(
          EntityIntentTypes.PREVIEW_ABSOLUTE,
          {
            position: {
              x: event.originalEvent.clientX,
              y: event.originalEvent.clientY,
            },
            entity: { id: item.id },
            entityId: item.id,
            resourceName: this.resource,
          },
        )
      },
      onClosePreview: (): void => {
        this.services.eventManager.dispatchEvent(
          EntityIntentTypes.PREVIEW_ABSOLUTE_CLOSE,
        )
      },
    }
    // Set up the manager
    this.mapManager = new HereMapManager(this.state.widget, baseMapOptions)
  }

  /**
   * Unsubscribe
   */
  destroy(): void {
    if (this.mapManager) {
      console.info('MAP DESTROYED')
      this.mapManager.destroy()
      this.services.eventManager.dispatchEvent(
        EntityIntentTypes.PREVIEW_ABSOLUTE_CLOSE,
      )
    }

    this.unsubscribe()
  }

  protected get effectiveQuery(): CollectionQuery {
    const fields = [
      {
        attribute: 'id',
      },
      {
        attribute: this.getLatitude(),
        alias: 'lat',
      },
      {
        attribute: this.getLongitude(),
        alias: 'lng',
      },
    ] as Field[]

    this.queryManager.setCustomFilter({
      attribute: this.getLatitude(),
      // The goal here is to not fetch items with a latitude and longitude equal to zero, a request made by a QA in this ticket: FE-1218
      //@ts-ignore to remove and update after this ticket will be done API-2770
      operator: 'NOTIN',
      value: ['null', 0],
    })
    this.queryManager.setCustomFilter({
      attribute: this.getLongitude(),
      //@ts-ignore
      operator: 'NOTIN',
      value: ['null', 0],
    })

    this.queryManager.setFieldsAndExtensionsFromAttributes(this.getAttributes())
    return { ...this.queryManager.query, fields, limit: 2000 }
  }
}
