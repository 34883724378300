<template>
  <div>
    {{ text }}
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { LocaleMessage } from 'vue-i18n'
import { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OpenShiftRequestsColumnSkillThreshold',
  inject: ['getItemHook'],
  computed: {
    text(): LocaleMessage {
      return this.$t(
        'tt-entity-design.open-shift-requests.attrs.skill-threshold',
        {
          present: this.getItemHook().get('extensions.skillCount.present'),
          total: this.getItemHook().get('extensions.skillCount.total'),
        },
      )
    },
  },
  created() {
    this.getItemHook().addAttribute('extensions.skillCount')
  },
})
</script>
