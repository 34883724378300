import Vue, { PropType } from 'vue'
import { isEmpty } from '@/helpers/isEmpty'
import { cloneDeep, isEqual } from 'lodash'

export const ObjectInput = Vue.extend({
  data() {
    return {
      model: null,
      lastEmit: undefined,
      inputSetting: {
        emitNullOnDefault: false,
        emitInvalidSchema: true,
        defaultModel: null,
      },
    }
  },
  computed: {
    // If the prop is empty
    isEmptyInput(): boolean {
      return isEmpty(this.value)
    },
    // Emit null when on default value
    emitNullOnDefault(): boolean {
      return this.inputSetting.emitNullOnDefault
    },
    // Default model. You can overload this method
    defaultModel(): any {
      return cloneDeep(this.inputSetting.defaultModel ?? null)
    },
  },
  watch: {
    model: {
      deep: true,
      handler(model) {
        // Make a copy of the object
        let output = cloneDeep(model)

        // The output is the same as the previous emit, do not emit
        if (isEqual(this.lastEmit, output)) {
          return
        }
        // Keep the last emit to avoid recursive events
        this.lastEmit = cloneDeep(output) ?? null

        // Option to emit null when the value === default.
        output =
          this.emitNullOnDefault && isEqual(output, this.defaultModel)
            ? null
            : output

        // Run the emit
        this.$emit('input', output)

        // Life cycle hook
        this.afterEmit(cloneDeep(output))
      },
    },
    value: {
      deep: true,
      handler(val) {
        // When the prop change, we call the set input
        this.setInput(val, false)
      },
    },
  },
  // Set the initial input
  created() {
    const value = this.onCreated(this.value)
    this.setInput(value, true)
  },
  methods: {
    onCreated(val) {
      return val
    },
    onInput(input: any) {
      // Overload this method
    },
    afterEmit(output: any) {
      // Overload this method
    },
    onInputNull() {
      // Overload this method
    },
    // Set the model
    setInput(input: any, isInitial = false) {
      // We will
      input = cloneDeep(input)

      // Empty or Null
      if (this.isEmptyInput) {
        this.onInputNull()
      }

      // The input is the same as now. We do not change it
      if (input && isEqual(input, this.model) && !isInitial) {
        return
      }
      // Call the input before we set the model
      this.onInput(input)

      // If the input is null, we set the default
      if (this.isEmptyInput) {
        input = cloneDeep(this.defaultModel)
      }

      this.setLastEmit(input, isInitial)
      this.setModel(input)
    },
    setLastEmit(input: any, isInitial = false) {
      // Since we are in the initial state, we do not emit
      if (isInitial) {
        this.lastEmit = cloneDeep({ ...input })
      }
    },
    setModel(input: any) {
      // Set the local model
      this.model = cloneDeep({ ...input })
    },
  },
  props: {
    value: {
      type: Object as PropType<any>,
    },
  },
})
