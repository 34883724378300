import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const HeatMapWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'HeatMapWidget',
  'Heat Map',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.TWO,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)

HeatMapWidgetBuilder.addDataSetPropertie({
  sort: {
    allOf: [{ $ref: '#/definitions/AggregationSort' }],
  },
})

HeatMapWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/HeatMapOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadCsvPdf',
  },
})

HeatMapWidgetBuilder.addDefinitions({
  HeatMapOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      colorPalette: {
        title: 'Color Palette',
        $ref: '#/definitions/ColorPalette',
      },
    },
  },
})

export default HeatMapWidgetBuilder.schema
