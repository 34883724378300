<template>
  <tt-attr :name="attributeName">
    <template #default="{ value }">
      <TChipLabelBoolean
        v-bind="{ truthy, falsy, modifiers }"
        :is-truthy="isTruthy"
        :default-text="value"
      />
    </template>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ChipLabelBooleanConfig, Modifier } from '@/tt-ui/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ChipLabelBoolean',
  inject: ['getItemHook'],
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    truthy: {
      type: Object as PropType<ChipLabelBooleanConfig>,
      default: undefined,
    },
    falsy: {
      type: Object as PropType<ChipLabelBooleanConfig>,
      default: undefined,
    },
    modifiers: {
      type: Object as PropType<Modifier>,
      default: () => ({}),
    },
  },
  computed: {
    isTruthy(): boolean {
      return !!this.getItemHook().getRawValue(this.attributeName)
    },
  },
})
</script>
