// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import UsersTile from './UsersTile.vue'
import UsersColumns from './UsersColumns'
import UsersDetail from './UsersDetail.vue'
import UsersMetaCard from './UsersMetaCard.vue'
import UsersReference from './UsersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('UsersTile', UsersTile)
    Vue.component('UsersDetail', UsersDetail)
    Vue.component('UsersMetaCard', UsersMetaCard)
    Vue.component('UsersReference', UsersReference)

    modularManager.addResourcePreset(Resources.USERS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'UsersTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.USERS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: UsersColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.USERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'UsersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.USERS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'UsersMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.USERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'UsersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
