import template from './RichTextWidget.vue'
import RichTextWidgetHook from './RichTextWidgetHook'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const RichTextWidget: WidgetTypeDefinition = {
  name: WidgetName.RICH_TEXT_WIDGET,
  schema,
  component: {
    template: template as any,
    hook: RichTextWidgetHook,
  },
  config: {
    icon: 'mdi-format-color-text',
    color: 'green lighten-2',
    print: true,
    thumbnail: require('./rich-text.png'),
  },
}

export default RichTextWidget
