<template>
  <ProvideItemHook
    v-if="itemHookInternal"
    :key="uri"
    :provide="itemHookInternal"
  >
    <slot />
  </ProvideItemHook>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EventLifeCycleManager } from '@/tt-widget-entity-flow/EventLifeCycleManager'
import {
  EntityIntentTypes,
  ResourceUpdatedInterface,
} from '@/tt-widget-entity-flow/intents'

export default Vue.extend({
  name: 'EntityItem',
  props: {
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      default: null,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    resourceName: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { eventManager: null as null | EventLifeCycleManager }
  },
  computed: {
    uri(): string {
      return this.resourceName + '/' + this.entityId
    },
    itemHookInternal(): EntityItemHook {
      return (
        this.itemHook ||
        new EntityItemHook(this.$appContext, {
          resourceName: this.resourceName,
          entityId: this.entityId,
          entity: { id: this.entityId, ...this.entity },
        })
      )
    },
  },
  created() {
    this.eventManager = new EventLifeCycleManager(this.$eventManager)
  },
  mounted() {
    this.itemHookInternal && this.itemHookInternal.fetchIfRequired()
    this.eventManager &&
      this.eventManager.subscribeTo(
        EntityIntentTypes.RESOURCE_UPDATED,
        (payload: ResourceUpdatedInterface) => {
          if (payload.resource === this.resourceName && this.itemHookInternal) {
            this.itemHookInternal.fetchWait()
          }
        },
      )
  },
  beforeDestroy() {
    this.eventManager ? this.eventManager.destroy() : null
  },
})
</script>
