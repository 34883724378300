// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PaymentsTile from './PaymentsTile.vue'
import PaymentsColumns from './PaymentsColumns'
import PaymentsDetail from './PaymentsDetail.vue'
import PaymentsMetaCard from './PaymentsMetaCard.vue'
import PaymentsReference from './PaymentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PaymentsTile', PaymentsTile)
    Vue.component('PaymentsDetail', PaymentsDetail)
    Vue.component('PaymentsMetaCard', PaymentsMetaCard)
    Vue.component('PaymentsReference', PaymentsReference)

    modularManager.addResourcePreset(
      Resources.PAYMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PaymentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.PAYMENTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: PaymentsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.PAYMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PaymentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYMENTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PaymentsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PaymentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
