// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaxClassItemsTile from './TaxClassItemsTile.vue'
import TaxClassItemsColumns from './TaxClassItemsColumns'
import TaxClassItemsDetail from './TaxClassItemsDetail.vue'
import TaxClassItemsReference from './TaxClassItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaxClassItemsTile', TaxClassItemsTile)
    Vue.component('TaxClassItemsDetail', TaxClassItemsDetail)
    Vue.component('TaxClassItemsReference', TaxClassItemsReference)

    modularManager.addResourcePreset(
      Resources.TAX_CLASS_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaxClassItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASS_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaxClassItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASS_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaxClassItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASS_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaxClassItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
