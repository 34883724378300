<template>
  <div
    class="notifications-chips"
    :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
  >
    <v-chip
      v-for="chip in chips"
      :key="chip.key"
      class="notifications-chips__chip elevation-3"
      tag="div"
    >
      {{ chip.title }}
      <v-avatar class="ml-auto">
        <v-progress-circular
          indeterminate
          size="16"
          width="3"
          color="var(--v-ttPrimary-base)"
        />
      </v-avatar>
    </v-chip>
  </div>
</template>

<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'
import { NotificationChipsStateInterface } from '../types'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutNotificationsChips',
  computed: {
    chips(): NotificationChipsStateInterface {
      return this.state.notificationChips.chips
    },
  },
})
</script>

<style lang="scss" scoped>
.notifications-chips {
  position: fixed;
  bottom: 16px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  z-index: 0;
  pointer-events: none;
}

.notifications-chips__chip {
  display: flex;
  margin: 16px 0 0;
  width: 175px;
  padding: 0 8px;
  height: 28px;
  pointer-events: all;
  border-radius: 0;

  & + & {
    margin-left: 16px;
  }

  .flex-column & {
    width: 100%;
    margin: 8px 0 0;
  }

  // overrides Vuetify default color without using !important
  &.v-chip {
    .theme--light & {
      background-color: #f9f9f9;
    }
  }

  // overrides nested component's default styles
  ::v-deep .v-chip__content {
    display: flex;
    width: 100%;
  }
}
</style>
