import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { Resources } from '../../types'

import BackOfficeSettingsDetailPayroll from './BackOfficeSettingsDetailPayroll.vue'
import BackOfficeSettingsDetailBilling from './BackOfficeSettingsDetailBilling.vue'

export default {
  install(Vue) {
    Vue.component(
      'BackOfficeSettingsDetailPayroll',
      BackOfficeSettingsDetailPayroll,
    )
    Vue.component(
      'BackOfficeSettingsDetailBilling',
      BackOfficeSettingsDetailBilling,
    )

    // Adding extra detail cards
    const payrollDetail: PresetItem = {
      title:
        'tt-entity-design.back-office-settings.attrs.payroll-overtime-settings',
      data: { is: 'BackOfficeSettingsDetailPayroll', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    const billingDetail: PresetItem = {
      title: 'tt-entity-design.back-office-settings.attrs.billing-settings',
      data: { is: 'BackOfficeSettingsDetailBilling', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.BACK_OFFICE_SETTINGS,
      PresetTypes.DETAILS_CARD,
      [payrollDetail, billingDetail],
    )
  },
}
