<template>
  <v-alert prominent text type="info">
    {{ noPolicyWarning }}
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'NoPolicyWarning',
  computed: {
    noPolicyWarning(): string {
      return this.$t(
        `tt-entity-design.leave-management.employee.settings.no-policy-warning`,
      )
    },
  },
})
</script>
