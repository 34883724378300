// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SkillCategoriesTile from './SkillCategoriesTile.vue'
import SkillCategoriesColumns from './SkillCategoriesColumns'
import SkillCategoriesDetail from './SkillCategoriesDetail.vue'
import SkillCategoriesReference from './SkillCategoriesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SkillCategoriesTile', SkillCategoriesTile)
    Vue.component('SkillCategoriesDetail', SkillCategoriesDetail)
    Vue.component('SkillCategoriesReference', SkillCategoriesReference)

    modularManager.addResourcePreset(
      Resources.SKILL_CATEGORIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SkillCategoriesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SKILL_CATEGORIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SkillCategoriesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SKILL_CATEGORIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SkillCategoriesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SKILL_CATEGORIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SkillCategoriesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
