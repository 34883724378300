import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'category',
  'region',
  'dateLabel',
  'label1',
  'label2',
] as ColumnInputDefinition[]
