import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'

import { Attribute } from '../../schema-types'
import { ClientActions } from './types'
import { Resources } from '../../types'
import ClientsAssignVendors from './ClientsAssignVendors.vue'
import ClientsDetailMainContact from './ClientsDetailMainContact.vue'
import ClientsDetailMore from './ClientsDetailMore.vue'
import ClientsPrioritizeVendors from './ClientsPrioritizeVendors.vue'
import ClientsSetInvoiceSplitSettings from './ClientsSetInvoiceSplitSettings.vue'
import DraggableSiteVendorsList from './DraggableSiteVendorsList.vue'

const { CLIENTS } = Resources

const getAttr = (attr: Attribute<typeof CLIENTS>): string => attr

export default {
  install(Vue) {
    Vue.component('ClientsDetailMainContact', ClientsDetailMainContact)
    Vue.component('ClientsDetailMore', ClientsDetailMore)
    Vue.component('DraggableSiteVendorsList', DraggableSiteVendorsList)

    // Add the schedule preview in the tabs
    addPreviewTab(CLIENTS, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })

    // Adding extra detail cards
    const mainContactDetail: PresetItem = {
      title: 'tt-entity-design.main_contact',
      data: { is: 'ClientsDetailMainContact', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    const moreDetail: PresetItem = {
      title: 'tt-entity-design.more',
      data: { is: 'ClientsDetailMore', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(CLIENTS, PresetTypes.DETAILS_CARD, [
      mainContactDetail,
      moreDetail,
    ])

    modularManager.registerDynamicResourceJsonSchemaActions(CLIENTS, [
      ClientActions.ImportExceptionDates,
    ])

    //#region Register Form Components
    Vue.component('ClientsAssignVendors', ClientsAssignVendors)
    registerResourceForm(
      CLIENTS,
      'ClientsAssignVendors',
      ClientActions.AssignVendors,
    )

    Vue.component('ClientsPrioritizeVendors', ClientsPrioritizeVendors)
    registerResourceForm(
      CLIENTS,
      'ClientsPrioritizeVendors',
      ClientActions.PrioritizeVendors,
    )

    Vue.component(
      'ClientsSetInvoiceSplitSettings',
      ClientsSetInvoiceSplitSettings,
    )
    registerResourceForm(
      CLIENTS,
      'ClientsSetInvoiceSplitSettings',
      ClientActions.SetInvoiceSplitSettings,
    )
    //#endregion Register Form Components

    addPreviewTab(CLIENTS, {
      title: createI18nResourceKey(CLIENTS, getAttr('zones')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('zones'),
      },
    })

    addPreviewTab(CLIENTS, {
      title: createI18nResourceKey(CLIENTS, getAttr('positions')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('positions'),
      },
    })
  },
}
