import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import i18n from '@/plugins/i18n'

export default [
  'employee',
  {
    headerText: 'tt-entity-design.employee-account-bans.attrs.account.label',
    attributeName: 'account',
  },
  'startDate',
  'reason',
] as ColumnInputDefinition[]
