import Vue from 'vue'
import { PageInterface } from './types'
/**
 * @desc Handle dialog form layout and switching between dialog forms
 */
export type PageStackHandler = {
  reset: () => void
  back: () => void
  next: (page: PageInterface) => void
  setPageStack: (pages: PageInterface[]) => void
  getPageStack: () => PageInterface[]
  goTo: (index: number) => void
  hasAtLeastOnePage: () => boolean
}
// These are transition functions while waiting to find the best
// solution to make it communicate with the layout manager FE-699
export const next = (currentPages: PageInterface[], page: PageInterface) => [
  ...currentPages,
  page,
]

export const goTo = (currentPages: PageInterface[], index: number) =>
  currentPages.slice(0, index + 1)

export const back = (currentPages: PageInterface[]) => currentPages.slice(0, -1)

export const hasAtLeastOnePage = (currentPages: PageInterface[]): boolean =>
  !!currentPages.length

export const createPageStackHandler = (): PageStackHandler => {
  const state: { pageStack: PageInterface[] } = Vue.observable({
    pageStack: [],
  })

  return {
    back: () => {
      state.pageStack = back(state.pageStack)
    },
    getPageStack: () => state.pageStack,
    goTo: (index) => {
      state.pageStack = goTo(state.pageStack, index)
    },
    hasAtLeastOnePage: () => hasAtLeastOnePage(state.pageStack),
    next: (page) => {
      state.pageStack = next(state.pageStack, page)
    },
    reset: () => {
      state.pageStack = []
    },
    setPageStack: (pages) => {
      state.pageStack = [...pages]
    },
  }
}
