// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ClientInvoiceSplitSettingsTile from './ClientInvoiceSplitSettingsTile.vue'
import ClientInvoiceSplitSettingsColumns from './ClientInvoiceSplitSettingsColumns'
import ClientInvoiceSplitSettingsDetail from './ClientInvoiceSplitSettingsDetail.vue'
import ClientInvoiceSplitSettingsReference from './ClientInvoiceSplitSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ClientInvoiceSplitSettingsTile',
      ClientInvoiceSplitSettingsTile,
    )
    Vue.component(
      'ClientInvoiceSplitSettingsDetail',
      ClientInvoiceSplitSettingsDetail,
    )
    Vue.component(
      'ClientInvoiceSplitSettingsReference',
      ClientInvoiceSplitSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.CLIENT_INVOICE_SPLIT_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ClientInvoiceSplitSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_INVOICE_SPLIT_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ClientInvoiceSplitSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_INVOICE_SPLIT_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ClientInvoiceSplitSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_INVOICE_SPLIT_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ClientInvoiceSplitSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
