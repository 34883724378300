// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CalendarExceptionsTile from './CalendarExceptionsTile.vue'
import CalendarExceptionsColumns from './CalendarExceptionsColumns'
import CalendarExceptionsDetail from './CalendarExceptionsDetail.vue'
import CalendarExceptionsReference from './CalendarExceptionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CalendarExceptionsTile', CalendarExceptionsTile)
    Vue.component('CalendarExceptionsDetail', CalendarExceptionsDetail)
    Vue.component('CalendarExceptionsReference', CalendarExceptionsReference)

    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CalendarExceptionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
