// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import UserNotificationSettingsTile from './UserNotificationSettingsTile.vue'
import UserNotificationSettingsColumns from './UserNotificationSettingsColumns'
import UserNotificationSettingsDetail from './UserNotificationSettingsDetail.vue'
import UserNotificationSettingsMetaCard from './UserNotificationSettingsMetaCard.vue'
import UserNotificationSettingsReference from './UserNotificationSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('UserNotificationSettingsTile', UserNotificationSettingsTile)
    Vue.component(
      'UserNotificationSettingsDetail',
      UserNotificationSettingsDetail,
    )
    Vue.component(
      'UserNotificationSettingsMetaCard',
      UserNotificationSettingsMetaCard,
    )
    Vue.component(
      'UserNotificationSettingsReference',
      UserNotificationSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.USER_NOTIFICATION_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'UserNotificationSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_NOTIFICATION_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: UserNotificationSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_NOTIFICATION_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'UserNotificationSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_NOTIFICATION_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'UserNotificationSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_NOTIFICATION_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'UserNotificationSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
