<template>
  <v-app id="injected-backoffice-reports-view">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <BoundingBox v-slot="{ height }">
          <app-window :closable="false" :style="`height: ${height}px`">
            <div v-if="noVisibleTabs">
              <v-alert
                dense
                text
                color="error"
                icon="mdi-alert"
                class="d-flex justify-center align-center"
                height="200"
              >
                {{ $t('back-office-reports.missing-permissions') }}
              </v-alert>
            </div>
            <div v-else-if="isLoading">
              <TLoadingWave />
            </div>
            <div v-else class="d-flex flex-row fill-height">
              <BackOfficeReportsLeftNavigationMenu
                :menu-items="menuItems"
                :active-tab="activeTab"
                @menu-click="setActiveTab"
              />
              <ConfigurableTab :active-tab="activeTab" />
            </div>
          </app-window>
          <AppLayoutUtils :layout-configuration="layoutConfiguration" />
        </BoundingBox>
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import BoundingBox from '@/tt-widget-views/components/BoundingBox.vue'

import ConfigurableTab from '../components/ConfigurableTab.vue'
import BackOfficeReportsLeftNavigationMenu from '../components/BackOfficeReportsLeftNavigationMenu.vue'

import {
  BackOfficeReportsFinacialOperationalSubMenus,
  BackOfficeReportsMenus,
  BackOfficeReportsSubMenus,
  BackOfficeReportsTab,
  FinancialReportsCollectionResponse,
  FINANCIAL_REPORTS_COLLECTION,
  ReportingAndAnalyticsMenuItem,
  ReportingAndAnalyticsSubMenuItem,
  ReportingAndAnalyticsSubMenuTab,
  ReportingAndAnalyticsViews,
} from '../types'
import { WidgetStoreInterface } from '@/tt-widget-factory'
import {
  EARNINGS_PREMIUM_TABS,
  EFFECTIVE_DATE_TABS,
  HUMAN_CAPITAL_MANAGEMENT_TABS,
  LEAVE_MANAGEMENT_TABS,
  SCHEDULING_TABS,
} from '../constants'
import { TQLQueryWidgetModel } from '@/tt-widget-components'

/**
 * @TODO:Update Nomenclature to Reporting & Analytics (BOSS-4284)
 */
export default Vue.extend({
  name: 'BackOfficeReportsView',
  components: {
    ProvideLayoutManager,
    BoundingBox,
    ConfigurableTab,
    BackOfficeReportsLeftNavigationMenu,
  },
  props: {
    menuTabs: {
      type: Array as PropType<BackOfficeReportsMenus[]>,
      required: true,
    },
    subMenuTabs: {
      type: Array as PropType<BackOfficeReportsSubMenus[]>,
      required: true,
    },
    initialTab: {
      type: String as PropType<ReportingAndAnalyticsViews>,
      required: true,
    },
    visibleTabs: {
      type: Array as PropType<ReportingAndAnalyticsViews[]>,
      default: () => [],
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
      activeTab: {} as ReportingAndAnalyticsSubMenuTab,
      isLoading: true,
      financialOperationalReportViews: [] as WidgetStoreInterface[],
    }
  },
  computed: {
    noVisibleTabs(): boolean {
      return (
        this.initialTab === ReportingAndAnalyticsViews.EMPTY ||
        this.visibleTabs.length === 0
      )
    },
    menuItems(): ReportingAndAnalyticsMenuItem[] {
      const menuItems = [
        {
          key: BackOfficeReportsMenus.FINANCIAL_OPERATIONAL_MENU,
          menuTitle: 'back-office-reports.financial-operational.title',
          subMenus: this.financialOperationalSubMenuItems,
        },
        {
          key: BackOfficeReportsMenus.BACK_OFFICE_REPORTS_MENU,
          menuTitle: 'back-office-reports.title',
          subMenus: this.backOfficeReportsSubMenuItems,
        },
      ]

      return menuItems.filter(({ key }) => this.menuTabs.includes(key))
    },
    financialOperationalSubMenuItems(): ReportingAndAnalyticsSubMenuItem[] {
      const FORStabs = this.financialOperationalReportViews?.map(
        ({ title, widget }) => {
          const transformedTitle = title.replace(/ /g, '-').toLowerCase()

          return {
            key: transformedTitle as ReportingAndAnalyticsViews,
            title,
            widget: widget as TQLQueryWidgetModel,
          }
        },
      )

      return [
        {
          key: BackOfficeReportsFinacialOperationalSubMenus.FINANCIAL_PERFORMANCE_SUB_MENU,
          subMenuTitle: 'back-office-reports.financial-performance.title',
          tabs: FORStabs,
        },
      ]
    },
    backOfficeReportsSubMenuItems(): ReportingAndAnalyticsSubMenuItem[] {
      const items = [
        {
          key: BackOfficeReportsSubMenus.EFFECTIVE_DATE_SUB_MENU,
          subMenuTitle: 'back-office-reports.effective-date.title',
          tabs: EFFECTIVE_DATE_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.SCHEDULING_SUB_MENU,
          subMenuTitle:
            'back-office-reports.scheduling.scheduling-report.title',
          tabs: SCHEDULING_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.EARNINGS_PREMIUM_SUB_MENU,
          subMenuTitle: 'back-office-reports.earnings-premium.title',
          tabs: EARNINGS_PREMIUM_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.HCM_ACTIVITY_SUB_MENU,
          subMenuTitle: 'back-office-reports.hcm-activity.title',
          tabs: HUMAN_CAPITAL_MANAGEMENT_TABS.filter(
            this.availableMenuItems,
          ).map(this.createMenuItem),
        },
        {
          key: BackOfficeReportsSubMenus.LEAVE_MANAGEMENT_SUB_MENU,
          subMenuTitle: 'tt-entity-design.leave-management.label',
          tabs: LEAVE_MANAGEMENT_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
      ]

      return items.filter(({ key }) => this.subMenuTabs.includes(key))
    },
  },
  /**
   * Set the active tab to initialTab sent by the BackEnd
   * else set to the first item in the list
   */
  watch: {
    menuItems(items: ReportingAndAnalyticsMenuItem[]) {
      if (this.isLoading) return

      const extractTabs = ({ subMenus }) => subMenus.flatMap(({ tabs }) => tabs)
      const isInitialTab = ({ key }) => key === this.initialTab

      const allTabs = items.flatMap(extractTabs)
      const foundInitialTab = allTabs.find(isInitialTab)

      if (foundInitialTab) {
        this.activeTab = foundInitialTab
      } else if (allTabs.length) {
        this.activeTab = allTabs[0]
      }
    },
  },
  mounted() {
    this.fetchFinancialOperationalViews().then((views) => {
      this.financialOperationalReportViews = views
    })
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
  methods: {
    setActiveTab(tab: ReportingAndAnalyticsSubMenuTab) {
      this.activeTab = tab
    },
    availableMenuItems(tab: BackOfficeReportsTab): boolean {
      return this.visibleTabs.includes(tab.key)
    },
    createMenuItem({ title, key }): {
      title: string
      key: ReportingAndAnalyticsViews
    } {
      return { title, key }
    },
    async fetchFinancialOperationalViews(): Promise<WidgetStoreInterface[]> {
      return await this.$appContext.authModule
        .getApi()
        .get(FINANCIAL_REPORTS_COLLECTION, '')
        .then((res: FinancialReportsCollectionResponse) => res.data.views)
        .catch((error) => {
          this.$crash.captureException(error)

          return []
        })
        .finally(() => {
          this.isLoading = !this.isLoading
        })
    },
  },
})
</script>
