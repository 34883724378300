// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BackOfficeSettingsTile from './BackOfficeSettingsTile.vue'
import BackOfficeSettingsColumns from './BackOfficeSettingsColumns'
import BackOfficeSettingsDetail from './BackOfficeSettingsDetail.vue'
import BackOfficeSettingsReference from './BackOfficeSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BackOfficeSettingsTile', BackOfficeSettingsTile)
    Vue.component('BackOfficeSettingsDetail', BackOfficeSettingsDetail)
    Vue.component('BackOfficeSettingsReference', BackOfficeSettingsReference)

    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BackOfficeSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BackOfficeSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BackOfficeSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BackOfficeSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
