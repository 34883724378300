import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const PriceTiersBillSettingsColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'taskPriceTier.account.name',
    headerText: 'back-office-reports.effective-date.price-tiers.columns.client',
  },
  {
    attributeName: 'taskPriceTier.name',
    headerText: 'back-office-reports.effective-date.price-tiers.columns.name',
  },
  {
    attributeName: 'taskPriceTier.serviceModel',
    headerText:
      'back-office-reports.effective-date.price-tiers.columns.service-model',
  },
  { attributeName: 'chargeType' },
  { attributeName: 'effectiveDate' },
  { attributeName: 'appliedRate' },
]
