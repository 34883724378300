<template>
  <v-card
    class="level1 widget-card pa-0 ma-0 elevation-0 text-left"
    :data-cy="`widget-${item.uid}`"
  >
    <div class="text-right" style="min-height: 24px">
      <v-spacer />

      <v-menu v-if="showActionMenu" class="text-right">
        <template #activator="{ on }">
          <v-btn x-small icon text v-on="on">
            <v-icon color="menuColor lighten-1" x-small> more_horiz </v-icon>
          </v-btn>
        </template>
        <v-card class="level1">
          <WidgetActionContextualMenu :item="item">
            <template #default="{ menu, isLoading }">
              <v-list v-if="isLoading" style="width: 205px">
                <template v-for="index in 3">
                  <v-skeleton-loader
                    :key="`loader-${index}`"
                    type="list-item"
                  />
                  <v-divider v-if="index < 3" :key="`divider-${index}`" />
                </template>
              </v-list>
              <AppActionMenu else :menu="menu" divider />
            </template>
          </WidgetActionContextualMenu>
        </v-card>
      </v-menu>
    </div>
    <WidgetCardThumbnail :item="item" />
    <WidgetCardInfoList>
      <WidgetIcon slot="icon" :widget-type="item.is" />
      <v-tooltip slot="title" bottom>
        <template #activator="{ on, attrs }">
          <v-tooltip v-if="!isInjectedDashboardTab" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="!isInjectedDashboardTab && showSharedIcon"
                v-bind="attrs"
                class="mr-3"
                size="17"
                v-on="on"
              >
                {{ sharedWithMeIcon }}
              </v-icon>
            </template>
            <span
              v-if="sharedWithMeIcon == 'mdi-pencil'"
              v-text="$t(`common.role_EDITOR`)"
            />
            <span v-else v-text="$t(`common.role_VIEWER`)" />
          </v-tooltip>
          <span v-bind="attrs" v-on="on">
            {{ item.title }}
          </span>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
      <v-tooltip v-if="showSubtitle" slot="subtitle" bottom>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ getSubtitle }}
          </span>
        </template>
        <span>{{ getSubtitle }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="!item.ownedByMe && item.meta && item.meta.ownedBy"
        slot="right-action"
        class="text-right"
        bottom
      >
        <template #activator="{ on }">
          <v-btn x-small icon text style="opacity: 0.5" v-on="on">
            <SharableUserGroupAvatar
              :size="avatarSize"
              :item="item.meta.ownedBy"
              style="margin-top: 3px; margin-right: 6px; color: white"
            />
          </v-btn>
        </template>
        <span>
          <span v-text="$t(`common.owner`)" /> {{ item.meta.ownedBy.name }}
        </span>
      </v-tooltip>
    </WidgetCardInfoList>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import WidgetCardInfoList from './WidgetCardInfoList.vue'
import WidgetCardThumbnail from './WidgetCardThumbnail.vue'
import WidgetActionContextualMenu from './WidgetActionContextualMenu.vue'
import { SharableUserGroupAvatar } from '@/tt-widget-sharable'
import { WidgetStoreModel } from '@/tt-widget-factory'
import { FormatManager } from '../../../helpers/formats'
import { DateFormatTypes } from '../../../helpers/formats/dates'
import { ShareService, UserGroupsModel } from '../../../tt-widget-sharable'

type VueWithInjections = VueConstructor<
  Vue & { isInjectedDashboardTab: boolean }
>

export default (Vue as VueWithInjections).extend({
  name: 'WidgetCard',
  components: {
    WidgetActionContextualMenu,
    WidgetCardInfoList,
    WidgetCardThumbnail,
    SharableUserGroupAvatar,
  },
  inject: {
    isInjectedDashboardTab: {
      from: 'isInjectedDashboardTab',
      default: false,
    },
    showSharedIcon: {
      from: 'showSharedIcon',
      default: false,
    },
  },
  props: {
    item: {
      type: Object as PropType<WidgetStoreModel>,
      required: true,
    },
    showSubtitle: {
      default: false,
      type: Boolean,
    },
    showActionMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      avatarSize: 20,
    }
  },
  computed: {
    menuColor(): string {
      return this.$vuetify.theme.dark ? 'white' : '#666'
    },
    sharedWithMeIcon(): string {
      const hasWriteAccess = new ShareService(this.$appContext).hasWriteAccess(
        this.item.meta?.aclRule?.grant,
        UserGroupsModel.getters('myUserGroups'),
      )

      return hasWriteAccess ? 'mdi-pencil' : 'mdi-eye'
    },
    getSubtitle(): string {
      const formattedDate = FormatManager.parse(
        DateFormatTypes.asDate,
        this.item.meta.createdOn,
        this.$appContext.authModule.getUserPreferences(),
      )
      return `${formattedDate} - ${this.item.meta.ownedBy.name}`
    },
  },
})
</script>

<style scoped>
.widget-card {
  cursor: pointer;
}

.card-screen {
  background: rgb(2, 0, 36);
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 33, 214, 0.3) 0%,
    rgba(0, 33, 214, 0.1) 100%
  );
}

.card-icon-screen {
  background: rgb(2, 0, 36);
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 10, 200, 0.1) 0%,
    rgba(0, 10, 200, 0.05) 100%
  );
}
</style>
