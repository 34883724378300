import i18n from '@/plugins/i18n'
import { ErrorObject } from '@tracktik/tt-json-schema-form'

export const error: ErrorObject[] = [
  {
    dataPath: `.location`,
    keyword: 'required',
    message: i18n.t('common.required_field'),
    params: Object,
    schemaPath: '#/required',
  },
]
