<template>
  <div class="d-flex">
    <tt-attr :name="dateName" format="asDate" />
    <ClockTooltip
      v-if="hasSelectedTime"
      :date-name="dateName"
      :tooltip-text="tooltipText"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import ClockTooltip from '../ClockTooltip.vue'
import { isDateInPast } from '@/helpers/dates/isDateInPast'
import {
  convertPrefToMoment,
  dateOrUnix,
  getFormatWithoutTimezone,
} from '@/helpers/formats/dates/utils'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsDateTimeIconColumn',
  components: { ClockTooltip },
  inject: ['getItemHook'],
  props: {
    dateName: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    showPastDateWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributeDate(): string {
      return this.getItemHook().getRawValue(this.dateName)
    },
    hasSelectedTime(): boolean {
      const userPreferences = this.$appContext.authModule.getUserPreferences()
      const noTimeZone = convertPrefToMoment(userPreferences.timeFormat)
      const momentObj = dateOrUnix(this.attributeDate, userPreferences)
      const noTimeZoneFormat = momentObj.format(
        getFormatWithoutTimezone(noTimeZone),
      )
      return noTimeZoneFormat !== '00:00'
    },
    hasRequestPast(): boolean {
      return isDateInPast(this.attributeDate)
    },
  },
})
</script>
<style scoped>
.warning-color {
  color: var(--v-ttWarning-base);
  font-weight: bold;
}
</style>
