import { modularManager, PresetTypes } from '@/tt-app-modular'
import { ListWidgetModel, DefaultToolbar } from '@/tt-widget-components'
import CollectionWidgetHook from '@/tt-widget-components/base/CollectionWidgetHook'

/**
 * List Widget
 */
export default class ListWidgetHook extends CollectionWidgetHook<ListWidgetModel> {
  /**
   * Get the list item
   */
  public get listItem() {
    return this.state.widget?.listItem?.is ? this.state.widget.listItem : null
  }

  /**
   * Set the require fields
   * @param attributes
   */
  set requiredFields(attributes: string[]) {
    // Refactor to be set on collection widget hook see: FE-1565
    const widgetFields =
      this.state.widget.query?.fields?.map((field) => field.attribute) || []

    const uniqueAttributes = [
      ...new Set([...attributes, ...widgetFields, 'id']),
    ]
    this.attributes = [...uniqueAttributes]
    this.isReadyToFetch = true
    this.update()
  }

  setup() {
    // Not ready to fetch until we get the required fields
    this.isReadyToFetch = false
    if (!this.isValid) {
      return
    }

    // Override default tolbar
    if (!this.state?.widget?.toolbar) {
      this.state.widget.toolbar = {
        show: true,
        displayCounts: true,
        filterOptions: {
          allowSearch: true,
        },
      } as DefaultToolbar
    }

    // Set default presets
    if (!this.state?.widget?.listItem?.is) {
      const item = modularManager.getResourcePreset(
        this.state.widget.query.resource,
        PresetTypes.LIST_ITEM,
      )

      if (item) {
        if (!item.data.props) {
          item.data.props = {}
        }
        this.state.widget.listItem = item.data
      }
    }
  }
}
