// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ModelTranslationsTile from './ModelTranslationsTile.vue'
import ModelTranslationsColumns from './ModelTranslationsColumns'
import ModelTranslationsDetail from './ModelTranslationsDetail.vue'
import ModelTranslationsReference from './ModelTranslationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ModelTranslationsTile', ModelTranslationsTile)
    Vue.component('ModelTranslationsDetail', ModelTranslationsDetail)
    Vue.component('ModelTranslationsReference', ModelTranslationsReference)

    modularManager.addResourcePreset(
      Resources.MODEL_TRANSLATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ModelTranslationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MODEL_TRANSLATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ModelTranslationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MODEL_TRANSLATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ModelTranslationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MODEL_TRANSLATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ModelTranslationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
