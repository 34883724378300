// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import InvoiceLineTaxesTile from './InvoiceLineTaxesTile.vue'
import InvoiceLineTaxesColumns from './InvoiceLineTaxesColumns'
import InvoiceLineTaxesDetail from './InvoiceLineTaxesDetail.vue'
import InvoiceLineTaxesReference from './InvoiceLineTaxesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('InvoiceLineTaxesTile', InvoiceLineTaxesTile)
    Vue.component('InvoiceLineTaxesDetail', InvoiceLineTaxesDetail)
    Vue.component('InvoiceLineTaxesReference', InvoiceLineTaxesReference)

    modularManager.addResourcePreset(
      Resources.INVOICE_LINE_TAXES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'InvoiceLineTaxesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_LINE_TAXES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: InvoiceLineTaxesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_LINE_TAXES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'InvoiceLineTaxesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_LINE_TAXES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'InvoiceLineTaxesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
