<template>
  <json-form v-bind="formProps" @input="$emit('input', $event)" />
</template>

<script lang="ts">
import Vue from 'vue'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  BaseViews,
  FormOptions,
  vuetifyDefaults,
} from '@tracktik/tt-json-schema-form'
import { OpenAPICompatibleSchema } from '@tracktik/tt-json-schema-form'

import { getResourceDefinitions } from '@/tt-entity-forms'
import { normalizeKebabCaseName } from '../utils'

export default Vue.extend({
  name: 'AttributePatchForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
  },
  computed: {
    resourceAttributeEditSchema(): OpenAPICompatibleSchema {
      return this.$appContext.widgetServices.resourceMetaManager.getPatchAttributeFormSchema(
        this.resourceName,
        this.attribute,
      )
    },
    formProps(): {
      name: string
      formOptions: FormOptions
      value: any
      schema: any
    } {
      const normalizedResourceName = normalizeKebabCaseName(this.resourceName)
      const actionName = normalizeKebabCaseName(`${this.attribute}Patch`)

      const name = `${normalizedResourceName}${actionName}`

      return {
        name,
        formOptions: {
          baseViews: {
            [BaseViews.ENUM]: {
              is: 'VAutocomplete',
              props: {
                ...vuetifyDefaults,
                attach: false,
              },
            },
          },
          locale: this.$i18n.locale,
          definitions: getResourceDefinitions(this.$appContext),
          translateFunction: ResourceTranslator.getFormCallback(
            this.resourceName,
          ),
        },
        value: this.value,
        schema: this.resourceAttributeEditSchema,
      }
    },
  },
})
</script>
