import { AppContext } from '@/tt-app-context'
import { DocumentPresetInterface } from '@/tt-app-modular'
import { DocumentInput, DocumentProviders } from '@/tt-entity-documents/types'
import {
  DocumentServiceInput,
  DocumentServiceTemplateName,
} from '@/tt-entity-documents/providers/DocumentServiceProvider'
import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'
import { TTCloudDocumentInput } from '@/tt-entity-documents/providers/TTCloudDocumentServiceProvider'
import { isDocumentServiceAvailable } from '@/tt-entity-documents/DocumentService'

const titleTranslationKey =
  'tt-entity-design.mobile-runsheet-occurrences.runsheet-report'

const getTTCloudInput = async (
  entityReference: EntityItemViewInterface,
): Promise<TTCloudDocumentInput> => {
  return {
    documentName: titleTranslationKey,
    provider: DocumentProviders.TTCLOUD,
    entityReference,
    locale: 'en',
    data: {
      path: `/mobileplus/session/report/id/${entityReference.entityId}`,
    },
  }
}

const getDocumentServiceInput = (
  entityReference: EntityItemViewInterface,
  locale?: string,
): DocumentServiceInput => ({
  documentName: titleTranslationKey,
  provider: DocumentProviders.DOCUMENT_SERVICE,
  entityReference,
  locale: locale ?? 'en',
  data: {
    templateName: DocumentServiceTemplateName.MOBILE_RUNSHEET_OCCURRENCES,
  },
})

const MobileRunsheetOccurrenceDoc: DocumentPresetInterface<DocumentInput> = {
  name: titleTranslationKey,
  async toDocument(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
    locale?: string,
  ): Promise<DocumentInput> {
    return isDocumentServiceAvailable(appContext.authModule)
      ? getDocumentServiceInput(entityReference, locale)
      : getTTCloudInput(entityReference)
  },
}
export default MobileRunsheetOccurrenceDoc
