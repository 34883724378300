import { ContextFilter } from '@/tt-widget-components'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { ValidQueryParams } from '../types'

/**
 * Converts given context filters to query params
 */
export const convertContextFiltersToQueryParams = (
  contextFilters: ContextFilter[],
): string => {
  const byFilterName = (attr: AttributeName) => (filter: ContextFilter) =>
    filter.attribute === attr

  const getOperator = (attr: AttributeName) =>
    contextFilters.find(byFilterName(attr))?.operator

  const getValue = (attr: AttributeName) =>
    contextFilters.find(byFilterName(attr))?.value?.toString()

  const hasValue = ([key, value]) => !!value

  const queryParamObj: ValidQueryParams = {
    context_regions: getValue(AttributeName.REGION_ATTRIBUTE),
    context_regions_operator: getOperator(AttributeName.REGION_ATTRIBUTE),

    context_accounts: getValue(AttributeName.ACCOUNT_ATTRIBUTE),
    context_accounts_operator: getOperator(AttributeName.ACCOUNT_ATTRIBUTE),

    context_daterange: getValue(AttributeName.DATE_RANGE_ATTRIBUTE),
    context_daterange_operator: getOperator(AttributeName.DATE_RANGE_ATTRIBUTE),
  }

  const cleanEntries = Object.entries(queryParamObj).filter(hasValue)

  return new URLSearchParams(cleanEntries).toString()
}
