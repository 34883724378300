<template>
  <div></div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ContextHeaders } from '@tracktik/tt-authentication'

import { queryStringToResourceFiltersMap } from '@/tt-widget-factory/queryStringToFilter'
import {
  ContextManagerInterface,
  ResourceFiltersMap,
} from '@/tt-widget-factory/types'

const QUERY_FILTER_LAYER = 'QUERY_FILTER_LAYER'

type VueWithContextManager = VueConstructor<
  Vue & { contextManager: ContextManagerInterface }
>

export default (Vue as VueWithContextManager).extend({
  name: 'QueryContextFilter',
  inject: ['contextManager'],
  computed: {
    query(): Record<string, string | string[]> {
      return this.$route.query
    },
    queryResourceFilterLayer(): ResourceFiltersMap {
      return this.query ? queryStringToResourceFiltersMap(this.query) : {}
    },
    queryTTCRegionFilter(): string | undefined {
      const filter = this.query?.[ContextHeaders.RegionFilter]
      return Array.isArray(filter) ? filter[0] : filter
    },
  },
  watch: {
    queryResourceFilterLayer: {
      immediate: true,
      deep: true,
      handler() {
        this.contextManager.setResourceFilterLayer(
          QUERY_FILTER_LAYER,
          this.queryResourceFilterLayer,
        )
      },
    },
    queryTTCRegionFilter: {
      immediate: true,
      handler(regionId: string | undefined) {
        if (!regionId) return
        this.$appContext.authModule.setContextRegionId(regionId)
      },
    },
  },
})
</script>
