import { reactive } from 'vue'
import { AddressInterface, ClientInterface } from '../../dispatch-tasks/types'
import { Coordinates } from '@tracktik/tt-geo-proxy'

type LocationFormState = {
  isCustomLocation: boolean
  clientItems: ClientInterface | null
  customLocation: AddressInterface | null
}

export type LocationServiceProvider = {
  locationService: LocationFormServiceType
}

export type LocationFormServiceType = {
  setIsCustomLocation: (value: boolean) => void
  getIsCustomLocation: () => boolean
  setClientItems: (value: ClientInterface) => void
  setCustomLocation: (value: AddressInterface) => void
  getAddress: () => string | null
  getAddressObject: () => AddressInterface | null
  getLocationName: () => string | null
  getRegion: () => string | null
  getClientAdress: () => AddressInterface | null
  getDefaultCoordinate: () => Coordinates | null
}

export const locationFormService = (): LocationFormServiceType => {
  const state: LocationFormState = reactive({
    isCustomLocation: false,
    clientItems: null,
    customLocation: null,
  })

  const setIsCustomLocation = (value: boolean): void => {
    state.isCustomLocation = value
  }

  const setClientItems = (value: ClientInterface | null): void => {
    state.clientItems = value
  }

  const setCustomLocation = (value: AddressInterface): void => {
    state.customLocation = value
  }

  const getAddressObject = (): AddressInterface | null => {
    if (!state.clientItems && !state.customLocation) {
      return null
    }

    return state.isCustomLocation
      ? state.customLocation
      : state.clientItems?.address || ({} as AddressInterface)
  }

  const getAddress = (): string | null => {
    if (!state.clientItems && !state.customLocation) {
      return null
    }

    const address: AddressInterface = state.isCustomLocation
      ? state.customLocation
      : state.clientItems?.address || ({} as AddressInterface)

    const {
      name: _,
      latitude: __,
      longitude: ___,
      ...sanitizedAdress
    } = address

    return Object.values(sanitizedAdress).filter(Boolean).join(', ')
  }

  const getDefaultCoordinate = (): Coordinates | null => {
    if (!state.clientItems && !state.customLocation) {
      return null
    }

    const address: AddressInterface = state.isCustomLocation
      ? state.customLocation
      : state.clientItems?.address || ({} as AddressInterface)

    return {
      latitude: address.latitude,
      longitude: address.longitude,
    }
  }

  const getIsCustomLocation = (): boolean => {
    return state.isCustomLocation
  }

  const getLocationName = (): string | null => {
    return state.isCustomLocation
      ? state.customLocation?.name
      : state.clientItems?.company || null
  }

  const getRegion = (): string | null => {
    return state.clientItems?.region?.name || null
  }

  const getClientAdress = (): AddressInterface | null => {
    return state.clientItems?.address || null
  }

  return {
    setIsCustomLocation,
    setClientItems,
    setCustomLocation,
    getIsCustomLocation,
    getAddress,
    getAddressObject,
    getClientAdress,
    getLocationName,
    getRegion,
    getDefaultCoordinate,
  }
}
