import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { TextClass } from '@/helpers/text/typo'

const columns: ColumnInputDefinition[] = [
  {
    attributeName: 'name',
    style: {
      cssClass: TextClass.BOLD,
      width: 200,
    },
  },
  {
    attributeName: 'serialNumber',
  },
  {
    attributeName: 'type.category',
    headerText: 'tt-entity-design.asset-hub.asset-category',
    style: {
      width: 200,
    },
  },
  'type',
  {
    attributeName: 'type.supplier',
    headerText: 'tt-entity-design.asset-hub.asset-supplier',
  },
  'checkoutStatus',
  {
    attributeName: 'checkoutTransactionItem.person.name',
    headerText: 'tt-entity-design.asset-hub.checkout-by',
  },
  {
    attributeName: 'checkoutReturnDateTime',
    headerText: 'tt-entity-design.asset-hub.check-in-time',
  },
  'bundle.name',
]

export default columns
