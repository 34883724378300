import moment from 'moment-timezone'
import { ApiDateTimeFormats } from '../formats/dates/types'
import { UTC } from './timezones'
import { createDateTimeString } from './createDateTimeString'

/**
 * Given a date, a time, and a timezone,
 * returns the datetime string in ISO 8601 format.
 * If not timezone is provided, the date & time will be in UTC.
 *
 *
 * @param date - A date string in the format `YYYY-MM-DD`
 * @param time - A time string in the format `HH:mm:ss`
 * @param timeZone - A IANA timezone name (https://momentjs.com/timezone/docs/#/zone-object/name)
 */
export const createIsoDatetime = (
  date: string,
  time: string,
  timeZone: string = UTC,
) =>
  moment
    .tz(
      createDateTimeString(date, time),
      ApiDateTimeFormats.datetime,
      true,
      timeZone,
    )
    .format(ApiDateTimeFormats.ISO_8601)
