import {
  AclGrantPermission,
  AclRule,
  DENIED,
  EDITOR,
  PermissionType,
  THIS,
  UserGroupItem,
  VIEWER,
  AclRuleItemSource,
} from '../types'
import { cloneDeep } from 'lodash'

const addDeny = (aclRule: AclRule, tag: string): AclRule['deny'] => ({
  ...aclRule.deny,
  [tag]: THIS,
})

const addGrant = (
  aclRule: AclRule,
  tag: string,
  newPermission: AclGrantPermission,
): AclRule['grant'] => ({
  ...aclRule.grant,
  [tag]: { source: THIS, permission: newPermission },
})

const removeDeny = (aclRule: AclRule, tag: string): AclRule['deny'] => {
  const { [tag]: _, ...deny } = aclRule.deny || {}
  return deny
}

const removeGrant = (aclRule: AclRule, tag: string): AclRule['grant'] => {
  const { [tag]: _, ...grant } = aclRule.grant || {}
  return grant
}

export const createRuleViewItemFromGrant = ([tag, { source, permission }]) => ({
  tag,
  source,
  permission,
  isLoading: false,
})

export const createRuleViewItemFromDenied = ([tag, source]) => ({
  tag,
  source,
  permission: DENIED,
  isLoading: false,
})

export const addPermission = (
  aclRule: AclRule,
  tag: string,
  newPermission: PermissionType,
): AclRule => {
  if (newPermission === DENIED) {
    return {
      grant: removeGrant(aclRule, tag),
      deny: addDeny(aclRule, tag),
    }
  } else if (newPermission === VIEWER || newPermission === EDITOR) {
    return {
      grant: addGrant(aclRule, tag, newPermission),
      deny: removeDeny(aclRule, tag),
    }
  }
}

export const removePermissions = (aclRule: AclRule, tag: string): AclRule => ({
  grant: removeGrant(aclRule, tag),
  deny: removeDeny(aclRule, tag),
})

const cleanAndAddNewRuleWithPermission =
  (permission: PermissionType) =>
  (acc: AclRule, aclTag: string): AclRule => {
    const cleanAclRule = removePermissions(acc, aclTag)
    return addPermission(cleanAclRule, aclTag, permission)
  }

const extractTag = (item: UserGroupItem) => item.aclTag

export const addMultipleNewRules = (
  aclRule: AclRule,
  items: Pick<UserGroupItem, 'aclTag'>[],
  permission: PermissionType,
): AclRule =>
  items
    .map(extractTag)
    .reduce(cleanAndAddNewRuleWithPermission(permission), aclRule)

export const cleanParentRules = (aclRule: AclRule): AclRule => {
  const cleanedAclRule = cloneDeep(aclRule)
  cleanedAclRule.grant = Object.fromEntries(
    Object.entries(cleanedAclRule.grant).filter(([_, value]) => {
      return value.source !== AclRuleItemSource.PARENT
    }),
  )
  return cleanedAclRule
}
