// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagTemplateFieldGroupFieldsTile from './ReportFlagTemplateFieldGroupFieldsTile.vue'
import ReportFlagTemplateFieldGroupFieldsColumns from './ReportFlagTemplateFieldGroupFieldsColumns'
import ReportFlagTemplateFieldGroupFieldsDetail from './ReportFlagTemplateFieldGroupFieldsDetail.vue'
import ReportFlagTemplateFieldGroupFieldsMetaCard from './ReportFlagTemplateFieldGroupFieldsMetaCard.vue'
import ReportFlagTemplateFieldGroupFieldsReference from './ReportFlagTemplateFieldGroupFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportFlagTemplateFieldGroupFieldsTile',
      ReportFlagTemplateFieldGroupFieldsTile,
    )
    Vue.component(
      'ReportFlagTemplateFieldGroupFieldsDetail',
      ReportFlagTemplateFieldGroupFieldsDetail,
    )
    Vue.component(
      'ReportFlagTemplateFieldGroupFieldsMetaCard',
      ReportFlagTemplateFieldGroupFieldsMetaCard,
    )
    Vue.component(
      'ReportFlagTemplateFieldGroupFieldsReference',
      ReportFlagTemplateFieldGroupFieldsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagTemplateFieldGroupFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupFieldsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
