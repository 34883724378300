<template>
  <v-textarea v-model="model" outlined v-bind="$attrs" />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TextareaField',
  props: {
    // field property name from the form
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(newValue: string) {
        this.$emit('input', newValue)
      },
    },
  },
})
</script>
