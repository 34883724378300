// Enter your custom installation here
import { modularManager, PresetLevels, PresetTypes } from '@/tt-app-modular'
import { Resources } from '../../types'
import AssetReservationsReference from './AssetReservationsReference.vue'

export default {
  install(Vue) {
    Vue.component('AssetReservationsReference', AssetReservationsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetReservationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
