<template>
  <ChipLabelBoolean v-if="hasRegion" attributeName="isAvailableInSubRegion" />
  <TChipNoValue v-else />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketInstructionsIsAvailableInSubRegionAttr',
  inject: ['getItemHook'],
  computed: {
    hasRegion(): boolean {
      return !!this.getItemHook().getRawValue('region.id')
    },
  },
  created() {
    this.getItemHook().addAttribute('isAvailableInSubRegion')
    this.getItemHook().addAttribute('region.id')
  },
})
</script>
