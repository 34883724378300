import Vue from 'vue'

import * as jsonSchemaForm from '@tracktik/tt-json-schema-form'

import AppExtensionSlotFactory from '@/tt-app-extensions/base/AppExtensionSlotFactory.vue'

import AppActionMenu from './components/AppActionMenu.vue'
import AppLayout from './components/AppLayout.vue'
import AppLayoutSideSheet from './components/AppLayoutSideSheet.vue'
import AppLayoutSideSheetStandAlone from './components/AppLayoutSideSheetStandAlone.vue'
import AppLayoutUtils from './components/AppLayoutUtils.vue'
import AppSlot from './components/AppSlot.vue'
import AppWindow from './components/AppWindow.vue'
import DocumentViewer from './components/DocumentViewer.vue'
import GenericForm from './components/GenericForm.vue'
import GenericFormPage from './components/GenericFormPage.vue'
import OverflowBox from './components/OverflowBox.vue'
import PageBlock from './components/PageBlock.vue'
import PageTitleBar from './components/PageTitleBar.vue'
import RealTimeOverflowBox from './components/RealTimeOverflowBox.vue'
import TabSlot from './components/TabSlot.vue'

export * from './types'

Vue.use(jsonSchemaForm)
Vue.component('AppSlot', AppSlot)
Vue.component('TabSlot', TabSlot)
Vue.component('DocumentViewer', DocumentViewer)
Vue.component('OverflowBox', OverflowBox)
Vue.component('RealTimeOverflowBox', RealTimeOverflowBox)
Vue.component('GenericForm', GenericForm)
Vue.component('GenericFormPage', GenericFormPage)
Vue.component('PageTitleBar', PageTitleBar)
Vue.component('PageBlock', PageBlock)
Vue.component('AppWindow', AppWindow)
Vue.component('AppExtensionSlotFactory', AppExtensionSlotFactory)
Vue.component('AppLayout', AppLayout)
Vue.component('AppLayoutUtils', AppLayoutUtils)
Vue.component('AppLayoutSideSheet', AppLayoutSideSheet)
Vue.component('AppLayoutSideSheetStandAlone', AppLayoutSideSheetStandAlone)
Vue.component('AppActionMenu', AppActionMenu)
