// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractServiceModelsTile from './ContractServiceModelsTile.vue'
import ContractServiceModelsColumns from './ContractServiceModelsColumns'
import ContractServiceModelsDetail from './ContractServiceModelsDetail.vue'
import ContractServiceModelsReference from './ContractServiceModelsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContractServiceModelsTile', ContractServiceModelsTile)
    Vue.component('ContractServiceModelsDetail', ContractServiceModelsDetail)
    Vue.component(
      'ContractServiceModelsReference',
      ContractServiceModelsReference,
    )

    modularManager.addResourcePreset(
      Resources.CONTRACT_SERVICE_MODELS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractServiceModelsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_SERVICE_MODELS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractServiceModelsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_SERVICE_MODELS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractServiceModelsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_SERVICE_MODELS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractServiceModelsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
