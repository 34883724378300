import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { modularManager } from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import MessageBoardPostsDetailsBanner from './MessageBoardPostsDetailsBanner.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component(
      'MessageBoardPostsDetailsBanner',
      MessageBoardPostsDetailsBanner,
    )
    modularManager.addItem(
      `${Resources.MESSAGE_BOARD_POSTS}.preview.details.top`,
      {
        is: 'MessageBoardPostsDetailsBanner',
      },
    )

    const previewTagPageListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS,
      },
    }

    const previewTagPage: PreviewTabInterface = {
      title: createI18nPluralResourceKey('message-board-post-acknowledgements'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'message.id',
        widget: previewTagPageListWidget,
      },
    }
    addPreviewTab(Resources.MESSAGE_BOARD_POSTS, previewTagPage)
  },
}
