import { DocumentProviders } from '@/tt-entity-documents/types'
import { AppContext } from '@/tt-app-context/types'
import { ApiFile } from '@/tt-api-files/types'
import { ExternalUrlProviderDocumentInput } from '@/tt-entity-documents/providers/ExternalURLDocumentProvider'
import { DocumentPresetInterface } from '@/tt-app-modular'

const MimeTypesDictionnary: Record<ApiFile['format'], string> = {
  jpg: 'image/jpg',
  png: 'image/png',
  pdf: 'application/pdf',
} as const

const createBlob =
  (format: string) =>
  ({ data }) => {
    const type = MimeTypesDictionnary[format] || ''

    if (!type) console.error(`File format '${format}' is not supported.`)

    return new Blob([data], { type })
  }

const createObjectURL = (file) => URL.createObjectURL(file)

const createLocaleDocumentURL = async (appContext: AppContext, file: ApiFile) =>
  appContext.authModule
    .getHttp()
    .get(file.url, { responseType: 'blob' })
    .then(createBlob(file.format))
    .then(createObjectURL)

const onError = (err: Error) => {
  console.warn(err)

  return ''
}

/**
 * Converts a /files entity to a DocumentPreset with an objectURL
 */
export const convertFileToDocumentPreset = (
  file: ApiFile,
): DocumentPresetInterface<ExternalUrlProviderDocumentInput> => ({
  name: file.tag,
  toDocument: async (
    appContext: AppContext,
  ): Promise<ExternalUrlProviderDocumentInput> => {
    const url = await createLocaleDocumentURL(appContext, file).catch(onError)

    return {
      provider: DocumentProviders.EXTERNAL,
      data: { url },
      locale: 'en',
      documentName: file.tag,
    }
  },
})
