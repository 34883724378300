import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'eventsSubscription',
  'eventData',
  'attempts',
  'status',
  'responseType',
] as ColumnInputDefinition[]

// #NOT_DONE# Make sure to remove the line below to prevent your file from being overwritten
