// Enter your custom installation here
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import MobileRunsheetGroupsInstancesTab from './MobileRunsheetGroupsInstancesTab.vue'
import MobileRunsheetGroupsArchive from './MobileRunsheetGroupsArchive.vue'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    Vue.component(
      'MobileRunsheetGroupsInstancesTab',
      MobileRunsheetGroupsInstancesTab,
    )
    Vue.component('MobileRunsheetGroupsArchive', MobileRunsheetGroupsArchive)

    registerResourceForm(
      Resources.MOBILE_RUNSHEET_GROUPS,
      'MobileRunsheetGroupsArchive',
      'archive',
    )

    addPreviewTab(Resources.MOBILE_RUNSHEET_GROUPS, {
      title: 'runsheet_group.preview.instances_tab',
      is: 'MobileRunsheetGroupsInstancesTab',
    })
  },
}
