<template>
  <div>
    <json-field name="customAttributes" :item-props="{ blockCreation: true }" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetTypesAssignCustomAttributeForm',
})
</script>

<style scoped>
/**
   * This class is used in several places, so for safety this max-width 
   * will be applied only here to make the text-truncate class of 
   * the same component work for the sidebar.
   */
div >>> .entity-attribute-cell {
  max-width: 260px;
}
</style>
