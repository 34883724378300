import { PluginFunction } from 'vue'

const RunsheetCreateBatchView = () =>
  import(
    /* webpackChunkName: "RunsheetCreateBatchView" */
    './views/RunsheetCreateBatchView.vue'
  )

export { RunsheetCreateBatchView }

export const MobileRunsheetsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('RunsheetCreateBatchView', RunsheetCreateBatchView)
}
