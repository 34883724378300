// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskSiteInstructionsTile from './TaskSiteInstructionsTile.vue'
import TaskSiteInstructionsColumns from './TaskSiteInstructionsColumns'
import TaskSiteInstructionsDetail from './TaskSiteInstructionsDetail.vue'
import TaskSiteInstructionsReference from './TaskSiteInstructionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaskSiteInstructionsTile', TaskSiteInstructionsTile)
    Vue.component('TaskSiteInstructionsDetail', TaskSiteInstructionsDetail)
    Vue.component(
      'TaskSiteInstructionsReference',
      TaskSiteInstructionsReference,
    )

    modularManager.addResourcePreset(
      Resources.TASK_SITE_INSTRUCTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskSiteInstructionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_INSTRUCTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskSiteInstructionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_INSTRUCTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskSiteInstructionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_INSTRUCTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskSiteInstructionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
