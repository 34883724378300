import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import DrawCanvaField from '@/tt-entity-forms/components/DrawCanvasField.vue'
import { FieldMeta } from '@/tt-fieldset-builder/type'
import { FieldUI } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { FieldConverter } from '@/tt-fieldset-builder/type'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import schema from './schema.json'
import { SIGNATURE, SignatureFieldType } from './types'

import CustomFieldSignature from './CustomFieldSignature.vue'

const ui: FieldUI = {
  name: i18nFieldKey(SIGNATURE),
  icon: 'mdi-signature-freehand',
  color: 'pink',
}

// @ts-ignore
const converter: FieldConverter = (field: SignatureFieldType) => ({
  getJsonSchema: () => {
    return { allOf: [getBaseFieldJsonSchema(field), { type: 'number' }] }
  },
  getViewOption: () => {
    return {
      is: CustomFieldSignature,
      props: {
        uploadDeactivated: false, // review how to separate preview from real usage
      },
    }
  },
})

export const SignatureMeta: FieldMeta = {
  field: SIGNATURE,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
