<template>
  <tt-attr-row-card>
    <tt-attr-row name="status">
      <SystemExceptionTicketsStatusAttr />
    </tt-attr-row>
    <TextAreaAttr
      name="instructions"
      :no-value-placeholder="noInstructionsLabel"
      class="pt-3"
    />
    <tt-attr-row name="comments" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'
import TextAreaAttr from '../TextAreaAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'

export default Vue.extend({
  name: 'SystemExceptionTicketsDetail',
  components: {
    TextAreaAttr,
    SystemExceptionTicketsStatusAttr,
  },
  computed: {
    noInstructionsLabel(): string {
      return this.$t(this.translationKey('no-instructions'))
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.system-exception-tickets.instructions.${key}`
    },
  },
})
</script>
