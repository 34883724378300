<template>
  <v-container
    v-if="title"
    fluid
    class="widget-title text-center pl-1"
    :style="`display: ${showTitle ? 'flex' : 'none'}`"
  >
    <v-tooltip class="d-flex" :disabled="!showTooltip" bottom>
      <template #activator="{ on }">
        <span
          class="title-text"
          v-on="on"
          @mouseover="hoverItem"
          v-text="title"
        />
      </template>
      <span v-text="title" />
    </v-tooltip>
    <v-tooltip v-if="description" bottom>
      <template #activator="{ on }">
        <v-btn class="ml-4" x-small icon text v-on="on">
          <v-icon x-small v-text="'info_outlined'" />
        </v-btn>
      </template>
      <span v-text="description" />
    </v-tooltip>
    <WidgetInfoButton
      v-if="showInfoButton && !getEditMode()"
      :hook="hook"
      :is-dashboard-wrapper="isDashboardWrapper"
      class="info-button pr-2"
    />
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { EditModeProvider } from '../../apps/app.tracktik.insights.studio/types'
import { CollectionWidgetHook, DataSetWidgetHook } from '../base'
import { canDisplayWidgetDetails } from './WidgetDetails.utils'
import WidgetInfoButton from '@/tt-widget-components/components/WidgetInfoButton.vue'
import { usePresentationMode } from '@/apps/app.tracktik.insights.studio/lib/RouterUtils'

type VueWithEditMode = VueConstructor<Vue & EditModeProvider>
export default (Vue as VueWithEditMode).extend({
  name: 'WidgetTitle',
  components: {
    WidgetInfoButton,
  },
  inject: {
    getEditMode: {
      default() {
        return () => false
      },
    },
  },
  props: {
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: null,
    },
    hook: {
      type: Object as
        | PropType<CollectionWidgetHook<any>>
        | PropType<DataSetWidgetHook<any>>,
      default: null,
    },
  },
  data() {
    return {
      presentationMode: usePresentationMode(this.$router),
      showTooltip: false,
    }
  },
  computed: {
    widget(): any {
      return this.hook?.state?.widget
    },
    title(): string {
      return this.widget?.title ? this.$t(this.widget?.title) : ''
    },
    description(): string {
      return this.widget?.description || ''
    },
    showInfoButton(): boolean {
      return (
        !this.getEditMode() &&
        canDisplayWidgetDetails(this.widget, this.$appContext) &&
        this.container?.type !== undefined &&
        !this.presentationMode.get()
      )
    },
    showTitle(): boolean {
      if (this.container?.hideTitle) {
        return false
      }

      return ![
        WidgetContainerType.DASHBOARD_TAB,
        WidgetContainerType.STANDALONE,
        '',
      ].includes(this.container?.type ?? WidgetContainerType.DASHBOARD_CELL)
    },
    isDashboardWrapper(): boolean {
      return this.container?.type === WidgetContainerType.DASHBOARD_CELL
    },
  },
  methods: {
    hoverItem(e: MouseEvent): void {
      const isContentOverflowing = () =>
        (e.target as HTMLInputElement).offsetWidth <
        (e.target as HTMLInputElement).scrollWidth
      this.showTooltip = isContentOverflowing()
    },
  },
})
</script>

<style scoped>
.title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.widget-title {
  opacity: 0.8;
  font-weight: 400;
  font-size: 16px;
  justify-content: center;
}

.widget-title:hover .info-button {
  display: block !important;
}

.info-button {
  display: none;
  position: absolute;
  background-color: #0000 !important;
  right: 0;
  margin-right: 1em;
}

@media print {
  .widget-title {
    display: flex !important;
  }
}
</style>
