<template>
  <div class="text-caption text-secondary">
    <v-icon
      color="level1Control"
      class="mr-2"
      small
      :style="{ marginTop: '-3px' }"
      v-text="'mdi-information-outline'"
    />
    <span
      v-text="
        $t('tt-entity-design.mobile-runsheet-groups.actions.archive.warning')
      "
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'MobileRunsheetGroupsArchive',
})
</script>
