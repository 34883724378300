import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const employeesByRegionsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.EMPLOYEES,
      },
    }

    const employeesByRegions: PreviewTabInterface = {
      title: createI18nPluralResourceKey('employees'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'region.id',
        widget: employeesByRegionsListWidget,
      },
    }

    const clientsByRegionsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.CLIENTS,
      },
    }

    const clientsByRegions: PreviewTabInterface = {
      title: createI18nPluralResourceKey('clients'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'region.id',
        widget: clientsByRegionsListWidget,
      },
    }
    // Add employees list by region
    addPreviewTab(Resources.REGIONS, employeesByRegions)

    // Add clients list by region
    addPreviewTab(Resources.REGIONS, clientsByRegions)
  },
}
