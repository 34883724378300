// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeAccountAssignmentPaySettingsTile from './EmployeeAccountAssignmentPaySettingsTile.vue'
import EmployeeAccountAssignmentPaySettingsColumns from './EmployeeAccountAssignmentPaySettingsColumns'
import EmployeeAccountAssignmentPaySettingsDetail from './EmployeeAccountAssignmentPaySettingsDetail.vue'
import EmployeeAccountAssignmentPaySettingsMetaCard from './EmployeeAccountAssignmentPaySettingsMetaCard.vue'
import EmployeeAccountAssignmentPaySettingsReference from './EmployeeAccountAssignmentPaySettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmployeeAccountAssignmentPaySettingsTile',
      EmployeeAccountAssignmentPaySettingsTile,
    )
    Vue.component(
      'EmployeeAccountAssignmentPaySettingsDetail',
      EmployeeAccountAssignmentPaySettingsDetail,
    )
    Vue.component(
      'EmployeeAccountAssignmentPaySettingsMetaCard',
      EmployeeAccountAssignmentPaySettingsMetaCard,
    )
    Vue.component(
      'EmployeeAccountAssignmentPaySettingsReference',
      EmployeeAccountAssignmentPaySettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentPaySettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeAccountAssignmentPaySettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentPaySettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentPaySettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentPaySettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
