<template>
  <tt-attr name="priority">
    <TChip
      attributeName="priority"
      class="dispatch-tasks--priority font-weight-bold text--darken-2 lighten-5 text-uppercase"
      :chip-class="chipClass"
    />
  </tt-attr>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getPriorityClass } from './utils'
import TChip from '@/tt-entity-design/src/components/TChip.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksPriorityChipAttr',
  components: {
    TChip,
  },
  inject: ['getItemHook'],
  computed: {
    priorityKey(): string {
      return this.getItemHook().getRawValue('priority')
    },
    chipClass(): string {
      return getPriorityClass(this.priorityKey)
    },
  },
  created() {
    this.getItemHook().addAttribute('priority')
  },
})
</script>

<style scoped>
.dispatch-tasks--priority {
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  line-height: 1.5;
  padding: 0 6px;
  pointer-events: none;
}
</style>
