import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'region',
  'startDate',
  'endDate',
  'adhocsIncluded',
  'applyToSubRegions',
] as ColumnInputDefinition[]

// #NOT_DONE# Make sure to remove the line below to prevent your file from being overwritten
