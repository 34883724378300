import type { FieldMeta } from '../type'
import type { FieldName } from '../base/types'

import { BooleanMeta } from './checkbox'
import { TextMeta } from './text'
import { NumberMeta } from './number'
import { TextareaMeta } from './textarea'
import { ListMeta } from './list'
import { DateMeta } from './date'
import { DateTimeMeta } from './datetime'
import { TimeMeta } from './time'
import { SignatureMeta } from './signature'
import { PicturenMeta } from './picture'

export * from './types'

/**
 * Dictionnary of details field object for each fields
 */
export const FieldMetaMap: Record<FieldName, FieldMeta> = {
  text: TextMeta,
  checkbox: BooleanMeta,
  number: NumberMeta,
  textarea: TextareaMeta,
  list: ListMeta,
  datetime: DateTimeMeta,
  date: DateMeta,
  time: TimeMeta,
  signature: SignatureMeta,
  picture: PicturenMeta,
}
