// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionPriceMatrixRulesTile from './PositionPriceMatrixRulesTile.vue'
import PositionPriceMatrixRulesColumns from './PositionPriceMatrixRulesColumns'
import PositionPriceMatrixRulesDetail from './PositionPriceMatrixRulesDetail.vue'
import PositionPriceMatrixRulesMetaCard from './PositionPriceMatrixRulesMetaCard.vue'
import PositionPriceMatrixRulesReference from './PositionPriceMatrixRulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionPriceMatrixRulesTile', PositionPriceMatrixRulesTile)
    Vue.component(
      'PositionPriceMatrixRulesDetail',
      PositionPriceMatrixRulesDetail,
    )
    Vue.component(
      'PositionPriceMatrixRulesMetaCard',
      PositionPriceMatrixRulesMetaCard,
    )
    Vue.component(
      'PositionPriceMatrixRulesReference',
      PositionPriceMatrixRulesReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_PRICE_MATRIX_RULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionPriceMatrixRulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PRICE_MATRIX_RULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionPriceMatrixRulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PRICE_MATRIX_RULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPriceMatrixRulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PRICE_MATRIX_RULES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPriceMatrixRulesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PRICE_MATRIX_RULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionPriceMatrixRulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
