import hook from './TQLQueryWidgetHook'
import template from './TQLQueryWidget.vue'
import TQLQueryWidgetEditor from './TQLQueryWidgetEditor.vue'
import TQLQueryExportOptionsInput from './TQLQueryExportOptionsInput.vue'
import Vue from 'vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

Vue.component('TQLQueryWidgetEditor', TQLQueryWidgetEditor)
Vue.component('TQLQueryExportOptionsInput', TQLQueryExportOptionsInput)

const TQLQueryWidget: WidgetTypeDefinition = {
  name: WidgetName.TQL_QUERY_WIDGET,
  schema,
  component: {
    template: template as any,
    hook,
  },
  editorDefinitions: {
    TQLQueryExportOptions: {
      view: {
        is: 'TQLQueryExportOptionsInput',
      },
    },
    TQLQueryWidget: {
      view: {
        is: 'TQLQueryWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-database-search',
    color: '#ff9800',
    print: true,
    thumbnail: require('./tql-query.png'),
  },
}

export default TQLQueryWidget
