<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="customId" value-css-class="color-pill" />
    <tt-attr-row name="taskType" />
    <tt-attr-row name="description" />
    <tt-attr-row name="instructions" />
    <tt-attr-row name="client" />
    <!-- Add priceTier back when product requests it (SEU-431) -->
    <!-- <tt-attr-row name="priceTier" /> -->
    <tt-attr-row name="position" />
    <tt-attr-row name="checkpointTour" />
    <tt-attr-row name="reportTemplate" />
    <tt-attr-row name="type" />
    <tt-attr-row name="beginServiceDate" />
    <tt-attr-row name="endServiceDate" />
    <tt-attr-row name="rangeStartTime" />
    <tt-attr-row name="rangeEndTime" />
    <tt-attr-row name="durationMinutes" />
    <tt-attr-row name="dayOfWeekStart" />
    <tt-attr-row name="dayOfWeekEnd" />
    <tt-attr-row name="frequency" />
    <tt-attr-row name="status" />
    <tt-attr-row name="taskSiteInstruction" />

    <ResourceAllowedOperations
      v-slot="{ allowsActions }"
      :resource-name="exceptionTypesResourceName"
    >
      <!--
        DO NOT REMOVE: the Exceptions tab depends on this attribute being
        present in the entity.
        @see ./custom.ts
      -->
      <tt-attr-row v-if="allowsActions" name="exceptionTypes" />
    </ResourceAllowedOperations>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskSchedulesDetail',
  inject: ['getItemHook'],
  computed: {
    exceptionTypesResourceName(): Resources {
      return Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES
    },
  },
})
</script>
