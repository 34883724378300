// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftTemplatesTile from './ShiftTemplatesTile.vue'
import ShiftTemplatesColumns from './ShiftTemplatesColumns'
import ShiftTemplatesDetail from './ShiftTemplatesDetail.vue'
import ShiftTemplatesMetaCard from './ShiftTemplatesMetaCard.vue'
import ShiftTemplatesReference from './ShiftTemplatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftTemplatesTile', ShiftTemplatesTile)
    Vue.component('ShiftTemplatesDetail', ShiftTemplatesDetail)
    Vue.component('ShiftTemplatesMetaCard', ShiftTemplatesMetaCard)
    Vue.component('ShiftTemplatesReference', ShiftTemplatesReference)

    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftTemplatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftTemplatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftTemplatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftTemplatesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftTemplatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
