<template>
  <div>
    <json-field
      :item-props="{ as: 'RelationTreeField', parentAttributeName: 'parent' }"
      name="exceptionTypes"
    />

    <json-field name="doNotPerform" />
    <json-field name="rangeStartTime" />
    <json-field name="rangeEndTime" />

    <div class="text-caption text-secondary">
      <v-icon
        color="level1Control"
        class="mr-2"
        small
        :style="{ marginTop: '-3px' }"
        v-text="'mdi-information-outline'"
      />
      <span
        v-text="
          $t('tt-entity-design.site-task-schedules.add-exception-types.info')
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'MobileSchedulesAddExceptionTypes',
})
</script>

<style scoped>
.text-secondary {
  color: var(--v-level1Control-base);
}
</style>
