<template>
  <div class="flex-container">
    <EntityPresetRelation
      v-if="!presetFields.length"
      v-show="false"
      :entity="mockEntity"
      :resource-name="resource"
      fields-collector
      @fields="setPresetFields"
    />

    <v-autocomplete
      v-else
      v-model="model"
      :attach="true"
      :no-data-text="$t(`common.no_results`)"
      :hide-no-data="effectiveLoading"
      :loading="effectiveLoading && 'yellow darken-2'"
      :allow-overflow="false"
      clearable
      persistent-hint
      no-filter
      small-chips
      outlined
      open-on-clear
      dense
      flat
      loader-height="3"
      max-width="100%"
      :menu-props="{
        closeOnContentClick: true,
        closeOnClick: true,
      }"
      :search-input="search"
      v-bind="{
        ...$attrs,
        errorMessages,
        placeholder,
        hint: placeholder,
        label,
        multiple,
        items: currentViewItems,
      }"
      v-on="$listeners"
      @click:clear="clearAndSearch"
      @focus="clearAndSearch"
      @update:search-input="setSearchAndDebounce"
    >
      <template #item="{ item, on }">
        <slot name="item" v-bind="{ item, on }">
          <v-list-item v-if="multiple" @change="on.click($event)">
            <v-list-item-action>
              <v-checkbox hide-details :input-value="isSelected(item.value)" />
            </v-list-item-action>
            <v-list-item-content>
              <EntityPresetRelation
                :resource-name="resource"
                :entity="item.entity"
              />
            </v-list-item-content>
          </v-list-item>

          <EntityPresetRelation
            v-else
            :resource-name="resource"
            :entity="item.entity"
          />
        </slot>
      </template>
      <template #selection="{ item }">
        <v-chip v-if="multiple" outlined>
          <EntityPresetRelation
            :resource-name="resource"
            :entity="item.entity"
          />
        </v-chip>

        <EntityPresetRelation
          v-else
          :resource-name="resource"
          :entity="item.entity"
        />
      </template>
      <template #append-item>
        <div v-if="hasToDisplayObserver" v-intersect.quiet="_debounceNextPage">
          <v-skeleton-loader type="list-item" />
        </div>
      </template>
    </v-autocomplete>

    <v-btn
      v-if="allowCreation"
      class="ml-2 mt-1"
      icon
      small
      text
      elevation="1"
      @click="add"
    >
      <v-icon v-text="`mdi-plus`" />
    </v-btn>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import QueryManager from '@/tt-widget-components/base/QueryManager'
import { CollectionQuery } from '@/tt-widget-components'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { DEFAULT_QUERY_LIMIT } from '@/helpers/constants'
import { ContextAttributeMap } from '@/tt-widget-components/base/contextAttributeMap'

type EntityID = number

interface Entity {
  id: EntityID
  [k: string]: unknown
}

export interface SelectItem {
  entity: Entity
  value: EntityID
  depth?: number
}

const createViewItem = (entity: Entity): SelectItem => ({
  entity,
  value: entity.id,
})

const createViewItems = (entities: Entity[]): SelectItem[] =>
  entities.map(createViewItem)

const handleError = (error) =>
  console.warn('Error while fetching entities...', error)

export default BaseInput.extend({
  name: 'EntitySelectorField',
  components: { EntityPresetRelation },
  props: {
    /**
     * Function that receives a list of entities and returns a list of options
     */
    createViewItems: {
      type: Function as PropType<(entities: Entity[]) => SelectItem[]>,
      // @ts-ignore -- VueJS wrongly types default for function props
      default: createViewItems as () => (entities: Entity[]) => SelectItem[],
    },
    /**
     * Extra fields to be included in the API request
     */
    extraFields: { type: Array as PropType<string[]>, default: () => [] },
    /**
     * Force loading state
     */
    loading: { type: Boolean, default: null },
    /**
     * Name of the resource to be fetched from the API
     */
    resource: { type: String, required: true },
    /**
     * Selected entity ID, or list of IDs (if `multiple` prop is `true`)
     */
    value: {
      type: [Number, String, Array] as PropType<number | string | string[]>, //@todo: add a wrapper for array
    },
    /**
     * Allow multiple options to be selected
     */
    multiple: { type: Boolean, default: false },
    /**
     * Query options to be added to the request
     */
    queryOptions: {
      type: Object as PropType<Partial<CollectionQuery>>,
      default: (): Partial<CollectionQuery> => ({}),
    },
    /**
     * Show UI to create new entities of the resource's type
     */
    allowCreation: { type: Boolean, default: false },
    modelContext: {
      type: Object as PropType<ContextAttributeMap>,
      default: () => ({}),
    },
  },
  data() {
    return {
      innerLoading: false,
      queryManager: null as QueryManager | null,
      searchViewItems: [] as SelectItem[],
      itemCount: null as number,
      /**
       * All items that have been fetched from the API, both by the search and the values requests.
       */
      allViewItems: [] as SelectItem[],
      /**
       * List of fields needed by the preset of the resource.
       *
       * Will be set by the `EntityPresetRelation` when mounted.
       */
      presetFields: [] as string[],
      /**
       * The current search sync with the `v-autocomplete`
       */
      search: '',
      /**
       * Cancels the request that is currently fetching the selected items
       */
      cancelFetch: null as (() => void) | null,
    }
  },
  computed: {
    selectedIds(): EntityID[] {
      const value = this.value
      const arrayValues = isArray(value) ? value : [value].filter((v) => v)

      return arrayValues.map((v) => Number(v))
    },
    selectedViewItems(): SelectItem[] {
      return this.allViewItems.filter((item) =>
        this.selectedIds.includes(item.value),
      )
    },
    missingSelectedViewItemIds(): EntityID[] {
      return this.selectedIds.filter(
        (id) => !this.allViewItems.find((item) => item.value === id),
      )
    },
    currentViewItems(): SelectItem[] {
      return uniqBy(
        [...this.searchViewItems, ...this.selectedViewItems],
        'value',
      )
    },
    effectiveLoading(): boolean {
      return !!(this.loading || this.innerLoading)
    },
    fields(): string[] {
      return uniq(['id', ...this.presetFields, ...this.extraFields])
    },
    _debounceSearch(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.searchItems()
      }, 250)
    },
    mockEntity(): Record<string, unknown> {
      return { id: 0 }
    },
    _debounceNextPage(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.nextPage()
      }, 250)
    },
    hasLoadedAllData(): boolean {
      return this.itemCount <= this.searchViewItems.length
    },
    hasToDisplayObserver(): boolean {
      return ![null, 0].includes(this.itemCount) && !this.hasLoadedAllData
    },
  },
  watch: {
    fields() {
      this.fetchValue()
    },
    value() {
      this.fetchValue()
    },
  },
  methods: {
    addToAllItems(items: SelectItem[]) {
      this.allViewItems = uniqBy([...this.allViewItems, ...items], 'value')
    },
    setSearchAndDebounce(search?: string) {
      const newSearch = search || ''
      if (newSearch === this.search) return
      this.search = newSearch
      this.cancelFetch?.()
      this.innerLoading = true
      this._debounceSearch()
    },
    clearAndSearch() {
      this.search = ''
      this.searchItems()
    },
    setPresetFields(fields: string[]) {
      this.presetFields = fields
      this.$emit('update:fields', this.fields)
    },
    isSelected(id: EntityID) {
      return this.selectedIds.includes(id)
    },
    async fetch(
      query: CollectionQuery,
      onSuccess: ({ items }: { items: Entity[] }) => void,
    ): Promise<void> {
      this.cancelFetch?.()
      this.innerLoading = true

      const cleanAll = () => {
        this.innerLoading = false
        this.cancelFetch = null
      }

      const request = this.createCancellableRequest(query)

      this.cancelFetch = () => request.cancel('Canceled by component')

      await request.run().then(onSuccess).catch(handleError).finally(cleanAll)
    },
    async searchItems(): Promise<void> {
      this._debounceSearch.cancel()
      this.searchViewItems = []
      this.setQueryManager()

      const onSuccess = ({
        items,
        total,
      }: {
        items: Entity[]
        total: number
      }) => {
        const viewItems = this.createViewItems(items)
        this.searchViewItems = viewItems
        this.addToAllItems(viewItems)
        this.itemCount = total
      }

      // Default to last search if none is provided
      this.queryManager.setSearch(this.search)

      await this.fetch(this.queryManager.query, onSuccess)
    },
    createCancellableRequest(query: CollectionQuery) {
      return this.$appContext.widgetServices.resourceDataManager.cancelableGetCollection(
        query,
      )
    },
    async fetchValue() {
      if (!this.missingSelectedViewItemIds.length) return

      const setItems = ({
        items,
        total,
      }: {
        items: Entity[]
        total: number
      }) => {
        this.addToAllItems(this.createViewItems(items))
        this.itemCount = total
      }
      this.setQueryManager()
      this.queryManager.setCustomFilters([
        {
          attribute: 'id',
          operator: FilterOperatorType.IN,
          value: this.missingSelectedViewItemIds,
        },
      ])

      await this.fetch(this.queryManager.query, setItems)
    },
    add() {
      const onSuccess = ({ id }) => {
        this.model = this.multiple ? [...this.model, id] : id
      }

      this.$appContext.eventManager.dispatchEvent(EntityIntentTypes.CREATE, {
        resourceName: this.resource,
        onSuccess,
      })
    },
    setQueryManager(): void {
      this.queryManager = new QueryManager(
        { ...this.queryOptions, resource: this.resource },
        this.$appContext.contextManager,
        {
          services: this.$appContext.widgetServices,
          contextAttributeMap: this.modelContext,
        },
      )
      this.queryManager.setLimit(DEFAULT_QUERY_LIMIT)
      this.queryManager.setFieldsAndExtensionsFromAttributes(this.fields)
    },
    async nextPage(): Promise<void> {
      if (this.innerLoading) return

      const currentOffset = this.queryManager.query.offset ?? 0
      const onSuccess = ({
        items,
        total,
      }: {
        items: Entity[]
        total: number
      }) => {
        const viewItems = this.createViewItems(items)
        this.searchViewItems = [...this.searchViewItems, ...viewItems]
        this.addToAllItems(viewItems)
        this.itemCount = total
      }

      this.queryManager.setOffset(currentOffset + DEFAULT_QUERY_LIMIT)

      await this.fetch(this.queryManager.query, onSuccess)
    },
  },
  beforeDestroy() {
    this.cancelFetch?.()
    this._debounceSearch.cancel()
  },
})
</script>

<style scoped>
.v-select__selection {
  display: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

/**
  * Hide the hint of the text field
  */

.v-autocomplete >>> .v-text-field__details {
  opacity: 0;
  transition: opacity 0.1s linear;
}

/**
  * Show the hint of the text field on hover
  */
.v-autocomplete >>> .v-input__control:hover > .v-text-field__details {
  opacity: 1 !important;
}
</style>
