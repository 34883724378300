import hook from './DashboardWidgetHook'
import template from './DashboardWidget.vue'
import schema from './schema'
import {
  WidgetEditorType,
  WidgetSchema,
  WidgetTypeDefinition,
} from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const DashboardWidget: WidgetTypeDefinition = {
  name: WidgetName.DASHBOARD_WIDGET,
  schema: schema as WidgetSchema,
  component: {
    template,
    hook,
  },
  config: {
    thumbnail: require('./data-table.png'),
    icon: 'dashboard',
    color: '#0050e1',
    layout: true,
    print: true,
    editor: WidgetEditorType.INLINE,
  },
}

export default DashboardWidget
