import Moment from 'moment-timezone'

/**
 * Return the absolute difference between two dates in minuts
 */
export const getDiffInMinuts = (dateA: string, dateB: string): number => {
  const diff = Moment(dateA).diff(Moment(dateB))

  return Math.abs(Moment.duration(diff).asMinutes())
}

/**
 * Return the absolute difference between now and a given date, in minuts
 */
export const getDiffInMinutsFromNow = (date: string): number => {
  const now = Moment().toString()

  return getDiffInMinuts(now, date)
}
