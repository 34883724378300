// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PrivacyPoliciesTile from './PrivacyPoliciesTile.vue'
import PrivacyPoliciesColumns from './PrivacyPoliciesColumns'
import PrivacyPoliciesDetail from './PrivacyPoliciesDetail.vue'
import PrivacyPoliciesMetaCard from './PrivacyPoliciesMetaCard.vue'
import PrivacyPoliciesReference from './PrivacyPoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PrivacyPoliciesTile', PrivacyPoliciesTile)
    Vue.component('PrivacyPoliciesDetail', PrivacyPoliciesDetail)
    Vue.component('PrivacyPoliciesMetaCard', PrivacyPoliciesMetaCard)
    Vue.component('PrivacyPoliciesReference', PrivacyPoliciesReference)

    modularManager.addResourcePreset(
      Resources.PRIVACY_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PrivacyPoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRIVACY_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PrivacyPoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRIVACY_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PrivacyPoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRIVACY_POLICIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PrivacyPoliciesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRIVACY_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PrivacyPoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
