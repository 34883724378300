import moment from 'moment'
import { Filter } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import {
  isValidDate,
  isValidDatetime,
  isValidIsoDatetime,
} from '@/helpers/dates/datetimeFormatValidator'
import { ApiDateTimeFormats } from '@/helpers/formats/dates/types'

import { isDatePreset } from '../date-presets'
import { extractTimezone } from '../timezone'
import { BETWEEN_SEPARATOR_TEMPORAL } from '../contants'
import { State } from './types'
import { Timezone } from '@/helpers/dates/timezones'

type ParserOutput = Partial<State>

const isValidIsoDatetimeRange = (from: string, to: string) =>
  isValidIsoDatetime(from) && isValidIsoDatetime(to)

const isValidDatetimeRange = (from: string, to: string) =>
  isValidDatetime(from) && isValidDatetime(to)

const isValidDateRange = (from: string, to: string) =>
  isValidDate(from) && isValidDate(to)

export const parseBetweenFilter = (
  datetime: string,
  timezone: Timezone | '',
): ParserOutput => {
  const [from, to] = datetime.split(BETWEEN_SEPARATOR_TEMPORAL)

  if (!from || !to) {
    console.warn('Invalid temporal filter BETWEEN', { datetime, timezone })

    return {}
  }

  // 2020-01-01 00:00:00
  if (isValidDatetimeRange(from, to)) {
    const momentFrom = moment(from, ApiDateTimeFormats.datetime, true)
    const momentTo = moment(to, ApiDateTimeFormats.datetime, true)

    return {
      fromDate: momentFrom.format(ApiDateTimeFormats.date),
      fromTime: momentFrom.format(ApiDateTimeFormats.time),
      toDate: momentTo.format(ApiDateTimeFormats.date),
      toTime: momentTo.format(ApiDateTimeFormats.time),
      timezone,
    }
  }

  // 2020-01-01
  if (isValidDateRange(from, to)) {
    return {
      fromDate: from,
      toDate: to,
      timezone,
    }
  }

  // 2020-01-01T00:00:00-04:00
  if (isValidIsoDatetimeRange(from, to)) {
    const momentFrom = moment(from, ApiDateTimeFormats.ISO_8601, true)
    const momentTo = moment(to, ApiDateTimeFormats.ISO_8601, true)

    return {
      fromDate: momentFrom.format(ApiDateTimeFormats.date),
      fromTime: momentFrom.format(ApiDateTimeFormats.time),
      toDate: momentTo.format(ApiDateTimeFormats.date),
      toTime: momentTo.format(ApiDateTimeFormats.time),
      timezone,
    }
  }

  console.warn('Invalid temporal filter BETWEEN', { datetime, timezone })

  return {}
}

const parseEqualFilter = (
  datetime: string,
  timezone: Timezone | '',
): ParserOutput => {
  if (isValidDatetime(datetime)) {
    const datetimeMoment = moment(datetime, ApiDateTimeFormats.datetime, true)

    return {
      fromDate: datetimeMoment.format(ApiDateTimeFormats.date),
      fromTime: datetimeMoment.format(ApiDateTimeFormats.time),
      timezone,
    }
  }

  if (isValidDate(datetime)) return { fromDate: datetime }

  console.warn('Invalid temporal filter EQUAL', { datetime, timezone })

  return {}
}

/**
 * Parse temporal filter information for TemporalFilterManager state
 */
export const parseTemporalFilter = (filter: Filter): ParserOutput => {
  const value = Array.isArray(filter.value)
    ? filter.value.join(BETWEEN_SEPARATOR_TEMPORAL)
    : filter.value

  if (typeof value !== 'string') {
    console.warn('Invalid temporal filter', filter)

    return {}
  }

  if (isDatePreset(value)) return { preset: value }

  const { datetime, timezone } = extractTimezone(value)

  if (
    filter.operator === FilterOperatorType.BEFORE ||
    filter.operator === FilterOperatorType.AFTER
  ) {
    const parsedFilter = parseEqualFilter(datetime, timezone)

    return { ...parsedFilter, operator: filter.operator }
  }

  if (!filter.operator || filter.operator === FilterOperatorType.EQUAL) {
    return parseEqualFilter(datetime, timezone)
  }

  if (filter.operator === FilterOperatorType.BETWEEN) {
    return parseBetweenFilter(datetime, timezone)
  }

  console.warn('Invalid temporal filter', filter)

  return {}
}
