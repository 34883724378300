import { FormatterCallbackInterface } from '@/helpers/formats/types'
import words from 'lodash/words'
import lowerCase from 'lodash/lowerCase'

export const asLowerCaseSentence: FormatterCallbackInterface = (value) => {
  return words(value.toString()).map(lowerCase).join(' ')
}

export default {
  asLowerCaseSentence,
}
