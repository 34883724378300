import { PluginFunction } from 'vue'

const SystemSettingsView = () =>
  import(
    /* webpackChunkName: "SystemSettingsView" */
    './views/SystemSettingsView.vue'
  )

export { SystemSettingsView }

export const SystemSettingsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('SystemSettingsView', SystemSettingsView)
}
