// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BreakRuleComplianceLogsTile from './BreakRuleComplianceLogsTile.vue'
import BreakRuleComplianceLogsColumns from './BreakRuleComplianceLogsColumns'
import BreakRuleComplianceLogsDetail from './BreakRuleComplianceLogsDetail.vue'
import BreakRuleComplianceLogsMetaCard from './BreakRuleComplianceLogsMetaCard.vue'
import BreakRuleComplianceLogsReference from './BreakRuleComplianceLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BreakRuleComplianceLogsTile', BreakRuleComplianceLogsTile)
    Vue.component(
      'BreakRuleComplianceLogsDetail',
      BreakRuleComplianceLogsDetail,
    )
    Vue.component(
      'BreakRuleComplianceLogsMetaCard',
      BreakRuleComplianceLogsMetaCard,
    )
    Vue.component(
      'BreakRuleComplianceLogsReference',
      BreakRuleComplianceLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.BREAK_RULE_COMPLIANCE_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BreakRuleComplianceLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_COMPLIANCE_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BreakRuleComplianceLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_COMPLIANCE_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleComplianceLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_COMPLIANCE_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleComplianceLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_COMPLIANCE_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BreakRuleComplianceLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
