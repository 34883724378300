import {
  Attribute,
  FieldTypes,
  RelationTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import { Resources } from '../tt-entity-design/src/types'

/**
 * Filters by name
 */
const filterResourceMap: {
  [resource: string]: string
} = {
  [Resources.REGIONS]: 'CustomFilterRegionRelation',
}

/**
 * Filters by type
 */
const filterTypeMap: {
  [type in FieldTypes]?: string
} = {
  // Specials
  [FieldTypes.Enum]: 'FilterEnum',
  [FieldTypes.Relation]: 'FilterRelation',
  // Temporal Fields
  [FieldTypes.Date]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.DateTime]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.TimeStampNumber]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.TimeStampDate]: 'FilterDateTimeAttributeAdapter',
  // Strings
  [FieldTypes.Label]: 'FilterString',
  [FieldTypes.PhoneNumber]: 'FilterString',
  [FieldTypes.Email]: 'FilterString',
  [FieldTypes.TextArea]: 'FilterString',
  [FieldTypes.CSV]: 'FilterString',
  // Numbers
  [FieldTypes.Integer]: 'FilterNumber',
  [FieldTypes.Number]: 'FilterNumber',
  [FieldTypes.CurrencyRate]: 'FilterNumber',
  [FieldTypes.Currency]: 'FilterNumber',
  [FieldTypes.Decimal]: 'FilterNumber',
  // Boolean
  [FieldTypes.Boolean]: 'FilterBoolean',
}

/**
 * Get the attribute filter component name by type
 * @param type
 */
export function getAttributeFilterComponentByType(
  type?: string,
): string | null {
  if (!type) {
    type = FieldTypes.Label
  }

  return filterTypeMap[type] ?? null
}

/**
 * Get the attribute filter component name
 * @param attribute
 */
export function getAttributeFilterComponent(
  attribute: Attribute,
): string | null {
  return (
    filterResourceMap[attribute.relation?.resource] ??
    getAttributeFilterComponentByType(attribute.type)
  )
}

/**
 * Checks if an attribute is filterable
 *
 * @param attributeMeta
 */
export function isAttributeFilterable(attributeMeta: Attribute) {
  const type = attributeMeta.type ?? FieldTypes.Label
  // Exclude has one relations as they are hidden IDS
  if (
    type === FieldTypes.Relation &&
    (attributeMeta.relation?.type === RelationTypes.HasOne ||
      attributeMeta.relation.embed)
  ) {
    return false
  }

  return Object.keys(filterTypeMap).includes(type)
}
