<template>
  <v-list class="navigation--container fill-height" nav dense flat>
    <v-list-item-group
      v-for="({ menuTitle, subMenus, key }, index) in menuItems"
      :key="index"
      no-action
    >
      <v-tooltip>
        <template #activator="{ on }">
          <h4 v-on="key === 'back-office-reports-menu' && on">
            {{ $t(menuTitle) }}
          </h4>
        </template>
        <span v-text="$t('back-office-reports.tooltip')" />
      </v-tooltip>
      <v-list-item-group
        v-for="{ subMenuTitle, tabs } in subMenus"
        :key="subMenuTitle"
        no-action
        class="ml-3"
      >
        <v-list-item-content class="pt-2 pb-2">
          <h5>{{ $t(subMenuTitle) }}</h5>
        </v-list-item-content>
        <v-list-item
          v-for="tab in tabs"
          :key="tab.title"
          :class="{ 'active-item': tab.key === activeTab.key }"
          dense
          @click="menuClick(tab)"
        >
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t(tab.title) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list-item-group>
  </v-list>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutComponents } from '@/tt-app-layout'
import { VueWithLayoutManager } from '@/tt-widget-views/types'

import {
  ReportingAndAnalyticsMenuItem,
  ReportingAndAnalyticsSubMenuTab,
} from '../types'

/**
 * @TODO:Update Nomenclature to Reporting & Analytics (BOSS-4284)
 */
export default (Vue as VueWithLayoutManager).extend({
  name: 'BackOfficeReportsLeftNavigationMenu',
  inject: ['layoutManager'],
  props: {
    menuItems: {
      type: Array as PropType<ReportingAndAnalyticsMenuItem[]>,
      required: true,
    },
    activeTab: {
      type: Object as PropType<ReportingAndAnalyticsSubMenuTab>,
      required: true,
    },
  },
  computed: {
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
  },
  methods: {
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
    menuClick(tab: ReportingAndAnalyticsSubMenuTab) {
      this.$emit('menu-click', tab)
      this.closeSidePanel()
    },
  },
})
</script>
<style scoped>
.navigation--container {
  min-width: 250px;
  width: auto;
  padding: 15px 15px 15px 0px;
  background-color: var(--v-muted-base);
}

.navigation--container >>> .v-list-item__title {
  padding: 5px 0;
}

.navigation--container >>> .v-list-item--dense {
  min-height: 25px;
}

.navigation--container >>> .v-list-item__content {
  padding: 4px 0;
}

.active-item {
  background-color: var(--v-muted-darken1);
}
</style>
