<template>
  <tt-attr :name="name">
    <template #default="{ value }">
      <div v-if="isAvatar">
        <v-avatar :color="backgroundColor(value)" size="60">
          <tt-attr :name="name" style="font-size: 10px" class="white--text" />
        </v-avatar>
      </div>
      <div v-else>
        <v-chip
          v-bind="{ ...style }"
          label
          text-color="white"
          style="max-width: 130px"
          :title="value"
          :color="backgroundColor(value)"
          v-text="truncateText(value)"
        />
      </div>
    </template>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import truncate from 'lodash/truncate'

enum TypeLayout {
  AVATAR = 'AVATAR',
  CHIP = 'CHIP',
}

export default Vue.extend({
  name: 'StatusColor',
  props: {
    name: {
      type: String,
      required: true,
    },
    layout: {
      type: String as PropType<TypeLayout>,
      required: true,
    },
    sizeProp: {
      type: String,
      default: 'x-small',
    },
  },
  computed: {
    isAvatar(): boolean {
      return this.layout === TypeLayout.AVATAR
    },
    style(): Record<string, true> {
      return { [this.sizeProp]: true }
    },
  },
  methods: {
    backgroundColor(status: string): string {
      switch (status?.toLowerCase()) {
        case 'completed':
        case 'active':
        case 'closed':
        case 'approved':
          return 'green'
        case 'missed':
        case 'new':
          return 'orange'
        case 'pending':
        case 'verification':
          return 'blue'
        default:
          return 'grey'
      }
    },
    truncateText(value: string): string {
      return truncate(value, { length: 18, omission: '...' })
    },
  },
})
</script>
