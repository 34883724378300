<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { createViewAction } from '../../tools/actions-generator'
import { Resources } from '../../types'
const { MOBILE_DEVICES, SYSTEM_EXCEPTION_TICKETS, DISPATCH_TASKS } = Resources
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import {
  ViewAction,
  VueWithInjections,
} from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { getEditFormState } from '../../tools/helpers/form-state-generator'
import {
  EntityActionIntentInterface,
  EntityEditIntentInterface,
} from '@/tt-widget-entity-flow'
import SystemExceptionTicketsFormPrefill from './SystemExceptionTicketsFormPrefill.vue'
import SystemExceptionTicketsTakeActions from './SystemExceptionTicketsTakeActions.vue'
import DispatchTasksForm from '../dispatch-tasks/form/DispatchTasksForm.vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { ASSIGN, LEAVE, TAKE, UN_ASSIGN, CHANGE_STATUS } from './constants'
import {
  isDispatchableSystemExceptionTickets,
  isPanicTriggeredTicket,
} from './utils'
import { CTAType, PanicTriggerCoordinates } from './type'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import { Routes } from '@/apps/app.tracktik.dispatch/enumRoutes'
import {
  DashboardToMapArrayPayload,
  FocusOnMapData,
} from '@/apps/app.tracktik.dispatch/browser-channel/types'
import { createBrowserChannel } from '@tracktik/tt-browser-channel'
// allow apps to override the action bar see FE-1626
import {
  BROWSER_CHANNEL_MAP,
  PREVIEW_ON_MAP,
} from '@/apps/app.tracktik.dispatch/browser-channel/constants'
import { CustomMobileDeviceResponse } from '../mobile-devices/types'

export default (Vue as VueWithInjections).extend({
  name: 'SystemExceptionTicketsActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  computed: {
    systemExceptionTicketsType(): CTAType {
      return this.getItemHook().getRawValue('type')
    },
    relationType(): string {
      return this.getItemHook().getRawValue('relation.type')
    },
    relationObjectId(): number {
      return this.getItemHook().getRawValue('relation.object')
    },
    isLinkedToDispatchTask(): boolean {
      return !!this.getItemHook().getRawValue('dispatchTask.id')
    },
    isDispatchTask(): boolean {
      return this.relationType === DISPATCH_TASKS
    },
    dispatchTaskEntityId(): number | null {
      return this.isLinkedToDispatchTask
        ? this.getItemHook().getRawValue('dispatchTask.id')
        : this.relationObjectId
    },
    assignedOperator(): number | null {
      return this.getItemHook().getRawValue('assignedOperator.id')
    },
    dispatchActionTitle(): string {
      if (!this.assignedOperator) {
        return this.$t(
          'tt-entity-design.system-exception-tickets.disabled-message',
        )
      }

      if (this.isDispatchTask || this.isLinkedToDispatchTask) {
        return this.$t('tt-entity-design.dispatch-tasks.editing-dispatch')
      } else {
        return this.$t('tt-entity-design.dispatch-tasks.new-dispatch')
      }
    },
    isDisabledDispatchAction(): boolean {
      if (!this.assignedOperator) {
        return true
      }

      return !isDispatchableSystemExceptionTickets(
        this.systemExceptionTicketsType,
      )
    },
    dispatchActionIcon(): string {
      return this.isDispatchTask || this.isLinkedToDispatchTask
        ? 'mdi-pencil-outline'
        : 'mdi-play-outline'
    },
    dispatchAction(): ViewAction {
      return {
        icon: this.dispatchActionIcon,
        title: this.dispatchActionTitle,
        onClick: async () => await this.openTasksForm(),
        disabled: this.isDisabledDispatchAction,
      }
    },
    panicTriggerCoordinates(): PanicTriggerCoordinates {
      return {
        lat: this.getItemHook().getRawValue('geoLocation.latitude'),
        long: this.getItemHook().getRawValue('geoLocation.longitude'),
      }
    },
    isShowOnMapActionDisabled(): boolean {
      return (
        !this.panicTriggerCoordinates.lat || !this.panicTriggerCoordinates.long
      )
    },
    showOnMapAction(): ViewAction {
      return {
        icon: 'mdi-map-marker',
        title: this.$t('tt-entity-design.common.show-on-map'),
        onClick: async () => {
          const browserChannel =
            createBrowserChannel<DashboardToMapArrayPayload>({
              channelName: BROWSER_CHANNEL_MAP,
            })

          const data: FocusOnMapData[] = await this.prepareDataToSend()

          await browserChannel?.send(PREVIEW_ON_MAP, data).catch((err) => {
            console.warn(
              "Failed to send message. Most probably you don't have the Command Center map opened in another tab.",
              err,
            )
            // if no map is opened, we open it in a new tab and pass data throw query params
            const encodedData = encodeURIComponent(JSON.stringify(data))
            window.open(`#${Routes.LIVE_MAP}?focusOnMapData=${encodedData}`)
          })

          browserChannel.destroy()
        },
        disabled: this.isShowOnMapActionDisabled,
      }
    },
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const options: Pick<EntityActionIntentInterface, 'formOptions'> = {
          formOptions: {
            fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
            requiredFieldLabelTransformer: null,
          },
        }
        const pageInterface = await this.getItemHook().getActionFormAsPage(
          actionName,
          options,
        )
        const payload =
          /**
           * If the action is 'take', we want to override the pageInterface with a different component
           */
          actionName === TAKE
            ? {
                ...pageInterface,
                is: SystemExceptionTicketsTakeActions,
              }
            : pageInterface

        this.activeWindow.next(payload)
      }

      return {
        [TAKE]: createViewAction(
          'mdi-account-check-outline',
          TAKE,
          SYSTEM_EXCEPTION_TICKETS,
          openFormAction,
        ),
        [ASSIGN]: createViewAction(
          'mdi-clipboard-account-outline',
          ASSIGN,
          SYSTEM_EXCEPTION_TICKETS,
          openFormAction,
        ),
        [UN_ASSIGN]: createViewAction(
          'mdi-cancel',
          UN_ASSIGN,
          SYSTEM_EXCEPTION_TICKETS,
          openFormAction,
        ),
        [LEAVE]: createViewAction(
          'mdi-logout',
          LEAVE,
          SYSTEM_EXCEPTION_TICKETS,
          openFormAction,
        ),
        [CHANGE_STATUS]: createViewAction(
          'mdi-circle-edit-outline',
          CHANGE_STATUS,
          SYSTEM_EXCEPTION_TICKETS,
          openFormAction,
        ),
      }
    },
    actionList(): ViewAction[] {
      const availableActions: ViewAction[] = this.actions
        .map((action) => {
          return (
            this.actionsDictionnary[action.actionName] || (null as ViewAction)
          )
        })
        .filter(Boolean)

      return isPanicTriggeredTicket(this.systemExceptionTicketsType)
        ? [this.dispatchAction, ...availableActions, this.showOnMapAction]
        : [this.dispatchAction, ...availableActions]
    },
    dispatchTaskItemHook(): EntityItemHook | null {
      if (this.isDispatchTask || this.isLinkedToDispatchTask) {
        return new EntityItemHook(this.$appContext, {
          resourceName: DISPATCH_TASKS,
          entityId: this.dispatchTaskEntityId,
        })
      }

      return null
    },
  },
  watch: {
    dispatchTaskItemHook(hook) {
      if (hook) {
        hook.addAttribute('assignedGroup.resourceType')
      }
    },
  },
  async created() {
    this.getItemHook().addAttribute('assignedOperator.id')
    this.getItemHook().addAttribute('relation')
    this.getItemHook().addAttribute('dispatchTask.id')
    // ORIGIN COORDINATES OF CTA
    this.getItemHook().addAttribute('geoLocation.latitude')
    this.getItemHook().addAttribute('geoLocation.longitude')
  },
  methods: {
    async editTaskForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: DISPATCH_TASKS,
        title: '', //We want to hide default title
        entityId: this.dispatchTaskEntityId,
        itemHook: this.dispatchTaskItemHook,
        formOptions: {
          fieldErrorRule: FieldErrorRule.ALWAYS,
        },
      }
      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: DispatchTasksForm,
        title: 'tt-entity-design.dispatch-tasks.editing-dispatch',
        props: { ...payload },
      })
    },
    prefillTaskForm(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: SystemExceptionTicketsFormPrefill,
        title: 'tt-entity-design.system-exception-tickets.form-title',
        props: {
          mobileDeviceId: this.relationObjectId,
          systemExceptionTicketsId: this.getItemHook().getRawValue('id'),
        },
      })
    },
    async openTasksForm(): Promise<void> {
      // If the system exception ticket is linked to a dispatch task, we want to edit the dispatch task
      if (this.isDispatchTask || this.isLinkedToDispatchTask) {
        await this.editTaskForm()
      } else {
        this.prefillTaskForm()
      }
    },
    async fetchMobileDeviceData(): Promise<CustomMobileDeviceResponse | null> {
      let mobileDeviceData: CustomMobileDeviceResponse | null = null
      // doing request on server as relation is a polymorph field
      // and could not fetch mobile-device coordinates
      // to investigate/fix reason why
      if (this.relationType === MOBILE_DEVICES) {
        mobileDeviceData = await this.$auth
          .getApi()
          .get(MOBILE_DEVICES, this.relationObjectId, {
            fields: [
              { attribute: 'id' },
              { attribute: 'lastLongitude' },
              { attribute: 'lastLatitude' },
            ],
          })
          .then(
            ({
              id,
              lastLongitude,
              lastLatitude,
            }: CustomMobileDeviceResponse) => ({
              id,
              lastLongitude,
              lastLatitude,
            }),
          )
      }

      return mobileDeviceData
    },
    async prepareDataToSend(): Promise<FocusOnMapData[]> {
      const mobileDevice = await this.fetchMobileDeviceData()
      const data = []
      if (
        mobileDevice &&
        mobileDevice.lastLatitude &&
        mobileDevice.lastLongitude
      ) {
        data.push({
          uid: MOBILE_DEVICES,
          entityId: mobileDevice.id,
          location: {
            lat: mobileDevice.lastLatitude,
            lng: mobileDevice.lastLongitude,
          },
        })
      }
      const originPanicTriggerLocation = {
        lat: this.getItemHook().getRawValue('geoLocation.latitude'),
        long: this.getItemHook().getRawValue('geoLocation.longitude'),
      }
      if (originPanicTriggerLocation.lat && originPanicTriggerLocation.long) {
        data.push({
          uid: SYSTEM_EXCEPTION_TICKETS,
          entityId: this.getItemHook().getEntityId(),
          location: {
            lat: originPanicTriggerLocation.lat,
            lng: originPanicTriggerLocation.long,
          },
        })
      }

      return data
    },
  },
})
</script>
