<template>
  <div style="clear: both">
    <v-subheader v-text="$t(`widget_edit.ColorCondition.when_value`)" />
    <div>
      <v-row>
        <v-col> <json-field name="operator" hide-details mt-0 /></v-col>
        <v-col> <json-field name="value" hide-details /></v-col>
      </v-row>
    </div>
    <v-divider />
    <v-subheader v-text="$t(`widget_edit.ColorCondition.then_format`)" />
    <div>
      <json-field name="color" :label="$t(`common.color`)" hide-details />
      <json-field name="flashing" as="VSwitch" />
    </div>
  </div>
</template>
<script lang="ts">
import { ObjectInput } from '@/tt-widget-components/ObjectInput'

export default ObjectInput.extend({
  name: 'ConditionalColorInput',
  computed: {
    defaultModel(): {} {
      return {
        operator: '>',
        value: 0,
        flashing: false,
      }
    },
  },
})
</script>
