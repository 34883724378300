<template>
  <div :id="`filterSearchInput-${uniqueId}`">
    <div small class="mx-2 search-toolbar level0 px-3">
      <input
        v-model="search"
        class="search-toolbar--input primary--text"
        :placeholder="$t('common.type_to_search')"
      />

      <v-btn
        v-if="search"
        x-small
        icon
        class="ml-0 ttPrimary muted--text"
        @click="clearSearch"
      >
        <v-icon x-small v-text="'close'" />
      </v-btn>
      <v-btn v-else x-small disabled icon class="ml-0">
        <v-icon x-small v-text="'search'" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import uniqueId from 'lodash/uniqueId'

import BaseQueryManager from '@/tt-widget-components/base/BaseQueryManager'
import { SearchInWidget } from '@/plugins/o11n'
import { DebouncedFunc, debounce } from 'lodash'
import { INPUT_DEBOUNCE_TIME } from '@/helpers/constants'
type DebouncedSearchFunctionType = DebouncedFunc<(value: string) => void>

export default Vue.extend({
  name: 'EntityToolbarSearch',
  props: {
    queryManager: {
      type: Object as PropType<BaseQueryManager>,
      required: true,
    },
  },
  computed: {
    uniqueId: (): string => uniqueId(),
    search: {
      get(): string {
        return this.queryManager.search
      },
      set(value: string): void {
        this.debounceSearch(value)
      },
    },
    debounceSearch(): DebouncedSearchFunctionType {
      return debounce((value: string) => {
        this.setSearch(value)
      }, INPUT_DEBOUNCE_TIME)
    },
  },
  methods: {
    setSearch(value: string): void {
      this.trackSearchInEvent(value)
      this.queryManager.setSearch(value)
      this.$emit('update', value)
    },
    clearSearch(): void {
      this.setSearch('')
    },
    trackSearchInEvent(search: string): void {
      const event = SearchInWidget.create({ label: search })
      this.$analytics.track(event)
    },
  },
})
</script>

<style scoped>
.search-toolbar {
  height: 28px;
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-toolbar:hover {
  background: var(--v-level0-base) !important;
}

.search-toolbar--input {
  font-size: 12px;
  padding: 5px;
  outline: none;
}
</style>
