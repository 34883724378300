import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { FieldTypes } from '../services'
import { ResourceMetaManagerInterface } from '../types'

export type ValueParser = (value: any) => any
export type ValueParsers = Partial<Record<FieldTypes, ValueParser>>

/**
 * Loop through the resource's attributes and apply the appropriate value
 * parser for each one according to their type in the resource's schema
 */
const parseResourceAttributes = (
  resourceMetaManager: ResourceMetaManagerInterface,
  resourceName: string,
  data: Record<string, any>,
  valueParsers: ValueParsers,
) => {
  const getAttributeType = (attrName: string) => {
    const attr = resourceMetaManager.getAttribute(resourceName, attrName)
    return attr?.type ?? 'Label'
  }

  const parsedEntries = Object.entries(data).map(([attrName, value]) => {
    const attrType = getAttributeType(attrName)
    const valueParser = valueParsers[attrType]
    const parsedValue = valueParser ? valueParser(value) : value
    return [attrName, parsedValue]
  })

  return Object.fromEntries(parsedEntries)
}

/**
 * Remove time and timezone information from the provided date
 *
 * Supported input formats:
 *  - 2015-10-08T00:00:00+00:00
 *  - 2015-10-08T00:00:00.000Z
 *
 * Output format: 2015-10-08
 */
export const dateWithoutTimeAndTimezone: ValueParser = <T>(
  value: T,
): string | T => {
  if (typeof value !== 'string') return value
  return value.match(/\d{4}-\d{1,2}-\d{1,2}/)?.[0] ?? value
}

/**
 * Returns the resource data with all attributes marked as type `Date` (not
 * `DateTime`) in their schema formatted to the YYYY-MM-DD format, disregarding
 * their time and timezone information completely.
 */
export const parseResourceDates = (
  resourceMetaManager: ResourceMetaManagerInterface,
  resourceName: string,
  data: Record<string, any>,
): Record<string, any> => {
  const valueParsers = {
    [FieldTypes.Date]: dateWithoutTimeAndTimezone,
  }

  return parseResourceAttributes(
    resourceMetaManager,
    resourceName,
    data,
    valueParsers,
  )
}
