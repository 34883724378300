import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { ListWidgetModel } from '@/tt-widget-components'

import MobileSchedulesAddExceptionTypes from './MobileSchedulesAddExceptionTypes.vue'
import MobileSchedulesCreateForm from './MobileSchedulesCreateForm.vue'
import { Resources } from '../../types'
import { ActionFormTypes } from '@/tt-app-modular'

export default {
  install(Vue) {
    Vue.component(
      'MobileSchedulesAddExceptionTypes',
      MobileSchedulesAddExceptionTypes,
    )

    Vue.component('MobileSchedulesCreateForm', MobileSchedulesCreateForm)

    registerResourceForm(
      Resources.MOBILE_SCHEDULES,
      'MobileSchedulesCreateForm',
      ActionFormTypes.FULL_ENTITY,
    )

    registerResourceForm(
      Resources.MOBILE_SCHEDULES,
      'MobileSchedulesAddExceptionTypes',
      'add-exception-types',
    )

    const exceptionsListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
      },
    }
    addPreviewTab(Resources.MOBILE_SCHEDULES, {
      title: 'tt-entity-design.mobile-schedules.preview.tab-exceptions',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'mobileSchedule',
        widget: exceptionsListWidget,
        widgetProps: {
          onSelect: () => {}, // Do nothing
        },
      },
      /**
       * If the user can perform actions on exception types, the Details preset
       * includes the `exceptionTypes` field in the request.
       * If the field has value (empty or not), show tab. If it's nil, hide it.
       */
      condition: ({ itemHook }) => itemHook.get('exceptionTypes') != null,
    })
  },
}
