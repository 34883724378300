import kebabCase from 'lodash/kebabCase'
import Vue from 'vue'

type OptOutItem = (componentName: string) => boolean

const DATA_ATTR_PREFIX = 'data-tt'

/**
 * Be conscious that every function in this list is going to be executed for
 * every component being rendered.
 *
 * The whole goal is to avoid unnecessary processing of third-party components
 * for performance reasons, but if identifying said component is more costly
 * then actually processing them, it's not worth adding them to the opt-out
 * list.
 */
const OPT_OUT_LIST: OptOutItem[] = [
  /**
   * Vue components
   */
  (componentName) => 'transition' === componentName,
  /**
   * Vuetify components
   */
  (componentName) =>
    componentName.startsWith('v-') || /^V[A-Z]/.test(componentName),
  /**
   * SyncFusion components: No need to opt out. Only the top-level component is
   * rendered through VueJS.
   */
]

const isOptOut = (componentName: string): boolean =>
  OPT_OUT_LIST.some((fn) => fn(componentName))

/**
 * This mixin is used to add a data-tt attribute to the root element of a component.
 * The attribute is the name of the component in kebab-case.
 * - if the component being rendered isn't an HTML element, then we skip it
 * - if the component matches an item in the opt-out list, then we skip it
 * - if the component name is already in kebab-case, then we use it
 */
const TTDataAttrMixin = Vue.extend({
  mounted() {
    const componentName = this.$options.name
    const isHtmlElement = this.$el instanceof HTMLElement

    if (!isHtmlElement || !componentName || isOptOut(componentName)) return

    const kebabCaseName = kebabCase(componentName)

    // if the kebab-case name is different from the component name, then we use the transformed name
    if (kebabCaseName !== componentName.toLowerCase()) {
      this.$el.setAttribute(DATA_ATTR_PREFIX, kebabCaseName)
    } else if (!componentName.includes('-')) {
      this.$el.setAttribute(DATA_ATTR_PREFIX, componentName)
    }
  },
})

export default TTDataAttrMixin
