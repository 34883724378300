// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveBalancesTile from './LeaveBalancesTile.vue'
import LeaveBalancesColumns from './LeaveBalancesColumns'
import LeaveBalancesDetail from './LeaveBalancesDetail.vue'
import LeaveBalancesReference from './LeaveBalancesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeaveBalancesTile', LeaveBalancesTile)
    Vue.component('LeaveBalancesDetail', LeaveBalancesDetail)
    Vue.component('LeaveBalancesReference', LeaveBalancesReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_BALANCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveBalancesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_BALANCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveBalancesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_BALANCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveBalancesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_BALANCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveBalancesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
