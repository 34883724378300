<template>
  <tt-attr-row-card v-bind="$props">
    <TextAreaAttr
      name="description"
      :no-value-placeholder="noInstructionsLabel"
      class="pt-3 mb-4"
    />
    <tt-if-attr name="client.id" not :value="[null, undefined]">
      <tt-attr-row name="client" />
    </tt-if-attr>
    <tt-if-attr name="region.id" not :value="[null, undefined]">
      <tt-attr-row name="region" />
      <tt-attr-row name="isAvailableInSubRegion">
        <ChipLabelBoolean attributeName="isAvailableInSubRegion" />
      </tt-attr-row>
    </tt-if-attr>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'
import TextAreaAttr from '../TextAreaAttr.vue'

export default Vue.extend({
  name: 'SystemExceptionTicketInstructionsDetail',
  components: {
    TextAreaAttr,
  },
  computed: {
    noInstructionsLabel(): string {
      return this.$t(this.translationKey('no-instructions'))
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.system-exception-tickets.instructions.${key}`
    },
  },
})
</script>
