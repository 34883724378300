// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RevenueProcessorOccurrencesTile from './RevenueProcessorOccurrencesTile.vue'
import RevenueProcessorOccurrencesColumns from './RevenueProcessorOccurrencesColumns'
import RevenueProcessorOccurrencesDetail from './RevenueProcessorOccurrencesDetail.vue'
import RevenueProcessorOccurrencesMetaCard from './RevenueProcessorOccurrencesMetaCard.vue'
import RevenueProcessorOccurrencesReference from './RevenueProcessorOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'RevenueProcessorOccurrencesTile',
      RevenueProcessorOccurrencesTile,
    )
    Vue.component(
      'RevenueProcessorOccurrencesDetail',
      RevenueProcessorOccurrencesDetail,
    )
    Vue.component(
      'RevenueProcessorOccurrencesMetaCard',
      RevenueProcessorOccurrencesMetaCard,
    )
    Vue.component(
      'RevenueProcessorOccurrencesReference',
      RevenueProcessorOccurrencesReference,
    )

    modularManager.addResourcePreset(
      Resources.REVENUE_PROCESSOR_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'RevenueProcessorOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REVENUE_PROCESSOR_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: RevenueProcessorOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REVENUE_PROCESSOR_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RevenueProcessorOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REVENUE_PROCESSOR_OCCURRENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'RevenueProcessorOccurrencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REVENUE_PROCESSOR_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RevenueProcessorOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
