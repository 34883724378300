<template>
  <div>
    <slot v-bind="size" />
  </div>
</template>

<script lang="ts">
import type { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import { createNewPayload } from './ResizeObserver'

/**
 * Wrapper component to observe the size of its content.
 * You can get the `ResizeEventPayload` using its slots, or by listening to `@resize`.
 */
export default Vue.extend({
  name: 'ResizeObserver',
  data() {
    return {
      resizeObserver: null as ResizeObserver,
      size: createNewPayload(),
    }
  },
  computed: {
    debounceCalculation(): DebouncedFunc<any> {
      return debounce(
        (entry: ResizeObserverEntry) => this.getNewSizeAndEmit(entry),
        100,
      )
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.debounceCalculation(entries[0])
    })
    this.resizeObserver.observe(this.$el)
  },
  activated() {
    this.resizeObserver.observe(this.$el)
  },
  deactivated() {
    this.resizeObserver.disconnect()
  },
  beforeDestroy() {
    this.resizeObserver.disconnect()
  },
  methods: {
    getNewSizeAndEmit(entry: ResizeObserverEntry) {
      const clientWidth = entry.contentRect.width
      const clientHeight = entry.contentRect.height

      /**
       * When `display: none` the element is hidden,
       * no need to send an empty size.
       */
      const isBoxHidden = !clientWidth && !clientHeight
      const isSameSize =
        clientWidth === this.size.clientWidth &&
        clientHeight === this.size.clientHeight

      if (isBoxHidden || isSameSize) return

      this.size = {
        width: `${clientWidth}px`,
        height: `${clientHeight}px`,
        clientWidth,
        clientHeight,
      }

      this.$emit('resize', this.size)
    },
  },
})
</script>
