<template>
  <v-card flat>
    <WidgetFactory
      class="h-100 timeline--widget"
      :widget="widget"
      skip-validation
      @update:hook="$emit('update:hook', $event)"
    />
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ListWidgetModel } from '@/tt-widget-components/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { Attribute } from '@/tt-entity-design/src/schema-types'

import MobileScheduleOccurrenceTimelineTile from './MobileScheduleOccurrenceTimelineTile.vue'
import { MOBILE_SCHEDULE_OCCURRENCES } from './constants'
import { MOBILE_RUNSHEET_OCCURRENCES } from '../mobile-runsheet-occurrences/constants'

type Attr = Attribute<typeof MOBILE_SCHEDULE_OCCURRENCES>

const sortOrderAttr: Attr = 'mobileRunsheetSortOrder'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleOccurrenceTimeline',
  props: {
    mobileRunsheetOccurrenceId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    widget(): ListWidgetModel {
      return {
        is: WidgetName.LIST_WIDGET,
        title: '',
        listItem: {
          // @ts-ignore
          is: MobileScheduleOccurrenceTimelineTile,
        },
        query: {
          resource: MOBILE_SCHEDULE_OCCURRENCES,
          relationListResource: {
            attribute: 'mobileScheduleOccurrences',
            id: this.mobileRunsheetOccurrenceId,
            resource: MOBILE_RUNSHEET_OCCURRENCES,
          },
          fields: [
            {
              attribute: 'client.address.latitude',
            },
            {
              attribute: 'client.address.longitude',
            },
          ],
          sort: [
            {
              attribute: sortOrderAttr,
              direction: SortDirectionType.ASC,
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
})
</script>

<style scoped>
/* removes border added by List Widget */
.timeline--widget >>> .list-item-div {
  border: 0 !important;
}

/* removes the separator */
.timeline--widget >>> .list-item-div > hr {
  display: none !important;
}

/* hide widget title at the top */
.timeline--widget >>> .widget-title {
  display: none !important;
}
</style>
