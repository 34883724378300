<template>
  <div class="exception-type-groups-status">
    <TChipLabel v-if="status" v-bind="chipProps" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { TChipLabelType } from '@/tt-ui/types'

import { ExceptionTypeGroupsStatusColor, Status } from './types'
import { getExceptionTypeGroupStatusColor } from './helpers'
import { VueWithItemHookInjection } from '@/helpers/components/types'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'ExceptionTypeGroupsStatus',
  inject: ['getItemHook'],
  computed: {
    statusColor(): ExceptionTypeGroupsStatusColor | null {
      return getExceptionTypeGroupStatusColor(this.status)
    },
    status(): Status | null {
      return this.getItemHook()?.get('status')?.toUpperCase() ?? null
    },
    chipProps(): Record<string, unknown> {
      return {
        ...this.statusColor,
        modifiers: {
          style: { fontWeight: 'normal', textTransform: 'none' },
        },
        textKey: this.status,
        type: TChipLabelType.Pill,
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
})
</script>
