import i18n from '@/plugins/i18n'
import {
  Attribute,
  FieldTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { isNullOperatorType } from '@/tt-entity-filter/util'
import { isEmpty } from '@/helpers/isEmpty'
import { FormatterOutput } from '@/helpers/formats/types'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Filter } from '@/tt-widget-components'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import { formatTemporalFilter } from './temporal-filters/formatTemporalFilter'
import { isTemporalFieldType } from './temporal-filters/field-types-validator'

const isEnumOrBoolean = (attributeMeta: Attribute) =>
  [FieldTypes.Boolean, FieldTypes.Enum].includes(attributeMeta.type)

/**
 * Filter value parser
 * Get's the best output.
 */
export default class FilterValueParser {
  static getFilterValue(
    attributeMeta: Attribute,
    filter?: Filter,
    userPreference?: UserPreferenceInterface,
  ) {
    if (isNullOperatorType(filter?.operator)) {
      return filter.operator === FilterOperatorType.ISNULL
        ? i18n.tc('common.is_null')
        : i18n.tc('common.is_not_null')
    }

    if (!this.isValueDisplayable(filter)) {
      return null
    }

    const value = filter.value.toString()
    const valueCount = value.split(',').length

    if (isTemporalFieldType(attributeMeta.type))
      return formatTemporalFilter(filter, attributeMeta.type, userPreference)

    const parseEqualFilter = () => {
      if (isEnumOrBoolean(attributeMeta)) {
        const key = createI18nEnumKey(
          attributeMeta.resource,
          attributeMeta.name,
          String(filter.value),
        )
        return i18n.tc(key)
      }

      return filter.value as string
    }

    const normalizedValueForBetweenFilter = (
      filterValue: string | string[],
    ): FormatterOutput[] => {
      if (Array.isArray(filterValue)) return filterValue

      const value = filterValue.toString()
      const splitValue = value.includes(', ')
        ? value.split(', ')
        : value.split('|')

      return splitValue
    }

    const parseBetweenFilter = () => {
      if (isEmpty(filter.value)) {
        return null
      }

      return normalizedValueForBetweenFilter(
        filter.value as string | string[],
      ).join('<>')
    }

    const filterOperatorValue = {
      [FilterOperatorType.EQUAL]: parseEqualFilter(),
      [FilterOperatorType.STARTSWITH]: value,
      [FilterOperatorType.ENDSWITH]: value,
      [FilterOperatorType.CONTAINS]: value,
      [FilterOperatorType.BETWEEN]: parseBetweenFilter(),
      [FilterOperatorType.IN]: `(${valueCount})`,
      [FilterOperatorType.GT]: `>${value}`,
      [FilterOperatorType.GTE]: `>=${value}`,
      [FilterOperatorType.LT]: `<${value}`,
      [FilterOperatorType.LTE]: `<=${value}`,
    }

    return filterOperatorValue[filter.operator]
  }

  private static isValueDisplayable(filter: Filter) {
    return filter && !isEmpty(filter.value)
  }
}
