<template>
  <div>
    <json-field name="name" />

    <json-field name="description" />

    <v-row no-gutters>
      <v-col class="mr-3" cols="3">
        <json-field name="customId" />
      </v-col>
      <v-col cols="8">
        <json-field name="parentCategory" />
      </v-col>
    </v-row>

    <AccountContextJsonField name="account" />
    <v-row no-gutters>
      <v-col class="mr-3">
        <json-field name="icon" as="IconSelector" />
      </v-col>
      <v-col>
        <json-field name="color" as="ColorSelector" />
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetCategoriesForm',
})
</script>
