<template>
  <tt-attr-action action="export-to-asset">
    <AssetContactsImportBtn />
  </tt-attr-action>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetContactActions',
})
</script>
