<template>
  <div v-if="resource && hasCustomFilters">
    <CollapsibleHeader>
      <template #title>
        <span class="pl-4 pr-6">
          {{ $t('components.custom_filter_input.custom_filters') }}
          <v-badge v-if="hasFilters" dot inline />
        </span>
      </template>
      <v-card flat class="pa-4">
        <v-autocomplete
          v-if="items.length"
          :value="filterSelection"
          v-bind="$attrs"
          prepend-icon="add"
          :disabled="!resource"
          outlined
          dense
          :label="$t('components.custom_filter_input.select_available_filters')"
          :items="items"
          :placeholder="placeholder"
          @input="addFilter"
        />
        <div>
          <template v-if="hasFilters">
            <div
              v-for="{ filterName, value } in value"
              :key="filterName"
              class="level1 pl-2 pr-2"
            >
              <EntityToolbarCustomFilterCollapsibleSection
                :resource="resource"
                :filter-name="filterName"
                :value="value"
                opened
                @input="setFilter(filterName, $event)"
                @delete="removeFilter(filterName)"
              >
                <template #right>
                  <v-tooltip top>
                    {{ $t('components.attribute_filter_input.delete_filter') }}
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click.stop="removeFilter(filterName)"
                      >
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </EntityToolbarCustomFilterCollapsibleSection>
            </div>
          </template>

          <template v-else>
            <CaptionBox
              v-text="$t(`components.custom_filter_input.no_filters_selected`)"
            />
          </template>
        </div>
      </v-card>
    </CollapsibleHeader>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { cloneDeep, isEmpty } from 'lodash'
import { CustomFilterMeta } from '@/tt-widget-factory/services/resource-meta/types'
import { CustomFilter } from '@/tt-widget-factory/definitions'
import { ResourceProvider } from '../index'
import EntityToolbarCustomFilterCollapsibleSection from './widget-form/query-settings/EntityToolbarCustomFilterCollapsibleSection.vue'

type MetaEntries = [string, CustomFilterMeta]

export default (Vue as VueConstructor<Vue & ResourceProvider>).extend({
  name: 'CustomFilterInput',
  components: {
    EntityToolbarCustomFilterCollapsibleSection,
  },
  inject: ['resourceProvider'],
  inheritAttrs: false,
  props: {
    value: {
      type: Array as PropType<CustomFilter[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterSelection: null,
    }
  },
  computed: {
    items(): { text: string; value: string }[] {
      const filters = this.value

      const isNotAlreadySelected = ([filterName]: MetaEntries) =>
        !filters.some((filter) => filter.filterName === filterName)

      const createItem = ([filterName, filterMeta]: MetaEntries): {
        text: string
        value: string
      } => ({
        text: filterMeta.labels?.label ?? '',
        value: filterName,
      })

      return Object.entries(this.customFilters)
        .filter(isNotAlreadySelected)
        .map(createItem)
    },
    customFilters(): Record<string, CustomFilterMeta> {
      return this.$appContext.widgetServices.resourceMetaManager.getCustomFilters(
        this.resource,
      )
    },
    hasCustomFilters(): boolean {
      return !isEmpty(this.customFilters)
    },
    hasFilters(): boolean {
      return !!this.value?.length
    },
    resource(): string {
      return this.resourceProvider.resource
    },
  },
  methods: {
    removeFilter(filterName: string) {
      const isNotFilterToRemove = (item) => item.filterName !== filterName

      const filtered = cloneDeep(this.value).filter(isNotFilterToRemove)

      this.$emit('input', filtered)
    },
    async addFilter(filterName: string) {
      this.filterSelection = filterName
      const newFilter: CustomFilter = { filterName, value: null }
      this.$emit('input', [...this.value, newFilter])

      await Vue.nextTick()
      this.filterSelection = null
    },
    setFilter(filterName: string, value: string) {
      const model: CustomFilter[] = cloneDeep(this.value)

      const byFilterName = (filter) => filter.filterName === filterName
      const index = model.findIndex(byFilterName)

      model[index] = { filterName, value }

      this.$emit('input', model)
    },
  },
})
</script>
