// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskTypesTile from './TaskTypesTile.vue'
import TaskTypesColumns from './TaskTypesColumns'
import TaskTypesDetail from './TaskTypesDetail.vue'
import TaskTypesMetaCard from './TaskTypesMetaCard.vue'
import TaskTypesReference from './TaskTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaskTypesTile', TaskTypesTile)
    Vue.component('TaskTypesDetail', TaskTypesDetail)
    Vue.component('TaskTypesMetaCard', TaskTypesMetaCard)
    Vue.component('TaskTypesReference', TaskTypesReference)

    modularManager.addResourcePreset(
      Resources.TASK_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaskTypesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
