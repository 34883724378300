import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import schema from './schema.json'
import { TEXTAREA, TextareaFieldType } from './types'
import TextareaField from './TextareaField.vue'

const ui: FieldUI = {
  name: i18nFieldKey(TEXTAREA),
  icon: 'mdi-text',
  color: 'red',
}

// @ts-ignore -- make FormOptions accept component definition in addition of component name as string
const converter: FieldConverter = (field: TextareaFieldType) => ({
  getJsonSchema: () => {
    return { ...getBaseFieldJsonSchema(field), type: 'string' }
  },
  getViewOption: () => {
    return {
      is: TextareaField,
    }
  },
})

export const TextareaMeta: FieldMeta = {
  field: TEXTAREA,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
