import { LeavePolicyItemsComponents } from '@/tt-entity-design/src/components/leave-policy-items/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { EmployeeCustomComponenets, LeaveRequestsStatus } from '../../types'

const baseTranslation = 'tt-entity-design.leave-management.employee.overview'

const EmployeeOverviewCustomColumns: ColumnInputDefinition[] = [
  'leaveType',
  {
    attributeName: 'accrualRate',
    headerText:
      'tt-entity-design.leave-management.leave-policy-items.is-accrued',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsAccruedColumn,
    },
  },
  'entitlementUnit',
  {
    headerText: `${baseTranslation}.total`,
    name: 'extensions.balance.total',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsTotalColumn,
    },
  },
  {
    headerText: `${baseTranslation}.used`,
    name: 'extensions.balance.used',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsValueField,
      props: {
        entitlementValueName: 'extensions.balance.used',
        accentColor: '',
      },
    },
  },
  {
    headerText: `${baseTranslation}.available`,
    name: 'extensions.balance.available',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsValueField,
      props: {
        entitlementValueName: 'extensions.balance.available',
        accentColor: 'indigo--text font-weight-bold',
      },
    },
  },
  {
    headerText: `${baseTranslation}.pending.title`,
    component: {
      is: EmployeeCustomComponenets.OverviewRequestsButtons,
      props: {
        requestsStatus: LeaveRequestsStatus.PENDING,
      },
    },
  },
  {
    headerText: `${baseTranslation}.pending_cancellation.title`,
    component: {
      is: EmployeeCustomComponenets.OverviewRequestsButtons,
      props: {
        requestsStatus: LeaveRequestsStatus.PENDING_CANCELLATION,
      },
    },
  },
  {
    headerText: `${baseTranslation}.approved.title`,
    component: {
      is: EmployeeCustomComponenets.OverviewRequestsButtons,
      props: {
        requestsStatus: LeaveRequestsStatus.APPROVED,
      },
    },
  },
]

export default EmployeeOverviewCustomColumns
