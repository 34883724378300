import { PluginFunction } from 'vue'

const CalendarExceptionsCustomDetail = () =>
  import(
    /* webpackChunkName: "CalendarExceptionsCustomDetail" */
    './components/CalendarExceptionsCustomDetail.vue'
  )
const DataTableSelectorField = () =>
  import(
    /* webpackChunkName: "DataTableSelectorField" */
    '../components/DataTableSelectorField.vue'
  )
const ExceptionTypesPublishToAccountsAction = () =>
  import(
    /* webpackChunkName: "ExceptionTypesPublishToAccountsAction" */
    './components/ExceptionTypesPublishToAccountsAction.vue'
  )
const ExceptionTypesView = () =>
  import(
    /* webpackChunkName: "ExceptionTypesView" */
    './views/ExceptionTypesView.vue'
  )
const MobileSchedulesAddExceptionsView = () =>
  import(
    /* webpackChunkName: "MobileSchedulesAddExceptionsView" */
    './views/MobileSchedulesAddExceptionsView.vue'
  )
const MobileSchedulesPreviewView = () =>
  import(
    /* webpackChunkName: "MobileSchedulesPreviewView" */
    './views/MobileSchedulesPreviewView.vue'
  )
const RunsheetAddExceptionView = () =>
  import(
    /* webpackChunkName: "RunsheetAddExceptionView" */
    './views/RunsheetAddExceptionView.vue'
  )
const RunsheetsPreviewView = () =>
  import(
    /* webpackChunkName: "RunsheetsPreviewView" */
    './views/RunsheetsPreviewView.vue'
  )
const SiteCalendarExceptionsView = () =>
  import(
    /* webpackChunkName: "SiteCalendarExceptionsView" */
    './views/SiteCalendarExceptionsView.vue'
  )
const ZoneCalendarExceptionsView = () =>
  import(
    /* webpackChunkName: "ZoneCalendarExceptionsView" */
    './views/ZoneCalendarExceptionsView.vue'
  )

const ExceptionTypeGroupsView = () =>
  import(
    /* webpackChunkName: "ExceptionTypeGroupsView" */
    './views/ExceptionTypeGroupsView.vue'
  )

const ExceptionGroupsPage = () =>
  import(
    /* webpackChunkName: "ExceptionGroupsPage" */
    './pages/ExceptionGroupsPage.vue'
  )

const ExceptionGroupsWizardPage = () =>
  import(
    /* webpackChunkName: "ExceptionGroupsWizardPage" */
    './pages/ExceptionGroupsWizardPage.vue'
  )

export {
  ExceptionTypesView,
  MobileSchedulesAddExceptionsView,
  MobileSchedulesPreviewView,
  RunsheetAddExceptionView,
  RunsheetsPreviewView,
  SiteCalendarExceptionsView,
  ZoneCalendarExceptionsView,
  ExceptionTypeGroupsView,
  ExceptionGroupsPage,
  ExceptionGroupsWizardPage,
}

export const CalendarExceptionsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component(
    'CalendarExceptionsCustomDetail',
    CalendarExceptionsCustomDetail,
  )
  Vue.component('DataTableSelectorField', DataTableSelectorField)
  Vue.component(
    'ExceptionTypesPublishToAccountsAction',
    ExceptionTypesPublishToAccountsAction,
  )
  Vue.component('ExceptionTypesView', ExceptionTypesView)
  Vue.component(
    'MobileSchedulesAddExceptionsView',
    MobileSchedulesAddExceptionsView,
  )
  Vue.component('MobileSchedulesPreviewView', MobileSchedulesPreviewView)
  Vue.component('RunsheetAddExceptionView', RunsheetAddExceptionView)
  Vue.component('RunsheetsPreviewView', RunsheetsPreviewView)
  Vue.component('SiteCalendarExceptionsView', SiteCalendarExceptionsView)
  Vue.component('ZoneCalendarExceptionsView', ZoneCalendarExceptionsView)
  Vue.component('ExceptionTypeGroupsView', ExceptionTypeGroupsView)
  Vue.component('ExceptionGroupsPage', ExceptionGroupsPage)
  Vue.component('ExceptionGroupsWizardPage', ExceptionGroupsWizardPage)
}
