import { FieldTypes } from '../../tt-widget-factory/services/resource-meta/types'
import {
  FieldTypeDateTime,
  FieldTypeDateTimeISO,
  FieldTypeTemporal,
} from './types'

const { Date, DateTime, TimeStampDate, TimeStampNumber } = FieldTypes

/**
 * If `type` is of any Temporal type (ISO format, DateTime, or Date only).
 */
export const isTemporalFieldType = (type: string): type is FieldTypeTemporal =>
  ([Date, DateTime, TimeStampDate, TimeStampNumber] as string[]).includes(type)

/**
 * If `type` is a date AND time (with or without UTC offset), but not Date only.
 */
export const isDateTimeFieldType = (
  type: FieldTypes,
): type is FieldTypeDateTime =>
  [DateTime, TimeStampDate, TimeStampNumber].includes(type)

/**
 * If `type` is a locale date and time (non ISO).
 */
export const isLocaleDateTimeFieldType = (
  type: FieldTypes,
): type is FieldTypeDateTime => [DateTime].includes(type)

/**
 * If `type` is an ISO Date & Time (with UTC offset).
 */
export const isIsoDateTimeFieldType = (
  type: FieldTypes,
): type is FieldTypeDateTimeISO =>
  [TimeStampDate, TimeStampNumber].includes(type)
