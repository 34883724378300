import { DataTableWidgetModel } from '@/tt-widget-components'

export enum LeavePoliciesCustomComponents {
  BasicInformationClickableColumn = 'BasicInformationClickableColumn',
  AssignedPolicyItemsClickableColumn = 'AssignedPolicyItemsClickableColumn',
  AssignedEmployeesClickableColumn = 'AssignedEmployeesClickableColumn',
  LeavePolicyItemsPartOfColumn = 'LeavePolicyItemsPartOfColumn',
  CarryoverColumn = 'LeavePoliciesCarryoverColumn',
}

export enum EmployeeCustomComponenets {
  OverviewRequestsButtons = 'OverviewRequestsButtons',
  LeaveHistoryDatesColumn = 'LeaveHistoryDatesColumn',
  LeaveHistoryAmountColumn = 'LeaveHistoryAmountColumn',
  TransactionActivityDateColumn = 'TransactionActivityDateColumn',
  TransactionActivityTransactionTypeColumn = 'TransactionActivityTransactionTypeColumn',
}

export type LeaveRequestProvider = {
  isLeaveRequestPremium?: boolean
  manageLRinPayroll?: boolean
}

export type LeaveManagementTab = {
  title: string
  icon: string
  resource: string
  creationForm: string
  listWidget: DataTableWidgetModel
  createButtonText: string
}

export type LeavePolicyFormStep = {
  key: string
  title: string
  icon: string
  disabled?: boolean
}

export type EmployeeTab = LeavePolicyFormStep & {
  is: string
}
export type LeaveRequestsTab = LeavePolicyFormStep & {
  key: LeaveRequestsAvailableTabs
  is: string
}

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum LeavePolicyStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum LeaveRequestsStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
}

export type OverviewRequestsColumns = 'requests' | 'cancellations' | 'upcoming'

export type PolicyItemLeaveRequests = {
  approvedRequestsIds?: [FutureLeaveRequests]
  pendingRequestsIds?: [FutureLeaveRequests]
}

export type LeavePolicyItem = {
  id: number
  customId: string
  name: string
  entitlementUnit: EntitlementUnit
  quantity: number
  region: number
  leaveType: number
  status: LeavePolicyStatus
  description?: string
  accrualRate?: number
  leavePolicies?: LeavePolicy[]
  futureLeaveRequests?: PolicyItemLeaveRequests
  pastLeaveRequests?: PolicyItemLeaveRequests
  carryOver?: boolean
  carryOverLimit?: number
  balance?: LeavePolicyItemBalance
  payRatePreference?: string
  payCode?: number
}

export type EntitlementUnit = 'HOURS' | 'DAYS'
export enum EntitlementUnitEnum {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum PayRatePreference {
  EMPLOYEE = 'EMPLOYEE',
}

export type LeavePolicyItemBalance = {
  total: number
  available: number
  used: number
}

export type FutureLeaveRequests = {
  id: number
  status: LeaveRequestsStatus
}

export type LeavePolicy = {
  customId: string
  name: string
  description?: string
  region: number
  status?: LeavePolicyStatus
  payHoursPerDay: number
  effectiveStartDate?: string
  id?: number
  employees?: Array<{ id: number }>
  leavePolicyItems?: LeavePolicyItem[]
}

export enum LeaveManagementActions {
  ASSIGN_EMPLOYEES = 'assign-employees',
  UNASSIGN_EMPLOYEES = 'unassign-employees',
  ASSIGN_POLICY_ITEMS = 'assign-policy-items',
  UNASSIGN_POLICY_ITEMS = 'unassign-policy-items',
}

export enum ServiceLevelImpactRisk {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM_LOW = 'MEDIUM_LOW',
  MEDIUM_HIGH = 'MEDIUM_HIGH',
  HIGH = 'HIGH',
}

export type UiChipColor = {
  background: string
  text: string
}

export type LeaveManagementButtonStyle = {
  color: string
  'background-color': string
}

export const ServiceLevelImpactColors: Record<
  ServiceLevelImpactRisk,
  UiChipColor
> = {
  [ServiceLevelImpactRisk.NONE]: { background: '', text: '' },
  [ServiceLevelImpactRisk.LOW]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [ServiceLevelImpactRisk.MEDIUM_LOW]: {
    background: 'bg-indigo1',
    text: 'bg-text-indigo1',
  },
  [ServiceLevelImpactRisk.MEDIUM_HIGH]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [ServiceLevelImpactRisk.HIGH]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}

export const LeaveRequestStatusColors: Record<
  LeaveRequestsStatus,
  UiChipColor
> = {
  [LeaveRequestsStatus.PENDING]: ServiceLevelImpactColors.MEDIUM_LOW,
  [LeaveRequestsStatus.APPROVED]: ServiceLevelImpactColors.LOW,
  [LeaveRequestsStatus.DECLINED]: ServiceLevelImpactColors.HIGH,
  [LeaveRequestsStatus.CANCELLED]: ServiceLevelImpactColors.MEDIUM_HIGH,
  [LeaveRequestsStatus.PENDING_CANCELLATION]:
    ServiceLevelImpactColors.MEDIUM_HIGH,
}

export enum LeaveRequestsAvailableTabs {
  ABSENT = 'absent',
  PENDING = 'pending',
  CANCELLATIONS = 'cancellations',
  COMPLETED = 'completed',
  CONFLICTS = 'conflicts',
  REQUESTS = 'requests',
  CERTIFICATIONS = 'certifications',
}

export type LeaveManagementApiError = {
  [key: string]: string[]
}

export type CustomErrorTranslation = {
  key: string
  param: string | number
}

export type CustomRangeErrorTranslation = {
  key: string
  param: { min: number; max: number }
}

export const LeaveRequestsStatusColorOptions: Record<
  LeaveRequestsStatus,
  UiChipColor
> = {
  [LeaveRequestsStatus.APPROVED]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [LeaveRequestsStatus.PENDING]: {
    background: 'bg-indigo1',
    text: 'bg-text-indigo1',
  },
  [LeaveRequestsStatus.PENDING_CANCELLATION]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [LeaveRequestsStatus.CANCELLED]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [LeaveRequestsStatus.DECLINED]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}

export type OverviewButtonStyle = {
  'background-color': string
  color: string
  icon?: string
}

export const OverviewRequestsButtonColorOptions: Record<
  LeaveRequestsStatus,
  OverviewButtonStyle
> = {
  [LeaveRequestsStatus.APPROVED]: {
    'background-color': 'var(--v-bg-green1-base)',
    color: 'var(--v-bg-text-green1-base)',
    icon: 'mdi-arrow-right',
  },
  [LeaveRequestsStatus.PENDING]: {
    'background-color': 'var(--v-bg-indigo1-base)',
    color: 'var(--v-bg-text-indigo1-base)',
    icon: 'mdi-clock-outline',
  },
  [LeaveRequestsStatus.PENDING_CANCELLATION]: {
    'background-color': 'var(--v-bg-orange1-base)',
    color: 'var(--v-bg-text-orange1-base)',
    icon: 'mdi-close-circle',
  },
  [LeaveRequestsStatus.CANCELLED]: {
    'background-color': 'var(--v-bg-orange1-base)',
    color: 'var(--v-bg-text-orange1-base)',
  },
  [LeaveRequestsStatus.DECLINED]: {
    'background-color': 'var(--v-bg-red1-base)',
    color: 'var(--v-bg-text-red1-base)',
  },
}

export enum LeaveRequestInjectedOrigin {
  DASHBOARD = 'dashboard',
  PAYROLL = 'payroll',
}
