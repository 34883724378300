<template>
  <div>
    <slot :menu="menu" :is-loading="isLoading" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ActionMenuConfig, ActionMenuItem } from '@/tt-app-layout'
import { modularManager } from '@/tt-app-modular'
import { WidgetStoreModel } from '@/tt-widget-factory'
import { getWidgetViewRoute } from '../utils'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'WidgetActionContextualMenu',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<WidgetStoreModel>,
      required: true,
    },
  },
  data() {
    return {
      /**
       *  @todo: to review
       * modularManager.getMenuItems() is used for displaying actions in listing and widget view,
       * but it has too many differences to be used on both places.
       */
      displayActions: [
        {
          action: {
            type: 'route',
            props: {
              name: getWidgetViewRoute(this.item),
              params: { viewId: this.item.uid },
            },
          },
          color: 'green',
          icon: 'insert_chart',
          text: 'widget_card.view',
        },
      ] as ActionMenuItem[],
      menu: {} as ActionMenuConfig,
      isLoading: false,
    }
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.buildActions()
      },
    },
  },
  created() {
    this.buildActions()
  },
  methods: {
    buildActions(): void {
      this.isLoading = true
      modularManager
        .getMenuItems(
          this.$appContext,
          'WidgetStore.actions',
          this.item,
          Resources.DATA_VIEWS,
        )
        .then((menuItems) => {
          this.menu = { items: [...this.displayActions, ...menuItems] }
          this.isLoading = false
        })
    },
  },
})
</script>
