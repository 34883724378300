<template>
  <TButtonToggle
    v-model="scopeValue"
    class="filter-btn-activator mr-2"
    outlined
    :text="filterText"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { ResourceScope } from '@/tt-widget-factory/services/resource-meta/types'
import { translateScope } from '@/tt-widget-entity-flow/ResourceTranslator'

import TButtonToggle from '@/tt-ui/components/TButtonToggle.vue'

export default Vue.extend({
  name: 'EntityToolbarScopeFilterButton',
  components: {
    TButtonToggle,
  },
  props: {
    currentScope: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    resource: { type: String, required: true },
    scopeName: { type: String, required: true },
  },
  computed: {
    scopeValue: {
      set(value: boolean) {
        this.toggleScopeFilter()
      },
      get(): boolean {
        return this.hasValue
      },
    },
    currentScopeValue(): boolean {
      return this.currentScope.some((scope: string) => scope === this.scopeName)
    },
    filterText(): string {
      return translateScope(this.resource, this.scopeFilterMeta.key) as string
    },
    hasValue(): boolean {
      return this.currentScopeValue
    },
    scopeFilterMeta(): ResourceScope {
      const { resourceMetaManager } = this.$appContext.widgetServices
      const resourceScopeList = resourceMetaManager.getResourceScopes(
        this.resource,
      )
      return resourceScopeList[this.scopeName]
    },
  },
  methods: {
    toggleScopeFilter() {
      if (!this.scopeValue) {
        this.$emit('addscope', this.scopeName)
        this.$emit('apply')
      } else {
        this.$emit('removescope', this.scopeName)
        this.$emit('apply')
      }
    },
  },
})
</script>

<style scoped>
.filter-btn-activator {
  background-color: var(--v-level0-base) !important;
  font-size: 0.75em !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  border-color: var(--v-level0-darken1);
  height: 30px !important;
}
</style>
