import Vue from 'vue'

import {
  EntityCollectionRequestOptions,
  EntityCollectionResponse,
} from 'tracktik-sdk/lib/common/entity-collection'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { AppContext } from '@/tt-app-context'
import {
  CollectionQueryResponse,
  WidgetStoreInterface,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'

import {
  DashboardDataViewProviderInterface,
  DashboardDataViewState,
  PageSettings,
} from './types'
import {
  getWidgetQueryByDashboard,
  getWidgetQueryByDashboardAndSearch,
  getWidgetQueryByUid,
} from './dashboard-data-view-provider-query'
import { toWidgetStoreModel } from './normalize-dashboard-data-view-response'

export default class DashboardDataViewProvider
  implements DashboardDataViewProviderInterface
{
  appContext: AppContext
  private state: DashboardDataViewState
  private dashboardCount: {
    views: number
    searchViews: number
  }

  /**
   * App context
   * @param appContext
   */
  constructor(appContext: AppContext) {
    this.appContext = appContext
    this.dashboardCount = Vue.observable({
      views: 0,
      searchViews: 0,
    })
    this.state = Vue.observable({
      dashboardViews: [],
      searchViews: [],
    })
  }

  private userId(): number {
    return this.appContext.authModule.getUserId()
  }

  /**
   *  Fetch dashboard views
   *  @param pageSettings
   */
  async fetchDashboardViews(pageSettings: PageSettings): Promise<void> {
    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEWS,
      )
    ) {
      this.state.dashboardViews = []
    }
    const { resource, ...options } = getWidgetQueryByDashboard(pageSettings)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    this.dashboardCount.views = response.total

    this.state.dashboardViews = [
      ...this.getDashboardDataViews(),
      ...response.items.map((dataView: any) =>
        toWidgetStoreModel(dataView, { myId: this.userId() }),
      ),
    ]
  }

  /**
   *  Fetch dashboard views by search
   *  @param search
   */
  async fetchDashboardViewsBySearch(search: string): Promise<void> {
    const { resource, ...options } = getWidgetQueryByDashboardAndSearch(search)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    this.dashboardCount.searchViews = response.total

    this.state.searchViews = response.items.map((dataView: any) =>
      toWidgetStoreModel(dataView, { myId: this.userId() }),
    )
  }

  /**
   *  Fetch views by uid
   *  @param uid
   */
  async fetchWidgetByUid(uid: string): Promise<WidgetStoreInterface> {
    const { resource, ...options } = getWidgetQueryByUid(uid)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    const formattedResponse = response.items.map((dataView: any) => {
      return toWidgetStoreModel(dataView, { myId: this.userId() })
    })

    return formattedResponse[0]
  }

  getDashboardDataViews(): WidgetStoreInterface[] {
    return this.state.dashboardViews
  }

  getSearchDataViews(): WidgetStoreInterface[] {
    return this.state.searchViews
  }

  getDashboardCount(): {
    views: number
    searchViews: number
  } {
    return this.dashboardCount
  }
}
