import { ValueOf } from '@/helpers/types/ValueOf'

/**
 * Some of these values are release flags, meaning they are short-lived.
 * When the enum value is removed from the BE, it should be removed from the FE
 * too.
 * There is a test that will check for obsolete tags when the resources schemas
 * are updated and fail if the map below contains any values the schemas don't.
 */
export const systemSettingsTag = {
  APPLICATION_LOGO: 'APPLICATION-LOGO',
  DEVIATIONS: 'DEVIATIONS',
  DISPATCH_IMPROVEMENT_RELEASE_FLAG: 'DISPATCH-IMPROVEMENT-RELEASE-FLAG',
  EMPLOYEE_PAY_RATE_PRIORITY: 'EMPLOYEE-PAY-RATE-PRIORITY',
  FORCE_SITE_UID: 'FORCE-SITE-UID',
  GENERIC_LOGIN: 'GENERIC-LOGIN',
  GLOBAL_VOIP: 'GLOBAL-VOIP',
  HEADQUARTER: 'HEADQUARTER',
  HELP_BUTTON: 'HELP-BUTTON',
  LAYOUT_THEME: 'LAYOUT-THEME',
  LEAVE_REQUEST: 'LEAVE-REQUEST',
  MOBILE_VERSIONS: 'MOBILE-VERSIONS',
  MODULES: 'MODULES',
  NOTIF_EMAIL_CATCH_ALL: 'NOTIF-EMAIL-CATCH-ALL',
  OBJECT_LANGUAGES: 'OBJECT-LANGUAGES',
  OPENID: 'OPENID',
  RUNSHEETS: 'RUNSHEETS',
  SIGN_IN_SCREEN: 'SIGN-IN-SCREEN',
  TT_FLOW_SETTINGS: 'TT-FLOW-SETTINGS',
  TT_SHIFT_LONE_WORKER_RELEASE_FLAG: 'TT-SHIFT-LONE-WORKER-RELEASE-FLAG',
  TWILIOV2: 'TWILIOV2',
  WHITE_LIST_DOMAIN_LINK: 'WHITE-LIST-DOMAIN-LINK',
} as const

export type SystemSettingsTag = ValueOf<typeof systemSettingsTag>
