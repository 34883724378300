<template>
  <tt-tile>
    <div class="d-flex justify-space-between align-center flex-grow-1">
      <div class="d-flex flex-column">
        <tt-attr
          slot="line3"
          name="status"
          class="text-body-2 font-weight-light"
        />
        <tt-attr
          slot="line4"
          name="createdOn"
          format="asLongDateTime"
          class="text-body-2 font-weight-light"
        />
      </div>
      <div>
        <LeavePolicyCarryOverStatusChip :is-small="false" />
      </div>
    </div>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import LeavePolicyCarryOverStatusChip from './LeavePolicyCarryOverStatusChip.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyCarryOverLogsTile',
  components: { LeavePolicyCarryOverStatusChip },
})
</script>
