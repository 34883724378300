<template>
  <v-card flat class="transparent pa-8">
    <h1 style="opacity: 0.1" v-text="$t(message)" />
  </v-card>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'TNoData',
  props: {
    message: {
      type: String,
      default: 'common.no_results',
    },
  },
})
</script>
