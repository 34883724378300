// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileScheduleOccurrenceLogsTile from './MobileScheduleOccurrenceLogsTile.vue'
import MobileScheduleOccurrenceLogsColumns from './MobileScheduleOccurrenceLogsColumns'
import MobileScheduleOccurrenceLogsDetail from './MobileScheduleOccurrenceLogsDetail.vue'
import MobileScheduleOccurrenceLogsMetaCard from './MobileScheduleOccurrenceLogsMetaCard.vue'
import MobileScheduleOccurrenceLogsReference from './MobileScheduleOccurrenceLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileScheduleOccurrenceLogsTile',
      MobileScheduleOccurrenceLogsTile,
    )
    Vue.component(
      'MobileScheduleOccurrenceLogsDetail',
      MobileScheduleOccurrenceLogsDetail,
    )
    Vue.component(
      'MobileScheduleOccurrenceLogsMetaCard',
      MobileScheduleOccurrenceLogsMetaCard,
    )
    Vue.component(
      'MobileScheduleOccurrenceLogsReference',
      MobileScheduleOccurrenceLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileScheduleOccurrenceLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
