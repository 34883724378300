<template>
  <div class="fill-height">
    <EntityItem
      :key="`${resourceName}-${entityId}-initial`"
      v-bind="{ entityId, resourceName }"
      class="fill-height d-flex flex-column"
    >
      <v-list two-line class="ma-0">
        <tt-preset-view type="listItem" />
      </v-list>

      <AppSlot :slot-name="`${slotName}.details.top`" :props-data="propsData" />

      <div class="fill-height">
        <EntityDetailCard v-bind="propsData" :slot-name="slotName" />
      </div>
    </EntityItem>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '../types'
import { EntityItemHook } from '../EntityItemHook'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityRelationPreview',
  inject: ['getItemHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resourceName(): string {
      return this.getItemHook().getAttributeRelationResource(this.name)
    },
    entityId(): number {
      return this.getItemHook().get(`${this.name}.id`)
    },
    propsData(): {
      resourceName: string
      entityId: number
    } {
      return {
        resourceName: this.resourceName,
        entityId: this.entityId,
      }
    },
    slotName(): string {
      return `${this.resourceName}.preview`
    },
  },
})
</script>
