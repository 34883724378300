// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ConflictsTile from './ConflictsTile.vue'
import ConflictsColumns from './ConflictsColumns'
import ConflictsDetail from './ConflictsDetail.vue'
import ConflictsMetaCard from './ConflictsMetaCard.vue'
import ConflictsReference from './ConflictsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ConflictsTile', ConflictsTile)
    Vue.component('ConflictsDetail', ConflictsDetail)
    Vue.component('ConflictsMetaCard', ConflictsMetaCard)
    Vue.component('ConflictsReference', ConflictsReference)

    modularManager.addResourcePreset(
      Resources.CONFLICTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ConflictsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.CONFLICTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ConflictsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.CONFLICTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ConflictsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONFLICTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ConflictsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONFLICTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ConflictsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
