import Vue from 'vue'
import { ObservabilityVue } from '@tracktik/tt-observability'
import { Crash } from '@tracktik/tt-observability/dist/src/crash/vue'
import { Analytics } from '@tracktik/tt-observability/dist/src/analytics'
import { ObservabilityAnalytics } from '@tracktik/tt-observability'

declare module 'vue/types/vue' {
  interface Vue {
    $crash: Crash
    $analytics: Analytics
  }
}

export type ObservabilityVueConfig = ObservabilityVue.ObservabilityVueConfig

export const eventBus = new Vue()

const updateIdentity = (Vue) => {
  const $o11n = Vue.prototype.$o11n
  const $appContext = Vue.prototype.$appContext
  const $authModule = $appContext.authModule

  if ($authModule.isAuthenticated()) {
    $o11n.identify($authModule.getUserId(), {
      serverUrl: $authModule.getServerUrl(),
      preferences: $authModule.getUserPreferences(),
    })
  } else {
    $o11n.identify(null)
  }
}

export const onIdentityChanged = (Vue) => {
  eventBus.$once('widgets.installed', updateIdentity.bind(null, Vue))
  eventBus.$on('auth.signed-in', updateIdentity.bind(null, Vue))
  eventBus.$on('auth.is-signed-in', updateIdentity.bind(null, Vue))
  eventBus.$on('auth.signed-out', updateIdentity.bind(null, Vue))
  eventBus.$on('auth.session-changed', updateIdentity.bind(null, Vue))
}

export const install = (Vue, config: any, services: any) => {
  const o11nConfig =
    ObservabilityVue.ObservabilityVueConfigBuilder.merge(config)
  Vue.use(ObservabilityVue, o11nConfig, services)
  onIdentityChanged(Vue)
}

export enum Categories {
  Authentication = 'Authentication',
  Dashboard = 'Dashboard',
  Widget = 'Widget',
  TQL = 'TQL',
  Profile = 'Profile',
  Folder = 'Folder',
}

export const EnableToolbar = ObservabilityAnalytics.createEventBuilder({
  action: 'Enable Toolbar',
  category: Categories.Widget,
})

export const ApplyContextFilters = ObservabilityAnalytics.createEventBuilder({
  action: 'Apply Context Filters',
  category: Categories.Dashboard,
})

export const SignInViaSSO = ObservabilityAnalytics.createEventBuilder({
  action: 'Sign In Via SSO',
  category: Categories.Authentication,
})

export const SignInViaOauth = ObservabilityAnalytics.createEventBuilder({
  action: 'Sign In Via Oauth',
  category: Categories.Authentication,
})

export const ChooseWidgetInDashboard =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Choose Widget In Dashboard',
    category: Categories.Dashboard,
  })

export const ChooseWidgetByDragging = ObservabilityAnalytics.createEventBuilder(
  {
    action: 'Choose Widget By Dragging',
    category: Categories.Dashboard,
  },
)

export const SearchTerm = ObservabilityAnalytics.createEventBuilder({
  action: 'Search Term',
  category: Categories.Dashboard,
})

export const ChooseFolder = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Folder',
  category: Categories.Widget,
})

export const ChooseDomain = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Domain',
  category: Categories.Authentication,
})

export const AddDomain = ObservabilityAnalytics.createEventBuilder({
  action: 'Add Domain',
  category: Categories.Authentication,
})

export const CreateWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Create Widget',
  category: Categories.Widget,
})

export const CopyWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Copy Widget',
  category: Categories.Widget,
})

export const CreateDashboard = ObservabilityAnalytics.createEventBuilder({
  action: 'Create Dashboard',
  category: Categories.Dashboard,
})

export const ChooseWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Widget',
  category: Categories.Widget,
})

export const ChooseTemplate = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Template',
  category: Categories.Dashboard,
})

export const CopyDashboard = ObservabilityAnalytics.createEventBuilder({
  action: 'Copy Dashboard',
  category: Categories.Dashboard,
})

export const CreateWidgetIntoDashboard =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Create Widget Into Dashboard',
    category: Categories.Dashboard,
  })

export const ChooseWidgetTypeFromDashboardCounter =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Choose Widget Type From Dashboard Counter',
    category: Categories.Dashboard,
  })

export const ChooseCommonFilter = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Common Filter',
  category: Categories.Widget,
})

export const IncludeArchived = ObservabilityAnalytics.createEventBuilder({
  action: 'Include Archived',
  category: Categories.Widget,
})

export const AddSortAttribute = ObservabilityAnalytics.createEventBuilder({
  action: 'Add Sort Attribute',
  category: Categories.Widget,
})

export const SelectSortCriteria = ObservabilityAnalytics.createEventBuilder({
  action: 'Select Sort Criteria',
  category: Categories.Widget,
})

export const AddAttributeFilter = ObservabilityAnalytics.createEventBuilder({
  action: 'Add Attribute Filter',
  category: Categories.Widget,
})

export const AddCustomFilter = ObservabilityAnalytics.createEventBuilder({
  action: 'Add Custom Filter',
  category: Categories.Widget,
})

export const ApplyWhereQL = ObservabilityAnalytics.createEventBuilder({
  action: 'Apply WhereQL',
  category: Categories.TQL,
})

export const TypeIntoHavingQL = ObservabilityAnalytics.createEventBuilder({
  action: 'Type Into HavingQL',
  category: Categories.TQL,
})

export const StarWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Star Widget',
  category: Categories.Widget,
})

export const UnStarWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Un Star Widget',
  category: Categories.Widget,
})

export const ChooseDateRange = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Date Range',
  category: Categories.Widget,
})

export const ChooseRegion = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Region',
  category: Categories.Widget,
})

export const ChooseAccount = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose Account',
  category: Categories.Widget,
})

export const EnablePresentationMode = ObservabilityAnalytics.createEventBuilder(
  {
    action: 'Enable Presentation Mode',
    category: Categories.Widget,
  },
)

export const ChangeLanguage = ObservabilityAnalytics.createEventBuilder({
  action: 'Change Language',
  category: Categories.Profile,
})

export const AddAttributeFilterInToolbar =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Add Attribute Filter From Toolbar',
    category: Categories.Widget,
  })

export const ChooseRegionFromTopFilter =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Choose Region From Top Filter',
    category: Categories.Widget,
  })

export const ChooseAccountFromTopFilter =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Choose Account From Top Filter',
    category: Categories.Widget,
  })

export const ChooseDateRangeFromTopFilter =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Choose Date Range From Top Filter',
    category: Categories.Widget,
  })

export const SelectConfiguredAttributeFilter =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Select Configured Attribute Filter',
    category: Categories.Widget,
  })

export const SearchInWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Search In Widget',
  category: Categories.Widget,
})

export const SearchInDetailsView = ObservabilityAnalytics.createEventBuilder({
  action: 'Search In Details View',
  category: Categories.Widget,
})

export const SaveWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Save Widget',
  category: Categories.Widget,
})

export const ApplyTQLQuery = ObservabilityAnalytics.createEventBuilder({
  action: 'Apply TQL Query',
  category: Categories.TQL,
})

export const SaveTQLAsWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Save TQL As Widget',
  category: Categories.TQL,
})

export const ShareWidget = ObservabilityAnalytics.createEventBuilder({
  action: 'Share Widget',
  category: Categories.Widget,
})

export const ShareFolder = ObservabilityAnalytics.createEventBuilder({
  action: 'Share Folder',
  category: Categories.Folder,
})

export const SelectShareWidgetFrom = ObservabilityAnalytics.createEventBuilder({
  action: 'Select Share Widget From',
  category: Categories.Widget,
})

export const CreateFolder = ObservabilityAnalytics.createEventBuilder({
  action: 'Create Folder',
  category: Categories.Dashboard,
})

export const CreateFolderInActionMenu =
  ObservabilityAnalytics.createEventBuilder({
    action: 'Create Folder in Action Menu',
    category: Categories.Dashboard,
  })

export const SaveDashboard = ObservabilityAnalytics.createEventBuilder({
  action: 'Save Dashboard',
  category: Categories.Dashboard,
})

export const Search = ObservabilityAnalytics.createEventBuilder({
  action: 'Search',
  category: Categories.Widget,
})

export const Unarchive = ObservabilityAnalytics.createEventBuilder({
  action: 'Unarchive',
})

export const Archive = ObservabilityAnalytics.createEventBuilder({
  action: 'Archive',
})

export const DeleteFolder = ObservabilityAnalytics.createEventBuilder({
  action: 'Delete Folder',
})
