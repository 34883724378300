<template>
  <v-row no-gutters>
    <v-col cols="8">
      <v-select
        :attach="true"
        dense
        :items="typeOptions"
        :label="$t('widget_edit.default_sort.criteria')"
        :value="type"
        outlined
        @input="setType"
      />
    </v-col>
    <v-col class="pl-4">
      <SortDirectionInput :value="direction" @input="setDirection" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { AggregationSort, SortDirection } from '../schemas-types'
import {
  AGGREATION_SORT_ITEM_TYPE,
  AggregationSortItemType,
  FormHookProvider,
  ResourceProvider,
  SortType,
  SortTypeOption,
} from '../types'
import { SelectSortCriteria } from '@/plugins/o11n'
import { WidgetName } from '@/tt-widget-components/lib/names'

type DataSetSortInputType = VueConstructor<
  Vue & FormHookProvider & ResourceProvider
>

function enforceArray<T>(val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val]
}

export default (Vue as DataSetSortInputType).extend({
  name: 'DataSetSortInput',
  inject: ['formHook', 'resourceProvider'],
  props: {
    value: {
      type: Array as PropType<AggregationSort>,
      default: () => [],
    },
  },
  computed: {
    resource(): string | null {
      return this.resourceProvider.resource
    },
    widgetName(): WidgetName {
      return this.formHook().getPathValue('is')
    },
    dataSet(): Record<string, any> {
      return this.formHook().getPathValue('dataSet')
    },
    sortTypes(): AggregationSortItemType[] {
      return AGGREATION_SORT_ITEM_TYPE.filter(
        (key) => this.dataSet[key.toLowerCase()],
      )
    },
    model(): AggregationSort[number] | undefined {
      // We doing this because currently we only support 1 sort option in the array
      return this.value && this.value[0]
    },
    type(): SortType | undefined {
      if (!this.model) return undefined

      if (
        this.model.type === AggregationSortItemType.DIMENSIONS ||
        this.model.type === AggregationSortItemType.MEASURES
      ) {
        return {
          type: this.model.type,
          index: this.model.index,
        }
      }
      return { type: this.model.type }
    },
    direction(): SortDirection {
      return this.model ? this.model.direction : 'ASC'
    },
    typeOptions(): SortTypeOption[] {
      return this.sortTypes.map((type) => this.getSortTypes(type)).flat()
    },
  },
  methods: {
    setType(val: SortType): void {
      this.$emit('input', [{ ...val, direction: this.direction }])
      this.trackChange()
    },
    setDirection(val: SortDirection): void {
      this.$emit('input', [{ ...this.type, direction: val }])
      this.trackChange()
    },
    trackChange(): void {
      if (!this.type) return
      const gaEventLabel = [this.type, this.direction].filter(Boolean).join('_')
      const gaEvent = SelectSortCriteria.create({ label: gaEventLabel })
      this.$analytics.track(gaEvent)
    },
    getSortTypes(type: AggregationSortItemType): SortTypeOption[] {
      const dataSetTypes = enforceArray<Record<string, any>>(
        this.dataSet[type.toLowerCase()],
      )
      const localizedType = this.$tc(
        `widget_edit.aggregation_sort.type.${type}`,
      )
      if (
        [
          AggregationSortItemType.MEASURE,
          AggregationSortItemType.DIMENSION,
        ].includes(type)
      ) {
        return [
          {
            text: localizedType,
            value: { type },
          },
        ]
      }
      return dataSetTypes.map((_, index) => ({
        text: `${localizedType} ${index + 1}`,
        value: { type, index },
      }))
    },
  },
})
</script>
