<template>
  <v-btn
    small
    text
    outlined
    v-bind="$attrs"
    :color="conflictAction.color"
    elevation="0"
    :data-cy="`conflict-${action}-action`"
    @click.stop="applyAction(conflictAction.type)"
  >
    <span v-text="$t(conflictAction.label)" />
  </v-btn>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ConflictAction, ConflictActions, ConflictActionType } from './types'
import { Resources } from '../../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ConflictsBtnAction',
  inject: ['getItemHook'],
  inheritAttrs: false,
  props: {
    action: {
      type: String as PropType<ConflictActionType>,
      required: true,
    },
  },
  computed: {
    conflictId(): number {
      return this.getItemHook().getRawValue('id')
    },
    conflictAction(): ConflictAction {
      const conflictActionsDictionary: Record<
        ConflictActionType,
        ConflictAction
      > = {
        resolve: {
          type: ConflictActions.RESOLVE,
          label: 'common.resolve',
          color: 'success',
        },
        dismiss: {
          type: ConflictActions.DISMISS,
          label: 'common.dismiss',
          color: 'error',
        },
      }

      return conflictActionsDictionary[this.action]
    },
  },
  methods: {
    async applyAction(action: ConflictActionType): Promise<void> {
      return await this.$appContext.entityServices.persister.executeEntityAction(
        Resources.CONFLICTS,
        action,
        this.conflictId,
      )
    },
  },
})
</script>
