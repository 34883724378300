<template>
  <div class="widget-loader--container">
    <TLoadingBar class="widget-loader--bar" />
    <slot name="default">
      <div class="slot-image">
        <v-img
          :src="thumbnail"
          contain
          max-height="200px"
          class="pa-3"
          :style="style"
        />
      </div>
    </slot>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'WidgetLoader',
  props: {
    widget: {
      type: Object as PropType<{ is: string }>,
      required: true,
    },
  },
  computed: {
    style(): Record<string, unknown> {
      return {
        opacity: 0.3,
      }
    },
    thumbnail(): string {
      const config =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          this.widget.is,
        )
      return config ? config.config.thumbnail : null
    },
  },
})
</script>

<style scoped>
.slot-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget-loader--container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widget-loader--bar {
  z-index: 1;
}
</style>
