// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import VehiclesTile from './VehiclesTile.vue'
import VehiclesColumns from './VehiclesColumns'
import VehiclesDetail from './VehiclesDetail.vue'
import VehiclesMetaCard from './VehiclesMetaCard.vue'
import VehiclesReference from './VehiclesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('VehiclesTile', VehiclesTile)
    Vue.component('VehiclesDetail', VehiclesDetail)
    Vue.component('VehiclesMetaCard', VehiclesMetaCard)
    Vue.component('VehiclesReference', VehiclesReference)

    modularManager.addResourcePreset(
      Resources.VEHICLES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'VehiclesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.VEHICLES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: VehiclesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.VEHICLES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'VehiclesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.VEHICLES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'VehiclesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.VEHICLES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'VehiclesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
