<template>
  <div class="darklight--container">
    <TopBarButton
      text="extensions.dark-light.label"
      :icon="isDark ? 'mdi-white-balance-sunny' : 'mdi-weather-night'"
      @click="toggleTheme"
    />
    <TLoadingBar v-if="isRendering" class="darklight--loader" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Theme } from '@/tt-ui/ThemeProvider'
import TopBarButton from '@/tt-app-layout/components/TopBarButton.vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'

const themeStorageKey = 'tt-theme'

export default Vue.extend({
  name: 'DarkLightExtension',
  components: { TopBarButton },
  data: () => ({
    isRendering: false,
  }),
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark
    },
  },
  created() {
    const theme = localStorage.getItem(themeStorageKey)
    if (!theme) {
      this.loadThemeFromQueryParams()
      return
    }
    this.$vuetify.theme.dark = theme === Theme.DARK
  },
  methods: {
    async toggleTheme() {
      this.isRendering = true
      await updateDOM()

      const isDark = !this.$vuetify.theme.dark
      this.setTheme(isDark ? Theme.DARK : Theme.LIGHT)

      this.isRendering = false
    },
    setTheme(theme: Theme) {
      localStorage.setItem(themeStorageKey, theme)
      this.$vuetify.theme.dark = theme === Theme.DARK
      document.body.classList.toggle('dark', theme === Theme.DARK)
    },
    loadThemeFromQueryParams() {
      const { dark } = this.$route.query

      if (dark) {
        this.setTheme(Theme.DARK)
      }
    },
  },
})
</script>

<style>
body.dark {
  background-color: #000000;
}
</style>
<style scoped>
.darklight--container {
  position: relative;
}

.darklight--loader {
  top: 0;
  position: absolute;
}
</style>
