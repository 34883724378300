import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { createDocumentColumn } from '@/tt-widget-entity-flow/createDocumentColumn'
import ReportDoc from '@/tt-entity-design/src/components/reports/ReportDoc'

export default [
  createDocumentColumn(ReportDoc),
  'id',
  'reportTemplate',
  'reportFlag',
  'siteLocation',
  'siteLocation.type',
  'account',
  'account.region',
  'submittedOn',
  'createdBy',
  'createdBy.jobTitle',
  {
    attributeName: 'status',
    component: {
      is: 'StatusColor',
      props: {
        name: 'status',
        layout: 'CHIP',
      },
    },
  },
] as ColumnInputDefinition[]
