var l = { VITE_HERE_MAP_API_KEY: "N_dhLfSwWLUbJ20VyqqeqDOdAcs5WtFCZrH7gzPQud0" };
const v = {
  log: (...n) => {
    (l.NODE_ENV === "development" || l.NODE_ENV === "test") && console.log("[DEV]", ...n);
  },
  warn: (...n) => {
    (l.NODE_ENV === "development" || l.NODE_ENV === "test") && console.warn("[DEV]", ...n);
  }
}, _ = (n) => {
  var w;
  const a = (...e) => n.development && v.log("[tt-browser-channel]", ...e), t = (w = n.broadcastChannel) != null ? w : new BroadcastChannel(n.channelName), c = /* @__PURE__ */ new Map(), h = (e, o) => new Promise((r, d) => {
    const s = Math.random(), g = setTimeout(() => {
      a(
        "Message timeout! Most probably because there is no other tab listening to the same channel."
      ), c.delete(s), d(new Error("TIMEOUT"));
    }, 1e3), u = () => clearTimeout(g);
    c.set(s, () => (a("Message delivered!"), c.delete(s), u(), r(void 0)));
    const i = {
      messageType: "new-message",
      messageID: s,
      // @ts-ignore
      payload: { name: e, data: o }
    };
    a("Sending message...", i), t.postMessage(i);
  }), E = (e) => {
    const o = {
      messageType: "acknowledgement",
      messageID: e
    };
    t.postMessage(o);
  }, N = (e) => Object.keys(n.onMessageReceived || {}).includes(e), m = (e) => {
    var d;
    a("New message received...", e.data);
    const { messageID: o, messageType: r } = e.data;
    if (r === "acknowledgement") {
      const s = c.get(o);
      s == null || s();
    } else if (r === "new-message") {
      E(o);
      const s = (d = e.data.payload) == null ? void 0 : d.name;
      if (!N(s)) {
        console.warn(`No handler found for event named: ${s}`);
        return;
      }
      const g = n.onMessageReceived[s];
      g(e.data.payload.data);
    } else
      console.warn("Unknown message:", e.data);
  };
  t.onmessage = m;
  const M = () => {
    a("Destroying browser channel..."), c.clear(), t.removeEventListener("message", m), t.close();
  };
  return a("Browser channel created!"), {
    send: h,
    destroy: M
  };
};
export {
  _ as createBrowserChannel
};
