<template>
  <div>
    <v-toolbar>
      <v-toolbar-title class="full-page-title pl-4">
        <span class="mr-3">{{ adjustBalanceInfo.leaveTypeName }}</span>
        <AccruedChip v-if="adjustBalanceInfo.isAccrued" />
      </v-toolbar-title>
      <v-icon small @click="closeDialog">mdi-close</v-icon>
    </v-toolbar>

    <v-card tile class="px-8 py-6">
      <div class="d-flex justify-space-between py-4 border-bottom-gray">
        <h3 class="heading" style="color: var(--v-ttPrimary-base)">
          {{ totalAmountText }}
        </h3>
        <EmployeeInfoChip :info="hoursPerDayText" class="ml-4" />
      </div>

      <AdjustBalanceDialogLine
        v-for="{ label, balance, value, apiUnitType } in adjustableTextFields"
        :key="apiUnitType"
        v-bind="{ label, balance, value }"
        @set-value="setUnitValue(apiUnitType, $event)"
        @add="add(apiUnitType)"
        @remove="remove(apiUnitType)"
      />

      <v-alert
        v-if="warning.display"
        class="mb-0 mt-6"
        type="warning"
        color="orange darken-2"
        icon="mdi-alert"
        outlined
        dense
        text
      >
        {{ warning.message }}
      </v-alert>

      <div class="pt-6">
        <v-textarea v-model="description" outlined dense :label="reasonLabel" />
      </div>

      <v-card-actions>
        <v-btn plain @click="closeDialog">
          <span v-text="$t(cancelBtnText)" />
        </v-btn>

        <v-spacer />

        <v-btn
          depressed
          color="var(--v-ttPrimary-base)"
          class="white--text"
          :disabled="warning.display || !isValid"
          @click="saveChanges"
        >
          <v-icon small>mdi-check</v-icon>
          <span class="ml-1" v-text="$t(saveBtnText)" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { BalanceOperation, BalanceUnit, AdjustBalanceInfo } from './types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { Resources } from '@/tt-entity-design/src/types'
import AccruedChip from '../../AccruedChip.vue'
import EmployeeInfoChip from '../employment-profile/EmployeeInfoChip.vue'
import round from 'lodash/round'
import AdjustBalanceDialogLine from './AdjustBalanceDialogLine.vue'

const TRANSLATION_BASE = 'tt-entity-design.leave-management.employee'

const HOURS_UNIT = 'hours'
const DAYS_UNIT = 'days'

type UnitTextField = {
  apiUnitType: BalanceUnit
  label: string
  balance: number
  value: number
}

type Alert = {
  display: boolean
  message?: string
}

export default Vue.extend({
  name: 'AdjustBalanceDialog',
  components: { AccruedChip, EmployeeInfoChip, AdjustBalanceDialogLine },
  props: {
    adjustBalanceInfo: {
      type: Object as PropType<AdjustBalanceInfo>,
      required: true,
    },
  },
  data() {
    return {
      operation: {
        days: 0,
        hours: 0,
      },
      description: '',
    }
  },
  computed: {
    adjustableTextFields(): UnitTextField[] {
      return [
        {
          apiUnitType: DAYS_UNIT,
          label: `${TRANSLATION_BASE}.overview.days-title`,
          balance: round(this.availableDaysBalance, 4),
          value: this.operation.days,
        },
        {
          apiUnitType: HOURS_UNIT,
          label: `${TRANSLATION_BASE}.settings.hours`,
          balance: round(this.availableHoursBalance, 4),
          value: this.operation.hours,
        },
      ]
    },
    hoursEntitlementUnit(): boolean {
      return this.adjustBalanceInfo.entitlementUnit === HOURS_UNIT
    },
    payHoursPerDay(): number {
      return this.adjustBalanceInfo.payHoursPerDay
    },
    availableTotalBalance(): number {
      return this.adjustBalanceInfo.availableTotalBalance
    },
    availableHoursBalance(): number {
      if (this.hoursEntitlementUnit) {
        return this.availableTotalBalance % this.payHoursPerDay
      } else {
        return (
          (this.availableTotalBalance * this.payHoursPerDay) %
          this.payHoursPerDay
        )
      }
    },
    availableDaysBalance(): number {
      if (this.hoursEntitlementUnit) {
        return Math.floor(this.availableTotalBalance / this.payHoursPerDay)
      } else {
        return Math.floor(this.availableTotalBalance)
      }
    },
    invalidOperationsWarning(): string {
      return this.$t(`${TRANSLATION_BASE}.settings.invalid-operations-warning`)
    },
    exceedsDaysWarning(): string {
      return this.$t(`${TRANSLATION_BASE}.settings.exceeds-days-warning`)
    },
    cancelBtnText(): string {
      return 'common.cancel.btn'
    },
    hoursPerDayText(): string {
      return this.$tc(
        `${TRANSLATION_BASE}.employmentProfile.hours-per-day`,
        this.payHoursPerDay,
      )
    },
    totalText(): string {
      return this.$t(`${TRANSLATION_BASE}.overview.total`)
    },
    roundedTotal(): number {
      return round(this.totalBalanceToDisplay, 4)
    },
    unitAmountText(): string {
      const unitText = this.hoursEntitlementUnit
        ? `${TRANSLATION_BASE}.settings.total-hours`
        : `${TRANSLATION_BASE}.settings.total-days`
      return this.$tc(unitText, this.roundedTotal)
    },
    totalAmountText(): string {
      return `${this.totalText}: ${this.unitAmountText}`
    },
    isValid(): boolean {
      return (
        (!!this.operation.days || !!this.operation.hours) &&
        this.description.length > 0
      )
    },
    warning(): Alert {
      if (this.hasErrorWithOperationValues) {
        return { display: true, message: this.invalidOperationsWarning }
      }
      if (this.hasErrorWithExceedingTotalAmount) {
        return { display: true, message: this.exceedsDaysWarning }
      }
      return { display: false }
    },
    hasErrorWithOperationValues(): boolean {
      return (
        (this.operation.days > 0 && this.operation.hours < 0) ||
        (this.operation.days < 0 && this.operation.hours > 0)
      )
    },
    hasErrorWithExceedingTotalAmount(): boolean {
      const daysInOneYear = 365
      const hoursInOneYear = daysInOneYear * this.payHoursPerDay
      if (this.hoursEntitlementUnit) {
        return this.roundedTotal > hoursInOneYear
      } else {
        return this.roundedTotal > daysInOneYear
      }
    },
    reasonLabel(): string {
      return this.$t(`${TRANSLATION_BASE}.settings.reason-field`)
    },
    saveBtnText(): string {
      return 'common.save_changes.btn'
    },
    totalBalanceToDisplay(): number {
      const convertedHours = this.operation.hours / this.payHoursPerDay
      const convertedDays = this.operation.days * this.payHoursPerDay
      const newTotalBalanceToAdjust = this.hoursEntitlementUnit
        ? Number(this.operation.hours) + convertedDays
        : Number(this.operation.days) + convertedHours
      return this.availableTotalBalance + newTotalBalanceToAdjust
    },
    buildActionPayload(): BalanceOperation {
      return {
        employee: this.adjustBalanceInfo.employeeId,
        leaveType: this.adjustBalanceInfo.leaveTypeId,
        description: this.description,
        days: this.operation.days,
        hours: this.operation.hours,
      }
    },
  },
  methods: {
    closeDialog(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)
    },
    setUnitValue(unit: BalanceUnit, value = 0) {
      this.operation = {
        ...this.operation,
        [unit]: Number(value),
      }
    },
    add(unit: BalanceUnit) {
      const newValue = this.operation[unit] + 1
      this.setUnitValue(unit, newValue)
    },
    remove(unit: BalanceUnit) {
      const newValue = this.operation[unit] - 1
      this.setUnitValue(unit, newValue)
    },
    actionSuccess() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t(
          `${TRANSLATION_BASE}.settings.adjustment-action.success`,
        ),
      })
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: Resources.LEAVE_POLICY_ITEMS,
      })
    },
    actionFailure() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t(
          `${TRANSLATION_BASE}.settings.adjustment-action.failure`,
        ),
      })
    },
    async saveChanges() {
      try {
        await this.$appContext.entityServices.persister.executeEntityAction(
          Resources.LEAVE_POLICIES,
          'update-balance',
          this.adjustBalanceInfo.leavePolicyId,
          this.buildActionPayload,
        )
        this.actionSuccess()
      } catch (err) {
        this.$crash.captureException(err)
        this.actionFailure()
      } finally {
        this.closeDialog()
      }
    },
  },
})
</script>

<style scoped>
.v-toolbar >>> .v-toolbar__content {
  justify-content: space-between !important;
  border-bottom: rgba(0, 0, 0, 0.17) 1px solid !important;
}
.border-bottom-gray {
  border-bottom: rgba(0, 0, 0, 0.17) 1px solid;
}
</style>
