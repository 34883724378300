import {
  EventHandler,
  EventHandlerMap,
  GlobalEventHandler,
  EventManagerInterface,
  EventPayloadInterface,
} from './types'

export default class EventManager<
  EventPayload extends EventPayloadInterface = Record<string, unknown>,
> implements EventManagerInterface<EventPayload>
{
  private eventHandlerMap: EventHandlerMap = {}
  private globalEventHandlers: GlobalEventHandler[] = []

  /**
   * Subscribe to all events
   * @param handler
   */
  subscribeGlobal(handler: GlobalEventHandler) {
    this.globalEventHandlers.push(handler)
  }

  /**
   * Dispatch an event
   *
   * @param eventName
   * @param payload
   * @param contextId
   */
  dispatchEvent<E extends keyof EventPayload>(
    eventName: E & string,
    payload: EventPayload[E],
    contextId?: string,
  ) {
    console.info(
      'MODULE_EVENT',
      eventName,
      payload,
      `context ${contextId || 'not defined'}`,
    )

    if (this.globalEventHandlers.length) {
      this.globalEventHandlers.forEach((handler: GlobalEventHandler) => {
        handler(eventName, payload, contextId)
      })
    }

    let subscriptors = this.eventHandlerMap[eventName] || []

    if (subscriptors.length === 0) {
      console.info(`no callback subscribed for event: ${eventName}`)

      return
    }

    if (contextId) {
      subscriptors = subscriptors.filter(
        ({ contextIds }) => contextIds && contextIds.includes(contextId),
      )
    }

    subscriptors.forEach((subscriptor) => {
      subscriptor.handler(payload)
    })
  }

  /**
   * Subcribe to an event
   *
   * @param eventName
   * @param handler
   * @param contextIds
   */
  subscribeEvent<E extends keyof EventPayload>(
    eventName: E & string,
    handler: EventHandler,
    contextIds?: string[],
  ) {
    const subscription = { handler, contextIds }

    this.eventHandlerMap[eventName] = this.eventHandlerMap[eventName] || []
    this.eventHandlerMap[eventName].push(subscription)

    return () => {
      this.eventHandlerMap[eventName] = this.eventHandlerMap[eventName].filter(
        (eventSubscriptor) => eventSubscriptor !== subscription,
      )
    }
  }
}
