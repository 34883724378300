// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowActionSettingsTile from './WorkflowActionSettingsTile.vue'
import WorkflowActionSettingsColumns from './WorkflowActionSettingsColumns'
import WorkflowActionSettingsDetail from './WorkflowActionSettingsDetail.vue'
import WorkflowActionSettingsReference from './WorkflowActionSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowActionSettingsTile', WorkflowActionSettingsTile)
    Vue.component('WorkflowActionSettingsDetail', WorkflowActionSettingsDetail)
    Vue.component(
      'WorkflowActionSettingsReference',
      WorkflowActionSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_ACTION_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowActionSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_ACTION_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowActionSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_ACTION_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowActionSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_ACTION_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowActionSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
