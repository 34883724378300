import { Action } from '@/tt-entity-design/src/schema-types'

type AssetActions = Action<'assets'> | 'entity'

type ValidActions<T extends AssetActions> = T

export type DisplayKeys =
  | ValidActions<'check-in'>
  | ValidActions<'check-out'>
  | ValidActions<'entity'>

export type DisplayOnOptions = {
  /**
   * Display this field
   */
  show?: boolean
  /**
   * Make this field required
   */
  required?: boolean
}

/**
 * Source of truth for the field names
 */
export const enum FieldName {
  /**
   * Text
   */
  Text = 'text',
  /**
   * Text area
   */
  Textarea = 'textarea',
  /**
   * Number
   */
  Number = 'number',
  /**
   * List
   */
  List = 'list',
  /**
   * Date
   */
  Date = 'date',
  /**
   * Time
   */
  Time = 'time',
  /**
   * Date & time
   */
  DateTime = 'datetime',
  /**
   * Check box
   */
  Checkbox = 'checkbox',
  /**
   * Signature
   */
  Signature = 'signature',
  /**
   * Picture
   */
  Picture = 'picture',
}

/**
 * @asType
 */
export type BaseField = {
  /**
   * Exact field name as expected by the API
   */
  type: FieldName
  /**
   * Field name
   */
  name: string

  /**
   * Field description
   */
  description?: string

  /**
   * Automatically generated and should be unique accross the account
   */
  uid: string

  /**
   * Available options depends on the Field type.
   */
  options?: Record<string, unknown>

  /**
   * When should this field be displayed
   */
  toDisplayOn?: {
    /**
     * During asset creation / edition
     */
    entity?: DisplayOnOptions

    /**
     * During asset check-in
     */
    'check-in'?: DisplayOnOptions

    /**
     * During asset check-out
     */
    'check-out'?: DisplayOnOptions
  }
}
