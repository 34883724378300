import hook from './GaugeChartWidgetHook'
import GaugeChartWidgetEditor from './GaugeChartWidgetEditor.vue'
import Vue from 'vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "GaugeChartWidget" */ './GaugeChartWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('GaugeChartWidgetEditor', GaugeChartWidgetEditor)

const GaugeChartWidget: WidgetTypeDefinition = {
  name: WidgetName.GAUGE_CHART_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    GaugeChartWidget: {
      view: {
        is: 'GaugeChartWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-looks',
    color: '#83c15a',
    thumbnail: require('./gauge-chart.png'),
    print: false,
  },
}

export default GaugeChartWidget
