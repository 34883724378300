// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import HolidayGroupsTile from './HolidayGroupsTile.vue'
import HolidayGroupsColumns from './HolidayGroupsColumns'
import HolidayGroupsDetail from './HolidayGroupsDetail.vue'
import HolidayGroupsReference from './HolidayGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('HolidayGroupsTile', HolidayGroupsTile)
    Vue.component('HolidayGroupsDetail', HolidayGroupsDetail)
    Vue.component('HolidayGroupsReference', HolidayGroupsReference)

    modularManager.addResourcePreset(
      Resources.HOLIDAY_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'HolidayGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: HolidayGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'HolidayGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'HolidayGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
