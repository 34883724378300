<template>
  <v-toolbar
    dense
    short
    height="35"
    flat
    :style="{ opacity: hover ? '1' : '0.5' }"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <v-spacer />

    <v-tooltip bottom :open-delay="500">
      <template #activator="{ on }">
        <v-btn v-bind="buttonProps" v-on="on" @click="deleteColumn()">
          <v-icon v-bind="iconProps">mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      {{ $t('widgets.dashboard.delete_column') }}
    </v-tooltip>
    <v-tooltip v-if="widgetExists" bottom :open-delay="500">
      <template #activator="{ on }">
        <v-btn
          v-bind="buttonProps"
          v-on="on"
          @click.stop="hook.edit(rowIndex, colIndex, tab)"
        >
          <v-icon v-bind="iconProps">mdi-cog-outline</v-icon>
        </v-btn>
      </template>
      {{ $t('widgets.dashboard.edit_this_widget') }}
    </v-tooltip>
  </v-toolbar>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import DashboardWidgetHook from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetHook'
import { WidgetModels } from '@/tt-widget-components/schemas-types'
import { isEmpty } from 'lodash'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { LayoutComponents } from '@/tt-app-layout'

type VueWithLayoutManager = VueConstructor<
  Vue & { layoutManager: LayoutManager }
>

export default (Vue as VueWithLayoutManager).extend({
  name: 'DashboardColumnEditToolbar',
  inject: ['layoutManager'],
  props: {
    hook: Object as PropType<DashboardWidgetHook>,
    widget: {
      type: Object as PropType<WidgetModels>,
      default: () => ({}),
    },
    tab: {
      type: Number,
      default: 0,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      buttonProps: {
        icon: true,
        small: true,
      },
      iconProps: {
        small: true,
      },
    }
  },
  computed: {
    widgetExists(): boolean {
      return !isEmpty(this.widget)
    },
    isSideSheetOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
  },
  methods: {
    closeSideSheet(): void {
      this.layoutManager.close(LayoutComponents.sideSheet)
    },
    deleteColumn(): void {
      if (this.isSideSheetOpen) {
        this.closeSideSheet()
      }
      this.hook.deleteColumn(this.rowIndex, this.colIndex)
    },
  },
})
</script>
