import { ContextAccountHandler } from '@/tt-widget-factory/context/ContextAccountHandler'
import { Resources } from '@/tt-entity-design/src/types'

import {
  AssetTrackingSettings,
  AssetTrackingSettingsActions,
  FetchAssetTrackingSettingsParams,
} from '../types'

/**
 * Fetch Asset Tracking Settings
 * Fetch the account settings for Asset Tracking
 *
 * The settings are based on several factors determined by the portal configuration
 * and reflected on this endpoint
 */

// Key for the session storage
const ASSET_TRACKING_SETTINGS_KEY = 'AT_SETTINGS_'

/**
 * Function to get the storage key based on the account ID
 *
 * @param accountId number
 * @returns string
 */
function getStorageKey(accountId: number): string {
  return `${ASSET_TRACKING_SETTINGS_KEY}${accountId}`
}

/**
 * Function to get the asset tracking settings from the session storage
 *
 * @param accountId number
 * @returns AssetTrackingSettings | null
 */
export function getAssetTrackingSettingsFromStorage(
  accountId: number,
): AssetTrackingSettings | null {
  const storedSettings = sessionStorage.getItem(getStorageKey(accountId))

  if (storedSettings) {
    try {
      return JSON.parse(storedSettings) as AssetTrackingSettings
    } catch (e) {
      console.warn('Unnable to recover Asset Tracking Settings from cache: ', e)

      return null
    }
  }

  return null
}

/**
 * Function to set the asset tracking settings in the session storage
 *
 * @param accountId number
 * @param settings AssetTrackingSettings
 */
export function setAssetTrackingSettingsInStorage(
  accountId: number,
  settings: AssetTrackingSettings,
): void {
  sessionStorage.setItem(getStorageKey(accountId), JSON.stringify(settings))
}

/**
 * Function to remove the asset tracking settings from the session storage
 */
export function removeAssetTrackingSettingsFromStorage(
  accountId: number,
): void {
  sessionStorage.removeItem(getStorageKey(accountId))
}

/**
 * Cache for the ongoing fetch promises
 */
const fetchPromiseCache: {
  [accountId: string]: Promise<AssetTrackingSettings>
} = {}

/**
 * Function to fetch the asset tracking settings
 *
 * @param appContext AppContext
 * @returns Promise<AssetTrackingSettings>
 */
export async function fetchAssetTrackingSettings({
  accountId,
  appContext,
}: FetchAssetTrackingSettingsParams): Promise<AssetTrackingSettings> {
  const account = accountId
    ? accountId
    : ContextAccountHandler(appContext).get()

  if (!account) {
    throw new Error('No account provided or set in the context.')
  }

  // Check if the settings are already in sessionStorage
  const settings = getAssetTrackingSettingsFromStorage(account)

  if (settings) {
    return settings
  }

  // Check if there is an ongoing fetch for the same account to avoid multiple requests
  if (!fetchPromiseCache[account]) {
    // If not, start a new fetch
    fetchPromiseCache[account] = (async () => {
      try {
        const response = await appContext.authModule
          .getApi()
          .httpClient.get(
            `${Resources.ASSETS}/accountsettings?accountId=${account}`,
          )

        const newSettings = response.data as AssetTrackingSettings
        setAssetTrackingSettingsInStorage(account, newSettings)

        return newSettings
      } catch (error) {
        console.error(
          'Error fetching asset tracking settings from the API',
          error,
        )
      } finally {
        // Clean up the cache to avoid stale promises
        delete fetchPromiseCache[account]
      }
    })()
  }

  // Return the ongoing promise for the current account
  return fetchPromiseCache[account]
}

/**
 * Check if the user can execute a given action (E.g. 'canCreate' or 'canList') for
 * a given resource in the selected account, based on the account settings.
 *
 * @param resourceName string
 * @param actionName AssetTrackingSettingsActions
 * @param settings AssetTrackingSettings
 * @returns boolean
 */
export function canExecuteAction(
  resourceName: string,
  actionName: AssetTrackingSettingsActions,
  settings: AssetTrackingSettings,
): boolean {
  const settingsNotPresent = !settings?.resources?.[resourceName]

  /**
   * If the settings for the given resource are not present,
   * we assume that the action is allowed to ensure backward compatibility.
   */
  if (settingsNotPresent) {
    return true
  }

  return !!settings.resources[resourceName]?.[actionName]
}
