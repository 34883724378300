// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplateFieldsTile from './ReportTemplateFieldsTile.vue'
import ReportTemplateFieldsColumns from './ReportTemplateFieldsColumns'
import ReportTemplateFieldsDetail from './ReportTemplateFieldsDetail.vue'
import ReportTemplateFieldsMetaCard from './ReportTemplateFieldsMetaCard.vue'
import ReportTemplateFieldsReference from './ReportTemplateFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportTemplateFieldsTile', ReportTemplateFieldsTile)
    Vue.component('ReportTemplateFieldsDetail', ReportTemplateFieldsDetail)
    Vue.component('ReportTemplateFieldsMetaCard', ReportTemplateFieldsMetaCard)
    Vue.component(
      'ReportTemplateFieldsReference',
      ReportTemplateFieldsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplateFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELDS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
