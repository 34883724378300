import Vue, { CreateElement, VueConstructor } from 'vue'
import { cloneDeep } from 'lodash'
import ContextManager from '@/tt-widget-factory/context/ContextManager'
import { ContextDictionary } from '@/tt-widget-factory'

type VueWithContextManager = VueConstructor<
  Vue & { contextManager: ContextManager }
>
/**
 * Simple component that emits the context change
 */
export default (Vue as VueWithContextManager).extend({
  name: 'ContextFilterModel',
  inject: ['contextManager'],
  watch: {
    'contextManager.context': {
      deep: true,
      immediate: true,
      handler(val: ContextDictionary) {
        this.$emit('input', cloneDeep(val))
      },
    },
  },
  render(h: CreateElement) {
    return h('span')
  },
})
