import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'customId',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'customId',
        backgroundColor: 'indigo darken-2',
        textColor: 'white',
      },
    },
  },
  'licensePlate',
  'make',
  'model',
  'color',
  {
    attributeName: 'ownershipType',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'ownershipType',
      },
    },
  },
  'vin',
] as ColumnInputDefinition[]
