import Vue from 'vue'
import { FileHandler } from './types'

/**
 * FileHandler" is a small service that enables you to create a state/dictionary
 * for storing the files returned by the API.
 * This way, we can retrieve the saved files by providing the file's ID.
 */

export const createFileHandler = (): FileHandler => {
  const state: Record<number, File> = Vue.observable({})

  const getFile = (fileId) => state[fileId]

  const setFile = (fileId, file: File) => {
    state[fileId] = file
  }

  return {
    getFile,
    setFile,
  }
}

export const fileHandler = {
  default: () => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        'if you want to use this service to handle a dictionary of File ID -> File please provide fileHandler',
      )
    }
    const fileHandler = createFileHandler()
    return () => fileHandler
  },
}
