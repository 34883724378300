<template>
  <v-btn
    text
    x-small
    :loading="isLoading"
    :disabled="isLoading"
    @click.stop="unAssignExceptionType(exceptionGroupItemId)"
  >
    <v-icon> mdi-close</v-icon>
  </v-btn>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '../../types'
import { ExceptionTypeGroupsActions } from '../exception-type-groups/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'

const translationScope = 'exception_groups_view.wizard.holidays'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ExceptionTypesUnassignBtn',
  inject: ['getItemHook'],
  inheritAttrs: false,
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    exceptionGroupsResource(): string {
      return Resources.EXCEPTION_TYPE_GROUPS
    },
    exceptionTypesResource(): string {
      return Resources.EXCEPTION_TYPES
    },
    exceptionGroupItemsResource(): string {
      return Resources.EXCEPTION_TYPE_GROUP_ITEMS
    },
    exceptionGroupItemId(): number {
      return this.itemHook.getRawValue('type.id')
    },
    exceptionGroupId(): number {
      return this.itemHook.getRawValue('group')
    },
    actionName(): string {
      return ExceptionTypeGroupsActions.UN_ASSIGN_TYPES
    },
  },
  created() {
    this.itemHook.addAttribute('group')
    this.itemHook.addAttribute('type.id')
  },
  methods: {
    translationKey(key: string): string {
      return `${translationScope}.${key}`
    },
    reload(resource: string): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: resource,
      })
    },
    async unAssignExceptionType(exceptionId: number) {
      this.isLoading = true
      const unAssignHolidays = {
        exceptionTypes: [exceptionId],
      }
      const { entityServices, eventManager } = this.$appContext

      try {
        await entityServices.persister.executeEntityAction(
          this.exceptionGroupsResource,
          this.actionName,
          this.exceptionGroupId,
          unAssignHolidays,
        )
        this.reload(this.exceptionTypesResource)
        this.reload(this.exceptionGroupItemsResource)
        eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
          message: this.$t(this.translationKey('unassign.success')),
        })
      } catch (err) {
        this.$crash.captureException(err)

        const messages = parseErrorMessages({
          actionName: this.actionName,
          error: err,
          resourceName: this.exceptionGroupsResource,
        })

        displayErrorMessages(messages, eventManager)

        eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
          message: this.$t(this.translationKey('unassign.failure')),
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
