import TListContentCard from './components/TListContentCard.vue'
import TChipLabel from './components/TChipLabel.vue'
import TChipLabelBoolean from './components/TChipLabelBoolean.vue'
import TChipsList from './components/TChipsList.vue'
import TLoadingWave from './components/TLoadingWave.vue'
import TLoadingBar from './components/TLoadingBar.vue'
import TThemeProvider from './components/TThemeProvider.vue'
import TTooltipOnOverflow from './components/TTooltipOnOverflow.vue'
import TTooltipIcon from './components/TTooltipIcon.vue'
import TAlert from './components/TAlert.vue'
import TCustomFieldsDetails from './components/TCustomFieldsDetails.vue'
import TChipNoValue from './components/TChipNoValue.vue'
import TLogo from './components/TLogo.vue'
import TButtonToggle from './components/TButtonToggle.vue'
import TButtonToggleMultiple from './components/TButtonToggleMultiple.vue'
import TPriorityIcon from './components/TPriorityIcon.vue'
import TTimelineItem from './components/TTimelineItem.vue'
import TPaginationUnknownTotal from './components/TPaginationUnknownTotal.vue'

export const install = (Vue) => {
  Vue.component('TThemeProvider', TThemeProvider)
  Vue.component('TListContentCard', TListContentCard)
  Vue.component('TChipLabel', TChipLabel)
  Vue.component('TChipLabelBoolean', TChipLabelBoolean)
  Vue.component('TChipsList', TChipsList)
  Vue.component('TLoadingWave', TLoadingWave)
  Vue.component('TLoadingBar', TLoadingBar)
  Vue.component('TTooltipOnOverflow', TTooltipOnOverflow)
  Vue.component('TTooltipIcon', TTooltipIcon)
  Vue.component('TAlert', TAlert)
  Vue.component('TCustomFieldsDetails', TCustomFieldsDetails)
  Vue.component('TChipNoValue', TChipNoValue)
  Vue.component('TLogo', TLogo)
  Vue.component('TButtonToggle', TButtonToggle)
  Vue.component('TButtonToggleMultiple', TButtonToggleMultiple)
  Vue.component('TPriorityIcon', TPriorityIcon)
  Vue.component('TTimelineItem', TTimelineItem)
  Vue.component('TPaginationUnknownTotal', TPaginationUnknownTotal)
}

export default { install }
