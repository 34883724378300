<template>
  <div v-if="showField">
    <v-row no-gutters class="ma-1">
      <v-col class="attribute-row-label" :cols="cols || 4">
        <slot name="title">
          <tt-attr-label v-bind="{ ...$props, ...$attrs }" />
        </slot>
      </v-col>
      <v-col class="ml-1 attribute-row-value">
        <slot>
          <div>
            <tt-attr
              v-bind="{ ...$props, ...$attrs }"
              :style="attrStyle"
              :text-truncate="textTruncate"
            />
          </div>
        </slot>
      </v-col>
    </v-row>
    <v-divider style="opacity: 0.5" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'EntityAttributeRow',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    cols: { type: Number, default: undefined },
    name: { type: String, required: true },
    textTruncate: { type: Boolean, default: true },
  },
  computed: {
    attrStyle(): string {
      return this.textTruncate ? 'text-overflow: ellipsis;' : ''
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        relationPrefix: this.relationPrefix,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
})
</script>

<style scoped>
.attribute-row-label {
  color: #888;
  font-size: 0.8em;
}

.attribute-row-value {
  font-size: 0.8em;
}
</style>
