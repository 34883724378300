import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import DateField from '@/tt-entity-forms/components/DateField.vue'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import schema from './schema.json'
import { DateFieldType, DATE } from './types'

const ui: FieldUI = {
  name: i18nFieldKey(DATE),
  color: 'blue',
  icon: 'mdi-calendar-range',
}

// @ts-ignore
const converter: FieldConverter = (field: DateFieldType) => ({
  getJsonSchema: () => {
    return {
      ...getBaseFieldJsonSchema(field),
      type: 'string',
      format: 'date',
    }
  },
  getViewOption: () => {
    return { is: DateField }
  },
})

export const DateMeta: FieldMeta = {
  field: DATE,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
