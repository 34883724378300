<template>
  <div style="position: relative">
    <TLoadingBar v-if="loading" />
    <TaskDispatchClientListAssets
      v-else-if="assetManager && hasAssets"
      hide-collapsible-header
    />
    <div v-else class="d-flex justify-center ma-10">
      <v-icon color="ttPrimary" v-text="'mdi-information-outline'" />
      <span
        class="ml-2"
        style="color: var(--v-ttPrimary-base)"
        v-text="$t('tt-entity-design.dispatch-tasks.no-assets')"
      ></span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import TaskDispatchClientListAssets from './TaskDispatchClientListAssets.vue'
import { AssetManagerName, prepareAssetManager } from '../asset-manager'
import { AssetManager } from '../asset-manager/types'
import { VueWithInjections } from '../../conflicts/types'

export default (Vue as VueWithInjections).extend({
  name: 'TaskDispatchClientListAssetsWrapper',
  components: {
    TaskDispatchClientListAssets,
  },
  inject: ['getItemHook'],
  provide(): { [AssetManagerName]: () => AssetManager } {
    return {
      assetManager: () => this.assetManager,
    }
  },
  data: () => ({
    loading: true,
    assetManager: null as AssetManager,
  }),
  computed: {
    clientId(): number {
      return (
        this.getItemHook().getRawValue('client.id') ||
        this.getItemHook().getRawValue('account.id')
      )
    },
    taskTypeId(): number {
      return this.getItemHook().getRawValue('taskType.id')
    },
    hasAssets(): boolean {
      return this.assetManager.hasAssets()
    },
  },
  async created() {
    await this.getAssetManager()
  },
  methods: {
    async getAssetManager(): Promise<void> {
      try {
        this.loading = true
        this.assetManager = await prepareAssetManager({
          auth: this.$auth,
          taskTypeId: this.taskTypeId,
          clientId: this.clientId,
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
