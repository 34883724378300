<template>
  <div class="tt-loading">
    <div class="tt-loading__bar" :class="animationClass" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

/**
 * Loading bar that can be used when an async operation is loading.
 *
 * This is a pure css animation that does not need re-paint,
 * therefore can be used even when the main JS thread is blocked by a long operation.
 */
export default Vue.extend({
  name: 'TLoadingBar',
  props: {
    /**
     * Change the animation from the default "left-to-right, left-to-right, ...",
     * to "left-to-right-to-left" (back and forth).
     */
    backAndForthAnimation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    animationClass(): string {
      return this.backAndForthAnimation
        ? `back-and-forth-animation`
        : `left-to-right-animation`
    },
  },
})
</script>

<style scoped lang="scss">
.tt-loading {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  overflow: hidden;

  &__bar {
    width: 100%;
    height: 100%;
  }

  &__bar.left-to-right-animation {
    animation: left-to-right 1s infinite linear;
    /**
      left and right sides of the bar are transparent with a fading gradient.
    */
    background: linear-gradient(
      to right,
      transparent 0% 40%,
      var(--v-ttPrimary-base) 60% 70%,
      transparent 90% 100%
    );
  }

  &__bar.back-and-forth-animation {
    animation: back-and-forth 3s infinite ease-in-out;
    background: linear-gradient(
      to right,
      transparent,
      var(--v-success-base) 20% 80%,
      transparent
    );
  }
}

/**
  Default infinite left-ro-right animation.

  Starts the animation hidden in the outer left side of the container,
  and ends it on the outer right side.
 */
@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/**
  Alternative infinite back-and-forth animation.
*/
@keyframes back-and-forth {
  0% {
    transform: translateX(-90%);
  }
  40% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(-90%);
  }
}
</style>
