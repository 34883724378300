// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteVendorsTile from './SiteVendorsTile.vue'
import SiteVendorsColumns from './SiteVendorsColumns'
import SiteVendorsDetail from './SiteVendorsDetail.vue'
import SiteVendorsReference from './SiteVendorsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SiteVendorsTile', SiteVendorsTile)
    Vue.component('SiteVendorsDetail', SiteVendorsDetail)
    Vue.component('SiteVendorsReference', SiteVendorsReference)

    modularManager.addResourcePreset(
      Resources.SITE_VENDORS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteVendorsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_VENDORS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteVendorsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_VENDORS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteVendorsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_VENDORS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteVendorsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
