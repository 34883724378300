// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContactsTile from './ContactsTile.vue'
import ContactsColumns from './ContactsColumns'
import ContactsDetail from './ContactsDetail.vue'
import ContactsMetaCard from './ContactsMetaCard.vue'
import ContactsReference from './ContactsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContactsTile', ContactsTile)
    Vue.component('ContactsDetail', ContactsDetail)
    Vue.component('ContactsMetaCard', ContactsMetaCard)
    Vue.component('ContactsReference', ContactsReference)

    modularManager.addResourcePreset(
      Resources.CONTACTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContactsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.CONTACTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ContactsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.CONTACTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContactsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTACTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ContactsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTACTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContactsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
