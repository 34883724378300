import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'shift.employee',
  'account',
  'shift.startsOn',
  'shift.endsOn',
  'totalMinutesExpected',
  'totalMinutesActual',
  'totalMinutesStatus',
] as ColumnInputDefinition[]
