<template>
  <div>
    <div class="d-flex align-center my-5">
      <h4 class="text-subtitle-2 pr-6" v-text="$t(step.title)" />
    </div>

    <div v-if="formSchema && hasEmployeesAssigned" class="d-flex mt-5 mb-6">
      <v-alert dense color="orange" text type="info" class="ma-0">
        {{ notEditableAlert }}
      </v-alert>
    </div>

    <v-skeleton-loader
      v-if="loading"
      style="max-width: 350px"
      class="my-5"
      type="text, text, sentences, sentences, text, button"
    />
    <json-form
      v-if="formSchema"
      v-model="basicInfoModel"
      :schema="formSchema"
      :form-options="formOptions"
      :name="rootName"
      style="max-width: 350px"
      @errors="errors = $event"
      @valid="errors = null"
      @debouncing="debouncing = $event"
    >
      <LeavePolicyUniqueField
        name="customId"
        :disabled="disableField"
        :max-length="9"
        :resource="resource"
        :response-errors="responseErrors"
      />
      <LeavePolicyUniqueField
        name="name"
        :resource="resource"
        :disabled="disableField"
      />
      <json-field name="description" maxlength="255" />
      <json-field
        v-if="!regionAssigned"
        name="region"
        :disabled="disableField"
        :value="regionAssigned"
      />
      <LeavePolicyRangeField
        name="payHoursPerDay"
        :disabled="disableField"
        :max="24"
        :min="1"
      />
      <json-field
        name="balanceResetReferenceMonth"
        :label="$t(`${translationBase}.reset-month-label`)"
        :placeholder="$t(`${translationBase}.reset-month-label`)"
        :disabled="disableField"
      />
    </json-form>
    <v-btn
      class="white--text"
      :disabled="!isValid"
      raised
      color="#4E69E9"
      style="text-transform: none"
      :loading="isSubmitting || loading"
      @click="editMode ? updatePolicy() : createPolicy()"
    >
      <v-icon left>{{ buttonIcon }}</v-icon>
      <span v-text="$t(buttonText)" />
    </v-btn>
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { camelCase } from 'lodash'
import { ErrorObject } from 'ajv'
import { FormSchemaTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { FormOptions } from '@tracktik/tt-json-schema-form'
import { getResourceDefinitions } from '@/tt-entity-forms'
import { Resources } from '@/tt-entity-design/src/types'
import LeavePolicyUniqueField from '@/tt-widget-views/leave-management/LeavePolicyUniqueField.vue'
import LeavePolicyRangeField from '@/tt-widget-views/leave-management/LeavePolicyRangeField.vue'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import {
  LeaveManagementApiError,
  LeavePolicy,
  LeavePolicyFormStep,
} from '@/tt-widget-views/leave-management/types'
import { LeavePolicyApiResponse } from '@/tt-entity-design/src/components/leave-policies/types'

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BasicInfoForm',
  components: { LeavePolicyUniqueField, LeavePolicyRangeField },
  props: {
    translationBase: {
      type: String,
      required: true,
    },
    step: {
      type: Object as PropType<LeavePolicyFormStep>,
      required: true,
    },
    leavePolicyId: {
      type: Number,
      default: null,
    },
    hasEmployeesAssigned: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      basicInfoModel: {} as LeavePolicy,
      employees: 0,
      debouncing: false,
      loading: false,
      isSubmitting: false,
      errors: null as { [key: string]: ErrorObject[] } | null,
      formSchema: {},
      responseErrors: {} as LeaveManagementApiError,
      rootName: 'LeavePoliciesPost',
    }
  },
  computed: {
    formOptions(): FormOptions {
      return {
        locale: this.$i18n.locale,
        definitions: getResourceDefinitions(this.$appContext),
        translateFunction: ResourceTranslator.getFormCallback(this.resource),
      }
    },
    isValid(): boolean {
      return !this.errors
    },
    editMode(): boolean {
      return !!this.leavePolicyId
    },
    disableField(): boolean {
      return this.hasEmployeesAssigned && this.editMode
    },
    regionAssigned(): number {
      return Number(this.$appContext.authModule.getContextRegionId())
    },
    resource(): Resources {
      return Resources.LEAVE_POLICIES
    },
    buttonIcon(): string {
      return this.editMode ? 'mdi-check' : 'mdi-arrow-right'
    },
    buttonText(): string {
      return this.editMode
        ? `${this.translationBase}.btn-save-policy`
        : `${this.translationBase}.btn-next-policy-items`
    },
    notEditableAlert(): string {
      return this.$t(`${this.translationBase}.not-editable-message`)
    },
  },
  async created(): Promise<void> {
    // Edit mode
    if (this.leavePolicyId) {
      this.loading = true
      await this.$appContext.entityServices.persister.api
        .get(this.resource, this.leavePolicyId)
        .then((data: LeavePolicy) => {
          const { effectiveStartDate, ...leavePolicy } = data
          if (leavePolicy) {
            this.basicInfoModel = leavePolicy
            this.$emit('policyName', leavePolicy.name)
          }
          this.loading = false
        })
    }
    // Creation mode
    this.formSchema =
      this.$appContext.widgetServices.resourceMetaManager.getFormSchema(
        this.resource,
        this.editMode ? FormSchemaTypes.EDIT : FormSchemaTypes.CREATE,
      )

    if (this.regionAssigned && !this.basicInfoModel.region)
      this.basicInfoModel.region = this.regionAssigned
  },
  methods: {
    createPolicy() {
      this.isSubmitting = true
      this.$appContext.entityServices.persister
        .addEntity(this.resource, this.basicInfoModel)
        .then((leavePolicyInfo: LeavePolicyApiResponse) => {
          this.$emit('savePolicyAndContinue', leavePolicyInfo.id)
          this.$emit('policyName', leavePolicyInfo.name)
          this.responseErrors = {}
        })
        .catch((error) => {
          this.errorHandler(error)
        })
        .finally(() => (this.isSubmitting = false))
    },
    updatePolicy() {
      this.isSubmitting = true
      this.$appContext.entityServices.persister
        .updateEntity(this.resource, this.basicInfoModel, this.leavePolicyId)
        .then((leavePolicyInfo: LeavePolicyApiResponse) => {
          this.$emit('nextStep')
          this.$emit('policyName', leavePolicyInfo.name)
          this.responseErrors = {}
        })
        .catch((error) => {
          this.errorHandler(error)
        })
        .finally(() => (this.isSubmitting = false))
    },
    errorHandler(error) {
      this.$crash.captureException(error)
      const hasData = !!error?.response?.data
      const hasMessage = !!error?.response?.data?.message

      if (hasData) {
        if (hasMessage) {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.response.data.message,
          })
        } else {
          /* 
            The API is returning the attribute keys as snake case but JSON Form expects them to be camel case
          */
          this.responseErrors = this.parseErrorKeys(error.response.data)
        }
      }
    },
    parseErrorKeys(error: LeaveManagementApiError): LeaveManagementApiError {
      return Object.entries(error).reduce((acc, [key, value]) => {
        const convertedKeyName = camelCase(key)
        return { ...acc, [convertedKeyName]: value }
      }, {})
    },
  },
})
</script>
