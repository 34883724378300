// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeesTile from './EmployeesTile.vue'
import EmployeesColumns from './EmployeesColumns'
import EmployeesDetail from './EmployeesDetail.vue'
import EmployeesMetaCard from './EmployeesMetaCard.vue'
import EmployeesReference from './EmployeesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeesTile', EmployeesTile)
    Vue.component('EmployeesDetail', EmployeesDetail)
    Vue.component('EmployeesMetaCard', EmployeesMetaCard)
    Vue.component('EmployeesReference', EmployeesReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.EMPLOYEES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: EmployeesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.EMPLOYEES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
