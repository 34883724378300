import Moment from 'moment-timezone'

/**
 * Convert date string 2024-08-09T12:38:12+00:00" to timestampMilliseconds.
 */
export const convertDateToTimeStamp = (date: string): number => {
  const momentDate = Moment(date)

  return momentDate.valueOf()
}

/**
 * Get now timestamp to timestampMilliseconds.
 */
export const nowTimeStamp = (): number => {
  return Moment().valueOf()
}
