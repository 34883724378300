import {
  DocumentInput,
  DocumentProviderInterface,
  DocumentProviders,
  DocumentServiceMethods,
} from '@/tt-entity-documents/types'

export interface HtmlDocumentInput extends DocumentInput {
  data: {
    html: string
  }
}

/**
 * Html document service provider
 */
export default class HtmlDocumentServiceProvider
  implements DocumentProviderInterface
{
  async getDocumentOptionsSchema(): Promise<null> {
    return null
  }

  getMethod(): DocumentServiceMethods {
    return DocumentServiceMethods.GET
  }

  async getDocumentUrl(input: HtmlDocumentInput): Promise<string> {
    return HtmlDocumentServiceProvider.htmlToDataUri(input.data.html)
  }

  static htmlToDataUri(html: string) {
    return `data:text/html;charset=utf-8,${encodeURIComponent(html)}`
  }

  getProviderTag(): DocumentProviders {
    return DocumentProviders.HTML
  }
}
