<template>
  <TLoadingBar v-if="!assetManager" />

  <div
    v-else
    class="w-100 d-flex flex-column"
    style="height: 800px; flex-grow: 0"
  >
    <TaskDispatchClientListAssets />

    <v-tabs v-model="currentTab" color="ttPrimary">
      <v-tab v-for="tab in tabs" :key="tab" class="w-100" v-text="tab" />
    </v-tabs>

    <v-tabs-items v-model="currentTab" class="fill-height" style="flex-grow: 1">
      <v-tab-item class="fill-height">
        <MobileDevicesList
          :on-select="onSelect"
          :region-id="regionId"
          :coordinates="coordinates"
          :task-type-id="taskTypeId"
        />
      </v-tab-item>

      <v-tab-item class="fill-height">
        <VendorsList
          :on-select="onSelect"
          :initial-city-name="cityName"
          :initial-state-name="stateName"
          :task-type-id="taskTypeId"
        />
      </v-tab-item>

      <v-tab-item class="fill-height">
        <WidgetFactory :widget="employeesModel" skip-validation />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { WidgetModels } from '@/tt-widget-components/types'
import { Resources } from '@/tt-entity-design/src/types'

import EmployeesTileForDispatchAssign from './EmployeesTileForDispatchAssign.vue'

import TaskDispatchClientListAssets from './TaskDispatchClientListAssets.vue'

import MobileDevicesList from './MobileDevicesList.vue'
import VendorsList from './VendorsList.vue'
import { AssetManagerName, prepareAssetManager } from '../asset-manager'
import { AssetManager } from '../asset-manager/types'
import { OnSelectCallback } from '../types'

export default defineComponent({
  name: 'DispatchAssignView',
  components: {
    TaskDispatchClientListAssets,
    MobileDevicesList,
    VendorsList,
  },
  provide(): { [AssetManagerName]: () => AssetManager } {
    return {
      assetManager: () => this.assetManager,
    }
  },
  props: {
    taskTypeId: {
      type: Number,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    regionId: {
      type: Number,
      required: true,
    },
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
    coordinates: {
      type: String,
      default: '',
    },
    cityName: {
      type: String,
      default: '',
    },
    stateName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentTab: 0,
      assetManager: null as AssetManager,
    }
  },
  computed: {
    tabs(): string[] {
      return ['Nearby', 'Vendors', 'Employees']
    },
    clientAndTaskIds(): string {
      return `${this.clientId}-${this.taskTypeId}`
    },
    employeesModel(): WidgetModels {
      return {
        title: '',
        is: 'ListWidget',
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: EmployeesTileForDispatchAssign,
          props: {
            // @ts-ignore
            onSelect: (payload) => this.onSelect(payload),
          },
        },
        query: {
          resource: Resources.EMPLOYEES,
          sort: {
            attribute: 'name',
            direction: 'ASC',
          },
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            allowSearch: true,
          },
        },
      }
    },
  },
  watch: {
    clientAndTaskIds: {
      async handler(newId, oldId) {
        if (newId && newId !== oldId) {
          this.assetManager = await prepareAssetManager({
            auth: this.$auth,
            taskTypeId: this.taskTypeId,
            clientId: this.clientId,
          })
        }
      },
      immediate: true,
    },
  },
})
</script>
