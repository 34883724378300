import { FormatterCallbackInterface } from './types'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import round from 'lodash/round'

export enum DistanceType {
  Miles = 'mi',
  Km = 'km',
  Default = 'default',
}

const INVALID_DISTANCE = -1

// @todo (INS-1416)
const asDistanceInMiles = (value) => {
  return `${value} mi`
}

// @todo (INS-1416)
const asDistanceInKm = (value) => {
  return `${value} km`
}

const FACTOR = 0.621371

const convertToKm = (distance: number): number => {
  return round(distance / FACTOR, 2)
}

const convertToMiles = (distance: number): number => {
  return round(distance * FACTOR, 2)
}

export const matchUserPref = (
  distanceTypes: any,
  userPref: UserPreferenceInterface,
) => {
  const userDistanceFormat = userPref.distanceFormat
  return (
    distanceTypes[userDistanceFormat] ?? distanceTypes[DistanceType.Default]
  )
}

export const asDistance: FormatterCallbackInterface = (value, userPref) => {
  if (value === INVALID_DISTANCE) {
    return null
  }

  const toDistanceFormat = matchUserPref(
    {
      [DistanceType.Miles]: asDistanceInMiles,
      [DistanceType.Km]: asDistanceInKm,
      [DistanceType.Default]: asDistanceInMiles,
    },
    userPref,
  )

  // Given the value parameter is always defined in unit "miles"
  const toDistanceUnit = matchUserPref(
    {
      [DistanceType.Km]: convertToKm,
      [DistanceType.Default]: () => value,
    },
    userPref,
  )

  const convertedValue = toDistanceUnit(value)

  return toDistanceFormat(convertedValue)
}

export default {
  asDistance,
}
