// export const MobileScheduleGroupsPlugin: PluginFunction<void> = (Vue) => {
// }

import { PluginFunction } from 'vue'

const ScheduleGroupCreateWizard = () =>
  import(
    /* webpackChunkName: "ScheduleGroupCreateWizard" */
    './components/ScheduleGroupCreateWizard.vue'
  )

const ScheduleGroupsView = () =>
  import(
    /* webpackChunkName: "ScheduleGroupsView" */
    './views/ScheduleGroupsView.vue'
  )

const ScheduleGroupList = () =>
  import(
    /* webpackChunkName: "ScheduleGroupsList" */
    './components/ScheduleGroupList.vue'
  )

const ScheduleOccurrenceDayAttr = () =>
  import('./components/ScheduleOccurrenceDayAttr.vue')

const ScheduleOccurrenceTimeRangeAttr = () =>
  import('./components/ScheduleOccurrenceTimeRangeAttr.vue')

export { ScheduleGroupsView }

export const MobileScheduleGroupsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ScheduleGroupCreateWizard', ScheduleGroupCreateWizard)
  Vue.component('ScheduleGroupsView', ScheduleGroupsView)
  Vue.component('ScheduleGroupList', ScheduleGroupList)
  Vue.component('ScheduleOccurrenceDayAttr', ScheduleOccurrenceDayAttr)
  Vue.component(
    'ScheduleOccurrenceTimeRangeAttr',
    ScheduleOccurrenceTimeRangeAttr,
  )
}
