<template>
  <div>
    <CollapsibleHeader>
      <template #title>
        <v-tooltip top :disabled="!isLabelOverflowing">
          <span v-text="scopeLabel" />
          <template #activator="{ on }">
            <span class="pl-4 hide-overflow" v-on="on">
              <span
                ref="label"
                :class="{ 'has-value': hasValue }"
                v-text="scopeLabel"
              />
              <v-badge v-if="hasValue" color="green" dot inline />
            </span>
          </template>
        </v-tooltip>
      </template>

      <template #right>
        <slot name="right">
          <v-btn v-if="hasValue" icon x-small @click.stop="clear">
            <v-icon small>close</v-icon>
          </v-btn>
        </slot>
      </template>

      <v-card flat min-height="90" min-width="250" class="level0 pa-0 ma-0">
        <ScopeFilterField
          :value="currentScopeValue"
          :scope-label="scopeLabel"
          @change="toggleScopeFilter"
        />
      </v-card>
    </CollapsibleHeader>
    <v-divider />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { isElementOverflowing } from '@/helpers/dom'
import ScopeFilterField from '@/tt-widget-components/components/filters/ScopeFilterField.vue'
import CollapsibleHeader from '@/tt-widget-entity-flow/components/CollapsibleHeader.vue'
import { ResourceScope } from '@/tt-widget-factory/services/resource-meta/types'
import { translateScope } from '@/tt-widget-entity-flow/ResourceTranslator'

export default Vue.extend({
  name: 'EntityToolbarFilterScopeCollapsibleSection',
  components: {
    CollapsibleHeader,
    ScopeFilterField,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    scopeName: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
    },
    resource: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      isLabelOverflowing: false,
    }
  },
  computed: {
    hasValue(): boolean {
      return this.currentScopeValue
    },
    scopeLabel(): string {
      return translateScope(this.resource, this.scopeFilterMeta.key) as string
    },
    scopeFilterMeta(): ResourceScope {
      const { resourceMetaManager } = this.$appContext.widgetServices
      const resourceScopes = resourceMetaManager.getResourceScopes(
        this.resource,
      )
      return resourceScopes[this.scopeName]
    },
    currentScopeValue(): boolean {
      return this.value.some((scope: string) => scope === this.scopeName)
    },
  },
  mounted() {
    this.checkLabelOverflow()
  },
  updated() {
    this.checkLabelOverflow()
  },
  methods: {
    clear(): void {
      this.$emit('input', false, this.scopeName)
      this.$emit('apply')
    },
    toggleScopeFilter(shouldApplyScopeFilter: boolean): void {
      this.$emit('input', shouldApplyScopeFilter, this.scopeName)
    },
    async checkLabelOverflow() {
      await Vue.nextTick()
      const labelElement = this.$refs['label'] as Element
      this.isLabelOverflowing = isElementOverflowing(labelElement)
    },
  },
})
</script>
<style scoped>
.has-value {
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}
</style>
