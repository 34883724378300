<template>
  <div class="flex--column">
    <MobileDevicesMap
      v-if="hook && coordinates"
      :hook="hook"
      :on-select="onSelect"
    />

    <v-banner
      v-else
      class="my-2 pa-2 d-flex justify-center"
      dark
      outlined
      rounded
      color="warning"
      v-text="$t('common.address_not_geocoded')"
    />

    <div v-if="hook" class="w-100 pa-3">
      <TButtonToggleMultiple
        v-model="accountTypeSelected"
        class="ma-1"
        :items="accountTypeItems"
      />

      <TButtonToggleMultiple
        v-model="connectedMethodSelected"
        class="ma-1"
        :items="connectedMethodItems"
      />

      <TButtonToggle
        v-model="sameRegionSelected"
        :text="sameRegionLabel"
        class="ma-1"
        :disabled="!regionId"
      />

      <TButtonToggle
        v-model="nearCoordinatesSelected"
        :text="closeByLabel"
        class="ma-1"
        :disabled="!coordinates"
      />

      <TButtonToggle
        v-model="skillsRequirementSelected"
        :text="skillRequirementLabel"
        class="ma-1"
      />
    </div>

    <WidgetFactory
      :key="`ListWidget-${widgetKey}`"
      :widget="listWidgetConfig"
      skip-validation
      @[RECORDS_LOADED]="updateGeoLocationsAndAssets"
      @update:hook="hook = $event"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  CollectionQuery,
  CustomFilter,
  Filter,
  WidgetModels,
} from '@/tt-widget-components/types'

import MobileDevicesTileForDispatchAssign from './MobileDevicesTileForDispatchAssign.vue'

import { ListWidgetEventNames } from '@/tt-widget-components/widgets/List/types'
import {
  DistanceMatrixSdk,
  createDistanceMatrixSdk,
} from '@tracktik/tt-geo-proxy'

import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import {
  ACCOUNT_TYPE_SCOPES,
  AccountTypeScope,
  CONNECTED_METHODS,
  MOBILE_DEVICES,
  NEAR_COORDINATES,
  SKILL_FOR_TASK_TYPE,
  Scope,
  localizeScope,
  ConnectedMethodScope,
} from './MobileDevices.utils'

import MobileDevicesMap from './MobileDevicesMap.vue'
import TButtonToggle from '@/tt-ui/components/TButtonToggle.vue'
import TButtonToggleMultiple, {
  Item,
} from '@/tt-ui/components/TButtonToggleMultiple.vue'
import { DistanceMatrixProvider } from './types'
import { OnSelectCallback } from '../types'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default Vue.extend({
  name: 'MobileDevicesList',
  components: {
    TButtonToggle,
    TButtonToggleMultiple,
    MobileDevicesMap,
  },
  provide(): DistanceMatrixProvider {
    return {
      distanceMatrixSdk: this.distanceMatrixSdk,
      destinationCoordinates: this.coordinates,
    }
  },
  props: {
    coordinates: {
      type: String,
      default: null,
    },
    taskTypeId: {
      type: Number,
      required: true,
    },
    regionId: {
      type: Number,
      required: true,
    },
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
  },
  data() {
    return {
      widgetKey: 0,
      hook: null as ListWidgetHook,
      accountTypeSelected: 'ZONE_DEVICE' as AccountTypeScope,
      connectedMethodSelected: 'CLOCKED_IN' as ConnectedMethodScope,
      sameRegionSelected: true,
      nearCoordinatesSelected: !!this.coordinates,
      skillsRequirementSelected: true,
    }
  },
  computed: {
    sameRegionLabel(): string {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.same-region',
      )
    },
    closeByLabel(): string {
      return this.$t('tt-entity-design.dispatch-tasks.assign-action.close-by')
    },
    skillRequirementLabel(): string {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.skills-requirement',
      )
    },
    distanceMatrixSdk(): DistanceMatrixSdk {
      return createDistanceMatrixSdk(this.$auth)
    },
    accountTypeItems(): Item[] {
      return ACCOUNT_TYPE_SCOPES.map((scope) => ({
        text: localizeScope(scope),
        value: scope,
      }))
    },
    connectedMethodItems(): Item[] {
      return CONNECTED_METHODS.map((scope) => ({
        text: localizeScope(scope),
        value: scope,
      }))
    },
    RECORDS_LOADED(): string {
      return ListWidgetEventNames.RECORDS_LOADED
    },
    widgetQuery(): CollectionQuery {
      const methodScope = this.connectedMethodSelected
        ? [this.connectedMethodSelected]
        : []
      const accountTypeScope = this.accountTypeSelected
        ? [this.accountTypeSelected]
        : []

      const scope: Scope[] = [
        'AVAILABLE_FOR_DISPATCH',
        ...methodScope,
        ...accountTypeScope,
      ]

      let filters: Filter[] = []

      if (this.sameRegionSelected) {
        filters = [
          ...filters,
          {
            attribute: 'position.account.region.id',
            operator: 'EQUAL',
            value: this.regionId,
          },
        ]
      }
      if (this.nearCoordinatesSelected && this.coordinates) {
        filters = [
          //@ts-ignore: operator type will be updated in this ticket API-2770
          ...filters,
          {
            attribute: 'lastLatitude',
            //@ts-ignore
            operator: 'NOTIN',
            value: ['null', 0],
          },
          {
            attribute: 'lastLongitude',
            //@ts-ignore
            operator: 'NOTIN',
            value: ['null', 0],
          },
        ]
      }

      const coordinatesFilter: CustomFilter = this.nearCoordinatesSelected &&
        this.coordinates && {
          filterName: NEAR_COORDINATES,
          value: this.coordinates,
        }

      const skillFilter: CustomFilter = this.skillsRequirementSelected && {
        filterName: SKILL_FOR_TASK_TYPE,
        value: this.taskTypeId,
      }

      const customFilters: CustomFilter[] = [
        coordinatesFilter,
        skillFilter,
      ].filter(Boolean)

      return {
        resource: MOBILE_DEVICES,
        scope,
        filters,
        customFilters,
      }
    },
    listWidgetConfig(): WidgetModels {
      return {
        title: '',
        is: WidgetName.LIST_WIDGET,
        allowActions: true,
        disableResourcePreviewOnClick: true,
        // add in the collection widget schema
        // needed for the assets to be displayed
        keepRelationList: true,
        listItem: {
          // @ts-ignore
          is: MobileDevicesTileForDispatchAssign,
          props: {
            // @ts-ignore
            onSelect: (payload) => this.onSelect(payload),
          },
        },
        query: this.widgetQuery,
      }
    },
  },
  watch: {
    /**
     * We force the widget to re-render when the widget config changes
     */
    listWidgetConfig() {
      this.widgetKey++
    },
  },
  methods: {
    async updateGeoLocationsAndAssets(entities: any[]) {
      if (!this.coordinates || !entities.length) return

      const entityIdLocationMapping = Object.fromEntries(
        entities.map((entity) => [
          [entity.id],
          `${entity.lastLatitude},${entity.lastLongitude}`,
        ]),
      )

      const origins: string[] = Object.values(entityIdLocationMapping)

      const destinations = [this.coordinates]

      await this.distanceMatrixSdk.fetchMatrixDistances(origins, destinations)
    },
  },
})
</script>
