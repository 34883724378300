<script lang="ts">
import Vue from 'vue'
import moment from 'moment-timezone'
import { FormatManager } from '@/helpers/formats'

export default Vue.extend({
  name: 'TimeStampConvert',
  props: {
    timeZone: String,
    format: String,
    value: String,
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    isDate: {
      default: true,
      type: Boolean,
    },
  },
  render(h) {
    const momentObject = moment(`${this.value}Z`).clone().tz(this.timeZone)

    let date: string | number = momentObject.format('YYYY-MM-DD HH:mm:ss')

    if (this.format) {
      date = FormatManager.parse(this.format, date, {
        dateTimeFormat: 'Y-m-d',
        locale: 'en',
        dateFormat: 'Ymd',
        timeFormat: 'h:ia',
        distanceFormat: '',
        timeZone: '',
        currency: '',
      })
    }

    return h('span', `${this.prefix}${date}${this.suffix}`)
  },
})
</script>
