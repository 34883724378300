import Vue, { PropType } from 'vue'
import { isEqual, cloneDeep } from 'lodash'

export default Vue.extend({
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.model = cloneDeep(val ?? [])
      },
    },
    model: {
      deep: true,
      handler(val) {
        val = cloneDeep(val ?? [])
        if (!isEqual(this.lastEmit, val)) {
          this.lastEmit = cloneDeep(val)
          this.$emit('input', cloneDeep(val))
        }
      },
    },
  },
  props: {
    name: String,
    value: Array,
    label: String,
    placeholder: String,
    errorMessages: Array as PropType<string[]>,
  },
  data() {
    return {
      lastEmit: null,
      model: [],
    }
  },
  computed: {
    hasErrors(): boolean {
      return this.errorMessages?.length > 0
    },
  },
  created() {
    this.lastEmit = cloneDeep(this.value ?? [])
    this.model = cloneDeep(this.lastEmit)
  },
})
