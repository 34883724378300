<template>
  <v-btn x-small @click.stop="click">
    {{ actionName }}
  </v-btn>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityActionButton',
  inject: ['getItemHook'],
  props: {
    actionName: {
      type: String,
      required: true,
    },
  },
  methods: {
    click() {
      const { entityId, resourceName } = this.getItemHook().entityReference

      this.$eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
        actionName: this.actionName,
        resourceName,
        entityId,
      })
    },
  },
})
</script>
