// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractOccurrencesTile from './ContractOccurrencesTile.vue'
import ContractOccurrencesColumns from './ContractOccurrencesColumns'
import ContractOccurrencesDetail from './ContractOccurrencesDetail.vue'
import ContractOccurrencesMetaCard from './ContractOccurrencesMetaCard.vue'
import ContractOccurrencesReference from './ContractOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContractOccurrencesTile', ContractOccurrencesTile)
    Vue.component('ContractOccurrencesDetail', ContractOccurrencesDetail)
    Vue.component('ContractOccurrencesMetaCard', ContractOccurrencesMetaCard)
    Vue.component('ContractOccurrencesReference', ContractOccurrencesReference)

    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ContractOccurrencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
