<template>
  <span v-if="isActionAvailable">
    <slot></slot>
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'TtAttrAction',
  inject: ['getItemHook'],
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  computed: {
    isActionAvailable(): boolean {
      return this.getItemHook().isActionAvailable(this.action)
    },
  },
  async created() {
    await this.getItemHook().needActions()
  },
})
</script>
