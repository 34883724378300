// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AccountsTile from './AccountsTile.vue'
import AccountsColumns from './AccountsColumns'
import AccountsDetail from './AccountsDetail.vue'
import AccountsMetaCard from './AccountsMetaCard.vue'
import AccountsReference from './AccountsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AccountsTile', AccountsTile)
    Vue.component('AccountsDetail', AccountsDetail)
    Vue.component('AccountsMetaCard', AccountsMetaCard)
    Vue.component('AccountsReference', AccountsReference)

    modularManager.addResourcePreset(
      Resources.ACCOUNTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AccountsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ACCOUNTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: AccountsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ACCOUNTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AccountsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ACCOUNTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AccountsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ACCOUNTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AccountsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
