import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'frequency',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'frequency',
        backgroundColor: 'indigo darken-2',
        textColor: 'white',
      },
    },
  },
  'name',
  'periodStartDate',
  'periodStartTime',
  {
    attributeName: 'isHolidayCountedInOvertime',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'isHolidayCountedInOvertime',
      },
    },
  },
  {
    attributeName: 'status',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'status',
      },
    },
  },
] as ColumnInputDefinition[]
