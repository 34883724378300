<template>
  <!-- file field -->
  <v-file-input
    outlined
    dense
    :value="file"
    :accept="acceptedFileTypes.listWithDots"
    :label="selectFileText"
    truncate-length="50"
    prepend-icon="mdi-file-table"
    :loading="isLoading || loading"
    @change="selectFile"
  />
  <!-- / file field -->
</template>
<script lang="ts">
/**
 * @desc Generic component field to handle "File Upload" which uploads any file type to the `file-upload` endpoint.
 * Keep in mind this upload itself creates orphan files on the server that are not associated with any resource.
 */
import Vue, { VueConstructor } from 'vue'
import { AxiosResponse } from 'axios'
import { genericFileUpload } from '@/tt-api-files'
import { FormHookProvider } from '@/tt-widget-components'
import {
  AcceptedFileTypesInterface,
  getAcceptedFileTypes,
} from '@/tt-entity-forms/accepted-file-types'
import { FileHandlerProvider } from '@/tt-entity-forms/components/utils/types'
import { fileHandler } from '@/tt-entity-forms/components/utils/file-handler'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & FileHandlerProvider>
).extend({
  name: 'FileUpload',
  inject: {
    fileHandler,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    selectFileText(): string {
      return this.$t('tt-widget-sharable.file-upload.select-file')
    },
    acceptedFileTypes(): AcceptedFileTypesInterface {
      return getAcceptedFileTypes()
    },
    file(): File {
      return this.fileHandler().getFile(this.value)
    },
  },
  methods: {
    async selectFile(file: File) {
      this.loading = true

      if (file) {
        this.uploadFile(file)
      } else {
        this.$emit('input', null)
        this.loading = false
      }
    },
    async uploadFile(file: File): Promise<void> {
      if (!file) {
        return
      }
      const { authModule } = this.$appContext
      const formData = new FormData()
      formData.append('file', file)

      const fileId = await genericFileUpload(authModule, formData)
        .then((data: AxiosResponse) => {
          return data.data.id ? Number(data.data.id) : null
        })
        .catch((err) => {
          this.loading = false

          this.$crash.captureException(err)
          return undefined
        })
        .finally(() => {
          this.loading = false
        })

      this.fileHandler().setFile(fileId, file)
      this.$emit('input', fileId)
    },
  },
})
</script>
