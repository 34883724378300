// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskOccurrenceSessionsTile from './SiteTaskOccurrenceSessionsTile.vue'
import SiteTaskOccurrenceSessionsColumns from './SiteTaskOccurrenceSessionsColumns'
import SiteTaskOccurrenceSessionsDetail from './SiteTaskOccurrenceSessionsDetail.vue'
import SiteTaskOccurrenceSessionsMetaCard from './SiteTaskOccurrenceSessionsMetaCard.vue'
import SiteTaskOccurrenceSessionsReference from './SiteTaskOccurrenceSessionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'SiteTaskOccurrenceSessionsTile',
      SiteTaskOccurrenceSessionsTile,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionsDetail',
      SiteTaskOccurrenceSessionsDetail,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionsMetaCard',
      SiteTaskOccurrenceSessionsMetaCard,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionsReference',
      SiteTaskOccurrenceSessionsReference,
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskOccurrenceSessionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
