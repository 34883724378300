// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import FilterRuleSetAssetsTile from './FilterRuleSetAssetsTile.vue'
import FilterRuleSetAssetsColumns from './FilterRuleSetAssetsColumns'
import FilterRuleSetAssetsDetail from './FilterRuleSetAssetsDetail.vue'
import FilterRuleSetAssetsReference from './FilterRuleSetAssetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('FilterRuleSetAssetsTile', FilterRuleSetAssetsTile)
    Vue.component('FilterRuleSetAssetsDetail', FilterRuleSetAssetsDetail)
    Vue.component('FilterRuleSetAssetsReference', FilterRuleSetAssetsReference)

    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_ASSETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetAssetsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_ASSETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: FilterRuleSetAssetsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_ASSETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetAssetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_ASSETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetAssetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
