import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const employeesCustomColumns: ColumnInputDefinition[] = [
  'customId',
  'name',
  {
    attributeName: 'employmentProfile.employeeClass.name',
    headerText:
      'tt-entity-design.leave-management.leave-policies.employee-type',
  },
  'region',
  'jobTitle',
  'currentLeavePolicy',
]
