<template>
  <TChipValue
    class="workflow-status-name"
    :text="humanizedDiff"
    :chip-class="computedStyles"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getDiffInMinutsFromNow } from '@/helpers/dates/getDiffInMinuts'
import { DispatchTaskAttribute } from './types'
import { getDurationHumanized } from '@/helpers/formats/dates/utils'
import { WorkflowInstanceAttribute } from '../workflow-instances/types'
import {
  WorkflowStatusAttribute,
  WorkFlowStatuses,
} from '../workflow-statuses/types'
import TChipValue from '@/tt-ui/components/TChipValue.vue'

const WORKFLOW_INSTANCE: DispatchTaskAttribute = 'workflowInstance'

// relation
const INSTANCE_STATUS: WorkflowInstanceAttribute = 'currentStatus'
const LAST_MODIFIED: WorkflowInstanceAttribute = 'modifiedOn'

// sub-relation
const WARNING_THRESHOLD: WorkflowStatusAttribute = 'warningThresholdInMinutes'
const ALERT_THRESHOLD: WorkflowStatusAttribute = 'alertThresholdInMinutes'

const WORKFLOW_STATUS_CLASSES: Record<WorkFlowStatuses, string> = {
  OK: 'font-weight-bold grey--text text--darken-2 grey lighten-4',
  ALERT:
    'text-capitalize font-weight-bold red--text text--darken-2 red lighten-5',
  WARNING:
    'text-capitalize font-weight-bold orange--text text--darken-2 orange lighten-4',
}

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'WorkflowStatusLastChanged',
  components: { TChipValue },
  inject: ['getItemHook'],
  computed: {
    lastModified(): string {
      return this.getItemHook().getRawValue(
        `${WORKFLOW_INSTANCE}.${LAST_MODIFIED}`,
      )
    },
    warningThreshold(): number {
      return this.getItemHook().getRawValue(
        `${WORKFLOW_INSTANCE}.${INSTANCE_STATUS}.${WARNING_THRESHOLD}`,
      )
    },
    alertThreshold(): number {
      return this.getItemHook().getRawValue(
        `${WORKFLOW_INSTANCE}.${INSTANCE_STATUS}.${ALERT_THRESHOLD}`,
      )
    },
    diffInMinutes(): number {
      if (!this.lastModified) return 0

      // should this calculation be done on the API side ?
      return getDiffInMinutsFromNow(this.lastModified)
    },
    humanizedDiff(): string {
      if (!this.lastModified) return 'N/A'

      return getDurationHumanized(this.lastModified, Date().toString())
    },
    viewStatus(): WorkFlowStatuses {
      if (this.diffInMinutes >= this.alertThreshold) return 'ALERT'

      if (this.diffInMinutes >= this.warningThreshold) return 'WARNING'

      return 'OK'
    },
    computedStyles(): string {
      return WORKFLOW_STATUS_CLASSES[this.viewStatus]
    },
  },
  created() {
    this.getItemHook().addAttribute(`${WORKFLOW_INSTANCE}.${LAST_MODIFIED}`)
    this.getItemHook().addAttribute(
      `${WORKFLOW_INSTANCE}.${INSTANCE_STATUS}.${WARNING_THRESHOLD}`,
    )
    this.getItemHook().addAttribute(
      `${WORKFLOW_INSTANCE}.${INSTANCE_STATUS}.${ALERT_THRESHOLD}`,
    )
  },
})
</script>

<style scoped>
.workflow-status-name {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  opacity: 1;
  line-height: 1.5;
  padding: 0 6px;
}

.counter--flashing {
  animation: flashing 1s linear infinite;
}

@keyframes flashing {
  50% {
    opacity: 50%;
  }
}
</style>
