<template>
  <TTimelineItem :index="index" :status="timelineStatus">
    <tt-tile>
      <tt-attr slot="title" name="taskType.name" />

      <div slot="line2" class="d-flex">
        <tt-attr name="rangeStartTime" />
        <span v-text="' - '" />
        <tt-attr name="rangeEndTime" />
      </div>

      <tt-attr
        slot="line3"
        prepend-icon="mdi-domain"
        prepend-icon-color="ttPrimary"
        name="zonePosition.name"
        hide-empty-value
      />

      <template v-if="logStatus" #actionRight>
        <RelationPrefixProvider :relation-prefix="logsAttr">
          <MobileScheduleOccurrenceLogStatus :status="logStatus" />
        </RelationPrefixProvider>
      </template>
    </tt-tile>
  </TTimelineItem>
</template>

<script lang="ts">
import Vue from 'vue'
import { TimelineItemStatus } from '@/tt-ui/components/TTimelineItem.vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import { Attribute, AttributeEnum } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'
import MobileScheduleOccurrenceLogStatus from '../mobile-schedule-occurrence-logs/MobileScheduleOccurrenceLogStatus.vue'
import RelationPrefixProvider from '@/tt-widget-components/components/RelationPrefixProvider.vue'

type LogStatus = AttributeEnum<
  typeof Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS,
  'status'
>

const logsAttr: Attribute<typeof Resources.MOBILE_SCHEDULE_OCCURRENCES> =
  'mobileScheduleOccurrenceLog'
const logStatusAttr: Attribute<
  typeof Resources.MOBILE_SCHEDULE_OCCURRENCE_LOGS
> = 'status'
const LOG_STATUS_ATTR = `${logsAttr}.${logStatusAttr}`

const STATUS_MAP: Record<LogStatus, TimelineItemStatus> = {
  COMPLETED: 'COMPLETED',
  ASSIGNED: 'ACTIVE',
  EN_ROUTE: 'ACTIVE',
  INITIATED: 'ACTIVE',
  MISSED: 'ERROR',
  ONSITE: 'ACTIVE',
  TO_REASSIGN: 'ERROR',
}

export default (Vue as VueWithItemHookInjection).extend({
  name: 'MobileScheduleOccurrenceTimelineTile',
  components: { MobileScheduleOccurrenceLogStatus, RelationPrefixProvider },
  inject: ['getItemHook'],
  props: {
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    logsAttr(): string {
      return logsAttr
    },
    logStatus(): LogStatus {
      return this.getItemHook().getRawValue(LOG_STATUS_ATTR)
    },
    timelineStatus(): TimelineItemStatus {
      return STATUS_MAP[this.getItemHook().getRawValue(LOG_STATUS_ATTR)]
    },
  },
  created() {
    this.getItemHook().addAttribute(LOG_STATUS_ATTR)
  },
})
</script>
