<template>
  <div class="text-center container">
    <WidgetTitle :hook="hook" :container="container" style="height: auto" />
    <v-img
      :src="thumbnail"
      contain
      eager
      max-height="250px"
      :style="style"
      @load="$emit('rendered')"
    />
    <CaptionBox>
      <span
        class="text-overline"
        v-text="$t('widget_edit.setup_incompleted')"
      ></span>
    </CaptionBox>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import BaseWidget from '@/tt-widget-components/components/BaseWidget'

export default BaseWidget.extend({
  name: 'WidgetFactoryInvalid',
  props: {
    widget: Object as PropType<{ is: string; title?: string }>,
  },
  computed: {
    style(): Record<string, unknown> {
      return {
        height: this.height + 'px',
        opacity: 0.3,
        flex: 'auto',
        filter: 'grayscale(1)',
      }
    },
    height(): number {
      return +this.container.height - 60
    },
    thumbnail(): string {
      const config =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          this.widget.is,
        )
      return config ? config.config.thumbnail : null
    },
  },
})
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
