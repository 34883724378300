<template>
  <EntityItem
    :key="resourceName + '-' + entityId"
    v-bind="{ entityId, resourceName }"
    :item-hook="itemHook"
    class="fill-height d-flex flex-column overflow-hidden"
  >
    <v-list two-line class="ma-0">
      <tt-preset-view type="listItem" />
    </v-list>

    <v-tabs v-model="tab" slider-color="orange" height="45">
      <v-tab v-for="item in items" :key="item.name" class="text-capitalize">
        {{ $t(item.name) }}
      </v-tab>
    </v-tabs>

    <template v-for="(item, i) in items">
      <CheckpointTourSessionsRelationList
        v-if="tab == i"
        :key="item.name"
        class="fill-height d-flex flex-column"
        :resource-name="resourceName"
        :entity-id="entityId"
        :entity="item.attribute"
        :item-hook="itemHook"
      />
    </template>
  </EntityItem>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { WidgetHookInterface } from '@/tt-widget-factory'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import CheckpointTourSessionsRelationList from './CheckpointTourSessionsRelationList.vue'

export enum CheckpointsAttr {
  CHECKPOINTS_SCANS = 'checkpointsScans',
  CHECKPOINTS = 'checkpoints',
}

export default Vue.extend({
  name: 'CheckpointTourSessionsRelationListPreview',
  components: {
    CheckpointTourSessionsRelationList,
  },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
  },
  data() {
    return {
      hook: null as WidgetHookInterface,
      tab: null,
      items: [
        {
          name: 'tt-entity-design.checkpoint-tour-sessions.attrs.checkpoints',
          attribute: CheckpointsAttr.CHECKPOINTS,
        },
        {
          name: 'tt-entity-design.checkpoint-tour-sessions.attrs.checkpoints-scanned',
          attribute: CheckpointsAttr.CHECKPOINTS_SCANS,
        },
      ],
    }
  },
})
</script>
