import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'customId',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'customId',
        themeKey: 'tertiary',
      },
    },
  },
  'region',
  {
    attributeName: 'subType',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'subType',
        themeKey: 'muted',
      },
    },
  },
  'name',
  'positions',
  'address',
] as ColumnInputDefinition[]
