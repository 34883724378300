<template>
  <v-menu v-if="hasMatchingAssets" open-on-hover top offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        x-small
        class="assets--view-btn"
        depressed
        v-on="on"
      >
        {{ taskAssetLabel }}
      </v-btn>
    </template>

    <div class="level0 pa-3">
      <div v-if="matchingZoneAssets.length > 0" class="flex--column w-100">
        <div class="pl-3 pb-3 overline" v-text="zoneAssetsLabel" />
        <TaskAssetsTree :assets="matchingZoneAssets" mark-as-checked />
      </div>

      <div v-if="matchingSiteAssets.length > 0" class="flex--column w-100">
        <div class="pl-3 pb-3 overline" v-text="siteAssetsLabel" />
        <TaskAssetsTree :assets="matchingSiteAssets" mark-as-checked />
      </div>
    </div>
  </v-menu>

  <div v-else-if="siteAssets.length || zoneAssets.length">
    <v-chip small class="opacity-faded">{{ noMatchingAssetLabel }}</v-chip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import TaskAssetsTree from './TaskAssetsTree.vue'
import { useAssetManager } from '../asset-manager'
import { TreeViewAsset } from '../asset-manager/types'

export default Vue.extend({
  name: 'UserMatchingTaskAssets',
  components: {
    TaskAssetsTree,
  },
  props: {
    assets: {
      type: Array as PropType<{ id: number }[]>,
      default: () => [] as { id: number }[],
    },
  },
  setup() {
    const assetManager = useAssetManager()

    return { assetManager }
  },
  computed: {
    taskAssetLabel(): string {
      const label = this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.task-assets',
      )
      const count =
        this.matchingSiteAssets.length + this.matchingZoneAssets.length

      return `${count} ${label}`
    },
    zoneAssetsLabel(): string {
      const label = this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.zone-assets',
      )
      const count = this.matchingZoneAssets.length

      return `${label} (${count})`
    },
    siteAssetsLabel(): string {
      const label = this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.site-assets',
      )
      const count = this.matchingSiteAssets.length

      return `${label} (${count})`
    },
    noMatchingAssetLabel(): string {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.no-matching-asset',
      )
    },
    siteAssets(): TreeViewAsset[] {
      return this.assetManager().getSiteAssets()
    },
    zoneAssets(): TreeViewAsset[] {
      return this.assetManager().getZoneAssets()
    },
    userAssetIds(): number[] {
      return this.assets.map((item) => item.id)
    },
    hasMatchingAssets(): boolean {
      return !!(
        this.assetManager() &&
        this.matchingSiteAssets.length + this.matchingZoneAssets.length
      )
    },
    matchingSiteAssets(): TreeViewAsset[] {
      return this.siteAssets.filter(({ id }) => this.userAssetIds.includes(id))
    },
    matchingZoneAssets(): TreeViewAsset[] {
      return this.zoneAssets.filter(({ id }) => this.userAssetIds.includes(id))
    },
  },
})
</script>

<style scoped>
.assets--view-btn {
  color: var(--v-success-base);
  background-color: var(--v-success-lighten5) !important;
}
</style>
../asset-manager/helpers
