// Enter your custom installation here

import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'

import ActualDurationInMinuteAttr from '../ActualDurationInMinuteAttr.vue'
import DispatchTasksDetailType from './DispatchTasksDetailType.vue'
import DispatchTasksDetailPriceTier from './DispatchTasksDetailPriceTier.vue'
import DispatchTasksDetailLocation from './DispatchTasksDetailLocation.vue'
import DispatchTasksDetailDate from './DispatchTasksDetailDate.vue'
import WorkflowStatusLastChanged from './WorkflowStatusLastChanged.vue'
import DispatchTasksClientLocationAttr from './DispatchTasksClientLocationAttr.vue'
import DispatchTasksActionBar from './DispatchTasksActionBar.vue'
import DispatchTasksForm from './form/DispatchTasksForm.vue'
import DispatchTasksPriorityAttr from './DispatchTasksPriorityAttr.vue'
import DispatchTasksAssign from './DispatchTasksAssign.vue'
import TaskDispatchClientListAssetsWrapper from './assign/TaskDispatchClientListAssetsWrapper.vue'
import WorkflowInstanceLogsListWrapper from './WorkflowInstanceLogsListWrapper.vue'
import DispatchAssignView from './assign/DispatchAssignView.vue'
import DispatchTaskNotesListWidget from './notes/DispatchTaskNotesListWidget.vue'
import DispatchTasksAssignmentAttr from './DispatchTasksAssignmentAttr.vue'
import DispatchTasksReportWrapper from './DispatchTasksReportWrapper.vue'
import DispatchTasksKeyAttr from './DispatchTasksKeyAttr.vue'
import { Resources } from '../../types'
import { Attribute } from '../../schema-types'

const DISPATCH_TASKS = Resources.DISPATCH_TASKS

const createRelationListTab = (
  attributeName: Attribute<'dispatch-tasks'>,
): PreviewTabInterface => ({
  title: createI18nResourceKey(DISPATCH_TASKS, attributeName),
  is: 'EntityRelationListCustomPreviewWrapper',
  props: {
    attributeName,
  },
})

export default {
  install(Vue) {
    Vue.component('ActualDurationInMinuteAttr', ActualDurationInMinuteAttr)
    Vue.component('DispatchTasksDetailType', DispatchTasksDetailType)
    Vue.component('DispatchTasksDetailPriceTier', DispatchTasksDetailPriceTier)
    Vue.component('DispatchTasksDetailLocation', DispatchTasksDetailLocation)
    Vue.component('DispatchTasksDetailDate', DispatchTasksDetailDate)
    Vue.component('WorkflowStatusLastChanged', WorkflowStatusLastChanged)
    Vue.component(
      'DispatchTasksClientLocationAttr',
      DispatchTasksClientLocationAttr,
    )
    Vue.component('DispatchTasksForm', DispatchTasksForm)
    Vue.component('DispatchTasksPriorityAttr', DispatchTasksPriorityAttr)
    Vue.component('DispatchTasksAssign', DispatchTasksAssign)
    Vue.component('DispatchAssignView', DispatchAssignView)
    Vue.component('DispatchTasksAssignmentAttr', DispatchTasksAssignmentAttr)
    Vue.component('DispatchTasksReportWrapper', DispatchTasksReportWrapper)
    Vue.component('DispatchTasksKeyAttr', DispatchTasksKeyAttr)

    registerResourceForm(DISPATCH_TASKS, 'DispatchTasksAssign', 'assign')

    const reportRelationDetails: PreviewTabInterface = {
      title: createI18nResourceKey(DISPATCH_TASKS, 'report'),
      is: 'DispatchTasksReportWrapper',
      props: {
        name: 'report',
        type: PresetTypes.DETAILS_CARD,
      },
    }

    addPreviewTab(DISPATCH_TASKS, reportRelationDetails)

    const taskTypeDetail: PresetItem = {
      title: 'res.dispatch-tasks.attr.taskType.label',
      data: { is: 'DispatchTasksDetailType', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    const priceTierDetail: PresetItem = {
      title: 'res.dispatch-tasks.attr.priceTier.label',
      data: { is: 'DispatchTasksDetailPriceTier', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    const locationDetail: PresetItem = {
      title: 'tt-entity-design.location',
      data: { is: 'DispatchTasksDetailLocation', opened: false },
      order: 3,
      level: PresetLevels.DEFAULTS,
    }

    const dateDetail: PresetItem = {
      title: 'tt-entity-design.dateTimeAndDuration',
      data: { is: 'DispatchTasksDetailDate', opened: false },
      order: 4,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      DISPATCH_TASKS,
      PresetTypes.DETAILS_CARD,
      [taskTypeDetail, priceTierDetail, locationDetail, dateDetail],
    )

    addPreviewTab(DISPATCH_TASKS, {
      title: createI18nResourceKey(DISPATCH_TASKS, 'notes'),
      is: DispatchTaskNotesListWidget,
    })

    addPreviewTab(DISPATCH_TASKS, {
      title: createI18nResourceKey(DISPATCH_TASKS, 'logs'),
      is: WorkflowInstanceLogsListWrapper,
    })
    addPreviewTab(
      DISPATCH_TASKS,
      createRelationListTab('childrenDispatchTasks'),
    )
    addPreviewTab(DISPATCH_TASKS, {
      title: createI18nResourceKey(Resources.ASSET_TYPES, 'assets'),
      is: TaskDispatchClientListAssetsWrapper,
    })

    modularManager.registerActionBar(DISPATCH_TASKS, {
      is: DispatchTasksActionBar,
    })
  },
}
