<template>
  <div class="fill-height d-flex flex-column">
    <WidgetTitle :hook="hook" :container="container" />
    <v-sheet class="rich-text fill-height">
      <VueMarkdown :source="hook.state.widget.text" />
    </v-sheet>
  </div>
</template>

<script lang="ts">
import VueMarkdown from 'vue-markdown'
import BaseWidget from '@/tt-widget-components/components/BaseWidget'

export default BaseWidget.extend({
  name: 'RichTextWidget',
  components: {
    VueMarkdown,
  },
  mounted() {
    this.$emit('rendered')
  },
})
</script>

<style scoped>
.rich-text {
  overflow-y: scroll;
  margin-left: 6px;
}
</style>
