<template>
  <tt-tile v-bind="$props">
    <template #icon>
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </template>

    <tt-attr slot="title" name="name" />
  </tt-tile>
</template>

<script lang="ts">
import Vue from 'vue'

import { REPORT_LIBRARY_ICON, REPORT_LIBRARY_ICON_COLOR } from './constants'

export default Vue.extend({
  name: 'ReportLibrariesTile',
  computed: {
    icon(): string {
      return REPORT_LIBRARY_ICON
    },
    iconColor(): string {
      return REPORT_LIBRARY_ICON_COLOR
    },
  },
})
</script>
