<template>
  <tt-tile v-bind="$props">
    <ResourceAllowedOperations
      v-slot="{ allowsActions }"
      :resource-name="resourceName"
      :style="{ width: '100%' }"
    >
      <div class="d-flex align-center">
        <div class="flex-grow-1">
          <tt-attr
            class="font-weight-bold text-body-2"
            name="exceptionType.label"
          />
        </div>

        <div class="flex-grow-1">
          <tt-if-attr name="perform" :value="true">
            <div class="d-flex align-center justify-end">
              <div class="text-center">
                <div
                  class="time-label text-secondary"
                  v-text="$t(translationKey('time-from'))"
                />
                <tt-attr class="time-value text-h6" name="timeFrom" />
              </div>
              <div class="mx-3">-</div>
              <div class="text-center">
                <div
                  class="time-label text-secondary"
                  v-text="$t(translationKey('time-to'))"
                />
                <tt-attr class="time-value text-h6" name="timeTo" />
              </div>
            </div>
          </tt-if-attr>

          <tt-if-attr name="perform" :value="false">
            <div class="do-not-perform text-right">
              <span
                class="font-weight-bold text-secondary text-body-2"
                v-text="$t(translationKey('not-perform'))"
              />
            </div>
          </tt-if-attr>
        </div>

        <div v-if="allowsActions" class="ml-5">
          <v-btn
            icon
            color="level1Control"
            :title="$t(translationKey('remove-btn'))"
            @click.stop="confirmRemove"
          >
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </ResourceAllowedOperations>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout'

import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskScheduleExceptionTypesTile',
  inject: ['getItemHook'],
  computed: {
    entityId(): number {
      return this.getItemHook().get('id')
    },
    resourceName(): Resources {
      return Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES
    },
    translationScope(): string {
      return 'tt-entity-design.site-task-schedule-exception-types.tile'
    },
  },
  created() {
    this.getItemHook().addAttribute('id')
  },
  methods: {
    confirmRemove(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: this.$t(`${this.translationScope}.confirm-deletion-dialog`),
        accept: this.remove,
      })
    },
    remove(): void {
      this.$appContext.entityServices.persister.executeEntityAction(
        this.resourceName,
        'remove',
        this.entityId,
      )
    },
    translationKey(key: string): string {
      return `${this.translationScope}.${key}`
    },
  },
})
</script>

<style scoped>
.text-secondary {
  color: var(--v-level1Control-base);
}

.time-label {
  font-size: 13px;
  line-height: 23px;
}

.time-value {
  color: var(--v-primary-base);
  line-height: 23px;
}
</style>
