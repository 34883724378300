<template>
  <v-container class="d-flex justify-center">
    <v-btn-toggle
      :value="model.value"
      color="success"
      borderless
      rounded
      @change="setValue"
    >
      <v-btn v-for="(label, key) in labelList" :key="key" :value="key" small>
        {{ label }}
      </v-btn>
    </v-btn-toggle>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  Filter,
  FilterOperatorType,
} from 'tracktik-sdk/lib/common/entity-filters'
import { translateBooleanAttributeValue } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  Attribute,
  AttributeLabels,
} from '@/tt-widget-factory/services/resource-meta/types'
import VueI18n from 'vue-i18n'
import { mapValues } from 'lodash'

export default Vue.extend({
  name: 'FilterBoolean',
  props: {
    value: {
      type: Object as PropType<Filter>,
      required: true,
    },
    attributeMeta: {
      type: Object as PropType<Attribute>,
      required: true,
    },
  },
  computed: {
    model(): Filter {
      return this.value
    },
    labelList(): AttributeLabels {
      const { resource, name, labels } = this.attributeMeta

      const translate = (value, key): VueI18n.TranslateResult => {
        return translateBooleanAttributeValue(resource, name, key === 'true')
      }

      return mapValues(labels.list, translate)
    },
  },
  methods: {
    setValue(value) {
      const newFilter = {
        attribute: this.model.attribute,
        operator: FilterOperatorType.EQUAL,
        value,
      }
      this.$emit('input', newFilter)
    },
  },
})
</script>
