<template>
  <DispatchTasksSlaTime :time-object="timeObject" />
</template>

<script lang="ts">
import Vue from 'vue'
import { formatMinutesToTimeObject } from '@/helpers/dates/formatMinutesToTimeObject'
import { getDiffInMinutsFromNow } from '@/helpers/dates/getDiffInMinuts'
import DispatchTasksSlaTime from './DispatchTasksSlaTime.vue'

export default Vue.extend({
  name: 'DispatchTasksSlaCountdown',
  components: {
    DispatchTasksSlaTime,
  },
  props: {
    initalTime: {
      type: String,
      required: true,
    },
    slaMinutes: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      diffInMinutes: getDiffInMinutsFromNow(this.initalTime),
    }
  },
  computed: {
    timeObject(): { days: number; hours: number; minutes: number } {
      return formatMinutesToTimeObject(this.diffInMinutes)
    },
    hasTimeLeft(): boolean {
      return this.diffInMinutes < this.slaMinutes
    },
  },
  watch: {
    hasTimeLeft() {
      this.$emit('update')
    },
  },
  mounted() {
    this.startCountdown()
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        this.diffInMinutes = getDiffInMinutsFromNow(this.initalTime)
      }, 1000)
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
  },
})
</script>
