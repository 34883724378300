// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MeTile from './MeTile.vue'
import MeColumns from './MeColumns'
import MeDetail from './MeDetail.vue'
import MeMetaCard from './MeMetaCard.vue'
import MeReference from './MeReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MeTile', MeTile)
    Vue.component('MeDetail', MeDetail)
    Vue.component('MeMetaCard', MeMetaCard)
    Vue.component('MeReference', MeReference)

    modularManager.addResourcePreset(Resources.ME, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'MeTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ME, PresetTypes.COLUMNS, {
      title: 'Default',
      data: MeColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ME, PresetTypes.DETAILS_CARD, {
      title: 'Default',
      data: { is: 'MeDetail' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ME, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'MeMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ME,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MeReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
