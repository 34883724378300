<template>
  <div>
    <FileHandlerProvider>
      <json-field name="fileId" as="FileUpload" />
    </FileHandlerProvider>
    <FileUploadAvailableFileFormat />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetTypesAssociateFileForm',
})
</script>
