<template>
  <JsonBoolean
    v-slot="{ name, definitionName, value, setValue, localesObject, on }"
    v-bind="$attrs"
  >
    <!-- UI Component here -->
    <v-radio-group v-model="selectedOption" v-on="on">
      <v-list-item-group>
        <v-list-item
          v-for="(option, index) in localesObject"
          :key="index"
          @click="
            setValue(index === 'true')
            selectedOption = index
          "
        >
          <v-list-item-action>
            <v-radio :value="index" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="option.label" />
            <v-list-item-subtitle
              class="text-wrap"
              v-text="option.description"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-radio-group>
  </JsonBoolean>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'BooleanList',
  data() {
    return {
      selectedOption: true,
    }
  },
})
</script>
