<template>
  <span>
    <tt-attr :prepend-icon="icon" name="label" />
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { reportTemplateFieldIconMap, ReportTemplateFieldType } from './types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplateFieldsReference',
  inject: ['getItemHook'],
  computed: {
    icon(): string {
      return reportTemplateFieldIconMap[this.type] ?? 'mdi-link'
    },
    type(): ReportTemplateFieldType {
      return this.getItemHook().getRawValue('type') as ReportTemplateFieldType
    },
  },
  created() {
    this.getItemHook().addAttribute('type')
  },
})
</script>
