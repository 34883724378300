<template>
  <div>
    <v-container>
      <json-field
        class="logs--status"
        name="status"
        :additional-query-options="queryWorkflowStatuses"
        :loading="loading"
      />
      <json-field name="user" />
      <json-field name="createdOn" />
    </v-container>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery, FormHookProvider } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'
import { CustomResponseInterface } from '@/tt-entity-design/src/components/workflow-instance-logs/types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'WorkflowInstanceLogsFormEdit',
  inject: ['formHook'],
  data: () => ({
    loading: true,
    workflowId: null,
    response: null,
  }),
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    queryWorkflowStatuses(): Partial<CollectionQuery> {
      return {
        filters: [
          {
            value: this.workflowId,
            operator: FilterOperatorType.EQUAL,
            attribute: 'workflow',
          },
        ],
      }
    },
  },
  async created() {
    this.workflowId = await this.$auth
      .getApi()
      .get(Resources.WORKFLOW_INSTANCE_LOGS, this.itemHook.getEntityId(), {
        fields: [{ attribute: 'workflowInstance.workflow.id' }],
      })
      .then(
        ({ workflowInstance }: CustomResponseInterface) =>
          workflowInstance.workflow.id,
      )

    this.loading = false
  },
})
</script>
<style scoped>
.logs--status ::v-deep span {
  flex-grow: 0 !important;
}
</style>
