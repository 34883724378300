import { WidgetSchema } from '@/tt-widget-factory'

export default {
  type: 'object',
  title: 'Data Table',
  required: ['title', 'is', 'query'],
  properties: {
    is: {
      enum: ['DataTableWidget'],
    },
    title: {
      title: 'Widget Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Widget Title',
      type: 'string',
    },
    query: {
      title: 'Query',
      $ref: '#/definitions/CollectionQuery',
    },
    component: {
      $ref: '#/definitions/DataTableOptions',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    contextFilters: {
      $ref: '#/definitions/DefaultContextFilters',
    },
    allowActions: {
      title: 'Allow Actions',
      type: 'boolean',
    },
    allowCreation: {
      title: 'Allow Creation',
      type: 'boolean',
    },
    allowDownload: {
      $ref: '#/definitions/AllowDownloadCsvPdf',
    },
    selectionMode: {
      title: 'Selection Mode',
      type: 'string',
      enum: ['Single', 'Multiple'],
    },
    expandableAttribute: {
      title: 'Expandable child mapping attribute',
      description:
        'That allows to pass a relation list attribute of a resource. It will add an "expandable arrow" on each entity (each row) that has at least 1 item in its relation list attribute.',
      type: 'string',
    },
    allowLiveUpdate: {
      title: 'Allow Live Update',
      description:
        'Allow the datatable to automatically refresh its data when a change is received from the API for that resource. Will use Pusher internally.',
      type: 'boolean',
    },
  },
  definitions: {
    Pagination: {
      title: 'Pagination',
      type: 'object',
      properties: {
        pageSize: {
          title: 'Page Size',
          type: 'number',
          default: 50,
        },
        pageSizeOptions: {
          title: 'Page Size Options',
          type: 'array',
          items: {
            type: 'number',
          },
          default: [10, 20, 50, 100],
        },
      },
    },
    ColumnComponent: {
      title: 'Column Component',
      $ref: '#/definitions/ComponentReference',
    },
    DataTableOptions: {
      title: 'Table Options',
      type: 'object',
      properties: {
        columnHeaderFilters: {
          title: 'Enable Search and Filter',
          $ref: '#/definitions/EnableColumnHeaderFilters',
        },

        pagination: {
          $ref: '#/definitions/Pagination',
        },

        columns: {
          title: 'Columns',
          type: 'array',
          items: {
            $ref: '#/definitions/ColumnDefinition',
          },
        },
        downloadColumns: {
          title: 'Download Columns',
          type: 'array',
          items: {
            $ref: '#/definitions/ColumnDefinition',
          },
        },
      },
    },
    ColumnDefinition: {
      oneOf: [
        {
          title: 'Full column definition',
          $ref: '#/definitions/Column',
        },
        {
          title: 'Attribute Name',
          $ref: '#/definitions/ResourceAttributeName',
        },
      ],
    },
    Column: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          type: 'string',
          title: 'Name of Column (internal)',
        },
        headerText: {
          title: 'Header text',
          type: 'string',
        },
        allowSorting: {
          title: 'Allow Sorting',
          default: true,
          type: 'boolean',
        },
        allowFilters: {
          title: 'Allow filters',
          default: true,
          type: 'boolean',
        },
        attributeName: {
          title: 'Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        format: {
          title: 'Formatters',
          $ref: '#/definitions/Formatters',
        },
        style: {
          title: 'Column style',
          additionalProperties: false,
          type: 'object',
          properties: {
            clipMode: {
              type: 'string',
              enum: ['Clip', 'Ellipsis', 'EllipsisWithTooltip'],
            },
            cssClass: {
              $ref: '#/definitions/CssClass',
            },
            textAlign: {
              $ref: '#/definitions/TextAlign',
            },
            minWidth: {
              title: 'Min width',
              type: 'number',
            },
            maxWidth: {
              title: 'Max width',
              type: 'number',
            },
            width: {
              title: 'Width',
              type: 'number',
            },
          },
        },
        component: {
          $ref: '#/definitions/ColumnComponent',
        },
      },
    },
    EnableColumnHeaderFilters: { type: 'boolean' },
  },
} as WidgetSchema
