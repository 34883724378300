<template>
  <tt-tile v-bind="$props">
    <template #icon>
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </template>

    <tt-attr slot="title" name="name" />
  </tt-tile>
</template>

<script lang="ts">
import Vue from 'vue'

import {
  REPORT_TEMPLATE_FIELD_GROUP_ICON,
  REPORT_TEMPLATE_FIELD_GROUP_ICON_COLOR,
} from './constants'

export default Vue.extend({
  name: 'ReportTemplateFieldGroupsTile',
  computed: {
    icon(): string {
      return REPORT_TEMPLATE_FIELD_GROUP_ICON
    },
    iconColor(): string {
      return REPORT_TEMPLATE_FIELD_GROUP_ICON_COLOR
    },
  },
})
</script>
