// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveRequestVacatedShiftsTile from './LeaveRequestVacatedShiftsTile.vue'
import LeaveRequestVacatedShiftsColumns from './LeaveRequestVacatedShiftsColumns'
import LeaveRequestVacatedShiftsDetail from './LeaveRequestVacatedShiftsDetail.vue'
import LeaveRequestVacatedShiftsReference from './LeaveRequestVacatedShiftsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'LeaveRequestVacatedShiftsTile',
      LeaveRequestVacatedShiftsTile,
    )
    Vue.component(
      'LeaveRequestVacatedShiftsDetail',
      LeaveRequestVacatedShiftsDetail,
    )
    Vue.component(
      'LeaveRequestVacatedShiftsReference',
      LeaveRequestVacatedShiftsReference,
    )

    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_VACATED_SHIFTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveRequestVacatedShiftsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_VACATED_SHIFTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveRequestVacatedShiftsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_VACATED_SHIFTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveRequestVacatedShiftsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_VACATED_SHIFTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveRequestVacatedShiftsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
