import i18n from '@/plugins/i18n'
import { AppContext } from '@/tt-app-context'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { DialogFormInterface, LayoutWindowEvent } from '@/tt-app-layout'
import { EmptyValueRule } from '@tracktik/tt-json-schema-form'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'

import { ReportFormData } from './types'

export const getReportEditFormBuilder = (
  entityId: number,
  report: ReportFormData,
  appContext: AppContext,
) => {
  const itemHook = new EntityItemHook(appContext, {
    entity: { id: entityId },
    resourceName: Resources.REPORTS,
    entityId: entityId,
  })
  const submitFn = (entity) =>
    appContext.entityServices.persister.updateEntity(
      Resources.REPORTS,
      entity,
      entityId,
    )
  const successFn: DialogFormInterface['success'] = () => {
    appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
      message: i18n.t('common.operation_successful'),
    })
  }
  return new DialogFormBuilder(' ', ' ', {
    eventManager: appContext.eventManager,
  })
    .setJsonSchema(report.schema)
    .addToInitialModel(report.values)
    .addToFormOptions({
      emptyValues: EmptyValueRule.KEEP,
      removeAdditional: true,
    })
    .addToFormOptions(report.formOptions)
    .addToContext({ resourceName: Resources.REPORTS, itemHook })
    .onSubmit(submitFn)
    .onSuccess(successFn)
}
