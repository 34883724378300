// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTypesTile from './AssetTypesTile.vue'
import AssetTypesColumns from './AssetTypesColumns'
import AssetTypesDetail from './AssetTypesDetail.vue'
import AssetTypesMetaCard from './AssetTypesMetaCard.vue'
import AssetTypesReference from './AssetTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetTypesTile', AssetTypesTile)
    Vue.component('AssetTypesDetail', AssetTypesDetail)
    Vue.component('AssetTypesMetaCard', AssetTypesMetaCard)
    Vue.component('AssetTypesReference', AssetTypesReference)

    modularManager.addResourcePreset(
      Resources.ASSET_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
