import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'from',
  'to',
  'user',
  'region',
  'site',
  'createdOn',
] as ColumnInputDefinition[]
