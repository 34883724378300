// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionsTile from './PositionsTile.vue'
import PositionsColumns from './PositionsColumns'
import PositionsDetail from './PositionsDetail.vue'
import PositionsMetaCard from './PositionsMetaCard.vue'
import PositionsReference from './PositionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionsTile', PositionsTile)
    Vue.component('PositionsDetail', PositionsDetail)
    Vue.component('PositionsMetaCard', PositionsMetaCard)
    Vue.component('PositionsReference', PositionsReference)

    modularManager.addResourcePreset(
      Resources.POSITIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.POSITIONS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: PositionsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.POSITIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
