<template>
  <tt-attr-row-card>
    <LeaveRequestsDetailAffectedShifts />
    <tt-attr-row name="employee" :cols="5" />
    <tt-attr-row name="startedOn" format="asDateTime" :cols="5" />
    <tt-attr-row name="endedOn" format="asDateTime" :cols="5" />
    <tt-attr-row name="requestedDays" :cols="5" />
    <tt-attr-row name="serviceLevelImpact" :label="SLItext" :cols="5">
      <template #title>
        <v-tooltip top>
          <template #activator="{ on }">
            <div class="d-inline">
              <span v-text="SLItext" />
              <v-icon small right v-on="on"> mdi-information </v-icon>
            </div>
          </template>
          <span v-text="serviceLevelImpactDescription" />
        </v-tooltip>
      </template>
      <ServiceLevelImpactPercentage in-details />
    </tt-attr-row>
    <tt-if-attr
      name="employee.externalIdObject.hcmAccrualBalance"
      not
      :value="[null, undefined, '']"
    >
      <HCMAccrualBalanceRow />
    </tt-if-attr>
    <tt-attr-row name="reason" :cols="5" />
    <tt-attr-row name="leaveType" :cols="5" />
    <tt-attr-row name="reviewer" :cols="5" />
    <tt-attr-row name="reviewReason" :cols="5" />
    <tt-attr-row name="reviewedOn" format="asDate" :cols="5" />
    <tt-attr-row name="status" :cols="5">
      <StatusChip :status-color-options="leaveRequestsStatusColorOptions" />
    </tt-attr-row>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'

import { LeaveRequestsStatusColorOptions } from './types'

export default Vue.extend({
  name: 'LeaveRequestsDetail',
  computed: {
    SLItext(): string {
      return this.$t('tt-entity-design.leave-management.leave-requests.sli')
    },
    leaveRequestsStatusColorOptions(): typeof LeaveRequestsStatusColorOptions {
      return LeaveRequestsStatusColorOptions
    },
    serviceLevelImpactDescription(): string {
      return this.$t(
        'tt-entity-design.leave-management.leave-requests.service-level-impact-description',
      )
    },
  },
})
</script>
