<template>
  <ProvideItemHook :provide="itemHook">
    <v-card flat class="pa-6 d-flex align-center">
      <div class="mr-4 d-flex">
        <v-avatar size="64">
          <img :src="employee.avatar" :alt="employee.name" />
        </v-avatar>
      </div>
      <div class="d-flex flex-grow-1 flex-column">
        <h1 class="text-subtitle-1">{{ employee.name }}</h1>
        <h1 class="text-subtitle-2">{{ employee.jobTitle }}</h1>
        <tt-attr name="leaveType.name"></tt-attr>
      </div>
    </v-card>
    <CollapsibleHeader opened :title="$t('components.entity-preview.detail')">
      <tt-attr-row-card>
        <tt-attr-row
          name="extensions.balance.total"
          format="asRounded4Decimals"
        ></tt-attr-row>
        <tt-attr-row
          name="extensions.balance.payHoursPerDay"
          format="asRounded4Decimals"
        ></tt-attr-row>
        <tt-attr-row
          v-if="!hoursEntitlementUnit"
          :label="totalDaysText"
          format="asRounded4Decimals"
        >
          {{ availableDaysBalance }}
        </tt-attr-row>
        <tt-attr-row :label="totalHoursText" format="asRounded4Decimals">
          {{ availableHoursBalance }}
        </tt-attr-row>
      </tt-attr-row-card>
    </CollapsibleHeader>
  </ProvideItemHook>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import CollapsibleHeader from '@/tt-widget-entity-flow/components/CollapsibleHeader.vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { LeavePolicyEmployeeInfo } from '../leave-policies/types'
import { EntitlementUnit } from '@/tt-widget-views/leave-management/types'

export default Vue.extend({
  name: 'LeavePolicyItemsAccrualBreakdown',
  components: {
    CollapsibleHeader,
  },
  props: {
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
    employee: {
      type: Object as PropType<LeavePolicyEmployeeInfo>,
      required: true,
    },
  },
  computed: {
    totalDaysText(): string {
      return this.$t(
        'tt-entity-design.leave-management.employee.overview.total-days',
      )
    },
    totalHoursText(): string {
      return this.$t(
        'tt-entity-design.leave-management.employee.overview.total-hours',
      )
    },
    payHoursPerDay(): number {
      return this.itemHook.get('extensions.balance.payHoursPerDay')
    },
    entitlementUnit(): EntitlementUnit {
      return this.itemHook.getRawValue('entitlementUnit')
    },
    hoursEntitlementUnit(): boolean {
      return this.entitlementUnit === 'HOURS'
    },
    availableTotalBalance(): number {
      return this.itemHook.get('extensions.balance.total')
    },
    availableHoursBalance(): number {
      if (this.hoursEntitlementUnit) {
        return this.availableTotalBalance
      } else {
        return (
          (this.availableTotalBalance * this.payHoursPerDay) %
          this.payHoursPerDay
        )
      }
    },
    availableDaysBalance(): number {
      return Math.floor(this.availableTotalBalance)
    },
  },
})
</script>
