import DataSetWidgetHookPercent from '@/tt-widget-components/base/DataSetWidgetHookPercent'
import { TreeMapWidgetModel } from '@/tt-widget-components'
import { AggregationQueryResponse } from '@/tt-widget-factory'

export default class TreeMapWidgetHook extends DataSetWidgetHookPercent<
  TreeMapWidgetModel
> {
  SERIES_INDEX = 0
  DIMENSION_INDEX = 0
  MEASURE_INDEX = 0

  get dataSource() {
    if (!this.isDataFetched) {
      return []
    }
    const dimension = this.getDimensionByIndex(
      this.SERIES_INDEX,
      this.DIMENSION_INDEX,
    )
    const measure = this.getMeasureByIndex(
      this.SERIES_INDEX,
      this.MEASURE_INDEX,
    )
    let dataSource = this.series[this.SERIES_INDEX].asSeries

    dataSource = this.formatDataSource(
      dataSource,
      this.SERIES_INDEX,
      dimension,
      measure,
      this.state.widget.dataSet.measure.format,
    )
    return dataSource
  }

  /**
  This chart type does not support displaying negative values
*/

  async update() {
    const filterNegative = (response: AggregationQueryResponse) => {
      return response.filter((record) => record.data[this.measureKey] > 0)
    }

    await this._update(filterNegative)
  }

  get measureKey() {
    return this.getMeasureByIndex(this.SERIES_INDEX, this.MEASURE_INDEX).key
  }
}
