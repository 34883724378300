import Vue from 'vue'

import {
  Dependencies,
  Params,
  State,
  Entity,
  EntityCollector,
  EntityId,
  ApiEntity,
} from './type'
import { createField } from '@/tt-widget-factory/services/resource-meta/Fields'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'

type Query = EntityCollectionRequestOptions

/**
 * Service to fetch a given set of entities IDs.
 */
export const createEntityCollector = (
  { authModule }: Dependencies,
  { resource, fields }: Params,
): EntityCollector => {
  const state: State = Vue.observable({
    loading: false,
    errors: null,
    ids: [],
    entities: Object.freeze([]),
    cache: Object.freeze([]),
    cancellablePromise: null,
  })

  const prepareQuery = async (ids: EntityId[]): Promise<Query> => {
    state.loading = true

    return {
      fields: ['id', ...fields].map(createField),
      limit: ids.length,
      filters: [
        {
          attribute: 'id',
          operator: 'IN',
          // @ts-ignore
          value: ids,
        },
      ],
    }
  }

  const fetchEntities = (query: Query) => {
    const createCancellablePromise = () =>
      authModule.getApi().createCancellableGetAll(resource, query)

    state.cancellablePromise?.cancel('Canceling previous request')
    state.cancellablePromise = createCancellablePromise()

    return state.cancellablePromise.run()
  }

  const setEntities = async (newEntities: ApiEntity[] = []) => {
    const castIdToNumber = (entity: ApiEntity): Entity => ({
      ...entity,
      id: Number(entity.id),
    })
    const addToCache = async (entities) => [...state.cache, ...entities]
    const getUniqueEntities = (entities: Entity[]) => [
      ...new Map(entities.map((entity) => [entity.id, entity])).values(),
    ]
    const freezeObject = (entities) => Object.freeze(entities)

    state.entities = freezeObject(newEntities.map(castIdToNumber))
    state.cache = await addToCache(state.entities)
      .then(getUniqueEntities)
      .then(freezeObject)
  }

  const handleError = (error) => {
    console.warn('error while fetching selected entities', error)
    // @todo: check if the error was because the request was cancelled (FE-872)
    state.errors = error
    setEntities([])
    throw new Error(error)
  }

  const fetch = (ids: EntityId[]) =>
    prepareQuery(ids)
      .then(fetchEntities)
      .catch(handleError)
      .then(({ items }) => setEntities(items))
      .finally(() => (state.loading = false))

  const setNewIds = async (ids: (string | number)[] = []) => {
    const isNotInCache = (id: number) =>
      !state.cache.some((entity) => entity.id === id)
    const castToNumber = (id) => Number(id)

    const newIds = ids.map(castToNumber)
    const idsToFetch = newIds.filter(isNotInCache)

    state.ids = [...newIds]

    if (idsToFetch.length) await fetch(idsToFetch)
  }

  const getEntities = () =>
    state.cache.filter((entity) => state.ids.includes(entity.id))

  const hasAllEntities = () =>
    state.ids.every((id) => state.cache.some((entity) => entity.id === id))

  return {
    isLoading: () => state.loading,
    hasErrors: () => !!state.errors,
    hasAllEntities,
    getEntities,
    setNewIds,
  }
}
