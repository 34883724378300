<template>
  <JsonBoolean v-slot="{ localesObject, setValue, value, on }" v-bind="$attrs">
    <v-select
      v-bind="{
        ...vuetifyDefaults,
      }"
      :items="[
        { text: localesObject.false.label, value: false },
        {
          text: localesObject.true.label,
          value: true,
        },
      ]"
      :value="value"
      :label="$attrs.label"
      :placeholder="$attrs.placeholder"
      v-on="on"
      @input="(value) => setValue(value)"
    />
  </JsonBoolean>
</template>

<script lang="ts">
import Vue from 'vue'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

export default Vue.extend({
  name: 'BooleanSelector',
  data() {
    return {
      vuetifyDefaults,
    }
  },
})
</script>
