import { WidgetName } from '@/tt-widget-components/lib/names'
import { WidgetTypeDefinition } from '@/tt-widget-factory'

import hook from '../Scheduler/SchedulerWidgetHook'
import schema from './schema'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import('./CalendarWidget.vue').catch(handleAsyncComponentError)

const SchedulerWidget: WidgetTypeDefinition = {
  name: WidgetName.CALENDAR_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  config: {
    icon: 'mdi-calendar',
    color: '#f23d3d',
    // @todo: get an appropriate thumbnail
    thumbnail: require('../Scheduler/data-table.png'),
    description: 'Scheduler',
  },
}

export default SchedulerWidget
