<script lang="ts">
import Vue from 'vue'

/**
 * Provides the `relationPrefix` to its descendants.
 */
export default Vue.extend({
  name: 'RelationPrefixProvider',
  provide() {
    return {
      relationPrefix: this.relationPrefix,
    }
  },
  props: {
    relationPrefix: {
      type: String,
      required: true,
    },
  },
  render() {
    return this.$slots.default[0]
  },
})
</script>
