<template>
  <div class="ml-3 mr-3">
    <v-checkbox
      v-for="item in enums"
      :key="item.value"
      v-model="selected"
      dense
      hide-details
      :label="item.text"
      :value="item.value"
    />
  </div>
</template>
<script lang="ts">
import VueI18n from 'vue-i18n'

import {
  FilterOperatorType,
  Filter,
} from 'tracktik-sdk/lib/common/entity-filters'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import BaseFilter from './BaseFilter'
import { PropType } from 'vue'
import { EnumAttributeFilterOptions } from '@/tt-widget-components'

export default BaseFilter.extend({
  name: 'FilterEnum',
  props: {
    options: {
      type: Object as PropType<EnumAttributeFilterOptions>,
      default: () => ({} as EnumAttributeFilterOptions),
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        if (val) {
          this.model = {
            value: val ? val.join(',') : null,
            operator: FilterOperatorType.IN,
            attribute: this.value.attribute,
          }
        }
      },
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    onInput(input?: { value: string | string[] }) {
      const value = input?.value || []
      this.selected = typeof value === 'string' ? value.split(',') : value
    },
    reset() {
      this.selected = []
    },
  },
  computed: {
    defaultModel(): Filter {
      return {
        attribute: this.attributeMeta.name,
        operator: FilterOperatorType.IN,
        value: null,
      }
    },
    enums(): {
      text: VueI18n.TranslateResult
      value: string
    }[] {
      const isNotExcluded = (value: string) =>
        !this.options.exclude?.includes(value)
      const createItem = (value: string) => ({
        text: this.$t(
          this.$appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
            this.attributeMeta.resource,
            this.attributeMeta.name,
            FormLabelTypes.LABEL,
            value,
          ),
        ),
        value,
      })
      let enumList = this.attributeMeta.enum
      if (this.options.exclude) {
        enumList = enumList.filter(isNotExcluded)
      }

      return enumList.map(createItem)
    },
  },
})
</script>
