import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const taxClassItemsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.TAX_CLASS_ITEMS,
      },
    }

    const taxClassItems: PreviewTabInterface = {
      title: 'res.tax-classes.attr.items.label',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'taxClass.id',
        widget: taxClassItemsListWidget,
      },
    }

    // Add the tax class items tab
    addPreviewTab(Resources.TAX_CLASSES, taxClassItems)
  },
}
