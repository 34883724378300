<template>
  <div>
    <ReportFieldsForm
      v-model="formModel"
      class="pt-2"
      :form="form"
      @errors="valid = false"
      @valid="valid = true"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn v-if="showBackButton" class="mr-2" @click="$emit('back')">
        {{ $t('report.views.create.back') }}
      </v-btn>
      <v-btn
        :loading="saving"
        :disabled="!valid"
        raised
        color="success"
        @click="submit"
      >
        {{ $t('report.views.create.save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import ReportFieldsForm from './ReportFieldsForm.vue'
import { ReportFormData } from '../types'

export default Vue.extend({
  name: 'ReportFields',
  components: {
    ReportFieldsForm,
  },
  props: {
    /**
     * Form schema and options
     */
    form: {
      type: Object as PropType<ReportFormData>,
      required: true,
    },
    /**
     * Save button loading state
     */
    saving: { type: Boolean, default: false },
    /**
     * Form value
     */
    value: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: false,
    }
  },
  computed: {
    formModel: {
      get(): Record<string, unknown> {
        return this.value
      },
      set(value: Record<string, unknown>) {
        this.$emit('input', value)
      },
    },
    showBackButton(): boolean {
      return !!this.$listeners?.back
    },
  },
  methods: {
    submit(): void {
      this.$emit('submit')
    },
  },
})
</script>
