<template>
  <div class="d-flex flex-column">
    <ProvideItemHook v-if="periodicItemHook" :provide="periodicItemHook">
      <tt-preset-view
        type="detailCard"
        class="ma-2"
        :opened="true"
        :title="$t('components.entity-preview.detail')"
      />
    </ProvideItemHook>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '../../types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleGroupsPeriodicalAssignmentsTab',
  inject: ['getItemHook'],
  data() {
    return {
      periodicItemHook: null as EntityItemHook,
    }
  },
  computed: {
    resource(): string {
      return Resources.PERIODIC_SCHEDULES
    },
    periodicScheduleId(): number {
      return this.getItemHook().get('periodicSchedule.id')
    },
  },
  async created() {
    this.periodicItemHook = new EntityItemHook(
      this.$appContext,
      {
        resourceName: this.resource,
        entityId: this.periodicScheduleId as number,
      },
      {
        hasFetched: false,
        fetchEnabled: true,
        fetchActions: false,
      },
    )
  },
})
</script>
