// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchTasksTile from './DispatchTasksTile.vue'
import DispatchTasksColumns from './DispatchTasksColumns'
import DispatchTasksDetail from './DispatchTasksDetail.vue'
import DispatchTasksMetaCard from './DispatchTasksMetaCard.vue'
import DispatchTasksReference from './DispatchTasksReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchTasksTile', DispatchTasksTile)
    Vue.component('DispatchTasksDetail', DispatchTasksDetail)
    Vue.component('DispatchTasksMetaCard', DispatchTasksMetaCard)
    Vue.component('DispatchTasksReference', DispatchTasksReference)

    modularManager.addResourcePreset(
      Resources.DISPATCH_TASKS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchTasksTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASKS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchTasksColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASKS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchTasksDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASKS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchTasksMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_TASKS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchTasksReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
