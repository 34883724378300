<template>
  <div class="d-flex align-center">
    <tt-attr
      v-show="!isSet || name"
      prepend-icon="mdi-format-list-numbered"
      prepend-icon-color="purple"
      name="name"
    />

    <template v-if="!name && isSet">
      <v-icon color="purple" x-small> mdi-format-list-numbered </v-icon>
      <span class="pl-1" v-text="dayOfWeek" />
    </template>
  </div>
</template>

<script lang="ts">
import { VueWithItemHookInjection } from '@/helpers/components/types'
import Vue, { VueConstructor } from 'vue'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'MobileRunsheetsReference',
  inject: ['getItemHook', 'relationPrefix'],
  computed: {
    isSet(): boolean {
      return !this.getItemHook().loading && this.id !== null
    },
    id(): string {
      return this.getItemHook().getRawValue(this.getAttr('id')) ?? null
    },
    name(): string {
      return this.getItemHook().getRawValue(this.getAttr('name'))
    },
    dayOfWeek(): string {
      return this.getItemHook().get(this.getAttr('dayOfWeek'))
    },
  },
  created() {
    this.getItemHook().addAttribute(this.getAttr('name'))
    this.getItemHook().addAttribute(this.getAttr('dayOfWeek'))
  },
  methods: {
    getAttr(relation: string): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${relation}`
        : relation
    },
  },
})
</script>
