import { reactive } from 'vue'

import { computedGetter } from '@tracktik/tt-helpers/lib/functions/computedGetter'

import { Feature, FeatureManager } from './types'

export const createFeatureManager = (): FeatureManager => {
  const state = reactive({
    features: [] as Feature[],
  })

  const getActiveFeatures = computedGetter(() =>
    state.features.filter((feature: Feature) => feature.active),
  )

  const getInactiveFeatures = computedGetter(() =>
    state.features.filter((feature: Feature) => !feature.active),
  )

  const getFeatureObject = (code: string) => {
    return state.features.find((feature) => feature.code === code)
  }

  const hasFeature = (code: string) =>
    getActiveFeatures().some((feature) => feature.code === code)

  const setFeatures = (features: Feature[]) => {
    state.features = features
  }

  return {
    getActiveFeatures,
    getInactiveFeatures,
    getFeatureObject,
    hasFeature,
    setFeatures,
  }
}
