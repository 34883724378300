<template>
  <div
    style="display: inline-block; text-align: right; cursor: pointer"
    @click="$emit('click')"
  >
    <SharableUserGroupAvatar
      :size="size"
      :item="ownedByItem"
      :style="{ zIndex: maxDisplayedAvatars + 1 }"
    />
    <template v-if="userGroups && hasGrantedAclRules">
      <template v-for="(item, key, i) in aclRule.grant">
        <SharableUserGroupAvatar
          v-if="i < maxDisplayedAvatars - 1 && userGroups[key]"
          :key="`avatar-${key}`"
          :style="{ marginLeft: '-5px', zIndex: maxDisplayedAvatars - i }"
          :size="size"
          :item="userGroups[key]"
        />
        <template v-if="i == maxDisplayedAvatars - 1">
          <v-avatar
            :key="`avatar-plus${i}`"
            color="grey lighten-4"
            :size="size + 2"
            style="margin-left: -5px; font-size: 0.9em; color: #333"
          >
            +{{ plusCount }}
          </v-avatar>
        </template>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { WidgetOwner } from '@/tt-widget-factory/types'
import SharableUserGroupAvatar from './SharableUserGroupAvatar.vue'
import { ShareService } from '../ShareService'
import { AclRule, UserGroupItemTypes } from '../types'

export default Vue.extend({
  name: 'SharableAvatars',
  components: {
    SharableUserGroupAvatar,
  },
  data() {
    return {
      size: 25,
      maxDisplayedAvatars: 4,
      userGroups: null,
    }
  },
  computed: {
    aclRuleKey(): string {
      return JSON.stringify(this.aclRule)
    },
    plusCount(): number {
      return this.hasGrantedAclRules
        ? Object.keys(this.aclRule.grant).length - 3
        : null
    },
    ownedByItem(): {
      type: UserGroupItemTypes
      avatar: string
    } {
      return {
        type: UserGroupItemTypes.USER,
        avatar:
          this.ownedBy && this.ownedBy.avatar ? this.ownedBy.avatar : null,
      }
    },
    hasGrantedAclRules(): boolean {
      return !!this.aclRule?.grant
    },
  },
  created() {
    this.fetchGroups()
  },
  methods: {
    fetchGroups() {
      if (
        !this.hasGrantedAclRules ||
        Object.keys(this.aclRule.grant).length == 0
      ) {
        return null
      }
      const service = new ShareService(this.$appContext)
      service.translateRule(this.aclRule).then((userGroups) => {
        this.userGroups = userGroups
      })
    },
  },
  props: {
    aclRule: Object as PropType<AclRule>,
    ownedBy: Object as PropType<WidgetOwner>,
  },
  watch: {
    aclRule: {
      deep: true,
      handler() {
        this.fetchGroups()
      },
    },
  },
})
</script>
