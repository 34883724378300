<template>
  <v-container fluid>
    <v-row>
      <v-col class="px-4 col-2" align-self="center">
        <v-icon x-large color="blue"> mdi-format-list-bulleted </v-icon>
      </v-col>
      <v-col class="pa-0 col-10">
        <tt-tile>
          <tt-attr slot="title" name="name" />
          <div slot="line2" class="d-flex">
            <span v-text="$t('res.leave-policies.attr.region.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="region" />
          </div>
          <div slot="line3" class="d-flex">
            <span
              v-text="$t('res.leave-policies.attr.effectiveStartDate.label')"
            />
            <span class="pr-1">:</span>
            <tt-attr name="effectiveStartDate" />
          </div>
          <div slot="line4" class="d-flex">
            <span v-text="$t('res.leave-policies.attr.payHoursPerDay.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="payHoursPerDay" />
          </div>
          <div slot="line5" class="d-flex">
            <span v-text="$t('res.leave-policies.attr.status.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="status" />
          </div>
        </tt-tile>
        <tt-attr-action :action="action" class="px-3">
          <v-btn
            class="text-capitalize carryover-button"
            :color="buttonStyle.background"
            elevation="0"
            small
            @click.stop="openCarryoverDialog"
          >
            <v-icon left small> mdi-arrow-right </v-icon>
            <span v-text="$t(carryOverLabel)" />
          </v-btn>
        </tt-attr-action>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { DIALOG_WIDTH_MEDIUM_LARGE } from '@/helpers/constants'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor } from 'vue'
import {
  ServiceLevelImpactColors,
  UiChipColor,
} from '@/tt-widget-views/leave-management/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePoliciesTile',
  inject: ['getItemHook'],
  computed: {
    action(): string {
      return 'carry-over'
    },
    carryOverLabel(): string {
      return 'tt-entity-design.leave-management.leave-policies.carryover'
    },
    buttonStyle(): UiChipColor {
      return ServiceLevelImpactColors.MEDIUM_LOW
    },
  },
  created() {
    this.requestCurrentPeriodAttributes()
    this.requestAvaiableActions()
  },
  methods: {
    requestCurrentPeriodAttributes() {
      this.getItemHook().addAttribute('startPeriodDate')
      this.getItemHook().addAttribute('endPeriodDate')
    },
    requestAvaiableActions() {
      this.getItemHook().needActions()
    },
    openCarryoverDialog() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'LeavePoliciesCarryoverDialog',
        width: DIALOG_WIDTH_MEDIUM_LARGE,
        props: {
          action: this.action,
          leavePolicy: this.getItemHook().getEntityId(),
          itemHook: this.getItemHook(),
        },
      })
    },
  },
})
</script>
<style scoped>
.carryover-button >>> .v-btn__content {
  color: var(--v-bg-text-indigo1-base);
}
</style>
