<template>
  <v-btn-toggle v-model="model" mandatory small class="mt-1" color="green">
    <v-btn value="ASC" small class="px-2">
      <div class="d-flex align-center">
        <v-icon small>mdi-arrow-up</v-icon>
        <span
          class="text-color"
          v-text="$t('components.sort_direction_input.ascending')"
        />
      </div>
    </v-btn>
    <v-btn value="DESC" small class="px-1">
      <div class="d-flex align-center">
        <v-icon small>mdi-arrow-down</v-icon>
        <span
          class="text-color"
          v-text="$t('components.sort_direction_input.descending')"
        />
      </div>
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { SortDirection } from '../schemas-types'

export default Vue.extend({
  name: 'SortDirectionInput',
  props: {
    value: {
      type: String as PropType<SortDirection>,
      default: null,
    },
  },
  data() {
    return {
      model: this.value,
    }
  },
  watch: {
    model(val) {
      this.$emit('input', val)
    },
    value(val) {
      if (val !== this.model) {
        this.model = val
      }
    },
  },
})
</script>
<style scoped>
.text-color {
  color: #000000;
}
.theme--dark .text-color {
  color: white;
}
</style>
