<template>
  <div
    class="d-flex justify-space-between align-center py-2 border-bottom-gray"
  >
    <div class="d-flex align-center">
      <h4 v-text="$t(label)" />
      <h4>: {{ balance }}</h4>
    </div>

    <div class="d-flex align-center" style="width: 150px">
      <v-btn
        fab
        dark
        x-small
        outlined
        color="var(--v-ttPrimary-base)"
        class="adjustable-value-btn adjustable-value-btn-left"
        @click.stop="$emit('remove')"
      >
        <v-icon v-text="'mdi-minus'" />
      </v-btn>

      <v-text-field
        :value="value"
        type="number"
        hide-details
        class="hide-spin-buttons"
        outlined
        dense
        @input="$emit('set-value', $event)"
      />

      <v-btn
        fab
        dark
        x-small
        outlined
        color="var(--v-ttPrimary-base)"
        class="adjustable-value-btn adjustable-value-btn-right"
        @click.stop="$emit('add')"
      >
        <v-icon v-text="'mdi-plus'" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AdjustBalanceDialogLine',
  props: {
    label: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style scoped>
.hide-spin-buttons >>> input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input[type='number'] {
  -moz-appearance: textfield;
}

.border-bottom-gray {
  border-bottom: rgba(0, 0, 0, 0.17) 1px solid;
}
.adjustable-value-btn {
  width: 24px;
  height: 24px;
  border-width: 2px;
}

/* Have to be implicit here because vuetify's 'ml/mr' is giving different values */
.adjustable-value-btn-left {
  margin-right: 8px;
}
.adjustable-value-btn-right {
  margin-left: 8px;
}
</style>
