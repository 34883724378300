<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="dayOfWeekStart" />
    <tt-attr-row name="dayOfWeekEnd" />
    <tt-attr-row name="rangeStartTime" />
    <tt-attr-row name="rangeEndTime" />
    <tt-attr-row name="durationMinutes" />
    <tt-attr-row name="beginServiceDate" />
    <tt-attr-row name="endServiceDate" />
    <TCustomTextAreaAttr name="description" />
    <TCustomTextAreaAttr name="instructions" />
    <tt-attr-row name="isAdhoc" />
    <tt-attr-row name="adhocSelectable" />
    <tt-attr-row name="taskType" />
    <tt-attr-row name="mobileRunsheet" />
    <tt-attr-row name="client" />
    <tt-attr-row name="priceTier" />
    <tt-attr-row name="zonePosition" />
    <tt-attr-row name="reportTemplate" />

    <!--
      DO NOT REMOVE: the Exceptions tab depends on this attribute being
      present in the entity.
      @see ./custom.ts
    -->
    <tt-attr-row v-if="calendarExceptionsEnabled" name="exceptionTypes" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import TCustomTextAreaAttr from '../TCustomTextAreaAttr.vue'
type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'MobileSchedulesDetail',
  components: {
    TCustomTextAreaAttr,
  },
  inject: ['getItemHook'],
  computed: {
    calendarExceptionsEnabled(): boolean {
      return this.getItemHook().hasAttribute('exceptionTypes')
    },
  },
})
</script>
