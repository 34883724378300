import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { ComponentNames } from './types'

export default [
  {
    attributeName: 'requester',
    style: {
      width: 150,
      clipMode: 'EllipsisWithTooltip',
    },
  },
  {
    headerText:
      'res.open-shift-requests.extensions.aroundShifts.attr.previous.labels.label',
    component: {
      is: 'OpenShiftRequestsExtAroundShifts',
      props: {
        type: 'previous',
      },
    },
    style: {
      width: 100,
    },
  },
  {
    headerText:
      'res.open-shift-requests.extensions.aroundShifts.attr.next.labels.label',
    component: {
      is: 'OpenShiftRequestsExtAroundShifts',
      props: {
        type: 'next',
      },
    },
    style: {
      width: 100,
    },
  },
  {
    headerText: 'res.employees.attr.skills.label',
    allowSorting: false,
    allowFilters: false,
    component: {
      is: ComponentNames.OpenShiftRequestsColumnSkillThreshold,
    },
    style: {
      width: 100,
    },
  },
  {
    attributeName: 'extensions.distance.distance',
    format: 'asDistance',
    style: {
      width: 100,
    },
  },
  {
    attributeName: 'extensions.hourlyPayRate.rate',
    format: 'asPayRateHourly',
    style: {
      width: 100,
    },
  },
  {
    headerText:
      'res.open-shift-requests.extensions.overtimeImpact.attr.hours.labels.label',
    component: {
      is: ComponentNames.OpenShiftRequestsAttrOvertimeImpact,
    },
    style: {
      width: 100,
    },
  },
  {
    headerText:
      'res.open-shift-requests.extensions.weeklyHoursRemaining.attr.hours.labels.label',
    component: {
      is: ComponentNames.OpenShiftRequestsAttrWeeklyHoursRemaining,
    },
    style: {
      width: 100,
    },
  },
  {
    component: {
      is: ComponentNames.OpenShiftRequestsActions,
      props: {
        action: 'approve',
        small: true,
      },
    },
    style: {
      width: 142,
    },
  },
  {
    component: {
      is: ComponentNames.OpenShiftRequestsActions,
      props: {
        action: 'decline',
        small: true,
      },
    },
    style: {
      width: 142,
    },
  },
] as ColumnInputDefinition[]
