// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTypeToReportTemplateAssignmentTile from './AssetTypeToReportTemplateAssignmentTile.vue'
import AssetTypeToReportTemplateAssignmentColumns from './AssetTypeToReportTemplateAssignmentColumns'
import AssetTypeToReportTemplateAssignmentDetail from './AssetTypeToReportTemplateAssignmentDetail.vue'
import AssetTypeToReportTemplateAssignmentReference from './AssetTypeToReportTemplateAssignmentReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'AssetTypeToReportTemplateAssignmentTile',
      AssetTypeToReportTemplateAssignmentTile,
    )
    Vue.component(
      'AssetTypeToReportTemplateAssignmentDetail',
      AssetTypeToReportTemplateAssignmentDetail,
    )
    Vue.component(
      'AssetTypeToReportTemplateAssignmentReference',
      AssetTypeToReportTemplateAssignmentReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTypeToReportTemplateAssignmentTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTypeToReportTemplateAssignmentColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypeToReportTemplateAssignmentDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTypeToReportTemplateAssignmentReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
