// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import GeoFencesTile from './GeoFencesTile.vue'
import GeoFencesColumns from './GeoFencesColumns'
import GeoFencesDetail from './GeoFencesDetail.vue'
import GeoFencesMetaCard from './GeoFencesMetaCard.vue'
import GeoFencesReference from './GeoFencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('GeoFencesTile', GeoFencesTile)
    Vue.component('GeoFencesDetail', GeoFencesDetail)
    Vue.component('GeoFencesMetaCard', GeoFencesMetaCard)
    Vue.component('GeoFencesReference', GeoFencesReference)

    modularManager.addResourcePreset(
      Resources.GEO_FENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'GeoFencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_FENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: GeoFencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_FENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'GeoFencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_FENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'GeoFencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_FENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'GeoFencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
