// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskScheduleExceptionTypesTile from './SiteTaskScheduleExceptionTypesTile.vue'
import SiteTaskScheduleExceptionTypesColumns from './SiteTaskScheduleExceptionTypesColumns'
import SiteTaskScheduleExceptionTypesDetail from './SiteTaskScheduleExceptionTypesDetail.vue'
import SiteTaskScheduleExceptionTypesReference from './SiteTaskScheduleExceptionTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'SiteTaskScheduleExceptionTypesTile',
      SiteTaskScheduleExceptionTypesTile,
    )
    Vue.component(
      'SiteTaskScheduleExceptionTypesDetail',
      SiteTaskScheduleExceptionTypesDetail,
    )
    Vue.component(
      'SiteTaskScheduleExceptionTypesReference',
      SiteTaskScheduleExceptionTypesReference,
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleExceptionTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskScheduleExceptionTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleExceptionTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleExceptionTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
