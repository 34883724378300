<template>
  <v-alert
    class="mb-0"
    :color="alertType.background"
    :style="{ color: alertType.text }"
  >
    <div class="d-flex">
      <v-icon :color="alertType.text" class="mr-4">
        {{ alertType.icon }}
      </v-icon>
      <span v-text="$t(text)" />
    </div>
  </v-alert>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { AlertTypes, AlertConfig } from '../types'
export default Vue.extend({
  name: 'TAlert',
  props: {
    text: {
      type: String,
      required: true,
    },
    alertType: {
      type: Object as PropType<AlertConfig>,
      default: () => AlertTypes.WARNING,
    },
  },
})
</script>
