<template>
  <v-list-item class="pt-2">
    <v-list-item-content class="pb-2">
      <v-list-item-subtitle>
        <v-icon color="green lighten-2" medium v-text="iconName" />
        <span class="ml-2" v-text="$t(title)" />
      </v-list-item-subtitle>
      <slot>
        <v-list-item-title
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$t(text)"
        />
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WidgetDetailsListItem',
  props: {
    iconName: {
      type: String,
    },
    text: {
      type: String,
    },
    title: {
      type: String,
    },
  },
})
</script>
