import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'account',
  'status',
  'conflictCategory',
  'conflictType',
  {
    name: 'affectedEntity',
    headerText: 'res.conflicts.attr.affectedEntity.label',
    component: {
      is: 'ConflictsAffectedEntityAttr',
    },
  },
  'conflictSubtype',
] as ColumnInputDefinition[]
