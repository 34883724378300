//******** This file is autogenerated DO NOT MODIFY **********
import AccountKpisInstaller from './account-kpis'
import AccountsInstaller from './accounts'
import AddressesInstaller from './addresses'
import AlarmConnectionsInstaller from './alarm-connections'
import AlarmOrganizationsInstaller from './alarm-organizations'
import AlarmTypesInstaller from './alarm-types'
import AlarmsInstaller from './alarms'
import AppLicensesInstaller from './app-licenses'
import AssetCategoriesInstaller from './asset-categories'
import AssetCategoryTranslationsInstaller from './asset-category-translations'
import AssetContactsInstaller from './asset-contacts'
import AssetCustomAttributesInstaller from './asset-custom-attributes'
import AssetCustomFieldsInstaller from './asset-custom-fields'
import AssetFieldsetFieldsInstaller from './asset-fieldset-fields'
import AssetFieldsetsInstaller from './asset-fieldsets'
import AssetFilesInstaller from './asset-files'
import AssetManufacturersInstaller from './asset-manufacturers'
import AssetPersonsInstaller from './asset-persons'
import AssetReservationsInstaller from './asset-reservations'
import AssetSuppliersInstaller from './asset-suppliers'
import AssetTransactionItemsInstaller from './asset-transaction-items'
import AssetTransactionsInstaller from './asset-transactions'
import AssetTypeCustomAttributesInstaller from './asset-type-custom-attributes'
import AssetTypeFilesInstaller from './asset-type-files'
import AssetTypeToReportTemplateAssignmentInstaller from './asset-type-to-report-template-assignment'
import AssetTypeTranslationsInstaller from './asset-type-translations'
import AssetTypesInstaller from './asset-types'
import AssetsInstaller from './assets'
import AuditLogsInstaller from './audit-logs'
import BackOfficePaySettingsInstaller from './back-office-pay-settings'
import BackOfficeSettingsInstaller from './back-office-settings'
import BillItemsInstaller from './bill-items'
import BillableItemsInstaller from './billable-items'
import BillingAddressesInstaller from './billing-addresses'
import BillingAdhocItemsInstaller from './billing-adhoc-items'
import BreakRuleComplianceLogsInstaller from './break-rule-compliance-logs'
import BreakRuleConditionsInstaller from './break-rule-conditions'
import BreakRuleExceptionsInstaller from './break-rule-exceptions'
import BreakRuleRelationsInstaller from './break-rule-relations'
import BreakRulesInstaller from './break-rules'
import BreakSessionsInstaller from './break-sessions'
import CalendarExceptionGroupDatesInstaller from './calendar-exception-group-dates'
import CalendarExceptionsInstaller from './calendar-exceptions'
import CheckpointQuestionAnswersInstaller from './checkpoint-question-answers'
import CheckpointScansInstaller from './checkpoint-scans'
import CheckpointTourAssignmentsInstaller from './checkpoint-tour-assignments'
import CheckpointTourScheduleOccurrencesInstaller from './checkpoint-tour-schedule-occurrences'
import CheckpointTourSchedulesInstaller from './checkpoint-tour-schedules'
import CheckpointTourSessionCommentsInstaller from './checkpoint-tour-session-comments'
import CheckpointTourSessionsInstaller from './checkpoint-tour-sessions'
import CheckpointToursInstaller from './checkpoint-tours'
import CheckpointsInstaller from './checkpoints'
import CitiesInstaller from './cities'
import ClientBillingSettingsInstaller from './client-billing-settings'
import ClientInvoiceSplitSettingsInstaller from './client-invoice-split-settings'
import ClientUsersInstaller from './client-users'
import ClientsInstaller from './clients'
import CommentsInstaller from './comments'
import ConflictsInstaller from './conflicts'
import ContactsInstaller from './contacts'
import ContractBillingCalendarDatesInstaller from './contract-billing-calendar-dates'
import ContractBillingCalendarsInstaller from './contract-billing-calendars'
import ContractOccurrenceLogsInstaller from './contract-occurrence-logs'
import ContractOccurrencesInstaller from './contract-occurrences'
import ContractRecurrentFixedServicesInstaller from './contract-recurrent-fixed-services'
import ContractServiceModelsInstaller from './contract-service-models'
import ContractsInstaller from './contracts'
import CreditMemosInstaller from './credit-memos'
import DataViewCategoriesInstaller from './data-view-categories'
import DataViewModularHooksInstaller from './data-view-modular-hooks'
import DataViewsInstaller from './data-views'
import DepartmentsInstaller from './departments'
import DispatchCustomLocationsInstaller from './dispatch-custom-locations'
import DispatchEtaLogsInstaller from './dispatch-eta-logs'
import DispatchSlaTimerSettingsInstaller from './dispatch-sla-timer-settings'
import DispatchSlasInstaller from './dispatch-slas'
import DispatchTaskLogsInstaller from './dispatch-task-logs'
import DispatchTasksInstaller from './dispatch-tasks'
import EmergencyContactAccountAssignmentsInstaller from './emergency-contact-account-assignments'
import EmergencyContactsInstaller from './emergency-contacts'
import EmployeeAccountAssignmentPaySettingsInstaller from './employee-account-assignment-pay-settings'
import EmployeeAccountAssignmentsInstaller from './employee-account-assignments'
import EmployeeAccountBansInstaller from './employee-account-bans'
import EmployeeAvailabilitiesInstaller from './employee-availabilities'
import EmployeeBillPayMatrixRateSettingsInstaller from './employee-bill-pay-matrix-rate-settings'
import EmployeeClassPaySettingsInstaller from './employee-class-pay-settings'
import EmployeeClassesInstaller from './employee-classes'
import EmployeeExternalIdFieldsInstaller from './employee-external-id-fields'
import EmployeeExternalIdsInstaller from './employee-external-ids'
import EmployeeExtraRegionsInstaller from './employee-extra-regions'
import EmployeePaySettingsInstaller from './employee-pay-settings'
import EmployeePremiumAssignmentsInstaller from './employee-premium-assignments'
import EmployeeRolesInstaller from './employee-roles'
import EmployeeSenioritiesInstaller from './employee-seniorities'
import EmployeeSkillsInstaller from './employee-skills'
import EmployeesInstaller from './employees'
import EmploymentProfilesInstaller from './employment-profiles'
import EmploymentTerminationReasonsInstaller from './employment-termination-reasons'
import EntityLabelAvailabilityTypesInstaller from './entity-label-availability-types'
import EntityLabelsInstaller from './entity-labels'
import EventActivitiesInstaller from './event-activities'
import EventsSubscriptionLogsInstaller from './events-subscription-logs'
import EventsSubscriptionsInstaller from './events-subscriptions'
import ExceptionTypeDatesInstaller from './exception-type-dates'
import ExceptionTypeGroupItemsInstaller from './exception-type-group-items'
import ExceptionTypeGroupsInstaller from './exception-type-groups'
import ExceptionTypesInstaller from './exception-types'
import FilesInstaller from './files'
import FilterRuleSetAssetsInstaller from './filter-rule-set-assets'
import FilterRuleSetReportsInstaller from './filter-rule-set-reports'
import FilterRuleSetsInstaller from './filter-rule-sets'
import GeoCountriesInstaller from './geo-countries'
import GeoCountrySubdivisionsInstaller from './geo-country-subdivisions'
import GeoFencesInstaller from './geo-fences'
import GeoLocationsInstaller from './geo-locations'
import GuidResourcesInstaller from './guid-resources'
import HazardAlertsInstaller from './hazard-alerts'
import HolidayDatesInstaller from './holiday-dates'
import HolidayGroupsInstaller from './holiday-groups'
import HolidaysInstaller from './holidays'
import InvoiceBatchesInstaller from './invoice-batches'
import InvoiceLineTaxesInstaller from './invoice-line-taxes'
import InvoiceLinesInstaller from './invoice-lines'
import InvoicesInstaller from './invoices'
import LeaveAccrualsInstaller from './leave-accruals'
import LeaveBalancesInstaller from './leave-balances'
import LeaveManagementAdjustmentsInstaller from './leave-management-adjustments'
import LeavePoliciesInstaller from './leave-policies'
import LeavePolicyBalancesInstaller from './leave-policy-balances'
import LeavePolicyCarryOverLogsInstaller from './leave-policy-carry-over-logs'
import LeavePolicyItemsInstaller from './leave-policy-items'
import LeaveRequestLogsInstaller from './leave-request-logs'
import LeaveRequestVacatedShiftsInstaller from './leave-request-vacated-shifts'
import LeaveRequestsInstaller from './leave-requests'
import LeaveTypesInstaller from './leave-types'
import LoneWorkerCheckInLogsInstaller from './lone-worker-check-in-logs'
import LoneWorkerCheckInTicketsInstaller from './lone-worker-check-in-tickets'
import LoneWorkerCheckInsInstaller from './lone-worker-check-ins'
import LoneWorkerPhoneCallsInstaller from './lone-worker-phone-calls'
import LoneWorkerPhonesInstaller from './lone-worker-phones'
import LoneWorkerScheduleCheckInsInstaller from './lone-worker-schedule-check-ins'
import LoneWorkerTimeKeepingSettingsInstaller from './lone-worker-time-keeping-settings'
import MasterServiceTypesInstaller from './master-service-types'
import MeInstaller from './me'
import MessageBoardPostAcknowledgementsInstaller from './message-board-post-acknowledgements'
import MessageBoardPostCategoriesInstaller from './message-board-post-categories'
import MessageBoardPostRecipientsInstaller from './message-board-post-recipients'
import MessageBoardPostsInstaller from './message-board-posts'
import MobileDeviceGeoLocationsInstaller from './mobile-device-geo-locations'
import MobileDevicesInstaller from './mobile-devices'
import MobileRunsheetExceptionTypesInstaller from './mobile-runsheet-exception-types'
import MobileRunsheetGroupsInstaller from './mobile-runsheet-groups'
import MobileRunsheetOccurrencesInstaller from './mobile-runsheet-occurrences'
import MobileRunsheetSessionsInstaller from './mobile-runsheet-sessions'
import MobileRunsheetSessionsLogsInstaller from './mobile-runsheet-sessions-logs'
import MobileRunsheetsInstaller from './mobile-runsheets'
import MobileScheduleExceptionTypesInstaller from './mobile-schedule-exception-types'
import MobileScheduleGroupsInstaller from './mobile-schedule-groups'
import MobileScheduleOccurrenceLogsInstaller from './mobile-schedule-occurrence-logs'
import MobileScheduleOccurrenceReassignmentsInstaller from './mobile-schedule-occurrence-reassignments'
import MobileScheduleOccurrencesInstaller from './mobile-schedule-occurrences'
import MobileSchedulesInstaller from './mobile-schedules'
import ModelTranslationsInstaller from './model-translations'
import NotesInstaller from './notes'
import Oauth2AccessTokensInstaller from './oauth2-access-tokens'
import Oauth2AuthCodesInstaller from './oauth2-auth-codes'
import Oauth2ClientsInstaller from './oauth2-clients'
import Oauth2RefreshTokensInstaller from './oauth2-refresh-tokens'
import OpenShiftRequestsInstaller from './open-shift-requests'
import OperationCenterEscalationPoliciesInstaller from './operation-center-escalation-policies'
import OperationCenterEscalationTicketsInstaller from './operation-center-escalation-tickets'
import OperationCenterOperatorsInstaller from './operation-center-operators'
import OperationCenterRegionsInstaller from './operation-center-regions'
import OperationCentersInstaller from './operation-centers'
import OvertimeRuleItemsInstaller from './overtime-rule-items'
import OvertimeRulesInstaller from './overtime-rules'
import PatrolRecordingsInstaller from './patrol-recordings'
import PayAndBillTransactionsInstaller from './pay-and-bill-transactions'
import PaymentMethodsInstaller from './payment-methods'
import PaymentsInstaller from './payments'
import PayrollAdhocsInstaller from './payroll-adhocs'
import PayrollCodesInstaller from './payroll-codes'
import PayrollDeductionsInstaller from './payroll-deductions'
import PayrollPayrunItemsInstaller from './payroll-payrun-items'
import PayrollPayrunsInstaller from './payroll-payruns'
import PayrollScheduleOccurrencesInstaller from './payroll-schedule-occurrences'
import PayrollSchedulesInstaller from './payroll-schedules'
import PeriodicSchedulesInstaller from './periodic-schedules'
import PhoneCallLogsInstaller from './phone-call-logs'
import PositionBillSettingsInstaller from './position-bill-settings'
import PositionBillingSettingsInstaller from './position-billing-settings'
import PositionExpenseReimbursementsInstaller from './position-expense-reimbursements'
import PositionPaySettingsInstaller from './position-pay-settings'
import PositionPayrollSettingsInstaller from './position-payroll-settings'
import PositionPremiumAssignmentsInstaller from './position-premium-assignments'
import PositionPremiumsInstaller from './position-premiums'
import PositionPriceMatrixRulesInstaller from './position-price-matrix-rules'
import PositionSkillsInstaller from './position-skills'
import PositionsInstaller from './positions'
import PostOrderAcknowledgementsInstaller from './post-order-acknowledgements'
import PostOrderSubjectsInstaller from './post-order-subjects'
import PostOrdersInstaller from './post-orders'
import PriceTiersInstaller from './price-tiers'
import PrivacyPoliciesInstaller from './privacy-policies'
import ProcessedPayrollPayrunsInstaller from './processed-payroll-payruns'
import RegionsInstaller from './regions'
import ReportConditionSetActionsInstaller from './report-condition-set-actions'
import ReportConditionSetsInstaller from './report-condition-sets'
import ReportConditionsInstaller from './report-conditions'
import ReportFieldsInstaller from './report-fields'
import ReportFlagCategoriesInstaller from './report-flag-categories'
import ReportFlagGroupAssignmentsInstaller from './report-flag-group-assignments'
import ReportFlagGroupsInstaller from './report-flag-groups'
import ReportFlagTemplateFieldGroupFieldsInstaller from './report-flag-template-field-group-fields'
import ReportFlagTemplateFieldGroupsInstaller from './report-flag-template-field-groups'
import ReportFlagTemplateFieldsInstaller from './report-flag-template-fields'
import ReportFlagTemplatesInstaller from './report-flag-templates'
import ReportFlagsInstaller from './report-flags'
import ReportLibrariesInstaller from './report-libraries'
import ReportLibraryFieldsInstaller from './report-library-fields'
import ReportPreDefinedFieldTemplatesInstaller from './report-pre-defined-field-templates'
import ReportTemplateFieldGroupFieldsInstaller from './report-template-field-group-fields'
import ReportTemplateFieldGroupsInstaller from './report-template-field-groups'
import ReportTemplateFieldsInstaller from './report-template-fields'
import ReportTemplatesInstaller from './report-templates'
import ReportTemplatesCategoryInstaller from './report-templates-category'
import ReportsInstaller from './reports'
import RevenueProcessorOccurrencesInstaller from './revenue-processor-occurrences'
import RoleAccessPoliciesInstaller from './role-access-policies'
import RolePermissionsInstaller from './role-permissions'
import RolesInstaller from './roles'
import ScheduleOffPeriodsInstaller from './schedule-off-periods'
import SchedulersInstaller from './schedulers'
import ShiftLogsInstaller from './shift-logs'
import ShiftNotesInstaller from './shift-notes'
import ShiftPeriodSummariesInstaller from './shift-period-summaries'
import ShiftProposalQueueItemsInstaller from './shift-proposal-queue-items'
import ShiftSlicesInstaller from './shift-slices'
import ShiftSummariesInstaller from './shift-summaries'
import ShiftTemplateSettingsInstaller from './shift-template-settings'
import ShiftTemplatesInstaller from './shift-templates'
import ShiftsInstaller from './shifts'
import SiteLocationsInstaller from './site-locations'
import SiteTaskOccurrenceSessionLogsInstaller from './site-task-occurrence-session-logs'
import SiteTaskOccurrenceSessionsInstaller from './site-task-occurrence-sessions'
import SiteTaskOccurrencesInstaller from './site-task-occurrences'
import SiteTaskScheduleExceptionTypesInstaller from './site-task-schedule-exception-types'
import SiteTaskScheduleTimingsInstaller from './site-task-schedule-timings'
import SiteTaskSchedulesInstaller from './site-task-schedules'
import SiteVendorsInstaller from './site-vendors'
import SkillCategoriesInstaller from './skill-categories'
import SkillsInstaller from './skills'
import SmsLogsInstaller from './sms-logs'
import SystemExceptionTicketInstructionsInstaller from './system-exception-ticket-instructions'
import SystemExceptionTicketsInstaller from './system-exception-tickets'
import SystemSettingsInstaller from './system-settings'
import SystemUsageLogsInstaller from './system-usage-logs'
import TaskPriceTierBillSettingsInstaller from './task-price-tier-bill-settings'
import TaskPriceTiersInstaller from './task-price-tiers'
import TaskSiteInstructionsInstaller from './task-site-instructions'
import TaskSiteSettingsInstaller from './task-site-settings'
import TaskTypeSkillsInstaller from './task-type-skills'
import TaskTypesInstaller from './task-types'
import TaxClassItemsInstaller from './tax-class-items'
import TaxClassesInstaller from './tax-classes'
import TaxLocationOverrideGroupsInstaller from './tax-location-override-groups'
import TaxLocationOverrideMappingsInstaller from './tax-location-override-mappings'
import TaxLocationsInstaller from './tax-locations'
import TenantsInstaller from './tenants'
import TwilioAccountsInstaller from './twilio-accounts'
import UnitsInstaller from './units'
import UserLoginLogsInstaller from './user-login-logs'
import UserNotificationSettingsInstaller from './user-notification-settings'
import UserRolesInstaller from './user-roles'
import UsersInstaller from './users'
import VehiclesInstaller from './vehicles'
import VendorsInstaller from './vendors'
import WhitelistedIpsInstaller from './whitelisted-ips'
import WorkSessionsInstaller from './work-sessions'
import WorkflowActionSettingsInstaller from './workflow-action-settings'
import WorkflowInstanceLogsInstaller from './workflow-instance-logs'
import WorkflowInstanceTimerLogsInstaller from './workflow-instance-timer-logs'
import WorkflowInstanceTimersInstaller from './workflow-instance-timers'
import WorkflowInstancesInstaller from './workflow-instances'
import WorkflowStatusActionsInstaller from './workflow-status-actions'
import WorkflowStatusTransitionFormsInstaller from './workflow-status-transition-forms'
import WorkflowStatusTransitionsInstaller from './workflow-status-transitions'
import WorkflowStatusesInstaller from './workflow-statuses'
import WorkflowTimersInstaller from './workflow-timers'
import WorkflowsInstaller from './workflows'
import ZoneClientsInstaller from './zone-clients'
import ZonesInstaller from './zones'
export default {
  install(Vue) {
    {
      Vue.use(AccountKpisInstaller)
      Vue.use(AccountsInstaller)
      Vue.use(AddressesInstaller)
      Vue.use(AlarmConnectionsInstaller)
      Vue.use(AlarmOrganizationsInstaller)
      Vue.use(AlarmTypesInstaller)
      Vue.use(AlarmsInstaller)
      Vue.use(AppLicensesInstaller)
      Vue.use(AssetCategoriesInstaller)
      Vue.use(AssetCategoryTranslationsInstaller)
      Vue.use(AssetContactsInstaller)
      Vue.use(AssetCustomAttributesInstaller)
      Vue.use(AssetCustomFieldsInstaller)
      Vue.use(AssetFieldsetFieldsInstaller)
      Vue.use(AssetFieldsetsInstaller)
      Vue.use(AssetFilesInstaller)
      Vue.use(AssetManufacturersInstaller)
      Vue.use(AssetPersonsInstaller)
      Vue.use(AssetReservationsInstaller)
      Vue.use(AssetSuppliersInstaller)
      Vue.use(AssetTransactionItemsInstaller)
      Vue.use(AssetTransactionsInstaller)
      Vue.use(AssetTypeCustomAttributesInstaller)
      Vue.use(AssetTypeFilesInstaller)
      Vue.use(AssetTypeToReportTemplateAssignmentInstaller)
      Vue.use(AssetTypeTranslationsInstaller)
      Vue.use(AssetTypesInstaller)
      Vue.use(AssetsInstaller)
      Vue.use(AuditLogsInstaller)
      Vue.use(BackOfficePaySettingsInstaller)
      Vue.use(BackOfficeSettingsInstaller)
      Vue.use(BillItemsInstaller)
      Vue.use(BillableItemsInstaller)
      Vue.use(BillingAddressesInstaller)
      Vue.use(BillingAdhocItemsInstaller)
      Vue.use(BreakRuleComplianceLogsInstaller)
      Vue.use(BreakRuleConditionsInstaller)
      Vue.use(BreakRuleExceptionsInstaller)
      Vue.use(BreakRuleRelationsInstaller)
      Vue.use(BreakRulesInstaller)
      Vue.use(BreakSessionsInstaller)
      Vue.use(CalendarExceptionGroupDatesInstaller)
      Vue.use(CalendarExceptionsInstaller)
      Vue.use(CheckpointQuestionAnswersInstaller)
      Vue.use(CheckpointScansInstaller)
      Vue.use(CheckpointTourAssignmentsInstaller)
      Vue.use(CheckpointTourScheduleOccurrencesInstaller)
      Vue.use(CheckpointTourSchedulesInstaller)
      Vue.use(CheckpointTourSessionCommentsInstaller)
      Vue.use(CheckpointTourSessionsInstaller)
      Vue.use(CheckpointToursInstaller)
      Vue.use(CheckpointsInstaller)
      Vue.use(CitiesInstaller)
      Vue.use(ClientBillingSettingsInstaller)
      Vue.use(ClientInvoiceSplitSettingsInstaller)
      Vue.use(ClientUsersInstaller)
      Vue.use(ClientsInstaller)
      Vue.use(CommentsInstaller)
      Vue.use(ConflictsInstaller)
      Vue.use(ContactsInstaller)
      Vue.use(ContractBillingCalendarDatesInstaller)
      Vue.use(ContractBillingCalendarsInstaller)
      Vue.use(ContractOccurrenceLogsInstaller)
      Vue.use(ContractOccurrencesInstaller)
      Vue.use(ContractRecurrentFixedServicesInstaller)
      Vue.use(ContractServiceModelsInstaller)
      Vue.use(ContractsInstaller)
      Vue.use(CreditMemosInstaller)
      Vue.use(DataViewCategoriesInstaller)
      Vue.use(DataViewModularHooksInstaller)
      Vue.use(DataViewsInstaller)
      Vue.use(DepartmentsInstaller)
      Vue.use(DispatchCustomLocationsInstaller)
      Vue.use(DispatchEtaLogsInstaller)
      Vue.use(DispatchSlaTimerSettingsInstaller)
      Vue.use(DispatchSlasInstaller)
      Vue.use(DispatchTaskLogsInstaller)
      Vue.use(DispatchTasksInstaller)
      Vue.use(EmergencyContactAccountAssignmentsInstaller)
      Vue.use(EmergencyContactsInstaller)
      Vue.use(EmployeeAccountAssignmentPaySettingsInstaller)
      Vue.use(EmployeeAccountAssignmentsInstaller)
      Vue.use(EmployeeAccountBansInstaller)
      Vue.use(EmployeeAvailabilitiesInstaller)
      Vue.use(EmployeeBillPayMatrixRateSettingsInstaller)
      Vue.use(EmployeeClassPaySettingsInstaller)
      Vue.use(EmployeeClassesInstaller)
      Vue.use(EmployeeExternalIdFieldsInstaller)
      Vue.use(EmployeeExternalIdsInstaller)
      Vue.use(EmployeeExtraRegionsInstaller)
      Vue.use(EmployeePaySettingsInstaller)
      Vue.use(EmployeePremiumAssignmentsInstaller)
      Vue.use(EmployeeRolesInstaller)
      Vue.use(EmployeeSenioritiesInstaller)
      Vue.use(EmployeeSkillsInstaller)
      Vue.use(EmployeesInstaller)
      Vue.use(EmploymentProfilesInstaller)
      Vue.use(EmploymentTerminationReasonsInstaller)
      Vue.use(EntityLabelAvailabilityTypesInstaller)
      Vue.use(EntityLabelsInstaller)
      Vue.use(EventActivitiesInstaller)
      Vue.use(EventsSubscriptionLogsInstaller)
      Vue.use(EventsSubscriptionsInstaller)
      Vue.use(ExceptionTypeDatesInstaller)
      Vue.use(ExceptionTypeGroupItemsInstaller)
      Vue.use(ExceptionTypeGroupsInstaller)
      Vue.use(ExceptionTypesInstaller)
      Vue.use(FilesInstaller)
      Vue.use(FilterRuleSetAssetsInstaller)
      Vue.use(FilterRuleSetReportsInstaller)
      Vue.use(FilterRuleSetsInstaller)
      Vue.use(GeoCountriesInstaller)
      Vue.use(GeoCountrySubdivisionsInstaller)
      Vue.use(GeoFencesInstaller)
      Vue.use(GeoLocationsInstaller)
      Vue.use(GuidResourcesInstaller)
      Vue.use(HazardAlertsInstaller)
      Vue.use(HolidayDatesInstaller)
      Vue.use(HolidayGroupsInstaller)
      Vue.use(HolidaysInstaller)
      Vue.use(InvoiceBatchesInstaller)
      Vue.use(InvoiceLineTaxesInstaller)
      Vue.use(InvoiceLinesInstaller)
      Vue.use(InvoicesInstaller)
      Vue.use(LeaveAccrualsInstaller)
      Vue.use(LeaveBalancesInstaller)
      Vue.use(LeaveManagementAdjustmentsInstaller)
      Vue.use(LeavePoliciesInstaller)
      Vue.use(LeavePolicyBalancesInstaller)
      Vue.use(LeavePolicyCarryOverLogsInstaller)
      Vue.use(LeavePolicyItemsInstaller)
      Vue.use(LeaveRequestLogsInstaller)
      Vue.use(LeaveRequestVacatedShiftsInstaller)
      Vue.use(LeaveRequestsInstaller)
      Vue.use(LeaveTypesInstaller)
      Vue.use(LoneWorkerCheckInLogsInstaller)
      Vue.use(LoneWorkerCheckInTicketsInstaller)
      Vue.use(LoneWorkerCheckInsInstaller)
      Vue.use(LoneWorkerPhoneCallsInstaller)
      Vue.use(LoneWorkerPhonesInstaller)
      Vue.use(LoneWorkerScheduleCheckInsInstaller)
      Vue.use(LoneWorkerTimeKeepingSettingsInstaller)
      Vue.use(MasterServiceTypesInstaller)
      Vue.use(MeInstaller)
      Vue.use(MessageBoardPostAcknowledgementsInstaller)
      Vue.use(MessageBoardPostCategoriesInstaller)
      Vue.use(MessageBoardPostRecipientsInstaller)
      Vue.use(MessageBoardPostsInstaller)
      Vue.use(MobileDeviceGeoLocationsInstaller)
      Vue.use(MobileDevicesInstaller)
      Vue.use(MobileRunsheetExceptionTypesInstaller)
      Vue.use(MobileRunsheetGroupsInstaller)
      Vue.use(MobileRunsheetOccurrencesInstaller)
      Vue.use(MobileRunsheetSessionsInstaller)
      Vue.use(MobileRunsheetSessionsLogsInstaller)
      Vue.use(MobileRunsheetsInstaller)
      Vue.use(MobileScheduleExceptionTypesInstaller)
      Vue.use(MobileScheduleGroupsInstaller)
      Vue.use(MobileScheduleOccurrenceLogsInstaller)
      Vue.use(MobileScheduleOccurrenceReassignmentsInstaller)
      Vue.use(MobileScheduleOccurrencesInstaller)
      Vue.use(MobileSchedulesInstaller)
      Vue.use(ModelTranslationsInstaller)
      Vue.use(NotesInstaller)
      Vue.use(Oauth2AccessTokensInstaller)
      Vue.use(Oauth2AuthCodesInstaller)
      Vue.use(Oauth2ClientsInstaller)
      Vue.use(Oauth2RefreshTokensInstaller)
      Vue.use(OpenShiftRequestsInstaller)
      Vue.use(OperationCenterEscalationPoliciesInstaller)
      Vue.use(OperationCenterEscalationTicketsInstaller)
      Vue.use(OperationCenterOperatorsInstaller)
      Vue.use(OperationCenterRegionsInstaller)
      Vue.use(OperationCentersInstaller)
      Vue.use(OvertimeRuleItemsInstaller)
      Vue.use(OvertimeRulesInstaller)
      Vue.use(PatrolRecordingsInstaller)
      Vue.use(PayAndBillTransactionsInstaller)
      Vue.use(PaymentMethodsInstaller)
      Vue.use(PaymentsInstaller)
      Vue.use(PayrollAdhocsInstaller)
      Vue.use(PayrollCodesInstaller)
      Vue.use(PayrollDeductionsInstaller)
      Vue.use(PayrollPayrunItemsInstaller)
      Vue.use(PayrollPayrunsInstaller)
      Vue.use(PayrollScheduleOccurrencesInstaller)
      Vue.use(PayrollSchedulesInstaller)
      Vue.use(PeriodicSchedulesInstaller)
      Vue.use(PhoneCallLogsInstaller)
      Vue.use(PositionBillSettingsInstaller)
      Vue.use(PositionBillingSettingsInstaller)
      Vue.use(PositionExpenseReimbursementsInstaller)
      Vue.use(PositionPaySettingsInstaller)
      Vue.use(PositionPayrollSettingsInstaller)
      Vue.use(PositionPremiumAssignmentsInstaller)
      Vue.use(PositionPremiumsInstaller)
      Vue.use(PositionPriceMatrixRulesInstaller)
      Vue.use(PositionSkillsInstaller)
      Vue.use(PositionsInstaller)
      Vue.use(PostOrderAcknowledgementsInstaller)
      Vue.use(PostOrderSubjectsInstaller)
      Vue.use(PostOrdersInstaller)
      Vue.use(PriceTiersInstaller)
      Vue.use(PrivacyPoliciesInstaller)
      Vue.use(ProcessedPayrollPayrunsInstaller)
      Vue.use(RegionsInstaller)
      Vue.use(ReportConditionSetActionsInstaller)
      Vue.use(ReportConditionSetsInstaller)
      Vue.use(ReportConditionsInstaller)
      Vue.use(ReportFieldsInstaller)
      Vue.use(ReportFlagCategoriesInstaller)
      Vue.use(ReportFlagGroupAssignmentsInstaller)
      Vue.use(ReportFlagGroupsInstaller)
      Vue.use(ReportFlagTemplateFieldGroupFieldsInstaller)
      Vue.use(ReportFlagTemplateFieldGroupsInstaller)
      Vue.use(ReportFlagTemplateFieldsInstaller)
      Vue.use(ReportFlagTemplatesInstaller)
      Vue.use(ReportFlagsInstaller)
      Vue.use(ReportLibrariesInstaller)
      Vue.use(ReportLibraryFieldsInstaller)
      Vue.use(ReportPreDefinedFieldTemplatesInstaller)
      Vue.use(ReportTemplateFieldGroupFieldsInstaller)
      Vue.use(ReportTemplateFieldGroupsInstaller)
      Vue.use(ReportTemplateFieldsInstaller)
      Vue.use(ReportTemplatesInstaller)
      Vue.use(ReportTemplatesCategoryInstaller)
      Vue.use(ReportsInstaller)
      Vue.use(RevenueProcessorOccurrencesInstaller)
      Vue.use(RoleAccessPoliciesInstaller)
      Vue.use(RolePermissionsInstaller)
      Vue.use(RolesInstaller)
      Vue.use(ScheduleOffPeriodsInstaller)
      Vue.use(SchedulersInstaller)
      Vue.use(ShiftLogsInstaller)
      Vue.use(ShiftNotesInstaller)
      Vue.use(ShiftPeriodSummariesInstaller)
      Vue.use(ShiftProposalQueueItemsInstaller)
      Vue.use(ShiftSlicesInstaller)
      Vue.use(ShiftSummariesInstaller)
      Vue.use(ShiftTemplateSettingsInstaller)
      Vue.use(ShiftTemplatesInstaller)
      Vue.use(ShiftsInstaller)
      Vue.use(SiteLocationsInstaller)
      Vue.use(SiteTaskOccurrenceSessionLogsInstaller)
      Vue.use(SiteTaskOccurrenceSessionsInstaller)
      Vue.use(SiteTaskOccurrencesInstaller)
      Vue.use(SiteTaskScheduleExceptionTypesInstaller)
      Vue.use(SiteTaskScheduleTimingsInstaller)
      Vue.use(SiteTaskSchedulesInstaller)
      Vue.use(SiteVendorsInstaller)
      Vue.use(SkillCategoriesInstaller)
      Vue.use(SkillsInstaller)
      Vue.use(SmsLogsInstaller)
      Vue.use(SystemExceptionTicketInstructionsInstaller)
      Vue.use(SystemExceptionTicketsInstaller)
      Vue.use(SystemSettingsInstaller)
      Vue.use(SystemUsageLogsInstaller)
      Vue.use(TaskPriceTierBillSettingsInstaller)
      Vue.use(TaskPriceTiersInstaller)
      Vue.use(TaskSiteInstructionsInstaller)
      Vue.use(TaskSiteSettingsInstaller)
      Vue.use(TaskTypeSkillsInstaller)
      Vue.use(TaskTypesInstaller)
      Vue.use(TaxClassItemsInstaller)
      Vue.use(TaxClassesInstaller)
      Vue.use(TaxLocationOverrideGroupsInstaller)
      Vue.use(TaxLocationOverrideMappingsInstaller)
      Vue.use(TaxLocationsInstaller)
      Vue.use(TenantsInstaller)
      Vue.use(TwilioAccountsInstaller)
      Vue.use(UnitsInstaller)
      Vue.use(UserLoginLogsInstaller)
      Vue.use(UserNotificationSettingsInstaller)
      Vue.use(UserRolesInstaller)
      Vue.use(UsersInstaller)
      Vue.use(VehiclesInstaller)
      Vue.use(VendorsInstaller)
      Vue.use(WhitelistedIpsInstaller)
      Vue.use(WorkSessionsInstaller)
      Vue.use(WorkflowActionSettingsInstaller)
      Vue.use(WorkflowInstanceLogsInstaller)
      Vue.use(WorkflowInstanceTimerLogsInstaller)
      Vue.use(WorkflowInstanceTimersInstaller)
      Vue.use(WorkflowInstancesInstaller)
      Vue.use(WorkflowStatusActionsInstaller)
      Vue.use(WorkflowStatusTransitionFormsInstaller)
      Vue.use(WorkflowStatusTransitionsInstaller)
      Vue.use(WorkflowStatusesInstaller)
      Vue.use(WorkflowTimersInstaller)
      Vue.use(WorkflowsInstaller)
      Vue.use(ZoneClientsInstaller)
      Vue.use(ZonesInstaller)
    }
  },
}
