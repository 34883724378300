<template>
  <GenericForm
    v-bind="payload"
    @cancel="back"
    @close="close"
    @submit:error="onSubmitError"
    @submit:success="onSubmitSuccess"
  />
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { ActiveWindow, DialogFormInterface, LayoutWindowEvent } from '../types'
import { GenericFormError, GenericFormSuccess } from './GenericForm.vue'

type VueWithInjections = VueConstructor<Vue & { activeWindow: ActiveWindow }>

export default (Vue as VueWithInjections).extend({
  name: 'GenericFormPage',
  inject: {
    // Not used internally, but may be used in the payload.close callback
    activeWindow: { default: null },
  },
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  methods: {
    back(): void {
      this.emitCloseEvent('back')
    },
    close(): void {
      this.emitCloseEvent('close')
    },
    emitCloseEvent(eventName: 'back' | 'close'): void {
      if (this.payload.close) this.payload.close(this)
      this.$emit(eventName)
    },
    onSubmitError({ data, error }: GenericFormError): void {
      if (this.payload.error) {
        this.payload.error(error, data)
      } else {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
          message: this.$i18n.t('common.error_message'),
        })
      }
    },
    onSubmitSuccess({ data, response }: GenericFormSuccess): void {
      if (this.payload.success) {
        this.payload.success(data, response)
      }
      this.back()
    },
  },
})
</script>
