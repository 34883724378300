<template>
  <v-text-field
    disabled
    error
    :error-messages="missingVariableErrorMessage"
    prepend-inner-icon="mdi-alert-circle-outline"
    v-bind="{ ...$attrs }"
  />
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { FormHookProvider } from '@/tt-widget-components'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import { removeVariablePrefix } from './utils/RelationField'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'RelationFieldMissingVariables',
  inject: {
    formHook: { from: 'formHook' },
  },
  props: {
    missingVariableValues: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  computed: {
    actionFormName(): string {
      return this.formHook().getUserContextValue('action')
    },
    /**
     * The main form resource. Not the current relation resource.
     */
    formHookResource(): string {
      return this.formHook().getUserContextValue('resourceName')
    },
    isActionForm(): boolean {
      return !!this.actionFormName
    },
    missingVariableErrorMessage(): string {
      const translateVariable = (attr) =>
        this.isActionForm
          ? this.translateActionAttribute(attr)
          : this.translateEntityAttribute(attr)

      const missingVariables = this.missingVariableValues
        .map(translateVariable)
        .join(', ')

      const errorMsg = i18n.t(
        'tt-entity-forms.relation-fields.missing-variables-error',
      )

      return `${errorMsg} (${missingVariables})`
    },
  },
  methods: {
    translateActionAttribute(attr: string) {
      return ResourceTranslator.translateActionAttribute(
        this.formHookResource,
        this.actionFormName,
        removeVariablePrefix(attr),
        FormLabelTypes.LABEL,
      )
    },
    translateEntityAttribute(attr: string) {
      const { resourceMetaManager } = this.$appContext.widgetServices
      return ResourceTranslator.translateAttribute(
        this.formHookResource,
        removeVariablePrefix(attr),
        FormLabelTypes.LABEL,
        null,
        { resourceMetaManager },
      )
    },
  },
})
</script>
