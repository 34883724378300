import { DateTime } from '@/helpers/dates/types'
import { Filter } from '@/tt-widget-components'
import { FieldTypes } from '@/tt-widget-factory'
import { createIsoDatetime } from '@/helpers/dates/createIsoDatetime'
import { createDateTimeString } from '@/helpers/dates/createDateTimeString'

import { createFilter } from '../util'
import { createTemporalFilterManager } from './TemporalFilterManager'
import { BETWEEN_SEPARATOR_TEMPORAL } from './contants'
import { FieldTypeTemporal } from './types'

type Formatter = ({ date, time }: DateTime) => string

/**
 * Convers a temporal filter to a query filter, ready to be sent to the API.
 */
export const convertTemporalFilterToQueryFilter = (
  filter: Filter,
  type: FieldTypeTemporal,
): Filter => {
  const manager = createTemporalFilterManager(filter)

  if (manager.isPreset()) return manager.getFilter()

  const timezone = manager.getTimezone()

  const typeFormatterMap: Record<FieldTypeTemporal, Formatter> = {
    [FieldTypes.Date]: ({ date }) => date,
    [FieldTypes.DateTime]: ({ date, time }) => createDateTimeString(date, time),
    [FieldTypes.TimeStampDate]: ({ date, time }) =>
      createIsoDatetime(date, time, timezone),
    [FieldTypes.TimeStampNumber]: ({ date, time }) =>
      createIsoDatetime(date, time, timezone),
  }

  const formatter = typeFormatterMap[type]

  const range = manager.getDateTimeRange()

  if (manager.isExactDate()) {
    const value = formatter(range[0])

    return createFilter(filter.attribute, filter.operator, value)
  }

  if (manager.isDateRange()) {
    const value = range.map(formatter).join(BETWEEN_SEPARATOR_TEMPORAL)

    return createFilter(filter.attribute, filter.operator, value)
  }

  return null
}
