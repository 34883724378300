<template>
  <MetadataLoadingGuard>
    <v-card>
      <v-toolbar dense flat color="#fafafa">
        <v-app-bar-nav-icon>
          <v-icon color="orange">insert_chart</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title
          class="ml-3"
          v-text="$t(`scim_form_view.toolbar.title`)"
        />
      </v-toolbar>
      <v-container style="min-height: 200px">
        <v-row class="px-3">
          <v-col>
            <v-select
              v-model="property"
              :items="items"
              label="Select SCIM property"
              outlined
              :loading="isLoading"
            ></v-select>

            <div v-if="schema" class="pa-6">
              <JsonForm
                v-model="model"
                :schema="schema"
                :form-options="formOptions"
                :show-status="true"
                @valid="showSubmit = true"
                @error="showSubmit = false"
              />
              <v-btn v-if="showSubmit" color="success" @click="submit">
                <span v-text="$t(`scim_form_view.button.submit`)" />
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </MetadataLoadingGuard>
</template>

<script lang="ts">
import Vue from 'vue'

import { JsonForm, FormOptions } from '@tracktik/tt-json-schema-form'
import { AppContext } from '@/tt-app-context'
import MetadataLoadingGuard from '@/tt-widget-views/components/MetadataLoadingGuard.vue'

export enum PropertyEnum {
  USER = 'User',
  GROUP = 'Group',
}

const updatedBaseUrl = (defaultUrl: string) => {
  return defaultUrl.replace('/rest/v1', '/rest/scim/v2')
}

export default Vue.extend({
  name: 'ScimFormView',
  components: { JsonForm, MetadataLoadingGuard },
  data() {
    return {
      items: [PropertyEnum.USER, PropertyEnum.GROUP],
      property: null as PropertyEnum,
      definitions: {
        Relation: {
          view: { is: 'RelationField', props: { outlined: true, dense: true } },
        },
      },
      schema: null,
      formOptions: null as FormOptions,
      model: null,
      callback: null,
      showSubmit: false,
      isLoading: false,
    }
  },
  computed: {
    baseURL(): string {
      return updatedBaseUrl(this.$appContext.authModule.getServerUrl())
    },
  },
  watch: {
    property: {
      handler(property: PropertyEnum) {
        this.schema = null
        this.model = null
        this.formOptions = null
        this.callback = null

        if (property) this.fetchReport(property)
      },
    },
  },
  methods: {
    fetchReport(property: PropertyEnum): void {
      const appContext: AppContext = this.$appContext

      const setState = ({ data }) => {
        const { schema, values, callback } = data

        this.formOptions = { definitions: this.definitions }

        this.schema = schema
        this.model = values
        this.callback = callback
      }

      this.isLoading = true

      appContext.authModule
        .getHttp()
        .get(`/ConfigurationMapping/JsonFormSchema/${property}`, {
          baseURL: this.baseURL,
        })
        .then(setState)
        .catch(console.warn)
        .finally(() => {
          this.isLoading = false
        })
    },
    submit() {
      const appContext: AppContext = this.$appContext

      appContext.authModule
        .getHttp()
        .put(`/ConfigurationMapping/${this.property}`, this.model, {
          baseURL: this.baseURL,
        })
        .then(console.log)
        .catch(console.warn)
    },
    clear() {
      this.model = {}
    },
  },
})
</script>
