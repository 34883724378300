import MarkerCollectionWidgetHook from '@/tt-widget-components/widgets/MapMultiMarkers/MarkerCollectionWidgetHook'
import {
  BasicMarkerOptions,
  Filter,
  FiltersMap,
  MapMultiMarkersWidgetModel,
  MarkerComponent,
} from '../../types'
import { BaseWidgetHook } from '@/tt-widget-factory/types'
import ResourcePermissionAuditor from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { reactive } from 'vue'
import type { MapManager } from '@tracktik/tt-maps'
import { mergeManagedFilters } from '@/tt-entity-filter/helpers/managed-filters'

type ConfigID = string
export type HooksDictionnary = Record<ConfigID, MarkerCollectionWidgetHook>

export default class MapMultiMarkersWidgetHook extends BaseWidgetHook<MapMultiMarkersWidgetModel> {
  mapState = reactive({
    hooks: {} as HooksDictionnary,
    hiddenHooks: [] as ConfigID[],
  })

  mapManager: MapManager | null = null

  get isValid(): boolean {
    return true
  }

  isHookVisible(uid: ConfigID): boolean {
    return !this.mapState.hiddenHooks.includes(uid)
  }

  toggleHookVisibility(uid: ConfigID): void {
    if (this.mapState.hiddenHooks.includes(uid))
      this.mapState.hiddenHooks = this.mapState.hiddenHooks.filter(
        (item) => item !== uid,
      )
    else this.mapState.hiddenHooks.push(uid)
  }

  getVisibleHooks(): HooksDictionnary {
    return Object.fromEntries(
      Object.entries(this.mapState.hooks).filter(([uid]) =>
        this.isHookVisible(uid),
      ),
    )
  }

  hasPermission(): boolean {
    const { authModule, resourceMetaManager } = this.services

    const resources = this.getResourcesAndContext().map((item) => item.resource)

    return ResourcePermissionAuditor.canViewAllResources(
      { authModule, resourceMetaManager },
      resources,
    )
  }

  initializeMarkers(mapManager: MapManager) {
    const createMarkerHooks = () => {
      this.widget?.configs.forEach((config) => {
        const hook = new MarkerCollectionWidgetHook(
          {
            services: this.services,
            widget: config,
            options: {
              skipValidation: true,
            },
          },
          { mapManager },
        )

        hook.initialize()

        this.mapState.hooks = {
          ...this.mapState.hooks,
          [config.uid]: hook,
        }
      })
    }

    createMarkerHooks()
    this.mapManager = mapManager
  }

  getMarkerOptions(uid: string): BasicMarkerOptions | MarkerComponent {
    return (
      this.widget.configs.find((config) => config.uid === uid)?.markerOptions ??
      {}
    )
  }

  get loading(): boolean {
    return Object.values(this.mapState.hooks).some(
      (markerHook) => markerHook.loading,
    )
  }

  isOverAPILimit(): boolean {
    return Object.values(this.mapState.hooks).some((markerHook) =>
      markerHook.isOverAPILimit(),
    )
  }

  setFilter(hookUid: string, attribute: string, filter: Filter | null): void {
    this.setFilters(hookUid, { [attribute]: filter })
  }

  setFilters(hookUid: string, filters: FiltersMap): void {
    const newFilters = mergeManagedFilters(
      this.mapState.hooks[hookUid].queryManager.customFilters,
      filters,
      this.mapState.hooks[hookUid].widget.toolbar?.filterOptions,
    )
    const newFiltersList = Object.values(newFilters)
    this.mapState.hooks[hookUid].queryManager.setCustomFilters(newFiltersList)
  }

  destroy(): void {
    super.destroy()
  }
}
