// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowTimersTile from './WorkflowTimersTile.vue'
import WorkflowTimersColumns from './WorkflowTimersColumns'
import WorkflowTimersDetail from './WorkflowTimersDetail.vue'
import WorkflowTimersMetaCard from './WorkflowTimersMetaCard.vue'
import WorkflowTimersReference from './WorkflowTimersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowTimersTile', WorkflowTimersTile)
    Vue.component('WorkflowTimersDetail', WorkflowTimersDetail)
    Vue.component('WorkflowTimersMetaCard', WorkflowTimersMetaCard)
    Vue.component('WorkflowTimersReference', WorkflowTimersReference)

    modularManager.addResourcePreset(
      Resources.WORKFLOW_TIMERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowTimersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_TIMERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowTimersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_TIMERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowTimersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_TIMERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowTimersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_TIMERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowTimersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
