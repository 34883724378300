// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerPhonesTile from './LoneWorkerPhonesTile.vue'
import LoneWorkerPhonesColumns from './LoneWorkerPhonesColumns'
import LoneWorkerPhonesDetail from './LoneWorkerPhonesDetail.vue'
import LoneWorkerPhonesReference from './LoneWorkerPhonesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LoneWorkerPhonesTile', LoneWorkerPhonesTile)
    Vue.component('LoneWorkerPhonesDetail', LoneWorkerPhonesDetail)
    Vue.component('LoneWorkerPhonesReference', LoneWorkerPhonesReference)

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhonesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerPhonesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhonesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhonesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
