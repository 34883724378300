// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionPayrollSettingsTile from './PositionPayrollSettingsTile.vue'
import PositionPayrollSettingsColumns from './PositionPayrollSettingsColumns'
import PositionPayrollSettingsDetail from './PositionPayrollSettingsDetail.vue'
import PositionPayrollSettingsReference from './PositionPayrollSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionPayrollSettingsTile', PositionPayrollSettingsTile)
    Vue.component(
      'PositionPayrollSettingsDetail',
      PositionPayrollSettingsDetail,
    )
    Vue.component(
      'PositionPayrollSettingsReference',
      PositionPayrollSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_PAYROLL_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionPayrollSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAYROLL_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionPayrollSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAYROLL_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPayrollSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAYROLL_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionPayrollSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
