// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileDeviceGeoLocationsTile from './MobileDeviceGeoLocationsTile.vue'
import MobileDeviceGeoLocationsColumns from './MobileDeviceGeoLocationsColumns'
import MobileDeviceGeoLocationsDetail from './MobileDeviceGeoLocationsDetail.vue'
import MobileDeviceGeoLocationsMetaCard from './MobileDeviceGeoLocationsMetaCard.vue'
import MobileDeviceGeoLocationsReference from './MobileDeviceGeoLocationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileDeviceGeoLocationsTile', MobileDeviceGeoLocationsTile)
    Vue.component(
      'MobileDeviceGeoLocationsDetail',
      MobileDeviceGeoLocationsDetail,
    )
    Vue.component(
      'MobileDeviceGeoLocationsMetaCard',
      MobileDeviceGeoLocationsMetaCard,
    )
    Vue.component(
      'MobileDeviceGeoLocationsReference',
      MobileDeviceGeoLocationsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICE_GEO_LOCATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileDeviceGeoLocationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICE_GEO_LOCATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileDeviceGeoLocationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICE_GEO_LOCATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileDeviceGeoLocationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICE_GEO_LOCATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileDeviceGeoLocationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICE_GEO_LOCATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileDeviceGeoLocationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
