// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BreakRulesTile from './BreakRulesTile.vue'
import BreakRulesColumns from './BreakRulesColumns'
import BreakRulesDetail from './BreakRulesDetail.vue'
import BreakRulesMetaCard from './BreakRulesMetaCard.vue'
import BreakRulesReference from './BreakRulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BreakRulesTile', BreakRulesTile)
    Vue.component('BreakRulesDetail', BreakRulesDetail)
    Vue.component('BreakRulesMetaCard', BreakRulesMetaCard)
    Vue.component('BreakRulesReference', BreakRulesReference)

    modularManager.addResourcePreset(
      Resources.BREAK_RULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BreakRulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BreakRulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRulesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BreakRulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
