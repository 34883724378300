import Vue, { VueConstructor } from 'vue'

import { LayoutExtensionSlots } from '@/tt-app-modular'

import LayoutManager from '../LayoutManager'
import { AppLayoutState, LayoutWindowEvent } from '../types'

type VueWithLayoutManager = VueConstructor<
  Vue & { layoutManager: LayoutManager }
>

export default (Vue as VueWithLayoutManager).extend({
  name: 'AppBottomSheet',
  inject: ['layoutManager'],
  data() {
    return {
      eventType: LayoutWindowEvent,
      slotNames: LayoutExtensionSlots,
    }
  },
  computed: {
    state(): AppLayoutState {
      return this.layoutManager.state
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    isPhone(): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
})
