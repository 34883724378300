<template>
  <v-card flat>
    <v-toolbar dense short>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        {{ $t('common.download.btn') }}
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="copy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        {{ $t('common.copy.btn') }}
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <JsonPretty
        :show-length="true"
        :show-line="false"
        :show-double-quotes="true"
        :data="jsonCleaned"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import { cloneDeep } from 'lodash'
import { copyToClipboard } from '@/tt-widget-components/lib/copy-to-clipboard'
import { downloadAsFile } from '@/helpers/downloadAsFile'

export default Vue.extend({
  name: 'WidgetJsonViewer',
  props: {
    json: Object,
  },
  computed: {
    asString(): string {
      return JSON.stringify(this.jsonCleaned, null, 2)
    },
    jsonCleaned(): any {
      const json = cloneDeep(this.json)
      delete json.provider
      delete json.uid
      return json
    },
  },
  methods: {
    async copy() {
      await copyToClipboard(this.asString, this.$eventManager)
    },
    download() {
      downloadAsFile(
        this.asString,
        `${this.json.title.replace(/ /g, '-')}.widget`,
        'JSON',
      )
    },
  },
})
</script>
