import { AuthModule } from '@tracktik/tt-authentication'
import { Resources } from '../../../types'
import { TaskAsset, TaskSiteSettings } from './types'

const uniqueAssets = (acc, curr) =>
  acc.some((asset) => asset.id === curr.id) ? acc : [...acc, curr]

const createAssetModel = ({ id, name, account, type, assets }): TaskAsset => ({
  id,
  name,
  account: {
    id: account.id,
    name: account.name,
    type: account.type,
  },
  type: {
    id: type.id,
    name: type.name,
  },
  assets:
    assets?.map(({ id }) => ({
      id,
    })) || [],
})

export const getAssetsFromInstructions = (
  taskInstructions: TaskSiteSettings['taskInstructions'],
  taskId,
): TaskAsset[] => {
  if (!taskInstructions?.length) return []

  const isCorrectType = (instruction) => instruction.taskType === taskId

  return taskInstructions
    .filter(isCorrectType)
    .map((item) => item.dispatchableAssets.map(createAssetModel))
    .flat()
    .reduce(uniqueAssets, [])
}

const ASSET_FIELDS_TO_INCLUDE = ['account', 'assets', 'type']

export const createSubAssetsFetcher =
  (auth: AuthModule) =>
  async (assetId: number): Promise<TaskAsset[]> => {
    const url = `${Resources.ASSETS}/${assetId}/${Resources.ASSETS}`

    return auth
      .getApi()
      .getAll(url, {
        include: ASSET_FIELDS_TO_INCLUDE,
      })
      .then((assets) => assets.items.map(createAssetModel))
      .catch(() => {
        console.warn('Error while fetching sub assets')

        return []
      })
  }

export const fetchTaskSiteSettings = async (
  auth: AuthModule,
  clientId: number,
): Promise<TaskSiteSettings> => {
  const assetBasePath = 'taskInstructions.dispatchableAssets'

  const include = ASSET_FIELDS_TO_INCLUDE.map(
    (field) => `${assetBasePath}.${field}`,
  )

  /**
   * In the API:
   * taskSiteSettings ID === client ID
   */
  const taskSiteSettingID = clientId // 237 client with assets

  return auth
    .getApi()
    .get(Resources.TASK_SITE_SETTINGS, taskSiteSettingID, {
      include,
    })
    .catch((err) => {
      console.error('Could not fetch task site settings', err)

      return null
    })
}
