// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ClientUsersTile from './ClientUsersTile.vue'
import ClientUsersColumns from './ClientUsersColumns'
import ClientUsersDetail from './ClientUsersDetail.vue'
import ClientUsersMetaCard from './ClientUsersMetaCard.vue'
import ClientUsersReference from './ClientUsersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ClientUsersTile', ClientUsersTile)
    Vue.component('ClientUsersDetail', ClientUsersDetail)
    Vue.component('ClientUsersMetaCard', ClientUsersMetaCard)
    Vue.component('ClientUsersReference', ClientUsersReference)

    modularManager.addResourcePreset(
      Resources.CLIENT_USERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ClientUsersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_USERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ClientUsersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_USERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ClientUsersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_USERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ClientUsersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_USERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ClientUsersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
