<template>
  <div data-cy="app-action-menu">
    <v-list :key="$i18n.locale" :dense="dense">
      <template v-if="menu.header">
        <v-subheader class="navigation-group" v-text="$t(menu.header.text)" />
        <v-divider />
      </template>

      <template v-for="(item, index) in menu.items">
        <template v-if="item.action && item.action.type === 'component'">
          <component
            v-bind="item.action.props"
            :is="item.action.props.is"
            :key="`comp.nav.${index}`"
          />
        </template>
        <template v-else>
          <v-list-item
            :key="index"
            class="navigation-item px-0"
            :class="isRouteActive(item) ? 'highlighted' : ''"
            :data-cy="`action-${item.icon}`"
            @click="navigate(item)"
          >
            <v-tooltip
              top
              :disabled="!showTooltip"
              :open-delay="tooltipOpenDelay"
            >
              <template #activator="{ on }">
                <div class="d-flex flex-grow-1 px-4" v-on="on">
                  <v-list-item-icon v-if="item.icon" class="mr-4">
                    <v-icon :color="item.color" size="20">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <component
                    :is="item.component"
                    v-else-if="item.component"
                    class="mr-4"
                  />
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-light"
                      v-text="$t(item.text)"
                    />
                  </v-list-item-content>
                </div>
              </template>
              <span v-text="$t(item.text)" />
            </v-tooltip>
          </v-list-item>
        </template>
        <v-divider
          v-if="divider && index < menu.items.length - 1"
          :key="`menu-${index}`"
        />
      </template>
    </v-list>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  ActionMenuConfig,
  ActionMenuItem,
  isActionDispatch,
  ActionTypes,
} from '@/tt-app-layout'
import { executeAction } from '@/tt-app-layout/utils'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'AppActionMenu',
  props: {
    menu: {
      /**
       * @todo: to change to another type here without a callback, we just want
       * a basic object type. FE-1048
       */
      type: Object as PropType<ActionMenuConfig>,
      required: true,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
  },
  methods: {
    isRouteActive(item: ActionMenuItem): boolean {
      if (item.action?.type === 'route')
        return item.action.props.name === this.$router.currentRoute.name

      return false
    },
    navigate(item: ActionMenuItem) {
      this.emitEventFromAction(item.action)
      executeAction(this.$appContext, this.$router, item.action)
    },
    emitEventFromAction(action: ActionTypes): void {
      this.$emit('click', action)

      const event = this.extractEventNameFromAction(action)
      if (event) {
        this.$emit(`click:${event}`, action)
      }
    },
    extractEventNameFromAction(action: ActionTypes): string | undefined {
      if (isActionDispatch(action)) {
        const { eventName } = action.props
        return eventName.split('.').splice(-1)[0]
      }
    },
  },
})
</script>

<style scoped>
.highlighted::before {
  opacity: 0.24;
}
</style>
