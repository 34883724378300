<template>
  <div>
    <tt-attr :name="name" empty-value="tt-entity-design.no-report-category">
      <template #default="{ value }">
        <v-chip
          x-small
          label
          text-color="grey"
          style="max-width: 130px"
          :title="value"
          v-text="truncateText(value)"
        />
      </template>
    </tt-attr>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import truncate from 'lodash/truncate'

export default Vue.extend({
  name: 'ReportsCategory',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    truncateText(value: string): string {
      return truncate(value, { length: 18, omission: '...' })
    },
  },
})
</script>
