<template>
  <span v-if="tag === systemSettingsTag.MODULES" :title="value.modules">
    <v-chip
      v-for="(module, index) in value ? value.modules : []"
      :key="index"
      class="chip"
      color="#ff7833"
      dark
      label
      x-small
    >
      {{ $t(module) }}
    </v-chip>
  </span>
  <div v-else-if="valueAsObject">
    <div v-for="(propValue, propKey) in valueAsObject" :key="propKey">
      <v-chip class="chip" color="primary" dark label x-small>
        {{ $t(propKey) }}
      </v-chip>
      <v-chip
        v-if="isBoolean(propValue)"
        class="chip"
        :color="propValue ? 'success' : 'error'"
        dark
        label
        x-small
      >
        {{ $t(propValue ? 'common.enabled' : 'common.disabled') }}
      </v-chip>
      <v-chip
        v-else-if="isColor(propValue)"
        class="px-2"
        :color="propValue"
        label
        :title="propValue"
        x-small
      />
      <img v-else-if="isImage(propValue)" class="image" :src="propValue" />
      <span v-else-if="isString(propValue)">{{ propValue }}</span>
      <span v-else>{{ stringify(propValue) }}</span>
    </div>
  </div>
  <tt-attr v-else name="value" style="text-overflow: ellipsis" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import isObject from '@/helpers/object/isObject'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { systemSettingsTag } from './system-settings-tag'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'SystemSettingsValue',
  inject: ['getItemHook'],
  computed: {
    isBooleanMap(): boolean {
      return (
        isObject(this.value) && Object.values(this.value).every(this.isBoolean)
      )
    },
    systemSettingsTag(): typeof systemSettingsTag {
      return systemSettingsTag
    },
    tag(): string {
      return this.getItemHook().getRawValue('tag')
    },
    value(): any {
      return this.getItemHook().entityReference.entity.value
    },
    valueAsBooleanMap(): Record<string, boolean> | undefined {
      return this.isBooleanMap ? this.value : undefined
    },
    valueAsObject(): Record<string, any> | undefined {
      return isObject(this.value) ? this.value : undefined
    },
  },
  created() {
    this.getItemHook().addAttribute('tag')
    this.getItemHook().addAttribute('value')
  },
  methods: {
    isBoolean(value: unknown): value is boolean {
      return typeof value === 'boolean'
    },
    isColor(value: unknown): value is string {
      return this.isString(value) && CSS.supports('color', value)
    },
    isImage(value: unknown): value is string {
      if (!this.isURL(value)) return false
      const imageExtentionRegex = /\.(bmp|gif|jfif|jpe?g|png|tiff?|webp)$/i

      return imageExtentionRegex.test(new URL(value).pathname)
    },
    isString(value: unknown): value is string {
      return typeof value === 'string'
    },
    isURL(value: unknown): value is string {
      if (!this.isString(value)) return
      try {
        const url = new URL(value)

        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch (_) {
        return false
      }
    },
    stringify(value: unknown): string {
      return JSON.stringify(value)
    },
  },
})
</script>

<style scoped>
.chip {
  margin-right: 1px;
  padding-left: 4px;
  padding-right: 4px;
}
.image {
  height: 50px;
  vertical-align: top;
  width: 50px;
}
</style>
