import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const PieChartWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'PieChartWidget',
  'Pie Chart',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)

PieChartWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/PieChartOptions',
  },
})

PieChartWidgetBuilder.addDefinitions({
  PieChartOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      colorPalette: {
        title: 'Color Palette',
        $ref: '#/definitions/ColorPalette',
      },
      disableClick: {
        title: 'Disable Click',
        $ref: '#/definitions/DisableClick',
      },
    },
  },
})

export default PieChartWidgetBuilder.schema
