import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import CheckpointTourSessionsResultColumn from '@/tt-entity-design/src/components/checkpoint-tour-sessions/CheckpointTourSessionsResultColumn'
import { createDocumentColumn } from '@/tt-widget-entity-flow/createDocumentColumn'
import CheckpointTourSessionDoc from '@/tt-entity-design/src/components/checkpoint-tour-sessions/CheckpointTourSessionDoc'

export default [
  createDocumentColumn(CheckpointTourSessionDoc),
  'tour',
  'tour.account',
  CheckpointTourSessionsResultColumn,
  'performedBy',
  {
    attributeName: 'status',
    allowSorting: false,
    allowFilters: false,
  },
  'startedOn',
  'endedOn',
  {
    allowSorting: false,
    allowFilters: false,
    headerText:
      'tt-entity-design.checkpoint-tour-sessions.attrs.actual-duration',
    component: {
      is: 'ActualDurationInMinuteAttr',
    },
  },
] as ColumnInputDefinition[]
