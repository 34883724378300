<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { getEditFormState } from '../../tools/helpers/form-state-generator'
import { EntityEditIntentInterface } from '@/tt-widget-entity-flow'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import { ARCHIVE } from './constants'
import { createViewAction } from '@/tt-entity-design/src/tools/actions-generator'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & ActiveWindowProvider
>
export default (Vue as VueWithInjections).extend({
  name: 'SystemExceptionTicketInstructionsActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  computed: {
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const payload = await this.getItemHook().getActionFormAsPage(actionName)

        this.activeWindow.next(payload)
      }

      return {
        [ARCHIVE]: createViewAction(
          'mdi-archive-outline',
          ARCHIVE,
          Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
          openFormAction,
        ),
      }
    },
    actionList(): ViewAction[] {
      const availableActions: ViewAction[] = this.actions
        .map((action) => {
          return (
            this.actionsDictionnary[action.actionName] || (null as ViewAction)
          )
        })
        .filter(Boolean)

      const editAction: ViewAction = {
        icon: 'mdi-pencil-outline',
        title: this.$t('common.edit.btn'),
        onClick: async () => this.openTasksForm(),
      }

      return [editAction, ...availableActions]
    },
  },
  methods: {
    async openTasksForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        formOptions: {
          fieldErrorRule: FieldErrorRule.ALWAYS,
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        props: { payload },
      })
    },
  },
})
</script>
