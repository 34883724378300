<template>
  <div class="fill-height d-flex flex-column">
    <WidgetTitle :hook="hook" :container="container" />
    <DashboardWidgetCard
      v-if="editMode"
      class="card-container"
      :item="hook.widget"
    />
    <iframe
      v-if="hook.url && !editMode"
      :src="hook.url"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    />
  </div>
</template>

<script lang="ts">
import { ContextManagerInterface } from '@/tt-widget-factory'
import { VueConstructor } from 'vue'
import BaseWidget from '../../components/BaseWidget'
import DashboardWidgetCard from '../../widgets/Dashboard/DashboardWidgetCard.vue'

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseWidget> & {
    parentContext: ContextManagerInterface
    getEditMode: () => boolean
  }
>

export default (BaseWidget as VueWithInjections).extend({
  name: 'VimeoWidget',
  inject: {
    getEditMode: {
      default() {
        return () => false
      },
    },
  },
  components: {
    DashboardWidgetCard,
  },
  computed: {
    editMode(): boolean {
      return this.getEditMode()
    },
  },
  mounted() {
    this.$emit('rendered')
  },
})
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
