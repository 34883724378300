// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeExternalIdFieldsTile from './EmployeeExternalIdFieldsTile.vue'
import EmployeeExternalIdFieldsColumns from './EmployeeExternalIdFieldsColumns'
import EmployeeExternalIdFieldsDetail from './EmployeeExternalIdFieldsDetail.vue'
import EmployeeExternalIdFieldsReference from './EmployeeExternalIdFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeExternalIdFieldsTile', EmployeeExternalIdFieldsTile)
    Vue.component(
      'EmployeeExternalIdFieldsDetail',
      EmployeeExternalIdFieldsDetail,
    )
    Vue.component(
      'EmployeeExternalIdFieldsReference',
      EmployeeExternalIdFieldsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_ID_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_ID_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeExternalIdFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_ID_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_ID_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
