<template>
  <div v-if="resource">
    <div>
      <v-row v-if="isDate(model.attribute)">
        <ChipListInput
          :style="
            isDate(model.attribute)
              ? 'margin-left:10px; margin-bottom:35px;'
              : 'margin-left:10px; margin-bottom:15px;'
          "
          color="blue"
          dark
          x-small
          :items="items"
          :value="model.modifier"
          :error="hasErrors('modifier')"
          @input="setFormValueFromPath('modifier', $event)"
        />
      </v-row>
    </div>
    <v-row class="no-gutters">
      <v-col class="col-8 no-gutters">
        <ResourceAttributeSelector
          :filter="filter"
          :value="model.attribute"
          :label="attributeFieldLabel"
          :error="hasErrors('attribute')"
          @change="updateModelFromAttribute"
        />
      </v-col>
      <v-col class="col-4 no-gutters">
        <v-text-field
          :disabled="!resource"
          outlined
          dense
          :value="model.alias"
          class="ml-2"
          :label="aliasFieldLabel"
          :placeholder="
            $t('components.resource_dimension_selector.column_name')
          "
          :error="hasErrors('alias')"
          @input="setFormValueFromPath('alias', $event, true)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import { TranslateResult } from 'vue-i18n'
import { VueConstructor } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { AttributeFilterInterface } from '@/tt-widget-factory/services/resource-meta/types'
import {
  DimensionModifier,
  dimensionModifierFormValues,
} from '@/tt-widget-factory/definitions'

import BaseObjectInput from './BaseObjectInput'
import ChipListInput from './ChipListInput.vue'
import {
  Dimension,
  ResourceProvider,
  FormHookProvider,
  NamespaceProvider,
  ResourceAttributeName,
} from '../types'

export default (
  BaseObjectInput as VueConstructor<
    InstanceType<typeof BaseObjectInput> &
      FormHookProvider &
      ResourceProvider &
      NamespaceProvider
  >
).extend({
  name: 'ResourceDimensionSelector',
  components: { ChipListInput },
  inject: ['formHook', 'resourceProvider', 'namespace'],
  data() {
    return {
      model: null,
      filter: (attributeFilter: AttributeFilterInterface) => {
        return (
          !['createdBy.', 'updatedBy.'].includes(
            attributeFilter.absoluteName,
          ) && attributeFilter.attribute.dimension
        )
      },
      items: dimensionModifierFormValues,
    }
  },
  props: {
    value: Object,
  },
  computed: {
    aliasFieldLabel(): string {
      const fieldName = this.attributeFieldName('alias')
      const baseLabel = this.$t('components.resource_dimension_selector.label')
      return this.formHook().getFieldLabel(fieldName, baseLabel)
    },
    attributeFieldLabel(): string {
      const baseLabel = this.$t(
        'components.resource_dimension_selector.group_by',
      )

      // @todo: first argument should be `this.attributeFieldName('attribute')` FE-835
      return this.formHook().getFieldLabel(this.name, baseLabel)
    },
    resource(): string {
      return this.resourceProvider.resource
    },
    rules(): ((val: any) => boolean | string)[] {
      return [
        (val): boolean | string => {
          if (!val || val.length === 0) {
            return this.$t(
              'components.resource_dimension_selector.is_required_property',
            ) as string
          }

          return true
        },
      ]
    },
  },
  methods: {
    attributeFieldName(attribute: string): string {
      return `${this.namespace}.${attribute}`
    },
    hasErrors(key: keyof Dimension): boolean {
      const errors = this.formHook().errors
      const path = this.attributeFieldName(key)
      return !isEmpty(errors[this.namespace]) || !isEmpty(errors[path])
    },
    updateModelFromAttribute(attribute: ResourceAttributeName) {
      if (!attribute) {
        this.model = {}

        return
      }

      this.model = {
        attribute,
        alias: this.getAlias(attribute),
        modifier: this.getModifier(attribute),
      }
    },
    getAlias(attribute: ResourceAttributeName): TranslateResult {
      return this.$t(
        this.$appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
          this.resource,
          attribute,
          FormLabelTypes.LABEL,
        ),
      )
    },
    getModifier(attribute: ResourceAttributeName): DimensionModifier | string {
      return this.isDate(attribute) ? DimensionModifier.DATE : ''
    },
    setFormValueFromPath<K extends keyof Dimension>(
      key: K,
      val: Dimension[K],
      debounce = false,
    ) {
      const path = `${this.namespace}.${key}`
      this.formHook().setObjectValue(path, val, { debounce })
    },
    isDate(attribute: ResourceAttributeName): boolean {
      if (!attribute) {
        return false
      }

      return this.$appContext.widgetServices.resourceMetaManager.isDateAttribute(
        this.resource,
        attribute,
      )
    },
  },
})
</script>
