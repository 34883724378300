<template>
  <div class="flex--column">
    <div class="d-flex align-center pt-4 pb-2">
      <h5 class="text-body-1 font-weight-bold px-6 py-2">
        {{ employeeCountTitle }}
      </h5>
      <span
        class="pr-2 pl-2 text-caption font-weight-medium text-medium-emphasis"
      >
        {{ showEmployeesToggleText }}
      </span>
      <v-switch
        v-model="showAssignedEmployees"
        color="blue"
        class="d-flex mt-0 pt-0 text-caption"
        style="transform: scale(0.875)"
        inset
        hide-details
      />
    </div>
    <v-alert
      v-if="isReady && !areEmployeesAvailable"
      type="info"
      text
      prominent
      dense
      class="ma-5"
    >
      {{ noAvailableEmployeesMsg }}
    </v-alert>
    <div class="flex--column">
      <v-skeleton-loader v-if="!isReady" type="table" />
      <WidgetFactory
        v-else
        :widget="assignEmployeesWidgetModel"
        :selected-records="selectedEmployeeIds"
        :deactivated-records="initialSelection"
        class="light-toolbar"
        @update:hook="saveHook"
        @selection-updated="selectedEmployeeIds = $event"
      />
    </div>
    <div class="pa-4">
      <v-btn
        class="white--text"
        raised
        color="var(--v-ttPrimary-base)"
        style="text-transform: none"
        :disabled="disableSaveBtn"
        @click="submit"
      >
        <v-icon left>mdi-arrow-right</v-icon>
        <span v-text="$t(btnText)" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  DataTableWidgetModel,
  Filter,
  SortAttribute,
} from '@/tt-widget-components'
import { employeesCustomColumns } from './EmployeesCustomColumns'
import { GridSelectionMode } from '@/tt-widget-components/widgets/DataTable/types'
import { Resources } from '@/tt-entity-design/src/types'
import { LeaveManagementActions } from '../../types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { FilterOperatorType } from '@/tt-widget-factory'
import { LeavePolicyApiResponse } from '@/tt-entity-design/src/components/leave-policies/types'
import DataTableWidgetHook from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default Vue.extend({
  name: 'AssignEmployeesView',
  props: {
    translationBase: {
      type: String,
      required: true,
    },
    leavePolicyId: {
      type: Number,
      default: null,
    },
    hasPolicyItemsAssigned: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      policyRegion: null,
      initialSelection: [] as number[],
      selectedEmployeeIds: [] as number[],
      showAssignedEmployees: true,
      hook: null as null | DataTableWidgetHook,
    }
  },
  computed: {
    employeeIdsToAssign(): number[] {
      return this.selectedEmployeeIds.filter(
        (selectedId) => !this.initialSelection.includes(selectedId),
      )
    },
    areEmployeesAvailable(): boolean {
      if (!this.hook) return true

      return !!this.hook.totalEntities
    },
    isReady(): boolean {
      return this.$appContext.isReady() && !this.loading
    },
    resource(): Resources {
      return Resources.LEAVE_POLICIES
    },
    disableSaveBtn(): boolean {
      return (
        this.loading ||
        this.employeeIdsToAssign.length === 0 ||
        !this.hasPolicyItemsAssigned
      )
    },
    employeeCountTitle(): string {
      const title = this.$t(
        `${this.translationBase}.tabs.assigned-to.assign-employees`,
      )
      return `${title} (${this.employeeIdsToAssign.length})`
    },
    showEmployeesToggleText(): string {
      return this.$tc(
        `${this.translationBase}.tabs.assigned-to.show-assigned-employees`,
        this.initialSelection.length,
      )
    },
    btnText(): string {
      return `${this.translationBase}.btn-save-policy`
    },
    noAvailableEmployeesMsg(): string {
      return this.$t(
        `${this.translationBase}.tabs.assigned-to.no-employees-message`,
      )
    },
    policyHasEmployeesAssigned(): boolean {
      return this.initialSelection.length > 0
    },
    genericErrorTranslationKey(): string {
      return 'common.error_message'
    },
    assignEmployeesWidgetModel(): DataTableWidgetModel {
      const hasAssignAccessFilter: Filter = {
        attribute: 'assignableResources',
        operator: FilterOperatorType.EQUAL,
        value: this.policyRegion,
      }

      const assignedOrCanBeAssignedToLeavePolicy: Filter = {
        attribute: 'assignedOrCanBeAssignedToLeavePolicy',
        operator: FilterOperatorType.EQUAL,
        value: this.leavePolicyId,
      }

      const employeeSort: SortAttribute = [
        {
          attribute: 'currentLeavePolicy',
          direction: 'DESC',
        },
      ]

      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.EMPLOYEES,
          filters: this.showAssignedEmployees
            ? [assignedOrCanBeAssignedToLeavePolicy]
            : [hasAssignAccessFilter],
          sort: employeeSort,
          scope: this.showAssignedEmployees ? [] : ['WITHOUT_ACTIVE_POLICY'],
        },
        toolbar: {
          displayCounts: !this.policyHasEmployeesAssigned,
          filterOptions: {
            allowSearch: true,
          },
        },
        selectionMode: GridSelectionMode.MULTIPLE,
        component: { columns: employeesCustomColumns },
      }
    },
  },
  watch: {
    showAssignedEmployees() {
      this.loadCurrentlyAssignedEmployees()
    },
  },
  created() {
    this.loadCurrentlyAssignedEmployees()
  },
  methods: {
    saveHook(hook: DataTableWidgetHook) {
      this.hook = hook
    },
    async loadCurrentlyAssignedEmployees() {
      this.loading = true

      await this.$auth
        .getApi()
        .get<number, LeavePolicyApiResponse>(
          Resources.LEAVE_POLICIES,
          this.leavePolicyId,
          {
            include: [Resources.EMPLOYEES],
          },
        )
        .then((leavePolicyInfo) => {
          this.policyRegion = leavePolicyInfo.region
          this.$emit('policyName', leavePolicyInfo.name)

          const initialSelection = leavePolicyInfo.employees.map(
            (item) => item.id,
          )

          this.initialSelection = [...initialSelection]
          this.selectedEmployeeIds = [...initialSelection]
        })

      this.loading = false
    },
    dispatchSuccessMessage(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t(`${this.translationBase}.create-success`),
      })
    },
    dispatchErrorMessage(errorMessage: string): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: errorMessage,
      })
    },
    async submit() {
      this.loading = true

      await this.$appContext.entityServices.persister
        .executeEntityAction(
          this.resource,
          LeaveManagementActions.ASSIGN_EMPLOYEES,
          this.leavePolicyId,
          { employeesIds: this.employeeIdsToAssign },
        )
        .then(() => {
          this.dispatchSuccessMessage()
          this.$emit('nextStep')
        })
        .catch(({ response }) => {
          this.$crash.captureException(response)

          if (response?.data) {
            const failedActionsErrors = Object.values(response.data)

            failedActionsErrors.forEach((errorContent: string[]) => {
              errorContent.forEach((message: string) => {
                this.dispatchErrorMessage(message)
              })
            })
          } else {
            this.dispatchErrorMessage(this.$t(this.genericErrorTranslationKey))
          }
        })

      this.loading = false
    },
  },
})
</script>
<style scoped>
/* Create a light toolbar */
.light-toolbar >>> .v-toolbar__content {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}
/* Create a black outlined search box */
.light-toolbar >>> .v-toolbar__content > span:first-of-type .v-chip {
  background-color: white;
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px;
}
.light-toolbar
  >>> .v-toolbar__content
  > span:first-of-type
  .v-chip:hover::before {
  opacity: 0;
}
/* Make items count background white to blend in with toolbar */
.light-toolbar >>> .v-toolbar__content > div:last-child > .v-chip {
  background-color: white;
}
.light-toolbar
  >>> .v-toolbar__content
  > div:last-child
  > .v-chip:hover::before {
  background-color: white;
}
</style>
