<template>
  <TChipLabel v-if="label" v-bind="chipProps" />
  <v-chip v-else label text-color="grey" x-small>N/A</v-chip>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { TChipLabelType } from '@/tt-ui/types'

import { CalendarExceptionTypeLevel } from './types'
import {
  ExceptionTypeColor,
  ExceptionTypeId,
  getExceptionTypeColor,
} from '../exception-types/exception-types-parent'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'CalendarExceptionsTypeColumn',
  inject: ['getItemHook'],
  props: {
    attrName: {
      type: String,
      default: 'type',
    },
    level: {
      type: String as PropType<CalendarExceptionTypeLevel>,
      default: CalendarExceptionTypeLevel.Root,
    },
  },
  computed: {
    chipProps(): Record<string, any> {
      return {
        ...this.color,
        modifiers: {
          style: { fontWeight: 'normal', textTransform: 'none' },
        },
        textKey: this.label,
        title: this.label,
        type: TChipLabelType.Pill,
      }
    },
    color(): ExceptionTypeColor {
      return getExceptionTypeColor(this.rootTypeId)
    },
    hasParentType(): boolean {
      return !!this.getItemHook().get(`${this.attrName}.parent.id`)
    },
    label(): string | null {
      const getRootTypeLabelAttr = () =>
        this.hasParentType
          ? `${this.attrName}.parent.label`
          : `${this.attrName}.label`

      const getSubTypeLabelAttr = () =>
        this.hasParentType ? `${this.attrName}.label` : null

      const attr =
        this.level === CalendarExceptionTypeLevel.Root
          ? getRootTypeLabelAttr()
          : getSubTypeLabelAttr()

      return attr && this.getItemHook().get(attr)
    },
    rootTypeId(): ExceptionTypeId {
      return this.hasParentType
        ? this.getItemHook().get(`${this.attrName}.parent.id`)
        : this.getItemHook().get(`${this.attrName}.id`)
    },
  },
  created() {
    this.getItemHook().addAttribute(`${this.attrName}.id`)
    this.getItemHook().addAttribute(`${this.attrName}.label`)
    this.getItemHook().addAttribute(`${this.attrName}.parent.id`)
    this.getItemHook().addAttribute(`${this.attrName}.parent.label`)
  },
})
</script>
