import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const PositionsEarningsPayPremiumColumns: ColumnInputDefinition[] = [
  { attributeName: 'premium.type' },
  {
    attributeName: 'position.account.name',
    headerText: 'back-office-reports.earnings-premium.positions.client',
  },
  {
    attributeName: 'position.name',
    headerText: 'back-office-reports.earnings-premium.positions.position',
  },
  {
    attributeName: 'premium.payCode',
    headerText: 'back-office-reports.earnings-premium.payCode',
  },
  {
    attributeName: 'premium.rate',
    headerText: 'back-office-reports.earnings-premium.premiumValue',
  },
  {
    attributeName: 'premium.isAddToBaseRate',
    headerText: 'back-office-reports.earnings-premium.addToBaseRate',
    component: {
      is: 'EarningsPremiumAddToBaseRateCustomColumn',
      props: {
        attributeNameAddToBaseRate: 'premium.isAddToBaseRate',
        isCheckboxColumn: true,
      },
    },
  },
]

export const PositionsEarningsPayPremiumDownloadableColumns: ColumnInputDefinition[] =
  [
    { attributeName: 'premium.type' },
    {
      attributeName: 'position.account.name',
      headerText: 'back-office-reports.earnings-premium.positions.client',
    },
    {
      attributeName: 'position.name',
      headerText: 'back-office-reports.earnings-premium.positions.position',
    },
    {
      attributeName: 'premium.payCode.name',
      headerText: 'back-office-reports.earnings-premium.payCode',
    },
    {
      attributeName: 'premium.rate',
      headerText: 'back-office-reports.earnings-premium.premiumValue',
    },
    {
      attributeName: 'premium.isAddToBaseRate',
      headerText: 'back-office-reports.earnings-premium.addToBaseRate',
    },
  ]
