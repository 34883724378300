// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskPriceTiersTile from './TaskPriceTiersTile.vue'
import TaskPriceTiersColumns from './TaskPriceTiersColumns'
import TaskPriceTiersDetail from './TaskPriceTiersDetail.vue'
import TaskPriceTiersMetaCard from './TaskPriceTiersMetaCard.vue'
import TaskPriceTiersReference from './TaskPriceTiersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaskPriceTiersTile', TaskPriceTiersTile)
    Vue.component('TaskPriceTiersDetail', TaskPriceTiersDetail)
    Vue.component('TaskPriceTiersMetaCard', TaskPriceTiersMetaCard)
    Vue.component('TaskPriceTiersReference', TaskPriceTiersReference)

    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskPriceTiersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskPriceTiersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskPriceTiersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaskPriceTiersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskPriceTiersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
