import { LeavePolicyEmployeeInfo } from '../leave-policies/types'

export enum LeavePolicyItemsComponents {
  LeavePolicyItemsValueField = 'LeavePolicyItemsValueField',
  LeavePolicyItemsAccruedColumn = 'LeavePolicyItemsAccruedColumn',
  LeavePolicyItemsTotalColumn = 'LeavePolicyItemsTotalColumn',
  LeavePolicyItemsAccrualBreakdown = 'LeavePolicyItemsAccrualBreakdown',
  LeavePolicyItemsDetailsQuantityRow = 'LeavePolicyItemsDetailsQuantityRow',
}

export type EmployeeInfoProvider = {
  getEmployeeInfo: () => LeavePolicyEmployeeInfo
}
