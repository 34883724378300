export const sortAttributesNames = (a: string, b: string): number => {
  const aPoints = a.split('.').length
  const bPoints = b.split('.').length
  if (aPoints === bPoints) {
    return a.toUpperCase() > b.toUpperCase() ? 1 : -1
  }
  return aPoints > bPoints ? 1 : -1
}

export const sortAttributesNamesObjects = (
  a: { text: string },
  b: { text: string },
): number => {
  return sortAttributesNames(a.text, b.text)
}
