import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'
import { TTCloudDocumentInput } from '@/tt-entity-documents/providers/TTCloudDocumentServiceProvider'
import { DocumentProviders } from '@/tt-entity-documents/types'
import { DocumentPresetInterface } from '@/tt-app-modular'
import { AppContext } from '@/tt-app-context'
const titleTranslationKey = 'tt-entity-design.checkpoint-tour-sessions.report'

const Document: DocumentPresetInterface<TTCloudDocumentInput> = {
  name: titleTranslationKey,
  async toDocument(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
  ): Promise<TTCloudDocumentInput> {
    return {
      documentName: titleTranslationKey,
      provider: DocumentProviders.TTCLOUD,
      entityReference,
      locale: 'en',
      data: {
        path: `/patrol/checkpoint/viewprintabletour/idtour/${entityReference.entityId}`,
      },
    }
  },
}

export default Document
