import Vue from 'vue'

import BaseQueryManager, {
  QueryTypes,
} from '@/tt-widget-components/base/BaseQueryManager'
import { DefaultToolbar } from '@/tt-widget-components/types'

export class EntityToolbarManager<QueryType extends QueryTypes = QueryTypes> {
  model: DefaultToolbar
  queryManager: BaseQueryManager<QueryType>

  constructor(
    model: DefaultToolbar,
    queryManager: BaseQueryManager<QueryType>,
  ) {
    this.model = Vue.observable(model ?? {})
    this.queryManager = queryManager
  }
}
