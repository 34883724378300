import { LeavePoliciesCustomComponents } from '@/tt-widget-views/leave-management/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

const LeavePoliciesCustomColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'customId',
    style: {
      width: 150,
    },
  },
  {
    headerText: 'res.leave-policies.attr.name.label',
    component: {
      is: LeavePoliciesCustomComponents.BasicInformationClickableColumn,
    },
  },
  {
    headerText: 'res.leave-policies.attr.leavePolicyItems.label',
    component: {
      is: LeavePoliciesCustomComponents.AssignedPolicyItemsClickableColumn,
    },
  },
  {
    headerText:
      'tt-entity-design.leave-management.leave-policies.tabs.assigned-to.title',
    component: {
      is: LeavePoliciesCustomComponents.AssignedEmployeesClickableColumn,
    },
  },
  {
    attributeName: 'createdOn',
    format: 'asDate',
  },
]
export default LeavePoliciesCustomColumns
