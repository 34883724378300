<template>
  <div class="d-flex justify-space-between ml-1 pb-1">
    <div class="text-truncate">
      <tt-tile v-bind="$props">
        <div slot="action">
          <AssetTypesIcon prefix="type" size="50" />
        </div>

        <div slot="title">
          <tt-attr name="account" /> -
          <tt-attr name="name" class="text-subtitle-1" />
          <tt-if-attr name="barcode" not :value="[null, undefined, '']">
            <v-chip x-small class="opacity-faded ml-2">
              <tt-attr name="barcode" hide-empty-value />
            </v-chip>
          </tt-if-attr>
        </div>

        <span slot="line2">
          <tt-attr
            name="type.category.name"
            class="text-subtitle-2 font-weight-regular"
          />
          /
          <tt-attr
            name="type.name"
            class="text-subtitle-2 font-weight-regular"
          />
        </span>

        <span slot="line3">
          <tt-if-attr
            name="accountLocation.id"
            not
            :value="[null, undefined, '']"
          >
            <tt-attr
              name="accountLocation"
              class="mr-3 text-caption"
              hide-empty-value
            />
          </tt-if-attr>
          <tt-if-attr name="status" not value="ACTIVE">
            <v-chip class="mr-3" x-small color="orange">
              <tt-attr name="status" hide-empty-value />
            </v-chip>
          </tt-if-attr>
          <tt-if-attr name="checkoutStatus" value="CHECKED_OUT">
            <v-chip class="mr-3" x-small color="blue" dark hide-empty-value>
              <tt-attr name="checkoutStatus" />
            </v-chip>
          </tt-if-attr>
          <tt-if-attr
            name="assetState"
            :value="['LOST', 'BROKEN']"
            class="text-uppercase"
          >
            <v-chip class="mr-3" x-small color="red" dark hide-empty-value>
              <tt-attr name="assetState" />
            </v-chip>
          </tt-if-attr>
        </span>
        <span slot="line4">
          <tt-if-attr name="type.isBundle" :value="true" class="text-uppercase">
            <v-chip class="mr-3 pl-2" x-small dark>
              <span v-text="$t('tt-entity-design.is-bundle')"> </span>
            </v-chip>
          </tt-if-attr>
          <tt-if-attr
            name="hasPendingReservations"
            :value="true"
            class="text-uppercase"
          >
            <v-chip class="mr-3 pl-2" x-small color="yellow">
              <span
                v-text="$t('tt-entity-design.asset-hub.pending-reservation')"
              ></span>
            </v-chip>
          </tt-if-attr>
        </span>
        <span slot="line5">
          <tt-if-attr name="bundle.id" not :value="[null, undefined, '']">
            <span
              class="text-uppercase text-caption"
              v-text="$t('tt-entity-design.bundle')"
            ></span
            >:
            <tt-attr name="bundle.name" class="text-caption" />
          </tt-if-attr>
        </span>
      </tt-tile>
    </div>
    <!-- Action -->
    <div v-if="canBeDeleted" class="pl-2 pr-2 mr-2 d-flex align-center">
      <tt-attr name="id">
        <template #default="{ value }">
          <v-btn
            class="pr-1"
            small
            plain
            v-bind="$attrs"
            color="error"
            elevation="0"
            @click="deleteAsset(value)"
          >
            <v-icon>mdi-minus-circle-outline</v-icon>
          </v-btn>
        </template>
      </tt-attr>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { VueWithItemHookInjection } from '@/helpers/components/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '../../types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'AssetsTileWithActions',
  inject: ['getItemHook'],
  computed: {
    assetID(): number {
      return this.getItemHook().getRawValue('bundle.id')
    },
    shouldBeContainedIntoBundle(): boolean {
      return this.getItemHook().getRawValue('type.beContainedIntoBundle')
    },
    canBeDeleted(): boolean {
      return !this.shouldBeContainedIntoBundle
    },
  },
  created() {
    this.getItemHook().addAttribute('type.beContainedIntoBundle')
  },
  methods: {
    deleteAsset(assetID: number): void {
      const { eventManager } = this.$appContext

      this.$auth
        .getApi()
        .doAction(Resources.ASSETS, this.assetID, 'remove-assets', {
          assets: [assetID],
        })
        .then(() => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
            message: this.$t(`tt-entity-design.asset-hub.asset-remove-success`),
          })
          eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.ASSETS,
          })
        })
        .catch((error) => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message:
              error || this.$t('tt-entity-design.asset-hub.asset-remove-error'),
          })
        })
    },
  },
})
</script>
