var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NotificationPermissionStatus, PushEndpoints, } from '.';
export class NotificationService {
    constructor(provider) {
        this.messageReceivedCallbacks = [];
        this.provider = provider;
        this.provider.onMessageReceived((message) => this.notifyMessageReceived(message));
        this.provider.onClientDetailsChanged(() => this.onClientDetailsChanged());
    }
    areNotificationsEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            const status = yield this.provider.getPermissionStatus();
            return status === NotificationPermissionStatus.GRANTED;
        });
    }
    permissionStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.provider.getPermissionStatus();
        });
    }
    get isClientRegistered() {
        return !!this.provider.getClientRegistry();
    }
    get hasToken() {
        const clientDetails = this.provider.getClientDetails();
        return !!clientDetails.token;
    }
    register() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.areNotificationsEnabled) {
                throw new Error('Notification service is not enabled');
            }
            const apiInstance = yield this.provider.getApi();
            const clientDetails = this.provider.getClientDetails();
            const clientRegistry = this.provider.getClientRegistry();
            const registration = yield apiInstance.create(PushEndpoints.REGISTER, Object.assign(Object.assign({}, clientDetails), (clientRegistry && {
                uid: clientRegistry.uid,
            })));
            this.provider.setClientRegistry(registration);
            return registration;
        });
    }
    unregister() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.areNotificationsEnabled) {
                throw new Error('Notification service is not enabled');
            }
            const clientDetails = this.provider.getClientDetails();
            if (!clientDetails) {
                throw new Error('Client details must exist before this action');
            }
            const apiInstance = yield this.provider.getApi();
            return apiInstance
                .create(PushEndpoints.UNREGISTER, {
                token: clientDetails.token,
            })
                .then(() => { var _a, _b; return (_b = (_a = this.provider).onUnregistering) === null || _b === void 0 ? void 0 : _b.call(_a); });
        });
    }
    ping() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.areNotificationsEnabled) {
                throw new Error('Notification service is not enabled');
            }
            const clientRegistry = this.provider.getClientRegistry();
            if (!clientRegistry) {
                throw new Error('Client registration must be performed before this action');
            }
            const apiInstance = yield this.provider.getApi();
            return apiInstance.get(PushEndpoints.PING, clientRegistry.uid);
        });
    }
    enableNotifications() {
        return this.provider.enableNotifications();
    }
    notifyMessageReceived(message) {
        this.messageReceivedCallbacks.forEach((callback) => callback(message));
    }
    onClientDetailsChanged() {
        if (!this.areNotificationsEnabled) {
            return;
        }
        this.register();
    }
    addOnMessageReceivedCallback(messageReceivedCallback) {
        this.messageReceivedCallbacks.push(messageReceivedCallback);
    }
}
