import {
  addPreviewTab,
  addResourceDocument,
  setAttributeView,
} from '@/tt-widget-entity-flow/helper'
import {
  createI18nResourceKey,
  createI18nPluralResourceKey,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import InvoiceDoc from './InvoiceDoc'
import InvoicesDetailDates from './InvoicesDetailDates.vue'
import InvoicesDetailsBanner from './InvoicesDetailsBanner.vue'
import InvoicesStatusAttr from './InvoicesStatusAttr.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    // Add the document
    addResourceDocument(Resources.INVOICES, InvoiceDoc)

    Vue.component('InvoicesStatusAttr', InvoicesStatusAttr)
    Vue.component('InvoicesDetailDates', InvoicesDetailDates)

    Vue.component('InvoicesDetailsBanner', InvoicesDetailsBanner)
    modularManager.addItem('invoices.preview.details.top', {
      is: 'InvoicesDetailsBanner',
    })

    setAttributeView(Resources.INVOICES, 'status', {
      is: 'InvoicesStatusAttr',
    })

    const invoicesLinesListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.INVOICE_LINES,
      },
    }
    const invoicesLines: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.INVOICES, 'lines'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'invoice.id',
        widget: invoicesLinesListWidget,
      },
    }

    const billableItemsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.BILLABLE_ITEMS,
      },
    }

    const billableItems: PreviewTabInterface = {
      title: createI18nPluralResourceKey(Resources.BILLABLE_ITEMS),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'invoice.id',
        widget: billableItemsListWidget,
      },
    }

    const billableItemsPieChartWidget: WidgetModels = {
      is: 'PieChartWidget',
      title: 'Revenue by type',
      dataSet: {
        resource: Resources.BILLABLE_ITEMS,
        dimension: {
          attribute: 'billItem',
        },
        measure: {
          attribute: 'price',
          operation: 'SUM',
          alias: 'Price',
        },
      },
    }

    const billableItemsStats: PreviewTabInterface = {
      title: 'tt-entity-design.stats',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'invoice.id',
        widget: billableItemsPieChartWidget,
      },
    }

    // Add the invoice lines
    addPreviewTab(Resources.INVOICES, invoicesLines)

    // Add the billable items
    addPreviewTab(Resources.INVOICES, billableItems)

    // Add a stats tab to the invoice
    addPreviewTab(Resources.INVOICES, billableItemsStats)

    // Adding extra detail cards
    const dateDetail: PresetItem = {
      title: 'tt-entity-design.dates',
      data: { is: 'InvoicesDetailDates', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.INVOICES,
      PresetTypes.DETAILS_CARD,
      [dateDetail],
    )
  },
}
