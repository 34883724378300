<template>
  <v-container fluid>
    <v-row v-if="!isFetching" class="flex-column">
      <v-col class="d-flex flex-column">
        <span class="font-weight-bold">{{ translatedResource }}</span>
        <tt-attr name="conflictType" />
      </v-col>
      <v-toolbar v-if="showActions" small dense flat class="level2">
        <ConflictsBtnGroupActions />
      </v-toolbar>
    </v-row>
    <template v-else>
      <v-skeleton-loader
        class="field-loader"
        tile
        type="list-item-three-line"
      />
    </template>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { getResourceName } from '@/tt-widget-components/helpers'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { Resources } from '../../types'

type AffectedEntityEmployee = {
  avatar: string
  id: number
  name: string
}

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ConflictsTile',
  inject: ['getItemHook'],
  props: {
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFetching: false,
      employee: {} as AffectedEntityEmployee,
    }
  },
  computed: {
    resourceName(): string {
      return this.getItemHook().getRawValue('affectedEntity.type')
    },
    isEmployeeResource(): boolean {
      return this.resourceName === Resources.EMPLOYEES
    },
    entityId(): number {
      return this.getItemHook().getRawValue('affectedEntity.object')
    },
    translatedResource(): string {
      if (this.isEmployeeResource && this.employee.name) {
        return this.employee.name
      }
      if (this.resourceName) {
        return getResourceName(this.$appContext, this.resourceName)
      }

      return ''
    },
  },
  created() {
    this.getItemHook().addAttribute('affectedEntity')
    this.getItemHook().addAttribute('id')

    if (this.isEmployeeResource) {
      this.fetchAffectedEntityEmployee()
    }
  },
  methods: {
    async fetchAffectedEntityEmployee(): Promise<void> {
      this.isFetching = true
      try {
        const employee: AffectedEntityEmployee =
          await this.$appContext.entityServices.persister.api.get(
            Resources.EMPLOYEES,
            this.entityId,
          )
        this.employee = employee
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.isFetching = false
      }
    },
  },
})
</script>
