import { BatchFileResponse } from '@/types'

export const parseBatchFileErrorMessages = ({
  data: { processErrors = [], validationErrors = [] },
}: {
  data: BatchFileResponse
}): string[] => {
  const errors = [...processErrors, ...validationErrors]

  return errors.map(({ errors }) => Object.values(errors)[0]?.[0])
}
