<template>
  <div class="fill-height">
    <MetadataLoadingGuard>
      <WidgetFactory :widget="conflictsWidget" />
    </MetadataLoadingGuard>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { Resources } from '@/tt-entity-design/src/types'
import { HumanCapitalManagementEmployeeConflictsColumns } from './HumanCapitalManagementEmployeeConflictsColumns'
import MetadataLoadingGuard from '../../../../components/MetadataLoadingGuard.vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import {
  AttributeFilter,
  DataTableWidgetModel,
  Filter,
  Sort,
} from '@/tt-widget-components/types'
export default Vue.extend({
  name: 'HumanCapitalManagementEmployeeConflicts',
  components: {
    MetadataLoadingGuard,
  },
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    conflictsWidget(): DataTableWidgetModel {
      const employeeFilter: Filter = {
        attribute: 'employee',
        operator: 'EQUAL',
        value: this.employeeId,
      }

      const toolbarAccountFilter: AttributeFilter = {
        attributeName: 'account',
      }
      const toolbarStatusFilter: AttributeFilter = {
        attributeName: 'status',
      }
      const toolbarConflictTypeFilter: AttributeFilter = {
        attributeName: 'conflictType',
      }

      const sortByMostRecent: Sort = {
        attribute: 'createdOn',
        direction: 'DESC',
      }

      return {
        is: WidgetName.DATA_TABLE_WIDGET,
        title: '',
        allowActions: this.allowActions,
        query: {
          resource: Resources.CONFLICTS,
          filters: [employeeFilter],
          sort: [sortByMostRecent],
        },
        component: { columns: HumanCapitalManagementEmployeeConflictsColumns },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: [
              toolbarAccountFilter,
              toolbarStatusFilter,
              toolbarConflictTypeFilter,
            ],
            allowSearch: true,
          },
        },
      }
    },
  },
})
</script>
