<template>
  <div class="ma-4 text-center">
    <v-icon size="35" :color="iconColor" v-text="iconType" />
    <div class="pa-3" v-text="$t(textError)" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'EntityFilterError',
  props: {
    iconColor: {
      type: String,
      default: 'orange',
    },
    iconType: {
      type: String,
      default: 'error',
    },
    textError: {
      type: String,
      required: true,
    },
  },
})
</script>
