<template>
  <div @click="$emit('click')">
    <v-img
      v-if="isBanner"
      class="white--text align-end"
      :height="120"
      :style="{
        opacity: $vuetify.theme.dark ? 0.8 : 1,
      }"
      width="100%"
      :src="bannerImage"
    >
      <div class="card-screen" />
    </v-img>

    <DashboardContentThumbnail
      v-if="isDash"
      :widget="item.widget"
      :width="200"
      :margin="10"
      :height="110"
    />

    <div v-if="isThumb" class="text-center level1">
      <img
        class="white--text pa-3 align-center"
        :height="112"
        :style="{ opacity: 0.8 }"
        :src="thumbnail"
      />
    </div>

    <div
      v-if="isIcon"
      class="level1"
      style="height: 120px; overflow: hidden; position: relative"
    >
      <v-icon :size="200" style="color: white">{{ icon }}</v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import DashboardContentThumbnail from './DashboardContentThumbnail.vue'
import { WidgetStoreModel, WidgetTypeDefinition } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'WidgetCardThumbnail',
  components: {
    DashboardContentThumbnail,
  },
  props: {
    item: Object as PropType<WidgetStoreModel>,
  },
  computed: {
    isBanner(): boolean {
      return !!this.bannerImage
    },
    isDash(): boolean {
      return !this.isBanner && this.item.is === 'DashboardWidget'
    },
    isThumb(): boolean {
      return !!(!this.isBanner && !this.isDash && this.thumbnail)
    },
    isIcon(): boolean {
      return !this.isBanner && !this.isDash && !this.isThumb
    },
    thumbnail(): string {
      if (!this.widgetConfig?.config?.thumbnail) {
        return null
      }
      return this.widgetConfig?.config?.thumbnail
    },
    icon(): string {
      if (!this.widgetConfig?.config?.icon) {
        return 'insert_chart'
      }
      return this.widgetConfig.config.icon
    },
    bannerImage(): string {
      return this.item.meta.bannerImage
    },
    widgetConfig(): WidgetTypeDefinition {
      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        this.item.is,
      )
    },
  },
})
</script>
