import { FormOptions } from '@tracktik/tt-json-schema-form'

import {
  ActiveWindow,
  ActiveWindowPage,
  TabInterface,
} from '@/tt-app-layout/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export type EntityPreviewEditHandlerOptions = {
  activeWindow?: ActiveWindow
  activeWindowPage?: ActiveWindowPage
  formOptions?: FormOptions
  itemHook: EntityItemHook
}

export type EntityPreviewEditHandler = (
  opt: EntityPreviewEditHandlerOptions,
) => void

export interface EventProviderInterface {
  getEventName(): string
  run(payload: any)
}

export interface EntityRecord {
  itemHook?: EntityItemHook
  id: number
  [key: string]: any
}

export enum Breakpoints {
  TABLET_AND_SMALLER = 'smAndDown',
  TABLET_AND_LARGER = 'smAndUp',
  PHONE = 'xs',
}

export type ItemHookProvider = {
  getItemHook: () => EntityItemHook
  relationPrefix: string
}

export type PreviewTabInterface = TabInterface<{ itemHook: EntityItemHook }>
