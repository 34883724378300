<template>
  <v-treeview :items="assets">
    <template #label="{ item }">
      <TTooltipOnOverflow
        :text="`${item.type} : ${item.name}`"
        :disable-tooltip="!isOverflowing"
      >
        <OverflowObserver class="w-100" @overflow="onOverflow">
          <span
            :class="textColor"
            class="list--assets body-2 d-block text-truncate"
          >
            {{ item.type }} : {{ item.name }}
            <v-icon
              v-if="markAsChecked"
              small
              color="green"
              class="pl-2"
              v-text="`check`"
            />
          </span>
        </OverflowObserver>
      </TTooltipOnOverflow>
    </template>
  </v-treeview>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { TreeViewAsset } from '../asset-manager/types'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'
import OverflowObserver from '@/tt-widget-components/components/OverflowObserver.vue'

export default Vue.extend({
  name: 'TaskAssetsTree',
  components: { TTooltipOnOverflow, OverflowObserver },
  props: {
    assets: {
      type: Array as PropType<TreeViewAsset[]>,
      required: true,
    },
    markAsChecked: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOverflowing: false,
  }),
  computed: {
    textColor(): string {
      return this.markAsChecked ? 'green--text' : 'grey--text'
    },
  },
  methods: {
    onOverflow(isOverflowing: boolean) {
      this.isOverflowing = isOverflowing
    },
  },
})
</script>

<style scoped>
.v-treeview >>> .v-treeview-node__level {
  width: 12px !important;
}
.list--assets {
  display: flex;
}

.button--expand {
  font-size: 0.7em;
  height: 100%;
  padding: 0 1em;
  margin: 0 1em;
}
</style>
