// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RolesTile from './RolesTile.vue'
import RolesColumns from './RolesColumns'
import RolesDetail from './RolesDetail.vue'
import RolesMetaCard from './RolesMetaCard.vue'
import RolesReference from './RolesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('RolesTile', RolesTile)
    Vue.component('RolesDetail', RolesDetail)
    Vue.component('RolesMetaCard', RolesMetaCard)
    Vue.component('RolesReference', RolesReference)

    modularManager.addResourcePreset(Resources.ROLES, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'RolesTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ROLES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: RolesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ROLES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RolesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ROLES, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'RolesMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ROLES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RolesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
