import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const overtimeRuleItemsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.OVERTIME_RULE_ITEMS,
      },
    }

    const overtimeRuleItems: PreviewTabInterface = {
      title: createI18nPluralResourceKey('overtime-rule-items'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'overtimeRule.id',
        widget: overtimeRuleItemsListWidget,
      },
    }

    addPreviewTab(Resources.OVERTIME_RULES, overtimeRuleItems)
  },
}
