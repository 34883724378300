import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const PositionsBillRateColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'positionBilling.position.account.name',
    headerText: 'res.client-users.attr.client.label',
  },
  {
    attributeName: 'positionBilling.position.name',
    headerText: 'res.position-billing-settings.attr.position.label',
  },
  {
    attributeName: 'positionBilling.position.status',
    headerText: 'res.positions.attr.status.label',
  },
  { attributeName: 'billingType' },
  { attributeName: 'effectiveDate' },
  { attributeName: 'rate' },
]
