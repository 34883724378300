// Enter your custom installation here
import TaskSiteInstructionsForm from './TaskSiteInstructionsForm.vue'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    Vue.component('TaskSiteInstructionsForm', TaskSiteInstructionsForm)
    registerResourceForm(
      Resources.TASK_SITE_INSTRUCTIONS,
      'TaskSiteInstructionsForm',
    )
  },
}
