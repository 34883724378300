import hook from './TreeMapWidgetHook'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import TreeMapWidgetEditor from './TreeMapWidgetEditor.vue'
import Vue from 'vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(/* webpackChunkName: "TreeMapWidget" */ './TreeMapWidget.vue').catch(
    handleAsyncComponentError,
  )

Vue.component('TreeMapWidgetEditor', TreeMapWidgetEditor)

const TreeMapWidget: WidgetTypeDefinition = {
  name: WidgetName.TREE_MAP_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    TreeMapWidget: {
      view: {
        is: 'TreeMapWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-chart-tree',
    color: '#d03762',
    print: true,
    thumbnail: require('./tree-map.png'),
  },
}

export default TreeMapWidget
