import Vue from 'vue'
import { getAssetsFromInstructions } from './helpers'
import {
  AssetManager,
  AssetManagerState,
  TaskAsset,
  TreeViewAsset,
} from './types'

/**
 * Stateful service to manage the assets of a task. Once created, the instance can be provided / injected in Vue components.
 */
export const createAssetManager = ({
  taskInstructions,
  taskTypeId,
  fetchSubAssets,
}): AssetManager => {
  const state: AssetManagerState = Vue.observable({
    /**
     * The assets linked to the task.
     * This is the list of assets fetched from the task instructions.
     */
    taskAssets: [],
    /**
     * List of additional assets fetched. To be used when mapping children assets of assets.
     * Same type as `taskAssets` above.
     */
    assetsDictionnary: {},
    /**
     * Tree structure to be used with Vuetify `<v-treeview />`
     *
     * check `createAssetTree()` to see the items type
     */
    viewTree: [],
  })

  /**
   * Site can be SITE, MULTI, or CLIENT, and Zone will be only ZONE
   */
  const isZone = (asset) => asset.account.type === 'ZONE'

  const addToDictionnary = (assets: TaskAsset[] = []) => {
    const createEntry = (subAsset) => [subAsset.id, subAsset]

    state.assetsDictionnary = {
      ...state.assetsDictionnary,
      ...Object.fromEntries(assets.map(createEntry)),
    }

    state.viewTree = state.taskAssets.map(createAssetTree)
  }

  const getAllTaskAssets = () => [...state.taskAssets]

  const getSiteAssets = () => state.viewTree.filter((item) => !item.isZone)

  const getZoneAssets = () => state.viewTree.filter((item) => item.isZone)

  const hasAssets = () => !!state.taskAssets.length

  const loadSubAssets = (assetId) =>
    fetchSubAssets(assetId)
      .then(addToDictionnary)
      .catch((err) =>
        // eslint-disable-next-line no-console
        console.error(`Could not fetch sub-assets of asset '${assetId}'`, err),
      )

  /**
   * Vuetify shows an "expand" icon when there's an empty array and we can lazy request the sub-items with `load-children`.
   * So "children" must be :
   *    - `undefined`  if no children
   *    - `[]`         if children exists but not fetched yet
   *    - `[{...}]`    if children exists and already fetched
   *
   * https://v15.vuetifyjs.com/en/components/treeview
   */
  const createChildren = (assets: { id: number }[]) => {
    if (assets.length === 0) return undefined

    const subAssets = assets.map(
      (asset: TaskAsset) => state.assetsDictionnary[asset.id],
    )

    return subAssets.every((v) => v) ? subAssets.map(createAssetTree) : []
  }

  /**
   * Model available in Vuetify's treeview `item` slot in the template
   */
  const createAssetTree = (asset: TaskAsset): TreeViewAsset => ({
    id: asset.id,
    name: asset.name,
    type: asset.type.name,
    isZone: isZone(asset),
    children: createChildren(asset.assets),
  })

  // initialization
  const taskAssets = getAssetsFromInstructions(taskInstructions, taskTypeId)

  state.taskAssets = taskAssets
  addToDictionnary(taskAssets)

  return {
    getAllTaskAssets,
    getSiteAssets,
    getZoneAssets,
    loadSubAssets,
    hasAssets,
  }
}
