<template>
  <div style="height:100%; overflow: scroll;">
    <div class="tql-section-table" v-for="(section,i) in sections" :key="`section_${i}`">
        <h1 style="font-size:20px;" class="text-center pt-4 mt-5 mb-4">{{section.title}}<div v-if="section.subTitle" style="font-weight:500; opacity:0.5; font-size:14px; margin-top:0px;">{{section.subTitle}}</div></h1>
        <TQLTable :columns="columns" :row-data="section.rowData" :footData="section.footData" :templateMarkup="templateMarkup" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import {rowsToSections, TQLMarkup, TQLSection} from "@/tt-widget-components/widgets/TQLQuery/markup";
import TQLTable from "@/tt-widget-components/widgets/TQLQuery/TQLTable.vue";
import {TQLColumn} from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";

export default Vue.extend({
  name: 'TQLSectionTable',
  components: {TQLTable},
  computed: {
    sections(): TQLSection[] {
        return rowsToSections(this.rowData)
    },
    tableStyle(): string {
      return this.templateMarkup?.template?.style || ''
    }
  },
  props: {
    columns: {
      type: Array as PropType<TQLColumn[]>,
      required: true
    },
    rowData: {
      type: Array as PropType<any>,
      required: true
    },
    footData: {
      type: Object
    },
    templateMarkup: {
      type: Object as PropType<TQLMarkup>
    }
  }
})
</script>
<style>
.cell-warning {
  background-color: #FFFAC6;
}

.dark .cell-warning {
  background-color: #5B5B0B;
}

.row-subtotal {
  font-weight: bold;
  margin-bottom: 10px;

}

.row-subtotal td {
  border-top: 1px solid #999;
  border-bottom: 2px solid #999 !important;
  font-weight: bold;
  padding: 8px !important;
}

.tql-section-table td{
  border-bottom: 1px dashed #ddd !important;
  font-size:11px!important;
  height: 25px!important;
}

</style>
