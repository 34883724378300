export enum rangeTimeKeys {
  START_TIME_KEY = 'rangeStartTime',
  END_TIME_KEY = 'rangeEndTime',
  LABEL = 'dayOfWeekStart',
  DO_NOT_PERFORM = 'doNotPerform',
}

export enum exceptionRangeTimeKeys {
  START_TIME_KEY = 'timeFrom',
  END_TIME_KEY = 'timeTo',
  LABEL = 'exceptionType.label',
  PERFORM = 'perform',
}

export enum MobileScheduleActions {
  REMOVE = 'archive',
}

export enum MobileScheduleExpceptionActions {
  REMOVE = 'remove',
}
