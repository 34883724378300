// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetCustomAttributesTile from './AssetCustomAttributesTile.vue'
import AssetCustomAttributesColumns from './AssetCustomAttributesColumns'
import AssetCustomAttributesDetail from './AssetCustomAttributesDetail.vue'
import AssetCustomAttributesReference from './AssetCustomAttributesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetCustomAttributesTile', AssetCustomAttributesTile)
    Vue.component('AssetCustomAttributesDetail', AssetCustomAttributesDetail)
    Vue.component(
      'AssetCustomAttributesReference',
      AssetCustomAttributesReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetCustomAttributesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetCustomAttributesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetCustomAttributesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetCustomAttributesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
