// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaxClassesTile from './TaxClassesTile.vue'
import TaxClassesColumns from './TaxClassesColumns'
import TaxClassesDetail from './TaxClassesDetail.vue'
import TaxClassesReference from './TaxClassesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaxClassesTile', TaxClassesTile)
    Vue.component('TaxClassesDetail', TaxClassesDetail)
    Vue.component('TaxClassesReference', TaxClassesReference)

    modularManager.addResourcePreset(
      Resources.TAX_CLASSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaxClassesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaxClassesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaxClassesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_CLASSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaxClassesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
