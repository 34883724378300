// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import NotesTile from './NotesTile.vue'
import NotesColumns from './NotesColumns'
import NotesDetail from './NotesDetail.vue'
import NotesMetaCard from './NotesMetaCard.vue'
import NotesReference from './NotesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('NotesTile', NotesTile)
    Vue.component('NotesDetail', NotesDetail)
    Vue.component('NotesMetaCard', NotesMetaCard)
    Vue.component('NotesReference', NotesReference)

    modularManager.addResourcePreset(Resources.NOTES, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'NotesTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.NOTES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: NotesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.NOTES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'NotesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.NOTES, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'NotesMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.NOTES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'NotesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
