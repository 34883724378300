import { WidgetCategoryModel, WidgetReference } from '@/tt-widget-factory'
import { WidgetCopyPayload } from './intents/WidgetCopy'

export const MY_INSIGHTS_CATEGORY_UID = 'my-insights-category|'

export type DraggableInterface = {
  target?: any
  type: string
  from?: 'switching' | 'side-bar'
  payload?: any
}

export interface WidgetEditorRenameEventInterface {
  uid: string
  title: string
  description: string
}

export interface WidgetEditorDashboardCreateInterface {
  success: (widget: WidgetReference) => void
}

export interface WidgetEditorTagInterface {
  tag: string
  uid: string
  remove?: boolean
}

export interface WidgetExploreRouteToWidgetInterface {
  widget: WidgetReference
  editMode?: boolean
}

export type WidgetEditInterface = {
  forceAddWidgetView?: boolean
  widgetType: string
  widgetGetter?: (widget: WidgetReference) => WidgetReference
  widgetSetter?: (widget: WidgetReference) => WidgetReference
}

/**
 * Droppable
 */
export enum DroppableTypes {
  WIDGET = 'WIDGET',
  CATEGORY = 'CATEGORY',
}

export enum CategoryNames {
  TUTORIALS = 'tutorials-root',
  MY_INSIGHTS = 'my-insights-category|',
  HR = 'hr-root',
  REVENUE = 'revenue-root',
  OPERATIONS = 'operations-root',
  CLIENT = 'client-root',
  STAFF = 'staff-root',
  CLIENT_PORTAL = 'client-portal-root',
  FORS = 'fors-root',
}

/**
 * Widget Events
 */
export enum WidgetEditorEvents {
  CATEGORY_CREATE_SUB = 'insights.category.create-sub',
  CATEGORY_EDIT = 'insight.category.edit',
  CATEGORY_OPTIONS = 'insight.category.options',
  CATEGORY_SHARE = 'insights.category.share',
  CATEGORY_UPDATED = 'insights.category.update',
  CATEGORY_DOWNLOAD_AS_COLLECTION = 'insights.category.download-as-collection',
  CATEGORY_REMOVE = 'insights.category.remove',
  DASHBOARD_CREATE = 'insight.dashboard.create',
  DROP_ON_CATEGORY = 'insights.drop-on.category',
  DROP_IN_TRASH = 'insights.drop-on.trash',
  WIDGET_EDIT = 'insights.widget.edit',
  WIDGET_EDIT_TYPE = 'insights.widget.edit-type',
  WIDGET_COPY = 'insight.widget.copy',
  WIDGET_CREATE = 'insight.dashboard.widget.create',
  WIDGET_SAVE = 'insight.widget.save',
  WIDGET_SAVE_COMPLETE = 'insights.widget.save',
  WIDGET_ARCHIVE = 'insight.widget.archive',
  WIDGET_UNARCHIVE = 'insight.widget.unarchive',
  WIDGET_TAG = 'insight.widget.tag',
  WIDGET_LOG = 'insight.widget.log',
  WIDGET_SHARE = 'insights.widget.editor.share',
  WIDGET_STAR = 'insights.widget.editor.star',
  WIDGET_UNSTAR = 'insights.widget.editor.unstar',
}

export type WidgetEditorEventPayload = {
  [WidgetEditorEvents.CATEGORY_CREATE_SUB]: WidgetCategoryModel
  [WidgetEditorEvents.CATEGORY_SHARE]: { id: number }
  [WidgetEditorEvents.CATEGORY_OPTIONS]: WidgetCategoryModel
  [WidgetEditorEvents.CATEGORY_UPDATED]: {}
  [WidgetEditorEvents.CATEGORY_EDIT]: WidgetCategoryModel
  [WidgetEditorEvents.CATEGORY_DOWNLOAD_AS_COLLECTION]: WidgetCategoryModel
  [WidgetEditorEvents.CATEGORY_REMOVE]: WidgetCategoryModel
  [WidgetEditorEvents.DASHBOARD_CREATE]:
    | WidgetEditorDashboardCreateInterface
    | {}
  [WidgetEditorEvents.DROP_ON_CATEGORY]: DraggableInterface
  [WidgetEditorEvents.DROP_IN_TRASH]: DraggableInterface
  [WidgetEditorEvents.WIDGET_EDIT]: WidgetEditInterface
  [WidgetEditorEvents.WIDGET_EDIT_TYPE]: {}
  [WidgetEditorEvents.WIDGET_COPY]: WidgetCopyPayload
  [WidgetEditorEvents.WIDGET_CREATE]: {} // never use
  [WidgetEditorEvents.WIDGET_SAVE]: WidgetReference
  [WidgetEditorEvents.WIDGET_SAVE_COMPLETE]: {}
  [WidgetEditorEvents.WIDGET_ARCHIVE]: {} // never use
  [WidgetEditorEvents.WIDGET_UNARCHIVE]: {} // never use
  [WidgetEditorEvents.WIDGET_TAG]: WidgetEditorTagInterface
  [WidgetEditorEvents.WIDGET_LOG]: {} // nerver use
  [WidgetEditorEvents.WIDGET_SHARE]: { id: number }
  [WidgetEditorEvents.WIDGET_STAR]: {} // nerver use
  [WidgetEditorEvents.WIDGET_UNSTAR]: {} // never use
}

export enum WidgetExploreEvents {
  ROUTE_TO_WIDGET = 'widget-explore.route.widget',
  ROUTE_TO_EXPLORE = 'widget-explore.route.explore',
  ROUTE_TO_INSIGHTS = 'widget-explore.route.insights',
}

export type WidgetExploreEventPayload = {
  [WidgetExploreEvents.ROUTE_TO_EXPLORE]: any // type payload to fix
  [WidgetExploreEvents.ROUTE_TO_WIDGET]: WidgetExploreRouteToWidgetInterface
  [WidgetExploreEvents.ROUTE_TO_INSIGHTS]: any
}

export enum WidgetViewEnum {
  DASHBOARD = 'dashboard',
  WIDGET = 'widget',
}

export enum WidgetViewMode {
  GRID = 'GRID',
  LIST = 'LIST',
}

export type EditModeProvider = {
  getEditMode: () => boolean
}

export type SideBarTreeNode = {
  id: string
  icon: string
  color: string
  to: string
  name: string
  item: WidgetCategoryModel
  children: SideBarTreeNode[]
}
