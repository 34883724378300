import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'name',
  'description',
  'region',
  { attributeName: 'effectiveStartDate', format: 'asDate' },
  'payHoursPerDay',
  'leavePolicyItems',
  'status',
] as ColumnInputDefinition[]
