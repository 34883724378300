<template>
  <div>
    <slot name="before"></slot>
    <v-card-text>
      <v-text-field
        :label="$t('common.type_to_search')"
        outlined
        dense
        clearable
        hide-details
        @input="setSearch($event)"
      />
      <div v-if="model.length" class="pt-2">
        <v-btn small icon text @click="clear">
          <v-icon x-small>close</v-icon>
        </v-btn>
        {{ model.length }} {{ $t('common.selected') }}
        <v-btn small text color="green" @click="showSelected = !showSelected">
          <span v-text="!showSelected ? 'View' : 'Hide'" />
        </v-btn>
      </div>
    </v-card-text>
    <RegionListFilter
      :value="model"
      :search="search"
      :show-selected="showSelected"
      :user-regions="userRegions"
      @input="toggle($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { UserRegion } from '../treeview/utils'
import RegionListFilter from './RegionListFilter.vue'

export default Vue.extend({
  name: 'RegionListWrapper',
  components: { RegionListFilter },
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    userRegions: {
      type: Array as PropType<UserRegion[]>,
      default: () => [],
    },
  },
  data() {
    return {
      search: null,
      showSelected: false,
    }
  },
  computed: {
    model: {
      get(): string[] {
        return this.value || []
      },
      set(newValue: string[]) {
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val || val.length === 0) {
          this.showSelected = false
        }
      },
    },
  },
  methods: {
    isSelected(id: number) {
      const stringId = String(id || '')
      return this.model.includes(stringId)
    },
    toggle(val) {
      if (!val) {
        return
      }
      if (this.model.includes(val)) {
        this.model = this.model.filter((item) => item != val)
      } else {
        this.model = [...this.model, val]
      }
    },
    clear() {
      this.model = []
    },
    setSearch(val: string) {
      this.search = val?.toLowerCase()
    },
  },
})
</script>
