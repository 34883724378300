<template>
  <ProvideDataSetResource>
    <template #default="{ resource }">
      <WidgetTabEditor
        :tabs="[
          { text: 'widget_edit.tab.data_source' },
          { text: 'widget_edit.tab.options', needsResource: true },
          { text: 'widget_edit.tab.toolbar', needsResource: true },
        ]"
        :default-schema="{
          component: {
            is: 'GaugeChartWidget',
          },
        }"
      >
        <template #default="{ tab }">
          <div v-show="tab === 0">
            <!-- Resource Selector -->
            <json-field name="dataSet.resource" />

            <json-field name="options.target" :error="targetError" />

            <!-- Filter Section-->
            <template v-if="resource">
              <json-field name="dataSet.measure" />
              <DataSetFiltersEditor />
            </template>
          </div>

          <div v-show="tab === 1">
            <json-field name="options.color1" />
          </div>
          <div v-show="tab === 2">
            <json-field name="allowDownload" />
            <json-field name="toolbar" />
          </div>
        </template>
      </WidgetTabEditor>
    </template>
  </ProvideDataSetResource>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'GaugeChartWidgetEditor',
  inject: ['formHook'],
  computed: {
    targetError(): boolean {
      return !!(
        this.formHook().errors['options'] ||
        this.formHook().errors['options.target']
      )
    },
  },
})
</script>
