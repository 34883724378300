import { Model } from '@vuex-orm/core'

import { WidgetStoreInterface } from '@/tt-widget-factory'
import { WidgetReference } from '@/tt-widget-factory/types'
import { WidgetCategoryModelMeta } from '@/tt-widget-factory/WidgetCategoryModel'
import { PageSettings } from '@/apps/app.tracktik.insights.studio/lib/DataViewProvider/types'
import { WidgetName } from '@/tt-widget-components/lib/names'

export enum WidgetViewTags {
  STARRED = 'SYSTEM.STARRED',
  MY = 'SYSTEM.MY',
}

export interface WidgetCategoryInterface {
  name: string
  description?: string
  uid?: string
  meta?: WidgetCategoryModelMeta
  ownedByMe?: boolean
  parentCategory?: string
  provider?: string
  isFromMarketplace?: boolean
}

/**
 * I widget collecton persister
 */
export interface WidgetCollectionPersisterInterface {
  copy(widget: WidgetReference): Promise<WidgetReference>

  save(widget: WidgetReference): Promise<WidgetReference>

  create(widget: WidgetReference): Promise<WidgetReference>

  archive(widget: WidgetReference): Promise<WidgetReference>

  createWidget(widget: WidgetStoreInterface): Promise<WidgetReference>

  editWidget(widget: WidgetStoreInterface): Promise<WidgetStoreInterface>
}

export interface WidgetProviderInterface {
  /**
   * Provider code.
   */
  getProviderName(): string

  /**
   * Provider label
   */
  getProviderLabel(): string

  getAllCategories: () => Promise<WidgetCategoryInterface[]>

  getAllViews: () => Promise<WidgetStoreInterface[]>

  getAllInactiveViews?: () => Promise<WidgetStoreInterface[]>
}

export interface WidgetCollectionSearchProviderInterface {
  categoryId?: string | string[]
  categoryIdExcludes?: string[]
  searchQuery?: string
  starred?: boolean
  typeFilter?: WidgetName | WidgetName[]
  typeFilterExclude?: WidgetName | WidgetName[]
  sortBy?: string
  sharedWithMe?: boolean
  ownedByMe?: boolean
  limit?: number
  pagination?: PageSettings
}

export class WidgetTagModel extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'WidgetTagModel'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      uid: this.string(null),
      title: this.string(''),
      tag: this.string(''),
    }
  }
}
