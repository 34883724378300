// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BillableItemsTile from './BillableItemsTile.vue'
import BillableItemsColumns from './BillableItemsColumns'
import BillableItemsDetail from './BillableItemsDetail.vue'
import BillableItemsMetaCard from './BillableItemsMetaCard.vue'
import BillableItemsReference from './BillableItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BillableItemsTile', BillableItemsTile)
    Vue.component('BillableItemsDetail', BillableItemsDetail)
    Vue.component('BillableItemsMetaCard', BillableItemsMetaCard)
    Vue.component('BillableItemsReference', BillableItemsReference)

    modularManager.addResourcePreset(
      Resources.BILLABLE_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BillableItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLABLE_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BillableItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLABLE_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BillableItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLABLE_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BillableItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLABLE_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BillableItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
