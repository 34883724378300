<template>
  <v-btn
    v-bind="btnProps"
    outlined
    @click.stop="applyAction()"
    v-text="$t(label)"
  >
    {{ label }}
  </v-btn>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { DefinitionOptionMap, FormOptions } from '@tracktik/tt-json-schema-form'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OpenShiftRequestsActionBtn',
  inject: ['getItemHook'],
  inheritAttrs: false,
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      approve: {
        label: 'common.approve',
        color: 'green',
        text: true,
      },
      decline: {
        label: 'common.decline',
        color: 'red',
        text: true,
      },
    }
  },
  computed: {
    btnProps(): any {
      return {
        ...this.$attrs,
        ...this[this.action],
      }
    },
    label(): any {
      return this[this.action]?.label ?? this.action
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  methods: {
    applyAction() {
      const { eventManager } = this.getItemHook().appContext

      const customFormDefinitionOptions: DefinitionOptionMap = {
        OpenShiftRequestsApprove: {
          view: {
            is: 'OpenShiftRequestsApproveForm',
            props: {
              employeeId: this.getItemHook().get('requester.id'),
              employeeName: this.getItemHook().get('requester.name'),
              shiftStartDate: this.getItemHook().get('shift.startDateTime'),
              shiftEndDate: this.getItemHook().get('shift.endDateTime'),
            },
          },
        },
      }

      const formOptions: FormOptions = {
        definitions: customFormDefinitionOptions,
      }

      eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
        actionName: this.action,
        ...this.getItemHook().entityReference,
        formOptions,
      })
    },
  },
})
</script>
