import TracktikSdk from 'tracktik-sdk'
import { Resources } from '@/tt-entity-design/src/types'
import {
  RegionContactType,
  RegionResponse,
  SiteZoneEmcContactResponseType,
  SiteZoneContactResponseType,
} from './types'
import i18n from '@/plugins/i18n'

const { REGIONS, CLIENTS } = Resources

/**
 * Simple manager to handle the CTA contacts requests.
 */
export type CtaContactsManager = {
  fetchRegionEmergencyContact: (
    regionId: string,
  ) => Promise<RegionContactType[]>
  fetchSiteZonesEmergencyContact: (
    siteId: string,
  ) => Promise<SiteZoneEmcContactResponseType>
  fetchSiteZonesContact: (
    siteId: string,
  ) => Promise<SiteZoneContactResponseType>
}

export const createCtaContactsManager = (
  sdk: TracktikSdk,
): CtaContactsManager => {
  const fetchRegionEmergencyContact = async (regionId: string) => {
    const attribute = 'emergencyContactNumber'
    const response: RegionResponse = await sdk.get(REGIONS, regionId, {
      fields: [{ attribute: 'id' }, { attribute: attribute }],
    })

    const emergencyContactResponse = response.emergencyContactNumber
    if (emergencyContactResponse) {
      return [
        {
          description: i18n.t('CTA.contacts.region.label'),
          phone: emergencyContactResponse,
        },
      ]
    }

    return []
  }

  const fetchSiteZonesEmergencyContact = async (clientId: string) => {
    const response: SiteZoneEmcContactResponseType = await sdk.get(
      CLIENTS,
      clientId,
      {
        fields: [
          { attribute: 'zones.name' },
          { attribute: 'zones.emergencyContactAssignments.contact.firstName' },
          { attribute: 'zones.emergencyContactAssignments.contact.lastName' },
          { attribute: 'zones.emergencyContactAssignments.contact.jobTitle' },
          {
            attribute: 'zones.emergencyContactAssignments.contact.primaryPhone',
          },
        ],
      },
    )

    return response
  }

  const fetchSiteZonesContact = async (clientId: string) => {
    const response: SiteZoneContactResponseType = await sdk.get(
      CLIENTS,
      clientId,
      {
        fields: [
          { attribute: 'zones.name' },
          { attribute: 'zones.contacts.firstName' },
          { attribute: 'zones.contacts.lastName' },
          { attribute: 'zones.contacts.jobTitle' },
          { attribute: 'zones.contacts.primaryPhone' },
        ],
      },
    )

    return response
  }

  return {
    fetchRegionEmergencyContact,
    fetchSiteZonesEmergencyContact,
    fetchSiteZonesContact,
  }
}
