<template>
  <span v-if="showField">
    <!-- <v-tooltip top v-if="description">
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">{{ _label }}</span>
      </template>
      <template v-text="description" />
    </v-tooltip> -->
    <span>{{ _label }}</span>
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'EntityAttributeLabel',
  inject: ['getItemHook', 'relationPrefix'],
  inheritAttrs: false,
  props: {
    label: { type: String, default: undefined },
    name: { type: String, required: true },
    prefix: { type: String, default: '' },
  },
  computed: {
    resource(): string | null {
      return this.getItemHook().entityReference.resourceName
    },

    _label(): string | null {
      if (this.label) {
        return i18n.t(this.label) as string
      }

      const newName = this.relationPrefix
        ? `${this.relationPrefix}.${this.name}`
        : this.name

      const label = ResourceTranslator.translateAttribute(
        this.resource,
        newName,
        FormLabelTypes.LABEL,
      )

      const prefix = this.prefix && i18n.t(this.prefix)
      return `${prefix}${label}`
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        relationPrefix: this.relationPrefix,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
})
</script>
