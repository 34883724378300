// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftSlicesTile from './ShiftSlicesTile.vue'
import ShiftSlicesColumns from './ShiftSlicesColumns'
import ShiftSlicesDetail from './ShiftSlicesDetail.vue'
import ShiftSlicesReference from './ShiftSlicesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftSlicesTile', ShiftSlicesTile)
    Vue.component('ShiftSlicesDetail', ShiftSlicesDetail)
    Vue.component('ShiftSlicesReference', ShiftSlicesReference)

    modularManager.addResourcePreset(
      Resources.SHIFT_SLICES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftSlicesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SLICES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftSlicesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SLICES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftSlicesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SLICES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftSlicesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
