// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SkillsTile from './SkillsTile.vue'
import SkillsColumns from './SkillsColumns'
import SkillsDetail from './SkillsDetail.vue'
import SkillsMetaCard from './SkillsMetaCard.vue'
import SkillsReference from './SkillsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SkillsTile', SkillsTile)
    Vue.component('SkillsDetail', SkillsDetail)
    Vue.component('SkillsMetaCard', SkillsMetaCard)
    Vue.component('SkillsReference', SkillsReference)

    modularManager.addResourcePreset(Resources.SKILLS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'SkillsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.SKILLS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: SkillsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.SKILLS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SkillsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.SKILLS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'SkillsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.SKILLS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SkillsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
