import { WidgetSchema } from '@/tt-widget-factory'

export default {
  type: 'object',
  title: 'Map',
  required: ['geoMapOptions', 'query', 'is', 'title'],
  properties: {
    is: {
      enum: ['MapWidget'],
    },
    title: {
      title: 'Widget Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Widget Title',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
      default: {},
    },
    geoMapOptions: {
      title: 'Attribute Mapping',
      $ref: '#/definitions/ResourceGeoMappingOptions',
      default: {},
    },
    options: {
      title: 'Map Options',
      $ref: '#/definitions/MapOptions',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
  },
  definitions: {
    MapOptions: {
      title: 'Customization Options',
      type: 'object',
      properties: {
        zoom: {
          title: 'Default Zoom',
          type: 'string',
          defaultValue: '10',
        },
        latitude: {
          title: 'Default Latitude',
          type: 'string',
          defaultValue: '45.516136',
        },
        longitude: {
          title: 'Default Longitude',
          type: 'string',
          defaultValue: '-73.65683',
        },
        showCurrentMapInfo: {
          title: 'Show Current Map Info',
          type: 'boolean',
          defaultValue: true,
        },
      },
    },
  },
} as WidgetSchema
