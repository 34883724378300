// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ClientBillingSettingsTile from './ClientBillingSettingsTile.vue'
import ClientBillingSettingsColumns from './ClientBillingSettingsColumns'
import ClientBillingSettingsDetail from './ClientBillingSettingsDetail.vue'
import ClientBillingSettingsReference from './ClientBillingSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ClientBillingSettingsTile', ClientBillingSettingsTile)
    Vue.component('ClientBillingSettingsDetail', ClientBillingSettingsDetail)
    Vue.component(
      'ClientBillingSettingsReference',
      ClientBillingSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.CLIENT_BILLING_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ClientBillingSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_BILLING_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ClientBillingSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_BILLING_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ClientBillingSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CLIENT_BILLING_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ClientBillingSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
