const multi1 = Object.freeze({
  red: '#ef9a9a',
  pink: '#f48fb1',
  purple: '#ce93d8',
  indigo: '#9fa8da',
  lightBlue: '#90caf9',
  cyan: '#80deea',
  teal: '#80cbc4',
  lightGreen: '#c5e1a5',
  yellow: '#fff59d',
  orange: '#ffcc80',
})
const multi2 = Object.freeze({
  red: '#c62828',
  pink: '#ad1457',
  purple: '#6a1b9a',
  indigo: '#283593',
  lightBlue: '#0277bd',
  cyan: '#00838f',
  teal: '#00695c',
  lightGreen: '#558b2f',
  yellow: '#f9a825',
  orange: '#ef6c00',
})

export default Object.freeze({
  multi1,
  multi2,
})
