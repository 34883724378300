// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AlarmTypesTile from './AlarmTypesTile.vue'
import AlarmTypesColumns from './AlarmTypesColumns'
import AlarmTypesDetail from './AlarmTypesDetail.vue'
import AlarmTypesMetaCard from './AlarmTypesMetaCard.vue'
import AlarmTypesReference from './AlarmTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AlarmTypesTile', AlarmTypesTile)
    Vue.component('AlarmTypesDetail', AlarmTypesDetail)
    Vue.component('AlarmTypesMetaCard', AlarmTypesMetaCard)
    Vue.component('AlarmTypesReference', AlarmTypesReference)

    modularManager.addResourcePreset(
      Resources.ALARM_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AlarmTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AlarmTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_TYPES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmTypesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AlarmTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
