import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { createDocumentColumn } from '@/tt-widget-entity-flow/createDocumentColumn'
import WorkSessionDoc from '@/tt-entity-design/src/components/work-sessions/WorkSessionDoc'

export default [
  createDocumentColumn(WorkSessionDoc),
  'employee',
  'employee.jobTitle',
  'account',
  {
    attributeName: 'clockInMethod',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'clockInMethod',
      },
    },
  },
  'startedOn',
  'durationHours',
  'shift.overtimeHours',
  'shift.attendanceStatus',
  {
    attributeName: 'status',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'status',
      },
    },
  },
] as ColumnInputDefinition[]
