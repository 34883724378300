<template>
  <v-dialog :value="true" width="1100" @input="closeDialog">
    <v-card class="pa-0 ma-0">
      <PageTitleBar :title="resource">
        <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
      </PageTitleBar>
      <v-card-text style="height: 600px">
        <widget-factory :widget="widget" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WidgetDialog',
  props: {
    resource: {
      type: String,
    },
    widget: {
      type: Object,
    },
    closeDialog: {
      type: Function,
    },
  },
})
</script>
