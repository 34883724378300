// Enter your custom installation here
import { modularManager } from '@/tt-app-modular'
import MobileRunsheetSessionsActionBar from './MobileRunsheetSessionsActionBar.vue'
import MobileRunsheetSessionsStatusAttr from './MobileRunsheetSessionsStatusAttr.vue'
import { Resources } from '../../types'
import { ListWidgetModel } from '@/tt-widget-components'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

const { MOBILE_RUNSHEET_SESSIONS } = Resources
const getAttr = (attr: Attribute<typeof MOBILE_RUNSHEET_SESSIONS>) => attr

export default {
  install(Vue) {
    Vue.component(
      'MobileRunsheetSessionsStatusAttr',
      MobileRunsheetSessionsStatusAttr,
    )

    const sessionsLogsListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      },
    }

    addPreviewTab(MOBILE_RUNSHEET_SESSIONS, {
      title: 'tt-entity-design.mobile-runsheets.preview.tab-logs',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'mobileRunsheetSession',
        widget: sessionsLogsListWidget,
      },
    })

    modularManager.registerActionBar(MOBILE_RUNSHEET_SESSIONS, {
      is: MobileRunsheetSessionsActionBar,
    })

    addPreviewTab(MOBILE_RUNSHEET_SESSIONS, {
      title: createI18nResourceKey(
        MOBILE_RUNSHEET_SESSIONS,
        getAttr('mobileScheduleOccurrenceLogs'),
      ),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('mobileScheduleOccurrenceLogs'),
      },
    })
  },
}
