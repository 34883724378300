<template>
  <div>
    <json-field name="columnHeaderFilters" />
    <DataTableColumnsEditor
      v-if="resource"
      :resource="resource"
      :value="selectedColumns"
      @input="emitUpdate"
    />
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { DataTableOptions } from '@/tt-widget-components/schemas-types'
import DataTableColumnsEditor from './DataTableColumnsEditor.vue'
import { ResourceProvider } from '@/tt-widget-components/types'

export default (Vue as VueConstructor<Vue & ResourceProvider>).extend({
  name: 'DataTableOptions',
  components: {
    DataTableColumnsEditor,
  },
  inject: ['resourceProvider'],
  props: {
    value: {
      type: Object as PropType<DataTableOptions>,
      default: () => ({
        columns: [],
      }),
    },
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    resource(): any {
      return this.resourceProvider.resource
    },
    selectedColumns(): any[] {
      const columns = this.value.columns || []
      return [...columns]
    },
  },
  methods: {
    emitUpdate(columns) {
      this.$emit('input', { ...this.value, columns })
    },
  },
})
</script>
