<template>
  <div>
    <json-field name="resource" />
    <template v-if="resource">
      <json-field name="scope" />
      <v-divider />
      <json-field name="includeInactive" />
      <v-divider />
      <json-field name="sort" as="QuerySortObjectInput" />
      <v-divider />
      <json-field name="filters" />
      <v-divider />
      <json-field name="customFilters" />
      <v-divider />
      <json-field name="whereQL" />
      <v-divider />
      <json-field name="contextFilters" />
      <v-divider />
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider, ResourceProvider } from '../types'

export default (
  Vue as VueConstructor<Vue & ResourceProvider & FormHookProvider>
).extend({
  inject: ['formHook', 'resourceProvider'],
  computed: {
    resource(): string | null {
      return this.resourceProvider.resource
    },
  },
})
</script>
