import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'contract.account',
    headerText:
      'tt-entity-design.contract-occurence-logs.attrs.contract.account',
  },
  'status',
  'occurrenceDate',
  'invoice',
  'contract',
] as ColumnInputDefinition[]
