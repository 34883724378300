// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PayrollScheduleOccurrencesTile from './PayrollScheduleOccurrencesTile.vue'
import PayrollScheduleOccurrencesColumns from './PayrollScheduleOccurrencesColumns'
import PayrollScheduleOccurrencesDetail from './PayrollScheduleOccurrencesDetail.vue'
import PayrollScheduleOccurrencesReference from './PayrollScheduleOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PayrollScheduleOccurrencesTile',
      PayrollScheduleOccurrencesTile,
    )
    Vue.component(
      'PayrollScheduleOccurrencesDetail',
      PayrollScheduleOccurrencesDetail,
    )
    Vue.component(
      'PayrollScheduleOccurrencesReference',
      PayrollScheduleOccurrencesReference,
    )

    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULE_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PayrollScheduleOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULE_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PayrollScheduleOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULE_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollScheduleOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULE_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PayrollScheduleOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
