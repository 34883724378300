// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTransactionItemsTile from './AssetTransactionItemsTile.vue'
import AssetTransactionItemsColumns from './AssetTransactionItemsColumns'
import AssetTransactionItemsDetail from './AssetTransactionItemsDetail.vue'
import AssetTransactionItemsMetaCard from './AssetTransactionItemsMetaCard.vue'
import AssetTransactionItemsReference from './AssetTransactionItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetTransactionItemsTile', AssetTransactionItemsTile)
    Vue.component('AssetTransactionItemsDetail', AssetTransactionItemsDetail)
    Vue.component(
      'AssetTransactionItemsMetaCard',
      AssetTransactionItemsMetaCard,
    )
    Vue.component(
      'AssetTransactionItemsReference',
      AssetTransactionItemsReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTION_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTransactionItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTION_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTransactionItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTION_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTransactionItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTION_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTransactionItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTION_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTransactionItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
