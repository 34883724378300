<template>
  <ProvideResource :resource="resource">
    <slot :resource="resource"></slot>
  </ProvideResource>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider, ResourceProvider } from '..'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & ResourceProvider>
).extend({
  name: 'ProvideCollectionResource',
  inject: ['formHook'],
  computed: {
    resource(): any {
      return this.formHook().getPathValue('query.resource')
    },
  },
})
</script>
