import { EntityListIntent } from './EntityListIntent'
import { EntityPreviewIntent } from './EntityPreviewIntent'
import { EntityEditIntent } from './EntityEditIntent'
import { EntityCreateIntent } from './EntityCreateIntent'
import { EntityAggregationDetailIntent } from './EntityAggregationDetailIntent'
import { EntityActionIntent } from './EntityActionIntent'
import { EntityBatchActionIntent } from './EntityBatchActionIntent'
import { AppContext } from '@/tt-app-context'
import { EventGroupManager } from '@/tt-widget-entity-flow/EventGroupManager'
import { RegionFilterIntent } from './RegionFilterIntent'
import { TTCloudLeaveRequestsActionCreateIntent } from '@/tt-entity-design/src/components/leave-requests/TTCloudLeaveRequestsActionCreateIntent'

export {
  EntityActionIntent,
  EntityAggregationDetailIntent,
  EntityBatchActionIntent,
  EntityCreateIntent,
  EntityEditIntent,
  EntityListIntent,
  EntityPreviewIntent,
}

export * from './types'

/**
 * Register entity intents
 * @param appContext
 */
export function registerEntityIntents(appContext: AppContext) {
  // Create the manager
  const manager = new EventGroupManager(appContext.eventManager)

  // Add the list intent
  const listIntent = new EntityListIntent(appContext)
  const previewIntent = new EntityPreviewIntent(appContext)
  const editIntent = new EntityEditIntent(appContext)
  const createIntent = new EntityCreateIntent(appContext)
  const aggDetailIntent = new EntityAggregationDetailIntent(appContext)
  const action = new EntityActionIntent(appContext)
  const batchAction = new EntityBatchActionIntent(appContext)

  // Add widget component intent
  const regionFilterIntent = new RegionFilterIntent(appContext)

  // leave-requests
  const ttcloudLeaveRequestsActionCreateIntent =
    new TTCloudLeaveRequestsActionCreateIntent(appContext)

  // Register
  manager.register(listIntent)
  manager.register(previewIntent)
  manager.register(editIntent)
  manager.register(createIntent)
  manager.register(aggDetailIntent)
  manager.register(action)
  manager.register(batchAction)
  manager.register(regionFilterIntent)
  manager.register(ttcloudLeaveRequestsActionCreateIntent)

  manager.subscribeEvents()

  return manager
}
