<template>
  <v-progress-linear
    v-model="value"
    height="25"
    background-color="var(--v-toolbar3-base)"
    color="var(--v-ttPrimary-base)"
    class="tt-progress-bar"
  >
    <strong>{{ Math.floor(value) }}%</strong>
  </v-progress-linear>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'FakeProgressBar',
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      factor: 1,
      timer: null,
    }
  },
  watch: {
    isCompleted: {
      handler(newValue, oldValue) {
        if (!oldValue && newValue) {
          this.value = 100

          this.clearTimer()
        }
      },
    },
  },
  mounted() {
    this.startLoadingProgress()
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    startLoadingProgress() {
      const damping = 500 // Increase this to slow down increment.
      const minVal = 0.1

      this.timer = setInterval(() => {
        // If timer is at 100, clear the interval and stop execution
        if (this.value >= 100) {
          this.clearTimer()
          return
        }

        if (this.value < 100) {
          this.factor = Math.max((100 - this.value) / damping, minVal)
        }

        this.value = this.value + this.factor
      }, 30)
    },
    // eslint-disable-next-line no-undef
    clearTimer() {
      if (this.timer) clearInterval(this.timer)
    },
  },
})
</script>

<style scoped>
.tt-progress-bar >>> .v-progress-linear__determinate {
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}
</style>
