<template>
  <v-dialog
    v-model="state.dialogLoading.state"
    class="white-shadow"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="isMobile ? null : payload.width"
    @input="onStateChange"
  >
    <v-card>
      <v-card-title>
        {{ payload.title }}

        <v-btn icon text circle class="ml-auto" @click="onStateChange(false)">
          <slot name="before">
            <v-icon v-text="'close'" />
          </slot>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <!-- progress bar -->
        <FakeProgressBar
          v-if="state.dialogLoading.state"
          :is-completed="isCompleted"
        />
        <!-- / progress bar -->

        <!-- subtitle -->
        <p class="text-center font-weight-medium mt-4 mb-12 text-h5">
          {{ isCompleted ? $t('common.download-completed') : payload.subTitle }}
        </p>
        <!-- / subtitle -->

        <div class="d-flex justify-end mt-12">
          <v-btn color="var(--v-ttPrimary-base)" @click="handleCancelRequest">
            <span
              class="white--text"
              v-text="$t('common.download.cancel.btn')"
            />
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { some } from 'lodash'
import { LayoutWindowEvent } from '@/tt-app-layout'
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'
import FakeProgressBar from '@/tt-widget-components/components/FakeProgressBar.vue'

import { DialogLoadingInterface } from '../types'

import { disableDocumentScroll, enableDocumentScroll } from './scroll'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutDialogLoading',
  components: {
    FakeProgressBar,
  },
  props: {
    lockScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCompleted: false,
      eventSubscriber: null,
    }
  },
  created() {
    this.eventSubscriber = this.$eventManager.subscribeEvent(
      LayoutWindowEvent.DIALOG_LOADING_COMPLETE,
      () => {
        this.isCompleted = true
      },
    )
  },
  beforeDestroy() {
    enableDocumentScroll()

    if (this.eventSubscriber) {
      this.eventSubscriber()
    }
  },
  computed: {
    isOpen(): boolean {
      return this.state.dialogLoading.state
    },
    payload(): DialogLoadingInterface {
      return this.layoutManager.state.dialogLoading.payload
    },
  },
  watch: {
    isOpen: {
      handler() {
        this.toggleLockScroll()

        if (!this.isOpen) {
          this.resetCompletedStatus()
        }
      },
    },
    lockScroll: 'toggleLockScroll',
  },
  methods: {
    toggleLockScroll(): void {
      if (this.lockScroll && this.isOpen) {
        disableDocumentScroll()
      } else {
        enableDocumentScroll()
      }
    },
    onStateChange(isOpening: boolean): void {
      if (!isOpening) {
        this.setNotificationChip()
        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.DIALOG_LOADING_CLOSE,
        )
      }
    },
    setNotificationChip(): void {
      const { chips } = this.layoutManager.state.notificationChips
      const { notificationChipId } = this.payload

      // check if a chip with the same key already exists
      const chipExists = chips && some(chips, { key: notificationChipId })

      // if the chip doesn't exists, then set it
      if (!chipExists) {
        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.NOTIFICATION_CHIPS_ADD,
          {
            title: this.payload.title,
            key: notificationChipId,
          },
        )
      }
    },
    handleCancelRequest(): void {
      this.payload?.cancel && this.payload.cancel()
    },
    resetCompletedStatus(): void {
      this.isCompleted = false
    },
  },
})
</script>
