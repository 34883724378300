<template>
  <div>
    <AccountContextJsonField name="account" />
    <json-field name="ruleName" />
    <json-field name="trigger" />
    <!-- Product don't want this to be displayed for now -->
    <!-- <json-field name="applyToSubSites" /> -->
    <json-field name="emailTemplate" />
    <json-field name="emailSubject" />
    <json-field name="emailBody" />
    <!-- Product don't want this to be displayed -->
    <!-- <json-field name="emailIncludeAttachment" /> -->
    <json-field name="sms" />
    <json-field name="email" />
    <json-field name="push" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'FilterRuleSetAssetsForm',
})
</script>
