import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'employee',
  'leaveType',
  {
    attributeName: 'balanceDays',
    format: 'asRounded2Decimals',
  },
  {
    attributeName: 'balanceDaysIncludingPending',
    format: 'asRounded2Decimals',
  },
  { attributeName: 'periodStartDateTime', format: 'asDate' },
  { attributeName: 'periodEndDateTime', format: 'asDate' },
  'entitlementDays',
  'payHoursPerDay',
] as ColumnInputDefinition[]
