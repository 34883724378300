// Enter your custom installation here
import LeavePolicyCarryOverStatusChip from './LeavePolicyCarryOverStatusChip.vue'

export default {
  install(Vue) {
    Vue.component(
      'LeavePolicyCarryOverStatusChip',
      LeavePolicyCarryOverStatusChip,
    )
  },
}
