<template>
  <div class="search-flex-box">
    <div class="pa-2 search-field-wrapper">
      <v-text-field
        :value="search"
        hide-details
        solo
        append-icon="search"
        clearable
        :placeholder="$t('components.widget_drag_search.search_widgets')"
        style="font-size: 14px; width: 100%; font-weight: 300"
        @input="debounceSearch"
      />
    </div>
    <WidgetSearchResults
      :draggable="true"
      :search-provider="searchProvider"
      :show-action-menu="false"
      :include-store-results="true"
      :is-widget-search-nav="true"
      view-mode="LIST"
    ></WidgetSearchResults>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import debounce from 'lodash/debounce'
import { WidgetName } from '../lib/names'

export default Vue.extend({
  name: 'WidgetDragSearch',
  data() {
    return {
      search: null,
    }
  },
  computed: {
    searchProvider(): WidgetCollectionSearchProviderInterface {
      return {
        starred: null,
        searchQuery: this.search,
        typeFilter: null,
        typeFilterExclude: [WidgetName.DASHBOARD_WIDGET],
      }
    },
    debounceSearch(): any {
      return debounce((value) => {
        this.search = value
      }, 750)
    },
  },
})
</script>
<style scoped>
.search-field-wrapper {
  border-bottom: 1px solid #ddd;
}
.search-flex-box {
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
}
</style>
