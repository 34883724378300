<template>
  <div class="main--container">
    <div class="content--container">
      <v-divider v-if="!isEditMode" class="mb-3" />
      <div v-if="!isEditMode" class="pa-6">
        <v-btn
          v-for="({ ui }, fieldName) in Fields"
          :key="fieldName"
          class="pa-2 ma-2 button--field--type"
          depressed
          :style="
            selectedType === fieldName
              ? 'box-shadow: 0 0 6px 2px cadetblue; color: cadetblue;'
              : 'color: grey;'
          "
          @click="selectType(fieldName)"
        >
          <div class="d-flex flex-column ma-3">
            <v-icon size="25" v-text="ui.icon" />
            <div class="caption pt-1" v-text="$t(ui.name)" />
          </div>
        </v-btn>
      </div>

      <v-divider class="mx-6" />

      <div v-if="selectedType" class="sub--container pa-3">
        <JsonForm
          :value="field"
          :schema="editSchema"
          :form-options="editorFormOptions"
          :show-status="isDev"
          class="form--config ma-6"
          @errors="isValid = false"
          @valid="isValid = true"
          @input="update"
        >
          <div class="d-flex">
            <v-icon
              size="25"
              :color="selectedFieldMeta.ui.color"
              v-text="selectedFieldMeta.ui.icon"
            />
            <h3 class="pa-3" v-text="$t(selectedFieldMeta.ui.name)" />
          </div>

          <div class="pa-2">
            <v-tabs
              v-model="tab"
              slider-color="orange"
              height="50"
              background-color="transparent"
              class="mb-6"
              show-arrows
            >
              <v-tab
                v-for="{ tag, text } in tabs"
                :key="tag"
                v-text="$t(text)"
              />
            </v-tabs>

            <div v-show="tab === 0">
              <json-field :name="path('name')" />

              <json-field
                :name="path('description')"
                as="VTextarea"
                height="70"
                class="mt-6"
              />
            </div>

            <div v-show="tabs[tab].tag === 'toDisplayOn'">
              <AssetCustomAttributesDisplayOnForm
                base-path="toDisplayOn.entity"
              />

              <v-divider />

              <AssetCustomAttributesDisplayOnForm
                base-path="toDisplayOn.check-out"
              />

              <v-divider />

              <AssetCustomAttributesDisplayOnForm
                base-path="toDisplayOn.check-in"
              />
            </div>

            <!-- Separate the rendering of the options based on the field properties type -->
            <template v-if="tabs[tab].tag === 'options'">
              <json-field name="options.list" :readonly="isEditMode" />
              <json-field name="options.high_quality" />
            </template>

            <v-spacer />

            <v-btn v-if="isDev" icon x-small @click="viewCode">
              <v-icon class="pa-3" v-text="`mdi-code-braces`" />
            </v-btn>
          </div>
        </JsonForm>

        <!-- <v-divider vertical class="ma-3" /> -->

        <!-- preview hidden -- keeping it for development -->
        <div v-if="false" class="preview--container ma-6">
          <h3 class="pb-3">WEB Preview (different from mobile)</h3>

          <JsonForm
            v-if="isValid"
            :value="undefined"
            :schema="previewSchema"
            :form-options="previewFormOptions"
            class="form--preview"
          />

          <div v-else class="form--preview">
            <v-banner
              outlined
              rounded
              color="warning"
              style="width: 100%"
              v-text="invalidMessage"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footer--container">
      <v-divider class="mx-3 pb-3" />

      <div class="btn--container pa-3">
        <v-btn color="grey" text @click="close">
          <span v-text="$t('common.close.btn')" />
        </v-btn>

        <v-btn
          color="success"
          :disabled="!isValid || !selectedType"
          @click="save"
        >
          <span v-text="$t('common.save.btn')" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import {
  EmptyValueRule,
  FormLabelTypes,
  FormOptions,
  FormTranslateFunctionInterface,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import {
  ActiveWindowProvider,
  DialogFormInterface,
  LayoutWindowEvent,
} from '@/tt-app-layout'

import isEmpty from 'lodash/isEmpty'

import { FieldMetaMap } from '@/tt-fieldset-builder/fields'
import { Field, FieldMeta } from '@/tt-fieldset-builder/type'
import {
  BaseField,
  FieldName,
  DisplayOnOptions,
} from '@/tt-fieldset-builder/base/types'
import { LOCALES_BASE } from '@/tt-fieldset-builder/i18n'
import { AssetCustomField } from '@/tt-fieldset-builder/api/types'
import { ContextAccountHandler } from '@/tt-widget-factory/context/ContextAccountHandler'
import uuid from 'uuid'
import AssetCustomAttributesDisplayOnForm from './AssetCustomAttributesDisplayOnForm.vue'
type Path =
  | keyof BaseField
  | `toDisplayOn.${keyof BaseField['toDisplayOn']}.${keyof DisplayOnOptions}`

type VueWithInjections = VueConstructor<Vue & ActiveWindowProvider>

const i18n_PREFIX = 'tt-entity-design.asset-custom-attributes.create-form'

const i18nKey = (path: string) => `${i18n_PREFIX}.${path}`

type Tab = {
  text: string
  tag: string
}

export default (Vue as VueWithInjections).extend({
  name: 'CustomFieldCreator',
  components: { AssetCustomAttributesDisplayOnForm },
  inject: ['activeWindow'],
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    field: null as Field | null,
    isValid: false,
  }),
  computed: {
    isEditMode(): boolean {
      return !!this.payload.initialModel?.config?.uid
    },
    invalidMessage(): string {
      const key = i18nKey('invalid-config')

      return this.$t(key)
    },
    isDev(): boolean {
      // always deactivate for now as UAT environment is also a dev environment
      return false
      // return process.env.NODE_ENV !== 'production'
    },
    Fields(): Record<FieldName, FieldMeta> {
      return FieldMetaMap
    },
    selectedType(): FieldName | undefined {
      return this.field?.type
    },
    selectedFieldMeta(): FieldMeta {
      return this.Fields[this.selectedType]
    },
    icon(): string {
      return this.selectedFieldMeta.ui.icon
    },
    editSchema(): JSONSchema7 {
      return this.selectedFieldMeta.editSchema
    },
    previewSchema(): JSONSchema7 | null {
      if (!this.isValid) return null

      const fieldSchema = this.selectedFieldMeta
        .converter(this.field)
        .getJsonSchema()

      // needs to be wrapped in an object schema to work properly with UI lib
      return {
        type: 'object',
        properties: {
          [this.field.uid]: fieldSchema,
        },
      }
    },
    editorFormOptions(): FormOptions | null {
      if (!this.selectedType) return null

      const translateFunction: FormTranslateFunctionInterface = (
        _,
        path,
        labelType,
      ) => {
        const basePath = `${LOCALES_BASE}.${this.selectedType}.${path}`

        const i18nKey = `${basePath}.description`
        const isLabel = () => labelType === FormLabelTypes.LABEL
        const keyExists = () => this.$te(i18nKey)

        return isLabel() && keyExists() ? this.$t(i18nKey) : ''
      }

      return { translateFunction, emptyValues: EmptyValueRule.KEEP }
    },
    previewFormOptions(): FormOptions | null {
      if (!this.isValid) return null

      const fieldViewOption = this.selectedFieldMeta
        .converter(this.field)
        .getViewOption()

      return {
        definitions: {
          '': {
            properties: { [this.field.uid]: fieldViewOption },
          },
        },
      }
    },
    tabs(): Tab[] {
      const tabs = [
        { text: i18nKey('tabs.main'), tag: 'main' },
        { text: i18nKey('tabs.display'), tag: 'toDisplayOn' },
      ]

      if (this.hasOptions)
        tabs.push({ text: i18nKey('tabs.options'), tag: 'options' })

      return tabs
    },
    hasOptions(): boolean {
      const optionsPath: Path = 'options'

      return !isEmpty(this.editSchema.properties?.[optionsPath]?.properties)
    },
  },
  created() {
    this.field = this.payload.initialModel?.config
  },
  methods: {
    // for typing the path in the template
    path(path: Path) {
      return path
    },
    selectType(type: FieldName) {
      const nameKey = i18nKey('new-field')

      const fieldType = this.$t(FieldMetaMap[type].ui.name)

      const newField = {
        type,
        name: this.$t(nameKey, { fieldType }),
        uid: uuid(),
        options: {},
        toDisplayOn: {},
      } as Field

      this.tab = 0
      this.isValid = false
      this.field = newField
    },
    viewCode() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        title: 'components.widget.code-preview.label',
        is: 'WidgetJsonViewer',
        props: {
          json: this.field,
        },
      })
    },
    update(newModel) {
      this.field = newModel
    },
    save() {
      const payload: AssetCustomField = {
        ...this.payload.initialModel,
        account: ContextAccountHandler(this.$appContext).get(),
        config: this.field,
      }

      this.payload
        .submit(payload)
        .then(() => this.activeWindow.back())
        .catch(() => {
          console.warn('error while saving... you might need to POST')
        })
    },
    close() {
      this.activeWindow.back()
    },
  },
})
</script>

<style lang="scss" scoped>
@import 'vuetify/src/styles/settings/_variables.scss';

.main--container {
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content--container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sub--container {
  display: flex;
}

.form--config {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form--preview {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: center;
}

.preview--container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fieldset--form {
  ::v-deep {
    .json-field {
      margin: 1em;
    }
  }
}

.type--icon {
  margin: 0 1em !important;
}

.btn--container {
  display: flex;
  justify-content: space-between;
}

.footer--container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button--field--type {
  height: 70px !important;
  width: 100px;
}

.form--config {
  ::v-deep {
    .form-array-container {
      padding-top: 1em;
    }
  }
}

.form--config {
  ::v-deep {
    .form-array-section {
      padding-bottom: 0.5em;
    }
  }
}
</style>
