<template>
  <tt-tile>
    <tt-attr slot="title" name="label" />
    <ExceptionTypeParentLabel slot="line2" />
  </tt-tile>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ExceptionTypeGroupItemsTile',
  provide: {
    relationPrefix: 'type',
  },
})
</script>
