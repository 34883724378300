import { AppContext } from '@/tt-app-context'
import { WidgetReference } from '@/tt-widget-factory'

import {
  DashboardWidgetModel,
  MapWidgetModel,
  RichTextWidgetModel,
  SchedulerWidgetModel,
  TQLQueryWidgetModel,
  VimeoWidgetModel,
  WidgetModels,
  WidgetModelsObject,
  YoutubeWidgetModel,
} from '../schemas-types'
import { isValidWidget } from '../helpers/is-valid-widget'

export type ExcludedWidgetModels =
  | YoutubeWidgetModel
  | VimeoWidgetModel
  | DashboardWidgetModel
  | MapWidgetModel
  | TQLQueryWidgetModel
  | RichTextWidgetModel
  | SchedulerWidgetModel

export type ExcludedWidgetNames = ExcludedWidgetModels['is']

export const excludedFromWidgetDetails: string[] = [
  'YoutubeWidget',
  'VimeoWidget',
  'DashboardWidget',
  'MapWidget',
  'TQLQueryWidget',
  'RichTextWidget',
  'SchedulerWidget',
]

export type WidgetDetailsModel = Exclude<
  WidgetModelsObject,
  ExcludedWidgetModels
>
export type CollectionWidget = Extract<WidgetDetailsModel, { query }>
export type DataSetWidget = Extract<WidgetDetailsModel, { dataSet }>

export const isDataSetWidget = (widget): widget is DataSetWidget =>
  !!widget?.dataSet
export const isCollectionWidget = (widget): widget is CollectionWidget =>
  !!widget?.query

export const canDisplayWidgetDetails = (
  widget: WidgetModels | WidgetReference,
  appContext: AppContext,
): boolean => {
  return (
    widget != undefined &&
    !excludedFromWidgetDetails.includes(widget?.is) &&
    isValidWidget(appContext.widgetServices.widgetManager, widget)
  )
}
