// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftLogsTile from './ShiftLogsTile.vue'
import ShiftLogsColumns from './ShiftLogsColumns'
import ShiftLogsDetail from './ShiftLogsDetail.vue'
import ShiftLogsMetaCard from './ShiftLogsMetaCard.vue'
import ShiftLogsReference from './ShiftLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftLogsTile', ShiftLogsTile)
    Vue.component('ShiftLogsDetail', ShiftLogsDetail)
    Vue.component('ShiftLogsMetaCard', ShiftLogsMetaCard)
    Vue.component('ShiftLogsReference', ShiftLogsReference)

    modularManager.addResourcePreset(
      Resources.SHIFT_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
