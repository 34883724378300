<template>
  <CollapsibleHeader>
    <template #title>
      <span class="ml-4">
        <span v-text="$t('widget_edit.default_sort.title')"></span>
        <v-badge v-if="model && model.attribute" color="green" dot inline />
      </span>
    </template>
    <div class="text-center align-center mb-5">
      <QuerySortInput v-model="model" />
    </div>
  </CollapsibleHeader>
</template>
<script lang="ts">
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import { cloneDeep } from 'lodash'

export default ObjectInput.extend({
  name: 'QuerySortObjectInput',
  computed: {
    emitNullOnDefault(): true {
      return true
    },
    defaultModel(): null {
      return null
    },
  },
  methods: {
    setModel(input: any) {
      if (input) {
        this.model = cloneDeep({ ...input })
      } else {
        this.model = null
      }
    },
  },
})
</script>
