<template>
  <div>
    <tt-attr name="requestedDays" :suffix="` ${translationDays}`" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsRequestedDays',
  inject: ['getItemHook'],
  computed: {
    translationDays(): string {
      const requestedAmount = this.getItemHook().get('requestedDays')

      return this.$tc('common.days', requestedAmount)
    },
  },
})
</script>
