// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowStatusActionsTile from './WorkflowStatusActionsTile.vue'
import WorkflowStatusActionsColumns from './WorkflowStatusActionsColumns'
import WorkflowStatusActionsDetail from './WorkflowStatusActionsDetail.vue'
import WorkflowStatusActionsReference from './WorkflowStatusActionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowStatusActionsTile', WorkflowStatusActionsTile)
    Vue.component('WorkflowStatusActionsDetail', WorkflowStatusActionsDetail)
    Vue.component(
      'WorkflowStatusActionsReference',
      WorkflowStatusActionsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_ACTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusActionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_ACTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowStatusActionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_ACTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusActionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_ACTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusActionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
