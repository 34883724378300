import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

const EXTENSIONS_PREFIX = 'extensions.'

export interface Extension {
  name: string
  path: string // path without 'extensions.'
}

export const hasExtensionPrefix = (path: string): boolean =>
  path && path.startsWith(EXTENSIONS_PREFIX)

export const isExtension = hasExtensionPrefix

export const isNotExtension = (path: string): boolean => !isExtension(path)

/**
 * Get the extension translation key
 * @param resource
 * @param attribute
 */
export const getExtensionLabelKey = (
  resource: string,
  attribute: string,
  type: string = FormLabelTypes.LABEL,
) => {
  const path = attribute.split('.')
  const parts = []
  for (let i = 0; i < path.length; i++) {
    parts.push(path[i])
    if (i == 0 || i === path.length - 1) {
      continue
    }
    parts.push('attr')
  }
  return `res.${resource}.${parts.join('.')}.labels.${type}`
}

export const getExtensionPathWithoutPrefix = (path: string): string =>
  hasExtensionPrefix(path) ? path.substring(EXTENSIONS_PREFIX.length) : path

// "extensions.breakCompliance.breakGracePeriods" >> "breakCompliance"
// "breakCompliance.breakGracePeriods" >> "breakCompliance"
export const getExtensionName = (path: string): string =>
  hasExtensionPrefix(path) ? path.split('.')[1] : path.split('.')[0]
