<template>
  <v-card-text class="mt-2">
    <v-select
      v-model="operator"
      dense
      outlined
      :items="operators"
      :label="$t('common.operator')"
      :menu-props="{ offsetY: true }"
    />
    <v-text-field
      v-if="showInput"
      dense
      hide-details
      outlined
      :value="filter.value"
      :label="$t('common.type_to_search')"
      :error="!filter.value"
      @input="debounceSetValue"
    />
  </v-card-text>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  FilterOperatorType,
  Filter,
} from 'tracktik-sdk/lib/common/entity-filters'
import { debounce, DebouncedFunc } from 'lodash'

type Debouncer = DebouncedFunc<(newValue: Filter['value']) => void>

export default Vue.extend({
  name: 'FilterString',
  props: {
    value: { type: Object as PropType<Filter>, default: () => ({}) },
  },
  data() {
    return {
      operators: [
        {
          value: FilterOperatorType.CONTAINS,
          text: this.$t('filter.operator.CONTAINS'),
        },
        {
          value: FilterOperatorType.STARTSWITH,
          text: this.$t('filter.operator.STARTSWITH'),
        },
        {
          value: FilterOperatorType.ENDSWITH,
          text: this.$t('filter.operator.ENDSWITH'),
        },
        {
          value: FilterOperatorType.ISNOTNULL,
          text: this.$t('filter.operator.ISNOTNULL'),
        },
        {
          value: FilterOperatorType.ISNULL,
          text: this.$t('filter.operator.ISNULL'),
        },
      ],
    }
  },
  computed: {
    filter(): Partial<Filter> {
      // to avoid confusion with this.value.value
      return this.value || {}
    },
    showInput(): boolean {
      return ![
        FilterOperatorType.ISNOTNULL,
        FilterOperatorType.ISNULL,
        // @ts-ignore
      ].includes(this.filter.operator)
    },
    debounceSetValue(): Debouncer {
      return debounce((newValue: Filter['value']) => {
        const attribute = this.filter.attribute
        const operator = this.filter.operator
        this.setFilter({ attribute, operator, value: newValue })
      }, 500)
    },
    operator: {
      get(): Filter['operator'] {
        return this.filter.operator
      },
      set(operator: Filter['operator']) {
        const attribute = this.filter.attribute
        const value = this.filter.value

        const newFilter =
          operator === 'ISNULL' || operator === 'ISNOTNULL'
            ? { attribute, operator }
            : { attribute, operator, value }

        this.setFilter(newFilter)
      },
    },
  },
  methods: {
    setFilter(newFilter: Filter) {
      this.$emit('input', { ...newFilter })
    },
  },
})
</script>
