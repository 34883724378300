import moment, { Moment } from 'moment-timezone'

export const isDateInPast = (
  date: string | number | Moment | Date,
  targetDate?: string | number | Moment | Date,
  granularity?: moment.unitOfTime.StartOf,
) => {
  const target = targetDate ?? moment().format()

  return moment.utc(date).isBefore(target, granularity)
}
