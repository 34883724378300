import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'taskType',
  'priority',
  'assignedUser',
  'assignedUser.jobTitle',
  'startedOn',
  {
    attributeName: 'endedOn',
    style: {
      width: 1,
    },
  },
  {
    allowSorting: false,
    allowFilters: false,
    headerText:
      'tt-entity-design.checkpoint-tour-sessions.attrs.actual-duration',
    component: {
      is: 'ActualDurationInMinuteAttr',
    },
  },
  'plannedDurationInMinutes',
  'report',
  'client',
  'location',
  {
    attributeName: 'taskType.billable',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'taskType.billable',
      },
    },
  },
] as ColumnInputDefinition[]
