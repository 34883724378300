<template>
  <div
    :style="{
      height: height + margin * 2 + 'px',
      margin: margin + 'px',
      marginBottom: 0,
      marginLeft: '25px',
      marginRight: '25px',
      class: 'level1',
      overflow: 'hidden',
    }"
  >
    <img
      :src="require('./img/header.png')"
      style="width: 100%"
      :style="{
        height: headerHeight + 'px',
        opacity: $vuetify.theme.dark ? 0.05 : 1,
      }"
    />
    <div class="elevation-1" style="display: block; opacity: 0.9; padding: 4px">
      <v-row
        v-for="(row, rowIndex) in rows"
        :key="`ROW_${rowIndex}`"
        :style="{
          height: rowHeight + 'px',
          margin: 0,
          padding: 0,
          marginBottom: marginPx,
        }"
      >
        <v-col
          v-for="(col, colIndex) in row.columns"
          :key="`ROW_${rowIndex}_COL_${colIndex}`"
          class="no-gutters align-stretch"
          :style="{
            margin: 0,
            padding: 0,
            borderRadius: `4px`,
            height: rowHeight + 'px',
            marginLeft: marginPx,
            ...boxStyle,
          }"
        >
          <slot :row-index="rowIndex" col-index="colIndex" :col="col"></slot>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    data: Array,
    height: Number,
    width: Number || String,
    border: Boolean,
    headerHeight: {
      type: Number,
      default: 15,
    },
    boxStyle: {
      type: Object,
      default() {
        return {
          border: '0px solid #cccccc70',
          backgroundColor: '#77777708',
          margin: '2px',
        }
      },
    },
    margin: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    borderStyle(): '1px solid #eee;' | '' {
      if (this.border) {
        return '1px solid #eee;'
      }
      return ''
    },
    rows(): unknown[] {
      if (!this.data) {
        return [
          {
            components: [
              {
                is: 'PieChartWidget',
              },
            ],
          },
          {
            components: [
              {
                is: 'ColumnChartWidget',
              },
            ],
          },
        ]
      }
      return this.data.filter((item, index) => {
        return index < 2
      })
    },
    rowHeight(): number {
      const paddingHeight = (this.rows.length + 1) * this.margin
      return (this.height - 20 - paddingHeight) / this.rows.length
    },
    marginPx(): string {
      return this.margin * 2 + 'px'
    },
  },
})
</script>
