import { Dimension, Measure } from '@/tt-widget-components'
import { JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { JSONSchema7Object } from '@tracktik/tt-json-schema-form/lib/src/JSONSchema7'
import { MeasureFormat } from '../components/measure-types'
import { WidgetSchema } from '@/tt-widget-factory'

export enum DataSetWidgetSchemaConfigValue {
  NONE = 'NONE',
  ONE_ONLY = 'ONE_ONLY',
  TWO = 'TWO',
  ONE_PLUS = 'ONE_PLUS',
}

export interface DataSetWidgetSchemaConfig {
  count: DataSetWidgetSchemaConfigValue
  measure: DataSetWidgetSchemaConfigValue
  dimension: DataSetWidgetSchemaConfigValue
  toolbar: boolean
}

type JSONSchema7DefinitionProperty = {
  [key: string]: JSONSchema7
}

export type DataSetWidgetSchema = {
  required: string[]
} & WidgetSchema

const defaultDimension: Readonly<Dimension> & JSONSchema7Object = {
  alias: null,
  modifier: null,
  attribute: null,
}

const defaultMeasure: Readonly<Measure> & JSONSchema7Object = {
  attribute: 'id',
  operation: 'COUNT',
  alias: 'Count',
  format: MeasureFormat.ROUND_0,
}

/**
 * Data Set Widget Schema Generator.
 * Tool to help generate JSON schema for data set widgets.
 */
export class DataSetWidgetSchemaGenerator {
  private _schema: JSONSchema7 = {}
  private _dataSet: JSONSchema7 = {
    required: [],
    properties: {
      contextFilters: {
        $ref: '#/definitions/ContextFiltersMap',
      },
    },
  }

  get schema(): WidgetSchema {
    return this._schema as WidgetSchema
  }

  constructor(is: string, title: string, config: DataSetWidgetSchemaConfig) {
    this._schema = DataSetWidgetSchemaGenerator.getDefaultSchema(is, title)
    this.setDataSetDimension(config.dimension)
    this.setDataSetMeasure(config.measure)
    this.setSchemaDataSet(config.count)
    if (config.toolbar) {
      this._schema.properties.toolbar = {
        $ref: '#/definitions/AggregationToolbar',
      }
    }
  }

  public addDataSetPropertie(properties: Record<string, JSONSchema7>): void {
    this._schema.properties.dataSet.properties = {
      ...this._schema.properties.dataSet.properties,
      ...properties,
    }
  }

  /**
   * Add the definitions
   * @param definitions
   */
  public addDefinitions(definitions: { [key: string]: JSONSchema7 }) {
    this._schema.definitions = { ...this._schema.definitions, ...definitions }
  }

  public addToRequired(property: string): void {
    this._schema.required.push(property)
  }

  private static getDefaultSchema(
    is: string,
    title: string,
  ): DataSetWidgetSchema {
    return {
      type: 'object',
      properties: {
        is: {
          enum: [is],
        },
        title: {
          type: 'string',
        },
        uid: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
      },
      title,
      required: ['is', 'title'],
    }
  }

  private setDataSetDimension(dimension: DataSetWidgetSchemaConfigValue) {
    if (dimension === DataSetWidgetSchemaConfigValue.NONE) {
      return
    }

    if (dimension === DataSetWidgetSchemaConfigValue.ONE_ONLY) {
      this._dataSet.required.push('dimension')
      this._dataSet.properties['dimension'] = {
        allOf: [{ $ref: '#/definitions/Dimension' }],
        default: defaultDimension,
      }

      return
    }

    const dimensionProperties: JSONSchema7 = {
      type: 'array',
      minItems: dimension === DataSetWidgetSchemaConfigValue.TWO ? 2 : 1,
      default: [defaultDimension],
      items: { $ref: '#/definitions/Dimension' },
    }

    if (dimension === DataSetWidgetSchemaConfigValue.TWO) {
      dimensionProperties['maxItems'] = 2
    }
    this._dataSet.required.push('dimensions')
    this._dataSet.properties['dimensions'] = dimensionProperties
  }

  private setDataSetMeasure(measure: DataSetWidgetSchemaConfigValue) {
    if (measure === DataSetWidgetSchemaConfigValue.NONE) {
      return
    }

    if (measure === DataSetWidgetSchemaConfigValue.ONE_ONLY) {
      this._dataSet.required.push('measure')
      this._dataSet.properties['measure'] = {
        allOf: [{ $ref: '#/definitions/Measure' }],
        default: defaultMeasure,
      }

      return
    }

    this._dataSet.required.push('measures')
    this._dataSet.properties['measures'] = {
      title: 'Measure (Count, Sum e.t.c)',
      type: 'array',
      minItems: measure === DataSetWidgetSchemaConfigValue.TWO ? 2 : 1,
      default: [defaultMeasure],
      items: {
        $ref: '#/definitions/Measure',
      },
    }
  }

  private setSchemaDataSet(count: DataSetWidgetSchemaConfigValue) {
    if (count == DataSetWidgetSchemaConfigValue.ONE_ONLY) {
      this._schema.required.push('dataSet')
      this._schema.properties.dataSet = {
        title: 'Data source',
        type: 'object',
        //@ts-ignore -- Type 'false' has no properties in common with type 'JSONSchema7'
        additionalProperties: false,
        required: ['resource', ...this._dataSet.required],
        properties: {
          resource: {
            $ref: '#/definitions/Resource',
          },
          filters: {
            $ref: '#/definitions/FilterGroup',
          },
          ...this._dataSet.properties,
        },
        default: {},
      }

      return
    }

    this._schema.required.push('dataSets')
    this._schema.properties.dataSets = {
      title: 'Data Set',
      type: 'array',
      items: {
        serieConfig: this._dataSet,
      } as JSONSchema7,
    }
  }
  /**
   * Set additional schema properties
   * @param additionalProperties
   */
  public setSchemaAdditionalProperties(
    additionalProperties: JSONSchema7DefinitionProperty,
  ) {
    this._schema.properties = {
      ...this._schema.properties,
      ...additionalProperties,
    }
  }
}
