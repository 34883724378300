// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowStatusesTile from './WorkflowStatusesTile.vue'
import WorkflowStatusesColumns from './WorkflowStatusesColumns'
import WorkflowStatusesDetail from './WorkflowStatusesDetail.vue'
import WorkflowStatusesReference from './WorkflowStatusesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowStatusesTile', WorkflowStatusesTile)
    Vue.component('WorkflowStatusesDetail', WorkflowStatusesDetail)
    Vue.component('WorkflowStatusesReference', WorkflowStatusesReference)

    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowStatusesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
