// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerTimeKeepingSettingsTile from './LoneWorkerTimeKeepingSettingsTile.vue'
import LoneWorkerTimeKeepingSettingsColumns from './LoneWorkerTimeKeepingSettingsColumns'
import LoneWorkerTimeKeepingSettingsDetail from './LoneWorkerTimeKeepingSettingsDetail.vue'
import LoneWorkerTimeKeepingSettingsMetaCard from './LoneWorkerTimeKeepingSettingsMetaCard.vue'
import LoneWorkerTimeKeepingSettingsReference from './LoneWorkerTimeKeepingSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'LoneWorkerTimeKeepingSettingsTile',
      LoneWorkerTimeKeepingSettingsTile,
    )
    Vue.component(
      'LoneWorkerTimeKeepingSettingsDetail',
      LoneWorkerTimeKeepingSettingsDetail,
    )
    Vue.component(
      'LoneWorkerTimeKeepingSettingsMetaCard',
      LoneWorkerTimeKeepingSettingsMetaCard,
    )
    Vue.component(
      'LoneWorkerTimeKeepingSettingsReference',
      LoneWorkerTimeKeepingSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_TIME_KEEPING_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerTimeKeepingSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_TIME_KEEPING_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerTimeKeepingSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_TIME_KEEPING_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerTimeKeepingSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_TIME_KEEPING_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerTimeKeepingSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_TIME_KEEPING_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerTimeKeepingSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
