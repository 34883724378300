import { SessionRegion } from '@tracktik/tt-authentication'

const selectedResults =
  (values: string[]) =>
  ({ id }) =>
    values.includes(id)

const searchResults =
  (search: string) =>
  ({ name }) =>
    name.toLowerCase().includes(search)

export const filterRegions = (
  regions: SessionRegion[],
  showSelected: boolean,
  values: string[],
  search: string,
): SessionRegion[] | null => {
  if (showSelected && values.length && search) {
    return regions.filter(selectedResults(values)).filter(searchResults(search))
  } else if (showSelected && values.length) {
    return regions.filter(selectedResults(values))
  } else if (search) {
    return regions.filter(searchResults(search))
  } else {
    return regions
  }
}
