<template>
  <v-alert dense color="orange" text type="info" class="ma-0">
    <slot></slot>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'AssignLeavePolicyItemsWarning',
})
</script>
