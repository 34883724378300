<template>
  <ResizeObserver class="overflow--container" @resize="saveContainerSize">
    <slot />

    <ResizeObserver class="overflow--hidden-slot" @resize="saveContentWidth">
      <slot />
    </ResizeObserver>
  </ResizeObserver>
</template>

<script lang="ts">
import Vue from 'vue'
import ResizeObserver from '@/tt-widget-components/components/ResizeObserver/ResizeObserver.vue'
import { ResizeEventPayload } from './ResizeObserver/ResizeObserver'

export default Vue.extend({
  name: 'OverflowObserver',
  components: { ResizeObserver },
  data: () => ({ containerWidth: 0, contentWidth: 0 }),
  computed: {
    isOverflowing(): boolean {
      return this.containerWidth < this.contentWidth
    },
  },
  watch: {
    isOverflowing() {
      this.$emit('overflow', this.isOverflowing)
    },
  },
  methods: {
    saveContainerSize(container: ResizeEventPayload) {
      this.containerWidth = container.clientWidth
    },
    saveContentWidth(content: ResizeEventPayload) {
      this.contentWidth = content.clientWidth
    },
  },
})
</script>

<style scoped>
.overflow--hidden-slot {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.overflow--container {
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
