<template>
  <v-chip
    :color="serviceLevelImpactColor.background"
    :text-color="serviceLevelImpactColor.text"
    :class="{ 'sli-percentage-in-row': inDetails }"
  >
    <span>{{ serviceLevelImpactPercentage }}%</span>
  </v-chip>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import round from 'lodash/round'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { UiChipColor } from '../types'
import { serviceLevelImpactColorPicker } from '../utils'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ServiceLevelImpactPercentage',
  inject: ['getItemHook'],
  props: {
    inDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    serviceLevelImpactPercentage(): number {
      const SLI = this.getItemHook().get('serviceLevelImpact')
      return SLI ? round(SLI * 100) : 0
    },
    serviceLevelImpactColor(): UiChipColor {
      return serviceLevelImpactColorPicker(this.serviceLevelImpactPercentage)
    },
  },
  created() {
    this.getItemHook().addAttribute('serviceLevelImpact')
  },
})
</script>
<style scoped>
.sli-percentage-in-row {
  height: 20px !important;
  padding: 0 7px;
  font-size: 12px !important;
}
</style>
