<template>
  <v-card
    flat
    tile
    class="d-flex flex-column justify-center align-center h-100 pt-10 px-10"
  >
    <div class="d-flex flex-row justify-center align-center">
      <div class="d-flex flex-column align-center">
        <v-btn icon :small="small" @click="addHour">
          <v-icon :large="!small" color="ttPrimary" v-text="'mdi-chevron-up'" />
        </v-btn>
        <v-text-field
          v-model.number="hourModel"
          :min="minHour"
          :max="maxHour"
          type="number"
          hide-details
          :color="color"
          class="hide-spin-buttons time-inputs"
          :class="!small && 'time-picker--large'"
          dense
        />
        <v-btn icon :small="small" @click="subtractHour">
          <v-icon :large="!small" :color="color" v-text="'mdi-chevron-down'" />
        </v-btn>
      </div>
      <span class="time-colon mx-2" :class="!small && 'time-picker--large'"
        >:</span
      >
      <div class="d-flex flex-column align-center">
        <v-btn icon :small="small" @click="addMinute">
          <v-icon :large="!small" :color="color" v-text="'mdi-chevron-up'" />
        </v-btn>
        <v-text-field
          v-model.number="minuteModel"
          :min="minMinute"
          :max="maxMinute"
          type="number"
          hide-details
          :color="color"
          class="hide-spin-buttons time-inputs"
          :class="!small && 'time-picker--large'"
          dense
        />
        <v-btn icon :small="small" @click="subtractMinute">
          <v-icon :large="!small" :color="color" v-text="'mdi-chevron-down'" />
        </v-btn>
      </div>
      <span v-if="displaySeconds" class="time-colon mx-2">:</span>
      <div v-if="displaySeconds" class="d-flex flex-column align-center">
        <v-btn icon :small="small" @click="addSecond">
          <v-icon :large="!small" :color="color" v-text="'mdi-chevron-up'" />
        </v-btn>
        <v-text-field
          v-model.number="secondModel"
          :min="minSecond"
          :max="maxSecond"
          type="number"
          hide-details
          :color="color"
          class="hide-spin-buttons time-inputs"
          dense
        />
        <v-btn icon :small="small" @click="subtractSecond">
          <v-icon :large="!small" :color="color" v-text="'mdi-chevron-down'" />
        </v-btn>
      </div>
      <div v-if="!is24hPreference" class="d-flex flex-column align-center">
        <v-btn
          :style="{ opacity: isAM ? '1' : '0.5' }"
          :disabled="isInvalidTime"
          plain
          height="22"
          :ripple="false"
          :color="color"
          @click="setAM(true)"
          v-text="$t(amLabel)"
        />
        <v-btn
          :style="{ opacity: isAM ? '0.5' : '1' }"
          :disabled="isInvalidTime"
          plain
          height="22"
          :ripple="false"
          :color="color"
          @click="setAM(false)"
          v-text="$t(pmLabel)"
        />
      </div>
    </div>
    <div class="invalid-time-error">
      <span v-if="isInvalidTime" v-text="$t('common.invalid-time')" />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { convertToTimeString, enforceLeadingZero } from '@/helpers/dates/time'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'

export default Vue.extend({
  name: 'TimePicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    is24hPreference: {
      type: Boolean,
      default: true,
    },
    displaySeconds: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hour: 0, // always 24h format
      minute: 0,
      second: 0,
    }
  },
  computed: {
    isAM(): boolean {
      return this.hour >= 0 && this.hour <= 11
    },
    amLabel(): string {
      return 'tt-etntity-forms.time-picker.am'
    },
    pmLabel(): string {
      return 'tt-etntity-forms.time-picker.pm'
    },
    hourModel: {
      get(): string {
        if (this.isInvalidHour) return String(this.hour)

        const userFormattedHour = this.formatHourToUserPref(this.hour)

        return enforceLeadingZero(userFormattedHour)
      },
      set(value: string) {
        this.setHour(value)

        this.emitDebouncedTimeInput()
      },
    },
    minuteModel: {
      get(): string {
        return enforceLeadingZero(this.minute)
      },
      set(value: string) {
        this.minute = parseInt(value, 10) || 0
        this.emitDebouncedTimeInput()
      },
    },
    secondModel: {
      get(): string {
        return enforceLeadingZero(this.second)
      },
      set(value: string) {
        this.second = parseInt(value, 10) || 0
        this.emitDebouncedTimeInput()
      },
    },
    fullTimeModel(): string {
      return convertToTimeString(this.hour, this.minute, this.second)
    },
    maxHour(): number {
      return this.is24hPreference ? 23 : 12
    },
    maxMinute(): number {
      return 59
    },
    maxSecond(): number {
      return 59
    },
    minHour(): number {
      return this.is24hPreference ? 0 : 1
    },
    minMinute(): number {
      return 0
    },
    minSecond(): number {
      return 0
    },
    emitDebouncedTimeInput(): DebouncedFunc<() => Promise<void>> {
      return debounce(() => {
        this.$emit('invalid-time', this.isInvalidTime)
        this.$emit('input', this.fullTimeModel)
      }, 250)
    },
    isInvalidHour(): boolean {
      return this.hour < 0 || this.hour > 23
    },
    isInvalidMinute(): boolean {
      return this.minute < 0 || this.minute > 59
    },
    isInvalidSecond(): boolean {
      return this.second < 0 || this.second > 59
    },
    isInvalidTime(): boolean {
      return this.isInvalidHour || this.isInvalidMinute || this.isInvalidSecond
    },
  },
  watch: {
    value: {
      handler(value: string) {
        this.minute = this.extractMinutes(value)
        this.hour = this.extractHours(value)
        this.second = this.extractSeconds(value)
      },
      immediate: true,
    },
  },
  methods: {
    setHour(newHour: string | number) {
      const newHourNumber = Number(newHour)

      this.hour = newHourNumber
    },
    formatHourToUserPref(hour: number): number {
      const formatTo12 = () => hour % 12 || 12
      return this.is24hPreference ? hour : formatTo12()
    },
    extractSeconds(value: string) {
      if (!value) {
        return 0
      } else {
        const [hour, minute, second] = value.split(':')
        return parseInt(second, 10)
      }
    },
    extractMinutes(value: string) {
      if (!value) {
        return 0
      } else {
        const [hour, minute, second] = value.split(':')
        return parseInt(minute, 10)
      }
    },
    extractHours(value: string) {
      if (!value) {
        return 0
      } else {
        const [hour, minute, second] = value.split(':')
        return parseInt(hour, 10)
      }
    },
    addHour() {
      const newHour = (this.hour + 1) % 24

      this.setHour(newHour)
      this.emitDebouncedTimeInput()
    },
    addMinute() {
      this.minute = (this.minute + 1) % (this.maxMinute + 1)
      this.emitDebouncedTimeInput()
    },
    addSecond() {
      this.second = (this.second + 1) % (this.maxSecond + 1)
      this.emitDebouncedTimeInput()
    },
    setAM(isAM: boolean) {
      // if already set, do nothing
      if (this.isAM === isAM) return

      const addition = isAM ? -12 : 12

      this.hourModel = String(this.hour + addition)

      this.emitDebouncedTimeInput()
    },
    subtractHour() {
      const currentHour = this.hour <= 0 ? 24 : this.hour

      this.setHour(currentHour - 1)

      this.emitDebouncedTimeInput()
    },
    subtractMinute() {
      this.minute =
        this.minute > this.minMinute ? this.minute - 1 : this.maxMinute

      this.emitDebouncedTimeInput()
    },
    subtractSecond() {
      this.second =
        this.second > this.minSecond ? this.second - 1 : this.maxSecond

      this.emitDebouncedTimeInput()
    },
  },
})
</script>
<style scoped>
.hide-spin-buttons >>> input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input[type='number'] {
  -moz-appearance: textfield;
}

.small {
  font-size: 12px;
}

.time-picker--large,
.time-picker--large >>> input {
  font-size: 32px;
}

.time-colon {
  font-weight: 500;
  color: var(--v-ttPrimary-base);
}

.time-inputs >>> input {
  text-align: center;
  width: 50px;
  color: var(--v-ttPrimary-base);
  font-weight: 500;
}

.invalid-time-error {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--v-error-base);
  font-weight: 500;
}
</style>
