// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowStatusTransitionsTile from './WorkflowStatusTransitionsTile.vue'
import WorkflowStatusTransitionsColumns from './WorkflowStatusTransitionsColumns'
import WorkflowStatusTransitionsDetail from './WorkflowStatusTransitionsDetail.vue'
import WorkflowStatusTransitionsReference from './WorkflowStatusTransitionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'WorkflowStatusTransitionsTile',
      WorkflowStatusTransitionsTile,
    )
    Vue.component(
      'WorkflowStatusTransitionsDetail',
      WorkflowStatusTransitionsDetail,
    )
    Vue.component(
      'WorkflowStatusTransitionsReference',
      WorkflowStatusTransitionsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowStatusTransitionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
