<template>
  <div class="fill-height d-flex flex-column">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import LayoutManager from '@/tt-app-layout/LayoutManager'
import { LayoutComponents, LayoutWindowEvent } from '@/tt-app-layout'

type VueWithLayoutManager = VueConstructor<
  Vue & { layoutManager: LayoutManager }
>

const { sideMenu, topBar } = LayoutComponents

/**
 * When mounted : will remove global layout UI, and force light mode.
 * When destroyed : will reset to previous settings.
 */
export default (Vue as VueWithLayoutManager).extend({
  name: 'LayoutRemover',
  inject: ['layoutManager'],
  data() {
    return {
      darkModeBackup: false,
      sideMenuBackup: false,
      topBarBackup: false,
    }
  },
  beforeDestroy() {
    this.revertLayout()
  },
  created() {
    this.prepareLayout()
  },
  methods: {
    prepareLayout(): void {
      this.darkModeBackup = this.$vuetify.theme.dark
      this.sideMenuBackup = this.layoutManager.getComponentState(sideMenu).state
      this.topBarBackup = this.layoutManager.getComponentState(topBar).state

      this.$vuetify.theme.dark = false
      this.layoutManager.setComponentState(sideMenu, false)
      this.layoutManager.setComponentState(topBar, false)
    },
    revertLayout(): void {
      this.$vuetify.theme.dark = this.darkModeBackup
      this.layoutManager.setComponentState(sideMenu, this.sideMenuBackup)
      this.layoutManager.setComponentState(topBar, this.topBarBackup)
    },
  },
})
</script>
