<template>
  <CollapsibleHeader>
    <template #title>
      <span class="pl-4 pr-6">
        {{ $t('components.whereQL_widget_form_input.title') }}
        <v-badge v-if="model" :color="'green'" dot inline />
      </span>
    </template>
    <template #default="{ opened }">
      <v-card flat class="pa-4 transparent">
        <InfoBox>
          {{ $t('components.whereQL_widget_form_input.user_info') }} <br />
          {{ $t('components.whereQL_widget_form_input.sample') }}
          <span style="font-family: monospace; color: green">
            name='Simon' or name='Julie'
          </span>
        </InfoBox>
        <WhereQLEditor :key="opened" v-model="model" :resource="resource" />
      </v-card>
    </template>
  </CollapsibleHeader>
</template>
<script lang="ts">
import WhereQLEditor from '@/tt-tql-inputs/src/components/WhereQLEditor.vue'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import { VueConstructor } from 'vue'
import { FormHookProvider, ResourceProvider } from '..'

export default (
  BaseInput as VueConstructor<
    InstanceType<typeof BaseInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'WhereQLWidgetFormInput',
  components: { WhereQLEditor },
  inject: ['formHook', 'resourceProvider'],
  computed: {
    resource(): any {
      return this.resourceProvider.resource
    },
  },
})
</script>
