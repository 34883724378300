<template>
  <div>
    <div v-show="!overflow">
      <span ref="div" v-resize="onResize">
        <span ref="start"></span>
        <slot></slot>
        <span ref="end"></span>
      </span>
    </div>
    <div v-if="overflow">
      <slot name="overflow"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'OverflowBox',
  props: {
    maxHeight: {
      type: Number,
    },
  },
  data() {
    return {
      size: null,
      container: null,
      overflow: false,
    }
  },
  watch: {
    size: {
      handler(val) {
        if (!this.$refs.start || !this.$refs.end) {
          return
        }
        // Max height strategy
        if (this.maxHeight) {
          this.overflow = val && val.height > this.maxHeight
        } else {
          // Pointers height strategy
          this.overflow =
            (this.$refs.start as Element)?.getBoundingClientRect()?.toJSON()
              .top <
            (this.$refs.end as Element)?.getBoundingClientRect()?.toJSON().top
        }
      },
      deep: true,
    },
  },
  mounted() {
    // We run a parallel check on init
    this.$nextTick(() => {
      setTimeout(() => {
        this.checkOverflow()
      }, 100)
    })
  },
  methods: {
    checkOverflow() {
      if (!this.$refs.div) {
        return
      }
      this.overflow = false
      this.$nextTick(() => {
        if (!this.$refs.div) {
          return
        }
        this.size = (this.$refs.div as Element)
          ?.getBoundingClientRect()
          ?.toJSON()
      })
    },
    onResize() {
      this.checkOverflow()
    },
  },
})
</script>
