import { Timezone, isValidTimezone } from '@/helpers/dates/timezones'

/**
 * This regex matches any string that ends with text enclosed in square brackets.
 *
 * - The .* part matches any characters, and the \[(.*?)\] part matches any characters enclosed in square brackets.
 * - The ? makes the match non-greedy, so it will grab the shortest match possible.
 * - The $ ensures that the pattern must be at the end of the string.
 *
 * Examples:
 * - `2020-01-01 00:00:00|2020-01-31 00:00:00[UTC]`
 * - `2020-01-01 00:00:00[Europe/Paris]`
 *
 */
const DATETIME_TIMEZONE_REGEX = new RegExp(/(.*)\[(.*?)\]$/)

type ParsedDatetime = {
  datetime: string
  timezone: Timezone | ''
}

/**
 * Parses a Temporal filter value and extracts the `datetime` and `timezone`.
 */
export const extractTimezone = (
  temporalFilterValue: string,
): ParsedDatetime => {
  const matches = temporalFilterValue.match(DATETIME_TIMEZONE_REGEX)

  if (!matches) return { datetime: temporalFilterValue, timezone: '' }

  const datetime = matches[1]
  const timezone = matches[2]

  if (!isValidTimezone(timezone)) {
    console.warn(`Extracted timezone "${timezone}" is invalid.`)
    return { datetime, timezone: '' }
  }

  return { datetime, timezone }
}

/**
 * Add IANA timezone name to a Temporal filter value.
 *
 * Examples:
 * - `2020-01-01 00:00:00` -> `2020-01-01 00:00:00[UTC]`
 * - `2020-01-01 00:00:00|2020-01-31 00:00:00` -> `2020-01-01 00:00:00|2020-01-31 00:00:00[UTC]`
 */
export const addTimezone = (datetime: string, timezone?: Timezone) =>
  timezone ? `${datetime}[${timezone}]` : datetime
