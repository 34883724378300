import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { EmployeeOperationsCustomComponents } from './types'
import { LeavePolicyItemsComponents } from '@/tt-entity-design/src/components/leave-policy-items/types'

export const SettingsTabColumns: ColumnInputDefinition[] = [
  'customId',
  'leaveType',
  {
    attributeName: 'accrualRate',
    headerText:
      'tt-entity-design.leave-management.leave-policy-items.is-accrued',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsAccruedColumn,
    },
  },
  'entitlementUnit',
  {
    headerText:
      'tt-entity-design.leave-management.employee.settings.balance.available',
    component: {
      is: EmployeeOperationsCustomComponents.AvailableDays,
    },
  },
  {
    headerText:
      'tt-entity-design.leave-management.employee.settings.balance.used',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsValueField,
      props: {
        entitlementValueName: 'extensions.balance.used',
      },
    },
  },
]
