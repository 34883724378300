import { PluginFunction } from 'vue'

const RunsheetGroupCreateWizard = () =>
  import(
    /* webpackChunkName: "RunsheetGroupCreateWizard" */
    './components/RunsheetGroupCreateWizard.vue'
  )

const RunsheetGroupsView = () =>
  import(
    /* webpackChunkName: "RunsheetGroupsView" */
    './views/RunsheetGroupsView.vue'
  )

const RunsheetGroupList = () =>
  import(
    /* webpackChunkName: "RunsheetGroupsList" */
    './components/RunsheetGroupList.vue'
  )

export { RunsheetGroupsView }

export const MobileRunsheetGroupsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('RunsheetGroupCreateWizard', RunsheetGroupCreateWizard)
  Vue.component('RunsheetGroupsView', RunsheetGroupsView)
  Vue.component('RunsheetGroupList', RunsheetGroupList)
}
