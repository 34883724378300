import { Resources } from '@/tt-entity-design/src/types'

export const {
  MOBILE_RUNSHEETS,
  MOBILE_SCHEDULES,
  MOBILE_RUNSHEET_OCCURRENCES,
  MOBILE_SCHEDULE_OCCURRENCES,
  MOBILE_RUNSHEET_SESSIONS,
  MOBILE_RUNSHEET_GROUPS,
} = Resources
