import { FormatterCallbackInterface } from './types'

export const asPayRateHourly: FormatterCallbackInterface = (
  value,
  userPref,
) => {
  return `${value} / h`
}

export default {
  asPayRateHourly,
}
