<template>
  <tt-attr
    :class="modifierClass"
    :name="name"
    :prefix="prefix"
    suffix=" h"
    ignore-attribute-view
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OpenShiftRequestsAttrWeeklyHoursRemaining',
  inject: ['getItemHook'],
  data() {
    return {
      name: 'extensions.weeklyHoursRemaining.hours',
    }
  },
  computed: {
    modifierClass(): string {
      return this.value >= 0 ? 'green--text' : 'error--text'
    },
    value(): number {
      return this.getItemHook().get(this.name)
    },
    prefix(): string | null {
      return this.value > 0 ? '+ ' : null
    },
  },
  created() {
    this.getItemHook().addAttribute('extensions.weeklyHoursRemaining')
  },
})
</script>
