<template>
  <v-btn
    class="days-input"
    plain
    outlined
    @click.stop="dispatchAdjustmentsDialog"
  >
    <tt-attr name="extensions.balance.available">
      {{ availableBalanceRounded }}
    </tt-attr>
  </v-btn>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  EmployeeOperationsCustomComponents,
  EmployeesSettingsProvider,
  AdjustBalanceInfo,
  BalanceUnit,
} from './types'
import round from 'lodash/round'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & EmployeesSettingsProvider>
).extend({
  name: 'AvailableDays',
  inject: ['getItemHook', 'leaveManagementEmployeeInfo'],
  computed: {
    isAccrued(): boolean {
      return !!this.getItemHook().get('accrualRate')
    },
    availableBalance(): number {
      return this.getItemHook().get('extensions.balance.available')
    },
    availableBalanceRounded(): number {
      if (Number.isInteger(this.availableBalance)) return this.availableBalance
      return round(this.availableBalance, 2)
    },
    entitlementUnit(): BalanceUnit {
      return this.getItemHook().get('entitlementUnit').toLowerCase()
    },
    leaveTypeId(): number {
      return this.getItemHook().get('leaveType.id')
    },
    leavePolicyId(): number {
      return this.leaveManagementEmployeeInfo.leavePolicy
    },
    employeeId(): number {
      return this.leaveManagementEmployeeInfo.employee
    },
    leaveTypeName(): string {
      return this.getItemHook().get('leaveType.name')
    },
    payHoursPerDay(): number {
      return this.getItemHook().get('extensions.balance.payHoursPerDay')
    },
  },
  created() {
    this.getItemHook().addAttribute('leaveType')
    this.getItemHook().addAttribute('accrualRate')
  },
  methods: {
    dispatchAdjustmentsDialog() {
      const adjustBalanceInfo: AdjustBalanceInfo = {
        availableTotalBalance: this.availableBalanceRounded,
        entitlementUnit: this.entitlementUnit,
        leaveTypeId: this.leaveTypeId,
        leavePolicyId: this.leavePolicyId,
        employeeId: this.employeeId,
        leaveTypeName: this.leaveTypeName,
        isAccrued: this.isAccrued,
        payHoursPerDay: this.payHoursPerDay,
      }
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: EmployeeOperationsCustomComponents.AdjustBalanceDialog,
        props: { adjustBalanceInfo },
        width: 400,
      })
    },
  },
})
</script>
<style scoped>
.days-input {
  border-color: rgba(0, 0, 0, 0.3);
}
.days-input:hover {
  border-color: black;
}
.days-input >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
