// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileDevicesTile from './MobileDevicesTile.vue'
import MobileDevicesColumns from './MobileDevicesColumns'
import MobileDevicesDetail from './MobileDevicesDetail.vue'
import MobileDevicesMetaCard from './MobileDevicesMetaCard.vue'
import MobileDevicesReference from './MobileDevicesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileDevicesTile', MobileDevicesTile)
    Vue.component('MobileDevicesDetail', MobileDevicesDetail)
    Vue.component('MobileDevicesMetaCard', MobileDevicesMetaCard)
    Vue.component('MobileDevicesReference', MobileDevicesReference)

    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileDevicesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileDevicesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileDevicesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileDevicesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_DEVICES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileDevicesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
