<template>
  <ActionBarPreset :actions="actions" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider } from '@/tt-app-layout'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'MobileRunsheetOccurrencesActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  computed: {
    actions(): ViewAction[] {
      const openFormAction = async (actionName: string) => {
        const payload = await this.getItemHook().getActionFormAsPage(actionName)

        this.activeWindow.next(payload)
      }

      return [
        {
          icon: 'mdi-arrow-right-drop-circle-outline',
          title: ResourceTranslator.translateActionLabel(
            Resources.MOBILE_RUNSHEET_SESSIONS,
            'continue-session',
          ),
          onClick: () => openFormAction('continue-session'),
        },
        {
          icon: 'mdi-close-circle-outline',
          title: ResourceTranslator.translateActionLabel(
            Resources.MOBILE_RUNSHEET_SESSIONS,
            'close',
          ),
          onClick: () => openFormAction('cancel'),
        },
      ]
    },
  },
})
</script>
