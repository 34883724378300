<template>
  <div class="d-flex flex-column">
    <WidgetTitle :hook="hook" :container="container" />

    <EntityToolbar
      v-if="hook.toolbarManager && hook.showToolbar"
      :hook="hook"
      :filter-handlers="filterHandlers"
      @update:filter="$emit('update:filters', $event)"
    >
      <template #after-filters>
        <slot name="toolbar-after-filters" />
      </template>
    </EntityToolbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { FilterHandler } from '@/tt-widget-components'
import { WidgetContainerInterface } from '@/tt-widget-factory'

import CollectionWidgetHook from '../base/CollectionWidgetHook'
import DataSetWidgetHook from '../base/DataSetWidgetHook'
import EntityToolbar from '@/tt-widget-entity-flow/components/EntityToolbar.vue'
import TQLQueryWidgetHook from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";

export default Vue.extend({
  name: 'WidgetTitleToolbar',
  components: { EntityToolbar },
  props: {
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: null,
    },
    filterHandlers: {
      type: Array as PropType<FilterHandler[]>,
      default: () => [] as FilterHandler[],
    },
    hook: {
      type: Object as
        | PropType<CollectionWidgetHook<any>>
        | PropType<DataSetWidgetHook<any>>
        | PropType<TQLQueryWidgetHook>,
      required: true,
    },
  },
})
</script>
