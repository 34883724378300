import Vue from 'vue'
import { JsonForm, FormOptions } from '@tracktik/tt-json-schema-form'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { getResourceDefinitions } from '@/tt-entity-forms'
import { normalizeKebabCaseName } from '../utils'

export default Vue.extend({
  name: 'ActionForm',
  props: {
    model: {
      type: Object,
    },
    resourceName: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  render(createElement, context) {
    const { resourceName, action } = context.props
    const normalizedResourceName = normalizeKebabCaseName(resourceName)
    const actionName = normalizeKebabCaseName(action)
    const name = `${normalizedResourceName}${actionName}`
    return createElement(JsonForm, {
      ...context,
      props: {
        name,
        userContext: {
          action,
          resourceName,
        },
        formOptions: {
          definitions: getResourceDefinitions(this.$appContext),
          translateFunction: ResourceTranslator.getFormCallback(
            resourceName,
            actionName,
          ),
        } as FormOptions,
        schema:
          this.$appContext.widgetServices.resourceMetaManager.getFormSchema(
            resourceName,
            action,
          ),
        ...context.props,
      },
    })
  },
})
