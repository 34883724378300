<template>
  <div @click.stop="openBreakdownDetails">
    <tt-attr
      name="extensions.balance.total"
      format="asRounded2Decimals"
      :class="accent"
    />
  </div>
</template>
<script lang="ts">
import { LayoutWindowEvent } from '@/tt-app-layout'
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EmployeeInfoProvider } from './types'
export default (
  Vue as VueConstructor<Vue & ItemHookProvider & EmployeeInfoProvider>
).extend({
  name: 'LeavePolicyItemsTotalColumn',
  inject: ['getItemHook', 'getEmployeeInfo'],
  computed: {
    hasAccuralRate(): boolean {
      return !!this.getItemHook().getRawValue('accrualRate')
    },
    accent(): string {
      return this.hasAccuralRate ? 'indigo--text font-weight-bold' : ''
    },
  },
  methods: {
    openBreakdownDetails() {
      if (!this.hasAccuralRate) return
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET, {
        title: this.$t('components.entity-preview.detail'),
        is: 'LeavePolicyItemsAccrualBreakdown',
        props: {
          itemHook: this.getItemHook(),
          employee: this.getEmployeeInfo(),
        },
      })
    },
  },
})
</script>
