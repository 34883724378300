<template>
  <div>
    <div class="d-flex flex-column">
      <span
        class="heading-6 pb-2"
        v-text="
          `${$t('res.mobile-devices.attr.account.label')}: ${account.name}`
        "
      />
      <span
        class="body-2 pb-2"
        v-text="
          $t('tt-entity-design.system-exception-tickets.address.description')
        "
      />
    </div>
    <SystemExceptionTicketsExpansionPanels v-if="hasAddress">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-if="positionAddress.addressLine1"
            readonly
            :value="positionAddress.addressLine1"
            :label="$t('res.addresses.attr.addressLine1.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="positionAddress.addressLine2"
            readonly
            :value="positionAddress.addressLine2"
            :label="$t('res.addresses.attr.addressLine2.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="positionAddress.city"
            class="mr-2"
            readonly
            :value="positionAddress.city"
            :label="$t('res.addresses.attr.city.label')"
            dense
            outlined
            color="ttPrimary"
            clearable
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="positionAddress.state"
            readonly
            :value="positionAddress.state"
            :label="$t('res.addresses.attr.state.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="positionAddress.postalCode"
            class="mr-2"
            readonly
            :value="positionAddress.postalCode"
            :label="$t('res.addresses.attr.postalCode.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="positionAddress.country"
            readonly
            :value="positionAddress.country"
            :label="$t('res.addresses.attr.country.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="positionAddress.latitude"
            readonly
            :value="positionAddress.latitude"
            :label="$t('res.addresses.attr.latitude.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="positionAddress.longitude"
            readonly
            :value="positionAddress.longitude"
            :label="$t('res.addresses.attr.longitude.label')"
            dense
            outlined
          />
        </v-col>
      </v-row>
    </SystemExceptionTicketsExpansionPanels>
    <div v-else>
      <v-icon
        class="mr-2"
        color="ttPrimary"
        small
        v-text="`mdi-alert-circle-outline`"
      />
      <span
        style="color: var(--v-ttPrimary-base)"
        class="body-2"
        v-text="$t('widgets.no_data_to_display')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import type { PropType } from 'vue/types/v3-component-props'
import SystemExceptionTicketsExpansionPanels from './SystemExceptionTicketsExpansionPanels.vue'
import { AccountType, AddressType } from './type'

export default Vue.extend({
  name: 'SystemExceptionTicketsAddressFields',
  components: {
    SystemExceptionTicketsExpansionPanels,
  },
  props: {
    positionAddress: {
      type: Object as PropType<AddressType>,
      default: () => ({} as AddressType),
    },
    account: {
      type: Object as PropType<AccountType>,
      default: () => ({} as AccountType),
    },
    hasAddress: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
