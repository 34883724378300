// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SchedulersTile from './SchedulersTile.vue'
import SchedulersColumns from './SchedulersColumns'
import SchedulersDetail from './SchedulersDetail.vue'
import SchedulersMetaCard from './SchedulersMetaCard.vue'
import SchedulersReference from './SchedulersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SchedulersTile', SchedulersTile)
    Vue.component('SchedulersDetail', SchedulersDetail)
    Vue.component('SchedulersMetaCard', SchedulersMetaCard)
    Vue.component('SchedulersReference', SchedulersReference)

    modularManager.addResourcePreset(
      Resources.SCHEDULERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SchedulersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SchedulersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SchedulersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SchedulersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SchedulersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
