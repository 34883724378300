<template>
  <div class="color-pill blue" @click.stop="openPreview">
    <tt-attr name="checkpointsScannedTotal" suffix=" / " />
    <tt-attr name="checkpointsTotal" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '../../types'

type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'CheckpointsScannedResultsAttr',
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  computed: {
    entityId(): number {
      return this.getItemHook().getRawValue('id')
    },
    isSideSheetChild(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  methods: {
    openPreview() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        resourceName: Resources.CHECKPOINT_TOUR_SESSIONS,
        entityId: this.entityId,
        itemHook: this.itemHook,
        hasToAddPages: this.isSideSheetChild,
        is: 'CheckpointTourSessionsRelationListPreview',
      })
    },
  },
})
</script>
