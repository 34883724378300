// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmploymentProfilesTile from './EmploymentProfilesTile.vue'
import EmploymentProfilesColumns from './EmploymentProfilesColumns'
import EmploymentProfilesDetail from './EmploymentProfilesDetail.vue'
import EmploymentProfilesReference from './EmploymentProfilesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmploymentProfilesTile', EmploymentProfilesTile)
    Vue.component('EmploymentProfilesDetail', EmploymentProfilesDetail)
    Vue.component('EmploymentProfilesReference', EmploymentProfilesReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_PROFILES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmploymentProfilesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_PROFILES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmploymentProfilesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_PROFILES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmploymentProfilesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_PROFILES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmploymentProfilesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
