// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EntityLabelsTile from './EntityLabelsTile.vue'
import EntityLabelsColumns from './EntityLabelsColumns'
import EntityLabelsDetail from './EntityLabelsDetail.vue'
import EntityLabelsReference from './EntityLabelsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EntityLabelsTile', EntityLabelsTile)
    Vue.component('EntityLabelsDetail', EntityLabelsDetail)
    Vue.component('EntityLabelsReference', EntityLabelsReference)

    modularManager.addResourcePreset(
      Resources.ENTITY_LABELS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EntityLabelsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABELS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EntityLabelsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABELS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EntityLabelsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABELS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EntityLabelsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
