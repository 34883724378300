import LeavePolicyItemsValueField from './LeavePolicyItemsValueField.vue'
import LeavePolicyItemsDetailsQuantityRow from './LeavePolicyItemsDetailsQuantityRow.vue'
import LeavePolicyItemsAccruedColumn from './LeavePolicyItemsAccruedColumn.vue'
import LeavePolicyItemsTotalColumn from './LeavePolicyItemsTotalColumn.vue'
import LeavePolicyItemsAccrualBreakdown from './LeavePolicyItemsAccrualBreakdown.vue'
import { LeavePolicyItemsComponents } from './types'

export default {
  install(Vue) {
    Vue.component(
      LeavePolicyItemsComponents.LeavePolicyItemsValueField,
      LeavePolicyItemsValueField,
    )
    Vue.component(
      LeavePolicyItemsComponents.LeavePolicyItemsDetailsQuantityRow,
      LeavePolicyItemsDetailsQuantityRow,
    )
    Vue.component(
      LeavePolicyItemsComponents.LeavePolicyItemsAccruedColumn,
      LeavePolicyItemsAccruedColumn,
    )

    Vue.component(
      LeavePolicyItemsComponents.LeavePolicyItemsTotalColumn,
      LeavePolicyItemsTotalColumn,
    )

    Vue.component(
      LeavePolicyItemsComponents.LeavePolicyItemsAccrualBreakdown,
      LeavePolicyItemsAccrualBreakdown,
    )
  },
}
