import {
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const TreeMapWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'TreeMapWidget',
  'Tree Map',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    toolbar: true,
  },
)

TreeMapWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/TreeMapOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadCsvPdf',
  },
})

TreeMapWidgetBuilder.addDefinitions({
  TreeMapOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      colorPalette: {
        title: 'Color Palette',
        $ref: '#/definitions/ColorPalette',
      },
      disableClick: {
        title: 'Disable Click',
        $ref: '#/definitions/DisableClick',
      },
    },
  },
})

export default TreeMapWidgetBuilder.schema
