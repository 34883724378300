import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'employee',
  'shift.position.account',
  'shift.position',
  'shift.startsOn',
  'answer',
  'status',
  {
    attributeName: 'viaCall',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'viaCall',
      },
    },
  },
  {
    attributeName: 'viaSms',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'viaSms',
      },
    },
  },
] as ColumnInputDefinition[]
