import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import { TEXT } from './types'
import schema from './schema.json'

const ui: FieldUI = {
  name: i18nFieldKey(TEXT),
  icon: 'mdi-format-color-text',
  color: 'orange',
}

const converter: FieldConverter = (field) => ({
  getJsonSchema: () => {
    return { allOf: [getBaseFieldJsonSchema(field), { type: 'string' }] }
  },
  getViewOption: () => {
    return {}
  },
})

export const TextMeta: FieldMeta = {
  field: TEXT,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
