// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointTourSessionsTile from './CheckpointTourSessionsTile.vue'
import CheckpointTourSessionsColumns from './CheckpointTourSessionsColumns'
import CheckpointTourSessionsDetail from './CheckpointTourSessionsDetail.vue'
import CheckpointTourSessionsMetaCard from './CheckpointTourSessionsMetaCard.vue'
import CheckpointTourSessionsReference from './CheckpointTourSessionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CheckpointTourSessionsTile', CheckpointTourSessionsTile)
    Vue.component('CheckpointTourSessionsDetail', CheckpointTourSessionsDetail)
    Vue.component(
      'CheckpointTourSessionsMetaCard',
      CheckpointTourSessionsMetaCard,
    )
    Vue.component(
      'CheckpointTourSessionsReference',
      CheckpointTourSessionsReference,
    )

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointTourSessionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
