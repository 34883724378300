// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetCategoriesTile from './AssetCategoriesTile.vue'
import AssetCategoriesColumns from './AssetCategoriesColumns'
import AssetCategoriesDetail from './AssetCategoriesDetail.vue'
import AssetCategoriesMetaCard from './AssetCategoriesMetaCard.vue'
import AssetCategoriesReference from './AssetCategoriesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetCategoriesTile', AssetCategoriesTile)
    Vue.component('AssetCategoriesDetail', AssetCategoriesDetail)
    Vue.component('AssetCategoriesMetaCard', AssetCategoriesMetaCard)
    Vue.component('AssetCategoriesReference', AssetCategoriesReference)

    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetCategoriesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetCategoriesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetCategoriesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetCategoriesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetCategoriesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
