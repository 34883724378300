import Vue from 'vue'

import CheckpointsScannedResultsAttr from './CheckpointsScannedResultsAttr.vue'
import ActualDurationInMinuteAttr from '../ActualDurationInMinuteAttr.vue'
import CheckpointTourSessionsDetailsBanner from './CheckpointTourSessionsDetailsBanner.vue'
import CheckpointTourSessionsStatusAttr from './CheckpointTourSessionsStatusAttr.vue'

import { modularManager } from '@/tt-app-modular'
import {
  addColumnPreset,
  addResourceDocument,
  setAttributeView,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import CheckpointTourSessionsResultColumn from '@/tt-entity-design/src/components/checkpoint-tour-sessions/CheckpointTourSessionsResultColumn'
import CheckpointTourSessionDoc from '@/tt-entity-design/src/components/checkpoint-tour-sessions/CheckpointTourSessionDoc'
import CheckpointTourSessionsRelationListPreview from './CheckpointTourSessionsRelationListPreview.vue'

export default {
  install(Vue) {
    Vue.component('ActualDurationInMinuteAttr', ActualDurationInMinuteAttr)

    Vue.component(
      'CheckpointTourSessionsStatusAttr',
      CheckpointTourSessionsStatusAttr,
    )

    Vue.component(
      'CheckpointsScannedResultsAttr',
      CheckpointsScannedResultsAttr,
    )

    Vue.component(
      'CheckpointTourSessionsRelationListPreview',
      CheckpointTourSessionsRelationListPreview,
    )

    Vue.component(
      'CheckpointTourSessionsDetailsBanner',
      CheckpointTourSessionsDetailsBanner,
    )
    modularManager.addItem(
      `${Resources.CHECKPOINT_TOUR_SESSIONS}.preview.details.top`,
      {
        is: 'CheckpointTourSessionsDetailsBanner',
      },
    )

    setAttributeView(Resources.CHECKPOINT_TOUR_SESSIONS, 'status', {
      is: 'CheckpointTourSessionsStatusAttr',
    })

    addResourceDocument(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      CheckpointTourSessionDoc,
    )

    addColumnPreset(
      Resources.CHECKPOINT_TOUR_SESSIONS,
      'tt-entity-design.checkpoint-tour-sessions.attrs.checkpoints-scanned-results',
      CheckpointTourSessionsResultColumn,
    )
  },
}
