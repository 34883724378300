import {
  Attribute,
  FieldTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import {
  Filter,
  FilterOperatorType,
} from 'tracktik-sdk/lib/common/entity-filters'
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import { isEmpty } from '@/helpers/isEmpty'
import { cloneDeep } from 'lodash'
import { PropType } from 'vue'

export default ObjectInput.extend({
  methods: {
    // Set the model value to null when it is empty
    setModelValue(val) {
      const model = cloneDeep(this.model)
      model.value = isEmpty(val) ? null : val
      this.model = model
    },
  },
  props: {
    attributeMeta: {
      type: Object as PropType<Attribute>,
    },
  },
  computed: {
    isBetweenOperator(): boolean {
      return [FilterOperatorType.BETWEEN].includes(this.model?.operator)
    },
    isNullOperator(): boolean {
      return [FilterOperatorType.ISNOTNULL, FilterOperatorType.ISNULL].includes(
        this.model?.operator,
      )
    },
    defaultModel(): Filter {
      return {
        attribute: this.attributeMeta.name,
        operator: FilterOperatorType.EQUAL,
        value: null,
      }
    },
    type(): FieldTypes {
      return this.attributeMeta.type
    },
  },
})
