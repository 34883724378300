import { EntityAggregationIntentInterface, EntityIntentTypes } from './types'
import { PageInterface } from '@/tt-app-layout'
import { BaseEntityIntent } from '@/tt-widget-entity-flow/intents/BaseEntityIntent'
import i18n from '@/plugins/i18n'
import { CollectionQuery } from '@/tt-widget-components'

/**
 * Entity List Intent
 */
export class EntityAggregationDetailIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.AGGREGATION_DETAILS
  }

  static getPage(payload: EntityAggregationIntentInterface) {
    const title = i18n.tc(
      'intents.entity_aggregation_detail_intent.detail_view',
    )
    //@ts-ignore Will be fix in this ticket FE-463
    const query: CollectionQuery = payload.query

    const widget: { title: string; query: CollectionQuery } = {
      title,
      query,
    }

    return {
      title,
      is: 'AggregationPageWrapper',
      close: true,
      props: {
        hasTitleBar: false,
        widget,
        aggregationContext: payload.aggregationContext,
        skipValidation: true, //To avoid false invalid, query come from API
      },
    } as PageInterface
  }

  /**
   * Payload
   * @param payload
   */
  run(payload: EntityAggregationIntentInterface): void {
    // this.appContext.eventManager.dispatchEvent(
    //   LayoutWindowEvent.SIDE_SHEET,
    //   EntityAggregationDetailIntent.getPage(payload)
    // )
  }
}
