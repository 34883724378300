// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFieldsTile from './ReportFieldsTile.vue'
import ReportFieldsColumns from './ReportFieldsColumns'
import ReportFieldsDetail from './ReportFieldsDetail.vue'
import ReportFieldsReference from './ReportFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportFieldsTile', ReportFieldsTile)
    Vue.component('ReportFieldsDetail', ReportFieldsDetail)
    Vue.component('ReportFieldsReference', ReportFieldsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
