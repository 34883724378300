// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetCategoryTranslationsTile from './AssetCategoryTranslationsTile.vue'
import AssetCategoryTranslationsColumns from './AssetCategoryTranslationsColumns'
import AssetCategoryTranslationsDetail from './AssetCategoryTranslationsDetail.vue'
import AssetCategoryTranslationsReference from './AssetCategoryTranslationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'AssetCategoryTranslationsTile',
      AssetCategoryTranslationsTile,
    )
    Vue.component(
      'AssetCategoryTranslationsDetail',
      AssetCategoryTranslationsDetail,
    )
    Vue.component(
      'AssetCategoryTranslationsReference',
      AssetCategoryTranslationsReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORY_TRANSLATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetCategoryTranslationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORY_TRANSLATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetCategoryTranslationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORY_TRANSLATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetCategoryTranslationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CATEGORY_TRANSLATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetCategoryTranslationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
