<template>
  <v-card
    flat
    style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc"
  >
    <v-layout>
      <v-flex>
        <div class="day-selector mt-4">
          <v-btn icon small @click="back">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex v-for="(day, index) in days" :key="index">
        <div
          class="day-selector day-selector--day pa-2"
          :class="index == 6 ? ' day-selector--last-child' : ''"
        >
          <div style="line-height: normal; font-size: 0.8em">
            {{ day.format('MMM') }}
          </div>
          <div style="font-weight: 400">{{ day.format('DD') }}</div>
        </div>
      </v-flex>
      <v-flex>
        <div class="day-selector mt-4">
          <v-btn icon small @click="forward">
            <v-icon>keyboard_arrow_right</v-icon>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'WeekSelector',
  props: {
    value: String,
  },
  data() {
    return {
      model: null as null | any,
    }
  },
  computed: {
    referenceDate(): moment.Moment {
      return moment(this.model)
    },
    days(): moment.Moment[] {
      const cursor = this.referenceDate
      const out: moment.Moment[] = []
      for (let i = 0; i < 7; i++) {
        const day = cursor.clone().add(i, 'day')
        out.push(day)
      }

      return out
    },
  },
  watch: {
    model(model) {
      this.$emit('input', model)
    },
  },
  created() {
    this.model = this.value
  },
  methods: {
    back() {
      const clone = this.referenceDate.clone()
      this.model = clone.subtract(7, 'day').format('YYYY-MM-DD')
    },
    forward() {
      const clone = this.referenceDate.clone()
      this.model = clone.add(7, 'day').format('YYYY-MM-DD')
    },
  },
})
</script>
<style>
.day-selector {
  line-height: px;
}

.day-selector--day {
  font-weight: 300;
  font-size: 1.1em;
  border-left: 1px #ccc solid;
  text-align: center;
}

.day-selector--last-child {
  border-right: 1px #ccc solid;
}
</style>
