import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'customId',
  {
    attributeName: 'zone.name',
    headerText: 'tt-entity-design.mobile-runsheets.attrs.zone.name',
  },
  {
    attributeName: 'zonePosition.name',
    headerText: 'tt-entity-design.mobile-runsheets.attrs.zonePosition.name',
  },
  'dayOfWeek',
  'rangeStartTime',
] as ColumnInputDefinition[]
