import { cloneData } from '@/helpers/cloneData'
import { CollectionWidget, DataSetWidget, Widget } from '../types'
import i18n from '@/plugins/i18n'
import { AppContext } from '@/tt-app-context'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { getCommonSchemaDefinitions } from '../lib'
import { getJsonSchema } from './getJsonSchema'
import { removeAdditional } from '@tracktik/tt-json-schema-form'

function getWidgetType(
  widget: Widget,
  appContext: AppContext,
): WidgetTypeDefinition | null {
  if (!widget.is) {
    return null
  }

  return appContext.widgetServices.widgetManager.getWidgetByName(widget.is)
}

function mapDataSetToCollection(widget: Widget): CollectionWidget {
  const newWidget = cloneData(widget)
  newWidget.query = {
    resource: widget.dataSet.resource,
  }

  return newWidget as CollectionWidget
}

function mapCollectionToDataSet(widget: Widget): DataSetWidget {
  const newWidget = cloneData(widget)
  newWidget.dataSet = {
    resource: widget.query.resource,
  }

  return newWidget
}

function openChangeWidgetConfirmDialog(appContext: AppContext): Promise<void> {
  const message = i18n.tc('widget_editor.change_type_confirm_message')
  return new Promise((resolve, reject) => {
    appContext.eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message,
      accept: () => resolve(),
      cancel: () => reject(),
    })
  })
}

export async function changeWidgetType(
  newType: WidgetTypeDefinition,
  oldWidget: Widget,
  appContext: AppContext,
): Promise<Widget> {
  if (getWidgetType(oldWidget, appContext)) {
    await openChangeWidgetConfirmDialog(appContext)
  }

  const newWidgetSchema = cloneData(
    appContext.widgetServices.widgetManager.getWidgetByName(newType.name)
      .schema,
  )

  const defaultDefinitions = getCommonSchemaDefinitions()
  newWidgetSchema.definitions = {
    ...defaultDefinitions,
    ...newWidgetSchema.definitions,
  }

  const currentSchema = getJsonSchema(getWidgetType(oldWidget, appContext))

  let widget = { ...oldWidget, is: newType.name }
  // If we're setting a type for the first time, set a default title
  if (!oldWidget.is) {
    const widgetName = i18n.tc(`widgets.${newType.name}.name`)
    widget.title = `${i18n.tc('common.new.btn')} ${widgetName}`
  }

  if (
    currentSchema &&
    currentSchema.properties.dataSet &&
    newWidgetSchema.properties.query
  ) {
    widget = mapDataSetToCollection(widget)
  } else if (
    currentSchema &&
    currentSchema.properties.query &&
    newWidgetSchema.properties.dataSet
  ) {
    widget = mapCollectionToDataSet(widget)
  }
  widget = removeAdditional(newWidgetSchema, widget)

  return widget
}
