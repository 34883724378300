<template>
  <EntitySelectorField
    v-model="model"
    :resource="'operation-centers'"
    :multiple="true"
    :query-options="queryOptions"
    :label="operationCentersToEscalateToLabel"
    :rules="[(v) => !!v.length || $t('common.required_field')]"
    style="width: 100%"
    @update:error="handleError"
  />
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { Filter } from 'tracktik-sdk/lib/common/entity-filters'
import { FormHookProvider } from '@/tt-widget-components'
import { error } from './constants'

type VueWithInjections = VueConstructor<
  Vue & FormHookProvider & { namespace: string }
>

export default (Vue as VueWithInjections).extend({
  name: 'OperationCentersToEscalateToField',
  inject: ['formHook'],
  props: {
    disabledIds: { type: Array as PropType<number[]>, default: () => [] },
    value: { type: Array as PropType<number[]>, default: () => [] },
    index: { type: Number, required: true },
  },
  computed: {
    model: {
      get(): number[] {
        return this.value
      },
      set(value: number[]) {
        this.$emit('input:operationCentersToEscalateTo', {
          operationCentersToEscalateTo: value,
        })
      },
    },
    operationCentersToEscalateToLabel(): string {
      return (
        this.formHook().getViewComponentProps('escalationRules')?.itemField
          ?.props?.items['operationCentersToEscalateTo']?.props?.$schema[
          'x-ttc-labels'
        ].label || ''
      )
    },
    queryOptions(): { filters: Filter[] } {
      return {
        filters: [
          {
            attribute: 'id',
            operator: FilterOperatorType.NOTIN,
            value: this.disabledIds,
          },
        ],
      }
    },
  },
  created(): void {
    if (this.value.length === 0) {
      this.handleError(true)
    }
  },
  beforeDestroy(): void {
    this.handleError(false)
  },
  methods: {
    handleError(hasError: boolean): void {
      if (hasError) {
        this.formHook().setCustomError(
          `operationCentersToEscalateTo.${this.index}`,
          error,
        )
      } else {
        this.formHook().setCustomError(
          `operationCentersToEscalateTo.${this.index}`,
          null,
        )
      }
    },
  },
})
</script>
