// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskPriceTierBillSettingsTile from './TaskPriceTierBillSettingsTile.vue'
import TaskPriceTierBillSettingsColumns from './TaskPriceTierBillSettingsColumns'
import TaskPriceTierBillSettingsDetail from './TaskPriceTierBillSettingsDetail.vue'
import TaskPriceTierBillSettingsMetaCard from './TaskPriceTierBillSettingsMetaCard.vue'
import TaskPriceTierBillSettingsReference from './TaskPriceTierBillSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'TaskPriceTierBillSettingsTile',
      TaskPriceTierBillSettingsTile,
    )
    Vue.component(
      'TaskPriceTierBillSettingsDetail',
      TaskPriceTierBillSettingsDetail,
    )
    Vue.component(
      'TaskPriceTierBillSettingsMetaCard',
      TaskPriceTierBillSettingsMetaCard,
    )
    Vue.component(
      'TaskPriceTierBillSettingsReference',
      TaskPriceTierBillSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIER_BILL_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskPriceTierBillSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIER_BILL_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskPriceTierBillSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIER_BILL_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskPriceTierBillSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIER_BILL_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaskPriceTierBillSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_PRICE_TIER_BILL_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskPriceTierBillSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
