<template>
  <v-card elevation="0" class="py-1 px-2 card-style" :class="backgroundClass">
    <v-card-title
      v-if="$slots.header"
      class="pa-0 justify-center text-center header-style"
    >
      <slot name="header" />
    </v-card-title>

    <v-card-text
      v-if="$slots['sub-header']"
      class="pa-0 justify-center text-center text-caption"
    >
      <slot name="sub-header" />
    </v-card-text>

    <template v-if="$slots.bottom">
      <v-divider class="ma-1 mt-0" />
      <v-card-actions class="pa-0 justify-center bottom-style">
        <slot name="bottom" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TListContentCard',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundClass(): string {
      return this.transparent ? 'card--transparent' : 'level1'
    },
  },
})
</script>

<style scoped>
.card--transparent {
  background: transparent;
}
.card-style {
  border-radius: 0.5em !important;
  width: 100%;
}

.header-style {
  font-size: 1em;
}

.bottom-style {
  height: 1.1em;
}
</style>
