import { EmployeeTab } from '../types'

export const completedRequestsTab: EmployeeTab = {
  key: 'leave-history',
  title:
    'tt-entity-design.leave-management.leave-requests.tabs.completed-requests.title',
  icon: 'mdi-check',
  is: 'HumanCapitalManagementEmployeeLeaveHistory',
}

export const conflictsTab: EmployeeTab = {
  key: 'conflicts',
  title:
    'tt-entity-design.leave-management.leave-requests.tabs.conflicts.title',
  icon: 'mdi-alert',
  is: 'HumanCapitalManagementEmployeeConflicts',
}
