<template>
  <WidgetWrapper
    v-if="hook.isReadyToFetch"
    v-bind="$props"
    @rendered="onWidgetRendered"
  >
    <template #default>
      <VerticalStackCard v-slot="{ height }">
        <v-simple-table dense :height="height" fixed-header class="transparent">
          <thead>
            <tr>
              <th class="text-left" :style="headerStyle">
                {{ dimension.alias }}
              </th>
              <th
                v-for="measure in measures"
                :key="measure.key"
                class="text-left cursor-pointer"
                :style="headerStyle"
                @click="hook.sortBy(measure.alias)"
                v-text="measure.alias"
              />
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, k) in dataSource">
              <tr
                :key="`row_${k}`"
                :style="rowStyle"
                style="cursor: pointer"
                @click="click(row)"
              >
                <td>{{ row[dimension.key] }}</td>
                <td
                  v-for="(measure, mIndex) in measures"
                  :key="`row_${k}_${mIndex}`"
                >
                  {{
                    hook.getFormattedMeasure(measure, mIndex, row[measure.key])
                  }}
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </VerticalStackCard>
    </template>

    <template #loader>
      <WidgetLoader :widget="hook.widget">
        <v-skeleton-loader type="table" />
      </WidgetLoader>
    </template>
  </WidgetWrapper>
</template>
<script lang="ts">
import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import {
  DataSetMeasure,
  DataSetRow,
  MetricTableOptions,
} from '@/tt-widget-components/types'
import { PropType } from 'vue'
import MetricTableWidgetHook from './MetricTableWidgetHook'

export default BaseWidget.extend({
  props: {
    hook: {
      type: Object as PropType<MetricTableWidgetHook>,
      required: true,
    },
  },
  computed: {
    dataSource(): DataSetRow[] {
      const series = this.hook.series[0]
      const sort = this.hook.sort
      return series ? series.asSortedSeries(sort.key, sort.desc) : []
    },
    dimension(): any {
      return this.hook.getDimensionByIndex(0, 0)
    },
    measures(): DataSetMeasure[] {
      return this.hook.isReadyToFetch
        ? this.hook.seriesManagers[0].measures
        : []
    },
    options(): MetricTableOptions {
      return this.hook.widget?.options || {}
    },
    headerStyle(): any {
      return {
        color: this.options.headerTextColor,
        'background-color': this.options.headerBackgroundColor,
      }
    },
    rowStyle(): any {
      return {
        color: this.options.bodyTextColor,
        'background-color': this.options.bodyBackgroundColor,
      }
    },
  },
  methods: {
    click(row: DataSetRow): void {
      this.hook.showAggregationDetails(row)
    },
    onWidgetRendered() {
      this.$emit('rendered')
    },
  },
  watch: {
    dataSource() {
      this.onWidgetRendered()
    },
  },
})
</script>

<style scoped>
@media print {
  /* removes scroll on print */
  .v-data-table >>> .v-data-table__wrapper {
    overflow: hidden;
  }

  /* reduce padding on print */
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 2px !important;
  }
}
</style>
