<template>
  <div><slot :resource="resource"></slot></div>
</template>

<script lang="ts">
import Vue from 'vue'
import { ResourceProvider } from '../types'

export default Vue.extend({
  name: 'ProvideResource',
  provide(): ResourceProvider {
    return {
      resourceProvider: this.resourceProvider,
    }
  },
  props: {
    resource: { type: String },
  },
  data() {
    return {
      resourceProvider: Vue.observable({
        resource: null,
      }),
    }
  },
  watch: {
    resource(val: string | null) {
      this.resourceProvider.resource = val
    },
  },
  created() {
    this.resourceProvider.resource = this.resource ?? null
  },
})
</script>
