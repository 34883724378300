// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetFieldsetsTile from './AssetFieldsetsTile.vue'
import AssetFieldsetsColumns from './AssetFieldsetsColumns'
import AssetFieldsetsDetail from './AssetFieldsetsDetail.vue'
import AssetFieldsetsReference from './AssetFieldsetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetFieldsetsTile', AssetFieldsetsTile)
    Vue.component('AssetFieldsetsDetail', AssetFieldsetsDetail)
    Vue.component('AssetFieldsetsReference', AssetFieldsetsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetFieldsetsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
