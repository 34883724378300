<template>
  <v-card flat>
    <v-divider />
    <AlarmsFormLocationFields
      class="pa-6"
      v-bind="{ hasLocationCoordinate, defaultPosition }"
      @input="setAdressFields"
    />
    <v-divider />
    <v-toolbar flat text>
      <v-spacer />
      <v-btn color="grey" class="mr-3" outlined raised @click="cancel">
        <span v-text="$t('common.cancel.btn')" />
      </v-btn>
      <v-btn
        class="mr-3"
        color="ttPrimary"
        outlined
        :disabled="disabledButton"
        @click="setLocation"
      >
        <v-icon small v-text="'mdi-account-outline'" />
        <span
          class="px-2"
          v-text="
            $t('tt-entity-design.dispatch-tasks.form-button.set-location')
          "
        />
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import AlarmsFormLocationFields from './AlarmsFormLocationFields.vue'
import { FormHook } from '@tracktik/tt-json-schema-form'
import { LocationFormServiceType } from './location-form-service'
import { Coordinates, Geocoding } from '@tracktik/tt-geo-proxy'
import { ErrorObject } from '@tracktik/tt-json-schema-form'

type AlarmsFormProvider = {
  locationService: LocationFormServiceType
  formHook: () => FormHook
  namespace: string
}

export default Vue.extend({
  name: 'AlarmsFormLocationPage',
  components: {
    AlarmsFormLocationFields,
  },
  provide(): AlarmsFormProvider {
    return {
      locationService: this.locationService,
      formHook: this.formHook,
      namespace: this.namespace,
    }
  },
  props: {
    formHook: {
      type: Function as PropType<() => FormHook>,
      required: true,
    },
    locationService: {
      type: Object as PropType<LocationFormServiceType>,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      geocode: null as Geocoding | null,
    }
  },
  computed: {
    hasLocationCoordinate(): boolean {
      return (
        !!this.formHook().getPathValue('location.latitude') &&
        !!this.formHook().getPathValue('location.longitude')
      )
    },
    defaultPosition(): Coordinates {
      return this.locationService.getDefaultCoordinate()
    },
    disabledButton(): boolean {
      const formErrors: Partial<Record<string, ErrorObject[]>> =
        this.formHook().errors ?? {}

      const hasLocationError = (error) => error.includes('location')

      return Object.keys(formErrors).some(hasLocationError)
    },
  },
  created() {
    this.formHook().setObjectValue('location', null)
  },
  beforeDestroy() {
    /**
     * If user didn't change location and location is null, we want to fall back to client location
     */
    if (!this.hasLocationCoordinate) {
      this.formHook().setObjectValue(
        'location',
        this.locationService.getClientAdress(),
      )
    }
  },
  methods: {
    setAdressFields(geocode: Geocoding): void {
      this.formHook().setObjectValue('location', {
        ...this.formHook().getPathValue('location'),
        ...geocode.address,
        ...geocode.coordinates,
      })
      this.geocode = geocode
    },
    setLocation(): void {
      const fullAddress = this.geocode
        ? {
            name: this.formHook().getPathValue('location.name'),
            ...this.geocode.address,
            ...this.geocode.coordinates,
          }
        : null

      this.locationService.setCustomLocation(fullAddress)
      this.locationService.setIsCustomLocation(!!this.geocode)
      this.$emit('back')
    },
    cancel() {
      this.locationService.setIsCustomLocation(false)
      this.formHook().setObjectValue(
        'location',
        this.locationService.getAddressObject(),
      )
      this.$emit('back')
    },
  },
})
</script>
