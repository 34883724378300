<template>
  <v-col
    v-if="showField"
    class="pa-2 ma-1 level0"
    style="min-height: 40px; border-radius: 5px"
  >
    <div class="header text-center">
      <slot>
        <tt-attr v-bind="{ ...$props, ...$attrs }" />
      </slot>
    </div>
    <div class="caption text-center">
      <slot name="label">
        <tt-attr-label v-bind="{ ...$props, ...$attrs }" />
      </slot>
    </div>
  </v-col>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'EntityAttributeBanner',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    name: { type: String, required: true },
  },
  computed: {
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        relationPrefix: this.relationPrefix,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
})
</script>
