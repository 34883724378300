// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaxLocationOverrideGroupsTile from './TaxLocationOverrideGroupsTile.vue'
import TaxLocationOverrideGroupsColumns from './TaxLocationOverrideGroupsColumns'
import TaxLocationOverrideGroupsDetail from './TaxLocationOverrideGroupsDetail.vue'
import TaxLocationOverrideGroupsMetaCard from './TaxLocationOverrideGroupsMetaCard.vue'
import TaxLocationOverrideGroupsReference from './TaxLocationOverrideGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'TaxLocationOverrideGroupsTile',
      TaxLocationOverrideGroupsTile,
    )
    Vue.component(
      'TaxLocationOverrideGroupsDetail',
      TaxLocationOverrideGroupsDetail,
    )
    Vue.component(
      'TaxLocationOverrideGroupsMetaCard',
      TaxLocationOverrideGroupsMetaCard,
    )
    Vue.component(
      'TaxLocationOverrideGroupsReference',
      TaxLocationOverrideGroupsReference,
    )

    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaxLocationOverrideGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_GROUPS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideGroupsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
