import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportTemplateFieldsForm from './ReportTemplateFieldsForm.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('ReportTemplateFieldsForm', ReportTemplateFieldsForm)

    registerResourceForm(
      Resources.REPORT_TEMPLATE_FIELDS,
      'ReportTemplateFieldsForm',
    )
  },
}
