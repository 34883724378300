import moment from 'moment'
import { setI18nLocale } from '@/plugins/i18n'
import { AuthModule, TTCloudLocale } from '@tracktik/tt-authentication'
import { SupportedLocale } from './language-options'
import { getValidLocale } from './utils'

const LANGUAGE_LOCAL_STORAGE_KEY = 'tt_frontend_locale'

export const setLocaleToLocaleStorage = (locale: string) => {
  try {
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, locale)
  } catch (error) {
    console.warn(`Could not set the locale to the Locale Storage.`, error)
  }
}

export const getLocaleFromLocaleStorage = (): string | null =>
  localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) as SupportedLocale

const setAppFrontEndLocale = (locale: SupportedLocale): void => {
  moment.locale(locale)
  setI18nLocale(locale)
}

export const setAppLocale = (
  authModule: AuthModule,
  locale: SupportedLocale | TTCloudLocale | string,
) => {
  const { frontendLocale, ttCloudLocale } = getValidLocale(locale)
  setAppFrontEndLocale(frontendLocale)
  authModule.setLocale(ttCloudLocale)
}

export const setAppLocaleFromTTCloud = (
  authModule: AuthModule,
  locale: TTCloudLocale,
) => {
  const { frontendLocale } = getValidLocale(locale)
  setAppFrontEndLocale(frontendLocale)
  authModule.setLocale(locale)
}
