<template>
  <v-list-item>
    <!-- Valid user -->
    <template v-if="userItem">
      <SharableUserGroupAvatar :item="userItem" tag="v-list-item-avatar" />

      <v-list-item-content>
        <v-list-item-title v-text="$t(userItem.name)" />
        <v-list-item-subtitle v-text="$t(userItem.subTitle)" />
      </v-list-item-content>
    </template>

    <!-- Invalid user -->
    <v-list-item-content v-else>
      <v-list-item-title>
        <v-alert
          class="mb-0 d-inline-block"
          dense
          text
          type="warning"
          v-text="$t('sharing.invalid-user')"
        />
      </v-list-item-title>
    </v-list-item-content>

    <!-- Extra details: OWNER? / SHARED FROM PARENT FOLDER? -->
    <v-list-item-action class="text-right">
      <v-btn
        v-if="isOwner"
        text
        small
        cursor="text"
        plain
        :ripple="false"
        v-text="$t(`common.role_OWNER`)"
      />
      <span v-else-if="isSharedFromParent">
        <v-chip
          small
          label
          outlined
          disabled
          class="text-uppercase"
          v-text="$t(`sharing.shared-from-folder`)"
        />
        <v-btn
          v-if="userItem"
          text
          small
          cursor="text"
          plain
          :ripple="false"
          v-text="$t(`common.role_${rule.permission}`)"
        />
      </span>

      <!-- SOURCE === THIS, AND Valid User, show PERMISSION selector -->
      <SharableFormPermissionSelector
        v-else-if="userItem"
        :value="rule.permission"
        @input="$emit('input', $event)"
      />
    </v-list-item-action>

    <!-- Delete button -->
    <v-list-item-action class="text-right">
      <v-btn v-if="!readOnly" icon text small @click="$emit('remove')">
        <v-icon small>close</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { UserGroupItem, AclRuleViewItem } from '@/tt-widget-sharable'
import SharableUserGroupAvatar from './SharableUserGroupAvatar.vue'
import SharableFormPermissionSelector from './SharableFormPermissionSelector.vue'
import { OWNER, AclRuleItemSource } from '@/tt-widget-sharable/src/types'

export default Vue.extend({
  name: 'SharableFormGrantTile',
  components: {
    SharableUserGroupAvatar,
    SharableFormPermissionSelector,
  },
  props: {
    userItem: {
      type: Object as PropType<UserGroupItem>,
      default: null,
    },
    rule: {
      type: Object as PropType<AclRuleViewItem>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOwner(): boolean {
      return this.rule.permission === OWNER
    },
    isSharedFromParent(): boolean {
      return this.rule.source === AclRuleItemSource.PARENT
    },
  },
})
</script>
