<template>
  <div>
    <div class="d-flex align-center">
      <span
        class="subtitle-2 mr-2"
        v-text="$t('tt-entity-design.alarms.form-fields.dispatch-timing')"
      />
      <v-radio-group v-model="selectedAlarmTiming" row>
        <v-radio
          v-for="item in alarmTimingOptions"
          :key="item.value"
          color="ttPrimary"
          :label="item.label"
          :value="item.value"
        />
      </v-radio-group>
    </div>
    <div v-if="selectedAlarmTiming === alarmTimingType.LATER">
      <v-row v-if="hasTriggeredOnField" no-gutters class="mt-4">
        <v-col cols="12" class="pr-4">
          <json-field name="triggeredOn" />
        </v-col>
      </v-row>
      <v-row v-if="hasRegisteredOnField" no-gutters>
        <v-col cols="12" class="pr-4">
          <json-field name="registeredOn" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { AlarmTiming, AlarmTimingOption } from '../types'
import { DATE_FIELDS } from '../constants'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'AlarmsFormTimingFields',
  inject: ['formHook'],
  props: {
    alarmTiming: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedAlarmTiming: {
      get(): AlarmTiming {
        return this.alarmTiming as unknown as AlarmTiming
      },
      set(value: AlarmTiming) {
        this.$emit('update:alarmTiming', value)
      },
    },
    hasTriggeredOnField(): boolean {
      return this.formHook().hasField('triggeredOn')
    },
    hasRegisteredOnField(): boolean {
      return this.formHook().hasField('registeredOn')
    },
    alarmTimingOptions(): AlarmTimingOption[] {
      return [
        {
          label: this.$t('tt-entity-design.dispatch-tasks.form-radios.now'),
          value: AlarmTiming.NOW,
        },
        {
          label: this.$t('tt-entity-design.dispatch-tasks.form-radios.later'),
          value: AlarmTiming.LATER,
        },
      ]
    },
    alarmTimingType(): typeof AlarmTiming {
      return AlarmTiming
    },
  },
  watch: {
    selectedAlarmTiming(value: AlarmTiming) {
      if (value === AlarmTiming.NOW) {
        this.dateSetters(AlarmTiming.NOW)
      } else if (value === AlarmTiming.LATER) {
        this.dateSetters(null)
      }
    },
  },
  methods: {
    dateSetters(value: AlarmTiming | null) {
      DATE_FIELDS.forEach((field) => {
        this.formHook().setObjectValue(field, value)
      })
    },
  },
})
</script>
