<template>
  <v-card-text>
    <v-tabs v-model="tabIndex" class="mt-2" left slider-color="orange">
      <v-tab v-text="browseLabel" />
      <v-tab :disabled="selectedCount === 0" v-text="selectedFieldsLabel" />
    </v-tabs>

    <template v-if="tabIndex === 0">
      <v-text-field
        :label="searchLabel"
        class="mt-2"
        outlined
        dense
        clearable
        hide-details
        :loading="loading"
        @blur="$emit('blur')"
        @input="setDebouncedSearch($event)"
      />

      <EntityCollectionListWrapper
        :query="searchQuery"
        is-sorted
        :value="model"
        class="entity--collection mt-2"
        @input="toggle($event)"
      />
    </template>

    <EntityCollectionListWrapper
      v-if="showSelected"
      :query="showSelectedQuery"
      is-sorted
      :value="model"
      class="entity--collection"
      @input="toggle($event)"
    />
  </v-card-text>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import Vue, { PropType } from 'vue'
import { DebouncedFunc } from 'lodash'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import EntityCollectionListWrapper from '@/tt-widget-entity-flow/components/EntityCollectionListWrapper.vue'
import { CollectionQuery } from '@/tt-widget-components'

export type EntityMultipleSelectorFieldQuery = Omit<
  CollectionQuery,
  'resource' | 'search' | 'sort'
>

export default Vue.extend({
  name: 'EntityMultipleSelectorField',
  components: { EntityCollectionListWrapper },
  props: {
    query: {
      type: Object as PropType<EntityMultipleSelectorFieldQuery>,
      default: () => ({} as EntityMultipleSelectorFieldQuery),
    },
    resource: { type: String, required: true },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      searchDebounced: null,
      loading: false,
      tabIndex: 0,
    }
  },
  computed: {
    model: {
      get(): string[] {
        return this.value || []
      },
      set(newValue: string[]) {
        this.$emit('input', newValue)
      },
    },
    selectedCount(): number {
      return this.model.length
    },
    selectedFieldsLabel(): string {
      return this.$t('tt-entity-forms.relation-fields.filter-selected', {
        selection: this.selectedCount,
      })
    },
    searchLabel(): string {
      return this.$t('common.type_to_search')
    },
    showSelected(): boolean {
      return this.tabIndex === 1 && this.selectedCount > 0
    },
    searchQuery(): CollectionQuery {
      const { resource, searchDebounced } = this

      return {
        ...this.query,
        resource,
        search: searchDebounced,
      }
    },
    showSelectedQuery(): CollectionQuery {
      const { resource, model } = this

      return {
        resource,
        filters: [
          {
            attribute: 'id',
            operator: FilterOperatorType.IN,
            value: model.join(','),
          },
        ],
      }
    },
    setDebouncedSearch(): DebouncedFunc<(val: string) => void> {
      return debounce((value) => {
        this.searchDebounced = value
      }, 500)
    },
    browseLabel(): string {
      return this.$t('tt-entity-forms.relation-fields.filter-list')
    },
  },
  methods: {
    toggle(val) {
      if (!val) {
        return
      }
      if (this.model.includes(val)) {
        this.model = this.model.filter((item) => item != val)
      } else {
        this.model = [...this.model, val]
      }
    },
  },
})
</script>

<style scoped>
.entity--collection {
  overflow-y: scroll;
  height: 200px;
}
</style>
