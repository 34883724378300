import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'

import { FormOptions } from '@tracktik/tt-json-schema-form'

import { Context, Field, Fieldset, ObjectJSONSchema } from './type'
import { FieldName } from './base/types'
import { FieldMetaMap } from './fields'
import { JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { isRequired, showField } from './base/converter'

export default class FieldsetBuilder {
  public state: {
    model: Partial<Fieldset>
  }

  constructor(initialValue?: Fieldset) {
    this.state = Vue.observable({ model: cloneDeep(initialValue) })
  }

  getFields(): Field[] {
    return this.state.model.fields || []
  }

  getField(index: number): Field | undefined {
    const field = this.getFields()[index]

    if (!field) console.warn('No field found at index :', index)

    return field
  }

  /**
   * Needed ?
   */
  getItemByUID(uid: string): Field | undefined {
    return this.getFields().find((field) => field.uid === uid)
  }

  /**
   * Move a field from a position to another
   */
  moveField(currentIndex: number, destinationIndex: number) {
    const field = this.getField(currentIndex)
    const newFields = [...this.getFields()]

    newFields.splice(currentIndex, 1)
    newFields.splice(destinationIndex, 0, field)

    this.setFields(newFields)
  }

  /**
   * Replace all the fields in the fieldset
   */
  setFields(fields: Field[]) {
    this.state.model = {
      ...this.state.model,
      fields: [...fields],
    }
  }

  /**
   * Add a fresh new field type at a position
   */
  addField<F extends FieldName>(type: F, index: number) {
    const newField = {
      type,
      name: `New ${type}`,
      uid: uniqueId('field_'),
      options: {},
      toDisplayOn: {},
    } as Field

    const newFields = [
      ...this.getFields().slice(0, index),
      newField,
      ...this.getFields().slice(index),
    ]

    this.setFields(newFields)
  }

  /**
   * Replace a field at a position
   */
  setField(field: Field, index: number) {
    const newFields = [...this.getFields()]

    newFields[index] = field

    this.setFields(newFields)
  }

  deleteField(index: number) {
    const newFields = this.getFields().filter((field, i) => i !== index)

    this.setFields(newFields)
  }

  /**
   * Return the JSON Schema of a field from his type.
   */
  getEditFieldSchema(index: number): JSONSchema7 {
    const type = this.getField(index).type

    return FieldMetaMap[type].editSchema
  }

  /**
   * Get the generated JSON Schema from the fieldset configuration.
   *
   * ie, can be use :
   * - in the JSON Form library
   * - to validate data with Ajv
   */
  getJsonSchema(context: Context): ObjectJSONSchema {
    const getFieldSchema = (field: Field) =>
      FieldMetaMap[field.type].converter(field).getJsonSchema()

    const formFields = this.getFields().filter((field) =>
      showField(field, context),
    )

    const fieldsSchemas: [string, JSONSchema7][] = formFields.map((field) => [
      field.uid,
      getFieldSchema(field),
    ])

    const required = formFields
      .filter((field) => isRequired(field, context))
      .map((field) => field.uid)

    return {
      type: 'object',
      required,
      properties: Object.fromEntries(fieldsSchemas),
    }
  }

  /**
   * Get the generated Form Options for the JSON Form UI
   */
  getFormOptions(): FormOptions {
    const getFieldViewOption = (field: Field) =>
      FieldMetaMap[field.type].converter(field).getViewOption()

    const fieldsViewOptions = this.state.model.fields.map((field) => {
      const viewOption = getFieldViewOption(field)

      return [field.uid, viewOption]
    })

    return {
      definitions: {
        '': {
          properties: { ...Object.fromEntries(fieldsViewOptions) },
        },
      },
    }
  }
}
