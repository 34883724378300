<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import _i18n from '@/plugins/i18n'
import { ItemHookProvider } from '../types'
import VueI18n from '../../vue-i18n'

export default Vue.extend({
  name: 'ProvideItemHook',
  provide(): ItemHookProvider & { i18n: VueI18n } {
    return {
      i18n: _i18n,
      relationPrefix: this.relationPrefix,
      getItemHook: () => this.provide,
    }
  },
  props: {
    provide: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
    relationPrefix: {
      type: String,
      default: undefined,
    },
  },
})
</script>
