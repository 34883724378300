import { StatusColorType } from '@/tt-ui/types'

export enum EmployeeSkillsStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
}

export const EmployeeSkillsStatusColors: Record<
  EmployeeSkillsStatus,
  StatusColorType
> = {
  [EmployeeSkillsStatus.ACTIVE]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [EmployeeSkillsStatus.EXPIRING_SOON]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [EmployeeSkillsStatus.EXPIRED]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}
