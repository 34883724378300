// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagTemplateFieldsTile from './ReportFlagTemplateFieldsTile.vue'
import ReportFlagTemplateFieldsColumns from './ReportFlagTemplateFieldsColumns'
import ReportFlagTemplateFieldsDetail from './ReportFlagTemplateFieldsDetail.vue'
import ReportFlagTemplateFieldsMetaCard from './ReportFlagTemplateFieldsMetaCard.vue'
import ReportFlagTemplateFieldsReference from './ReportFlagTemplateFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportFlagTemplateFieldsTile', ReportFlagTemplateFieldsTile)
    Vue.component(
      'ReportFlagTemplateFieldsDetail',
      ReportFlagTemplateFieldsDetail,
    )
    Vue.component(
      'ReportFlagTemplateFieldsMetaCard',
      ReportFlagTemplateFieldsMetaCard,
    )
    Vue.component(
      'ReportFlagTemplateFieldsReference',
      ReportFlagTemplateFieldsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagTemplateFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELDS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
