<template>
  <v-footer v-if="hasFooter" app class="hidden-print-only">
    <v-toolbar dense>
      <AppExtensionSlotFactory
        container-type="v-toolbar-items"
        slot-name="FOOTER_RIGHT"
      ></AppExtensionSlotFactory>
      <AppExtensionSlotFactory
        container-type="v-toolbar-items"
        slot-name="FOOTER_LEFT"
      ></AppExtensionSlotFactory>
    </v-toolbar>
  </v-footer>
</template>
<script lang="ts">
import Vue from 'vue'
import { LayoutExtensionSlots, modularManager } from '@/tt-app-modular'
export default Vue.extend({
  name: 'AppLayoutFooter',
  computed: {
    hasFooter(): boolean {
      if (!this.$vuetify.breakpoint.lgAndUp) {
        return false
      }
      return (
        modularManager.hasExtensions(LayoutExtensionSlots.FOOTER_LEFT) ||
        modularManager.hasExtensions(LayoutExtensionSlots.FOOTER_RIGHT)
      )
    },
  },
})
</script>
