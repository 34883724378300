import { AuthModule } from '@tracktik/tt-authentication'
import { reactive, watch } from 'vue'
import {
  UserRegion,
  getRegionsWithUnknownParent,
} from '@/tt-widget-components/components/treeview/utils'
import {
  RegionId,
  RegionManager,
  USER_DEFINED_REGIONS_CONTEXT_TYPE,
  MAIN_REGION_AND_ALL_SUBREGIONS_CONTEXT_TYPE,
} from './types'
import {
  fetchMissingParentRegions,
  getChildrenRegions,
  isActive,
  isRegion,
} from './utils'
import { computedGetter } from '@tracktik/tt-helpers/lib/functions/computedGetter'
import { extractId } from './extractId'

/**
 * Stateful service to handle the logic of TTC regions.
 */
export const createRegionManager = (authModule: AuthModule): RegionManager => {
  const state = reactive({
    /**
     * Context region ID set. If set in the AuthModule, it will be sync up in this state.
     */
    mainContextRegionId: null as RegionId | null,
    /**
     * Extra regions fetched (usually parent regions), not accessible by the user, but needed to build the FE tree logic.
     */
    extraRegions: [] as UserRegion[],
    /**
     * If it is currently fetching extra regions
     */
    isLoading: false,

    /**
     * Store the list of Region Context IDs to be used. It is either :
     * - all the sub-regions of the main context region (if set)
     * - a user-provided list of regions
     */
    contextRegionsList: [] as RegionId[],

    /**
     * The strategy used for the list of context regions (`contextRegionsList`).
     */
    contextType: MAIN_REGION_AND_ALL_SUBREGIONS_CONTEXT_TYPE as string,
  })

  /**
   * Returns the main context region ID currently set, if any.
   */
  const getMainContextRegionId = computedGetter(() => state.mainContextRegionId)

  /**
   * Returns all the regions accessible by the user
   */
  const getAllUserRegions = computedGetter(() => authModule.getSessionRegions())

  /**
   * Returns the extra regions non-accessible to the user.
   * That can be used to build proper UI trees of regions when user does not have access to all portal regions.
   */
  const getMissingParentRegions = computedGetter(() => [...state.extraRegions])

  /**
   * Returns all the regions accessible by the user + the extra regions fetched.
   */
  const getAllRegions = computedGetter(() => [
    ...getAllUserRegions(),
    ...getMissingParentRegions(),
  ])

  /**
   * Given a region ID, returns its corresponding region object.
   */
  const getRegion = (id: RegionId) => getAllRegions().find(isRegion(id))

  /**
   * Set the main context region ID in the RegionManager.
   *
   * It will warn if :
   * - the AuthModule already has a different context region set
   * - the user does not have access to the context region set
   *
   */
  const setMainContextRegionId = (newContextId: string | null = null) => {
    if (newContextId && !hasAccessToRegionId(newContextId))
      console.warn('User does not have access to this region ID', newContextId)

    const authModuleContextId = authModule.getContextRegionId()

    if (
      newContextId &&
      authModuleContextId &&
      newContextId !== authModuleContextId
    ) {
      console.warn(
        `Two context regions are set : one in the RegionManager (${newContextId}), one in the authModule (${authModuleContextId}). This could lead to unexpected behaviours.`,
      )
    }

    state.mainContextRegionId = newContextId

    const mainContextRegion = getRegion(state.mainContextRegionId)

    const getRegionAndSubRegions = () => [
      mainContextRegion,
      ...getChildrenRegions(getAllUserRegions(), state.mainContextRegionId),
    ]

    state.contextRegionsList = newContextId
      ? getRegionAndSubRegions().map((region) => region?.id)
      : getAllUserRegions().map((region) => region.id)

    state.contextType = MAIN_REGION_AND_ALL_SUBREGIONS_CONTEXT_TYPE
  }

  const setContextRegionsList = (listRegions: RegionId[]) => {
    state.contextType = USER_DEFINED_REGIONS_CONTEXT_TYPE
    state.contextRegionsList = listRegions
  }

  /**
   * Tells if a context region has been set. Could be coming from the AuthModule, or only from the RegionManager.
   */
  const hasMainContextRegion = () => !!state.mainContextRegionId

  /**
   * Returns all ACTIVE regions accessible by the user
   */
  const getAllUserActiveRegions = computedGetter(() =>
    getAllUserRegions().filter(isActive),
  )

  /**
   * Returns the main context region object
   */
  const getMainContextRegion = computedGetter(() =>
    getRegion(getMainContextRegionId()),
  )

  /**
   * Returns the context region set + all its sub-regions.
   * If not context region is set, it will returns all user regions.
   */
  const getContextRegions = computedGetter(() => {
    const contextRegion = getMainContextRegion()
    const allRegions = getAllUserRegions()
    const getRegionAndSubRegions = () => [
      contextRegion,
      ...getChildrenRegions(allRegions, contextRegion.id),
    ]

    return contextRegion ? getRegionAndSubRegions() : [...allRegions]
  })

  /**
   * Checks if in the user regions, some parent regions are missing (non-accessible to the user).
   * If so, it will fetch the missing regions, and add them to the `extraRegions`.
   */
  const checkForMissingParentRegions = async () => {
    const regionsWithMissingParents = getRegionsWithUnknownParent(
      getAllUserRegions(),
    ).map(extractId)

    if (regionsWithMissingParents.length === 0) return

    state.isLoading = true

    const missingRegions = await fetchMissingParentRegions(
      authModule,
      regionsWithMissingParents,
    )

    state.extraRegions = [...missingRegions]

    state.isLoading = false
  }

  /**
   * Returns the context region ID and all its sub-region IDs
   * If no context region is set, it will return all user region IDs
   */
  const getContextRegionIds = computedGetter(() => state.contextRegionsList)

  /**
   * Tells if the user has access to the region ID
   */
  const hasAccessToRegionId = (id: RegionId) =>
    getAllUserRegions().some(isRegion(id))

  /**
   * Tells if a region ID is active
   */
  const isActiveRegion = (id: RegionId) => getRegion(id)?.status === 'ACTIVE'

  const isContextRegionHandledByFrontend = () =>
    hasMainContextRegion() && !authModule.getContextRegionId()

  /**
   * We sync the RegionManager context with the authModule context region ID when it changes
   */
  watch(
    () => authModule.getContextRegionId(),
    (newId) => setMainContextRegionId(newId),
    { immediate: true },
  )

  /**
   * We make sure to fetch the parent regions that could be missing
   */
  watch(
    () => getAllUserRegions(),
    () => checkForMissingParentRegions(),
    { immediate: true },
  )

  return {
    getRegion,
    getAllUserRegions,
    getAllUserActiveRegions,
    getMainContextRegion,
    setMainContextRegionId,
    setContextRegionsList,
    getContextRegions,
    getContextRegionIds,
    hasMainContextRegion,
    getMissingParentRegions,
    hasAccessToRegionId,
    isActiveRegion,
    isContextRegionHandledByFrontend,
  }
}
