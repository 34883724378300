<template>
  <div>
    <AppLayoutDialogForm />
    <AppLayoutSnackBarLauncher />
    <AppLayoutAlertLauncher />
    <AppLayoutDialog />
    <AppLayoutFullPageDialog />
    <AppLayoutSideSheet :top="top" />
    <AppLayoutDialogLoading />
    <AppLayoutNotificationsChips />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import AppLayoutDialogForm from './AppLayoutDialogForm.vue'
import AppLayoutDialogLoading from './AppLayoutDialogLoading.vue'
import AppLayoutAlertLauncher from './AppLayoutAlertLauncher.vue'
import AppLayoutDialog from './AppLayoutDialog.vue'
import AppLayoutFullPageDialog from './AppLayoutFullPageDialog.vue'
import AppLayoutSnackBarLauncher from './AppLayoutSnackBarLauncher.vue'
import AppLayoutSideSheet from './AppLayoutSideSheet.vue'
import AppLayoutNotificationsChips from './AppLayoutNotificationsChips.vue'

export default Vue.extend({
  name: 'AppLayoutUtils',
  components: {
    AppLayoutDialog,
    AppLayoutFullPageDialog,
    AppLayoutAlertLauncher,
    AppLayoutDialogForm,
    AppLayoutDialogLoading,
    AppLayoutSnackBarLauncher,
    AppLayoutSideSheet,
    AppLayoutNotificationsChips,
  },
  props: {
    top: { type: Number, default: undefined },
  },
})
</script>
