<template>
  <div>
    <span class="contact-details--title pb-2">{{ $t(title) }}</span>
    <div
      v-for="(contact, index) in regionEmc"
      :key="index"
      class="pa-4 v-card v-card--flat v-sheet theme--light"
    >
      <div v-for="(value, key) in contact" :key="key">
        <v-row no-gutters class="ma-1">
          <v-col class="attribute-row-label" cols="4">
            <span>{{
              $t(createTranslationKey(`contacts-detail-labels.${key}`))
            }}</span>
          </v-col>
          <v-col class="ml-1 attribute-row-value">
            <div>
              <span>{{ value }}</span>
            </div>
          </v-col>
        </v-row>
        <v-divider style="opacity: 0.5" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { RegionContactType } from '@/tt-cta-contacts/types'
import {
  createCtaContactsManager,
  CtaContactsManager,
} from '@/tt-cta-contacts/CtaContacts'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'RegionContactDetails',
  inject: ['getItemHook'],
  data: () => ({
    regionEmc: [] as RegionContactType[],
    loading: false,
  }),
  computed: {
    title(): string {
      return this.createTranslationKey('emergency-contacts.region-contacts')
    },
    region(): string {
      return this.getItemHook().getRawValue('region')
    },
    ctaContactsManager(): CtaContactsManager {
      return createCtaContactsManager(this.$auth.getApi())
    },
  },
  watch: {
    async region(newValue) {
      if (newValue) {
        this.regionEmc = await this.fetchRegionEmergencyContacts()
        this.loading = false
      }
    },
  },
  async created() {
    if (this.region && this.regionEmc.length === 0) {
      this.regionEmc = await this.fetchRegionEmergencyContacts()
      this.loading = false
    }
  },
  methods: {
    async fetchRegionEmergencyContacts() {
      return this.ctaContactsManager.fetchRegionEmergencyContact(this.region)
    },
    createTranslationKey(key: string) {
      return `tt-entity-design.system-exception-tickets.${key}`
    },
  },
})
</script>
