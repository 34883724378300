export enum ComponentNames {
  OpenShiftRequestsColumnSkillThreshold = 'OpenShiftRequestsColumnSkillThreshold',
  OpenShiftRequestsAttrOvertimeImpact = 'OpenShiftRequestsAttrOvertimeImpact',
  OpenShiftRequestsAttrWeeklyHoursRemaining = 'OpenShiftRequestsAttrWeeklyHoursRemaining',
  OpenShiftRequestsActionBtn = 'OpenShiftRequestsActionBtn',
  OpenShiftRequestsShiftDateTime = 'OpenShiftRequestsShiftDateTime',
  OpenShiftRequestsActions = 'OpenShiftRequestsActions',
  OpenShiftRequestsApproveForm = 'OpenShiftRequestsApproveForm',
}

export enum CustomColumnNames {
  skillThreshold = 'skills',
  shiftDateTime = 'shiftDateTime',
}
