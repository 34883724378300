// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DataViewCategoriesTile from './DataViewCategoriesTile.vue'
import DataViewCategoriesColumns from './DataViewCategoriesColumns'
import DataViewCategoriesDetail from './DataViewCategoriesDetail.vue'
import DataViewCategoriesMetaCard from './DataViewCategoriesMetaCard.vue'
import DataViewCategoriesReference from './DataViewCategoriesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DataViewCategoriesTile', DataViewCategoriesTile)
    Vue.component('DataViewCategoriesDetail', DataViewCategoriesDetail)
    Vue.component('DataViewCategoriesMetaCard', DataViewCategoriesMetaCard)
    Vue.component('DataViewCategoriesReference', DataViewCategoriesReference)

    modularManager.addResourcePreset(
      Resources.DATA_VIEW_CATEGORIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DataViewCategoriesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_CATEGORIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DataViewCategoriesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_CATEGORIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewCategoriesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_CATEGORIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewCategoriesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_CATEGORIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DataViewCategoriesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
