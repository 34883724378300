// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointTourSchedulesTile from './CheckpointTourSchedulesTile.vue'
import CheckpointTourSchedulesColumns from './CheckpointTourSchedulesColumns'
import CheckpointTourSchedulesDetail from './CheckpointTourSchedulesDetail.vue'
import CheckpointTourSchedulesReference from './CheckpointTourSchedulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CheckpointTourSchedulesTile', CheckpointTourSchedulesTile)
    Vue.component(
      'CheckpointTourSchedulesDetail',
      CheckpointTourSchedulesDetail,
    )
    Vue.component(
      'CheckpointTourSchedulesReference',
      CheckpointTourSchedulesReference,
    )

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SCHEDULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSchedulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SCHEDULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointTourSchedulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SCHEDULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSchedulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SCHEDULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSchedulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
