<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="dayOfWeek" />
    <tt-attr-row name="rangeStartTime" />
    <tt-attr-row name="rangeEndTime" />
    <tt-attr-row name="beginDate" />
    <tt-attr-row name="zonePosition" />
    <tt-attr-row name="zone" />
    <tt-attr-row name="region" />
    <tt-attr-row name="sessions" />
    <!--
      DO NOT REMOVE: the Exceptions tab depends on this attribute being
      present in the entity.
      @see ./custom.ts
    -->
    <tt-attr-row v-if="calendarExceptionsEnabled" name="exceptionTypes" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'MobileRunsheetsDetail',
  inject: ['getItemHook'],
  computed: {
    calendarExceptionsEnabled(): boolean {
      return this.getItemHook().hasAttribute('exceptionTypes')
    },
  },
})
</script>
