// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeBillPayMatrixRateSettingsTile from './EmployeeBillPayMatrixRateSettingsTile.vue'
import EmployeeBillPayMatrixRateSettingsColumns from './EmployeeBillPayMatrixRateSettingsColumns'
import EmployeeBillPayMatrixRateSettingsDetail from './EmployeeBillPayMatrixRateSettingsDetail.vue'
import EmployeeBillPayMatrixRateSettingsMetaCard from './EmployeeBillPayMatrixRateSettingsMetaCard.vue'
import EmployeeBillPayMatrixRateSettingsReference from './EmployeeBillPayMatrixRateSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmployeeBillPayMatrixRateSettingsTile',
      EmployeeBillPayMatrixRateSettingsTile,
    )
    Vue.component(
      'EmployeeBillPayMatrixRateSettingsDetail',
      EmployeeBillPayMatrixRateSettingsDetail,
    )
    Vue.component(
      'EmployeeBillPayMatrixRateSettingsMetaCard',
      EmployeeBillPayMatrixRateSettingsMetaCard,
    )
    Vue.component(
      'EmployeeBillPayMatrixRateSettingsReference',
      EmployeeBillPayMatrixRateSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeBillPayMatrixRateSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeBillPayMatrixRateSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeBillPayMatrixRateSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeBillPayMatrixRateSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeBillPayMatrixRateSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
