<template>
  <v-dialog v-model="showDialog" :attach="attach" width="320" persistent>
    <template #activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :disabled="isReadOnly"
        v-bind="{
          ...vuetifyDefaults,
          label,
          loading,
          placeholder,
          errorMessages,
        }"
        prepend-inner-icon="event_note"
        readonly
        clearable
        v-on="on"
        @click:clear="clear"
      />
    </template>

    <v-card v-if="showDialog">
      <v-card-text class="pa-0">
        <v-date-picker
          v-model="state"
          v-bind="{ allowedDates, locale }"
          full-width
          scrollable
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="close">
          {{ $t('common.cancel.btn') }}
        </v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="!state" @click="save">
          {{ $t('common.ok.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import moment from 'moment-timezone'
import { PropType, VueConstructor } from 'vue'

import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import { FormHookProvider } from '@/tt-widget-components'

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

const formatDate = (date, userDateFormat) =>
  date ? moment(date, DEFAULT_DATE_FORMAT).format(userDateFormat) : ''

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider
>

export default (BaseInput as VueWithInjections).extend({
  name: 'DateField',
  inject: {
    formHook: { default: undefined },
  },
  props: {
    attach: {
      type: [Boolean, String, Object] as PropType<boolean | string | Node>,
      default: false,
    },
    allowedDates: {
      type: Function as PropType<(date: string) => boolean>,
      default: undefined,
    },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    locale: { type: String, default: 'en-us' },
    userDateFormat: { type: String, default: 'YYYY-MM-DD' },
    value: { type: String, default: null },
  },
  data: () => ({
    showDialog: false,
    vuetifyDefaults,
    state: null,
  }),
  computed: {
    formattedDate(): string {
      return formatDate(this.model, this.userDateFormat)
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || this.disabled
    },
  },
  methods: {
    save() {
      this.$emit('input', this.state)
      this.close()
    },
    clear() {
      this.$emit('input', null)
      this.$emit('blur')
    },
    close() {
      this.showDialog = false
      this.state = null
      this.$emit('blur')
    },
  },
})
</script>
