// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MessageBoardPostCategoriesTile from './MessageBoardPostCategoriesTile.vue'
import MessageBoardPostCategoriesColumns from './MessageBoardPostCategoriesColumns'
import MessageBoardPostCategoriesDetail from './MessageBoardPostCategoriesDetail.vue'
import MessageBoardPostCategoriesReference from './MessageBoardPostCategoriesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MessageBoardPostCategoriesTile',
      MessageBoardPostCategoriesTile,
    )
    Vue.component(
      'MessageBoardPostCategoriesDetail',
      MessageBoardPostCategoriesDetail,
    )
    Vue.component(
      'MessageBoardPostCategoriesReference',
      MessageBoardPostCategoriesReference,
    )

    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_CATEGORIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostCategoriesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_CATEGORIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MessageBoardPostCategoriesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_CATEGORIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostCategoriesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_CATEGORIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostCategoriesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
