import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { TextClass } from '@/helpers/text/typo'

export default [
  {
    attributeName: 'name',
    style: {
      cssClass: TextClass.BOLD,
    },
  },
  'contactName',
  'phoneNumber',
  'email',
] as ColumnInputDefinition[]
