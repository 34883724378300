<template>
  <div class="d-flex">
    <tt-attr name="effectiveDateTime" format="asDate" />
    <ClockTooltip
      v-if="displayClock"
      :date-name="'effectiveDateTime'"
      :tooltip-text="tooltipText"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import ClockTooltip from '../../ClockTooltip.vue'
import { formatDate } from '@/helpers/dates/formatDate'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsDateTimeIconColumn',
  components: { ClockTooltip },
  inject: ['getItemHook'],
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
  computed: {
    effectiveDate(): Date {
      return this.getItemHook().getRawValue('effectiveDateTime')
    },
    leaveType(): string {
      return this.getItemHook().get('type')
    },
    hasSelectedTime(): boolean {
      return formatDate(this.effectiveDate, 'HH:mm') !== '00:00'
    },
    displayClock(): boolean {
      return this.leaveType === 'leave-request' && this.hasSelectedTime
    },
  },
  created() {
    this.getItemHook().addAttribute('type')
  },
})
</script>
