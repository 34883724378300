// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveRequestsTile from './LeaveRequestsTile.vue'
import LeaveRequestsColumns from './LeaveRequestsColumns'
import LeaveRequestsDetail from './LeaveRequestsDetail.vue'
import LeaveRequestsMetaCard from './LeaveRequestsMetaCard.vue'
import LeaveRequestsReference from './LeaveRequestsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeaveRequestsTile', LeaveRequestsTile)
    Vue.component('LeaveRequestsDetail', LeaveRequestsDetail)
    Vue.component('LeaveRequestsMetaCard', LeaveRequestsMetaCard)
    Vue.component('LeaveRequestsReference', LeaveRequestsReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_REQUESTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveRequestsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUESTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveRequestsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUESTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveRequestsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUESTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveRequestsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUESTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveRequestsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
