// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DataViewsTile from './DataViewsTile.vue'
import DataViewsColumns from './DataViewsColumns'
import DataViewsDetail from './DataViewsDetail.vue'
import DataViewsMetaCard from './DataViewsMetaCard.vue'
import DataViewsReference from './DataViewsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DataViewsTile', DataViewsTile)
    Vue.component('DataViewsDetail', DataViewsDetail)
    Vue.component('DataViewsMetaCard', DataViewsMetaCard)
    Vue.component('DataViewsReference', DataViewsReference)

    modularManager.addResourcePreset(
      Resources.DATA_VIEWS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DataViewsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEWS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DataViewsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEWS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEWS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEWS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DataViewsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
