// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import Oauth2ClientsTile from './Oauth2ClientsTile.vue'
import Oauth2ClientsColumns from './Oauth2ClientsColumns'
import Oauth2ClientsDetail from './Oauth2ClientsDetail.vue'
import Oauth2ClientsMetaCard from './Oauth2ClientsMetaCard.vue'
import Oauth2ClientsReference from './Oauth2ClientsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('Oauth2ClientsTile', Oauth2ClientsTile)
    Vue.component('Oauth2ClientsDetail', Oauth2ClientsDetail)
    Vue.component('Oauth2ClientsMetaCard', Oauth2ClientsMetaCard)
    Vue.component('Oauth2ClientsReference', Oauth2ClientsReference)

    modularManager.addResourcePreset(
      Resources.OAUTH2_CLIENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'Oauth2ClientsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_CLIENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: Oauth2ClientsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_CLIENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'Oauth2ClientsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_CLIENTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'Oauth2ClientsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_CLIENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'Oauth2ClientsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
