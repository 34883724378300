import { EmployeeTab } from '../types'

export const overviewTab: EmployeeTab = {
  key: 'overview',
  title: 'tt-entity-design.leave-management.employee.overview.title',
  icon: 'mdi-account',
  is: 'EmployeeOverview',
}

export const leaveHistoryTab: EmployeeTab = {
  key: 'leave-history',
  title: 'tt-entity-design.leave-management.employee.leave-history.title',
  icon: 'mdi-chart-timeline-variant',
  is: 'LeaveHistory',
}

export const transactionActivityTab: EmployeeTab = {
  key: 'transaction-activity',
  title:
    'tt-entity-design.leave-management.employee.transaction-activity.title',
  icon: 'mdi-notification-clear-all',
  is: 'TransactionActivity',
}

export const settingsTab: EmployeeTab = {
  key: 'settings',
  title: 'tt-entity-design.leave-management.employee.settings.title',
  icon: 'mdi-cog-outline',
  is: 'SettingsTab',
}

export const DEFAULT_HOURS_PER_DAY = 8
