<template>
  <v-icon :color="color" v-text="icon" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { WidgetName } from '@/tt-widget-components/lib/names'
import { WidgetTypeDefinition } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'WidgetIcon',
  props: {
    widgetType: {
      type: String as PropType<WidgetName>,
      required: true,
    },
  },
  computed: {
    color(): string {
      return this.widgetConfig?.config?.color
    },
    icon(): string {
      return this.isDashboard ? 'dashboard' : this.widgetConfig?.config?.icon
    },
    isDashboard(): boolean {
      return (
        this.widgetType === WidgetName.DASHBOARD_WIDGET || !this.widgetConfig
      )
    },
    isTQLQuery(): boolean {
      return (
        this.widgetType === WidgetName.TQL_QUERY_WIDGET || !this.widgetConfig
      )
    },
    widgetConfig(): WidgetTypeDefinition {
      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        this.widgetType,
      )
    },
  },
})
</script>
