<template>
  <v-dialog
    v-model="dialog"
    :max-width="420"
    :scrollable="!$vuetify.breakpoint.xsOnly"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :close-on-content-click="false"
    :close-on-click="false"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on" :dirty-filter-count="dirtyFilterCount" />
    </template>
    <v-card flat class="level1">
      <v-toolbar flat color="level0" short dense>
        <v-app-bar-nav-icon>
          <v-icon small color="orange" v-text="`mdi-filter-variant`" />
        </v-app-bar-nav-icon>
        <v-toolbar-title
          style="font-size: 0.9em"
          v-text="$t('common.filters')"
        />
        <v-spacer />
        <v-btn small icon @click="dialog = false">
          <v-icon small v-text="`close`" />
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <EntityToolbarFilterCollapsibleSection
          v-for="(filter, i) in filters"
          :key="`filters-${i}`"
          v-model="model[filter.attributeName]"
          :resource="resource"
          :filter-label="filter.attributeName"
          :attributeName="filter.attributeName"
          :attribute-meta="filter.attributeMeta"
        />
        <EntityToolbarCustomFilterCollapsibleSection
          v-for="(filterName, i) in customFilterNames"
          :key="`custom-filters-${i}`"
          :value="value[filterName] && value[filterName].value"
          :filter-label="resource"
          :filter-name="filterName"
          :resource="resource"
          @input="setCustomFilter(filterName, $event)"
          @delete="setCustomFilter(filterName, null)"
        />
        <EntityToolbarFilterScopeCollapsibleSection
          v-for="(scopeName, i) in scopeFilterNames"
          :key="`scope-filters-${i}`"
          :scope-name="scopeName"
          :resource="resource"
          :value="currentScope"
          @input="setScopeFilter($event, scopeName)"
        />
      </v-card-text>
      <v-card-actions>
        <v-toolbar dense short flat class="level1">
          <v-btn
            text
            color="grey"
            @click="clear"
            v-text="$t(`common.clear_all.btn`)"
          />
          <v-spacer />
          <v-btn
            color="green"
            text
            @click="applyAndClose"
            v-text="$t(`common.apply.btn`)"
          />
        </v-toolbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { isEmpty } from '@/helpers/isEmpty'
import { Filter } from '@/tt-widget-components'
import { FilterSetting } from '@/tt-entity-filter/types'
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import EntityToolbarFilterCollapsibleSection from '@/tt-entity-filter/components/EntityToolbarFilterCollapsibleSection.vue'
import EntityToolbarCustomFilterCollapsibleSection from '@/tt-widget-components/components/widget-form/query-settings/EntityToolbarCustomFilterCollapsibleSection.vue'
import EntityToolbarFilterScopeCollapsibleSection from '@/tt-entity-filter/components/EntityToolbarFilterScopeCollapsibleSection.vue'
import { CustomFilter } from '@/tt-widget-factory/definitions'

export default ObjectInput.extend({
  name: 'EntityFilterDialog',
  components: {
    EntityToolbarFilterCollapsibleSection,
    EntityToolbarCustomFilterCollapsibleSection,
    EntityToolbarFilterScopeCollapsibleSection,
  },
  props: {
    value: {
      type: Object as PropType<Record<string, Filter | CustomFilter>>,
      default: () => ({}),
    },
    filters: {
      type: Array as PropType<FilterSetting[]>,
      default: () => [],
    },
    resource: {
      type: String,
      default: '',
    },
    customFilterNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    scopeFilterNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    currentScope: {
      type: Array as PropType<string[]>,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    dirtyFilterCount(): number {
      if (isEmpty(this.model)) {
        return 0
      }

      return Object.values(this.value).filter((item: Filter) => {
        return item && !isEmpty(item.value)
      }).length
    },
  },
  methods: {
    applyAndClose() {
      this.$emit('apply')
      this.dialog = false
    },
    clear() {
      this.$emit('input', {})
      this.$emit('removescope', this.scopeFilterNames)
      this.$emit('removeSearch')
      this.$nextTick(() => {
        this.$emit('apply')
        this.dialog = false
      })
    },
    setCustomFilter(filterName: string, value: Filter['value']) {
      const newFilter: Filter = {
        attribute: filterName,
        operator: 'EQUAL',
        value,
      }

      const newModel = { ...this.value, [filterName]: newFilter }

      this.$emit('input', newModel)
    },
    setScopeFilter(value: boolean, scopeName: string) {
      if (value) {
        this.$emit('addscope', scopeName)
      } else {
        this.$emit('removescope', scopeName)
      }
    },
  },
})
</script>
