<template>
  <!-- file formats -->
  <div id="file-formats-wrapper">
    <v-menu
      offset-y
      open-on-hover
      nudge-bottom="16px"
      class="file-formats"
      attach="#file-formats-wrapper"
      max-width="none"
    >
      <template #activator="{ on, attrs }">
        <p class="file-formats__trigger text-body-2">
          <span v-bind="attrs" v-on="on">
            {{ $t('tt-widget-sharable.file-upload.what-are-accepted-formats') }}
          </span>
        </p>
      </template>
      <v-card>
        <div class="d-flex file-formats__popover white--text">
          <!-- icon -->
          <div class="mr-3">
            <v-icon color="white" v-text="'mdi-check'" />
          </div>
          <!-- / icon -->

          <!-- formats -->
          <div class="text-body-2">
            <dl>
              <dt
                class="mt-1 mb-3"
                v-text="$t('tt-widget-sharable.file-upload.accepted-formats')"
              />
              <dd>
                {{ acceptedFileTypes.list }}
              </dd>
            </dl>
          </div>
          <!-- / formats -->
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  AcceptedFileTypesInterface,
  getAcceptedFileTypes,
} from '../accepted-file-types'

export default Vue.extend({
  name: 'FileUploadAvailableFileFormat',

  computed: {
    acceptedFileTypes(): AcceptedFileTypesInterface {
      return getAcceptedFileTypes()
    },
  },
})
</script>
<style lang="scss" scoped>
.file-formats {
  &__trigger {
    display: inline-block;
    color: #78889c;
    text-decoration: underline;
  }

  &__popover {
    width: 100%;
    border-radius: 4px;
    background-color: #343a40;
    padding: 16px;
  }
}
</style>
