<template>
  <v-app id="injected-leave-management-view">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <BoundingBox v-slot="{ height, initialized, top }">
          <app-window :closable="false" :style="`height: ${height}px`">
            <template #before>
              <v-icon small> arrow_back </v-icon>
            </template>
            <template #title="{ title }">
              <v-toolbar-title class="full-page-title pl-2">
                {{ title }}
              </v-toolbar-title>
            </template>
            <SetupView v-if="isViewActive('setup-view')" />
            <EmployeeView
              v-if="isViewActive('employee-view')"
              :employee-id="employeeId"
              :employee-region="employeeRegion"
              :allow-actions="allowActions"
            />
          </app-window>
          <AppLayoutUtils :layout-configuration="layoutConfiguration" />
        </BoundingBox>
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import SetupView from './setup/SetupView.vue'
import EmployeeView from './employee/EmployeeView.vue'
import CustomComponents from './CustomComponents'
import BoundingBox from '@/tt-widget-views/components/BoundingBox.vue'
import { Resources } from '@/tt-entity-design/src/types'
import { AllowEntityOperationsProvider, AllowEntityOption } from '@/types'
import { LeaveRequestProvider } from '@/tt-widget-views/leave-management/types'

Vue.use(CustomComponents)

export enum AvailableViews {
  SETUP = 'setup-view',
  EMPLOYEE = 'employee-view',
}

type Provider = AllowEntityOperationsProvider & LeaveRequestProvider
const allowManageResources: AllowEntityOption = [
  Resources.LEAVE_POLICIES as never,
  Resources.LEAVE_POLICY_ITEMS as never,
]

export default Vue.extend({
  name: 'LeaveManagementInjected',
  components: {
    ProvideLayoutManager,
    SetupView,
    EmployeeView,
    BoundingBox,
  },
  provide(): Provider {
    return {
      allowEntityCreation: allowManageResources,
      allowEntityDelete: false,
      allowEntityEdit: false,
      allowEntityActions: [Resources.LEAVE_REQUESTS],
      manageLRinPayroll: this.manageLRinPayroll,
    }
  },
  props: {
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({} as LayoutConfiguration),
    },
    view: {
      type: String as PropType<AvailableViews>,
      default: AvailableViews.SETUP,
    },
    employeeId: {
      type: Number,
      default: null,
    },
    employeeRegion: {
      type: Number,
      default: null,
    },
    allowActions: {
      type: Boolean,
      default: false,
    },
    manageLRinPayroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
  methods: {
    isViewActive(viewName: AvailableViews): boolean {
      return this.view === viewName
    },
  },
})
</script>
<style scoped>
.full-page-title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.3em;
}
</style>
