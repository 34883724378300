import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'report.id',
    headerText: 'tt-entity-design.report-fields.attrs.id',
  },
  {
    attributeName: 'report.account.name',
    headerText: 'tt-entity-design.report-fields.attrs.account',
  },
  {
    attributeName: 'templateField.label',
    headerText: 'tt-entity-design.report-fields.attrs.field-name',
  },
  {
    attributeName: 'valueLabel',
    headerText: 'tt-entity-design.report-fields.attrs.field-value',
  },
  {
    attributeName: 'report.reportTemplate',
    headerText: 'tt-entity-design.report-fields.attrs.template',
  },
  {
    attributeName: 'report.submittedOn',
    headerText: 'tt-entity-design.report-fields.attrs.submitted-on',
  },
  {
    attributeName: 'report.createdBy.name',
    headerText: 'tt-entity-design.report-fields.attrs.created-by',
  },
  {
    attributeName: 'report.status',
    headerText: 'tt-entity-design.report-fields.attrs.status',
  },
] as ColumnInputDefinition[]
