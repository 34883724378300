// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AlarmsTile from './AlarmsTile.vue'
import AlarmsColumns from './AlarmsColumns'
import AlarmsDetail from './AlarmsDetail.vue'
import AlarmsMetaCard from './AlarmsMetaCard.vue'
import AlarmsReference from './AlarmsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AlarmsTile', AlarmsTile)
    Vue.component('AlarmsDetail', AlarmsDetail)
    Vue.component('AlarmsMetaCard', AlarmsMetaCard)
    Vue.component('AlarmsReference', AlarmsReference)

    modularManager.addResourcePreset(Resources.ALARMS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'AlarmsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ALARMS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: AlarmsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ALARMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ALARMS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'AlarmsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ALARMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AlarmsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
