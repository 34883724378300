import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PresetTypes } from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'

import StatusColor from '../StatusColor.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('StatusColor', StatusColor)

    const accountRelationDetails: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.CHECKPOINT_TOURS, 'account'),
      is: 'tt-attr-relation',
      props: {
        name: 'account',
        type: PresetTypes.DETAILS_CARD,
      },
    }

    addPreviewTab(Resources.CHECKPOINT_TOURS, accountRelationDetails)
  },
}
