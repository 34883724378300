// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RolePermissionsTile from './RolePermissionsTile.vue'
import RolePermissionsColumns from './RolePermissionsColumns'
import RolePermissionsDetail from './RolePermissionsDetail.vue'
import RolePermissionsReference from './RolePermissionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('RolePermissionsTile', RolePermissionsTile)
    Vue.component('RolePermissionsDetail', RolePermissionsDetail)
    Vue.component('RolePermissionsReference', RolePermissionsReference)

    modularManager.addResourcePreset(
      Resources.ROLE_PERMISSIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'RolePermissionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_PERMISSIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: RolePermissionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_PERMISSIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RolePermissionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ROLE_PERMISSIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RolePermissionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
