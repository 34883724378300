import { DEFAULT_LANG, SupportedLocale } from '@/locales/language-options'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const shallowMerge = (acc, curr) => ({
  ...acc,
  ...curr,
})

const loadedLanguages: SupportedLocale[] = []

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: DEFAULT_LANG,
  messages: {},
  silentTranslationWarn: true,
})

const compileAllMessages = (locales: VueI18n.LocaleMessages[]) => {
  return locales.map((local) => local.default).reduce(shallowMerge, {})
}

const loadLocaleMessagesAsync = async (
  locale: SupportedLocale,
): Promise<void> => {
  if (loadedLanguages.includes(locale)) return

  const modules =
    typeof window !== 'undefined'
      ? (await import('./localesModules')).default
      : {}

  const asyncFileLoaders: Promise<any>[] = Object.entries(modules)
    .filter(([path]: any) => path.includes(`/${locale}.json`))
    .map(([_, loader]: any) => loader())

  return Promise.all(asyncFileLoaders)
    .then(compileAllMessages)
    .then((messages) => {
      i18n.setLocaleMessage(locale, messages)
      loadedLanguages.push(locale)
    })
}

/**
 * Will fetch all locale messages for the given lang (if not previously fetched) and will set i18n
 */
export const setI18nLocale = async (locale: SupportedLocale) => {
  const setI18nLang = () => (i18n.locale = locale)
  const logError = (err) =>
    console.error('Could not load i18n locale messages...', err)

  return loadLocaleMessagesAsync(locale).then(setI18nLang).catch(logError)
}

export const translateMap = (
  keyMap: Record<string, string>,
  locale?: string,
  values?: Record<string, string>,
): Record<string, string> => {
  const translateKey = (key: string): string =>
    i18n.t(key, locale, values) as string

  return Object.entries(keyMap).reduce(
    (acc, [key, tKey]) => ({ ...acc, [key]: translateKey(tKey) }),
    {},
  )
}

export default i18n
