// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SystemExceptionTicketsTile from './SystemExceptionTicketsTile.vue'
import SystemExceptionTicketsColumns from './SystemExceptionTicketsColumns'
import SystemExceptionTicketsDetail from './SystemExceptionTicketsDetail.vue'
import SystemExceptionTicketsMetaCard from './SystemExceptionTicketsMetaCard.vue'
import SystemExceptionTicketsReference from './SystemExceptionTicketsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SystemExceptionTicketsTile', SystemExceptionTicketsTile)
    Vue.component('SystemExceptionTicketsDetail', SystemExceptionTicketsDetail)
    Vue.component(
      'SystemExceptionTicketsMetaCard',
      SystemExceptionTicketsMetaCard,
    )
    Vue.component(
      'SystemExceptionTicketsReference',
      SystemExceptionTicketsReference,
    )

    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SystemExceptionTicketsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKETS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
