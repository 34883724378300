<template>
  <v-list-item class="pa-0">
    <v-list-item-icon v-if="$slots['icon']" class="my-3 mr-4">
      <slot name="icon"></slot>
    </v-list-item-icon>
    <v-list-item-content :style="contentStyle">
      <v-list-item-title>
        <v-tooltip
          :disabled="!chipText"
          right
          nudge-left="20"
          color="transparent"
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              :class="{ disabled }"
              style="font-size: 1.2em"
              v-on="on"
              v-text="title"
            />
          </template>
          <v-chip x-small
            ><span class="caption" v-text="$t(chipText)"
          /></v-chip>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-subtitle v-if="chipText" v-text="chipText" />
      <v-list-item-subtitle v-if="subtitle" v-text="subtitle" />
    </v-list-item-content>
    <v-list-item-action v-if="description">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn small icon color="blue" v-on="on">
            <v-icon small>help</v-icon>
          </v-btn>
        </template>
        <span v-text="description" />
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'TDropDownItemDetail',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    chipText: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object,
      default: (): object => ({}),
    },
  },
})
</script>

<style scoped>
.disabled {
  color: red;
  text-decoration: line-through;
}
</style>
