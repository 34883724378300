import {
  MeasureFormat,
  MeasureFormatFormValue,
  measureFormatFormValues,
  MeasureOperation,
} from '@/tt-widget-components/components/measure-types'

function getFormatFormValuesByValues(
  values: MeasureFormat[],
): MeasureFormatFormValue[] {
  return measureFormatFormValues.filter((formValue) =>
    values.includes(formValue.value),
  )
}

// @todo: handle that logic in json schema directly
export function getFormatFormValuesByMeasure(measure: MeasureOperation) {
  let ret: MeasureFormatFormValue[] = []

  if (measure === MeasureOperation.COUNT) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.ROUND_0,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  if (measure === MeasureOperation.SUM) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.NA,
      MeasureFormat.ROUND_0,
      MeasureFormat.ROUND_2,
      MeasureFormat.FLOOR,
      MeasureFormat.CEILING,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  if (measure === MeasureOperation.DISTINCT) {
    // todo

    return ret
  }

  if (measure === MeasureOperation.AVG) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.NA,
      MeasureFormat.ROUND_0,
      MeasureFormat.ROUND_2,
      MeasureFormat.FLOOR,
      MeasureFormat.CEILING,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  if (measure === MeasureOperation.MIN) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.NA,
      MeasureFormat.ROUND_0,
      MeasureFormat.ROUND_2,
      MeasureFormat.FLOOR,
      MeasureFormat.CEILING,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  if (measure === MeasureOperation.MAX) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.NA,
      MeasureFormat.ROUND_0,
      MeasureFormat.ROUND_2,
      MeasureFormat.FLOOR,
      MeasureFormat.CEILING,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  if (measure === MeasureOperation.EXPRESSION) {
    ret = getFormatFormValuesByValues([
      MeasureFormat.NA,
      MeasureFormat.ROUND_0,
      MeasureFormat.ROUND_2,
      MeasureFormat.FLOOR,
      MeasureFormat.CEILING,
      MeasureFormat.SUFFIX,
    ])

    return ret
  }

  return ret
}
