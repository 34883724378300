<template>
  <v-virtual-scroll
    v-if="userRegions.length"
    :items="userRegions"
    bench="5"
    min-width="300"
    height="200"
    item-height="45"
  >
    <template #default="{ item }">
      <ProvideItemHook
        :key="item.itemHook.getEntityId()"
        :provide="item.itemHook"
      >
        <slot :item-hook="item.itemHook" />
      </ProvideItemHook>
    </template>
  </v-virtual-scroll>

  <TNoData v-else />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EntityRecord } from '@/tt-widget-entity-flow/types'
import { filterRegions } from './utils'
import { UserRegion } from '../treeview/utils'
import TNoData from '@/tt-ui/components/TNoData.vue'

export default Vue.extend({
  name: 'RegionCollection',
  components: { TNoData },
  props: {
    search: { type: String, default: '' },
    showSelected: { type: Boolean, default: false },
    value: { type: Array as PropType<string[]>, default: () => [] },
    actions: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
    container: {
      type: String,
      default: 'v-list',
    },
    regions: {
      type: Array as PropType<UserRegion[]>,
      default: () => [],
    },
  },
  computed: {
    userRegions(): EntityRecord[] | null {
      const { showSelected, value, search } = this
      return this.injectEntityHook(
        filterRegions(this.regions, showSelected, value, search),
      )
    },
  },
  methods: {
    injectEntityHook(entities: any[]): EntityRecord[] | null {
      return entities.map((item: EntityRecord) => ({
        ...item,
        itemHook: new EntityItemHook(this.$appContext, {
          entity: item,
          resourceName: 'regions',
          entityId: item.id,
          availableActions: this.actions[item.id],
        }),
      }))
    },
  },
})
</script>
