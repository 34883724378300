// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetsTile from './MobileRunsheetsTile.vue'
import MobileRunsheetsColumns from './MobileRunsheetsColumns'
import MobileRunsheetsDetail from './MobileRunsheetsDetail.vue'
import MobileRunsheetsReference from './MobileRunsheetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileRunsheetsTile', MobileRunsheetsTile)
    Vue.component('MobileRunsheetsDetail', MobileRunsheetsDetail)
    Vue.component('MobileRunsheetsReference', MobileRunsheetsReference)

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
