// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeavePolicyItemsTile from './LeavePolicyItemsTile.vue'
import LeavePolicyItemsColumns from './LeavePolicyItemsColumns'
import LeavePolicyItemsDetail from './LeavePolicyItemsDetail.vue'
import LeavePolicyItemsMetaCard from './LeavePolicyItemsMetaCard.vue'
import LeavePolicyItemsReference from './LeavePolicyItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeavePolicyItemsTile', LeavePolicyItemsTile)
    Vue.component('LeavePolicyItemsDetail', LeavePolicyItemsDetail)
    Vue.component('LeavePolicyItemsMetaCard', LeavePolicyItemsMetaCard)
    Vue.component('LeavePolicyItemsReference', LeavePolicyItemsReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeavePolicyItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeavePolicyItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePolicyItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePolicyItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeavePolicyItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
