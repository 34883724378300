import metadataProvider from '@/tt-widget-factory/services/metadata-provider'

import * as Commons from './commons'
import { AppContext, AppContextEvents } from './types'

export * from './types'

export const events = (context: AppContext) => ({
  async [AppContextEvents.USER_SIGNED_IN]() {
    if (metadataProvider.isRemote()) {
      await metadataProvider.fetchAll(context)
    }
  },
})

export const builder = () => {
  return Commons.builder({ events })
}

export const create = (): AppContext => {
  return builder().build()
}
