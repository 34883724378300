<script lang="ts">
import { ResourceUpdatedInterface } from '@/tt-widget-entity-flow'
import QueryCache from '@/tt-widget-components/base/QueryCache'
import Vue from 'vue'

export default Vue.extend({
  name: 'QueryCacheManager',
  data() {
    return {
      queryCache: QueryCache,
      // Garbage collection
      gc: setInterval(() => {
        QueryCache.gc()
      }, 20000),
      unsubscribe: null,
    }
  },
  beforeDestroy() {
    if (this.gc) {
      clearInterval(this.gc)
    }
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  created() {
    this.unsubscribe = this.$eventManager.subscribeEvent(
      // EntityIntentTypes.RESOURCE_UPDATED,
      'resource-updated',
      (payload: ResourceUpdatedInterface) => {
        QueryCache.clearByTag(payload.resource)
      },
    )
  },
  render(h) {
    return h('span')
  },
})
</script>
