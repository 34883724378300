import { BaseField, FieldName } from '@/tt-fieldset-builder/base/types'

export const SIGNATURE = FieldName.Signature

export type SignatureOption = {
  // /**
  //  * Ink color
  //  */
  // inkColor?: 'BLUE' | 'BLACK' | 'RED'
  // /**
  //  * Disclosure
  //  */
  // disclosureText?: string
}

export type SignatureFieldType = BaseField & {
  type: typeof SIGNATURE
}
