// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowInstanceLogsTile from './WorkflowInstanceLogsTile.vue'
import WorkflowInstanceLogsColumns from './WorkflowInstanceLogsColumns'
import WorkflowInstanceLogsDetail from './WorkflowInstanceLogsDetail.vue'
import WorkflowInstanceLogsMetaCard from './WorkflowInstanceLogsMetaCard.vue'
import WorkflowInstanceLogsReference from './WorkflowInstanceLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowInstanceLogsTile', WorkflowInstanceLogsTile)
    Vue.component('WorkflowInstanceLogsDetail', WorkflowInstanceLogsDetail)
    Vue.component('WorkflowInstanceLogsMetaCard', WorkflowInstanceLogsMetaCard)
    Vue.component(
      'WorkflowInstanceLogsReference',
      WorkflowInstanceLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowInstanceLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
