<template>
  <v-card>
    <v-card-title>
      <v-icon> mdi-database </v-icon>
      <span class="pl-2">
        {{ resourceName }}
      </span>
    </v-card-title>
    <v-card-text>
      <div class="px-2">
        <v-text-field
          v-model="searchedAttribute"
          :placeholder="
            $t('widgets.resource_attributes_dialog.search_placeholder')
          "
          clearable
          append-icon="mdi-magnify"
          @click:clear="populateAttributesList"
          @click:append="searchAttribute"
        >
        </v-text-field>
      </div>
      <v-virtual-scroll
        v-if="!isSearchResultsEmpty"
        :items="validAttributesList"
        height="585"
        item-height="65"
      >
        <template #default="{ item }">
          <TDropDownItemDetail
            :title="item.labels.label"
            :subtitle="item.name"
            :description="item.rawType"
            :disabled="item.deprecated"
          />
        </template>
      </v-virtual-scroll>
      <div v-if="isSearchResultsEmpty" class="body-2">
        {{ $t('widgets.resource_attributes_dialog.search_no_results') }}
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import {
  Attribute,
  AttributeDictionary,
} from '@/tt-widget-factory/services/resource-meta/types'
import Vue, { PropType } from 'vue'
import TDropDownItemDetail from '@/tt-ui/components/TDropDownItemDetail.vue'
import debounce from 'lodash/debounce'

export default Vue.extend({
  name: 'ResourceAttributesDialog',
  components: {
    TDropDownItemDetail,
  },
  props: {
    validAttributes: {
      type: Object as PropType<AttributeDictionary>,
      required: true,
    },
    resourceName: String,
  },
  data() {
    return {
      validAttributesList: new Array<Attribute>(),
      searchedAttribute: '',
    }
  },
  computed: {
    isSearchResultsEmpty(): boolean {
      return this.validAttributesList.length == 0
    },
  },
  watch: {
    searchedAttribute(newValue: string, oldValue: string) {
      if (newValue) {
        this.searchAttribute()
      } else {
        this.populateAttributesList()
      }
    },
  },
  created() {
    this.populateAttributesList()
  },
  methods: {
    populateAttributesList() {
      this.validAttributesList = Object.values(this.validAttributes)
    },
    searchAttribute: debounce(function () {
      // @ts-ignore
      this.validAttributesList = this.validAttributesList.filter(
        (attribute: Attribute) => {
          // @ts-ignore
          return attribute.name.includes(this.searchedAttribute)
        },
      )
    }, 500),
  },
})
</script>
