<template>
  <ExceptionTypesUnassignBtn />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ExceptionTypeGroupItemActionsColumn',
})
</script>
