<template>
  <div>
    <v-row no-gutters class="ma-1">
      <v-col class="attribute-row-label" :cols="cols || 4">
        <span v-text="$t('tt-entity-design.link')" />
      </v-col>
      <v-col class="ml-1 attribute-row-value">
        <a
          target="_blank"
          :href="link"
          v-text="$t('tt-entity-design.google-map')"
        />
      </v-col>
    </v-row>
    <v-divider style="opacity: 0.5" />
  </div>
</template>

<script lang="ts">
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor } from 'vue'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'DispatachCustomLocationsGoogleMapLink',
  inject: ['getItemHook'],
  computed: {
    link(): string {
      const latitude = this.getItemHook().getRawValue('latitude')
      const longitude = this.getItemHook().getRawValue('longitude')

      return `https://www.google.com/maps/place/${latitude},${longitude}`
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
})
</script>

<style scoped>
.attribute-row-label {
  color: #888;
  font-size: 0.8em;
}

.attribute-row-value {
  font-size: 0.8em;
}
</style>
