// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionBillSettingsTile from './PositionBillSettingsTile.vue'
import PositionBillSettingsColumns from './PositionBillSettingsColumns'
import PositionBillSettingsDetail from './PositionBillSettingsDetail.vue'
import PositionBillSettingsMetaCard from './PositionBillSettingsMetaCard.vue'
import PositionBillSettingsReference from './PositionBillSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionBillSettingsTile', PositionBillSettingsTile)
    Vue.component('PositionBillSettingsDetail', PositionBillSettingsDetail)
    Vue.component('PositionBillSettingsMetaCard', PositionBillSettingsMetaCard)
    Vue.component(
      'PositionBillSettingsReference',
      PositionBillSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_BILL_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionBillSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILL_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionBillSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILL_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionBillSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILL_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionBillSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILL_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionBillSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
