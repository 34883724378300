<template>
  <div class="flex--column">
    <ResourceAllowedOperations
      v-slot="{ allowsActions: appAllowsActions }"
      :resource-name="operationCenterResource"
      class="flex--column"
    >
      <div v-if="appAllowsActions" class="flex--row align-center pa-3">
        <v-expand-transition>
          <span
            v-if="editMode && hasChanged"
            class="caption ttPrimary--text"
            v-text="$t('tt-entity-design.operation-centers.unsaved-changes')"
          />
        </v-expand-transition>

        <v-spacer />

        <div class="d-flex align-center">
          <span
            class="overline font-weight-light pr-3"
            v-text="$t('common.edit.btn')"
          />

          <div style="position: relative">
            <v-switch
              v-if="canUpdateRegions"
              :key="switchKey"
              :input-value="editMode"
              color="ttPrimary"
              hide-details
              class="pt-0 mt-0 overline"
              @click="editModeClick"
            />

            <v-tooltip v-else left>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-fab-transition>
                    <v-icon small v-on="on" v-text="`mdi-lock`" />
                  </v-fab-transition>
                </div>
              </template>

              <span
                v-text="
                  $t(
                    `tt-entity-design.operation-centers.cannot-modify-assigned-regions`,
                  )
                "
              />
            </v-tooltip>
          </div>
        </div>
      </div>

      <v-divider />

      <div class="flex--column" style="position: relative">
        <TLoadingBar v-if="isLoading" />

        <!-- VIEW MODE  -->
        <TreeviewSelector
          v-if="!editMode && apiRegionIds.length"
          :value="apiRegionIds"
          :items="treeItems"
          read-only
          class="pa-3 flex--column overflow-auto"
        />

        <!-- EDIT MODE -->
        <TreeviewSelector
          v-else-if="editMode"
          :key="`${editMode}`"
          v-model="draftRegionIds"
          :items="lazyLoadedTreeItems"
          :on-opening-children="fetchChildrenRegionOperationCenters"
          class="pa-3 flex--column overflow-auto"
          hide-batch-selection
        >
          <template #checkbox="{ item, onItemClick, isSelected }">
            <v-simple-checkbox
              v-if="canBeSelected(item)"
              dense
              hide-details
              :value="isSelected(item)"
              color="ttPrimary"
              class="d-flex"
              @input="onItemClick(item)"
            />
            <v-tooltip v-else top>
              <template #activator="{ on }">
                <v-icon
                  class="faded"
                  v-on="on"
                  v-text="`mdi-checkbox-blank-off-outline`"
                />
              </template>
              {{ $t('tt-entity-design.operation-centers.already-assigned') }}
              {{ getAssignedOperationCenterName(item) }}
            </v-tooltip>
          </template>

          <template #name="{ item, onItemClick, isSelected }">
            <span
              :class="{
                faded: !canBeSelected(item),
                'ttPrimary--text': isSelected(item),
              }"
              :style="
                canBeSelected(item) ? 'cursor: pointer' : 'cursor: default'
              "
              @click="() => canBeSelected(item) && onItemClick(item)"
            >
              {{ item.name }}
              <span v-if="getAssignedOperationCenterName(item)">
                - ({{ getAssignedOperationCenterName(item) }})
              </span>
            </span>
          </template>
        </TreeviewSelector>

        <TNoData v-else-if="!isLoading" />

        <div v-else class="h-100" />
      </div>

      <v-expand-transition>
        <div v-if="editMode" class="flex--row pa-3 elevation-6">
          <v-btn color="" small elevation="0" class="mr-6" @click="cancel">
            {{ $t('common.exit.btn') }}
          </v-btn>

          <v-spacer />

          <v-btn
            :disabled="!hasChanged"
            :loading="isLoading"
            color="success"
            small
            elevation="0"
            @click="save"
          >
            {{ $t('common.save_changes.btn') }}
          </v-btn>
        </div>
      </v-expand-transition>
    </ResourceAllowedOperations>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActionProperty, Action } from '@/tt-entity-design/src/schema-types'

import { Resources } from '@/tt-entity-design/src/types'
import { updateDOM } from '@/helpers/dom/updateDOM'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { getAttr } from './utils'
import TreeviewSelector from '@/tt-widget-components/components/treeview/TreeviewSelector.vue'
import {
  convertRegionsToTreeItems,
  findItem,
  UserRegion,
} from '@/tt-widget-components/components/treeview/utils'
import { TreeviewItem } from '@/tt-widget-components/components/treeview/types'
import TNoData from '@/tt-ui/components/TNoData.vue'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { cloneData } from '@/helpers/cloneData'
import { ItemId } from '@/tt-widget-components/components/regions/RegionTreeSelector.vue'
const { OPERATION_CENTERS } = Resources
const REPLACE_REGIONS: Action<typeof OPERATION_CENTERS> = 'replace-regions'

type SyncRegionsProperty = ActionProperty<
  typeof OPERATION_CENTERS,
  typeof REPLACE_REGIONS
>

type OtherOperationCenter = {
  id: number
  name: string
}

type RegionOperationCenterMap = Record<ItemId, OtherOperationCenter>

/**
 * Vuetify Treeview will lazy load children when the `children` property is an empty array.
 */
const replaceChildrenByEmptyArray = (item: TreeviewItem): TreeviewItem => ({
  ...item,
  children: item.children?.length ? [] : undefined,
})

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCentersRegions',
  components: { TreeviewSelector, TNoData },
  inject: ['getItemHook'],
  data() {
    return {
      isLoading: true,
      editMode: false,
      /**
       * The region ids that are currently selected in the API.
       * Should not be changed by the UI.
       */
      apiRegionIds: [] as string[],
      /**
       * The region ids that are currently selected in the UI.
       * This is the draft state that will be saved to the API.
       */
      draftRegionIds: [] as string[],
      /**
       * Used to force the switch to update when the edit mode is toggled and cancelled.
       */
      switchKey: 0,
      /**
       * Map of REGION -> OPERATION_CENTER assignment.
       * Used in edit mode to show which regions can be assigned to the current operation center.
       */
      regionOperationCenterMap: {} as RegionOperationCenterMap,
      /**
       * Edit mode treeItems. Each node will have a `lazyLoadedChildren` property with the children of the node.
       * This is used to load the children of the node when the node is expanded.
       */
      lazyLoadedTreeItems: [] as TreeviewItem[],
    }
  },
  computed: {
    hasChanged(): boolean {
      return (
        this.apiRegionIds.some(
          (apiID) => !this.draftRegionIds.includes(apiID),
        ) ||
        this.draftRegionIds.some(
          (draftID) => !this.apiRegionIds.includes(draftID),
        )
      )
    },
    regions(): UserRegion[] {
      return this.$appContext.regionManager.getAllUserActiveRegions()
    },
    operationCenterResource(): string {
      return OPERATION_CENTERS
    },
    canUpdateRegions(): boolean {
      return this.getItemHook().isActionAvailable(REPLACE_REGIONS)
    },
    operationCenterId(): number {
      return this.getItemHook().getEntityId()
    },
    treeItems(): TreeviewItem[] {
      return convertRegionsToTreeItems(
        this.$appContext.regionManager,
        this.regions,
      )
    },
  },
  created() {
    this.fetchSelectedRegionIds()
  },
  methods: {
    getAssignedOperationCenterName(region: TreeviewItem): string {
      return this.regionOperationCenterMap[region.id]?.name ?? ''
    },
    canBeSelected(item: TreeviewItem): boolean {
      return (
        this.regionOperationCenterMap[item.id] === null ||
        this.regionOperationCenterMap[item.id]?.id === this.operationCenterId
      )
    },
    async fetchChildrenRegionOperationCenters(
      item: TreeviewItem,
    ): Promise<void> {
      this.isLoading = true

      const directChildrenIds = this.$appContext.regionManager
        .getAllUserRegions()
        .filter((region) => region.parentId === item.id)
        .map(({ id }) => id)

      if (!directChildrenIds.length) {
        this.isLoading = false

        return
      }

      /**
       * We intentionally mutate the children property of the item
       * https://v2.vuetifyjs.com/en/components/treeview/#load-children
       */
      item.children = [...findItem(this.treeItems, item.id).children].map(
        replaceChildrenByEmptyArray,
      )

      await this.fetchRegionOperationCenter(directChildrenIds)

      this.isLoading = false
    },
    async fetchRegionOperationCenter(regionIds: string[]): Promise<void> {
      const response = await this.$auth
        .getApi()
        .getAll<{
          region: number
          operationCenter: OtherOperationCenter
        }>(Resources.OPERATION_CENTER_REGIONS, {
          fields: [
            { attribute: 'region' },
            { attribute: 'operationCenter.id' },
            { attribute: 'operationCenter.name' },
          ],
          filters: [
            {
              attribute: 'region',
              operator: 'IN',
              value: regionIds,
            },
            {
              attribute: 'operationCenter',
              operator: 'NOTIN',
              value: this.operationCenterId,
            },
          ],
          returnCount: false,
          limit: TTC_API_MAX_LIMIT,
        })
        .then((response) => {
          if (response.total > TTC_API_MAX_LIMIT) {
            console.error(
              'The number of regions to fetch exceeds the API limit:',
              response.total,
            )
          }

          return response.items
        })

      const addEntry = (acc, childrenRegionId): RegionOperationCenterMap => {
        const isChildrenRegion = ({ region }) =>
          String(region) === childrenRegionId
        const operationCenterObj =
          response.find(isChildrenRegion)?.operationCenter

        const newEntry: OtherOperationCenter = operationCenterObj?.id
          ? {
              id: operationCenterObj.id,
              name: operationCenterObj.name,
            }
          : null

        return {
          ...acc,
          [childrenRegionId]: newEntry,
        }
      }

      this.regionOperationCenterMap = regionIds.reduce(
        addEntry,
        this.regionOperationCenterMap,
      )
    },
    async fetchSelectedRegionIds(): Promise<void> {
      this.isLoading = true

      const selectedRegionIds = await this.$auth
        .getApi()
        .getAll<{ id: number }>(
          OPERATION_CENTERS +
            '/' +
            this.operationCenterId +
            '/' +
            getAttr('regions'),
          {
            fields: [{ attribute: 'id' }],
            returnCount: false,
            limit: TTC_API_MAX_LIMIT,
          },
        )
        .then(({ items }) => items.map(({ id }) => String(id)))

      this.apiRegionIds = [...selectedRegionIds]
      this.draftRegionIds = [...selectedRegionIds]

      this.isLoading = false
    },
    async editModeClick() {
      await updateDOM()

      if (this.editMode) {
        this.cancel()
      } else {
        this.isLoading = true
        this.editMode = true

        this.lazyLoadedTreeItems = cloneData(this.treeItems).map(
          replaceChildrenByEmptyArray,
        )

        const topParentIds = this.lazyLoadedTreeItems.map((item) => item.id)

        await this.fetchRegionOperationCenter(topParentIds)

        this.isLoading = false
      }
    },
    cancel(): void {
      const closeEditMode = () => {
        this.draftRegionIds = [...this.apiRegionIds]
        this.editMode = false
      }

      if (this.hasChanged) {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
          message: this.$t('widgets.discard_changes_question') as string,
          accept: closeEditMode,
          acceptColor: 'error',
          cancel: () => {
            // we force re-render the switch to update the state
            this.switchKey += 1
          },
          acceptText: this.$t('widgets.discard_changes') as string,
        })
      } else {
        closeEditMode()
      }
    },
    async save(): Promise<void> {
      this.isLoading = true

      await updateDOM()

      const payload: Record<SyncRegionsProperty, number[]> = {
        regions: this.draftRegionIds.map((id) => Number(id)),
      }

      await this.$auth
        .getApi()
        .doAction(
          OPERATION_CENTERS,
          this.operationCenterId,
          REPLACE_REGIONS,
          payload,
        )
        .then(async () => {
          this.editMode = false

          await this.fetchSelectedRegionIds()
        })
        .catch((error) => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: error.message,
          })
        })
      this.isLoading = false
    },
  },
})
</script>
