// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionPaySettingsTile from './PositionPaySettingsTile.vue'
import PositionPaySettingsColumns from './PositionPaySettingsColumns'
import PositionPaySettingsDetail from './PositionPaySettingsDetail.vue'
import PositionPaySettingsMetaCard from './PositionPaySettingsMetaCard.vue'
import PositionPaySettingsReference from './PositionPaySettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionPaySettingsTile', PositionPaySettingsTile)
    Vue.component('PositionPaySettingsDetail', PositionPaySettingsDetail)
    Vue.component('PositionPaySettingsMetaCard', PositionPaySettingsMetaCard)
    Vue.component('PositionPaySettingsReference', PositionPaySettingsReference)

    modularManager.addResourcePreset(
      Resources.POSITION_PAY_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionPaySettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAY_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionPaySettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAY_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPaySettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAY_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPaySettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PAY_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionPaySettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
