import { Priority } from './types'

const DISPATCH_TASK_PRIORITY_CLASS: Record<Priority, string> = {
  LOW: 'green--text green',
  IMPORTANT: 'orange--text orange',
  URGENT: 'red--text red lighten-5',
}

const DEFAULT_PRIORITY_COLOR = 'grey--text grey'

export const getPriorityClass = (priority: string) =>
  DISPATCH_TASK_PRIORITY_CLASS[priority] || DEFAULT_PRIORITY_COLOR

// Format hours and minutes to two digits
export const formatHoursMinutes = (hours: number, minutes: number): string => {
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}
