import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'firstName',
  'lastName',
  'jobTitle',
  'primaryPhone',
  'secondaryPhone',
  {
    headerText: 'tt-entity-design.asset-hub.asset-person.actions.column-title',
    component: {
      is: 'AssetContactsActions',
    },
    style: {
      width: 100,
    },
  },
] as ColumnInputDefinition[]
