<template>
  <v-dialog
    v-if="hasAtLeastOnePage"
    :width="width"
    scrollable
    :value="hasAtLeastOnePage"
    :persistent="persistent"
    :fullscreen="fullscreen"
    @input="onStateChange"
  >
    <AppWindow
      :value="hasAtLeastOnePage"
      closable
      :page-stack-handler="pageStackHandler"
      @input="onStateChange"
    >
    </AppWindow>
  </v-dialog>
</template>

<script lang="ts">
import BaseAppLayoutComponent from './BaseAppLayoutComponent'
import GenericForm from './GenericForm.vue'
import { createPageStackHandler } from '../PageStackHandler'
import {
  DialogFormInterface,
  LayoutComponents,
  LayoutWindowEvent,
  PageInterface,
} from '../types'

export default BaseAppLayoutComponent.extend({
  name: 'AppDialogForm',
  components: { GenericForm },
  data() {
    return {
      pageStackHandler: createPageStackHandler(),
      dialogFormSubscribe: null,
    }
  },
  computed: {
    activePage(): PageInterface {
      return this.pages.slice(-1)[0]
    },
    activePageOptions(): DialogFormInterface {
      return this.activePage?.props?.payload
    },
    fullscreen(): boolean {
      return this.$vuetify?.breakpoint?.smAndDown
    },
    hasAtLeastOnePage(): boolean {
      return this.pageStackHandler.hasAtLeastOnePage()
    },
    pages(): PageInterface[] {
      return this.pageStackHandler.getPageStack()
    },
    persistent(): boolean {
      return !(this.activePageOptions?.closeOnOutsideClick ?? false)
    },
    width(): number {
      return this.activePageOptions?.width ?? 600
    },
  },
  beforeDestroy() {
    if (this.dialogFormSubscribe) {
      this.dialogFormSubscribe()
    }
  },
  mounted() {
    this.dialogFormSubscribe = this.$eventManager.subscribeEvent(
      LayoutWindowEvent.DIALOG_FORM,
      (payload: DialogFormInterface) => {
        const page = this.wrapDialogFormPayloadWithGenericForm(payload)
        this.pageStackHandler.next(page)
      },
    )
  },
  methods: {
    close(): void {
      this.pageStackHandler.reset()
      this.layoutManager.close(LayoutComponents.dialogForm)
    },
    dispatchAlert(message: string): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message,
      })
    },
    onStateChange(isOpening: boolean): void {
      if (!isOpening) this.close()
    },
    wrapDialogFormPayloadWithGenericForm(
      payload: DialogFormInterface,
    ): PageInterface {
      return {
        is: 'GenericFormPage',
        props: { payload },
      }
    },
  },
})
</script>
