<template>
  <v-card class="position-card d-flex align-center pa-2">
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-space-between w-100">
        <div class="d-flex align-baseline text-truncate">
          <v-icon color="ttPrimary" size="18" v-text="'mdi-badge-account'" />
          <tt-attr
            class="text-truncate caption font-weight-medium ml-1"
            name="assignedGroupResource.object.name"
          />
        </div>
        <v-chip x-small label class="text-uppercase font-weight-bold ml-2">
          <tt-attr
            v-show="hasCustomId"
            class="text-truncate"
            name="assignedGroupResource.object.customId"
          />
          <tt-attr
            v-show="!hasCustomId"
            class="text-truncate"
            name="assignedGroupResource.object.id"
          />
        </v-chip>
      </div>
      <div v-show="hasAccount" class="d-flex align-baseline text-truncate">
        <v-icon color="ttPrimary" size="18" v-text="'mdi-domain'" />
        <tt-attr
          class="text-truncate caption ml-1"
          name="assignedGroupResource.object.account.name"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'DispatchTasksPositionCard',
  inject: ['getItemHook'],
  computed: {
    hasCustomId() {
      return !!this.getItemHook().getRawValue(
        'assignedGroupResource.object.customId',
      )
    },
    hasAccount() {
      return !!this.getItemHook().getRawValue(
        'assignedGroupResource.object.account.name',
      )
    },
  },
})
</script>

<style scoped>
.position-card {
  position: relative;
  width: 100%;
  min-width: 250px;
}
</style>
