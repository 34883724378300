import { LeavePolicyItemsComponents } from '@/tt-entity-design/src/components/leave-policy-items/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { LeavePoliciesCustomComponents } from '../../types'

const LeavePolicyItemsCustomColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'customId',
    style: {
      width: 150,
    },
  },
  {
    headerText: 'res.leave-policy-items.attr.name.label',
    component: {
      is: 'ClickableNameColumn',
    },
  },
  {
    attributeName: 'accrualRate',
    headerText:
      'tt-entity-design.leave-management.leave-policy-items.is-accrued',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsAccruedColumn,
    },
  },
  'carryOver',
  {
    headerText: 'tt-entity-design.leave-management.leave-policy-items.part-of',
    component: {
      is: LeavePoliciesCustomComponents.LeavePolicyItemsPartOfColumn,
    },
  },
  'leaveType',
  'entitlementUnit',
  {
    headerText: 'res.leave-policy-items.attr.quantity.label',
    component: {
      is: 'LeavePolicyItemsQuantityColumn',
    },
  },
  {
    attributeName: 'createdOn',
    format: 'asDate',
  },
]
export default LeavePolicyItemsCustomColumns
