<template>
  <tt-tile>
    <tt-attr slot="title" name="taskType.name" />
    <tt-attr slot="line2" name="name" />
  </tt-tile>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ScheduleGroupsViewProvide } from '@/tt-widget-views/mobile-schedule-groups/types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ScheduleGroupsViewProvide>
).extend({
  inject: ['getItemHook', 'periodicAssignmentEnabled'],
  created() {
    if (this.periodicAssignmentEnabled) {
      this.getItemHook().addAttribute('periodicSchedule.id')
    }
  },
})
</script>
