import { AuthModule } from '@tracktik/tt-authentication'
import { Field } from 'tracktik-sdk/lib/common/entity'

import { Filter } from '@/tt-widget-components'
import {
  FilterOperatorType,
  ResourceDataManagerInterface,
  WidgetServices,
} from '@/tt-widget-factory'

import { Resources } from '@/tt-entity-design/src/types'

type CheckpointTour = { id: number }
type ReportTemplate = { id: number }
type AssetType = { name: string }
type Asset = { name: string; type: AssetType }
type TaskSiteInstruction = {
  id: number
  checkPointTour?: number
  jobInstructions?: string
  schedulableAssets: Array<Asset>
}
type TaskType = { reportTemplate?: number }

const defaultFieldNames: string[] = ['id']

const apiField = (attribute: string): Field => ({ attribute })

const apiFilter = (
  attribute: Filter['attribute'],
  value: Filter['value'],
  operator = FilterOperatorType.EQUAL,
): Filter => ({ attribute, operator, value })

/**
 * Return null if the item is archived. The List endpoints omit items that are
 * archived, the Find endpoints don't.
 */
const getSingleItemFromCollection = async <T>(
  services: { resourceDataManager: ResourceDataManagerInterface },
  resource: string,
  filtersObj: Record<string, Filter['value']>,
  fieldNames: string[] = defaultFieldNames,
): Promise<T | null> => {
  const filters = Object.entries(filtersObj).map(([key, value]) =>
    apiFilter(key, value),
  )

  const { items } = await services.resourceDataManager.getCollection({
    resource,
    fields: fieldNames.map(apiField),
    filters,
    limit: 1,
  })

  return items[0] ?? null
}

export const getCheckpointTour = async (
  { widgetServices }: { widgetServices?: WidgetServices },
  checkPointTourId: number,
  fieldNames?: string[],
): Promise<CheckpointTour | null> => {
  return getSingleItemFromCollection<CheckpointTour>(
    widgetServices,
    Resources.CHECKPOINT_TOURS,
    { id: checkPointTourId },
    fieldNames,
  )
}

export const getReportTemplate = async (
  { widgetServices }: { widgetServices?: WidgetServices },
  reportTemplateId: number,
  fieldNames: string[] = defaultFieldNames,
): Promise<ReportTemplate | null> => {
  return getSingleItemFromCollection<ReportTemplate>(
    widgetServices,
    Resources.REPORT_TEMPLATES,
    { id: reportTemplateId },
    fieldNames,
  )
}

export const getTaskSiteInstruction = async (
  { widgetServices }: { widgetServices?: WidgetServices },
  filters: { clientId: number; taskTypeId: number },
  fieldNames: string[] = defaultFieldNames,
): Promise<TaskSiteInstruction> => {
  const { clientId, taskTypeId } = filters
  if (!clientId || !taskTypeId) return null

  return getSingleItemFromCollection<TaskSiteInstruction>(
    widgetServices,
    Resources.TASK_SITE_INSTRUCTIONS,
    { site: clientId, taskType: taskTypeId },
    fieldNames,
  )
}

export const getTaskType = async (
  { authModule }: { authModule: AuthModule },
  taskTypeId: number,
  fieldNames: string[] = defaultFieldNames,
): Promise<TaskType> => {
  if (!taskTypeId) return null

  return authModule
    .getApi()
    .get<number, TaskType>(Resources.TASK_TYPES, taskTypeId, {
      fields: fieldNames.map(apiField),
    })
}
