import { codemirror } from 'vue-codemirror'
import { VueConstructor } from 'vue'

import AccountContextJsonField from './components/AccountContextJsonField.vue'
import BooleanList from './components/BooleanList.vue'
import CurrencyField from './components/CurrencyField.vue'
import DateField from './components/DateField.vue'
import DateTimeField from './components/DateTimeField.vue'
import DateTimeFieldWrapper from './components/DateTimeFieldWrapper.vue'
import EntityMultipleSelectorField from './components/EntityMultipleSelectorField.vue'
import EntitySelectorField from './components/EntitySelectorField.vue'
import ImageField from './components/ImageField.vue'
import RegionSelectorField from './components/RegionSelectorField.vue'
import RelationField from './components/RelationField.vue'
import RelationTreeField from './components/RelationTreeField.vue'
import ReportFlagForm from './components/ReportFlagForm.vue'
import SectionDivider from './components/SectionDivider.vue'
import TimeField from './components/TimeField.vue'
import TextAreaField from './components/TextAreaField.vue'
import FileHandlerProvider from './components/FileHandlerProvider.vue'
import FileUpload from './components/FileUpload.vue'
import FileUploadAvailableFileFormat from './components/FileUploadAvailableFileFormat.vue'
import ViewReportsFields from './components/ViewReportsFields.vue'
import LanguageSelectorField from './components/LanguageSelectorField.vue'
import BooleanSelector from './components/BooleanSelector.vue'

const DrawCanvasField = () => import('./components/DrawCanvasField.vue')

export { getResourceDefinitions } from './EntityViewDefinitions'

export default {
  install(Vue: VueConstructor) {
    Vue.component('RegionSelectorField', RegionSelectorField)
    Vue.component('EntitySelectorField', EntitySelectorField)
    Vue.component('AccountContextJsonField', AccountContextJsonField)
    Vue.component('EntityMultipleSelectorField', EntityMultipleSelectorField)
    Vue.component('CurrencyField', CurrencyField)
    Vue.component('DateField', DateField)
    Vue.component('DateTimeField', DateTimeField)
    Vue.component('TimeField', TimeField)
    Vue.component('RelationField', RelationField)
    Vue.component('RelationTreeField', RelationTreeField)
    Vue.component('Codemirror', codemirror)
    Vue.component('DrawCanvasField', DrawCanvasField)
    Vue.component('ImageField', ImageField)
    Vue.component('ReportFlagForm', ReportFlagForm)
    Vue.component('BooleanList', BooleanList)
    Vue.component('DateTimeFieldWrapper', DateTimeFieldWrapper)
    Vue.component('TextAreaField', TextAreaField)
    Vue.component('FileHandlerProvider', FileHandlerProvider)
    Vue.component('FileUpload', FileUpload)
    Vue.component(
      'FileUploadAvailableFileFormat',
      FileUploadAvailableFileFormat,
    )
    Vue.component('SectionDivider', SectionDivider)
    Vue.component('ViewReportsFields', ViewReportsFields)
    Vue.component('LanguageSelectorField', LanguageSelectorField)
    Vue.component('BooleanSelector', BooleanSelector)
  },
}
