import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { Resources } from '../../types'

import ContractsDetailBilling from './ContractsDetailBilling.vue'
import ContractsDetailDates from './ContractsDetailDates.vue'

export default {
  install(Vue) {
    Vue.component('ContractsDetailBilling', ContractsDetailBilling)
    Vue.component('ContractsDetailDates', ContractsDetailDates)

    // Adding extra detail cards
    const billingDetail: PresetItem = {
      title: 'tt-entity-design.billing',
      data: { is: 'ContractsDetailBilling', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    const datesDetail: PresetItem = {
      title: 'tt-entity-design.dates',
      data: { is: 'ContractsDetailDates', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.CONTRACTS,
      PresetTypes.DETAILS_CARD,
      [billingDetail, datesDetail],
    )
  },
}
