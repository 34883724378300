<template>
  <div v-if="assignedTasksCount || runsheetStatus">
    <div v-if="completionPercentage !== null" class="flex--column">
      <span v-if="assignedTasksCount" class="overline">
        {{ assignedTasksLabel }}: {{ assignedTasksCount }}
      </span>

      <span class="pb-1 overline">
        {{ runsheetProgressLabel }} : {{ completionPercentage }}%
      </span>

      <v-progress-linear
        color="ttPrimary"
        :value="completionPercentage"
        class="rounded-sm"
      />

      <div class="flex--row align-center pt-1">
        <MobileScheduleOccurrenceLogStatus
          v-if="runsheetStatus"
          :status="runsheetStatus"
          class="pr-2"
        />
        <EntityPresetRelation
          v-if="runsheetCurrentSite"
          resource-name="clients"
          :entity="runsheetCurrentSite"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import MobileScheduleOccurrenceLogStatus from '../../mobile-schedule-occurrence-logs/MobileScheduleOccurrenceLogStatus.vue'
import { ExtensionWorkStatus, ExtensionWorkStatusCurrentSite } from './types'

export default Vue.extend({
  name: 'MobileDevicesWorkStatus',
  components: { EntityPresetRelation, MobileScheduleOccurrenceLogStatus },
  props: {
    workStatus: {
      type: Object as PropType<ExtensionWorkStatus>,
      default: null,
    },
  },
  computed: {
    runsheetProgressLabel(): string {
      return this.$t('tt-entity-design.mobile-devices.runsheet-progress')
    },
    assignedTasksLabel(): string {
      return this.$t('tt-entity-design.mobile-devices.assigned-tasks')
    },
    assignedTasksCount(): number {
      return this.workStatus?.assignedTasksCount || 0
    },
    runsheetName(): string {
      return this.workStatus?.runsheetStatus?.runsheetName || ''
    },
    runsheetStatus(): string {
      return this.workStatus?.runsheetStatus?.status || ''
    },
    completionPercentage(): number | null {
      const completed = this.workStatus?.runsheetStatus?.completedItemCount ?? 0
      const total = this.workStatus?.runsheetStatus?.totalItemCount ?? 0

      if (!total) return null

      return Math.round(100 * (completed / total))
    },
    runsheetCurrentSite(): ExtensionWorkStatusCurrentSite | null {
      return this.workStatus?.runsheetStatus?.currentSite ?? null
    },
  },
})
</script>

<style scoped>
.v-progress-linear {
  margin-bottom: 8px;
}
</style>
