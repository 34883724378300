import { AggregationSort } from '@/tt-widget-components'
import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'
import { JSONSchema7Array } from '@tracktik/tt-json-schema-form'

const defaultSort: Readonly<AggregationSort> & JSONSchema7Array = [
  { type: 'MEASURE', direction: 'ASC' },
]

const HorizontalChartWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'HorizontalChartWidget',
  'Horizontal Chart',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)

HorizontalChartWidgetBuilder.addDataSetPropertie({
  sort: {
    allOf: [{ $ref: '#/definitions/AggregationSort' }],
    default: defaultSort,
  },
})

HorizontalChartWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/HorizontalChartOptions',
  },
})

HorizontalChartWidgetBuilder.addDefinitions({
  HorizontalChartOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      color: {
        title: 'Color',
        $ref: '#/definitions/Color',
      },
      target: {
        title: 'Target Value',
        type: 'number',
      },
    },
    if: {
      properties: { target: { const: '' } },
    },
    then: {},
    else: {
      properties: {
        targetLineColor: {
          title: 'Target Line Color',
          $ref: '#/definitions/Color',
        },
        disableClick: {
          title: 'Disable Click',
          $ref: '#/definitions/DisableClick',
        },
      },
    },
  },
})

export default HorizontalChartWidgetBuilder.schema
