<template>
  <div>
    <json-field name="name" />
    <json-field name="details" />
    <json-field name="category" />
    <json-field name="type" />
    <json-field
      name="defaultLanguage"
      :placeholder="$t(defaultLanguagePlaceholder)"
    />
    <json-field name="tag" :label="$t(reportTagPlaceholder)" />
    <json-field name="translatable" as="BooleanSelector" />
    <json-field name="approveAutomatically" />
    <json-field name="adminOnly" :label="$t(adminOnlyPlaceholder)" />
    <json-field name="notifyAlertQueue" />
    <json-field name="excludeFromShiftReport" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ReportTemplatesForm',
  inject: ['formHook'],
  computed: {
    adminOnlyPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.admin-only.placeholder'
    },
    defaultLanguagePlaceholder(): string {
      return 'tt-entity-design.report-templates.form.default-language.placeholder'
    },
    reportTagPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.tag.placeholder'
    },
  },
})
</script>
