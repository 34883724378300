<template>
  <div>
    <div class="text-center level1">
      <img
        class="white--text pa-3 align-center"
        :height="112"
        :style="{ opacity: 0.8 }"
        :src="thumbnail"
      />
    </div>
    <div>
      <WidgetIcon slot="icon" :widget-type="item.is" />
      <span>
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { WidgetStoreModel, WidgetTypeDefinition } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'DashboardWidgetCard',
  props: {
    item: {
      type: Object as PropType<WidgetStoreModel>,
      required: true,
    },
  },
  computed: {
    thumbnail(): string {
      return this.widgetConfig?.config?.thumbnail || ''
    },
    widgetConfig(): WidgetTypeDefinition {
      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        this.item.is,
      )
    },
  },
})
</script>
