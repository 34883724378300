import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { Resources } from '@/tt-entity-design/src/types'
import { ConflictsStatusColors } from '@/tt-widget-views/leave-management/human-capital-management/types'

export const HumanCapitalManagementConflictsColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'id',
    style: {
      width: 110,
    },
  },
  {
    attributeName: 'account',
    style: {
      width: 200,
    },
  },
  {
    attributeName: 'conflictType',
    style: {
      width: 300,
    },
  },
  {
    headerText: 'res.leave-requests.attr.status.label',
    attributeName: 'status',
    component: {
      is: 'StatusChip',
      props: {
        statusColorOptions: ConflictsStatusColors,
        resource: Resources.CONFLICTS,
      },
    },
    style: {
      width: 150,
    },
  },
  'conflictInfo',
]

export const HumanCapitalManagementConflictsDownloadableColumns: ColumnInputDefinition[] =
  [
    {
      attributeName: 'id',
    },
    {
      attributeName: 'account.name',
    },
    {
      attributeName: 'conflictType',
    },
    {
      headerText: 'res.leave-requests.attr.status.label',
      attributeName: 'status',
    },
    { attributeName: 'conflictInfo' },
  ]
