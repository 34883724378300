<template>
  <tt-attr name="type" v-text="$t(translationKey)" />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'TransactionActivityTransactionTypeColumn',
  inject: ['getItemHook'],
  computed: {
    transactionType(): string {
      return this.getItemHook().get('type')
    },
    translationKey(): string {
      return `tt-entity-design.leave-management.employee.transaction-activity.attr-values.name.${this.transactionType}`
    },
  },
})
</script>
