<template>
  <CollapsibleHeader :opened="opened">
    <template #title>
      <v-tooltip top :disabled="!isLabelOverflowing">
        <span v-text="label" />
        <template #activator="{ on }">
          <span class="pl-4 hide-overflow" v-on="on">
            <span
              ref="label"
              :class="{ 'has-value': hasValue }"
              v-text="label"
            />
            <v-badge v-if="!!value" color="green" dot inline />
          </span>
        </template>
      </v-tooltip>
    </template>

    <template #right>
      <slot name="right">
        <v-btn v-if="!!value" icon x-small @click.stop="$emit('delete')">
          <v-icon small v-text="`close`" />
        </v-btn>
      </slot>
    </template>

    <v-card flat min-height="90" min-width="250" class="level0 pa-0 ma-0">
      <CustomFilterField
        v-model="model"
        :resource="resource"
        :filter-name="filterName"
      />
    </v-card>
  </CollapsibleHeader>
</template>

<script lang="ts">
import Vue from 'vue'

import CollapsibleHeader from '@/tt-widget-entity-flow/components/CollapsibleHeader.vue'
import { isElementOverflowing } from '@/helpers/dom'
import { isEmpty } from '@/helpers/isEmpty'

import CustomFilterField from '../../filters/CustomFilterField.vue'

export default Vue.extend({
  name: 'EntityToolbarCustomFilterCollapsibleSection',
  components: { CollapsibleHeader, CustomFilterField },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      default: undefined,
    },
  },
  data: () => ({
    isLabelOverflowing: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.value
      },
      set(value: any) {
        this.$emit('input', value)
      },
    },
    label(): string {
      // @todo: to be replaced by i18n translation when available (API-1029)
      return this.$appContext.widgetServices.resourceMetaManager.getCustomFilter(
        this.resource,
        this.filterName,
      ).labels.label
    },
    hasValue(): boolean {
      return !isEmpty(this.value)
    },
  },
  mounted() {
    this.checkLabelOverflow()
  },
  updated() {
    this.checkLabelOverflow()
  },
  methods: {
    async checkLabelOverflow() {
      await Vue.nextTick()
      const labelElement = this.$refs['label'] as Element
      this.isLabelOverflowing = isElementOverflowing(labelElement)
    },
  },
})
</script>

<style scoped>
.hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.has-value {
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}
</style>
