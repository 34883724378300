<template>
  <div v-if="showToggleOnly">
    <v-switch
      v-model="model"
      class="pl-4"
      color="green"
      as="VSwitch"
      hide-details
      :label="$t('widget_edit.include_inactive.title')"
      @change="changeIncludeInactive($event)"
    />
  </div>
  <div v-else>
    <CollapsibleHeader>
      <template #title>
        <span class="ml-4">
          <span v-text="$t('widget_edit.include_inactive.title')"></span>
          <v-badge v-if="model" color="green" dot inline />
        </span>
      </template>
      <InfoBox>
        <span v-text="$t('widget_edit.include_inactive.description')" />
      </InfoBox>
      <div class="text-center align-center mb-5">
        <v-switch
          v-model="model"
          class="pl-4"
          color="green"
          as="VSwitch"
          :label="$t('widget_edit.include_inactive.title')"
          @change="changeIncludeInactive($event)"
        />
      </div>
    </CollapsibleHeader>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { IncludeArchived } from '@/plugins/o11n'

export default Vue.extend({
  name: 'IncludeInactiveInput',
  props: {
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    showToggleOnly: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
  },
  data() {
    return {
      model: null,
    }
  },
  watch: {
    model(val: boolean | null) {
      this.$emit('input', val)
    },
    value(val: boolean | null) {
      if (val !== this.model) {
        this.model = val
      }
    },
  },
  created() {
    this.model = this.value
  },
  methods: {
    changeIncludeInactive(isSelected: boolean): void {
      const label = isSelected.toString()
      const event = IncludeArchived.create({ label })
      this.$analytics.track(event)
    },
  },
})
</script>
<style lang="scss" scoped>
@import 'vuetify/src/styles/settings/_variables.scss';

::v-deep .v-label {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 10px;
  }
}
</style>
