import { VueConstructor } from 'vue'

import { Drag, Drop } from 'vue-drag-drop'

import JsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import DataSetSortInput from './DataSetSortInput.vue'
import DataSetSortObjectInput from './DataSetSortObjectInput.vue'
import CustomFilterRegionRelation from './regions/CustomFilterRegionRelation.vue'
import RegionListFilter from '@/tt-widget-components/components/regions/RegionListFilter.vue'
import RegionListWrapper from '@/tt-widget-components/components/regions/RegionListWrapper.vue'
import RegionCollection from '@/tt-widget-components/components/regions/RegionCollection.vue'
import SortDirectionInput from '@/tt-widget-components/components/SortDirectionInput.vue'
import WidgetErrorDetails from '@/tt-widget-components/components/WidgetErrorDetails.vue'
import WidgetIcon from './WidgetIcon.vue'
import WidgetContextToolbar from '@/tt-widget-components/components/WidgetContextToolbar.vue'
import WidgetContextFiltersChips from '@/tt-widget-components/components/WidgetContextFiltersChips.vue'
import WidgetContainer from '@/tt-widget-components/components/WidgetContainer.vue'
import ColorPaletteSelector from '@/tt-widget-components/components/ColorPaletteSelector.vue'
import ColorSelector from '@/tt-widget-components/components/ColorSelector.vue'
import ColorPicker from '@/tt-widget-components/components/ColorPicker.vue'
import IncludeInactiveInput from '@/tt-widget-components/components/IncludeInactiveInput.vue'
import ResourceDimensionSelector from '@/tt-widget-components/components/ResourceDimensionSelector.vue'
import ResourceSelector from '@/tt-widget-components/components/ResourceSelector.vue'
import ResourceMeasureSelector from '@/tt-widget-components/components/ResourceMeasureSelector.vue'
import ResourceScopeSelector from '@/tt-widget-components/components/ResourceScopeSelector.vue'
import RichTextInput from '@/tt-widget-components/components/RichTextInput.vue'
import WidgetEditTitle from '@/tt-widget-components/components/WidgetEditTitle.vue'
import WidgetEditView from '@/tt-widget-components/components/WidgetEditView.vue'
import CollectionQueryForm from './CollectionQueryForm.vue'
import QuerySortInput from './QuerySortInput.vue'
import QuerySortObjectInput from './QuerySortObjectInput.vue'
import VerticalStackCard from '@/tt-widget-components/components/VerticalStackCard.vue'
import TqlEditor from '@/tt-tql-inputs/src/components/TqlEditor.vue'
import ResourceAttributeSelector from '@/tt-widget-components/components/ResourceAttributeSelector.vue'
import ContextAttributeMapEditForm from '@/tt-widget-components/components/ContextAttributeMapEditForm.vue'
import DataToolbarInput from '@/tt-widget-components/components/widget-form/toolbar/DataToolbarInput.vue'
import AttributeFilterInput from '@/tt-widget-components/components/AttributeFilterInput.vue'
import CustomFilterInput from '@/tt-widget-components/components/CustomFilterInput.vue'
import InfoBox from '@/tt-widget-components/components/InfoBox.vue'
import ProvideDataSetResource from '@/tt-widget-components/components/ProvideDataSetResource.vue'
import ProvideResource from '@/tt-widget-components/components/ProvideResource.vue'
import ProvideTQLResource from '@/tt-widget-components/components/ProvideTQLResource.vue'
import ProvideCollectionResource from '@/tt-widget-components/components/ProvideCollectionResource.vue'
import CaptionBox from '@/tt-widget-components/components/CaptionBox.vue'
import WhereQLWidgetFormInput from '@/tt-widget-components/components/WhereQLWidgetFormInput.vue'
import HavingQLWidgetFormInput from '@/tt-widget-components/components/HavingQLWidgetFormInput.vue'
import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'
import WidgetTabEditor from '@/tt-widget-components/components/WidgetTabEditor.vue'
import DataSetFiltersEditor from '@/tt-widget-components/components/DataSetFiltersEditor.vue'
import WidgetTitleToolbar from '@/tt-widget-components/components/WidgetTitleToolbar.vue'
import WidgetTitle from '@/tt-widget-components/components/WidgetTitle.vue'
import WidgetWrapper from '@/tt-widget-components/components/WidgetWrapper.vue'
import WidgetDragSearch from '@/tt-widget-components/components/WidgetDragSearch.vue'
import WidgetContextStateEditor from '@/tt-widget-components/components/WidgetContextStateEditor.vue'
import IconSelector from '@/tt-widget-components/components/IconSelector.vue'
import ResourceAttributesDialog from '@/apps/app.tracktik.insights.studio/components/ResourceAttributesDialog.vue'
import WidgetLoader from '@/tt-widget-components/components/WidgetLoader.vue'
import WidgetDetails from '@/tt-widget-components/components/WidgetDetails.vue'
import WidgetDetailsListItem from '@/tt-widget-components/components/WidgetDetailsListItem.vue'
import WidgetAllowDownloadField from '@/tt-widget-components/components/WidgetAllowDownloadField.vue'
import FiltersAppliedToWidgets from '@/tt-widget-components/components/FiltersAppliedToWidgets.vue'
import AppWindowAggregationClickSubscriber from '@/tt-widget-components/components/AppWindowAggregationClickSubscriber'
import AggregationPageWrapper from '@/tt-widget-components/components/AggregationPageWrapper.vue'
import FakeProgressBar from '@/tt-widget-components/components/FakeProgressBar.vue'
import DisableClickViewDetailsSwitch from './DisableClickViewDetailsSwitch.vue'
import EnableColumnHeaderFiltersSwitch from './EnableColumnHeaderFiltersSwitch.vue'

export default {
  install(Vue: VueConstructor) {
    Vue.component(
      'AppWindowAggregationClickSubscriber',
      AppWindowAggregationClickSubscriber,
    )
    Vue.component('DataSetSortInput', DataSetSortInput)
    Vue.component('WidgetDetailsListItem', WidgetDetailsListItem)
    Vue.component('WidgetAllowDownloadField', WidgetAllowDownloadField)
    Vue.component('DataSetSortObjectInput', DataSetSortObjectInput)
    Vue.component('CustomFilterRegionRelation', CustomFilterRegionRelation)
    Vue.component('IconSelector', IconSelector)
    Vue.component('RegionListFilter', RegionListFilter)
    Vue.component('RegionListWrapper', RegionListWrapper)
    Vue.component('RegionCollection', RegionCollection)
    Vue.component('SortDirectionInput', SortDirectionInput)
    Vue.component('WidgetContextStateEditor', WidgetContextStateEditor)
    Vue.component('WidgetErrorDetails', WidgetErrorDetails)
    Vue.component('WidgetIcon', WidgetIcon)
    Vue.component('ProvideDataSetResource', ProvideDataSetResource)
    Vue.component('ProvideResource', ProvideResource)
    Vue.component('ProvideTQLResource', ProvideTQLResource)
    Vue.component('ProvideCollectionResource', ProvideCollectionResource)
    Vue.component('IncludeInactiveInput', IncludeInactiveInput)
    Vue.component('IncludeInactiveInput', IncludeInactiveInput)
    Vue.component('WidgetTitle', WidgetTitle)
    Vue.component('DataSetFiltersEditor', DataSetFiltersEditor)
    Vue.component('WidgetTitleToolbar', WidgetTitleToolbar)
    Vue.component('WidgetWrapper', WidgetWrapper)
    Vue.component('JsonPretty', JsonPretty)
    Vue.component('WidgetTabEditor', WidgetTabEditor)
    Vue.component('WidgetEditView', WidgetEditView)
    Vue.component('VerticalStackCard', VerticalStackCard)
    Vue.component('Drag', Drag)
    Vue.component('InfoBox', InfoBox)
    Vue.component('WidgetJsonViewer', WidgetJsonViewer)
    Vue.component('CaptionBox', CaptionBox)
    Vue.component('CollectionQueryForm', CollectionQueryForm)
    Vue.component('QuerySortInput', QuerySortInput)
    Vue.component('QuerySortObjectInput', QuerySortObjectInput)
    Vue.component('Drop', Drop)
    Vue.component('WidgetContextToolbar', WidgetContextToolbar)
    Vue.component('WidgetContextFiltersChips', WidgetContextFiltersChips)
    Vue.component('WidgetContainer', WidgetContainer)
    Vue.component('ColorPaletteSelector', ColorPaletteSelector)
    Vue.component('ColorSelector', ColorSelector)
    Vue.component('ColorPicker', ColorPicker)
    Vue.component('ResourceDimensionSelector', ResourceDimensionSelector)
    Vue.component('ResourceAttributesDialog', ResourceAttributesDialog)
    Vue.component('ResourceMeasureSelector', ResourceMeasureSelector)
    Vue.component('ResourceAttributeSelector', ResourceAttributeSelector)
    Vue.component('ResourceSelector', ResourceSelector)
    Vue.component('ResourceScopeSelector', ResourceScopeSelector)
    Vue.component('TqlEditor', TqlEditor)
    Vue.component('RichTextInput', RichTextInput)
    Vue.component('WidgetEditTitle', WidgetEditTitle)
    Vue.component('ContextAttributeMapEditForm', ContextAttributeMapEditForm)
    Vue.component('DataToolbarInput', DataToolbarInput)
    Vue.component('AttributeFilterInput', AttributeFilterInput)
    Vue.component('CustomFilterInput', CustomFilterInput)
    Vue.component('WhereQLWidgetFormInput', WhereQLWidgetFormInput)
    Vue.component('HavingQLWidgetFormInput', HavingQLWidgetFormInput)
    Vue.component('WidgetDragSearch', WidgetDragSearch)
    Vue.component('WidgetLoader', WidgetLoader)
    Vue.component('WidgetDetails', WidgetDetails)
    Vue.component('FiltersAppliedToWidgets', FiltersAppliedToWidgets)
    Vue.component('AggregationPageWrapper', AggregationPageWrapper)
    Vue.component('FakeProgressBar', FakeProgressBar)
    Vue.component(
      'DisableClickViewDetailsSwitch',
      DisableClickViewDetailsSwitch,
    )
    Vue.component(
      'EnableColumnHeaderFiltersSwitch',
      EnableColumnHeaderFiltersSwitch,
    )
  },
}
