import { EntityIntentTypes, EntityPreviewIntentInterface } from './types'
import { LayoutWindowEvent, PageInterface } from '@/tt-app-layout'
import { BaseEntityIntent } from '@/tt-widget-entity-flow/intents/BaseEntityIntent'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { createResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
/**
 * Entity Preview Intent
 */
export class EntityPreviewIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.PREVIEW
  }

  /**
   * Return the previwe page
   * @param payload
   */
  getPage(payload: EntityPreviewIntentInterface): PageInterface {
    const component = payload.is || 'EntityPreview'

    if (!payload.itemHook) {
      payload.itemHook = new EntityItemHook(this.appContext, {
        resourceName: payload.resourceName,
        entityId: payload.entityId,
        entity: payload.entity,
      })
    }

    return {
      is: component,
      title: payload.title || createResourceKey(payload.resourceName),
      props: payload,
    }
  }

  /**
   * Payload
   * @param payload
   */
  async run(payload: EntityPreviewIntentInterface) {
    const page: PageInterface = {
      title: 'components.entity-preview.detail',
      ...this.getPage(payload),
    }

    if (payload.activeWindow && payload.hasToAddPages) {
      payload.activeWindow.next(page)
      return
    }

    const layoutWindowEvent = payload.hasToAddPages
      ? LayoutWindowEvent.SIDE_SHEET_ADD_PAGE
      : LayoutWindowEvent.SIDE_SHEET

    this.appContext.eventManager.dispatchEvent(layoutWindowEvent, {
      title: 'components.entity-preview.detail',
      ...this.getPage(payload),
    })
  }
}
