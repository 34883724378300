<template>
  <span>
    <TChipLabelBoolean
      v-if="showAsText"
      :is-truthy="isTruthy"
      :truthy="{
        textKey: createI18nStatusKey('OPEN'),
        textColor: 'orange',
        backgroundColor: 'transparent',
      }"
      :falsy="{
        textKey: createI18nStatusKey('CLOSED'),
        backgroundColor: 'transparent',
      }"
      :modifiers="{ 'x-small': '' }"
    />

    <TChipLabelBoolean
      v-else
      :is-truthy="isTruthy"
      :truthy="{
        textKey: createI18nStatusKey('OPEN'),
        textColor: 'white',
        backgroundColor: 'orange',
      }"
      :falsy="{
        textKey: createI18nStatusKey('CLOSED'),
      }"
      :modifiers="{ 'x-small': '' }"
    />
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'CheckpointTourSessionsStatusAttr',
  inject: ['getItemHook'],
  props: {
    showAsText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: null,
      truthyStrings: ['Open', 'true'],
    }
  },
  computed: {
    isTruthy(): boolean {
      return this.truthyStrings.includes(this.status)
    },
  },
  created() {
    this.status = this.getItemHook().get('status')
  },
  methods: {
    createI18nStatusKey: (value: string) =>
      createI18nEnumKey('checkpoint-tour-sessions', 'status', value),
  },
})
</script>
