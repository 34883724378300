// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagGroupAssignmentsTile from './ReportFlagGroupAssignmentsTile.vue'
import ReportFlagGroupAssignmentsColumns from './ReportFlagGroupAssignmentsColumns'
import ReportFlagGroupAssignmentsDetail from './ReportFlagGroupAssignmentsDetail.vue'
import ReportFlagGroupAssignmentsReference from './ReportFlagGroupAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportFlagGroupAssignmentsTile',
      ReportFlagGroupAssignmentsTile,
    )
    Vue.component(
      'ReportFlagGroupAssignmentsDetail',
      ReportFlagGroupAssignmentsDetail,
    )
    Vue.component(
      'ReportFlagGroupAssignmentsReference',
      ReportFlagGroupAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUP_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUP_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagGroupAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUP_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUP_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
