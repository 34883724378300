import Vue, { VueConstructor } from 'vue'
import { ActiveWindowProvider } from '@/tt-app-layout'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { StatusColorType } from '@/tt-ui/types'

export type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

export enum ConflictActions {
  RESOLVE = 'resolve',
  DISMISS = 'dismiss',
}

export type ConflictActionType = `${ConflictActions}`

export type ConflictAction = {
  type: ConflictActionType
  label: string
  color: string
}

export enum ConflictsStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  DISMISSED = 'DISMISSED',
}

export const ConflictsStatusColorsOptions: Record<
  ConflictsStatus,
  StatusColorType
> = {
  [ConflictsStatus.OPEN]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [ConflictsStatus.RESOLVED]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [ConflictsStatus.DISMISSED]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}
