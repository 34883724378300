import Vue from 'vue'
import WorkspaceLauncherExtension from './WorkspaceLauncherExtension.vue'
import { Breakpoint } from 'vuetify/types/services/breakpoint'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

Vue.component('WorkspaceLauncherExtension', WorkspaceLauncherExtension)

export const WORKSPACE_LAUNCHER_EXTENSION = 'extension.workspace.tracktik.com'

export default {
  name: WORKSPACE_LAUNCHER_EXTENSION,
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  enabled(extension: ExtensionInterface, breakpoint: Breakpoint) {
    return breakpoint.mdAndUp
  },
  component: {
    is: 'WorkspaceLauncherExtension',
  },
} as ExtensionInterface
