// Enter your custom installation here

import { addResourceDocument } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import DataViewsDoc from '@/tt-entity-design/src/components/data-views/DataViewsDoc'

export default {
  install(Vue) {
    // Add the document
    addResourceDocument(Resources.DATA_VIEWS, DataViewsDoc)
  },
}
