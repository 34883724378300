// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetOccurrencesTile from './MobileRunsheetOccurrencesTile.vue'
import MobileRunsheetOccurrencesColumns from './MobileRunsheetOccurrencesColumns'
import MobileRunsheetOccurrencesDetail from './MobileRunsheetOccurrencesDetail.vue'
import MobileRunsheetOccurrencesReference from './MobileRunsheetOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileRunsheetOccurrencesTile',
      MobileRunsheetOccurrencesTile,
    )
    Vue.component(
      'MobileRunsheetOccurrencesDetail',
      MobileRunsheetOccurrencesDetail,
    )
    Vue.component(
      'MobileRunsheetOccurrencesReference',
      MobileRunsheetOccurrencesReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
