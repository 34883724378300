<template>
  <v-menu v-model="menuOpen" max-width="300px" :close-on-content-click="false">
    <template #activator="{ on }">
      <v-text-field v-model="model" clearable v-bind="fieldProps" v-on="on">
        <template #append>
          <v-icon v-if="model" :color="model"> mdi-circle </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-color-picker v-model="colorPicker" mode="hexa"></v-color-picker>
      <v-card-actions>
        <v-btn @click="cancel">
          <span v-text="$t('common.cancel.btn')" />
        </v-btn>
        <v-spacer />
        <v-btn dark color="ttPrimary" @click="saveColor">
          <span v-text="$t('common.save.btn')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menuOpen: false,
      colorPicker: '',
    }
  },
  computed: {
    fieldProps(): any {
      return { ...{ outlined: true, dense: true }, ...this.$attrs }
    },
    model: {
      get(): string {
        return this.value
      },
      set(newValue: string): void {
        this.$emit('input', newValue || null)
      },
    },
  },
  watch: {
    value(newValue) {
      const hexValue = this.toHex(newValue)
      this.colorPicker = hexValue
    },
  },
  methods: {
    cancel() {
      this.colorPicker = this.model
      this.menuOpen = false
    },
    rgbToHex(rgb: string): string {
      //Convert a number into its hexadecimal form
      const numberToHex = (str: string): string =>
        Number.parseInt(str).toString(16).padStart(2, '0')

      const hexString = rgb
        //Ignore the 'rgb()' part of the string
        .slice(4, -1)
        .split(',')
        .map(numberToHex)
        .join('')

      return `#${hexString}`.toUpperCase()
    },
    shortHexToHex(shortHex: string): string {
      return (
        shortHex
          .split('')
          //Duplicate all characters
          .map((char) => char + char)
          .join('')
          //Remove the extra #
          .slice(1)
      )
    },
    //Transforms color codes to full hex, since the color picker doesn't set
    //if the color is not on the full hex format
    toHex(color: string): string {
      //Format rgb(250,123,0)
      if (color.startsWith('rgb(')) {
        return this.rgbToHex(color)
      }
      //Format: #FCC
      if (color.startsWith('#') && color.length === 4) {
        return this.shortHexToHex(color)
      }

      return color
    },
    saveColor() {
      this.model = this.colorPicker
      this.menuOpen = false
    },
  },
})
</script>
