<template>
  <component :is="menuType" v-model="menu" v-bind="menuProps">
    <template #activator="{ on }">
      <v-icon
        v-if="buttonIcon"
        small
        class="icon-button--activator"
        v-on="on"
        v-text="buttonIcon"
      />

      <FilterButtonActivator
        v-else
        :disabled="!hasAccess"
        :has-value="hasValue"
        :label="filterName"
        :tooltip-label="tooltipLabel"
        @clear="clear"
        v-on="on"
      >
        <template #button-left>
          <slot name="button-left" :selected="hasValue" />
        </template>
      </FilterButtonActivator>

      <v-tooltip v-if="!hasAccess" bottom>
        <template #activator="{ on }">
          <v-icon color="orange" v-on="on" v-text="`error`" />
        </template>
        <span
          v-text="$t(`entity_toolbar_filter_button.filter.not_resource_access`)"
        />
      </v-tooltip>
    </template>

    <v-card v-if="menu" min-height="90" class="level0 pa-0 ma-0">
      <v-toolbar v-if="menuType === 'v-dialog'" dense short flat class="level1">
        <v-icon class="mr-2"> mdi-filter-variant </v-icon>
        <span v-text="filterLabel" />
      </v-toolbar>

      <component
        :is="component"
        v-model="model"
        v-bind="{
          attributeMeta,
          attributeName,
          options,
          onUpdate,
        }"
        class="mb-2"
        @cancel="close"
        @clear="clear"
        @apply="apply"
      />

      <v-toolbar dense short flat class="level1">
        <v-btn text color="grey" @click="clear">
          <span v-text="$t('common.clear.btn')" />
        </v-btn>
        <v-spacer />
        <v-btn color="green" text @click="apply">
          <span v-text="$t('common.apply.btn')" />
        </v-btn>
      </v-toolbar>
    </v-card>
  </component>
</template>

<script lang="ts">
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { FieldTypes } from '@/tt-widget-factory/services/resource-meta/types'

import BaseFilterButton from './BaseFilterButton'
import FilterButtonActivator from './FilterButtonActivator.vue'
import { EntityFilterEvent } from '../types'
import { getFilterName } from '../util'
import { PropType } from 'vue'
import { AttributeFilterOptions, Filter } from '@/tt-widget-components'
import FilterValueParser from '../FilterValueParser'
import { isTemporalFieldType } from '../temporal-filters/field-types-validator'

export default BaseFilterButton.extend({
  name: 'EntityToolbarFilterButton',
  components: { FilterButtonActivator },
  inheritAttrs: false,
  data() {
    return {
      filterLabel: '',
    }
  },
  props: {
    attributeName: {
      type: String,
      default: '',
    },
    isContextFilter: {
      type: Boolean,
      default: false,
    },
    menuType: {
      type: String,
      default: 'v-menu',
    },
    prependIcon: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: null,
    },
    options: {
      type: Object as PropType<AttributeFilterOptions>,
      default: () => ({}),
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    filterName(): string {
      const translateFunction = (key: string): string => this.$tc(key)

      if (this.label) {
        return translateFunction(this.label)
      }

      const filterName = getFilterName(
        this.filterValue,
        this.parsedValue,
        this.$appContext.widgetServices.resourceMetaManager,
        translateFunction,
        this.attributeName,
        this.resource,
      )

      if (this.hasValue || this.parsedValue) {
        return this.isDateRangeAttribute ? this.parsedValue : filterName
      }

      return filterName
    },
    tooltipLabel(): string | null {
      const type = this.attributeMeta.type

      return isTemporalFieldType(type) && this.value
        ? FilterValueParser.getFilterValue(
            this.attributeMeta,
            this.value,
            this.userPreferences,
          )
        : null
    },
    menuProps(): Record<string, any> {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          nudgeBottom: 30,
          bottom: true,
          closeOnContentClick: false,
          left: true,
          width: 350,
          attach: this.isContextFilter,
        }
      } else {
        return {
          width: 350,
          closeOnContentClick: false,
        }
      }
    },
    filterText(): string {
      return this.$t('entity_toolbar_filter_button.filter.filter', {
        attributeLabel: this.attributeLabel,
      }) as string
    },
    hasAccess(): boolean {
      const canViewResource = () =>
        ResourcePermissionAuditor.canViewResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.attributeMeta.relation.resource,
        )

      return this.attributeMeta.type === FieldTypes.Relation
        ? canViewResource()
        : true
    },
  },
  methods: {
    onUpdate(filter: Filter) {
      this.model = filter

      this.apply()
    },
  },
  watch: {
    menu(isOpen: boolean) {
      this.$eventManager.dispatchEvent(
        EntityFilterEvent.TOOLBAR_MENU_ACTIVE,
        isOpen,
      )
    },
  },
})
</script>
