// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveRequestLogsTile from './LeaveRequestLogsTile.vue'
import LeaveRequestLogsColumns from './LeaveRequestLogsColumns'
import LeaveRequestLogsDetail from './LeaveRequestLogsDetail.vue'
import LeaveRequestLogsMetaCard from './LeaveRequestLogsMetaCard.vue'
import LeaveRequestLogsReference from './LeaveRequestLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeaveRequestLogsTile', LeaveRequestLogsTile)
    Vue.component('LeaveRequestLogsDetail', LeaveRequestLogsDetail)
    Vue.component('LeaveRequestLogsMetaCard', LeaveRequestLogsMetaCard)
    Vue.component('LeaveRequestLogsReference', LeaveRequestLogsReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveRequestLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveRequestLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveRequestLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveRequestLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_REQUEST_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveRequestLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
