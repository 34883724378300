<template>
  <div @click.stop="dispatchEditBasicInfoForm">
    <tt-attr name="name" class="font-weight-bold" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActiveWindowProvider } from '@/tt-app-layout/types'
export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'BasicInformationClickableColumn',
  inject: ['getItemHook', 'activeWindow'],
  methods: {
    dispatchEditBasicInfoForm() {
      this.activeWindow.next({
        title: 'tt-entity-design.leave-management.label',
        is: 'LeavePoliciesForm',
        props: { editStep: 0, policyIdToEdit: this.getItemHook().data.id },
      })
    },
  },
})
</script>
