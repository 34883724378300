// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeePremiumAssignmentsTile from './EmployeePremiumAssignmentsTile.vue'
import EmployeePremiumAssignmentsColumns from './EmployeePremiumAssignmentsColumns'
import EmployeePremiumAssignmentsDetail from './EmployeePremiumAssignmentsDetail.vue'
import EmployeePremiumAssignmentsReference from './EmployeePremiumAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmployeePremiumAssignmentsTile',
      EmployeePremiumAssignmentsTile,
    )
    Vue.component(
      'EmployeePremiumAssignmentsDetail',
      EmployeePremiumAssignmentsDetail,
    )
    Vue.component(
      'EmployeePremiumAssignmentsReference',
      EmployeePremiumAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PREMIUM_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeePremiumAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PREMIUM_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeePremiumAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PREMIUM_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeePremiumAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PREMIUM_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeePremiumAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
