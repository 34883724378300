<template>
  <div>
    <codemirror
      v-model="code"
      rows="3"
      class="CodeMirrorWhereQL"
      :options="cmOptions"
      style="width: 100%"
      @gutterClick="onGutterClick"
      @ready="onReady($event)"
    ></codemirror>

    <v-toolbar flat dense class="toolbar1">
      <template v-if="valid">
        <v-icon color="green">checkbox</v-icon>
      </template>
      <template v-else-if="!isEmpty">
        <v-icon color="red">warning</v-icon>
      </template>
      <div class="error--text pa-6" style="opacity: 0.5; font-size: 0.8em">
        {{ errorsAsText }}
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="canClear" small class="mr-2" @click="clear">
        <span v-text="$t('common.clear.btn')"></span>
      </v-btn>
      <v-btn :disabled="!canApply" small @click="onClickApplyButton">
        <span v-text="$t('common.apply.btn')"></span>
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script lang="ts">
import type { Editor } from 'codemirror'

import TQLEditorBase from '@/tt-tql-inputs/src/components/TQLEditorBase'
import { ApplyWhereQL } from '@/plugins/o11n'

export default TQLEditorBase.extend({
  name: 'WhereQLEditor',
  props: {
    resource: {
      type: String,
      required: true,
    },
  },
  computed: {
    resourceAsTableName(): string {
      return this.resource.replace(/-/g, '_')
    },
  },
  methods: {
    formatQuery(): string {
      return `Select 1 from ${this.resourceAsTableName}  WHERE  ${this.code}`
    },
    onClickApplyButton(): void {
      this.trackApplyWhereQLEvent()
      this.emit()
    },
    onGutterClick(cm: Editor): void {
      cm.focus()
    },
    trackApplyWhereQLEvent(): void {
      this.$analytics.track(ApplyWhereQL.create())
    },
  },
})
</script>
<style scoped>
.CodeMirror {
  font-size: 16px;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
