import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { DialogFormInterface, LayoutWindowEvent } from '@/tt-app-layout'
import { getResourceDefinitions } from '@/tt-entity-forms'
import {
  isEntityAttribute,
  removeVariablePrefix,
} from '@/tt-entity-forms/components/utils/RelationField'
import { modularManager } from '@/tt-app-modular'
import { normalizeName } from '@/helpers/text'

import { BaseEntityIntent } from './BaseEntityIntent'
import {
  displayErrorMessages,
  getSchemaRelationFilterVariables,
  parseErrorMessages,
} from './helpers'
import { EntityActionIntentInterface, EntityIntentTypes } from './types'
import { EntityItemHook } from '../EntityItemHook'
import { getResourceFormDefinition, getResourceFormWidth } from '../helper'
import { ResourceTranslator } from '../ResourceTranslator'

const confirmationSchema: JSONSchema7 = {
  title: 'Confirm?',
  type: 'object',
  properties: {},
}

const getSchemaRelationFilterAttributes = (schema: JSONSchema7): string[] =>
  getSchemaRelationFilterVariables(schema)
    .filter(isEntityAttribute)
    .map(removeVariablePrefix)

export class EntityActionIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.RUN_ACTION
  }

  async getDialogFormBuilder({
    resourceName,
    actionName: action,
    entityId,
    formOptions,
    title,
    initialModel = null,
  }: EntityActionIntentInterface): Promise<DialogFormBuilder> {
    const { entityServices, eventManager, widgetServices } = this.appContext
    const rootName = `${normalizeName(resourceName)}${normalizeName(action)}`

    const getJsonSchemaFormConfig = async (): Promise<Partial<Action>> => {
      const actions =
        await widgetServices.resourceActionManager.getEntityActions(
          resourceName,
          entityId,
          { jsonFormSchema: true },
        )

      const configSchema = actions.find(
        ({ actionName }) => actionName === action,
      )

      if (!configSchema) {
        throw new Error(
          'Failed to build form: schema is not in the list of available actions',
        )
      }

      // @ts-ignore -- json schema type is missing components
      const { components } = getDefaultConfig().schema ?? {}

      const schema = { components, ...configSchema.schema }

      return { ...configSchema, schema }
    }

    const getDefaultConfig = (): Partial<Action> => {
      const schema = widgetServices.resourceMetaManager.getFormSchema(
        resourceName,
        action,
      )

      return { schema }
    }

    const isJsonSchemaForm = modularManager.hasDynamicResourceJsonSchemaAction(
      resourceName,
      action,
    )
    const actionConfig = isJsonSchemaForm
      ? await getJsonSchemaFormConfig()
      : getDefaultConfig()

    const { schema: actionSchema, values } = actionConfig
    const schema = actionSchema ?? confirmationSchema

    const { definitions: actionDefinitions, ...actionFormOptions } =
      actionConfig?.formOptions ?? {}

    const resourceDefinitions = getResourceDefinitions(this.appContext)
    const customForm = getResourceFormDefinition(rootName, resourceName, action)
    const definitions = {
      ...resourceDefinitions,
      ...actionDefinitions,
      ...customForm,
    }

    const itemHook = new EntityItemHook(
      this.appContext,
      { resourceName, entityId },
      { fetchEnabled: true, hasFetched: false, fetchActions: true },
    )

    /**
     * Guarantees that the itemHook has all the attributes needed by the
     * schema's relation filters
     */
    getSchemaRelationFilterAttributes(schema).forEach((attribute) => {
      if (itemHook.hasAttribute(attribute)) {
        itemHook.addAttribute(attribute)
      } else {
        console.warn(`'${resourceName}' has no attribute '${attribute}'`)
      }
    })

    const dialogWidth = getResourceFormWidth(resourceName, action)

    const submitFn: DialogFormInterface['submit'] = (data) =>
      entityServices.persister.executeEntityAction(
        resourceName,
        action,
        entityId,
        data,
      )

    const successFn: DialogFormInterface['success'] = () => {
      eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: i18n.t('common.operation_successful'),
      })
    }

    const errorFn: DialogFormInterface['error'] = (error) => {
      const messages = parseErrorMessages({
        actionName: action,
        error,
        resourceName,
      })
      displayErrorMessages(messages, eventManager)
    }

    const effectiveTitle =
      title ??
      ResourceTranslator.translateActionLabel(resourceName, action) ??
      action

    return new DialogFormBuilder(effectiveTitle, rootName, {
      eventManager,
      i18n,
    })
      .setJsonSchema(schema)
      .addToContext({
        resourceName,
        action,
        itemHook, // needed for RelationField
      })
      .addToFormOptions({ ...actionFormOptions, ...formOptions })
      .addToInitialModel(initialModel ?? values)
      .setTranslateFunction(
        ResourceTranslator.getFormCallback(resourceName, action),
      )
      .addToDefinitions(definitions)
      .onSubmit(submitFn)
      .setWidth(dialogWidth)
      .onSuccess(successFn)
      .onError(errorFn)
  }

  async run(params: EntityActionIntentInterface) {
    const dialogBuilder = await this.getDialogFormBuilder(params)
    dialogBuilder.displayDialog()
  }
}
