<template>
  <tt-attr-row-card>
    <t-custom-fields-details v-if="!isLoading" :custom-fields="customFields" />

    <!-- Register relations so they are available in the preview tab, but don't show them to the user -->
    <div v-show="false">
      <tt-attr-row name="customAttributesDisplay" />
      <tt-attr-row name="customAttributesValues" />
    </div>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { CustomAttributeDisplay } from '../assets/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetsMetaCardCustomFields',
  inject: ['getItemHook'],
  computed: {
    customFields(): CustomAttributeDisplay[] {
      return this.getItemHook().getRawValue('customAttributesDisplay') || []
    },
    isLoading(): boolean {
      return this.getItemHook().loading
    },
  },
  created() {
    this.getItemHook().addAttribute('customAttributesDisplay')
  },
})
</script>
