// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetCustomFieldsTile from './AssetCustomFieldsTile.vue'
import AssetCustomFieldsColumns from './AssetCustomFieldsColumns'
import AssetCustomFieldsDetail from './AssetCustomFieldsDetail.vue'
import AssetCustomFieldsReference from './AssetCustomFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetCustomFieldsTile', AssetCustomFieldsTile)
    Vue.component('AssetCustomFieldsDetail', AssetCustomFieldsDetail)
    Vue.component('AssetCustomFieldsReference', AssetCustomFieldsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetCustomFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetCustomFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetCustomFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_CUSTOM_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetCustomFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
