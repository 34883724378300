<template>
  <span v-show="name" v-text="name" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Field } from '@/tt-fieldset-builder/type'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetCustomAttributeConfigView',
  inject: ['getItemHook'],
  props: {},
  data() {
    return {}
  },
  computed: {
    name(): Field['name'] | undefined {
      return this.getItemHook().getRawValue('config.name')
    },
  },
  created() {
    this.getItemHook().addAttribute('config')
  },
  methods: {},
})
</script>
