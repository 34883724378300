import { ValueOf } from '@/helpers/types/ValueOf'

export const siteTaskOccurrenceStatus = {
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  CONVERTING: 'CONVERTING',
  IN_PROGRESS: 'IN_PROGRESS',
  INCOMPLETE: 'INCOMPLETE',
  MISSED: 'MISSED',
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
}

export type SiteTaskOccurrenceStatus = ValueOf<typeof siteTaskOccurrenceStatus>

export const siteTaskOccurrenceStatusColor: Partial<
  Record<SiteTaskOccurrenceStatus, string>
> = {
  [siteTaskOccurrenceStatus.CANCELLED]: '#3D3A3A',
  [siteTaskOccurrenceStatus.COMPLETED]: '#92CD94',
  [siteTaskOccurrenceStatus.CONVERTING]: '#3894ED',
  [siteTaskOccurrenceStatus.IN_PROGRESS]: 'var(--v-ttPrimary-base)',
  [siteTaskOccurrenceStatus.INCOMPLETE]: '#E2831E',
  [siteTaskOccurrenceStatus.MISSED]: '#F05967',
  [siteTaskOccurrenceStatus.PENDING]: '#3894ED',
  [siteTaskOccurrenceStatus.SCHEDULED]: '#BCC3CA',
}

export const siteTaskOccurrenceStatusDefaultColor = '#BCC3CA'

export const getSiteTaskOccurrenceStatusColor = (
  status: SiteTaskOccurrenceStatus | null,
) => {
  const { [status]: statusColor } = siteTaskOccurrenceStatusColor

  return statusColor ?? siteTaskOccurrenceStatusDefaultColor
}
