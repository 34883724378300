<template>
  <tt-attr :name="name">
    <template #default="{ value }">
      <TChipLabel
        v-bind="{ textColor, backgroundColor, themeKey, modifiers, type }"
        :text-key="value"
      />
    </template>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Modifier, TChipLabelType } from '@/tt-ui/types'
import { TrackTikThemeKey } from '@/plugins/vuetify-plugin'

export default Vue.extend({
  name: 'ChipLabelText',
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    textColor: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    themeKey: {
      type: String as PropType<TrackTikThemeKey>,
      default: 'muted',
    },
    modifiers: {
      type: Object as PropType<Modifier>,
      default: () => ({}),
    },
    type: {
      type: String as PropType<TChipLabelType>,
      default: undefined,
    },
  },
})
</script>
