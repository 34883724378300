<template>
  <div class="fill-height">
    <slot />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import LayoutManager, { LayoutManagerProvider } from '../LayoutManager'

export default Vue.extend({
  name: 'ProvideLayoutManager',
  provide(): LayoutManagerProvider {
    return {
      layoutManager: this.layoutManager,
    }
  },
  props: {
    layoutManager: {
      type: Object as PropType<LayoutManager>,
      required: true,
    },
  },
})
</script>
