<template>
  <div v-if="canViewResource">
    <EntitySelectorField
      v-if="missingVariableValues.length === 0"
      v-bind="{ loading, queryOptions, ...$props, ...$attrs }"
      :create-view-items="createViewItems"
      :extra-fields="parentFields"
      :resource="resourceName"
      :style="{ width: '100%' }"
      :value="value"
      v-on="$listeners"
      @input="$emit('input', $event)"
      @update:fields="setFields"
    >
      <template #item="{ item }">
        <div :style="{ marginLeft: `${20 * item.depth}px` }">
          <EntityPresetRelation
            :entity="item.entity"
            :resource-name="resourceName"
          />
        </div>
      </template>
    </EntitySelectorField>
    <v-skeleton-loader
      v-else-if="isActionForm && itemHook.loading"
      type="heading"
      class="relation-field-skeleton"
    />
    <RelationFieldMissingVariables
      v-else
      v-bind="{ ...$attrs }"
      :missing-variable-values="missingVariableValues"
    />
  </div>
  <RelationFieldCannotView v-else v-bind="{ ...$attrs }" />
</template>

<script lang="ts">
import flow from 'lodash/flow'
import sortBy from 'lodash/sortBy'
import times from 'lodash/times'
import uniqBy from 'lodash/uniqBy'
import Vue, { VueConstructor } from 'vue'

import {
  EntityCollectionRequestOptions,
  Field,
} from 'tracktik-sdk/lib/common/entity-collection'
import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components'
import { RelationFilter } from '@/tt-widget-factory'

import RelationFieldCannotView from './RelationFieldCannotView.vue'
import RelationFieldMissingVariables from './RelationFieldMissingVariables.vue'
import {
  convertRelationFiltersToQueryOptions,
  getVariableValuesMap,
  getMissingVariableValues,
  replaceVariables,
  TTC_RELATION_FILTER,
  TTC_RELATION_NAME,
} from './utils/RelationField'
import { SelectItem } from './EntitySelectorField.vue'

type VueWithInjections = VueConstructor<
  Vue & FormHookProvider & { namespace: string }
>

export default (Vue as VueWithInjections).extend({
  name: 'RelationTreeField',
  components: {
    EntityPresetRelation,
    RelationFieldMissingVariables,
    RelationFieldCannotView,
  },
  inject: {
    formHook: { from: 'formHook' },
    namespace: { from: 'namespace' },
  },
  props: {
    /**
     * Field's label
     */
    label: { type: String, default: null },
    /**
     * Force loading state
     */
    loading: { type: Boolean, default: null },
    /**
     * tt-json-schema-form's field name
     */
    name: { type: String, required: true },
    /**
     * Name of the resource's attribute that links to its parent. It's used to build the tree view.
     */
    parentAttributeName: { type: String, required: true },
    /**
     * Field's label
     */
    placeholder: { type: String, default: null },
    /**
     * Name of the resource to be fetched from the API
     */
    resource: { type: String, default: undefined },
    /**
     * How many levels up of the entities' parent is requested to the API
     */
    treeMaxDepth: { type: Number, default: 5 },
    /**
     * Field's value
     */
    value: {
      type: [Number, String, Array],
      default: null,
    },
  },
  data() {
    return {
      fields: [] as string[],
    }
  },
  computed: {
    actionFormName(): string {
      return this.formHook().getUserContextValue('action')
    },
    canViewResource(): boolean {
      return ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.$appContext),
        this.resourceName,
      )
    },
    itemHook(): EntityItemHook | undefined {
      return this.formHook().getUserContextValue('itemHook')
    },
    isActionForm(): boolean {
      return !!this.actionFormName
    },
    missingVariableValues(): string[] {
      return getMissingVariableValues(this.relationFilters, {
        authModule: this.$appContext.authModule,
        formHook: this.formHook(),
        itemHook: this.itemHook,
        namespace: this.namespace,
      })
    },
    parentFields(): string[] {
      const isNotParentField = (field: string): boolean =>
        !field.startsWith(this.parentAttributeName)

      return this.fields
        .filter(isNotParentField)
        .map((field) => `${this.parentAttributeName}.${field}`)
    },
    queryOptions(): EntityCollectionRequestOptions {
      return {
        ...this.relationFilterQueryOptions,
        fields: [
          ...(this.relationFilterQueryOptions?.fields ?? []),
          ...this.queryOptionsParentTreeFields,
        ],
      }
    },
    queryOptionsParentTreeFields(): Field[] {
      const getParentPath = (depth: number): string =>
        times(depth, () => this.parentAttributeName).join('.')

      const getParentFields = (depth: number): string[] => {
        const path = getParentPath(depth)
        return this.fields.map((field) => `${path}.${field}`)
      }

      return times(this.treeMaxDepth).reduce(
        (fields, index) => [...fields, ...getParentFields(index + 1)],
        [],
      )
    },
    relationFilterQueryOptions(): EntityCollectionRequestOptions {
      const relationFiltersWithStaticValues = replaceVariables(
        this.relationFilters,
        this.variableValuesMap,
      )

      return convertRelationFiltersToQueryOptions(
        relationFiltersWithStaticValues,
      )
    },
    relationFilters(): RelationFilter {
      return this.schema[TTC_RELATION_FILTER] || {}
    },
    resourceName(): string {
      const resourceName = this.resource || this.schema[TTC_RELATION_NAME]

      if (!resourceName)
        console.warn('No resource provided in schema for relation')

      return resourceName
    },
    schema(): JSONSchema7 {
      const schema = this.formHook().getField(this.name)?.props?.$schema

      if (!schema) {
        console.warn(
          `Could not find schema in formHook for field path: ${this.name}`,
        )
      }

      return schema || {}
    },
    variableValuesMap(): Record<string, unknown> {
      return getVariableValuesMap(this.relationFilters, {
        authModule: this.$appContext.authModule,
        formHook: this.formHook(),
        itemHook: this.itemHook,
        namespace: this.namespace,
      })
    },
  },
  methods: {
    createViewItems<T extends { id: number; [k: string]: any }>(
      entities: T,
    ): SelectItem[] {
      const getParent = (item: T): T | undefined =>
        item[this.parentAttributeName]

      const flattenTree = (list: T[], item: T): T[] =>
        item ? [...flattenTree(list, getParent(item)), item] : list

      const getDepth = (entity: T, depth = 0): number => {
        const parent = getParent(entity)
        return parent ? getDepth(parent, depth + 1) : depth
      }

      const entityToOption = (entity: T): SelectItem => ({
        depth: getDepth(entity),
        entity,
        value: entity.id,
      })

      const entityOrder = (entity: T): string =>
        [getParent(entity)?.id, entity.id].filter(Boolean).join('')

      const options = flow([
        (entities) => entities.reduce(flattenTree, []),
        (entities) => uniqBy(entities, 'id'),
        (entities) => sortBy(entities, [entityOrder, ...this.fields]),
        (entities) => entities.map(entityToOption),
      ])(entities)

      return options
    },
    setFields(fields: string[]): void {
      this.fields = fields
    },
  },
})
</script>
