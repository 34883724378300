<template>
  <div class="d-flex">
    <v-tooltip slot="title" bottom>
      <template #activator="{ on }">
        <v-btn
          elevation="2"
          icon
          text
          class="mr-2"
          data-cy="change-widget-type-btn"
          v-on="on"
          @click="editWidgetType()"
        >
          <v-icon color="#64b5f6" style="cursor: pointer" v-text="icon" />
        </v-btn>
      </template>
      <span>
        {{ $t('components.widget_change_type.tooltip') }}
      </span>
    </v-tooltip>
    <v-text-field
      v-model="model"
      outlined
      dense
      :label="effectiveLabel"
      :error-messages="errorMessages"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'

import { LayoutWindowEvent } from '@/tt-app-layout'

// @todo: generic modules shouldn't depend on app modules
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

import { FormHookProvider, NamespaceProvider } from '../types'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & NamespaceProvider>
).extend({
  name: 'WidgetEditTitle',
  inject: ['formHook', 'namespace'],
  props: {
    name: String,
    value: String,
    label: String,
    placeholder: String,
    errorMessages: Array as PropType<string[]>,
  },
  computed: {
    absoluteName(): string {
      return this.namespace ? [this.namespace, this.name].join('.') : this.name
    },
    baseLabel(): string {
      return this.$t('components.widget_edit_title.widget_title')
    },
    effectiveLabel(): string {
      return this.formHook().getFieldLabel(this.absoluteName, this.baseLabel)
    },
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.formHook().setObjectValue(this.absoluteName, value, {
          debounce: true,
        })
      },
    },
    icon(): string | null {
      const widgetIs = this.formHook().getPathValue('is')
      if (!widgetIs) {
        return null
      }

      const widget =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(widgetIs)
      return widget ? widget.config.icon : null
    },
  },
  methods: {
    updateSidesheetTitle() {
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.SIDE_SHEET_EDIT_TITLE,
        { title: undefined },
      )
    },
    editWidgetType() {
      this.$appContext.widgetServices.contextManager.dispatchEvent(
        WidgetEditorEvents.WIDGET_EDIT_TYPE,
      )
      this.updateSidesheetTitle()
    },
  },
})
</script>

<style scoped>
.v-input__icon i {
  color: #64b5f6 !important;
}
</style>
