// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AlarmOrganizationsTile from './AlarmOrganizationsTile.vue'
import AlarmOrganizationsColumns from './AlarmOrganizationsColumns'
import AlarmOrganizationsDetail from './AlarmOrganizationsDetail.vue'
import AlarmOrganizationsMetaCard from './AlarmOrganizationsMetaCard.vue'
import AlarmOrganizationsReference from './AlarmOrganizationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AlarmOrganizationsTile', AlarmOrganizationsTile)
    Vue.component('AlarmOrganizationsDetail', AlarmOrganizationsDetail)
    Vue.component('AlarmOrganizationsMetaCard', AlarmOrganizationsMetaCard)
    Vue.component('AlarmOrganizationsReference', AlarmOrganizationsReference)

    modularManager.addResourcePreset(
      Resources.ALARM_ORGANIZATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AlarmOrganizationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_ORGANIZATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AlarmOrganizationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_ORGANIZATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmOrganizationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_ORGANIZATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmOrganizationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_ORGANIZATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AlarmOrganizationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
