<template>
  <v-toolbar
    v-if="items && items.length"
    dense
    short
    flat
    class="toolbar toolbar2"
    style="margin-left: -16px"
  >
    <v-btn
      v-if="isMobile"
      small
      icon
      text
      circle
      style="transform: rotate(90deg)"
      @click="goBack"
    >
      <v-icon small v-text="`mdi-subdirectory-arrow-left`" />
    </v-btn>
    <OverflowBox>
      <template #default>
        <template v-for="(item, index) in items">
          <span :key="`${item.label}-${index}`" class="breadcrumb">
            <span :key="`${item.label}-${index}`" class="pl-1">
              <a
                v-if="!isMobile"
                @click="clickOn(item)"
                v-text="$t(item.label)"
              />
              <span v-if="index < items.length - 1 && !isMobile" class="pl-1">
                &nbsp;/&nbsp;
              </span>
            </span>
          </span>
        </template>
      </template>
    </OverflowBox>
  </v-toolbar>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  getBreadCrumbs,
  getInsightRoute,
  BreadCrumbItem,
} from '@/apps/app.tracktik.insights.studio/utils'
import { omitPushErrors } from '@/plugins/router'
import { Routes } from '../enumRoutes'
import { WidgetStoreModel } from '@/tt-widget-factory'
import { MY_INSIGHTS_CATEGORY_UID } from '../types'

export default Vue.extend({
  name: 'WidgetViewToolbarBreadCrumb',
  computed: {
    items(): BreadCrumbItem[] {
      const { name, params } = this.$route
      const isSharedWithMe =
        name === Routes.SHARED_WITH_ME || name === Routes.SHARED_WITH_ME_VIEW

      const widget =
        WidgetStoreModel.find(params.viewId) || WidgetStoreModel.getSelected()

      if (widget.category === MY_INSIGHTS_CATEGORY_UID && isSharedWithMe) {
        return getBreadCrumbs(null, isSharedWithMe)
      }

      return getBreadCrumbs(widget.category, isSharedWithMe)
    },
    isRoot(): boolean {
      return this.items?.length === 1
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs
    },
  },
  methods: {
    clickOn(item: any) {
      if (!item) {
        this.$router
          .push(getInsightRoute(['explore']))
          .catch(omitPushErrors(['NavigationDuplicated']))
        return
      }

      this.$router
        .push(getInsightRoute(item.routeParams))
        .catch(omitPushErrors(['NavigationDuplicated']))
    },
    goBack() {
      if (!this.items.length) {
        this.$router.push('/')
      } else {
        const lastItem = this.items[this.items.length - 1]
        this.clickOn(lastItem)
      }
    },
  },
})
</script>

<style scoped>
.breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  font-size: 0.8em;
  line-height: 2em;
}
a {
  padding: 0.2em;
  font-weight: 300;
}
a:hover {
  background-color: var(--v-muted-base);
  border-radius: 0.3em;
}
</style>
