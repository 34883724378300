import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'label',
  'region',
  'code',
  {
    attributeName: 'nextOccurrence',
    headerText: 'tt-entity-design.holidays.next-occurrence-date',
  },
  'dates',
  'groups',
] as ColumnInputDefinition[]
