// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractRecurrentFixedServicesTile from './ContractRecurrentFixedServicesTile.vue'
import ContractRecurrentFixedServicesColumns from './ContractRecurrentFixedServicesColumns'
import ContractRecurrentFixedServicesDetail from './ContractRecurrentFixedServicesDetail.vue'
import ContractRecurrentFixedServicesMetaCard from './ContractRecurrentFixedServicesMetaCard.vue'
import ContractRecurrentFixedServicesReference from './ContractRecurrentFixedServicesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ContractRecurrentFixedServicesTile',
      ContractRecurrentFixedServicesTile,
    )
    Vue.component(
      'ContractRecurrentFixedServicesDetail',
      ContractRecurrentFixedServicesDetail,
    )
    Vue.component(
      'ContractRecurrentFixedServicesMetaCard',
      ContractRecurrentFixedServicesMetaCard,
    )
    Vue.component(
      'ContractRecurrentFixedServicesReference',
      ContractRecurrentFixedServicesReference,
    )

    modularManager.addResourcePreset(
      Resources.CONTRACT_RECURRENT_FIXED_SERVICES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractRecurrentFixedServicesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_RECURRENT_FIXED_SERVICES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractRecurrentFixedServicesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_RECURRENT_FIXED_SERVICES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractRecurrentFixedServicesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_RECURRENT_FIXED_SERVICES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ContractRecurrentFixedServicesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_RECURRENT_FIXED_SERVICES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractRecurrentFixedServicesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
