import { AppContext } from '@/tt-app-context'
import {
  DocumentInput,
  DocumentProviderInterface,
  DocumentProviders,
  DocumentServiceMethods,
} from '@/tt-entity-documents/types'

export interface TTCloudDocumentInput extends DocumentInput {
  provider: DocumentProviders.TTCLOUD
  data: {
    path: string
  }
}

export default class TTCloudDocumentServiceProvider
  implements DocumentProviderInterface
{
  private appContext: AppContext

  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  getMethod(): DocumentServiceMethods {
    return DocumentServiceMethods.GET
  }

  async getDocumentOptionsSchema(): Promise<null> {
    return null
  }

  async getDocumentUrl(input: TTCloudDocumentInput): Promise<string> {
    const domain = new URL(
      this.appContext.authModule.getHttp().defaults.baseURL,
    )
    const token = this.appContext.authModule.getUserToken()

    return `${domain.protocol}//${domain.host}${input.data.path}?__lang=${input.locale}&__token=${token}`
  }

  getProviderTag(): DocumentProviders {
    return DocumentProviders.TTCLOUD
  }
}
