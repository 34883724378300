// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DepartmentsTile from './DepartmentsTile.vue'
import DepartmentsColumns from './DepartmentsColumns'
import DepartmentsDetail from './DepartmentsDetail.vue'
import DepartmentsMetaCard from './DepartmentsMetaCard.vue'
import DepartmentsReference from './DepartmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DepartmentsTile', DepartmentsTile)
    Vue.component('DepartmentsDetail', DepartmentsDetail)
    Vue.component('DepartmentsMetaCard', DepartmentsMetaCard)
    Vue.component('DepartmentsReference', DepartmentsReference)

    modularManager.addResourcePreset(
      Resources.DEPARTMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DepartmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DEPARTMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DepartmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DEPARTMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DepartmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DEPARTMENTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DepartmentsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DEPARTMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DepartmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
