// Enter your custom installation here

import ConflictsAffectedEntityAttr from './ConflictsAffectedEntityAttr.vue'
import ConflictsBtnGroupActions from './ConflictsBtnGroupActions.vue'
import ConflictsBtnAction from './ConflictsBtnAction.vue'
import ConflictsDetail from './ConflictsDetail.vue'

export default {
  install(Vue) {
    Vue.component('ConflictsAffectedEntityAttr', ConflictsAffectedEntityAttr)
    Vue.component('ConflictsBtnGroupActions', ConflictsBtnGroupActions)
    Vue.component('ConflictsBtnAction', ConflictsBtnAction)
    Vue.component('ConflictsDetail', ConflictsDetail)
  },
}
