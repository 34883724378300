<template>
  <v-checkbox
    v-model="isAccrued"
    :disabled="isEditModeRestricted"
    :label="accrualCheckboxText"
    color="ttPrimary"
    :ripple="false"
    class="black-label mt-0"
  />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { BASE_LOCALE_KEY } from './LeavePolicyItemsForm.vue'

const PATH = ''

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'LeavePolicyItemSelectorFields',
  inject: ['formHook'],
  props: {
    responseErrors: {
      type: Object,
      required: true,
    },
    isEditModeRestricted: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    accrualCheckboxText(): string {
      return this.$t(`${BASE_LOCALE_KEY}.accrual-checkbox`)
    },
    isAccrued: {
      get(): boolean {
        return Boolean(this.formHook().getSelectedSubSchema(PATH))
      },
      set(option: boolean) {
        this.formHook().setSelectedSubSchema(PATH, Number(option))
        this.$emit('isAccrued', option)
      },
    },
  },
})
</script>
