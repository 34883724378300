import { PortalType } from '../types'

const PortalTypeInjection = 'portalType'

/**
 * Type and runtime helper for Vue Component to use the injection, ie:
 * ```
 * export default (Vue as VueConstructor<Vue & PortalTypeProvider>).extend({
 *    inject: { portalType }
 *    // ...
 * ```
 */
export type PortalTypeProvider = {
  [PortalTypeInjection]: PortalType
}

export const portalType = {
  from: PortalTypeInjection,
}
