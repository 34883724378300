<template>
  <div class="mx-4">
    <v-radio-group column :value="value" @change="$emit('input', $event)">
      <v-radio
        v-for="(v, i) in values"
        :key="i"
        :label="$t(v.label)"
        :value="v"
        @click="handler"
      />
    </v-radio-group>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'EnumRadioFilterHandler',
  props: {
    values: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
  methods: {
    handler(event) {
      event.stopPropagation()
    },
  },
})
</script>
