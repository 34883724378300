// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BackOfficePaySettingsTile from './BackOfficePaySettingsTile.vue'
import BackOfficePaySettingsColumns from './BackOfficePaySettingsColumns'
import BackOfficePaySettingsDetail from './BackOfficePaySettingsDetail.vue'
import BackOfficePaySettingsMetaCard from './BackOfficePaySettingsMetaCard.vue'
import BackOfficePaySettingsReference from './BackOfficePaySettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BackOfficePaySettingsTile', BackOfficePaySettingsTile)
    Vue.component('BackOfficePaySettingsDetail', BackOfficePaySettingsDetail)
    Vue.component(
      'BackOfficePaySettingsMetaCard',
      BackOfficePaySettingsMetaCard,
    )
    Vue.component(
      'BackOfficePaySettingsReference',
      BackOfficePaySettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_PAY_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BackOfficePaySettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_PAY_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BackOfficePaySettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_PAY_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BackOfficePaySettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_PAY_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BackOfficePaySettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BACK_OFFICE_PAY_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BackOfficePaySettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
