import { WidgetSchema } from '@/tt-widget-factory'

export default {
  type: 'object',
  title: 'List',
  required: ['title', 'query', 'is'],
  properties: {
    is: {
      enum: ['ListWidget'],
    },
    title: {
      title: 'Widget Title',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Widget Title',
      type: 'string',
    },
    query: {
      $ref: '#/definitions/CollectionQuery',
    },
    listItem: {
      $ref: '#/definitions/ComponentReference',
    },
    toolbar: {
      $ref: '#/definitions/DefaultToolbar',
    },
    allowActions: {
      title: 'Allow Actions',
      type: 'boolean',
    },
    allowCreation: {
      title: 'Allow Creation',
      type: 'boolean',
    },
    allowDownload: {
      $ref: '#/definitions/AllowDownloadCsvPdf'
    },
    disableResourcePreviewOnClick: {
      title: 'Disable Resource Preview on Click',
      type: 'boolean',
    },
  },
} as WidgetSchema
