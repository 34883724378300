<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <div
        class="live-update--container"
        v-on="on"
        @mouseenter="generateTooltipDurationLabel"
      >
        <v-icon :color="color" v-text="icon" />

        <v-icon
          v-show="showAnimation"
          color="success"
          class="live-update--icon-animated"
          v-text="'mdi-access-point'"
        />

        <div v-show="showAnimation" class="live-icon--static-bar" />

        <TLoadingBar
          v-if="isActive"
          back-and-forth-animation
          class="live-update--loading-bar"
        />
      </div>
    </template>

    <span v-text="tooltipLabel" />
  </v-tooltip>
</template>

<script lang="ts">
import { updateDOM } from '@/helpers/dom/updateDOM'
import { getSecondsHumanized } from '@/helpers/formats/dates/utils'
import Vue from 'vue'

export default Vue.extend({
  name: 'TLiveUpdateIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    lastUpdate: {
      type: Number, // UTC timestamp in milliseconds (ie: new Date().getTime())
      default: null,
    },
  },
  data() {
    return {
      showAnimation: false,
      localizedDuration: this.$t('tt-ui.no-events-received-yet'),
    }
  },
  computed: {
    tooltipLabel(): string {
      const prefix = this.$t('tt-ui.last-update')
      return `${prefix}: ${this.localizedDuration}`
    },
    color(): string {
      return this.isActive ? 'success' : 'error'
    },
    icon(): string {
      return this.isActive ? 'mdi-access-point' : 'mdi-access-point-off'
    },
  },
  watch: {
    async lastUpdate() {
      this.showAnimation = false
      await updateDOM()
      this.showAnimation = true
    },
  },
  methods: {
    /**
     * Calculate the duration between the last update and now,
     * and generate a humanized label for the tooltip.
     */
    generateTooltipDurationLabel() {
      if (this.lastUpdate === null) return

      const nowUtcInMilliSeconds = new Date().getTime()

      const diffInSeconds = Math.floor(
        (this.lastUpdate - nowUtcInMilliSeconds) / 1000,
      )

      this.localizedDuration = getSecondsHumanized(diffInSeconds, {
        suffix: true,
      })
    },
  },
})
</script>

<style scoped>
.live-update--container {
  position: relative;
}

.live-update--loading-bar {
  top: auto;
  bottom: 0;
  height: 1px;
}

.live-icon--static-bar {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--v-success-base);
  animation: hide-after-animation 3s both;
}

.live-update--icon-animated {
  position: absolute;
  top: 0;
  left: 0;
  animation: fade-out-animation 3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  filter: blur(2px);
}

@keyframes fade-out-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes hide-after-animation {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
