<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="reportLibrary" />
    <tt-attr-row name="isDispatcherField" />
    <tt-attr-row name="type" />
    <tt-attr-row name="label" />
    <tt-attr-row v-if="isSignature" name="extra" />
    <tt-attr-row v-if="isList" name="listItems" />
    <tt-attr-row name="required" />
    <tt-attr-row v-if="isText" name="confidential" />
    <tt-attr-row name="fieldTag" />
  </tt-attr-row-card>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { ReportLibraryFieldType } from './types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportLibraryFieldsDetail',
  inject: ['getItemHook'],
  computed: {
    isList(): boolean {
      return [
        ReportLibraryFieldType.List,
        ReportLibraryFieldType.ListMultiple,
      ].includes(this.type)
    },
    isSignature(): boolean {
      return this.type === ReportLibraryFieldType.Signature
    },
    isText(): boolean {
      return [
        ReportLibraryFieldType.Text,
        ReportLibraryFieldType.Textarea,
        ReportLibraryFieldType.TextNumber,
      ].includes(this.type)
    },
    type(): ReportLibraryFieldType {
      return this.getItemHook().getRawValue('type')
    },
  },
})
</script>
