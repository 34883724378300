<template>
  <ActionBarPreset :actions="actionList" />
</template>
<script lang="ts">
import Vue from 'vue'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import {
  ViewAction,
  VueWithInjections,
} from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import {
  EntityEditIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import { getEditFormState } from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { LayoutWindowEvent } from '@/tt-app-layout'

export default (Vue as VueWithInjections).extend({
  name: 'OperationCenterEscalationPoliciesActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['getItemHook'],
  computed: {
    actionList(): ViewAction[] {
      const editAction: ViewAction = {
        icon: 'mdi-pencil-outline',
        title: this.$t('common.edit.btn'),
        onClick: async () => this.openTasksForm(),
      }

      return [editAction]
    },
  },
  methods: {
    async openTasksForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.OPERATION_CENTER_ESCALATION_POLICIES,
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        onSuccess: () => {
          this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.OPERATION_CENTERS,
          })
        },
        formOptions: {
          fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        props: {
          payload: {
            ...payload,
            title: this.$t(
              'tt-entity-design.operation-center-escalation-policies.form-edit-title',
            ),
          },
        },
      })
    },
  },
})
</script>
