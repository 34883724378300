// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ExceptionTypeGroupItemsTile from './ExceptionTypeGroupItemsTile.vue'
import ExceptionTypeGroupItemsColumns from './ExceptionTypeGroupItemsColumns'
import ExceptionTypeGroupItemsDetail from './ExceptionTypeGroupItemsDetail.vue'
import ExceptionTypeGroupItemsMetaCard from './ExceptionTypeGroupItemsMetaCard.vue'
import ExceptionTypeGroupItemsReference from './ExceptionTypeGroupItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ExceptionTypeGroupItemsTile', ExceptionTypeGroupItemsTile)
    Vue.component(
      'ExceptionTypeGroupItemsDetail',
      ExceptionTypeGroupItemsDetail,
    )
    Vue.component(
      'ExceptionTypeGroupItemsMetaCard',
      ExceptionTypeGroupItemsMetaCard,
    )
    Vue.component(
      'ExceptionTypeGroupItemsReference',
      ExceptionTypeGroupItemsReference,
    )

    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ExceptionTypeGroupItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
