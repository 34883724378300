<template>
  <TopBarButton
    v-if="isSupported"
    text="extensions.full-screen.label"
    :icon="isFullScreen ? 'fullscreen_exit' : 'fullscreen'"
    @click="toggleFullScreen"
  />
</template>

<script lang="ts">
import Vue from 'vue'
import TopBarButton from '@/tt-app-layout/components/TopBarButton.vue'

export default Vue.extend({
  name: 'FullScreenExtension',
  components: { TopBarButton },
  data() {
    return {
      isFullScreen: false,
    }
  },
  computed: {
    isSupported(): boolean {
      return !!window.document.documentElement.requestFullscreen
    },
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullScreenChange)
  },
  created() {
    document.addEventListener('fullscreenchange', this.onFullScreenChange)
  },
  methods: {
    onFullScreenChange() {
      this.isFullScreen = !!window.document.fullscreenElement
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    },
    toggleFullScreen() {
      if (this.isFullScreen) {
        this.closeFullScreen()
      } else {
        document.documentElement.requestFullscreen()
      }
    },
  },
})
</script>
