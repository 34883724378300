import { WidgetSchema, WidgetTypeDefinition } from '@/tt-widget-factory'
import { getCommonSchemaDefinitions } from '@/tt-widget-components/lib'
import { cloneData } from '@/helpers/cloneData'

export function getJsonSchema(widgetType: WidgetTypeDefinition): WidgetSchema {
  if (!widgetType) {
    return null
  }

  // Merge the default definitions and the widget definitions
  const defaultDefinitions = getCommonSchemaDefinitions()
  const widgetDefinitions = widgetType.schema.definitions ?? {}
  const schema = cloneData(widgetType.schema)
  schema.definitions = { ...defaultDefinitions, ...widgetDefinitions }

  return schema
}
