// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagGroupsTile from './ReportFlagGroupsTile.vue'
import ReportFlagGroupsColumns from './ReportFlagGroupsColumns'
import ReportFlagGroupsDetail from './ReportFlagGroupsDetail.vue'
import ReportFlagGroupsMetaCard from './ReportFlagGroupsMetaCard.vue'
import ReportFlagGroupsReference from './ReportFlagGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportFlagGroupsTile', ReportFlagGroupsTile)
    Vue.component('ReportFlagGroupsDetail', ReportFlagGroupsDetail)
    Vue.component('ReportFlagGroupsMetaCard', ReportFlagGroupsMetaCard)
    Vue.component('ReportFlagGroupsReference', ReportFlagGroupsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUPS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
