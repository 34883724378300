<template>
  <v-container class="py-4 px-0">
    <v-row>
      <v-col>
        <span class="text-body-1">
          {{ $t('widget_edit.AllowDownloadAll.placeholder') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="type in supportedTypes" :key="type" cols="12" md="4" sm="4">
        <v-checkbox
          v-if="availableTypes[type]"
          v-model="selected"
          :value="type"
          :label="$t(`common.${type}.checkbox`)"
          class="mt-0"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-if="showDivider" class="mb-4">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { AllowDownloadAll } from '../schemas-types'
import { WidgetDownloadTypes } from '@/tt-widget-components'

const downloadTypesListToMap = (
  types: WidgetDownloadTypes[],
  value = true
): Partial<Record<WidgetDownloadTypes, boolean>> =>
  types.reduce(
    (acc, type) => ({ ...acc, [type]: value }),
    {} as Partial<Record<WidgetDownloadTypes, boolean>>
  )
export default Vue.extend({
  name: 'WidgetAllowDownloadField',
  props: {
    value: {
      type: [Boolean, Object] as PropType<AllowDownloadAll | null>,
      default: null
    },
    supportedTypes: {
      type: Array as PropType<WidgetDownloadTypes[]>,
      default: () => []
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected: {
      get(): WidgetDownloadTypes[] {
        if (this.value === false) return []

        if (this.value == null || this.value === true) {
          return Object.keys(this.availableTypes) as WidgetDownloadTypes[]
        }

        return Object.entries(this.value)
          .filter(([_, value]) => value)
          .map(([type]) => type) as WidgetDownloadTypes[]
      },
      set(value: WidgetDownloadTypes[]) {
        const getNewValue = () => {
          if (value.length === 0) return false
          if (this.supportedTypes.length === value.length) return true

          return {
            ...downloadTypesListToMap(this.supportedTypes, false),
            ...downloadTypesListToMap(value)
          }
        }

        this.$emit('input', getNewValue())
      }
    },
    availableTypes(): AllowDownloadAll | null {
      return downloadTypesListToMap(this.supportedTypes)
    }
  }
})
</script>
