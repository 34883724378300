import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'name',
    style: {
      cssClass: 'font-weight-bold',
    },
  },
  'website',
] as ColumnInputDefinition[]
