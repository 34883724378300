// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaxLocationsTile from './TaxLocationsTile.vue'
import TaxLocationsColumns from './TaxLocationsColumns'
import TaxLocationsDetail from './TaxLocationsDetail.vue'
import TaxLocationsMetaCard from './TaxLocationsMetaCard.vue'
import TaxLocationsReference from './TaxLocationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaxLocationsTile', TaxLocationsTile)
    Vue.component('TaxLocationsDetail', TaxLocationsDetail)
    Vue.component('TaxLocationsMetaCard', TaxLocationsMetaCard)
    Vue.component('TaxLocationsReference', TaxLocationsReference)

    modularManager.addResourcePreset(
      Resources.TAX_LOCATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaxLocationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaxLocationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaxLocationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
