import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'customId',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'customId',
        backgroundColor: 'indigo darken-2',
        textColor: 'white',
      },
    },
  },
  'name',
  'timeZone',
  'company',
  'firstName',
  'lastName',
] as ColumnInputDefinition[]
