<template v-if="isConflictingSerialNumber">
  <v-container fluid class="pa-0">
    <!-- Case 1: The serial number conflicts with an active asset -->
    <v-alert
      v-if="
        isConflictingSerialNumber &&
        conflictingAssetType === 'ACTIVE' &&
        hasAccessToConflictingAsset
      "
      type="error"
      color="red darken-2"
      icon="mdi-alert"
      class="mb-5"
      text
    >
      <p class="my-0">
        {{ duplicateSerialNumberActiveAssetError }}
      </p>
    </v-alert>

    <!-- Case 2: The serial number conflicts with an inactive asset -->
    <v-alert
      v-else-if="
        isConflictingSerialNumber &&
        conflictingAssetType === 'INACTIVE' &&
        hasAccessToConflictingAsset
      "
      type="warning"
      color="orange darken-2"
      icon="mdi-alert"
      class="mb-5"
      text
    >
      <p class="my-0">
        {{ duplicateSerialNumberInactiveAssetWarning }}
      </p>
      <div class="mt-3">
        <v-btn
          color="green"
          :loading="isLoadingDelete"
          @click="removeDuplicateSerialNumberAsset"
        >
          {{ removeDuplicateInactiveAssetBtn }}
        </v-btn>
      </div>
    </v-alert>

    <!-- Case 3: The serial number conflicts with an asset from an account where the user doesn't have access -->
    <v-alert
      v-else-if="isConflictingSerialNumber && !hasAccessToConflictingAsset"
      type="error"
      color="red darken-2"
      icon="mdi-alert"
      class="mb-5"
      text
    >
      <p class="my-0">
        {{ duplicateSerialNumberNoPermissionError }}
      </p>
    </v-alert>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'

import {
  Filter,
  FilterOperatorType,
} from 'tracktik-sdk/lib/common/entity-filters'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { INPUT_DEBOUNCE_TIME } from '@/helpers/constants'

import { Resources } from '../../types'
import { SearchedAsset } from './types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { SERIAL_NUMBER, ASSET_TYPE } from './constants'
import { debounce, DebouncedFunc } from 'lodash'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'AssetSerialNumberChecker',
  inject: ['formHook'],
  props: {
    serialNumber: {
      type: String,
      required: true,
    },
    assetType: {
      type: Number,
      required: false,
      default: null,
    },
    assetId: {
      type: Number,
      required: false,
      default: null,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      conflictingSerialNumberAsset: {} as SearchedAsset,
      showMessage: false,
      isLoadingDelete: false,
    }
  },
  computed: {
    hasAccessToConflictingAsset(): boolean {
      return !!this.conflictingSerialNumberAsset.id
    },
    isConflictingSerialNumber(): boolean {
      return this.showMessage
    },
    conflictingAssetType(): string {
      return this.conflictingSerialNumberAsset.status
    },
    duplicateSerialNumberActiveAssetError(): string {
      return this.$t(
        'tt-entity-design.asset-hub.duplicate-serial-number-active',
        {
          accountName: this.conflictingSerialNumberAsset.account.name,
          assetName: this.conflictingSerialNumberAsset.name,
        },
      )
    },
    duplicateSerialNumberInactiveAssetWarning(): string {
      return this.$t(
        'tt-entity-design.asset-hub.duplicate-serial-number-inactive',
        {
          accountName: this.conflictingSerialNumberAsset.account.name,
          assetName: this.conflictingSerialNumberAsset.name,
        },
      )
    },
    duplicateSerialNumberNoPermissionError(): string {
      return this.$t(
        'tt-entity-design.asset-hub.duplicate-serial-number-not-permitted',
      )
    },
    removeDuplicateInactiveAssetBtn(): string {
      return this.$t(
        'tt-entity-design.asset-hub.duplicate-serial-number-inactive-remove-btn',
      )
    },
    validateSerialNumberFilters(): Filter[] {
      const filters: Filter[] = [
        {
          attribute: SERIAL_NUMBER,
          operator: FilterOperatorType.EQUAL,
          value: this.serialNumber,
        },
      ]

      if (this.assetType) {
        filters.push({
          attribute: ASSET_TYPE,
          operator: FilterOperatorType.EQUAL,
          value: this.assetType,
        })
      }

      if (this.isEditing) {
        filters.push({
          attribute: 'id',
          operator: FilterOperatorType.EQUAL,
          value: this.assetId,
        })
      }

      return filters
    },
    debounceSearch(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.validateSerialNumber()
      }, INPUT_DEBOUNCE_TIME)
    },
  },
  watch: {
    serialNumber: {
      handler() {
        this.debounceSearch()
      },
      immediate: true,
    },
    assetType: {
      handler() {
        this.debounceSearch()
      },
      immediate: true,
    },
    assetId: {
      handler() {
        this.debounceSearch()
      },
      immediate: true,
    },
  },
  methods: {
    removeDuplicateSerialNumberAsset() {
      this.isLoadingDelete = true

      this.$appContext.authModule
        .getHttp()
        .patch(`${Resources.ASSETS}/${this.conflictingSerialNumberAsset.id}`, {
          serialNumber: null,
        })
        .then(() => {
          this.validateSerialNumber()

          this.$appContext.eventManager.dispatchEvent(
            LayoutWindowEvent.SNACK_SUCCESS,
            {
              message: this.$t(
                'tt-entity-design.asset-hub.success-deleted-duplicate-asset',
              ),
            },
          )
        })
        .catch((error) => {
          this.$crash.captureException(
            error,
            'Failed to delete duplicate inactive asset',
          )
          this.$appContext.eventManager.dispatchEvent(
            LayoutWindowEvent.SNACK_ERROR,
            {
              message: this.$t(
                'tt-entity-design.asset-hub.failed-to-delete-duplicate-asset',
              ),
            },
          )
        })
        .finally(() => {
          this.isLoadingDelete = false
        })
    },
    validateSerialNumber() {
      this.cleanConflictingSerialNumberWarning()

      this.$appContext.authModule
        .getApi()
        .getAll(`${Resources.ASSETS}/serialnumbersearch`, {
          filters: this.validateSerialNumberFilters,
          include: ['account', 'type'],
          limit: 1,
        })
        .then((result) => {
          const { items, itemCount } = result

          if (itemCount > 0) {
            const asset = items[0] as SearchedAsset
            const { isSerialNumberUnique } = asset.type

            /**
             * if the type requires that the serial number is unique, we show the warning message
             */
            if (isSerialNumberUnique) {
              this.showMessage = true
              this.conflictingSerialNumberAsset = asset
            } else {
              this.showMessage = false
              this.conflictingSerialNumberAsset = {} as SearchedAsset
            }
          } else {
            this.cleanConflictingSerialNumberWarning()
          }
        })
        .finally(() => {
          this.$emit(
            'serial-number-conflict',
            this.conflictingSerialNumberAsset,
          )
        })
    },
    cleanConflictingSerialNumberWarning() {
      this.showMessage = false
      this.conflictingSerialNumberAsset = {} as SearchedAsset
    },
  },
})
</script>
