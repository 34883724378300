// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskSchedulesTile from './SiteTaskSchedulesTile.vue'
import SiteTaskSchedulesColumns from './SiteTaskSchedulesColumns'
import SiteTaskSchedulesDetail from './SiteTaskSchedulesDetail.vue'
import SiteTaskSchedulesMetaCard from './SiteTaskSchedulesMetaCard.vue'
import SiteTaskSchedulesReference from './SiteTaskSchedulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SiteTaskSchedulesTile', SiteTaskSchedulesTile)
    Vue.component('SiteTaskSchedulesDetail', SiteTaskSchedulesDetail)
    Vue.component('SiteTaskSchedulesMetaCard', SiteTaskSchedulesMetaCard)
    Vue.component('SiteTaskSchedulesReference', SiteTaskSchedulesReference)

    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskSchedulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskSchedulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskSchedulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskSchedulesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskSchedulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
