<template>
  <EntityRelationListPreview
    :entity="relationListResource"
    :entity-id="entityId"
    :item-hook="itemHook"
    :resource-name="resource"
    :hide-entity-preview-header="true"
  />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ExceptionTypeGroupsSitesTab',
  inject: ['getItemHook'],
  props: {
    relationListResource: {
      type: String,
      default: null,
      required: true,
    },
  },
  computed: {
    entityId(): number {
      return this.getItemHook().entityReference.entityId
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resource(): string {
      return Resources.EXCEPTION_TYPE_GROUPS
    },
  },
})
</script>
