import { formatDate } from '@/helpers/dates/formatDate'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import moment, { MomentInput } from 'moment-timezone'
import {
  FutureLeaveRequests,
  LeaveRequestsStatus,
  UiChipColor,
  ServiceLevelImpactColors,
} from './types'

export const insertColumnsIntoArray = (
  columnsArray: ColumnInputDefinition[],
  index: number,
  newColumns: ColumnInputDefinition[],
): ColumnInputDefinition[] => [
  ...columnsArray.slice(0, index),
  ...newColumns,
  ...columnsArray.slice(index),
]

export const serviceLevelImpactColorPicker = (percent: number): UiChipColor => {
  if (percent >= 0 && percent <= 24) {
    return ServiceLevelImpactColors.LOW
  } else if (percent >= 25 && percent <= 49) {
    return ServiceLevelImpactColors.MEDIUM_LOW
  } else if (percent >= 50 && percent <= 74) {
    return ServiceLevelImpactColors.MEDIUM_HIGH
  } else if (percent >= 75 && percent <= 100) {
    return ServiceLevelImpactColors.HIGH
  } else {
    return ServiceLevelImpactColors.NONE
  }
}

export const filterRequestsByStatus = (
  leaveRequests: FutureLeaveRequests[],
  requestStatus: LeaveRequestsStatus,
): FutureLeaveRequests[] => {
  return leaveRequests?.filter(({ status }) => status === requestStatus) || []
}

/**
 * Daylight Savings Time occurs on two days throughout the year
 * North America and Europe have seperate Daylight Savings Time Dates
 * North America: 1) Second Sunday of March 2) First Sunday of November
 * Europe: 1) Last Sunday of March 2) Last Sunday of October
 */

export const DSTYearStartDate = (
  startDate: MomentInput,
  userPreferences: UserPreferenceInterface,
): string => {
  const isUKtimezone = userPreferences.timeZone.includes('London')
  const year = moment(startDate).year()

  const northAmericanMarchDate = moment()
    .year(year)
    .month('March')
    .date(1)
    .day(14)

  // Some years Sunday of the month one week ahead ex: 2026
  // If this is the case, we subtract 7 days from the date
  const northAmericanFormattedStartDate =
    northAmericanMarchDate.date() > 14
      ? formatDate(northAmericanMarchDate.day(-7))
      : formatDate(northAmericanMarchDate)

  const europeanStartDate = formatDate(
    moment().year(year).month('March').endOf('month').startOf('week'),
  )

  return isUKtimezone ? europeanStartDate : northAmericanFormattedStartDate
}

export const DSTYearEndDate = (
  startDate: MomentInput,
  userPreferences: UserPreferenceInterface,
): string => {
  const isUKtimezone = userPreferences.timeZone.includes('London')
  const year = moment(startDate).year()

  const northAmericanNovemberDate = moment()
    .year(year)
    .month('November')
    .date(1)
    .day(7)

  // Some years the first Sunday of the month is one week ahead ex: 2026
  // If this is the case, we subtract 7 days from the date
  const northAmericanFormattedEndDate =
    northAmericanNovemberDate.date() > 7
      ? formatDate(northAmericanNovemberDate.day(-7))
      : formatDate(northAmericanNovemberDate)

  const europeanEndDate = formatDate(
    moment().year(year).month('October').endOf('month').startOf('week'),
  )

  return isUKtimezone ? europeanEndDate : northAmericanFormattedEndDate
}

export const DSTDateBetweenDates = (
  startDate: MomentInput,
  endDate: MomentInput,
  userPreferences: UserPreferenceInterface,
): string | null => {
  const DSTStartDate = DSTYearStartDate(startDate, userPreferences)
  const DSTEndDate = DSTYearEndDate(endDate, userPreferences)
  if (moment(DSTStartDate).isBetween(startDate, endDate, undefined, '[]')) {
    return DSTStartDate
  } else if (
    moment(DSTEndDate).isBetween(startDate, endDate, undefined, '[]')
  ) {
    return DSTEndDate
  } else {
    return null
  }
}

// Returns the months number from 0-11
export const getDSTMonth = (DSTDate: string): number =>
  new Date(formatDate(DSTDate, 'YYYY-MM-DD mm:hh:ss')).getMonth()
