import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  {
    attributeName: 'portal',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'portal',
      },
    },
  },
  'order',
  'region',
] as ColumnInputDefinition[]
