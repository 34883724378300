import { DocumentPresetInterface } from '@/tt-app-modular'
import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'
import { DocumentProviders } from '@/tt-entity-documents/types'
import { Resources } from '@/tt-entity-design/src/types'
import { HtmlDocumentInput } from '@/tt-entity-documents/providers/HtmlDocumentServiceProvider'
import { AppContext } from '@/tt-app-context'

const PostOrderDoc: DocumentPresetInterface<HtmlDocumentInput> = {
  name: 'Details',
  async toDocument(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
  ): Promise<HtmlDocumentInput> {
    const api = appContext.authModule.getApi()
    const { entityId } = entityReference

    const data = (await api.get(Resources.POST_ORDERS, entityId, {
      fields: [
        {
          attribute: 'details',
          alias: 'html',
        },
        {
          attribute: 'subject.name',
          alias: 'subject',
        },
      ],
    })) as { html: string; subject: string }

    return {
      provider: DocumentProviders.HTML,
      documentName: data.subject,
      entityReference,
      locale: 'en',
      data: {
        html: `<html><body style='padding:30px; background: white; '>${data.html}</body></html>`,
      },
    }
  },
}
export default PostOrderDoc
