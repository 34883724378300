import { AppContext } from '@/tt-app-context'

/**
 * Checks if the selected account is of type 'REGION'
 * @returns boolean
 */
export const isRegion = (appContext: AppContext): boolean => {
  const fetchedAccounts = appContext.contextManager.getSelectedAccounts()

  if (fetchedAccounts.length > 1) {
    console.warn('More than one account is selected')
  }

  return fetchedAccounts[0]?.type === 'REGION'
}
