<template>
  <div class="operator--tile">
    <EmployeesTile />

    <div class="delete--button w-100 h-100">
      <div class="flex--column align-end justify-center pr-6">
        <v-tooltip left :disabled="canDelete()">
          <template #activator="{ on }">
            <!-- to make the tooltip work on disabled button -->
            <div v-on="on">
              <v-fab-transition>
                <v-btn
                  v-show="showDeleteButton()"
                  color="error"
                  icon
                  :loading="isLoading"
                  :disabled="!canDelete()"
                  @click="deleteOperatorFromOperationCenter"
                >
                  <v-icon v-text="'mdi-delete-circle'" />
                </v-btn>
              </v-fab-transition>
            </div>
          </template>

          <span
            v-text="
              $t('tt-entity-design.operation-centers.cannot-remove-operators')
            "
          />
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor, PropType } from 'vue'
import EmployeesTile from '@/tt-entity-design/src/components/employees/EmployeesTile.vue'

type DeleteCallback = (id: number) => void

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCenterOperatorTile',
  components: {
    EmployeesTile,
  },
  inject: ['getItemHook'],
  props: {
    showDeleteButton: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    canDelete: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    onDelete: {
      type: Function as PropType<DeleteCallback>,
      default: () =>
        console.warn(
          'No "delete" callback provided.',
        ) as unknown as DeleteCallback,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    employeeId(): number {
      return this.getItemHook().getEntityId()
    },
  },
  methods: {
    async deleteOperatorFromOperationCenter() {
      this.isLoading = true
      await this.onDelete(this.employeeId)
      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.delete--button {
  position: absolute;
  top: 0;
  left: 0;
}

.operator--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
