/**
 * Type to use for Typescript to understand the injection
 *
 * ```ts
 * (Vue as VueConstructor<Vue & ForceMountWidget>).extend( // ...
 * ```
 */
export type ForceMountWidget = {
  forceMountWidget: boolean
}

/**
 * Runtime object to `inject` into component
 *
 * ```ts
 * inject: { forceMountWidget }
 * ```
 */
export const forceMountWidget = {
  default: false,
}
