import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportConditionSetActionsForm from './ReportConditionSetActionsForm.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component(
      'ReportConditionSetActionsForm',
      ReportConditionSetActionsForm,
    )

    registerResourceForm(
      Resources.REPORT_CONDITION_SET_ACTIONS,
      'ReportConditionSetActionsForm',
    )
  },
}
