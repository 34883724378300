// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import InvoicesTile from './InvoicesTile.vue'
import InvoicesColumns from './InvoicesColumns'
import InvoicesDetail from './InvoicesDetail.vue'
import InvoicesMetaCard from './InvoicesMetaCard.vue'
import InvoicesReference from './InvoicesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('InvoicesTile', InvoicesTile)
    Vue.component('InvoicesDetail', InvoicesDetail)
    Vue.component('InvoicesMetaCard', InvoicesMetaCard)
    Vue.component('InvoicesReference', InvoicesReference)

    modularManager.addResourcePreset(
      Resources.INVOICES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'InvoicesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.INVOICES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: InvoicesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.INVOICES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'InvoicesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'InvoicesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'InvoicesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
