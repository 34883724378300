import { Model } from '@vuex-orm/core'
import {
  WidgetStoreInterface,
  WidgetStoreModelMeta,
  WidgetReference,
} from '@/tt-widget-factory/types'

/**
 * Archive model
 */
export class WidgetArchiveModel extends Model implements WidgetStoreInterface {
  static entity = 'WidgetArchiveModel'
  static primaryKey = 'uid'

  provider: string
  title: string
  uid: string
  is: string
  category: string
  ownedByMe: boolean
  meta: WidgetStoreModelMeta

  // Data stores the widget definitions
  widget: WidgetReference

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      is: this.string(null),
      uid: this.string(null),
      title: this.string(null),
      category: this.string(null),
      meta: this.attr({}),
      provider: this.string(''),
      ownedByMe: this.boolean(false),
      widget: this.attr({}),
    }
  }
}
