import { modularManager } from '@/tt-app-modular'
import MobileRunsheetOccurrencesActionBar from './MobileRunsheetOccurrencesActionBar.vue'
import MobileRunsheetOccurrenceDoc from './MobileRunsheetOccurrencesDoc'
import { Resources } from '../../types'
import { addResourceDocument } from '@/tt-widget-entity-flow/helper'
import { createResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { MOBILE_RUNSHEET_OCCURRENCES } from './constants'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { MOBILE_SCHEDULE_OCCURRENCES } from '@/apps/app.tracktik.runsheet/resources'
import MobileRunsheetOccurrencesScheduleOccurrences from './MobileRunsheetOccurrencesScheduleOccurrences.vue'

export default {
  install(Vue) {
    addResourceDocument(
      Resources.MOBILE_RUNSHEET_OCCURRENCES,
      MobileRunsheetOccurrenceDoc,
    )

    modularManager.registerActionBar(MOBILE_RUNSHEET_OCCURRENCES, {
      is: MobileRunsheetOccurrencesActionBar,
    })

    /**
     * Needs the API to add the relationList attribute
     */
    addPreviewTab(MOBILE_RUNSHEET_OCCURRENCES, {
      title: createResourceKey(MOBILE_SCHEDULE_OCCURRENCES),
      is: MobileRunsheetOccurrencesScheduleOccurrences,
    })
  },
}
