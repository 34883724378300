<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="id" />
    <tt-attr-row name="reportTemplate" />
    <tt-attr-row name="reportFlag" />
    <tt-attr-row name="createdBy" />
    <tt-attr-row name="position" />
    <tt-attr-row name="submittedOn" />
    <tt-attr-row name="approvedBy" />
    <tt-attr-row name="approvedBy.jobTitle" />
    <tt-attr-row name="approvedOn" />
    <ReportsPDFButton />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'
import ReportsPDFButton from './ReportsPDFButton.vue'

export default Vue.extend({
  name: 'ReportsDetail',
  components: { ReportsPDFButton },
})
</script>
