<template>
  <ShiftsDetail />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ShiftPeriodSummariesDetail',
  provide: {
    relationPrefix: 'shift',
  },
})
</script>
