<template>
  <td :class="cellClass" :style="cellStyle">
    <template v-if="tooltip">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ value }}</span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <template v-else>
      {{ value }}
    </template>
  </td>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import {TQLColumn} from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";
import {MarkupAttributes} from "@/tt-widget-components/widgets/TQLQuery/markup";

export default Vue.extend({
  name: 'TQLRowCell',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    column: {
      type: Object as PropType<TQLColumn>,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    columns: {
      type: Array as PropType<TQLColumn[]>,
      required: true
    }
  },
  computed: {
    tooltip(): boolean | string {
      return this.rowData[MarkupAttributes.CELL_TOOLTIP_PREFIX + this.column.name] || this.rowData[MarkupAttributes.CELL_TOOLTIP_PREFIX + this.columnIndex] || false
    },
    value(): string {
      return this.rowData[this.column.name] || ''
    },
    cellClass(): string {
      return (this.column.class ?? '') + ' ' + (this.rowData[MarkupAttributes.CELL_CLASS_PREFIX + this.column.name] || this.rowData[MarkupAttributes.CELL_CLASS_PREFIX + this.columnIndex] || '')
    },
    cellStyle(): string {
      return this.rowData[MarkupAttributes.CELL_STYLE_PREFIX + this.column.name] || this.rowData[MarkupAttributes.CELL_STYLE_PREFIX + this.columnIndex] || ''
    }
  }
})
</script>
