import { ACCEPTED_FORMATS } from '@/helpers/constants'

export type AcceptedFileTypesInterface = {
  list: string
  listWithDots: string
}

export const getAcceptedFileTypes = (): AcceptedFileTypesInterface => {
  // a raw list
  const list = ACCEPTED_FORMATS.join(', ')
  // a mapped list with the dot as prefix (can be used with the accept="" attribute on the input)
  const listWithDots = ACCEPTED_FORMATS.map((format) => `.${format}`).join(', ')

  return { list, listWithDots }
}
