// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagTemplatesTile from './ReportFlagTemplatesTile.vue'
import ReportFlagTemplatesColumns from './ReportFlagTemplatesColumns'
import ReportFlagTemplatesDetail from './ReportFlagTemplatesDetail.vue'
import ReportFlagTemplatesMetaCard from './ReportFlagTemplatesMetaCard.vue'
import ReportFlagTemplatesReference from './ReportFlagTemplatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportFlagTemplatesTile', ReportFlagTemplatesTile)
    Vue.component('ReportFlagTemplatesDetail', ReportFlagTemplatesDetail)
    Vue.component('ReportFlagTemplatesMetaCard', ReportFlagTemplatesMetaCard)
    Vue.component('ReportFlagTemplatesReference', ReportFlagTemplatesReference)

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagTemplatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplatesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
