// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplateFieldGroupFieldsTile from './ReportTemplateFieldGroupFieldsTile.vue'
import ReportTemplateFieldGroupFieldsColumns from './ReportTemplateFieldGroupFieldsColumns'
import ReportTemplateFieldGroupFieldsDetail from './ReportTemplateFieldGroupFieldsDetail.vue'
import ReportTemplateFieldGroupFieldsMetaCard from './ReportTemplateFieldGroupFieldsMetaCard.vue'
import ReportTemplateFieldGroupFieldsReference from './ReportTemplateFieldGroupFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportTemplateFieldGroupFieldsTile',
      ReportTemplateFieldGroupFieldsTile,
    )
    Vue.component(
      'ReportTemplateFieldGroupFieldsDetail',
      ReportTemplateFieldGroupFieldsDetail,
    )
    Vue.component(
      'ReportTemplateFieldGroupFieldsMetaCard',
      ReportTemplateFieldGroupFieldsMetaCard,
    )
    Vue.component(
      'ReportTemplateFieldGroupFieldsReference',
      ReportTemplateFieldGroupFieldsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplateFieldGroupFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupFieldsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
