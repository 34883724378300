<template>
  <v-menu :disabled="!$slots.menu" top open-on-hover offset-y nudge-top="8">
    <template #activator="{ on }">
      <div class="tt-map-marker" v-on="on">
        <v-card
          class="tt-map-marker--content d-flex justify-center align-center"
          :color="cardColor"
          elevation="4"
          @click="$emit('click')"
        >
          <v-icon
            class="tt-map-marker--icon"
            :color="iconColor"
            v-text="icon"
          />
        </v-card>
        <v-icon
          class="tt-map-marker--arrow-down"
          size="18"
          :color="cardColor"
          v-text="'mdi-menu-down'"
        />
      </div>
    </template>
    <v-card class="pa-3" height="153">
      <slot name="menu" :marker="marker" />
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Marker } from '@tracktik/tt-maps'
import '@tracktik/tt-maps/style'

export default Vue.extend({
  name: 'TMapMarker',
  props: {
    marker: {
      type: Object as PropType<Marker>,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-map-marker',
    },
    color: {
      type: String,
      default: 'ttPrimary',
    },
  },
  computed: {
    cardColor(): string {
      return this.marker.active ? this.color : 'white'
    },
    iconColor(): string {
      return this.marker.active ? 'white' : this.color
    },
  },
})
</script>

<style scoped>
.tt-map-marker {
  position: relative;
  bottom: 21px;
}
.tt-map-marker--arrow-down {
  position: absolute;
  bottom: -10px;
  left: 25%;
}

.tt-map-marker .tt-map-marker--content {
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.tt-map-marker .tt-map-marker--icon {
  font-size: 25px;
}

/* CSS when the marker is hover */
.tt-map-marker:hover {
  bottom: 23px;
}

.tt-map-marker:hover .tt-map-marker--content {
  height: 41px;
  width: 41px;
}

.tt-map-marker:hover .tt-map-marker--content .tt-map-marker--icon {
  font-size: 30px;
}

.tt-map-marker:hover .tt-map-marker--arrow-down {
  left: 28%;
}

/* All transitions */
.tt-map-marker {
  transition: bottom 0.3s ease;
}

.tt-map-marker .tt-map-marker--arrow-down {
  transition: left 0.3s ease;
}

.tt-map-marker .tt-map-marker--content {
  transition: height 0.3s, width 0.3s ease;
}

.tt-map-marker--icon {
  transition: font-size 0.3s ease;
}
</style>
