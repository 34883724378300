<template>
  <span v-if="!showField" />
  <tt-preset-view
    v-else-if="isDetailCard"
    v-bind="componentProps"
    class="ma-2"
    :opened="true"
    :title="$t('components.entity-preview.detail')"
    :resource-name="resource"
    collapsible
  />
  <tt-attr-relation-preview-dispatcher
    v-else
    v-slot="{ openPreview }"
    :class="[
      'text-truncate',
      'tt-entity-relation',
      { 'text-clickable': !preventClick },
    ]"
    :name="name"
  >
    <span
      style="width: 100%"
      class="text-truncate d-inline"
      @click.stop="openPreview"
    >
      <component :is="component.is" v-bind="componentProps" />
    </span>
  </tt-attr-relation-preview-dispatcher>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { modularManager, PresetTypes } from '@/tt-app-modular'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'TtAttrRelation',
  inject: ['getItemHook'],
  provide(): { relationPrefix: string } {
    return {
      relationPrefix: this.name,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<PresetTypes>,
      default: PresetTypes.RELATION_REFERENCE,
    },
  },
  computed: {
    component(): { is: string; props: Record<string, any> } | null {
      const item = modularManager.getResourcePreset(this.resource, this.type)

      return item ? item.data : null
    },
    componentProps(): Record<string, any> {
      return {
        ...this.$props,
        ...this.$attrs,
        ...(this.component.props ?? {}),
      }
    },
    isDetailCard(): boolean {
      return this.type === PresetTypes.DETAILS_CARD
    },
    resource(): string {
      return this.getItemHook().getAttributeRelationResource(this.name)
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
    preventClick(): boolean {
      return this.componentProps?.preventRelationPreview
    },
  },
})
</script>

<style scoped lang="scss">
.tt-entity-relation {
  pointer-events: none;

  &.text-clickable {
    pointer-events: all;

    &:hover,
    &.selected {
      color: var(--v-ttPrimary-base);

      ::v-deep *,
      ::v-deep *:hover {
        color: inherit;
      }
    }
  }
}
</style>
