import { PluginFunction } from 'vue'

const SplitInvoiceInjected = () =>
  import(
    /* webpackChuckName: "SplitInvoiceInjected" */
    './SplitInvoiceInjected.vue'
  )

export { SplitInvoiceInjected }

const SplitInvoiceForm = () =>
  import(
    /* webpackChuckName: "SplitInvoiceForm" */
    './SplitInvoiceForm.vue'
  )

export { SplitInvoiceForm }

export const SplitInvoicePlugin: PluginFunction<void> = (Vue) => {
  Vue.component('SplitInvoiceInjected', SplitInvoiceInjected)
  Vue.component('SplitInvoiceForm', SplitInvoiceForm)
}
