<template>
  <div class="d-flex flex-column">
    <div v-if="loading">
      <v-row v-for="index in 4" :key="`skeleton-${index}`" class="d-flex py-4">
        <v-col cols="7">
          <v-skeleton-loader type="heading" class="py-2" />
        </v-col>
        <v-col cols="5" class="d-flex my-1">
          <v-skeleton-loader type="button" class="flex-grow-1 mr-3" />
          <v-skeleton-loader type="button" class="flex-grow-1 mr-5" />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="hasScheduleData">
      <div v-for="day in scheduleData" :key="`day-${day.id}`">
        <ProvideItemHook :provide="day.itemHook">
          <MobileScheduleGroupRunsheetTile @remove="removeSchedule(day.id)" />
        </ProvideItemHook>
      </div>
      <div
        v-for="exceptionDay in exceptionsData"
        :key="`day-${exceptionDay.id}`"
      >
        <ProvideItemHook :provide="exceptionDay.itemHook">
          <MobileScheduleGroupRunsheetTile
            @remove="removeException(exceptionDay.exceptionType.id)"
          />
        </ProvideItemHook>
      </div>
    </div>
    <div v-else>
      <TNoData />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import TNoData from '@/tt-ui/components/TNoData.vue'
import {
  BatchFile,
  BatchFileActions,
  BatchFileOnFailureOptions,
  BatchFileOperation,
} from '@/types'
import { CollectionQuery } from '@/tt-widget-components'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EntityRecord, ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { Resources } from '@/tt-entity-design/src/types'

import MobileScheduleGroupRunsheetTile from './MobileScheduleGroupRunsheetTile.vue'
import { MobileScheduleActions, MobileScheduleExpceptionActions } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleGroupsInstancesTab',
  components: {
    MobileScheduleGroupRunsheetTile,
    TNoData,
  },
  inject: ['getItemHook'],
  data() {
    return {
      scheduleData: [],
      exceptionsData: [],
      loading: false,
    }
  },
  computed: {
    canViewExceptions(): boolean {
      return (
        this.$appContext.widgetServices.resourceMetaManager.hasResource(
          this.exceptionResource,
        ) &&
        ResourcePermissionAuditor.canViewResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.exceptionResource,
        )
      )
    },
    hasScheduleData(): boolean {
      return this.scheduleData.length > 0
    },
    scheduleIds(): number[] | null {
      return this.scheduleData?.map((schedule) => schedule.id) || null
    },
    scheduleResource(): string {
      return Resources.MOBILE_SCHEDULES
    },
    exceptionResource(): string {
      return Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES
    },
    query(): CollectionQuery | null {
      const entityId = this.getItemHook().entityReference.entityId
      if (!entityId) {
        return null
      }

      return {
        resource: this.scheduleResource,
        limit: 7, //Will only have 7 maximum, one for each day of the week
        sort: [{ attribute: 'id' }],
        filters: [
          {
            attribute: 'scheduleGroup.id',
            operator: 'EQUAL',
            value: entityId,
          },
        ],
      }
    },
    exceptionsQuery(): CollectionQuery {
      if (this.scheduleIds?.length === 0) {
        return null
      }

      return {
        resource: this.exceptionResource,
        limit: 100,
        sort: [{ attribute: 'id' }],
        filters: [
          {
            attribute: 'mobileSchedule.id',
            operator: 'EQUAL',
            value: this.scheduleIds[0],
          },
        ],
        include: ['exceptionType.label', 'mobileSchedule.id'],
      }
    },
  },
  created() {
    this.fetchscheduleData()
  },
  methods: {
    createItemHook(entity: EntityRecord, resourceName: string) {
      return {
        ...entity,
        itemHook: new EntityItemHook(
          this.$appContext,
          {
            entity,
            resourceName,
            entityId: entity.id,
          },
          { fetchEnabled: false, hasFetched: true },
        ),
      }
    },
    fetchscheduleData() {
      this.loading = true
      this.$appContext.widgetServices.resourceDataManager
        .getCollection(this.query, { disableCache: true })
        .then((data) => {
          if (!data?.items?.length) {
            this.scheduleData = []
            this.exceptionsData = []
            this.loading = false

            return
          }
          this.scheduleData = data.items.map((item) =>
            this.createItemHook(item, this.scheduleResource),
          )
          this.fetchExceptionsData()
        })
        .catch(this.handleError)
    },
    fetchExceptionsData() {
      if (!this.canViewExceptions) {
        this.loading = false

        return
      }
      this.$appContext.widgetServices.resourceDataManager
        .getCollection(this.exceptionsQuery, { disableCache: true })
        .then((data) => {
          if (!data?.items?.length) {
            this.exceptionsData = []
            this.loading = false

            return
          }
          this.exceptionsData = data.items.map((item) =>
            this.createItemHook(item, this.exceptionResource),
          )

          this.loading = false
        })
        .catch(this.handleError)
    },
    async removeSchedule(scheduleId: number) {
      this.loading = true
      await this.$appContext.entityServices.persister.executeEntityAction(
        this.scheduleResource,
        MobileScheduleActions.REMOVE,
        scheduleId,
      )
      this.fetchscheduleData()
    },
    async removeException(exceptionId: number) {
      this.loading = true
      const allScheduleExceptionsQuery: CollectionQuery = {
        resource: this.exceptionResource,
        limit: 7,
        sort: [{ attribute: 'id' }],
        filters: [
          {
            attribute: 'mobileSchedule.id',
            operator: 'IN',
            value: this.scheduleIds,
          },
          {
            attribute: 'exceptionType.id',
            operator: 'EQUAL',
            value: exceptionId,
          },
        ],
        include: ['id', 'mobileSchedule.id', 'exceptionType.id'],
      }

      const exceptionsToRemove = (
        await this.$appContext.widgetServices.resourceDataManager.getCollection(
          allScheduleExceptionsQuery,
        )
      ).items.map<BatchFileOperation>((exception) => ({
        action: BatchFileActions.EXECUTE,
        actionName: MobileScheduleExpceptionActions.REMOVE,
        data: {},
        resource: this.exceptionResource as Resources,
        lookup: exception.id,
      }))

      const removeExceptionsPayload: BatchFile = {
        onFailure: BatchFileOnFailureOptions.ABORT,
        operations: exceptionsToRemove,
      }

      await this.$appContext.entityServices.persister.executeBatchFileRequest(
        removeExceptionsPayload,
      )

      this.fetchscheduleData()
    },
    handleError(error: Error) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t('common.error'),
      })
      console.error('Error', error)
    },
  },
})
</script>
