export enum CustomFilterType {
  STRING = 'string',
  RELATION = 'Relation',
  RELATION_LIST = 'RelationList',
  FLOAT = 'float',
  DATE_TIME = 'DateTime',
}

export type CustomFilterString = {
  type: CustomFilterType.STRING
  labels: { label: string }
  format?: string
  relation: null
}

export type CustomFilterDateTime = {
  type: CustomFilterType.DATE_TIME
  labels: { label: string }
  format?: string
  relation: null
}

export type CustomFilterRelation = {
  type: CustomFilterType.RELATION | CustomFilterType.RELATION_LIST
  labels: { label: string }
  format?: string
  relation: { resource: string }
}

export type CustomFilterFloat = {
  type: CustomFilterType.FLOAT
  labels: { label: string }
  format: null
  relation: null
}

export type CustomFilterMeta =
  | CustomFilterString
  | CustomFilterRelation
  | CustomFilterFloat
  | CustomFilterDateTime
