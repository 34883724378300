// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchCustomLocationsTile from './DispatchCustomLocationsTile.vue'
import DispatchCustomLocationsColumns from './DispatchCustomLocationsColumns'
import DispatchCustomLocationsDetail from './DispatchCustomLocationsDetail.vue'
import DispatchCustomLocationsMetaCard from './DispatchCustomLocationsMetaCard.vue'
import DispatchCustomLocationsReference from './DispatchCustomLocationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchCustomLocationsTile', DispatchCustomLocationsTile)
    Vue.component(
      'DispatchCustomLocationsDetail',
      DispatchCustomLocationsDetail,
    )
    Vue.component(
      'DispatchCustomLocationsMetaCard',
      DispatchCustomLocationsMetaCard,
    )
    Vue.component(
      'DispatchCustomLocationsReference',
      DispatchCustomLocationsReference,
    )

    modularManager.addResourcePreset(
      Resources.DISPATCH_CUSTOM_LOCATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchCustomLocationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_CUSTOM_LOCATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchCustomLocationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_CUSTOM_LOCATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchCustomLocationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_CUSTOM_LOCATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchCustomLocationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_CUSTOM_LOCATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchCustomLocationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
