// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import FilterRuleSetsTile from './FilterRuleSetsTile.vue'
import FilterRuleSetsColumns from './FilterRuleSetsColumns'
import FilterRuleSetsDetail from './FilterRuleSetsDetail.vue'
import FilterRuleSetsReference from './FilterRuleSetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('FilterRuleSetsTile', FilterRuleSetsTile)
    Vue.component('FilterRuleSetsDetail', FilterRuleSetsDetail)
    Vue.component('FilterRuleSetsReference', FilterRuleSetsReference)

    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: FilterRuleSetsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
