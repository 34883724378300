<template>
  <RegionListWrapper v-model="selected" :user-regions="userRegions">
    <template #before>
      <v-card-text class="pb-0 tree-icon--container">
        <v-btn outlined class="tree-icon--button" @click="openTree">
          <v-icon v-text="`mdi-file-tree`" />
        </v-btn>
      </v-card-text>
    </template>
  </RegionListWrapper>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { WidgetComponentEvents } from '@/tt-widget-entity-flow'

import { Filter, FilterWithValue } from '../../types'
import { UserRegion } from '../treeview/utils'
import RegionListWrapper from './RegionListWrapper.vue'
import { LayoutWindowEvent } from '@/tt-app-layout/types'

export default Vue.extend({
  name: 'CustomFilterRegionRelation',
  components: {
    RegionListWrapper,
  },
  props: {
    value: {
      type: Object as PropType<Filter>,
      required: true,
    },
    /**
     * We use a callback instead of an event to set the selected filter regions in the tree,
     * because the regions tree is a detached modal from the region filter,
     * and there is no parent to listen to the event.
     */
    onUpdate: {
      type: Function as PropType<(filter: Filter) => void>,
      required: true,
    },
  },
  computed: {
    userRegions(): UserRegion[] {
      return this.$appContext.regionManager.getContextRegions()
    },
    selected: {
      get(): string[] {
        // TODO: narrow down Filter type
        const filterValue = (this.value?.value || '') as string
        return filterValue.split(',').filter(Boolean)
      },
      set(newVal: string[] = []) {
        const value = newVal.join(',')
        this.$emit('input', { ...this.defaultModel, value })
      },
    },
    defaultModel(): FilterWithValue {
      return {
        attribute: this.value.attribute,
        operator: FilterOperatorType.IN,
        value: null,
      }
    },
  },
  methods: {
    openTree(): void {
      this.$emit('cancel')
      this.$eventManager.dispatchEvent(WidgetComponentEvents.REGION_FILTER, {
        filter: { ...this.defaultModel, value: this.selected },
        userRegions: this.userRegions,
        onUpdate: (ids: string[]) => {
          this.onUpdate({
            ...this.defaultModel,
            value: ids.join(','),
          })
        },
        onClose: () => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)
        },
      })
    },
  },
})
</script>

<style scoped>
.tree-icon--container {
  display: flex;
  justify-content: flex-end;
}

.tree-icon--button {
  min-width: 14px;
  padding: 0 8px;
}
</style>
