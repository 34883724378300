<template>
  <ActionBarPreset :actions="actionList" />
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'

import { ActiveWindowProvider } from '@/tt-app-layout'
import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { createViewAction } from '../../tools/actions-generator'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'MobileRunsheetsActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      required: true,
    },
  },
  computed: {
    actionsDictionnary(): Record<string, ViewAction> {
      const openFormAction = async (actionName: string) => {
        const payload = await this.getItemHook().getActionFormAsPage(actionName)

        this.activeWindow.next(payload)
      }

      return {
        ['change-zone']: createViewAction(
          'mdi-swap-horizontal',
          'change-zone',
          Resources.MOBILE_RUNSHEETS,
          openFormAction,
        ),
        ['start-session']: createViewAction(
          'mdi-timer-outline',
          'start-session',
          Resources.MOBILE_RUNSHEETS,
          openFormAction,
        ),
        ['archive']: createViewAction(
          'mdi-archive-outline',
          'archive',
          Resources.MOBILE_RUNSHEETS,
          openFormAction,
        ),
        ['add-exception-types']: createViewAction(
          'mdi-plus',
          'add-exception-types',
          Resources.MOBILE_RUNSHEETS,
          openFormAction,
        ),
      }
    },
    actionList(): ViewAction[] {
      return this.actions
        .map(
          (action) =>
            this.actionsDictionnary[action.actionName] || (null as ViewAction),
        )
        .filter(Boolean)
    },
  },
})
</script>
