// Enter your custom installation here
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import FilterRuleSetAssetsRecipients from './FilterRuleSetAssetsRecipients.vue'
import FilterRuleSetAssetsForm from './FilterRuleSetAssetsForm.vue'
import FilterRuleSetAssetsFilters from './FilterRuleSetAssetsFilters.vue'
import { LayoutComponents } from '@/tt-app-layout'
import { ACTION_FILTERS } from './FilterRuleSetAssetsFilters.util'
import { ACTION_RECIPIENTS } from './FilterRuleSetAssetsRecipients.util'

import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('FilterRuleSetAssetsForm', FilterRuleSetAssetsForm)
    registerResourceForm(
      Resources.FILTER_RULE_SET_ASSETS,
      'FilterRuleSetAssetsForm',
    )

    Vue.component(
      'FilterRuleSetAssetsRecipients',
      FilterRuleSetAssetsRecipients,
    )

    Vue.component('FilterRuleSetAssetsFilters', FilterRuleSetAssetsFilters)

    registerResourceForm(
      Resources.FILTER_RULE_SET_ASSETS,
      'FilterRuleSetAssetsRecipients',
      ACTION_RECIPIENTS,
      {
        openIn: LayoutComponents.dialog,
      },
    )

    registerResourceForm(
      Resources.FILTER_RULE_SET_ASSETS,
      'FilterRuleSetAssetsFilters',
      ACTION_FILTERS,
      { openIn: LayoutComponents.dialog },
    )
  },
}
