<template>
  <div>
    <TLoadingBar v-show="loadingReportTemplate" />
    <json-field name="reportTemplate" />
    <json-field
      v-if="isReportTemplateDispatchableTask"
      name="isDispatcherField"
      v-bind="isDispatcherFieldsProps"
    />
    <json-field name="type" />
    <json-field name="label" />
    <json-field name="extra" />
    <json-field name="listItems">
      <template v-if="!isNewItem" #beforeItems>
        <v-alert outlined type="info">
          {{ listItemsWarningText }}
        </v-alert>
      </template>
    </json-field>
    <json-field name="required" />
    <json-field name="confidential" />
    <json-field name="repeatable" />
    <json-field name="fieldTag" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { Api } from '@tracktik/tt-authentication'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

import { FormHookProvider } from '@/tt-widget-components'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { ReportTemplateType } from '../report-templates/types'
import { Resources } from '../../types'

type VueWithInjections = VueConstructor<
  Vue & FormHookProvider & ItemHookProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplateFieldsForm',
  inject: ['formHook'],
  data() {
    return {
      loadingReportTemplate: false,
      reportTemplateType: null as null | ReportTemplateType,
    }
  },
  computed: {
    api(): Api {
      return this.$appContext.authModule.getApi()
    },
    id(): number {
      return this.formHook().getPathValue('id')
    },
    isDispatcherFieldItems(): { value: boolean; text: string }[] {
      if (!this.isReportTemplateDispatchableTask) {
        return []
      }

      const getText = (value: boolean): string =>
        this.$t(this.translationKey(`isDispatcherField.options.${value}`))

      return [
        { value: true, text: getText(true) },
        { value: false, text: getText(false) },
      ]
    },
    isDispatcherFieldsProps(): Record<string, unknown> {
      return {
        ...vuetifyDefaults,
        as: 'VAutocomplete',
        items: this.isDispatcherFieldItems,
        label: this.$t(this.translationKey('isDispatcherField.label')),
      }
    },
    isNewItem(): boolean {
      return !this.id
    },
    isReportTemplateDispatchableTask(): boolean {
      return (
        !this.loadingReportTemplate &&
        this.reportTemplateType === ReportTemplateType.DISPATCHABLE_TASK
      )
    },
    listItemsWarningText(): string {
      return this.$t('report_template_fields_view.list-items-warning')
    },
    reportTemplateId(): number | undefined {
      return this.formHook().getPathValue('reportTemplate')
    },
  },
  watch: {
    reportTemplateId: {
      immediate: true,
      async handler(): Promise<void> {
        this.reportTemplateType = await this.fetchReportTemplateType()

        const currentValue = this.formHook().getPathValue('isDispatcherField')
        if (this.reportTemplateType === null && currentValue != null) {
          this.formHook().setObjectValue('isDispatcherField', null)
        } else if (this.reportTemplateType !== null && currentValue == null) {
          this.formHook().setObjectValue('isDispatcherField', false)
        }
      },
    },
  },
  methods: {
    async fetchReportTemplateType(): Promise<ReportTemplateType | null> {
      if (!this.reportTemplateId) {
        return null
      }

      this.loadingReportTemplate = true

      return this.api
        .get(Resources.REPORT_TEMPLATES, this.reportTemplateId, {
          fields: [{ attribute: 'type' }],
        })
        .then((data: null | { type: ReportTemplateType }) => data?.type ?? null)
        .catch((err) => {
          this.$crash.captureException(err)

          return null
        })
        .finally(() => (this.loadingReportTemplate = false))
    },
    translationKey(key: string): string {
      return `tt-entity-design.report-template-fields.form.${key}`
    },
  },
})
</script>
