// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ProcessedPayrollPayrunsTile from './ProcessedPayrollPayrunsTile.vue'
import ProcessedPayrollPayrunsColumns from './ProcessedPayrollPayrunsColumns'
import ProcessedPayrollPayrunsDetail from './ProcessedPayrollPayrunsDetail.vue'
import ProcessedPayrollPayrunsReference from './ProcessedPayrollPayrunsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ProcessedPayrollPayrunsTile', ProcessedPayrollPayrunsTile)
    Vue.component(
      'ProcessedPayrollPayrunsDetail',
      ProcessedPayrollPayrunsDetail,
    )
    Vue.component(
      'ProcessedPayrollPayrunsReference',
      ProcessedPayrollPayrunsReference,
    )

    modularManager.addResourcePreset(
      Resources.PROCESSED_PAYROLL_PAYRUNS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ProcessedPayrollPayrunsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PROCESSED_PAYROLL_PAYRUNS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ProcessedPayrollPayrunsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PROCESSED_PAYROLL_PAYRUNS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ProcessedPayrollPayrunsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PROCESSED_PAYROLL_PAYRUNS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ProcessedPayrollPayrunsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
