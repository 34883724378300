<template>
  <v-toolbar
    dense
    short
    height="40"
    class="text-right hidden-print-only mb-6"
    style="opacity: 0.5; margin-top: 8px"
  >
    <v-toolbar-title class="caption">Row Settings</v-toolbar-title>
    <v-spacer />
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          small
          class="customMinWidth"
          v-on="on"
          @click="hook.addColumn(rowIndex, false)"
        >
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('widgets.dashboard.add_column_left')" />
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          small
          class="customMinWidth"
          v-on="on"
          @click="hook.addColumn(rowIndex, true)"
        >
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('widgets.dashboard.add_column_right')" />
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          small
          class="customMinWidth"
          v-on="on"
          @click="hook.addRow(rowIndex + 1)"
        >
          <v-icon>keyboard_arrow_down</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('widgets.dashboard.add_row_down')" />
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          small
          class="customMinWidth"
          v-on="on"
          @click="hook.addRow(rowIndex)"
        >
          <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('widgets.dashboard.add_row_up')" />
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          text
          small
          class="customMinWidth"
          v-on="on"
          @click="hook.deleteRow(rowIndex)"
        >
          <v-icon small>close</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('widgets.dashboard.delete_row')" />
    </v-tooltip>
    <OverflowBox>
      <v-chip
        v-for="size in sizes"
        :key="size"
        x-small
        :color="row.size === size ? 'primary' : ''"
        class="ml-4 cursor-pointer"
        @click="hook.changeRowSize(rowIndex, size)"
        v-text="$t(`widgets.dashboard.sizes.${size}`)"
      />
      <template #overflow>
        <RowSizesDialog
          :sizes="sizes"
          :selected-size="row.size"
          @change="hook.changeRowSize(rowIndex, $event)"
        >
          <template #activator="{ on }">
            <v-btn text x-small v-on="on">
              <span v-text="$t('widgets.dashboard.row_sizes')" />
            </v-btn>
          </template>
        </RowSizesDialog>
      </template>
    </OverflowBox>
  </v-toolbar>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import DashboardWidgetHook from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetHook'
import { DashboardWidgetRowSizes } from '@/tt-widget-components/widgets/Dashboard/types'
import { DashboardRow } from '@/tt-widget-components'
import RowSizesDialog from '@/tt-widget-components/components/RowSizesDialog.vue'

export default Vue.extend({
  components: {
    RowSizesDialog,
  },
  props: {
    hook: Object as PropType<DashboardWidgetHook>,
    rowIndex: Number,
    row: Object as PropType<DashboardRow>,
  },
  data() {
    return {
      sizes: [
        DashboardWidgetRowSizes.EXTRA_SMALL,
        DashboardWidgetRowSizes.SMALL,
        DashboardWidgetRowSizes.MEDIUM,
        DashboardWidgetRowSizes.LARGE,
        DashboardWidgetRowSizes.EXTRA_LARGE,
      ],
    }
  },
})
</script>

<style scoped>
button.customMinWidth {
  min-width: 30px !important;
  padding: 0 6px !important;
}
</style>
