<template>
  <span>
    <v-menu nudge-bottom>
      <template #activator="{ on }">
        <v-btn
          data-cy="permission-selector"
          small
          text
          :color="color"
          v-on="on"
        >
          <span>
            {{ value ? $t(`common.role_${value}`) : $t('common.choose_one') }}
          </span>
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="role in roles"
          :key="role"
          @click="$emit('input', role)"
          v-text="$t(`common.role_${role}`)"
        />
      </v-list>
    </v-menu>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PermissionTypeMap, PermissionType, DENIED } from '../types'

// @todo: hiding deny, waiting for API fix (API-973)
const roles = Object.keys(PermissionTypeMap).filter(
  (v: PermissionType) => v !== DENIED,
)
export default Vue.extend({
  name: 'SharableFormPermissionSelector',
  props: {
    value: {
      type: String as PropType<PermissionType>,
      default: null,
    },
  },
  data: () => ({ roles }),
  computed: {
    color(): string {
      return this.value === DENIED ? 'red' : 'primary'
    },
  },
})
</script>
