import hook from './SingleCounterWidgetHook'
import template from './SingleCounterWidget.vue'
import schema from './schema'
import SingleCounterWidgetEditor from './SingleCounterWidgetEditor.vue'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import Vue from 'vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import ConditionalColorInput from './ConditionalColorInput.vue'

Vue.component('SingleCounterWidgetEditor', SingleCounterWidgetEditor)
Vue.component('ConditionalColorInput', ConditionalColorInput)

const SingleCounterWidget: WidgetTypeDefinition = {
  name: WidgetName.SINGLE_COUNTER_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    Icon: {
      view: {
        is: 'IconSelector',
      },
    },
    ConditionalColor: {
      view: {
        is: 'ConditionalColorInput',
      },
    },
    SingleCounterWidget: {
      view: {
        is: 'SingleCounterWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-numeric',
    color: '#f23d3d',
    print: true,
    thumbnail: require('./counter.png'),
    description: 'Single counter based on an aggregation query',
  },
}

export default SingleCounterWidget
