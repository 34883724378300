<template>
  <div>
    <span class="contact-details--title pb-2">{{
      $t(createTranslationKey(title))
    }}</span>
    <div
      v-for="(contact, index) in contacts"
      :key="index"
      class="pa-4 v-card v-card--flat v-sheet theme--light"
    >
      <div v-for="(value, key) in contact" :key="key">
        <v-row no-gutters class="ma-1">
          <v-col class="attribute-row-label" cols="4">
            <span>{{
              $t(createTranslationKey(`contacts-detail-labels.${key}`))
            }}</span>
          </v-col>
          <v-col class="ml-1 attribute-row-value">
            <div>
              <span>{{ value }}</span>
            </div>
          </v-col>
        </v-row>
        <v-divider style="opacity: 0.5" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { ContactType, RegionContactType } from '@/tt-cta-contacts/types'
export default Vue.extend({
  name: 'AccountContactDetails',
  props: {
    contacts: {
      type: Array as PropType<Array<ContactType | RegionContactType>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    createTranslationKey(key: string) {
      return `tt-entity-design.system-exception-tickets.${key}`
    },
  },
})
</script>
