// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportConditionsTile from './ReportConditionsTile.vue'
import ReportConditionsColumns from './ReportConditionsColumns'
import ReportConditionsDetail from './ReportConditionsDetail.vue'
import ReportConditionsReference from './ReportConditionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportConditionsTile', ReportConditionsTile)
    Vue.component('ReportConditionsDetail', ReportConditionsDetail)
    Vue.component('ReportConditionsReference', ReportConditionsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_CONDITIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportConditionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportConditionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportConditionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportConditionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
