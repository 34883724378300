// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BreakRuleExceptionsTile from './BreakRuleExceptionsTile.vue'
import BreakRuleExceptionsColumns from './BreakRuleExceptionsColumns'
import BreakRuleExceptionsDetail from './BreakRuleExceptionsDetail.vue'
import BreakRuleExceptionsMetaCard from './BreakRuleExceptionsMetaCard.vue'
import BreakRuleExceptionsReference from './BreakRuleExceptionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BreakRuleExceptionsTile', BreakRuleExceptionsTile)
    Vue.component('BreakRuleExceptionsDetail', BreakRuleExceptionsDetail)
    Vue.component('BreakRuleExceptionsMetaCard', BreakRuleExceptionsMetaCard)
    Vue.component('BreakRuleExceptionsReference', BreakRuleExceptionsReference)

    modularManager.addResourcePreset(
      Resources.BREAK_RULE_EXCEPTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BreakRuleExceptionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_EXCEPTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BreakRuleExceptionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_EXCEPTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleExceptionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_EXCEPTIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleExceptionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_EXCEPTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BreakRuleExceptionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
