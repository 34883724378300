// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import UserRolesTile from './UserRolesTile.vue'
import UserRolesColumns from './UserRolesColumns'
import UserRolesDetail from './UserRolesDetail.vue'
import UserRolesReference from './UserRolesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('UserRolesTile', UserRolesTile)
    Vue.component('UserRolesDetail', UserRolesDetail)
    Vue.component('UserRolesReference', UserRolesReference)

    modularManager.addResourcePreset(
      Resources.USER_ROLES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'UserRolesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_ROLES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: UserRolesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_ROLES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'UserRolesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.USER_ROLES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'UserRolesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
