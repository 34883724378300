import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'type',
  'segment',
  'message',
  'countryCode',
  'numbers',
  'createdOn',
] as ColumnInputDefinition[]
