<template>
  <div>
    <slot :form-manager="formManager" />
  </div>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue, { PropType } from 'vue'

import { ErrorMap } from '@tracktik/tt-json-schema-form'

import { UnsubscribeFunction } from '@/tt-event-manager'

import getCompoundFormManager from './compoundFormManager'
import { CompoundFormManager, CompoundFormManagerProvider } from '../types'

export default Vue.extend({
  name: 'ProvideCompoundFormManager',
  provide(): CompoundFormManagerProvider {
    return { getFormManager: () => this.formManager }
  },
  props: {
    initialValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      formManager: null as CompoundFormManager | null,
      unsubscribers: [] as UnsubscribeFunction[],
    }
  },
  created() {
    this.formManager = getCompoundFormManager(this.initialValue)

    this.unsubscribers.push(
      this.formManager.onErrorsChange((errors: ErrorMap) => {
        if (isEmpty(errors)) {
          this.$emit('valid')
        } else {
          this.$emit('errors', errors)
        }
      }),
    )

    this.unsubscribers.push(
      this.formManager.onValueChange((value: Record<string, unknown>) => {
        this.$emit('input', value)
      }),
    )

    this.emitReady()
  },
  beforeDestroy() {
    this.unsubscribers.forEach((unsubscribe) => unsubscribe())
  },
  methods: {
    emitReady() {
      this.$emit('ready', this.formManager)
    },
  },
})
</script>
