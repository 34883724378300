// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileScheduleExceptionTypesTile from './MobileScheduleExceptionTypesTile.vue'
import MobileScheduleExceptionTypesColumns from './MobileScheduleExceptionTypesColumns'
import MobileScheduleExceptionTypesDetail from './MobileScheduleExceptionTypesDetail.vue'
import MobileScheduleExceptionTypesReference from './MobileScheduleExceptionTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileScheduleExceptionTypesTile',
      MobileScheduleExceptionTypesTile,
    )
    Vue.component(
      'MobileScheduleExceptionTypesDetail',
      MobileScheduleExceptionTypesDetail,
    )
    Vue.component(
      'MobileScheduleExceptionTypesReference',
      MobileScheduleExceptionTypesReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileScheduleExceptionTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileScheduleExceptionTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleExceptionTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileScheduleExceptionTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
