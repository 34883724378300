import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import { BooleanFieldType, CHECKBOX } from './types'
import schema from './schema.json'
import FieldsetCheckbox from './FieldsetCheckbox.vue'

const ui: FieldUI = {
  name: i18nFieldKey(CHECKBOX),
  color: 'purple',
  icon: 'mdi-checkbox-marked-circle-outline',
}

// @ts-ignore -- allow passing component object in viewOptions
const converter: FieldConverter = (field: BooleanFieldType) => ({
  getJsonSchema: () => {
    return {
      ...getBaseFieldJsonSchema(field),
      type: 'boolean',
    }
  },
  getViewOption: () => {
    return {
      is: FieldsetCheckbox,
    }
  },
})

export const BooleanMeta: FieldMeta = {
  field: CHECKBOX,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
