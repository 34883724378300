<template>
  <v-chip x-small label :class="classes" v-text="text" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OpenShiftRequestsAttrOvertimeImpact',
  inject: ['getItemHook'],
  data() {
    return {
      name: 'extensions.overtimeImpact.hours',
      classDefaults: {
        'color-pill': true,
      },
      overtimeImpact: {
        classes: {
          error: true,
          'lighten-4': true,
          'error--text': true,
          'text--darken-3': true,
        },
      },
      noOvertimeImpact: {
        classes: {
          green: true,
          'lighten-4': true,
          'green--text': true,
          'text--darken-3': true,
        },
      },
    }
  },
  computed: {
    classes(): Record<string, boolean> {
      const classes = this.hasOvertimeImpact
        ? this.overtimeImpact.classes
        : this.noOvertimeImpact.classes

      return {
        ...this.classDefaults,
        ...classes,
      }
    },
    text(): string {
      const label = this.hasOvertimeImpact ? 'OT' : 'OK'

      if (this.hasOvertimeImpact) {
        // @todo inspect i18n
        // this.$t('tt-entity-design.open-shift-requests.attrs.overtimeImpact.reach')
        return `${label} ${this.value}h`
      }

      // @todo inspect i18n
      // this.$t('tt-entity-design.open-shift-requests.attrs.overtimeImpact.unreach')
      return `${label}`
    },
    value(): number {
      return this.getItemHook().get(this.name)
    },
    hasOvertimeImpact(): boolean {
      return this.value > 0
    },
  },
  created() {
    this.getItemHook().addAttribute('extensions.overtimeImpact')
  },
})
</script>
