// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteLocationsTile from './SiteLocationsTile.vue'
import SiteLocationsColumns from './SiteLocationsColumns'
import SiteLocationsDetail from './SiteLocationsDetail.vue'
import SiteLocationsMetaCard from './SiteLocationsMetaCard.vue'
import SiteLocationsReference from './SiteLocationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SiteLocationsTile', SiteLocationsTile)
    Vue.component('SiteLocationsDetail', SiteLocationsDetail)
    Vue.component('SiteLocationsMetaCard', SiteLocationsMetaCard)
    Vue.component('SiteLocationsReference', SiteLocationsReference)

    modularManager.addResourcePreset(
      Resources.SITE_LOCATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteLocationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_LOCATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteLocationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_LOCATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteLocationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_LOCATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteLocationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_LOCATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteLocationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
