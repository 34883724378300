import { PluginFunction } from 'vue'

const BackOfficeReportsView = () => import('./views/BackOfficeReportsView.vue')
const ScheduleChangesHistory = () =>
  import('./components/scheduling/ScheduleChangesHistory.vue')
const ScheduleReportsClickGuard = () =>
  import('./components/scheduling/ScheduleReportsClickGuard.vue')
const EarningsPremiumAddToBaseRateCustomColumn = () =>
  import(
    './components/earnings-premium/EarningsPremiumAddToBaseRateCustomColumn.vue'
  )
const EarningsPremiumHoursCustomRow = () =>
  import('./components/earnings-premium/EarningsPremiumHoursCustomRow.vue')
const HCMAccrualBalanceRow = () =>
  import('./components/human-capital-management/HCMAccrualBalanceRow.vue')

export { BackOfficeReportsView }

export const BackOfficeReportsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('BackOfficeReportsView', BackOfficeReportsView)
  Vue.component('ScheduleChangesHistory', ScheduleChangesHistory)
  Vue.component('ScheduleReportsClickGuard', ScheduleReportsClickGuard)
  Vue.component(
    'EarningsPremiumAddToBaseRateCustomColumn',
    EarningsPremiumAddToBaseRateCustomColumn,
  )
  Vue.component('EarningsPremiumHoursCustomRow', EarningsPremiumHoursCustomRow)
  Vue.component('HCMAccrualBalanceRow', HCMAccrualBalanceRow)
}
