import { ChartComponent, FontModel } from '@syncfusion/ej2-vue-charts'
import { PivotViewComponent } from '@syncfusion/ej2-vue-pivotview'
import Vue from 'vue'
import { DashboardWidgetModel } from '..'
import { WidgetPosition } from '../widgets/Dashboard/types'

export const currencyFormat = 'c2'

/**
 * There are some instances when we need to refresh the SF component
 * Ex. Switching Themes we refresh to display proper colors.
 */
export async function refreshSFComponent(
  component: ChartComponent | PivotViewComponent,
): Promise<any> {
  await Vue.nextTick()
  return component?.ej2Instances.refresh()
}

/**
 * Get default text style
 * @param fontSize
 */
export function getTextStyle(fontSize: string | number = '1em'): FontModel {
  return {
    opacity: 0.8,
    fontFamily:
      '"Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont"',
    fontWeight: '600',
    size: fontSize + '',
  } as FontModel
}

export const getSubTitleStyle = () => ({
  opacity: 0.7,
  fontFamily:
    '"Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont"',
  fontWeight: 'regular',
  size: '12px',
  fontStyle: 'italic',
  textOverflow: 'none',
})

export type SyncFusionThemes = 'MaterialDark' | 'Material'
/**
 * Get the theme for the chart
 * @param isDark
 */
export function getTheme(isDark: boolean): SyncFusionThemes {
  return isDark ? 'MaterialDark' : 'Material'
}
/**
 * Get the background color for the chart
 * @param isDark
 */
export function getBackground(isDark: boolean) {
  return isDark ? '#00000000' : '#ffffff00'
}
