<template>
  <div>
    <json-object name="days">
      <SchedulingGroupDaySetupDayRow day-name="WEEKDAYS" :children="weekDays" />
      <SchedulingGroupDaySetupDayRow day-name="WEEKENDS" :children="weekEnds" />
    </json-object>
    <json-object v-if="showExceptions" name="holidays">
      <SchedulingGroupDaySetupDayRow
        v-for="exception in holidays"
        :key="holidayKey(exception.id)"
        :day-name="holidayKey(exception.id)"
        :children="childrenNames(exception.children)"
        is-holiday
      />
    </json-object>
    <json-object v-if="shouldDisplayOthers" name="others">
      <SchedulingGroupDaySetupDayRow
        v-for="exception in others"
        :key="othersKey(exception.id)"
        :day-name="othersKey(exception.id)"
        :children="othersChildrenNames(exception.children)"
        is-others
      />
    </json-object>
  </div>
</template>
<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue, { PropType } from 'vue'

import { Resources } from '@/tt-entity-design/src/types'

import {
  ExceptionType,
  SchedulingEntityResource,
  weekDays,
  weekEnds,
} from '../types'
import SchemaHelper from '../helpers/schema-helper'
import SchedulingGroupDaySetupDayRow from './SchedulingGroupDaySetupDayRow.vue'

export default Vue.extend({
  name: 'SchedulingGroupDaySetupForm',
  components: {
    SchedulingGroupDaySetupDayRow,
  },
  props: {
    holidays: { type: Array as PropType<ExceptionType[]>, default: () => [] },
    others: {
      type: Array as PropType<ExceptionType[]>,
      default: () => [],
    },
    exceptionEnabled: {
      type: Boolean,
      default: false,
    },
    othersTypeForRunsheetEventsEnabled: {
      type: Boolean,
      default: false,
    },
    othersTypeForRunsheetsEnabled: {
      type: Boolean,
      default: false,
    },
    othersTypeForSiteTasksEnabled: {
      type: Boolean,
      default: false,
    },
    resourceName: {
      type: String as PropType<SchedulingEntityResource>,
      required: true,
    },
  },
  computed: {
    hasExceptions(): boolean {
      return !isEmpty(this.holidays)
    },
    schemaHelper(): SchemaHelper {
      return new SchemaHelper(this.resourceName)
    },
    showExceptions(): boolean {
      return (
        this.hasExceptions &&
        (this.exceptionEnabled ||
          this.resourceName === Resources.MOBILE_RUNSHEETS)
      )
    },
    weekDays(): string[] {
      return [...weekDays]
    },
    weekEnds(): string[] {
      return [...weekEnds]
    },
    shouldDisplayOthers(): boolean {
      if (this.resourceName === Resources.MOBILE_RUNSHEETS) {
        return this.othersTypeForRunsheetsEnabled
      }
      if (this.resourceName === Resources.MOBILE_SCHEDULES) {
        return this.othersTypeForRunsheetEventsEnabled
      }
      if (this.resourceName === Resources.SITE_TASK_SCHEDULE_TIMINGS) {
        return this.othersTypeForSiteTasksEnabled
      }

      return false
    }
  },
  methods: {
    holidayKey(id: number): string {
      return this.schemaHelper.getHolidayKey(id)
    },
    othersKey(id: number): string {
      return this.schemaHelper.getOthersKey(id)
    },
    childrenNames(children: ExceptionType[]): string[] {
      return children?.map((child) => this.holidayKey(child.id)) || []
    },
    othersChildrenNames(children: ExceptionType[]): string[] {
      return children?.map((child) => this.othersKey(child.id)) || []
    },
    
  },
})
</script>
