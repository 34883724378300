import { CTAType } from './type'
import {
  DISPATCHABLE_TYPE,
  TYPE_VALUES,
  SYSTEM_EXCEPTION_TICKET_STATUS_CLASS,
  DEFAULT_STATUS_COLOR,
} from './constants'

export const getStatusClass = (status: string) =>
  SYSTEM_EXCEPTION_TICKET_STATUS_CLASS[status] || DEFAULT_STATUS_COLOR

export const isDispatchableSystemExceptionTickets = (type: CTAType): boolean =>
  DISPATCHABLE_TYPE.includes(type)

export const isPanicTriggeredTicket = (type: CTAType): boolean =>
  TYPE_VALUES.PANIC_TRIGGERED_TICKET === type
