import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'id',
  'updatedOn',
  'type',
  'createdBy',
  {
    attributeName: 'relation',
    component: { is: 'SystemExceptionTicketsRelationAttr' },
  },
  {
    attributeName: 'status',
    component: {
      is: 'SystemExceptionTicketsStatusAttr',
    },
  },
  'assignedOperator',
] as ColumnInputDefinition[]
