import { FormHookProvider } from '@/tt-widget-components'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor } from 'vue'

export type AclTag = string

export enum AclGrantPermission {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
}

export const { VIEWER, EDITOR } = AclGrantPermission

/**
 * Sharable service
 */
export interface SharableServiceInterface {
  /**
   * Return the list of UserGroups the current user is part of
   */
  getUserGroups(): Promise<UserGroupMap>

  /**
   * Search for user-groups by keyword
   * @param keyword
   */
  search(keyword: string): Promise<UserGroupItem[]>

  /**
   * Translate tags to UserGroups
   * @param tags
   */
  translateTags(tags: string[]): Promise<UserGroupMap>

  /**
   * Translate tags from a rule to UserGroups
   * @param rule
   */
  translateRule(rule: AclRule, ownerTag?: string): Promise<UserGroupMap>
}

// The item source
export enum AclRuleItemSource {
  THIS = 'THIS',
  PARENT = 'PARENT',
}

export const { THIS, PARENT } = AclRuleItemSource

// The rule item
export interface AclRuleItem {
  permission: AclGrantPermission
  source: AclRuleItemSource
}

// The rule
export interface AclRule {
  grant?: { [k: string]: AclRuleItem }
  deny?: { [k: string]: AclRuleItemSource }
}

export interface AclTransaction {
  aclRule: AclRule
  notify: boolean
  message: string
}

// UserGroupItem
export interface UserGroupItem {
  name: string
  aclTag: AclTag
  type: UserGroupItemTypes
  providerCode: string
  avatar?: string
  subTitle?: string
}

export type UserGroupMap = Record<string, UserGroupItem>

// UserGroupItem Type Enums
export enum UserGroupItemTypes {
  GROUP = 'GROUP',
  USER = 'USER',
}

export enum UserGroupEndpoints {
  SEARCH = 'reach/usergroup/search',
  TRANSLATE = 'reach/usergroup/translate',
  MY_USER_GROUPS = 'reach/usergroup/me',
}

export const DENIED = 'DENIED'

export const OWNER = 'OWNER'

export type PermissionType = AclGrantPermission | typeof DENIED

export const PermissionTypeMap: Record<string, keyof AclRule> = {
  [VIEWER]: 'grant',
  [EDITOR]: 'grant',
  [DENIED]: 'deny',
} as const

export type AclRuleViewItem = {
  tag: string
  permission: PermissionType | typeof OWNER
  source: AclRuleItemSource
  isLoading: boolean
}
