import {
  FieldTypes,
  ResourceMetaManagerInterface,
  ResourceMetaProviderInterface,
  ResourcesMeta,
} from './types'
import { Dimension, Measure } from '@/tt-widget-factory/definitions'
import {
  Attribute,
  AttributeDictionary,
  Resource,
  ResourceModelContext,
  ResourceScopeDictionary,
} from '@/tt-widget-factory/services/resource-meta/types'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'
import { CollectionQuery } from '@/tt-widget-components'

export default class ResourceMetaManager
  implements ResourceMetaManagerInterface
{
  /**
   * Registered providers
   */
  private providers: ResourceMetaProviderInterface[] = []

  /**
   * Constructor
   * @param providers
   */
  constructor(providers: ResourceMetaProviderInterface[] = []) {
    this.providers = providers
  }

  /**
   * Check if an attribute is a date
   * @param resource
   * @param attribute
   */
  isDateAttribute(resource: string, attribute: string): boolean {
    const attributeMeta = this.getAttribute(resource, attribute)
    if (!attributeMeta) {
      return false
    }
    return [
      FieldTypes.Date,
      FieldTypes.DateTime,
      FieldTypes.TimeStampDate,
      FieldTypes.TimeStampNumber,
    ].includes(attributeMeta.type)
  }

  /**
   * Register the provider
   * @param provider
   */
  registerProvider(provider: ResourceMetaProviderInterface) {
    this.providers.push(provider)
  }

  getAllResources(): ResourcesMeta {
    return this.providers
      .map((provider) => provider.getAllResources())
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }

  getRootResources(): ResourcesMeta {
    return this.providers
      .map((provider) => provider.getRootResources())
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }

  getRootResourceNames(): string[] {
    return this.providers.flatMap((provider) => provider.getRootResourceNames())
  }

  /**
   * Get a resource object
   * @param resourceName
   */
  getResource(resourceName: string): Resource | undefined {
    return this.resolveProvider(resourceName).getResource(resourceName)
  }

  /**
   * Check if a resource exists in the schema
   */
  hasResource(resourceName: string): boolean {
    return this.providers.some((provider) => provider.hasResource(resourceName))
  }
  /**
   * Get a resource label
   * @param resourceName
   */
  getResourceLabel(resourceName: string): string {
    return this.resolveProvider(resourceName).getResourceLabel(resourceName)
  }

  /**
   * Gets resource model context
   * @param resourceName
   * @returns resource model context
   */
  getResourceModelContext(
    resourceName: string,
  ): ResourceModelContext | undefined {
    return this.resolveProvider(resourceName).getResourceModelContext(
      resourceName,
    )
  }

  /**
   * Gets resource scopes
   * @param resourceName
   * @returns resource scopes
   */
  getResourceScopes(resourceName: string): ResourceScopeDictionary | undefined {
    return this.resolveProvider(resourceName).getResourceScopes(resourceName)
  }

  /**
   * Get the list of resource names
   */
  getResourceNames(): string[] {
    return this.providers.flatMap((provider) => provider.getResourceNames())
  }

  /**
   * Get the list of attributes.
   * @param resourceName
   * @param maxDepth
   */
  getAttributes(
    resourceName: string,
    maxDepth = 3,
  ): AttributeDictionary | undefined {
    return this.resolveProvider(resourceName).getAttributes(
      resourceName,
      maxDepth,
    )
  }

  getUpdatedRelationListAttribute(
    resourceName: string,
    fields: CollectionQuery['fields'],
  ): CollectionQuery['fields'] {
    return this.resolveProvider(resourceName).getUpdatedRelationListAttribute(
      resourceName,
      fields,
    )
  }

  getExtensions(resourceName: string) {
    return this.resolveProvider(resourceName).getExtensions(resourceName)
  }

  getExtensionPaths(resourceName: string, extensionName: string) {
    return this.resolveProvider(resourceName).getExtensionPaths(
      resourceName,
      extensionName,
    )
  }

  getAllExtensionsPaths(resourceName: string) {
    return this.resolveProvider(resourceName).getAllExtensionsPaths(
      resourceName,
    )
  }

  /**
   * Return an attribute.
   * This function supports dotted notation
   * shift.position.region.id
   * @param resourceName
   * @param attributeName
   */
  getAttribute(
    resourceName: string,
    attributeName: string,
  ): Attribute | undefined {
    return this.resolveProvider(resourceName).getAttribute(
      resourceName,
      attributeName,
    )
  }

  /**
   * Return the list of potential measures
   * @param resourceName
   * @param maxDepth
   */
  getResourceMeasures(resourceName: string, maxDepth = 3): Measure[] {
    return this.resolveProvider(resourceName).getResourceMeasures(
      resourceName,
      maxDepth,
    )
  }

  /**
   * Return the list of potential measures
   * @param resourceName
   * @param maxDepth
   */
  getResourceDimensions(resourceName: string, maxDepth = 3): Dimension[] {
    return this.resolveProvider(resourceName).getResourceDimensions(
      resourceName,
      maxDepth,
    )
  }

  /**
   * Return the schema for a form
   * @param resourceName
   * @param schemaName
   */
  getFormSchema(resourceName: string, schemaName: string) {
    return this.resolveProvider(resourceName).getFormSchema(
      resourceName,
      schemaName,
    )
  }

  getPatchAttributeFormSchema(resourceName: string, attributeName: string) {
    return this.resolveProvider(resourceName).getPatchAttributeFormSchema(
      resourceName,
      attributeName,
    )
  }

  getSchemaAttributes(
    resourceName: string,
    schemaName: string,
  ): string[] | undefined {
    return this.resolveProvider(resourceName).getSchemaAttributes(
      resourceName,
      schemaName,
    )
  }

  getAttributeLabelKey(
    resource: string,
    attribute: string,
    type: FormLabelTypes,
    enumValue?: string,
  ) {
    return this.resolveProvider(resource).getAttributeLabelKey(
      resource,
      attribute,
      type,
      enumValue,
    )
  }

  getAttributePathLabelKeys(resource: string, attribute: string) {
    return this.resolveProvider(resource).getAttributePathLabelKeys(
      resource,
      attribute,
    )
  }

  public getCustomFilters(resource: string) {
    return this.resolveProvider(resource).getCustomFilters(resource)
  }

  public getCustomFilter(resource: string, customFilterName: string) {
    return this.resolveProvider(resource).getCustomFilter(
      resource,
      customFilterName,
    )
  }

  getProviders(): ResourceMetaProviderInterface[] {
    return this.providers
  }

  /**
   * Loop through the providers and find one that listen to a specific resource name
   * @param resourceName
   */
  private resolveProvider(resourceName: string) {
    const provider = this.providers.find(
      (provider) => !!provider.getResource(resourceName),
    )
    if (!provider) {
      throw new Error(
        `No resource meta provider for resource ${resourceName}. Did you register it?`,
      )
    }
    return provider
  }
}
