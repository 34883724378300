export enum ClientActions {
  AssignVendors = 'assign-vendors',
  ImportExceptionDates = 'import-exception-dates',
  PrioritizeVendors = 'prioritize-vendors',
  SetInvoiceSplitSettings = 'set-invoice-split-settings',
}

export type ClientSplitInvoiceSetting = {
  destinationAccount: number
  splitPercentage: number
}
