<template>
  <div>
    <v-select
      v-model="model"
      dense
      outlined
      :label="label"
      :placeholder="placeholder"
      clearable
      :items="colors"
      :error-messages="errorMessages"
      v-on="$listeners"
    >
      <template #selection="data">
        <div
          :style="{
            backgroundColor: data.item.value,
            width: '100%',
            color: '#ffffffdd',
            marginTop: '7px',
            marginBottom: '3px',
            borderRadius: '7px',
            padding: '3px',
            paddingLeft: '10px',
          }"
        ></div>
      </template>
      <template #item="data">
        <div
          :style="{
            backgroundColor: data.item.value,
            width: '100%',
            color: '#fff',
            padding: '10px',
          }"
        ></div>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { uniqueId } from 'lodash'
import colors from 'vuetify/lib/util/colors'
import { Colors } from 'vuetify/es5/util/colors'

interface ColorListItem {
  text: string
  value: string
}

export default Vue.extend({
  name: 'ColorSelector',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      materialColors: { ...colors },
      uniqueId: '',
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(newValue: string): void {
        this.$emit('input', newValue || '')
      },
    },
    colors(): ColorListItem[] {
      const colorSet: Colors = { ...colors }
      // Ensure custom defined colors are supported
      if (this.model && this.model !== '') {
        colorSet['Custom'] = {
          base: this.model,
        }
      }
      return Object.keys(colorSet).map((item) => {
        return { value: colorSet[item].base, text: item }
      })
    },
  },
  created() {
    this.uniqueId = uniqueId('Color Picker')
  },
})
</script>
