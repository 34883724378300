import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'
import Vue from 'vue'
import UserSectionComponent from './components/UserSectionComponent.vue'

export const USER_SECTION_EXTENSION = 'app.tracktik.extensions.user-section'

Vue.component('UserSectionComponent', UserSectionComponent)

/**
 * Define the user extension
 */
export default {
  name: USER_SECTION_EXTENSION,
  component: {
    is: 'UserSectionComponent',
  },
  items: [],
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  enabled: true,
} as ExtensionInterface
