<template>
  <div v-if="value && value.uid" :key="`${value.uid}-${value.is}`">
    <div class="pa-4">
      <json-form
        v-if="jsonSchema"
        ref="widgetForm"
        :form-options="formOptions"
        :name="widgetFormName"
        :schema="jsonSchema"
        :show-state="true"
        :value="value"
        @input="$emit('input', $event)"
        @errors="errors = $event"
        @valid="errors = null"
        @debouncing="setDebouncing"
      />
    </div>
    <v-divider />
    <v-toolbar dense flat data-cy="widget-valid-label">
      <json-valid
        :valid="!errors"
        :errors="errors"
        :valid-text="$t('common.form.valid')"
        :invalid-text="$t('common.form.invalid')"
        :loading="debouncing"
      />
      <v-spacer />
      <template>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-btn icon small @click="viewCode">
                <v-icon>mdi-code-braces</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-text="$t('components.widget.code-preview.btn')" />
        </v-tooltip>
      </template>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { cloneDeep } from 'lodash'

import editorFormOptions from '@/tt-widget-components/lib/editor-form-options'
import {
  DefinitionOption,
  DefinitionOptionMap,
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import { widgetFormTranslateFunction } from '../helpers'
import { PageInterface, ActiveWindowProvider } from '@/tt-app-layout'
import { WidgetReference } from '@/tt-widget-factory/types'

export default (Vue as VueConstructor<Vue & ActiveWindowProvider>).extend({
  name: 'WidgetForm',
  inject: ['activeWindow'],
  props: {
    jsonSchema: {
      type: Object as PropType<JSONSchema7>,
      default: null,
    },
    value: {
      type: Object as PropType<WidgetReference>,
      default: null,
    },
  },
  data() {
    return {
      errors: null,
      debouncing: false,
    }
  },
  computed: {
    formOptions(): FormOptions {
      const baseOptions = cloneDeep(editorFormOptions) as FormOptions
      const definitions = this.formOptionDefinitions(baseOptions.definitions)

      return {
        ...baseOptions,
        definitions,
        emptyValues: EmptyValueRule.APPLY_DEFAULTS,
        locale: this.$i18n.locale,
        translateFunction: widgetFormTranslateFunction,
        removeAdditional: true,
      }
    },
    widgetFormName(): string {
      return `${this.value.is}Model`
    },
  },
  methods: {
    setDebouncing(event) {
      this.$emit('debouncing', event)
      this.debouncing = event
    },
    formOptionDefinitions(
      baseDefinitions: DefinitionOptionMap,
    ): DefinitionOptionMap {
      const widgetName = this.value.is

      // Add the form options definitions from the widget definition
      const widgetDefinition =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          widgetName,
        )
      const definitions: DefinitionOptionMap = {
        ...baseDefinitions,
        ...(widgetDefinition.editorDefinitions ?? {}),
      }

      const baseWidgetFormOptions: DefinitionOption =
        definitions[widgetName] ?? {}

      const widgetFormOptions = this.widgetFormOptions(baseWidgetFormOptions)

      return {
        ...definitions,
        [this.widgetFormName]: widgetFormOptions,
      }
    },
    viewCode(): void {
      this.activeWindow.next({
        title: 'components.widget.code-preview.label',
        is: 'WidgetJsonViewer',
        props: {
          json: this.value,
        },
      } as PageInterface)
    },
    widgetFormOptions({
      skip: baseSkip = [],
      ...baseOptions
    }: DefinitionOption): DefinitionOption {
      return {
        ...baseOptions,
        skip: [...baseSkip, 'uid', 'description'],
        properties: {
          title: { is: 'WidgetEditTitle' },
        },
      }
    },
  },
})
</script>
