<template>
  <tt-attr :name="name" v-bind="$attrs">
    <TChipLabel v-bind="{ ...effectiveChipProps }" />
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { TChipLabelType } from '@/tt-ui/types'

import { ChipLabelEnumColorMap, ChipLabelEnumColorMapItem } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ChipLabelEnum',
  inject: ['getItemHook'],
  props: {
    name: { type: String, required: true },
    chipProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    colorsMap: {
      type: Object as PropType<ChipLabelEnumColorMap>,
      default: () => ({}),
    },
  },
  computed: {
    attrRawValue(): string {
      return this.getItemHook().getRawValue(this.name)
    },
    attrValue(): string {
      return this.getItemHook().get(this.name)
    },
    colors(): ChipLabelEnumColorMapItem | null {
      return this.colorsMap[this.attrRawValue] ?? null
    },
    effectiveChipProps(): Record<string, unknown> {
      return {
        backgroundColor: this.colors?.background,
        textColor: this.colors?.text,
        textKey: this.attrValue,
        type: TChipLabelType.Pill,
        ...this.chipProps,
      }
    },
  },
})
</script>
