import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import TimeField from '@/tt-entity-forms/components/TimeField.vue'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import { TIME, TimeFieldType } from './types'
import schema from './schema.json'

const ui: FieldUI = {
  name: i18nFieldKey(TIME),
  color: 'yellow darken-2',
  icon: 'mdi-clock-outline',
}

// @ts-ignore
const converter: FieldConverter = (field: TimeFieldType) => ({
  getJsonSchema: () => {
    return {
      ...getBaseFieldJsonSchema(field),
      type: 'string',
      // @TODO: properly format the time on the TimeField component
      // format: 'time'
    }
  },
  getViewOption: () => {
    return {
      is: TimeField,
    }
  },
})

export const TimeMeta: FieldMeta = {
  field: TIME,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
