export const attributeTypeIconMap: { [k: string]: string } = {
  default: 'mdi-tag-outline',
  TimeStampNumber: 'mdi-calendar-clock',
  Image: 'mdi-file-image',
  TimeZone: 'mdi-tag-outline',
  Boolean: 'mdi-checkbox',
  PhoneNumber: 'mdi-phone',
  Enum: 'mdi-format-list-checks',
  TimeStampDate: 'mdi-calendar-clock',
  Date: 'mdi-calendar',
  DateTime: 'mdi-calendar-clock',
  Email: 'mdi-email',
  Uri: 'mdi-tag-outline',
  Label: 'mdi-format-text',
  TextArea: 'mdi-comment-text',
  Relation: 'mdi-link-plus',
  RelationList: 'mdi-link-plus',
  Time: 'mdi-clock-outline',
  Currency: 'mdi-coin-outline',
  CurrencyRate: 'mdi-coin-outline',
  Number: 'mdi-numeric-1-box-multiple-outline',
  Integer: 'mdi-numeric-1-box-multiple-outline',
  Decimal: 'mdi-decimal',
  Password: 'mdi-password',
  GeoRegion: 'mdi-tag-outline',
  GeoCountry: 'mdi-tag-outline',
  GeoPostalCode: 'mdi-tag-outline',
  GeoPosition: 'mdi-map-marker-outline',
}
