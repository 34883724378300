// Enter your custom installation here

import {
  addPreviewTab,
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import AssetCategoriesIcon from '@/tt-entity-design/src/components/asset-categories/AssetCategoriesIcon.vue'
/**
 * TODO: Move Asset Tracking's business logics to the application folder: SDAM-957
 */
import { AssetsTranslationsActions } from '@/apps/app.tracktik.assets/utils/constants'
import AssetCategoriesAddTranslationForm from './AssetCategoriesAddTranslationForm.vue'
import AssetCategoriesForm from './AssetCategoriesForm.vue'

export default {
  install(Vue) {
    Vue.component('AssetCategoriesIcon', AssetCategoriesIcon)
    Vue.component('AssetCategoriesForm', AssetCategoriesForm)
    Vue.component(
      'AssetCategoriesAddTranslationForm',
      AssetCategoriesAddTranslationForm,
    )

    registerResourceForm(Resources.ASSET_CATEGORIES, 'AssetCategoriesForm')

    registerResourceForm(
      Resources.ASSET_CATEGORIES,
      'AssetCategoriesAddTranslationForm',
      'add-translation',
    )

    setIconAndColorResourcePreset(
      Resources.ASSET_CATEGORIES,
      'mdi-folder',
      'green',
    )

    // Add transactions
    addPreviewTab(Resources.ASSET_CATEGORIES, {
      title: 'Types',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'category.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSET_TYPES,
          },
        },
      },
    })

    // Add transactions
    addPreviewTab(Resources.ASSET_CATEGORIES, {
      title: 'Assets',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'type.category.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSETS,
          },
        },
      },
    })

    // Add translations
    addPreviewTab(Resources.ASSET_CATEGORIES, {
      title: 'Translations',
      is: 'AssetsTranslationsTile',
      props: {
        resource: Resources.ASSET_CATEGORY_TRANSLATIONS,
        parentResource: Resources.ASSET_CATEGORIES,
        deleteAction:
          AssetsTranslationsActions.DELETE_ASSET_CATEGORY_TRANSLATION,
      },
    })
  },
}
