// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CreditMemosTile from './CreditMemosTile.vue'
import CreditMemosColumns from './CreditMemosColumns'
import CreditMemosDetail from './CreditMemosDetail.vue'
import CreditMemosMetaCard from './CreditMemosMetaCard.vue'
import CreditMemosReference from './CreditMemosReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CreditMemosTile', CreditMemosTile)
    Vue.component('CreditMemosDetail', CreditMemosDetail)
    Vue.component('CreditMemosMetaCard', CreditMemosMetaCard)
    Vue.component('CreditMemosReference', CreditMemosReference)

    modularManager.addResourcePreset(
      Resources.CREDIT_MEMOS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CreditMemosTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CREDIT_MEMOS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CreditMemosColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CREDIT_MEMOS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CreditMemosDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CREDIT_MEMOS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'CreditMemosMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CREDIT_MEMOS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CreditMemosReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
