import { ActionFormTypes, modularManager } from '@/tt-app-modular'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import CheckBooleanColumn from '../CheckBooleanColumn.vue'
import CalendarExceptionsTypeColumn from './CalendarExceptionsTypeColumn.vue'
import CreateCalendarException from './CreateCalendarException.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('CheckBooleanColumn', CheckBooleanColumn)
    Vue.component('CalendarExceptionsTypeColumn', CalendarExceptionsTypeColumn)

    Vue.component('CreateCalendarException', CreateCalendarException)
    registerResourceForm(
      Resources.CALENDAR_EXCEPTIONS,
      'CreateCalendarException',
    )
    modularManager.registerDynamicResourceJsonSchemaActions(
      Resources.CALENDAR_EXCEPTIONS,
      [ActionFormTypes.CREATE, ActionFormTypes.EDIT],
    )
  },
}
