<template>
  <div class="d-flex flex-column align-strech pb-2">
    <v-divider v-if="divider" class="mb-2"></v-divider>
    <div class="pl-2 d-flex flex-row align-center justify-space-between mt-2">
      <div class="d-flex flex-grow-1 flex-column align-start">
        <div class="d-flex align-self-stretch justify-space-between mb-4">
          <p class="d-flex ma-0 pa-0" v-text="$t(title)" />
          <slot></slot>
        </div>
        <slot name="value">
          <strong class="">{{ value }}</strong>
        </slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'EmployeeProfileInfoRow',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
