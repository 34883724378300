import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'code',
  'label',
  'status',
  {
    attributeName: 'availabilities.code',
    component: {
      is: 'EntityLabelsAvailabilitiesColumn',
    },
    allowSorting: false,
  },
] as ColumnInputDefinition[]
