<template>
  <AssetTrackingSettingsProvider
    v-slot="{ canList }"
    :resource-name="resourceName"
  >
    <tt-attr
      name="category"
      :prevent-relation-preview="isImported || !canList"
    />
  </AssetTrackingSettingsProvider>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetTypesCategoryAttr',
  inject: ['getItemHook'],
  computed: {
    isImported(): string {
      return this.getItemHook().getRawValue('isImported')
    },
    resourceName(): string {
      /**
       * TODO: Register this component in the Asset Tracking app directory
       * setting the `level` as "APPLICATION", so we don't mix entity-design items with
       * Asset Tracking business logic.
       *
       * Ticket: https://tracktik.atlassian.net/browse/SDAM-957
       */
      return Resources.ASSET_CATEGORIES
    },
  },
  created() {
    this.getItemHook().addAttribute('isImported')
  },
})
</script>
