import { ValueOf } from '@/helpers/types/ValueOf'

export const siteTaskOccurrenceSessionLogType = {
  ACCEPTED: 'ACCEPTED',
  ASSIGNED: 'ASSIGNED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
  OFFERED: 'OFFERED',
  REOPENED: 'RE-OPENED',
  SCHEDULED: 'SCHEDULED',
  STARTED: 'STARTED',
  STOPPED: 'STOPPED',
  TAKEN: 'TAKEN',
  UNASSIGNED: 'UNASSIGNED',
} as const

export type SiteTaskOccurrenceSessionLogType = ValueOf<
  typeof siteTaskOccurrenceSessionLogType
>

export const siteTaskOccurrenceSessionLogTypeIcon: Partial<
  Record<SiteTaskOccurrenceSessionLogType, string>
> = {
  [siteTaskOccurrenceSessionLogType.ACCEPTED]: 'mdi-account-check',
  [siteTaskOccurrenceSessionLogType.ASSIGNED]: 'mdi-account-plus',
  [siteTaskOccurrenceSessionLogType.CANCELLED]: 'mdi-close',
  [siteTaskOccurrenceSessionLogType.COMPLETED]: 'mdi-check',
  [siteTaskOccurrenceSessionLogType.DECLINED]: 'mdi-account-cancel',
  [siteTaskOccurrenceSessionLogType.OFFERED]: 'mdi-account-question',
  [siteTaskOccurrenceSessionLogType.REOPENED]: 'mdi-repeat',
  [siteTaskOccurrenceSessionLogType.SCHEDULED]: 'mdi-clock-outline',
  [siteTaskOccurrenceSessionLogType.STARTED]: 'mdi-play',
  [siteTaskOccurrenceSessionLogType.STOPPED]: 'mdi-pause',
  [siteTaskOccurrenceSessionLogType.TAKEN]: 'mdi-account-plus',
  [siteTaskOccurrenceSessionLogType.UNASSIGNED]: 'mdi-account-off',
}

export const getSiteTaskOccurrenceSessionLogTypeIcon = (
  type: SiteTaskOccurrenceSessionLogType,
) => {
  return siteTaskOccurrenceSessionLogTypeIcon[type]
}
