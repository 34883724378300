<template>
  <span class="text-clickable" v-text="availabilitiesText" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { getAvailabilitiesText } from './utils'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityLabelsAvailabilitiesColumn',
  inject: ['getItemHook'],
  computed: {
    availabilitiesText(): string {
      const availabilities = this.getItemHook().get('availabilities')

      return getAvailabilitiesText(availabilities)
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  created() {
    this.getItemHook().addAttribute('availabilities.code')
  },
})
</script>
