<template>
  <div style="display: contents">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import i18n from '@/plugins/i18n'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { DocumentPresetInterface } from '@/tt-app-modular'
import { ExternalUrlProviderDocumentInput } from '@/tt-entity-documents/providers/ExternalURLDocumentProvider'
import { convertFileToDocumentPreset } from '../FileHandler'
import { ApiFile } from '@/tt-api-files/types'
import { AllowedFileActions } from './types'

export type ActionTriggerProvider = { actionTrigger: (a: string) => void }
type DocumentPreset = DocumentPresetInterface<ExternalUrlProviderDocumentInput>

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActionTriggerProvider>
).extend({
  name: 'ActionTriggerProvider',
  inject: ['getItemHook'],
  provide() {
    return {
      actionTrigger: this.actionTrigger,
    }
  },
  data: () => ({
    urlsToRevoke: [] as string[],
  }),
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    actionDictionary(): Record<string, () => void> {
      return {
        archive: () => this.confirmFileArchive(),
        view: () => this.openViewer(),
        download: () => this.downLoadFile(),
      }
    },
    resourceName(): string {
      return this.getItemHook().getResourceName()
    },
    entityId(): number {
      return this.getItemHook().get('id')
    },
  },
  beforeDestroy() {
    this.urlsToRevoke.forEach(URL.revokeObjectURL)
  },
  methods: {
    async openViewer(): Promise<void> {
      const document: DocumentPreset = convertFileToDocumentPreset(
        this.getItemHook().data as ApiFile,
      )
      const documentInput = await document.toDocument(
        this.$appContext,
        this.getItemHook().entityReference,
        i18n.locale,
      )
      this.urlsToRevoke.push(documentInput.data.url)
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        documentInput,
      )
    },
    downLoadFile(): void {
      const fileURL = this.getItemHook().entityReference.entity.url
      const mediaToken =
        this.$appContext.authModule.getSession().profile.auth.mediaToken
      window.open(`${fileURL}?mediaToken=${mediaToken}`)
    },
    confirmFileArchive(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: this.$t('tt-entity-design.asset-hub.files.confirm-delete'),
        accept: () => this.archiveFile(),
      })
    },
    archiveFile(): void {
      this.$appContext.entityServices.persister.executeEntityAction(
        this.resourceName,
        AllowedFileActions.ARCHIVE,
        this.entityId,
      )
    },
    actionTrigger(action: AllowedFileActions) {
      this.actionDictionary[action]()
      const { eventManager } = this.getItemHook().appContext
      if (!Object.values(AllowedFileActions).includes(action)) {
        eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
          actionName: action,
          ...this.getItemHook().entityReference,
        })
      }
    },
  },
})
</script>
