<template>
  <v-dialog v-model="showDialog" :attach="attach" width="320" persistent>
    <template #activator="{ on }">
      <v-text-field
        :value="formattedTime"
        prepend-inner-icon="mdi-clock-time-four-outline"
        readonly
        clearable
        :disabled="isReadOnly"
        v-bind="{ ...vuetifyDefaults, label, placeholder, errorMessages }"
        v-on="on"
        @click:clear="clear"
      />
    </template>

    <v-card v-if="showDialog">
      <v-time-picker
        v-if="showDialog"
        v-model="state"
        class="d-flex"
        :format="format"
        ampm-in-title
      />
      <v-card-actions>
        <v-btn text color="primary" @click="close">
          {{ $t('common.cancel.btn') }}
        </v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="!state" @click="save">
          {{ $t('common.ok.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { PropType, VueConstructor } from 'vue'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import moment from 'moment-timezone'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'
import { getTimeFormat } from './utils'
import { FormHookProvider } from '@/tt-widget-components'

const VUETIFY_TIME_FORMAT = 'HH:mm'
const API_TIME_FORMAT = 'HH:mm:ss'

const formatTime = (time, userTimeFormat) =>
  moment(time, API_TIME_FORMAT).format(userTimeFormat)

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider
>

export default (BaseInput as VueWithInjections).extend({
  name: 'TimeField',
  inject: {
    formHook: { default: undefined },
  },
  props: {
    attach: {
      type: [Boolean, String, Object] as PropType<boolean | string | Node>,
      default: false,
    },
    userTimeFormat: {
      type: String,
      default: VUETIFY_TIME_FORMAT,
    },
  },
  data: () => ({
    state: null,
    showDialog: false,
    vuetifyDefaults,
  }),
  computed: {
    formattedTime(): string {
      const { model, userTimeFormat } = this

      return model ? formatTime(model, userTimeFormat) : ''
    },
    format(): 'ampm' | '24hr' {
      return getTimeFormat(this.userTimeFormat)
    },
    isReadOnly(): boolean {
      return this.formHook?.().isReadOnly() || false
    },
  },
  methods: {
    save() {
      this.$emit('input', this.state)
      this.close()
    },
    close() {
      this.showDialog = false
      this.state = null
      this.$emit('blur')
    },
    clear() {
      this.$emit('input', null)
      this.$emit('blur')
    },
  },
})
</script>
