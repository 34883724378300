import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'flagForReview',
  {
    attributeName: 'client.name',
    headerText: 'tt-entity-design.client-billing-settings.attrs.client.name',
  },
  {
    attributeName: 'client.region',
    headerText: 'tt-entity-design.client-billing-settings.attrs.client.region',
  },
  {
    attributeName: 'billingAddress',
    headerText: 'tt-entity-design.client-billing-settings.attrs.billingAddress',
  },
  'distributeByMail',
  'distributeByEmail',
] as ColumnInputDefinition[]
