import TracktikApiError from 'tracktik-sdk/lib/errors'
import { VuetifyIcon } from 'vuetify/types/services/icons'

export interface APIErrorViewItem {
  text: string
  icon: string
  color: string
}

export const ApiErrorCodes = {
  REQUIRED: 'rest.api.error.required.filter.group',
} as const

export interface APIErrorRequiredFilters {
  code: typeof ApiErrorCodes.REQUIRED
  type: string
  message: string
  documentationLink: string
  extraInfo: {
    requiredFiltersGroup: {
      resource: string
      scopes: string[]
      fields: string[]
    }
  }
}

export class APIErrorAdapter {
  public getError(e: TracktikApiError): APIErrorViewItem {
    // Network errors from axios
    if (e.response?.message === 'Network Error') {
      return {
        icon: 'mdi-lan',
        text: 'error.network.offline',
        color: 'red',
      }
    }

    // Forbidden
    if ([401, 403].includes(e.code)) {
      return {
        icon: 'mdi-lock',
        text: 'error.unauthorized',
        color: 'orange',
      }
    }

    if (e.response?.data?.code === ApiErrorCodes.REQUIRED) {
      return {
        icon: 'error',
        text: ApiErrorCodes.REQUIRED,
        color: 'orange',
      }
    }

    if (e.response?.data?.message) {
      return {
        icon: 'error',
        text: e.response?.data?.message,
        color: 'orange',
      }
    }

    return {
      icon: 'error',
      text: 'error.generic',
      color: 'orange',
    }
  }
}
