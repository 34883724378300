// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaxLocationOverrideMappingsTile from './TaxLocationOverrideMappingsTile.vue'
import TaxLocationOverrideMappingsColumns from './TaxLocationOverrideMappingsColumns'
import TaxLocationOverrideMappingsDetail from './TaxLocationOverrideMappingsDetail.vue'
import TaxLocationOverrideMappingsMetaCard from './TaxLocationOverrideMappingsMetaCard.vue'
import TaxLocationOverrideMappingsReference from './TaxLocationOverrideMappingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'TaxLocationOverrideMappingsTile',
      TaxLocationOverrideMappingsTile,
    )
    Vue.component(
      'TaxLocationOverrideMappingsDetail',
      TaxLocationOverrideMappingsDetail,
    )
    Vue.component(
      'TaxLocationOverrideMappingsMetaCard',
      TaxLocationOverrideMappingsMetaCard,
    )
    Vue.component(
      'TaxLocationOverrideMappingsReference',
      TaxLocationOverrideMappingsReference,
    )

    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_MAPPINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideMappingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_MAPPINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaxLocationOverrideMappingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_MAPPINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideMappingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_MAPPINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideMappingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TAX_LOCATION_OVERRIDE_MAPPINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaxLocationOverrideMappingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
