import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'person',
  'checkoutByDifferentPerson',
  'asset',
  {
    attributeName: 'parent.asset',
    headerText:
      'tt-entity-design.asset-hub.asset-reservations.reserved-as-part-of',
  },
  'startDateTime',
  'endDateTime',
  'status',
] as ColumnInputDefinition[]
