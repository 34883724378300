import { Coordinate } from '@/tt-widget-components/components/map/type'

/**
 * Tooltips
 *
 * Generic configurations for tooltips (in ms)
 */
export const TOOLTIP_OPEN_DELAY = 400
export const TOOLTIP_NUDGE = 8

/**
 * Inputs
 *
 * Generic configuration for debounce time in form inputs (in ms)
 */
export const INPUT_DEBOUNCE_TIME = 750

/**
 * Dialogs
 *
 * Generic configurations for dialogs (in px)
 */
export const DIALOG_WIDTH_MEDIUM = 600
export const DIALOG_WIDTH_MEDIUM_LARGE = 800
export const DIALOG_WIDTH_LARGE = 1200

/**
 * App toolbar
 *
 * Generic configurations for the app toolbar elements (tooltips, dropdowns, ...)
 * The z-index set to 10 prevent the tooltip to render behind the app bar extensions,
 * like Workspaces or the Profile Dropdown.
 */
export const DROPDOWN_APP_BAR_NUDGE = 45
export const TOOLTIP_APP_BAR_NUDGE = 47
export const TOOLTIP_APP_BAR_ZINDEX = 10

/**
 * Dashboard Tabs
 *
 * Generic configurations for the minimum size of a dashboard tab
 * Generic configurations for drag over
 */
export const MIN_TAB_WIDTH = 60
/**
 * Dashboard Tabs
 * Generic configurations for drag over
 */
export const DRAG_OVER_DELAY = 3000

/**
 * File Upload
 *
 * Generic configurations for our file upload component
 */
export const ACCEPTED_FORMATS = [
  'jpg',
  'jpeg',
  'jfif',
  'png',
  'webp',
  'gif',
  'bmp',
  'tif',
  'tiff',
  'mov',
  'mp4',
  'avi',
  'ogg',
  'webm',
  'wmv',
  'mkv',
  'pdf',
  'doc',
  'docx',
  'odf',
  'otd',
  'rtf',
  'txt',
  'ppt',
  'pptx',
  'odp',
  'msg',
]

/**
 * Query Manager
 *
 * Default query limit.
 */

export const DEFAULT_QUERY_LIMIT = 20

/**
 * Alarms Form
 *
 * Default coordinates object that point to the center of the globe.
 */

export const DEFAULT_COORDINATE: Coordinate = { lat: 30.0, lng: 31.0 }

/**
 * Injected views
 */

export const DEFAULT_INJECTION_HEIGHT = 800
