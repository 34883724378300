<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { createFileHandler } from './utils/file-handler'
import { FileHandlerProvider } from './utils/types'

export default Vue.extend({
  name: 'FileHandlerProvider',
  provide(): FileHandlerProvider {
    return {
      fileHandler: () => this.fileHandler,
    }
  },
  data() {
    return {
      fileHandler: createFileHandler(),
    }
  },
})
</script>
