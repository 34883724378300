import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { EmployeeSkillsStatusColors } from '@/tt-widget-views/leave-management/human-capital-management/types'
import { Resources } from '@/tt-entity-design/src/types'

export const HumanCapitalManagementCertificationsColumns: ColumnInputDefinition[] =
  [
    { attributeName: 'employee' },
    { attributeName: 'skill' },
    {
      headerText: 'tt-entity-design.human-capital-management.certification-id',
      attributeName: 'skill.externalIdObject.paycorCertificationId',
    },
    {
      headerText: 'tt-entity-design.human-capital-management.expiration-date',
      attributeName: 'skillDateLabelValue',
      format: 'asDate',
    },
    {
      headerText: 'res.leave-requests.attr.status.label',
      attributeName: 'status',
      component: {
        is: 'StatusChip',
        props: {
          statusColorOptions: EmployeeSkillsStatusColors,
          resource: Resources.EMPLOYEE_SKILLS,
        },
      },
    },
  ]

export const HumanCapitalManagementCertificationsDownloadableColumns: ColumnInputDefinition[] =
  [
    { attributeName: 'employee.name' },
    { attributeName: 'skill.name' },
    {
      headerText: 'tt-entity-design.human-capital-management.certification-id',
      attributeName: 'skill.externalIdObject.paycorCertificationId',
    },
    {
      headerText: 'tt-entity-design.human-capital-management.expiration-date',
      attributeName: 'skillDateLabelValue',
      format: 'asDate',
    },
    {
      headerText: 'res.leave-requests.attr.status.label',
      attributeName: 'status',
    },
  ]
