<template>
  <div :class="{ 'take-action-form--wrapper': showAlertBar }">
    <v-alert
      v-if="showAlertBar"
      type="error"
      color="warning"
      icon="mdi-alert"
      class="mb-0"
      text
    >
      <p
        class="my-0 font-weight-medium warning--text text--darken-3"
        v-text="alertLabel"
      />
    </v-alert>
    <GenericFormPage
      :payload="updatedPayload"
      @back="$emit('back')"
      @close="$emit('close')"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { DialogFormInterface } from '@/tt-app-layout'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { VueWithInjections } from '../conflicts/types'

export default (Vue as VueWithInjections).extend({
  name: 'SystemExceptionTicketsTakeActions',
  inject: {
    activeWindow: { default: undefined },
  },
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.payload.userContext.itemHook
    },
    assignedOperatorName(): string {
      return this.itemHook.getRawValue('assignedOperator.name')
    },
    userId(): number {
      return this.$appContext.authModule.getUserId()
    },
    alertLabel(): string {
      return `${this.$t(
        'tt-entity-design.system-exception-tickets.actions.take-warning-message',
        { name: this.assignedOperatorName },
      )}`
    },
    showAlertBar(): boolean {
      return (
        this.itemHook.getRawValue('assignedOperator.id') &&
        this.userId !== this.itemHook.getRawValue('assignedOperator.id')
      )
    },
    updatedPayload(): Record<string, any> {
      return {
        ...this.payload,
        hideTitleDivider: this.showAlertBar,
      }
    },
  },
  created() {
    this.itemHook.addAttribute('assignedOperator.id')
    this.itemHook.addAttribute('assignedOperator.name')
  },
  methods: {},
})
</script>

<style scoped>
.take-action-form--wrapper >>> .container {
  padding: 0px !important;
}

.take-action-form--wrapper >>> .v-alert__icon {
  align-self: center !important;
}
</style>
