// Enter your custom installation here
import DispatachCustomLocationsGoogleMapLink from './DispatachCustomLocationsGoogleMapLink.vue'

export default {
  install(Vue) {
    Vue.component(
      'DispatachCustomLocationsGoogleMapLink',
      DispatachCustomLocationsGoogleMapLink,
    )
  },
}
