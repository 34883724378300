// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchSlaTimerSettingsTile from './DispatchSlaTimerSettingsTile.vue'
import DispatchSlaTimerSettingsColumns from './DispatchSlaTimerSettingsColumns'
import DispatchSlaTimerSettingsDetail from './DispatchSlaTimerSettingsDetail.vue'
import DispatchSlaTimerSettingsMetaCard from './DispatchSlaTimerSettingsMetaCard.vue'
import DispatchSlaTimerSettingsReference from './DispatchSlaTimerSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchSlaTimerSettingsTile', DispatchSlaTimerSettingsTile)
    Vue.component(
      'DispatchSlaTimerSettingsDetail',
      DispatchSlaTimerSettingsDetail,
    )
    Vue.component(
      'DispatchSlaTimerSettingsMetaCard',
      DispatchSlaTimerSettingsMetaCard,
    )
    Vue.component(
      'DispatchSlaTimerSettingsReference',
      DispatchSlaTimerSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.DISPATCH_SLA_TIMER_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchSlaTimerSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLA_TIMER_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchSlaTimerSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLA_TIMER_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchSlaTimerSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLA_TIMER_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchSlaTimerSettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLA_TIMER_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchSlaTimerSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
