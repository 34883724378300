// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OvertimeRuleItemsTile from './OvertimeRuleItemsTile.vue'
import OvertimeRuleItemsColumns from './OvertimeRuleItemsColumns'
import OvertimeRuleItemsDetail from './OvertimeRuleItemsDetail.vue'
import OvertimeRuleItemsReference from './OvertimeRuleItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OvertimeRuleItemsTile', OvertimeRuleItemsTile)
    Vue.component('OvertimeRuleItemsDetail', OvertimeRuleItemsDetail)
    Vue.component('OvertimeRuleItemsReference', OvertimeRuleItemsReference)

    modularManager.addResourcePreset(
      Resources.OVERTIME_RULE_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OvertimeRuleItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULE_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OvertimeRuleItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULE_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OvertimeRuleItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULE_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OvertimeRuleItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
