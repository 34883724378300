// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplateFieldGroupsTile from './ReportTemplateFieldGroupsTile.vue'
import ReportTemplateFieldGroupsColumns from './ReportTemplateFieldGroupsColumns'
import ReportTemplateFieldGroupsDetail from './ReportTemplateFieldGroupsDetail.vue'
import ReportTemplateFieldGroupsReference from './ReportTemplateFieldGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportTemplateFieldGroupsTile',
      ReportTemplateFieldGroupsTile,
    )
    Vue.component(
      'ReportTemplateFieldGroupsDetail',
      ReportTemplateFieldGroupsDetail,
    )
    Vue.component(
      'ReportTemplateFieldGroupsReference',
      ReportTemplateFieldGroupsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplateFieldGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATE_FIELD_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplateFieldGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
