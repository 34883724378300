<template>
  <div>
    <v-menu :disabled="onHoverDisabled" open-on-hover bottom offset-y>
      <template #activator="{ on }">
        <div v-on="on">
          <tt-attr
            v-if="!isVendor"
            v-show="hasAssignedUser"
            name="assignedUser"
          />
          <div v-else v-show="hasAssignedUser" class="d-flex flex-column">
            <tt-attr
              class="vendors--attribute-link"
              :prepend-icon-color="vendorIcon.color"
              :prepend-icon="vendorIcon.icon"
              name="assignedUser.name"
              @click.native.stop="openVendorPreview"
            />
            <tt-attr
              v-if="showPhoneNumber"
              v-slot="{ value }"
              class="mt-2 font-weight-bold"
              name="assignedUser.primaryPhone"
            >
              <v-icon size="small" color="green" class="mr-1">
                mdi-phone
              </v-icon>
              <a :href="'tel:' + toPhoneNumber(value)">{{ value }}</a>
            </tt-attr>
          </div>
        </div>
      </template>
      <DispatchTasksUserCard :vendor-user="isVendor" :icon-class="vendorIcon" />
    </v-menu>

    <DispatchTasksAssignmentGroupResourceAttr
      v-show="!hasAssignedUser"
      :on-hover-disabled="onHoverDisabled"
      :class="{ 'd-flex': !hasAssignedUser }"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import DispatchTasksAssignmentGroupResourceAttr from './DispatchTasksAssignmentGroupResourceAttr.vue'
import DispatchTasksUserCard from './cards/DispatchTasksUserCard.vue'
import { USER_ATTRIBUTE_LIST } from './constants'
import { AssignedGroupResourceTypeEnums } from '@/tt-entity-design/src/components/dispatch-tasks/types'
import { iconDictionnary } from './constants'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import { IconType } from '@/tt-entity-design/src/components/dispatch-tasks/types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'DispatchTasksAssignmentAttr',
  components: {
    DispatchTasksAssignmentGroupResourceAttr,
    DispatchTasksUserCard,
  },
  inject: {
    activeWindow: { default: null },
    getItemHook: {},
  },
  props: {
    onHoverDisabled: {
      type: Boolean,
      default: false,
    },
    showPhoneNumber: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vendorIcon: {
        color: iconDictionnary[AssignedGroupResourceTypeEnums.VENDOR].color,
        icon: iconDictionnary[AssignedGroupResourceTypeEnums.VENDOR].icon,
      } as IconType,
    }
  },
  computed: {
    hasAssignedUser(): boolean {
      return !!this.getItemHook().getRawValue('assignedUser.id')
    },
    assignedUserId(): number {
      return this.getItemHook().getRawValue('assignedUser.id')
    },
    assignedGroupResourceTypeValue(): string {
      return this.getItemHook().getRawValue('assignedGroupResource.type')
    },
    isVendor(): boolean {
      return (
        this.assignedGroupResourceTypeValue ===
        AssignedGroupResourceTypeEnums.VENDOR
      )
    },
    isSideSheetChild(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
  },
  created() {
    this.getItemHook().addAttribute('assignedGroupResource')
    USER_ATTRIBUTE_LIST.forEach((attribute) => {
      this.getItemHook().addAttribute(attribute)
    })
  },
  methods: {
    openVendorPreview(): void {
      if (!this.assignedUserId) return

      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        activeWindow: this.activeWindow,
        resourceName: Resources.EMPLOYEES,
        entityId: this.assignedUserId,
        hasToAddPages: this.isSideSheetChild,
      })
    },
    toPhoneNumber(string: string): string {
      // keep only `+` and numbers
      return string.replace(/[^+0-9]/g, '')
    },
  },
})
</script>
<style scoped>
.vendors--attribute-link {
  cursor: pointer;
}
.vendors--attribute-link:hover {
  color: var(--v-ttPrimary-base);
}
</style>
