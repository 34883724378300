import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { ListWidgetModel, WidgetModels } from '@/tt-widget-components'

import MobileRunsheetsAddExceptionTypes from './MobileRunsheetsAddExceptionTypes.vue'
import MobileRunsheetsCreateBatchForm from './MobileRunsheetsCreateBatchForm.vue'
import MobileRunsheetsStatusAttr from './MobileRunsheetsStatusAttr.vue'
import MobileRunsheetsActionBar from './MobileRunsheetsActionBar.vue'
import { Resources } from '../../types'
import { ActionFormTypes, modularManager } from '@/tt-app-modular'
import { createResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import MobileRunsheetsSchedules from './MobileRunsheetsSchedules.vue'

export default {
  install(Vue) {
    Vue.component(
      'MobileRunsheetsAddExceptionTypes',
      MobileRunsheetsAddExceptionTypes,
    )
    Vue.component(
      'MobileRunsheetsCreateBatchForm',
      MobileRunsheetsCreateBatchForm,
    )
    Vue.component('MobileRunsheetsStatusAttr', MobileRunsheetsStatusAttr)

    registerResourceForm(
      Resources.MOBILE_RUNSHEETS,
      'MobileRunsheetsAddExceptionTypes',
      'add-exception-types',
    )

    modularManager.registerActionBar(Resources.MOBILE_RUNSHEETS, {
      is: MobileRunsheetsActionBar,
    })

    const occurrencesCalendarWidget: WidgetModels = {
      title: '',
      is: 'CalendarWidget',
      query: {
        resource: Resources.MOBILE_RUNSHEET_OCCURRENCES,
      },
      attributeMap: {
        startAttribute: 'rangeStartDateTime',
        endAttribute: 'rangeEndDateTime',
        titleAttribute: 'name',
      },
      toolbar: {
        show: false,
      },
    }

    const exceptionsListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
      },
    }

    const defaultDefinition = {
      skip: ['endDate', 'customId'],
    }

    registerResourceForm(
      Resources.MOBILE_RUNSHEETS,
      null,
      ActionFormTypes.FULL_ENTITY,
      { defaultDefinition },
    )

    addPreviewTab(Resources.MOBILE_RUNSHEETS, {
      title: 'tt-entity-design.mobile-runsheets.preview.tab-sessions',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'sessions',
      },
    })

    /**
     * Needs the API to add the relationList attribute
     */
    addPreviewTab(Resources.MOBILE_RUNSHEETS, {
      title: 'tt-entity-design.mobile-runsheets.preview.tab-occurrences',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'mobileRunsheet',
        widget: occurrencesCalendarWidget,
      },
    })

    /**
     * Needs the API to add the relationList attribute
     */
    addPreviewTab(Resources.MOBILE_RUNSHEETS, {
      title: createResourceKey('mobile-schedules'),
      is: MobileRunsheetsSchedules,
    })

    /**
     * Needs the API to add the relationList attribute
     */
    addPreviewTab(Resources.MOBILE_RUNSHEETS, {
      title: 'tt-entity-design.mobile-runsheets.preview.tab-exceptions',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'runsheet',
        widget: exceptionsListWidget,
        widgetProps: {
          onSelect: () => {}, // Do nothing
        },
      },

      /**
       * If the user can perform actions on exception types, the Details preset
       * includes the `exceptionTypes` field in the request.
       * If the field has value (empty or not), show tab. If it's nil, hide it.
       */
      condition: ({ itemHook }) => itemHook.get('exceptionTypes') != null,
    })
  },
}
