<template>
  <div class="tt-chips-list">
    <v-chip
      v-for="chip in chips"
      :key="chip.id"
      :close="hasCloseAction"
      :close-icon="getCloseIcon()"
      small
      @click:close="() => $emit('close', chip.id, chip.attributeName)"
    >
      <!-- chip icon -->
      <v-tooltip top left :open-delay="tooltipOpenDelay">
        <template #activator="{ on }">
          <v-icon
            v-if="chip.icon"
            color="white"
            class="mr-2 cursor-help"
            v-on="on"
          >
            {{ chip.icon }}
          </v-icon>
        </template>
        <span v-text="$t(chip.attributeLabel)" />
      </v-tooltip>
      <!-- / chip icon -->

      <!-- chip label -->
      <span class="tt-chips-list__text">
        <TTooltipOnOverflow
          :text="chip.label"
          :tooltip-text="chip.tooltipLabel"
        />
      </span>
      <!-- / chip label -->
    </v-chip>

    <!-- clear button -->
    <button
      v-if="hasClearAction"
      class="tt-chips-list__clear"
      @click="$emit('clear-all')"
    >
      <span v-text="$t('components.filters_chips.clear')" />
    </button>
    <!-- / clear button -->
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'
import { ContextFiltersChipsList } from '@/tt-widget-components/types'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'

export default Vue.extend({
  name: 'TChipsList',
  components: {
    TTooltipOnOverflow,
  },
  props: {
    chips: {
      type: Array as PropType<ContextFiltersChipsList>,
      default: null,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    hasCloseAction(): boolean {
      return !!this.$listeners['close']
    },
    hasClearAction(): boolean {
      return !!this.$listeners['clear-all']
    },
  },
  methods: {
    getCloseIcon(): string | null {
      return this.hasCloseAction ? 'mdi-close' : null
    },
  },
})
</script>

<style lang="scss" scoped>
.tt-chips-list {
  margin-left: -4px;
  margin-right: -4px;

  &__clear {
    border: none;
    background: none;
    color: var(--v-ttPrimary-base);
    margin-left: 8px;
    font-size: 11px;
    vertical-align: top;
    margin-top: 4px;
  }

  &__text {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep {
    .theme--light.v-chip:not(.v-chip--active) {
      display: inline-flex;
      background-color: var(--v-ttPrimary-base);
      color: var(--v-accent-lighten5);
      margin: 0 4px 8px;
    }

    .v-chip.v-size--small .v-icon.cursor-help {
      cursor: help;
    }
  }
}
</style>
