// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetReservationsTile from './AssetReservationsTile.vue'
import AssetReservationsColumns from './AssetReservationsColumns'
import AssetReservationsDetail from './AssetReservationsDetail.vue'
import AssetReservationsMetaCard from './AssetReservationsMetaCard.vue'
import AssetReservationsReference from './AssetReservationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetReservationsTile', AssetReservationsTile)
    Vue.component('AssetReservationsDetail', AssetReservationsDetail)
    Vue.component('AssetReservationsMetaCard', AssetReservationsMetaCard)
    Vue.component('AssetReservationsReference', AssetReservationsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetReservationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetReservationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetReservationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetReservationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_RESERVATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetReservationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
