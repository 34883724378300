// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ClientsTile from './ClientsTile.vue'
import ClientsColumns from './ClientsColumns'
import ClientsDetail from './ClientsDetail.vue'
import ClientsMetaCard from './ClientsMetaCard.vue'
import ClientsReference from './ClientsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ClientsTile', ClientsTile)
    Vue.component('ClientsDetail', ClientsDetail)
    Vue.component('ClientsMetaCard', ClientsMetaCard)
    Vue.component('ClientsReference', ClientsReference)

    modularManager.addResourcePreset(Resources.CLIENTS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'ClientsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.CLIENTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ClientsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.CLIENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ClientsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.CLIENTS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'ClientsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.CLIENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ClientsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
