export enum carryOverStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export type carryOverStatusColor = {
  background: string
  text: string
}

export const carrOverStatusColors: Record<
  carryOverStatus,
  carryOverStatusColor
> = {
  [carryOverStatus.QUEUED]: { background: '#F3F4F5', text: '#2A2E31' },
  [carryOverStatus.RUNNING]: { background: '#E9EAFF', text: '#405CE7' },
  [carryOverStatus.COMPLETED]: { background: '#DEF9E4', text: '#0E8129' },
  [carryOverStatus.ERROR]: { background: '#FFE4E9', text: '#D01131' },
}
