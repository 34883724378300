<template>
  <v-card v-if="isConverting && hasActions" flat>
    <v-card-subtitle class="pb-0">
      <tt-attr-label name="schedule.reasonToConvert" />
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <tt-attr name="schedule.reasonToConvert" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'

import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import {
  SiteTaskOccurrenceStatus,
  siteTaskOccurrenceStatus,
} from './site-task-occurrences-status'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskOccurrencesReasonToConvertCard',
  inject: ['getItemHook'],
  props: {
    actions: {
      type: Array as PropType<Action[]>,
      default: () => [],
    },
  },
  computed: {
    hasActions(): boolean {
      return this.actions.length > 0
    },
    isConverting(): boolean {
      return this.status === siteTaskOccurrenceStatus.CONVERTING
    },
    status(): SiteTaskOccurrenceStatus {
      return this.getItemHook().getRawValue('status')
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
})
</script>
