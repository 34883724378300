<template>
  <CollapsibleHeader
    v-if="resource"
    :title="
      $t('components.context_attribute_map_edit_form.collapsible_header_title')
    "
  >
    <div v-if="model">
      <InfoBox>
        {{
          $t(
            'components.context_attribute_map_edit_form.change_attribute_reactive',
          )
        }}
      </InfoBox>

      <v-row no-gutters>
        <v-col cols="3">
          <v-subheader class="ma-0 pa-0">{{
            $t('components.context_attribute_map_edit_form.date_range')
          }}</v-subheader>
        </v-col>
        <v-col>
          <ContextAttributeMapFilterSelector
            v-model="model.dateRangeAttribute"
            filter-name="dateRangeAttribute"
            :filter="filters.dateRangeFilter"
            @change="changeDateRange"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-subheader class="ma-0 pa-0">{{
            $t('components.context_attribute_map_edit_form.region')
          }}</v-subheader>
        </v-col>
        <v-col>
          <ContextAttributeMapFilterSelector
            v-model="model.regionAttribute"
            filter-name="regionAttribute"
            :filter="filters.regionFilter"
            @change="changeRegion"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="3">
          <v-subheader v-model="model.accountAttribute" class="ma-0 pa-0">
            {{ $t('components.context_attribute_map_edit_form.account') }}
          </v-subheader>
        </v-col>
        <v-col>
          <ContextAttributeMapFilterSelector
            v-model="model.accountAttribute"
            filter-name="accountAttribute"
            :filter="filters.accountFilter"
            @change="changeAccount"
          />
        </v-col>
      </v-row>
      <InfoBox>
        {{
          $t('components.context_attribute_map_edit_form.other_context_filter')
        }}
      </InfoBox>
    </div>
  </CollapsibleHeader>
</template>

<script lang="ts">
import isEqual from 'lodash/isEqual'
import Vue, { PropType, VueConstructor } from 'vue'

import {
  AttributeFilterInterface,
  FieldTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import { ChooseDateRange, ChooseRegion, ChooseAccount } from '@/plugins/o11n'
import { cloneData } from '@/helpers/cloneData'
import { Resources } from '@/tt-entity-design/src/types'

import ContextAttributeMapFilterSelector from './ContextAttributeMapFilterSelector.vue'
import { removeNullKeys } from '../types'
import { ResourceProvider, FormHookProvider } from '../types'
import { ContextAttributeMap } from '@/tt-widget-components/base/contextAttributeMap'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & ResourceProvider>
).extend({
  name: 'ContextAttributeMapEditForm',
  components: {
    ContextAttributeMapFilterSelector,
  },
  inject: ['formHook', 'resourceProvider'],
  props: {
    value: {
      type: Object as PropType<ContextAttributeMap>,
      default: undefined,
    },
  },
  data() {
    return {
      defaults: {
        dateRangeAttribute: null,
        regionAttribute: null,
        accountAttribute: null,
      },
      filters: {
        accountFilter(attr: AttributeFilterInterface) {
          if (!attr.attribute?.relation?.resource) {
            return false
          }
          return (
            [
              Resources.DEPARTMENTS,
              Resources.ACCOUNTS,
              Resources.CLIENTS,
              Resources.ZONES,
            ] as string[]
          ).includes(attr.attribute?.relation?.resource)
        },
        dateRangeFilter(attr: AttributeFilterInterface) {
          return [
            FieldTypes.DateTime,
            FieldTypes.Date,
            FieldTypes.TimeStampDate,
            FieldTypes.TimeStampNumber,
          ].includes(attr.attribute?.type)
        },
        regionFilter(attr: AttributeFilterInterface) {
          return attr.attribute?.relation?.resource === Resources.REGIONS
        },
      },
      model: {
        dateRangeAttribute: null,
        regionAttribute: null,
        accountAttribute: null,
      },
    }
  },
  computed: {
    output(): any {
      return this.toOutput(this.model)
    },
    resource(): any {
      return this.resourceProvider.resource
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        const newModel = this.toModel(val)
        if (!isEqual(this.model, newModel)) {
          this.model = newModel
        }
      },
    },
    model: {
      deep: true,
      handler(val) {
        const newValue = cloneData(this.toOutput(val))
        if (!isEqual(this.value, newValue)) {
          this.$emit('input', newValue)
        }
      },
    },
  },
  methods: {
    analyticsChangeLabel(val: string | null | false): string {
      switch (val) {
        case null:
          return 'default'
        case false:
          return 'disabled'
        default:
          return val
      }
    },
    changeAccount(val: string | null | false): void {
      this.trackChangeEvent(ChooseAccount, val)
    },
    changeDateRange(val: string | null | false): void {
      this.trackChangeEvent(ChooseDateRange, val)
    },
    changeRegion(val: string | null | false): void {
      this.trackChangeEvent(ChooseRegion, val)
    },
    toOutput(val) {
      if (!val) {
        return {}
      }
      return removeNullKeys(cloneData(val))
    },
    toModel(val) {
      return cloneData({
        ...this.defaults,
        ...(val ?? {}),
      })
    },
    trackChangeEvent(
      eventBuilder:
        | typeof ChooseAccount
        | typeof ChooseDateRange
        | typeof ChooseRegion,
      val: string | null | false,
    ): void {
      const label = this.analyticsChangeLabel(val)
      const event = eventBuilder.create({ label })
      this.$analytics.track(event)
    },
  },
})
</script>
