<template>
  <div class="vertical-stack-card">
    <slot v-bind="resizeEventPayload" />

    <ResizeObserver class="hidden--observer" @resize="saveSizeAndReEmit" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ResizeObserver from '@/tt-widget-components/components/ResizeObserver/ResizeObserver.vue'
import {
  ResizeEventPayload,
  createNewPayload,
} from '@/tt-widget-components/components/ResizeObserver/ResizeObserver'

/**
 * Component used to get the available size of a container in JS.
 * It will react to any change of its container.
 *
 * ie: it is mainly used to provide width / height to Syncfusion widgets.
 */
export default Vue.extend({
  name: 'VerticalStackCard',
  components: { ResizeObserver },
  data: () => ({
    resizeEventPayload: createNewPayload(),
  }),
  methods: {
    saveSizeAndReEmit(event: ResizeEventPayload) {
      this.resizeEventPayload = { ...event }
      this.$emit('resize', event)
    },
  },
})
</script>

<style scoped>
.vertical-stack-card {
  height: 100%;
  min-height: 0;
  position: relative;
  border-radius: 7px;
}

.hidden--observer {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
