import { i18n } from '@/main'
import { LayoutWindowEvent } from '@/tt-app-layout'

export const copyToClipboard = async (str, eventManager) => {
  try {
    await navigator.clipboard.writeText(str)
    eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
      message: i18n.t('widgets.json_viewer.copy_to_clipboard.success'),
    })
  } catch (error) {
    eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
      message: i18n.t('widgets.json_viewer.copy_to_clipboard.failure', {
        error,
      }),
    })
  }
}
