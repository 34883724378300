<template>
  <div @click.stop="dispatchEditAssignedPolicyItemsForm">
    <span style="cursor: pointer">
      {{ policyItemsTranslation }}
    </span>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActiveWindowProvider } from '@/tt-app-layout/types'
export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'AssignedPolicyItemsClickableColumn',
  inject: ['getItemHook', 'activeWindow'],
  computed: {
    policyItemsLocale(): string {
      return 'tt-entity-design.leave-management.leave-policies.policy-items-amount'
    },
    policyItemsCount(): number {
      return this.getItemHook().get('leavePolicyItems')
    },
    policyItemsTranslation(): string {
      return this.$tc(this.policyItemsLocale, this.policyItemsCount)
    },
  },
  created() {
    this.getItemHook().addAttribute('leavePolicyItems')
  },
  methods: {
    dispatchEditAssignedPolicyItemsForm() {
      this.activeWindow.next({
        title: 'tt-entity-design.leave-management.label',
        is: 'LeavePoliciesForm',
        props: { editStep: 1, policyIdToEdit: this.getItemHook().get('id') },
      })
    },
  },
})
</script>
