export enum CalendarExceptionTypeLevel {
  Root = 'ROOT',
  SubType = 'SUB_TYPE',
}

export type CalendarExceptionFeatureFlags = {
  calendarExceptionFeatureFlags: {
    othersTypeForRunsheetEventsEnabled?: boolean
    othersTypeForRunsheetsEnabled?: boolean
    othersTypeForSiteTasksEnabled?: boolean
  }
}
