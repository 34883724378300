import { modularManager } from '@/tt-app-modular'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import CreateExceptionSubtype from './CreateExceptionSubtype.vue'
import ExceptionTypeParentLabel from './ExceptionTypeParentLabel.vue'
import ExceptionTypesAddDates from './ExceptionTypesAddDates.vue'
import ExceptionTypesParentLabelWrapper from './ExceptionTypesParentLabelWrapper.vue'
import ExceptionTypesLabelWrapper from './ExceptionTypesLabelWrapper.vue'

import { ExceptionTypeActions } from './types'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('CreateExceptionSubtype', CreateExceptionSubtype)
    Vue.component('ExceptionTypeParentLabel', ExceptionTypeParentLabel)
    Vue.component('ExceptionTypesAddDates', ExceptionTypesAddDates)
    Vue.component(
      'ExceptionTypesParentLabelWrapper',
      ExceptionTypesParentLabelWrapper,
    )
    Vue.component('ExceptionTypesLabelWrapper', ExceptionTypesLabelWrapper)

    registerResourceForm(Resources.EXCEPTION_TYPES, 'CreateExceptionSubtype')
    registerResourceForm(
      Resources.EXCEPTION_TYPES,
      'ExceptionTypesAddDates',
      ExceptionTypeActions.AddDates,
    )

    modularManager.registerDynamicResourceJsonSchemaActions(
      Resources.EXCEPTION_TYPES,
      [ExceptionTypeActions.PublishToAccounts],
    )
  },
}
