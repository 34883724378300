<template>
  <EntityViewAttributeCollector
    v-if="fieldsCollector"
    :resource-name="resourceName"
    @input="$emit('fields', $event)"
  >
    <component :is="component" v-bind="componentProps" />
  </EntityViewAttributeCollector>

  <component :is="component" v-else v-bind="componentProps" />
</template>

<script lang="ts">
import Vue from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { PresetTypes, modularManager, PresetItem } from '@/tt-app-modular'
import { ItemHookProvider } from '../types'

export default Vue.extend({
  name: 'EntityPresetRelation',
  provide(): (ItemHookProvider & { relationPrefix: '' }) | null {
    return !this.fieldsCollector
      ? {
          getItemHook: () => this.itemHook,
          relationPrefix: '',
        }
      : null
  },
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    fieldsCollector: {
      type: Boolean,
      default: false,
    },
    fetchEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // @ts-ignore -- methods not seen by TS in data
      itemHook: this.createItemHook(),
    }
  },
  computed: {
    preset(): PresetItem {
      return modularManager.getResourcePreset(
        this.resourceName,
        PresetTypes.RELATION_REFERENCE,
      )
    },
    component(): string {
      return this.preset?.data?.is || 'FallbackReference'
    },
    componentProps(): Record<string, any> {
      return this.preset?.data.props || {}
    },
  },
  watch: {
    entity() {
      this.itemHook = this.createItemHook()
    },
    resourceName() {
      this.itemHook = this.createItemHook()
    },
  },
  methods: {
    createItemHook(): EntityItemHook {
      return new EntityItemHook(
        this.$appContext,
        {
          entity: this.entity,
          resourceName: this.resourceName,
          entityId: this.entity.id,
        },
        { fetchEnabled: this.fetchEnabled },
      )
    },
  },
})
</script>
