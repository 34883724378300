<template>
  <TTooltipOnOverflow
    :text="title"
    :disable-tooltip="!isOverflowing || isLoading"
  >
    <div class="actionbar--container">
      <TLoadingBar v-if="isLoading" />
      <v-btn
        class="actionbar--btn"
        :class="isLoading && 'actionbar--btn-loading'"
        tile
        color="ttPrimary"
        text
        :disabled="disabled"
        @click="click"
      >
        <v-icon v-text="icon" />

        <OverflowObserver class="w-100" @overflow="onOverflow">
          <span
            class="d-block text-truncate actionbar--btn-text"
            v-text="title"
          />
        </OverflowObserver>
      </v-btn>
    </div>
  </TTooltipOnOverflow>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'
import OverflowObserver from '@/tt-widget-components/components/OverflowObserver.vue'
import { updateDOM } from '@/helpers/dom/updateDOM'

export default Vue.extend({
  name: 'ActionbarButton',
  components: { TTooltipOnOverflow, OverflowObserver },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => Promise<void>>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOverflowing: false,
    isLoading: false,
  }),
  methods: {
    onOverflow(isOverflowing: boolean) {
      this.isOverflowing = isOverflowing
    },
    async click() {
      this.isLoading = true

      await this.$nextTick()
      await updateDOM()
      await this.onClick?.()

      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.actionbar--container {
  position: relative;
  height: 100%;
}
.actionbar--btn {
  width: 100%;
  height: 100% !important;
  padding: 3px;
}

.actionbar--btn >>> .v-btn__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.actionbar--btn-text {
  text-transform: capitalize;
  font-size: 0.8em;
}

.actionbar--btn-loading::before {
  opacity: 0 !important;
}
</style>
