<template>
  <div>
    <json-field name="person" />
    <json-field name="comments" />
    <json-field
      v-show="hasCustomAttributes"
      name="customAttributes"
      :show-header="hasCustomAttributes"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import { VueWithFormHookInjection } from '@/helpers/components/types'
import { isEmpty } from 'lodash'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'AssetsCheckIn',
  inject: ['formHook'],
  computed: {
    customAttributes(): Record<string, unknown> | undefined {
      return this.formHook().getPathValue('customAttributes')
    },
    hasCustomAttributes(): boolean {
      return !isEmpty(this.customAttributes)
    },
  },
})
</script>
