import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'position.account.name',
  'employee.name',
  'position',
  'startTime',
  'endTime',
  'startDayLabel',
] as ColumnInputDefinition[]
