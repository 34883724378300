import { PluginFunction } from 'vue'

const ClientWorkflowActionSettingsView = () =>
  import('./views/ClientWorkflowActionSettingsView.vue')

export { ClientWorkflowActionSettingsView }

export const DispatchActionSettingsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component(
    'ClientWorkflowActionSettingsView',
    ClientWorkflowActionSettingsView,
  )
}
