<template>
  <div
    ref="print-view"
    class="print-view letter-size--width letter-size--height"
  >
    <v-overlay :opacity="0.7">
      <v-alert text color="#e0e0e0" class="text-center print-alert">
        <p v-text="alertMessage" />

        <p
          v-if="totalToRender && !isClosing"
          class="text-center"
          v-text="renderingText"
        />

        <p>
          <v-progress-linear
            :value="loaderValue"
            :color="progressBarColor"
            stream
            buffer-value="0"
            rounded
          />
        </p>
        <v-btn v-if="!isClosing" outlined text small @click="close">
          {{ $t(`common.cancel.btn`) }}
        </v-btn>
      </v-alert>
    </v-overlay>

    <div class="first-page letter-size--height">
      <img
        :src="coverImageUrl"
        :transition="false"
        class="cover-image"
        @load="coverImageLoaded = true"
      />
      <img
        :src="portalLogoUrl"
        class="portal-logo"
        :transition="false"
        @load="portalLogoLoaded = true"
      />
      <h1 class="text-center">
        {{ widget.title }}
      </h1>
    </div>

    <widget-factory
      v-if="mountWidgetFactory"
      class="widget-factory"
      :widget="widget"
      @rendering="onCurrentlyRendering"
      @rendered="widgetRendered = true"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { WidgetModels } from '@/tt-widget-components'
import { updateDOM } from '@/helpers/dom'
import { ForceMountWidget } from '../widgets/Dashboard/ForceMountWidget'
import coverImageUrl from './img/widget_print_cover.jpg'

/**
 * The component is all about making sure everything is rendered into the DOM,
 * before triggering the browser print() function.
 */
export default Vue.extend({
  name: 'WidgetPrint',
  provide(): ForceMountWidget {
    return { forceMountWidget: true }
  },
  props: {
    widget: {
      type: Object as PropType<WidgetModels>,
      required: true,
    },
  },
  data() {
    return {
      currentlyRendered: 0,
      totalToRender: 0,
      mountWidgetFactory: false,
      isClosing: false,
      portalLogoUrl: '',
      portalLogoLoaded: false,
      coverImageLoaded: false,
      widgetRendered: false,
      coverImageUrl,
    }
  },
  computed: {
    loaderValue(): number {
      const getPercentage = () =>
        (this.currentlyRendered / this.totalToRender) * 100

      return this.isClosing ? 0 : getPercentage()
    },
    isReadyToPrint(): boolean {
      return (
        this.portalLogoLoaded && this.coverImageLoaded && this.widgetRendered
      )
    },
    renderingText(): string {
      const msg = `${this.currentlyRendered} / ${this.totalToRender}`

      return this.$t('widget_view.print.widgets_rendered', { msg })
    },
    progressBarColor(): string {
      return this.isReadyToPrint ? 'success' : 'warning'
    },
    alertMessage(): string {
      return !this.isClosing
        ? this.$t(`widget_view.print.wait`)
        : this.$t(`widget_view.print.closing`)
    },
  },
  watch: {
    async isReadyToPrint() {
      if (this.isReadyToPrint) {
        // to ensure the DOM is updated before printing
        await updateDOM()
        window.print()
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('afterprint', this.close)
  },
  created() {
    this.fetchPortalLogoUrl()
  },
  async mounted() {
    window.addEventListener('afterprint', this.close)

    // We let the overlay appears first and then mount the WidgetFactory
    await updateDOM()

    this.mountWidgetFactory = true
  },
  methods: {
    async fetchPortalLogoUrl() {
      const onError = (err) => {
        console.warn('Error while fetching portal logo url', err)

        return ''
      }

      this.portalLogoUrl = await this.$auth
        .getApi()
        .about()
        .then((res) => res.logo)
        .catch(onError)
    },
    async close() {
      this.isClosing = true

      // to ensure the "closing" message is displayed while its processing
      await updateDOM()
      this.$emit('exit')
    },
    onCurrentlyRendering({ current, total } = { current: 0, total: 0 }) {
      this.currentlyRendered = current || 0
      this.totalToRender = total || 0
    },
  },
})
</script>

<style scoped>
.print-alert {
  background: #000000a8 !important;
  min-width: 300px;
}

.print-view {
  margin: auto;
  print-color-adjust: exact;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.letter-size--height {
  height: 11in;
}

.letter-size--width {
  width: 8.5in;
}

.first-page {
  display: flex;
  flex-direction: column;
  border: 3px dashed #0a000054;
}

.cover-image {
  width: 100%;
  border-radius: 5px;
}

.portal-logo {
  max-width: 200px;
  margin: 5em auto;
}

@media print {
  .v-overlay {
    display: none !important;
  }

  .first-page {
    border: none;
  }
}
</style>
