import {
  Attribute,
  FieldTypes,
} from '@/tt-widget-factory/services/resource-meta/types'

import DataSetWidgetHook from '../../base/DataSetWidgetHook'
import { currencyFormat } from '../../syncfusion/helpers'
import { MetricTableWidgetModel } from '../../schemas-types'

const isAttributeDateOrTime = (attribute: Attribute): boolean =>
  [
    FieldTypes.Date,
    FieldTypes.DateTime,
    FieldTypes.Time,
    FieldTypes.TimeStampDate,
    FieldTypes.TimeStampNumber,
  ].includes(attribute.type)

export default class MetricTableWidgetHook extends DataSetWidgetHook<MetricTableWidgetModel> {
  /**
   * If no sort is defined and first dimension is a date, default to sorting by
   * the first dimension. Otherwise, sort by first measure.
   */
  get sort() {
    if (this.state.sort) {
      return this.state.sort
    }

    const { firstDimension, firstMeasure } = this.seriesManagers[0] ?? {}

    const dimensionAttribute =
      firstDimension &&
      this.services.resourceMetaManager.getAttribute(
        this.resource,
        firstDimension.attribute,
      )

    if (firstDimension?.key && isAttributeDateOrTime(dimensionAttribute)) {
      return { key: firstDimension.key, desc: true }
    }

    if (firstMeasure?.key) {
      return { key: firstMeasure.key, desc: true }
    }

    return null
  }

  set sort(sort) {
    this.state.sort = sort
  }

  sortBy(key: string, desc = true) {
    if (this.sort && this.sort.key === key) {
      this.sort = { key, desc: !this.sort.desc }
    } else {
      this.sort = { key, desc }
    }
  }

  getFormattedMeasure(measure: any, index: number, measureValue: number) {
    let prefix = ''

    if (this.getDefaultMeasureFormat(measure) === currencyFormat) {
      prefix = this.services.authModule.getUserPreferences().currency
    }

    const formattedMeasure = this.formatMeasureValue(
      measureValue,
      this.state.widget.dataSet.measures[index].format,
      false,
    )

    return `${prefix}${formattedMeasure}`
  }
}
