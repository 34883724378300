<template>
  <div class="d-flex justify-center align-center mt-2">
    <TPdfExportButton @click="view" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import i18n from '@/plugins/i18n'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '../../types'
import TPdfExportButton from '@/tt-ui/components/TPdfExportButton.vue'
import { getResourceDocument } from '@/tt-widget-entity-flow/helper'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ReportsPDFButton',
  components: { TPdfExportButton },
  inject: ['getItemHook', 'relationPrefix'],
  computed: {
    reportId(): number {
      const attribute = this.relationPrefix ? `${this.relationPrefix}.id` : 'id'

      return this.getItemHook().getRawValue(attribute)
    },
  },
  methods: {
    async view() {
      const translationKey = 'tt-entity-design.report'
      const documentPreset = getResourceDocument(
        Resources.REPORTS,
        translationKey,
      )

      if (!documentPreset) {
        console.error(
          `No document preset registered for resource "${
            Resources.REPORTS
          }" and document name ${this.$t(translationKey)}`,
        )

        return
      }
      const reportInput = await documentPreset.toDocument(
        this.$appContext,
        { ...this.getItemHook().entityReference, entityId: this.reportId },
        i18n.locale,
      )
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        reportInput,
      )
    },
  },
})
</script>
