<template>
  <div>
    <span class="contact-details--title pb-2">{{ $t(title) }}</span>
    <div class="pa-4 v-card v-card--flat v-sheet theme--light">
      <v-row no-gutters class="ma-1">
        <v-col class="attribute-row-label" cols="4">
          <span>{{
            $t(createTranslationKey('contacts-detail-labels.name'))
          }}</span>
        </v-col>
        <v-col class="ml-1 attribute-row-value">
          <div>
            <span>{{ name }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider style="opacity: 0.5" />
      <v-row no-gutters class="ma-1">
        <v-col class="attribute-row-label" cols="4">
          <span>{{
            $t(createTranslationKey('contacts-detail-labels.title'))
          }}</span>
        </v-col>
        <v-col class="ml-1 attribute-row-value">
          <div>
            <span>{{ jobTitle }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider style="opacity: 0.5" />
      <v-row no-gutters class="ma-1">
        <v-col class="attribute-row-label" cols="4">
          <span>{{
            $t(createTranslationKey('contacts-detail-labels.phone'))
          }}</span>
        </v-col>
        <v-col class="ml-1 attribute-row-value">
          <div>
            <span>{{ primaryPhone }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LinkedUserContactsDetail',
  inject: ['getItemHook'],
  computed: {
    title(): string {
      return this.createTranslationKey('contacts.related-user-contacts')
    },
    name(): string {
      return this.getItemHook().getRawValue('userRelation.name')
    },
    jobTitle(): string {
      return this.getItemHook().getRawValue('userRelation.jobTitle')
    },
    primaryPhone(): string {
      return this.getItemHook().getRawValue('userRelation.primaryPhone')
    },
  },
  methods: {
    createTranslationKey(key: string) {
      return `tt-entity-design.system-exception-tickets.${key}`
    },
  },
})
</script>
