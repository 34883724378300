import { FormatterInput } from '@/helpers/formats/types'
import { Moment, MomentZone } from 'moment-timezone'

export enum DateFormatTypes {
  asTimeFromNow = 'asTimeFromNow',
  asTimeToNow = 'asTimeToNow',
  asDate = 'asDate',
  asTime = 'asTime',
  asDateTime = 'asDateTime',
  asShortTime = 'asShortTime',
  asVeryShortTime = 'asVeryShortTime',
  asLongDate = 'asLongDate',
  asLongDateTime = 'asLongDateTime',
  asTimeToNowFromUTC = 'asTimeToNowFromUTC',
  asHoursFromNow = 'asHoursFromNow',
  asTimeWithoutTimezone = 'asTimeWithoutTimezone',
  asShortTimeWithoutTimezone = 'asShortTimeWithoutTimezone',
  asTimeZone = 'asTimeZone',
  asDuration = 'asDuration',
  asUTCDate = 'asUTCDate',
}

export enum ApiDateTimeFormats {
  ISO_8601 = 'YYYY-MM-DD[T]HH:mm:ssZ',
  datetime = 'YYYY-MM-DD HH:mm:ss',
  date = 'YYYY-MM-DD',
  time = 'HH:mm:ss',
}

export interface DateRangeInput {
  startDate: FormatterInput
  endDate: FormatterInput
}

export interface ShortFormatOptions {
  momentObj: Moment
  momentFormat: string
  removeTimezone?: boolean
}
