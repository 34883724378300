import { AppContext } from '@/tt-app-context'
import { EventProviderInterface } from '@/tt-widget-entity-flow/types'
import { FieldTypes, ResourceMetaManagerInterface } from '@/tt-widget-factory'
import { parseResourceDates } from '@/tt-widget-factory/helpers/parse-resource-attributes'

const isNotRelationList =
  (resourceMetaManager: ResourceMetaManagerInterface, resource: string) =>
  (attribute: string) =>
    resourceMetaManager.getAttribute(resource, attribute)?.type !==
    FieldTypes.RelationList

/**
 * Base entity intent
 */
export abstract class BaseEntityIntent implements EventProviderInterface {
  appContext: AppContext

  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  abstract getEventName(): string

  abstract run(payload: any): any

  /**
   * Get the entity
   * @param resource
   * @param entityId
   */
  async getEntityForEdit(resource: string, entityId: number) {
    const { resourceMetaManager } = this.appContext.widgetServices

    const attributes = resourceMetaManager.getAttributes(resource, 0) || []

    const fields = Object.keys(attributes)
      // For now we prevent editing relationList
      // @todo: FE-642
      .filter(isNotRelationList(resourceMetaManager, resource))
      .map((attribute) => ({ attribute }))

    const api = this.appContext.authModule.getApi()
    const entity = await api.get(resource, entityId, { fields })

    /**
     * DateField expects a Date format but API is sending DateTime format for
     * Date properties for the moment: API-1715
     */
    return parseResourceDates(resourceMetaManager, resource, entity)
  }
}
