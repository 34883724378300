<template>
  <v-card v-if="jsonSchema" flat class="fill-height">
    <v-divider />

    <FormUpdatedBanner class="mt-2" v-bind="{ resourceName, entityId }" />

    <v-container class="pa-0">
      <json-form
        v-model="model"
        :schema="jsonSchema"
        :form-options="formOptions"
        :name="rootName"
        :user-context="userContext"
        @errors="formErrors = $event"
        @valid="formErrors = {}"
        @debouncing="debouncing = $event"
      >
        <DispatchTasksAssign />
      </json-form>
    </v-container>

    <v-divider />

    <v-toolbar flat text>
      <json-valid
        v-if="isDev"
        :loading="debouncing"
        :show-text="false"
        :valid="!isSubmitDisabled"
        :errors="formErrors"
        :valid-text="$t('common.form.valid')"
        :invalid-text="$t('common.form.invalid')"
      />

      <v-spacer />

      <v-btn class="ma-3" color="grey" outlined raised @click="$emit('cancel')">
        <span v-text="$t('common.cancel.btn')" />
      </v-btn>

      <v-btn
        :disabled="isSubmitDisabled"
        raised
        color="success"
        class="ma-3"
        :loading="loading"
        @click="submitForm"
      >
        <span v-text="$t('common.save.btn')" />
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { cloneData } from '@/helpers/cloneData'
import { ErrorObject } from 'ajv'
import { FormOptions, JSONSchema7 } from '@tracktik/tt-json-schema-form'
import {
  GenericFormError,
  GenericFormSuccess,
} from '@/tt-app-layout/components/GenericForm.vue'
import DispatchTasksAssign from '../DispatchTasksAssign.vue'
import FormUpdatedBanner from '@/tt-app-layout/components/FormUpdatedBanner.vue'
import { Resources } from '../../../types'

export default Vue.extend({
  name: 'DispatchTasksAssignForm',
  components: {
    DispatchTasksAssign,
    FormUpdatedBanner,
  },
  inject: {
    activeWindow: { default: null },
  },
  props: {
    /**
     * tt-json-schema-form's form options
     */
    formOptions: { type: Object as PropType<FormOptions>, default: () => ({}) },
    /**
     * Form's initial value
     */
    initialModel: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({} as Record<string, any>),
    },
    /**
     * tt-json-schema-form's JSON Schema
     */
    jsonSchema: { type: Object as PropType<JSONSchema7>, required: true },
    /**
     * tt-json-schema-form's root field name
     */
    rootName: { type: String, default: undefined },
    /**
     * tt-json-schema-form's user context
     */
    userContext: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
    /**
     * Submit handler
     */
    submit: {
      type: Function as PropType<(data: Record<string, any>) => Promise<any>>,
      required: true,
    },
  },
  data() {
    return {
      debouncing: false,
      loading: false,
      formErrors: null as { [key: string]: ErrorObject[] } | null,
      model: {},
      hasReportErrors: false,
    }
  },
  computed: {
    isDev(): boolean {
      return process.env.NODE_ENV !== 'production'
    },
    isSubmitDisabled(): boolean {
      return (
        !!Object.keys(this.formErrors || {}).length ||
        this.debouncing ||
        this.loading ||
        this.hasReportErrors
      )
    },
    isValid(): boolean {
      return !Object.keys(this.formErrors || {}).length
    },
    entityId(): string {
      const entityId = this.userContext?.itemHook?.getEntityId()

      return entityId ? `${entityId}` : ''
    },
    resourceName(): string {
      return Resources.DISPATCH_TASKS
    },
  },
  created() {
    this.model = cloneData(this.initialModel)
  },
  methods: {
    async submitForm(): Promise<void> {
      if (!this.isValid) {
        return
      }

      this.$emit('submit', this.model)
      this.loading = true

      this.submit(this.model)
        .then((response) => {
          const event: GenericFormSuccess = { data: this.model, response }
          this.$emit('submit:success', event)
          this.$emit('back')
        })
        .catch((error) => {
          this.$crash.captureException(error)

          const event: GenericFormError = { data: this.model, error }
          this.$emit('submit:error', event)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
