import { AllowDownloadAll, WidgetDownloadTypes } from '../types'
import { WidgetReference } from '@/tt-widget-factory/WidgetHookInterface'

export const widgetAllowsDownloadByType = (
  widget: WidgetReference & { allowDownload?: AllowDownloadAll | null },
  type: WidgetDownloadTypes
): boolean => {
  if (widget.allowDownload == null) return true
  if (typeof widget.allowDownload === 'boolean') return widget.allowDownload
  return widget.allowDownload[type]
}
