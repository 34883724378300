import { WidgetCollectionProviderInterface } from '@/apps/app.tracktik.insights.studio/lib/MarketplaceWidgetProvider'
import {
  AttributeFilter,
  CollectionQuery,
  ColumnDefinition,
  TQLQueryWidgetModel,
} from '@/tt-widget-components'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

/**
 * @TODO:Update Nomenclature to Reporting & Analytics (BOSS-4284)
 */

// Name of collection fetched where new reports will be built
export const FINANCIAL_REPORTS_COLLECTION =
  'apps/ui/collections/admin.fors.collection'

export type FinancialReportsCollectionResponse = {
  data: WidgetCollectionProviderInterface
  name: string
  endpoint: string
  version: number
}

export type ReportingAndAnalyticsMenuItem = {
  key: BackOfficeReportsMenus
  menuTitle: string
  subMenus: ReportingAndAnalyticsSubMenuItem[]
}

export type ReportingAndAnalyticsSubMenuItem = {
  key: BackOfficeReportsSubMenus | BackOfficeReportsFinacialOperationalSubMenus
  subMenuTitle: string
  tabs: ReportingAndAnalyticsSubMenuTab[]
}

export type BackOfficeReportsTab = {
  query: CollectionQuery
  title: string
  key: ReportingAndAnalyticsViews
  columns: ColumnInputDefinition[]
  filters?: AttributeFilter[]
  propsData?: object
  allowDownload?: boolean
  downloadColumns?: ColumnDefinition[]
}

export type ReportingAndAnalyticsSubMenuTab = {
  title?: string
  key: ReportingAndAnalyticsViews
  widget?: TQLQueryWidgetModel
}

export enum ReportingAndAnalyticsViews {
  // Financial Performance views
  CUSTOMER_ANALYSIS_REPORT = 'customer-analysis-report',

  // Effective date views
  POSITIONS_BILL_RATE = 'positions-bill-rate',
  POSITIONS_PAY_RATE = 'positions-pay-rate',
  EMPLOYEE_PAY_RATE = 'employee-pay-rate',
  EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_RATE = 'employee-account-assignment-pay-rate',
  PRICE_TIERS_BILL_SETTINGS = 'price-tiers-bill-settings',

  // Scheduling views
  SCHEDULE_CHANGES = 'schedule-changes',
  SCHEDULING_REPORT = 'scheduling-report',

  // Earnings premium views
  POSITIONS_EARNINGS_PREMIUM = 'positions-earnings-premium',
  EMPLOYEE_PROFILE_EARNINGS_PREMIUM = 'employee-profile-earnings-premium',

  // Human Captial Management views
  VIEW_HCM_CERTIFICATIONS = 'hcmCertifications',
  VIEW_HCM_COMPLETED_REQUESTS = 'hcmCompletedRequests',
  VIEW_HCM_CONFLICTS = 'hcmConflicts',

  // Leave management views
  VIEW_LEAVE_MANAGEMENT_COMPLETED_REQUESTS = 'leave-management-completed-requests',

  // Empty view
  EMPTY = 'empty',
}

export enum BackOfficeReportsMenus {
  FINANCIAL_OPERATIONAL_MENU = 'financial-operational-menu',
  BACK_OFFICE_REPORTS_MENU = 'back-office-reports-menu',
}

export enum BackOfficeReportsFinacialOperationalSubMenus {
  FINANCIAL_PERFORMANCE_SUB_MENU = 'financial-performance-sub-menu',
}

export enum BackOfficeReportsSubMenus {
  EFFECTIVE_DATE_SUB_MENU = 'effective-date-sub-menu',
  SCHEDULING_SUB_MENU = 'scheduling-sub-menu',
  EARNINGS_PREMIUM_SUB_MENU = 'earnings-premium-sub-menu',
  HCM_ACTIVITY_SUB_MENU = 'hcm-activity-sub-menu',
  LEAVE_MANAGEMENT_SUB_MENU = 'leave-management-sub-menu',
}

export enum PositionPremiumTypes {
  PAY_HOURLY = 'PAY_HOURLY',
  BILL_HOURLY = 'BILL_HOURLY',
  PAY_ADHOC = 'PAY_ADHOC',
  PAY_ON_TOP = 'PAY_ON_TOP',
  BILL_ON_TOP = 'BILL_ON_TOP',
}
