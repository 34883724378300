// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerScheduleCheckInsTile from './LoneWorkerScheduleCheckInsTile.vue'
import LoneWorkerScheduleCheckInsColumns from './LoneWorkerScheduleCheckInsColumns'
import LoneWorkerScheduleCheckInsDetail from './LoneWorkerScheduleCheckInsDetail.vue'
import LoneWorkerScheduleCheckInsReference from './LoneWorkerScheduleCheckInsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'LoneWorkerScheduleCheckInsTile',
      LoneWorkerScheduleCheckInsTile,
    )
    Vue.component(
      'LoneWorkerScheduleCheckInsDetail',
      LoneWorkerScheduleCheckInsDetail,
    )
    Vue.component(
      'LoneWorkerScheduleCheckInsReference',
      LoneWorkerScheduleCheckInsReference,
    )

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_SCHEDULE_CHECK_INS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerScheduleCheckInsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_SCHEDULE_CHECK_INS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerScheduleCheckInsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_SCHEDULE_CHECK_INS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerScheduleCheckInsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_SCHEDULE_CHECK_INS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerScheduleCheckInsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
