import CodeMirror from 'codemirror'

export function install() {
  /**
   * Register helper
   */
  CodeMirror.registerHelper('hint', 'sql', function(editor, options) {
    const keywords = CodeMirror.resolveMode('text/x-tql').keywords

    const cur = editor.getCursor()
    const result = []
    const token = editor.getTokenAt(cur)
    let start
    let end
    let search

    if (token.end > cur.ch) {
      token.end = cur.ch
      token.string = token.string.slice(0, cur.ch - token.start)
    }

    if (token.string.match(/^[.`"\w@]\w*$/)) {
      search = token.string
      start = token.start
      end = token.end
    } else {
      start = end = cur.ch
      search = ''
    }

    const objectOrClass = function(w, className) {
      if (typeof w === 'object') {
        w.className = className
      } else {
        w = { text: w, className: className }
      }
      return w
    }

    addMatches(result, search, keywords, function(w) {
      return objectOrClass(w, 'CodeMirror-hint-keyword')
    })

    return {
      list: result,
      from: CodeMirror.Pos(cur.line, start),
      to: CodeMirror.Pos(cur.line, end)
    }
  })

  /**
   * Matches
   * @param string
   * @param word
   * @return {boolean}
   */
  function match(string, word) {
    const len = string.length
    const sub = (typeof word == 'string' ? word : word.text).substr(0, len)
    return string.toUpperCase() === sub.toUpperCase()
  }

  function addMatches(result, search, wordList, formatter) {
    if (Array.isArray(wordList)) {
      for (let i = 0; i < wordList.length; i++)
        if (match(search, wordList[i])) {
          result.push(formatter(wordList[i]))
        }
    } else {
      for (const word in wordList)
        if (wordList.hasOwnProperty(word)) {
          let val = wordList[word]
          if (!val || val === true) val = word
          else
            val = val.displayText
              ? { text: val.text, displayText: val.displayText }
              : val.text
          if (match(search, val)) result.push(formatter(val))
        }
    }
  }
}

install()
