// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import Oauth2AccessTokensTile from './Oauth2AccessTokensTile.vue'
import Oauth2AccessTokensColumns from './Oauth2AccessTokensColumns'
import Oauth2AccessTokensDetail from './Oauth2AccessTokensDetail.vue'
import Oauth2AccessTokensReference from './Oauth2AccessTokensReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('Oauth2AccessTokensTile', Oauth2AccessTokensTile)
    Vue.component('Oauth2AccessTokensDetail', Oauth2AccessTokensDetail)
    Vue.component('Oauth2AccessTokensReference', Oauth2AccessTokensReference)

    modularManager.addResourcePreset(
      Resources.OAUTH2_ACCESS_TOKENS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'Oauth2AccessTokensTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_ACCESS_TOKENS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: Oauth2AccessTokensColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_ACCESS_TOKENS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'Oauth2AccessTokensDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_ACCESS_TOKENS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'Oauth2AccessTokensReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
