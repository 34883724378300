import Vue from 'vue'
import merge from 'lodash/merge'
import { ErrorMap } from '@tracktik/tt-json-schema-form'

import {
  CompoundFormManager,
  CompoundFormManagerItemState,
  CompoundFormManagerState,
} from '../types'

const getCompoundFormManager = (defaultValue = {}): CompoundFormManager => {
  const state: { forms: CompoundFormManagerState } = Vue.observable({
    forms: {},
  })

  const callbacks = { errors: [], value: [] }

  //#region Private methods
  const updateFormState = (
    formId: string,
    newPartialState: Partial<CompoundFormManagerItemState>,
  ) => {
    const currentFormState = state.forms[formId]

    state.forms = {
      ...state.forms,
      [formId]: {
        ...currentFormState,
        ...newPartialState,
      },
    }
  }
  //#endregion

  //#region Public Methods
  const getFormErrors = (formId: string) => {
    return state.forms[formId]?.errors || {}
  }

  const getFormValue = (formId: string) => {
    return state.forms[formId]?.value || defaultValue
  }

  const getCompoundErrors = () => {
    return merge({}, ...Object.keys(state.forms).map(getFormErrors))
  }

  const clearValue = () => {
    const formIds = Object.keys(state.forms)

    formIds.forEach((formId) => {
      setFormValue(formId, {})
    })
  }

  const getCompoundValue = () => {
    const formIds = Object.keys(state.forms)

    if (formIds.length === 0) {
      return defaultValue
    }

    return merge({}, ...formIds.map(getFormValue))
  }

  const onErrorsChange = (callback: (errors: ErrorMap) => void) => {
    callbacks.errors.push(callback)

    return () => {
      callbacks.errors = callbacks.errors.filter((cb) => cb !== callback)
    }
  }

  const onValueChange = (
    callback: (value: Record<string, unknown>) => void,
  ) => {
    callbacks.value.push(callback)

    return () => {
      callbacks.value = callbacks.value.filter((cb) => cb !== callback)
    }
  }

  const setFormErrors = (formId: string, errors: ErrorMap) => {
    updateFormState(formId, {
      errors,
    })

    callbacks.errors.forEach((cb) => cb(getCompoundErrors()))
  }

  const setFormValue = (formId: string, value: Record<string, unknown>) => {
    updateFormState(formId, {
      value,
    })

    callbacks.value.forEach((cb) => cb(getCompoundValue()))
  }
  //#endregion

  return {
    getCompoundErrors,
    getCompoundValue,
    getFormErrors,
    getFormValue,
    onErrorsChange,
    onValueChange,
    setFormErrors,
    clearValue,
    setFormValue,
  }
}

export default getCompoundFormManager
