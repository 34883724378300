import { modularManager } from '@/tt-app-modular'
import { OPERATION_CENTERS } from '@/tt-entity-design/src/components/operation-centers/constants'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import OperationCentersEmployees from './OperationCentersEmployees.vue'
import OperationCentersRegions from './OperationCentersRegions.vue'
import OperationCentersActionBar from '@/tt-entity-design/src/components/operation-centers/OperationCentersActionBar.vue'
import { getAttr } from './utils'

export default {
  install(Vue) {
    addPreviewTab(OPERATION_CENTERS, {
      title: createI18nResourceKey(OPERATION_CENTERS, getAttr('operators')),
      is: OperationCentersEmployees,
    })

    addPreviewTab(OPERATION_CENTERS, {
      title: createI18nResourceKey(OPERATION_CENTERS, getAttr('regions')),
      is: OperationCentersRegions,
    })

    /**
     * To hide the default list of actions.
     * @TODO: find better way
     */
    modularManager.registerActionBar(OPERATION_CENTERS, {
      is: OperationCentersActionBar,
    })
  },
}
