<template>
  <div>
    <v-tabs v-model="tabIndex" class="mt-2" left slider-color="orange">
      <v-tab v-text="$t('assets.person.create')" />
      <v-tab v-text="$t('assets.person.import')" />
    </v-tabs>
    <div class="level1 tab-content-wrapper">
      <div v-if="tabIndex === 0" class="py-1">
        <GenericForm
          v-bind="formProps"
          @cancel="close"
          @close="close"
          @submit:error="onSubmitError"
          @submit:success="onSubmitSuccess"
        />
      </div>
      <div v-if="tabIndex === 1">
        <div style="min-height: 400px">
          <WidgetContainer :style="{ height: '500px' }">
            <WidgetFactory
              :widget="computedDataTable"
              :props-data="{ onRecordClick: null }"
            />
          </WidgetContainer>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

import GenericForm, {
  GenericFormError,
  GenericFormSuccess,
} from '@/tt-app-layout/components/GenericForm.vue'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { DataTableWidgetModel } from '@/tt-widget-components'
import { DialogFormInterface, LayoutWindowEvent } from '@/tt-app-layout'

export default Vue.extend({
  name: 'AssetPersonCreate',
  components: { GenericForm },
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
      search: null,
    }
  },
  computed: {
    computedDataTable(): DataTableWidgetModel {
      const account = this.$appContext.contextManager.getContextFilter(
        AttributeName.ACCOUNT_ATTRIBUTE,
      ).value

      const title = this.$t('assets.contacts.import')

      const widget = {
        is: 'DataTableWidget',
        title,
        query: {
          resource: 'asset-contacts',
          customFilters: [
            {
              filterName: 'notExportedToAssetAccount',
              value: account,
            },
          ],
        },
        toolbar: {
          show: true,
          displayCounts: true,
          filterOptions: {
            allowSearch: true,
            filters: [
              {
                attributeName: 'type',
              },
            ],
          },
        },
        allowActions: true,
      } as DataTableWidgetModel

      return widget
    },
    formProps(): DialogFormInterface {
      const newPayload: DialogFormInterface = cloneDeep(this.payload)
      newPayload.title = ''
      newPayload.formOptions.definitions['AssetPersonsPost'].view.is =
        'AssetPersonsForm'

      return newPayload
    },
  },
  methods: {
    close(): void {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_CLOSE,
      )
    },
    onSubmitError({ data, error }: GenericFormError): void {
      if (this.payload.error) {
        this.payload.error(error, data)
      }
    },
    onSubmitSuccess({ data, response }: GenericFormSuccess): void {
      if (this.payload.success) {
        this.payload.success(data, response)
      }
      this.close()
    },
  },
})
</script>

<style scoped>
.tab-content-wrapper {
  min-height: 404px;
}
</style>
