<template>
  <tt-attr v-slot="{ rawValue }" :name="attributeName">
    <v-chip
      small
      class="opacity-blended"
      link
      :color="rawValue.backgroundColor"
      :text-color="rawValue.textColor"
    >
      <span class="workflow-status-name text-overline">
        {{ rawValue.name }}
      </span>
    </v-chip>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'WorkflowStatusName',
  props: {
    attributeName: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped>
.workflow-status-name {
  display: block;
  width: 100%;
  font-size: 0.8em !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
