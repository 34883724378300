// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowsTile from './WorkflowsTile.vue'
import WorkflowsColumns from './WorkflowsColumns'
import WorkflowsDetail from './WorkflowsDetail.vue'
import WorkflowsMetaCard from './WorkflowsMetaCard.vue'
import WorkflowsReference from './WorkflowsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowsTile', WorkflowsTile)
    Vue.component('WorkflowsDetail', WorkflowsDetail)
    Vue.component('WorkflowsMetaCard', WorkflowsMetaCard)
    Vue.component('WorkflowsReference', WorkflowsReference)

    modularManager.addResourcePreset(
      Resources.WORKFLOWS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.WORKFLOWS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: WorkflowsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.WORKFLOWS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOWS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOWS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
