import { ObservabilityAnalytics } from '@tracktik/tt-observability'

export enum Categories {
  Authentication = 'Authentication',
  Dashboard = 'Dashboard',
  Widget = 'Widget',
  TQL = 'TQL',
  Profile = 'Profile',
}

export const ExportTQL = ObservabilityAnalytics.createEventBuilder({
  action: 'Choose TQL Export',
  category: Categories.TQL,
})
