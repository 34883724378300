<template>
  <tt-attr-row
    name="location"
    label="tt-entity-design.dispatch-tasks-detail.attrs.location"
  >
    <span v-if="hasAddress">
      <tt-attr name="location.addressLine1" hide-empty-value />
      <tt-attr name="location.addressLine2" hide-empty-value />
      <tt-attr name="location.city" hide-empty-value />
      <tt-attr name="location.postalCode" hide-empty-value />
      <tt-attr name="location.state" hide-empty-value />
      <tt-attr name="location.country" hide-empty-value />
    </span>
    <span v-else>
      <!-- force the "NA" box -->
      <tt-attr name="location.addressLine1" />
    </span>
  </tt-attr-row>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTaskLocationField',
  inject: ['getItemHook'],
  computed: {
    hasAddress(): boolean {
      if (!this.getItemHook().data.location) {
        return false
      }

      return (
        this.getItemHook().data.location.addressLine1 ||
        this.getItemHook().data.location.addressLine2 ||
        this.getItemHook().data.location.city ||
        this.getItemHook().data.location.postalCode ||
        this.getItemHook().data.location.state ||
        this.getItemHook().data.location.country
      )
    },
  },
  created() {
    this.getItemHook().addAttribute('location.addressLine1')
    this.getItemHook().addAttribute('location.addressLine2')
    this.getItemHook().addAttribute('location.city')
    this.getItemHook().addAttribute('location.postalCode')
    this.getItemHook().addAttribute('location.state')
    this.getItemHook().addAttribute('location.country')
  },
})
</script>
