<template>
  <v-card flat>
    <EscalationRulesField />
    <TicketTypesSection />
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'

import EscalationRulesField from './EscalationRulesField.vue'
import TicketTypesSection from './TicketTypesSection.vue'

export default Vue.extend({
  name: 'OperationCenterEscalationPoliciesForm',
  components: {
    EscalationRulesField,
    TicketTypesSection,
  },
})
</script>
