import Vue from 'vue'

import { LayoutExtensionSlots, modularManager } from '@/tt-app-modular'

import CollapsibleHeader from './components/CollapsibleHeader.vue'
import EntityAbsolutePositionView from './components/EntityAbsolutePositionView.vue'
import EntityActions from './components/EntityActions.vue'
import EntityActionsAsList from './components/EntityActionsAsList.vue'
import ResourceAllowedOperations from './components/ResourceAllowedOperations.vue'
import EntityAttributeBanner from './components/EntityAttributeBanner.vue'
import EntityAttributeIf from './components/EntityAttributeIf.vue'
import EntityAttributeLabel from './components/EntityAttributeLabel.vue'
import EntityAttributeRow from './components/EntityAttributeRow.vue'
import EntityAttributeRowCard from './components/EntityAttributeRowCard.vue'
import EntityCollection from './components/EntityCollection.vue'
import EntityCollectionsActions from './components/EntityCollectionsActions.vue'
import EntityDetailCard from './components/EntityDetailCard.vue'
import EntityDocumentButton from './components/EntityDocumentButton.vue'
import EntityImage from './components/EntityImage.vue'
import EntityItem from './components/EntityItem.vue'
import EntityItemUnavailable from './components/EntityItemUnavailable.vue'
import EntityItemWidget from './components/EntityItemWidget.vue'
import EntityLooper from './components/EntityLooper.vue'
import EntityPresetView from './components/EntityPresetView.vue'
import EntityPreview from './components/EntityPreview.vue'
import EntityRelationPreview from './components/EntityRelationPreview.vue'
import EntityRelationListPreview from './components/EntityRelationListPreview.vue'
import EntityRelationPreviewDispatcher from './components/EntityRelationPreviewDispatcher.vue'
import EntityRelationValue from './components/EntityRelationValue.vue'
import EntityRelationListValue from './components/EntityRelationListValue.vue'
import EntityTile from './components/EntityTile.vue'
import EntityValue from './components/EntityValue.vue'
import EntityValuePreload from './components/EntityValuePreload.vue'
import EntityView from './components/EntityView.vue'
import EntityViewAttributeCollector from './components/EntityViewAttributeCollector.vue'
import Localized from './components/Localized.vue'
import ProvideItemHook from './components/ProvideItemHook.vue'
import ResourceList from './components/ResourceList.vue'
import TimeStampConvert from './components/TimeStampConvert.vue'
import WidgetDialog from './components/WidgetDialog.vue'
import EntityCheckbox from './components/EntityCheckbox.vue'

export * from './intents'

export function installWidgetEntityFlowComponents() {
  Vue.component('ResourceList', ResourceList)
  Vue.component('TtAttrRow', EntityAttributeRow)
  Vue.component('EntityAbsolutePositionView', EntityAbsolutePositionView)
  Vue.component('TtAttrRowCard', EntityAttributeRowCard)
  Vue.component('TtAttr', EntityValue)
  Vue.component('TtAttrCheckbox', EntityCheckbox)
  Vue.component('TtAttrImg', EntityImage)
  Vue.component('TtPresetView', EntityPresetView)
  Vue.component('TtTile', EntityTile)
  Vue.component('TtAttrLabel', EntityAttributeLabel)
  Vue.component('TtIfAttr', EntityAttributeIf)
  Vue.component('TtAttrBanner', EntityAttributeBanner)
  Vue.component('TtAttrRelation', EntityRelationValue)
  Vue.component('TtAttrRelationList', EntityRelationListValue)
  Vue.component(
    'TtAttrRelationPreviewDispatcher',
    EntityRelationPreviewDispatcher,
  )
  Vue.component('TtAttrAction', EntityCollectionsActions)
  Vue.component('CollapsibleHeader', CollapsibleHeader)
  Vue.component('EntityItemWidget', EntityItemWidget)
  Vue.component('EntityItem', EntityItem)
  Vue.component('EntityItemUnavailable', EntityItemUnavailable)
  Vue.component('ProvideItemHook', ProvideItemHook)
  Vue.component('Localized', Localized)
  Vue.component('EntityView', EntityView)
  Vue.component('EntityDetailCard', EntityDetailCard)
  Vue.component('TimeStampConvert', TimeStampConvert)
  Vue.component('EntityActions', EntityActions)
  Vue.component('EntityActionsAsList', EntityActionsAsList)
  Vue.component('EntityViewAttributeCollector', EntityViewAttributeCollector)
  Vue.component('EntityCollection', EntityCollection)
  Vue.component('EntityPreview', EntityPreview)
  Vue.component('EntityRelationPreview', EntityRelationPreview)
  Vue.component('EntityRelationListPreview', EntityRelationListPreview)
  Vue.component('EntityLooper', EntityLooper)
  Vue.component('EntityValuePreload', EntityValuePreload)
  Vue.component('WidgetDialog', WidgetDialog)
  Vue.component('EntityDocumentButton', EntityDocumentButton)
  Vue.component('ResourceAllowedOperations', ResourceAllowedOperations)

  modularManager.registerExtension({
    slots: [LayoutExtensionSlots.BODY],
    enabled: true,
    name: 'EntityAbsolutePositionView',
    component: { is: EntityAbsolutePositionView },
  })
}
