import {
  EntityCreateIntent,
  EntityCreateIntentInterface,
  EntityEditIntent,
  EntityEditIntentInterface,
} from '@/tt-widget-entity-flow'
import { AppContext } from '@/tt-app-context'

export const getCreateFormState = async (
  appContext: AppContext,
  formBuilderOptions: EntityCreateIntentInterface,
) => {
  const builder = await new EntityCreateIntent(appContext).getDialogFormBuilder(
    formBuilderOptions,
  )

  return builder.getState()
}

export const getEditFormState = async (
  appContext: AppContext,
  formBuilderOptions: EntityEditIntentInterface,
) => {
  const builder = await new EntityEditIntent(appContext).getDialogFormBuilder(
    formBuilderOptions,
  )

  return builder.getState()
}
