<template>
  <div>
    <v-card v-if="initialPreview" class="mb-3">
      <v-btn
        icon
        style="position: absolute; right: 0"
        elevation="6"
        color="red"
        @click="clear"
      >
        <v-icon v-text="`mdi-close-circle-outline`" />
      </v-btn>
      <img :src="initialPreview" width="100%" />
    </v-card>

    <ImageField v-show="!initialPreview" v-model="model" v-bind="$attrs" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import ImageField from '@/tt-entity-forms/components/ImageField.vue'
import { ImageHandlerProvider } from '@/tt-fieldset-builder/image-handler/types'

type Value = number | string | null

const VueWithImageUrl = Vue as VueConstructor<Vue & ImageHandlerProvider>

export default VueWithImageUrl.extend({
  name: 'CustomFieldPicture',
  components: { ImageField },
  inject: ['imageHandler'],
  props: {
    background: {
      type: String,
      default: undefined,
    },
    preview: {
      type: String,
      default: '',
    },
    uploadDeactivated: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String] as PropType<Value>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    initialPreview: null,
  }),
  computed: {
    model: {
      get(): Value {
        return this.value
      },
      set(newValue: Value) {
        this.$emit('input', newValue)
      },
    },
  },
  created() {
    this.initialPreview = this.imageHandler().getImageUrl(this.value)
  },
  methods: {
    clear() {
      this.initialPreview = null
      this.model = null
    },
  },
})
</script>
