<template>
  <div>
    <t-alert v-if="!hasCustomFields" :text="noCustomFieldValuesMessage" />
    <template v-else>
      <div
        v-for="customField in customFields"
        :key="customField.uid"
        class="tt-field text-caption py-1"
      >
        <v-row no-gutters>
          <!-- label -->
          <v-col
            class="text-truncate grey--text darken-1"
            cols="12"
            sm="4"
            :title="customField.label"
          >
            <span>{{ customField.label }}:</span>
          </v-col>
          <!-- / label -->

          <!-- value -->
          <v-col
            class="text-truncate pl-2"
            cols="12"
            sm="8"
            :title="formatValue(customField)"
          >
            <template v-if="shouldFormatValue(customField)">
              <!-- if it's an image, renders a link o open the image on a new tab -->
              <template v-if="isImage(customField.type)">
                <v-icon small>mdi-magnify</v-icon>
                <a :href="customField.value" target="_blank" @click.stop>
                  <span
                    v-text="
                      $t('tt-entity-design.asset-hub.asset.logs.view-image')
                    "
                  />
                </a>
              </template>
              <template v-else>
                <span>{{ formatValue(customField) }}</span>
              </template>
            </template>
            <v-chip
              v-else
              x-small
              label
              text-color="grey"
              v-text="$t('attribute.none')"
            />
          </v-col>
          <!-- / value -->
        </v-row>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { CustomAttributeDisplay } from '@/tt-entity-design/src/components/assets/types'
import { FieldName } from '@/tt-fieldset-builder/base/types'

import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import dateFormatter from '@/helpers/formats/dates/dates'
import { FormatterOutput } from '@/helpers/formats/types'
import TAlert from './TAlert.vue'

export default Vue.extend({
  name: 'TCustomFieldsDetails',
  components: { TAlert },
  props: {
    customFields: {
      type: Array as PropType<CustomAttributeDisplay[]>,
      required: true,
    },
  },
  computed: {
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    hasCustomFields(): boolean {
      return this.customFields?.length > 0
    },
    noCustomFieldValuesMessage(): string {
      return this.$t('common.custom-fields.no-custom-fields-values')
    },
  },
  methods: {
    isImage(fieldType: FieldName): boolean {
      return [FieldName.Picture, FieldName.Signature].includes(fieldType)
    },
    formatValue(field: CustomAttributeDisplay): FormatterOutput {
      const { type, value } = field
      // force parse to string
      const parsedValue = value.toString()

      switch (type) {
        case FieldName.Checkbox: {
          return this.$t(
            value
              ? `common.friendly-booleans.${value}`
              : 'common.friendly-booleans.false',
          )
        }
        case FieldName.Date: {
          return dateFormatter.asDate(parsedValue, this.userPreferences)
        }
        case FieldName.DateTime: {
          return dateFormatter.asDateTime(parsedValue, this.userPreferences)
        }
        default:
          return parsedValue
      }
    },
    shouldFormatValue(field: CustomAttributeDisplay): boolean {
      const { type, value } = field

      /**
       * Even if there's no value, when it's a checkbox
       * we should return a friendly feedback to the user
       */
      if (type === FieldName.Checkbox) return true

      return !!value
    },
  },
})
</script>

<style lang="scss" scoped>
.tt-field {
  & + .tt-field {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
