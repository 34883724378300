// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PeriodicSchedulesTile from './PeriodicSchedulesTile.vue'
import PeriodicSchedulesColumns from './PeriodicSchedulesColumns'
import PeriodicSchedulesDetail from './PeriodicSchedulesDetail.vue'
import PeriodicSchedulesMetaCard from './PeriodicSchedulesMetaCard.vue'
import PeriodicSchedulesReference from './PeriodicSchedulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PeriodicSchedulesTile', PeriodicSchedulesTile)
    Vue.component('PeriodicSchedulesDetail', PeriodicSchedulesDetail)
    Vue.component('PeriodicSchedulesMetaCard', PeriodicSchedulesMetaCard)
    Vue.component('PeriodicSchedulesReference', PeriodicSchedulesReference)

    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PeriodicSchedulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PERIODIC_SCHEDULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PeriodicSchedulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
