<template>
  <v-skeleton-loader v-if="!itemHook.hasFetched" type="heading" />

  <v-expand-transition v-else>
    <div class="main-wrapper">
      <v-switch
        v-model="sendAll"
        inset
        :label="getLabel('sendAll')"
        color="success"
      />

      <v-expand-transition>
        <div v-if="!sendAll">
          <v-divider key="1" />
          <v-tabs key="2" v-model="activeTab" fixed-tabs show-arrows>
            <v-tab v-for="({ title, key }, index) in tabs" :key="key">
              <span
                style="font-size: 0.9em"
                :style="activeTab !== index ? 'opacity: 0.7;' : ''"
                v-text="title"
              />

              <v-chip
                x-small
                color="primary"
                class="ml-1"
                :disabled="activeTab !== index"
                :outlined="activeTab !== index"
                v-text="getFormRelationListCount(key)"
              />
            </v-tab>
          </v-tabs>

          <v-tabs-items key="3" v-model="activeTab" style="height: 400px">
            <v-tab-item
              v-for="({ widget, key }, index) in tabs"
              :key="key"
              class="fill-height"
            >
              <template v-if="activeTab === index">
                <keep-alive>
                  <WidgetFactory
                    :widget="widget"
                    :selected-records="getFormRelationList(key)"
                    @selection-updated="setFormRelationList(key, $event)"
                  />
                </keep-alive>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-expand-transition>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { Resources } from '../../types'

import {
  createWidget,
  Tab,
  FORM_KEYS,
  FormRelationList,
  ACTION_FILTERS,
  EXTENSION_FILTERS,
} from './FilterRuleSetAssetsFilters.util'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'FilterRuleSetAssetsFilters',
  inject: ['formHook'],
  data: () => ({
    activeTab: 0,
    loading: true,
  }),
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    entityAccount(): number {
      return this.itemHook.getRawValue('account')
    },
    sendAll: {
      get(): boolean {
        return this.formHook().getPathValue('sendAll')
      },
      set(newValue = false) {
        this.formHook().setObjectValue('sendAll', newValue)
      },
    },
    tabs(): Tab[] {
      return [
        {
          key: 'categories',
          title: this.getLabel('categories'),
          widget: createWidget(Resources.ASSET_CATEGORIES, this.entityAccount),
        },
        {
          key: 'types',
          title: this.getLabel('types'),
          widget: createWidget(Resources.ASSET_TYPES, this.entityAccount),
        },
        {
          key: 'assets',
          title: this.getLabel('assets'),
          widget: createWidget(Resources.ASSETS, this.entityAccount),
        },
      ]
    },
  },
  watch: {
    'itemHook.hasFetched': {
      handler(hasFetched) {
        if (hasFetched) {
          FORM_KEYS.forEach((key) => this.setFormWithExtension(key))
          this.loading = false
        }
      },
    },
  },
  created() {
    this.itemHook.addAttribute('account')
    this.itemHook.addAttribute(`extensions.${EXTENSION_FILTERS}`)
  },
  methods: {
    setFormWithExtension(key) {
      const entityValue =
        this.itemHook.getRawValue(`${EXTENSION_FILTERS}.${key}`) || []
      const defaultFormValue = this.formHook().getPathValue(key)

      this.formHook().setObjectValue(key, entityValue ?? defaultFormValue)
    },
    getFormRelationList(key: FormRelationList): number[] {
      return this.formHook().getPathValue(key)
    },
    getFormRelationListCount(key: FormRelationList): number {
      return this.getFormRelationList(key)?.length || 0
    },
    setFormRelationList(key: FormRelationList, value: number[]) {
      this.formHook().setObjectValue(key, value || [])
    },
    getLabel(key: string) {
      return ResourceTranslator.translateActionAttribute(
        Resources.FILTER_RULE_SET_ASSETS,
        ACTION_FILTERS,
        key,
        FormLabelTypes.LABEL,
      )
    },
  },
})
</script>

<style scoped>
.main-wrapper {
  max-height: 600px;
  display: flex;
  flex-direction: column;
}
</style>
