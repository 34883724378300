// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MasterServiceTypesTile from './MasterServiceTypesTile.vue'
import MasterServiceTypesColumns from './MasterServiceTypesColumns'
import MasterServiceTypesDetail from './MasterServiceTypesDetail.vue'
import MasterServiceTypesMetaCard from './MasterServiceTypesMetaCard.vue'
import MasterServiceTypesReference from './MasterServiceTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MasterServiceTypesTile', MasterServiceTypesTile)
    Vue.component('MasterServiceTypesDetail', MasterServiceTypesDetail)
    Vue.component('MasterServiceTypesMetaCard', MasterServiceTypesMetaCard)
    Vue.component('MasterServiceTypesReference', MasterServiceTypesReference)

    modularManager.addResourcePreset(
      Resources.MASTER_SERVICE_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MasterServiceTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MASTER_SERVICE_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MasterServiceTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MASTER_SERVICE_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MasterServiceTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MASTER_SERVICE_TYPES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MasterServiceTypesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MASTER_SERVICE_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MasterServiceTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
