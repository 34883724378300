<template>
  <v-timeline class="w-100 pr-2 pt-0" :class="extraClasses" dense>
    <v-timeline-item class="w-100 pb-0">
      <template #icon>
        <span v-text="formattedIndex" />
      </template>

      <template #default>
        <slot />
      </template>
    </v-timeline-item>
  </v-timeline>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

const FIRST_ITEM_CLASS = 'timeline--first'

const STATUS_CLASSES = {
  COMPLETED: 'timeline--completed',
  ACTIVE: 'timeline--active',
  ERROR: 'timeline--error',
  DEFAULT: '',
}

export type TimelineItemStatus = keyof typeof STATUS_CLASSES

export default Vue.extend({
  name: 'TTimelineItem',
  props: {
    /**
     * The item index in the list.
     */
    index: {
      type: Number,
      default: null,
    },
    status: {
      type: String as PropType<TimelineItemStatus>,
      default: 'DEFAULT',
    },
  },
  computed: {
    formattedIndex(): string {
      return this.index !== null ? `${this.index + 1}` : ''
    },
    extraClasses(): string[] {
      const statusClass = STATUS_CLASSES[this.status]
      const firstItemClass = this.index === 0 ? [FIRST_ITEM_CLASS] : []

      return [...firstItemClass, statusClass]
    },
  },
})
</script>

<style lang="scss" scoped>
.v-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  width: 60px;
  &:before {
    top: -50%;
    left: 46px !important;
    background: transparent !important;
    border-left: 4px solid var(--v-bg-grey4-darken1);
  }
  ::v-deep {
    .v-timeline-item__divider {
      .v-timeline-item__dot {
        box-shadow: none;
        border-radius: 10%;
        .v-timeline-item__inner-dot {
          border-radius: 10%;
          background-color: var(--v-bg-grey4-base) !important;
          color: var(--v-bg-grey4-darken4) !important;
        }
        .primary {
          color: 'transparent';
        }
      }
    }
    .timeline-tile-action-dots {
      display: none;
    }
    &:hover {
      margin-right: 0px;
      .timeline-tile-action-dots {
        display: block;
      }
    }
  }

  &.timeline--first {
    &:before {
      display: none;
    }
  }

  &.timeline--active {
    ::v-deep {
      .v-timeline-item__divider {
        .v-timeline-item__dot {
          .v-timeline-item__inner-dot {
            color: var(--v-ttPrimary-lighten5) !important;
            background-color: var(--v-ttPrimary-base) !important;
          }
        }
      }
    }
    &:before {
      border-left: 4px dashed var(--v-ttPrimary-lighten4);
    }
  }

  &.timeline--completed {
    ::v-deep {
      .v-timeline-item__divider {
        .v-timeline-item__dot {
          .v-timeline-item__inner-dot {
            color: var(--v-success-base) !important;
            background-color: #e8f5e9 !important;
          }
        }
      }
    }
    &:before {
      border-left: 4px solid var(--v-success-base);
    }
  }

  &.timeline--error {
    ::v-deep {
      .v-timeline-item__divider {
        .v-timeline-item__dot {
          .v-timeline-item__inner-dot {
            color: var(--v-error-base) !important;
            background-color: var(--v-error-lighten5) !important;
          }
        }
      }
    }
  }
}
.theme--dark {
  .v-timeline {
    ::v-deep {
      .v-timeline-item__divider {
        .v-timeline-item__dot {
          background-color: var(--v-primary-base) !important;
          .v-timeline-item__inner-dot {
            color: white !important;
          }
        }
      }
      &:hover {
        .v-timeline-item__dot {
          background-color: var(--v-primary-lighten2) !important;
        }
      }
    }
  }
}
</style>
