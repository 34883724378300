import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const breakRulesPositionsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.POSITIONS,
      },
    }

    const breakRulesPositions: PreviewTabInterface = {
      title: createI18nPluralResourceKey('positions'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'breakRule.id',
        widget: breakRulesPositionsListWidget,
      },
    }

    const breakRulesConditionsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.BREAK_RULE_CONDITIONS,
      },
    }

    const breakRulesConditions: PreviewTabInterface = {
      title: createI18nPluralResourceKey('break-rule-conditions'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'breakRule.id',
        widget: breakRulesConditionsListWidget,
      },
    }
    addPreviewTab(Resources.BREAK_RULES, breakRulesPositions)
    addPreviewTab(Resources.BREAK_RULES, breakRulesConditions)
  },
}
