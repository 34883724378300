import hook from './VimeoWidgetHook'
import template from './VimeoWidget.vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const VimeoWidget: WidgetTypeDefinition = {
  name: WidgetName.VIMEO_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  config: {
    icon: 'mdi-vimeo',
    color: 'blue lighten-2',
    thumbnail: require('./vimeo.png'),
  },
}

export default VimeoWidget
