import hook from '../../base/DataSetWidgetHookChart'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import LineChartWidgetEditor from './LineChartWidgetEditor.vue'
import Vue from 'vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "LineChartWidget" */ './LineChartWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('LineChartWidgetEditor', LineChartWidgetEditor)

const LineChartWidget: WidgetTypeDefinition = {
  name: WidgetName.LINE_CHART_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    LineChartWidget: {
      view: {
        is: 'LineChartWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-chart-line',
    color: '#ff7d22',
    thumbnail: require('./line-chart.png'),
    print: true,
  },
  ttcIgnoredAttrs: ['dataSet.sort'],
}

export default LineChartWidget
