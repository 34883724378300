import {
  ContactType,
  ContactApiType,
  RegionContactType,
} from '@/tt-cta-contacts/types'

/**
 * Format the API contact object to the correct type.
 * @param contact
 */
export function formatContactObject(
  contact: ContactApiType,
): RegionContactType | ContactType {
  const isNameEmpty = !contact.firstName && !contact.lastName

  if (isNameEmpty) {
    return {
      description: contact.jobTitle,
      phone: contact.primaryPhone,
    } as RegionContactType
  } else {
    return {
      name: `${contact.firstName} ${contact.lastName}`,
      title: contact.jobTitle,
      phone: contact.primaryPhone,
    } as ContactType
  }
}
