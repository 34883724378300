// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractBillingCalendarsTile from './ContractBillingCalendarsTile.vue'
import ContractBillingCalendarsColumns from './ContractBillingCalendarsColumns'
import ContractBillingCalendarsDetail from './ContractBillingCalendarsDetail.vue'
import ContractBillingCalendarsReference from './ContractBillingCalendarsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContractBillingCalendarsTile', ContractBillingCalendarsTile)
    Vue.component(
      'ContractBillingCalendarsDetail',
      ContractBillingCalendarsDetail,
    )
    Vue.component(
      'ContractBillingCalendarsReference',
      ContractBillingCalendarsReference,
    )

    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDARS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDARS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractBillingCalendarsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDARS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDARS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
