<template>
  <v-container class="d-flex justify-center">
    <v-checkbox v-model="model" :label="scopeLabel"></v-checkbox>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ScopeFilterField',
  props: {
    scopeLabel: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit('change', value)
      },
    },
  },
})
</script>
