<template>
  <tt-attr :name="name" :value-css-class="cssClass" ignore-attribute-view />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'InvoicesStatusAttr',
  inject: ['getItemHook'],
  data() {
    return {
      name: 'status',
      colorMap: {
        APPROVED: 'green',
        DRAFT: 'grey',
      },
      defaultValue: 'grey',
    }
  },
  computed: {
    cssClass(): string {
      const value = this.getItemHook().getRawValue(this.name)
      const color = Object.keys(this.colorMap).includes(value)
        ? this.colorMap[value]
        : this.defaultValue

      return `color-pill ${color}`
    },
  },
})
</script>
