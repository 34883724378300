// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AccountKpisTile from './AccountKpisTile.vue'
import AccountKpisColumns from './AccountKpisColumns'
import AccountKpisDetail from './AccountKpisDetail.vue'
import AccountKpisReference from './AccountKpisReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AccountKpisTile', AccountKpisTile)
    Vue.component('AccountKpisDetail', AccountKpisDetail)
    Vue.component('AccountKpisReference', AccountKpisReference)

    modularManager.addResourcePreset(
      Resources.ACCOUNT_KPIS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AccountKpisTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ACCOUNT_KPIS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AccountKpisColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ACCOUNT_KPIS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AccountKpisDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ACCOUNT_KPIS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AccountKpisReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
