// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OperationCenterEscalationPoliciesTile from './OperationCenterEscalationPoliciesTile.vue'
import OperationCenterEscalationPoliciesColumns from './OperationCenterEscalationPoliciesColumns'
import OperationCenterEscalationPoliciesDetail from './OperationCenterEscalationPoliciesDetail.vue'
import OperationCenterEscalationPoliciesMetaCard from './OperationCenterEscalationPoliciesMetaCard.vue'
import OperationCenterEscalationPoliciesReference from './OperationCenterEscalationPoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'OperationCenterEscalationPoliciesTile',
      OperationCenterEscalationPoliciesTile,
    )
    Vue.component(
      'OperationCenterEscalationPoliciesDetail',
      OperationCenterEscalationPoliciesDetail,
    )
    Vue.component(
      'OperationCenterEscalationPoliciesMetaCard',
      OperationCenterEscalationPoliciesMetaCard,
    )
    Vue.component(
      'OperationCenterEscalationPoliciesReference',
      OperationCenterEscalationPoliciesReference,
    )

    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationPoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OperationCenterEscalationPoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationPoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationPoliciesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationPoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
