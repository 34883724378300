import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { ConflictsStatusColors } from '../../types'
import { Resources } from '@/tt-entity-design/src/types'

export const HumanCapitalManagementEmployeeConflictsColumns: ColumnInputDefinition[] =
  [
    {
      attributeName: 'id',
      style: {
        width: 100,
      },
    },
    {
      attributeName: 'account',
      style: {
        width: 200,
      },
    },
    {
      attributeName: 'conflictCategory',
      style: {
        width: 200,
      },
    },
    {
      attributeName: 'conflictType',
      style: {
        width: 300,
      },
    },
    {
      headerText: 'res.leave-requests.attr.status.label',
      component: {
        is: 'StatusChip',
        props: {
          statusColorOptions: ConflictsStatusColors,
          resource: Resources.CONFLICTS,
        },
      },
      style: {
        width: 150,
      },
    },
    'conflictInfo',
  ]
