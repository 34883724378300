<template>
  <div v-if="loading">
    <v-skeleton-loader type="text, text, sentences, sentences" />
  </div>
  <div v-else>
    <div v-if="affectedShiftsTotal">
      <json-field name="toOpenShifts" as="BooleanList" />
      <v-divider v-if="showDivider" class="pb-5" />
    </div>
    <TAlert
      v-for="(alert, key) in alerts"
      :key="alert"
      :text="alert"
      :class="{ 'mt-4': key === 1 }"
    />
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { Filter } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '../../types'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { isDateInFuture } from '@/helpers/dates/isDateInFuture'
import { LeavePolicy } from './types'
import {
  LeavePolicyItem,
  LeaveRequestProvider,
} from '@/tt-widget-views/leave-management/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

type VueWithInjections = VueConstructor<Vue & LeaveRequestProvider>

export default (Vue as VueWithInjections).extend({
  name: 'LeaveRequestsApproveForm',
  inject: {
    manageLRinPayroll: { default: false },
  },
  props: {
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      currentLeavePolicy: {} as LeavePolicy,
      availableBalance: 0,
    }
  },
  computed: {
    affectedShiftsTotal(): number {
      return this.itemHook.get('extensions.affectedShifts.total')
    },
    leaveTypeId(): number {
      return this.itemHook.get('leaveType.id')
    },
    employeeId(): number {
      return this.itemHook.get('employee.id')
    },
    createPTOMessage(): string {
      return 'tt-entity-design.leave-requests.create-pto-message'
    },
    exceedsBalanceMessage(): string {
      return 'tt-entity-design.leave-requests.exceeds-requested-days-message'
    },
    futurePeriodMessage(): string {
      return 'tt-entity-design.leave-requests.future-period-warning'
    },
    isRequestOutsideCurrentPeriod(): boolean {
      return isDateInFuture(
        this.itemHook.getRawValue('startedOn'),
        this.currentLeavePolicy.endPeriodDate,
      )
    },
    showDivider(): boolean {
      return !!this.alerts.length
    },
    alerts(): string[] {
      const messages = []

      if (!this.manageLRinPayroll) {
        messages.push(this.createPTOMessage)
      }

      if (this.isRequestOutsideCurrentPeriod) {
        messages.push(this.futurePeriodMessage)
      }

      if (this.availableBalance < 0 && !this.isRequestOutsideCurrentPeriod) {
        messages.push(this.exceedsBalanceMessage)
      }

      return messages
    },
    policyItemsEmployeeFilter(): Filter {
      return {
        attribute: 'employee',
        value: this.employeeId,
        operator: FilterOperatorType.EQUAL,
      }
    },
    policyItemsLeaveTypeFilter(): Filter {
      return {
        attribute: 'leaveType',
        value: this.leaveTypeId,
        operator: FilterOperatorType.EQUAL,
      }
    },
    policyItemFilters(): Filter[] {
      return [this.policyItemsEmployeeFilter, this.policyItemsLeaveTypeFilter]
    },
  },
  watch: {
    policyItemFilters() {
      this.loadPolicyInfo()
    },
  },
  created() {
    this.includeRequiredAttributes()
    this.loadPolicyInfo()
  },
  methods: {
    async loadPolicyInfo(): Promise<void> {
      try {
        if (!this.leaveTypeId || !this.employeeId) {
          this.loading = false

          return
        }
        const { currentLeavePolicy } = await this.fetchLeavePolicy()
        this.currentLeavePolicy = currentLeavePolicy

        const { items } = await this.fetchPolicyItem()
        this.availableBalance = items[0].balance.available

        this.loading = false
      } catch (error) {
        this.$crash.captureException(error)
        this.dispatchErrorMessage(error?.response?.data)
      }
    },
    fetchLeavePolicy(): Promise<{ currentLeavePolicy: LeavePolicy }> {
      return this.$auth.getApi().get(Resources.EMPLOYEES, this.employeeId, {
        include: ['currentLeavePolicy'],
      })
    },
    fetchPolicyItem(): Promise<EntityCollectionResponse<LeavePolicyItem>> {
      return this.$appContext.widgetServices.resourceDataManager.getCollection({
        resource: Resources.LEAVE_POLICY_ITEMS,
        filters: this.policyItemFilters,
        extension: ['balance'],
      })
    },
    includeRequiredAttributes() {
      this.itemHook.addAttribute('extensions.affectedShifts.total')
      this.itemHook.addAttribute('employee.id')
      this.itemHook.addAttribute('leaveType.id')
    },
    dispatchErrorMessage(message: string) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: message || this.$t('common.error_message'),
      })
    },
  },
})
</script>
