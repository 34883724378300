import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'account',
  'name',
  'billingStartDate',
  'billingFrequency',
  'billingFrequencyType',
  'paymentTerms',
  {
    attributeName: 'status',
    component: {
      is: 'StatusColor',
      props: {
        name: 'status',
        layout: 'CHIP',
      },
    },
  },
] as ColumnInputDefinition[]
