<template>
  <CollapsibleHeader v-if="showAffectedShifts" :opened="true">
    <template #title>
      <span
        class="pl-4 pr-2"
        v-text="$t('res.leave-requests.extensions.affectedShifts.labels.label')"
      />
      <LeaveRequestsAffectedShiftsCounter in-details />
    </template>
    <tt-attr-row-card>
      <tt-attr name="extensions.affectedShifts.shifts">
        <v-container>
          <v-row v-for="(shift, index) in shifts" :key="index">
            <v-col cols="auto" class="py-0 pl-0">
              <TListContentCard>
                <template #header>
                  <span class="text-center">
                    <div>
                      {{
                        formattedValue(
                          shift.startDateTime,
                          'asShortTimeWithoutTimezone',
                        )
                      }}
                      - {{ formattedValue(shift.endDateTime, 'asShortTime') }}
                    </div>
                  </span>
                </template>

                <template #sub-header>
                  <div class="text-caption">
                    <span class="text-caption">{{
                      formattedValue(shift.startDateTime, 'asDate')
                    }}</span>
                  </div>
                </template>
              </TListContentCard>
            </v-col>
            <v-col class="py-0 pr-0">
              <div v-if="shift.employee">
                <div
                  v-if="isShiftCoveredByAnotherEmployee(shift)"
                  class="d-flex align-center"
                >
                  <v-icon v-bind="iconAttrs" color="green">
                    mdi-account
                  </v-icon>
                  <span class="caption" v-text="coveredByLabel" />
                </div>
                <v-avatar size="15" class="mr-1">
                  <v-img :src="shift.employee.avatar" />
                </v-avatar>
                {{ shift.employee.name }}
              </div>
              <div v-else-if="shift.onShiftBoard">
                <v-icon v-bind="iconAttrs" color="red">
                  mdi-calendar-account
                </v-icon>
                <v-chip small class="opacity-blended">
                  {{ openShiftText }}
                </v-chip>
              </div>
              <div v-else-if="shift.vacant">
                <v-icon v-bind="iconAttrs" color="red">
                  mdi-account-off
                </v-icon>
                <v-chip small class="opacity-blended">{{ vacantText }}</v-chip>
              </div>
              <div class="text-caption">
                <v-icon v-bind="iconAttrs" color="orange">mdi-domain</v-icon
                >{{ shift.position.account.name }}
              </div>
              <div class="text-caption">
                <v-icon v-bind="iconAttrs" color="teal">mdi-grid-large</v-icon
                >{{ shift.position.name }}
              </div>
              <div class="py-3">
                <v-divider />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </tt-attr>
    </tt-attr-row-card>
  </CollapsibleHeader>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { FormatManager } from '@/helpers/formats'
import LeaveRequestsAffectedShiftsCounter from './LeaveRequestsAffectedShiftsCounter.vue'
import i18n from '@/plugins/i18n'
import { Shift } from './types'
import { FormatterOutput } from '@/helpers/formats/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsDetailAffectedShifts',
  components: { LeaveRequestsAffectedShiftsCounter },
  inject: ['getItemHook'],
  data() {
    return {
      iconAttrs: {
        'x-small': true,
        style: 'font-size:13px; opacity:0.8',
        class: 'mr-1',
      },
    }
  },
  computed: {
    shifts(): Shift[] {
      return this.getItemHook().get('extensions.affectedShifts.shifts')
    },
    shiftsTotal(): number {
      return this.getItemHook().get('extensions.affectedShifts.total')
    },
    showAffectedShifts(): boolean {
      return !!this.shiftsTotal
    },
    vacantText(): string {
      return this.$t('tt-entity-design.shifts.attrs.vacant.label')
    },
    openShiftText(): string {
      return this.$t('tt-entity-design.shifts.attrs.openShift')
    },
    leaveRequestEmployee(): number {
      return this.getItemHook().get('employee.id')
    },
    coveredByLabel(): string {
      return this.$t('tt-entity-design.shifts.coveredBy')
    },
  },
  methods: {
    formattedValue(val, format): FormatterOutput {
      return FormatManager.parse(format, val, {
        ...this.$appContext.authModule.getUserPreferences(),
        locale: i18n.locale,
      })
    },
    isShiftCoveredByAnotherEmployee(shift: Shift): boolean {
      return (
        this.leaveRequestEmployee &&
        this.leaveRequestEmployee !== shift.employee.id
      )
    },
  },
})
</script>
