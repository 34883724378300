import { ResourceSchema } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'
const { ACCOUNTS } = Resources

export type RegionResponse = {
  id: number
  emergencyContactNumber?: string
}

export type RegionContactType = {
  description: string
  phone: string
}

export type ContactType = {
  name: string
  title: string
  phone: string
}

export type ContactApiType = {
  firstName: string
  lastName: string
  jobTitle: string
  primaryPhone: string
}

type ContactApiObjectType = {
  contact: ContactApiType
}

type CustomZoneWithEmcType = {
  name: string
  emergencyContactAssignments: ContactApiObjectType[]
}

type CustomZoneType = {
  name: string
  contacts: ContactApiType[]
}

export type SiteZoneEmcContactResponseType = {
  zones: CustomZoneWithEmcType[]
}

export type SiteZoneContactResponseType = {
  zones: CustomZoneType[]
}

export type ContactApiArrayType = ContactApiObjectType[]

type AccountType = keyof ResourceSchema<
  typeof ACCOUNTS
>['attributes']['type']['labels']['list']

export const ZONE: AccountType = 'ZONE'
