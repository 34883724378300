<template>
  <v-alert
    v-if="showMessage"
    type="warning"
    color="orange darken-2"
    icon="mdi-alert"
    class="mb-5"
    text
  >
    <p class="font-weight-medium ma-0">
      {{ upcomingReservationsWarningTitle }}
    </p>
    <p class="mt-2 mb-0">{{ upcomingReservationsWarningMessage }}</p>
  </v-alert>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'

import dateFormatter from '@/helpers/formats/dates'
import { FormHookProvider } from '@/tt-widget-components'
import { FormatterInput, FormatterOutput } from '@/helpers/formats/types'

import { isDifferent } from './helpers'
import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'UpcomingReservationWarning',
  inject: ['formHook'],
  props: {
    personId: {
      type: Number,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMessage: false,
      startDateTime: null,
    }
  },
  computed: {
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    upcomingReservationsWarningTitle(): string {
      return this.$t(
        'asset-types.conflicting-reservations.upcoming-reservation',
      )
    },
    upcomingReservationsWarningMessage(): string {
      return this.$t(
        'asset-types.conflicting-reservations.upcoming-reservation-description',
        {
          assetName: this.assetName,
          startDateTime: this.startDateTime,
        },
      )
    },
  },
  watch: {
    personId(newValue, oldValue) {
      if (isDifferent(newValue, oldValue)) {
        this.checkAvailability(newValue)
      }
    },
  },
  created() {
    this.checkAvailability(this.personId)
  },
  methods: {
    hideConflictingReservationsMessage(): void {
      this.showMessage = false
    },
    formatDate(date: FormatterInput): FormatterOutput {
      return dateFormatter.asDateTime(date, this.userPreferences)
    },
    checkAvailability(personId: number): void {
      this.hideConflictingReservationsMessage()

      const api = this.$appContext.authModule.getApi()
      const filters = [
        {
          attribute: 'excludePersonReservations',
          operator: FilterOperatorType.EQUAL,
          value: personId,
        },
        {
          attribute: 'scope',
          operator: FilterOperatorType.EQUAL,
          value: 'IN_THE_NEAR_FUTURE',
        },
        {
          attribute: 'asset_id',
          operator: FilterOperatorType.EQUAL,
          value: this.assetId,
        },
        {
          attribute: 'limit',
          operator: FilterOperatorType.EQUAL,
          value: 1,
        },
      ]

      /**
       * Call the ASSET_RESERVATIONS resource and check if there are upcoming reservations
       * for this asset, but reserved for another user than the selected one.
       *
       * If we have an upcoming reservation soon, we warn the user that someone else have
       * a reservation.
       */
      api.getAll(Resources.ASSET_RESERVATIONS, { filters }).then((res: any) => {
        const { items } = res

        const hasItems = items.length > 0
        const reservationForCurrentAsset = items.filter(
          (item) => item.asset === this.assetId,
        )[0]

        // check both for items length and if any of the reservations returned are for the current item
        if (hasItems && !!reservationForCurrentAsset) {
          const { startDateTime } = reservationForCurrentAsset

          this.startDateTime = this.formatDate(startDateTime)
          this.showMessage = true
        }
      })
    },
  },
})
</script>
