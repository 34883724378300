<template>
  <div>
    <span v-if="isAccrued">-</span>
    <LeavePolicyItemsValueField v-else entitlement-value-name="quantity" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import LeavePolicyItemsValueField from '@/tt-entity-design/src/components/leave-policy-items/LeavePolicyItemsValueField.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyItemsQuantityColumn',
  components: { LeavePolicyItemsValueField },
  inject: ['getItemHook'],
  computed: {
    isAccrued(): boolean {
      return !!this.getItemHook().get('accrualRate')
    },
  },
  created() {
    this.getItemHook().addAttribute('accrualRate')
    this.getItemHook().addAttribute('quantity')
  },
})
</script>
