// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileScheduleOccurrencesTile from './MobileScheduleOccurrencesTile.vue'
import MobileScheduleOccurrencesColumns from './MobileScheduleOccurrencesColumns'
import MobileScheduleOccurrencesDetail from './MobileScheduleOccurrencesDetail.vue'
import MobileScheduleOccurrencesMetaCard from './MobileScheduleOccurrencesMetaCard.vue'
import MobileScheduleOccurrencesReference from './MobileScheduleOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileScheduleOccurrencesTile',
      MobileScheduleOccurrencesTile,
    )
    Vue.component(
      'MobileScheduleOccurrencesDetail',
      MobileScheduleOccurrencesDetail,
    )
    Vue.component(
      'MobileScheduleOccurrencesMetaCard',
      MobileScheduleOccurrencesMetaCard,
    )
    Vue.component(
      'MobileScheduleOccurrencesReference',
      MobileScheduleOccurrencesReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileScheduleOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
