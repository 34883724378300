import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'firstName',
  'lastName',
  'username',
  'primaryPhone',
  'email',
] as ColumnInputDefinition[]
