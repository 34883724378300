<template>
  <div>
    <p v-text="contentOutput" />
    <p
      v-if="!readMore && content.length > maxContentLength"
      class="blue--text pr-3"
      style="cursor: pointer; width: 100%; text-align: right"
      @click="readMore = true"
    >
      {{ i18n.t('tt-entity-design.read-more') }}
    </p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TruncateText',
  inject: ['i18n'],
  props: {
    content: {
      type: String,
      required: true,
    },
    maxContentLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      readMore: false,
    }
  },
  computed: {
    contentOutput(): string {
      if (!this.content) {
        return ''
      }

      if (this.content.length <= this.maxContentLength || this.readMore) {
        return this.content
      }

      return `${this.content.substr(0, this.maxContentLength)}...`
    },
  },
})
</script>
