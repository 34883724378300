<template>
  <div class="flex--column">
    <v-toolbar class="toolbar3 text-capitalize">
      <div class="d-flex align-center">
        <v-btn text class="px-2 text-capitalize" @click="backToHome">
          {{ previousPageTitle }}
        </v-btn>
        <v-icon size="18">keyboard_arrow_right</v-icon>
        <span v-if="!policyName && policyIdToEdit" class="toolbar3">
          <v-skeleton-loader type="table-cell" />
        </span>
        <span v-else class="px-2 font-weight-bold text-no-wrap">
          {{ currentTitle }}
        </span>
      </div>
      <v-divider v-if="!isMobile" class="mx-4 ml-8" inset vertical />
      <template v-if="isMobile" #extension>
        <v-tabs
          v-model="tab"
          slider-color="var(--v-ttPrimary-base)"
          show-arrows
          align-with-title
          centered
          next-icon="mdi-arrow-right"
          prev-icon="mdi-arrow-left"
        >
          <template v-for="(step, index) in steps">
            <v-tab
              :key="step.key"
              class="text-capitalize ml-4 mr-4"
              :disabled="step.disabled"
              @click="closeSidePanel"
            >
              <v-icon size="16" left :color="setStepIconColor(index)">
                {{ step.icon }}
              </v-icon>
              {{ $t(getStepTitle(step)) }}
            </v-tab>
            <v-icon
              v-if="!isLastTab(index)"
              :key="`arrow-${step.key}`"
              size="18"
            >
              keyboard_arrow_right
            </v-icon>
          </template>
        </v-tabs>
      </template>
      <v-tabs
        v-if="!isMobile"
        v-model="tab"
        slider-color="var(--v-ttPrimary-base)"
        style="min-width: 0"
        align-with-title
      >
        <template v-for="(step, index) in steps">
          <v-tab
            :key="step.key"
            class="text-capitalize ml-4 mr-4"
            :disabled="step.disabled"
            @click="closeSidePanel"
          >
            <v-icon size="16" left :color="setStepIconColor(index)">
              {{ step.icon }}
            </v-icon>
            {{ $t(getStepTitle(step)) }}
          </v-tab>
          <v-icon v-if="!isLastTab(index)" :key="`arrow-${step.key}`" size="18">
            keyboard_arrow_right
          </v-icon>
        </template>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="tab" class="flex--column">
      <v-tab-item key="basic-info" class="px-8">
        <CreatePolicyForm
          :step="steps[0]"
          :leave-policy-id="leavePolicyId"
          :translation-base="translationBase"
          :has-employees-assigned="hasEmployeesAssigned"
          @savePolicyAndContinue="savePolicyAndContinue"
          @policyName="getPolicyName"
          @nextStep="toNextTab"
        />
      </v-tab-item>
      <v-tab-item key="policy-items" active-class="flex--column">
        <AssignLeavePolicyItem
          :step="steps[1]"
          :leave-policy-id="leavePolicyId"
          :has-employees-assigned="hasEmployeesAssigned"
          @policyName="getPolicyName"
          @nextStep="toNextTab"
          @updatePolicyItems="updatePolicyItems"
        />
      </v-tab-item>
      <v-tab-item key="assigned-to" active-class="flex--column">
        <AssignEmployeesView
          :step="steps[2]"
          :leave-policy-id="leavePolicyId"
          :translation-base="translationBase"
          :has-policy-items-assigned="hasLeavePolicyItems"
          @policyName="getPolicyName"
          @nextStep="toNextTab"
          @goTo="goTo"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import CreatePolicyForm from './CreatePolicyForm.vue'
import AssignEmployeesView from './AssignEmployeesView.vue'
import AssignLeavePolicyItem from './assign-leave-policy-item/AssignLeavePolicyItem.vue'
import { LeavePolicyFormStep } from '../../types'
import { Resources } from '@/tt-entity-design/src/types'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { LayoutComponents } from '@/tt-app-layout'
import { LeavePolicyItem } from '../../types'
import { LeavePolicyApiResponse } from '@/tt-entity-design/src/components/leave-policies/types'

export default (
  Vue as VueConstructor<Vue & { layoutManager: LayoutManager }>
).extend({
  name: 'LeavePoliciesForm',
  components: { CreatePolicyForm, AssignLeavePolicyItem, AssignEmployeesView },
  inject: ['layoutManager'],
  props: {
    policyIdToEdit: {
      type: Number,
      default: null,
    },
    editStep: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      leavePolicyId: null,
      policyName: null,
      tab: 0,
      activeStep: 0,
      employeesAssigned: [],
      leavePolicyItems: [],
    }
  },
  computed: {
    currentTitle(): string {
      if (this.leavePolicyId) {
        return this.policyName
      } else {
        return this.$t(`${this.translationBase}.new-entity-default`)
      }
    },
    hasEmployeesAssigned(): boolean {
      return this.employeesAssigned.length > 0
    },
    hasLeavePolicyItems(): boolean {
      return this.leavePolicyItems.length > 0
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    previousPageTitle(): string {
      return this.$t(`${this.translationBase}.title`)
    },
    steps(): LeavePolicyFormStep[] {
      return [
        {
          key: 'basic-info',
          title: `${this.translationBase}.tabs.basic-info.subtitle`,
          icon: 'mdi-information-outline',
          disabled: false,
        },
        {
          key: 'policy-items',
          title: `${this.translationBase}.tabs.policy-items.subtitle`,
          icon: 'mdi-clock-outline',
          disabled: !this.leavePolicyId,
        },
        {
          key: 'assigned-to',
          title: `${this.translationBase}.tabs.assigned-to.subtitle`,
          icon: 'mdi-account-multiple',
          disabled: !this.hasLeavePolicyItems,
        },
      ]
    },
    translationBase(): string {
      return 'tt-entity-design.leave-management.leave-policies'
    },
  },
  created() {
    if (this.policyIdToEdit) {
      this.leavePolicyId = this.policyIdToEdit
      this.loadLeavePolicyInfo()
    }
    if (this.editStep) {
      this.tab = this.editStep
      this.activeStep = this.editStep
    }
  },
  methods: {
    loadLeavePolicyInfo() {
      this.$appContext.authModule
        .getApi()
        .get<number, LeavePolicyApiResponse>(
          Resources.LEAVE_POLICIES,
          this.leavePolicyId,
          {
            include: ['employees', 'leavePolicyItems'],
          },
        )
        .then((leavePolicyInfo) => {
          this.employeesAssigned = leavePolicyInfo.employees
          this.leavePolicyItems = leavePolicyInfo.leavePolicyItems
        })
    },
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
    getStepTitle(step: LeavePolicyFormStep): string {
      return `${this.translationBase}.tabs.${step.key}.title`
    },
    savePolicyAndContinue(id: number) {
      if (!this.leavePolicyId) this.leavePolicyId = id
      this.toNextTab()
    },
    toNextTab() {
      this.closeSidePanel()
      if (this.isLastTab(this.tab)) return this.$emit('back')
      this.tab++
      this.activeStep++
    },
    backToHome() {
      this.closeSidePanel()
      this.$emit('back')
    },
    goTo(tabKey: string) {
      this.tab = this.steps.findIndex((step) => {
        return step.key === tabKey
      })
    },
    setStepIconColor(index: number): string {
      if (index === this.tab) return 'var(--v-ttPrimary-base)'
      if (index < this.tab) return 'green'
    },
    isLastTab(index: number): boolean {
      return index === this.steps.length - 1
    },
    getPolicyName(name: string) {
      this.policyName = name
    },
    updatePolicyItems(policyItems: LeavePolicyItem[]) {
      this.leavePolicyItems = policyItems
    },
  },
})
</script>
