<template>
  <div class="d-flex" style="flex-wrap: wrap">
    <div style="flex: 1">
      <tt-tile v-bind="$props">
        <template #avatar>
          <SiteTaskOccurrenceStatus layout="AVATAR" />
        </template>

        <tt-attr slot="title" name="taskType.name" />
        <tt-attr
          slot="line2"
          class="site-task-description"
          name="description"
        />

        <template #line3>
          <SiteTaskOccurrenceStatus layout="CHIP" />
        </template>
      </tt-tile>
    </div>
    <div class="d-flex align-center pa-4">
      <v-btn color="green" dark depressed @click.stop="openPreview">
        <tt-attr-label name="schedule" :prefix="`${$t('common.view.btn')} `" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { EntityPreviewEditHandler } from '@/tt-widget-entity-flow/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import SiteTaskSchedulesCreateWizard from '@/tt-widget-views/site-tasks/components/SiteTaskSchedulesCreateWizard.vue'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'SiteTaskOccurrencesTile',
  inject: ['getItemHook'],
  methods: {
    openPreview() {
      const onEdit: EntityPreviewEditHandler = ({ itemHook }) => {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.FULL_PAGE_DIALOG, {
          title: this.$t('tt-site-tasks.schedules-form.edit.title'),
          is: SiteTaskSchedulesCreateWizard,
          props: {
            editingEntityId: itemHook.getEntityId(),
            clientId: itemHook.getRawValue('client.id'),
            entityDescription: itemHook.getRawValue('description'),
          },
        })
        this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_CLOSE, {})
      }

      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        entityId: this.getItemHook().getRawValue('schedule.id'),
        resourceName: Resources.SITE_TASK_SCHEDULES,
        hasToAddPages: true,
        onEdit,
      })
    },
  },
})
</script>

<style scoped>
.site-task-description >>> .entity-attribute-cell span {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
