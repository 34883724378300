// Enter your custom installation here
import MobileScheduleOccurrenceLogStatusAttr from './MobileScheduleOccurrenceLogStatusAttr.vue'

export default {
  install(Vue) {
    Vue.component(
      'MobileScheduleOccurrenceLogStatusAttr',
      MobileScheduleOccurrenceLogStatusAttr,
    )
  },
}
