// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeePaySettingsTile from './EmployeePaySettingsTile.vue'
import EmployeePaySettingsColumns from './EmployeePaySettingsColumns'
import EmployeePaySettingsDetail from './EmployeePaySettingsDetail.vue'
import EmployeePaySettingsMetaCard from './EmployeePaySettingsMetaCard.vue'
import EmployeePaySettingsReference from './EmployeePaySettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeePaySettingsTile', EmployeePaySettingsTile)
    Vue.component('EmployeePaySettingsDetail', EmployeePaySettingsDetail)
    Vue.component('EmployeePaySettingsMetaCard', EmployeePaySettingsMetaCard)
    Vue.component('EmployeePaySettingsReference', EmployeePaySettingsReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PAY_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeePaySettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PAY_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeePaySettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PAY_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeePaySettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PAY_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeePaySettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_PAY_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeePaySettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
