<template>
  <ProvideResource :resource="resource">
    <template>
      <slot :resource="resource"></slot>
    </template>
  </ProvideResource>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { TQLValidator } from '@/tt-tql-inputs/src/lang/validator'
import { lang } from '@/tt-tql-inputs/src/lang/types'
import { FormHookProvider } from '..'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ProvideDataSetResource',
  inject: ['formHook'],
  data() {
    return {
      validator: null as TQLValidator | null,
    }
  },
  computed: {
    tql(): any {
      return this.formHook().getPathValue('dataSet.tql')
    },
    resource(): any {
      return this.validator.getResource(this.tql)
    },
  },
  created() {
    this.validator = new TQLValidator(
      this.$appContext.widgetServices.resourceMetaManager,
      lang
    )
  },
})
</script>
