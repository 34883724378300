<template>
  <span>
    <tt-attr :prepend-icon="icon" :prepend-icon-color="iconColor" name="name" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue'

import {
  REPORT_TEMPLATE_FIELD_GROUP_ICON,
  REPORT_TEMPLATE_FIELD_GROUP_ICON_COLOR,
} from './constants'

export default Vue.extend({
  name: 'ReportTemplateFieldGroupsReference',
  computed: {
    icon(): string {
      return REPORT_TEMPLATE_FIELD_GROUP_ICON
    },
    iconColor(): string {
      return REPORT_TEMPLATE_FIELD_GROUP_ICON_COLOR
    },
  },
})
</script>
