// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerCheckInLogsTile from './LoneWorkerCheckInLogsTile.vue'
import LoneWorkerCheckInLogsColumns from './LoneWorkerCheckInLogsColumns'
import LoneWorkerCheckInLogsDetail from './LoneWorkerCheckInLogsDetail.vue'
import LoneWorkerCheckInLogsMetaCard from './LoneWorkerCheckInLogsMetaCard.vue'
import LoneWorkerCheckInLogsReference from './LoneWorkerCheckInLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LoneWorkerCheckInLogsTile', LoneWorkerCheckInLogsTile)
    Vue.component('LoneWorkerCheckInLogsDetail', LoneWorkerCheckInLogsDetail)
    Vue.component(
      'LoneWorkerCheckInLogsMetaCard',
      LoneWorkerCheckInLogsMetaCard,
    )
    Vue.component(
      'LoneWorkerCheckInLogsReference',
      LoneWorkerCheckInLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerCheckInLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
