<template>
  <tt-attr-action :action="action">
    <v-btn
      class="text-capitalize"
      :style="buttonStyle"
      elevation="0"
      small
      @click.stop="openCarryoverDialog"
    >
      <v-icon left small>mdi-arrow-right</v-icon>
      <span v-text="$t(carryOverLabel)" />
    </v-btn>
  </tt-attr-action>
</template>

<script lang="ts">
import { DIALOG_WIDTH_MEDIUM_LARGE } from '@/helpers/constants'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import Vue, { VueConstructor } from 'vue'
import { ServiceLevelImpactColors } from '../../../types'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

type LeaveManagementButtonStyle = {
  color: string
  'background-color': string
}

export default (Vue as VueWithInjections).extend({
  name: 'LeavePoliciesCarryoverColumn',
  inject: ['getItemHook'],
  computed: {
    action(): string {
      return 'carry-over'
    },
    carryOverLabel(): string {
      return 'tt-entity-design.leave-management.leave-policies.carryover'
    },
    buttonStyle(): LeaveManagementButtonStyle {
      return {
        color: ServiceLevelImpactColors.MEDIUM_LOW.text,
        'background-color': ServiceLevelImpactColors.MEDIUM_LOW.background,
      }
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  created() {
    this.requestCurrentPeriodAttributes()
  },
  methods: {
    requestCurrentPeriodAttributes() {
      this.getItemHook().addAttribute('startPeriodDate')
      this.getItemHook().addAttribute('endPeriodDate')
    },
    openCarryoverDialog() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'LeavePoliciesCarryoverDialog',
        width: DIALOG_WIDTH_MEDIUM_LARGE,
        props: {
          action: this.action,
          leavePolicy: this.getItemHook().getEntityId(),
          itemHook: this.itemHook,
        },
      })
    },
  },
})
</script>
