<template>
  <v-card v-if="form" class="fill-height">
    <GenericForm
      v-bind="form"
      :closable="false"
      :save-btn-text="submitBtnTranslationKey"
      v-on="$listeners"
      @input="onFormValueChange"
      @submit:error="onSubmitError"
      @submit:success="onSubmitSuccess"
    />
  </v-card>
  <TLoadingWave v-else />
</template>

<script lang="ts">
import flow from 'lodash/flow'
import Vue, { VueConstructor } from 'vue'

import {
  DefinitionOption,
  DefinitionOptionMap,
  FormOptions,
} from '@tracktik/tt-json-schema-form'

import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { DialogFormInterface } from '@/tt-app-layout/types'
import { EntityCreateIntent } from '@/tt-widget-entity-flow'
import { EntityEditIntent } from '@/tt-widget-entity-flow/intents/EntityEditIntent'
import {
  GenericFormError,
  GenericFormSuccess,
} from '@/tt-app-layout/components/GenericForm.vue'
import { Resources } from '@/tt-entity-design/src/types'
import {
  SiteTaskScheduleType,
  siteTaskScheduleType,
} from '@/tt-entity-design/src/components/site-task-schedules/site-task-schedule-type'
import {
  siteTaskFeatureFlags,
  SiteTaskFeatureFlags,
} from '@/tt-entity-design/src/components/site-task-schedules/types'

type VueWithInjections = VueConstructor<
  Vue & { formOptions: FormOptions } & SiteTaskFeatureFlags
>

interface SiteTaskScheduleFormValue {
  type?: SiteTaskScheduleType
  [key: string]: unknown
}

const patchDefinition = (
  definitions: DefinitionOptionMap,
  definitionName: string,
  patch: (
    definition: DefinitionOption,
    definitionName: string,
  ) => DefinitionOption,
): DefinitionOptionMap => {
  return {
    ...definitions,
    [definitionName]: patch(definitions[definitionName], definitionName),
  }
}

const skipField = (
  definition: DefinitionOption,
  field: string,
): DefinitionOption => ({
  ...definition,
  skip: [...(definition.skip ?? []), field],
})

const skipClientField = (definition: DefinitionOption): DefinitionOption =>
  skipField(definition, 'client')

const skipExceptionTypesField = (
  definition: DefinitionOption,
): DefinitionOption => skipField(definition, 'exceptionTypes')

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskSchedulesForm',
  inject: {
    formOptions: { default: undefined },
    siteTaskFeatureFlags,
  },
  props: {
    /**
     * Id of the site the entity will belong to. Only used for entity creation.
     * If left empty, the user will be able to select it in the form field.
     */
    clientId: { type: Number, default: undefined },

    /**
     * Id of the entity handled by the form. Leave empty for creating a new entity.
     */
    entityId: { type: Number, default: undefined },
  },
  data() {
    return {
      formBuilder: null as DialogFormBuilder | null,
      formValue: {} as SiteTaskScheduleFormValue,
    }
  },
  computed: {
    form(): DialogFormInterface | null {
      return this.formBuilder?.getState()
    },
    resourceName(): Resources {
      return Resources.SITE_TASK_SCHEDULES
    },
    submitBtnTranslationKey(): string | undefined {
      return this.siteTaskFeatureFlags.enableCalendarExceptions &&
        this.formValue.type === siteTaskScheduleType.RECURRING
        ? this.translationKey('submit-btn')
        : undefined // use default text
    },
    translationScope(): string {
      return 'tt-site-tasks.schedules-form'
    },
  },
  watch: {
    entityId: {
      immediate: true,
      async handler(newVal, oldVal) {
        // if (newVal === oldVal) return

        this.formBuilder = null // set component to loading state

        this.formBuilder = newVal
          ? await this.getEditFormBuilder()
          : await this.getCreateFormBuilder()
      },
    },
  },
  methods: {
    buildDefinitions(
      definitions: DefinitionOptionMap,
      formName: string,
    ): DefinitionOptionMap {
      const getPatch = (patch) => (definitions) =>
        patch ? patchDefinition(definitions, formName, patch) : definitions

      const applyPatches = flow(
        getPatch(this.clientId && skipClientField),
        getPatch(
          !this.siteTaskFeatureFlags.enableCalendarExceptions &&
            skipExceptionTypesField,
        ),
      )

      return applyPatches(definitions)
    },
    getCreateFormBuilder(): Promise<DialogFormBuilder> {
      const initialModel = {
        client: this.clientId,
        type: siteTaskScheduleType.ONE_TIME,
      }

      const intent = new EntityCreateIntent(this.$appContext)

      return intent.getDialogFormBuilder({
        buildDefinitions: this.buildDefinitions,
        formOptions: this.formOptions,
        initialModel,
        resourceName: this.resourceName,
        title: this.translationKey('create.title'),
      })
    },
    getEditFormBuilder(): Promise<DialogFormBuilder> {
      const intent = new EntityEditIntent(this.$appContext)

      return intent.getDialogFormBuilder({
        buildDefinitions: this.buildDefinitions,
        entityId: this.entityId,
        formOptions: this.formOptions,
        resourceName: this.resourceName,
        title: this.translationKey('edit.title'),
      })
    },
    onFormValueChange(value: SiteTaskScheduleFormValue): void {
      this.formValue = value
    },
    onSubmitError({ data, error }: GenericFormError): void {
      this.form?.error(error, data)
    },
    onSubmitSuccess({ data, response }: GenericFormSuccess): void {
      this.form?.success(data, response)
    },
    translationKey(key: string): string {
      return `${this.translationScope}.${key}`
    },
  },
})
</script>
