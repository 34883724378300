// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetsTile from './AssetsTile.vue'
import AssetsColumns from './AssetsColumns'
import AssetsDetail from './AssetsDetail.vue'
import AssetsMetaCard from './AssetsMetaCard.vue'
import AssetsReference from './AssetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetsTile', AssetsTile)
    Vue.component('AssetsDetail', AssetsDetail)
    Vue.component('AssetsMetaCard', AssetsMetaCard)
    Vue.component('AssetsReference', AssetsReference)

    modularManager.addResourcePreset(Resources.ASSETS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'AssetsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.ASSETS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: AssetsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ASSETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ASSETS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'AssetsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ASSETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
