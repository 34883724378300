<template>
  <v-chip color="toolbar-3" label small>
    {{ accruedLocale }}
  </v-chip>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'AccruedChip',
  computed: {
    accruedLocale(): string {
      return this.$t(
        'tt-entity-design.leave-management.leave-policy-items.accrued-chip',
      )
    },
  },
})
</script>
