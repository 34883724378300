<template>
  <CollapsibleHeader>
    <template #title>
      <span class="pl-4 pr-6">
        {{ $t('components.havingQL_widget_form_input.tilte') }}
        <v-badge v-if="model" :color="'green'" dot inline />
      </span>
    </template>
    <template #default>
      <v-card flat class="pa-4 transparent">
        <InfoBox>
          {{ $t('components.havingQL_widget_form_input.user_info') }}
        </InfoBox>
        <v-text-field
          v-model="model"
          dense
          :label="$t('components.havingQL_widget_form_input.label')"
          outlined
          @change="change"
        />
      </v-card>
    </template>
  </CollapsibleHeader>
</template>
<script lang="ts">
import { VueConstructor } from 'vue'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import WhereQLEditor from '@/tt-tql-inputs/src/components/WhereQLEditor.vue'
import { TypeIntoHavingQL } from '@/plugins/o11n'
import { FormHookProvider, ResourceProvider } from '..'

export default (
  BaseInput as VueConstructor<
    InstanceType<typeof BaseInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'HavingQLWidgetFormInput',
  components: { WhereQLEditor },
  inject: ['formHook', 'resourceProvider'],
  computed: {
    resource(): any {
      return this.resourceProvider.resource
    },
  },
  methods: {
    change(): void {
      this.trackTypeIntoHavingQLEvent()
    },
    trackTypeIntoHavingQLEvent(): void {
      this.$analytics.track(TypeIntoHavingQL.create())
    },
  },
})
</script>
