<template>
  <v-container no-gutters class="p-0">
    <v-row no-gutters>
      <v-col cols="12" sm="12" class="flex-grow-1">
        <div>
          <json-field name="account" :item-props="{ blockCreation: true }" />
          <json-field
            name="assets"
            :item-props="{
              blockCreation: true,
              modelContext: { accountAttribute: false },
            }"
          />
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" class="flex-grow-1 fill-height">
        <div style="height: 350px; overflow-y: hidden" class="p-0">
          <v-divider class="pb-6" />
          <WidgetFactory
            :key="JSON.stringify(listWidget)"
            :widget="listWidget"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider, ListWidgetModel } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { modularManager, PresetItem, PresetTypes } from '@/tt-app-modular'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'TagInputViaRelationLists',
  inject: ['formHook'],
  props: {
    entityId: { type: Number, required: true },
  },
  computed: {
    preset(): PresetItem {
      return modularManager.getResourcePreset(
        Resources.ASSETS,
        PresetTypes.LIST_ITEM,
      )
    },
    listWidget(): ListWidgetModel {
      return {
        is: WidgetName.LIST_WIDGET,
        query: {
          relationListResource: {
            attribute: 'account',
            id: this.entityId,
            resource: Resources.ASSETS,
          },
          filters: [
            {
              attribute: 'bundle.id',
              operator: FilterOperatorType.EQUAL,
              value: this.entityId,
            },
          ],
          contextFilters: {
            accountAttribute: false,
          },
          resource: Resources.ASSETS,
        },
        title: '',
        toolbar: {
          show: false,
        },
        listItem: this.preset && { is: 'AssetsAndSitesTile' },
        disableResourcePreviewOnClick: true,
      }
    },
  },
})
</script>
