<template>
  <tt-attr-row-card v-bind="$props">
    <tt-attr-row name="occurrenceDate" />
    <tt-attr-row name="customId" value-css-class="color-pill" />
    <tt-attr-row name="taskType" />
    <tt-attr-row name="description" />
    <tt-attr-row name="instructions" />
    <tt-attr-row name="status" />
    <tt-attr-row name="client" />
    <!-- Add priceTier back when product requests it (SEU-431) -->
    <!-- <tt-attr-row name="priceTier" /> -->
    <tt-attr-row name="position" />
    <tt-attr-row name="session.employee" />
    <tt-attr-row name="checkpointTour" />
    <tt-attr-row name="reportTemplate" />
    <tt-attr-row name="type" />
    <tt-attr-row name="beginServiceDate" />
    <tt-attr-row name="endServiceDate" />
    <tt-attr-row name="rangeStartTime" />
    <tt-attr-row name="rangeEndTime" />
    <tt-attr-row v-if="isCalendarExceptionsEnabled" name="exception" />
    <tt-attr-row name="durationMinutes" />
    <tt-attr-row name="dayOfWeekStart" />
    <tt-attr-row name="dayOfWeekEnd" />
    <tt-attr-row name="frequency" />
    <tt-attr-row name="rangeStartedDateTime" />
    <tt-attr-row name="rangeEndedDateTime" />
    <tt-attr-row name="timeZone" />
    <SiteTaskOccurrencesReasonToConvertAttrRow />

    <!--
      Register relations so they are available in the preview tab, but don't
      show them to the user -->
    <div v-show="false">
      <tt-attr-row name="schedule" />
      <tt-attr-row name="session" />
      <tt-attr-row name="session.checkpointTourSession" />
      <tt-attr-row name="taskSiteInstruction" />
    </div>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskOccurrencesDetail',
  inject: ['getItemHook'],
  computed: {
    isCalendarExceptionsEnabled(): boolean {
      return !!this.getItemHook().hasAttribute('exception')
    },
  },
})
</script>
