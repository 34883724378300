import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'employee',
  {
    attributeName: 'employee.status',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'employee.status',
        backgroundColor: 'green',
        textColor: 'white',
      },
    },
  },
  'description',
  'startedOn',
  'endedOn',
] as ColumnInputDefinition[]
