<template>
  <span
    class="v-chip v-chip--label v-size--x-small grey--text"
    :class="themeClass"
    v-text="$t(text)"
  >
  </span>
</template>

<script lang="ts">
import Vue from 'vue'

const DEFAULT_NO_VALUE_TEXT = 'N/A'

export default Vue.extend({
  name: 'TChipNoValue',
  props: {
    text: {
      type: String,
      default: DEFAULT_NO_VALUE_TEXT,
    },
  },
  computed: {
    themeClass(): string {
      return this.$vuetify.theme.dark ? 'theme--dark' : 'theme--light'
    },
  },
})
</script>
