<template>
  <div v-if="resource">
    <component
      :is="component"
      v-model="attributeName"
      :data-cy="label"
      v-bind="$attrs"
      :disabled="!resource"
      outlined
      dense
      :label="label"
      :items="attributes"
      :error-messages="errorMessages"
      :placeholder="placeholder"
      :filter="customSearchFilter"
      :attach="true"
      :menu-props="menuProps"
      v-on="$listeners"
      @change.self="$emit('change', $event)"
    >
      <template v-if="!disableSelectionScope" #selection="{ item }">
        <div class="selection">
          <v-icon :color="item.color" class="mr-4" v-text="item.icon" />
          <span class="selection-text" v-text="item.text" />
        </div>
      </template>
      <template #item="{ on, item }">
        <div :style="{ opacity: item.opacity, width: '100%' }" class="d-flex">
          <TDropDownItemDetail
            :title="item.text"
            :subtitle="item.value"
            :description="item.description"
            :disabled="item.deprecated"
            :content-style="contentStyle"
          >
            <template #icon>
              <v-icon :color="item.color" small v-text="item.icon" />
            </template>
          </TDropDownItemDetail>
        </div>
      </template>
    </component>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import {
  getResourceAttributesForList,
  ResourceAttributeSelectorFilter,
  ResourceAttributeSelectorItem,
} from '@/tt-widget-components/lib/attribute-list'
import TDropDownItemDetail from '@/tt-ui/components/TDropDownItemDetail.vue'
import { ResourceProvider } from '..'
import { customSearchFilter } from '../helpers'

type ViewItem = ResourceAttributeSelectorItem

export default (Vue as VueConstructor<Vue & ResourceProvider>).extend({
  name: 'ResourceAttributeSelector',
  components: { TDropDownItemDetail },
  inject: ['resourceProvider'],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    disableSelectionScope: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function as PropType<ResourceAttributeSelectorFilter>,
      default() {
        return () => {
          return true
        }
      },
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    contentStyle: {
      type: Object,
      default: () => ({ maxWidth: '300px' }),
    },
    component: {
      type: String as PropType<'v-autocomplete' | 'v-select'>,
      default: 'v-autocomplete',
    },
    menuProps: {
      type: Object,
      default: () => ({
        maxWidth: 'none',
      }),
    },
  },
  computed: {
    attributeName: {
      get(): string {
        return this.value
      },
      set(newValue: string) {
        this.$emit('input', newValue)
      },
    },
    resource(): string {
      return this.resourceProvider.resource
    },
    attributes(): ViewItem[] {
      if (!this.resource) {
        return []
      }
      const resourceMetaManager =
        this.$appContext.widgetServices.resourceMetaManager

      const convertPathToText = (item: ViewItem): ViewItem => {
        const translateAttr = (key: string): string => this.$tc(key)
        const text = resourceMetaManager
          .getAttributePathLabelKeys(this.resource, item.value)
          .map(translateAttr)
          .join(' · ')

        return { ...item, text }
      }

      return getResourceAttributesForList(
        resourceMetaManager,
        this.resource,
        this.filter,
      ).map(convertPathToText)
    },
  },
  methods: {
    customSearchFilter,
  },
})
</script>

<style scoped>
.selection {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selection-text {
  font-size: 0.8em;
  white-space: nowrap;
}
</style>
