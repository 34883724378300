<template>
  <v-card flat>
    <v-card class="pl-4" style="height: 100px">
      <div class="pt-4 widget-details-title">
        <div class="title-text">
          <WidgetIcon
            style="font-size: 40px"
            class="mr-2"
            :widget-type="widget.is"
          />

          <v-tooltip :disabled="!showTooltip" bottom>
            <template #activator="{ on }">
              <span
                class="text-capitalize"
                @mouseover="hoverItem"
                v-on="on"
                v-text="widget.title"
              />
            </template>

            <span v-text="widget.title" />
          </v-tooltip>

          <div
            class="ml-12"
            style="font-size: 14px; text-transform: capitalize"
            v-text="$tc(`widgets.${widget.is}.name`)"
          />
        </div>

        <DownloadCSVButton
          v-if="showCsvDownload"
          v-slot="{ exportCSV, isLoading }"
          :hook="hook"
          class="pr-4"
        >
          <v-tooltip bottom :open-on-focus="false">
            <template #activator="{ on }">
              <button
                :disabled="isLoading"
                class="download-csv--button"
                @click="exportCSV"
                v-on="on"
              >
                <v-progress-circular
                  v-if="isLoading"
                  indeterminate
                  size="16"
                  width="2"
                  color="var(--v-ttPrimary-base)"
                />

                <v-icon v-else :size="32" v-text="`$csvIcon`" />
              </button>
            </template>

            <span v-text="$t('common.download.csv.btn')" />
          </v-tooltip>
        </DownloadCSVButton>
      </div>
    </v-card>

    <v-card flat outlined class="pb-4">
      <WidgetDetailsListItem
        icon-name="mdi-database"
        :text="$t(`${i18nResourceKey}`)"
        title="widget_edit.tab.data_source"
      />
      <WidgetDetailsListItem
        v-if="dimensions.length"
        icon-name="mdi-link"
        :title="$t('components.resource_dimension_selector.group_by')"
      >
        <v-list-item-title
          v-for="(dimension, i) in dimensions"
          :key="i"
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$t(localizeAttribute(dimension.attribute))"
        />
      </WidgetDetailsListItem>
      <WidgetDetailsListItem
        v-if="widgetDataSet && measures"
        icon-name="mdi-arrow-top-right-bottom-left"
        :title="$t('widget_editor.measure.label')"
      >
        <v-list-item-title
          v-for="(measure, i) in measures"
          :key="i"
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$t(`widget_edit.MeasureOperation.enum.${measure.operation}`)"
        />
      </WidgetDetailsListItem>
    </v-card>

    <v-card flat outlined class="pb-4">
      <WidgetDetailsListItem
        v-if="contextFilters.dateRangeAttribute"
        icon-name="mdi-calendar-blank"
        :text="localizeAttribute(contextFilters.dateRangeAttribute)"
        :title="$t(`components.widget_context_toolbar.filter.date_range`)"
      />
      <WidgetDetailsListItem
        v-if="contextFilters.regionAttribute"
        icon-name="mdi-google-maps"
        :title="$t(`components.widget_context_toolbar.filter.region`)"
        :text="localizeAttribute(contextFilters.regionAttribute)"
      />
      <WidgetDetailsListItem
        v-if="contextFilters.accountAttribute"
        icon-name="mdi-office-building-outline"
        :title="$t(`components.widget_context_toolbar.filter.account`)"
        :text="localizeAttribute(contextFilters.accountAttribute)"
      />
    </v-card>

    <v-card v-if="showBottomSection" flat outlined class="pb-4">
      <WidgetDetailsListItem
        v-if="includeInactive"
        icon-name="mdi-plus"
        :text="`${includeInactive}`"
        title="widget_edit.include_inactive.title"
      />
      <WidgetDetailsListItem
        v-if="scopes.length"
        icon-name="mdi-filter"
        :title="$t(`components.resource_scope_selector.scope_filters`)"
      >
        <v-list-item-title
          v-for="(scope, i) in scopes"
          :key="i"
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$tc(`res.${resource}.scopes.${scope}.label`)"
        />
      </WidgetDetailsListItem>
      <WidgetDetailsListItem
        v-if="attributeFilters.length"
        icon-name="mdi-filter"
        :title="$t(`components.attribute_filter_input.attribute_filters`)"
      >
        <v-list-item-title
          v-for="(filter, i) in attributeFilters"
          :key="i"
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$t(localizeAttribute(filter.attribute))"
        />
      </WidgetDetailsListItem>
      <WidgetDetailsListItem
        v-if="customFilters.length"
        icon-name="mdi-filter"
        :title="$t(`components.custom_filter_input.custom_filters`)"
      >
        <v-list-item-title
          v-for="(filter, i) in customFilters"
          :key="i"
          style="font-size: 16px; text-transform: capitalize"
          class="ml-8"
          v-text="$t(filter.filterName)"
        />
      </WidgetDetailsListItem>
      <WidgetDetailsListItem
        v-if="whereQL"
        icon-name="mdi-database-refresh"
        :text="whereQL"
        :title="$t(`components.whereQL_widget_form_input.title`)"
      />
      <WidgetDetailsListItem
        v-if="widgetDataSet && havingQL"
        icon-name="mdi-database"
        :text="havingQL"
        :title="$t(`components.havingQL_widget_form_input.tilte`)"
      />
    </v-card>
  </v-card>
</template>

<script lang="ts">
import merge from 'lodash/merge'
import Vue, { PropType } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { ResourceModelContext } from '@/tt-widget-factory'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

import DownloadCSVButton from './DownloadCSVButton.vue'
import WidgetDetailsListItem from './WidgetDetailsListItem.vue'
import WidgetIcon from './WidgetIcon.vue'
import {
  ContextFiltersMap,
  CustomFilter,
  Dimension,
  Filter,
  Measure,
  WidgetModels,
} from '../schemas-types'
import {
  CollectionWidget,
  DataSetWidget,
  WidgetDetailsModel,
  isCollectionWidget,
  isDataSetWidget,
} from './WidgetDetails.utils'
import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import CollectionWidgetHook from '@/tt-widget-components/base/CollectionWidgetHook'
import { WidgetDownloadType } from '@/tt-widget-components/types'
import { widgetAllowsDownloadByType } from '@/tt-widget-components/components/allow-download-helpers'

export default Vue.extend({
  name: 'WidgetDetails',
  components: {
    WidgetIcon,
    WidgetDetailsListItem,
    DownloadCSVButton,
  },
  props: {
    hook: {
      type: Object as PropType<
        DataSetWidgetHook<WidgetModels> | CollectionWidgetHook<WidgetModels>
      >,
      required: true,
    },
    isDashboardWrapper: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false as boolean,
    }
  },
  computed: {
    showCsvDownload(): boolean {
      return (
        this.isDashboardWrapper &&
        widgetAllowsDownloadByType(this.hook.widget, WidgetDownloadType.CSV)
      )
    },
    widget(): WidgetDetailsModel {
      return this.hook.state.widget
    },
    widgetDataSet(): DataSetWidget['dataSet'] | null {
      return isDataSetWidget(this.widget) ? this.widget.dataSet : null
    },
    widgetQuery(): CollectionWidget['query'] {
      return isCollectionWidget(this.widget) ? this.widget.query : null
    },
    dimensions(): Dimension[] {
      if (isDataSetWidget(this.widget)) {
        const dataset = this.widgetDataSet

        // @ts-ignore
        return (dataset?.dimensions || [dataset?.dimension]).filter(Boolean)
      } else return []
    },
    showBottomSection(): boolean {
      return !!(
        this.includeInactive ||
        this.scopes.length ||
        this.attributeFilters.length ||
        this.customFilters.length ||
        this.whereQL ||
        this.havingQL
      )
    },
    measures(): Measure[] {
      if (isDataSetWidget(this.widget)) {
        const dataset = this.widgetDataSet

        // @ts-ignore
        return (dataset?.measures || [dataset?.measure]).filter(Boolean)
      } else return []
    },
    attributeFilters(): Filter[] {
      const attributeFilters = isDataSetWidget(this.widget)
        ? this.widgetDataSet?.filters?.filters
        : this.widgetQuery?.filters

      return (attributeFilters || []).filter(Boolean)
    },
    scopes(): string[] {
      const scope = isDataSetWidget(this.widget)
        ? this.widgetDataSet?.filters?.scopes
        : this.widgetQuery?.scope

      return (scope || []).filter(Boolean)
    },
    includeInactive(): boolean {
      return this.widgetDataSet
        ? this.widgetDataSet?.filters?.includeInactive
        : this.widgetQuery?.includeInactive
    },
    customFilters(): CustomFilter[] {
      const customFilters = isDataSetWidget(this.widget)
        ? this.widgetDataSet?.filters?.customFilters
        : this.widgetQuery?.customFilters

      return (customFilters || []).filter(Boolean)
    },
    resource(): string {
      return isDataSetWidget(this.widget)
        ? this.widgetDataSet?.resource
        : this.widgetQuery?.resource
    },
    i18nResourceKey(): string {
      return createI18nPluralResourceKey(this.resource)
    },
    whereQL(): string | undefined {
      return isDataSetWidget(this.widget)
        ? this.widgetDataSet?.filters?.whereQL
        : this.widgetQuery?.whereQL
    },
    havingQL(): string | undefined {
      return isDataSetWidget(this.widget)
        ? this.widgetDataSet?.filters?.havingQL
        : null
    },
    resourceModelContext(): ResourceModelContext | undefined {
      return this.$appContext.widgetServices.resourceMetaManager.getResourceModelContext(
        this.resource,
      )
    },
    widgetContextFilters(): ContextFiltersMap {
      const contextFilters = isCollectionWidget(this.widget)
        ? this.widgetQuery?.contextFilters
        : this.widgetDataSet?.contextFilters

      return contextFilters || {}
    },
    contextFilters(): ContextFiltersMap {
      const contextFiltersWithDefaults = merge(
        {},
        this.resourceModelContext,
        this.widgetContextFilters,
      )

      const { regionAttribute, accountAttribute, dateRangeAttribute } =
        contextFiltersWithDefaults

      return { regionAttribute, accountAttribute, dateRangeAttribute }
    },
  },
  methods: {
    hoverItem(e: MouseEvent): void {
      const isContentOverflowing = () =>
        (e.target as HTMLInputElement).offsetWidth >
        (e.target as HTMLInputElement).parentElement.clientWidth
      this.showTooltip = isContentOverflowing()
    },
    localizeAttribute(attribute: string) {
      return this.$appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
        this.resource,
        attribute,
        FormLabelTypes.LABEL,
      )
    },
  },
})
</script>

<style scoped>
.title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
.widget-details-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.download-csv--button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
