import MenuLogoExtension from './component/MenuLogoExtension.vue'
import Vue from 'vue'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

Vue.component('MenuLogoExtension', MenuLogoExtension)

// Constant of the extension
export const MENU_LOGO_EXTENSION = 'extension.menu-logo.tracktik.com'

/**
 * Logo interface
 */
export interface MenuLogoInterface {
  src: string
  maxWidth?: string
}

export default {
  name: MENU_LOGO_EXTENSION,
  enabled: true,
  slots: [LayoutExtensionSlots.BRANDING],
  component: {
    is: 'MenuLogoExtension',
  },
} as ExtensionInterface
