import { ApiDateTimeFormats } from '@/helpers/formats/dates/types'
import { AppContext } from '@/tt-app-context'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'

export const createObjectUrl = (file) => {
  try {
    return file ? URL.createObjectURL(file) : null
  } catch (e) {
    console.warn('Error while creating object URL', e)
    return null
  }
}

export const revokeObjectUrl = (objectUrl) => {
  if (objectUrl) {
    try {
      URL.revokeObjectURL(objectUrl)
    } catch (e) {
      console.warn('Error while revoking object URL', e)
    }
  }
}

// @todo: implement an `uploadFile` method in the SDK
export const uploadFile = (
  appContext: AppContext,
  file: File,
): Promise<AxiosResponse<any>> => {
  const formData = new FormData()
  formData.append('file', file)

  return appContext.authModule.getHttp().post('file-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

/**
 * Parse the user preference to get the time format : 24hr or ampm
 */
export const getTimeFormat = (timeFormat: string): '24hr' | 'ampm' =>
  timeFormat.includes('H') ? '24hr' : 'ampm'

//@todo: add tests
export const convertDateWithTimezone = (date: string, timezone: string) =>
  moment.tz(date, timezone).format(ApiDateTimeFormats.ISO_8601)

// Filename needs to have the extension for the API (.png)
const convertBlobToPngFile = (blob) =>
  new File([blob], 'Image.png', { type: 'image/png' })

export const fetchImage = (appContext: AppContext, url: string) =>
  appContext.authModule
    .getHttp()
    .get(url, { responseType: 'blob' })
    .then(({ data }) => data)
    .then(convertBlobToPngFile)

export const convertBase64ImageToFile = async (base64) =>
  fetch(base64)
    .then((res) => res.blob())
    .then(convertBlobToPngFile)
    .catch((e) => {
      console.warn('Error while converting image...', e)
    })
