<template>
  <div class="flex--column">
    <div v-if="isReady" class="d-flex align-center pt-4 pb-2">
      <h5 class="text-body-1 font-weight-bold px-6 py-2">
        {{ titleText }}
      </h5>
      <!-- policy assigned to employees warning -->
      <AssignLeavePolicyItemsWarning v-show="hasEmployeesAssigned">
        {{ notEditableAlert }}
      </AssignLeavePolicyItemsWarning>

      <!-- no selected items warning -->
      <AssignLeavePolicyItemsWarning
        v-show="!hasSelectedRecords && !hasEmployeesAssigned"
      >
        {{ noSelectedItemsText }}
      </AssignLeavePolicyItemsWarning>
    </div>
    <div class="flex--column">
      <v-skeleton-loader v-if="!isReady" type="table" />
      <WidgetFactory
        v-if="isReady"
        :class="[{ 'disable-checkbox': hasEmployeesAssigned }, 'light-toolbar']"
        :widget="leavePolicyItemsWidgetModel"
        :selected-records="currentSelectedRecords"
        :disabled-records="initialSelection"
        @selection-updated="currentSelectedRecords = $event"
      />
    </div>
    <div class="pa-4">
      <v-btn
        class="white--text mr-3"
        raised
        color="#4E69E9"
        style="text-transform: none"
        :loading="loading || submitting"
        :disabled="!hasSelectedRecords"
        @click="submit"
      >
        <v-icon left> mdi-arrow-right </v-icon>
        <span v-text="buttonText" />
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import { DataTableWidgetModel, Filter } from '@/tt-widget-components/types'
import { Resources } from '@/tt-entity-design/src/types'
import { GridSelectionMode } from '@/tt-widget-components/widgets/DataTable/types'
import AssignLeavePolicyItemsCustomColumns from '@/tt-widget-views/leave-management/setup/leave-policies/assign-leave-policy-item/AssignLeavePolicyItemsCustomColumns'
import Vue, { PropType } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { LeavePolicyFormStep } from '@/tt-widget-views/leave-management/types'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Sort } from 'tracktik-sdk/lib/common/entity-collection'
import AssignLeavePolicyItemsWarning from './AssignLeavePolicyItemsWarning.vue'
import { LeavePolicyApiResponse } from '@/tt-entity-design/src/components/leave-policies/types'

export default Vue.extend({
  name: 'AssignLeavePolicyItem',
  components: {
    AssignLeavePolicyItemsWarning,
  },
  props: {
    step: {
      type: Object as PropType<LeavePolicyFormStep>,
      default: null,
    },
    leavePolicyId: {
      type: Number,
      required: true,
    },
    hasEmployeesAssigned: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      leavePolicy: {} as LeavePolicyApiResponse,
      initialSelection: [] as number[],
      currentSelectedRecords: [] as number[],
      loading: false,
      submitting: false,
    }
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady() && !this.loading
    },
    translationBase(): string {
      return `tt-entity-design.leave-management.leave-policies.tabs.${this.step.key}`
    },
    nextStepButtonText(): string {
      return `${this.translationBase}.save-button-create`
    },
    assignedEmployeesText(): string {
      return `${this.translationBase}.next-assigned-employees`
    },
    buttonText(): string {
      return this.hasEmployeesAssigned
        ? this.$t(this.assignedEmployeesText)
        : this.$t(this.nextStepButtonText)
    },
    noSelectedItemsText(): string {
      return this.$t(`${this.translationBase}.warning`, {
        n: this.currentSelectedRecords.length,
      })
    },
    titleText(): string {
      const editionTitle = this.$t(`${this.translationBase}.title`)
      const creationTitle = this.$t(`${this.translationBase}.subtitle`)
      return this.hasEmployeesAssigned
        ? `${editionTitle} (${this.currentSelectedRecords.length})`
        : `${creationTitle} (${this.currentSelectedRecords.length})`
    },
    genericErrorTranslationKey(): string {
      return 'common.error_message'
    },
    notEditableAlert(): string {
      return this.$t(
        `tt-entity-design.leave-management.leave-policies.not-editable-message`,
      )
    },
    hasSelectedRecords(): boolean {
      return this.currentSelectedRecords.length > 0
    },
    hasAssignAccessFilter(): Filter {
      return {
        attribute: 'hasAssignAccess',
        operator: FilterOperatorType.EQUAL,
        value: this.leavePolicy.region,
      }
    },
    orderByAssignedPolicyFilter(): Filter {
      return {
        attribute: 'orderByAssignedToPolicy',
        operator: FilterOperatorType.EQUAL,
        value: this.leavePolicy.id,
      }
    },
    sortByLeaveTypeNameDesc(): Sort {
      return {
        attribute: 'leaveType.name',
        direction: 'DESC',
      }
    },
    recordsToAssign(): number[] {
      return this.currentSelectedRecords.filter(
        (currentSelectedId) =>
          !this.initialSelection.includes(currentSelectedId),
      )
    },
    recordsToUnassign(): number[] {
      return this.initialSelection.filter(
        (initialSelectedId) =>
          !this.currentSelectedRecords.includes(initialSelectedId),
      )
    },

    leavePolicyItemsWidgetModel(): DataTableWidgetModel {
      let assignedPolicyItemsFilter: Filter[] = [
        this.hasAssignAccessFilter,
        this.orderByAssignedPolicyFilter,
      ]

      if (this.hasEmployeesAssigned) {
        const assignedPolicyItems: Filter = {
          attribute: 'id',
          operator: FilterOperatorType.IN,
          value: this.initialSelection,
        }
        assignedPolicyItemsFilter = [
          ...assignedPolicyItemsFilter,
          assignedPolicyItems,
        ]
      }

      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_POLICY_ITEMS,
          filters: assignedPolicyItemsFilter,
          sort: [this.sortByLeaveTypeNameDesc],
        },
        component: { columns: AssignLeavePolicyItemsCustomColumns },
        selectionMode: GridSelectionMode.MULTIPLE,
      }
    },
  },
  created() {
    this.loadCurrentlyAssignedPolicyItems()
  },
  methods: {
    async loadCurrentlyAssignedPolicyItems() {
      this.loading = true

      await this.$appContext.authModule
        .getApi()
        .get<number, LeavePolicyApiResponse>(
          Resources.LEAVE_POLICIES,
          this.leavePolicyId,
          {
            include: ['leavePolicyItems'],
          },
        )
        .then((leavePolicyInfo) => {
          this.leavePolicy = leavePolicyInfo
          this.$emit('policyName', leavePolicyInfo.name)
          const initialSelection = leavePolicyInfo.leavePolicyItems.map(
            (item) => item.id,
          )

          this.initialSelection = [...initialSelection]
          this.currentSelectedRecords = [...initialSelection]
        })

      this.loading = false
    },
    dispatchSuccessMessage(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t(`${this.translationBase}.assign-success`),
      })
    },
    dispatchErrorMessage(message: string): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message,
      })
    },
    async submit() {
      if (this.hasEmployeesAssigned) return this.$emit('nextStep')

      this.submitting = true

      const requestsToProcess = new Array<Promise<void>>()

      if (this.recordsToAssign.length > 0) {
        const assignLeavePolicyItemData = {
          leavePolicyItemsIds: [...this.recordsToAssign],
        }
        requestsToProcess.push(
          this.$appContext.entityServices.persister.executeEntityAction(
            Resources.LEAVE_POLICIES,
            'assign-leave-policy-items',
            this.leavePolicyId,
            assignLeavePolicyItemData,
          ),
        )
      }

      if (this.recordsToUnassign.length > 0) {
        const unassignLeavePolicyItemData = {
          leavePolicyItemsIds: [...this.recordsToUnassign],
        }
        requestsToProcess.push(
          this.$appContext.entityServices.persister.executeEntityAction(
            Resources.LEAVE_POLICIES,
            'unassign-leave-policy-items',
            this.leavePolicyId,
            unassignLeavePolicyItemData,
          ),
        )
      }

      await Promise.all(requestsToProcess)
        .then(() => {
          this.initialSelection = this.currentSelectedRecords
          this.$emit('updatePolicyItems', this.currentSelectedRecords)
          this.dispatchSuccessMessage()
          this.$emit('nextStep')
        })
        .catch((error) => {
          this.$crash.captureException(error)

          if (error?.response?.data) {
            const failedActionsErrors = Object.values(error.response.data)

            failedActionsErrors.forEach((errorContent: string[]) => {
              errorContent.forEach((message: string) => {
                this.dispatchErrorMessage(message)
              })
            })
          } else {
            this.dispatchErrorMessage(this.$t(this.genericErrorTranslationKey))
          }
        })

      this.submitting = false
    },
  },
})
</script>

<style scoped>
/* Create a light toolbar */
.light-toolbar >>> .v-toolbar__content {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}
/* Create a black outlined search box */
.light-toolbar >>> .v-toolbar__content > span:first-of-type .v-chip {
  background-color: white;
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px;
}
.light-toolbar
  >>> .v-toolbar__content
  > span:first-of-type
  .v-chip:hover::before {
  opacity: 0;
}
/* Make items count background white to blend in with toolbar */
.light-toolbar >>> .v-toolbar__content > div:last-child > .v-chip {
  background-color: white;
}
.light-toolbar
  >>> .v-toolbar__content
  > div:last-child
  > .v-chip:hover::before {
  background-color: white;
}
</style>
