<template>
  <JsonForm
    :value="customFieldsValue"
    :schema="jsonSchema"
    :form-options="formOptions"
    class="fill-height"
    @input="$emit('input', $event)"
    @errors="$emit('errors')"
    @valid="$emit('valid')"
  />
</template>

<script lang="ts">
import FieldsetBuilder from '@/tt-fieldset-builder/FieldsetBuilder'
import { Fieldset, Context } from '@/tt-fieldset-builder/type'
import { Field } from 'tracktik-sdk/lib/common/entity-collection'

import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'AssetCustomAttributeListAsForm',
  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    customFieldsValue: {
      type: Object,
      default: () => {},
    },
    context: {
      type: String as PropType<Context>,
      default: 'entity' as Context,
    },
  },
  computed: {
    formBuilder(): FieldsetBuilder {
      return new FieldsetBuilder(this.fieldSet)
    },
    fieldSet(): Fieldset {
      return {
        name: 'Custom Field Group',
        fields: this.fields,
      }
    },
    jsonSchema(): any {
      return this.formBuilder.getJsonSchema(this.context)
    },
    formOptions(): any {
      return this.formBuilder.getFormOptions()
    },
  },
})
</script>
