<template>
  <v-menu open-on-hover bottom offset-y>
    <template #activator="{ on }">
      <div class="text-clickable" v-on="on">
        <v-icon small color="green" v-text="icon" />
        <span
          class="pl-1"
          @click.stop="openPreview"
          v-text="translatedResource"
        />
      </div>
    </template>
    <SystemExceptionTicketsRelationsCard
      :entity-id="entityId"
      :relation-type="resourceName"
    />
  </v-menu>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getResourceName } from '@/tt-widget-components/helpers'
import SystemExceptionTicketsRelationsCard from './cards/SystemExceptionTicketsRelationsCard.vue'

type VueWithInjections = VueConstructor<
  Vue & ActiveWindowProvider & ItemHookProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'SystemExceptionTicketsRelationAttr',
  components: {
    SystemExceptionTicketsRelationsCard,
  },
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  data() {
    return {
      icon: 'mdi-link-plus',
    }
  },
  computed: {
    resourceName(): string {
      return this.getItemHook().getRawValue('relation.type')
    },
    entityId(): number {
      return this.getItemHook().getRawValue('relation.object')
    },
    translatedResource(): string {
      if (this.resourceName) {
        return getResourceName(this.$appContext, this.resourceName)
      }

      return ''
    },
    isSideSheetChildren(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
  },
  created() {
    this.getItemHook().addAttribute(`relation`)
  },
  methods: {
    openPreview() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        activeWindow: this.activeWindow,
        resourceName: this.resourceName,
        entityId: this.entityId,
        hasToAddPages: this.isSideSheetChildren,
      })
    },
  },
})
</script>

<style scoped>
.text-clickable:hover,
.text-clickable:hover >>> *,
.text-clickable.selected,
.text-clickable.selected >>> * {
  color: #4e69e9 !important;
}
</style>
