<template>
  <div>
    <AccruedChip v-if="isAccrued" />
    <span v-else>-</span>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import AccruedChip from '@/tt-widget-views/leave-management/AccruedChip.vue'
export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyItemsAccruedColumn',
  components: { AccruedChip },
  inject: ['getItemHook'],
  computed: {
    accruedLocale(): string {
      return this.$t(
        'tt-entity-design.leave-management.leave-policy-items.accrued-chip',
      )
    },
    notAvailableLocale(): string {
      return this.$t('tt-entity-design.NA')
    },
    isAccrued(): boolean {
      return !!this.getItemHook().get('accrualRate')
    },
  },
  created() {
    this.getItemHook().addAttribute('accrualRate')
  },
})
</script>
