import { FilterOperator } from '@/tt-widget-components'

export enum PDF_IFRAME_MESSAGES {
  CLOSE = 'tt-pdf-route.close',
  MOUNTED = 'tt-pdf-route.mounted',
}

export type ValidQueryParams = {
  context_regions?: string
  context_regions_operator?: FilterOperator

  context_accounts?: string
  context_accounts_operator?: FilterOperator

  context_daterange?: string
  context_daterange_operator?: FilterOperator
}

export type PrintRouteQueryParam = keyof ValidQueryParams
