import { EntityIntentTypes, EntityListIntentInterface } from './types'
import { LayoutWindowEvent, PageInterface } from '@/tt-app-layout'
import { BaseEntityIntent } from '@/tt-widget-entity-flow/intents/BaseEntityIntent'

/**
 * Entity List Intent
 */
export class EntityListIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.LIST
  }

  /**
   * Payload
   * @param payload
   */
  run(payload: EntityListIntentInterface): void {
    // Create a page
    const page = {
      title: 'Some resource',
      is: 'widget-factory',
      props: {
        widget: {},
      },
    } as PageInterface

    // Dispatch the page
    this.appContext.eventManager.dispatchEvent(
      LayoutWindowEvent.SIDE_SHEET,
      page,
    )
  }
}
