<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    content-class="allow-download--dropdown"
  >
    <template #activator="{ on, attrs, value: menuValue }">
      <v-btn v-bind="attrs" small class="ml-3" v-on="on">
        <span v-text="$t('common.download.btn')" />
        <v-icon right v-text="`mdi-chevron-${menuValue ? 'up' : 'down'}`" />
      </v-btn>
    </template>
    <WidgetAllowDownloadField
      :supported-types="supportedTypes"
      :value="value"
      :show-divider="false"
      @input="handleFormInput"
    />
  </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { AllowDownloadAll } from '@/tt-widget-components'
import { WidgetDownloadTypes } from '@/tt-widget-components'

export default Vue.extend({
  name: 'WidgetAllowDownloadDropdown',
  props: {
    value: {
      type: [Boolean, Object] as PropType<AllowDownloadAll | null>,
      default: null
    },
    supportedTypes: {
      type: Array as PropType<WidgetDownloadTypes[]>,
      default: () => [] as WidgetDownloadTypes[]
    }
  },
  methods: {
    handleFormInput(event: AllowDownloadAll | null) {
      this.$emit('input', event)
    }
  }
})
</script>
<style lang="scss" scoped>
.allow-download--dropdown {
  background: white;
  padding: 16px;
  width: 350px !important;
}
</style>
