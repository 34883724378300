<template>
  <tt-attr-row-card>
    <tt-attr-row name="account" />
    <tt-attr-row name="affectedEntity">
      <ConflictsAffectedEntityAttr />
    </tt-attr-row>
    <tt-attr-row name="status">
      <StatusChip
        :status-color-options="conflictsStatusColorOptions"
        :resource="resource"
      />
    </tt-attr-row>
    <tt-attr-row name="conflictCategory" />
    <tt-attr-row name="conflictType" />
    <tt-attr-row name="conflictSubtype" />
    <tt-attr-row name="conflictInfo" />
    <tt-attr-row name="refBatchName" />
    <tt-attr-row name="extSource" />
    <tt-attr-row name="extDirection" :label="extDirectionText" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'

import { ConflictsStatusColorsOptions } from './types'
import { Resources } from '../../types'

export default Vue.extend({
  name: 'ConflictsDetail',
  computed: {
    resource(): Resources {
      return Resources.CONFLICTS
    },
    extDirectionText(): string {
      return 'tt-entity-design.human-capital-management.ext-direction'
    },
    conflictsStatusColorOptions(): typeof ConflictsStatusColorsOptions {
      return ConflictsStatusColorsOptions
    },
  },
})
</script>
