import { Status } from './types'

const ALARM_STATUS_CLASS: Record<Status, string> = {
  ACTIVE:
    'font-weight-bold text--darken-2 ttPrimary--text ttPrimary lighten-5 text-uppercase',
  CANCELLED:
    'font-weight-bold text--darken-2 yellow--text yellow lighten-5 text-uppercase',
  RESOLVED:
    'font-weight-bold text--darken-2 green--text green lighten-5 text-uppercase',
  ARCHIVED:
    'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase',
}

const DEFAULT_STATUS_COLOR =
  'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase'

export const getStatusClass = (status: string) =>
  ALARM_STATUS_CLASS[status] || DEFAULT_STATUS_COLOR
