import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import SystemExceptionTicketInstructionsCreateForm from './SystemExceptionTicketInstructionsCreateForm.vue'
import SystemExceptionTicketInstructionsEditForm from './SystemExceptionTicketInstructionsEditForm.vue'
import SystemExceptionTicketInstructionsActionBar from './SystemExceptionTicketInstructionsActionBar.vue'
import SystemExceptionTicketInstructionsIsAvailableInSubRegionAttr from './SystemExceptionTicketInstructionsIsAvailableInSubRegionAttr.vue'
import { Resources } from '../../types'
import { ActionFormTypes, modularManager } from '@/tt-app-modular'

export default {
  install(Vue) {
    Vue.component(
      'SystemExceptionTicketInstructionsCreateForm',
      SystemExceptionTicketInstructionsCreateForm,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsEditForm',
      SystemExceptionTicketInstructionsEditForm,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsIsAvailableInSubRegionAttr',
      SystemExceptionTicketInstructionsIsAvailableInSubRegionAttr,
    )

    registerResourceForm(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      'SystemExceptionTicketInstructionsCreateForm',
      ActionFormTypes.CREATE,
    )

    registerResourceForm(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      'SystemExceptionTicketInstructionsEditForm',
      ActionFormTypes.EDIT,
    )

    modularManager.registerActionBar(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      {
        is: SystemExceptionTicketInstructionsActionBar,
      },
    )
  },
}
