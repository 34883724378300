<template>
  <v-dialog
    v-model="layoutManager.state.fullPageDialog.state"
    fullscreen
    content-class="position--relative"
  >
    <v-card>
      <AppWindow
        v-model="layoutManager.state.fullPageDialog.state"
        dark
        closable
        :event="eventType.FULL_PAGE_DIALOG"
        :landing-page="layoutManager.state.fullPageDialog.page"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'
export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutFullPageDialog',
})
</script>

<style scoped>
.v-dialog__content {
  /** Default is "fixed", but for injected views in TTC we need "absolute" */
  position: absolute;
}

.v-dialog__content >>> .position--relative {
  position: relative;
}
</style>
