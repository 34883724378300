<template>
  <div class="tt-entity-unavailable">
    <v-icon small class="mr-2" v-text="'mdi-lock'" />
    <span v-text="$t('components.entity-preview.unavailable')" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'EntityItenUnavailable',
})
</script>

<style lang="scss" scoped>
.tt-entity-unavailable {
  padding: 40px 0;
  text-align: center;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e8e8;
  border-bottom: 1px solid #e7e8e8;
}
</style>
