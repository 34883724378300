<template>
  <v-dialog :value="true" max-width="500" @input="cancel">
    <v-card class="level1">
      <v-card-text class="pa-6 pt-8">
        <v-text-field
          v-model="model.title"
          :label="$t('components.widget_edit_title_menu.title')"
          outlined
          dense
        />
        <v-textarea
          v-model="model.description"
          :label="$t('components.widget_edit_title_menu.description')"
          outlined
          dense
        />
      </v-card-text>
      <v-divider />
      <v-toolbar flat dense text>
        <v-spacer />
        <v-btn color="grey" text @click="cancel">
          <span v-text="$t('common.cancel.btn')"></span>
        </v-btn>
        <v-btn :disabled="hasNoTitle" color="success" text @click="apply">
          <span v-text="$t('common.apply.btn')"></span>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WidgetEditTitleMenu',
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
  },
  data() {
    return {
      model: {
        title: this.title,
        description: this.description,
      },
    }
  },
  computed: {
    hasNoTitle(): boolean {
      return !this.model?.title?.trim()
    },
  },
  methods: {
    apply() {
      this.$emit('apply', { ...this.model })
      this.cancel()
    },
    cancel() {
      this.$emit('close')
    },
  },
})
</script>
