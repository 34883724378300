// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PhoneCallLogsTile from './PhoneCallLogsTile.vue'
import PhoneCallLogsColumns from './PhoneCallLogsColumns'
import PhoneCallLogsDetail from './PhoneCallLogsDetail.vue'
import PhoneCallLogsMetaCard from './PhoneCallLogsMetaCard.vue'
import PhoneCallLogsReference from './PhoneCallLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PhoneCallLogsTile', PhoneCallLogsTile)
    Vue.component('PhoneCallLogsDetail', PhoneCallLogsDetail)
    Vue.component('PhoneCallLogsMetaCard', PhoneCallLogsMetaCard)
    Vue.component('PhoneCallLogsReference', PhoneCallLogsReference)

    modularManager.addResourcePreset(
      Resources.PHONE_CALL_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PhoneCallLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PHONE_CALL_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PhoneCallLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PHONE_CALL_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PhoneCallLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PHONE_CALL_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PhoneCallLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PHONE_CALL_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PhoneCallLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
