// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PaymentMethodsTile from './PaymentMethodsTile.vue'
import PaymentMethodsColumns from './PaymentMethodsColumns'
import PaymentMethodsDetail from './PaymentMethodsDetail.vue'
import PaymentMethodsReference from './PaymentMethodsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PaymentMethodsTile', PaymentMethodsTile)
    Vue.component('PaymentMethodsDetail', PaymentMethodsDetail)
    Vue.component('PaymentMethodsReference', PaymentMethodsReference)

    modularManager.addResourcePreset(
      Resources.PAYMENT_METHODS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PaymentMethodsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYMENT_METHODS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PaymentMethodsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYMENT_METHODS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PaymentMethodsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYMENT_METHODS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PaymentMethodsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
