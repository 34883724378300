<template>
  <v-select
    v-model="selectedLanguage"
    dense
    outlined
    class="px-4 py-2"
    style="margin-bottom: -18px"
    :label="$t('user_section.language')"
    :placeholder="$t('user_section.language')"
    :items="languageOptions"
  />
</template>

<script lang="ts">
import Vue from 'vue'
import {
  SupportedLocale,
  LANGUAGE_OPTIONS,
  LanguageOption,
} from '@/locales/language-options'
import { ChangeLanguage } from '@/plugins/o11n'
import { setAppLocale, setLocaleToLocaleStorage } from '@/locales'

export default Vue.extend({
  name: 'LanguageSelect',
  computed: {
    languageOptions(): LanguageOption[] {
      return LANGUAGE_OPTIONS
    },
    selectedLanguage: {
      get(): string {
        return this.$i18n.locale
      },
      set(locale: SupportedLocale) {
        setAppLocale(this.$appContext.authModule, locale)
        setLocaleToLocaleStorage(locale)

        this.$analytics.track(ChangeLanguage.create({ label: locale }))
      },
    },
  },
})
</script>
