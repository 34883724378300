<template>
  <WidgetPage
    :widget="widgetUpdated"
    :has-title-bar="hasTitleBar"
    :aggregation-context="aggregationContext"
    :skip-validation="skipValidation"
  >
    <template #default>
      <span
        v-if="isAvgSumMeasureOperation || isMinMaxMeasureOperation"
        class="ma-2 align-self-end"
      >
        <v-chip color="green" text-color="white">
          {{ value }}
        </v-chip>
      </span>
    </template>
  </WidgetPage>
</template>
<script lang="ts">
import { modularManager, PresetTypes } from '@/tt-app-modular'
import { AggregationContext } from '@/tt-widget-entity-flow'
import { Measure, Sort } from '@/tt-widget-factory'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'
import Vue, { PropType } from 'vue'
import {
  CollectionQuery,
  ColumnDefinition,
  DataTableWidgetModel,
  ListWidgetModel,
} from '..'
import { WidgetName } from '../lib/names'
import { MeasureOperation } from './measure-types'
export default Vue.extend({
  name: 'AggregationPageWrapper',
  props: {
    hasTitleBar: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object as PropType<{
        title: string
        query: CollectionQuery
      }>,
      required: true,
    },
    aggregationContext: {
      type: Object as PropType<AggregationContext>,
      required: true,
    },
    skipValidation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    measure(): Measure {
      return this.aggregationContext.measure
    },
    presetColumns(): ColumnDefinition[] {
      return modularManager.getResourcePreset(
        this.widget.query.resource,
        PresetTypes.COLUMNS,
      )?.data
    },
    attributeExist(): boolean {
      const checkAttribute = (obj) =>
        obj.attributeName === this.measure.attribute

      return (
        this.presetColumns.some(checkAttribute) ||
        this.presetColumns.includes(this.measure.attribute)
      )
    },
    hasToDispayColumn(): boolean {
      return (
        !this.attributeExist &&
        (this.isAvgSumMeasureOperation || this.isMinMaxMeasureOperation)
      )
    },
    widgetDataTable(): DataTableWidgetModel {
      const attributeToAdd = {
        attributeName: this.measure.attribute,
        style: { cssClass: 'font-weight-bold text-body-2' },
      }

      const component: DataTableWidgetModel['component'] = {
        columns: this.hasToDispayColumn
          ? [...this.presetColumns, attributeToAdd]
          : [...this.presetColumns],
      }

      const query: DataTableWidgetModel['query'] = {
        ...this.widget.query,
        sort: this.sort,
      }

      return {
        ...this.widget,
        is: WidgetName.DATA_TABLE_WIDGET,
        query,
        component,
      }
    },
    widgetListWidget(): ListWidgetModel {
      return {
        ...this.widget,
        is: WidgetName.LIST_WIDGET,
      }
    },
    isMinMaxMeasureOperation(): boolean {
      return [MeasureOperation.MIN, MeasureOperation.MAX].includes(
        this.measure.operation as MeasureOperation,
      )
    },
    isAvgSumMeasureOperation(): boolean {
      return [MeasureOperation.AVG, MeasureOperation.SUM].includes(
        this.measure.operation as MeasureOperation,
      )
    },
    sort(): Sort[] {
      return this.isMinMaxMeasureOperation
        ? [
            {
              direction:
                this.measure.operation === MeasureOperation.MIN
                  ? SortDirectionType.ASC
                  : SortDirectionType.DESC,
              attribute: this.measure.attribute,
            },
          ]
        : []
    },
    widgetUpdated(): DataTableWidgetModel | ListWidgetModel {
      return this.$vuetify.breakpoint.smAndDown
        ? this.widgetListWidget
        : this.widgetDataTable
    },
    translatedAttribute(): string {
      const i18nKeys =
        this.$appContext.widgetServices.resourceMetaManager.getAttributePathLabelKeys(
          this.widget.query.resource,
          this.measure.attribute,
        )
      return i18nKeys.map((key) => this.$t(key)).join(' - ')
    },
    value(): string {
      return `${this.translatedAttribute} ${this.measure.alias}: ${this.aggregationContext.value}`
    },
  },
})
</script>
