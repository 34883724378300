<template>
  <div class="fill-height d-flex flex-column">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import ContextManager from '@/tt-widget-factory/context/ContextManager'
import unionBy from 'lodash/unionBy'
import { ContextFilter } from '@/tt-widget-components'
import { ContextManagerInterface } from '@/tt-widget-factory/types'

type VueWithInjections = VueConstructor<
  Vue & { parentContext: ContextManagerInterface }
>

/**
 * Creates and provides a ContextManager to all children components.
 */
export default (Vue as VueWithInjections).extend({
  name: 'ContextManagerProvider',
  provide(): { contextManager: ContextManager } {
    return { contextManager: this.contextManager }
  },
  inject: {
    parentContext: {
      from: 'contextManager',
      default() {
        /**
         * If no parent context provided, the parent is the AppContext's Context Manager.
         *
         * ie: in AssetTracking, the AppContext ContextManager holds the "account" context, which is applied to all sub context.
         */
        return this.$appContext.contextManager
      },
    },
  },
  props: {
    initialContextFilters: {
      type: Array as PropType<ContextFilter[]>,
      default: (): ContextFilter[] => [],
    },
  },
  data() {
    const { authModule, regionManager } = this.$appContext
    return {
      contextManager: new ContextManager(
        authModule,
        regionManager,
        this.parentContext,
        { ...this.parentContext.context },
      ),
    }
  },
  created() {
    // at this point, should contains parent context filters only
    const currentContextFilters = this.contextManager.getContextFilters()

    // the "initial filters" replace the "parent filters" if defined
    const newContextFilters = unionBy(
      this.initialContextFilters,
      currentContextFilters,
      'attribute',
    )

    this.contextManager.setContextFilters(newContextFilters)
  },
})
</script>
