<template>
  <v-container class="pa-6">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      dense
      :height="40"
      slider-color="orange"
    >
      <v-tab key="details">
        {{ $t('tt-entity-design.mobile-schedules.tab-details') }}
      </v-tab>
      <v-tab key="schedule">
        {{ $t('tt-entity-design.mobile-schedules.tab-schedule') }}
      </v-tab>
    </v-tabs>
    <v-card flat color="transparent">
      <div class="mt-6">
        <div v-show="tab === 0">
          <json-field name="taskPriceTier" />
          <json-field name="taskType" />
          <json-field name="zonePosition" />
          <json-field name="checkpointTourOption" />
          <json-field
            name="checkpointTour"
            :loading="isLoadingTaskInstruction"
          />
          <json-field name="description" />
          <json-field name="instructions" :loading="isLoadingTaskInstruction" />
          <json-field name="postOrders" />
          <json-field
            name="reportTemplate"
            :loading="isLoadingReportTemplate"
          />
          <json-field name="client" />
          <!-- task type assets -->
          <template>
            <p class="text-sm-body-2 grey--text mb-0">
              {{ taskTypeAssetsLabel }}
            </p>
            <v-chip-group>
              <template v-if="taskTypeId && !isLoadingTaskInstruction">
                <template v-if="hasAssets">
                  <v-chip
                    v-for="asset in taskTypeAssets"
                    :key="asset.name"
                    small
                  >
                    <span>{{ getAssetName(asset) }}</span>
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip small>
                    <span>{{ taskTypeNoAssetSet }}</span>
                  </v-chip>
                </template>
              </template>
              <template v-else>
                <v-chip small>
                  <span>{{ taskTypeNotSelected }}</span>
                </v-chip>
              </template>
            </v-chip-group>
          </template>
          <!-- / task type assets -->
          <json-field name="assets" :label="additionalAssetsLabel" />
        </div>
        <div v-show="tab === 1">
          <json-field name="rangeStartTime" :attach="isNew" />
          <json-field name="rangeEndTime" :attach="isNew" />
          <json-field name="durationMinutes" />
          <json-field name="jobFrequency" :label="jobFrequencyLabel" />
          <json-field name="isAdhoc" />
          <json-field :custom-view="daysOfWeekView" name="dayOfWeekStart" />
          <json-field name="beginServiceDate" :attach="isNew" />
          <json-field name="endServiceDate" :attach="isNew" />
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import DayOfWeekSelector from '@/tt-entity-design/src/components/DayOfWeekSelector.vue'
import { ViewOption } from '@tracktik/tt-json-schema-form'

import {
  getCheckpointTour,
  getReportTemplate,
  getTaskSiteInstruction,
  getTaskType,
} from '../task-site-instructions/helpers/task-site-instructions-api-helper'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'MobileSchedulesCreateForm',
  inject: ['formHook'],
  data() {
    return {
      isLoadingTaskInstruction: false,
      isLoadingReportTemplate: false,
      tab: 0,
      taskTypeAssets: [],
    }
  },
  computed: {
    daysOfWeekView(): ViewOption {
      return this.isNew
        ? {
            is: DayOfWeekSelector,
          }
        : null
    },
    jobFrequencyLabel(): string {
      return this.$t('tt-entity-design.mobile-schedules.job-frequency-label')
    },
    additionalAssetsLabel(): string {
      return this.$t(
        'tt-entity-design.mobile-schedules.additional-assets-label',
      )
    },
    taskTypeAssetsLabel(): string {
      return this.$t('tt-entity-design.mobile-schedules.task-type-assets-label')
    },
    taskTypeNotSelected(): string {
      return this.$t('tt-entity-design.mobile-schedules.task-type-not-selected')
    },
    taskTypeNoAssetSet(): string {
      return this.$t('tt-entity-design.mobile-schedules.task-type-no-asset-set')
    },
    taskTypeId(): number {
      return this.getFieldValue('taskType') as number
    },
    isNew(): boolean {
      return !this.getFieldValue('id')
    },
    hasAssets() {
      return this.taskTypeAssets && this.taskTypeAssets.length > 0
    },
  },
  watch: {
    async taskTypeId(taskTypeId) {
      if (taskTypeId) {
        this.syncTaskTypeCheckpointTourAndInstructions()
        this.syncTaskTypeReportTemplate()
      }
    },
  },
  methods: {
    getFieldValue(field: string): unknown {
      return this.formHook().getPathValue(field)
    },
    setFieldValue(field: string, value: unknown): void {
      this.formHook().setObjectValue(field, value)
    },
    getAssetName(asset): string {
      return `${asset.type.name} - ${asset.name}`
    },
    async syncTaskTypeCheckpointTourAndInstructions(): Promise<void> {
      this.isLoadingTaskInstruction = true

      const clientId = this.getFieldValue('client') as number
      const checkpointTourId = this.getFieldValue('checkpointTour') as number

      // Get selected task-type's associated checkpoint-tour, if any
      const taskSiteInstruction = await getTaskSiteInstruction(
        this.$appContext,
        { clientId, taskTypeId: this.taskTypeId },
        [
          'checkPointTour',
          'jobInstructions',
          'schedulableAssets.name',
          'schedulableAssets.type.name',
        ],
      )
      if (
        taskSiteInstruction?.checkPointTour &&
        checkpointTourId !== taskSiteInstruction.checkPointTour
      ) {
        // Check if associated checkpoint-tour is archived
        const checkpointTour = await getCheckpointTour(
          this.$appContext,
          taskSiteInstruction.checkPointTour,
        )
        if (checkpointTour) {
          this.setFieldValue('checkpointTour', checkpointTour.id)
        }
      }

      this.setFieldValue(
        'instructions',
        taskSiteInstruction?.jobInstructions ?? '',
      )

      this.taskTypeAssets = taskSiteInstruction?.schedulableAssets ?? []

      this.isLoadingTaskInstruction = false
    },
    async syncTaskTypeReportTemplate(): Promise<void> {
      this.isLoadingReportTemplate = true

      const taskTypeId = this.getFieldValue('taskType') as number
      const reportTemplateId = this.getFieldValue('reportTemplate') as number

      // Get selected task-type's associated report-template, if any
      const taskType = await getTaskType(this.$appContext, taskTypeId, [
        'reportTemplate',
      ])
      if (
        taskType?.reportTemplate &&
        reportTemplateId !== taskType.reportTemplate
      ) {
        // Check if associated report-template is archived
        const reportTemplate = await getReportTemplate(
          this.$appContext,
          taskType.reportTemplate,
        )
        if (reportTemplate) {
          this.setFieldValue('reportTemplate', reportTemplate.id)
        }
      }

      this.isLoadingReportTemplate = false
    },
  },
})
</script>
<style scoped>
::v-deep .v-select.v-text-field:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
</style>
