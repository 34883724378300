<template>
  <div v-if="metadatasReady && !widgetModel" style="position: relative">
    <InfoBannerDashboardView
      v-if="canShowBanner()"
      @click="setUserIdToLocalStorage()"
    />
    <ToolbarDashboardView v-model="search" :count="count" />

    <TLoadingWave v-if="isLoading && !widgetModel" />
    <WidgetDashboardResults
      v-else
      class="my-8 mx-4"
      :dashboard-views="views"
      :has-result="hasResult"
      :view-types="viewTypes"
      @click="getWidgetView($event.uid)"
      @infinite="getDashboardViews($event)"
    />
  </div>

  <WidgetRender
    v-else-if="widgetModel"
    :widget-model="widgetModel"
    :is-dirty="false"
    :controls="false"
    :query-as-filters="false"
    :edit-mode="false"
    :print-mode="printMode"
    @print-mode="printMode = $event"
  >
    <template #icon>
      <v-btn class="mt-3" small icon text circle @click="goBack()">
        <v-icon small>mdi-arrow-left</v-icon>
      </v-btn>
    </template>
  </WidgetRender>

  <TLoadingWave v-else />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { debounce } from 'lodash'

import { WidgetStoreInterface } from '@/tt-widget-factory'
import { LayoutWindowEvent } from '@/tt-app-layout'

import DashboardDataViewProvider from '../DashboardDataViewProvider'
import {
  hasDismissedBanner,
  setUserIdToLocalStorage,
} from '../LocalStorage/locale-storage-helper'
import { ViewTypes } from '../types'

import WidgetDashboardResults from './WidgetDashboardResults.vue'
import InfoBannerDashboardView from './InfoBannerDashboardView.vue'
import ToolbarDashboardView from './ToolbarDashboardView.vue'

/**
 * @TODO: review hierarchy, core components should not use app components
 * https://tracktik.atlassian.net/browse/FE-647
 */
import WidgetRender from '@/apps/app.tracktik.insights.studio/components/WidgetRender.vue'
import { portalType, PortalTypeProvider } from '../PortalTypeProvider'

const VueWithInjection = Vue as VueConstructor<Vue & PortalTypeProvider>

export default VueWithInjection.extend({
  name: 'DashboardView',
  components: {
    WidgetDashboardResults,
    ToolbarDashboardView,
    WidgetRender,
    InfoBannerDashboardView,
  },
  inject: { portalType },
  data() {
    return {
      provider: new DashboardDataViewProvider(this.$appContext),
      search: '',
      offset: 0,
      views: [] as WidgetStoreInterface[],
      widgetModel: null as WidgetStoreInterface | null,
      count: 0,
      hasResult: true,
      viewTypes: ViewTypes.View,
      isLoading: false,
      printMode: false,
    }
  },
  computed: {
    setDebouncedLoading() {
      return debounce(() => {
        this.isLoading = false
      }, 500)
    },
    metadatasReady(): boolean {
      return this.$appContext.isReady()
    },
  },
  watch: {
    async search(newValue: string, oldValue: string) {
      if (newValue) {
        await this.provider
          .fetchDashboardViewsBySearch(newValue)
          .catch((error) => {
            this.$crash.captureException(error)
            return this.handleError('dashboard_views.error_dashboard')
          })

        this.count = this.provider.getDashboardCount().searchViews

        this.views = this.provider.getSearchDataViews()

        this.viewTypes = ViewTypes.Search
      } else {
        this.count = this.provider.getDashboardCount().views

        this.views = this.provider.getDashboardDataViews()
      }

      this.setHasResults(this.count)
    },
  },
  methods: {
    // Needs to be a method because hasDismissedBanner() is not reactive (uses local storage)
    canShowBanner() {
      return (
        !hasDismissedBanner(this.$appContext.authModule) &&
        this.portalType === 'admin'
      )
    },
    goBack() {
      this.widgetModel = null

      this.setDebouncedLoading()
    },

    setHasResults(count: number) {
      this.hasResult = !!count
    },

    handleError(message) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: this.$t(message),
      })
    },

    setUserIdToLocalStorage() {
      setUserIdToLocalStorage(this.$appContext.authModule)
    },

    async getDashboardViews({ loaded, complete }) {
      const limit = 20

      await this.provider
        .fetchDashboardViews({
          limit,
          offset: this.offset,
        })
        .catch((error) => {
          this.$crash.captureException(error)
          return this.handleError('dashboard_views.error_dashboard')
        })

      this.views = this.provider.getDashboardDataViews()

      this.count = this.provider.getDashboardCount().views

      this.offset += limit

      this.setHasResults(this.count)

      if (
        this.provider.getDashboardDataViews().length === this.offset ||
        this.offset !== 0
      ) {
        loaded()
      }

      if (this.provider.getDashboardDataViews().length < this.offset) {
        complete()
      }
    },

    async getWidgetView(viewId: string) {
      this.isLoading = true

      this.widgetModel = await this.provider
        .fetchWidgetByUid(viewId)
        .catch((error) => {
          this.$crash.captureException(error)
          this.handleError('dashboard_views.error_widget')
          return null
        })
    },
  },
})
</script>
<style scoped>
.sticky-toolbar {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 5;
}
.v-application.theme--dark .sticky-toolbar {
  background-color: #111;
}
</style>
