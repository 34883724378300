// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ExceptionTypeGroupsTile from './ExceptionTypeGroupsTile.vue'
import ExceptionTypeGroupsColumns from './ExceptionTypeGroupsColumns'
import ExceptionTypeGroupsDetail from './ExceptionTypeGroupsDetail.vue'
import ExceptionTypeGroupsMetaCard from './ExceptionTypeGroupsMetaCard.vue'
import ExceptionTypeGroupsReference from './ExceptionTypeGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ExceptionTypeGroupsTile', ExceptionTypeGroupsTile)
    Vue.component('ExceptionTypeGroupsDetail', ExceptionTypeGroupsDetail)
    Vue.component('ExceptionTypeGroupsMetaCard', ExceptionTypeGroupsMetaCard)
    Vue.component('ExceptionTypeGroupsReference', ExceptionTypeGroupsReference)

    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ExceptionTypeGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUPS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
