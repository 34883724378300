<template>
  <tt-attr-row-card>
    <tt-attr-row name="type" />
    <tt-attr-row name="modelId" />
    <tt-attr-row name="status">
      <StatusChip :status-color-options="leaveRequestsStatusColorOptions" />
    </tt-attr-row>
    <tt-attr-row name="leaveType" />
    <tt-attr-row name="effectiveDateTime" />
    <tt-attr-row name="quantityDays" />
    <tt-attr-row name="adjustmentDays" />
    <tt-attr-row name="adjustmentHours" />
    <tt-attr-row name="payHoursPerDay" />
    <tt-attr-row name="employee" />
    <tt-attr-row name="approvalUser" />
    <tt-attr-row name="timeZone" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'

import { LeaveRequestsStatusColorOptions } from '../leave-requests/types'

export default Vue.extend({
  name: 'LeaveManagementAdjustmentsDetail',
  computed: {
    leaveRequestsStatusColorOptions(): typeof LeaveRequestsStatusColorOptions {
      return LeaveRequestsStatusColorOptions
    },
  },
})
</script>
