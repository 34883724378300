<template>
  <div class="flex--column">
    <v-divider />
    <div class="actionbar--container">
      <div
        v-for="{ title, icon, onClick, disabled } in visibleActions"
        :key="title"
        class="actionbar--btn-wrapper"
      >
        <ActionbarButton
          :title="title"
          :icon="icon"
          :disabled="disabled"
          :on-click="onClick"
        />
        <v-divider vertical class="py-3" />
      </div>

      <v-speed-dial
        v-if="shoreMoreButton"
        v-model="isMoreButtonActive"
        direction="bottom"
        transition="scroll-y-transition"
        class="actionbar--btn-wrapper"
        open-on-hover
      >
        <template #activator>
          <ActionbarButton
            :title="moreLabel"
            :icon="isMoreButtonActive ? 'mdi-close' : 'mdi-dots-horizontal'"
          />
        </template>

        <v-list class="actionbar--extra-action-container">
          <v-list-item
            v-for="({ title, icon, onClick }, index) in moreActions"
            :key="title"
            class="actionbar--extra-action-wrapper"
          >
            <v-btn
              text
              tile
              color="ttPrimary"
              class="actionbar--extra-action-btn"
              @click="onClick"
            >
              <span class="text-capitalize" v-text="title" />
            </v-btn>

            <v-divider v-if="index !== moreActions.length - 1" />
          </v-list-item>
        </v-list>
      </v-speed-dial>
    </div>
    <v-divider />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import ActionbarButton from './ActionbarButton.vue'
import {
  MAX_BUTTONS_IN_ROW,
  ViewAction,
  VueWithInjections,
} from './ActionBarPreset'

export default (Vue as VueWithInjections).extend({
  name: 'ActionBarPreset',
  components: {
    ActionbarButton,
  },
  inject: ['activeWindow', 'getItemHook'],
  props: {
    actions: {
      type: Array as PropType<ViewAction[]>,
      default: () => [],
    },
  },
  data: () => ({
    isMoreButtonActive: false,
  }),
  computed: {
    moreLabel(): string {
      return this.$t('components.action-bar-preset.more')
    },
    shoreMoreButton(): boolean {
      return this.actions.length > MAX_BUTTONS_IN_ROW
    },
    visibleActions(): ViewAction[] {
      return this.shoreMoreButton
        ? this.actions.slice(0, MAX_BUTTONS_IN_ROW - 1)
        : this.actions
    },
    moreActions(): ViewAction[] {
      return this.actions.slice(MAX_BUTTONS_IN_ROW - 1)
    },
  },
})
</script>

<style scoped>
.actionbar--container {
  background-color: var(--v-level0-base);

  display: flex;

  width: 100%;
  height: 70px !important;
}
.actionbar--btn-wrapper {
  display: flex;
  align-items: center;

  width: 20% !important;
  height: 100%;
}

.actionbar--btn-wrapper >>> .v-speed-dial__list {
  padding: 0;
  margin: 0;
}

.actionbar--extra-action-container {
  width: 100%;
  margin: 0;
  padding: 0;
}
.actionbar--extra-action-wrapper {
  background-color: var(--v-level0-base);

  padding: 0;
  height: 60px;
}
.actionbar--extra-action-btn {
  margin: 0;
  height: 100% !important;
  width: 100% !important;
}
</style>
