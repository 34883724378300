class QueryCache {
  queryCache: { [key: string]: { expiry: number; results: any } } = {}
  queryCacheTags: { [key: string]: string[] } = {}

  get(key: string, defaultVal: any = null): any {
    if (!this.has(key)) {
      return defaultVal
    }
    return this.queryCache[key].results
  }

  time(): number {
    return Math.round(new Date().getTime() / 1000)
  }

  set(key: string, results: any, duration: number, tags: string[] = []) {
    // Add the keys to the tags groups
    tags.forEach((tag: string) => {
      if (!this.queryCacheTags[tag]) {
        this.queryCacheTags[tag] = []
      }
      this.queryCacheTags[tag].push(key)
    })

    this.queryCache[key] = {
      expiry: this.time() + duration,
      results,
    }
  }

  // Clear by tag
  clearByTag(tag) {
    if (!this.queryCacheTags[tag]) {
      return
    }
    this.queryCacheTags[tag].forEach((key: string) => {
      if (this.queryCache[key]) {
        this.clear(key)
      }
    })
    delete this.queryCacheTags[tag]
  }

  clear(key: string) {
    delete this.queryCache[key]
  }

  gc() {
    Object.keys(this.queryCache).forEach((key: string) => {
      const { expiry } = this.queryCache[key]
      if (this.time() > expiry) {
        this.clear(key)
      }
    })
  }

  has(key: string): boolean {
    if (!this.queryCache[key]) {
      return false
    }
    const { expiry } = this.queryCache[key]
    if (this.time() > expiry) {
      this.clear(key)
      return false
    }
    return true
  }

  reset() {
    this.queryCache = {}
  }
}

export default new QueryCache()
