import hook from './YoutubeWidgetHook'
import template from './YoutubeWidget.vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const YoutubeWidget: WidgetTypeDefinition = {
  name: WidgetName.YOUTUBE_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  config: {
    icon: 'mdi-youtube',
    color: 'red',
    thumbnail: require('./youtube.png'),
  },
}

export default YoutubeWidget
