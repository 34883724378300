import { CollectionQuery, WidgetModels } from '@/tt-widget-components'
import { RouteConfig } from 'vue-router'
import withProps from '@/helpers/withProps'
import { Resources } from '@/tt-entity-design/src/types'
const WidgetPage = () => import('@/tt-widget-views/WidgetPage.vue')
export type CollectionQueryPartial = Partial<CollectionQuery>

export const createWidgetRoute = (
  routeName: string,
  widget: WidgetModels,
  props: any = {},
): RouteConfig => {
  return {
    name: routeName,
    path: routeName,
    component: WidgetPage,
    props: { widget, showContext: true, ...props },
  }
}

export const getResourceLabelKey = (resource: string): string => {
  return `res.${resource}.label`
}

export const createListRoute = (
  routeName: string,
  resource: Resources,
  query: CollectionQueryPartial = {},
  props: any = {},
): RouteConfig => {
  const collectionQuery = {
    ...query,
    resource: resource,
  } as CollectionQueryPartial
  return {
    name: routeName,
    path: routeName,
    component: createListComponent(collectionQuery, props),
  }
}

export const createListComponent = (
  query?: CollectionQueryPartial,
  props: any = {},
) => {
  return withProps('ResourceList', { query, ...props })
}
