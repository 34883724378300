<template>
  <div :class="paginationClasses" class="level0">
    <v-btn
      :disabled="isFirstPage"
      class="pagination--first-page-btn"
      rounded
      icon
      @click="goToPage(1)"
    >
      <v-icon small> mdi-page-first </v-icon>
    </v-btn>

    <v-btn :disabled="isFirstPage" rounded icon @click="goToPage(page - 1)">
      <v-icon small> mdi-chevron-left </v-icon>
    </v-btn>

    <v-btn v-if="!isFirstPage" class="pagination--dots" rounded icon disabled>
      <span v-text="'...'" />
    </v-btn>

    <v-btn rounded class="pagination--active-page" icon disabled>
      <span v-text="page" />
    </v-btn>

    <v-btn v-if="!isLastPage" class="pagination--dots" rounded icon disabled>
      <span v-text="'...'" />
    </v-btn>

    <v-btn rounded icon :disabled="isLastPage" @click="goToPage(page + 1)">
      <v-icon small> mdi-chevron-right </v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

type ResponsiveClassName = 'x-small-pagination'

/**
 * Pagination component for when the total number of items is unknown.
 */
export default Vue.extend({
  name: 'TPaginationUnknownTotal',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      required: true,
    },
    /**
     * The number of items on the current page.
     */
    currentPageCount: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isFirstPage(): boolean {
      return this.page === 1
    },
    isLastPage(): boolean {
      return this.currentPageCount < this.limit
    },
    page(): number {
      return Math.ceil(this.offset / this.limit) + 1
    },
    paginationClasses(): Record<ResponsiveClassName, boolean> {
      return {
        'x-small-pagination': this.width > 0 && this.width <= 250,
      }
    },
  },
  methods: {
    goToPage(page: number): void {
      const newOffset = (page - 1) * this.limit

      this.$emit('new-offset', newOffset)
    },
  },
})
</script>

<style scoped>
.pagination--active-page {
  background-color: var(--v-ttPrimary-lighten5);
  font-weight: normal;
}

.pagination--active-page span {
  color: var(--v-ttPrimary-base);
}

/**
 * Extra small (small dashboard columns)
 * 0 > 250
 */
.x-small-pagination {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.x-small-pagination .pagination--first-page-btn,
.x-small-pagination .pagination--dots {
  display: none;
}
</style>
