import i18n from '@/plugins/i18n'
import { AppContext } from '@/tt-app-context'
import { createResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

export const getResourceName = (
  appContext: AppContext,
  resourceName: string,
): string => {
  const getFallBackResourceName = () =>
    appContext.widgetServices.resourceMetaManager.getResourceLabel(resourceName)

  const key = createResourceKey(resourceName)

  return i18n.te(key) ? i18n.tc(key) : getFallBackResourceName()
}
