import { BaseEntityIntent } from '@/tt-widget-entity-flow/intents/BaseEntityIntent'
import { DefinitionOptionMap, FormOptions } from '@tracktik/tt-json-schema-form'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'

interface TTCloudLeaveRequestsCreateFormOptions {
  employee: string | number
}

export enum TTCloudLeaveRequestsIntentTypes {
  CREATE = 'ttcloud.leave-requests.create',
}

export type TTCloudLeaveRequestsIntentTypesPayload = {
  [TTCloudLeaveRequestsIntentTypes.CREATE]: TTCloudLeaveRequestsCreateFormOptions
}
/**
 * Entity List Intent
 */
export class TTCloudLeaveRequestsActionCreateIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return TTCloudLeaveRequestsIntentTypes.CREATE
  }

  run({ employee }: TTCloudLeaveRequestsCreateFormOptions): void {
    const createLeaveFormDefinition: DefinitionOptionMap = {
      LeaveRequestsPost: {
        view: {
          is: 'LeaveRequestsLogForm',
          props: {
            employee: employee,
          },
        },
      },
    }

    const formOptions: FormOptions = {
      definitions: createLeaveFormDefinition,
    }

    this.appContext.eventManager.dispatchEvent(EntityIntentTypes.CREATE, {
      resourceName: Resources.LEAVE_REQUESTS,
      initialModel: { employee },
      formOptions,
    })
  }
}
