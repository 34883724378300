import { BaseField, FieldName } from '../../base/types'

export const LIST = FieldName.List

export type ListFieldOptions = {
  /**
   * List options
   *
   * @minItems 2
   * @uniqueItems true
   */
  list: string[]
}

export type ListFieldType = BaseField & {
  type: typeof LIST
  options: ListFieldOptions
}
