import Vue from 'vue'
import FullScreenExtension from './components/FullScreenExtension.vue'
import { Breakpoint } from 'vuetify/types/services/breakpoint'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

Vue.component('FullScreenExtension', FullScreenExtension)

export const FULLSCREEN_EXTENSION = 'extension.full-screen.tracktik.com'

export default {
  name: FULLSCREEN_EXTENSION,
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  enabled(extension: ExtensionInterface, breakpoint: Breakpoint) {
    return breakpoint.mdAndUp
  },
  component: {
    is: 'FullScreenExtension',
  },
} as ExtensionInterface
