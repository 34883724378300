<template>
  <div class="flex--column">
    <v-tabs
      v-if="items.length > 1"
      v-model="tab"
      slider-color="orange"
      height="45"
      show-arrows
    >
      <v-tab v-for="(item, i) in items" :key="i" v-text="$t(item.title)" />
    </v-tabs>
    <slot v-if="!items || tab === 0" />
    <template v-for="(item, i) in items">
      <template v-if="i > 0">
        <component
          :is="item.is"
          v-if="tab == i"
          :key="`tab-${i}`"
          v-bind="item.props"
          class="fill-height"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { modularManager } from '@/tt-app-modular'
import { TabInterface } from '../types'

type DefaultTab = Omit<TabInterface, 'is'>

export default Vue.extend({
  name: 'TabSlot',
  props: {
    defaultTabLabel: {
      type: String,
      default: 'common.details',
    },
    context: {
      type: Object as PropType<Record<string, unknown>>,
      default: null,
    },
    slotName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manager: modularManager,
      tab: 0,
    }
  },
  computed: {
    items(): [DefaultTab, ...TabInterface[]] {
      const items = this.manager.getItems(this.slotName) as TabInterface[]
      const filteredItems = items.filter(
        (item) => item.condition == null || item.condition(this.context),
      )

      return [{ title: this.defaultTabLabel }, ...filteredItems]
    },
  },
})
</script>
