<template>
  <div>
    <json-field name="onSiteInformation.instructions" />
    <json-field name="onSiteInformation.sla" />
    <json-field name="onSiteInformation.extraInformation" />
    <json-field name="onSiteInformation.sensitiveInformation" />

    <FileHandlerProvider>
      <json-field name="attachments" :item-props="{ as: 'FileUpload' }" />
    </FileHandlerProvider>
    <FileUploadAvailableFileFormat v-if="hasAttachmentsField" />
    <json-field name="notes" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  inject: ['formHook'],
  computed: {
    // Attachments field aren't a property in edit form,
    // we want to hide FileUploadAvailableFileFormat in this case
    hasAttachmentsField(): boolean {
      return this.formHook().hasField('attachments')
    },
  },
})
</script>
