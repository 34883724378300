<template>
  <v-btn-toggle v-model="selectedIndex" borderless mandatory>
    <v-btn
      v-for="(item, index) in items"
      :key="`toggle--${index}--${item.value}`"
      :ripple="false"
      small
    >
      {{ item.text }}

      <v-icon
        v-if="item.icon"
        class="ml-1"
        small
        :color="isSelected(index) ? 'ttPrimary' : ''"
        v-text="item.icon"
      />
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import Vue, { PropType } from 'vue'

type SelectedIndex = number | null

export type Item<T = string> = {
  text: string
  value: T
  icon?: string
}

export default Vue.extend({
  name: 'TButtonToggleMultiple',
  props: {
    /**
     * The currently selected item value. If null, no item is selected.
     */
    value: {
      type: String as PropType<Item['value']>,
      default: null,
    },
    /**
     * List of items to display in the toggle
     */
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },
  computed: {
    selectedIndex: {
      get(): SelectedIndex {
        return this.items.findIndex((item) => item.value === this.value)
      },
      async set(selectedIndex: number | null) {
        await updateDOM()

        if (selectedIndex === null) {
          this.$emit('input', null)

          return
        }

        const newSelectedValue = this.items[selectedIndex].value

        this.$emit('input', newSelectedValue)
      },
    },
  },
  methods: {
    isSelected(index: number): boolean {
      return this.selectedIndex === index
    },
  },
})
</script>

<style scoped>
.v-btn--active {
  color: var(--v-ttPrimary-base) !important;
  background-color: var(--v-ttPrimary-lighten5) !important;
}

.v-btn--active:before {
  opacity: 0 !important;
}
</style>
