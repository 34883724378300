// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportConditionSetsTile from './ReportConditionSetsTile.vue'
import ReportConditionSetsColumns from './ReportConditionSetsColumns'
import ReportConditionSetsDetail from './ReportConditionSetsDetail.vue'
import ReportConditionSetsReference from './ReportConditionSetsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportConditionSetsTile', ReportConditionSetsTile)
    Vue.component('ReportConditionSetsDetail', ReportConditionSetsDetail)
    Vue.component('ReportConditionSetsReference', ReportConditionSetsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportConditionSetsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
