<template>
  <ProvideLayoutManager :layout-manager="manager">
    <AppLayoutBottomSheet />
    <AppLayoutToolbar />
    <v-main class="fill-height level0 position-relative">
      <div class="flex--column">
        <slot />
        <AppExtensionSlotFactory container-type="div" slot-name="ANYWHERE" />
        <AppLayoutNotificationsChips />
      </div>
    </v-main>
    <AppLayoutFooter />
    <AppLayoutSideMenu />
    <AppLayoutSideSheet />
    <AppLayoutDialogForm />
    <AppLayoutDialogLoading />
    <AppLayoutSnackBarLauncher />
    <AppLayoutAlertLauncher />
    <AppLayoutDialog />
    <AppLayoutFullPageDialog />
  </ProvideLayoutManager>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '../types'
import AppLayoutToolbar from './AppLayoutToolbar.vue'
import AppLayoutDialogForm from './AppLayoutDialogForm.vue'
import AppLayoutDialogLoading from './AppLayoutDialogLoading.vue'
import AppLayoutAlertLauncher from './AppLayoutAlertLauncher.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from './ProvideLayoutManager.vue'
import AppLayoutSideMenu from './AppLayoutSideMenu.vue'
import AppLayoutSideSheet from './AppLayoutSideSheet.vue'
import AppLayoutDialog from './AppLayoutDialog.vue'
import AppLayoutFullPageDialog from './AppLayoutFullPageDialog.vue'
import AppLayoutBottomSheet from './AppLayoutBottomSheet.vue'
import AppLayoutFooter from './AppLayoutFooter.vue'
import AppLayoutSnackBarLauncher from './AppLayoutSnackBarLauncher.vue'
import AppLayoutNotificationsChips from './AppLayoutNotificationsChips.vue'
export default Vue.extend({
  name: 'AppLayout',
  components: {
    ProvideLayoutManager,
    AppLayoutSideMenu,
    AppLayoutSideSheet,
    AppLayoutToolbar,
    AppLayoutDialog,
    AppLayoutFooter,
    AppLayoutFullPageDialog,
    AppLayoutBottomSheet,
    AppLayoutAlertLauncher,
    AppLayoutDialogForm,
    AppLayoutDialogLoading,
    AppLayoutSnackBarLauncher,
    AppLayoutNotificationsChips,
  },
  props: {
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager, this.layoutConfiguration),
    }
  },
  watch: {
    '$route.path'() {
      this.manager.state.sideSheet.state = false
      this.manager.state.sideSheet.pages = null
    },
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>

<style scoped>
.position-relative {
  position: relative;
}
</style>
