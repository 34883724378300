<template>
  <TDropDownItemDetail
    :title="translatedResource"
    :subtitle="domainModule"
    :description="description"
    :disabled="isDeprecated"
    :chip-text="resource"
  />
</template>

<script lang="ts">
import Vue from 'vue'
import {
  Resource,
  ResourceMetaManagerInterface,
} from '@/tt-widget-factory/services/resource-meta/types'
import TDropDownItemDetail from '@/tt-ui/components/TDropDownItemDetail.vue'
import { getResourceName } from '../helpers'

export default Vue.extend({
  name: 'ResourceSelectorItem',
  components: { TDropDownItemDetail },
  props: {
    item: {
      type: String,
      required: true,
    },
  },
  computed: {
    resourceMeta(): Resource {
      const metaManager: ResourceMetaManagerInterface =
        this.$appContext.widgetServices.resourceMetaManager

      return metaManager.getResource(this.resource)
    },
    resource(): string {
      return this.item
    },
    translatedResource(): string {
      return getResourceName(this.$appContext, this.resource)
    },
    isDeprecated(): boolean {
      return this.resourceMeta.deprecated ?? false
    },
    description(): string {
      const key = `res.${this.resource}.description`
      return this.$te(key) ? this.$tc(key) : ''
    },
    domainModule(): string {
      const domain = this.resourceMeta?.domain?.name
      const module = this.resourceMeta?.module?.name
      return `${domain} / ${module}`
    },
  },
})
</script>
