import { PluginFunction } from 'vue'

import EntityLabelAvailabilityTypesInstaller from '@/tt-entity-design/src/components/entity-label-availability-types'

const EntityLabelsView = () =>
  import(
    /* webpackChunkName: "EntityLabelsView" */ './views/EntityLabelsView.vue'
  )

export { EntityLabelsView }

export const EntityLabelsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('EntityLabelsView', EntityLabelsView)

  /**
   * This should be handled by src/tt-entity-design/src/components/entities.ts
   * but the resource is not available in the latest portal yet.
   * @todo: Remove this from here and let the entities.ts file handle it after
   * SEU-800 (TTCloud) reaches the latest portal and we update the schemas and
   * presets against it.
   */
  Vue.use(EntityLabelAvailabilityTypesInstaller)
}
