<template>
  <v-card flat>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      dense
      :height="40"
      slider-color="orange"
    >
      <v-tab
        key="details"
        v-text="$t('tt-entity-design.site-task-schedules.tab-details')"
      />
      <v-tab
        key="schedule"
        v-text="$t('tt-entity-design.site-task-schedules.tab-schedule')"
      />
    </v-tabs>

    <v-card flat color="transparent">
      <div class="mt-6">
        <!-- Details tab's content -->
        <div v-show="tab === 0">
          <json-field name="customId" />
          <json-field name="taskType" />
          <json-field name="description" />
          <json-field name="instructions" :loading="isLoadingTaskInstruction" />
          <json-field name="details" />
          <json-field name="client" />
          <json-field name="priceTier" />
          <json-field name="position" />
          <json-field
            name="checkpointTour"
            :loading="isLoadingTaskInstruction"
          />
          <json-field
            name="reportTemplate"
            :loading="isLoadingReportTemplate"
          />
        </div>

        <!-- Schedule tab's content -->
        <div v-show="tab === 1">
          <json-field name="type" />

          <v-row v-if="!showNewUI">
            <v-col>
              <json-field name="exceptionsOnly" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <json-field name="beginServiceDate" />
            </v-col>
            <v-col>
              <json-field name="endServiceDate" />
            </v-col>
          </v-row>

          <v-row v-if="!showNewUI">
            <v-col>
              <json-field name="rangeStartTime" />
            </v-col>
            <v-col>
              <json-field name="rangeEndTime" />
            </v-col>
          </v-row>

          <json-field name="durationMinutes" />
          <json-field v-if="!showNewUI" name="dayOfWeekStart" />
          <json-field name="frequency" />
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import { siteTaskScheduleType } from '@/tt-entity-design/src/components/site-task-schedules/site-task-schedule-type'
import { siteTaskFeatureFlags, SiteTaskFeatureFlags } from './types'
import {
  getCheckpointTour,
  getReportTemplate,
  getTaskSiteInstruction,
  getTaskType,
} from '../task-site-instructions/helpers/task-site-instructions-api-helper'

type VueWithInjections = VueConstructor<
  Vue & FormHookProvider & SiteTaskFeatureFlags
>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskSchedulesJsonForm',
  inject: {
    formHook: {},
    siteTaskFeatureFlags,
  },
  data() {
    return {
      isLoadingTaskInstruction: false,
      isLoadingReportTemplate: false,
      tab: 0,
    }
  },
  computed: {
    showNewUI(): boolean {
      return this.siteTaskFeatureFlags.enableSiteTaskEnhancement
    },
    type(): typeof siteTaskScheduleType {
      return this.getFieldValue('type')
    },
    isNew(): boolean {
      return !this.getFieldValue('id')
    },
    taskTypeId(): number {
      return this.getFieldValue('taskType')
    },
  },
  watch: {
    async taskTypeId(taskTypeId) {
      if (taskTypeId && this.isNew) {
        this.syncTaskTypeCheckpointTourAndInstructions()
        this.syncTaskTypeReportTemplate()
      }
    },
    //@TODO: Remove after FE-1042 is fixed
    type(type) {
      if (type !== siteTaskScheduleType.RECURRING) {
        this.setFieldValue('exceptionsOnly', undefined)
      }
    },
  },
  methods: {
    getFieldValue(field: string): any {
      return this.formHook().getPathValue(field)
    },
    setFieldValue(field: string, value: any): void {
      this.formHook().setObjectValue(field, value)
    },
    async syncTaskTypeCheckpointTourAndInstructions(): Promise<void> {
      this.isLoadingTaskInstruction = true

      const clientId = this.getFieldValue('client')
      const checkpointTourId = this.getFieldValue('checkpointTour')

      // Get selected task-type's associated checkpoint-tour, if any
      const taskSiteInstruction = await getTaskSiteInstruction(
        this.$appContext,
        { clientId, taskTypeId: this.taskTypeId },
        ['checkPointTour', 'jobInstructions'],
      )
      if (
        taskSiteInstruction?.checkPointTour &&
        checkpointTourId !== taskSiteInstruction.checkPointTour
      ) {
        // Check if associated checkpoint-tour is archived
        const checkpointTour = await getCheckpointTour(
          this.$appContext,
          taskSiteInstruction.checkPointTour,
        )
        if (checkpointTour) {
          this.setFieldValue('checkpointTour', checkpointTour.id)
        }
      }

      this.setFieldValue(
        'instructions',
        taskSiteInstruction?.jobInstructions ?? '',
      )

      this.isLoadingTaskInstruction = false
    },
    async syncTaskTypeReportTemplate(): Promise<void> {
      this.isLoadingReportTemplate = true

      const taskTypeId = this.getFieldValue('taskType')
      const reportTemplateId = this.getFieldValue('reportTemplate')

      // Get selected task-type's associated report-template, if any
      const taskType = await getTaskType(this.$appContext, taskTypeId, [
        'reportTemplate',
      ])
      if (
        taskType?.reportTemplate &&
        reportTemplateId !== taskType.reportTemplate
      ) {
        // Check if associated report-template is archived
        const reportTemplate = await getReportTemplate(
          this.$appContext,
          taskType.reportTemplate,
        )
        if (reportTemplate) {
          this.setFieldValue('reportTemplate', reportTemplate.id)
        }
      }

      this.isLoadingReportTemplate = false
    },
  },
})
</script>
