import { CollectionQuery } from '@/tt-widget-components/types'
import { Dimension, Measure } from '@/tt-widget-factory/definitions'
import {
  FormLabelTypes,
  OpenAPICompatibleSchema,
} from '@tracktik/tt-json-schema-form'
import { CustomFilterMeta } from './types/CustomFilters'

export * from './types/CustomFilters'

export interface AttributeFilterInterface {
  absoluteName: string
  attribute: Attribute
}

export interface ResourceAuditorInterface {}

export enum UserPortals {
  ADMIN = 'admin',
  STAFF = 'staff',
  CLIENT = 'client',
  VENDOR = 'vendor',
}

export enum FormSchemaTypes {
  EDIT = 'put',
  CREATE = 'post',
}

export enum FieldTypes {
  TimeStampNumber = 'TimeStampNumber',
  Image = 'Image',
  TimeZone = 'TimeZone',
  Boolean = 'Boolean',
  PhoneNumber = 'PhoneNumber',
  Enum = 'Enum',
  TimeStampDate = 'TimeStampDate',
  Date = 'Date',
  DateTime = 'DateTime',
  Email = 'Email',
  Uri = 'Uri',
  Label = 'Label',
  TextArea = 'TextArea',
  Relation = 'Relation',
  RelationList = 'RelationList',
  Time = 'Time',
  Currency = 'Currency',
  CurrencyRate = 'CurrencyRate',
  Number = 'Number',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Password = 'Password',
  GeoRegion = 'GeoRegion',
  GeoCountry = 'GeoCountry',
  GeoPostalCode = 'GeoPostalCode',
  GeoPosition = 'GeoPosition',
  GeoPositionLatitude = 'GeoPositionLatitude',
  GeoPositionLongitude = 'GeoPositionLongitude',
  JSON = 'JSON',
  CSV = 'CSV',
  Minutes = 'Minutes',
  Hours = 'Hours',
}

export type TypeFieldTypes =
  | FieldTypes.TimeStampNumber
  | FieldTypes.Image
  | FieldTypes.TimeZone
  | FieldTypes.Boolean
  | FieldTypes.PhoneNumber
  | FieldTypes.Enum
  | FieldTypes.TimeStampDate
  | FieldTypes.Date
  | FieldTypes.DateTime
  | FieldTypes.Email
  | FieldTypes.Uri
  | FieldTypes.Label
  | FieldTypes.TextArea
  | FieldTypes.Relation
  | FieldTypes.RelationList
  | FieldTypes.Time
  | FieldTypes.Currency
  | FieldTypes.CurrencyRate
  | FieldTypes.Number
  | FieldTypes.Integer
  | FieldTypes.Decimal
  | FieldTypes.Password
  | FieldTypes.GeoRegion
  | FieldTypes.GeoCountry
  | FieldTypes.GeoPostalCode
  | FieldTypes.GeoPosition
  | FieldTypes.GeoPositionLatitude
  | FieldTypes.GeoPositionLongitude

export type RawTypes =
  | 'string'
  | 'integer'
  | 'boolean'
  | 'float'
  | 'double'
  | 'array'

export interface ResourcesMeta {
  [k: string]: Resource
}

export interface ResourceDomain {
  name: string
  id: string
}

export enum SecurityRuleOperation {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  EXECUTE = 'execute',
  DELETE = 'delete',
}

export interface SecurityRule {
  operations: SecurityRuleOperation[]
  portals: UserPortals[]
}

export interface Resource {
  labels?: Labels | null
  operations?: Operations
  domain?: ResourceDomain
  module?: ResourceModule | null
  attributes: AttributeDictionary
  scopes?: ResourceScopeDictionary
  secured?: SecurityRule[]
  isRootResource?: boolean
  actions?: null | {
    [k: string]: any
  }
  modelContext?: ResourceModelContext
  extensions?: ExtensionDictionary
  customFilters?: Record<string, CustomFilterMeta>

  [k: string]: any
}

export interface Labels {
  label?: string
  description?: string
  placeholder?: string

  [k: string]: any
}

export interface Operations {
  post: boolean
  put: boolean
  patch: boolean

  [k: string]: any
}

export interface ResourceModule {
  name: string
  feature?: string

  [k: string]: any
}

export interface AttributeDictionary {
  [k: string]: Attribute
}

export type RelationFilter = {
  customFilters?: CollectionQuery['customFilters']
  filters?: CollectionQuery['filters']
  includeInactive?: CollectionQuery['includeInactive']
  scopes?: CollectionQuery['scope']
  whereQL?: CollectionQuery['whereQL']
}

export interface Attribute {
  resource?: string
  name?: string
  enum?: null | string[]
  labels?: AttributeLabels | null
  onDemand?: boolean
  type?: FieldTypes
  rawType: RawTypes | null | string
  operations?: Operations | null
  rules?: Rules | null
  relation?: Relation | null
  groups?: string[] | null
  defaultValue?: null | string | number | boolean
  measure?: AttributeMeasure | null
  dimension?: AttributeDimension | null
  deprecated?: boolean

  [k: string]: any
}

export interface AttributeLabels {
  label?: string
  description?: string
  placeholder?: string
  list?: LabelList
  key?: string
  nullBehaviorMessage?: string

  [k: string]: any
}

export interface LabelList {
  [k: string]: { label: string }
}

export interface Rules {
  Choice?: ChoiceRule
  NotBlank?: NotBlankRule
  LessThan?: LessThanRule
  LessThanOrEqual?: LessThanOrEqualRule
  GreaterThan?: GreaterThanRule
  GreaterThanOrEqual?: GreaterThanOrEqualRule
  Length?: LengthRule
  Range?: RangeRule
  Regex?: RegexRule

  [k: string]: any
}

export interface ChoiceRule {
  choices: string[]
  multiple: boolean
  strict: boolean
  message: string
  multipleMessage: string
  minMessage: string
  maxMessage: string

  [k: string]: any
}

export interface NotBlankRule {
  message: string

  [k: string]: any
}

export interface LessThanRule {
  value: number

  [k: string]: any
}

export interface LessThanOrEqualRule {
  value: number

  [k: string]: any
}

export interface GreaterThanRule {
  value?: number
  propertyPath?: string

  [k: string]: any
}

export interface GreaterThanOrEqualRule {
  value?: number

  [k: string]: any
}

export interface LengthRule {
  min: number
  max: number

  [k: string]: any
}

export interface RangeRule {
  min?: number
  max?: number

  [k: string]: any
}

export interface RegexRule {
  pattern: string

  [k: string]: any
}

export enum RelationTypes {
  BelongsTo = 'BelongsTo',
  HasOne = 'HasOne',
  HasMany = 'HasMany',
  ManyToMany = 'ManyToMany',
}

export interface Relation {
  resource: string
  embed?: boolean
  type?: RelationTypes
  relationFilter?: RelationFilter
  [k: string]: any
}

export interface AttributeMeasure {
  sum?: boolean
  count?: boolean
  avg?: boolean
  min?: boolean
  max?: boolean

  [k: string]: any
}

export interface AttributeDimension {
  [k: string]: string | boolean
}

export interface ExtensionAttribute {
  attributes?: ExtensionAttributesDictionnary
  labels: Labels
  rawType: string
}

export interface ExtensionAttributesDictionnary {
  [name: string]: ExtensionAttribute
}

export interface Extension {
  labels: Labels
  operations: Operations
  attributes: ExtensionAttributesDictionnary
  secured: SecurityRule[]
}

export interface ExtensionDictionary {
  [name: string]: Extension
}

export interface ResourceScopeDictionary {
  [k: string]: ResourceScope
}

export interface ResourceScope {
  key?: string
  labels?: Labels
  providesTimeline?: boolean

  [k: string]: any
}

export interface ResourceModelContext {
  dateRangeAttribute?: string
  accountAttribute?: string
  regionAttribute?: string

  [k: string]: any
}

export type ExploreRelationOptions = {
  maxDepth?: number
  namesToExclude?: string[]
}

export interface ResourceMetaProviderInterface {
  getAllResources(): ResourcesMeta

  getRootResources(): ResourcesMeta

  /**
   * Return a resource object
   * @param resourceName
   */
  getResource(resourceName: string): Resource | undefined

  hasResource(resourceName: string): boolean

  /**
   * Return a model context object
   * @param resourceName
   * @returns resource model context
   */
  getResourceModelContext(
    resourceName: string,
  ): ResourceModelContext | undefined

  /**
   *
   * @param resourceName
   * @returns resource scopes
   */
  getResourceScopes(resourceName: string): ResourceScopeDictionary | undefined

  /**
   * Return the list of resource names
   */
  getResourceNames(): string[]

  getRootResourceNames(): string[]

  /**
   * Return an attribute.
   * This function supports dotted notation
   * shift.position.region.id
   * @param resourceName
   * @param attributeName
   */
  getAttribute(
    resourceName: string,
    attributeName: string,
  ): Attribute | undefined

  /**
   * Get the list of based attributes
   * @param resourceName
   */
  getAttributes(
    resourceName: string,
    maxDepth?: number,
  ): AttributeDictionary | undefined

  /**
   * Get the list of measures
   * @param resourceName
   * @param maxDepth
   */
  getResourceMeasures(resourceName: string, maxDepth?: number): Measure[]

  /**
   * Get the list of dimensions
   * @param resourceName
   * @param maxDepth
   */
  getResourceDimensions(resourceName: string, maxDepth?: number): Dimension[]

  getFormSchema(
    resourceName: string,
    schemaName: string,
  ): OpenAPICompatibleSchema | undefined

  getPatchAttributeFormSchema(
    resourceName: string,
    attributeName: string,
  ): OpenAPICompatibleSchema | undefined

  getSchemaAttributes(
    resourceName: string,
    schemaName: string,
  ): string[] | undefined

  getAttributeLabelKey(
    resource: string,
    attribute: string,
    type: FormLabelTypes,
    enumValue?: string,
  ): string

  getExtensions(resourceName: string): ExtensionDictionary

  getAllExtensionsPaths(resourceName: string): string[]

  getExtensionPaths(resourceName: string, extensionName: string): string[]

  getAttributePathLabelKeys(resource: string, attrPath: string): string[]

  getCustomFilters(resource: string): Record<string, CustomFilterMeta>

  getCustomFilter(resource: string, customFilterName: string): CustomFilterMeta

  getUpdatedRelationListAttribute(
    resourceName: string,
    fields: CollectionQuery['fields'],
  ): CollectionQuery['fields']

  /**
   * Return a resource label
   * @param resourceName
   */
  getResourceLabel(resourceName: string): string
}

export type ResourceMetaManagerInterface = {
  getProviders(): ResourceMetaProviderInterface[]
  isDateAttribute(resource: string, attribute: string): boolean
  registerProvider(provider: ResourceMetaProviderInterface): void
} & ResourceMetaProviderInterface
