// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OperationCenterOperatorsTile from './OperationCenterOperatorsTile.vue'
import OperationCenterOperatorsColumns from './OperationCenterOperatorsColumns'
import OperationCenterOperatorsDetail from './OperationCenterOperatorsDetail.vue'
import OperationCenterOperatorsReference from './OperationCenterOperatorsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OperationCenterOperatorsTile', OperationCenterOperatorsTile)
    Vue.component(
      'OperationCenterOperatorsDetail',
      OperationCenterOperatorsDetail,
    )
    Vue.component(
      'OperationCenterOperatorsReference',
      OperationCenterOperatorsReference,
    )

    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_OPERATORS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OperationCenterOperatorsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_OPERATORS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OperationCenterOperatorsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_OPERATORS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterOperatorsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_OPERATORS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OperationCenterOperatorsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
