// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftPeriodSummariesTile from './ShiftPeriodSummariesTile.vue'
import ShiftPeriodSummariesColumns from './ShiftPeriodSummariesColumns'
import ShiftPeriodSummariesDetail from './ShiftPeriodSummariesDetail.vue'
import ShiftPeriodSummariesReference from './ShiftPeriodSummariesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftPeriodSummariesTile', ShiftPeriodSummariesTile)
    Vue.component('ShiftPeriodSummariesDetail', ShiftPeriodSummariesDetail)
    Vue.component(
      'ShiftPeriodSummariesReference',
      ShiftPeriodSummariesReference,
    )

    modularManager.addResourcePreset(
      Resources.SHIFT_PERIOD_SUMMARIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftPeriodSummariesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PERIOD_SUMMARIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftPeriodSummariesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PERIOD_SUMMARIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftPeriodSummariesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PERIOD_SUMMARIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftPeriodSummariesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
