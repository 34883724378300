// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import FilesTile from './FilesTile.vue'
import FilesColumns from './FilesColumns'
import FilesDetail from './FilesDetail.vue'
import FilesMetaCard from './FilesMetaCard.vue'
import FilesReference from './FilesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('FilesTile', FilesTile)
    Vue.component('FilesDetail', FilesDetail)
    Vue.component('FilesMetaCard', FilesMetaCard)
    Vue.component('FilesReference', FilesReference)

    modularManager.addResourcePreset(Resources.FILES, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'FilesTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.FILES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: FilesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.FILES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'FilesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.FILES, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'FilesMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.FILES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'FilesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
