import VueRouter from 'vue-router'
import { AppContext } from '@/tt-app-context'
import { handleNavigationError } from '@/helpers/router/error-handler'
import { ActionMenuItem, ActionTypes } from './types'

const { redirected, aborted, duplicated } = VueRouter.NavigationFailureType
/**
 * Execute the actions
 * @param action
 */
export function executeAction(
  appContext: AppContext,
  router: any,
  action: ActionTypes,
  contextPayload: any = {},
) {
  switch (action.type) {
    case 'externalRoute':
      window.open(
        action.props.href,
        action.props.openInNewTab ? '_blank' : '_self',
      )
      break
    case 'route':
      router
        .push(action.props)
        .catch(handleNavigationError([redirected, aborted, duplicated]))
      break
    case 'dispatch':
      //@ts-ignore
      appContext.eventManager.dispatchEvent(action.props.eventName, {
        ...action.props.payload,
        ...contextPayload,
      })
  }
}

export const validateAssertion = (item: ActionMenuItem): boolean => {
  const assert = item.assert ?? null

  // no assertion, we default to true
  if (assert === null) return true

  // function
  if (typeof assert === 'function') return assert({ availableActions: [] })

  // boolean
  return assert
}
