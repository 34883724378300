// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerPhoneCallsTile from './LoneWorkerPhoneCallsTile.vue'
import LoneWorkerPhoneCallsColumns from './LoneWorkerPhoneCallsColumns'
import LoneWorkerPhoneCallsDetail from './LoneWorkerPhoneCallsDetail.vue'
import LoneWorkerPhoneCallsMetaCard from './LoneWorkerPhoneCallsMetaCard.vue'
import LoneWorkerPhoneCallsReference from './LoneWorkerPhoneCallsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LoneWorkerPhoneCallsTile', LoneWorkerPhoneCallsTile)
    Vue.component('LoneWorkerPhoneCallsDetail', LoneWorkerPhoneCallsDetail)
    Vue.component('LoneWorkerPhoneCallsMetaCard', LoneWorkerPhoneCallsMetaCard)
    Vue.component(
      'LoneWorkerPhoneCallsReference',
      LoneWorkerPhoneCallsReference,
    )

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONE_CALLS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhoneCallsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONE_CALLS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerPhoneCallsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONE_CALLS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhoneCallsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONE_CALLS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhoneCallsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_PHONE_CALLS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerPhoneCallsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
