<template>
  <tt-attr
    name="balanceDays"
    :class="{ 'red--text': balanceIsNegative }"
    :suffix="` ${translationDays}`"
  >
    {{ balanceDaysRounded }}
  </tt-attr>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import round from 'lodash/round'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsBalanceDays',
  inject: ['getItemHook'],
  computed: {
    balanceDaysRounded(): number {
      return round(this.getItemHook().get('balanceDays'), 2)
    },
    translationDays(): string {
      return this.$tc('common.days', this.balanceDaysRounded)
    },
    balanceIsNegative(): boolean {
      return this.balanceDaysRounded < 0
    },
  },
})
</script>
