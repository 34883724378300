import LayoutManager from './LayoutManager'
import {
  back,
  goTo,
  hasAtLeastOnePage,
  PageStackHandler,
} from './PageStackHandler'
import { LayoutMultiPages } from './types'

export const createLayoutPageStackHandler = (
  layoutManager: LayoutManager,
  component: LayoutMultiPages,
): PageStackHandler => {
  const getPageStack = () => layoutManager.getPages(component)

  const setPageStack = (pages) => {
    layoutManager.setPageStack(component, pages)
  }

  return {
    back: () => {
      setPageStack(back(getPageStack()))
    },
    getPageStack,
    goTo: (index) => {
      setPageStack(goTo(getPageStack(), index))
    },
    hasAtLeastOnePage: () => hasAtLeastOnePage(getPageStack()),
    next: (page) => {
      layoutManager.nextPage(component, page)
    },
    reset: () => {
      layoutManager.resetPages(component)
    },
    setPageStack,
  }
}
