import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import {
  DraggableInterface,
  DroppableTypes,
} from '@/apps/app.tracktik.insights.studio/types'
import DashboardWidgetHook from './DashboardWidgetHook'
import { WidgetPosition } from './types'
import { ChooseWidgetInDashboard } from '@/plugins/o11n'

export type DashboardDragAndDropProvider = {
  dashboardWidgetDragAndDropService: () => DashboardWidgetDragAndDropService
}

export default class DashboardWidgetDragAndDropService {
  private hook: DashboardWidgetHook
  private analytics
  private initialPosition: WidgetPosition

  constructor(hook: DashboardWidgetHook, analytics) {
    this.hook = hook
    this.analytics = analytics
    this.initialPosition = Vue.observable({} as WidgetPosition)
  }

  public dragStartPosition(position: WidgetPosition): void {
    this.initialPosition = position
  }

  public getInitialPosition(): WidgetPosition {
    return this.initialPosition
  }

  /**
   * Drop widget on dasboard tab aera or widget aera
   */
  public dropOverWidget(
    event: DraggableInterface,
    position: WidgetPosition,
  ): void {
    const { rowIndex, colIndex, tabIndex } = position
    const widget = event.payload?.widget || {}

    if (event.from === 'switching') {
      const draggedRowColPositions = {
        rowIndex: event.payload?.rowIndex,
        colIndex: event.payload?.colIndex,
        tabIndex: event.payload?.tabIndex,
      }

      // Checks if widget has been dropped at the same dragged widget point
      if (!isEqual(draggedRowColPositions, position)) {
        this.hook.switchWidgets(event.payload, position)
      }
    } else {
      this.analytics.track(
        ChooseWidgetInDashboard.create({
          label: event.payload.widget,
        }),
      )

      if (event.type !== DroppableTypes.WIDGET) {
        return
      }
      this.hook.place(rowIndex, colIndex, tabIndex, widget)
    }
  }

  /**
   * Drop widget on plus button aera
   */
  public dropAndAddWidget(
    event: DraggableInterface,
    position: WidgetPosition,
  ): number {
    let index = 0

    const draggedRowColPositions: WidgetPosition = {
      rowIndex: event.payload?.rowIndex,
      colIndex: event.payload?.colIndex,
      tabIndex: event.payload?.tabIndex,
    }
    if (event?.from === 'switching') {
      index = this.hook.addDraggedWidget(draggedRowColPositions, position)
    } else {
      index = this.hook.addTab(
        position.rowIndex,
        position.colIndex,
        event.payload?.widget,
      )
    }
    return index
  }
}
