<template>
  <v-card flat outlined>
    <v-toolbar class="pt-1" height="20" flat>
      <v-spacer />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon small v-on="on" @click="clearLocation">
            <v-icon small v-text="'close'" />
          </v-btn>
        </template>
        <span
          v-text="$t('tt-entity-design.dispatch-tasks.clear-custom-location')"
        />
      </v-tooltip>
    </v-toolbar>
    <v-list class="py-0">
      <v-list-item class="tt-tile pb-6 pt-1">
        <v-list-item-action class="my-0 mr-4">
          <v-icon x-large color="ttPrimary" v-text="'mdi-map-marker-radius'" />
        </v-list-item-action>
        <v-list-item-content class="py-0">
          <v-list-item-subtitle class="pb-1">
            <span v-text="`lat: ${latitude}`" />
          </v-list-item-subtitle>
          <v-list-item-subtitle class="pb-1">
            <span v-text="`lng: ${longitude}`" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="region" class="pb-1">
            <span v-text="`${$t('common.label.region')}: ${region}`" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormCoordinatesTile',
  inject: ['formHook', 'service'],
  computed: {
    latitude(): string {
      return this.formHook().getPathValue('location.latitude')
    },
    longitude(): string {
      return this.formHook().getPathValue('location.longitude')
    },
    region(): string {
      return this.formHook().getPathValue('location.region')
    },
  },
  methods: {
    clearLocation() {
      this.service.setIsCustomLocation(false)
    },
  },
})
</script>
