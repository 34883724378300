<template>
  <div>
    <json-field name="name" />
    <json-field name="customId" />
    <json-field name="durationMinutes" />
    <json-field name="beginServiceDate" />
    <json-field name="endServiceDate" />
    <json-field name="priceTier" />
    <json-field name="taskType" />
    <json-field name="zonePosition" />
    <json-field name="description" />
    <json-field name="instructions" :loading="isLoadingTaskInstruction" />
    <json-field name="postOrders" />
    <json-field name="assets" />
    <json-field name="reportTemplate" :loading="isLoadingReportTemplate" />
    <json-field name="checkpointTourOption" />
    <json-field name="checkpointTour" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import {
  getTaskSiteInstruction,
  getReportTemplate,
  getTaskType,
} from '../task-site-instructions/helpers/task-site-instructions-api-helper'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'MobileScheduleGroupsForm',
  inject: ['formHook'],
  data() {
    return {
      isLoadingTaskInstruction: false,
      isLoadingReportTemplate: false,
    }
  },
  computed: {
    isNew(): boolean {
      return !this.getFieldValue('id')
    },
    taskTypeId(): number {
      return this.getFieldValue('taskType')
    },
  },
  watch: {
    async taskTypeId(taskTypeId) {
      if (taskTypeId && this.isNew) {
        this.syncTaskTypeInstructions()
        this.syncTaskTypeReportTemplate()
      }
    },
  },
  methods: {
    getFieldValue(field: string): any {
      return this.formHook().getPathValue(field)
    },
    setFieldValue(field: string, value: any): void {
      this.formHook().setObjectValue(field, value)
    },
    async syncTaskTypeInstructions(): Promise<void> {
      this.isLoadingTaskInstruction = true

      const clientId = this.getFieldValue('client')

      // Get selected task-type's associated checkpoint-tour, if any
      const taskSiteInstruction = await getTaskSiteInstruction(
        this.$appContext,
        { clientId, taskTypeId: this.taskTypeId },
        ['jobInstructions'],
      )
      this.setFieldValue(
        'instructions',
        taskSiteInstruction?.jobInstructions ?? '',
      )

      this.isLoadingTaskInstruction = false
    },
    async syncTaskTypeReportTemplate(): Promise<void> {
      this.isLoadingReportTemplate = true

      const taskTypeId = this.getFieldValue('taskType')
      const reportTemplateId = this.getFieldValue('reportTemplate')

      // Get selected task-type's associated report-template, if any
      const taskType = await getTaskType(this.$appContext, taskTypeId, [
        'reportTemplate',
      ])
      if (
        taskType?.reportTemplate &&
        reportTemplateId !== taskType.reportTemplate
      ) {
        // Check if associated report-template is archived
        const reportTemplate = await getReportTemplate(
          this.$appContext,
          taskType.reportTemplate,
        )
        if (reportTemplate) {
          this.setFieldValue('reportTemplate', reportTemplate.id)
        }
      }

      this.isLoadingReportTemplate = false
    },
  },
})
</script>
