import { normalizeName } from '@/helpers/text'
import {
  ColumnDefinition,
  DataTableWidgetModel,
  Sort,
} from '@/tt-widget-components'
import { LeaveManagementTab } from './types'
export interface CreateLeaveManagementTabInterface {
  title: string
  icon: string
  resource: string
  createButtonText: string
  customColumns?: ColumnDefinition[]
  allowActions?: boolean
}

export const createLeaveManagementTab = (
  tab: CreateLeaveManagementTabInterface,
): LeaveManagementTab => {
  const formName = `${normalizeName(tab.resource)}Form`

  const sortByMostRecentItemCreated: Sort = {
    attribute: 'createdOn',
    direction: 'DESC',
  }

  const dataTableWidget: DataTableWidgetModel = {
    is: 'DataTableWidget',
    title: '',
    query: {
      resource: tab.resource,
      sort: [sortByMostRecentItemCreated],
    },
    component: tab.customColumns ? { columns: tab.customColumns } : {},
    toolbar: {
      filterOptions: {
        allowSearch: true,
      },
    },
    allowActions: tab.allowActions,
  }
  return {
    title: tab.title,
    icon: tab.icon,
    resource: tab.resource,
    creationForm: formName,
    listWidget: dataTableWidget,
    createButtonText: tab.createButtonText,
  }
}
