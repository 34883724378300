import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'account',
  'description',
  {
    attributeName: 'access',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'access',
      },
    },
  },
] as ColumnInputDefinition[]
