// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointsTile from './CheckpointsTile.vue'
import CheckpointsColumns from './CheckpointsColumns'
import CheckpointsDetail from './CheckpointsDetail.vue'
import CheckpointsMetaCard from './CheckpointsMetaCard.vue'
import CheckpointsReference from './CheckpointsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CheckpointsTile', CheckpointsTile)
    Vue.component('CheckpointsDetail', CheckpointsDetail)
    Vue.component('CheckpointsMetaCard', CheckpointsMetaCard)
    Vue.component('CheckpointsReference', CheckpointsReference)

    modularManager.addResourcePreset(
      Resources.CHECKPOINTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
