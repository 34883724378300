// do not change this to @/widget-factory; it will break build:schemas
import {
  dimensionModifiers,
  FilterOperatorType,
} from '../../tt-widget-factory/definitions'
import { JSONSchema7 } from '../../../../tt-json-schema-form'
import { AttributeName } from './names'
import { WidgetDownloadType, WidgetDownloadTypes } from '../types'

const {
  AFTER,
  BEFORE,
  BETWEEN,
  CONTAINS,
  ENDSWITH,
  EQUAL,
  GT,
  GTE,
  IN,
  LT,
  LTE,
  NOT,
  STARTSWITH,
  ISNULL,
  ISNOTNULL,
} = FilterOperatorType

const OPERATOR_WITH_VALUE = [
  '',
  AFTER,
  BEFORE,
  BETWEEN,
  CONTAINS,
  ENDSWITH,
  EQUAL,
  GT,
  GTE,
  IN,
  LT,
  LTE,
  NOT,
  STARTSWITH,
]

const OPERATOR_WITHOUT_VALUE = [ISNULL, ISNOTNULL]

// Toolbar.
const toolbar: JSONSchema7 = {
  title: 'Toolbar',
  type: 'object',
  properties: {
    show: {
      title: 'Display toolbar',
      type: 'boolean',
      default: false,
    },
  },
  if: {
    properties: {
      show: {
        const: true,
      },
    },
  },
  then: {
    properties: {
      /**
       * Parameter can be used to avoid having the API returning the total count of records.
       * If `false`, it improves performance of the API request response time.
       */
      displayCounts: {
        title: 'Display Counts',
        type: 'boolean',
        default: false,
      },
      displayReload: {
        title: 'Display refresh button',
        type: 'boolean',
      },
      autoHide: {
        title: 'Auto Hide Toolbar',
        description: 'Hide the toolbar when mouse is not over',
        type: 'boolean',
      },
      filterOptions: {
        $ref: '#/definitions/FilterOptions',
      },
      controls: {
        title: 'Buttons',
        type: 'array',
        items: {
          $ref: '#/definitions/ControlButton',
        },
      },
    },
  },
}

const AnyType: JSONSchema7 = {
  description:
    'Defines a `any` type, but with only valid types (ie: when converted to TS no functions or Symbol allowed)',
  anyOf: [
    {
      type: ['boolean', 'integer', 'null', 'number', 'string'],
    },
    {
      type: 'array',
      items: {
        $ref: '#/definitions/AnyType',
      },
    },
    {
      type: 'object',
      additionalProperties: {
        $ref: '#/definitions/AnyType',
      },
    },
  ],
}

const ContextFilter: JSONSchema7 = {
  allOf: [
    { $ref: '#/definitions/FilterWithValue' },
    {
      properties: {
        attribute: {
          enum: [
            AttributeName.ACCOUNT_ATTRIBUTE,
            AttributeName.REGION_ATTRIBUTE,
            AttributeName.DATE_RANGE_ATTRIBUTE,
          ],
        },
      },
      required: ['attribute'],
    },
  ],
}

const buildAllowDownload = (types: WidgetDownloadTypes[]): JSONSchema7 => {
  return {
    title: 'Allow Download',
    description: 'Choose which formats can be downloaded',
    oneOf: [
      {
        title: 'All file types',
        type: 'boolean',
      },
      {
        title: 'Select specific file type',
        type: 'object',
        properties: types.reduce((acc, type) => {
          acc[type] = { type: 'boolean' } as JSONSchema7

          return acc
        }, {}),
        additionalProperties: false,
      },
    ],
    default: true,
  }
}

/**
 *
 * If a definition is exclusively for a widget, please
 * add this definition in the widget schema directly
 *
 */
export function getCommonSchemaDefinitions(): Record<string, JSONSchema7> {
  return {
    AnyType,
    Toolbar: toolbar,
    DefaultToolbar: {
      ...toolbar,
      default: {
        show: true,
        displayCounts: true,
        filterOptions: {
          showSearch: true,
          showScopes: true,
        },
      },
    },
    AggregationToolbar: {
      ...toolbar,
      default: {
        show: false,
        displayCounts: false,
        filterOptions: {
          allowSearch: false,
          filters: [],
          customFilters: [],
        },
      },
    },
    TQLToolbar: {
      ...toolbar,
      default: {
        show: false,
        displayCounts: true,
        filterOptions: {
          showSearch: false,
          showScopes: true,
        },
      },
    },
    ControlButton: {
      type: 'object',
      properties: {},
    },
    FilterOptions: {
      title: 'Filter Options',
      type: 'object',
      properties: {
        allowIncludeInactive: {
          title: 'Allow Include Inactive',
          type: 'boolean',
          default: false,
        },
        allowScopes: {
          title: 'Show Scopes',
          type: 'boolean',
          default: false,
        },
        allowSearch: {
          title: 'Show Search',
          type: 'boolean',
          default: true,
        },
        allowWhereQL: {
          title: 'Show whereQL',
          type: 'boolean',
          default: false,
        },
        filters: {
          title: 'Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/AttributeFilter',
          },
        },
        customFilters: {
          title: 'Predefined Filter name',
          type: 'array',
          items: {
            anyOf: [
              {
                type: 'string',
              },
              {
                $ref: '#/definitions/CustomFilter',
              },
            ],
          },
        },
        scope: {
          title: 'Scopes',
          $ref: '#/definitions/ResourceScope',
        },
      },
    },
    AttributeFilter: {
      title: 'Attribute',
      type: 'object',
      properties: {
        attributeName: {
          title: 'Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        defaultValue: { $ref: '#/definitions/DefaultValue' },
        options: { $ref: '#/definitions/AttributeFilterOptions' },
        label: { type: 'string' },
      },
    },
    DefaultValue: {
      type: 'object',
      properties: {
        value: { type: ['string', 'number', 'boolean', 'array'] },
        operator: { $ref: '#/definitions/FilterOperator' },
      },
    },
    AttributeFilterOptions: {
      anyOf: [
        { $ref: '#/definitions/EnumAttributeFilterOptions' },
        { $ref: '#/definitions/RelationAttributeFilterOptions' },
      ],
    },
    EnumAttributeFilterOptions: {
      type: 'object',
      properties: {
        exclude: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    RelationAttributeFilterOptions: {
      type: 'object',
      properties: {
        relationFilter: {
          properties: {
            filters: {
              title: 'Filters',
              type: 'array',
              items: {
                $ref: '#/definitions/Filter',
              },
            },
            customFilters: {
              title: 'Predefined Filters',
              type: 'array',
              items: {
                $ref: '#/definitions/CustomFilter',
              },
            },
          },
        },
      },
    },
    CustomFilter: {
      type: 'object',
      properties: {
        filterName: { type: 'string' },
        value: { type: ['string', 'number', 'array'] },
      },
    },
    RichText: { type: 'string' },
    TQLStatement: { type: 'string' },
    TQLContextAttribute: { type: 'string' },
    Color: { type: 'string' },
    ColorPalette: { type: 'string' },
    DisableClick: { type: 'boolean' },
    Resource: { type: 'string', title: 'Data Source' },
    RelationListResource: {
      type: 'object',
      description:
        'This object will be converted to string like: `resource/id/attribute` to be able to fetch and display relationList',
      required: ['resource', 'id', 'attribute'],
      properties: {
        resource: {
          type: 'string',
        },
        id: {
          type: 'number',
        },
        attribute: {
          type: 'string',
        },
      },
    },
    Filters: { type: 'object' },
    Scopes: { type: 'string' },
    WhereQL: { type: 'string' },
    HavingQL: { type: 'string' },
    TQLField: { type: 'string' },
    Field: {
      type: 'object',
      required: ['attribute'],
      properties: {
        attribute: {
          type: 'string',
        },
        alias: {
          type: 'string',
        },
        modifier: {
          type: 'string',
        },
      },
    },
    ResourceAttributeName: {
      title: 'Attribute',
      type: 'string',
    },
    GeoResourceAttributeName: {
      title: 'Attribute',
      type: 'string',
    },
    ResourceScope: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    FilterGroup: {
      title: 'Advanced Filters',
      type: 'object',
      properties: {
        scopes: {
          title: 'Scopes',
          $ref: '#/definitions/ResourceScope',
        },
        includeInactive: {
          title: 'Include Archived / Inactive objects',
          type: 'boolean',
        },
        whereQL: {
          title: 'Where TQL',
          $ref: '#/definitions/WhereQL',
        },
        havingQL: {
          title: 'Having TQL',
          $ref: '#/definitions/TQLField',
        },
        filters: {
          title: 'Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/Filter',
          },
        },
        customFilters: {
          title: 'Predefined Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/CustomFilter',
          },
        },
      },
    },
    Filter: {
      anyOf: [
        { $ref: '#/definitions/FilterWithoutValue' },
        { $ref: '#/definitions/FilterWithValue' },
      ],
    },
    FilterWithValue: {
      type: 'object',
      properties: {
        attribute: {
          title: 'Field name',
          $ref: '#/definitions/ResourceAttributeName',
        },
        operator: {
          title: 'Operator',
          type: 'string',
          enum: [...OPERATOR_WITH_VALUE],
        },
        value: {
          title: 'Value',
          type: ['string', 'number', 'boolean', 'array'],
        },
      },
      required: ['attribute', 'operator', 'value'],
      // @ts-ignore
      additionalProperties: false,
    },
    /**
     * Filter operators `ISNULL` or `ISNOTNULL` do not need a `value`
     */
    FilterWithoutValue: {
      type: 'object',
      properties: {
        attribute: {
          title: 'Field name',
          $ref: '#/definitions/ResourceAttributeName',
        },
        operator: {
          title: 'Operator',
          type: 'string',
          enum: [...OPERATOR_WITHOUT_VALUE],
        },
        value: { type: 'null' },
      },
      required: ['attribute', 'operator'],
      // @ts-ignore
      additionalProperties: false,
    },
    Sort: {
      title: 'Sort Field',
      type: 'object',
      properties: {
        attribute: {
          title: 'Sort Attribute',
          $ref: '#/definitions/ResourceAttributeName',
        },
        direction: {
          title: 'Sort Order',
          $ref: '#/definitions/SortDirection',
        },
      },
      required: ['attribute'],
    },
    SortDirection: {
      title: 'Sort Order',
      default: 'ASC',
      type: 'string',
      enum: ['ASC', 'DESC'],
    },
    AggregationSortItemSingle: {
      title: 'Sort Item',
      type: 'object',
      properties: {
        type: {
          title: 'Sorting criteria',
          type: 'string',
          enum: ['DIMENSION', 'MEASURE'],
        },
        direction: {
          title: 'Sort Order',
          $ref: '#/definitions/SortDirection',
        },
      },
    },
    AggregationSortItemMultiple: {
      title: 'Sort Item',
      type: 'object',
      properties: {
        type: {
          title: 'Sorting criteria',
          type: 'string',
          enum: ['DIMENSIONS', 'MEASURES'],
        },
        index: {
          title: 'Dimension or Measure index',
          type: 'number',
        },
        direction: {
          title: 'Sort Order',
          $ref: '#/definitions/SortDirection',
        },
      },
    },
    AggregationSort: {
      title: 'Sort field',
      type: 'array',
      items: {
        oneOf: [
          { $ref: '#/definitions/AggregationSortItemSingle' },
          { $ref: '#/definitions/AggregationSortItemMultiple' },
        ],
      },
    },
    Dimension: {
      title: 'Group by',
      description: 'The dimension',
      type: 'object',
      properties: {
        attribute: {
          $ref: '#/definitions/ResourceAttributeName',
        },
        alias: {
          type: 'string',
        },
        modifier: {
          title: 'Dimension modifier',
          $ref: '#/definitions/DimensionModifier',
        },
      },
      required: ['attribute'],
    },
    DimensionModifier: {
      title: 'Modifier',
      type: 'string',
      enum: [...dimensionModifiers, ''],
    },
    Measure: {
      title: 'Measure (Count, Sum, etc.)',
      type: 'object',
      properties: {
        operation: {
          $ref: '#/definitions/MeasureOperation',
        },
        attribute: {
          $ref: '#/definitions/ResourceAttributeName',
        },
        alias: {
          type: 'string',
        },
        format: {
          $ref: '#/definitions/MeasureFormat',
        },
      },
      required: ['attribute', 'operation'],
    },
    MeasureFormat: {
      title: 'Format',
      type: 'string',
      enum: ['NA', 'ROUND_0', 'ROUND_2', 'FLOOR', 'CEILING', 'SUFFIX'],
    },
    MeasureOperation: {
      title: 'Measure operation',
      default: 'COUNT',
      type: 'string',
      enum: ['COUNT', 'AVG', 'SUM', 'MIN', 'MAX', 'EXPRESSION'],
    },
    FilterOperator: {
      type: 'string',
      enum: [
        '',
        AFTER,
        BEFORE,
        BETWEEN,
        CONTAINS,
        ENDSWITH,
        EQUAL,
        GT,
        GTE,
        IN,
        LT,
        LTE,
        NOT,
        STARTSWITH,
        ISNULL,
        ISNOTNULL,
      ],
    },
    ResourceGeoMappingOptions: {
      type: 'object',
      title: 'Attribute Mapping',
      required: ['latitudeAttribute', 'longitudeAttribute'],
      properties: {
        latitudeAttribute: {
          title: 'Latitude Attribute',
          $ref: '#/definitions/GeoResourceAttributeName',
        },
        longitudeAttribute: {
          title: 'Longitude Attribute',
          $ref: '#/definitions/GeoResourceAttributeName',
        },
      },
    },
    TextAlign: {
      title: 'enum',
      enum: ['Left', 'Center', 'Right', 'Justify'],
    },
    ComponentReference: {
      title: 'Component Reference',
      type: 'object',
      properties: {
        is: {
          title: 'Component',
          type: 'string',
        },
        props: {
          title: 'Props',
          type: 'object',
          additionalProperties: { $ref: '#/definitions/AnyType' },
        },
      },
    },
    AllowDownloadCsvPdf: buildAllowDownload([
      WidgetDownloadType.CSV,
      WidgetDownloadType.PDF,
    ]),
    AllowDownloadAll: buildAllowDownload([
      WidgetDownloadType.CSV,
      WidgetDownloadType.PDF,
      WidgetDownloadType.XLS,
    ]),
    Formatters: {
      title: 'Formatter',
      enum: [
        'asTimeFromNow',
        'asTimeToNow',
        'asDate',
        'asTime',
        'asDateTime',
        'asShortTime',
        'asVeryShortTime',
        'asLongDate',
        'asLongDateTime',
        'asTimeToNowFromUTC',
        'asCurrency',
        'asRoundedCurrency',
        'asRounded2Decimals',
        'asDistance',
        'asPayRateHourly',
        'asUTCDate',
      ],
    },
    CssClass: {
      title: 'CSS Class',
      anyOf: [
        {
          type: 'string',
        },
        {
          enum: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'subtitle-1',
            'subtitle-2',
            'body-1',
            'body-2',
            'button',
            'caption',
            'overline',
            'text-caption',
            'text-decoration-line-through',
            'text-decoration-overline',
            'text-decoration-underline',
            'text-truncate',
            'text-capitalize',
            'text-uppercase',
            'text-lowercase',
            'font-weight-black',
            'font-weight-bold',
            'font-weight-medium',
            'font-weight-regular',
            'font-weight-light',
            'font-weight-thin',
            'font-italic',
            'text--primary',
            'text--secondary',
            'text--disabled',
            'w-100',
          ],
        },
      ],
    },
    Scope: {
      type: 'string',
    },
    Icon: {
      title: 'Icon',
      description: 'Icon name ex. home',
      type: 'string',
      default: 'insert_chart',
    },
    CollectionQuery: {
      title: 'Collection Query',
      description: 'Query to fetch a collection of records',
      type: 'object',
      properties: {
        resource: {
          $ref: '#/definitions/Resource',
        },
        relationListResource: {
          $ref: '#/definitions/RelationListResource',
        },
        extension: {
          title: 'Extensions',
          type: 'array',
          items: {
            $ref: '#/definitions/ResourceExtension',
          },
        },
        offset: {
          description: 'Page Offset',
          type: 'number',
        },
        limit: {
          description: 'Page Size',
          type: 'number',
        },
        search: {
          title: 'Search Criteria',
          type: 'string',
        },
        sort: {
          oneOf: [
            {
              title: 'Sort attribute',
              type: 'array',
              items: {
                $ref: '#/definitions/Sort',
              },
            },
            {
              $ref: '#/definitions/Sort',
            },
          ],
        },
        filters: {
          title: 'Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/Filter',
          },
        },
        customFilters: {
          title: 'Predefined Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/CustomFilter',
          },
        },
        context: {
          type: 'array',
          items: {
            $ref: '#/definitions/Filter',
          },
        },
        scope: {
          title: 'Common Filters',
          type: 'array',
          items: {
            $ref: '#/definitions/Scope',
          },
        },
        includeInactive: {
          title: 'Include Inactive',
          type: 'boolean',
        },
        fields: {
          type: 'array',
          items: {
            $ref: '#/definitions/Field',
          },
        },
        include: {
          type: 'array',
          items: {
            $ref: '#/definitions/ResourceAttributeName',
          },
        },
        flat: {
          type: 'boolean',
        },
        whereQL: {
          $ref: '#/definitions/WhereQL',
        },
        havingQL: {
          $ref: '#/definitions/HavingQL',
        },
        contextFilters: {
          $ref: '#/definitions/ContextFiltersMap',
        },
        returnCount: {
          type: 'boolean',
          description:
            'Can be set to `false` to not return the total entities count. This will improve performance of the response time of the API. Defaults to `true`.',
        },
      },
      required: ['resource'],
    },
    ResourceExtension: {
      title: 'Extension',
      type: 'string',
    },
    ContextFilter: ContextFilter,
    ContextFiltersMap: {
      type: 'object',
      title: 'Context Reactivity',
      properties: {
        regionAttribute: {},
        dateRangeAttribute: {},
        accountAttribute: {},
      },
    },
    DefaultContextFilters: {
      type: 'object',
      title: 'Default Context Filters',
      properties: {
        defaults: {
          title: 'Context Filter Defaults Values',
          type: 'array',
          items: { $ref: '#/definitions/ContextFilter' },
        },
      },
    },
  }
}
