<template>
  <transition name="slide-fade">
    <v-banner v-if="showBanner" class="pa-2" outlined color="ttIndigo">
      <div class="d-flex">
        <v-icon color="ttPrimary" v-text="'mdi-information-outline'" />
        <span
          class="banner-label font-weight-medium ml-4"
          v-text="bannerLabel"
        />
      </div>
    </v-banner>
  </transition>
</template>

<script lang="ts">
import Vue from 'vue'

import { UnsubscribeFunction } from '@/tt-event-manager'
import { PusherEvents } from '@/tt-app-extensions/pusher/events'
import { EventLogByResource } from '@/tt-app-extensions/pusher/types'

export default Vue.extend({
  name: 'FormUpdatedBanner',
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      unsubscribe: [] as UnsubscribeFunction[],
      showBanner: false,
    }
  },
  computed: {
    bannerLabel(): string {
      const resourceName = this.$t(`res.${this.resourceName}.label`)

      return this.$t('common.form_banner_updated.text', {
        resource: resourceName,
      })
    },
  },
  beforeDestroy() {
    this.unsubscribe.forEach((fx) => fx())
  },
  created() {
    this.listenUpdatedEntity()
  },
  methods: {
    listenUpdatedEntity(): void {
      this.unsubscribe.push(
        this.$eventManager.subscribeEvent(
          PusherEvents.UPDATED_ENTITIES,
          (payload: EventLogByResource) => {
            const entitiesUpdate = payload[this.resourceName] || []

            this.showBanner = entitiesUpdate.some(
              ({ entityId }) => entityId === this.entityId,
            )
          },
        ),
      )
    },
  },
})
</script>

<style scoped>
.banner-label {
  color: var(--v-ttPrimary-base);
}
.slide-fade-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
