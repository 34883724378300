// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PostOrderSubjectsTile from './PostOrderSubjectsTile.vue'
import PostOrderSubjectsColumns from './PostOrderSubjectsColumns'
import PostOrderSubjectsDetail from './PostOrderSubjectsDetail.vue'
import PostOrderSubjectsReference from './PostOrderSubjectsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PostOrderSubjectsTile', PostOrderSubjectsTile)
    Vue.component('PostOrderSubjectsDetail', PostOrderSubjectsDetail)
    Vue.component('PostOrderSubjectsReference', PostOrderSubjectsReference)

    modularManager.addResourcePreset(
      Resources.POST_ORDER_SUBJECTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PostOrderSubjectsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_SUBJECTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PostOrderSubjectsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_SUBJECTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PostOrderSubjectsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_SUBJECTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PostOrderSubjectsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
