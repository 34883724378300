<template>
  <TChipLabel v-bind="payableChipProps" />
</template>

<script lang="ts">
import Vue from 'vue'

const sidePanelClass = 'side-panel'

export default Vue.extend({
  name: 'PayrollCodesPayableChip',
  props: {
    rawValue: {
      type: Boolean,
      required: true,
    },
    valueCssClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    isSidePanel(): boolean {
      return this.valueCssClass === sidePanelClass
    },
    payable(): boolean {
      return this.rawValue
    },
    color(): string {
      return this.payable ? 'bg-green1' : ''
    },
    textColor(): string {
      return this.payable ? 'bg-text-green1' : ''
    },
    chipText(): string {
      return this.$t(
        `tt-entity-design.payroll-codes.attrs.payable.labels.list.${this.payable}.label`,
      )
    },
    payableChipProps(): Record<string, unknown> {
      return {
        textKey: this.chipText,
        textColor: this.textColor,
        backgroundColor: this.color,
        modifiers: {
          class: ['mx-3 px-2'],
          xSmall: this.isSidePanel,
        },
        type: 'LABEL',
        isSmall: !this.isSidePanel,
      }
    },
  },
})
</script>
