import {Resource, ResourceMetaProviderInterface} from '@/tt-widget-factory/services/resource-meta/types'
import parser, {convertParserJSONToTQL, OperandTypes, TQLJSON, TQLOperand} from './parser'
import {aggregationFunctions} from "@/tt-tql-inputs/src/lang/types";
import {DataTableWidgetModel} from "@/tt-widget-components";

export interface TQLValidatorInput {
  tqlStatement: string
  output: {
    guessedResourceName?: string
    valid: boolean
    resourceName: string
    attributeNames: string[]
    errors: any[]
  }
}

export interface TQLLang {
  version: string,
  engine: string,
  description: string,
  features: {
    subQueries: boolean,
    subQueriesInPredicate: boolean,
  }
  syntax: {
    functions: string[],
    constants: string[],
    operators: string[],
    operands: string[],
    unsupported: string[]
  }
}

export class TQLValidator {
  readonly metaProvider: ResourceMetaProviderInterface
  readonly lang: TQLLang
  readonly parentAttributes: string[]
  ignoreValidation: boolean

  constructor(
    metaProvider: ResourceMetaProviderInterface,
    lang: TQLLang,
    ignoreValidation = false,
    parentAttributes: string[] = []
  ) {
    this.metaProvider = metaProvider
    this.lang = lang
    this.ignoreValidation = ignoreValidation
    this.parentAttributes = parentAttributes
  }


  validate(input: TQLValidatorInput) {

    try {
      if (!input.output.errors) {
        input.output.errors = []
      }
      const tqlJSON: TQLJSON = parser(input.tqlStatement)
      if (!tqlJSON) {
        return this.onError(input, 'Invalid Syntax')
      }

      if (tqlJSON.hasJoins) {
        return this.onError(input, '`JOINS` are not allowed in TQL')
      }
      if (tqlJSON.hasMultiTables) {
        return this.onError(input, 'Only one table is allowed in the `FROM` TQL')
      }
      if (tqlJSON.hasTableAlias) {
        return this.onError(input, 'The `FROM` Table alias is not supported in TQL')
      }

      const tqlFinder = new TQLFinder(tqlJSON)

      this.testTable(input, tqlFinder)
      this.testSubQueries(input, tqlFinder)
      this.testStatement(input, tqlFinder)

      if (tqlFinder.subQueries.length > 0) {

        let parentAttributes = input.output.attributeNames.map((attribute) => `mainTable.${attribute}`)

        tqlFinder.subQueries.forEach((subQuery) => {
          let subQueryValidator = new TQLValidator(this.metaProvider, this.lang, this.ignoreValidation, parentAttributes)
          let subQueryInput = {
            tqlStatement: convertParserJSONToTQL(subQuery),
            output: {
              valid: false,
              resourceName: '',
              attributeNames: [],
              errors: []
            }
          }
          if (!subQueryValidator.validate(subQueryInput)) {
            subQueryInput.output.errors.forEach((error) => {
              input.output.errors.push(error)
            })
          }
        })
      }

      input.output.valid = input.output.errors.length === 0
      return input.output.valid

    } catch (e) {
      return this.onError(input, e.message)
    }
  }

  public testSubQueries(input: TQLValidatorInput, tqlFinder: TQLFinder): void {

    // We do not have any sub-queries
    if (!this.hasAny([OperandTypes.InSubQueryPredicate, OperandTypes.Select], tqlFinder.operands)) {
      return
    }
    // Sub-queries not allowed in sub-queries
    if (this.isSubQuery) {
      throw new Error('Sub-Queries are not allowed in Sub-Queries')
    }

    if (!this.lang.features.subQueriesInPredicate && this.hasAny([OperandTypes.InSubQueryPredicate], tqlFinder.operands)) {
      throw new Error('Sub-Queries In Predicate not supported')
    }
    if (!this.lang.features.subQueries && this.hasAny([OperandTypes.SubQuery], tqlFinder.operands)) {
      throw new Error('Sub-Queries not supported')
    }
  }

  /**
   * Return the resource
   * @param tql
   */
  getResource(tql: string) {
    try {
      const out = parser(tql)
      return out.model
    } catch (e) {
      console.log(e)
      return null
    }
  }


  private testTable(input: TQLValidatorInput, tqlFinder: TQLFinder): void {

    const resourceNames = this.metaProvider.getResourceNames();
    const tableAsResource = tqlFinder.tableAsResource


    if (!resourceNames.includes(tableAsResource)) {
      throw new Error(`Invalid table \`${tqlFinder.table}\``)
    }

    const resource: Resource = this.metaProvider.getResource(tableAsResource)
    if (!resource) {
      throw new Error(`Invalid table \`${tqlFinder.table}\``)
    }

    if (this.isSubQuery) {
      if (tqlFinder.selectColumnsCount !== 1) {
        throw new Error('Sub-Queries can only have one select')
      }
    }

    // Set the attributes names
    input.output.attributeNames = [...Object.keys(this.metaProvider.getAttributes(tableAsResource, 3)), ...this.parentAttributes]
  }

  get isSubQuery(): boolean {
    return this.parentAttributes.length > 0
  }

  private testAllowed(allowed: string[], used: string[], message: string, input: TQLValidatorInput) {
    const unsupported = used.filter((item: string) => {
      return !allowed.includes(item)
    })

    if (unsupported.length) {
      input.output.errors.push(message + unsupported.join(', '))
    }
  }

  private hasAny(notAllowed: string[], used: string[]): boolean {
    const unsupported = used.filter((item: string) => {
      return notAllowed.includes(item)
    })
    return unsupported.length > 0
  }

  public testStatement(input, tqlFinder) {


    const aliases = tqlFinder.aliases
    const attributeNames = input.output.attributeNames
    const allowedForWhereAndSelect: string[] = [...attributeNames, ...this.lang.syntax.constants]
    const allowedForOrderAndGroups: string[] = [...aliases, ...allowedForWhereAndSelect];
    const allowedForHaving: string[] = [...aliases, ...this.lang.syntax.constants];
    const allowedFunctions: string[] = this.lang.syntax.functions;

    this.testAllowed(
      allowedForWhereAndSelect,
      [...tqlFinder.whereIdentifiers],
      'Unknown attributes in the `WHERE`: ',
      input
    )

    this.testAllowed(
      allowedForWhereAndSelect,
      [...tqlFinder.selectIdentifiers],
      'Unknown attributes in the `SELECT`: ',
      input
    )

    this.testAllowed(
      allowedForOrderAndGroups,
      [...tqlFinder.groupByIdentifiers],
      'Unknown attributes in the `GROUP BY`: ',
      input
    )

    this.testAllowed(
      allowedForOrderAndGroups,
      [...tqlFinder.orderByIdentifiers],
      'Unknown attributes in the `ORDER BY`: ',
      input
    )

    this.testAllowed(
      allowedForHaving,
      tqlFinder.havingIdentifiers,
      'Unknown attributes in HAVING clause: ',
      input
    );

    this.testAllowed(
      [...allowedFunctions, ...aggregationFunctions],
      tqlFinder.functions,
      'Unknown functions: ',
      input
    )

  }

  private onError(input, message) {
    input.output.errors.push(message)
    input.output.valid = false
    return false
  }
}


export interface TQLResultRow {
  __group_0?: string
  __group_1?: string
  __group_2?: string
  __group_3?: string
  __group_4?: string
  __group_5?: string
  __group_6?: string
  __id?: string

  [key: string]: string
}

enum OperandScopes {
  Select = 'Select',
  Where = 'Where',
  GroupBy = 'GroupBy',
  OrderBy = 'OrderBy',
  Having = 'Having',
}

export class TQLZoom {
  private tql: string
  private tqlJSON: TQLJSON

  constructor(tql: string) {
    this.tql = tql
    this.tqlJSON = parser(tql)
  }

  public asDataTableWidget(row: TQLResultRow): DataTableWidgetModel {
    return {
      is: 'DataTableWidget',
      title: 'TQL Result',
      allowActions: false,
      query: {
        resource: this.tqlJSON.model,
        whereQL: this.whereQL(row),
        havingQL: this.havingQL()
      }
    }
  }

  private whereQL(row: TQLResultRow): string {

    const whereOperands: TQLOperand[] = this.where(row);
    if (this.tqlJSON.where) {
      whereOperands.push(this.tqlJSON.where)
    }
    return whereOperands.map((operand) => {
      return convertParserJSONToTQL(operand)
    }).join(' AND ')
  }

  private havingQL(): string | null {
    if (!this.tqlJSON.having) {
      return null
    }
    return convertParserJSONToTQL(this.tqlJSON);
  }

  private where(row: TQLResultRow): TQLOperand[] {

    // If we have an ID, it is a simple row
    if (row.__id) {
      return [{
        type: OperandTypes.ComparisonBooleanPrimary,
        left: {
          type: OperandTypes.Identifier,
          value: 'id'
        },
        right: {
          type: OperandTypes.String,
          value: this.quote(row.__id)
        },
        operator: '='
      }]
    }

    // No group by but aggregations ex: select sum(x) from table where y = 1
    return Object.keys(row).sort().filter((key) => key.startsWith('__group_')).map((key) => {
      const groupIndex = parseInt(key.replace('__group_', ''))
      return {
        type: OperandTypes.ComparisonBooleanPrimary,
        left: this.tqlJSON.groupBy[groupIndex],
        right: {
          type: OperandTypes.String,
          value: this.quote(row[key])
        },
        operator: '='
      }
    })

  }

  private quote(val) {
    return "'" + val.replace(/'/g, "''") + "'"
  }

}

/**
 * Finder
 */
export class TQLFinder {
  private usedFunctions: string[] = []
  private usedIdentifiers: string[] = []
  public groupByIdentifiers: string[] = []
  public selectIdentifiers: string[] = []
  public whereIdentifiers: string[] = []
  public havingIdentifiers: string[] = []
  public orderByIdentifiers: string[] = []
  private usedAliases: string[] = []
  private usedOperands: string[] = []
  public subQueries: any[] = []
  private tqlJSON: TQLJSON | null = null


  constructor(tqlJSON: TQLJSON = null) {
    if (tqlJSON) {
      this.setJSON(tqlJSON)
    }
  }

  table: string = ''

  get tableAsResource(): string | null {
    return this.table?.replace(/_/g, '-') ?? null
  }

  get selectColumnsCount(): number {
    return this.tqlJSON.selects?.length ?? 0
  }

  addOperand(operand: TQLOperand | null, scope: OperandScopes) {
    if (!operand) {
      return
    }

    if (operand.type == OperandTypes.Select) {
      console.log(operand)
      console.log(convertParserJSONToTQL(operand))
      this.subQueries.push(operand)
      return;
    }

    // Check the operand type
    if (!this.usedOperands.includes(operand.type)) {
      this.usedOperands.push(operand.type)
    }

    // Keep the alias we used
    if (operand.alias) {
      this.usedAliases.push(operand.alias)
    }

    // Function call. Register the function
    if (operand.type == OperandTypes.FunctionCall && operand.name) {
      this.usedFunctions.push(operand.name.toUpperCase())
    }

    // Function call. Register the function
    if (operand.type == OperandTypes.Identifier) {

      let identifier = operand.value
      if (identifier.startsWith(this.table + '.')) {
        // Remove the table name from the beginning
        identifier = identifier.replace(this.table + '.', '')
      }


      this.usedIdentifiers.push(identifier)

      switch (scope) {
        case OperandScopes.Select:
          this.selectIdentifiers.push(identifier)
          break
        case OperandScopes.GroupBy:
          this.groupByIdentifiers.push(identifier)
          break
        case OperandScopes.Having:
          this.havingIdentifiers.push(identifier)
          break
        case OperandScopes.OrderBy:
          this.orderByIdentifiers.push(identifier)
          break
        case OperandScopes.Where:
          this.whereIdentifiers.push(identifier)
          break
      }
      return
    }

    // Skip raw types
    if (
      [
        OperandTypes.String,
        OperandTypes.Boolean,
        OperandTypes.Number,
        OperandTypes.Null
      ].includes(operand.type)
    ) {
      return
    }

    // We have an array
    if (operand.value && Array.isArray(operand.value)) {
      this.addOperands(operand.value, scope)
      return
    }

    // We have an object
    if (operand.value && Object.keys(operand.value)) {
      this.addOperand(operand.value, scope)
      return
    }
    if (operand.left) {
      this.addOperand(operand.left, scope)
    }
    if (operand.right) {
      this.addOperand(operand.right, scope)
    }

    if (operand.params && Array.isArray(operand.params)) {
      this.addOperands(operand.params, scope)
      return
    }

    if (operand.params && Object.keys(operand.params)) {
      this.addOperand(operand.params, scope)
      return
    }
  }

  get identifiers(): string[] {
    return this.usedIdentifiers
  }

  get functions(): string[] {
    return this.usedFunctions
  }

  get aliases(): string[] {
    return this.usedAliases
  }

  get operands(): string[] {
    return this.usedOperands
  }

  setJSON(tqlJSON: TQLJSON) {
    this.tqlJSON = tqlJSON
    this.table = tqlJSON.model
    this.addOperands(tqlJSON.selects, OperandScopes.Select)
    this.addOperand(tqlJSON.where, OperandScopes.Where)
    this.addOperands(tqlJSON.groupBy, OperandScopes.GroupBy)
    this.addOperands(tqlJSON.orderBy, OperandScopes.OrderBy)
    this.addOperand(tqlJSON.having, OperandScopes.Having)
  }

  // Function that returns if the query is a collection query or aggregation query
  isAggregationQuery(): boolean {
    aggregationFunctions.forEach((func) => {
      if (this.usedFunctions.includes(func)) {
        return true
      }
    })
    return false
  }

  addOperands(operands: TQLOperand[] | null, scope: OperandScopes) {
    if (!operands) {
      return
    }
    operands.forEach((operand) => {
      this.addOperand(operand, scope)
    })
  }
}
