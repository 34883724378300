<template>
  <div>
    <json-field
      name="type"
      :model-context="{ accountAttribute: false }"
      :disabled="isEditing"
      :block-creation="isEditing"
      :additional-query-options="typeQueryOptions"
    />
    <v-row>
      <v-col>
        <json-field name="name" />
      </v-col>
      <v-col>
        <json-field name="barcode" prepend-inner-icon="mdi-barcode-scan" />
      </v-col>
    </v-row>
    <json-field name="bundle" :model-context="{ accountAttribute: false }" />
    <json-field name="description" />
    <json-field name="instructions" />

    <json-field name="status" />
    <json-field name="serialNumber" />

    <AssetSerialNumberChecker
      v-if="currentSerialNumber"
      :serial-number="currentSerialNumber"
      :asset-type="assetType"
      :asset-id="assetId"
      :is-editing="isEditing"
      @serial-number-conflict="receiveSerialNumberConflict"
    />

    <AccountContextJsonField name="account" />
    <json-field
      name="accountLocation"
      :model-context="{ accountAttribute: false }"
    />
    <json-field name="dateInDateTime" :placeholder="dateInPlaceholder" />
    <json-field
      v-if="isBundle"
      name="canBeSeparatelyReservedOrCheckedOut"
      as="VSwitch"
      color="green"
      hide-details
    />
    <json-field
      name="checkinNotRequired"
      as="VSwitch"
      color="green"
      hide-details
    />
    <template v-if="hasFieldsToShow">
      <v-divider class="mt-6" />
      <AssetCustomAttributeListAsForm
        :fields="fields"
        :custom-fields-value="customAttributesValues"
        @input="setCustomFieldsValues"
        @errors="setCustomFieldsError"
        @valid="cleanCustomFieldsError"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { some, get } from 'lodash'
import { ErrorObject } from 'ajv'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'

import { Field } from '@/tt-fieldset-builder/type'
import { ImageHandlerProvider } from '@/tt-fieldset-builder/image-handler/types'
import { AssetCustomField } from '@/tt-fieldset-builder/api/types'
import { createImageHandler } from '@/tt-fieldset-builder/image-handler'
import { formatDate } from '@/helpers/dates/formatDate'
import { ApiDateTimeFormats } from '@/helpers/formats/dates'
import { VueWithFormHookInjection } from '@/helpers/components/types'

import { Resources } from '../../types'
import AssetCustomAttributeListAsForm from '../asset-custom-attributes/AssetCustomAttributeListAsForm.vue'
import AssetSerialNumberChecker from './AssetSerialNumberChecker.vue'
import {
  CustomAttributeDisplay,
  FilteredAssetTypesInfo,
  SearchedAsset,
} from './types'
import {
  ATTR_DATE_IN,
  ATTR_ASSET_BUNDLE,
  ATTR_CUSTOM_ATTRIBUTE_DISPLAY,
  ATTR_CUSTOM_ATTRIBUTE_VALUES,
  ASSET_TYPE,
  ASSET_INSTRUCTIONS,
  SERIAL_NUMBER,
} from './constants'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'AssetsForm',
  components: { AssetCustomAttributeListAsForm, AssetSerialNumberChecker },
  inject: ['formHook'],
  provide(): ImageHandlerProvider {
    return {
      imageHandler: () => this.imageHandler,
    }
  },
  data() {
    return {
      fields: [] as Field[],
      imageHandler: createImageHandler(),
      beContainedIntoBundle: false,
      isBundle: false,
      currentSerialNumber: '',
    }
  },
  computed: {
    createSerialNumberError(): ErrorObject[] {
      return [
        {
          dataPath: `.${SERIAL_NUMBER}`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    today(): string {
      return formatDate(new Date(), ApiDateTimeFormats.ISO_8601)
    },
    isEditing(): boolean {
      // if we have the id, it means that we're editing an entity, otherwise it's a creation and we should not show the "bundle" field.
      return !!this.assetId
    },
    customAttributesValues(): Record<string, unknown> {
      return this.formHook().getPathValue(ATTR_CUSTOM_ATTRIBUTE_VALUES) || {}
    },
    customAttributesDisplay(): CustomAttributeDisplay[] {
      return this.formHook().getPathValue(ATTR_CUSTOM_ATTRIBUTE_DISPLAY) || []
    },
    bundleValue(): string {
      return this.formHook().getPathValue(ATTR_ASSET_BUNDLE)
    },
    hasFieldsToShow(): boolean {
      /**
       * Iterates through the fields list and check if any item among them
       * needs to be displayed during the Asset creation
       */
      return some(
        this.fields,
        (field) => get(field, 'toDisplayOn.entity.show') === true,
      )
    },
    customAttributeError(): ErrorObject[] {
      return [
        {
          dataPath: `.${ATTR_CUSTOM_ATTRIBUTE_VALUES}`,
          keyword: '',
          params: {},
          schemaPath: '',
        },
      ]
    },
    bundleRequiredError(): ErrorObject[] {
      return [
        {
          dataPath: `.${ATTR_ASSET_BUNDLE}`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    dateInPlaceholder(): string {
      return this.$t('tt-entity-design.asset-hub.date-in')
    },
    serialNumber() {
      return this.formHook().getPathValue(SERIAL_NUMBER)
    },
    assetType(): number {
      return this.formHook().getPathValue(ASSET_TYPE)
    },
    assetId(): number {
      return this.formHook().getPathValue('id')
    },
    typeQueryOptions(): EntityCollectionRequestOptions {
      return this.isEditing
        ? {
            filters: [
              {
                attribute: 'includeInactive',
                operator: 'EQUAL',
                value: 1,
              },
              {
                attribute: 'status',
                operator: 'IN',
                value: ['ACTIVE', 'PENDING', 'INACTIVE'],
              },
            ],
          }
        : {}
    },
  },
  watch: {
    customAttributesDisplay: {
      immediate: true,
      handler() {
        this.customAttributesDisplay
          .filter(({ fileId }) => fileId)
          .forEach(({ fileId, value }) =>
            this.imageHandler.setImageUrl(fileId, value),
          )
      },
    },
    bundleValue() {
      this.handleBundleRequiredValidation()
    },
    beContainedIntoBundle() {
      this.handleBundleRequiredValidation()
    },
    serialNumber(newSerialNumber) {
      this.currentSerialNumber = newSerialNumber
    },
  },
  created() {
    if (this.isEditing) {
      this.loadCustomFields()
    }

    // Set today's date and time as default value for dateInDateTime field
    if (!this.isEditing) {
      this.setDateInDefaultValue()
    }

    const getAssetType = () => this.formHook().getPathValue(ASSET_TYPE)

    // @TODO Refactor these two methods to use one API call for getting the asset type details instead of 2.
    const customFieldsObject = () => {
      this.setDefaultInstructions()
      this.loadCustomFields()
    }

    this.$watch(getAssetType, customFieldsObject)
  },
  methods: {
    receiveSerialNumberConflict(conflictingAsset: SearchedAsset) {
      if (Object.keys(conflictingAsset).length) {
        this.setSerialNumberFormError()
      } else {
        this.clearSerialNumberFormError()
      }
    },
    setSerialNumberFormError(): void {
      this.formHook().setCustomError(
        SERIAL_NUMBER,
        this.createSerialNumberError,
      )
    },
    clearSerialNumberFormError(): void {
      this.formHook().setCustomError(SERIAL_NUMBER, null)
    },
    cleanCustomFieldsError(): void {
      this.formHook().setCustomError(ATTR_CUSTOM_ATTRIBUTE_VALUES, null)
    },
    cleanBundleRequiredError(): void {
      this.formHook().setCustomError(ATTR_ASSET_BUNDLE, null)
    },
    setCustomFieldsError(): void {
      this.formHook().setCustomError(
        ATTR_CUSTOM_ATTRIBUTE_VALUES,
        this.customAttributeError,
      )
    },
    setDefaultInstructions() {
      this.getAssetType(this.assetType)
        .then((assetTypeDetails: any) => {
          const assetTypeInstructions = assetTypeDetails?.instructions
          this.formHook().setObjectValue(
            ASSET_INSTRUCTIONS,
            assetTypeInstructions,
          )
        })
        .catch((err) => {
          this.$crash.captureException(err)
        })
    },
    getAssetType(assetTypeID: number) {
      return this.$auth.getApi().get(Resources.ASSET_TYPES, assetTypeID)
    },
    setCustomFieldsValues($event): void {
      this.formHook().setObjectValue(ATTR_CUSTOM_ATTRIBUTE_VALUES, $event)
    },
    setBundleRequiredError(): void {
      this.formHook().setCustomError(
        ATTR_ASSET_BUNDLE,
        this.bundleRequiredError,
      )
    },
    loadCustomFields() {
      // everytime it changes, clear the custom fields errors
      this.cleanCustomFieldsError()
      this.cleanBundleRequiredError()

      return this.getCustomFields(this.assetType)
        .then(
          (typeDetails: Record<string, unknown>): FilteredAssetTypesInfo => {
            const { beContainedIntoBundle, customAttributes, isBundle } =
              typeDetails

            // filter the results and extract the fields and a validation attribute
            return {
              isBundle,
              beContainedIntoBundle,
              filteredFields:
                (customAttributes as AssetCustomField[]).map(
                  ({ config }) => config,
                ) || [],
            } as FilteredAssetTypesInfo
          },
        )
        .then(({ filteredFields, beContainedIntoBundle, isBundle }): void => {
          // assign the filtered fields to the data object
          this.fields = filteredFields
          // show/hide a validation error if the bundle field is required for the selected asset type
          this.beContainedIntoBundle = beContainedIntoBundle
          this.isBundle = isBundle
        })
        .catch((err) => this.$crash.captureException(err))
    },
    getCustomFields(assetTypeID: number) {
      return this.$auth.getApi().get(Resources.ASSET_TYPES, assetTypeID, {
        include: ['customAttributes'],
      })
    },
    handleBundleRequiredValidation(): void {
      if (this.beContainedIntoBundle && !this.bundleValue) {
        this.setBundleRequiredError()
      } else {
        this.cleanBundleRequiredError()
      }
    },
    setDateInDefaultValue(): void {
      this.formHook().setObjectValue(ATTR_DATE_IN, this.today)
    },
  },
})
</script>
