<template>
  <div @click.stop="callEditForm">
    <tt-attr name="name" class="font-weight-bold" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ActiveWindowProvider } from '@/tt-app-layout'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
export default (
  Vue as VueConstructor<Vue & ActiveWindowProvider & ItemHookProvider>
).extend({
  name: 'ClickableNameColumn',
  inject: ['activeWindow', 'getItemHook'],
  computed: {
    leavePolicyItemId(): number {
      return this.getItemHook().entityReference.entityId
    },
    leaveManagementTitle(): string {
      return 'tt-entity-design.leave-management.label'
    },
  },
  methods: {
    callEditForm(): void {
      this.activeWindow.next({
        title: this.leaveManagementTitle,
        is: 'LeavePolicyItemsForm',
        props: {
          leavePolicyItemId: this.leavePolicyItemId,
        },
      })
    },
  },
})
</script>
