// Enter your custom installation here
import AssetTransactionItemsCustomFields from './AssetTransactionItemsCustomFields.vue'
import { setAttributeView } from '@/tt-widget-entity-flow/helper'

import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component(
      'AssetTransactionItemsCustomFields',
      AssetTransactionItemsCustomFields,
    )

    setAttributeView(
      Resources.ASSET_TRANSACTION_ITEMS,
      'customAttributesDisplay',
      {
        is: 'AssetTransactionItemsCustomFields',
      },
    )
  },
}
