import { AttributeFilter, Filter } from '../schemas-types'

export const attributeFilterToFilter = ({
  attributeName,
  defaultValue,
}: AttributeFilter): Filter => {
  const { operator, value } = defaultValue
  const attribute = attributeName

  return operator === 'ISNULL' || operator === 'ISNOTNULL'
    ? { attribute, operator }
    : {
        attribute,
        operator,
        value: value.toString(),
      }
}
