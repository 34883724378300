<template>
  <div>
    <v-skeleton-loader v-if="isLoading" size="60" class="mr-7" type="avatar" />
    <v-avatar v-else :color="changeColor" size="60" class="mr-7">
      <span class="white--text">{{ daysUntilShift }}</span>
    </v-avatar>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DaysUntilShiftAttr',
  inject: ['getItemHook', 'relationPrefix'],
  data() {
    return {
      daysBetween: null,
    }
  },
  computed: {
    startsOnAttr(): string {
      return 'startsOn'
    },
    startsOnPath(): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${this.startsOnAttr}`
        : this.startsOnAttr
    },
    startsOn(): string {
      return this.getItemHook().getRawValue(this.startsOnPath)
    },
    isLoading(): boolean {
      return this.getItemHook().loading
    },
    daysUntilShift(): string {
      const today = new Date().getTime()
      const timeDifference = Date.parse(this.startsOn) - today
      if (timeDifference < 0) return this.$tc('tt-entity-design.passed')
      const hours =
        Math.floor((Date.parse(this.startsOn) - today) / 1000 / 60 / 60) % 24
      const days = Math.floor(
        (Date.parse(this.startsOn) - today) / 1000 / 60 / 60 / 24,
      )

      return `${days}d ${hours}h`
    },
    changeColor: function (): string {
      return this.daysUntilShift == this.$tc('tt-entity-design.passed')
        ? 'orange'
        : 'indigo'
    },
  },
})
</script>
