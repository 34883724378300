/**
 * Convert minutes to an object with days, hours, and minutes
 */
export const formatMinutesToTimeObject = (
  minutes: number,
): { days: number; hours: number; minutes: number } => {
  const days = Math.floor(minutes / 1440) // 1440 minutes in a day
  const hours = Math.floor((minutes % 1440) / 60) // 60 minutes in an hour
  const mins = Math.floor(minutes % 60)

  return {
    days,
    hours,
    minutes: mins,
  }
}
