<template>
  <v-btn
    outlined
    text
    color="ttPrimary"
    small
    :loading="loading"
    @click.stop="importContact"
  >
    {{ $t(label) }}
  </v-btn>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '../../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetContactsImportBtn',
  inject: ['getItemHook'],
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      label: 'common.import',
    }
  },
  computed: {
    /**
     * @TODO: Use the "Resources" type and return Resources.ASSET_CONTACTS
     * after SDAM-835 is merged into master and the schemas properly updated.
     *
     * Follow-up ticket: SDAM-836
     */
    resource(): string {
      return 'asset-contacts'
    },
  },
  methods: {
    importContact() {
      const { contextManager, authModule, eventManager } = this.$appContext
      const account = contextManager.getContextFilter(
        AttributeName.ACCOUNT_ATTRIBUTE,
      ).value
      this.loading = true

      authModule
        .getApi()
        .doAction(
          this.resource,
          this.getItemHook().entityReference.entityId,
          'export-to-asset',
          { account },
        )
        .then(() => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
            message: this.$t(
              `tt-entity-design.asset-hub.asset-person.import-success`,
            ),
          })
          eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.ASSET_PERSONS,
          })
        })
        .catch((error) => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message:
              error ||
              this.$t('tt-entity-design.asset-hub.asset-person.import-error'),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
