<template>
  <v-menu offset-y :disabled="isAvailableActionListEmpty">
    <template #activator="{ on, attrs }">
      <div>
        <v-btn
          small
          :color="color"
          :depressed="isAvailableActionListEmpty"
          class="text-uppercase white--text"
          v-bind="attrs"
          v-on="on"
        >
          {{ value }}
          <v-icon v-if="!isAvailableActionListEmpty" right>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </div>
    </template>
    <v-list>
      <tt-attr-action v-for="action in actions" :key="action" :action="action">
        <v-list-item link @click="$emit('apply-action', action)">
          <v-list-item-title v-text="$t(getTranslationKey(action))" />
        </v-list-item>
      </tt-attr-action>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { LeaveRequestsStatus, SupportedActions } from './types'
import Vue, { PropType, VueConstructor } from 'vue'

export default (Vue as VueConstructor).extend({
  name: 'LeaveRequestsDropdownAction',
  props: {
    value: {
      type: String,
      required: true,
    },
    currentStatus: {
      type: String as PropType<LeaveRequestsStatus>,
      required: true,
    },
    actions: {
      type: Array as PropType<Array<SupportedActions>>,
      required: true,
    },
  },
  data() {
    return {
      actionDictionary: {
        approve: 'common.approve',
        decline: 'common.decline',
        'approve-cancellation': 'common.approve_cancellation',
        'decline-cancellation': 'common.decline_cancellation',
      },
    }
  },
  computed: {
    color(): string {
      return {
        APPROVED: 'success',
        DECLINED: 'error',
        PENDING_CANCELLATION: 'orange darken-2',
        CANCELLED: 'error lighten-2',
      }[this.currentStatus]
    },
    isAvailableActionListEmpty(): boolean {
      return !this.actions?.length
    },
  },
  methods: {
    getTranslationKey(actionKey: string) {
      return this.actionDictionary[actionKey] ?? actionKey
    },
  },
})
</script>

<style scoped>
.v-list-item {
  text-transform: capitalize;
}
</style>
