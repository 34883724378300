// Enter your custom installation here
import AssetContactsActions from './AssetContactsActions.vue'
import AssetContactsImportBtn from './AssetContactsImportBtn.vue'

export default {
  install(Vue) {
    Vue.component('AssetContactsActions', AssetContactsActions)
    Vue.component('AssetContactsImportBtn', AssetContactsImportBtn)
  },
}
