import moment, { Moment } from 'moment-timezone'

export const isDateInFuture = (
  date: string | number | Moment | Date,
  targetDate?: string | number | Moment | Date,
  granularity?: moment.unitOfTime.StartOf,
) => {
  const target = targetDate ?? moment().format()

  return moment.utc(date).isAfter(target, granularity)
}
