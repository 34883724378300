<template>
  <ExceptionTypeParentLabel />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ExceptionTypesLabelWrapper',
  provide: {
    relationPrefix: 'type',
  },
})
</script>
