// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import GeoCountrySubdivisionsTile from './GeoCountrySubdivisionsTile.vue'
import GeoCountrySubdivisionsColumns from './GeoCountrySubdivisionsColumns'
import GeoCountrySubdivisionsDetail from './GeoCountrySubdivisionsDetail.vue'
import GeoCountrySubdivisionsReference from './GeoCountrySubdivisionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('GeoCountrySubdivisionsTile', GeoCountrySubdivisionsTile)
    Vue.component('GeoCountrySubdivisionsDetail', GeoCountrySubdivisionsDetail)
    Vue.component(
      'GeoCountrySubdivisionsReference',
      GeoCountrySubdivisionsReference,
    )

    modularManager.addResourcePreset(
      Resources.GEO_COUNTRY_SUBDIVISIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'GeoCountrySubdivisionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRY_SUBDIVISIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: GeoCountrySubdivisionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRY_SUBDIVISIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'GeoCountrySubdivisionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_COUNTRY_SUBDIVISIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'GeoCountrySubdivisionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
