// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TenantsTile from './TenantsTile.vue'
import TenantsColumns from './TenantsColumns'
import TenantsDetail from './TenantsDetail.vue'
import TenantsMetaCard from './TenantsMetaCard.vue'
import TenantsReference from './TenantsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TenantsTile', TenantsTile)
    Vue.component('TenantsDetail', TenantsDetail)
    Vue.component('TenantsMetaCard', TenantsMetaCard)
    Vue.component('TenantsReference', TenantsReference)

    modularManager.addResourcePreset(Resources.TENANTS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'TenantsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.TENANTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: TenantsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.TENANTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TenantsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.TENANTS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'TenantsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.TENANTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TenantsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
