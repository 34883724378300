<template>
  <div>
    <json-field name="name" />
    <json-field :custom-view="daysOfWeekView" name="dayOfWeek" />
    <json-field name="customId" />
    <json-field name="zonePosition" />
    <json-field name="zone" />
    <json-field name="beginDate" />
    <json-field name="endDate" />
    <json-field name="rangeStartTime" />
    <json-field name="rangeEndTime" />
    <json-field name="status" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import { ViewOption } from '@tracktik/tt-json-schema-form'

import DayOfWeekSelector from '../DayOfWeekSelector.vue'

export default Vue.extend({
  name: 'MobileRunsheetsCreateBatchForm',
  computed: {
    daysOfWeekView(): ViewOption {
      return {
        is: DayOfWeekSelector,
      }
    },
  },
})
</script>
