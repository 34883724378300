// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MessageBoardPostRecipientsTile from './MessageBoardPostRecipientsTile.vue'
import MessageBoardPostRecipientsColumns from './MessageBoardPostRecipientsColumns'
import MessageBoardPostRecipientsDetail from './MessageBoardPostRecipientsDetail.vue'
import MessageBoardPostRecipientsReference from './MessageBoardPostRecipientsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MessageBoardPostRecipientsTile',
      MessageBoardPostRecipientsTile,
    )
    Vue.component(
      'MessageBoardPostRecipientsDetail',
      MessageBoardPostRecipientsDetail,
    )
    Vue.component(
      'MessageBoardPostRecipientsReference',
      MessageBoardPostRecipientsReference,
    )

    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_RECIPIENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostRecipientsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_RECIPIENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MessageBoardPostRecipientsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_RECIPIENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostRecipientsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_RECIPIENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostRecipientsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
