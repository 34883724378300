// Enter your custom installation here

import LeaveManagementAdjustmentsDetail from './LeaveManagementAdjustmentsDetail.vue'

export default {
  install(Vue) {
    Vue.component(
      'LeaveManagementAdjustmentsDetail',
      LeaveManagementAdjustmentsDetail,
    )
  },
}
