import { AuthModule } from '@tracktik/tt-authentication'

type LocalStorageDismissedBannerUserIds = Record<string, number[]>

const USER_ID_LOCAL_STORAGE_KEY = 'ttc_datalab_tab_welcome_banner_dismissed'

const setDismissedBannerForUserIds = (
  newState: LocalStorageDismissedBannerUserIds,
): void => {
  try {
    localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, JSON.stringify(newState))
  } catch (error) {
    console.warn(`Could not set the user id to the Locale Storage.`, error)
  }
}

export const getDismissedBannerForUserIds =
  (): LocalStorageDismissedBannerUserIds => {
    try {
      return JSON.parse(localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY)) || {}
    } catch (error) {
      console.warn(`Could not get the user id from the Locale Storage.`, error)
      return {}
    }
  }

export const setUserIdToLocalStorage = ({
  getUserId,
  getServerUrl,
}: AuthModule) => {
  const userId = getUserId()
  const serverUrl = getServerUrl()
  const currentStorageForAllServers = getDismissedBannerForUserIds()
  const currentUserIdsForGivenServerUrl =
    currentStorageForAllServers[serverUrl] || []

  const newUserIds = [...currentUserIdsForGivenServerUrl, userId]

  const newStorage = {
    ...currentStorageForAllServers,
    [serverUrl]: newUserIds,
  }

  setDismissedBannerForUserIds(newStorage)
}

export const hasDismissedBanner = ({
  getUserId,
  getServerUrl,
}: AuthModule): boolean => {
  const userId = getUserId()
  const serverUrl = getServerUrl()
  const userIds = getDismissedBannerForUserIds()[serverUrl] || []

  return userIds.includes(userId)
}
