// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTypeCustomAttributesTile from './AssetTypeCustomAttributesTile.vue'
import AssetTypeCustomAttributesColumns from './AssetTypeCustomAttributesColumns'
import AssetTypeCustomAttributesDetail from './AssetTypeCustomAttributesDetail.vue'
import AssetTypeCustomAttributesReference from './AssetTypeCustomAttributesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'AssetTypeCustomAttributesTile',
      AssetTypeCustomAttributesTile,
    )
    Vue.component(
      'AssetTypeCustomAttributesDetail',
      AssetTypeCustomAttributesDetail,
    )
    Vue.component(
      'AssetTypeCustomAttributesReference',
      AssetTypeCustomAttributesReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_CUSTOM_ATTRIBUTES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTypeCustomAttributesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_CUSTOM_ATTRIBUTES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTypeCustomAttributesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_CUSTOM_ATTRIBUTES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypeCustomAttributesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_CUSTOM_ATTRIBUTES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTypeCustomAttributesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
