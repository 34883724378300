import Vue from 'vue'
import DarkLightExtension from './components/DarkLightExtension.vue'
import { Breakpoint } from 'vuetify/types/services/breakpoint'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

Vue.component('DarkLightExtension', DarkLightExtension)

export const DARK_LIGHT_EXTENSION = 'extension.dark-light.tracktik.com'

export default {
  name: DARK_LIGHT_EXTENSION,
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  component: {
    is: 'DarkLightExtension',
  },
} as ExtensionInterface
