<template>
  <ExceptionTypeParentLabel />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ExceptionTypesParentLabelWrapper',
  provide: {
    relationPrefix: '',
  },
})
</script>
