<template>
  <span>{{ label }}</span>
</template>
<script lang="ts">
//@ts-nocheck
//@todo: type inject
import Vue from 'vue'
export default Vue.extend({
  name: 'Localized',
  inject: ['i18n'],
  props: {
    t: String,
  },
  computed: {
    label(): any {
      // TODO Look into why this.i18n is undefined in the test suite
      if (!this.i18n) {
        return this.t
      }

      return this.i18n.t(this.t)
    },
  },
})
</script>
