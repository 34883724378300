<template>
  <!--
    If it is converting and assigned to the current user, we show the
    reasonToConvert elsewhere -->
  <tt-attr-row
    v-if="hasReasonToConvert && (!isConverting || !isAssignedToCurrentUser)"
    name="schedule.reasonToConvert"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import {
  SiteTaskOccurrenceStatus,
  siteTaskOccurrenceStatus,
} from './site-task-occurrences-status'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskOccurrencesReasonToConvertRow',
  inject: ['getItemHook'],
  computed: {
    assignedEmployeeId(): number {
      return this.getItemHook().get('session.employee.id')
    },
    hasReasonToConvert(): string {
      return this.getItemHook().get('schedule.reasonToConvert')
    },
    isAssignedToCurrentUser(): boolean {
      return this.assignedEmployeeId === this.userId
    },
    isConverting(): boolean {
      return this.status === siteTaskOccurrenceStatus.CONVERTING
    },
    status(): SiteTaskOccurrenceStatus {
      return this.getItemHook().getRawValue('status')
    },
    userId(): number {
      return this.$appContext.authModule.getUserId()
    },
  },
  created() {
    this.getItemHook().addAttribute('schedule.reasonToConvert')
    this.getItemHook().addAttribute('session.employee')
    this.getItemHook().addAttribute('status')
  },
})
</script>
