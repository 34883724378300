import { PluginFunction } from 'vue'

const RunsheetToSiteTaskFormView = () =>
  import(
    /* webpackChunkName: "RunsheetToSiteTaskFormView" */
    './views/RunsheetToSiteTaskFormView.vue'
  )
const SiteTasksView = () =>
  import(
    /* webpackChunkName: "SiteTasksView" */
    './views/SiteTasksView.vue'
  )

const SiteTaskAnalyticsView = () => import('./views/SiteTaskAnalyticsView.vue')

export { RunsheetToSiteTaskFormView, SiteTasksView, SiteTaskAnalyticsView }

export const SiteTasksPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('RunsheetToSiteTaskFormView', RunsheetToSiteTaskFormView)
  Vue.component('SiteTasksView', SiteTasksView)
  Vue.component('SiteTaskAnalyticsView', SiteTaskAnalyticsView)
}
