// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftTemplateSettingsTile from './ShiftTemplateSettingsTile.vue'
import ShiftTemplateSettingsColumns from './ShiftTemplateSettingsColumns'
import ShiftTemplateSettingsDetail from './ShiftTemplateSettingsDetail.vue'
import ShiftTemplateSettingsReference from './ShiftTemplateSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftTemplateSettingsTile', ShiftTemplateSettingsTile)
    Vue.component('ShiftTemplateSettingsDetail', ShiftTemplateSettingsDetail)
    Vue.component(
      'ShiftTemplateSettingsReference',
      ShiftTemplateSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATE_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftTemplateSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATE_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftTemplateSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATE_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftTemplateSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_TEMPLATE_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftTemplateSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
