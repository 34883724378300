<template>
  <div>
    <div v-for="component in components" :key="uniqueKey + component.title">
      <template v-if="collapsible && component.title">
        <CollapsibleHeader
          :title="component.title"
          :opened="component.props.opened"
        >
          <component :is="component.is" v-bind="component" />
        </CollapsibleHeader>
      </template>
      <template v-else>
        <v-subheader v-if="component.title" v-text="component.title" />
        <component :is="component.is" v-bind="component" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { modularManager, PresetItem, PresetTypes } from '@/tt-app-modular'
import { cloneDeep } from 'lodash'
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '../types'

type ComponentProps = {
  is: string
  props: Record<string, string>
  title: string
}

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityPresetView',
  inject: ['getItemHook'],
  props: {
    type: String as () => PresetTypes,
    title: String,
    collapsible: { type: Boolean, default: false },
    opened: { type: Boolean, default: false },
    resourceName: { type: String, default: null },
  },
  computed: {
    uniqueKey(): string {
      return `${this.getItemHook().entityReference.resourceName}-${
        this.getItemHook().entityReference.entityId
      }`
    },
    components(): ComponentProps[] {
      const presetsList = this.getPresetsList

      if (presetsList.length === 0) return []

      return this.createComponentList(presetsList)
    },
    resource(): string {
      return (
        this.resourceName || this.getItemHook().entityReference.resourceName
      )
    },
    getPresetsList(): PresetItem[] {
      const presetList =
        modularManager.getResourcePresets(this.resource, this.type) || []

      if (presetList.length === 0) return []

      return this.orderList(presetList)
    },
  },
  methods: {
    orderList(presetsList: PresetItem[]): PresetItem[] {
      const newPresetList = cloneDeep(presetsList)

      return newPresetList.sort(function (a: PresetItem, b: PresetItem) {
        if (!a.order) return -presetsList.length
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1
      })
    },
    createComponentList(presetsList: PresetItem[]): ComponentProps[] {
      const componentsList: ComponentProps[] = []
      presetsList.forEach(({ data, hide, title }) => {
        // ActionsCard default component doesn't need to be registered to be shown
        const is =
          data?.is ||
          (this.type === PresetTypes.ACTIONS_CARD && 'EntityActionsAsList')
        // can be hidden
        if (hide === true || !is) return

        const props = {
          ...data?.props,
          ...this.$attrs,
          opened: data.opened !== undefined ? data.opened : this.opened,
        }

        componentsList.push({
          is,
          props,
          title: title === 'Default' ? this.title : title,
        })
      })

      return componentsList
    },
  },
})
</script>
