// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftNotesTile from './ShiftNotesTile.vue'
import ShiftNotesColumns from './ShiftNotesColumns'
import ShiftNotesDetail from './ShiftNotesDetail.vue'
import ShiftNotesMetaCard from './ShiftNotesMetaCard.vue'
import ShiftNotesReference from './ShiftNotesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftNotesTile', ShiftNotesTile)
    Vue.component('ShiftNotesDetail', ShiftNotesDetail)
    Vue.component('ShiftNotesMetaCard', ShiftNotesMetaCard)
    Vue.component('ShiftNotesReference', ShiftNotesReference)

    modularManager.addResourcePreset(
      Resources.SHIFT_NOTES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftNotesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_NOTES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftNotesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_NOTES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftNotesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_NOTES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftNotesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_NOTES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftNotesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
