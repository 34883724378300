import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

import { CollectionQuery } from '@/tt-widget-components'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { Field, FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'

export const getQueryWidgetFields = (): Field[] => [
  {
    attribute: 'id',
    alias: 'meta.id',
  },
  {
    attribute: 'uid',
  },
  {
    attribute: 'title',
  },
  {
    attribute: 'is',
  },
  {
    attribute: 'uri',
    alias: 'meta.uri',
  },
  {
    attribute: 'aclRule',
    alias: 'meta.aclRule',
  },
  {
    attribute: 'ownedBy.id',
    alias: 'meta.ownedBy.id',
  },
  {
    attribute: 'ownedBy.avatar',
    alias: 'meta.ownedBy.avatar',
  },
  {
    attribute: 'ownedBy.name',
    alias: 'meta.ownedBy.name',
  },
  {
    attribute: 'category',
  },
  {
    attribute: 'configs',
  },
  {
    attribute: 'createdBy',
    alias: 'meta.createdBy',
  },
  {
    attribute: 'createdOn',
    alias: 'meta.createdOn',
  },
  {
    attribute: 'updatedOn',
    alias: 'meta.updatedOn',
  },
]

export const getWidgetQueryByDashboard = ({
  offset,
  limit,
}): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: 'widgetType',
      operator: FilterOperatorType.EQUAL,
      value: WidgetName.DASHBOARD_WIDGET,
    },
  ],
  sort: [
    {
      attribute: 'updatedOn',
      direction: SortDirectionType.DESC,
    },
  ],
  limit,
  offset,
})

export const getWidgetQueryByDashboardAndSearch = (
  search,
): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: 'widgetType',
      operator: FilterOperatorType.EQUAL,
      value: WidgetName.DASHBOARD_WIDGET,
    },
  ],
  sort: [
    {
      attribute: 'updatedOn',
      direction: SortDirectionType.DESC,
    },
  ],
  search,
})

export const getWidgetQueryByUid = (uid: string): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: 'uid',
      operator: FilterOperatorType.EQUAL,
      value: uid,
    },
  ],
})
