import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'payrun.payrollSchedule',
  'employee',
  'account.region',
  'account',
  'expenseDate',
  'payrollCodeLabel',
  'quantity',
  'rate',
  'total',
  'employeeType',
] as ColumnInputDefinition[]
