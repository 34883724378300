// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import VendorsTile from './VendorsTile.vue'
import VendorsColumns from './VendorsColumns'
import VendorsDetail from './VendorsDetail.vue'
import VendorsMetaCard from './VendorsMetaCard.vue'
import VendorsReference from './VendorsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('VendorsTile', VendorsTile)
    Vue.component('VendorsDetail', VendorsDetail)
    Vue.component('VendorsMetaCard', VendorsMetaCard)
    Vue.component('VendorsReference', VendorsReference)

    modularManager.addResourcePreset(Resources.VENDORS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'VendorsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.VENDORS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: VendorsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.VENDORS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'VendorsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.VENDORS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'VendorsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.VENDORS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'VendorsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
