<template>
  <div class="flex--column">
    <MapFlyRoute
      v-if="markers.length"
      style="height: 300px"
      :markers="markers"
    />
    <MobileScheduleOccurrenceTimeline
      class="flex--column"
      :mobile-runsheet-occurrence-id="mobileRunsheetOccurrenceId"
      @update:hook="hook = $event"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import { MapFlyRoute, Marker } from '@tracktik/tt-maps'
import MobileScheduleOccurrenceTimeline from '../mobile-schedule-occurrences/MobileScheduleOccurrenceTimeline.vue'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'

/**
 * To review after https://tracktik.atlassian.net/browse/API-3209
 */
export default (Vue as VueWithItemHookInjection).extend({
  name: 'MobileRunsheetOccurrencesScheduleOccurrences',
  components: {
    MobileScheduleOccurrenceTimeline,
    MapFlyRoute,
  },
  inject: ['getItemHook'],
  data() {
    return {
      hook: null as ListWidgetHook | null,
    }
  },
  computed: {
    mobileRunsheetOccurrenceId(): string {
      return this.getItemHook().getEntityId()
    },
    markers(): Marker[] {
      return (
        this.hook?.entities?.map((item: any) => ({
          id: item.id,
          position: [
            item.client.address.longitude,
            item.client.address.latitude,
          ],
          active: false,
        })) ?? []
      )
    },
  },
})
</script>
