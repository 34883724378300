<template>
  <DrawCanvasField v-model="model" :preview="previewUrl" v-bind="$attrs" />
</template>

<script lang="ts">
import { ImageHandlerProvider } from '@/tt-fieldset-builder/image-handler/types'
import Vue, { VueConstructor } from 'vue'

type Value = number | string | null

const VueWithImageUrl = Vue as VueConstructor<Vue & ImageHandlerProvider>

export default VueWithImageUrl.extend({
  name: 'CustomFieldSignature',
  inject: ['imageHandler'],
  props: {
    name: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      default: undefined,
    },
    preview: {
      type: String,
      default: '',
    },
    uploadDeactivated: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    model: {
      get(): Value {
        return this.value
      },
      set(newValue: Value) {
        this.$emit('input', newValue)
      },
    },
    previewUrl(): string {
      return this.imageHandler().getImageUrl(this.value)
    },
  },
})
</script>
