<template>
  <div :key="key" class="dense widget-edit-wrapper">
    <v-row no-gutters dense>
      <v-col no-gutters>
        <AddWidgetView
          v-if="showSelectWidgetType"
          :key="key"
          :current-widget-type="widgetIs"
          @input="trackAndSetWidgetType"
        />

        <WidgetForm
          v-if="jsonSchema && !showSelectWidgetType"
          v-model="widget"
          :json-schema="jsonSchema"
          @debouncing="setStoreDebouncing"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor, defineComponent } from 'vue'
import uniqueId from 'lodash/uniqueId'

import AddWidgetView from '@/tt-widget-components/components/AddWidgetView.vue'
import WidgetForm from '@/tt-widget-components/components/WidgetForm.vue'
import {
  ChooseWidgetTypeFromDashboardCounter,
  CreateWidgetIntoDashboard,
} from '@/plugins/o11n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { UnsubscribeFunction } from '@/tt-event-manager'
import {
  WidgetReference,
  WidgetSchema,
  WidgetStoreInterface,
  WidgetStoreModel,
  WidgetTypeDefinition,
} from '@/tt-widget-factory/types'
import { getJsonSchema } from '@/tt-widget-components/helpers'
import { changeWidgetType } from '@/tt-widget-components/helpers'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

type WidgetTransformer = (widget: WidgetReference) => WidgetReference

export default defineComponent({
  name: 'WidgetEditView',
  components: {
    AddWidgetView,
    WidgetForm,
  },
  props: {
    widgetGetter: {
      type: Function as PropType<WidgetTransformer>,
      default: (widget) => widget,
    },
    widgetSetter: {
      type: Function as PropType<WidgetTransformer>,
      default: (widget) => widget,
    },
  },
  data: () => ({
    isEditingType: false,
    unsubscribeEditType: null as UnsubscribeFunction,
  }),
  computed: {
    key: (): string => uniqueId('editor_key_'),
    showSelectWidgetType(): boolean {
      return !this.widget?.is || this.isEditingType
    },
    widget: {
      get(): WidgetReference {
        return this.widgetState && this.widgetGetter(this.widgetState.widget)
      },
      set(newValue: WidgetReference) {
        this.setWidgetState(newValue)
      },
    },
    widgetIs(): string {
      return this.widget?.is
    },
    widgetState(): WidgetStoreInterface {
      return WidgetStoreModel.getSelected()
    },
    jsonSchema(): WidgetSchema {
      return getJsonSchema(this.widgetType)
    },
    widgetType(): WidgetTypeDefinition {
      const widgetIs = this.widget?.is
      if (!widgetIs) {
        return null
      }

      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        widgetIs,
      )
    },
  },
  created() {
    this.unsubscribeEditType = this.$eventManager.subscribeEvent(
      WidgetEditorEvents.WIDGET_EDIT_TYPE,
      () => {
        this.isEditingType = true
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribeEditType) this.unsubscribeEditType()
  },
  methods: {
    setStoreDebouncing(event) {
      WidgetStoreModel.setDebouncingSelected(event)
    },
    close(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_CLOSE, {})
    },
    setWidgetState(widget: WidgetReference): void {
      WidgetStoreModel.setSelected({
        ...this.widgetState,
        widget: this.widgetSetter(widget),
      })
    },
    trackAndSetWidgetType(type: WidgetTypeDefinition) {
      // creating if `is` is empty and editing if it already has a value
      if (this.widget?.is == null) {
        this.trackCreateEvent()
      }
      this.trackChooseWidgetTypeEvent(type)
      return changeWidgetType(type, this.widget, this.$appContext)
        .then((newWidget) => this.setWidgetState(newWidget))
        .catch(() => {
          /* User aborted changing type */
        })
        .finally(() => {
          this.isEditingType = false
          this.$eventManager.dispatchEvent(
            LayoutWindowEvent.SIDE_SHEET_EDIT_TITLE,
            { title: this.widget?.is },
          )
        })
    },
    trackCreateEvent(): void {
      this.$analytics.track(CreateWidgetIntoDashboard.create())
    },
    trackChooseWidgetTypeEvent(type: WidgetTypeDefinition): void {
      const event = ChooseWidgetTypeFromDashboardCounter.create({
        label: type.name,
      })
      this.$analytics.track(event)
    },
  },
})

/* eslint-disable prettier/prettier*/
</script>

<style scoped>
/** Remove autofill default styles for inputs */
.widget-edit-wrapper >>> .v-text-field input:-webkit-autofill,
.widget-edit-wrapper >>> .v-text-field input:-webkit-autofill:hover,
.widget-edit-wrapper >>> .v-text-field input:-webkit-autofill:focus {
  border: inherit;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px var(--v-level1-base) inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** Remove autofill default styles for inputs in the dark mode */
.theme--dark .widget-edit-wrapper >>> .v-text-field input:-webkit-autofill,
.theme--dark
  .widget-edit-wrapper
  >>> .v-text-field
  input:-webkit-autofill:hover,
.theme--dark
  .widget-edit-wrapper
  >>> .v-text-field
  input:-webkit-autofill:focus {
  border: inherit;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px var(--v-level1-base) inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
