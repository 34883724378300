// Enter your custom installation here

import { ActionFormTypes } from '@/tt-app-modular'
import {
  registerResourceFormStandaloneComponent,
  setAttributeView,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '../../types'
import CustomFieldCreator from './CustomFieldCreator.vue'
import AssetCustomAttributeConfigView from './AssetCustomAttributeConfigView.vue'

export default {
  install(Vue) {
    Vue.component('CustomFieldCreator', CustomFieldCreator)
    Vue.component(
      'AssetCustomAttributeConfigView',
      AssetCustomAttributeConfigView,
    )

    // Set the color
    setIconAndColorResourcePreset(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      'mdi-form-textbox',
      'blue',
    )

    setAttributeView(Resources.ASSET_CUSTOM_ATTRIBUTES, 'config', {
      is: 'AssetCustomAttributeConfigView',
    })

    registerResourceFormStandaloneComponent(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      'CustomFieldCreator',
      ActionFormTypes.CREATE,
      { width: 600 },
    )

    registerResourceFormStandaloneComponent(
      Resources.ASSET_CUSTOM_ATTRIBUTES,
      'CustomFieldCreator',
      ActionFormTypes.EDIT,
      { width: 600 },
    )
  },
}
