<template>
  <div :class="paginationClasses" class="t-pagination-container level0">
    <v-pagination
      v-if="showPageNumbers"
      :value="page"
      :length="pageNumbers"
      :total-visible="7"
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      circle
      class="t-pagination level0"
      :class="!showSelect && 'w-100'"
      @input="updatePage"
    />

    <TPaginationUnknownTotal
      v-else
      :offset="offset"
      :limit="limit"
      :current-page-count="currentPageCount"
      :width="width"
      class="pa-1"
      @new-offset="(newOffset) => emitUpdate(newOffset, limit)"
      @new-limit="(newLimit) => emitUpdate(offset, newLimit)"
    />

    <v-select
      v-show="showSelect"
      :value="limit"
      dense
      hide-details
      :items="[10, 20, 50, 100]"
      class="t-pagination--select"
      @input="updateLimit"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

type ResponsiveClassName = 'x-small-pagination'

export default Vue.extend({
  name: 'TPagination',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      required: true,
    },
    /**
     * The number of items on the current page.
     */
    currentPageCount: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    total: {
      /**
       * Can be `null` if total is unknown
       */
      type: Number,
      default: null,
    },
  },
  computed: {
    showPageNumbers(): boolean {
      return this.total !== null
    },
    page(): number {
      return Math.ceil(this.offset / this.limit) + 1
    },
    pageNumbers(): number {
      return Math.ceil(this.total / this.limit)
    },
    showSelect(): boolean {
      return this.width > 600
    },
    paginationClasses(): Record<ResponsiveClassName, boolean> {
      return {
        'x-small-pagination': this.width > 0 && this.width <= 250,
      }
    },
  },
  methods: {
    emitUpdate(offset: number, limit: number): void {
      this.$emit('update:pagination', {
        offset,
        limit,
      })
    },
    updatePage(page: number): void {
      const offset = (page - 1) * this.limit

      this.emitUpdate(offset, this.limit)
    },
    updateLimit(limit: number): void {
      const offset =
        this.page > this.pageNumbers ? (this.page - 1) * limit : this.offset

      this.emitUpdate(offset, limit)
    },
  },
})
</script>
<style scoped>
.t-pagination-container {
  display: flex;
}
.t-pagination {
  background-color: transparent;
  display: flex;
  width: auto;
  align-items: center;
  text-align: center;
  border: none;
  padding: 0;
  position: sticky !important;
  bottom: 0;
  border-top: 1px solid lightgrey;
  --v-active-background: #e1f5fe;
  font-size: 12px !important;
  justify-content: space-between;
}
.t-pagination ::v-deep ul {
  margin-left: 18px;
}
.t-pagination ::v-deep .v-pagination {
  justify-content: flex-start;
  align-items: center;
}
.t-pagination ::v-deep li {
  justify-content: center !important;
  align-items: center !important;
}
.t-pagination ::v-deep .v-pagination__item--active {
  background-color: var(--v-active-background) !important;
  color: var(--v-ttPrimary-base);
  box-shadow: none;
}
.t-pagination ::v-deep .v-pagination__navigation {
  box-shadow: none;
  margin: 0;
}
.t-pagination ::v-deep .v-pagination__item {
  box-shadow: none;
  margin: 0;
  font-size: 13px;
}
.t-pagination ::v-deep .v-pagination__more {
  align-items: end;
  font-weight: bold;
}
.t-pagination ::v-deep .v-icon.v-icon {
  font-size: 20px;
}
.t-pagination--select {
  font-size: 13px;
  max-width: 60px;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
}
.t-pagination--select ::v-deep input[type='text'] {
  transition: box-shadow 0.3s ease; /* Adding transition */
  box-shadow: none; /* Initial box-shadow state */
}

.t-pagination--select:hover ::v-deep input[type='text'] {
  box-shadow: 0 1px 0 #040404 !important;
}

.t-pagination--select ::v-deep input[type='text']:hover {
  box-shadow: 0 1px 0 #040404 !important;
  -webkit-box-shadow: 0 1px 0 #040404 !important;
}
/**
 * Extra small (small dashboard columns)
 * 0 > 250
 */
.x-small-pagination {
  ::v-deep .t-pagination {
    width: 100%;
  }
  ::v-deep .v-pagination__more,
  & ::v-deep .v-pagination__item {
    display: none;
  }
}
</style>
