import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'account',
  'position',
  'applicationId',
  'installationCode',
  'number',
  'updatedOn',
] as ColumnInputDefinition[]
