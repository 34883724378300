<template>
  <div>
    <v-select
      v-model="model"
      dense
      outlined
      :label="label"
      :placeholder="placeholder"
      clearable
      :items="palettes"
      :error-messages="errorMessages"
    >
      <template #selection="data">
        <div style="width: 100%">
          <v-row no-gutters>
            <v-col
              v-for="color in data.item.colors"
              :key="color"
              :style="{
                backgroundColor: color,
                height: '30px',
              }"
            ></v-col>
          </v-row>
        </div>
      </template>
      <template #item="data">
        <v-row no-gutters>
          <v-col
            v-for="color in data.item.colors"
            :key="color"
            :style="{
              backgroundColor: color,
              height: '30px',
            }"
          ></v-col>
        </v-row>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import colorPalettes, {
  Palette,
} from '@/tt-widget-components/lib/color-palettes'
import BaseInput from '@/tt-widget-components/components/BaseInput'

interface ColorPaletteListItem {
  value: string
  text: string
  colors: string[]
}

export default Vue.extend({
  name: 'ColorPaletteSelector',
  extends: BaseInput,
  computed: {
    palettes(): ColorPaletteListItem[] {
      const out: ColorPaletteListItem[] = []
      colorPalettes.forEach((colorPalette: Palette) => {
        if (colorPalette.name !== 'SHADES') {
          out.push({
            value: colorPalette.name,
            colors: colorPalette.colors,
            text: colorPalette.name,
          })
        }
      })
      return out
    },
  },
})
</script>
