<template>
  <div :class="['d-flex', { 'pa-2': withinDatePicker }]">
    <div v-for="(conflict, key) in conflictsInfo" :key="key">
      <v-btn
        v-if="conflict.displayItem"
        class="d-flex align-center"
        style="text-transform: unset !important"
        text
        @click="openConflicts(key)"
      >
        <span class="pr-1" v-text="$t(conflict.text)" />
        <v-chip
          small
          link
          pill
          :color="conflict.color.background"
          :text-color="conflict.color.text"
          v-text="conflict.amount"
        />
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import LeaveRequestsConflictsList from './LeaveRequestsConflictsList.vue'

import {
  ActiveWindowProvider,
  LayoutWindowEvent,
  PageInterface,
} from '@/tt-app-layout'
import { Resources } from '../../types'
import { ConflictInfo, LeaveRequest, Shift } from './types'
import { ServiceLevelImpactColors } from '@/tt-widget-views/leave-management/types'
import { DIALOG_WIDTH_MEDIUM } from '@/helpers/constants'

export default (Vue as VueConstructor<Vue & ActiveWindowProvider>).extend({
  name: 'ConflictButtons',
  inject: { activeWindow: { default: undefined } },
  props: {
    conflictingShifts: {
      type: Array as PropType<Shift[]>,
      default: () => [],
    },
    conflictingLeaves: {
      type: Array as PropType<LeaveRequest[]>,
      default: () => [],
    },
    withinDatePicker: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translationBase(): string {
      return 'tt-entity-design.leave-requests'
    },
    conflictingLeavesAmount(): number {
      return this.conflictingLeaves.length
    },
    conflictingShiftsAmount(): number {
      return this.conflictingShifts.length
    },
    conflictingShiftsText(): string {
      return this.$t(`${this.translationBase}.conflicting-shifts`)
    },
    conflictingLeavesText(): string {
      return this.$t(`${this.translationBase}.conflicting-leaves`)
    },
    shiftConflict(): ConflictInfo {
      return {
        resource: Resources.SHIFTS,
        amount: this.conflictingShiftsAmount,
        color: ServiceLevelImpactColors.MEDIUM_LOW,
        text: this.conflictingShiftsText,
        displayItem: this.hasShiftConflicts,
        conflicts: this.conflictingShifts,
      }
    },
    leaveConflict(): ConflictInfo {
      return {
        resource: Resources.LEAVE_REQUESTS,
        amount: this.conflictingLeavesAmount,
        color: ServiceLevelImpactColors.HIGH,
        text: this.conflictingLeavesText,
        displayItem: this.hasLeaveConflicts,
        conflicts: this.conflictingLeaves,
      }
    },
    hasShiftConflicts(): boolean {
      return this.conflictingShifts.length > 0
    },
    hasLeaveConflicts(): boolean {
      return this.conflictingLeaves.length > 0
    },
    shiftConflictInfo(): ConflictInfo[] {
      return this.hasShiftConflicts ? [this.shiftConflict] : []
    },
    leaveConflictInfo(): ConflictInfo[] {
      return this.hasLeaveConflicts ? [this.leaveConflict] : []
    },
    conflictsInfo(): ConflictInfo[] {
      return [...this.shiftConflictInfo, ...this.leaveConflictInfo]
    },
  },
  methods: {
    openConflicts(key: number) {
      this.withinDatePicker ? this.dispatchDialog(key) : this.addPageStack(key)
    },
    addPageStack(key: number) {
      const page: PageInterface = {
        closeButton: false,
        is: LeaveRequestsConflictsList,
        props: { conflictInfo: this.conflictsInfo, tabSelected: key },
      }
      this.activeWindow?.next(page)
    },
    dispatchDialog(key: number) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: LeaveRequestsConflictsList,
        props: { conflictInfo: this.conflictsInfo, tabSelected: key },
        width: DIALOG_WIDTH_MEDIUM,
      })
    },
  },
})
</script>
