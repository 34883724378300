// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ZoneClientsTile from './ZoneClientsTile.vue'
import ZoneClientsColumns from './ZoneClientsColumns'
import ZoneClientsDetail from './ZoneClientsDetail.vue'
import ZoneClientsReference from './ZoneClientsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ZoneClientsTile', ZoneClientsTile)
    Vue.component('ZoneClientsDetail', ZoneClientsDetail)
    Vue.component('ZoneClientsReference', ZoneClientsReference)

    modularManager.addResourcePreset(
      Resources.ZONE_CLIENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ZoneClientsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ZONE_CLIENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ZoneClientsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ZONE_CLIENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ZoneClientsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ZONE_CLIENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ZoneClientsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
