// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetExceptionTypesTile from './MobileRunsheetExceptionTypesTile.vue'
import MobileRunsheetExceptionTypesColumns from './MobileRunsheetExceptionTypesColumns'
import MobileRunsheetExceptionTypesDetail from './MobileRunsheetExceptionTypesDetail.vue'
import MobileRunsheetExceptionTypesReference from './MobileRunsheetExceptionTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileRunsheetExceptionTypesTile',
      MobileRunsheetExceptionTypesTile,
    )
    Vue.component(
      'MobileRunsheetExceptionTypesDetail',
      MobileRunsheetExceptionTypesDetail,
    )
    Vue.component(
      'MobileRunsheetExceptionTypesReference',
      MobileRunsheetExceptionTypesReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetExceptionTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetExceptionTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetExceptionTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetExceptionTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
