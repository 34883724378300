import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'

export enum DocumentProviders {
  HTML = 'DOCUMENT_HTML',
  DOCUMENT_SERVICE = 'DOCUMENT_SERVICE',
  TTCLOUD = 'DOCUMENT_TTCLOUD',
  EXTERNAL = 'DOCUMENT_EXTERNAL',
}

export enum DocumentServiceMethods {
  GET = 'GET',
  POST = 'POST',
}

export interface DocumentProviderInterface {
  getProviderTag(): DocumentProviders
  getMethod(): DocumentServiceMethods
  getDocumentOptionsSchema(input: DocumentInput): Promise<JSONSchema7 | null>
  getDocumentUrl(
    input: DocumentInput,
    params?: Record<string, string>,
  ): Promise<string>
}

export interface DocumentInput {
  provider: DocumentProviders
  locale: string
  documentName: string
  entityReference?: EntityItemViewInterface
  options?: any
}
