// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BillingAddressesTile from './BillingAddressesTile.vue'
import BillingAddressesColumns from './BillingAddressesColumns'
import BillingAddressesDetail from './BillingAddressesDetail.vue'
import BillingAddressesMetaCard from './BillingAddressesMetaCard.vue'
import BillingAddressesReference from './BillingAddressesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BillingAddressesTile', BillingAddressesTile)
    Vue.component('BillingAddressesDetail', BillingAddressesDetail)
    Vue.component('BillingAddressesMetaCard', BillingAddressesMetaCard)
    Vue.component('BillingAddressesReference', BillingAddressesReference)

    modularManager.addResourcePreset(
      Resources.BILLING_ADDRESSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BillingAddressesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADDRESSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BillingAddressesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADDRESSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BillingAddressesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADDRESSES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BillingAddressesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADDRESSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BillingAddressesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
