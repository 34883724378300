import hook from '../../base/DataSetWidgetHookChart'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import HorizontalChartWidgetEditor from './HorizontalChartWidgetEditor.vue'
import Vue from 'vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "HorizontalChartWidget" */ './HorizontalChartWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('HorizontalChartWidgetEditor', HorizontalChartWidgetEditor)

const HorizontalChartWidget: WidgetTypeDefinition = {
  name: WidgetName.HORIZONTAL_CHART_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    HorizontalChartWidget: {
      view: {
        is: 'HorizontalChartWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-align-horizontal-left',
    color: '#9b2fae',
    thumbnail: require('./horiz-chart.png'),
    print: true,
  },
  ttcIgnoredAttrs: ['dataSet.sort'],
}

export default HorizontalChartWidget
