import { HeatMapModel } from '@syncfusion/ej2-vue-heatmap'

import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import { DataSetRow, DataSetValue } from '@/tt-widget-components'
import {
  getTextStyle,
  getTheme,
} from '@/tt-widget-components/syncfusion/helpers'
import { getWidgetPaletteCollection } from '@/tt-widget-components/lib/color-palettes'
import { HeatMapWidgetModel } from '@/tt-widget-components/schemas-types'
import {
  SyncFusionComponentTag,
  WidgetSyncFusionModelAdapter,
  WidgetSyncFusionComponentAdapter,
} from '../types'
import { DimensionModifier } from '@/tt-widget-factory'
import {
  dayOfWeekDictionary,
  monthsDictionary,
} from '@/tt-widget-components/helpers/dictionaries'
import i18n from '@/plugins/i18n'

const uniqueJSONValues = (jsonArray: DataSetRow[], key: string): unknown[] => {
  return [...new Set(jsonArray.map((row) => row[key]))]
}

const getValueTableByJson = (
  hook: DataSetWidgetHook<HeatMapWidgetModel>,
  jsonData: DataSetRow[],
  xAxisDimensionKey: string,
  yAxisDimensionKey: string,
  valueMap: string,
): (string | number)[][] => {
  const xAxisValues = uniqueJSONValues(jsonData, xAxisDimensionKey)
  const yAxisValues = uniqueJSONValues(jsonData, yAxisDimensionKey)

  // Initialize the table
  const table: DataSetValue[][] = xAxisValues.map(() =>
    yAxisValues.map(() => 0),
  )

  // Calculate the table values
  jsonData.forEach((item) => {
    const xValue: DataSetValue = item[xAxisDimensionKey]
    const xIndex = xAxisValues.indexOf(xValue)

    const yValue: DataSetValue = item[yAxisDimensionKey]
    const yIndex = yAxisValues.indexOf(yValue)

    table[xIndex][yIndex] += item[valueMap]
  })

  // Format the table values
  return table.map((tableRow: DataSetValue[]) =>
    tableRow.map((tableCell: DataSetValue) =>
      hook.formatMeasureValue(
        tableCell,
        // @ts-ignore -- Type '"NA"' is not assignable to type 'MeasureFormat'
        hook.widget.dataSet.measure.format,
        false,
      ),
    ),
  )
}

const getSortedData = (
  hook: DataSetWidgetHook<HeatMapWidgetModel>,
): DataSetRow[] => {
  return hook.sortDataSource(
    hook.series[0]?.asSeries ?? [],
    0,
    hook.widget.dataSet.sort,
  )
}

const getDataSource = (
  hook: DataSetWidgetHook<HeatMapWidgetModel>,
): (string | number)[][] => {
  if (!hook.series || !hook.series[0]) {
    return []
  }
  const sortedData = getSortedData(hook)
  const xAxisDimensionKey = hook.getDimensionByIndex(0, 0).key
  const yAxisDimensionKey = hook.getDimensionByIndex(0, 1).key
  const value = hook.getMeasureByIndex(0, 0).key

  if (!xAxisDimensionKey || !yAxisDimensionKey || !value) {
    return []
  }
  return getValueTableByJson(
    hook,
    sortedData,
    xAxisDimensionKey,
    yAxisDimensionKey,
    value,
  )
}

const formatters = {
  [DimensionModifier.MONTHNUMBER]: (month) =>
    monthsDictionary[month] ? i18n.t(monthsDictionary[month]) : 'N/A',
  [DimensionModifier.DAYOFWEEKNUMBER]: (dayOfWeek) =>
    dayOfWeekDictionary[dayOfWeek]
      ? i18n.t(dayOfWeekDictionary[dayOfWeek])
      : 'N/A',
}

const getXAxis = (hook: DataSetWidgetHook<HeatMapWidgetModel>): string[] => {
  const { key, modifier } = hook.getDimensionByIndex(0, 0)
  const xAxis = key
    ? (uniqueJSONValues(getSortedData(hook), key) as string[])
    : []

  const formatter = formatters[modifier]
  return formatter ? xAxis.map(formatter) : xAxis
}

const getYAxis = (hook: DataSetWidgetHook<HeatMapWidgetModel>): string[] => {
  const { key, modifier } = hook.getDimensionByIndex(0, 1)
  const yAxis = key
    ? (uniqueJSONValues(getSortedData(hook), key) as string[])
    : []

  const formatter = formatters[modifier]
  return formatter ? yAxis.map(formatter) : yAxis
}

const getHeatMapSettings: WidgetSyncFusionModelAdapter<
  DataSetWidgetHook<HeatMapWidgetModel>,
  HeatMapModel
> = (hook, context) => ({
  dataSource: getDataSource(hook),
  height: context.height?.toString(),
  paletteSettings: {
    palette: getWidgetPaletteCollection(hook.widget),
  },
  theme: getTheme(context.dark),
  xAxis: {
    labelRotation: 45,
    labels: getXAxis(hook),
    textStyle: getTextStyle(12),
  },
  yAxis: {
    labels: getYAxis(hook),
    textStyle: getTextStyle(12),
  },
  width: context.width?.toString(),
})

export const getHeatMapSyncFusionComponent: WidgetSyncFusionComponentAdapter<
  DataSetWidgetHook<HeatMapWidgetModel>,
  HeatMapModel
> = (hook, context) => ({
  is: SyncFusionComponentTag.HeatMap,
  props: getHeatMapSettings(hook, context),
})
