import { Attribute } from '@/tt-widget-factory/services/resource-meta/types'
import { AttributeFilterOptions, Filter } from '@/tt-widget-components'

export interface AttributeFilterItem {
  attributeName: string
}

/**
 * The filter settings used for the filter elements
 */
export interface FilterSetting {
  component?: string
  icon?: string
  attributeName: string
  attributeMeta: Attribute
  filter?: Filter
  options?: AttributeFilterOptions
  label?: string
}

export interface FilterValues {
  whereQL?: string
  includeInactive?: boolean
  scope?: string[]
  search?: string | null
  filters?: { [k: string]: Filter }
}

export enum EntityFilterEvent {
  TOOLBAR_MENU_ACTIVE = 'filter.toolbar.menu-active',
}

export type EntityFilterEventPayload = {
  [EntityFilterEvent.TOOLBAR_MENU_ACTIVE]: boolean
}

export enum FilterDateTimeTabs {
  PRESETS = 'presets',
  RANGE = 'range',
}

export type FilterDateTimeTab = {
  key: FilterDateTimeTabs
  label: string
}
