<template>
  <!-- 404 or permission errors -->
  <EntityItemUnavailable v-if="hasFetchErrors" />
  <!-- / 404 or permission errors -->

  <EntityItem
    v-else
    :key="resourceName + '-' + entityId + '-' + key"
    v-bind="{ entity, entityId, resourceName }"
    :item-hook="customItemHook"
    class="flex--column"
  >
    <ResourceAllowedOperations
      v-slot="{ allowsActions, allowsEdit }"
      :resource-name="resourceName"
      :entity="customItemHook.data"
    >
      <!-- Todo refactor this component https://tracktik.atlassian.net/browse/FE-1159 -->
      <EntityActions
        v-slot="{ actions, canEdit: canEditItem }"
        class="level0"
        :entity-id="entityId"
        :resource-name="resourceName"
        v-bind="$props"
      >
        <v-btn
          v-if="!!onEdit && allowsEdit && canEditItem"
          small
          fab
          dark
          absolute
          right
          color="green"
          @click="edit"
        >
          <v-icon>edit</v-icon>
        </v-btn>

        <v-list two-line class="entity-preview--tile">
          <tt-preset-view type="listItem" />
        </v-list>

        <template v-if="allowsActions">
          <component
            :is="actionBarPreset.is"
            v-if="actionBarPreset"
            :actions="actions"
          />

          <EntityActionsAsList v-else :actions="actions">
            <template #beforeList>
              <!-- @TODO: to review if can be removed, seems to be used by site-tasks only -->
              <AppSlot
                :slot-name="`${slotName}.actions.top`"
                :props-data="{ ...propsData, actions }"
              />
            </template>
          </EntityActionsAsList>
        </template>
      </EntityActions>
    </ResourceAllowedOperations>

    <AppSlot :slot-name="`${slotName}.details.top`" :props-data="propsData" />

    <TabSlot
      :slot-name="`${slotName}.tabs`"
      :context="{ itemHook: customItemHook }"
    >
      <div v-if="!tabs" class="fill-height">
        <EntityDetailCard v-bind="propsData" />
      </div>
    </TabSlot>
  </EntityItem>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormOptions } from '@tracktik/tt-json-schema-form'

import { ActionBarPreset, modularManager } from '@/tt-app-modular'
import { Resources } from '@/tt-entity-design/src/types'
import {
  ActiveWindowPageProvider,
  ActiveWindowProvider,
  LayoutWindowEvent,
} from '@/tt-app-layout'

import { EntityIntentTypes } from '../intents'
import { EntityItemHook } from '../EntityItemHook'
import { EntityPreviewEditHandler } from '../types'

type VueWithInjections = VueConstructor<
  Vue &
    ActiveWindowPageProvider &
    ActiveWindowProvider & { formOptions: FormOptions }
>

const defaultEditCallback: EntityPreviewEditHandler = ({
  itemHook,
  formOptions,
}) => {
  itemHook.dispatchEdit({ formOptions })
}

export default (Vue as VueWithInjections).extend({
  name: 'EntityPreview',
  inject: {
    activeWindow: { default: undefined },
    activeWindowPage: { default: undefined },
    formOptions: { default: undefined },
  },
  props: {
    resourceName: {
      type: String as PropType<Resources>,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    entity: {
      type: Object,
      default: undefined,
    },
    onEdit: {
      type: Function as PropType<EntityPreviewEditHandler>,
      default: defaultEditCallback,
    },
  },
  data() {
    return {
      tabs: 0,
      // @ts-ignore -- methods not seen by TS
      customItemHook: this.createCustomHook() as EntityItemHook,
      key: 'initial',
    }
  },
  computed: {
    actionBarPreset(): ActionBarPreset | null {
      return modularManager.getActionBar(this.resourceName)
    },
    propsData(): {
      resourceName: string
      entityId: number
      entity: Record<string, any>
    } {
      return {
        resourceName: this.resourceName,
        entityId: this.entityId,
        entity: this.entity,
      }
    },
    slotName(): string {
      return `${this.resourceName}.preview`
    },
    hasFetchErrors(): boolean {
      return this.customItemHook.hasFetchErrors
    },
  },
  beforeDestroy() {
    this.customItemHook.beforeDestroy()

    const { resourceName, entityId } = this

    this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW_CLOSE, {
      resourceName,
      entityId,
    })
  },
  methods: {
    createCustomHook(): EntityItemHook {
      const { resourceName, entityId } = this

      return new EntityItemHook(
        this.$appContext,
        { resourceName, entityId },
        {
          fetchEnabled: true,
          hasFetched: false,
          fetchActions: true,
          events: {
            /**
             * When the entity is updated, we create a new itemHook and force the component to re-render,
             * to refetch all required attributes into the new itemHook.
             */
            onEntityUpdated: () => this.refreshItemHookAndReRender(),
            /**
             * Once the entity is deleted, the component must be closed to avoid user
             * interaction and causing errors.
             */
            onEntityDeleted: () => this.closePage(),
          },
        },
      )
    },
    refreshItemHookAndReRender() {
      this.key = Math.random().toString()
    },
    edit() {
      if (this.onEdit) {
        this.onEdit({
          activeWindow: this.activeWindow,
          activeWindowPage: this.activeWindowPage,
          formOptions: this.formOptions,
          itemHook: this.customItemHook,
        })
      }
    },
    closePage(): void {
      if (this.activeWindow && this.activeWindowPage?.index) {
        /**
         * Navigates explicitly to the previous page instead of calling
         * activeWindow.back() because this method can also be called when the
         * EntityPreview is not on the top of the page stack.
         */
        const previousPage = this.activeWindowPage.index - 1
        this.activeWindow.goTo(previousPage)
      } else {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_CLOSE, {})
      }
    },
  },
})
</script>

<style scoped>
.entity-preview--tile {
  margin: 0;
}
</style>
