<template>
  <div>
    <TLoadingBar v-if="!isReady || loading" />

    <DispatchAssignSelectedItem
      v-else-if="hasEntity"
      class="ma-3"
      :resource="resource"
      :entity-id="entityId"
      @clear="clear"
    />

    <DispatchAssignView v-else v-bind="assignViewProps" />

    <json-field
      v-if="isUserResource"
      class="ma-3"
      name="notify"
      as="v-switch"
      color="success"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import DispatchAssignView from './assign/DispatchAssignView.vue'
import DispatchAssignSelectedItem from './assign/DispatchAssignSelectedItem.vue'
import { Resources } from '../../types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components'
import { updateDOM } from '../../../../helpers/dom/updateDOM'
import {
  ApiActionPayload,
  AssignType,
  AssignViewProps,
  PayloadGroup,
  PayloadUser,
  SelectPayload,
  TaskInterface,
} from './types'
import {
  createAssignManager,
  AssignManagerInterface,
} from './assign/dispatch-assign-manager'

export type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'DispatchTasksAssign',
  components: { DispatchAssignView, DispatchAssignSelectedItem },
  inject: ['formHook'],
  data: () => ({
    loading: false,
  }),
  computed: {
    payload(): ApiActionPayload {
      return this.formHook().value as ApiActionPayload
    },
    notify: {
      get(): boolean {
        return !!this.payload?.notify
      },
      set(newValue: boolean | null) {
        this.formHook().setObjectValue('notify', newValue ?? false)
      },
    },
    resource(): AssignType | '' {
      const groupResource = this.formHook().getPathValue('group.resource') || ''

      return this.formHook().getPathValue('user')
        ? 'users'
        : groupResource.toLowerCase()
    },
    isUserResource(): boolean {
      return this.resource === 'users'
    },
    entityId(): number | null {
      return (
        this.formHook().getPathValue('user') ||
        Number(this.formHook().getPathValue('group.id')) ||
        null
      )
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    hasEntity(): boolean {
      return !!(this.entityId && this.resource)
    },
    assignViewProps(): AssignViewProps {
      return {
        coordinates: this.assignManager.getCoordinates(),
        regionId: this.assignManager.getRegionId(),
        cityName: this.assignManager.getCity(),
        stateName: this.assignManager.getState(),
        taskTypeId: this.assignManager.getTaskTypeId(),
        clientId: this.assignManager.getClientId(),
        onSelect: this.onSelect,
      }
    },
    assignManager(): AssignManagerInterface {
      return createAssignManager(this.taskData)
    },
    isReady(): boolean {
      return this.assignManager.isReady()
    },
    taskData(): TaskInterface {
      const location = {
        type: this.itemHook.getRawValue('location.type'),
        regionId: this.itemHook.getRawValue('location.region.id'),
        latitude: this.itemHook.getRawValue('location.latitude'),
        longitude: this.itemHook.getRawValue('location.longitude'),
        city: this.itemHook.getRawValue('location.city'),
        state: this.itemHook.getRawValue('location.state'),
      }

      const accountLocation = {
        accountId: this.itemHook.getRawValue('account.id'),
        addressId: this.itemHook.getRawValue('account.address.id'),
        latitude: this.itemHook.getRawValue('account.address.latitude'),
        longitude: this.itemHook.getRawValue('account.address.longitude'),
        city: this.itemHook.getRawValue('account.address.city'),
        state: this.itemHook.getRawValue('account.address.state'),
      }

      return {
        clientId: this.itemHook.getRawValue('client.id'),
        clientRegionId: this.itemHook.getRawValue('client.region.id'),
        taskTypeId: this.itemHook.getRawValue('taskType.id'),
        locationType: this.itemHook.getRawValue('locationType'),
        location,
        accountLocation,
      }
    },
  },
  created() {
    this.itemHook.addAttribute('client.id')
    this.itemHook.addAttribute('client.region.id')
    this.itemHook.addAttribute('taskType.id')
    this.itemHook.addAttribute('location.type')
    this.itemHook.addAttribute('location.region.id')
    this.itemHook.addAttribute('location.latitude')
    this.itemHook.addAttribute('location.longitude')
    this.itemHook.addAttribute('location.city')
    this.itemHook.addAttribute('location.state')
    this.itemHook.addAttribute('locationType')
    this.itemHook.addAttribute('account.id')
    this.itemHook.addAttribute('account.address.id')
    this.itemHook.addAttribute('account.address.city')
    this.itemHook.addAttribute('account.address.state')
    this.itemHook.addAttribute('account.address.country')
    this.itemHook.addAttribute('account.address.latitude')
    this.itemHook.addAttribute('account.address.longitude')
  },
  methods: {
    async onSelect({ resource, id }: SelectPayload) {
      this.loading = true

      await updateDOM()

      if (resource === Resources.USERS) {
        const payload: PayloadUser = {
          user: id,
          notify: this.notify,
          group: undefined,
        }

        this.formHook().setObjectValue('', payload)
      } else {
        const payload: PayloadGroup = {
          group: {
            // @ts-ignore -- API schema is missing "positions"
            resource: resource.toUpperCase() as Uppercase<typeof resource>,
            id: String(id),
          },
          notify: false,
          user: undefined,
        }

        this.formHook().setObjectValue('', payload)
      }

      this.loading = false
    },
    async clear() {
      this.loading = true

      await updateDOM()

      this.formHook().setValue({
        group: undefined,
        notify: undefined,
        user: undefined,
      })

      this.loading = false
    },
  },
})
</script>
