import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { CalendarExceptionTypeLevel } from '@/tt-entity-design/src/components/calendar-exceptions/types'

export default [
  'description',
  {
    attributeName: 'type',
    headerText: 'tt-entity-design.calendar-exceptions.columns.type.header-text',
    component: {
      is: 'CalendarExceptionsTypeColumn',
      props: {
        level: CalendarExceptionTypeLevel.Root,
        attrName: 'exceptionType',
      },
    },
  },
  'date',
  'status',
  'impactRunsheet',
  'impactSchedule',
] as ColumnInputDefinition[]
