<template>
  <tt-attr-action :action="action">
    <template v-bind="{ action, small }">
      <OpenShiftRequestsActionBtn :action="action" :small="small" />
    </template>
  </tt-attr-action>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'OpenShiftRequestsActions',
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
