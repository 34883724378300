import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'subject',
  'account',
  'version',
  // we need RelationList to display 'acknowledgements'
  // 'acknowledgements',
  'updatedOn',
] as ColumnInputDefinition[]
