import { LocationType, TaskInterface } from '../types'

export interface AssignManagerInterface {
  isReady: () => boolean
  getRegionId: () => number
  getCoordinates: () => string | null
  getCity: () => string
  getState: () => string
  getTaskTypeId: () => number | null
  getClientId: () => number | null
}

export const createAssignManager = (
  taskData: TaskInterface,
): AssignManagerInterface => {
  const isAccountAddress = (): boolean =>
    taskData.locationType === LocationType.ACCOUNT_ADDRESS

  const getRegionId = (): number => {
    return isAccountAddress()
      ? taskData.clientRegionId
      : taskData.location?.regionId
  }

  const isReady = (): boolean => {
    const checkRequiredValues = () =>
      ['taskTypeId', 'clientId', 'locationType']
        .map((attr) => taskData[attr])
        .every(Boolean)
    const regionId = getRegionId() || taskData.clientRegionId

    return regionId && checkRequiredValues()
  }

  const getCoordinates = (): string | null => {
    const createCoordinates = (lat, long) =>
      lat && long ? `${lat},${long}` : null

    const getTaskLocationCoordinates = () => {
      const lat = taskData.location?.latitude
      const long = taskData.location?.longitude

      return createCoordinates(lat, long)
    }

    const getAccountCoordinates = () => {
      const lat = taskData.accountLocation?.latitude
      const long = taskData.accountLocation?.longitude

      return lat && long ? `${lat},${long}` : null
    }

    return isAccountAddress()
      ? getAccountCoordinates()
      : getTaskLocationCoordinates()
  }

  const getCity = (): string => {
    return isAccountAddress()
      ? taskData.accountLocation?.city
      : taskData.location?.city
  }

  const getState = (): string => {
    return isAccountAddress()
      ? taskData.accountLocation?.state
      : taskData.location?.state
  }

  const getTaskTypeId = (): number | null => {
    return taskData.taskTypeId || null
  }

  const getClientId = (): number | null => {
    return taskData.clientId || null
  }

  return {
    isReady,
    getRegionId,
    getCoordinates,
    getCity,
    getState,
    getTaskTypeId,
    getClientId,
  }
}
