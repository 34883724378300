import moment from 'moment'
import { defaultFormat, formatDate } from './formatDate'

// Stack overfow reference https://stackoverflow.com/a/23796069
export const enumerateDaysBetweenDates = (
  startDate: string,
  endDate: string,
  formatType = defaultFormat,
): string[] => {
  const dates = []

  const firstDate = moment(startDate).startOf('day')
  const lastDate = moment(endDate).startOf('day')

  while (firstDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(formatDate(firstDate.clone().toDate(), formatType))
  }

  return dates
}
