// Enter your custom installation here

import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { modularManager } from '@/tt-app-modular'
import { Resources } from '@/tt-entity-design/src/types'

import { ZoneActions } from './types'
import { Attribute } from '@/tt-entity-design/src/schema-types'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

const { ZONES } = Resources
const getAttr = (attr: Attribute<typeof ZONES>): string => attr

export default {
  install(Vue) {
    // Add the schedule preview in the tabs
    addPreviewTab(ZONES, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })

    modularManager.registerDynamicResourceJsonSchemaActions(ZONES, [
      ZoneActions.ImportExceptionDates,
    ])

    addPreviewTab(ZONES, {
      title: createI18nResourceKey(ZONES, getAttr('clients')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('clients'),
      },
    })

    addPreviewTab(ZONES, {
      title: createI18nResourceKey(ZONES, getAttr('positions')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('positions'),
      },
    })

    addPreviewTab(ZONES, {
      title: createI18nResourceKey(ZONES, getAttr('subZones')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('subZones'),
      },
    })
  },
}
