import { Resources } from '@/tt-entity-design/src/types'
import {
  AssetStatusCode,
  AssetStatusColor,
  assetStatusEnums,
  ColumnBlackList,
  FiltersBlackList,
} from '../types'
import { PresetItem } from '@/tt-app-modular'

export const assetStatusColorMap: Record<AssetStatusCode, AssetStatusColor> = {
  [assetStatusEnums.ACTIVE]: {
    backgroundColor: '#DEF9E4',
    textColor: '#0E8129',
  },
  [assetStatusEnums.INACTIVE]: {
    backgroundColor: '#FFEFE1',
    textColor: '#F17300',
  },
  [assetStatusEnums.PENDING]: {
    backgroundColor: '#E2831E',
    textColor: '#FFECD0',
  },
}

/**
 * Default Preset
 * Used to set the default icon and color for the menu item in the "Create" dropdown menu.
 */
export const defaultPreset: PresetItem = {
  title: '',
  data: { icon: 'arrow-left', color: 'red' },
}

/**
 * Asset Hub module permission to access region level
 */
export const ASSET_PERMISSION_REGION_LEVEL = 'assetHubModule:accessRegionLevel'

/**
 * Region Level Filters Blacklist
 * Filters that should not be displayed in the region level
 */
export const REGION_LEVEL_FILTERS_BLACKLIST: FiltersBlackList = {
  [Resources.ASSET_TYPES]: ['manufacturer', 'supplier'],
}

/**
 * Region Level Columns Blacklist
 * Columns that should not be displayed in the region level
 */
export const REGION_LEVEL_COLUMNS_BLACKLIST: ColumnBlackList = {
  [Resources.ASSET_TYPES]: ['manufacturer'],
}

/**
 * Assets Translations Actions
 * Set of actions to delete asset categories and types translations
 */
export enum AssetsTranslationsActions {
  DELETE_ASSET_TYPE_TRANSLATION = 'delete-asset-type-translation',
  DELETE_ASSET_CATEGORY_TRANSLATION = 'delete-asset-category-translation',
}
