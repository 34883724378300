// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AuditLogsTile from './AuditLogsTile.vue'
import AuditLogsColumns from './AuditLogsColumns'
import AuditLogsDetail from './AuditLogsDetail.vue'
import AuditLogsReference from './AuditLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AuditLogsTile', AuditLogsTile)
    Vue.component('AuditLogsDetail', AuditLogsDetail)
    Vue.component('AuditLogsReference', AuditLogsReference)

    modularManager.addResourcePreset(
      Resources.AUDIT_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AuditLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.AUDIT_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AuditLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.AUDIT_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AuditLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.AUDIT_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AuditLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
