import { AppContext } from '@/tt-app-context'
import { Model } from '@vuex-orm/core'
import { Module } from 'vuex'
import { ShareService } from './ShareService'
import { AclTag, UserGroupMap } from './types'

/**
 * VuexORM Store Model to be used across the app
 * to store the current user groups fetched
 */
export class UserGroupsModel extends Model {
  static entity = 'UserGroups'
}

type State = {
  dictionnary: UserGroupMap
  myUserGroups: AclTag[]
}

export const UserGroupsStoreModule: Module<State, unknown> = {
  state: {
    dictionnary: {},
    myUserGroups: [],
  },
  actions: {
    fetchMyUserGroups({ commit }, appContext: AppContext) {
      const setMyUserGroups = (res: UserGroupMap) => {
        const userGroupsMap = res ?? {}
        commit('setMyUserGroups', userGroupsMap)
      }

      return new ShareService(appContext)
        .getUserGroups()
        .then(setMyUserGroups)
        .catch(console.warn)
    },
  },
  mutations: {
    setDictionnary(state, payload: UserGroupMap) {
      state.dictionnary = { ...payload }
    },
    setMyUserGroups(state, userGroupsMap: UserGroupMap) {
      state.dictionnary = { ...userGroupsMap }
      state.myUserGroups = [...Object.keys(userGroupsMap)]
    },
  },
  getters: {
    myUserGroups: (state) => state.myUserGroups,
  },
}
