<template>
  <tt-attr name="status">
    <TChipLabel v-if="status" v-bind="chipProps" />
  </tt-attr>
</template>
<script lang="ts">
import { TChipLabelType } from '@/tt-ui/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  carryOverStatus,
  carryOverStatusColor,
  carrOverStatusColors,
} from './types'
import Vue, { VueConstructor } from 'vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyCarryOverStatusChip',
  inject: ['getItemHook'],
  props: {
    isSmall: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    status(): string {
      return this.getItemHook().get('status')
    },
    rawStatus(): carryOverStatus {
      return this.getItemHook().getRawValue('status')
    },
    statusColor(): carryOverStatusColor {
      return carrOverStatusColors[this.rawStatus]
    },
    chipProps(): Record<string, unknown> {
      return {
        textColor: this.statusColor.text,
        backgroundColor: this.statusColor.background,
        modifiers: {
          style: {
            fontWeight: 'normal',
            textTransform: 'none',
            opacity: 1,
          },
        },
        isSmall: this.isSmall,
        textKey: this.status,
        type: TChipLabelType.Pill,
      }
    },
  },
})
</script>
