// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import Oauth2AuthCodesTile from './Oauth2AuthCodesTile.vue'
import Oauth2AuthCodesColumns from './Oauth2AuthCodesColumns'
import Oauth2AuthCodesDetail from './Oauth2AuthCodesDetail.vue'
import Oauth2AuthCodesReference from './Oauth2AuthCodesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('Oauth2AuthCodesTile', Oauth2AuthCodesTile)
    Vue.component('Oauth2AuthCodesDetail', Oauth2AuthCodesDetail)
    Vue.component('Oauth2AuthCodesReference', Oauth2AuthCodesReference)

    modularManager.addResourcePreset(
      Resources.OAUTH2_AUTH_CODES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'Oauth2AuthCodesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_AUTH_CODES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: Oauth2AuthCodesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_AUTH_CODES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'Oauth2AuthCodesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OAUTH2_AUTH_CODES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'Oauth2AuthCodesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
