import { ApiDateTimeFormats } from '@/helpers/formats/dates'
import { DayID, DayName } from './types'

/**
 * The reference date used to calculate the current week days, and offset the days.
 * Used only internally to use a "real" calendar UI.
 * Could be any other date. Having a fix dates also helps for testing.
 */
export const DATE_REFERENCE = '2020-01-01'

/**
 * Find a way to type the day name against the API schema.
 */
export const ID_TO_DAY = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const

export const DAY_TO_ID: Record<DayName, DayID> = ID_TO_DAY.reduce(
  (acc, day, index) => ({ ...acc, [day]: index }),
  {} as Record<DayName, DayID>,
)

export const DATE_FORMAT = ApiDateTimeFormats.date

export const DAY_START_TIME = '00:00:00'
export const DAY_END_TIME = '23:59:59'
