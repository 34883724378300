// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchSlasTile from './DispatchSlasTile.vue'
import DispatchSlasColumns from './DispatchSlasColumns'
import DispatchSlasDetail from './DispatchSlasDetail.vue'
import DispatchSlasReference from './DispatchSlasReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchSlasTile', DispatchSlasTile)
    Vue.component('DispatchSlasDetail', DispatchSlasDetail)
    Vue.component('DispatchSlasReference', DispatchSlasReference)

    modularManager.addResourcePreset(
      Resources.DISPATCH_SLAS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchSlasTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLAS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchSlasColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLAS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchSlasDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_SLAS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchSlasReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
