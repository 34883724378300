export type SiteTaskFeatureFlags = {
  siteTaskFeatureFlags: {
    /**
     * Show the new SiteTask form + the "Instances" preview tab
     */
    enableSiteTaskEnhancement: boolean

    /**
     * Enable the calendar Exceptions UI
     */
    enableCalendarExceptions: boolean
  }
}

export const siteTaskFeatureFlags: Record<
  'default',
  SiteTaskFeatureFlags['siteTaskFeatureFlags']
> = {
  default: {
    enableSiteTaskEnhancement: false,
    enableCalendarExceptions: false,
  },
}
