// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LoneWorkerCheckInTicketsTile from './LoneWorkerCheckInTicketsTile.vue'
import LoneWorkerCheckInTicketsColumns from './LoneWorkerCheckInTicketsColumns'
import LoneWorkerCheckInTicketsDetail from './LoneWorkerCheckInTicketsDetail.vue'
import LoneWorkerCheckInTicketsMetaCard from './LoneWorkerCheckInTicketsMetaCard.vue'
import LoneWorkerCheckInTicketsReference from './LoneWorkerCheckInTicketsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LoneWorkerCheckInTicketsTile', LoneWorkerCheckInTicketsTile)
    Vue.component(
      'LoneWorkerCheckInTicketsDetail',
      LoneWorkerCheckInTicketsDetail,
    )
    Vue.component(
      'LoneWorkerCheckInTicketsMetaCard',
      LoneWorkerCheckInTicketsMetaCard,
    )
    Vue.component(
      'LoneWorkerCheckInTicketsReference',
      LoneWorkerCheckInTicketsReference,
    )

    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_TICKETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInTicketsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_TICKETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LoneWorkerCheckInTicketsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_TICKETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInTicketsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_TICKETS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInTicketsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LONE_WORKER_CHECK_IN_TICKETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LoneWorkerCheckInTicketsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
