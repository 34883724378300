<template>
  <v-btn
    v-if="!!requestsAmount"
    class="text-capitalize"
    :style="{
      color: buttonStyle.color,
      backgroundColor: buttonStyle['background-color'],
    }"
    elevation="0"
    small
    @click.stop="sideSheetDispatch"
  >
    <v-icon :color="buttonStyle.color" left small>
      {{ buttonStyle.icon }}
    </v-icon>
    <span>{{ btnText }}</span>
  </v-btn>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  FutureLeaveRequests,
  LeaveRequestsStatus,
  OverviewRequestsButtonColorOptions,
  OverviewButtonStyle,
} from '../../types'
import { EmployeeInfoProvider } from '@/tt-entity-design/src/components/leave-policy-items/types'
import { filterRequestsByStatus } from '../../utils'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & EmployeeInfoProvider>
).extend({
  name: 'OverviewRequestsButtons',
  inject: ['getItemHook', 'getEmployeeInfo'],
  props: {
    requestsStatus: {
      type: String as PropType<LeaveRequestsStatus>,
      required: true,
    },
  },
  computed: {
    leavePolicyItemId(): number {
      return this.getItemHook().get('id')
    },
    requestsLocale(): string {
      const localeStatus = this.requestsStatus.toLowerCase()
      const basePath = 'tt-entity-design.leave-management.employee.overview'
      return `${basePath}.${localeStatus}`
    },
    futurePendingRequests(): FutureLeaveRequests[] {
      const futureRequests = this.getItemHook().get(
        'futureLeaveRequests.pendingRequestsIds',
      )
      return filterRequestsByStatus(futureRequests, this.requestsStatus)
    },
    futureApprovedRequests(): FutureLeaveRequests[] {
      return this.getItemHook().get('futureLeaveRequests.approvedRequestsIds')
    },
    pastPendingRequests(): FutureLeaveRequests[] {
      const pastRequests = this.getItemHook().get(
        'pastLeaveRequests.pendingRequestsIds',
      )
      return filterRequestsByStatus(pastRequests, this.requestsStatus)
    },
    arePendingRequests(): boolean {
      return (
        this.requestsStatus === LeaveRequestsStatus.PENDING ||
        this.requestsStatus === LeaveRequestsStatus.PENDING_CANCELLATION
      )
    },
    areApprovedRequests(): boolean {
      return this.requestsStatus === LeaveRequestsStatus.APPROVED
    },
    buttonStyle(): OverviewButtonStyle {
      return OverviewRequestsButtonColorOptions[this.requestsStatus]
    },
    requests(): FutureLeaveRequests[] | null {
      if (this.arePendingRequests) {
        if (
          this.futurePendingRequests?.length &&
          this.pastPendingRequests?.length
        )
          return [...this.futurePendingRequests, ...this.pastPendingRequests]
        if (this.futurePendingRequests?.length)
          return [...this.futurePendingRequests]
        if (this.pastPendingRequests?.length)
          return [...this.pastPendingRequests]
      }

      if (this.areApprovedRequests && this.futureApprovedRequests?.length) {
        return this.futureApprovedRequests
      }

      return null
    },
    requestsAmount(): number {
      return this.requests?.length
    },
    btnText(): string {
      return this.$tc(`${this.requestsLocale}.amount`, this.requestsAmount)
    },
  },
  created() {
    if (!this.getItemHook().get('exentions.futureLeaveRequests')) {
      this.getItemHook().addAttribute('extensions.futureLeaveRequests')
    }
    if (!this.getItemHook().get('exentions.pastLeaveRequests')) {
      this.getItemHook().addAttribute('extensions.pastLeaveRequests')
    }
    this.getItemHook().addAttribute('id')
  },
  methods: {
    sideSheetDispatch() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET, {
        is: 'LeaveRequestsTileList',
        title: `${this.requestsLocale}.title`,
        props: {
          employeeId: this.getEmployeeInfo().id,
          leavePolicyItemId: this.leavePolicyItemId,
          requestsStatus: this.requestsStatus,
        },
      })
    },
  },
})
</script>
