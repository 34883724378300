// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PostOrdersTile from './PostOrdersTile.vue'
import PostOrdersColumns from './PostOrdersColumns'
import PostOrdersDetail from './PostOrdersDetail.vue'
import PostOrdersMetaCard from './PostOrdersMetaCard.vue'
import PostOrdersReference from './PostOrdersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PostOrdersTile', PostOrdersTile)
    Vue.component('PostOrdersDetail', PostOrdersDetail)
    Vue.component('PostOrdersMetaCard', PostOrdersMetaCard)
    Vue.component('PostOrdersReference', PostOrdersReference)

    modularManager.addResourcePreset(
      Resources.POST_ORDERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PostOrdersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PostOrdersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PostOrdersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PostOrdersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PostOrdersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
