<template>
  <div>
    <v-card flat class="pa-6">
      {{ $t('components.entity-view.fake') }}
      <v-btn @click="$emit('open', samplePage)">{{
        $t('common.next.btn')
      }}</v-btn>
    </v-card>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'EntityView',
  props: {
    resourceName: String,
    entityId: Number,
    entity: Object,
  },
  data() {
    return {
      samplePage: {
        is: 'EntityView',
        title: 'Entity View',
      },
    }
  },
  computed: {},
})
</script>
