import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const PivotTableWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'PivotTableWidget',
  'Pivot Table',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_PLUS,
    dimension: DataSetWidgetSchemaConfigValue.ONE_PLUS,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)
PivotTableWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/PivotTableOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadAll',
  },
})
PivotTableWidgetBuilder.addDefinitions({
  PivotTableOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      headerBackgroundColor: {
        title: 'Header Background Color',
        $ref: '#/definitions/Color',
      },
      headerTextColor: {
        title: 'Header Text Color',
        $ref: '#/definitions/Color',
      },
      bodyBackgroundColor: {
        title: 'Body Background Color',
        $ref: '#/definitions/Color',
      },
      bodyTextColor: {
        title: 'Body Text Color',
        $ref: '#/definitions/Color',
      },
    },
  },
})

export default PivotTableWidgetBuilder.schema
