import ColorConditionParser from '../../widgets/SingleCounter/ColorConditionParser'
import DataSetWidgetHook from '../../base/DataSetWidgetHook'
import { ConditionalColor, SingleCounterWidgetModel } from '../../schemas-types'
import { currencyFormat } from '../../syncfusion/helpers'

export default class SingleCounterWidgetHook extends DataSetWidgetHook<
  SingleCounterWidgetModel
> {
  SERIES_INDEX = 0
  MEASURE_INDEX = 0

  get conditions(): ConditionalColor[] | null {
    return this.widget.options?.conditions ?? null
  }

  get conditionalColor(): null | ConditionalColor {
    const conditions = this.widget.options?.conditions ?? null
    if (!conditions) {
      return null
    }
    const measure = this.getMeasureByIndex(
      this.SERIES_INDEX,
      this.MEASURE_INDEX,
    )
    if (!measure.key) {
      return null
    }

    const value = this.series[this.SERIES_INDEX].getValue(
      this.MEASURE_INDEX,
      measure.key,
    )

    if (value === null) {
      return null
    }

    const conditionParser = new ColorConditionParser()
    return conditionParser.getSuccessCondition(conditions, value)
  }

  get color(): null | string {
    const conditionColor = this.conditionalColor
    if (conditionColor) {
      return conditionColor.color
    }
    return this.widget.options?.color ?? null
  }

  get counterValue() {
    if (!this.isDataFetched) {
      return '...'
    }

    const measure = this.getMeasureByIndex(
      this.SERIES_INDEX,
      this.MEASURE_INDEX,
    )
    if (!measure.key) {
      return '...'
    }

    const value = this.series[this.SERIES_INDEX].getValue(
      this.MEASURE_INDEX,
      measure.key,
    )

    let prefix = ''
    if (this.getDefaultMeasureFormat(measure) === currencyFormat) {
      prefix = this.services.authModule.getUserPreferences().currency
    }

    const formattedValue = this.formatMeasureValue(
      value,
      this.state.widget.dataSet.measure.format,
    )

    return `${prefix}${formattedValue}`
  }

  get flashing(): boolean {
    return this.conditionalColor?.flashing ?? false
  }
}
