// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftSummariesTile from './ShiftSummariesTile.vue'
import ShiftSummariesColumns from './ShiftSummariesColumns'
import ShiftSummariesDetail from './ShiftSummariesDetail.vue'
import ShiftSummariesReference from './ShiftSummariesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftSummariesTile', ShiftSummariesTile)
    Vue.component('ShiftSummariesDetail', ShiftSummariesDetail)
    Vue.component('ShiftSummariesReference', ShiftSummariesReference)

    modularManager.addResourcePreset(
      Resources.SHIFT_SUMMARIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftSummariesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SUMMARIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftSummariesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SUMMARIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftSummariesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_SUMMARIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftSummariesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
