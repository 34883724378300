// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PayrollSchedulesTile from './PayrollSchedulesTile.vue'
import PayrollSchedulesColumns from './PayrollSchedulesColumns'
import PayrollSchedulesDetail from './PayrollSchedulesDetail.vue'
import PayrollSchedulesReference from './PayrollSchedulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PayrollSchedulesTile', PayrollSchedulesTile)
    Vue.component('PayrollSchedulesDetail', PayrollSchedulesDetail)
    Vue.component('PayrollSchedulesReference', PayrollSchedulesReference)

    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PayrollSchedulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PayrollSchedulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollSchedulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_SCHEDULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PayrollSchedulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
