<template>
  <v-card flat>
    <WidgetFactory
      class="h-100 mobile-schedules-time-line"
      :widget="widget"
      skip-validation
    />
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ListWidgetModel } from '@/tt-widget-components/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { Resource, Attribute } from '@/tt-entity-design/src/schema-types'

import MobileSchedulesTimelineTile from './MobileSchedulesTimelineTile.vue'

const MOBILE_SCHEDULES: Resource = 'mobile-schedules'
type Attr = Attribute<typeof MOBILE_SCHEDULES>

const sorderAttr: Attr = 'mobileRunsheetSortOrder'
const filteredAttr: Attr = 'mobileRunsheet'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileSchedulesTimeline',
  props: {
    mobileRunsheetId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    widget(): ListWidgetModel {
      return {
        is: WidgetName.LIST_WIDGET,
        title: '',
        listItem: {
          // @ts-ignore
          is: MobileSchedulesTimelineTile,
        },
        query: {
          resource: MOBILE_SCHEDULES,
          filters: [
            {
              attribute: filteredAttr,
              operator: FilterOperatorType.EQUAL,
              value: this.mobileRunsheetId,
            },
          ],
          sort: [
            {
              attribute: sorderAttr,
              direction: SortDirectionType.ASC,
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
})
</script>

<style scoped>
/* removes border added by List Widget */
.mobile-schedules-time-line >>> .list-item-div {
  border: 0 !important;
}

/* removes the separator */
.mobile-schedules-time-line >>> .list-item-div > hr {
  display: none !important;
}

/* hide widget title at the top */
.mobile-schedules-time-line >>> .widget-title {
  display: none !important;
}

.mobile-schedules-time-line {
  padding-top: 1em;
}
</style>
