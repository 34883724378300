<template>
  <TTimelineItem :index="index">
    <tt-tile>
      <tt-attr slot="title" name="taskType.name" />

      <div slot="line2" class="d-flex">
        <tt-attr name="rangeStartTime" />
        <span v-text="' - '" />
        <tt-attr name="rangeEndTime" />
      </div>

      <tt-attr
        slot="line3"
        prepend-icon="mdi-domain"
        prepend-icon-color="ttPrimary"
        name="zonePosition.name"
      />
    </tt-tile>
  </TTimelineItem>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'MobileSchedulesTimelineTile',
  props: {
    index: {
      type: Number,
      default: null,
    },
  },
})
</script>
