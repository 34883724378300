import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'name',
  'description',
  'status',
  'address',
  'region',
] as ColumnInputDefinition[]
