<template>
  <v-skeleton-loader v-if="loading" type="list-item" />
  <div v-else-if="workflowInstanceTimer">
    <v-row no-gutters class="ma-1">
      <v-col class="attribute-row-label" cols="4">
        {{ timerName }}
      </v-col>
      <v-col class="ml-1 attribute-row-value">
        <v-chip small link text-color="white" :color="chipColor">
          <span>
            {{ timerText }}
          </span>
        </v-chip>
      </v-col>
    </v-row>
    <v-divider style="opacity: 0.5" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Resources } from '../../types'
import {
  EntityCollectionRequestOptions,
  EntityCollectionResponse,
} from 'tracktik-sdk/lib/common/entity-collection'
import { formatedElapsedSeconds } from '@/helpers/formats/dates/utils'
import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'
import { VueWithItemHookInjection } from '@/helpers/components/types'

type WorkflowInstanceTimerType = {
  workflowInstance: number
  status: string
  slaInMinutes: number
  slaStatus: string
  elapsedSeconds: number
  id: number
  timer: {
    customId: string
    name: string
    description: string
    slaInMinutes: number
    id: number
  }
}

enum ChipColorEnum {
  ERROR = 'error',
  SUCCESS = 'success',
}

export default (Vue as VueWithItemHookInjection).extend({
  name: 'WorkflowInstanceTimer',
  inject: ['getItemHook'],
  data() {
    return {
      loading: false,
      workflowInstanceTimer: null as WorkflowInstanceTimerType,
    }
  },
  computed: {
    timerName(): string {
      return this.workflowInstanceTimer?.timer?.name || ''
    },
    workflowInstanceId(): number {
      return this.getItemHook().getRawValue('workflowInstance.id')
    },
    timer(): string {
      return this.workflowInstanceTimer
        ? formatedElapsedSeconds(this.workflowInstanceTimer?.elapsedSeconds)
        : 'N/A'
    },
    slaInMinutes(): number {
      return this.workflowInstanceTimer?.timer?.slaInMinutes || 0
    },
    timerText(): string {
      return `${this.timer} (${this.$t('common.sla')} ${
        this.slaInMinutes
      } ${this.$tc('common.minutes', this.slaInMinutes)})`
    },
    chipColor(): string {
      const sla = this.workflowInstanceTimer?.timer?.slaInMinutes || null
      const elapsedSeconds = this.workflowInstanceTimer?.elapsedSeconds || 0

      return sla * 60 < elapsedSeconds
        ? ChipColorEnum.ERROR
        : ChipColorEnum.SUCCESS
    },
  },
  watch: {
    workflowInstanceId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.getWorkflowInstanceTimers(id)
        } else {
          this.workflowInstanceTimer = null
        }
      },
    },
  },
  created() {
    this.getItemHook().addAttribute(`workflowInstance.id`)
  },
  methods: {
    handleError(error): void {
      this.$crash.captureException(error)

      const messages = parseErrorMessages({
        error,
        resourceName: Resources.WORKFLOW_INSTANCE_TIMERS,
      })
      displayErrorMessages(messages, this.$appContext.eventManager)
    },
    getWorkflowInstanceTimers(workflowInstanceId: number) {
      const options: EntityCollectionRequestOptions = {
        filters: [
          {
            attribute: 'workflowInstance',
            operator: 'IN',
            value: workflowInstanceId,
          },
        ],
        include: ['timer'],
      }

      this.loading = true
      this.$appContext.authModule
        .getApi()
        .getAll(Resources.WORKFLOW_INSTANCE_TIMERS, options)
        .then(
          ({ items }: EntityCollectionResponse<WorkflowInstanceTimerType>) => {
            this.workflowInstanceTimer = items.length === 0 ? null : items[0]
          },
        )
        .catch(this.handleError)
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>

<style scoped>
.attribute-row-label {
  color: #888;
  font-size: 0.8em;
}

.attribute-row-value {
  font-size: 0.8em;
}
</style>
