<template>
  <div @click.stop="dispatchEditAssignedEmployeesForm">
    <span style="cursor: pointer">
      {{ employeesTranslation }}
    </span>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActiveWindowProvider } from '@/tt-app-layout/types'
export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'AssignedEmployeesClickableColumn',
  inject: ['getItemHook', 'activeWindow'],
  computed: {
    employeesLocale(): string {
      return 'tt-entity-design.leave-management.leave-policies.tabs.assigned-to.employees'
    },
    employeesCount(): number {
      return this.getItemHook().get('employees')
    },
    hasLeavePolicyItems(): boolean {
      return !!this.getItemHook().get('leavePolicyItems')
    },
    employeesTranslation(): string {
      return this.$tc(this.employeesLocale, this.employeesCount)
    },
  },
  created() {
    this.getItemHook().addAttribute('employees')
    this.getItemHook().addAttribute('leavePolicyItems')
  },
  methods: {
    dispatchEditAssignedEmployeesForm() {
      if (this.hasLeavePolicyItems) {
        this.activeWindow.next({
          title: 'tt-entity-design.leave-management.label',
          is: 'LeavePoliciesForm',
          props: { editStep: 2, policyIdToEdit: this.getItemHook().get('id') },
        })
      }
    },
  },
})
</script>
