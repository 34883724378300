import i18n from '@/plugins/i18n'
import {
  SchedulerEventHandler,
  wrapSchedulerEventTemplate,
} from '@/tt-widget-components/widgets/Scheduler/scheduler-event-handler'

import {
  getSiteTaskOccurrenceStatusColor,
  siteTaskOccurrenceStatus,
} from './site-task-occurrences-status'
import { Resources } from '../../types'

const requiredFields: SchedulerEventHandler['requiredFields'] = ({
  resourceMetaManager,
}) => {
  const isCalendarExceptionsEnabled = !!resourceMetaManager.getAttribute(
    Resources.SITE_TASK_OCCURRENCES,
    'exception',
  )

  return [
    'assets',
    'checkpointTour.name',
    'description',
    'occurrenceDate',
    'position.name',
    'rangeEndTime',
    'rangeStartTime',
    'session.effectiveDuration',
    'session.employee.name',
    'status',
    'taskType.name',
    ...(isCalendarExceptionsEnabled ? ['exception'] : []),
  ]
}

const transform: SchedulerEventHandler['transform'] = (itemHook, entities) => {
  const statusRaw = itemHook.getRawValue('status')
  const hasException =
    itemHook.hasAttribute('exception') && !!itemHook.get('exception')

  const getTime = (): { style: string; text: string } => {
    const startTime = itemHook.get('rangeStartTime')
    const text =
      statusRaw === siteTaskOccurrenceStatus.COMPLETED
        ? `${startTime} (${itemHook.get('session.effectiveDuration')})`
        : [startTime, itemHook.get('rangeEndTime')].filter(Boolean).join(' - ')
    const style = hasException
      ? 'color: var(--v-ttPrimary-base); font-weight: bold'
      : ''

    return { style, text }
  }

  const getStatus = (): { color: string; label: string; key: string } => ({
    color: getSiteTaskOccurrenceStatusColor(statusRaw) ?? 'N/A',
    label:
      itemHook.get('status') ??
      i18n.t(`tt-entity-design.site-task-occurrences.new`),
    key: statusRaw,
  })

  const showAssets = entities?.some((entity) => entity.assets !== 0)

  return {
    assets: {
      display: showAssets ? 'block' : 'none',
      text: i18n.tc('tt-site-tasks.asset-count', itemHook.get('assets')),
    },
    id: itemHook.getEntityId(),
    occurrenceDate: itemHook.getRawValue('occurrenceDate'),
    taskType: itemHook.get('taskType.name') ?? 'N/A',
    description: itemHook.get('description') ?? 'N/A',
    position: itemHook.get('position.name') ?? 'N/A',
    employee:
      itemHook.get('session.employee.name') ??
      i18n.t('tt-site-tasks.not-assigned'),
    time: getTime() ?? 'N/A',
    checkpointTour: itemHook.get('checkpointTour.name') ?? 'N/A',
    status: getStatus(),
    exception: {
      display: hasException ? 'block' : 'none',
    },
  }
}

const template: SchedulerEventHandler['template'] =
  wrapSchedulerEventTemplate(/* html */ `
<div style="color: #57626e; font-size: 12px; padding: 8px 15px 15px">
  <div style="color: #343a40">
    <strong>\${taskType}</strong>
  </div>
  <div style="color: #343a40" title="\${description}">
    \${description}
  </div>
  <div>
    <strong>\${position}</strong>
  </div>
  <div>\${employee}</div>
  <div style="\${time.style}">
    \${time.text}
  </div>
  <div>\${checkpointTour}</div>
  <div class="d-flex flex-nowrap">
    <div class="flex-grow-1">
      <strong class="status--\${status.key} d-inline-block mr-1 text-uppercase" style="background-color: \${status.color}; border-radius: 2px; color: #ffffff; font-size: 0.815em; line-height: 1.5em; padding: 0 5px">
        \${status.label}
      </strong>
    </div>

    <div class="flex-grow-0" style="color: var(--v-ttPrimary-base); display: \${exception.display}">
      <i class="v-icon mdi mdi-progress-clock" style="font-size: 15px"></i>
    </div>
  </div>
  <div style="display: \${assets.display}; height:1em">
    \${assets.text}
  </div>
</div>
`)

export const siteTaskOccurrencesSchedulerEventHandler: SchedulerEventHandler = {
  requiredFields,
  template,
  transform,
}
