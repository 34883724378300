import {
  WidgetCategoryModel,
  WidgetStoreInterface,
} from '@/tt-widget-factory/types'
import { MY_INSIGHTS_CATEGORY_UID } from './types'
import { Routes } from './enumRoutes'

export type BreadCrumbItem = {
  label: string
  routeParams: string[]
}

/**
 * Get a route
 * @param parts
 */
export function getInsightRoute(parts: string[]) {
  return `/insight-studio/${parts.join('/')}`
}

export function cloneData(object) {
  return JSON.parse(JSON.stringify(object))
}

export function addBreadCrumbParent(
  category: WidgetCategoryModel,
  output: BreadCrumbItem[],
  routesSharedWithMe: boolean,
) {
  let newOutput = [...output]

  const paramId = routesSharedWithMe ? `${category.meta.id}` : category.uid
  const paramPath = routesSharedWithMe ? 'shared-with-me' : 'explore'

  const crumbItem: BreadCrumbItem = {
    label: category.name,
    routeParams: [paramPath, paramId],
  }

  newOutput.unshift(crumbItem)

  if (category.parentCategory?.trim()) {
    const widgetCategoryModel = WidgetCategoryModel.find(
      category.parentCategory,
    )

    if (!widgetCategoryModel) {
      return newOutput
    }

    const parentCategory = cloneData(widgetCategoryModel)

    if (
      parentCategory.parentCategory === MY_INSIGHTS_CATEGORY_UID &&
      routesSharedWithMe
    ) {
      parentCategory.parentCategory = null
    }

    newOutput = addBreadCrumbParent(
      parentCategory,
      newOutput,
      routesSharedWithMe,
    )
  }

  return newOutput
}

export function getBreadCrumbs(
  categoryId: string,
  isSharedWithMe: boolean,
): BreadCrumbItem[] {
  const widgetCategoryModel = WidgetCategoryModel.find(categoryId)

  const rootCrumbSharedWithMe: BreadCrumbItem = {
    label: 'navigation.shared-with-me',
    routeParams: [Routes.SHARED_WITH_ME],
  }

  let output = []

  if (!widgetCategoryModel) {
    return isSharedWithMe ? [rootCrumbSharedWithMe] : output
  }

  const category = cloneData(widgetCategoryModel)

  if (category.parentCategory === MY_INSIGHTS_CATEGORY_UID && isSharedWithMe) {
    category.parentCategory = null
  }

  output = addBreadCrumbParent(category, output, isSharedWithMe)

  if (isSharedWithMe) output = [rootCrumbSharedWithMe, ...output]

  return output
}

export const getWidgetViewRoute = (item: WidgetStoreInterface): Routes => {
  if (item.isFromMarketplace) return Routes.PREDEFINED_VIEW

  // else is a `data-view`
  return item.ownedByMe ? Routes.WIDGET_VIEW : Routes.SHARED_WITH_ME_VIEW
}
