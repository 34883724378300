<template>
  <tt-tile>
    <template #avatar>
      <v-icon v-if="isActive" v-text="`mdi-bell`" />
      <v-icon v-else v-text="`mdi-bell-off`" />
    </template>

    <tt-attr slot="title" name="ruleName" />
    <tt-attr slot="line2" name="trigger" />

    <template #line3>
      <div v-show="isActive">
        <tt-if-attr name="email" :value="true">
          <v-chip color="accent" small><v-icon v-text="`mdi-email`" /></v-chip>
        </tt-if-attr>
        &nbsp;
        <tt-if-attr name="push" :value="true">
          <v-chip color="accent" small>
            <v-icon v-text="`mdi-cellphone`" />
          </v-chip>
        </tt-if-attr>
        &nbsp;
        <tt-if-attr name="sms" :value="true">
          <v-chip color="accent" small>
            <v-icon v-text="`mdi-message-text`" />
          </v-chip>
        </tt-if-attr>
      </div>

      <v-chip v-show="!isActive" small v-text="$t(`inactive`)" />
    </template>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'FilterRuleSetAssetsTile',
  inject: ['getItemHook'],
  computed: {
    isActive(): boolean {
      const METHOD_ATTRIBUTES = ['sms', 'email', 'push']

      return METHOD_ATTRIBUTES.some((attr) =>
        this.getItemHook().getRawValue(attr),
      )
    },
  },
})
</script>
