<template>
  <div class="report-template-fields-sorter">
    <template v-if="loading">
      <div v-for="index in 5" :key="index">
        <v-divider v-show="index > 1" />
        <div class="d-flex">
          <v-skeleton-loader
            class="flex-grow-1 mt-2"
            height="40"
            tile
            type="card-heading"
          />
          <v-skeleton-loader type="actions" />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(field, index) in fields"
        :key="field.id"
        class="report-template-field"
      >
        <v-divider v-show="index > 0" />

        <div class="align-center d-flex pa-2">
          <span v-text="field.label" />

          <v-spacer />

          <v-btn :disabled="index === 0" icon @click="moveFieldUp(index)">
            <v-icon v-text="'mdi-chevron-up'" />
          </v-btn>

          <v-btn
            :disabled="isLastField(field)"
            icon
            @click="moveFieldDown(index)"
          >
            <v-icon v-text="'mdi-chevron-down'" />
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import isEqual from 'lodash/isEqual'
import Vue, { PropType } from 'vue'

import { CollectionQuery } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { getReportTemplateFieldResource } from '@/tt-widget-views/report-templates/helpers'

import { ReportFieldParentType } from '@/tt-widget-views/report-templates/types'

import { Resources } from '../../types'

type ReportTemplateField = {
  id: number
  label: string
}

export default Vue.extend({
  name: 'ReportTemplateFieldsSorter',
  props: {
    /**
     * Entity ID
     */
    entityId: { type: Number, required: true },
    /** The entity type of the parent */
    parentType: {
      type: String as PropType<ReportFieldParentType>,
      required: true,
    },
    /**
     * List of field IDs in the order they should be sorted
     */
    value: { type: Array as PropType<number[]>, default: () => [] },
  },
  data() {
    return {
      fields: [] as ReportTemplateField[],
      loading: false,
    }
  },
  computed: {
    fieldIds(): number[] {
      return this.fields.map((field) => field.id)
    },
    filterAttribute(): string {
      return this.parentType === Resources.REPORT_TEMPLATES
        ? 'reportTemplate'
        : 'reportFlagTemplate'
    },
    isValueValid(): boolean {
      return isEqual([...this.fieldIds].sort(), [...this.value].sort())
    },
    resource(): string {
      return getReportTemplateFieldResource(this.parentType)
    },
  },
  watch: {
    entityId: {
      immediate: true,
      handler() {
        this.fetchFields()
      },
    },
    value: {
      immediate: true,
      handler() {
        this.sortByValue()
      },
    },
  },
  methods: {
    emitCurrentValue(): void {
      this.emitInput(this.fieldIds)
    },
    emitInput(value: number[]): void {
      /**
       * @event input
       * @type {number[]}
       */
      this.$emit('input', value)
    },
    async fetchFields(): Promise<void> {
      this.loading = true
      this.fields = []

      const query: CollectionQuery = {
        resource: this.resource,
        fields: [{ attribute: 'id' }, { attribute: 'label' }],
        filters: [
          {
            attribute: this.filterAttribute,
            operator: FilterOperatorType.EQUAL,
            value: this.entityId,
          },
        ],
        sort: { attribute: 'displayOrder', direction: 'DESC' },
      }

      await this.$appContext.widgetServices.resourceDataManager
        .getCollection(query)
        .then(({ items }) => (this.fields = items))
        .catch((err) => this.$crash.captureException(err))
        .finally(() => (this.loading = false))

      if (this.isValueValid) {
        this.sortByValue()
      } else {
        this.emitCurrentValue()
      }
    },
    isLastField(field: ReportTemplateField): boolean {
      const index = this.fields.findIndex((f) => f.id === field.id)

      return index === this.fields.length - 1
    },
    moveFieldDown(fromIndex: number): void {
      this.moveFieldFromTo(fromIndex, fromIndex + 1)
      this.emitCurrentValue()
    },
    moveFieldFromTo(fromIndex: number, toIndex: number): void {
      const isValidIndex = (index: number): boolean =>
        index >= 0 && index < this.fields.length

      if (
        isValidIndex(fromIndex) &&
        isValidIndex(toIndex) &&
        fromIndex !== toIndex
      ) {
        const fields = [...this.fields]
        fields.splice(toIndex, 0, fields.splice(fromIndex, 1)[0])
        this.fields = fields
      }
    },
    moveFieldUp(fromIndex: number): void {
      this.moveFieldFromTo(fromIndex, fromIndex - 1)
      this.emitCurrentValue()
    },
    sortByValue(): void {
      if (this.isValueValid) {
        this.fields.forEach((field, fromIndex) => {
          const toIndex = this.value.findIndex((id) => id === field.id)
          this.moveFieldFromTo(fromIndex, toIndex)
        })
      }
    },
  },
})
</script>
