<template>
  <span>
    <tt-attr :prepend-icon="icon" :prepend-icon-color="iconColor" name="name" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue'

import { REPORT_LIBRARY_ICON, REPORT_LIBRARY_ICON_COLOR } from './constants'

export default Vue.extend({
  name: 'ReportLibraryFieldsReference',
  computed: {
    icon(): string {
      return REPORT_LIBRARY_ICON
    },
    iconColor(): string {
      return REPORT_LIBRARY_ICON_COLOR
    },
  },
})
</script>
