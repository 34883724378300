<template>
  <div>
    <json-field name="conditionSet" />
    <json-field name="triggerField" block-creation />
    <json-field name="operator" />
    <ReportConditionsValueField />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import ReportConditionsValueField from './ReportConditionsValueField.vue'

export default Vue.extend({
  name: 'ReportConditionsForm',
  components: { ReportConditionsValueField },
})
</script>
