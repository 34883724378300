<template>
  <div class="d-flex align-center justify-space-between pa-3">
    <!-- file info -->
    <div class="text-truncate">
      <!-- file title -->
      <tt-tile class="file-title-container text-body-1 font-weight-medium">
        <tt-attr slot="title" name="name" class="m-0 p-0" />
        <tt-attr slot="title" name="url" hidden />
        <tt-attr slot="title" name="format" hidden />
        <tt-attr slot="title" name="tag" hidden />
      </tt-tile>
      <!-- / file title -->

      <div class="d-flex caption mt-1">
        <!-- created by -->
        <div class="mr-2">
          <tt-attr slot="title" name="createdBy" />
        </div>
        <!-- / created by -->

        <!-- created on -->
        <div>
          <v-icon small class="mr-1">mdi-calendar</v-icon>
          <tt-attr slot="title" name="createdOn" />
        </div>
        <!-- / created on -->
      </div>
    </div>
    <!-- / file info -->

    <!-- file actions -->
    <ResourceAllowedOperations :resource-name="resourceName" class="ml-auto">
      <ActionTriggerProvider>
        <FileInlineBtnGroupActions :actions="availableActions()" />
      </ActionTriggerProvider>
    </ResourceAllowedOperations>
    <!-- / file actions -->
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { AllowedFileActions } from './types'
import { Resources } from '../../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'FilesTile',
  inject: ['getItemHook'],
  computed: {
    resourceName(): string {
      return this.getItemHook().getResourceName() || Resources.FILES
    },
    entityAvailableActions(): string[] {
      return this.getItemHook().getAvailableActions()
    },
  },
  mounted() {
    this.getItemHook().needActions()
  },
  methods: {
    availableActions(): string[] {
      /**
       * The download and view actions doesn't depend on permissions,
       * so they're always available, and also we spread any other available
       * actions for this entity.
       */
      return [
        AllowedFileActions.DOWNLOAD,
        AllowedFileActions.VIEW,
        ...this.entityAvailableActions,
      ]
    },
  },
})
</script>

<style scoped>
.file-title-container {
  padding: 0 !important;
  font-size: 1rem;
}
.file-title-container >>> .v-list-item {
  padding: 0 !important;
  margin: 0 !important;
  min-height: unset;
}
.v-btn::before {
  display: none;
}
</style>
