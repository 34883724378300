<template>
  <div class="d-flex justify-end pa-1">
    <v-btn
      :class="{ 'edit--button': hasAlarmConnectionCustomId }"
      :disabled="!hasAlarmConnectionCustomId"
      depressed
      @click="$emit('click')"
    >
      <v-icon small v-text="'mdi-pencil'" />
      <span class="pl-1" v-text="buttonLabel" />
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AlarmsFormEditButton',
  props: {
    hasLocationCoordinate: {
      type: Boolean,
      required: true,
    },
    hasAlarmConnectionCustomId: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonLabel(): string {
      return this.hasLocationCoordinate
        ? this.$t('tt-entity-design.dispatch-tasks.form-button.change-location')
        : this.$t('tt-entity-design.dispatch-tasks.form-button.set-location')
    },
  },
})
</script>

<style scoped>
.edit--button {
  color: var(--v-ttPrimary-base);
  background-color: var(--v-ttPrimary-lighten5) !important;
  text-transform: none !important;
}
.v-btn--disabled {
  text-transform: none !important;
}
</style>
