// Enter your custom installation here

import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const employeeClassListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.EMPLOYEES,
      },
    }

    const employeeClass: PreviewTabInterface = {
      title: 'tt-entity-design.employee-classes.assigned-employees',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'employmentProfile.employeeClass',
        widget: employeeClassListWidget,
      },
    }

    addPreviewTab(Resources.EMPLOYEE_CLASSES, employeeClass)
  },
}
