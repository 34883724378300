import { Coordinates } from '@tracktik/tt-geo-proxy'

export const isValidCoordinates = (coordinates: Coordinates): boolean => {
  // Check if coordinates is null or undefined
  if (!coordinates) return false

  const values = Object.values(coordinates)
  // Check if values are null/undefined
  if (values.some((value) => value === null || value === undefined)) {
    return false
  }

  // Check if values are numbers
  if (values.some((value) => typeof value !== 'number')) {
    return false
  }

  // Check if coordinate are within valid ranges
  if (
    coordinates.latitude < -90 ||
    coordinates.latitude > 90 ||
    coordinates.longitude < -180 ||
    coordinates.longitude > 180
  ) {
    console.warn('Coordinates are out of range', coordinates)
    return false
  }

  // If all checks pass, the coordinates are valid
  return true
}
