<template>
  <div>
    <v-chip
      v-for="(item, index) in items"
      v-bind="$attrs"
      :key="`items-${index}`"
      class="mr-2 mt-2"
      :class="item.value == model ? 'selected' : 'not-selected darken-3'"
      @click="setItem(item.value)"
      v-text="$t(item.text)"
    />
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ChipListInput',
  inheritAttrs: false,
  props: {
    items: Array,
    value: String,
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      model: null,
    }
  },
  watch: {
    value(value) {
      if (value !== this.model) {
        this.model = value
      }
    },
  },
  created() {
    if (this.value) {
      this.model = this.value
    }
  },
  methods: {
    setItem(val) {
      this.model = val
      this.$emit('input', val)
    },
  },
})
</script>
<style scoped>
.not-selected {
  opacity: 0.5;
}
</style>
