<template>
  <ProvideItemHook
    v-show="false"
    :provide="resourceItemHook"
    :relation-prefix="relationPrefix"
  >
    <slot :item-hook="resourceItemHook" />
  </ProvideItemHook>
</template>
<script lang="ts">
import Vue from 'vue'
import {
  EntityItemHook,
  EntityItemViewInterface,
} from '@/tt-widget-entity-flow/EntityItemHook'

export default Vue.extend({
  name: 'EntityViewAttributeCollector',
  inject: { relationPrefix: { default: '' } },
  props: {
    resourceName: {
      type: String,
      required: true,
    },
  },
  computed: {
    resourceItemHook(): EntityItemHook {
      const emptyEntity: EntityItemViewInterface = {
        resourceName: this.resourceName,
        entityId: null,
        entity: {},
        availableActions: [],
      }

      const itemHook = new EntityItemHook(this.$appContext, emptyEntity, {
        fetchEnabled: false,
        events: {
          onActionsNeeded: () => this.$emit('action'),
          onNewRequiredAttributes: (requiredAttributes) => {
            if (requiredAttributes.length) {
              this.$emit('input', requiredAttributes)
            }
          },
        },
      })

      // @TODO: should not be needed
      return Vue.observable(itemHook)
    },
  },
})
</script>
