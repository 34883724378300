<template>
  <tt-attr-row-card>
    <tt-attr-row name="skill" />
    <tt-attr-row name="skill.category" />
    <tt-if-attr name="skillLabel1Value" not :value="[null, undefined, '']">
      <tt-attr-row>
        <tt-attr slot="title" name="skillLabel1" />
        <tt-attr name="skillLabel1Value" />
      </tt-attr-row>
    </tt-if-attr>
    <tt-if-attr name="skillLabel2Value" not :value="[null, undefined, '']">
      <tt-attr-row>
        <tt-attr slot="title" name="skillLabel2" />
        <tt-attr name="skillLabel2Value" />
      </tt-attr-row>
    </tt-if-attr>
    <tt-if-attr name="skillDateLabelValue" not :value="[null, undefined, '']">
      <tt-attr-row>
        <tt-attr slot="title" name="skillDateLabel" />
        <tt-attr
          v-if="isPaycorCertificationIdPresent"
          name="skillDateLabelValue"
          format="asUTCDate"
        />
        <tt-attr v-else name="skillDateLabelValue" />
      </tt-attr-row>
    </tt-if-attr>
    <tt-attr-row name="status">
      <StatusChip
        :status-color-options="employeeSkillsStatusColors"
        :resource="resource"
      />
    </tt-attr-row>
    <tt-if-attr name="comments" not :value="[null, undefined, '']">
      <tt-attr-row name="comments" />
    </tt-if-attr>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { Resources } from '../../types'

import { EmployeeSkillsStatusColors } from './types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EmployeeSkillsDetail',
  inject: ['getItemHook'],
  computed: {
    resource(): Resources {
      return Resources.EMPLOYEE_SKILLS
    },
    employeeSkillsStatusColors(): typeof EmployeeSkillsStatusColors {
      return EmployeeSkillsStatusColors
    },
    isPaycorCertificationIdPresent(): boolean {
      return !!this.getItemHook().get(
        'skill.externalIdObject.paycorCertificationId',
      )
    },
  },
  created() {
    this.getItemHook().addAttribute(
      'skill.externalIdObject.paycorCertificationId',
    )
  },
})
</script>
