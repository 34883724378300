// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PostOrderAcknowledgementsTile from './PostOrderAcknowledgementsTile.vue'
import PostOrderAcknowledgementsColumns from './PostOrderAcknowledgementsColumns'
import PostOrderAcknowledgementsDetail from './PostOrderAcknowledgementsDetail.vue'
import PostOrderAcknowledgementsReference from './PostOrderAcknowledgementsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PostOrderAcknowledgementsTile',
      PostOrderAcknowledgementsTile,
    )
    Vue.component(
      'PostOrderAcknowledgementsDetail',
      PostOrderAcknowledgementsDetail,
    )
    Vue.component(
      'PostOrderAcknowledgementsReference',
      PostOrderAcknowledgementsReference,
    )

    modularManager.addResourcePreset(
      Resources.POST_ORDER_ACKNOWLEDGEMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PostOrderAcknowledgementsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_ACKNOWLEDGEMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PostOrderAcknowledgementsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_ACKNOWLEDGEMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PostOrderAcknowledgementsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POST_ORDER_ACKNOWLEDGEMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PostOrderAcknowledgementsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
