<template>
  <div class="steps-wizard fill-height d-flex flex-column">
    <v-toolbar flat :height="56" :style="{ flexGrow: 0 }">
      <slot name="toolbar-before" />

      <v-tabs
        align-with-title
        centered
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        slider-color="ttPrimary"
        :value="selectedTabIndex"
        @change="selectTabByIndex"
      >
        <template v-for="(tab, index) in tabs">
          <v-icon
            v-if="index > 0"
            :key="`arrow-${tab.key}`"
            size="18"
            v-text="'keyboard_arrow_right'"
          />

          <v-tooltip :key="tab.key" bottom :disabled="!resolve(tab.tooltip)">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-tab
                  class="mx-2 px-2 py-5"
                  :disabled="resolve(tab.disabled, false)"
                  @click="onClickTab(tab.key)"
                >
                  <v-icon
                    v-if="resolve(tab.icon)"
                    :color="resolve(tab.iconColor)"
                    left
                    size="16"
                    v-text="resolve(tab.icon)"
                  />
                  <span
                    :style="{
                      fontWeight: isTabActive(tab.key) ? 'bold' : null,
                      textTransform: 'none',
                    }"
                    v-text="$t(tab.label)"
                  />
                </v-tab>
              </div>
            </template>

            <template #default>
              <span v-text="$t(resolve(tab.tooltip))" />
            </template>
          </v-tooltip>
        </template>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items
      class="fill-height flex-grow-1 pt-4"
      :value="selectedTabIndex"
    >
      <v-tab-item v-for="tab in tabs" :key="tab.key" class="fill-height">
        <slot
          :name="`tab-${tab.key}`"
          :is-active="tab.key === value"
          :select-tab="selectTab"
          :selected-tab="value"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { resolve } from '../helpers'
import { StepsWizardTab } from '../types'

export default Vue.extend({
  name: 'StepsWizard',
  props: {
    /**
     * Tabs list options
     */
    tabs: {
      type: Array as PropType<StepsWizardTab[]>,
      required: true,
    },
    /**
     * Key of the tab the is selected on initialization
     */
    initialValue: { type: String, default: undefined },
  },
  data() {
    return {
      value: this.initialValue,
    }
  },
  computed: {
    selectedTabIndex(): number {
      return this.value && this.getTabIndex(this.value)
    },
  },
  methods: {
    getTab(key: string): StepsWizardTab {
      return this.tabs.find((tab) => tab.key === key)
    },
    getTabIndex(key: string): number {
      return this.tabs.findIndex((tab) => tab.key === key)
    },
    isTabActive(key: string): boolean {
      return key === this.value
    },
    onClickTab(key: string): void {
      const tab = this.getTab(key)
      if (tab?.onClick instanceof Function) {
        tab.onClick()
      }
    },
    resolve,
    selectTab(key: string): void {
      this.value = key

      /**
       * Change selected tab event
       *
       * @event change
       * @type {string}
       */
      this.$emit('change', this.value)
    },
    selectTabByIndex(index: number): void {
      const tab = this.tabs[index]
      this.selectTab(tab.key)
    },
  },
})
</script>
