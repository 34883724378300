<template>
  <RegionCollection
    :show-selected="showSelected"
    :value="value"
    :search="search"
    :regions="userRegions"
  >
    <template #default="{ itemHook }">
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            hide-details
            :input-value="isSelected(itemHook)"
            @click="emitInput(itemHook)"
          />
        </v-list-item-action>
        <v-list-item-content @click="emitInput(itemHook)">
          <tt-preset-view
            type="relationReference"
            class="d-inline-block text-truncate"
          />
        </v-list-item-content>
      </v-list-item>
    </template>
  </RegionCollection>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityItemHook } from '../../../tt-widget-entity-flow/EntityItemHook'
import { UserRegion } from '../treeview/utils'
import RegionCollection from './RegionCollection.vue'

const castToString = (itemHook: EntityItemHook): string =>
  String(itemHook.entityReference.entityId || '')

export default Vue.extend({
  name: 'RegionListFilter',
  components: { RegionCollection },
  props: {
    search: { type: String, default: '' },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showSelected: { type: Boolean, default: false },
    userRegions: {
      type: Array as PropType<UserRegion[]>,
      default: () => [],
    },
  },
  methods: {
    isSelected(itemHook: EntityItemHook) {
      const value = this.value ?? []
      return value.includes(castToString(itemHook))
    },
    emitInput(itemHook: EntityItemHook) {
      this.$emit('input', castToString(itemHook))
    },
  },
})
</script>
