<template>
  <MetadataLoadingGuard>
    <div id="hcm-requests-view" class="d-flex flex-column fill-height">
      <div id="hcm-request-view">
        <v-toolbar class="toolbar3 text-capitalize py-0 px-6" flat short>
          <v-tabs
            v-if="!isXSmall"
            v-model="selectedTab"
            show-arrows
            slider-color="ttPrimary"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              class="text-capitalize px-2 mr-6"
              active-class="active-icon"
              @click="closeSidePanel"
            >
              <v-icon small left>
                {{ tab.icon }}
              </v-icon>
              {{ $t(tab.title) }}
            </v-tab>
          </v-tabs>
          <template v-if="isXSmall" #extension>
            <v-tabs
              v-model="selectedTab"
              slider-color="ttPrimary"
              show-arrows
              align-with-title
              centered
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.title"
                active-class="active-icon"
                class="text-capitalize"
                @click="closeSidePanel"
              >
                <v-icon small left>
                  {{ tab.icon }}
                </v-icon>
                {{ $t(tab.title) }}
              </v-tab>
            </v-tabs>
          </template>
          <v-spacer />
        </v-toolbar>
      </div>
      <div class="fill-height d-flex flex-column">
        <v-tabs-items v-model="selectedTab" class="fill-height">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.key"
            active-class="fill-height"
          >
            <component
              :is="tab.is"
              :key="tab.key"
              :allow-actions="allowActions"
              :employee-id="employeeId"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </MetadataLoadingGuard>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import HumanCapitalManagementEmployeeLeaveHistory from './leave-history/HumanCapitalManagementEmployeeLeaveHistory.vue'
import HumanCapitalManagementEmployeeConflicts from './conflicts/HumanCapitalManagementEmployeeConflicts.vue'
import { LayoutComponents } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { completedRequestsTab, conflictsTab } from '../constants'
import { EmployeeTab } from '../../types'
import MetadataLoadingGuard from '@/tt-widget-views/components/MetadataLoadingGuard.vue'

export default (
  Vue as VueConstructor<Vue & { layoutManager: LayoutManager }>
).extend({
  name: 'HumanCapitalManagementEmployeeView',
  components: {
    HumanCapitalManagementEmployeeLeaveHistory,
    HumanCapitalManagementEmployeeConflicts,
    MetadataLoadingGuard,
  },
  inject: ['layoutManager'],
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    employeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    isXSmall(): boolean {
      return this.$vuetify.breakpoint.xs
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    tabs(): EmployeeTab[] {
      return [completedRequestsTab, conflictsTab]
    },
  },
  methods: {
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
  },
})
</script>
<style scoped>
.leave-requests-title {
  height: 3.5rem;
  display: flex;
  align-content: center;
}
/* 
  Overriding default behaviour for flex items min-width: auto.
  This will allow the tabs to adapt the content properly, see: https://stackoverflow.com/a/36247448
*/
#leave-request-view {
  min-width: 0;
}
.active-icon .v-icon {
  color: var(--v-ttPrimary-base);
}
</style>
