<template>
  <tt-attr v-slot="{ rawValue }" :name="attributeName" hide-empty-value>
    <v-icon v-if="rawValue" :color="color" small>{{ icon }}</v-icon>
    <span v-else />
  </tt-attr>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CheckBooleanColumn',
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'success',
    },
    icon: {
      type: String,
      default: 'check_circle',
    },
  },
})
</script>
