import { Attribute, ResourceSchema } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'
import { AttributeEnum } from '@/tt-entity-design/src/schema-types'
import { ContactType, RegionContactType } from '@/tt-cta-contacts/types'

export type CTAType = AttributeEnum<
  typeof Resources.SYSTEM_EXCEPTION_TICKETS,
  'type'
>

export type AddressType = Record<Attribute<'addresses'>, unknown>
export type AccountType = Record<Attribute<'accounts'>, unknown>

export type Status = keyof ResourceSchema<
  typeof Resources.SYSTEM_EXCEPTION_TICKETS
>['attributes']['status']['labels']['list']

export enum StatusEnum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export type PanicTriggerCoordinates = {
  lat: number | null
  long: number | null
}

export type FormatedZoneContactType = {
  zoneName: string
  contacts: Array<ContactType | RegionContactType>
}
