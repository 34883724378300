import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'name',
    headerText: 'tt-entity-design.exception-groups.columns.name',
  },
  {
    attributeName: 'status',
    component: {
      is: 'ExceptionTypeGroupsStatus',
    },
  },
  {
    headerText: 'tt-entity-design.exception-groups.columns.subscribedClients',
    attributeName: 'subscribedClients',
    component: {
      props: {
        showTooltip: true,
      },
    },
  },
  {
    headerText: 'tt-entity-design.exception-groups.columns.subscribedZones',
    attributeName: 'subscribedZones',
    component: {
      props: {
        showTooltip: true,
      },
    },
  },
  'exceptionTypes',
  {
    attributeName: 'isDefault',
    headerText: 'tt-entity-design.exception-groups.columns.isDefault',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'isDefault',
      },
    },
    style: {
      width: 50,
    },
  },
  'region',
  {
    attributeName: 'isAvailableToSubRegions',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'isAvailableToSubRegions',
      },
    },
  },
  {
    attributeName: 'updatedOn',
    headerText: 'tt-entity-design.exception-groups.columns.updatedOn',
  },
] as ColumnInputDefinition[]
