<template>
  <div class="widget-error--wrapper">
    <ResizeObserver class="resize--observer" @resize="checkOverflow">
      <div
        ref="widget-error-observer--hidden"
        class="error-text--container pa-2"
      >
        <span v-text="$t(text)" />
        <slot />
      </div>
    </ResizeObserver>

    <v-tooltip bottom max-width="300" :disabled="!isOverflowing">
      <template #activator="{ on }">
        <v-icon
          :large="large"
          :size="iconSize"
          :color="iconColor"
          v-on="on"
          v-text="icon"
        />
      </template>

      <span v-text="$t(text)" />
    </v-tooltip>

    <div v-if="!isOverflowing" class="error-text--container pa-2">
      <span v-text="$t(text)" />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ResizeObserver from '@/tt-widget-components/components/ResizeObserver/ResizeObserver.vue'

const ICON_SIZE = 60

export default Vue.extend({
  name: 'WidgetError',
  components: { ResizeObserver },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'red',
    },
  },
  data: () => ({
    isOverflowing: true,
    large: false,
  }),

  computed: {
    iconSize() {
      return ICON_SIZE
    },
  },

  methods: {
    checkOverflow() {
      const el = this.$refs['widget-error-observer--hidden'] as Element

      const parentWidth = el.parentElement?.getBoundingClientRect().width
      const elementWidth = el.getBoundingClientRect().width

      const parentHeight = el.parentElement?.getBoundingClientRect().height
      const elementHeight = el.getBoundingClientRect().height + ICON_SIZE

      this.isOverflowing =
        !!parentWidth &&
        (elementWidth > parentWidth || elementHeight > parentHeight)

      this.large =
        this.isOverflowing && parentHeight <= ICON_SIZE ? true : false
    },
  },
})
</script>

<style scoped>
.widget-error--wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.error-text--container {
  max-width: 500px;
  text-align: center;
}

.resize--observer {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 100%;
}
</style>
