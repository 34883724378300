// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkSessionsTile from './WorkSessionsTile.vue'
import WorkSessionsColumns from './WorkSessionsColumns'
import WorkSessionsDetail from './WorkSessionsDetail.vue'
import WorkSessionsMetaCard from './WorkSessionsMetaCard.vue'
import WorkSessionsReference from './WorkSessionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkSessionsTile', WorkSessionsTile)
    Vue.component('WorkSessionsDetail', WorkSessionsDetail)
    Vue.component('WorkSessionsMetaCard', WorkSessionsMetaCard)
    Vue.component('WorkSessionsReference', WorkSessionsReference)

    modularManager.addResourcePreset(
      Resources.WORK_SESSIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkSessionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORK_SESSIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkSessionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORK_SESSIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkSessionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORK_SESSIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkSessionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORK_SESSIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkSessionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
