<template>
  <v-card flat>
    <v-alert icon="mdi-info" text type="warning" class="px-3">{{
      transitionLabel
    }}</v-alert>

    <v-container>
      <div v-if="isLoadingSchema && !schema">
        <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
      </div>
      <v-row v-if="schema && !isLoadingSchema" class="px-3">
        <v-col>
          <div class="pa-6">
            <JsonForm
              v-model="model"
              :schema="schema"
              :form-options="formOptions"
              :show-status="true"
              @valid="showSubmit = true"
              @errors="showSubmit = false"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="onClose" v-text="$t('common.cancel.btn')" />
        </v-col>
        <v-col class="text-end">
          <v-btn
            :disabled="!showSubmit"
            :loading="isSubmitting"
            color="success"
            @click="submit"
            v-text="$t('common.save.btn')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import isEmpty from 'lodash/isEmpty'
import { AppContext } from '@/tt-app-context'
import {
  JsonForm,
  FormOptions,
  EmptyValueRule,
} from '@tracktik/tt-json-schema-form'
import { createEntityViewDefinitions } from '@/tt-entity-forms/EntityViewDefinitions'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { LayoutWindowEvent } from '@/tt-app-layout'

interface RaiseEventPayload {
  event: string
  reportFields?: object
}

export default Vue.extend({
  name: 'WorkflowStatusTransitionsForm',
  components: { JsonForm },
  props: {
    form: {
      type: Number,
      default: undefined,
    },
    taskId: {
      type: Number,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
    eventLabel: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data() {
    const definitions = createEntityViewDefinitions(
      this.$appContext.authModule.getUserPreferences(),
    )

    return {
      definitions,
      schema: null,
      formOptions: null as FormOptions,
      model: {},
      showSubmit: true,
      isLoadingSchema: false,
      isSubmitting: false,
    }
  },
  computed: {
    transitionLabel(): string {
      return `${this.$t(
        'tt-entity-design.workflow-status-transitions.dialog.transition-title',
      )} ${this.eventLabel}?`
    },
  },
  created() {
    if (this.form) {
      this.fetchReport(this.form)
    }
  },
  methods: {
    fetchReport(id: number) {
      const appContext: AppContext = this.$appContext

      this.isLoadingSchema = true

      const setState = ({ jsonFormSchema }) => {
        const { schema, formOptions, values } = jsonFormSchema
        const { reportTemplate } = values

        const definitions = { ...this.definitions, ...formOptions.definitions }

        this.schema = schema
        this.formOptions = { definitions, emptyValues: EmptyValueRule.REMOVE }
        this.model = { reportTemplate }
      }

      appContext.authModule
        .getApi()
        .get(Resources.WORKFLOW_STATUS_TRANSITION_FORMS, id, {
          extension: ['jsonFormSchema'],
        })
        .then(setState)
        .catch((error) => this.onSubmitError(error))
        .finally(() => {
          this.isLoadingSchema = false
        })
    },
    submit() {
      const appContext: AppContext = this.$appContext
      const data: RaiseEventPayload = {
        event: this.event,
      }

      if (!isEmpty(this.model)) {
        data.reportFields = this.model
      }

      this.isSubmitting = true

      appContext.authModule
        .getApi()
        .httpClient.post<{ data: RaiseEventPayload }>(
          `${Resources.DISPATCH_TASKS}/${this.taskId}/actions/raise-event`,
          data,
        )
        .then(() => this.onSubmitSuccess())
        .catch((error) => this.onSubmitError(error))
        .finally(() => {
          this.isSubmitting = false
        })
    },
    onSubmitSuccess(): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: Resources.DISPATCH_TASKS,
        entityId: this.taskId,
      })
      this.onClose()
    },
    onSubmitError(error): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error,
      })
    },
  },
})
</script>

<style scoped>
.transition--label {
  border-radius: 30px;
  background-color: #e0e0e0;
}
</style>
