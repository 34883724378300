// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractsTile from './ContractsTile.vue'
import ContractsColumns from './ContractsColumns'
import ContractsDetail from './ContractsDetail.vue'
import ContractsMetaCard from './ContractsMetaCard.vue'
import ContractsReference from './ContractsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContractsTile', ContractsTile)
    Vue.component('ContractsDetail', ContractsDetail)
    Vue.component('ContractsMetaCard', ContractsMetaCard)
    Vue.component('ContractsReference', ContractsReference)

    modularManager.addResourcePreset(
      Resources.CONTRACTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.CONTRACTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ContractsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.CONTRACTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ContractsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
