<template>
  <div>
    <div class="d-flex flex-column">
      <span
        class="heading-6 pb-2"
        v-text="
          $t('tt-entity-design.system-exception-tickets.last-coordinates.label')
        "
      />
      <span
        class="body-2 pb-2"
        v-text="
          $t(
            'tt-entity-design.system-exception-tickets.last-coordinates.description',
          )
        "
      />
    </div>
    <SystemExceptionTicketsExpansionPanels v-if="hasCoordinates">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="lastLatitude"
            :label="$t('res.mobile-devices.attr.lastLatitude.label')"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="lastLongitude"
            :label="$t('res.mobile-devices.attr.lastLongitude.label')"
            dense
            outlined
          />
        </v-col>
      </v-row>
    </SystemExceptionTicketsExpansionPanels>
    <div v-else>
      <v-icon
        class="mr-2"
        color="ttPrimary"
        small
        v-text="`mdi-alert-circle-outline`"
      />
      <span
        style="color: var(--v-ttPrimary-base)"
        class="body-2"
        v-text="$t('widgets.no_data_to_display')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import SystemExceptionTicketsExpansionPanels from './SystemExceptionTicketsExpansionPanels.vue'

export default Vue.extend({
  name: 'SystemExceptionTicketsCoordinatesFields',
  components: {
    SystemExceptionTicketsExpansionPanels,
  },
  props: {
    lastLatitude: {
      type: Number,
      default: null,
    },
    lastLongitude: {
      type: Number,
      default: null,
    },
    hasCoordinates: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
