import moment from 'moment'
import { ApiDateTimeFormats } from '../formats/dates/types'

export const isValidIsoDatetime = (datetimeISO: string): boolean =>
  moment(datetimeISO, ApiDateTimeFormats.ISO_8601, true).isValid()

export const isValidDatetime = (datetimeISO: string): boolean =>
  moment(datetimeISO, ApiDateTimeFormats.datetime, true).isValid()

export const isValidDate = (date: string): boolean =>
  moment(date, ApiDateTimeFormats.date, true).isValid()
