import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'employee',
  'account',
  'actionDateTime',
  'breakRule',
  'type',
  'comment',
] as ColumnInputDefinition[]
