<template>
  <ResourceAllowedOperations
    v-if="entityId"
    v-slot="{ allowsCreation }"
    :resource-name="'workflow-instance-logs'"
  >
    <v-btn
      v-if="allowsCreation"
      class="form--button text-center"
      text
      dark
      :loading="loading"
      color="green"
      @click="createManualLog"
    >
      <v-icon small v-text="`add`" />
      <span class="px-2" v-text="$t(translationKey('create-manual-log'))" />
    </v-btn>
    <EntityRelationListPreview
      class="list-container"
      :entity-id="entityId"
      :item-hook="itemHook"
      :resource-name="resourceName"
      :entity="entity"
      hide-entity-preview-header
      disable-resource-preview
    />
  </ResourceAllowedOperations>
</template>

<script lang="ts">
import Vue from 'vue'
import { Resources } from '../../types'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { updateDOM } from '@/helpers/dom'
import {
  EntityCreateIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'
import { getCreateFormState } from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { LayoutWindowEvent } from '@/tt-app-layout'

const WORKFLOW_INSTANCE_LOGS = 'workflowInstance.logs'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'WorkflowInstanceLogsListWrapper',
  inject: ['getItemHook'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    resourceName(): string {
      return Resources.DISPATCH_TASKS
    },
    entity(): string {
      return WORKFLOW_INSTANCE_LOGS
    },
    entityId(): number {
      return this.getItemHook().getRawValue('workflowInstance.id')
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  created() {
    this.getItemHook().addAttribute('workflowInstance.id')
    this.getItemHook().addAttribute('workflowInstance.workflow.id')
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.workflow-instance-logs.${key}`
    },
    async createManualLog() {
      this.loading = true

      await updateDOM()

      const formConfig: EntityCreateIntentInterface = {
        resourceName: Resources.WORKFLOW_INSTANCE_LOGS,
        title: '',
        itemHook: this.itemHook,
        onSuccess: () => {
          this.$eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: Resources.WORKFLOW_INSTANCE_LOGS,
          })
        },
        formOptions: {
          fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
        },
      }
      const payload = await getCreateFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        title: this.translationKey('form.create-form-title'),
        props: { payload },
      })

      this.loading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.list-container {
  min-height: 300px;
}
</style>
