<template>
  <div v-if="isSaving || isRendering" class="mx-3 loading-bar--container">
    <TLoadingBar />
  </div>

  <!-- MOBILE -->
  <div v-else-if="isMobile" class="mobile-edit-actions-bar">
    <v-btn
      v-if="editMode && canSave"
      small
      text
      color="green"
      @click="emitEvent('save')"
    >
      <span v-text="$t('tql_query_view.save')" />
    </v-btn>
    <v-btn
      small
      text
      class="hidden-print-only"
      @click="emitEvent('enter-edit-mode')"
    >
      <span v-text="$t('common.edit.btn')" />
    </v-btn>
  </div>

  <!-- DESKTOP -->
  <div v-else>
    <!-- EDIT -->
    <v-btn
      v-if="!editMode"
      small
      text
      class="hidden-print-only"
      @click="emitEvent('enter-edit-mode')"
    >
      <span v-text="$t('common.edit.btn')" />
    </v-btn>

    <!-- EXIT EDIT MODE -->
    <v-btn
      v-if="editMode"
      class="ml-1 mr-1"
      small
      text
      color="var(--v-ttPrimary-base)"
      @click="emitEvent('exit-edit-mode')"
    >
      <span v-text="$t('widget_edit.exit_edit_mode')" />
    </v-btn>

    <!-- SAVE -->
    <v-btn
      v-if="editMode"
      :disabled="!canSave || disableSave"
      class="white--text ml-1 mr-1"
      small
      color="var(--v-ttPrimary-base)"
      @click="emitEvent('save')"
    >
      <span v-text="$t('widget_view.save')" />
    </v-btn>

    <!-- ADD WIDGET -->
    <v-btn
      v-if="showSearchWidgetButton"
      small
      class="ml-3"
      @click="emitEvent('open-widget-library')"
    >
      <span v-text="$t('widget_view.search_widgets')" />
    </v-btn>
    <WidgetAllowDownloadDropdown
      v-if="editMode && showDownloadButton"
      :supported-types="supportedDownloadTypes"
      :value="allowDownload"
      @input="handleAllowDownloadChange"
    />

    <!-- TOGGLE EDITOR -->
    <v-btn
      v-if="showOpenEditPanelButton"
      small
      text
      @click="emitEvent('open-edit-panel')"
    >
      <span v-text="$t('widget_view.open_edit_panel')" />
    </v-btn>
  </div>
</template>

<script lang="ts">
import { updateDOM } from '@/helpers/dom'
import Vue, { PropType } from 'vue'
import TLoadingBar from '@/tt-ui/components/TLoadingBar.vue'
import WidgetAllowDownloadDropdown from './WidgetAllowDownloadDropdown.vue'

import { AllowDownloadAll, WidgetDownloadTypes } from '@/tt-widget-components'

export type Events =
  | 'save'
  | 'enter-edit-mode'
  | 'open-edit-panel'
  | 'open-widget-library'
  | 'exit-edit-mode'

export default Vue.extend({
  name: 'WidgetViewEditModeActions',
  components: { TLoadingBar, WidgetAllowDownloadDropdown },
  props: {
    supportedDownloadTypes: {
      type: Array as PropType<WidgetDownloadTypes[]>,
      default: null
    },
    allowDownload: {
      type: [Boolean, Object] as PropType<AllowDownloadAll | null>,
      default: null
    },
    isMobile: { type: Boolean, default: false },
    canSave: { type: Boolean, default: false },
    disableSave: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    showSearchWidgetButton: { type: Boolean, default: false },
    showOpenEditPanelButton: { type: Boolean, default: false },
    isSaving: { type: Boolean, default: false },
    showDownloadButton: { type: Boolean, default: false },
  },
  data: () => ({
    isRendering: false,
  }),
  methods: {
    handleAllowDownloadChange(allowDownload: AllowDownloadAll | null) {
      return this.$emit('allow-download:change', allowDownload)
    },
    async emitEvent(eventName: Events) {
      this.isRendering = true

      await updateDOM()

      this.$emit(eventName)

      this.isRendering = false
    },
  },
})
</script>

<style scoped>
.loading-bar--container {
  width: 200px;
  position: relative;
  border-radius: 1em;
}
</style>
