<template>
  <div>
    <v-btn small icon dense class="ml-2" @click="toggle">
      <v-icon
        :color="starred ? 'orange' : ''"
        v-text="starred ? 'star' : 'star_outline'"
        >star_outline
      </v-icon>
    </v-btn>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import {
  WidgetTagModel,
  WidgetViewTags,
} from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { StarWidget, UnStarWidget } from '@/plugins/o11n'
import { Collection } from '@vuex-orm/core'

export default Vue.extend({
  name: 'WidgetStarredIcon',
  props: {
    uid: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: WidgetViewTags.STARRED,
    },
  },
  computed: {
    all(): Collection<WidgetTagModel> {
      return WidgetTagModel.query()
        .where((tag) => {
          return tag.tag == WidgetViewTags.STARRED && tag.uid == this.uid
        })
        .all()
    },
    starred(): boolean {
      if (!this.uid) {
        return false
      }
      return (
        WidgetTagModel.query()
          .where((tag) => {
            return tag.tag == WidgetViewTags.STARRED && tag.uid == this.uid
          })
          .all().length > 0
      )
    },
  },
  methods: {
    toggle() {
      if (this.starred) {
        this.$analytics.track(StarWidget.create())
        this.$eventManager.dispatchEvent(WidgetEditorEvents.WIDGET_TAG, {
          tag: this.tag,
          uid: this.uid,
          remove: true,
        })
      } else {
        this.$analytics.track(UnStarWidget.create())
        this.$eventManager.dispatchEvent(WidgetEditorEvents.WIDGET_TAG, {
          tag: this.tag,
          uid: this.uid,
        })
      }
    },
  },
})
</script>
