// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeExternalIdsTile from './EmployeeExternalIdsTile.vue'
import EmployeeExternalIdsColumns from './EmployeeExternalIdsColumns'
import EmployeeExternalIdsDetail from './EmployeeExternalIdsDetail.vue'
import EmployeeExternalIdsReference from './EmployeeExternalIdsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeExternalIdsTile', EmployeeExternalIdsTile)
    Vue.component('EmployeeExternalIdsDetail', EmployeeExternalIdsDetail)
    Vue.component('EmployeeExternalIdsReference', EmployeeExternalIdsReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_IDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_IDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeExternalIdsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_IDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTERNAL_IDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeExternalIdsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
