import { WidgetReference } from '@/tt-widget-factory/types'
import colors from 'vuetify/lib/util/colors'
import multiPalettes from './multiPalettes'
import { PaletteCollectionModel } from '@syncfusion/ej2-vue-heatmap'

const DEFAULT_COLOR_PALETTE = 'INDIGO'
export interface Palette {
  name: string
  colors: string[]
}

const emptyColorsObject = {}

const multiPaletteNames = Object.keys(multiPalettes)

const newColors = Object.assign(emptyColorsObject, colors, multiPalettes)

const colorPalettes = new Map<string, Palette>()

Object.entries(newColors).forEach(([key, color]) => {
  if (multiPaletteNames.includes(key)) {
    colorPalettes.set(key.toUpperCase(), <Palette>{
      name: key.toUpperCase(),
      colors: [
        color.red,
        color.pink,
        color.purple,
        color.indigo,
        color.lightBlue,
        color.cyan,
        color.teal,
        color.lightGreen,
        color.yellow,
        color.orange,
      ],
    })
  } else {
    colorPalettes.set(key.toUpperCase(), <Palette>{
      name: key.toUpperCase(),
      colors: [
        color.darken4,
        color.darken3,
        color.darken2,
        color.darken1,
        color.base,
        color.lighten1,
        color.lighten2,
        color.lighten3,
        color.lighten4,
        color.lighten5,
      ],
    })
  }
})

export default colorPalettes

export const getWidgetColorPalette = (widget: WidgetReference): string[] => {
  const widgetColorPaletteExist =
    widget.options?.colorPalette &&
    colorPalettes.has(widget.options.colorPalette)

  const colorPalette = widgetColorPaletteExist
    ? widget.options.colorPalette
    : DEFAULT_COLOR_PALETTE

  return colorPalettes.get(colorPalette).colors
}

export const getWidgetPaletteCollection = (
  widget: WidgetReference,
): PaletteCollectionModel[] => {
  const createPaletteCollection = (color: string): PaletteCollectionModel => ({
    color,
  })

  return getWidgetColorPalette(widget).map(createPaletteCollection).reverse()
}
