import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'name',
  'email',
  'primaryPhone',
  'client',
  'client.region',
] as ColumnInputDefinition[]
