<template>
  <tt-tile>
    <v-skeleton-loader v-if="loading" type="list-item-text" />
    <div class="d-flex justify-space-between align-center flex-grow-1">
      <div class="d-flex flex-column">
        <span slot="line2" class="text-subtitle-2" v-text="$t(reviewerName)" />
        <tt-attr
          slot="line3"
          name="createdOn"
          class="text-body-2 font-weight-light"
        />
      </div>
      <div>
        <StatusChip
          :status-color-options="LeaveRequestsStatusColorOptions"
          :is-small="false"
        />
      </div>
    </div>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '../../types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  LeaveRequestsStatus,
  LeaveRequestsStatusColorOptions,
} from '../leave-requests/types'

type Employee = {
  avatar: string
  id: number
  name: string
}

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestLogsTile',
  inject: ['getItemHook'],
  data() {
    return {
      reviewerName: null,
      loading: true,
    }
  },
  computed: {
    status(): LeaveRequestsStatus {
      return this.getItemHook().getRawValue('status')
    },
    reviewerLogId(): number {
      return Number(this.getItemHook().get('attributes.reviewer_id'))
    },
    createdById(): number {
      return Number(this.getItemHook().get('attributes.create_user_id'))
    },
    leaveRequestUserId(): number {
      return Number(this.getItemHook().get('attributes.user_id'))
    },
    LeaveRequestsStatusColorOptions(): typeof LeaveRequestsStatusColorOptions {
      return LeaveRequestsStatusColorOptions
    },
    requestLogUserId(): number {
      if (this.status === LeaveRequestsStatus.PENDING_CANCELLATION) {
        return this.leaveRequestUserId
      } else if (this.reviewerLogId) {
        return this.reviewerLogId
      } else {
        return this.createdById
      }
    },
  },
  mounted() {
    if (this.requestLogUserId) this.fetchRequestLogReview(this.requestLogUserId)
  },
  created() {
    this.getItemHook().addAttribute('attributes')
    this.getItemHook().addAttribute('leaveRequest.reviewer')
  },
  methods: {
    fetchRequestLogReview(id: number) {
      this.$appContext.entityServices.persister.api
        .get(Resources.EMPLOYEES, id)
        .then(({ name }: Employee) => (this.reviewerName = name))
        .catch((error) => {
          this.$crash.captureException(error)
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: this.$t('common.error_message'),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
