<template>
  <div>
    <tt-attr
      :name="entitlementValueName"
      format="asRounded2Decimals"
      :class="accentClass"
    />
    <tt-attr
      v-if="showEntitlementUnit"
      name="entitlementUnit"
      :prefix="nonBreakingSpace"
      :class="accentClass"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'LeavePolicyItemsValueField',
  inject: ['getItemHook'],
  props: {
    entitlementValueName: {
      type: String,
      required: true,
    },
    showEntitlementUnit: {
      type: Boolean,
      default: false,
    },
    accentClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    nonBreakingSpace(): string {
      // hexcode for &nbsp;
      return '\xa0'
    },
  },
})
</script>
