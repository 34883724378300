import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const EmployeePremiumAssignmentColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'premium.type',
  },
  {
    attributeName: 'employee.name',
  },
  {
    attributeName: 'assignedClient.company',
  },
  {
    attributeName: 'assignedSite.company',
  },
  {
    attributeName: 'assignedPosition.name',
  },
  {
    attributeName: 'premium.payCode',
    headerText: 'back-office-reports.earnings-premium.payCode',
  },
  {
    attributeName: 'premium.rate',
    headerText: 'back-office-reports.earnings-premium.premiumValue',
  },
  {
    attributeName: 'premium.isAddToBaseRate',
    headerText: 'back-office-reports.earnings-premium.addToBaseRate',
    component: {
      is: 'EarningsPremiumAddToBaseRateCustomColumn',
      props: {
        attributeNameAddToBaseRate: 'premium.isAddToBaseRate',
        isCheckboxColumn: true,
      },
    },
  },
]

export const EmployeePremiumAssignmentDownloadableColumns: ColumnInputDefinition[] =
  [
    {
      attributeName: 'premium.type',
    },
    {
      attributeName: 'employee.name',
    },
    {
      attributeName: 'assignedClient.company',
    },
    {
      attributeName: 'assignedSite.company',
    },
    {
      attributeName: 'assignedPosition.name',
    },
    {
      attributeName: 'premium.payCode.name',
      headerText: 'back-office-reports.earnings-premium.payCode',
    },
    {
      attributeName: 'premium.rate',
      headerText: 'back-office-reports.earnings-premium.premiumValue',
    },
    {
      attributeName: 'premium.isAddToBaseRate',
      headerText: 'back-office-reports.earnings-premium.addToBaseRate',
    },
  ]
