import { LeavePolicyItemsComponents } from '@/tt-entity-design/src/components/leave-policy-items/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'customId',
  'name',
  {
    attributeName: 'accrualRate',
    headerText:
      'tt-entity-design.leave-management.leave-policy-items.is-accrued',
    component: {
      is: LeavePolicyItemsComponents.LeavePolicyItemsAccruedColumn,
    },
  },
  'description',
  'entitlementUnit',
  'quantity',
  'carryOverLimit',
] as ColumnInputDefinition[]
