// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import InvoiceBatchesTile from './InvoiceBatchesTile.vue'
import InvoiceBatchesColumns from './InvoiceBatchesColumns'
import InvoiceBatchesDetail from './InvoiceBatchesDetail.vue'
import InvoiceBatchesMetaCard from './InvoiceBatchesMetaCard.vue'
import InvoiceBatchesReference from './InvoiceBatchesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('InvoiceBatchesTile', InvoiceBatchesTile)
    Vue.component('InvoiceBatchesDetail', InvoiceBatchesDetail)
    Vue.component('InvoiceBatchesMetaCard', InvoiceBatchesMetaCard)
    Vue.component('InvoiceBatchesReference', InvoiceBatchesReference)

    modularManager.addResourcePreset(
      Resources.INVOICE_BATCHES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'InvoiceBatchesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_BATCHES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: InvoiceBatchesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_BATCHES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'InvoiceBatchesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_BATCHES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'InvoiceBatchesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.INVOICE_BATCHES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'InvoiceBatchesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
