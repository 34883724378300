import { VueConstructor } from 'vue'
import ActionForm from '@/tt-widget-entity-action-forms/components/ActionForm'
import AttributePatchForm from '@/tt-widget-entity-action-forms/components/AttributePatchForm.vue'
import EntityFormsInstaller from '@/tt-entity-forms'

export default {
  install(Vue: VueConstructor) {
    Vue.use(EntityFormsInstaller)

    Vue.component('ActionForm', ActionForm)
    Vue.component('ActionPatchForm', AttributePatchForm)
  },
}
