<template>
  <v-autocomplete
    multiple
    dense
    outlined
    :items="items"
    :label="label"
    :value="value"
    :disabled="disabled"
    @input="$emit('input', $event)"
  />
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { FormHook } from '@tracktik/tt-json-schema-form'

export default (
  Vue as VueConstructor<Vue & { formHook: () => FormHook }>
).extend({
  name: 'TicketTypesField',
  inject: ['formHook'],
  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: Array as PropType<string[]>, default: () => [] },
    disabledValues: { type: Array as PropType<string[]>, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    itemField(): { is: string; props: Record<string, any> } {
      return this.formHook().getViewComponentProps(this.name).itemField
    },
    items(): {
      text: string
      value: string
    }[] {
      return this.itemField.props.items.map((item) => ({
        ...item,
        disabled: this.disabledValues.includes(item.value),
      }))
    },
  },
})
</script>
