<template>
  <div class="resource-allowed-operations" v-bind="$attrs">
    <slot
      :allows-actions="allowsActions"
      :allows-creation="allowsCreation"
      :allows-delete="allowsDelete"
      :allows-edit="allowsEdit"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { AllowEntityOperationsProvider } from '@/types'
import { isResourceWhitelisted } from '@/tt-widget-factory/services/metadata-provider/resource-blacklist'
import { Resource } from '@/tt-entity-design/src/schema-types'

import { allowsEntityAction, allowsEntityOperation } from '../helper'

type ViewWithInjections = VueConstructor<Vue & AllowEntityOperationsProvider>

export default (Vue as ViewWithInjections).extend({
  name: 'ResourceAllowedOperations',
  inject: {
    // if not provided by a parent view, permissions fallback to the appContext setting
    allowEntityActions: {
      default() {
        return this.$appContext.allowEntityActions
      },
    },
    allowEntityCreation: {
      default() {
        return this.$appContext.allowEntityCreation
      },
    },
    allowEntityDelete: {
      default() {
        return this.$appContext.allowEntityDelete
      },
    },
    allowEntityEdit: {
      default() {
        return this.$appContext.allowEntityEdit
      },
    },
  },
  props: {
    resourceName: {
      type: String as PropType<Resource>,
      required: true,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    allowsActions(): boolean {
      return allowsEntityAction(this.allowEntityActions, this.resourceName)
    },
    allowsCreation(): boolean {
      return (
        allowsEntityOperation(this.allowEntityCreation, {
          resourceName: this.resourceName,
        }) && this.hasApiCreationPermission
      )
    },
    allowsDelete(): boolean {
      return (
        allowsEntityOperation(this.allowEntityDelete, {
          resourceName: this.resourceName,
          entity: this.entity,
        }) && this.hasApiDeletePermission
      )
    },
    allowsEdit(): boolean {
      return (
        allowsEntityOperation(
          this.allowEntityEdit,
          {
            resourceName: this.resourceName,
            entity: this.entity,
          },
          this.$appContext,
        ) && this.hasApiEditPermission
      )
    },
    hasApiCreationPermission(): boolean {
      try {
        return ResourcePermissionAuditor.canCreateResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.resourceName,
        )
      } catch (e) {
        if (this.ignoreSchemaError) {
          return false
        } else {
          throw e
        }
      }
    },
    hasApiDeletePermission(): boolean {
      try {
        return ResourcePermissionAuditor.canDeleteResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.resourceName,
        )
      } catch (e) {
        if (this.ignoreSchemaError) {
          return false
        } else {
          throw e
        }
      }
    },
    hasApiEditPermission(): boolean {
      try {
        return ResourcePermissionAuditor.canEditResource(
          getResourcePermissionAuditorServices(this.$appContext),
          this.resourceName,
        )
      } catch (e) {
        if (this.ignoreSchemaError) {
          return false
        } else {
          throw e
        }
      }
    },
    ignoreSchemaError(): boolean {
      return isResourceWhitelisted(this.resourceName)
    },
  },
  watch: {
    allowsActions: {
      immediate: true,
      handler(value: boolean) {
        /**
         * @event change:allowsActions
         * @type {boolean}
         */
        this.$emit('change:allowsActions', value)
      },
    },
    allowsCreation: {
      immediate: true,
      handler(value: boolean) {
        /**
         * @event change:allowsCreation
         * @type {boolean}
         */
        this.$emit('change:allowsCreation', value)
      },
    },
    allowsDelete: {
      immediate: true,
      handler(value: boolean) {
        /**
         * @event change:allowsDelete
         * @type {boolean}
         */
        this.$emit('change:allowsDelete', value)
      },
    },
    allowsEdit: {
      immediate: true,
      handler(value: boolean) {
        /**
         * @event change:allowsEdit
         * @type {boolean}
         */
        this.$emit('change:allowsEdit', value)
      },
    },
  },
})
</script>
