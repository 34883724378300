<template>
  <v-icon :color="color" v-bind="$attrs">
    {{ icon }}
  </v-icon>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetTypesIcon',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    prefix: String,
  },
  computed: {
    absolutePrefix(): string {
      const prefix = this.prefix ?? this.relationPrefix ?? false

      return prefix ? `${prefix}.` : ''
    },
    color(): string {
      const color = this.getItemHook().get(`${this.absolutePrefix}color`)

      return color && color !== '' ? color : 'blue darken-2'
    },
    icon(): string {
      const icon = this.getItemHook().get(`${this.absolutePrefix}icon`)

      return icon && icon !== '' ? icon : 'radio_button_checked'
    },
  },
  created() {
    this.getItemHook().addAttribute(`${this.absolutePrefix}icon`)
    this.getItemHook().addAttribute(`${this.absolutePrefix}color`)
  },
})
</script>
