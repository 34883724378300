import { Model } from '@vuex-orm/core'
import { Module } from 'vuex'

import { AclRule } from '@/tt-widget-sharable'
import { WidgetCategoryInterface } from '@/tt-widget-factory/services/widget-collections/types'

export enum WidgetCategoryModelMetaLayouts {
  GRID = 'GRID',
  SUB_MENU = 'SUB_MENU',
  CARDS = 'CARDS',
}

export interface WidgetCategoryModelMeta {
  id?: number | string
  bannerImage?: string
  icon?: string
  color?: string
  layout?: WidgetCategoryModelMetaLayouts
  createdOn?: string
  updatedOn?: string
  aclRule?: AclRule
  ownedBy?: {
    name?: string
    id?: number | string
    avatar?: string
  }
}

export interface WidgetCategoryPayload {
  name: string
  iconClass?: string
  parentCategory?: string
  options?: WidgetCategoryOptions
}

export interface WidgetCategoryOptions {
  bannerImage: string
  color: string
  icon: string
}

export class WidgetCategoryModel
  extends Model
  implements WidgetCategoryInterface
{
  static entity = 'WidgetCategoryModel'
  static primaryKey = 'uid'

  description: string
  icon: string
  name: string
  meta?: WidgetCategoryModelMeta
  parentCategory: string
  ownedByMe: boolean
  uid: string
  isFromMarketplace: boolean

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      icon: this.string('folder'),
      uid: this.string(null).nullable(),
      name: this.string(''),
      description: this.string(''),
      ownedByMe: this.boolean(false),
      parentCategory: this.string(null).nullable(),
      meta: this.attr({}),
      isFromMarketplace: this.boolean(false),
    }
  }
}

interface WidgetCategoryState {
  hasBeenFetched: boolean
}

export const WidgetCategoryStoreModule: Module<WidgetCategoryState, any> = {
  state: {
    hasBeenFetched: false,
  },
  getters: {
    hasBeenFetched: (state) => state.hasBeenFetched,
  },
  actions: {
    finishFetching({ commit }) {
      commit('setHasBeenFetched', true)
    },
  },
  mutations: {
    setHasBeenFetched(state, payload: WidgetCategoryState['hasBeenFetched']) {
      state.hasBeenFetched = payload
    },
  },
}
