export enum TimeTense {
  PAST = -1,
  PRESENT = 0,
  FUTURE = 1,
}

export enum DatePresetValue {
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  LastYear = 'LAST_YEAR',
  NextMonth = 'NEXT_MONTH',
  NextWeek = 'NEXT_WEEK',
  NextYear = 'NEXT_YEAR',
  ThisMonth = 'THIS_MONTH',
  ThisWeek = 'THIS_WEEK',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  Yesterday = 'YESTERDAY',
}

export interface DatePreset {
  text: string
  value: DatePresetValue
  tense: TimeTense
}

export const DATE_PRESETS: DatePreset[] = [
  {
    text: 'date.preset.TODAY',
    value: DatePresetValue.Today,
    tense: TimeTense.PRESENT,
  },
  {
    text: 'date.preset.THIS_WEEK',
    value: DatePresetValue.ThisWeek,
    tense: TimeTense.PRESENT,
  },
  {
    text: 'date.preset.THIS_MONTH',
    value: DatePresetValue.ThisMonth,
    tense: TimeTense.PRESENT,
  },
  {
    text: 'date.preset.THIS_YEAR',
    value: DatePresetValue.ThisYear,
    tense: TimeTense.PRESENT,
  },
  {
    text: 'date.preset.YESTERDAY',
    value: DatePresetValue.Yesterday,
    tense: TimeTense.PAST,
  },
  {
    text: 'date.preset.LAST_WEEK',
    value: DatePresetValue.LastWeek,
    tense: TimeTense.PAST,
  },
  {
    text: 'date.preset.LAST_MONTH',
    value: DatePresetValue.LastMonth,
    tense: TimeTense.PAST,
  },
  {
    text: 'date.preset.LAST_YEAR',
    value: DatePresetValue.LastYear,
    tense: TimeTense.PAST,
  },
  {
    text: 'date.preset.TOMORROW',
    value: DatePresetValue.Tomorrow,
    tense: TimeTense.FUTURE,
  },
  {
    text: 'date.preset.NEXT_WEEK',
    value: DatePresetValue.NextWeek,
    tense: TimeTense.FUTURE,
  },
  {
    text: 'date.preset.NEXT_MONTH',
    value: DatePresetValue.NextMonth,
    tense: TimeTense.FUTURE,
  },
  {
    text: 'date.preset.NEXT_YEAR',
    value: DatePresetValue.NextYear,
    tense: TimeTense.FUTURE,
  },
]

export const isDatePreset = (value: string): value is DatePresetValue =>
  DATE_PRESETS.map((item) => item.value).some((v) => v === value)
