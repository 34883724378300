// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import FilterRuleSetReportsTile from './FilterRuleSetReportsTile.vue'
import FilterRuleSetReportsColumns from './FilterRuleSetReportsColumns'
import FilterRuleSetReportsDetail from './FilterRuleSetReportsDetail.vue'
import FilterRuleSetReportsReference from './FilterRuleSetReportsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('FilterRuleSetReportsTile', FilterRuleSetReportsTile)
    Vue.component('FilterRuleSetReportsDetail', FilterRuleSetReportsDetail)
    Vue.component(
      'FilterRuleSetReportsReference',
      FilterRuleSetReportsReference,
    )

    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_REPORTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetReportsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_REPORTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: FilterRuleSetReportsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_REPORTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetReportsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.FILTER_RULE_SET_REPORTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'FilterRuleSetReportsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
