import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'employmentProfile',
  'effectiveDate',
  'employeePayType',
  'hourlyRateType',
  'hourlyRate',
] as ColumnInputDefinition[]

// #NOT_DONE# Make sure to remove the line below to prevent your file from being overwritten
