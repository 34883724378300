import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportLibraryFieldsForm from './ReportLibraryFieldsForm.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('ReportLibraryFieldsForm', ReportLibraryFieldsForm)

    registerResourceForm(
      Resources.REPORT_LIBRARY_FIELDS,
      'ReportLibraryFieldsForm',
    )
  },
}
