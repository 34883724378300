<template>
  <v-card-text>
    <v-select
      dense
      outlined
      hide-details
      :items="operatorsList"
      :value="model.operator"
      :label="$t('common.operator')"
      :error="!model.operator"
      :menu-props="{ offsetY: true }"
      @input="setOperator"
    />
    <template v-if="!isNullOperator">
      <v-row v-if="isBetweenOperator" align="center" no-gutters class="mt-4">
        <v-col>
          <v-text-field
            type="number"
            dense
            hide-details
            outlined
            v-bind="firstInput"
            @input="setBetweenFirstValue"
          />
        </v-col>
        <v-col cols="1" class="text-center">-</v-col>
        <v-col>
          <v-text-field
            type="number"
            hide-details
            dense
            outlined
            v-bind="secondInput"
            @input="setBetweenSecondValue"
          />
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="mt-4">
        <v-col>
          <v-text-field
            type="number"
            dense
            hide-details
            outlined
            :value="model.value"
            :error="isEmpty(model.value)"
            :label="$t('common.value')"
            @input="setValue"
          />
        </v-col>
      </v-row>
    </template>
  </v-card-text>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  FilterOperatorType,
  Filter,
} from 'tracktik-sdk/lib/common/entity-filters'
import {
  BETWEEN_SEPARATOR_NUMBER,
  isNullOperatorType,
} from '@/tt-entity-filter/util'
import { debounce } from 'lodash'

type InputValue = { value: string; label: string; error: boolean }
type OperatorViewItem = {
  text: string
  value: string
}

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<Filter>,
      default: null,
    },
  },
  data() {
    return {
      setValue: debounce((value: Filter['value']) => {
        //@ts-ignore
        this.emitUpdate({ ...this.model, value })
      }, 500),
    }
  },
  computed: {
    model(): Partial<Filter> {
      // to avoid confusion with filter value (this.value.value)
      return this.value || {}
    },
    firstInput(): InputValue {
      // @ts-ignore
      const value = this.model.value?.split(BETWEEN_SEPARATOR_NUMBER)[0] ?? ''
      const label = this.$t('common.from') as string
      const error = this.isEmpty(value)

      return { value, label, error }
    },
    secondInput(): InputValue {
      // @ts-ignore
      const value = this.model.value?.split(BETWEEN_SEPARATOR_NUMBER)[1] ?? ''
      const label = this.$t('common.to') as string
      const error = this.isEmpty(value)

      return { value, label, error }
    },
    isBetweenOperator(): boolean {
      return this.model.operator === FilterOperatorType.BETWEEN
    },
    isNullOperator(): boolean {
      const { operator } = this.model
      return isNullOperatorType(operator)
    },
    operatorsList(): OperatorViewItem[] {
      return [
        {
          value: FilterOperatorType.BETWEEN,
          text: this.$t('filter.operator.BETWEEN'),
        },
        {
          value: FilterOperatorType.EQUAL,
          text: this.$t('filter.operator.EQUAL'),
        },
        {
          value: FilterOperatorType.GT,
          text: this.$t('filter.operator.GT'),
        },
        {
          value: FilterOperatorType.GTE,
          text: this.$t('filter.operator.GTE'),
        },
        {
          value: FilterOperatorType.LT,
          text: this.$t('filter.operator.LT'),
        },
        {
          value: FilterOperatorType.LTE,
          text: this.$t('filter.operator.LTE'),
        },
        {
          value: FilterOperatorType.ISNOTNULL,
          text: this.$t('filter.operator.ISNOTNULL'),
        },
        {
          value: FilterOperatorType.ISNULL,
          text: this.$t('filter.operator.ISNULL'),
        },
      ] as OperatorViewItem[]
    },
  },
  methods: {
    setOperator(operator: Filter['operator']) {
      this.emitUpdate({ ...this.model, operator, value: null })
    },
    setBetweenFirstValue(firstValue) {
      const value = `${firstValue}${BETWEEN_SEPARATOR_NUMBER}${this.secondInput.value}`
      this.setValue(value)
    },
    setBetweenSecondValue(secondValue) {
      const value = `${this.firstInput.value}${BETWEEN_SEPARATOR_NUMBER}${secondValue}`
      this.setValue(value)
    },
    emitUpdate(newFilter: Partial<Filter>) {
      this.$emit('input', { ...newFilter })
    },
    isEmpty: (value) => !value && value !== 0,
  },
})
</script>
