import WorkflowInstanceLogsFormEdit from './form/WorkflowInstanceLogsFormEdit.vue'
import WorkflowInstanceLogsFormCreate from './form/WorkflowInstanceLogsFormCreate.vue'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import { Resources } from '../../types'
import { ActionFormTypes } from '@/tt-app-modular'
const resourceName = Resources.WORKFLOW_INSTANCE_LOGS
export default {
  install(Vue) {
    Vue.component(
      'WorkflowInstanceLogsFormCreate',
      WorkflowInstanceLogsFormCreate,
    )
    Vue.component('WorkflowInstanceLogsFormEdit', WorkflowInstanceLogsFormEdit)

    registerResourceForm(
      resourceName,
      'WorkflowInstanceLogsFormCreate',
      ActionFormTypes.CREATE,
    )
    registerResourceForm(
      resourceName,
      'WorkflowInstanceLogsFormEdit',
      ActionFormTypes.EDIT,
    )
  },
}
