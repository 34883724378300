import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  'contract',
  'account',
  'customId',
  'taxClass',
  'chargeType',
] as ColumnInputDefinition[]
