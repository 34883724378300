/**
 * Convert time string (HH:mm:ss) to seconds.
 *
 * Will throw an error if the time string is invalid.
 */
export const convertTimeToSeconds = (time: string): number => {
  const toNumber = (value: string): number => parseInt(value, 10)

  const [hour, minute, second] = time.split(':').map(toNumber)

  const seconds = hour * 3600 + minute * 60 + second

  if (isNaN(seconds)) {
    console.warn(`Invalid time format: "${time}"`)
    return 0
  }

  return seconds
}
