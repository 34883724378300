// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OvertimeRulesTile from './OvertimeRulesTile.vue'
import OvertimeRulesColumns from './OvertimeRulesColumns'
import OvertimeRulesDetail from './OvertimeRulesDetail.vue'
import OvertimeRulesReference from './OvertimeRulesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OvertimeRulesTile', OvertimeRulesTile)
    Vue.component('OvertimeRulesDetail', OvertimeRulesDetail)
    Vue.component('OvertimeRulesReference', OvertimeRulesReference)

    modularManager.addResourcePreset(
      Resources.OVERTIME_RULES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OvertimeRulesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OvertimeRulesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OvertimeRulesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OVERTIME_RULES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OvertimeRulesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
