import { FormOptions } from '@tracktik/tt-json-schema-form'
import {
  AttributeFilterInterface,
  FieldTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import { DefaultToolbar } from '@/tt-widget-components'
import { WidgetDownloadType } from '@/tt-widget-components/types'

const closedCollapsibleObject = {
  view: {
    props: {
      showHeader: true,
      collapsible: true,
      opened: false,
    },
  },
}
const openedCollapsibleObject = {
  view: {
    props: {
      showHeader: true,
      collapsible: true,
      opened: true,
    },
  },
}

export default {
  definitions: {
    ContextFiltersMap: {
      view: {
        is: 'ContextAttributeMapEditForm',
      },
    },
    DefaultToolbar: {
      view: {
        is: 'DataToolbarInput',
        props: {
          defaultSchema: {
            show: true,
            displayCounts: true,
            filterOptions: {
              allowSearch: true,
              filters: [],
            },
          } as DefaultToolbar,
        },
      },
    },
    TQLToolbar: {
      view: {
        is: 'DataToolbarInput',
        props: {
          defaultSchema: {
            show: true,
            displayCounts: true,
            filterOptions: {
              allowSearch: false,
              filters: [],
            },
          } as DefaultToolbar,
        },
      },
    },
    AggregationToolbar: {
      view: {
        is: 'DataToolbarInput',
        props: {
          defaultSchema: {
            show: false,
            displayCounts: false,
            filterOptions: {
              allowSearch: false,
              filters: [],
            },
          } as DefaultToolbar,
        },
      },
    },
    FilterOptions: {
      skip: ['allowIncludeInactive', 'allowScopes', 'allowWhereQL'],
    },
    DisableClick: {
      view: {
        is: 'DisableClickViewDetailsSwitch',
      },
    },
    EnableColumnHeaderFilters: {
      view: {
        is: 'EnableColumnHeaderFiltersSwitch',
      },
    },
    Dimension: {
      view: {
        is: 'ResourceDimensionSelector',
      },
    },
    AggregationSort: {
      view: {
        is: 'DataSetSortInput',
      },
    },
    Sort: {
      view: {
        is: 'QuerySortInput',
      },
    },
    SortDirection: {
      view: {
        is: 'SortDirectionInput',
      },
    },
    TQLStatement: {
      view: {
        is: 'TqlEditor',
      },
    },
    WhereQL: {
      view: {
        is: 'WhereQLWidgetFormInput',
      },
    },
    ColorPalette: {
      view: {
        is: 'ColorPaletteSelector',
      },
    },
    ResourceScope: {
      view: {
        is: 'ResourceScopeSelector',
      },
    },
    Color: {
      view: {
        is: 'ColorSelector',
      },
    },
    ColumnChartOptions: openedCollapsibleObject,
    MetricTableOptions: openedCollapsibleObject,
    PieChartOptions: openedCollapsibleObject,
    ResourceGeoMappingOptions: {
      view: {
        props: {
          showHeader: false,
        },
      },
    },
    TQLQueryOptions: {
      view: {
        props: {
          collapsible: true,
          opened: false,
          showHeader: false,
        },
      },
    },
    TQLQueryWidget: {
      properties: {
        options: {
          props: {
            showHeader: false,
          },
        },
      },
    },
    GaugeChartWidgetOptions: openedCollapsibleObject,
    MapOptions: closedCollapsibleObject,
    HorizontalChartOptions: openedCollapsibleObject,
    LineChartOptions: openedCollapsibleObject,
    FilterGroup: {
      ...closedCollapsibleObject,
    },
    Measure: {
      view: {
        is: 'ResourceMeasureSelector',
      },
    },
    Resource: {
      view: {
        is: 'ResourceSelector',
      },
    },
    ResourceAttributeName: {
      view: {
        is: 'ResourceAttributeSelector',
        props: {},
      },
    },
    GeoResourceAttributeName: {
      view: {
        is: 'ResourceAttributeSelector',
        props: {
          filter: (attributeFilter: AttributeFilterInterface) => {
            return [
              FieldTypes.GeoPositionLongitude,
              FieldTypes.GeoPositionLatitude,
              FieldTypes.GeoPosition,
            ].includes(attributeFilter.attribute.type)
          },
        },
      },
    },
    RichText: {
      view: {
        is: 'RichTextInput',
      },
    },
    RootLevelWidget: {
      skip: ['uid', 'description'],
      properties: {
        title: {
          is: 'WidgetEditTitle',
        },
      },
    },
    CollectionQuery: {
      properties: {
        includeInactive: {
          is: 'IncludeInactiveInput',
        },
        scope: {
          is: 'ResourceScopeSelector',
          props: {},
        },
        filters: {
          is: 'AttributeFilterInput',
          props: {
            collection: true,
          },
        },
        customFilters: {
          is: 'CustomFilterInput',
          props: {
            collection: true,
          },
        },
      },
      view: {
        is: 'CollectionQueryForm',
      },
    },
    DataTableOptions: {
      view: {
        is: 'DataTableOptions'
      }
    },
    AllowDownloadCsvPdf: {
      view: {
        is: 'WidgetAllowDownloadField',
        props: {
          supportedTypes: [WidgetDownloadType.PDF, WidgetDownloadType.CSV]
        },
      },
    },
    AllowDownloadAll: {
      view: {
        is: 'WidgetAllowDownloadField',
        props: {
          supportedTypes: [
            WidgetDownloadType.PDF,
            WidgetDownloadType.CSV,
            WidgetDownloadType.XLS
          ],
        },
      },
    },
  },
} as FormOptions
