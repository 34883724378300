<template>
  <v-row class="fill-height">
    <v-col class="fill-height">
      <v-sheet class="fill-height text-center transparent">
        <v-icon size="400" color="grey lighten-3">mdi-magnify</v-icon>
        <h1
          style="font-weight: 100"
          class="pa-5"
          v-text="$t('common.no_results')"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'NoSearchResultsDashboardView',
})
</script>
