<template>
  <div>
    <json-field name="type" :additional-query-options="typeQueryOptions" />
    <json-field name="description" />
    <json-field name="date" />
    <json-field name="account" />
    <json-field name="impactRunsheet" />
    <json-field name="impactSchedule" />
    <json-field name="impactSiteTask" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { rootExceptionTypeId } from '../exception-types/exception-types-parent'

const TYPE_FILTER = [rootExceptionTypeId.Closed].map((typeId) =>
  typeId.toString(),
)
export default Vue.extend({
  computed: {
    typeQueryOptions(): EntityCollectionRequestOptions {
      return {
        filters: [
          {
            attribute: 'id',
            operator: 'IN',
            value: TYPE_FILTER,
          },
        ],
      }
    },
  },
})
</script>
