import WidgetErrorNewVersionAvailable from '@/tt-widget-components/components/WidgetErrorNewVersionAvailable.vue'

/**
 * Error handler used when lazy-loading a component. It returns an information banner that indicates the page needs to be refreshed.
 *
 * It can happen :
 * - On the CDN when a new version is pushed, the previous version files are removed. So the app currently loaded tries to fetch inexistent files.
 * - Locally durring development if the local server was stopped or restarted, and the page was not refreshed.
 */
export const handleAsyncComponentError = (error: unknown) => {
  console.warn(
    'Error while fetching async component. Files on server might have been updated. Try to refresh your page.',
    error,
  )
  return WidgetErrorNewVersionAvailable
}
