<template>
  <div style="min-height: 400px">
    <WidgetFactory :widget="computedWidget" :props-data="widgetProps" />
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import Vue, { VueConstructor, PropType } from 'vue'

import {
  DataTableWidgetModel,
  Filter,
  PieChartWidgetModel,
  WidgetModels,
} from '@/tt-widget-components'

import { ItemHookProvider } from '../types'

const isCollectionWidget = (widget): widget is DataTableWidgetModel =>
  !!widget.query
const isAggregationWidget = (widget): widget is PieChartWidgetModel =>
  !!widget.dataSet

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityItemWidget',
  inject: ['getItemHook'],
  props: {
    /**
     * Attribute in the widget's resource that has the relation to the
     * itemHook's resource
     */
    filterAttribute: { type: String, required: true },
    /**
     * Attribute in the itemHook's resource that defined the value the widget's
     * resource is filtered by
     */
    valueAttribute: { type: String, default: 'id' },
    /**
     * Widget model
     */
    widget: { type: Object as PropType<WidgetModels>, required: true },
    /**
     * Props forward to the widget component
     */
    widgetProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  computed: {
    computedWidget(): WidgetModels {
      const widget = cloneDeep(this.widget)

      const value =
        this.valueAttribute === 'id'
          ? this.getItemHook().entityReference.entityId
          : this.getItemHook().get(this.valueAttribute)

      const newFilter: Filter = {
        attribute: this.filterAttribute,
        operator: 'EQUAL',
        value,
      }

      if (isCollectionWidget(widget)) {
        const filters = widget.query.filters || []
        widget.query.filters = [...filters, newFilter]
        return widget
      }

      if (isAggregationWidget(widget)) {
        const filters = widget.dataSet.filters?.filters || []
        widget.dataSet.filters = {
          ...widget.dataSet.filters,
          filters: [...filters, newFilter],
        }
        return widget
      }

      console.warn('Widget is not a collection or aggregation widget')
      return undefined
    },
  },
})
</script>
