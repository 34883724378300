import i18n from '@/plugins/i18n'

import cloneDeep from 'lodash/cloneDeep'

import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import {
  ColumnChartOptions,
  DataSetDimension,
  DataSetMeasure,
  DataSetValue,
  HorizontalChartOptions,
  LineChartOptions,
  WidgetModels,
} from '@/tt-widget-components'
import { DimensionModifier } from '@/tt-widget-factory'

import { currencyFormat } from '@/tt-widget-components/syncfusion/helpers'
import { MeasureFormat } from '@/tt-widget-components/components/measure-types'
import { dayOfWeekDictionary, monthsDictionary } from '../helpers/dictionaries'

export default class DataSetWidgetHookChart<
  WidgetModel extends WidgetModels,
> extends DataSetWidgetHook<WidgetModel> {
  get color(): string {
    return this.options && this.options.color ? this.options.color : '#512da8ff'
  }

  get options():
    | ColumnChartOptions
    | HorizontalChartOptions
    | LineChartOptions {
    return this.state.widget.options
  }

  /**
   * ** Used in VUE component
   *
   * @param dataSource
   * @param dimension
   * @param measure
   * @param measureFormat
   */
  public formatDataSource(
    dataSource: DataSetValue[],
    dimension: DataSetDimension,
    measure: DataSetMeasure,
    measureFormat: MeasureFormat,
    measureWithCommas = true,
  ): any[] {
    const dataSourceCopy = cloneDeep(dataSource)

    let prefix = ''
    if (this.getDefaultMeasureFormat(measure) === currencyFormat) {
      prefix = '$'
    }

    // Modify the Data Source and add the Tooltip
    dataSourceCopy.forEach((dataSourcePoint) => {
      if (measureFormat !== MeasureFormat.SUFFIX) {
        dataSourcePoint[measure.key] = this.formatMeasureValue(
          dataSourcePoint[measure.key],
          measureFormat,
          false, // chart value must be numeric
        )
      }

      if (dimension.modifier === DimensionModifier.MONTHNUMBER) {
        const month = monthsDictionary[dataSourcePoint[dimension.key]]
        dataSourcePoint[dimension.key] = month ? i18n.t(month) : 'N/A'
      }

      if (dimension.modifier === DimensionModifier.DAYOFWEEKNUMBER) {
        const day = dayOfWeekDictionary[dataSourcePoint[dimension.key]]
        dataSourcePoint[dimension.key] = day ? i18n.t(day) : 'N/A'
      }

      const tooltipValue = this.formatMeasureValue(
        dataSourcePoint[measure.key],
        measureFormat,
        measureWithCommas,
      )

      dataSourcePoint['tooltip'] = `${
        dataSourcePoint[dimension.key]
      }: <b>${prefix}${tooltipValue}</b>`
    })

    return dataSourceCopy
  }
}
