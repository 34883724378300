// Enter your custom installation here

import FileInlineBtnGroupActions from './FileInlineBtnGroupActions.vue'
import FileInlineBtnActions from './FileInlineBtnActions.vue'
import ActionTriggerProvider from './ActionTriggerProvider.vue'

export default {
  install(Vue) {
    Vue.component('ActionTriggerProvider', ActionTriggerProvider)
    Vue.component('FileInlineBtnActions', FileInlineBtnActions)
    Vue.component('FileInlineBtnGroupActions', FileInlineBtnGroupActions)
  },
}
