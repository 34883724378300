<template>
  <div>
    <!-- Detail Card -->

    <tt-preset-view
      type="detailCard"
      class="ma-2"
      :opened="true"
      collapsible
      :title="$t('components.entity-preview.detail')"
    />

    <tt-preset-view
      type="actionsCard"
      class="ma-2"
      collapsible
      :title="$t('components.entity-preview.action')"
      :opened="true"
    />

    <tt-preset-view
      type="metaCard"
      class="ma-2"
      collapsible
      :title="$t('components.entity-preview.more-information')"
    />
    <AppSlot
      :slot-name="`${slotName}.bottom`"
      :props-data="$props"
      class="ma-2"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'EntityDetailCard',
  props: {
    resourceName: { type: String, required: true },
    entityId: { type: Number, required: true },
    entity: { type: Object, default: undefined },
  },
  computed: {
    slotName(): string {
      return `${this.resourceName}.preview`
    },
  },
})
</script>
