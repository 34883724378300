<template>
  <div class="mobile-device--tile">
    <tt-tile :avatar-size="40" class="pb-0">
      <template #avatar>
        <tt-attr
          v-slot="{ rawValue }"
          name="user.avatar"
          class="w-100 fill-height"
        >
          <v-img alt="" :src="rawValue" />
        </tt-attr>
      </template>

      <template #title>
        <tt-attr name="user.name" />
      </template>

      <template #line2>
        <tt-attr name="account" />
      </template>

      <template #line3>
        <div>
          <UserMatchingTaskAssets :assets="userAssets" />
        </div>
      </template>

      <template #actionRight>
        <div class="d-flex">
          <!-- REVIEW LOADER not working -->
          <div class="flex--column text-right pr-3">
            <span class="caption text--secondary" v-text="etaLabel" />

            <div v-if="isLoadingGeodistance" style="position: relative">
              <TLoadingBar />
            </div>
            <template v-else>
              <span class="display1 ttPrimary--text font-weight-bold">
                {{ duration }}
              </span>
              <span class="caption text--secondary">{{ distance }}</span>
            </template>
          </div>
          <MobileDevicesTileActions
            :on-select="onSelect"
            :is-tile-map="isTileMap"
          />
        </div>
      </template>
    </tt-tile>

    <MobileDevicesWorkStatus v-bind="{ workStatus }" class="pt-0 pb-3 px-4" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { Attribute } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'

import UserMatchingTaskAssets from './UserMatchingTaskAssets.vue'
import { DistanceMatrixProvider, ExtensionWorkStatus } from './types'
import { OnSelectCallback } from '../types'

import MobileDevicesWorkStatus from './MobileDevicesWorkStatus.vue'
import MobileDevicesTileActions from './MobileDevicesTileActions.vue'

type MOBILE_DEVICES = typeof Resources.MOBILE_DEVICES

const ATTRS: Record<string, Attribute<MOBILE_DEVICES>> = {
  lastLatitude: 'lastLatitude',
  lastLongitude: 'lastLongitude',
}

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & DistanceMatrixProvider>
).extend({
  name: 'MobileDevicesTileForDispatchAssign',
  components: {
    UserMatchingTaskAssets,
    MobileDevicesWorkStatus,
    MobileDevicesTileActions,
  },
  inject: ['getItemHook', 'distanceMatrixSdk', 'destinationCoordinates'],
  props: {
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
    isTileMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    etaLabel(): string {
      return this.$t('tt-entity-design.dispatch-tasks.assign-action.eta')
    },
    coordinates(): string {
      return `${this.getItemHook().getRawValue(
        ATTRS.lastLatitude,
      )},${this.getItemHook().getRawValue(ATTRS.lastLongitude)}`
    },
    isLoadingGeodistance(): boolean {
      return this.distanceMatrixSdk.isLoading()
    },
    distance(): string {
      return this.distanceMatrixSdk.getFormattedDistance(
        this.coordinates,
        this.destinationCoordinates,
      )
    },
    duration(): string {
      return this.distanceMatrixSdk.getFormattedDuration(
        this.coordinates,
        this.destinationCoordinates,
      )
    },
    userAssets(): { id: number }[] {
      return this.getItemHook().getRawValue('user.checkedOutAssets')
    },
    workStatus(): ExtensionWorkStatus {
      return this.getItemHook().data['workStatus']
    },
  },
  created() {
    this.getItemHook().addAttribute(ATTRS.lastLatitude)
    this.getItemHook().addAttribute(ATTRS.lastLongitude)
    this.getItemHook().addAttribute('user.checkedOutAssets')
    this.getItemHook().addAttribute('user.id')
    this.getItemHook().addAttribute('position.id')
    this.getItemHook().addAttribute('position.account.id')
    this.getItemHook().addAttribute('position.account.type')

    this.getItemHook().addAttribute('extensions.workStatus')
  },
})
</script>

<style scoped>
.mobile-device--tile >>> .v-list-item__action {
  margin: 0 !important;
}
</style>
