import { WidgetCategoryModel } from '@/tt-widget-factory'
import { Any } from 'ts-toolbelt'
import VueRouter from 'vue-router'

export const getCurrentRouteCategory = (
  router: VueRouter,
): WidgetCategoryModel => {
  const routeCategoryUid = router.currentRoute.params.categoryId

  return routeCategoryUid ? WidgetCategoryModel.find(routeCategoryUid) : null
}

/**
 * Read and write `presentationMode` query param
 */
type PresentationMode = { get: () => boolean; exit: () => void }

export const usePresentationMode = (
  router: VueRouter | undefined,
): PresentationMode => {
  const routerHandler: PresentationMode = {
    get: () => router?.currentRoute?.query?.presentationMode === 'true',
    exit: () => {
      // clears from query params
      const { presentationMode: _, ...query } =
        router?.currentRoute?.query || {}
      router.replace({ query })
    },
  }

  const fallbackHandler: PresentationMode = {
    get: () => false,
    exit: () => {},
  }

  return router ? routerHandler : fallbackHandler
}
