<template>
  <v-card class="client-location-card pa-2">
    <div class="d-flex justify-space-between align-baseline mb-1">
      <div class="d-flex align-baseline text-truncate">
        <v-icon color="ttPrimary" size="18" v-text="'mdi-domain'" />
        <span
          class="caption font-weight-medium ml-1 d-inline text-truncate align-baseline"
          v-text="clientName"
        />
      </div>
      <v-chip
        x-small
        label
        class="text-uppercase font-weight-bold ml-2"
        v-text="clientCustomId"
      />
    </div>
    <div v-if="address.length" class="d-flex align-start">
      <v-icon color="ttPrimary" size="20" v-text="iconAddress" />
      <div class="d-flex flex-column text-truncate ml-1">
        <template v-for="(item, index) in address">
          <span
            :key="index"
            class="d-inline text-truncate caption"
            v-text="item"
          />
        </template>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksAddressCard',
  inject: ['getItemHook'],
  props: {
    clientName: { type: String, required: true },
    clientCustomId: { type: [String, Number], required: true },
    iconAddress: { type: String, required: true },
    address: { type: Array, required: true },
  },
})
</script>

<style scoped>
.client-location-card {
  position: relative;
  width: 100%;
  min-width: 250px;
}
</style>
