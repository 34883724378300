<template>
  <v-list dense color="transparent">
    <v-list-item>
      <v-list-item-icon style="margin-right: 10px; margin-top: 10px">
        <slot name="icon" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="margin-right: 10px; margin-top: 5px">
          <span style="font-size: 14px">
            <slot name="title" />
          </span>
        </v-list-item-title>
        <v-list-item-subtitle style="margin-right: 10px">
          <slot name="subtitle" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <slot name="right-action" />
    </v-list-item>
  </v-list>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'WidgetCardInfoList',
})
</script>
