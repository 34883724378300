import { AttributeName, WidgetName } from './names'

export interface IconInterface {
  icon: string
  color: string
}

export function getIconByAttributeName(
  attributeName: AttributeName,
): IconInterface {
  switch (attributeName) {
    case AttributeName.ACCOUNT_ATTRIBUTE:
      return {
        icon: 'mdi-office-building',
        color: 'green',
      }
    case AttributeName.DATE_RANGE_ATTRIBUTE:
      return {
        icon: 'mdi-calendar-range',
        color: 'green',
      }
    case AttributeName.REGION_ATTRIBUTE:
      return {
        icon: 'mdi-earth',
        color: 'green',
      }
    default:
      return {
        icon: '',
        color: '',
      }
  }
}
