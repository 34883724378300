// Enter your custom installation here

import { addResourceDocument } from '@/tt-widget-entity-flow/helper'
import PostOrderDoc from '@/tt-entity-design/src/components/post-orders/PostOrderDoc'
import { Resources } from '@/tt-entity-design/src/types'
import DispatchTaskLocationField from '@/tt-entity-design/src/components/dispatch-tasks/DispatchTaskLocationField.vue'

export default {
  install(Vue) {
    Vue.component('DispatchTaskLocationField', DispatchTaskLocationField)
    addResourceDocument(Resources.POST_ORDERS, PostOrderDoc)
  },
}
