// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PatrolRecordingsTile from './PatrolRecordingsTile.vue'
import PatrolRecordingsColumns from './PatrolRecordingsColumns'
import PatrolRecordingsDetail from './PatrolRecordingsDetail.vue'
import PatrolRecordingsMetaCard from './PatrolRecordingsMetaCard.vue'
import PatrolRecordingsReference from './PatrolRecordingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PatrolRecordingsTile', PatrolRecordingsTile)
    Vue.component('PatrolRecordingsDetail', PatrolRecordingsDetail)
    Vue.component('PatrolRecordingsMetaCard', PatrolRecordingsMetaCard)
    Vue.component('PatrolRecordingsReference', PatrolRecordingsReference)

    modularManager.addResourcePreset(
      Resources.PATROL_RECORDINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PatrolRecordingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PATROL_RECORDINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PatrolRecordingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PATROL_RECORDINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PatrolRecordingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PATROL_RECORDINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PatrolRecordingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PATROL_RECORDINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PatrolRecordingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
