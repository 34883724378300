// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OpenShiftRequestsTile from './OpenShiftRequestsTile.vue'
import OpenShiftRequestsColumns from './OpenShiftRequestsColumns'
import OpenShiftRequestsDetail from './OpenShiftRequestsDetail.vue'
import OpenShiftRequestsMetaCard from './OpenShiftRequestsMetaCard.vue'
import OpenShiftRequestsReference from './OpenShiftRequestsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OpenShiftRequestsTile', OpenShiftRequestsTile)
    Vue.component('OpenShiftRequestsDetail', OpenShiftRequestsDetail)
    Vue.component('OpenShiftRequestsMetaCard', OpenShiftRequestsMetaCard)
    Vue.component('OpenShiftRequestsReference', OpenShiftRequestsReference)

    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OpenShiftRequestsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OpenShiftRequestsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OpenShiftRequestsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'OpenShiftRequestsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OpenShiftRequestsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
