import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { TextClass } from '@/helpers/text/typo'

export default [
  {
    attributeName: 'name',
    component: {
      is: 'AssetNameImportedAttr',
      props: {
        cssClass: TextClass.BOLD,
      },
    },
  },
  'customId',
  {
    attributeName: 'category',
    component: {
      is: 'AssetTypesCategoryAttr',
    },
  },
  'manufacturer',
  'isCheckable',
  'isBundle',
  {
    attributeName: 'beContainedIntoBundle',
    headerText: 'tt-entity-design.asset-hub.be-contained-in-bundle',
  },
  'isSerialNumberUnique',
  {
    attributeName: 'status',
    component: {
      is: 'AssetsStatusAttr',
    },
  },
] as ColumnInputDefinition[]
