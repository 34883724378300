// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTypeFilesTile from './AssetTypeFilesTile.vue'
import AssetTypeFilesColumns from './AssetTypeFilesColumns'
import AssetTypeFilesDetail from './AssetTypeFilesDetail.vue'
import AssetTypeFilesMetaCard from './AssetTypeFilesMetaCard.vue'
import AssetTypeFilesReference from './AssetTypeFilesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetTypeFilesTile', AssetTypeFilesTile)
    Vue.component('AssetTypeFilesDetail', AssetTypeFilesDetail)
    Vue.component('AssetTypeFilesMetaCard', AssetTypeFilesMetaCard)
    Vue.component('AssetTypeFilesReference', AssetTypeFilesReference)

    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_FILES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTypeFilesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_FILES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTypeFilesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_FILES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypeFilesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_FILES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypeFilesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_FILES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTypeFilesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
