// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowStatusTransitionFormsTile from './WorkflowStatusTransitionFormsTile.vue'
import WorkflowStatusTransitionFormsColumns from './WorkflowStatusTransitionFormsColumns'
import WorkflowStatusTransitionFormsDetail from './WorkflowStatusTransitionFormsDetail.vue'
import WorkflowStatusTransitionFormsMetaCard from './WorkflowStatusTransitionFormsMetaCard.vue'
import WorkflowStatusTransitionFormsReference from './WorkflowStatusTransitionFormsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'WorkflowStatusTransitionFormsTile',
      WorkflowStatusTransitionFormsTile,
    )
    Vue.component(
      'WorkflowStatusTransitionFormsDetail',
      WorkflowStatusTransitionFormsDetail,
    )
    Vue.component(
      'WorkflowStatusTransitionFormsMetaCard',
      WorkflowStatusTransitionFormsMetaCard,
    )
    Vue.component(
      'WorkflowStatusTransitionFormsReference',
      WorkflowStatusTransitionFormsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITION_FORMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionFormsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITION_FORMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowStatusTransitionFormsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITION_FORMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionFormsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITION_FORMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionFormsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_STATUS_TRANSITION_FORMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowStatusTransitionFormsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
