<template>
  <tt-tile v-bind="$props">
    <div class="d-flex align-center" :style="{ width: '100%' }">
      <div class="flex-grow-1">
        <v-list-item-title class="pb-1">
          <tt-attr name="triggerField.label" />
        </v-list-item-title>

        <v-list-item-subtitle class="pb-1">
          <tt-attr name="operator" />{{ ' ' }}
          <tt-attr name="value" />
        </v-list-item-subtitle>
      </div>

      <ResourceAllowedOperations
        v-slot="{ allowsDelete }"
        :resource-name="resourceName"
      >
        <div v-if="!readOnly && allowsDelete" class="ml-5">
          <v-btn
            icon
            color="level1Control"
            :title="$t(translationKey('remove-btn'))"
            @click.stop="confirmRemove"
          >
            <v-icon small> mdi-delete-outline </v-icon>
          </v-btn>
        </div>
      </ResourceAllowedOperations>
    </div>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { confirmDeleteEntity } from '../../helpers'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportConditionsTile',
  inject: ['getItemHook'],
  props: {
    readOnly: { type: Boolean, default: false },
  },
  computed: {
    entityId(): number {
      return this.getItemHook().get('id')
    },
    resourceName(): string {
      return this.getItemHook().getResourceName()
    },
    translationScope(): string {
      return `tt-entity-design.${this.resourceName}.tile`
    },
  },
  created() {
    this.getItemHook().addAttribute('id')
    this.getItemHook().needActions()
  },
  methods: {
    confirmRemove(): void {
      const { entityServices, eventManager } = this.$appContext
      const { persister } = entityServices

      confirmDeleteEntity(
        {
          entityId: this.entityId,
          message: this.$t(`${this.translationScope}.confirm-deletion-dialog`),
          resourceName: this.resourceName,
        },
        { eventManager, persister },
      )
    },
    translationKey(key: string): string {
      return `${this.translationScope}.${key}`
    },
  },
})
</script>
