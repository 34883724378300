<template>
  <v-card flat class="escalation-policies--timeline-wrapper pa-2">
    <v-timeline dense>
      <v-timeline-item
        v-for="(escalationRule, index) in escalationRules"
        :key="index"
        fill-dot
        color="ttPrimary"
      >
        <template #icon>
          <span v-text="escalationRule.level" />
        </template>
        <v-card>
          <v-card-title class="subtitle-2">
            {{
              $tc(
                'tt-entity-design.operation-center-escalation-policies.timeline.time-to-escalate',
                escalationRule.timeToEscalate,
                { count: escalationRule.timeToEscalate },
              )
            }}
          </v-card-title>
          <v-card-text>
            <span
              class="mr-2"
              v-text="
                $t(
                  'tt-entity-design.operation-center-escalation-policies.timeline.operation-centers-to-escalate-to',
                )
              "
            />
            <span
              v-text="escalationRule.operationCentersToEscalateTo.join(', ')"
            />
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EscalationPolicies } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OperationCenterEscalationPoliciesTimeline',
  inject: ['getItemHook'],
  computed: {
    escalationRules(): EscalationPolicies[] {
      return this.getItemHook().getRawValue('escalationRules')
    },
  },
  created() {
    this.getItemHook().addAttribute('escalationRules')
  },
})
</script>

<style scoped>
.escalation-policies--timeline-wrapper
  >>> .v-timeline-item--fill-dot
  .v-timeline-item__inner-dot {
  color: white;
}
</style>
