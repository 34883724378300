export type EmployeesSettingsProvider = {
  leaveManagementEmployeeInfo: LeaveManagementEmployeeInfo
}

export type LeaveManagementEmployeeInfo = {
  employee: number
  leavePolicy: number
}

export type ButtonAction = 'remove' | 'add'

export type BalanceUnit = 'days' | 'hours'

export type BalanceOperation = {
  employee: number
  leaveType: number
  description: string
  hours?: number
  days?: number
}

export enum EmployeeOperationsCustomComponents {
  AdjustBalanceDialog = 'AdjustBalanceDialog',
  AvailableDays = 'AvailableDays',
}

export interface AdjustBalanceInfo {
  availableTotalBalance: number
  entitlementUnit: BalanceUnit
  leaveTypeId: number
  leavePolicyId: number
  employeeId: number
  leaveTypeName: string
  isAccrued: boolean
  payHoursPerDay: number
}
