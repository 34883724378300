// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PriceTiersTile from './PriceTiersTile.vue'
import PriceTiersColumns from './PriceTiersColumns'
import PriceTiersDetail from './PriceTiersDetail.vue'
import PriceTiersMetaCard from './PriceTiersMetaCard.vue'
import PriceTiersReference from './PriceTiersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PriceTiersTile', PriceTiersTile)
    Vue.component('PriceTiersDetail', PriceTiersDetail)
    Vue.component('PriceTiersMetaCard', PriceTiersMetaCard)
    Vue.component('PriceTiersReference', PriceTiersReference)

    modularManager.addResourcePreset(
      Resources.PRICE_TIERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PriceTiersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRICE_TIERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PriceTiersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRICE_TIERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PriceTiersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRICE_TIERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PriceTiersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PRICE_TIERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PriceTiersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
