// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import HolidayDatesTile from './HolidayDatesTile.vue'
import HolidayDatesColumns from './HolidayDatesColumns'
import HolidayDatesDetail from './HolidayDatesDetail.vue'
import HolidayDatesReference from './HolidayDatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('HolidayDatesTile', HolidayDatesTile)
    Vue.component('HolidayDatesDetail', HolidayDatesDetail)
    Vue.component('HolidayDatesReference', HolidayDatesReference)

    modularManager.addResourcePreset(
      Resources.HOLIDAY_DATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'HolidayDatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_DATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: HolidayDatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_DATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'HolidayDatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAY_DATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'HolidayDatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
