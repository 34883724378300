<template>
  <div class="d-flex">
    <tt-attr name="endedOn" format="asDate" />
    <TTooltipIcon
      v-if="hasRequestPast"
      :icon="'warning'"
      :icon-color="'ttWarning'"
      :open-direction="'bottom'"
      :tooltip-text="tooltipText"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import TTooltipIcon from '@/tt-ui/components/TTooltipIcon.vue'
import { isDateInPast } from '@/helpers/dates/isDateInPast'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsPastDateWarningColumn',
  components: { TTooltipIcon },
  inject: ['getItemHook'],
  computed: {
    date(): Date {
      return this.getItemHook().getRawValue('endedOn')
    },
    tooltipText(): string {
      return 'tt-entity-design.leave-management.leave-requests.past-date'
    },
    hasRequestPast(): boolean {
      return isDateInPast(this.date)
    },
  },
})
</script>
<style scoped>
.warning-color {
  color: var(--v-ttWarning-base);
  font-weight: bold;
}
</style>
