<template>
  <span v-if="!showField" />
  <v-skeleton-loader v-else-if="attributeLoading" type="text" />
  <v-img v-else :src="value" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'EntityValue',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    name: { type: String, required: true },
  },
  computed: {
    attributeLoading(): boolean {
      return !this.getItemHook().data ? true : this.value === undefined
    },
    absoluteName(): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${this.name}`
        : this.name
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        relationPrefix: this.relationPrefix,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
    value(): string {
      return this.getItemHook().get(this.absoluteName)
    },
  },
  mounted() {
    this.getItemHook().addAttribute(this.absoluteName)
  },
})
</script>
