<template>
  <div class="flex--column h-100">
    <div class="flex--row align-center pa-3">
      <v-icon class="mx-2" color="ttPrimary" v-text="`mdi-comment`" />
      <v-textarea
        v-model="newNoteText"
        class="mt-0"
        filled
        rounded
        rows="1"
        auto-grow
        :placeholder="
          $t('tt-entity-design.system-exception-tickets.add-comment')
        "
        hide-details
      />
      <v-btn
        icon
        color="ttPrimary"
        :disabled="!newNoteText"
        :loading="isSubmitting"
        @click="sendNewNote"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </div>

    <v-divider />

    <WidgetFactory
      class="system-exception-tickets--notes h-100"
      :widget="widget"
      skip-validation
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import { ListWidgetModel } from '@/tt-widget-components'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  Resource,
  Action,
  Attribute,
} from '@/tt-entity-design/src/schema-types'

import CommentsNotesTile from '../../comments/CommentsNotesTile.vue'

const SYSTEM_EXCEPTION_TICKETS: Resource = 'system-exception-tickets'
const NOTES: Attribute<typeof SYSTEM_EXCEPTION_TICKETS> = 'comments'
// @ts-ignore remove after schema update https://tracktik.atlassian.net/browse/FE-1372
const ADD_COMMENT: Action<typeof SYSTEM_EXCEPTION_TICKETS> = 'add-comment'

const COMMENTS: Resource = 'comments'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsNotesListWidget',
  inject: ['getItemHook'],
  data: () => ({
    newNoteText: '',
    isSubmitting: false,
  }),
  computed: {
    SystemExceptionTicketsId(): number {
      return this.getItemHook().getEntityId()
    },
    widget(): ListWidgetModel {
      const orderAttr: Attribute<typeof SYSTEM_EXCEPTION_TICKETS> = 'createdOn'

      return {
        is: 'ListWidget',
        title: createI18nResourceKey(SYSTEM_EXCEPTION_TICKETS, NOTES),
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: CommentsNotesTile,
        },
        query: {
          resource: COMMENTS,
          relationListResource: {
            resource: SYSTEM_EXCEPTION_TICKETS,
            id: this.SystemExceptionTicketsId,
            attribute: NOTES,
          },
          sort: [
            {
              attribute: orderAttr,
              direction: 'DESC',
            },
          ],
        },
        toolbar: {
          show: false,
        },
      }
    },
  },
  methods: {
    async sendNewNote() {
      this.isSubmitting = true
      await updateDOM()

      await this.$appContext.entityServices.persister.executeEntityAction(
        SYSTEM_EXCEPTION_TICKETS,
        ADD_COMMENT,
        this.SystemExceptionTicketsId,
        {
          comment: this.newNoteText,
        },
      )

      this.isSubmitting = false
    },
  },
})
</script>

<style scoped>
/* removes border added by List Widget */
.system-exception-tickets--notes >>> .list-item-div {
  border: 0 !important;
}

/* removes the separator */
.system-exception-tickets--notes >>> .list-item-div > hr {
  display: none !important;
}

/* hide widget title at the top */
.system-exception-tickets--notes >>> .widget-title {
  display: none !important;
}

.system-exception-tickets--notes {
  padding-top: 1em;
}

.v-textarea >>> .v-input__slot {
  min-height: 0 !important;
}

.v-textarea >>> textarea {
  margin-top: 4px !important;
}
</style>
