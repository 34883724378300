<template>
  <FilterDateTime
    hide-timezone
    hide-presets
    :start-date="startDate"
    :end-date="endDate"
    :time-start="timeStart"
    :time-end="timeEnd"
    @new-date-range="setDateRange"
    @update:time-start="setStartTime"
    @update:time-end="setEndTime"
  />
</template>
<script lang="ts">
import Vue from 'vue'
import FilterDateTime from '@/tt-entity-filter/components/FilterDateTime.vue'
import { parseBetweenFilter } from '@/tt-entity-filter/temporal-filters/TemporalFilterManager/utils'

export default Vue.extend({
  name: 'FilterDateTimeCustomFilterAdapter',
  components: {
    FilterDateTime,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      timeStart: '00:00:00',
      timeEnd: '00:00:00',
    }
  },
  watch: {
    value: {
      handler: 'parseDateRange',
      immediate: true,
    },
  },
  methods: {
    parseDateRange(newValue: string) {
      if (!newValue) {
        return
      }

      const { fromDate, toDate, fromTime, toTime } = parseBetweenFilter(
        newValue,
        '',
      )
      this.startDate = fromDate
      this.endDate = toDate
      this.timeStart = fromTime
      this.timeEnd = toTime
    },
    setDateRange(newRange: string[]) {
      const [first, second] = newRange
      this.startDate = first
      this.endDate = second

      this.emitUpdate()
    },
    setStartTime(time: string) {
      this.timeStart = time
      this.emitUpdate()
    },
    setEndTime(time: string) {
      this.timeEnd = time
      this.emitUpdate()
    },
    emitUpdate() {
      if (!this.startDate || !this.endDate) {
        return
      }
      const newRange = `${this.startDate} ${this.timeStart}|${this.endDate} ${this.timeEnd}`

      this.$emit('input', newRange)
    },
  },
})
</script>
