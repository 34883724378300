import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

import MapMultiMarkersWidgetHook from './MapMultiMarkersWidgetHook'
import { MapMultiMarkersWidgetSchema } from './schema'

/**
 * A map widget that can take multiple "marker collections" configuration to render multiple markers.
 */
export const MapMultiMarkersDefinition: WidgetTypeDefinition = {
  name: WidgetName.MAP_MARKERS_WIDGET,
  schema: MapMultiMarkersWidgetSchema,
  component: {
    template: () => import('./MapMultiMarkersWidget.vue'),
    hook: MapMultiMarkersWidgetHook,
  },
  config: {
    icon: 'mdi-map',
    print: false,
    color: '#ff5d49',
    thumbnail: require('./maps.png'),
  },
}
