<template>
  <div class="ma-4">
    <v-card-actions class="text-center">
      <v-btn class="mx-auto" text @click="show = !show">
        {{ expandButton.text }}
        <v-icon right v-text="expandButton.icon" />
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-card max-width="800" class="mx-auto" outlined>
          <v-app-bar flat dense class="font-weight-light">
            {{ $t(`widgets.error_details.select_one_filter`) }}
          </v-app-bar>

          <div v-if="commonFilters.length">
            <v-toolbar flat dense color="transparent">
              <v-icon color="grey" left small v-text="`mdi-filter-variant`" />
              <span
                class="text-body-2"
                v-text="$t(`components.resource_scope_selector.scope_filters`)"
              />
            </v-toolbar>

            <v-container
              class="text-subtitle-2 px-6 pt-0"
              v-html="commonFilters"
            />
          </div>

          <v-divider
            v-if="commonFilters.length && attributeFilters.length"
            class="mx-3"
          />

          <div v-if="attributeFilters.length">
            <v-toolbar flat dense color="transparent">
              <v-icon color="grey" left small v-text="`mdi-filter-variant`" />
              <span
                class="text-body-2"
                v-text="$t(`widgets.attribute_filters`)"
              />
            </v-toolbar>

            <v-container
              class="text-subtitle-2 px-6 pt-0"
              v-html="attributeFilters"
            />
          </div>
        </v-card>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { APIErrorRequiredFilters } from '@/tt-widget-components/lib/api-error-adapter'
import {
  ResourceTranslator,
  translateScope,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

const htmlDotSeparator = ' &#8226; '

export default Vue.extend({
  name: 'WidgetErrorRequiredFilter',
  props: {
    error: {
      type: Object as PropType<APIErrorRequiredFilters>,
      required: true,
    },
  },
  data: () => ({
    show: false,
  }),
  computed: {
    resource(): string {
      return this.error.extraInfo.requiredFiltersGroup.resource
    },
    commonFilters(): string {
      const scopes = this.error.extraInfo.requiredFiltersGroup.scopes
      const translate = (scope) => translateScope(this.resource, scope)

      return scopes.map(translate).join(htmlDotSeparator)
    },
    attributeFilters(): string {
      const error: APIErrorRequiredFilters = this.error
      const fields = error.extraInfo.requiredFiltersGroup.fields
      const translate = (attr) =>
        ResourceTranslator.translateAttribute(
          this.resource,
          attr,
          FormLabelTypes.LABEL,
        )

      return fields.map(translate).join(htmlDotSeparator)
    },
    expandButton(): { text: string; icon: string } {
      return {
        text: this.$t(
          `widgets.error_details.expand_btn.${this.show ? `hide` : `show`}`,
        ) as string,
        icon: `mdi-chevron-${this.show ? 'up' : 'down'}`,
      }
    },
  },
})
</script>

<style scoped>
div.v-sheet.v-card.v-sheet--outlined.theme--dark {
  border: thin solid #d3d3d338;
}
</style>
