// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointScansTile from './CheckpointScansTile.vue'
import CheckpointScansColumns from './CheckpointScansColumns'
import CheckpointScansDetail from './CheckpointScansDetail.vue'
import CheckpointScansReference from './CheckpointScansReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('CheckpointScansTile', CheckpointScansTile)
    Vue.component('CheckpointScansDetail', CheckpointScansDetail)
    Vue.component('CheckpointScansReference', CheckpointScansReference)

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_SCANS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointScansTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_SCANS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointScansColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_SCANS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointScansDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_SCANS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointScansReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
