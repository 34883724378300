export const enforceLeadingZero = (value: number): string => {
  const leadingDigit = value < 10 ? '0' : ''
  return `${leadingDigit}${value}`
}

export const convertToTimeString = (
  hour = 0,
  minute = 0,
  second = 0,
): string => {
  const formattedHour = enforceLeadingZero(hour)
  const formattedMinute = enforceLeadingZero(minute)
  const formattedSecond = enforceLeadingZero(second)
  return `${formattedHour}:${formattedMinute}:${formattedSecond}`
}
