import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'region',
  'scheduleCycleFirstDate',
  'schedulePreferredPublishFrequency',
  'payrollHourlyRate',
  'payrollSchedule',
  'payrollOvertimeRule',
] as ColumnInputDefinition[]
