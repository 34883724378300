<template>
  <CollapsibleHeader
    v-if="resource"
    :title="$t('components.data_toolbar_filter_input.attribute_filters')"
    opened
  >
    <ResourceAttributeSelector
      v-model="attributeSelection"
      :resource="resource"
      :filter="isNotAlreadySelected"
      :label="$t('components.data_toolbar_filter_input.search')"
      prepend-icon="add"
      permanent-hint
      hint=""
      placeholder=""
      @change="changeAttributeSelection"
    />
    <div>
      <v-list v-if="attributesFilters.length" dense>
        <span
          v-for="(item, i) in attributesFilters"
          :key="`${item.text} - ${i}`"
        >
          <v-list-item dense>
            <v-list-item-icon>
              <v-icon :color="item.color" v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.text)" />
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn small icon @click="removeFilterAtPosition(i)">
                <v-icon small v-text="`close`" />
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
        </span>
      </v-list>
      <CaptionBox
        v-else
        v-text="$t(`components.custom_filter_input.no_filters_selected`)"
      />
    </div>
  </CollapsibleHeader>
</template>

<script lang="ts">
import { PropType } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import BaseArrayInput from '@/tt-widget-components/components/BaseArrayInput'
import { AddAttributeFilterInToolbar } from '@/plugins/o11n'
import { AttributeFilterInterface } from '@/tt-widget-factory/services/resource-meta/types'
import { AttributeFilterItem, FilterSetting } from '@/tt-entity-filter/types'
import { isAttributeFilterable } from '@/tt-entity-filter/type-map'
import {
  getResourceAttributesForList,
  ResourceAttributeSelectorFilter,
  ResourceAttributeSelectorItem,
} from '../../../lib/attribute-list'
import { ResourceAttributeName } from '../../../schemas-types'

export default BaseArrayInput.extend({
  name: 'DataToolbarAttributeFilterInput',
  props: {
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<FilterSetting[]>,
      default: () => [],
    },
  },
  data() {
    return {
      attributeSelection: null,
    }
  },
  watch: {
    attributeSelection(val) {
      if (!val) {
        return
      }
      this.model.push({ attributeName: val })

      this.$nextTick(() => {
        this.attributeSelection = null
      })
    },
  },
  methods: {
    changeAttributeSelection(val: ResourceAttributeName | null): void {
      const eventLabel = val || ''
      const event = AddAttributeFilterInToolbar.create({ label: eventLabel })
      this.$analytics.track(event)
    },
    removeFilterAtPosition(i: number): void {
      const filters = cloneDeep(this.model)
      filters.splice(i, 1)
      this.model = [...filters]
    },
  },
  computed: {
    filterAttributeNames(): string[] {
      return this.model.map((item: AttributeFilterItem) => item.attributeName)
    },
    isNotAlreadySelected(): ResourceAttributeSelectorFilter {
      const selectedAttributes = this.filterAttributeNames

      return (attr: AttributeFilterInterface): boolean =>
        isAttributeFilterable(attr.attribute) &&
        !selectedAttributes.includes(attr.absoluteName)
    },
    emitNullOnDefault(): boolean {
      return true
    },
    attributesFilters(): ResourceAttributeSelectorItem[] {
      const names = [...this.filterAttributeNames]

      if (!this.model?.length) {
        return []
      }

      const filterCurrentlySelectedAttr = (attr: AttributeFilterInterface) =>
        names.includes(attr.absoluteName)

      const byAttrName = (
        a: ResourceAttributeSelectorItem,
        b: ResourceAttributeSelectorItem,
      ) => (names.indexOf(a.value) > names.indexOf(b.value) ? 1 : -1)

      return getResourceAttributesForList(
        this.$appContext.widgetServices.resourceMetaManager,
        this.resource,
        filterCurrentlySelectedAttr,
      ).sort(byAttrName)
    },
  },
})
</script>
