import Vue, { PropType } from 'vue'
import { uniqueId } from 'lodash'
import {
  WidgetContainerInterface,
  WidgetHookInterface,
} from '@/tt-widget-factory'

export default Vue.extend({
  props: {
    hook: {
      type: Object as PropType<WidgetHookInterface>,
    },
    container: {
      type: Object as PropType<WidgetContainerInterface>,
    },
  },
  data() {
    return {
      uniqueId: uniqueId('widget'),
    }
  },
})
