<template>
  <div>
    <v-toolbar dense short flat class="toolbar3">
      <slot name="before" />

      <slot name="title" :title="title">
        <v-toolbar-title class="text-capitalize text-body-1 pl-2">
          <span v-text="$t(title)" />
        </v-toolbar-title>
      </slot>

      <v-flex class="text-right">
        <v-spacer />

        <slot />
      </v-flex>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PageTitleBar',
  props: {
    title: { type: String, required: true },
  },
})
</script>
