<template>
  <CollapsibleHeader>
    <template #title>
      <span class="ml-4">
        <span v-text="$t('widget_edit.default_sort.title')"></span>
        <v-badge v-if="!!model" color="green" dot inline />
      </span>
    </template>
    <div class="text-center align-center mb-5">
      <DataSetSortInput v-model="model" />
    </div>
  </CollapsibleHeader>
</template>

<script lang="ts">
import BaseArrayInput from './BaseArrayInput'

export default BaseArrayInput.extend({
  name: 'DataSetSortObjectInput',
})
</script>
