<template>
  <TAvatar
    :avatar="item.avatar"
    :is-colored-avatar="isColoredAvatar"
    :tag="tag"
    :size="size"
    :color="color"
  />
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { UserGroupItem } from '../types'
import TAvatar from '@/tt-ui/components/TAvatar.vue'

export default Vue.extend({
  name: 'SharableUserGroupAvatar',
  components: {
    TAvatar,
  },
  props: {
    color: {
      type: String,
      default: 'green',
    },
    tag: {
      type: String,
      default: 'v-avatar',
    },
    size: {
      type: Number,
      default: 32,
    },
    item: {
      type: Object as PropType<UserGroupItem>,
      required: true,
    },
  },
  computed: {
    isColoredAvatar(): boolean {
      if (!this.item) {
        return false
      }
      return !this.item.avatar || this.item.type !== 'USER'
    },
  },
})
</script>
