<template>
  <div class="d-flex flex-column fill-height">
    <header class="py-3 px-6">
      <h6 class="text-h6" v-text="$t(leaveManagementTitle)" />
    </header>
    <div v-if="isTranslationLoaded && isReady" id="setup-view">
      <v-toolbar class="toolbar3 text-capitalize py-0 px-6" flat short>
        <v-tabs
          v-if="!isXsmall"
          v-model="selectedTab"
          show-arrows
          slider-color="ttPrimary"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title"
            class="text-capitalize px-2 mr-6"
            active-class="active-icon"
            @click="closeSidePanel"
          >
            <v-icon small left>
              {{ tab.icon }}
            </v-icon>
            {{ $t(tab.title) }}
          </v-tab>
        </v-tabs>
        <template v-if="isXsmall" #extension>
          <v-tabs
            v-model="selectedTab"
            slider-color="var(--v-ttPrimary-base)"
            show-arrows
            align-with-title
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="$t(tab.title)"
              active-class="active-icon"
              class="text-capitalize px-2 mr-6"
              @click="closeSidePanel"
            >
              <v-icon small left>
                {{ tab.icon }}
              </v-icon>
              {{ $t(tab.title) }}
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer />
        <ResourceAllowedOperations
          v-slot="{ allowsCreation }"
          :resource-name="tabs[selectedTab].resource"
        >
          <v-btn
            v-if="allowsCreation"
            depressed
            color="var(--v-ttPrimary-base)"
            dark
            class="ml-2"
            style="text-transform: none"
            data-cy="create-button"
            @click="dispatchCreationForm"
          >
            <v-icon left> mdi-plus </v-icon>
            {{ tabs[selectedTab].createButtonText }}
          </v-btn>
        </ResourceAllowedOperations>
      </v-toolbar>
    </div>
    <div class="setup-view--main-content">
      <v-skeleton-loader v-if="!isReady" type="table" />
      <WidgetFactory
        v-if="isReady"
        :key="tabs[selectedTab].resource"
        :widget="tabs[selectedTab].listWidget"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'
import Vue, { VueConstructor } from 'vue'
import { LeaveManagementTab } from '@/tt-widget-views/leave-management/types'
import {
  createLeaveManagementTab,
  CreateLeaveManagementTabInterface,
} from '@/tt-widget-views/leave-management/LeaveManagementHelper'
import LeavePolicyItemsCustomColumns from './leave-policy-items/LeavePolicyItemsCustomColumns'
import { Resources } from '@/tt-entity-design/src/types'
import LeavePoliciesCustomColumns from './leave-policies/LeavePoliciesCustomColumns'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import isEmpty from 'lodash/isEmpty'

export default (
  Vue as VueConstructor<
    Vue & ActiveWindowProvider & { layoutManager: LayoutManager }
  >
).extend({
  name: 'SetupView',
  inject: ['activeWindow', 'layoutManager'],
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    isTranslationLoaded(): boolean {
      return !isEmpty(this.$i18n.messages)
    },
    isXsmall(): boolean {
      return this.$vuetify.breakpoint.xs
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    leaveManagementTitle(): string {
      return 'tt-entity-design.leave-management.label'
    },
    tabs(): LeaveManagementTab[] {
      const leavePoliciesTab: CreateLeaveManagementTabInterface = {
        title: 'tt-entity-design.leave-management.leave-policies.title',
        icon: 'mdi-calendar-text',
        resource: Resources.LEAVE_POLICIES,
        customColumns: LeavePoliciesCustomColumns,
        createButtonText: this.$t(
          'tt-entity-design.leave-management.create-policy',
        ),
        allowActions: true,
      }
      const leavePolicyItemsTab: CreateLeaveManagementTabInterface = {
        title: 'tt-entity-design.leave-management.leave-policy-items.title',
        icon: 'mdi-clock-outline',
        resource: Resources.LEAVE_POLICY_ITEMS,
        customColumns: LeavePolicyItemsCustomColumns,
        createButtonText: this.$t(
          'tt-entity-design.leave-management.create-policy-item',
        ),
      }
      return [leavePoliciesTab, leavePolicyItemsTab].map<LeaveManagementTab>(
        (tab: CreateLeaveManagementTabInterface) =>
          createLeaveManagementTab(tab),
      )
    },
  },
  methods: {
    dispatchCreationForm() {
      this.closeSidePanel()
      this.activeWindow.next({
        title: this.leaveManagementTitle,
        is: this.tabs[this.selectedTab].creationForm,
      })
    },
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
  },
})
</script>

<style scoped>
/* 
  Overriding default behaviour for flex items min-width: auto.
  This will allow the tabs to adapt the content properly, see: https://stackoverflow.com/a/36247448
*/
#setup-view {
  min-width: 0;
}

.active-icon .v-icon {
  color: var(--v-ttPrimary-base) !important;
}

.setup-view--main-content {
  height: 100%;
  min-height: 0;
}
</style>
