import { Filter } from '@/tt-widget-components'
import { isEmpty } from '@/helpers/isEmpty'
import { ResourceFiltersMap } from './types'

/**
 * Convert resource filter query params to a resource filters map consumable by
 * the context manager.
 *
 * ex: { "payroll-payrun-items.payrun.id": "199" }
 * becomes: {"payroll-payrun-item":[{ attribute:"payrun.id", operator: "EQUAL", value: "199" }]}
 */
export function queryStringToResourceFiltersMap(
  queryString: Record<string, string | string[]>,
): ResourceFiltersMap {
  const filterMap: ResourceFiltersMap = {}

  if (isEmpty(queryString)) {
    return filterMap
  }
  Object.keys(queryString).forEach((key: string) => {
    const value = queryString[key]
    const parts = key.split('.')
    // Not a valid format
    if (parts.length < 2) {
      return
    }
    const resource = parts.shift()
    const attribute = parts.join('.')

    // Split to find an operator
    const operatorParts = attribute.split(':')

    // Not a valid format
    if (operatorParts.length > 2) {
      return
    }
    // Get the operator
    const operator =
      operatorParts.length === 2 ? operatorParts[1].toUpperCase() : 'EQUAL'

    const filter = { attribute: operatorParts[0], operator, value } as Filter
    filterMap[resource] = filterMap[resource] ?? []
    filterMap[resource].push(filter)
  })

  return filterMap
}
