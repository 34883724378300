<template>
  <div>
    <v-autocomplete
      v-model.number="model"
      autocomplete="off"
      :label="label"
      outlined
      dense
      :placeholder="placeholder"
      :items="regions"
      :readonly="regions.length <= 1"
      v-on="$listeners"
    />
  </div>
</template>
<script lang="ts">
import BaseNumberInput from '@/tt-widget-components/components/BaseNumberInput'

export default BaseNumberInput.extend({
  name: 'RegionSelectorField',
  data() {
    return {
      regions: [] as { text: string; value: number }[],
    }
  },
  async mounted() {
    const regions = this.$auth.getSession().profile.scopes.regions

    this.regions = regions.map(({ id, name }) => ({
      text: name,
      value: parseInt(id),
    }))
  },
})
</script>
