<template>
  <div>
    <tt-attr name="rangeStartTime" prepend-icon="mdi-clock-outline" />
    <tt-attr
      v-if="isCompleted"
      name="session.effectiveDuration"
      prefix="("
      suffix=")"
    />
    <tt-attr v-else name="rangeEndTime" prefix=" - " hide-empty-value />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

import {
  SiteTaskOccurrenceStatus,
  siteTaskOccurrenceStatus,
} from './site-task-occurrences-status'

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskOccurrenceTime',
  inject: ['getItemHook'],
  computed: {
    isCompleted(): boolean {
      return this.status === siteTaskOccurrenceStatus.COMPLETED
    },
    status(): SiteTaskOccurrenceStatus {
      return this.getItemHook().getRawValue('status')
    },
  },
  mounted() {
    this.getItemHook().addAttribute('rangeEndTime')
    this.getItemHook().addAttribute('session.effectiveDuration')
    this.getItemHook().addAttribute('status')
  },
})
</script>
