import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { FieldConverter, FieldMeta, FieldUI } from '@/tt-fieldset-builder/type'

import schema from './schema.json'
import { LIST, ListFieldType } from './types'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

const ui: FieldUI = {
  name: i18nFieldKey(LIST),
  icon: 'mdi-format-list-bulleted',
  color: 'purple',
}

const converter: FieldConverter = (field: ListFieldType) => ({
  getJsonSchema: () => {
    return {
      ...getBaseFieldJsonSchema(field),
      type: 'string',
      enum: [...field.options.list],
    }
  },
  getViewOption: () => {
    return {}
  },
})

export const ListMeta: FieldMeta = {
  field: LIST,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
