// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveManagementAdjustmentsTile from './LeaveManagementAdjustmentsTile.vue'
import LeaveManagementAdjustmentsColumns from './LeaveManagementAdjustmentsColumns'
import LeaveManagementAdjustmentsDetail from './LeaveManagementAdjustmentsDetail.vue'
import LeaveManagementAdjustmentsReference from './LeaveManagementAdjustmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'LeaveManagementAdjustmentsTile',
      LeaveManagementAdjustmentsTile,
    )
    Vue.component(
      'LeaveManagementAdjustmentsDetail',
      LeaveManagementAdjustmentsDetail,
    )
    Vue.component(
      'LeaveManagementAdjustmentsReference',
      LeaveManagementAdjustmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.LEAVE_MANAGEMENT_ADJUSTMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveManagementAdjustmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_MANAGEMENT_ADJUSTMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveManagementAdjustmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_MANAGEMENT_ADJUSTMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveManagementAdjustmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_MANAGEMENT_ADJUSTMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveManagementAdjustmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
