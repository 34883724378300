//@ts-ignore
import sqlParser from 'js-sql-parser'


export interface TQLJSON {
  model: string
  selects: TQLOperand[] | null
  groupBy: TQLOperand[] | null
  rollUp?: boolean | null
  where: TQLOperand | null
  orderBy: TQLOperand[] | null
  having: TQLOperand | null
  tql: string,
  hasJoins?: boolean,
  hasMultiTables?: boolean,
  hasTableAlias?: boolean
}

export interface SqlParserResponse {
  value: SqlParserBody
}


export interface SqlParserGroupBy extends TQLOperand {
  type: OperandTypes.GroupBy
  rollUp?: boolean
}
export interface SqlParserBody {
  groupBy?: SqlParserGroupBy
  orderBy?: TQLOperand
  selectItems?: TQLOperand
  where?: TQLOperand
  rollUp?: boolean | null
  from: TQLFrom
  having: TQLOperand
}

export interface TQLFrom {
  type: OperandTypes.TableReferences
  value: TQLFromTableReferenceItem[]
}

export interface TQLFromTableReferenceItem {
  type: OperandTypes.TableReference,
  value: TQLFromTableReference
}

export interface TQLFromTableReference {
  type: OperandTypes.InnerCrossJoinTable | OperandTypes.NaturalJoinTable | OperandTypes.StraightJoinTable | OperandTypes.TableFactor
  value: TQLOperand
}

export interface TQLOperand {
  alias?: string
  hasAs?: boolean
  type: OperandTypes
  value?: any | any[]
  name?: string
  left?: any
  right?: any
  params?: TQLOperand,
  operator?: string
}

/**
 * Operand types
 */
export enum OperandTypes {
  Identifier = 'Identifier',
  FunctionCall = 'FunctionCall',
  SelectExpr = 'SelectExpr',
  String = 'String',
  Number = 'Number',
  Null = 'Null',
  FunctionCallParam = 'FunctionCallParam',
  Boolean = 'Boolean',
  IdentifierList = 'IdentifierList',
  WhenThenList = 'WhenThenList',
  CaseWhen = 'CaseWhen',
  SimpleExprParentheses = 'SimpleExprParentheses',
  SubQuery = 'SubQuery',
  IdentifierExpr = 'IdentifierExpr',
  BitExpression = 'BitExpression',
  InSubQueryPredicate = 'InSubQueryPredicate',
  InExpressionListPredicate = 'InExpressionListPredicate',
  LikePredicate = 'LikePredicate',
  BetweenPredicate = 'BetweenPredicate',
  SoundsLikePredicate = 'SoundsLikePredicate',
  RegexpPredicate = 'RegexpPredicate',
  IsNullBooleanPrimary = 'IsNullBooleanPrimary',
  ComparisonBooleanPrimary = 'ComparisonBooleanPrimary',
  ComparisonSubQueryBooleanPrimary = 'ComparisonSubQueryBooleanPrimary',
  BooleanExtra = 'BooleanExtra',
  IsExpression = 'IsExpression',
  NotExpression = 'NotExpression',
  AndExpression = 'AndExpression',
  OrExpression = 'OrExpression',
  XORExpression = 'XORExpression',
  ExpressionList = 'ExpressionList',
  GroupBy = 'GroupBy',
  OrderBy = 'OrderBy',
  GroupByOrderByItem = 'GroupByOrderByItem',
  TableReferences = 'TableReferences',
  InnerCrossJoinTable = 'InnerCrossJoinTable',
  StraightJoinTable = 'StraightJoinTable',
  NaturalJoinTable = 'NaturalJoinTable',
  OnJoinCondition = 'OnJoinCondition',
  UsingJoinCondition = 'UsingJoinCondition',
  TableFactor = 'TableFactor',
  TableReference = 'TableReference',
  Select = 'Select'
}

let parsedCache = {}
/**
 * Parse the statement
 * @param sql
 * @returns {*}
 */
export default function convertTQLToJSON(sql: string): TQLJSON | null {
  try {

    if (parsedCache[sql] ?? false) {
      return parsedCache[sql]
    }
    let parserResponse = sqlParser.parse(sql)
    // Memory management
    if (Object.keys(parsedCache).length > 100) {
      parsedCache = {}
    }
    const parsed: TQLJSON = parseResult(sql, parserResponse.value)
    parsedCache[sql] = parsed
    console.log(parsed)
    return parsed
  } catch (e) {
    console.error(e)
    return null
  }
}

export function convertParserJSONToTQL(query: any): string {
  return sqlParser.stringify({value: query})
}

const parseResult = (tql: string, query: SqlParserBody): TQLJSON | null => {

  if (!tql || tql.trim() == '') {
    return null
  }

  const tableReferences: TQLFromTableReferenceItem[] = query.from.value
  const hasMultiTables: boolean = tableReferences.length > 1
  const mainTableReference: TQLOperand = tableReferences[0].value
  const hasJoins: boolean = mainTableReference.type != OperandTypes.TableFactor
  const mainTableName = !hasJoins ? mainTableReference.value.value : mainTableReference.left.value.value
  const hasTableAlias = !hasJoins && ((mainTableReference.value?.type ?? null) == OperandTypes.Identifier) && mainTableReference.alias != null
  const model = mainTableName?.replace(/_/g, '-') || null
  if (!model) {
    return null
  }

  const selects: TQLOperand[] = query.selectItems?.value ?? null;
  const groupBy: TQLOperand[] = query.groupBy?.value ?? null;
  const where: TQLOperand = query.where ?? null;
  const orderBy: TQLOperand[] = query.orderBy?.value ?? null;
  const having = query.having?.value ?? null
  return {
    tql,
    model,
    selects,
    groupBy,
    orderBy,
    where,
    having,
    hasJoins,
    rollUp: query.groupBy?.rollUp ?? null,
    hasMultiTables,
    hasTableAlias
  }
}


