<template>
  <component :is="containerType">
    <slot v-if="!extensions || !extensions.length"></slot>
    <template v-for="extension in extensions">
      <component
        :is="extension.component.is"
        :key="extension.name"
        :slot-name="slotName"
        v-bind="extension.component.props"
      />
    </template>
  </component>
</template>
<script lang="ts">
import Vue from 'vue'
import { ExtensionInterface, modularManager } from '@/tt-app-modular'

export default Vue.extend({
  name: 'AppExtensionSlotFactory',
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    containerType: {
      type: String,
      default: 'div',
    },
    slotName: {
      type: String,
      default: '',
    },
  },
  computed: {
    extensions(): ExtensionInterface[] {
      if (!modularManager.getSlot(this.slotName)) {
        return []
      }
      // Filter the extensions
      const list = modularManager.getSlot(this.slotName).filter((extension) => {
        // Enabled by default
        if (extension.enabled === undefined) {
          return true
        }
        // Disabled by demand
        if (extension.enabled === false) {
          return false
        }
        if (typeof extension.enabled == 'function') {
          return extension.enabled(extension, this.$vuetify.breakpoint)
        }
        return true
      })
      return this.reverse ? list.reverse() : list
    },
  },
})
</script>
