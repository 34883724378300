import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const SchedulingReportColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'startDateTime',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.startsOn',
  },
  {
    attributeName: 'position.account',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.account',
    component: {
      is: 'ScheduleReportsClickGuard',
      props: {
        attributeName: 'position.account',
      },
    },
  },
  {
    attributeName: 'position',
    component: {
      is: 'ScheduleReportsClickGuard',
      props: {
        attributeName: 'position',
      },
    },
  },
  {
    attributeName: 'employee',
    component: {
      is: 'ScheduleReportsClickGuard',
      props: {
        attributeName: 'employee',
      },
    },
  },
  {
    attributeName: 'plannedDurationHours',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.durationInHours',
  },
  {
    attributeName: 'breakInMinutes',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.breakInMinutes',
  },
  {
    attributeName: 'clockedHours',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.clockedRawHours',
  },
  { attributeName: 'approvedHours' },
  { attributeName: 'attendanceStatus' },
]

export const SchedulingReportDownloadableColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'startDateTime',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.startsOn',
  },
  {
    attributeName: 'position.account.name',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.account',
  },
  {
    attributeName: 'position.name',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.position',
  },
  {
    attributeName: 'employee.name',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.employee',
  },
  {
    attributeName: 'plannedDurationHours',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.durationInHours',
  },
  {
    attributeName: 'breakInMinutes',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.breakInMinutes',
  },
  {
    attributeName: 'clockedHours',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.clockedRawHours',
  },
  {
    attributeName: 'approvedHours',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.approvedHours',
  },
  {
    attributeName: 'attendanceStatus',
    headerText:
      'back-office-reports.scheduling.scheduling-report.columns.attendanceStatus',
  },
]
