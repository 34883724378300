import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { createDocumentColumn } from '@/tt-widget-entity-flow/createDocumentColumn'
import DataViewsDoc from '@/tt-entity-design/src/components/data-views/DataViewsDoc'

export default [
  createDocumentColumn(DataViewsDoc),
  'title',
  'widgetType',
  'description',
] as ColumnInputDefinition[]
