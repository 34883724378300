<template>
  <span>
    <v-icon
      x-small
      style="font-size: 13px; opacity: 0.8"
      color="purple"
      class="mr-1"
    >
      mdi-car
    </v-icon>
    {{ fullName }}
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'VehiclesReference',
  inject: ['getItemHook'],
  computed: {
    fullName(): string {
      return `${this.getValue('make')} - ${this.getValue('model')}`
    },
  },
  created() {
    this.getItemHook().addAttribute('make')
    this.getItemHook().addAttribute('model')
  },
  methods: {
    getValue(attr: string): string {
      return this.getItemHook()?.getRawValue(attr) || ''
    },
  },
})
</script>
