<template>
  <component :is="customComponent" v-if="show && customComponent" />
  <v-navigation-drawer
    v-else-if="show"
    ref="nav"
    v-model="layoutManager.state.sideMenu.state"
    class="primary hidden-print-only app-menu"
    width="200px"
    :mobile-breakpoint="768"
    dark
    disable-route-watcher
    disable-resize-watcher
    :permanent="layoutManager.state.sideMenu.permanent"
    app
  >
    <AppExtensionSlotFactory :slot-name="slotNames.BRANDING" />
    <div>
      <AppExtensionSlotFactory :slot-name="slotNames.SIDE_MENU_TOP" />
      <AppActionMenu
        v-for="(menu, i) in menus"
        :key="`menu-${i}`"
        :menu="menu"
        show-tooltip
      />
      <AppExtensionSlotFactory :slot-name="slotNames.SIDE_MENU_BOTTOM" />
    </div>
  </v-navigation-drawer>
</template>
<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'
import { ActionMenuConfig, LayoutComponents } from '@/tt-app-layout'
import { Component } from 'vue'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutSideMenu',
  data: () => ({
    menus: [] as ActionMenuConfig[],
  }),
  computed: {
    customComponent(): Component {
      return this.layoutManager.configuration?.sideMenu?.is
    },
    show(): boolean {
      return !this.layoutManager.configuration.sideMenu.hidden
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        // @ts-ignore
        if (this.$refs.nav && this.$refs.nav.isMobile) {
          this.layoutManager.setComponentState(LayoutComponents.sideMenu, false)
        }
      },
    },
    'layoutManager.state.sideMenu.state': () => {
      window.dispatchEvent(new Event('resize'))
    },
  },
  async mounted() {
    this.menus = await this.layoutManager.getNavigation()
  },
})
</script>
