<template>
  <tt-attr-row-card>
    <tt-attr-row name="customId">
      <TChip
        attributeName="customId"
        class="grey text--darken-4 lighten-3 text-uppercase"
      />
    </tt-attr-row>
    <tt-attr-row name="dayOfWeekEnd" />
    <tt-attr-row name="occurrenceDate" />
    <tt-attr-row name="rangeStartTime" />
    <tt-attr-row name="rangeEndTime" />
    <tt-attr-row name="durationMinutes" />
    <tt-attr-row name="beginServiceDate" />
    <tt-attr-row name="endServiceDate" />
    <TCustomTextAreaAttr name="description" />
    <TCustomTextAreaAttr name="details" />
    <tt-attr-row name="mobileRunsheetSortOrder" />
    <tt-attr-row name="rangeStartedDateTime" />
    <tt-attr-row name="rangeEndedDateTime" />
    <tt-attr-row name="taskType" />
    <tt-attr-row name="mobileRunsheet" />
    <tt-attr-row name="client" />
    <tt-attr-row name="priceTier" />
    <tt-attr-row name="zonePosition" />
    <tt-attr-row name="reportTemplate" />
    <tt-attr-row name="mobileScheduleOccurrenceLog" />
    <tt-attr-row name="timeZone" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'
import TCustomTextAreaAttr from '../TCustomTextAreaAttr.vue'
import TChip from '../TChip.vue'
export default Vue.extend({
  components: {
    TChip,
    TCustomTextAreaAttr,
  },
})
</script>
