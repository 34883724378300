<template>
  <div>
    <div v-if="loading">
      <v-row v-for="index in 4" :key="`skeleton-${index}`" class="d-flex py-4">
        <v-col cols="7">
          <v-skeleton-loader type="heading" class="py-2" />
        </v-col>
        <v-col cols="5" class="d-flex my-1">
          <v-skeleton-loader type="button" class="flex-grow-1 mr-3" />
          <v-skeleton-loader type="button" class="flex-grow-1 mr-5" />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="hasData">
      <div v-for="timeAdjustment in data" :key="timeAdjustment.id">
        <SiteTaskSchedulesTimeAdjusmentsTile :data="timeAdjustment" />
      </div>
    </div>
    <div v-else>
      <TNoData />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { CollectionQuery } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import SiteTaskSchedulesTimeAdjusmentsTile from './SiteTaskSchedulesTimeAdjusmentsTile.vue'
import TNoData from '@/tt-ui/components/TNoData.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SiteTaskSchedulesTimeAdjustmentsGroup',
  components: {
    SiteTaskSchedulesTimeAdjusmentsTile,
    TNoData,
  },
  inject: ['getItemHook'],
  data() {
    return {
      loading: false,
      data: [],
    }
  },
  computed: {
    hasData() {
      return this.data.length > 0
    },
    queryTimings(): CollectionQuery | null {
      return {
        resource: Resources.SITE_TASK_SCHEDULE_TIMINGS,
        limit: 100,
        sort: [{ attribute: 'id' }],
        filters: [
          {
            attribute: 'schedule.id',
            operator: 'EQUAL',
            value: this.entityId,
          },
        ],
      }
    },
    scheduleIds(): number[] | null {
      return this.data?.map((schedule) => schedule.id) || null
    },
    entityId() {
      return this.getItemHook().entityReference.entityId
    },
    queryExceptionTypes(): CollectionQuery | null {
      return {
        resource: Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
        limit: 100,
        sort: [{ attribute: 'id' }],
        filters: [
          {
            attribute: 'schedule.id',
            operator: 'EQUAL',
            value: this.entityId,
          },
        ],
        include: ['exceptionType.label'],
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      const resp =
        await this.$appContext.widgetServices.resourceDataManager.getCollection(
          this.queryTimings,
          { disableCache: true },
        )

      this.data = resp.items.map((item) => ({
        ...item,
        label: item.dayOfWeekStart,
        isActive: true,
      }))

      this.fetchExceptionTypes()
    },
    async fetchExceptionTypes() {
      const resp =
        await this.$appContext.widgetServices.resourceDataManager.getCollection(
          this.queryExceptionTypes,
          { disableCache: true },
        )

      this.loading = false
      const mappedItems = resp.items.map((item) => ({
        ...item,
        label: item.exceptionType.label,
        rangeStartTime: item.timeFrom,
        rangeEndTime: item.timeTo,
        isActive: item.perform,
      }))
      this.data = [...this.data, ...mappedItems]
    },
  },
})
</script>
