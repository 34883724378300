import ExceptionTypeDateRemoveOccurrences from './ExceptionTypeDateRemoveOccurrences.vue'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import { Resources } from '../../types'

import { ExceptionTypeDateActions } from './types'

export default {
  install(Vue) {
    Vue.component(
      'ExceptionTypeDateRemoveOccurrences',
      ExceptionTypeDateRemoveOccurrences,
    )

    registerResourceForm(
      Resources.EXCEPTION_TYPE_DATES,
      'ExceptionTypeDateRemoveOccurrences',
      ExceptionTypeDateActions.REMOVE_OCCURRENCES,
    )
  },
}
