import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '../types'
import { PromiseFunction } from '@/types'

export const createViewAction = (
  icon: string,
  action: string,
  resources: Resources,
  onClickFn: PromiseFunction<void>,
) => ({
  icon,
  title: ResourceTranslator.translateActionLabel(resources, action) || action,
  onClick: () => onClickFn(action),
})
