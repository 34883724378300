import Vue from 'vue'
import { ImageHandler, ImageID } from './types'

export const createImageHandler = (): ImageHandler => {
  const state: Record<ImageID, string> = Vue.observable({})

  const getImageUrl = (fileId) => state[fileId]

  const setImageUrl = (fileId, url: string) => {
    state[fileId] = url
  }

  return {
    getImageUrl,
    setImageUrl,
  }
}
