<template>
  <div>
    <json-any-of v-slot="{ selector }" name="">
      <json-field name="conditionSet" />
      <json-field name="type" />

      <json-any-of-selector
        v-if="showOneOfSelector"
        v-bind="selector"
        v-on="selector.on"
      />

      <json-field name="affectedField" block-creation />
      <json-field name="affectedGroup" block-creation />
      <json-field name="affectedLibrary" block-creation />
    </json-any-of>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { Resources } from '../../types'

export default Vue.extend({
  name: 'ReportConditionSetActionsForm',
  computed: {
    groupsEnabled(): boolean {
      return this.hasAttribute('affectedGroup')
    },
    librariesEnabled(): boolean {
      return this.hasAttribute('affectedLibrary')
    },
    resourceName(): string {
      return Resources.REPORT_CONDITION_SET_ACTIONS
    },
    showOneOfSelector(): boolean {
      return this.groupsEnabled || this.librariesEnabled
    },
  },
  methods: {
    hasAttribute(attributeName: string): boolean {
      const { resourceMetaManager } = this.$appContext.widgetServices

      return !!resourceMetaManager.getAttribute(
        this.resourceName,
        attributeName,
      )
    },
  },
})
</script>
