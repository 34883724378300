import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import ReportTemplateFieldsSorter from './ReportTemplateFieldsSorter.vue'
import ReportTemplatesSortFields from './ReportTemplatesSortFields.vue'
import ReportTemplatesForm from './ReportTemplatesForm.vue'
import { Resources } from '../../types'
import { ReportTemplateActions } from './types'

export default {
  install(Vue) {
    Vue.component('ReportTemplateFieldsSorter', ReportTemplateFieldsSorter)
    Vue.component('ReportTemplatesSortFields', ReportTemplatesSortFields)
    Vue.component('ReportTemplatesForm', ReportTemplatesForm)

    registerResourceForm(Resources.REPORT_TEMPLATES, 'ReportTemplatesForm')
    registerResourceForm(
      Resources.REPORT_TEMPLATES,
      'ReportTemplatesSortFields',
      ReportTemplateActions.SortFields,
    )
  },
}
