import { VuetifyTheme, TrackTikThemeKey } from '@/plugins/vuetify-plugin'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum Luminance {
  DARK,
  LIGHT,
}

interface ColorRGB {
  r: number
  g: number
  b: number
}

export type Color = string

export interface TThemeProviderConfig {
  textColor?: Color | TrackTikThemeKey
  backgroundColor?: Color | TrackTikThemeKey
  themeKey: TrackTikThemeKey
}

export interface TThemeProviderColors {
  text: Color
  background: Color
}

export class ThemeProvider {
  private vuetifyTheme: VuetifyTheme
  readonly themeKey: TrackTikThemeKey

  constructor(vuetifyTheme, themeKey: TrackTikThemeKey = 'muted') {
    this.vuetifyTheme = vuetifyTheme
    this.themeKey = themeKey
  }

  get isDark(): boolean {
    return this.vuetifyTheme.dark
  }

  get currentTheme(): Partial<VuetifyThemeVariant> {
    return this.vuetifyTheme.currentTheme
  }

  get color(): Color {
    return this.currentTheme[this.themeKey] as Color
  }

  get contrastColor(): Color {
    return this.getContrastColor(this.color)
  }

  getContrastColor(
    color: Color,
    darkContrast: Color = '#000000DE',
    lightContrast: Color = '#FFFFFF',
  ): Color {
    return this.getLuminance(color) === Luminance.LIGHT
      ? darkContrast
      : lightContrast
  }

  isRgbFormat(color: Color): boolean {
    return !!color.match(/^rgb/)
  }

  processRgb(color: Color): ColorRGB {
    const colorRegex = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )

    return {
      r: +colorRegex[1],
      g: +colorRegex[2],
      b: +colorRegex[3],
    }
  }

  convertHexToRgb(color: Color): ColorRGB {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const colorRGB = +(
      '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
    )

    return {
      r: colorRGB >> 16,
      g: (colorRGB >> 8) & 255,
      b: colorRGB & 255,
    }
  }

  getLuminance(color: Color): Luminance {
    // Variables for red, green, blue values
    const { r, g, b } = this.isRgbFormat(color)
      ? this.processRgb(color)
      : this.convertHexToRgb(color)

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    return hsp > 127.5 ? Luminance.LIGHT : Luminance.DARK
  }
}
