// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OperationCenterEscalationTicketsTile from './OperationCenterEscalationTicketsTile.vue'
import OperationCenterEscalationTicketsColumns from './OperationCenterEscalationTicketsColumns'
import OperationCenterEscalationTicketsDetail from './OperationCenterEscalationTicketsDetail.vue'
import OperationCenterEscalationTicketsMetaCard from './OperationCenterEscalationTicketsMetaCard.vue'
import OperationCenterEscalationTicketsReference from './OperationCenterEscalationTicketsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'OperationCenterEscalationTicketsTile',
      OperationCenterEscalationTicketsTile,
    )
    Vue.component(
      'OperationCenterEscalationTicketsDetail',
      OperationCenterEscalationTicketsDetail,
    )
    Vue.component(
      'OperationCenterEscalationTicketsMetaCard',
      OperationCenterEscalationTicketsMetaCard,
    )
    Vue.component(
      'OperationCenterEscalationTicketsReference',
      OperationCenterEscalationTicketsReference,
    )

    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_TICKETS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationTicketsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_TICKETS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OperationCenterEscalationTicketsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_TICKETS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationTicketsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_TICKETS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationTicketsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_ESCALATION_TICKETS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OperationCenterEscalationTicketsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
