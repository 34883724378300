import { Resources } from '@/tt-entity-design/src/types'

import { ResourcesMeta } from '../resource-meta/types'

/**
 * Additional list of resources to be ignored by the CI (tests, types).
 * Useful when a new resource needs to be used for a "beta" version, but is not deployed in production.
 * Allows the FE to merge the related code without failing the CI. The usage should always be temporary.
 */
export enum ResourcesWhitelist {
  ASSET_RESERVATIONS = 'asset-reservations', // @TODO: waiting for API-1503 to be merged
  ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT = 'asset-type-to-report-template-assignment', // @todo: waiting for SDAM-382.
  ASSET_CUSTOM_ATTRIBUTES = 'asset-custom-attributes',
  ASSET_TYPE_CUSTOM_ATTRIBUTES = 'asset-type-custom-attributes',
  CONFLICTS = 'conflicts', //@todo: remove when BOSS-2759 gets merged
  ASSET_FILES = 'asset-files', //@todo: remove when SDAM-444 gets merged into master
  ASSET_TYPE_FILES = 'asset-type-files', //@todo: remove when SDAM-444 gets merged into master
  ASSET_CONTACTS = 'asset-contacts', //@todo: remove when SDAM-835 gets merged into master. Follow-up ticket: SDAM-836
  SITE_VENDORS = 'site-vendors', //@todo: remove when SEU-2594 gets merged into master. Follow-up ticket: SEU-2594
  WORKFLOW_ACTION_SETTINGS = 'workflow-action-settings', //@todo: remove when SEU-2754 gets merged into master
}

/**
 * List of resources to be hidden at runtime, even though they are available in the authenticated API.
 * Will be removed from the schema.
 */
const ResourcesBlacklist = {}

export const ResourcesWithWhitelist = {
  ...Resources,
  ...ResourcesWhitelist,
} as const

export type ResourcesWithWhitelist = typeof ResourcesWithWhitelist

export function isResourceWhitelisted(resourceName: string): boolean {
  const items: string[] = Object.values(ResourcesWhitelist)

  return items.includes(resourceName)
}

export function isResourceBlacklisted(resourceName: string): boolean {
  const items: string[] = Object.values(ResourcesBlacklist)

  return items.includes(resourceName)
}

export function processResourceBlacklist(
  resourcesMeta: ResourcesMeta,
): ResourcesMeta {
  return Object.entries(resourcesMeta).reduce(
    (acc, [resourceName, resourceObj]) =>
      isResourceBlacklisted(resourceName)
        ? acc
        : { ...acc, [resourceName]: resourceObj },
    {},
  )
}
