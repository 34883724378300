<template>
  <div class="d-flex flex-column">
    <WeekSelector v-model="weekOf" />
    <entity-collection
      :query="query"
      container="v-list"
      :container-props="{
        threeLine: true,
      }"
    >
      <template #default="{ itemHook }">
        <div @click="itemHook.dispatchPreview({ activeWindow })">
          <ShiftsTile />
        </div>
        <v-divider />
      </template>
      <template #loading>
        <v-skeleton-loader
          v-for="index in 10"
          :key="index"
          type="list-item-avatar-three-line"
        />
      </template>
      <template #empty class="pa-2 pt-6 mt-6 text-center">
        <div style="font-weight: 300" class="text-center pa-3">
          {{ $t('tt-entity-design.no-shift') }}
        </div>
      </template>
    </entity-collection>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider } from '@/tt-app-layout'
import { Filter } from '@/tt-widget-components'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { Resources } from '../types'

type Query = {
  resource: string
  limit: number
  sort: {
    attribute: string
  }[]
  filters: Filter[]
}

export default (
  Vue as VueConstructor<Vue & ActiveWindowProvider & ItemHookProvider>
).extend({
  name: 'SchedulePreview',
  inject: ['activeWindow', 'getItemHook'],
  data() {
    return {
      // for testing purposes:
      // weekOf: moment('2019-12-02').format('YYYY-MM-DD')
      weekOf: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    itemFilter(): Filter | null {
      if (!this.getItemHook() || !this.getItemHook().entityReference) {
        return null
      }
      switch (this.getItemHook().entityReference.resourceName) {
        case Resources.ACCOUNTS:
        case Resources.CLIENTS:
        case Resources.DEPARTMENTS:
        case Resources.ZONES:
          return {
            attribute: 'position.account',
            operator: 'EQUAL',
            value: this.getItemHook().entityReference.entityId,
          }
        case Resources.EMPLOYEES:
          return {
            attribute: 'employee',
            operator: 'EQUAL',
            value: this.getItemHook().entityReference.entityId,
          }
        default:
          return null
      }
    },
    query(): Query {
      const weekOf = this.weekOf
      const itemFilter = this.itemFilter

      const dateRange = `${moment(weekOf).format('YYYY-MM-DD')}|${moment(weekOf)
        .add(6, 'day')
        .format('YYYY-MM-DD')}`

      return {
        resource: 'shifts',
        limit: 20,
        sort: [{ attribute: 'startDateTime' }],
        filters: [
          itemFilter,
          {
            attribute: 'startDateTime',
            operator: 'BETWEEN',
            value: dateRange,
          },
        ],
      }
    },
  },
})
</script>
