<template>
  <div class="pt-3 px-3">
    <div class="subtitle-2" v-text="title" />

    <div class="d-flex flex-column pt-3 workflow--container">
      <TLoadingBar v-show="isLoading" />

      <v-btn
        v-for="transition in transitions"
        :key="transition.id"
        class="transition--button"
        depressed
        @click="performTransition(transition)"
      >
        <v-icon small v-text="'mdi-arrow-right'" />
        <span class="pl-3" v-text="transition.eventLabel" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import {
  DispatchTaskExtension,
  TransitionsExtensionProperty,
} from '@/tt-entity-design/src/components/dispatch-tasks/types'
import TLoadingBar from '@/tt-ui/components/TLoadingBar.vue'
import { ActiveWindowProvider } from '@/tt-app-layout'
import WorkflowStatusTransitionsForm from './WorkflowStatusTransitionsForm.vue'

const EXTENSION_NAME: DispatchTaskExtension = 'availableTransitions'

const EXT_ATTRIBUTE: TransitionsExtensionProperty = 'transitions'

type ExtensionPayload = {
  /**
   * resource: worlflow-status-transitions
   */
  id: number
  event: string
  eventLabel: string
  form: number
  fromStatus: number
  toStatus: number
}

const BASE_KEY = 'tt-entity-design.dispatch-tasks'

/**
 * Workflow available events of a `dispatch-task` are exposed by the API via the extension `availableTransitions`
 */
export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'DispatchTasksWorkflowAvailableEvents',
  components: { TLoadingBar },
  inject: ['getItemHook', 'activeWindow'],
  computed: {
    extensionPayload(): ExtensionPayload[] | null {
      return this.getItemHook().data[EXTENSION_NAME]?.[EXT_ATTRIBUTE]
    },
    transitions(): ExtensionPayload[] {
      return this.extensionPayload || []
    },
    isLoading(): boolean {
      return this.extensionPayload !== null && this.getItemHook().loading
    },
    title(): string {
      const i18nKey =
        this.isLoading || this.transitions.length
          ? 'available-transitions'
          : 'no-transition-available'

      return this.$t(`${BASE_KEY}.${i18nKey}`)
    },
  },
  created() {
    this.getItemHook().addAttribute(`extensions.${EXTENSION_NAME}`)
  },
  methods: {
    performTransition(transition: ExtensionPayload) {
      this.activeWindow.next({
        is: WorkflowStatusTransitionsForm,
        title: ' ',
        props: {
          form: transition.form,
          event: transition.event,
          taskId: this.getItemHook().getEntityId(),
          eventLabel: transition.eventLabel,
          onClose: () => this.activeWindow.back(),
        },
      })
    },
  },
})
</script>

<style scoped>
.transition--button {
  width: 100%;
  margin-bottom: 10px;
}
.transition--button >>> .v-btn__content {
  display: flex;
  justify-content: flex-start;
}

.workflow--container {
  position: relative;
}
</style>
