<template>
  <div v-if="relationResource">
    <TButtonToggleMultiple
      v-model="operatorType"
      class="pa-4"
      :items="operatorToggleItems"
    />

    <v-expand-transition>
      <EntityMultipleSelectorField
        v-if="showSelection"
        v-model="selected"
        :query="relationQuery"
        :resource="relationResource"
        class="pt-0"
      />
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { Attribute } from '@/tt-widget-factory'
import { RelationAttributeFilterOptions, Filter } from '@/tt-widget-components'
import TButtonToggleMultiple from '@/tt-ui/components/TButtonToggleMultiple.vue'
import { EntityMultipleSelectorFieldQuery } from '@/tt-entity-forms/components/EntityMultipleSelectorField.vue'
import { hasMustacheVariable } from '@/tt-entity-forms/components/utils/RelationField'

import BaseFilter from './BaseFilter'

const valueIsNotDynamic = <T extends { value?: any }>(item: T): boolean =>
  typeof item.value !== 'string' || !hasMustacheVariable(item.value)

type RelationOperator =
  | typeof FilterOperatorType.ISNULL
  | typeof FilterOperatorType.ISNOTNULL
  | typeof FilterOperatorType.IN

type OperatorToggleItemType = {
  text: string
  value: string
}

export default BaseFilter.extend({
  name: 'FilterRelation',
  components: {
    TButtonToggleMultiple,
  },
  props: {
    attributeMeta: {
      type: Object as PropType<Attribute>,
      required: true,
    },
    attributeName: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<RelationAttributeFilterOptions>,
      default: (): RelationAttributeFilterOptions => ({}),
    },
    value: {
      type: Object as PropType<Filter | null>,
      default: null,
    },
  },
  data() {
    return {
      operatorType: this.value.operator as RelationOperator,
    }
  },
  watch: {
    operatorType(newVal: RelationOperator) {
      this.model = {
        attribute: this.attributeName,
        operator: FilterOperatorType[newVal],
        ...(newVal === FilterOperatorType.IN && {
          value: this.selected.join(','),
        }),
      }
    },
  },
  computed: {
    operatorToggleItems(): OperatorToggleItemType[] {
      return [
        {
          text: this.$t('filter.toggle.in'),
          value: FilterOperatorType.IN,
        },
        {
          text: this.$t('filter.toggle.is_not_null'),
          value: FilterOperatorType.ISNOTNULL,
        },
        {
          text: this.$t('filter.toggle.is_null'),
          value: FilterOperatorType.ISNULL,
        },
      ]
    },
    showSelection(): boolean {
      return this.operatorType === FilterOperatorType.IN
    },
    selected: {
      get(): string[] {
        const stringValue = this.model?.value

        return stringValue ? stringValue.split(',') : []
      },
      set(newVal: string[] = []) {
        const value = (newVal || []).join(',')

        this.model = {
          attribute: this.attributeName,
          operator: FilterOperatorType.IN,
          value: value,
        }
      },
    },
    relationQuery(): EntityMultipleSelectorFieldQuery {
      const {
        customFilters = [],
        filters = [],
        scopes,
        whereQL,
        ...query
      } = this.attributeMeta.relation?.relationFilter ?? {}

      return {
        ...query,
        filters: [
          ...filters.filter(valueIsNotDynamic),
          ...(this.options.relationFilter?.filters || []),
        ],
        customFilters: [
          ...customFilters.filter(valueIsNotDynamic),
          ...(this.options.relationFilter?.customFilters || []),
        ],
        scope: scopes,
        ...(whereQL && !hasMustacheVariable(whereQL) ? { whereQL } : {}), // remove whereQL if it has a dynamic value
      }
    },
    relationResource(): string | undefined {
      return this.attributeMeta.relation?.resource
    },
  },
  methods: {
    onInput(input) {
      if (input && input.value) {
        this.selected = input.value.split(',')
      }
    },
    onInputNull() {
      this.selected = []
    },
  },
})
</script>

<style scoped>
.v-switch {
  min-width: 300px;
}

.switch--label {
  white-space: nowrap;
}
</style>
