<template>
  <v-container>
    <EntitySelectorField
      v-if="filterType.isRelation"
      v-model="model"
      :resource="relationResource"
    />
    <EntityMultipleSelectorField
      v-else-if="filterType.isRelationList"
      v-model="model"
      :resource="relationResource"
      multiple
    />
    <v-text-field
      v-else-if="filterType.isString"
      v-model="model"
      outlined
      dense
      clearable
      hide-details
    />
    <v-text-field
      v-else-if="filterType.isFloat"
      v-model="model"
      type="number"
      outlined
      dense
      clearable
      hide-details
    />
    <FilterDateTimeCustomFilterAdapter
      v-else-if="filterType.isDateTime"
      v-model="model"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  CustomFilterMeta,
  CustomFilterType,
} from '@/tt-widget-factory/services/resource-meta/types'

import FilterDateTimeCustomFilterAdapter from '@/tt-entity-filter/components/FilterDateTimeCustomFilterAdapter.vue'

const { RELATION, FLOAT, STRING, RELATION_LIST, DATE_TIME } = CustomFilterType

export default Vue.extend({
  name: 'CustomFilterField',
  components: { FilterDateTimeCustomFilterAdapter },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      default: undefined,
    },
  },
  computed: {
    model: {
      get(): any {
        return this.value
      },
      set(value: any) {
        this.$emit('input', value)
      },
    },
    customFilter(): CustomFilterMeta {
      return this.$appContext.widgetServices.resourceMetaManager.getCustomFilter(
        this.resource,
        this.filterName,
      )
    },
    relationResource(): string {
      return this.customFilter.relation?.resource
    },
    filterType(): {
      isRelation: boolean
      isString: boolean
      isRelationList: boolean
      isFloat: boolean
      isDateTime: boolean
    } {
      const typeIs = (type: CustomFilterMeta['type']): boolean =>
        this.customFilter.type === type

      return {
        isRelation: typeIs(RELATION),
        isRelationList: typeIs(RELATION_LIST) && !!this.customFilter.relation,
        isString: typeIs(STRING),
        isFloat: typeIs(FLOAT),
        isDateTime: typeIs(DATE_TIME),
      }
    },
  },
  methods: {
    logError() {
      console.error('CustomFilter type is unknown:', this.customFilter.type)

      return ''
    },
  },
})
</script>
