<template>
  <div>
    <v-switch v-model="model" color="blue">
      <template #prepend>
        <span class="mt-1 clickable-text">
          {{ $t('widget_edit.tab.options.filterable_searchable') }}
        </span>
      </template>
    </v-switch>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'EnableColumnHeaderFiltersSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean) {
        this.$emit('input', newValue)
      },
    },
  },
})
</script>

<style scoped>
.v-input .clickable-text {
  width: 250px;
  color: grey;
}
</style>
