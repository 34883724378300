<template>
  <!-- not using tt-attr for performance -->
  <TChipLabel
    v-if="statusKey"
    :text-key="labelKey"
    :background-color="color"
    text-color="white"
    :modifiers="{ 'x-small': true }"
  />
  <div v-else>N/A</div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { getStatusColor } from './utils'
import { RESOURCE, STATUS_ATTR } from './constants'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileRunsheetSessionsStatusAttr',
  inject: ['getItemHook', 'relationPrefix'],
  computed: {
    attributePath(): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${STATUS_ATTR}`
        : STATUS_ATTR
    },
    statusKey(): string {
      return this.getItemHook().getRawValue(this.attributePath)
    },
    color(): string {
      return getStatusColor(this.statusKey) || 'pink'
    },
    labelKey(): string {
      return createI18nEnumKey(RESOURCE, STATUS_ATTR, this.statusKey)
    },
  },
  created() {
    this.getItemHook().addAttribute(this.attributePath)
  },
})
</script>
