<template>
  <div>
    <span v-if="isCloseDispatchTask" class="datatable-row--close-task" />
    <TTooltipOnOverflow
      :text="attributeLabel"
      class="w-100"
      :disable-tooltip="!isOverflowing"
    >
      <OverflowObserver class="w-100" @overflow="onOverflow">
        <span
          class="d-block text-truncate actionbar--btn-text"
          :class="{ 'actionbar--btn-text-close': isCloseDispatchTask }"
          v-text="attributeLabel"
        />
      </OverflowObserver>
    </TTooltipOnOverflow>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'
import OverflowObserver from '@/tt-widget-components/components/OverflowObserver.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Status } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksKeyAttr',
  components: { TTooltipOnOverflow, OverflowObserver },
  inject: ['getItemHook'],
  data: () => ({
    isOverflowing: false,
  }),
  computed: {
    taskTypePrefix(): string | null {
      return this.getItemHook().getRawValue('taskType.prefix') || null
    },
    id(): string {
      return this.getItemHook().getRawValue('id')
    },
    attributeLabel(): string {
      return this.taskTypePrefix
        ? `${this.taskTypePrefix} - ${this.id}`
        : `${this.id}`
    },
    isCloseDispatchTask(): boolean {
      return this.getItemHook().getRawValue('status') === Status.CLOSED
    },
  },
  created() {
    this.getItemHook().addAttribute('taskType.prefix')
    this.getItemHook().addAttribute('status')
  },
  methods: {
    onOverflow(isOverflowing: boolean) {
      this.isOverflowing = isOverflowing
    },
  },
})
</script>

<style scoped>
.datatable-row--close-task {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  border-left: 4px solid var(--v-muted-darken2);
  background-color: rgb(250, 250, 250, 0.6);
}
.actionbar--btn-text-close {
  color: var(--v-muted-darken2);
}
</style>
