<template>
  <div>
    <TAlert class="mb-4" :text="requestCancellationMessage" />
    <json-field
      name="reason"
      outlined
      :label="$tc('res.leave-requests.attr.reason.label')"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'LeaveRequestsRequestCancellationForm',
  computed: {
    requestCancellationMessage(): string {
      return this.$t(
        'tt-entity-design.leave-requests.request-cancellation-message',
      )
    },
  },
})
</script>
