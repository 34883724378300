import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { DateFormatTypes } from '@/helpers/formats/dates'

import { CalendarExceptionTypeLevel } from './types'

export default [
  {
    attributeName: 'imported',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'imported',
        icon: 'mdi-import',
        color: 'blue',
      },
    },
  },
  { attributeName: 'date', format: DateFormatTypes.asLongDate },
  {
    attributeName: 'type',
    headerText: 'tt-entity-design.calendar-exceptions.columns.type.header-text',
    component: {
      is: 'CalendarExceptionsTypeColumn',
      props: { level: CalendarExceptionTypeLevel.Root },
    },
  },
  {
    attributeName: 'type',
    headerText:
      'tt-entity-design.calendar-exceptions.columns.sub-type.header-text',
    component: {
      is: 'CalendarExceptionsTypeColumn',
      props: { level: CalendarExceptionTypeLevel.SubType },
    },
  },
  'description',
  {
    attributeName: 'impactRunsheet',
    headerText:
      'tt-entity-design.calendar-exceptions.columns.impactRunsheet.header-text',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'impactRunsheet',
      },
    },
  },
  {
    attributeName: 'impactSchedule',
    headerText:
      'tt-entity-design.calendar-exceptions.columns.impactSchedule.header-text',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'impactSchedule',
      },
    },
  },
  {
    attributeName: 'impactSiteTask',
    headerText:
      'tt-entity-design.calendar-exceptions.columns.impactSiteTask.header-text',
    component: {
      is: 'CheckBooleanColumn',
      props: {
        attributeName: 'impactSiteTask',
      },
    },
  },
] as ColumnInputDefinition[]
