// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import RegionsTile from './RegionsTile.vue'
import RegionsColumns from './RegionsColumns'
import RegionsDetail from './RegionsDetail.vue'
import RegionsMetaCard from './RegionsMetaCard.vue'
import RegionsReference from './RegionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('RegionsTile', RegionsTile)
    Vue.component('RegionsDetail', RegionsDetail)
    Vue.component('RegionsMetaCard', RegionsMetaCard)
    Vue.component('RegionsReference', RegionsReference)

    modularManager.addResourcePreset(Resources.REGIONS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'RegionsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.REGIONS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: RegionsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.REGIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'RegionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.REGIONS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'RegionsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.REGIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'RegionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
