import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'name',
    headerText: 'tt-entity-design.checkpoint-tours.attrs.name',
  },
  'account',
  'checkpoints',
  'estimatedDuration',
  'position',
  'recurrenceType',
  {
    attributeName: 'active',
    headerText: 'tt-entity-design.checkpoint-tours.attrs.active',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'active',
        truthy: {
          textKey: 'tt-entity-design.checkpoint-tours.attr-values.active.true',
        },
        falsy: {
          textKey: 'tt-entity-design.checkpoint-tours.attr-values.active.false',
        },
      },
    },
  },
] as ColumnInputDefinition[]
