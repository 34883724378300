// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionPremiumsTile from './PositionPremiumsTile.vue'
import PositionPremiumsColumns from './PositionPremiumsColumns'
import PositionPremiumsDetail from './PositionPremiumsDetail.vue'
import PositionPremiumsMetaCard from './PositionPremiumsMetaCard.vue'
import PositionPremiumsReference from './PositionPremiumsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionPremiumsTile', PositionPremiumsTile)
    Vue.component('PositionPremiumsDetail', PositionPremiumsDetail)
    Vue.component('PositionPremiumsMetaCard', PositionPremiumsMetaCard)
    Vue.component('PositionPremiumsReference', PositionPremiumsReference)

    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionPremiumsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionPremiumsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPremiumsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPremiumsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionPremiumsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
