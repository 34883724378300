<template>
  <div v-if="resource">
    <v-select
      v-model="model"
      :disabled="!resource"
      outlined
      dense
      :label="label"
      :items="attributes"
      :error-messages="errorMessages"
      :placeholder="placeholder"
      :attach="true"
      @change="$emit('change', $event)"
    >
      <template #selection="{ item }">
        <div class="selection">
          <v-icon :color="item.color" class="mr-2" v-text="item.icon" />
          <span class="selection__text" v-text="$t(item.text)" />
        </div>
      </template>
      <template #item="{ on, item }">
        <v-list-item
          :style="{ opacity: item.opacity }"
          dense
          style="border-bottom: 1px dotted #cccc; margin-right: 15px"
          v-on="on"
        >
          <v-list-item-icon>
            <v-icon :color="item.color" v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.text)" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { PropType, VueConstructor } from 'vue'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import {
  getResourceAttributesForList,
  ResourceAttributeSelectorFilter,
} from '@/tt-widget-components/lib/attribute-list'
import { ResourceMetaManagerInterface } from '@/tt-widget-factory'
import { FormHookProvider, ResourceProvider } from '..'

export default (
  BaseInput as VueConstructor<
    InstanceType<typeof BaseInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'ContextAttributeMapFilterSelector',
  inject: ['formHook', 'resourceProvider'],
  props: {
    value: {
      type: [String, Boolean],
    },
    filterName: {
      type: String,
      required: true,
    },
    filter: {
      type: Function as PropType<ResourceAttributeSelectorFilter>,
      default() {
        return () => {
          return true
        }
      },
    },
  },
  data() {
    return {
      model: null,
    }
  },
  computed: {
    resource(): any {
      return this.resourceProvider.resource
    },
    attributes(): any[] {
      const resourceManager: ResourceMetaManagerInterface =
        this.$appContext.widgetServices.resourceMetaManager
      if (!this.resource) {
        return []
      }
      const items = getResourceAttributesForList(
        resourceManager,
        this.resource,
        this.filter,
      )

      let defaults = resourceManager.getResourceModelContext(this.resource)

      if (!defaults) {
        defaults = {}
      }

      const list = []
      if (defaults[this.filterName]) {
        list.push({
          text: this.$t(
            'components.context_attribute_map_filter_selector.default_filter_name',
            { filterName: defaults[this.filterName] },
          ),
          icon: 'check',
          color: 'green',
          value: null,
        })
        // Disable
        list.push({
          icon: 'close',
          color: 'red',
          text: this.$t(
            'components.context_attribute_map_filter_selector.disabled',
          ),
          value: false,
        })
      } else {
        list.push({
          text: this.$t(
            'components.context_attribute_map_filter_selector.default_none',
          ),
          icon: 'close',
          color: 'orange',
          value: null,
        })
      }

      return [
        ...list,
        {
          divider: true,
        },
        {
          header: this.$t(
            'components.context_attribute_map_filter_selector.bind_custom_attribute',
          ),
        },
        ...items,
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.selection {
  flex-wrap: nowrap;

  .selection__text {
    font-size: 0.8em;
  }
}
</style>
