import Vue from 'vue'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import hook from './DataTableWidgetHook'
import schema from './schema'
import DataTableWidgetEditor from './DataTableWidgetEditor.vue'
import DataTableOptions from './DataTableOptions.vue'
import EntityActionButton from './EntityActionButton.vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "DataTableWidget" */ './DataTableWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('DataTableWidgetEditor', DataTableWidgetEditor)
Vue.component('DataTableOptions', DataTableOptions)
Vue.component('EntityActionButton', EntityActionButton)

const DataTableWidget: WidgetTypeDefinition = {
  name: WidgetName.DATA_TABLE_WIDGET,
  schema,
  component: {
    template: template as any,
    hook,
  },
  editorDefinitions: {
    DataTableWidget: {
      view: {
        is: 'DataTableWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-table-large',
    color: '#00a7f0',
    print: true,
    thumbnail: require('./data-table.png'),
  },
}

export default DataTableWidget
