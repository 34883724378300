import { ResourceMetaManagerInterface } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'

import {
  ReportConditionSetType,
  ReportFieldGroupType,
  ReportFieldParentType,
  ReportFieldType,
  ReportTemplateBuilderJsonFieldsHelper,
  ReportTemplateBuilderJsonFieldsRules,
} from './types'

export const getReportTemplateBuilderJsonFieldHelper = ({
  groupIds = [],
  inheritedFieldsIds = [],
  libraryIds = [],
}: {
  groupIds?: number[]
  inheritedFieldsIds?: number[]
  libraryIds?: number[]
}): ReportTemplateBuilderJsonFieldsHelper => {
  const getIdFromName = (fieldName: string): number =>
    Number(fieldName.replace(/[^0-9]/g, '') || 0)

  const getIdsFromAbsoluteName = (fieldAbsoluteName: string): number[] =>
    fieldAbsoluteName.split('.').map(getIdFromName)

  const getIdFromAbsoluteName = (fieldAbsoluteName: string): number =>
    getIdsFromAbsoluteName(fieldAbsoluteName).pop()

  const isGroupId = (id: number): boolean => groupIds.includes(id)

  const isGroup = (fieldAbsoluteName: string): boolean => {
    const ids = getIdsFromAbsoluteName(fieldAbsoluteName)

    return isGroupId(ids[ids.length - 1])
  }

  const isInsideGroup = (fieldAbsoluteName: string): boolean =>
    getIdsFromAbsoluteName(fieldAbsoluteName).slice(0, -1).some(isGroupId)

  const isLibraryId = (id: number): boolean => libraryIds.includes(id)

  const isLibrary = (fieldAbsoluteName: string): boolean => {
    const ids = getIdsFromAbsoluteName(fieldAbsoluteName)

    return isLibraryId(ids[ids.length - 1])
  }

  const isInsideLibrary = (fieldAbsoluteName: string): boolean =>
    getIdsFromAbsoluteName(fieldAbsoluteName).slice(0, -1).some(isLibraryId)

  const isInheritedFieldId = (id: number): boolean =>
    inheritedFieldsIds.includes(id)

  const isInheritedField = (fieldAbsoluteName: string): boolean =>
    getIdsFromAbsoluteName(fieldAbsoluteName).some(isInheritedFieldId)

  const isInsideInheritedObject = (fieldAbsoluteName: string): boolean =>
    getIdsFromAbsoluteName(fieldAbsoluteName)
      .slice(0, -1)
      .some(isInheritedFieldId)

  return {
    getIdFromName: getIdFromAbsoluteName,
    getIdsFromName: getIdsFromAbsoluteName,
    isGroup,
    isInheritedField,
    isInsideGroup,
    isInsideInheritedObject,
    isInsideLibrary,
    isLibrary,
  }
}

export const getReportTemplateBuilderJsonFieldsRules = ({
  groupIds = [],
  inheritedFieldsIds = [],
  libraryIds = [],
}: {
  groupIds?: number[]
  inheritedFieldsIds?: number[]
  libraryIds?: number[]
}): ReportTemplateBuilderJsonFieldsRules => {
  const helper = getReportTemplateBuilderJsonFieldHelper({
    groupIds,
    inheritedFieldsIds,
    libraryIds,
  })

  const allowDrag = (fieldName: string): boolean =>
    !helper.isInsideLibrary(fieldName) &&
    !helper.isInsideInheritedObject(fieldName)

  const allowEdit = (fieldName: string): boolean =>
    !helper.isLibrary(fieldName) &&
    !helper.isInsideLibrary(fieldName) &&
    !helper.isInheritedField(fieldName)

  const allowMoveIntoObject = (
    fieldName: string,
    objectFieldName: string,
  ): boolean => {
    /**
     * Allow moving anything into the root
     */
    if (!objectFieldName) {
      return true
    }

    /**
     * Don't allow moving a field into itself
     */
    if (fieldName === objectFieldName) {
      return false
    }

    /**
     * Don't allow moving anything into a library
     */
    if (
      helper.isLibrary(objectFieldName) ||
      helper.isInsideLibrary(objectFieldName)
    ) {
      return false
    }

    /**
     * Don't allow moving anything into an inherited object
     */
    if (helper.isInheritedField(objectFieldName)) {
      return false
    }

    /**
     * Don't allow moving groups, inherited fields, or libraries into objects
     */
    return (
      !helper.isGroup(fieldName) &&
      !helper.isInheritedField(fieldName) &&
      !helper.isLibrary(fieldName)
    )
  }

  const allowRemove = (fieldName: string): boolean =>
    !helper.isInsideLibrary(fieldName) && !helper.isInheritedField(fieldName)

  return { allowDrag, allowEdit, allowMoveIntoObject, allowRemove }
}

export const getReportConditionSetParentAttribute = (
  parentType: ReportFieldParentType,
): string => {
  switch (parentType) {
    case Resources.REPORT_TEMPLATES:
      return 'reportTemplate'
    default:
      throw new Error(
        `Unknown report condition set parent type '${parentType}'`,
      )
  }
}

export const getReportConditionSetResource = (
  parentType: ReportFieldParentType,
): ReportConditionSetType => {
  switch (parentType) {
    case Resources.REPORT_TEMPLATES:
      return Resources.REPORT_CONDITION_SETS
    default:
      throw new Error(`Unknown report condition set type '${parentType}'`)
  }
}

export const getReportTemplateFieldGroupResource = (
  parentType: ReportFieldParentType,
): ReportFieldGroupType => {
  switch (parentType) {
    case Resources.REPORT_TEMPLATES:
      return Resources.REPORT_TEMPLATE_FIELD_GROUPS
    case Resources.REPORT_FLAG_TEMPLATES:
      return Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS
    default:
      throw new Error(
        `Unknown report template field group type '${parentType}'`,
      )
  }
}

export const getReportTemplateFieldParentAttribute = (
  parentType: ReportFieldParentType,
): string => {
  switch (parentType) {
    case Resources.REPORT_TEMPLATES:
      return 'reportTemplate'
    case Resources.REPORT_FLAG_TEMPLATES:
      return 'reportFlagTemplate'
    case Resources.REPORT_LIBRARIES:
      return 'reportLibrary'
    case Resources.REPORT_TEMPLATE_FIELD_GROUPS:
      return 'fieldGroup'
    case Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS:
      return 'fieldGroup'
    default:
      throw new Error(
        `Unknown report template field parent type '${parentType}'`,
      )
  }
}

export const getReportTemplateFieldResource = (
  parentType: ReportFieldParentType,
): ReportFieldType => {
  switch (parentType) {
    case Resources.REPORT_TEMPLATES:
      return Resources.REPORT_TEMPLATE_FIELDS
    case Resources.REPORT_FLAG_TEMPLATES:
      return Resources.REPORT_FLAG_TEMPLATE_FIELDS
    case Resources.REPORT_LIBRARIES:
      return Resources.REPORT_LIBRARY_FIELDS
    case Resources.REPORT_TEMPLATE_FIELD_GROUPS:
      return Resources.REPORT_TEMPLATE_FIELD_GROUP_FIELDS
    case Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS:
      return Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS
    default:
      throw new Error(
        `Unknown report template field parent type '${parentType}'`,
      )
  }
}

export const parentSupportsConditions = (
  parentType: ReportFieldParentType,
): boolean => {
  try {
    return !!getReportConditionSetResource(parentType)
  } catch (e) {
    return false
  }
}

export const parentSupportsAccount = (
  parentType: ReportFieldParentType,
): boolean => {
  return (
    parentType === Resources.REPORT_TEMPLATES ||
    parentType === Resources.REPORT_FLAG_TEMPLATES
  )
}

export const reportTemplateResourceSupportsLibraries = (
  parentType: ReportFieldParentType,
  {
    resourceMetaManager,
  }: { resourceMetaManager: ResourceMetaManagerInterface },
): boolean => {
  const attributes = resourceMetaManager.getAttributes(parentType)

  return Object.values(attributes).some(
    (attr) => attr.relation?.resource === Resources.REPORT_LIBRARIES,
  )
}
