import Vue from 'vue'
import defaultDeeps from 'lodash/defaultsDeep'
import isEmpty from 'lodash/isEmpty'

import { createAuthModule } from '@tracktik/tt-authentication'

import ContextManager from '@/tt-widget-factory/context/ContextManager'
import ContextManagerBase from '@/tt-widget-factory/context/ContextManagerBase'
import EventManager from '@/tt-event-manager/EventManager'
import metadataProvider, {
  MetaKey,
} from '@/tt-widget-factory/services/metadata-provider'
import ResourceActionManager from '@/tt-widget-factory/services/resource-action/ResourceActionManager'
import ResourceActionProvider from '@/tt-widget-factory/services/resource-action/ResourceActionProvider'
import ResourceDataManager from '@/tt-widget-factory/services/resource-data/ResourceDataManager'
import ResourceDataProvider from '@/tt-widget-factory/services/resource-data/ResourceDataProvider'
import ResourceMetaManager from '@/tt-widget-factory/services/resource-meta/ResourceMetaManager'
import ResourceMetaProvider from '@/tt-widget-factory/services/resource-meta/ResourceMetaProvider'
import widgetInstaller from '@/tt-widget-components/widgets'
import WidgetManager from '@/tt-widget-factory/WidgetManager'
import { AllowEntityOption } from '@/types'
import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'

import {
  AppContext,
  AppContextBuilder,
  AppContextServices,
  AppContextEventPayload,
} from './types'
import { createRegionManager } from '../tt-region-manager/RegionManager'
import { createPusherSdk } from '@tracktik/tt-pusher'

export const subsribeToEvents = (
  eventManager,
  eventRecord: Record<string, Function>,
) => {
  Object.entries(eventRecord).forEach(([eventType, handler]) => {
    eventManager.subscribeEvent(eventType, handler)
  })
}

const BuilderDefaults: Partial<AppContextBuilderOptions> = {
  allowEntityEdit: false,
  allowEntityCreation: false,
  allowEntityActions: false,
  events() {
    return {}
  },
  services: {
    eventManager: () => {
      return new EventManager<AppContextEventPayload>()
    },
    authModule: (context) => {
      return createAuthModule({
        dispatchEvent(event, payload) {
          context.eventManager.dispatchEvent(event, payload)
        },
      })
    },
    regionManager: (context) => {
      return createRegionManager(context.authModule)
    },
    contextManager: (context) => {
      const contextBase = new ContextManagerBase(context.eventManager)

      return new ContextManager(
        context.authModule,
        context.regionManager,
        contextBase,
      )
    },
    entityServices(context) {
      return {
        persister: new EntityPersistRunner(
          context.authModule,
          context.eventManager,
        ),
      }
    },
    widgetManager(_context) {
      const widgetManager = new WidgetManager()
      widgetInstaller.install(widgetManager, Vue)

      return widgetManager
    },
    resourceActionManager({ authModule }) {
      const resourceActionProvider = new ResourceActionProvider(authModule)

      return new ResourceActionManager([resourceActionProvider])
    },
    resourceMetaManager(_context) {
      return new ResourceMetaManager([new ResourceMetaProvider()])
    },
    resourceDataManager(context, service) {
      return new ResourceDataManager([
        new ResourceDataProvider(
          context.authModule,
          service.resourceMetaManager(context, service),
        ),
      ])
    },
    widgetServices(context, services) {
      const resourceMetaManager = services.resourceMetaManager(
        context,
        services,
      )
      const standardizedServices = {
        ...services,
        resourceMetaManager: () => resourceMetaManager,
      }

      return {
        authModule: context.authModule,
        widgetManager: standardizedServices.widgetManager(
          context,
          standardizedServices,
        ),
        resourceDataManager: standardizedServices.resourceDataManager(
          context,
          standardizedServices,
        ),
        resourceActionManager: standardizedServices.resourceActionManager(
          context,
          standardizedServices,
        ),
        resourceMetaManager,
        contextManager: context.contextManager,
      }
    },
  },
  validate(state) {
    Object.entries(state).forEach(([key, value]) => {
      const defined = [undefined, null].includes(value) === false
      if (!defined) {
        throw new Error(`AppContextBuilder ${key} is required`)
      }
    })
  },
  defaults(state, services) {
    state.eventManager = services.eventManager(state, services)
    state.authModule = services.authModule(state, services)
    state.regionManager = services.regionManager(state, services)
    state.pusherSdk = createPusherSdk({ authModule: state.authModule })
    state.contextManager = services.contextManager(state, services)
    state.entityServices = services.entityServices(state, services)
    state.widgetServices = services.widgetServices(state, services)
    state.allowEntityEdit = state.allowEntityEdit || false
    state.allowEntityCreation = state.allowEntityCreation || false
    state.allowEntityActions = state.allowEntityActions || false
  },
}

export interface AppContextBuilderOptions {
  events(state: Partial<AppContext>): Record<string, Function>
  validate(state: Partial<AppContext>): void
  defaults(state: Partial<AppContext>, services: AppContextServices): void
  services: Partial<AppContextServices>
  allowEntityEdit?: AllowEntityOption
  allowEntityCreation?: AllowEntityOption
  allowEntityActions?: AllowEntityOption
}

const defaults = (
  options?: Partial<AppContextBuilderOptions>,
): AppContextBuilderOptions => {
  return defaultDeeps(options, BuilderDefaults) as AppContextBuilderOptions
}

export const isReady = () =>
  !isEmpty(metadataProvider.get(MetaKey.resources)) &&
  !isEmpty(metadataProvider.get(MetaKey.openApiSchema))

export const builder = (
  options?: Partial<AppContextBuilderOptions>,
): AppContextBuilder => {
  const allowEntityEdit = options.allowEntityEdit
  const allowEntityCreation = options.allowEntityCreation
  const allowEntityActions = options.allowEntityActions

  const opts = defaults(options)

  const state: Partial<AppContext> = {
    authModule: null,
    eventManager: null,
    regionManager: null,
    contextManager: null,
    entityServices: null,
    widgetServices: null,
  }

  const validate = opts.validate

  const services = (): AppContextServices => {
    return opts.services as AppContextServices
  }

  const builderRef: AppContextBuilder = {
    defaults(updater) {
      opts.defaults = updater

      return builderRef
    },
    events(updater) {
      const eventRef = opts.events

      opts.events = (state) => {
        const current = eventRef(state)

        return updater(current, services())
      }

      return builderRef
    },
    build() {
      opts.defaults(state, services())
      validate(state)
      subsribeToEvents(state.eventManager, opts.events(state))

      const appContext = {
        ...state,
        isReady,
        allowEntityEdit: allowEntityEdit ?? opts.allowEntityEdit,
        allowEntityCreation: allowEntityCreation ?? opts.allowEntityCreation,
        allowEntityActions: allowEntityActions ?? opts.allowEntityActions,
      }

      return appContext as AppContext
    },
  }

  return builderRef
}
