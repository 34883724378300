<template>
  <div v-if="!!activeTab.key" class="fill-height w-100">
    <ContextManagerProvider>
      <WidgetContainer class="fill-height">
        <MetadataLoadingGuard>
          <div class="fill-height">
            <WidgetContextToolbar
              v-if="showContextToolbar"
              :default-filter-values="defaultFilterValues"
              :style="{
                'z-index': 1,
                position: 'sticky',
              }"
              :hook="hook"
            />
            <WidgetFactory
              :widget="widgetConfig"
              :container="container"
              @set-has-data="hasData = $event"
              @update:hook="saveHook"
            />
          </div>
        </MetadataLoadingGuard>
      </WidgetContainer>
    </ContextManagerProvider>
  </div>
  <div v-else class="fill-height d-flex flex-column w-100">
    <v-skeleton-loader type="text, table-row, table" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import ContextManagerProvider from '@/tt-widget-factory/context/ContextManagerProvider.vue'
import {
  DataTableWidgetModel,
  Filter,
  TQLQueryWidgetModel,
} from '@/tt-widget-components/types'
import DataTableWidgetHook from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { VueWithLayoutManager } from '@/tt-widget-views/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { WidgetContainerInterface } from '@/tt-widget-factory'
import MetadataLoadingGuard from '../../components/MetadataLoadingGuard.vue'
import { BackOfficeReportsTab, ReportingAndAnalyticsSubMenuTab } from '../types'
import {
  EARNINGS_PREMIUM_TABS,
  EFFECTIVE_DATE_TABS,
  HUMAN_CAPITAL_MANAGEMENT_TABS,
  LEAVE_MANAGEMENT_TABS,
  SCHEDULING_TABS,
} from '../constants'

export default (Vue as VueWithLayoutManager).extend({
  name: 'ConfigurableTab',
  components: {
    MetadataLoadingGuard,
    ContextManagerProvider,
  },
  inject: { layoutManager: {}, isInjectedDashboardTab: { default: false } },
  props: {
    activeTab: {
      type: Object as PropType<ReportingAndAnalyticsSubMenuTab>,
      required: true,
    },
  },
  data() {
    return {
      hasData: false,
      hook: null as DataTableWidgetHook,
    }
  },
  computed: {
    backOfficeReportsTabs(): BackOfficeReportsTab[] {
      /**
       * EFFECTIVE DATE TABS
       * - Positions Pay Rate
       * - Positions Bill Rate
       * - Employee Profile Pay Rate
       * - Site Specific Employee Pay Rate
       * - Price Tier
       *
       * SCHEDULING TABS
       * - Schedule Changes
       * - Scheduling Reports
       *
       * EARNINGS PREMIUM TABS
       * - Positions Earnings Premium
       * - Employee Profile Earnings Premium
       *
       * HUMAN CAPITAL MANAGEMENT TABS
       * - Completed Leave Requests
       * - Conflicts
       * - Certifications
       *
       * LEAVE MANAGEMENT TABS
       * - Completed Leave Requests
       */
      return [
        ...EFFECTIVE_DATE_TABS,
        ...SCHEDULING_TABS,
        ...EARNINGS_PREMIUM_TABS,
        ...HUMAN_CAPITAL_MANAGEMENT_TABS,
        ...LEAVE_MANAGEMENT_TABS,
      ]
    },
    showContextToolbar(): boolean {
      //@ts-ignore
      return this.activeTab.widget?.contextFilters ?? false
    },
    defaultFilterValues(): Filter[] {
      //@ts-ignore
      return this.widgetConfig?.contextFilters?.defaults ?? []
    },
    container(): WidgetContainerInterface {
      return {
        hideTitle: this.showContextToolbar,
      }
    },
    widgetConfig(): DataTableWidgetModel | TQLQueryWidgetModel {
      return this.activeTab.widget ?? this.backofficeReportsWidget
    },
    backofficeReportsWidget(): DataTableWidgetModel {
      const componentConfig = this.backOfficeReportsTabs.find(
        (tab) => tab.key === this.activeTab.key,
      )

      return {
        is: WidgetName.DATA_TABLE_WIDGET,
        title: componentConfig.title,
        query: {
          resource: componentConfig.query.resource,
          filters: componentConfig.query.filters,
        },
        component: {
          columns: componentConfig.columns,
          // columns must include attributeName to download successfully
          downloadColumns:
            componentConfig.downloadColumns ?? componentConfig.columns,
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: componentConfig.filters ?? [],
            allowSearch: true,
          },
        },
      }
    },
  },
  methods: {
    saveHook(hook: DataTableWidgetHook) {
      this.hook = hook
    },
  },
})
</script>
