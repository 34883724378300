import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'label',
  {
    attributeName: 'parent',
    headerText: 'tt-entity-design.exception-types.columns.parent.header-text',
    component: { is: 'ExceptionTypesParentLabelWrapper' },
  },
  'account',
  {
    attributeName: 'dates',
    headerText: 'tt-entity-design.exception-types.columns.dates.header-text',
    allowSorting: false,
  },
] as ColumnInputDefinition[]
