<template>
  <json-field :name="name" :disabled="disabled" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { ErrorObject } from 'ajv'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'
import { FilterOperatorType } from '@/tt-widget-factory'
import { CustomErrorTranslation, LeaveManagementApiError } from './types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'LeavePolicyUniqueField',
  inject: ['formHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    resource: {
      type: String,
      required: true,
    },
    responseErrors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fieldValue(): string {
      return this.formHook().getPathValue(this.name)
    },
    isDebouncing(): boolean {
      return this.formHook().state.isDebouncing
    },
    uniqueFieldErrorTranslationKey(): string {
      return 'tt-entity-design.leave-management.unique-field-error'
    },
    maxLengthErrorTranslationKey(): string {
      return 'tt-entity-design.leave-management.max-length-error'
    },
  },
  watch: {
    async fieldValue(val: string): Promise<void> {
      if (this.isReadyToValidate(val) && !this.disabled) {
        if (this.isBiggerThanMaxLength(val)) {
          return this.registerMaxLengthError()
        }

        const taken = await this.isValueAlreadyTaken(val)
        if (taken) {
          return this.registerUniqueFieldError()
        }

        this.clearFieldError()
      }
    },
    responseErrors(errors: LeaveManagementApiError) {
      if (errors) {
        Object.keys(errors)
          .filter((errorKey) => this.name == errorKey)
          .forEach((errorKey) => {
            this.formHook().setCustomError(
              errorKey,
              this.createError(
                errorKey,
                {
                  key: null,
                  param: null,
                },
                errors[errorKey][0],
              ),
            )
          })
      }
    },
  },
  methods: {
    clearFieldError(): void {
      this.formHook().setCustomError(this.name, null)
    },
    async isValueAlreadyTaken(val): Promise<boolean> {
      try {
        const { total } = await this.$appContext.authModule
          .getApi()
          .getAll(this.resource, {
            filters: [
              {
                attribute: this.name,
                value: val,
                operator: FilterOperatorType.EQUAL,
              },
              {
                attribute: 'id',
                value: this.formHook().getPathValue('id'),
                operator: FilterOperatorType.NOT,
              },
            ],
          })

        return total > 0
      } catch (error) {
        this.$crash.captureException(error)
        return false
      }
    },
    createError(
      name: string,
      { key, param }: CustomErrorTranslation,
      apiErrorMessage?: string,
    ): ErrorObject[] {
      return [
        {
          dataPath: `.${name}`,
          keyword: 'required',
          message: apiErrorMessage || this.$t(key, { arg: param }),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    getFieldLabel(): string {
      return ResourceTranslator.translateAttribute(
        this.resource,
        this.name,
        FormLabelTypes.LABEL,
      )
    },
    isBiggerThanMaxLength(val: string): boolean {
      return val.length > this.maxLength
    },
    isReadyToValidate(val): boolean {
      return !this.isDebouncing && val
    },
    registerMaxLengthError(): void {
      return this.formHook().setCustomError(
        this.name,
        this.createError(this.name, {
          key: this.maxLengthErrorTranslationKey,
          param: this.maxLength,
        }),
      )
    },
    registerUniqueFieldError(): void {
      this.formHook().setCustomError(
        this.name,
        this.createError(this.name, {
          key: this.uniqueFieldErrorTranslationKey,
          param: this.getFieldLabel(),
        }),
      )
    },
  },
})
</script>
