// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeAvailabilitiesTile from './EmployeeAvailabilitiesTile.vue'
import EmployeeAvailabilitiesColumns from './EmployeeAvailabilitiesColumns'
import EmployeeAvailabilitiesDetail from './EmployeeAvailabilitiesDetail.vue'
import EmployeeAvailabilitiesReference from './EmployeeAvailabilitiesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeAvailabilitiesTile', EmployeeAvailabilitiesTile)
    Vue.component('EmployeeAvailabilitiesDetail', EmployeeAvailabilitiesDetail)
    Vue.component(
      'EmployeeAvailabilitiesReference',
      EmployeeAvailabilitiesReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_AVAILABILITIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeAvailabilitiesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_AVAILABILITIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeAvailabilitiesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_AVAILABILITIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeAvailabilitiesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_AVAILABILITIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeAvailabilitiesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
