import defaultTo from 'lodash/defaultTo'
import toNumber from 'lodash/toNumber'
import round from 'lodash/round'

import DataSetWidgetHook from '../../base/DataSetWidgetHook'
import { GaugeChartWidgetModel } from '@/tt-widget-components'

export default class GaugeChartWidgetHook extends DataSetWidgetHook<
  GaugeChartWidgetModel
> {
  SERIES_INDEX = 0
  MEASURE_INDEX = 0

  get value(): number {
    if (!this.seriesManagers.length || !this.series.length) {
      return 0
    }

    const measure = this.getMeasureByIndex(
      this.SERIES_INDEX,
      this.MEASURE_INDEX,
    )
    if (!measure.key) {
      return 0
    }

    const value = this.series[this.SERIES_INDEX].getValue(
      this.MEASURE_INDEX,
      measure.key,
    )

    const number = defaultTo(toNumber(value), 0)
    const roundedNumber = round(number * 100) / 100

    return roundedNumber
  }
}
