// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveTypesTile from './LeaveTypesTile.vue'
import LeaveTypesColumns from './LeaveTypesColumns'
import LeaveTypesDetail from './LeaveTypesDetail.vue'
import LeaveTypesMetaCard from './LeaveTypesMetaCard.vue'
import LeaveTypesReference from './LeaveTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeaveTypesTile', LeaveTypesTile)
    Vue.component('LeaveTypesDetail', LeaveTypesDetail)
    Vue.component('LeaveTypesMetaCard', LeaveTypesMetaCard)
    Vue.component('LeaveTypesReference', LeaveTypesReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_TYPES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveTypesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
