<template>
  <WidgetFactoryInvalid
    v-if="!hook || !isValid"
    v-bind="invalidProps"
    @rendered="widgetRendered"
  />
  <WidgetFactoryUnauthorized
    v-else-if="!hasPermission"
    v-bind="invalidProps"
    @hook:mounted="widgetRendered"
  />
  <component
    :is="widgetTemplate"
    v-else
    ref="widget"
    class="widget-factory"
    v-bind="widgetProps"
    v-on="listeners"
    @rendered="widgetRendered"
  />
</template>

<script lang="ts">
import isEqual from 'lodash/isEqual'
import { flatten } from 'flat'
import Vue, { PropType, VueConstructor } from 'vue'

import ContextManager from '@/tt-widget-factory/context/ContextManager'
import WidgetFactoryInvalid from './WidgetFactoryInvalid.vue'
import WidgetFactoryUnauthorized from './WidgetFactoryUnauthorized.vue'
import {
  HookConstructor,
  WidgetContainerInterface,
  WidgetHookInterface,
  WidgetReference,
  WidgetState,
  WidgetTypeDefinition,
} from '../types'
import { WidgetModels } from '@/tt-widget-components'
import { updateDOM } from '@/helpers/dom'

type VueWithContextManager = VueConstructor<
  Vue & { contextManager: ContextManager }
>

const attrsShouldNotTriggerReRender = ['title', 'description']

const isEqualAfterIgnoringAttributes = (
  record1: Record<string, any>,
  record2: Record<string, any>,
  ignoredAttributes: string[],
): boolean => {
  if (!record1 && !record2) return true
  if (!record1 || !record2) return false

  const omitIgnored = (record: Record<string, any>): Record<string, any> => {
    const flattenedRecord: Record<string, any> = flatten<
      Record<string, any>,
      Record<string, any>
    >(record)
    const entries = Object.entries(flattenedRecord).filter(
      ([key]) =>
        !ignoredAttributes.includes(key) &&
        //In case the property is an object or an array, we remove all the
        //sub properties
        !ignoredAttributes.some((attr) => key.startsWith(attr + '.')),
    )

    return Object.fromEntries(entries)
  }

  const record1WithoutIgnored = omitIgnored(record1)
  const record2WithoutIgnored = omitIgnored(record2)

  return isEqual(record1WithoutIgnored, record2WithoutIgnored)
}

export default (Vue as VueWithContextManager).extend({
  name: 'WidgetFactory',
  components: {
    WidgetFactoryUnauthorized,
    WidgetFactoryInvalid,
  },
  inject: { contextManager: { default: null } },
  props: {
    /**
     * Widget container data
     */
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: () => ({}),
    },
    /**
     * Widget hook initial state
     */
    initialState: {
      type: Object as PropType<WidgetState>,
      default: () => ({}),
    },
    /**
     * Props to be forwarded to the widget component
     */
    propsData: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    /**
     * Skip widget hook validations?
     */
    skipValidation: { type: Boolean, default: false },
    /**
     * Widget config object
     */
    widget: { type: Object as PropType<WidgetModels>, required: true },
  },
  data() {
    return {
      hook: null as null | WidgetHookInterface,
    }
  },
  computed: {
    listeners(): object {
      return {
        ...this.$listeners,
        ...this.$on,
      }
    },
    widgetProps(): object {
      return {
        ...this.$attrs,
        ...this.$props,
        ...this.propsData,
        hook: this.hook,
      }
    },
    widgetTemplate(): string {
      return this.$appContext.widgetServices.widgetManager.getTemplate(
        this.widget.is,
      )
    },
    widgetConfig(): WidgetTypeDefinition | undefined {
      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        this.widget.is,
      )
    },
    invalidProps(): object {
      return {
        ...this.$props,
        ...this.propsData,
      }
    },
    isValid(): boolean {
      return this.hook.isValid
    },
    hasPermission(): boolean {
      return !!(this.isValid && this.hook?.hasPermission())
    },
    hasData(): boolean {
      return this.hook?.hasDataSource ?? false
    },
  },
  watch: {
    hasData: {
      handler() {
        this.$emit('set-has-data', this.hook.hasDataSource)
      },
    },
    widget: {
      deep: true,
      handler(widget: WidgetModels) {
        // Same exact valid widget ignoring title and description, we avoid recreating
        if (
          this.hook &&
          isEqualAfterIgnoringAttributes(
            this.hook.state.initialWidget,
            this.widget,
            attrsShouldNotTriggerReRender,
          ) &&
          this.hook.isValid
        ) {
          this.hook.widget.title = widget.title
          this.hook.widget.description = widget.description
          return
        }
        this.buildHook()
      },
    },
  },
  created() {
    this.buildHook()
  },
  beforeDestroy() {
    if (this.hook) {
      this.hook.destroy()
    }
  },
  /**
   * For performance reasons, widgets get deactivated (but not unmounted thanks to `<keep-alive>`) in some scenarios like :
   * - when component is mounted, but behind a hidden tab in a dashboard
   * - when component is mounted, but its dashboard row goes outside the viewport
   *
   * We stack the updates that are happening while the component is deactivated,
   * and run them when components gets activated again.
   */
  deactivated() {
    this.hook.deactivate()
  },
  activated() {
    this.hook.reactivate()
  },
  methods: {
    createWidgetHook(
      widget: WidgetReference,
      contextManager?: ContextManager,
    ): WidgetHookInterface {
      const Hook: HookConstructor =
        this.$appContext.widgetServices.widgetManager.getHookClassName(
          widget.is,
        )

      const state: WidgetState =
        this.hook?.state && this.shouldRecycleState()
          ? { ...this.hook?.state, initialWidget: widget, widget }
          : { ...this.initialState }

      return new Hook({
        options: { skipValidation: this.skipValidation },
        services: {
          authModule: this.$appContext.authModule,
          contextManager: contextManager ?? this.$appContext.contextManager,
          eventManager: this.$appContext.eventManager,
          resourceDataManager:
            this.$appContext.widgetServices.resourceDataManager,
          resourceMetaManager:
            this.$appContext.widgetServices.resourceMetaManager,
          widgetManager: this.$appContext.widgetServices.widgetManager,
          pusherSdk: this.$appContext.pusherSdk,
        },
        state,
        widget,
      })
    },
    buildHook(): void {
      if (this.hook) {
        this.hook.destroy()
      }
      this.hook = this.createWidgetHook(this.widget, this.contextManager)

      if (this.hasPermission) {
        this.hook.initialize(!this.shouldRecycleState())
      }
      this.$emit('update:hook', this.hook)
    },
    async widgetRendered() {
      await updateDOM()
      this.$emit('rendered')
    },
    shouldRecycleState(): boolean {
      const ttcIgnoredAttributes =
        this.$appContext.widgetServices.widgetManager.getTtcIgnoredAttributes(
          this.widget?.is,
        )

      if (
        ttcIgnoredAttributes.length === 0 ||
        !this.hook.hasDataSource ||
        !this.widget
      ) {
        return false
      }

      return isEqualAfterIgnoringAttributes(
        this.hook.state.initialWidget,
        this.widget,
        ttcIgnoredAttributes,
      )
    },
  },
})
</script>

<style scoped>
@media print {
  .widget-factory {
    break-inside: avoid;
  }
}
</style>
