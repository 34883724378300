<template>
  <div>
    <TChipLabel
      :text-key="textKey"
      :text-color="themeConfig.textColor"
      :background-color="themeConfig.backgroundColor"
      :theme-key="themeConfig.themeKey"
      :modifiers="modifiers"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  ChipLabelBooleanConfig,
  DefaultFalsy,
  DefaultTruthy,
  Modifier,
} from '@/tt-ui/types'
import { TThemeProviderConfig } from '@/tt-ui/ThemeProvider'

export default Vue.extend({
  name: 'TChipLabelBoolean',
  props: {
    isTruthy: {
      type: Boolean,
      required: true,
    },
    truthy: {
      type: Object as PropType<ChipLabelBooleanConfig>,
      default: () => DefaultTruthy,
    },
    falsy: {
      type: Object as PropType<ChipLabelBooleanConfig>,
      default: () => DefaultFalsy,
    },
    defaultText: {
      type: String,
      default: '',
    },
    modifiers: {
      type: Object as PropType<Modifier>,
      default: () => ({}),
    },
  },
  computed: {
    textKey(): string {
      if (this.truthy.textKey && this.falsy.textKey) {
        return this.isTruthy ? this.truthy.textKey : this.falsy.textKey
      }

      return this.defaultText
    },
    result(): ChipLabelBooleanConfig {
      return this.isTruthy ? this.truthy : this.falsy
    },
    themeConfig(): TThemeProviderConfig {
      const defaultResult: ChipLabelBooleanConfig = this.isTruthy
        ? DefaultTruthy
        : DefaultFalsy

      return {
        ...defaultResult,
        ...this.result,
      }
    },
  },
})
</script>
