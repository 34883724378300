<template>
  <tt-tile>
    <template #action>
      <MobileScheduleOccurrenceLogStatusAttr class="ml-3" />
    </template>
    <template slot="title">
      <span class="d-flex">
        <tt-if-attr
          class="mr-1"
          name="employee.name"
          not
          :value="[null, undefined, '']"
        >
          <tt-attr name="employee.name" />
        </tt-if-attr>
        <tt-if-attr name="employee.jobTitle" not :value="[null, undefined, '']">
          <span>-</span>
        </tt-if-attr>
        <tt-if-attr
          class="text-truncate ml-1"
          name="employee.jobTitle"
          not
          :value="[null, undefined, '']"
        >
          <tt-attr
            class="d-inline text-truncate ml-1"
            name="employee.jobTitle"
          />
        </tt-if-attr>
      </span>
    </template>
    <template slot="line2">
      <tt-attr name="mobileSchedule.client.name" />
    </template>
    <template slot="line3">
      <tt-attr name="enRouteOn" prepend-icon="mdi-car" />
    </template>
    <template slot="line4">
      <tt-attr name="onSiteOn" prepend-icon="mdi-office-building" />
    </template>
    <template slot="line5">
      <tt-attr name="lastStatusOn" prepend-icon="mdi-clock-check-outline" />
    </template>
  </tt-tile>
</template>

<script lang="ts">
import Vue from 'vue'
import MobileScheduleOccurrenceLogStatusAttr from './MobileScheduleOccurrenceLogStatusAttr.vue'

export default Vue.extend({
  name: 'MobileScheduleOccurrenceLogsTile',
  components: {
    MobileScheduleOccurrenceLogStatusAttr,
  },
})
</script>
