import { FormatterInput } from '@/helpers/formats/types'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import { round as _round } from 'lodash'

function round(number, precision) {
  const factor = Math.pow(10, precision)
  const tempNumber = number * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}

export default {
  asCurrency(payload: FormatterInput, preferences: UserPreferenceInterface) {
    const currencySymbol = preferences.currency
    return `${currencySymbol}${round(payload, 2).toFixed(2)}`
  },
  asRoundedCurrency(
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) {
    const currencySymbol = preferences.currency
    return `${currencySymbol}${round(payload, 0)}`
  },
  asRounded2Decimals(
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) {
    return round(payload, 2)
  },
  asRounded4Decimals(
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) {
    return round(payload, 4)
  },
}
