<template>
  <div>
    <div class="d-flex align-center tab-container">
      <v-tooltip :disabled="!showTooltip" top :open-delay="tooltipOpenDelay">
        <template #activator="{ on }">
          <div class="tab-wrapper" @dragenter="setIncomingDragOver(true)">
            <label
              ref="label"
              class="tab-title text-capitalize px-3"
              :for="`${uid}${tabIndex}`"
              v-on="on"
            >
              {{ $t(widget.title) || $t('widgets.dashboard.untitled') }}
            </label>
            <input
              :id="`${uid}${tabIndex}`"
              type="radio"
              class="d-none"
              :name="`${uid}-tab`"
              :value="tabIndex"
              @input="$emit('input', $event)"
            />
          </div>
        </template>

        <span>
          {{ $t(widget.title) || $t('widgets.dashboard.untitled') }}
        </span>
      </v-tooltip>
      <v-tooltip
        v-if="hasToDisplayDeleteTab"
        bottom
        :open-delay="tooltipOpenDelay"
      >
        <template #activator="{ on }">
          <v-btn
            text
            icon
            small
            style="opacity: 0.5"
            v-on="on"
            @click.stop="$emit('deleteTab')"
          >
            <v-icon small> close </v-icon>
          </v-btn>
        </template>
        {{ $t('widgets.dashboard.delete_tab') }}
      </v-tooltip>
    </div>
    <!-- drop zone -->
    <drop
      :style="{
        visibility: incomingDragOver ? 'visible' : 'hidden',
      }"
      class="overlay-drop-zone"
      @drop="onDrop($event)"
      @dragleave="setIncomingDragOver(false)"
    />
    <!-- / drop zone -->
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import isEqual from 'lodash/isEqual'
import { updateDOM } from '@/helpers/dom'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { DraggableInterface } from '@/apps/app.tracktik.insights.studio/types'
import { TOOLTIP_OPEN_DELAY, DRAG_OVER_DELAY } from '@/helpers/constants'
import { WidgetModels } from '@/tt-widget-components'
import { WidgetPosition } from './types'
import DashboardWidgetDragAndDropService from './DashboardWidgetDragAndDropService'

type VueWithPreviousPosition = VueConstructor<
  Vue & {
    dragAndDropService: () => DashboardWidgetDragAndDropService
  }
>

export default (Vue as VueWithPreviousPosition).extend({
  name: 'DashboardWidgetTab',
  inject: {
    dragAndDropService: {
      from: 'dashboardWidgetDragAndDropService',
    },
  },
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    uid: {
      type: String,
      required: true,
    },
    widget: {
      type: Object as PropType<WidgetModels>,
      required: true,
    },
    hasToDisplayDeleteTab: {
      type: Boolean,
      default: false,
    },
    currentPosition: {
      type: Object as PropType<WidgetPosition>,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      incomingDragOver: false,
    }
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    model: {
      get(): number {
        return this.tabIndex
      },
      set(tabIndex: number) {
        this.$emit('input', tabIndex)
      },
    },
    debounceIncomingDragOver(): DebouncedFunc<() => void> {
      return debounce(() => (this.incomingDragOver = false), DRAG_OVER_DELAY)
    },
  },
  async mounted() {
    await updateDOM()
    this.checkOverflow()
  },
  methods: {
    checkOverflow() {
      const element = this.$refs['label'] as HTMLElement

      const isContentOverflowing = () =>
        element.offsetWidth < element.scrollWidth

      this.showTooltip = isContentOverflowing()
    },
    setIncomingDragOver(value: boolean) {
      // if we drag over the current dragging item, we do nothing
      if (
        isEqual(
          this.currentPosition,
          this.dragAndDropService()?.getInitialPosition(),
        )
      )
        return

      this.incomingDragOver = value

      if (value) {
        this.debounceIncomingDragOver()
      }
    },
    async onDrop(event: DraggableInterface) {
      this.incomingDragOver = false

      this.dragAndDropService()?.dropOverWidget(event, this.currentPosition)

      this.$emit('input', this.currentPosition.tabIndex)

      await updateDOM()
    },
  },
})
</script>
<style scoped lang="scss">
.tab-container {
  position: relative;
  width: 100%;

  .tab-wrapper {
    display: grid;

    .tab-title {
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tab-container:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.overlay-drop-zone {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.186);
}
</style>
