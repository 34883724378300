// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmploymentTerminationReasonsTile from './EmploymentTerminationReasonsTile.vue'
import EmploymentTerminationReasonsColumns from './EmploymentTerminationReasonsColumns'
import EmploymentTerminationReasonsDetail from './EmploymentTerminationReasonsDetail.vue'
import EmploymentTerminationReasonsMetaCard from './EmploymentTerminationReasonsMetaCard.vue'
import EmploymentTerminationReasonsReference from './EmploymentTerminationReasonsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmploymentTerminationReasonsTile',
      EmploymentTerminationReasonsTile,
    )
    Vue.component(
      'EmploymentTerminationReasonsDetail',
      EmploymentTerminationReasonsDetail,
    )
    Vue.component(
      'EmploymentTerminationReasonsMetaCard',
      EmploymentTerminationReasonsMetaCard,
    )
    Vue.component(
      'EmploymentTerminationReasonsReference',
      EmploymentTerminationReasonsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_TERMINATION_REASONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmploymentTerminationReasonsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_TERMINATION_REASONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmploymentTerminationReasonsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_TERMINATION_REASONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmploymentTerminationReasonsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_TERMINATION_REASONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmploymentTerminationReasonsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYMENT_TERMINATION_REASONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmploymentTerminationReasonsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
