// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskOccurrencesTile from './SiteTaskOccurrencesTile.vue'
import SiteTaskOccurrencesColumns from './SiteTaskOccurrencesColumns'
import SiteTaskOccurrencesDetail from './SiteTaskOccurrencesDetail.vue'
import SiteTaskOccurrencesMetaCard from './SiteTaskOccurrencesMetaCard.vue'
import SiteTaskOccurrencesReference from './SiteTaskOccurrencesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SiteTaskOccurrencesTile', SiteTaskOccurrencesTile)
    Vue.component('SiteTaskOccurrencesDetail', SiteTaskOccurrencesDetail)
    Vue.component('SiteTaskOccurrencesMetaCard', SiteTaskOccurrencesMetaCard)
    Vue.component('SiteTaskOccurrencesReference', SiteTaskOccurrencesReference)

    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrencesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskOccurrencesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrencesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrencesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrencesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
