import ResourceMetaManager from './ResourceMetaManager'
import ResourceMetaProvider from './ResourceMetaProvider'

export { default as ResourceMetaManager } from './ResourceMetaManager'
export { default as ResourceMetaProvider } from './ResourceMetaProvider'
export * from './types'

const metaProvider = new ResourceMetaProvider()

const defaultResourceMetaManager = new ResourceMetaManager([metaProvider])

// Export a default manager object
// This does not prevent to create your own
export default defaultResourceMetaManager
