<template v-slot:activator="{ on, attrs }">
  <div v-if="firstError" class="d-flex justify-center align-center h-100">
    <slot :errors="errors" :first-error="firstError">
      <WidgetError
        v-if="!isTimeout && !isRequiredFilterError"
        :icon="firstError.icon"
        :icon-color="firstError.color"
        :text="firstError.text"
      />
    </slot>
    <WidgetError
      v-if="isTimeout && !isRequiredFilterError"
      :icon="'$timeoutIcon'"
      :text="'widgets.error_details.timeout'"
    />
    <WidgetError
      v-if="isRequiredFilterError"
      :icon="firstError.icon"
      :icon-color="firstError.color"
      :text="firstError.text"
    >
      <template #default>
        <WidgetErrorRequiredFilter
          :resource="resource"
          :error="errors[0].response.data"
        />
      </template>
    </WidgetError>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  APIErrorAdapter,
  APIErrorViewItem,
  ApiErrorCodes,
} from '@/tt-widget-components/lib/api-error-adapter'
import TracktikApiError from 'tracktik-sdk/lib/errors'
import WidgetErrorRequiredFilter from '@/tt-widget-components/components/WidgetErrorRequiredFilter.vue'
import WidgetError from '@/tt-widget-components/components/WidgetError.vue'

export default Vue.extend({
  name: 'WidgetErrorDetails',
  components: {
    WidgetErrorRequiredFilter,
    WidgetError,
  },
  props: {
    errors: {
      type: Array as PropType<TracktikApiError[]>,
      default: () => [],
    },
    resource: {
      type: String,
      default: '',
    },
  },
  computed: {
    firstError(): APIErrorViewItem | null {
      if (!this.errors.length) {
        return null
      }

      return this.errorList[0]
    },
    errorList(): APIErrorViewItem[] {
      const adapter = new APIErrorAdapter()

      return this.errors.map((e) => {
        return adapter.getError(e)
      })
    },
    isTimeout(): boolean {
      if (
        this.errors &&
        this.errors[0] &&
        this.errors[0].response &&
        this.errors[0].response.code === 'ECONNABORTED'
      ) {
        return true
      } else if (this.errors && this.errors[0].code === 'ECONNABORTED') {
        return true
      }

      return false
    },
    isRequiredFilterError(): boolean {
      return (
        this.firstError.text === ApiErrorCodes.REQUIRED &&
        this.errors[0]?.response?.data?.extraInfo
      ) // @todo: to remove, prevent errors on aggregation queries, waiting for an API fix
    },
  },
})
</script>
