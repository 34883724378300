import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const EmployeeAccountAssignmentPayRateColumns: ColumnInputDefinition[] =
  ['employee', 'account', 'effectiveDate', 'hourlyRate']

export const EmployeeAccountAssignmentPayRateDownloadableColumns: ColumnInputDefinition[] =
  [
    { attributeName: 'employee.name' },
    { attributeName: 'account.name' },
    { attributeName: 'effectiveDate' },
    { attributeName: 'hourlyRate' },
  ]
