<template>
  <div>
    <v-tabs v-model="currentTab" slider-color="orange" dense small short>
      <v-tab v-text="$t('tt-entity-design.main')" />
      <v-tab v-text="$t('tt-entity-design.on-site-information')" />
    </v-tabs>

    <v-container v-if="currentTab === 0">
      <AlarmsFormMainFields
        v-bind="{ alarmTiming, hasLocationCoordinate, defaultPosition }"
        @update:alarmTiming="alarmTiming = $event"
      />
    </v-container>
    <v-container v-if="currentTab === 1">
      <AlarmsFormInformationFields />
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import AlarmsFormMainFields from './AlarmsFormMainFields.vue'
import AlarmsFormInformationFields from './AlarmsFormInformationFields.vue'
import { Resources } from '../../../types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import {
  locationFormService,
  LocationFormServiceType,
  LocationServiceProvider,
} from './location-form-service'
import { ClientInterface } from '../../dispatch-tasks/types'
import { AlarmTiming } from '../types'
import { DATE_FIELDS } from '../constants'
import { Coordinates } from '@tracktik/tt-geo-proxy'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'AlarmsFormCreate',
  components: {
    AlarmsFormMainFields,
    AlarmsFormInformationFields,
  },
  inject: ['formHook'],
  provide(): LocationServiceProvider {
    return {
      locationService: this.locationService,
    }
  },
  data() {
    return {
      currentTab: 0,
      alarmTiming: AlarmTiming.NOW,
    }
  },
  computed: {
    alarmOrganization(): string | null {
      return this.formHook().getPathValue('alarmOrganization')
    },
    alarmConnectionCustomId(): string | null {
      return this.formHook().getPathValue('alarmConnectionCustomId')
    },
    locationService(): LocationFormServiceType {
      return locationFormService()
    },
    hasLocationCoordinate(): boolean {
      return (
        !!this.formHook().getPathValue('location.latitude') &&
        !!this.formHook().getPathValue('location.longitude')
      )
    },
    defaultPosition(): Coordinates {
      return this.locationService.getDefaultCoordinate()
    },
  },
  watch: {
    alarmConnectionCustomId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchClientAddress()
        } else {
          this.setClientItems(value)
        }
      },
    },
    alarmOrganization() {
      this.formHook().setObjectValue('alarmConnectionCustomId', null)
      this.formHook().setObjectValue('alarmTypeCode', null)
      this.formHook().setObjectValue('location', null)
      this.locationService.setCustomLocation(null)
      this.setClientItems(null)
    },
  },
  created() {
    DATE_FIELDS.forEach((field) => {
      this.formHook().setObjectValue(field, AlarmTiming.NOW)
    })
  },
  methods: {
    setClientItems(value: ClientInterface) {
      if (!this.locationService.getIsCustomLocation()) {
        this.formHook().setObjectValue('location', value?.address)
      }
      this.locationService.setClientItems(value)
    },
    async fetchClientAddress() {
      const options = {
        filters: [
          {
            value: this.alarmOrganization,
            operator: FilterOperatorType.IN,
            attribute: 'alarmOrganization',
          },
          {
            value: this.alarmConnectionCustomId,
            operator: FilterOperatorType.IN,
            attribute: 'customId',
          },
        ],
        include: ['client.address', 'client.region'],
      }
      const { items } = await this.$appContext.authModule
        .getApi()
        .getAll<Record<string, ClientInterface>>(
          Resources.ALARM_CONNECTIONS,
          options,
        )
      this.setClientItems(items[0]?.client || null)
    },
  },
})
</script>
