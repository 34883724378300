import { ErrorObject } from '@tracktik/tt-json-schema-form'
import isEmpty from 'lodash/isEmpty'

export const filterErrors = (
  fieldList: string[],
  errorObject: Record<string, ErrorObject[]>,
): Record<string, ErrorObject[]> | null => {
  const filteredErrors = {}

  for (const key in errorObject) {
    if (!fieldList.some((prefix) => key.startsWith(prefix))) {
      filteredErrors[key] = errorObject[key]
    }
  }

  return isEmpty(filteredErrors) ? null : filteredErrors
}
