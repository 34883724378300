<template>
  <div>
    <v-tabs v-model="currentTab" slider-color="orange" dense small short>
      <v-tab v-text="$t('tt-entity-design.on-site-information')" />
    </v-tabs>

    <v-container v-if="currentTab === 0">
      <AlarmsFormInformationFields />
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import AlarmsFormInformationFields from './AlarmsFormInformationFields.vue'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'AlarmsFormEdit',
  components: {
    AlarmsFormInformationFields,
  },
  inject: ['formHook'],
  data() {
    return {
      currentTab: 0,
    }
  },
})
</script>
