<template>
  <div>
    <v-btn
      depressed
      color="#4E69E9"
      dark
      class="ml-2"
      style="text-transform: none"
      @click="dispatchLogEmployeeLeaveForm"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>
<script lang="ts">
import { TTCloudLeaveRequestsIntentTypes } from '@/tt-entity-design/src/components/leave-requests/TTCloudLeaveRequestsActionCreateIntent'
import Vue from 'vue'
export default Vue.extend({
  name: 'LogEmployeeLeaveBtn',
  props: {
    employee: {
      type: Number,
      required: true,
    },
  },
  computed: {
    buttonText(): string {
      return this.$t('tt-entity-design.leave-requests.log-leave-btn')
    },
  },
  methods: {
    dispatchLogEmployeeLeaveForm(): void {
      this.$eventManager.dispatchEvent(TTCloudLeaveRequestsIntentTypes.CREATE, {
        employee: this.employee,
      })
    },
  },
})
</script>
