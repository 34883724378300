<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="14.1 -14.1 196.05 210.33"
    class="tt--logo"
  >
    <g>
      <g>
        <path
          d="M17.06,14.1h179V70.65H149.11v-11h36V25.08H28V60.77h85.38V169.48h-11V71.75H17.06Z"
          transform="translate(0 -14.1)"
        />
        <path
          d="M136.48,43.48v148H78V83.83H89v96.64H125.5v-137Z"
          transform="translate(0 -14.1)"
        />
        <path d="M172.72,48.14h-134v-11h134Z" transform="translate(0 -14.1)" />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TLogo',
})
</script>

<style scoped>
.tt--logo {
  background-color: var(--v-ttPrimary-base);
}

.tt--logo path {
  fill: var(--v-level0-base);
  fill-rule: evenodd;
}
</style>
