// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskOccurrenceSessionLogsTile from './SiteTaskOccurrenceSessionLogsTile.vue'
import SiteTaskOccurrenceSessionLogsColumns from './SiteTaskOccurrenceSessionLogsColumns'
import SiteTaskOccurrenceSessionLogsDetail from './SiteTaskOccurrenceSessionLogsDetail.vue'
import SiteTaskOccurrenceSessionLogsMetaCard from './SiteTaskOccurrenceSessionLogsMetaCard.vue'
import SiteTaskOccurrenceSessionLogsReference from './SiteTaskOccurrenceSessionLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'SiteTaskOccurrenceSessionLogsTile',
      SiteTaskOccurrenceSessionLogsTile,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionLogsDetail',
      SiteTaskOccurrenceSessionLogsDetail,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionLogsMetaCard',
      SiteTaskOccurrenceSessionLogsMetaCard,
    )
    Vue.component(
      'SiteTaskOccurrenceSessionLogsReference',
      SiteTaskOccurrenceSessionLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskOccurrenceSessionLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskOccurrenceSessionLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
