import { BaseField, FieldName } from '../../base/types'

export const NUMBER = FieldName.Number

// export type NumberFieldOptions = {
//   /**
//    * Minimum value (inclusive)
//    */
//   minimum?: number
//   /**
//    * Maximum value (inclusive)
//    */
//   maximum?: number
// }

export type NumberFieldType = BaseField & {
  type: typeof NUMBER
}
