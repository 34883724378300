// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import GuidResourcesTile from './GuidResourcesTile.vue'
import GuidResourcesColumns from './GuidResourcesColumns'
import GuidResourcesDetail from './GuidResourcesDetail.vue'
import GuidResourcesReference from './GuidResourcesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('GuidResourcesTile', GuidResourcesTile)
    Vue.component('GuidResourcesDetail', GuidResourcesDetail)
    Vue.component('GuidResourcesReference', GuidResourcesReference)

    modularManager.addResourcePreset(
      Resources.GUID_RESOURCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'GuidResourcesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GUID_RESOURCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: GuidResourcesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GUID_RESOURCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'GuidResourcesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GUID_RESOURCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'GuidResourcesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
