<template>
  <div v-if="resourceName">
    <CollapsibleHeader>
      <template #title>
        <span class="pl-4 pr-6">
          {{ $t('components.attribute_filter_input.attribute_filters') }}
          <v-badge
            v-if="hasFilters"
            :color="hasErrors ? 'red' : 'green'"
            dot
            inline
          />
        </span>
      </template>
      <v-card flat class="pa-4">
        <ResourceAttributeSelector
          v-model="attributeSelection"
          :resource="resourceName"
          prepend-icon="add"
          :filter="filterAttributes"
          permanent-hint
          hint=""
          :label="$t('components.attribute_filter_input.add_attribute')"
          placeholder=""
          @change="changeAttributeSelection($event)"
        />
        <div>
          <template v-if="hasFilters">
            <div
              v-for="(filter, attribute) in filterList"
              :key="attribute"
              class="level1 pl-2 pr-2"
            >
              <EntityToolbarFilterCollapsibleSection
                opened
                :attribute-meta="filter.attributeMeta"
                :value="filter.filter"
                :resource="resourceName"
                :attributeName="attribute"
                @input="setFilter(attribute, $event)"
              >
                <template #right>
                  <v-tooltip top>
                    {{ $t('components.attribute_filter_input.delete_filter') }}
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click.stop="removeFilter(attribute)"
                      >
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </EntityToolbarFilterCollapsibleSection>
            </div>
          </template>
          <template v-else>
            <CaptionBox>{{
              $t('components.attribute_filter_input.no_filters_selected')
            }}</CaptionBox>
          </template>
        </div>
      </v-card>
    </CollapsibleHeader>
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { VueConstructor } from 'vue'

import EntityToolbarFilterCollapsibleSection from '@/tt-entity-filter/components/EntityToolbarFilterCollapsibleSection.vue'
import { AddAttributeFilter } from '@/plugins/o11n'
import { AttributeFilterInterface } from '@/tt-widget-factory/services/resource-meta/types'
import { FilterSetting } from '@/tt-entity-filter/types'
import { getFilterSetting } from '@/tt-entity-filter/util'
import { isAttributeFilterable } from '@/tt-entity-filter/type-map'

import BaseArrayInput from './BaseArrayInput'
import { Filter, FormHookProvider, ResourceProvider } from '../types'
import { ResourceAttributeSelectorFilter } from '../lib/attribute-list'

export default (
  BaseArrayInput as VueConstructor<
    InstanceType<typeof BaseArrayInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'AttributeFilterInput',
  components: {
    EntityToolbarFilterCollapsibleSection,
  },
  inject: ['formHook', 'resourceProvider'],
  props: {
    resource: String,
  },
  methods: {
    changeAttributeSelection(val: string | null): void {
      const gaEvent = AddAttributeFilter.create({ label: val ?? '' })
      this.$analytics.track(gaEvent)
    },
    removeFilter(attributeName) {
      // Make a copy
      const model = cloneDeep(this.model ?? [])
      // Remove the filters for the same attribute and remove the ones with bad values
      const filtered = model.filter((item: Filter) => {
        return item.attribute !== attributeName
      })

      // Set the model
      this.model = cloneDeep(filtered)
    },
    setFilter(name: string, filter?: Filter) {
      const model: Filter[] = cloneDeep(this.model ?? [])

      const byAttribute = ({ attribute }) => attribute === name
      const index = model.findIndex(byAttribute)

      model[index] = filter

      this.model = cloneDeep(model)
    },
  },
  watch: {
    attributeSelection(val: string | null) {
      // No resource, can't process
      if (!this.resourceName) {
        return
      }
      // No value, we skip
      if (!val) {
        return
      }
      const model = this.model ?? []
      model.push({ attribute: val, operator: 'EQUAL', value: null } as Filter)
      this.model = cloneDeep(model)

      // Reset the selector
      this.$nextTick(() => {
        this.attributeSelection = null
      })
    },
  },
  data() {
    return {
      attributeSelection: null,
    }
  },
  computed: {
    filterAttributes(): ResourceAttributeSelectorFilter {
      const selectedAttributes = (this.model ?? []).map(
        (item: Filter) => item.attribute,
      )
      return function (attr: AttributeFilterInterface): boolean {
        return (
          isAttributeFilterable(attr.attribute) &&
          !selectedAttributes.includes(attr.absoluteName)
        )
      }
    },
    filterList(): { [attribute: string]: FilterSetting } {
      // No filters, we return null
      if (!this.hasFilters) {
        return null
      }
      // Create a map of attribute => filter
      const out = {} as { [attribute: string]: FilterSetting }

      this.model.forEach((filter: Filter) => {
        out[filter.attribute] = getFilterSetting(
          this.$appContext.widgetServices.resourceMetaManager,
          this.resourceName,
          filter,
        )
      })
      return out
    },
    hasFilters(): number {
      return this.model && this.model.length
    },
    resourceName(): any {
      return this.resourceProvider.resource
    },
  },
})
</script>
