import Vue, { VueConstructor } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import DataTableWidgetHook from './DataTableWidgetHook'
import { DataTableWidgetModel } from '../../schemas-types'

import BaseWidget from '@/tt-widget-components/BaseWidget.vue'

export type DataTableSelection = number[]

export type DataTableWidgetRecordClickHandler = (
  hook: DataTableWidgetHook,
  itemHook?: EntityItemHook,
) => void

export const GridSelectionMode: Record<
  string,
  DataTableWidgetModel['selectionMode']
> = {
  SINGLE: 'Single',
  MULTIPLE: 'Multiple',
} as const

export type DatatableComponent = VueConstructor<Vue & ExpandedRowProvider> &
  typeof BaseWidget

export type EntityId = number

export type RowModel = {
  entityId: EntityId
  isExpandedRow?: boolean
}

export type ExpandedRowProvider = {
  /**
   * Injected value used so that the sub-datatable knows it is an expanded row.
   */
  isExpandedRowDatatable?: boolean
}
