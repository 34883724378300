import { ContextManagerInterface } from '@/tt-widget-factory'
import { createField } from '@/tt-widget-factory/services/resource-meta/Fields'
import {
  getExtensionName,
  isExtension,
  isNotExtension,
} from '@/tt-widget-factory/services/resource-meta/Extensions'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'

import BaseQueryManager, { BaseQueryManagerOptions } from './BaseQueryManager'
import { CollectionQuery, removeNullKeys, Field, Sort } from '../types'

/**
 * Manager that handles the collection queries' filters
 */
export default class QueryManager extends BaseQueryManager<CollectionQuery> {
  fields?: Field[]
  extensions?: string[]

  /**
   * @todo: Remove props if not used: FE-1539
   */
  limit? = 20
  offset?: number
  sort?: Sort[]

  constructor(
    query: CollectionQuery,
    contextManager: ContextManagerInterface,
    options: BaseQueryManagerOptions,
  ) {
    super(query, contextManager, options)
  }

  get query(): CollectionQuery {
    return removeNullKeys({
      ...this.initialQuery,
      filters: this.filters,
      whereQL: this.whereQL,
      includeInactive: this.includeInactive,
      search: this.search,
      fields: this.fields,
      scope: this.scope,
      extension: this.extensions?.length ? this.extensions : null,
      limit: this.initialQuery.limit ?? this.limit,
      offset: this.initialQuery.offset ?? this.offset,
      sort: this.initialQuery.sort ?? this.sort,
    } as CollectionQuery)
  }

  setFieldsAndExtensionsFromAttributes(attributes: string[]): void {
    const fields = attributes.filter(isNotExtension).map(createField)
    const extensions = attributes.filter(isExtension).map(getExtensionName)

    this.setFields(fields)
    this.setExtensions(extensions)
  }

  private setFields(fields: Field[]): void {
    this.fields = [...fields]
  }

  private setExtensions(extensions: string[]): void {
    this.extensions = [...extensions]
  }

  setOffset(offset: number): void {
    this.state.initialQuery = {
      ...this.state.initialQuery,
      offset,
    }
  }

  setLimit(limit: number): void {
    this.state.initialQuery = {
      ...this.state.initialQuery,
      limit,
    }
  }

  setSort(sort: Sort[]): void {
    const { sort: _, ...initialQuery } = this.state.initialQuery
    this.state.initialQuery = {
      ...initialQuery,
      ...(sort.length > 0 ? { sort } : {}),
    }
  }

  getCsvQuery(): CollectionQuery {
    return {
      ...this.query,
      limit: TTC_API_MAX_LIMIT,
      offset: 0,
    }
  }
}
