import { DefaultToolbar } from '@/tt-widget-components/schemas-types'

export const DEFAULT_TOOLBAR: DefaultToolbar = {
  show: true,
  displayCounts: false,
  filterOptions: {
    allowSearch: false,
    allowScopes: true,
    filters: [],
  },
}

export const COLORS = ['ttPrimary', 'success', 'info', 'ttWarning', 'error']
