<template>
  <div>
    <json-field name="language" as="LanguageSelectorField" />
    <json-field name="name" />
    <json-field name="instructions" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetTypesAddTranslationForm',
})
</script>
