// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BreakRuleRelationsTile from './BreakRuleRelationsTile.vue'
import BreakRuleRelationsColumns from './BreakRuleRelationsColumns'
import BreakRuleRelationsDetail from './BreakRuleRelationsDetail.vue'
import BreakRuleRelationsReference from './BreakRuleRelationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BreakRuleRelationsTile', BreakRuleRelationsTile)
    Vue.component('BreakRuleRelationsDetail', BreakRuleRelationsDetail)
    Vue.component('BreakRuleRelationsReference', BreakRuleRelationsReference)

    modularManager.addResourcePreset(
      Resources.BREAK_RULE_RELATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BreakRuleRelationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_RELATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BreakRuleRelationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_RELATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleRelationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_RELATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BreakRuleRelationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
