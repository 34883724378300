<template>
  <tr :class="rowClass">
    <TQLRowCell v-for="(column, colIndex) in columns"
                :key="`cell_${colIndex}_${colIndex}`"
                :column-index="colIndex"
                :column="column"
                :columns="columns"
                :row-data="rowData"
    />
  </tr>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import TQLRowCell from './TQLRowCell.vue'
import {TQLColumn} from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";
import {MarkupAttributes, MarkupClasses} from "@/tt-widget-components/widgets/TQLQuery/markup";

export default Vue.extend({
  name: 'TQLRow',
  components: {
    TQLRowCell
  },
  computed: {
    rowClass(): string {
      let cssClass = (this.rowData[MarkupAttributes.SECTION_SUB_TOTAL] ?? false) == "1" ? MarkupClasses.ROW_SUBTOTAL : '';
      return cssClass + (this.rowData[MarkupAttributes.ROW_CLASS] || '')
    },
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    columns: {
      type: Array as PropType<TQLColumn[]>,
      required: true
    }
  }
})
</script>
