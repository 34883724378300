<template>
  <v-tooltip top :open-delay="tooltipOpenDelay">
    <template #activator="{ on }">
      <v-icon
        color="ttPrimary"
        small
        class="pl-2"
        v-on="on"
        v-text="'mdi-clock-outline'"
      />
    </template>
    <span class="pr-1" v-text="$t(tooltipText)" />
    <tt-attr :name="dateName" format="asTime" />
  </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'ClockTooltip',
  props: {
    dateName: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
  },
})
</script>
