import { inject } from 'vue'

import { AuthModule } from '@tracktik/tt-authentication'

import { AssetManager } from './types'
import { createSubAssetsFetcher, fetchTaskSiteSettings } from './helpers'
import { createAssetManager } from './AssetManager'

export const prepareAssetManager = async (deps: {
  clientId: number
  taskTypeId: number
  auth: AuthModule
}): Promise<AssetManager> => {
  const { auth, clientId, taskTypeId } = deps

  const taskSiteSettings = await fetchTaskSiteSettings(auth, clientId)
  const taskInstructions = taskSiteSettings?.taskInstructions

  const fetchSubAssets = createSubAssetsFetcher(auth)

  return createAssetManager({
    taskInstructions,
    taskTypeId,
    fetchSubAssets,
  })
}

export const AssetManagerName = 'assetManager'

export const useAssetManager = () => {
  return inject<() => AssetManager>(AssetManagerName)
}
