import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'name',
    headerText: 'tt-entity-design.checkpoints.attrs.name',
  },
  // todo: tour
  'account',
  // todo: last scanned
  'position',
  {
    attributeName: 'instructions',
    headerText: 'tt-entity-design.checkpoints.attrs.instructions',
  },
  {
    attributeName: 'active',
    headerText: 'Status',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'active',
        truthy: {
          textKey: 'tt-entity-design.checkpoints.attr-values.active.true',
        },
        falsy: {
          textKey: 'tt-entity-design.checkpoints.attr-values.active.false',
        },
      },
    },
  },
] as ColumnInputDefinition[]
