// TQL markups are configurations that helps style the TQL Query Widget results
// # @Template( name="MY_TEMPLATE" class="my-class" style="")
// # @Columns( align="L,L,,,,,R" sizes="20%,,,")

export interface TQLMarkupColumns {
  align?: string[] | string
  sizes?: string[] | string
}

export interface TQLMarkupTemplate {
  class?: string
  style?: string
  name?: string
}

// <template align=e"L,L,,,,,R" sizes="20%,,,"/>
export interface TQLMarkup {
  columns?: TQLMarkupColumns
  template?: TQLMarkupTemplate

  [x: string]: any
}

export enum MarkupClassAliases {
  L = "text-left",
  R = "text-right",
  C = "text-center"
}

export enum MarkupClasses{
  ROW_SUBTOTAL = 'row-subtotal',
}

export enum MarkupAttributes {
  SECTION_TITLE = '__section_title',
  SECTION_SUB_TITLE = '__section_subtitle',
  SECTION_SUB_TOTAL = '__section_subtotal',
  CELL_STYLE_PREFIX = '__cell_style_',
  CELL_CLASS_PREFIX = '__cell_class_',
  CELL_TOOLTIP_PREFIX = '__cell_tooltip_',
  ROW_CLASS = '__row_class'
}

export function markup(tql: string): TQLMarkup {

  // Split by lines and see if the line starts with a #
  const lines = tql
    .split('\n')
    .map(l => l.trim())
    .filter(l => l.startsWith('#'))
    .map(l => l.substring(1).trim())
    .filter(l => l.length > 0 && l.startsWith('@') && l.endsWith(')'))
    .map(l => {
      return l.replace(/@(\w+)\((.*?)\)/, function (_, tagName, attributes) {
        return `<${tagName.toLowerCase()} ${attributes.trim()} />`;
      });
    })
  if (lines.length === 0) {
    return {}
  }

  const out = {} as TQLMarkup
  for (const line of lines) {
    const parser: DOMParser = new DOMParser();
    const htmlDoc: Document = parser.parseFromString(line, 'text/xml');
    // Check if the line is a valid XML
    if (htmlDoc.documentElement === null) {
      continue
    }

    // Parse the attributes of the XML
    out[htmlDoc.documentElement.tagName] = Array.from(htmlDoc.documentElement.attributes).reduce((acc, attr) => {
      acc[attr.name] = attr.value;
      return acc;
    }, {});
  }

  if (out.columns && out.columns.align && typeof out.columns.align == 'string') {
    out.columns.align = out.columns.align.split(",").map((a: string) => MarkupClassAliases[a as keyof typeof MarkupClassAliases] ?? a)
  }
  if (out.columns && out.columns.sizes && typeof out.columns.sizes == 'string') {
    out.columns.sizes = out.columns.sizes.split(",")
  }

  return out as TQLMarkup;
}


export interface TQLSection {
  title: string,
  subTitle?: string,
  rowData: any[],
  footData?: any[]
}

export function rowsToSections(rowData: any[]): TQLSection[] {

  const sections: TQLSection[] = [];

  let sectionCursor = null as TQLSection | null;
  rowData.forEach((row) => {
    const title = row[MarkupAttributes.SECTION_TITLE] ?? ''
    const prevTitle = sectionCursor ? sectionCursor.title : null;
    // No section cursor
    if (!sectionCursor || (title !== prevTitle)) {

      if (sectionCursor) {
        sections.push(sectionCursor)
      }
      sectionCursor = {
        title: title,
        subTitle: row[MarkupAttributes.SECTION_SUB_TITLE] ?? null,
        rowData: []
      }
    }

    // Subtitle can come from any rows
    if(!sectionCursor.subTitle && (row[MarkupAttributes.SECTION_SUB_TITLE] ?? null)){
      sectionCursor.subTitle = row[MarkupAttributes.SECTION_SUB_TITLE]
    }

    sectionCursor.rowData.push(row)
  })

  if (sectionCursor) {
    sections.push(sectionCursor)
  }
  return sections

}
