// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DispatchEtaLogsTile from './DispatchEtaLogsTile.vue'
import DispatchEtaLogsColumns from './DispatchEtaLogsColumns'
import DispatchEtaLogsDetail from './DispatchEtaLogsDetail.vue'
import DispatchEtaLogsMetaCard from './DispatchEtaLogsMetaCard.vue'
import DispatchEtaLogsReference from './DispatchEtaLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DispatchEtaLogsTile', DispatchEtaLogsTile)
    Vue.component('DispatchEtaLogsDetail', DispatchEtaLogsDetail)
    Vue.component('DispatchEtaLogsMetaCard', DispatchEtaLogsMetaCard)
    Vue.component('DispatchEtaLogsReference', DispatchEtaLogsReference)

    modularManager.addResourcePreset(
      Resources.DISPATCH_ETA_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DispatchEtaLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_ETA_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DispatchEtaLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_ETA_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchEtaLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_ETA_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DispatchEtaLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DISPATCH_ETA_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DispatchEtaLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
