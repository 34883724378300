// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EventsSubscriptionsTile from './EventsSubscriptionsTile.vue'
import EventsSubscriptionsColumns from './EventsSubscriptionsColumns'
import EventsSubscriptionsDetail from './EventsSubscriptionsDetail.vue'
import EventsSubscriptionsMetaCard from './EventsSubscriptionsMetaCard.vue'
import EventsSubscriptionsReference from './EventsSubscriptionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EventsSubscriptionsTile', EventsSubscriptionsTile)
    Vue.component('EventsSubscriptionsDetail', EventsSubscriptionsDetail)
    Vue.component('EventsSubscriptionsMetaCard', EventsSubscriptionsMetaCard)
    Vue.component('EventsSubscriptionsReference', EventsSubscriptionsReference)

    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EventsSubscriptionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
