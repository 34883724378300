import { RouteConfig } from 'vue-router'

const SignInSso = () => import('./views/SignInSso.vue')
const SignInOauth = () => import('./views/SignInOauth.vue')
const NotFoundView = () => import('./views/NotFoundView.vue')

export type SsoQueryParams =
  | 'url'
  | 'token'
  | 'path'
  | 'account_id'
  | 'locale'
  | 'dark'

export const RouteSSO: RouteConfig = {
  path: '/sign-in-sso',
  component: SignInSso,
  name: 'single-sign-on',
  props: (route) => {
    // valid query params for the SSO route
    const { url, token, path, account_id, locale, dark } = route.query

    return {
      domainUrl: url,
      token,
      redirectionPath: path,
      accountId: account_id,
      locale,
      dark,
    }
  },
}

export const RouteOAuth: RouteConfig = {
  path: '/sign-in-oauth',
  component: SignInOauth,
  name: 'oauth',
  props: (route) => {
    // valid query params for the Oauth2 route
    const {
      url,
      token,
      path,
      account_id,
      locale,
      client_id,
      client_secret,
      dark,
    } = route.query
    return {
      domainUrl: url,
      token,
      redirectionPath: path,
      accountId: account_id,
      locale,
      clientId: client_id,
      clientSecret: client_secret,
      dark,
    }
  },
}

/**
 * /!\ Will match all route paths, so it needs to be inserted as the last route into the router /!\
 */
export const Route404: RouteConfig = {
  path: '/*',
  component: NotFoundView,
  name: '404-not-found',
}
