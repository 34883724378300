import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'
import { DocumentProviders } from '@/tt-entity-documents/types'
import { AppContext } from '@/tt-app-context'
import i18n from '@/plugins/i18n'
import {
  DocumentServiceInput,
  DocumentServiceTemplateName,
} from '@/tt-entity-documents/providers/DocumentServiceProvider'
import { DocumentPresetInterface } from '@/tt-app-modular'

const DataViewDocument: DocumentPresetInterface<DocumentServiceInput> = {
  name: 'DataViews',
  async toDocument(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
  ): Promise<DocumentServiceInput> {
    return {
      options: {
        title: entityReference.entity?.title,
      },
      provider: DocumentProviders.DOCUMENT_SERVICE,
      documentName: 'Export Dashboard',
      locale: i18n.locale,
      entityReference: entityReference,
      data: {
        templateName: DocumentServiceTemplateName.DASHBOARD,
      },
    }
  },
}

export default DataViewDocument
