import { ComponentModel } from '@syncfusion/ej2-base'

import { WidgetHookInterface } from '@/tt-widget-factory'

export enum SyncFusionComponentTag {
  AccumulationChart = 'ejs-accumulationchart',
  Chart = 'ejs-chart',
  CircularGague = 'ejs-circulargauge',
  HeatMap = 'ejs-heatmap',
  Schedule = 'ejs-schedule',
  TreeMap = 'ejs-treemap',
}

export interface SyncFusionComponentData<SFModel extends ComponentModel> {
  is: SyncFusionComponentTag
  props: SFModel
}

export interface WidgetContext {
  content?: Record<string, string>
  dark: boolean
  height?: number
  locale?: string
  width?: number
}

export type WidgetSyncFusionModelAdapter<
  Hook extends WidgetHookInterface,
  SFModel extends ComponentModel,
  WC extends WidgetContext = WidgetContext,
> = (hook: Hook, context: WC) => SFModel

export type WidgetSyncFusionComponentAdapter<
  Hook extends WidgetHookInterface,
  SFModel extends ComponentModel,
  WC extends WidgetContext = WidgetContext,
> = (hook: Hook, context: WC) => SyncFusionComponentData<SFModel>
