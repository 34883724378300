<template>
  <span>
    <RelationField
      v-model="model"
      v-bind="{
        resource,
        disabled: isReadOnly,
        ...$props,
        ...$attrs,
      }"
      @blur="$emit('blur')"
    />
    <v-card v-if="hasFields" class="pa-3 ma-3">
      <!-- we only show the report-flag schema, but we bind everything to the main report form -->
      <JsonForm
        v-bind="{ schema, formOptions, value: subFormValue }"
        @debouncing="formHook().setIsDebouncing($event)"
        @errors="setSubFormErrors"
        @input="setSubFormValue"
        @update:hook="subFormHook = $event"
        @valid="clearSubFormErrors"
      />
    </v-card>
  </span>
</template>

<script lang="ts">
import merge from 'lodash/merge'
import { VueConstructor } from 'vue'

import {
  DefinitionOption,
  ErrorMap,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'
import { EntityRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { FormHook } from '@tracktik/tt-json-schema-form'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import { CompoundFormManagerProvider } from '@/tt-app-layout'
import { FormHookProvider } from '@/tt-widget-components'

// @todo: add type to tt-sdk
type ReportFlagJsonFormSchemaExtension = {
  schema: JSONSchema7
  values: any
  formOptions: FormOptions
  callback: {
    method: string
    url: string
  }
}

type Response = {
  jsonFormSchema?: ReportFlagJsonFormSchemaExtension
}

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> &
    FormHookProvider &
    CompoundFormManagerProvider
>

const queryOptions: EntityRequestOptions = {
  extension: ['jsonFormSchema'],
}
const EmptyComponent: DefinitionOption = {
  view: { is: '' },
}

export default (BaseInput as VueWithInjections).extend({
  name: 'ReportFlagForm',
  inject: ['formHook', 'getFormManager'],
  props: {
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    formOptions: null as FormOptions | null,
    schema: null as JSONSchema7 | null,
    subFormHook: null as FormHook | null,
  }),
  computed: {
    fields(): Record<string, JSONSchema7> {
      return this.schema?.definitions?.reportFields?.properties || {}
    },
    fieldNames(): string[] {
      return Object.keys(this.fields)
    },
    formHookFieldNames(): string[] {
      if (!this.subFormHook) return []
      return Object.keys(this.subFormHook.fieldMap)
    },
    formId(): string {
      return this.$options.name
    },
    hasFields(): boolean {
      return this.fieldNames.length > 0
    },
    subFormValue(): Record<string, unknown> {
      return this.getFormManager().getFormValue(this.formId) ?? {}
    },
    isReadOnly(): boolean {
      return this.formHook().isReadOnly() || false
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.fetchSchema(this.value)
        } else {
          this.resetData()
        }
      },
    },
  },
  methods: {
    setData(schema: JSONSchema7, formOptions: FormOptions) {
      if (!schema) return this.resetData()

      this.schema = schema
      this.formOptions = merge({}, this.formHook().formOptions, formOptions)

      // a report-flag form should not be shown in another report-flag
      this.formOptions.definitions.ReportFlag = EmptyComponent
    },
    resetData() {
      this.schema = null
      this.formOptions = null
    },
    clearSubFormErrors(): void {
      this.setSubFormErrors({})
    },
    fetchSchema(value: string | number) {
      const updateSchemaAndOptions = ({ jsonFormSchema }: Response) => {
        const { schema, formOptions } = jsonFormSchema || {}
        // not all report-flags have a sub-form
        if (schema) this.setData(schema, formOptions)
        else this.resetData()
      }

      const onError = (err) => {
        this.$crash.captureException(err)
        this.resetData()
      }

      this.$appContext.authModule
        .getApi()
        .get(this.resource, value, queryOptions)
        .then(updateSchemaAndOptions)
        .catch(onError)
    },
    setSubFormErrors(errors: ErrorMap): void {
      this.getFormManager().setFormErrors(this.formId, errors)
    },
    setSubFormValue(value: Record<string, unknown>): void {
      this.getFormManager().setFormValue(this.formId, value)
    },
  },
})
</script>
