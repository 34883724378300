<template>
  <tt-attr v-slot="{ rawValue: dateIn }" name="startedOn">
    <tt-attr v-slot="{ rawValue: dateOut }" name="endedOn">
      <template v-if="dateOut">
        {{ prefix }}
        {{ getDifference(dateIn, dateOut, 'minutes') }}
        {{
          $tc('tt-entity-design.min', getDifference(dateIn, dateOut, 'minutes'))
        }}
      </template>
    </tt-attr>
  </tt-attr>
</template>

<script lang="ts">
import Vue from 'vue'
import { getDifference } from '@/helpers/formats/dates'

export default Vue.extend({
  name: 'ActualDurationInMinuteAttr',
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  methods: {
    getDifference,
  },
})
</script>
