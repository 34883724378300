import { Resources } from '@/tt-entity-design/src/types'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { AuthModule } from '@tracktik/tt-authentication'
import { ContextManagerInterface } from '../types'

export type ContextAccount = {
  id: number
  timeZone: string
}

type AccoundId = string | number

type ContextAccountHandlerDependencies = {
  authModule: AuthModule
  contextManager: ContextManagerInterface
}

const { ACCOUNT_ATTRIBUTE } = AttributeName

/**
 * Service to get/set the context account.
 *
 * Sets the account as a filter into the main ContextManager,
 * and sets the account timezone into the authModule to be used throughout the app (in place of the user session timezone).
 */
export const ContextAccountHandler = ({
  authModule,
  contextManager,
}: ContextAccountHandlerDependencies) => {
  const setAccountFilter = (account: ContextAccount) => {
    contextManager.setContextFilter({
      attribute: ACCOUNT_ATTRIBUTE,
      operator: 'EQUAL',
      value: account.id,
    })

    return account
  }

  const removeAccountFilter = () =>
    contextManager.removeContextFilter(ACCOUNT_ATTRIBUTE)

  const setTimezone = (account: ContextAccount) => {
    authModule.setCustomTimezone(account.timeZone)

    return account
  }

  const fetchAccount = (id: AccoundId) =>
    authModule.getApi().get<AccoundId, ContextAccount>(Resources.ACCOUNTS, id, {
      fields: [{ attribute: 'id' }, { attribute: 'timeZone' }],
    })

  return {
    get: (): number | undefined => {
      const contextFilter = contextManager.getContextFilter(ACCOUNT_ATTRIBUTE)

      return contextFilter?.value as number
    },
    set: async (accountId: AccoundId): Promise<ContextAccount | undefined> => {
      if (!accountId) {
        removeAccountFilter()

        return
      }

      return fetchAccount(accountId)
        .then(setAccountFilter)
        .then(setTimezone)
        .catch((err) => {
          console.error(err)
          throw err
        })
    },
  }
}
