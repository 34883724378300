import { Status } from './types'

const STATUS_COLOR_MAP: Record<Status, string> = {
  INITIATED: 'blue',
  STARTED: 'success',
  ENDED: 'grey',
  ENDED_INCOMPLETED: 'red',
  CANCELLED: 'yellow',
  PAUSED: 'orange',
}

const DEFAULT_STATUS_COLOR = 'grey'

export const getStatusColor = (status: string) =>
  STATUS_COLOR_MAP[status] || DEFAULT_STATUS_COLOR
