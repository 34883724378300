<template>
  <div :class="['text-subtitle-2 mt-5', { disabled, 'mb-2': !!label }]">
    {{ label }}
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SectionDivider',
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
  },
})
</script>
