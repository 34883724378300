// Enter your custom installation here

import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    // Add the schedule preview in the tabs
    addPreviewTab(Resources.DEPARTMENTS, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })
  },
}
