// Enter your custom installation here

import { setIconAndColorResourcePreset } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    setIconAndColorResourcePreset(
      Resources.ASSET_CUSTOM_FIELDS,
      'mdi-format-list-bulleted',
      'blue darken-3',
    )
  },
}
