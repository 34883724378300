// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import HazardAlertsTile from './HazardAlertsTile.vue'
import HazardAlertsColumns from './HazardAlertsColumns'
import HazardAlertsDetail from './HazardAlertsDetail.vue'
import HazardAlertsMetaCard from './HazardAlertsMetaCard.vue'
import HazardAlertsReference from './HazardAlertsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('HazardAlertsTile', HazardAlertsTile)
    Vue.component('HazardAlertsDetail', HazardAlertsDetail)
    Vue.component('HazardAlertsMetaCard', HazardAlertsMetaCard)
    Vue.component('HazardAlertsReference', HazardAlertsReference)

    modularManager.addResourcePreset(
      Resources.HAZARD_ALERTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'HazardAlertsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HAZARD_ALERTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: HazardAlertsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HAZARD_ALERTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'HazardAlertsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HAZARD_ALERTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'HazardAlertsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HAZARD_ALERTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'HazardAlertsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
