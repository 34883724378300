// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplatesTile from './ReportTemplatesTile.vue'
import ReportTemplatesColumns from './ReportTemplatesColumns'
import ReportTemplatesDetail from './ReportTemplatesDetail.vue'
import ReportTemplatesMetaCard from './ReportTemplatesMetaCard.vue'
import ReportTemplatesReference from './ReportTemplatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportTemplatesTile', ReportTemplatesTile)
    Vue.component('ReportTemplatesDetail', ReportTemplatesDetail)
    Vue.component('ReportTemplatesMetaCard', ReportTemplatesMetaCard)
    Vue.component('ReportTemplatesReference', ReportTemplatesReference)

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
