// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetSessionsTile from './MobileRunsheetSessionsTile.vue'
import MobileRunsheetSessionsColumns from './MobileRunsheetSessionsColumns'
import MobileRunsheetSessionsDetail from './MobileRunsheetSessionsDetail.vue'
import MobileRunsheetSessionsMetaCard from './MobileRunsheetSessionsMetaCard.vue'
import MobileRunsheetSessionsReference from './MobileRunsheetSessionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileRunsheetSessionsTile', MobileRunsheetSessionsTile)
    Vue.component('MobileRunsheetSessionsDetail', MobileRunsheetSessionsDetail)
    Vue.component(
      'MobileRunsheetSessionsMetaCard',
      MobileRunsheetSessionsMetaCard,
    )
    Vue.component(
      'MobileRunsheetSessionsReference',
      MobileRunsheetSessionsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetSessionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
