import { normalizeText, removeSpecialCharacters } from '.'

export type ResourceItemBasic = {
  text: string
  value: string
}

export const customSearchFilter = (
  item: ResourceItemBasic,
  queryText: string,
) => {
  const normalizedResource = normalizeText(`${item.text}${item.value}`)
  const normalizedQuery = normalizeText(queryText)

  const reformatValue = (resource: string) =>
    resource.toLowerCase().replace(/\s/g, '')

  return reformatValue(removeSpecialCharacters(normalizedResource)).includes(
    reformatValue(removeSpecialCharacters(normalizedQuery)),
  )
}
