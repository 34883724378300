<template>
  <v-row no-gutters>
    <v-col cols="8">
      <ResourceAttributeSelector
        v-model="attribute"
        clearable
        :label="$t('widget_edit.default_sort.attribute')"
        @change="changeAttribute($event)"
      />
    </v-col>
    <v-col class="pl-4">
      <SortDirectionInput v-model="direction" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AddSortAttribute } from '@/plugins/o11n'
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import { ResourceAttributeName, Sort, SortDirection } from '../schemas-types'
import { cloneDeep } from 'lodash'

export default ObjectInput.extend({
  name: 'QuerySortInput',
  data() {
    return {
      direction: null,
      attribute: null,
    }
  },
  watch: {
    direction(val: SortDirection) {
      if (this.attribute) {
        this.model = { attribute: this.attribute, direction: val }
      }
    },
    attribute(val: ResourceAttributeName | null) {
      if (!val) {
        this.model = null
      } else {
        this.model = { attribute: val, direction: this.direction }
      }
    },
  },
  methods: {
    changeAttribute(val: ResourceAttributeName | null): void {
      const gaEventLabel = val ? `${val}_${this.direction}` : ''
      const gaEvent = AddSortAttribute.create({ label: gaEventLabel })
      this.$analytics.track(gaEvent)
    },
    onInputNull(): void {
      this.attribute = null
    },
    onInput(val: Partial<Sort>): void {
      if (val && val.attribute) {
        this.attribute = val.attribute
        this.direction = val.direction
      }
    },
    setModel(input: any) {
      if (input) {
        this.model = cloneDeep({ ...input })
      } else {
        this.model = null
      }
    },
  },
  computed: {
    emitNullOnDefault(): true {
      return true
    },
    defaultModel(): null {
      return null
    },
  },
})
</script>
