<template>
  <div>
    <json-field v-if="hasRegionValue" name="isAvailableInSubRegion" />
    <json-field name="description" as="TextAreaField" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'SystemExceptionTicketInstructionsCreateForm',
  inject: ['formHook'],
  computed: {
    formHook() {
      return this.formHook()
    },
    hasRegionValue(): boolean {
      return !!this.formHook().getPathValue('region')
    },
  },
})
</script>
