<template>
  <v-card v-if="formHook" color="transparent" flat>
    <json-field as="WidgetEditTitle" name="title" />
    <v-tabs
      v-model="tab"
      slider-color="orange"
      class="transparent"
      background-color="transparent"
      dense
      :height="40"
    >
      <v-tab
        v-for="item in tabs"
        :key="item.text"
        :disabled="item.needsResource && !resource"
        v-text="$t(item.text)"
      />
    </v-tabs>
    <v-card flat color="transparent">
      <div class="mt-6">
        <slot :tab="tab"></slot>
      </div>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import BaseObjectInput from '@/tt-widget-components/components/BaseObjectInput'
import { PropType, VueConstructor } from 'vue'
import { JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { ResourceProvider, FormHookProvider } from '..'

export interface WidgetTabEditorTab {
  text: string
  needsResource: boolean
}

export default (
  BaseObjectInput as VueConstructor<
    InstanceType<typeof BaseObjectInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'WidgetTabEditor',
  inject: ['formHook', 'resourceProvider'],
  props: {
    defaultSchema: {
      type: Object as PropType<JSONSchema7>,
    },
    tabs: {
      required: true,
      type: Array as PropType<WidgetTabEditorTab[]>,
    },
  },
  computed: {
    resource(): any {
      return this.resourceProvider.resource
    },
    defaultModel(): JSONSchema7 | {} {
      return this.defaultSchema ?? {}
    },
  },
  data() {
    return {
      tab: 0,
    }
  },
})
</script>
