import { AppContext } from '@/tt-app-context'
import { compact } from 'lodash'
import {
  SharableServiceInterface,
  UserGroupItem,
  UserGroupEndpoints,
  UserGroupItemTypes,
  UserGroupMap,
  AclRule,
} from './types'

/**
 * Implementation of the SharableServiceInterface
 */
export class ShareService implements SharableServiceInterface {
  appContext: AppContext

  /**
   * @param appContext
   */
  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  /**
   * Get the HTTP Client
   */
  private get httpClient() {
    return this.appContext.authModule.getApi().httpClient
  }

  /**
   * Search for users and groups
   * @param keyword
   */
  search(keyword: string): Promise<UserGroupItem[]> {
    return this.httpClient
      .get(
        `${UserGroupEndpoints.SEARCH}?keyword=${encodeURIComponent(keyword)}`,
      )
      .then((response): UserGroupItem[] => {
        const results = compact<UserGroupItem>(response.data.data)
        return results.sort((item: UserGroupItem) => {
          return item.type == UserGroupItemTypes.USER ? -1 : 1
        }) as UserGroupItem[]
      })
  }

  /**
   * Translate tags
   * @param tags
   */
  translateTags(tags: string[]): Promise<UserGroupMap> {
    return this.httpClient
      .get(
        `${UserGroupEndpoints.TRANSLATE}?tags=${encodeURIComponent(
          tags.join(','),
        )}`,
      )
      .then((response) => {
        return response.data.data as UserGroupMap
      })
  }

  /**
   * Translate a rule by sending all of the
   * @param rule
   */
  translateRule(rule: AclRule, ownerTag?: string): Promise<UserGroupMap> {
    if (!rule.deny) {
      rule.deny = {}
    }
    if (!rule.grant) {
      rule.grant = {}
    }

    const grantKeys = Object.keys(rule.grant)
    const denyKeys = Object.keys(rule.deny)
    const tags = [...grantKeys, ...denyKeys]
    if (ownerTag) {
      tags.push(ownerTag)
    }
    return this.translateTags(tags)
  }

  /**
   * Return the user groups
   */
  getUserGroups(): Promise<UserGroupMap> {
    return this.httpClient
      .get(UserGroupEndpoints.MY_USER_GROUPS)
      .then((response) => {
        return response.data.data as UserGroupMap
      })
  }

  hasWriteAccess(
    grantObj: AclRule['grant'] | undefined,
    myUserGroups: string[],
  ): boolean {
    if (!grantObj) {
      return false
    }

    return myUserGroups
      .filter((userGroup) => Object.keys(grantObj ?? []).includes(userGroup))
      .reduce(
        (canEdit, role) => grantObj[role].permission === 'EDITOR' || canEdit,
        false,
      )
  }
}
