<template>
  <div class="cta-contacts--container ma-2">
    <ContactList />
    <EmergencyContactsList />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import ContactList from './contacts/ContactsList.vue'
import EmergencyContactsList from './emergencyContacts/EmergencyContactsList.vue'
export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsContactsListWidget',
  components: {
    ContactList,
    EmergencyContactsList,
  },
  inject: ['getItemHook'],
  async created() {
    this.getItemHook().addAttribute('relation')
    this.getItemHook().addAttribute('region')
    this.getItemHook().addAttribute('userRelation')
    this.getItemHook().addAttribute('userRelation.id')
    this.getItemHook().addAttribute('userRelation.name')
    this.getItemHook().addAttribute('userRelation.jobTitle')
    this.getItemHook().addAttribute('userRelation.primaryPhone')
    this.getItemHook().addAttribute('accountRelation')
    // Contacts
    this.getItemHook().addAttribute('accountRelation.contacts.firstName')
    this.getItemHook().addAttribute('accountRelation.contacts.lastName')
    this.getItemHook().addAttribute('accountRelation.contacts.jobTitle')
    this.getItemHook().addAttribute('accountRelation.contacts.primaryPhone')
    // Emergency Contacts
    this.getItemHook().addAttribute(
      'accountRelation.emergencyContactAssignments.contact.firstName',
    )
    this.getItemHook().addAttribute(
      'accountRelation.emergencyContactAssignments.contact.lastName',
    )
    this.getItemHook().addAttribute(
      'accountRelation.emergencyContactAssignments.contact.jobTitle',
    )
    this.getItemHook().addAttribute(
      'accountRelation.emergencyContactAssignments.contact.primaryPhone',
    )
  },
})
</script>
<style scoped>
.cta-contacts--container {
  height: inherit !important;
}
.cta-contacts--container ::v-deep .attribute-row-label {
  color: #888 !important;
  font-size: 0.8em !important;
}
.cta-contacts--container ::v-deep .attribute-row-value {
  font-size: 0.8em !important;
}

.cta-contacts--container ::v-deep .contact-details--title {
  color: #888 !important;
  font-size: 0.9em !important;
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
