import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'payrollSchedule',
  'status',
  'occurrenceStartDate',
  'occurrenceEndDate',
  'countEmployees',
  'totalHours',
  'totalCost',
] as ColumnInputDefinition[]
