// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import UnitsTile from './UnitsTile.vue'
import UnitsColumns from './UnitsColumns'
import UnitsDetail from './UnitsDetail.vue'
import UnitsMetaCard from './UnitsMetaCard.vue'
import UnitsReference from './UnitsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('UnitsTile', UnitsTile)
    Vue.component('UnitsDetail', UnitsDetail)
    Vue.component('UnitsMetaCard', UnitsMetaCard)
    Vue.component('UnitsReference', UnitsReference)

    modularManager.addResourcePreset(Resources.UNITS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'UnitsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.UNITS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: UnitsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.UNITS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'UnitsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.UNITS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'UnitsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.UNITS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'UnitsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
