import {
  FormatterInput,
  FormatterCallbacksDictionary,
} from '@/helpers/formats/types'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import { ApiDateTimeFormats, DateFormatTypes, DateRangeInput } from './types'
import {
  convertPrefToMoment,
  dateOrUnix,
  getFormatWithoutTimezone,
  getShortFormat,
} from './utils'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import { isUndefined } from 'lodash'

export const getDifference = (
  dateIn,
  dateOut,
  as: moment.unitOfTime.Diff,
  precise = false,
) => moment(dateOut).diff(moment(dateIn), as, precise)

export const formatTwoDatesAsOne = (
  { startDate, endDate }: DateRangeInput,
  preferences: UserPreferenceInterface,
) => {
  const momentStartDate = dateOrUnix(startDate, preferences)
  const momentEndDate = dateOrUnix(endDate, preferences)

  const getTime = () => momentEndDate.format('LT')
  const getDateTime = () => momentEndDate.format('llll')
  const isSameDay = momentStartDate.isSame(momentEndDate, 'day')

  const startDateString = momentStartDate.format('llll')
  const endDateString = isSameDay ? getTime() : getDateTime()

  return `${startDateString} - ${endDateString}`
}
export const formatDateRange = (
  { startDate, endDate }: DateRangeInput,
  preferences: UserPreferenceInterface,
) => {
  if ([startDate, endDate].every(isUndefined)) {
    return ''
  }

  const momentStartDate = dateOrUnix(startDate, preferences)
  const momentEndDate = dateOrUnix(endDate, preferences)

  const startDateString = momentStartDate.format('MMMM Do YYYY, HH:mm')
  const endDateString = momentEndDate.format('MMMM Do YYYY, HH:mm')

  if (isUndefined(endDate)) {
    return startDateString
  }

  return `${startDateString} - ${endDateString}`
}

const dateFormatter: FormatterCallbacksDictionary = {
  [DateFormatTypes.asDate]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ): string => {
    return dateOrUnix(payload, preferences).format(
      convertPrefToMoment(preferences.dateFormat),
    )
  },
  [DateFormatTypes.asTime]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ): string => {
    return dateOrUnix(payload, preferences).format(
      convertPrefToMoment(preferences.timeFormat),
    )
  },
  [DateFormatTypes.asVeryShortTime]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    const momentFormat = convertPrefToMoment(preferences.timeFormat, true)
    const momentObj = dateOrUnix(payload, preferences)

    const shortFormat = getShortFormat({ momentObj, momentFormat })
    return momentObj.format(shortFormat).replace('m', '').trim()
  },
  [DateFormatTypes.asShortTime]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    const momentFormat = convertPrefToMoment(preferences.timeFormat, true)
    const momentObj = dateOrUnix(payload, preferences)

    const shortFormat = getShortFormat({ momentObj, momentFormat })
    return momentObj.format(shortFormat)
  },
  [DateFormatTypes.asShortTimeWithoutTimezone]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    const momentFormat = convertPrefToMoment(preferences.timeFormat, true)
    const momentObj = dateOrUnix(payload, preferences)

    const shortFormat = getShortFormat({
      momentObj,
      momentFormat,
      removeTimezone: true,
    })
    return momentObj.format(shortFormat)
  },
  [DateFormatTypes.asTimeWithoutTimezone]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    const momentFormat = convertPrefToMoment(preferences.timeFormat)
    const momentObj = dateOrUnix(payload, preferences)

    return momentObj.format(getFormatWithoutTimezone(momentFormat))
  },
  [DateFormatTypes.asDateTime]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).format(
      convertPrefToMoment(preferences.dateTimeFormat),
    )
  },
  [DateFormatTypes.asLongDate]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).format('LL')
  },
  [DateFormatTypes.asLongDateTime]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).format('LLLL')
  },
  [DateFormatTypes.asTimeToNow]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).toNow()
  },
  [DateFormatTypes.asTimeFromNow]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).fromNow()
  },
  [DateFormatTypes.asTimeToNowFromUTC]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    moment.locale(preferences.locale)
    return moment(payload + 'Z').fromNow()
  },
  [DateFormatTypes.asHoursFromNow]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    const hours = dateOrUnix(payload, preferences).diff(moment(), 'hours')
    const absoluteHours = Math.abs(hours)
    return `${absoluteHours}h`
  },
  [DateFormatTypes.asTimeZone]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences).format('z')
  },
  [DateFormatTypes.asDuration]: (payload: FormatterInput) => {
    return moment
      .utc(moment.duration(payload, 'hours').asMilliseconds())
      .format('HH:mm')
  },
  [DateFormatTypes.asUTCDate]: (
    payload: FormatterInput,
    preferences: UserPreferenceInterface,
  ) => {
    return dateOrUnix(payload, preferences)
      .utc()
      .format(convertPrefToMoment(preferences.dateFormat))
  },
}

export default dateFormatter
