export enum MeasureOperation {
  COUNT = 'COUNT',
  AVG = 'AVG',
  SUM = 'SUM',
  MIN = 'MIN',
  MAX = 'MAX',
  DISTINCT = 'DISTINCT',
  EXPRESSION = 'EXPRESSION',
}

export const measureOperations: MeasureOperation[] = [
  MeasureOperation.COUNT,
  MeasureOperation.AVG,
  MeasureOperation.SUM,
  MeasureOperation.MIN,
  MeasureOperation.MAX,
  MeasureOperation.DISTINCT,
  MeasureOperation.EXPRESSION,
]

export enum MeasureFormat {
  NA = 'NA',
  ROUND_0 = 'ROUND_0',
  ROUND_2 = 'ROUND_2',
  FLOOR = 'FLOOR',
  CEILING = 'CEILING',
  SUFFIX = 'SUFFIX',
}

export const measureFormats: MeasureFormat[] = [
  MeasureFormat.NA,
  MeasureFormat.ROUND_0,
  MeasureFormat.ROUND_2,
  MeasureFormat.FLOOR,
  MeasureFormat.CEILING,
  MeasureFormat.SUFFIX,
]

export type MeasureFormatFormValue = {
  value: MeasureFormat
  text: string
}

export const measureFormatFormValues: MeasureFormatFormValue[] = [
  {
    value: MeasureFormat.NA,
    text: 'measure_format.NA',
  },
  {
    value: MeasureFormat.ROUND_0,
    text: 'measure_format.round_0',
  },
  {
    value: MeasureFormat.ROUND_2,
    text: 'measure_format.round_2',
  },
  {
    value: MeasureFormat.FLOOR,
    text: 'measure_format.floor',
  },
  {
    value: MeasureFormat.CEILING,
    text: 'measure_format.ceiling',
  },
  {
    value: MeasureFormat.SUFFIX,
    text: 'measure_format.suffix',
  },
]
