import CodeMirror from 'codemirror'
import { allowedFunctions, relativeDates } from '../lang/types'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

import './tql-hint.js'

const wordListReducer = (acc, item) => {
  acc[item] = true
  return acc
}

const defaultKeywords = [
  'SELECT',
  'FROM',
  'WHERE',
  'ORDER',
  'GROUP',
  'BY',
  'AND',
  'AS',
  'ASC',
  'BETWEEN',
  'BY',
  'COUNT',
  'DESC',
  'DISTINCT',
  'FROM',
  'GROUP',
  'IN',
  'IS',
  'LIKE',
  'NOT',
  'OR',
  'ORDER',
  'SELECT',
  'WHERE',
  'LIMIT',
  'CASE',
  'WHEN',
  'THEN',
  'END',
  ...relativeDates,
  ...allowedFunctions
]

// `identifier`select name from accounts where name like '%ss%'
function hookIdentifier(stream) {
  // MySQL/MariaDB identifiers
  // ref: http://dev.mysql.com/doc/refman/5.6/en/identifier-qualifiers.html
  let ch
  while ((ch = stream.next()) != null) {
    if (ch == '`' && !stream.eat('`')) return 'variable-2'
  }
  stream.backUp(stream.current().length - 1)
  return stream.eatWhile(/\w/) ? 'variable-2' : null
}

setEditorKeyword('none', [])

/**
 * Set the editor keywords
 * @param code
 * @param keywords
 */
export function setEditorKeyword(code, keywords = []) {
  CodeMirror.defineMIME('text/x-tql', {
    name: 'sql',
    keywords: [...keywords, ...defaultKeywords].reduce(wordListReducer, {}),
    atoms: ['false', 'true', 'null', 'unknown'].reduce(wordListReducer, {}),
    dateSQL: ['date', 'time', 'timestamp'].reduce(wordListReducer, {}),
    support: [
      'decimallessFloat',
      'zerolessFloat',
      'binaryNumber',
      'hexNumber',
      'ODBCdotTable',
      'doubleQuote',
      'nCharCast',
      'charsetCast',
      'commentHash',
      'commentSpaceRequired'
    ].reduce(wordListReducer, {}),
    operatorChars: /^[*+\-%<>!=&|^]/,
    hooks: {
      '`': hookIdentifier
    }
  })
}
