// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetSessionsLogsTile from './MobileRunsheetSessionsLogsTile.vue'
import MobileRunsheetSessionsLogsColumns from './MobileRunsheetSessionsLogsColumns'
import MobileRunsheetSessionsLogsDetail from './MobileRunsheetSessionsLogsDetail.vue'
import MobileRunsheetSessionsLogsMetaCard from './MobileRunsheetSessionsLogsMetaCard.vue'
import MobileRunsheetSessionsLogsReference from './MobileRunsheetSessionsLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileRunsheetSessionsLogsTile',
      MobileRunsheetSessionsLogsTile,
    )
    Vue.component(
      'MobileRunsheetSessionsLogsDetail',
      MobileRunsheetSessionsLogsDetail,
    )
    Vue.component(
      'MobileRunsheetSessionsLogsMetaCard',
      MobileRunsheetSessionsLogsMetaCard,
    )
    Vue.component(
      'MobileRunsheetSessionsLogsReference',
      MobileRunsheetSessionsLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetSessionsLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_SESSIONS_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetSessionsLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
