import { BaseWidgetHook } from '@/tt-widget-factory/types'
import { YoutubeWidgetModel } from '@/tt-widget-components'

export default class YoutubeWidgetHook extends BaseWidgetHook<YoutubeWidgetModel> {
  hasPermission(): boolean {
    return true
  }

  get url(): string {
    const url = this.state.widget.url

    if (!url) {
      return ''
    }

    const urlParams = new URLSearchParams(`${url.split('?')[1]}`)
    const videoId = urlParams.get('v')

    if (!videoId) {
      return ''
    }

    return `https://www.youtube${
      this.state.widget.privacyMode ? '-nocookie' : ''
    }.com/embed/${videoId}`
  }
}
