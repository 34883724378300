<template>
  <v-card v-if="objectURL" v-bind="$attrs">
    <img :src="objectURL" width="100%" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { revokeObjectUrl, createObjectUrl } from './utils'

export default Vue.extend({
  name: 'LocalImagePreview',
  props: {
    file: {
      type: File,
    },
  },
  computed: {
    objectURL(): string {
      return createObjectUrl(this.file)
    },
  },
  watch: {
    objectURL(newUrl, oldUrl) {
      revokeObjectUrl(oldUrl)
    },
  },
  beforeDestroy() {
    revokeObjectUrl(this.objectURL)
  },
})
</script>
