import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

/**
 * Data Table Widget Helpers
 */

/**
 * Get Column Attribute Name
 *
 * @returns The column attribute name as a string.
 */
export const getColumnAttributeName = (column: ColumnInputDefinition): string =>
  typeof column === 'string' ? column : column.attributeName
