<template>
  <v-row class="ma-0">
    <v-checkbox
      v-if="showAllButton"
      v-model="allValue"
      :label="translateKey('all')"
      class="mr-2"
    />
    <v-checkbox
      v-for="day in dayOptions"
      :key="`${name}-${day}`"
      v-model="model"
      class="mr-2"
      :value="day"
      :label="translateKey(day.toLowerCase())"
    />
  </v-row>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'DayOfWeekSelector',
  inject: ['formHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showAllButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allValue: {
      get(): boolean {
        return this.model.length === this.dayOptions.length
      },
      set(value: boolean) {
        value ? (this.model = this.dayOptions) : (this.model = [])
      },
    },
    dayOptions(): string[] {
      return this.formHook().getField(`${this.name}.*`)?.props?.$schema
        .enum as string[]
    },
    model: {
      get(): string[] {
        return this.value
      },
      set(newValue: string[]): void {
        this.$emit('input', newValue)
      },
    },
    translationScope() {
      return 'common.day'
    },
  },
  methods: {
    translateKey(key: string, scope?: string): string {
      return this.$t(`${scope || this.translationScope}.${key}`)
    },
  },
})
</script>
