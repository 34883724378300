<template>
  <div v-if="resourceName && scopes">
    <CollapsibleHeader
      :title="$t('components.resource_scope_selector.scope_filters')"
    >
      <template #title>
        <span class="ml-4">
          {{ $t('components.resource_scope_selector.scope_filters') }}
          <v-badge v-if="model && model.length" color="green" dot inline />
        </span>
      </template>
      <v-card flat class="pa-4 pt-2 text-capitalize">
        <v-checkbox
          v-for="item in scopes"
          :key="item.value"
          v-model="model"
          hide-details
          :label="item.text"
          :value="item.value"
          @change="changeScope(item, $event)"
        />
        <div class="mt-3"></div>
      </v-card>
    </CollapsibleHeader>
  </div>
</template>

<script lang="ts">
import VueI18n from 'vue-i18n'
import { VueConstructor } from 'vue'

import BaseArrayInput from '@/tt-widget-components/components/BaseArrayInput'
import { ChooseCommonFilter } from '@/plugins/o11n'
import { FormHookProvider, ResourceProvider } from '..'
import { ResourceScope, Scope } from '../schemas-types'

export default (
  BaseArrayInput as VueConstructor<
    InstanceType<typeof BaseArrayInput> & FormHookProvider & ResourceProvider
  >
).extend({
  name: 'ResourceScopeSelector',
  inject: ['formHook', 'resourceProvider'],
  props: {
    resource: String,
  },
  computed: {
    resourceName(): any {
      return this.resourceProvider.resource
    },
    scopes(): {
      text: VueI18n.TranslateResult
      value: string
    }[] {
      if (!this.resourceName) {
        return []
      }
      const scopes =
        this.$appContext.widgetServices.resourceMetaManager.getResourceScopes(
          this.resourceName,
        )

      if (!scopes) {
        return null
      }

      return Object.keys(scopes).map((key) => {
        const item = scopes[key]

        const translation = this.$t(
          `res.${this.resourceName}.scopes.${key}.label`,
        )
        return { text: translation, value: item.key }
      })
    },
  },
  methods: {
    changeScope(
      scope: { text: string; value: Scope },
      val: ResourceScope,
    ): void {
      const isAddingScope = val.includes(scope.value)
      if (isAddingScope) {
        const gaEvent = ChooseCommonFilter.create({ label: scope.text })
        this.$analytics.track(gaEvent)
      }
    },
  },
})
</script>
