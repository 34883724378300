<template>
  <div v-if="filtersCount > 0" class="tt-filters-chips">
    <!-- accordion -->
    <v-expansion-panels>
      <v-expansion-panel>
        <!-- accordion header -->
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <!-- amount -->
          <v-chip small class="flex-grow-0 mr-3">
            {{ filtersCount }}
          </v-chip>
          <!-- / amount -->
          <span v-text="$t('components.filters_chips.title')" />
        </v-expansion-panel-header>
        <!-- / accordion header -->

        <!-- accordion content -->
        <v-expansion-panel-content>
          <TChipsList
            :chips="filters"
            @close="removeContextFilter"
            @clear-all="removeContextFilters"
          />
        </v-expansion-panel-content>
        <!-- / accordion content -->
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- / accordion -->
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import TChipsList from '@/tt-ui/components/TChipsList.vue'
import FilterValueParser from '@/tt-entity-filter/FilterValueParser'
import { ContextManagerInterface } from '@/tt-widget-factory'
import { AttributeName } from '@/tt-widget-components/lib/names'
import {
  createContextFilterSetting,
  createFilter,
} from '@/tt-entity-filter/util'
import { ContextFiltersChipsList } from '@/tt-widget-components/types'

type VueWithExtensions = VueConstructor<
  Vue & {
    contextManager: ContextManagerInterface
  }
>

export default (Vue as VueWithExtensions).extend({
  name: 'WidgetContextFiltersChips',
  components: {
    TChipsList,
  },
  inject: ['contextManager'],
  computed: {
    filtersCount(): number {
      return this.contextManager.getFiltersCount()
    },
    filters(): ContextFiltersChipsList {
      const dateRangeFilter = this.dateRange
      const accountFilters = this.accounts
      const regionFilters = this.regions

      return dateRangeFilter
        ? [...dateRangeFilter, ...regionFilters, ...accountFilters]
        : [...regionFilters, ...accountFilters]
    },
    accounts(): ContextFiltersChipsList {
      const accountFilterSettings = createContextFilterSetting(
        AttributeName.ACCOUNT_ATTRIBUTE,
      )

      const accounts = this.contextManager
        .getSelectedAccounts()
        .map((item) => ({
          id: item.id,
          label: item.name,
          attributeName: accountFilterSettings.attributeName,
          attributeLabel: accountFilterSettings.attributeMeta.labels.label,
          icon: accountFilterSettings.icon,
        }))

      return accounts as ContextFiltersChipsList
    },
    regions(): ContextFiltersChipsList {
      const regionFilterSettings = createContextFilterSetting(
        AttributeName.REGION_ATTRIBUTE,
      )

      const regions = this.contextManager.getSelectedRegions().map((item) => ({
        id: item.id,
        label: item.name,
        attributeName: regionFilterSettings.attributeName,
        attributeLabel: regionFilterSettings.attributeMeta.labels.label,
        icon: regionFilterSettings.icon,
      }))

      return regions as ContextFiltersChipsList
    },
    dateRange(): ContextFiltersChipsList {
      const filter = this.contextManager.getSelectedDateRange()

      const { attributeName, attributeMeta, icon } = createContextFilterSetting(
        AttributeName.DATE_RANGE_ATTRIBUTE,
      )
      const parsedDate = FilterValueParser.getFilterValue(
        attributeMeta,
        filter,
        this.$appContext.authModule.getUserPreferences(),
      )

      const dateRangeObj = {
        id: filter?.value,
        label: parsedDate,
        tooltipLabel: parsedDate,
        attributeName,
        attributeLabel: attributeMeta.labels.label,
        icon,
      }

      return filter?.value ? ([dateRangeObj] as ContextFiltersChipsList) : null
    },
  },
  methods: {
    removeContextFilter(id: string | number, attribute: AttributeName): void {
      const oldFilter = this.contextManager.getContextFilter(attribute)

      if (!oldFilter) return

      const filteredValues =
        oldFilter.value
          ?.toString()
          .split(',')
          .filter((item) => item !== id.toString()) || []

      const newFilter = createFilter(
        attribute,
        oldFilter.operator,
        filteredValues,
      )

      this.contextManager.setContextFilter(newFilter)
    },
    removeContextFilters(): void {
      this.contextManager.clearContextFilters()
    },
  },
})
</script>

<style lang="scss" scoped>
.tt-filters-chips {
  // target nested components
  ::v-deep {
    // accordion wrapper
    .v-expansion-panel {
      background-color: var(--v-toolbar3-base);
      border-color: var(--v-toolbar3-base);
      border-radius: 0;

      &::before {
        box-shadow: none;
      }

      &--active > .v-expansion-panel-header {
        min-height: auto;
      }
    }

    // accordion header
    .v-expansion-panel-header {
      color: var(--v-ttPrimary-base);
      width: auto;

      .theme--dark & {
        color: var(--v-accent-lighten5);
      }

      .v-expansion-panel-header__icon .v-icon {
        color: inherit;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s !important;
      }
    }

    // chips
    .theme--light.v-chip:not(.v-chip--active) {
      background-color: var(--v-ttPrimary-base);
      color: var(--v-accent-lighten5);
    }
  }
}
</style>
