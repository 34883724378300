// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import HolidaysTile from './HolidaysTile.vue'
import HolidaysColumns from './HolidaysColumns'
import HolidaysDetail from './HolidaysDetail.vue'
import HolidaysReference from './HolidaysReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('HolidaysTile', HolidaysTile)
    Vue.component('HolidaysDetail', HolidaysDetail)
    Vue.component('HolidaysReference', HolidaysReference)

    modularManager.addResourcePreset(
      Resources.HOLIDAYS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'HolidaysTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.HOLIDAYS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: HolidaysColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.HOLIDAYS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'HolidaysDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.HOLIDAYS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'HolidaysReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
