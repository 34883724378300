import { WidgetModels } from '@/tt-widget-components'
import { GridSelectionMode } from '@/tt-widget-components/widgets/DataTable/types'

import { Resources } from '../../types'
import {
  Action,
  Extension,
  ExtensionProperty,
  Resource,
} from '../../schema-types'

/**
 * GET with the extension `filters`.
 * POST with the action `add-filters`.
 */
type FILTER_RULE_SET_ASSETS = typeof Resources.FILTER_RULE_SET_ASSETS

export const ACTION_FILTERS: Action<FILTER_RULE_SET_ASSETS> = 'add-filters'
export const EXTENSION_FILTERS: Extension<FILTER_RULE_SET_ASSETS> = 'filters'

export type FormKey = ExtensionProperty<
  FILTER_RULE_SET_ASSETS,
  typeof EXTENSION_FILTERS
>

export type FormRelationList = Extract<
  FormKey,
  'assets' | 'types' | 'categories'
>

export const FORM_KEYS: FormKey[] = ['assets', 'types', 'categories', 'sendAll']

export const createWidget = (
  resource: Resources,
  account: number,
): WidgetModels => ({
  is: 'DataTableWidget',
  title: '',
  query: {
    resource,
    filters: [
      {
        attribute: 'account',
        operator: 'EQUAL',
        value: account,
      },
    ],
  },
  selectionMode: GridSelectionMode.MULTIPLE,
})

export type Tab = {
  key: FormRelationList
  title: string
  widget: WidgetModels
}
