<template>
  <v-toolbar dense v-bind="$attrs" short :class="cssClasses">
    <template v-if="isLoading">
      <v-skeleton-loader type="table-cell" />
      <v-spacer />
      <template v-if="menuItems && menuItems.length">
        <template v-for="(item, i) in menuItems">
          <v-skeleton-loader :key="i" type="table-cell" class="mr-2" />
        </template>
      </template>
      <v-skeleton-loader type="table-cell" class="mr-2" />
    </template>
    <template v-else>
      <slot name="left" />
      <v-spacer />
      <slot name="beforeMenu" />
      <template v-if="!hide && isExtended">
        <template v-for="(item, i) in menuItems">
          <v-btn :key="i" text small @click="click(item.action)">
            <span v-text="$t(item.text)"></span>
          </v-btn>
        </template>
      </template>
      <slot name="right" />
      <template v-if="!hide && !isExtended && menuItems.length">
        <v-menu :nudge-bottom="50">
          <template #activator="{ on }">
            <v-btn icon text v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <AppActionMenu :menu="{ items: menuItems }" divider />
        </v-menu>
      </template>
    </template>
  </v-toolbar>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  ActionDispatch,
  ActionMenuItem,
  ActionTypes,
  isActionDispatch,
} from '@/tt-app-layout'
import { executeAction } from '@/tt-app-layout/utils'

export default Vue.extend({
  name: 'ActionMenuToolbar',
  props: {
    mobileBreakpoint: {
      type: String,
      default: 'lgAndUp',
    },
    menuItems: {
      type: Array as PropType<ActionMenuItem[]>,
      default: () => [],
    },
    hide: {
      type: Boolean,
      default: false,
    },
    contextObject: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExtended(): boolean {
      return this.$vuetify.breakpoint[this.mobileBreakpoint]
    },
    cssClasses(): string {
      return this.$vuetify.theme.dark ? 'toolbar toolbar2' : ''
    },
  },
  methods: {
    click(item) {
      this.emitEventFromAction(item)
      executeAction(this.$appContext, this.$router, item, this.contextObject)
    },
    // This exposes the events related to the clicked menu item
    emitEventFromAction(action: ActionDispatch): void {
      this.$emit('click', action)

      const event = this.extractEventNameFromAction(action)
      if (event) {
        this.$emit(`click:${event}`, action)
      }
    },
    extractEventNameFromAction(action: ActionTypes): string | undefined {
      if (isActionDispatch(action)) {
        const { eventName } = action.props
        return eventName.split('.').splice(-1)[0]
      }
    },
  },
})
</script>
