import VueRouter, { NavigationFailureType as navFailureType } from 'vue-router'

const { isNavigationFailure, NavigationFailureType } = VueRouter

type NavigationErrorHandler = (
  errorTypes: navFailureType[],
  handler?: () => void,
) => (error: Error) => void | never

export const handleNavigationError: NavigationErrorHandler =
  (errorTypes, handler) => (error) => {
    const isNavigationError = errorTypes.some((type) =>
      isNavigationFailure(error, type),
    )

    if (isNavigationError) {
      handler && handler()
    } else {
      throw error
    }
  }

export const handleNavigationDuplicatedError = (
  error: Error,
  handler?: () => void,
): void | never => {
  handleNavigationError([NavigationFailureType.duplicated], handler)(error)
}
