<template>
  <div>
    <json-field name="person" />
    <json-field name="startDateTime" />
    <json-field name="endDateTime" />

    <!-- warn user about a conflicting reservation -->
    <v-alert
      v-if="showConflictingReservationsWarning"
      type="warning"
      color="orange darken-2"
      icon="mdi-alert"
      text
    >
      {{ conflictingReservationsWarning }}
      <json-field
        class="tt-agreement mt-4"
        name="agreement"
        :light="isLightTheme"
        :color="isLightTheme ? 'primary darken-1' : ''"
      />
    </v-alert>
    <!-- / warn user about a conflicting reservation -->

    <json-field name="checkoutByDifferentPerson" />
    <json-field name="comments" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ErrorObject } from 'ajv'

import { FormHookProvider } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory/definitions'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormatterInput } from '@/helpers/formats/types'
import { Resources } from '../../types'
import { RESERVATION_FORM_FIELDS } from './constants'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetsReservation',
  inject: ['formHook'],
  data() {
    return {
      showConflictingReservationsWarning: false,
      numberOfConflicts: 0,
    }
  },
  computed: {
    isLightTheme(): boolean {
      return !this.$vuetify.theme.dark
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    startDateTimeFieldValue(): FormatterInput {
      return this.formHook().getPathValue(RESERVATION_FORM_FIELDS.startDateTime)
    },
    endDateTimeFieldValue(): FormatterInput {
      return this.formHook().getPathValue(RESERVATION_FORM_FIELDS.endDateTime)
    },
    agreementFieldValue(): FormatterInput {
      return this.formHook().getPathValue(RESERVATION_FORM_FIELDS.agreement)
    },
    isDebouncing(): boolean {
      return this.formHook().state.isDebouncing
    },
    getAssetId(): number {
      return this.itemHook.getRawValue('id')
    },
    conflictingReservationsWarning(): string {
      return this.$tc(
        'asset-types.conflicting-reservations.warning-message',
        this.numberOfConflicts,
      )
    },
    createAgreementError(): ErrorObject[] {
      return [
        {
          dataPath: `.agreement`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
  },
  watch: {
    startDateTimeFieldValue() {
      this.checkAvailability()
    },
    endDateTimeFieldValue() {
      this.checkAvailability()
    },
    agreementFieldValue() {
      if (this.agreementFieldValue) {
        this.clearFormAgreementError()
      } else {
        this.setFormAgreementError()
      }
    },
  },
  methods: {
    checkAvailability(): void {
      this.hideConflictingReservationsMessage()
      this.clearFormAgreementError()

      /**
       * If the user has selected a start and end date, we can trigger the validation
       * to check if there are any conflicts in assets or sub-bundles and their
       * reservations.
       */
      if (this.canCheckAvailability()) {
        const api = this.$appContext.authModule.getApi()
        const filters = [
          {
            attribute: 'inDateTimeRange',
            operator: FilterOperatorType.EQUAL,
            value: `${this.startDateTimeFieldValue}|${this.endDateTimeFieldValue}`,
          },
          {
            attribute: 'reservationRelatedToAsset',
            operator: FilterOperatorType.EQUAL,
            value: this.getAssetId,
          },
          {
            attribute: 'scope',
            operator: FilterOperatorType.EQUAL,
            value: 'PENDING',
          },
        ]

        /**
         * Call the ASSET_RESERVATIONS resource and check if there are reservations
         * for that asset/bundle and it's sub items. If there are any other reservations,
         * we display the warning to the user.
         */
        api.getAll(Resources.ASSET_RESERVATIONS, { filters }).then((res) => {
          const { items } = res

          if (items.length) {
            this.showConflictingReservationsMessage(items.length)
          }
        })
      }
    },
    canCheckAvailability(): boolean {
      const hasStartDate = !!this.startDateTimeFieldValue
      const hasEndDate = !!this.endDateTimeFieldValue

      return !this.isDebouncing && hasStartDate && hasEndDate
    },
    showConflictingReservationsMessage(itemsCount: number): void {
      this.showConflictingReservationsWarning = true
      this.numberOfConflicts = itemsCount
      this.setFormAgreementError()
    },
    hideConflictingReservationsMessage(): void {
      this.showConflictingReservationsWarning = false
      this.numberOfConflicts = 0
      this.formHook().setObjectValue(RESERVATION_FORM_FIELDS.agreement, false)
    },
    setFormAgreementError(): void {
      this.formHook().setCustomError(
        RESERVATION_FORM_FIELDS.agreement,
        this.createAgreementError,
      )
    },
    clearFormAgreementError(): void {
      this.formHook().setCustomError(RESERVATION_FORM_FIELDS.agreement, null)
    },
  },
})
</script>
