import hook from './PivotTableWidgetHook'
import PivotTableWidgetEditor from './PivotTableWidgetEditor.vue'
import Vue from 'vue'
import schema from './schema'
import { WidgetSchema, WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "PivotTableWidget" */ './PivotTableWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('PivotTableWidgetEditor', PivotTableWidgetEditor)

const PivotWidget: WidgetTypeDefinition = {
  name: WidgetName.PIVOT_TABLE_WIDGET,
  schema: schema as WidgetSchema,
  component: {
    template: template as any,
    hook,
  },
  editorDefinitions: {
    PivotTableWidget: {
      view: {
        is: 'PivotTableWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-table',
    color: 'amber',
    thumbnail: require('./table.png'),
    print: false,
    pdf: false,
    export: true,
  },
}

export default PivotWidget
