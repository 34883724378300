<template>
  <TChipLabel :text-key="labelKey" :background-color="color" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { getStatusColor } from './utils'
import { RESOURCE, STATUS_ATTR } from './contants'
import { Status } from './types'

/**
 * Component UI for displaying the `status` attribute of a mobile schedule occurrence log.
 */
export default Vue.extend({
  name: 'MobileScheduleOccurrenceLogStatus',
  props: {
    status: {
      type: String as PropType<Status>,
      default: '',
    },
  },
  computed: {
    statusKey(): string {
      return this.status
    },
    color(): string {
      return getStatusColor(this.statusKey)
    },
    labelKey(): string {
      return createI18nEnumKey(RESOURCE, STATUS_ATTR, this.statusKey)
    },
  },
})
</script>
