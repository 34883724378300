export interface DocumentPresetInterface {
  name: string
}

export function createDocumentColumn(documentPreset: DocumentPresetInterface) {
  return {
    name: 'Download PDF',
    style: {
      maxWidth: 50,
      width: 50,
    },
    component: {
      is: 'EntityDocumentButton',
      props: {
        document: {
          name: documentPreset.name,
        },
      },
    },
  }
}
