// Enter your custom installation here
import MobileRunsheetSessionsLogsResultantStatusAttr from './MobileRunsheetSessionsLogsResultantStatusAttr.vue'

export default {
  install(Vue) {
    Vue.component(
      'MobileRunsheetSessionsLogsResultantStatusAttr',
      MobileRunsheetSessionsLogsResultantStatusAttr,
    )
  },
}
