// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportsTile from './ReportsTile.vue'
import ReportsColumns from './ReportsColumns'
import ReportsDetail from './ReportsDetail.vue'
import ReportsMetaCard from './ReportsMetaCard.vue'
import ReportsReference from './ReportsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportsTile', ReportsTile)
    Vue.component('ReportsDetail', ReportsDetail)
    Vue.component('ReportsMetaCard', ReportsMetaCard)
    Vue.component('ReportsReference', ReportsReference)

    modularManager.addResourcePreset(Resources.REPORTS, PresetTypes.LIST_ITEM, {
      title: 'Default',
      data: { is: 'ReportsTile' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(Resources.REPORTS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: ReportsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.REPORTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.REPORTS, PresetTypes.META_CARD, {
      title: 'Default',
      data: { is: 'ReportsMetaCard' },
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.REPORTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
