<template>
  <div>
    <v-select
      v-model="selectedLanguage"
      v-bind="{
        ...vuetifyDefaults,
      }"
      :items="languageOptions"
      :label="$t('user_section.language')"
      :placeholder="$t('user_section.language')"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { getValidLocale } from '@/locales/utils'
import { LANGUAGE_OPTIONS } from '@/locales/language-options'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

export interface LanguageSelectItem {
  text: string
  value: string
}

export default (Vue as VueWithFormHookInjection).extend({
  name: 'LanguageSelectorField',
  inject: ['formHook'],
  props: {
    items: {
      type: Array as PropType<LanguageSelectItem[]>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      vuetifyDefaults,
    }
  },
  computed: {
    selectedLanguage: {
      get(): string {
        return this.value
      },
      set(lang: string) {
        this.formHook().setObjectValue(this.name, lang)
      },
    },
    /**
     * Returns the language options in the format required by v-select
     * changing the text to a friendly name (e.g. English) instead of the locale code (e.g. en),
     * but the value is kept as the locale code used in the BE.
     */
    languageOptions(): LanguageSelectItem[] {
      return this.items.map((lang) => {
        const { frontendLocale } = getValidLocale(lang.value)
        const languageName = LANGUAGE_OPTIONS.filter(
          (lang) => lang.value === frontendLocale,
        )[0].text

        return {
          text: languageName,
          value: lang.value,
        }
      }) as LanguageSelectItem[]
    },
  },
})
</script>
