// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeRolesTile from './EmployeeRolesTile.vue'
import EmployeeRolesColumns from './EmployeeRolesColumns'
import EmployeeRolesDetail from './EmployeeRolesDetail.vue'
import EmployeeRolesReference from './EmployeeRolesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeRolesTile', EmployeeRolesTile)
    Vue.component('EmployeeRolesDetail', EmployeeRolesDetail)
    Vue.component('EmployeeRolesReference', EmployeeRolesReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ROLES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeRolesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ROLES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeRolesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ROLES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeRolesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ROLES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeRolesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
