import { PluginFunction } from 'vue'

const MobileScheduleCreateView = () =>
  import(
    /* webpackChunkName: "RunsheetGroupsView" */
    './views/MobileScheduleCreateView.vue'
  )

const MobileScheduleAssetsReference = () =>
import(
  './components/MobileScheduleAssetsReference.vue'
)

export { MobileScheduleCreateView }

export const MobileSchedulesPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('MobileScheduleCreateView', MobileScheduleCreateView)
  Vue.component('MobileScheduleAssetsReference', MobileScheduleAssetsReference)
}
