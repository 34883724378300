<template functional>
  <tt-tile v-bind="props">
    <AssetCategoriesIcon slot="action" size="40" default-icon="mdi-folder" />
    <tt-attr slot="title" name="name" class="text-subtitle-1" />
    <tt-attr
      slot="line2"
      name="parentCategory"
      class="text-subtitle-2 font-weight-regular"
      hide-empty-value
      prefix=""
    />
    <tt-attr
      slot="line3"
      name="description"
      class="text-caption"
      hide-empty-value
    />

    <template slot="actionRight">
      <v-icon size="30" class="opacity-faded">mdi-folder</v-icon>
    </template>
  </tt-tile>
</template>
<script>
import AssetCategoriesIcon from '@/tt-entity-design/src/components/asset-categories/AssetCategoriesIcon.vue'

export default {
  components: { AssetCategoriesIcon },
}
</script>
