import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { DialogFormInterface, LayoutWindowEvent } from '@/tt-app-layout'
import { getResourceDefinitions } from '@/tt-entity-forms'
import { normalizeName } from '@/helpers/text'

import { BaseEntityIntent } from './BaseEntityIntent'
import { EntityBatchActionIntentInterface, EntityIntentTypes } from './types'
import {
  displayErrorMessages,
  getBachableEntitites,
  parseBatchActionErrorMessages,
} from './helpers'
import { getResourceFormDefinition, getResourceFormWidth } from '../helper'
import { ResourceTranslator } from '../ResourceTranslator'
import { modularManager } from '@/tt-app-modular'
import { Action } from '@/tt-widget-factory/services/resource-action/types'

const confirmationSchema: JSONSchema7 = {
  title: 'Confirm?',
  type: 'object',
  properties: {},
}

export class EntityBatchActionIntent extends BaseEntityIntent {
  getEventName(): string {
    return EntityIntentTypes.RUN_BATCH_ACTION
  }

  async getDialogFormBuilder({
    actionName: action,
    entities,
    formOptions = {},
    label,
    resourceName,
    width,
  }: EntityBatchActionIntentInterface) {
    const { eventManager, widgetServices } = this.appContext
    const rootName = `${normalizeName(resourceName)}${normalizeName(action)}`

    const getDynamicConfig = async (): Promise<Partial<Action>> => {
      const actions =
        await widgetServices.resourceActionManager.getBatchActions(
          resourceName,
          { includeOperations: true, jsonFormSchema: true },
        )
      const config = actions.find(({ actionName }) => actionName === action)
      if (!config) {
        throw new Error(
          `Failed to build form: ${action} schema is not in the list of available actions`,
        )
      }

      // @ts-ignore -- json schema type is missing components
      const { components } = getDefaultConfig().schema ?? {}

      const schema = { components, ...config.schema }
      return { ...config, schema }
    }

    const getDefaultConfig = (): Partial<Action> => {
      const schema = widgetServices.resourceMetaManager.getFormSchema(
        resourceName,
        action,
      )
      return { schema }
    }

    const api = this.appContext.authModule.getApi()
    const batchEntities = await getBachableEntitites(
      api,
      resourceName,
      action,
      entities,
    ).catch(() => {
      console.warn('Error on getBachableEntitites')
      return []
    })

    const isJsonSchemaForm = modularManager.hasDynamicResourceJsonSchemaAction(
      resourceName,
      action,
    )

    const formConfig = isJsonSchemaForm
      ? await getDynamicConfig()
      : getDefaultConfig()

    const { definitions: dynamicDefinitions, ...dynamicFormOptions } =
      formConfig?.formOptions ?? {}

    const resourceDefinitions = getResourceDefinitions(this.appContext)
    const customForm = getResourceFormDefinition(rootName, resourceName, action)
    const definitions = {
      ...resourceDefinitions,
      ...customForm,
      ...dynamicDefinitions,
    }

    const dialogWidth = width ?? getResourceFormWidth(resourceName, action)

    const submitFn: DialogFormInterface['submit'] = (data) =>
      api.doBatchActions(resourceName, batchEntities, action, data)

    const successFn: DialogFormInterface['success'] = () => {
      eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: i18n.t('common.operation_successful'),
      })
    }

    const errorFn: DialogFormInterface['error'] = (error) => {
      const messages = parseBatchActionErrorMessages({
        actionName: action,
        error,
        resourceName,
      })
      displayErrorMessages(messages, eventManager)
    }

    return new DialogFormBuilder(label ?? action, rootName, {
      eventManager,
      i18n,
    })
      .setJsonSchema(formConfig.schema || confirmationSchema)
      .addToContext({ resourceName, action })
      .addToFormOptions({ ...dynamicFormOptions, ...formOptions })
      .setTranslateFunction(
        ResourceTranslator.getFormCallback(resourceName, action),
      )
      .addToDefinitions(definitions)
      .setWidth(dialogWidth)
      .onSubmit(submitFn)
      .onSuccess(successFn)
      .onError(errorFn)
  }

  async run(params: EntityBatchActionIntentInterface) {
    const dialogBuilder = await this.getDialogFormBuilder(params)
    dialogBuilder.displayDialog()
  }
}
