export enum ReportTemplateFieldAction {
  Archive = 'archive',
}

/**
 * Keep in mind that the order of this array will determine
 * the order in which the icons appear in the ReportTemplateFieldBuilder
 */
export enum ReportTemplateFieldType {
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  TextNumber = 'TEXT_NUMBER',
  TextBarcode = 'TEXT_BARCODE',
  MultiBarcode = 'MULTI_BARCODE',
  MultiBarcodeUnique = 'MULTI_BARCODE_UNIQUE',
  Picture = 'PICTURE',
  GalleryPicture = 'GALLERY_PICTURE',
  QualityPicture = 'QUALITY_PICTURE',
  Signature = 'SIGNATURE',
  Checkbox = 'CHECKBOX',
  ListFlag = 'LIST_FLAG',
  ListSiteLocation = 'LIST_SITE_LOCATION',
  List = 'LIST',
  ListMultiple = 'LIST_MULTIPLE',
  DrawBus = 'DRAW_BUS',
  DrawCar = 'DRAW_CAR',
  DrawBody = 'DRAW_BODY',
  DrawTrailer = 'DRAW_TRAILER',
  DrawGolfCart = 'DRAW_GOLF_CART',
  DrawVan = 'DRAW_VAN',
  ListClient = 'LIST_CLIENT',
  ListClientRelink = 'LIST_CLIENT_RELINK',
  ListEmployee = 'LIST_EMPLOYEE',
  ListPatrolVehicle = 'LIST_PATROL_VEHICLE',
  ListResident = 'LIST_RESIDENT',
  Section = 'SECTION',
  Time = 'TIME',
  Date = 'DATE',
  Datetime = 'DATE_TIME',
  Idcard = 'IDCARD',
}

export const reportTemplateFieldIconMap: Record<
  ReportTemplateFieldType,
  string
> = {
  [ReportTemplateFieldType.Checkbox]: 'mdi-checkbox-marked-outline',
  [ReportTemplateFieldType.Date]: 'mdi-calendar-month',
  [ReportTemplateFieldType.Datetime]: 'mdi-calendar-clock',
  [ReportTemplateFieldType.DrawBody]: 'mdi-account-edit',
  [ReportTemplateFieldType.DrawBus]: 'mdi-bus',
  [ReportTemplateFieldType.DrawCar]: 'mdi-car',
  [ReportTemplateFieldType.DrawGolfCart]: 'mdi-golf-cart',
  [ReportTemplateFieldType.DrawTrailer]: 'mdi-truck',
  [ReportTemplateFieldType.DrawVan]: 'mdi-van-utility',
  [ReportTemplateFieldType.GalleryPicture]: 'mdi-panorama',
  [ReportTemplateFieldType.Idcard]: 'mdi-id-card',
  [ReportTemplateFieldType.List]: 'mdi-format-list-bulleted',
  [ReportTemplateFieldType.ListClient]: 'mdi-calendar-account-outline',
  [ReportTemplateFieldType.ListClientRelink]: 'mdi-calendar-account-outline',
  [ReportTemplateFieldType.ListEmployee]: 'mdi-calendar-account-outline',
  [ReportTemplateFieldType.ListFlag]: 'mdi-flag',
  [ReportTemplateFieldType.ListMultiple]: 'mdi-format-list-bulleted',
  [ReportTemplateFieldType.ListPatrolVehicle]: 'mdi-alarm-light',
  [ReportTemplateFieldType.ListResident]: 'mdi-home',
  [ReportTemplateFieldType.ListSiteLocation]: 'mdi-map-marker',
  [ReportTemplateFieldType.MultiBarcode]: 'mdi-barcode',
  [ReportTemplateFieldType.MultiBarcodeUnique]: 'mdi-barcode',
  [ReportTemplateFieldType.Picture]: 'mdi-panorama',
  [ReportTemplateFieldType.QualityPicture]: 'mdi-panorama',
  [ReportTemplateFieldType.Section]: 'mdi-rectangle-outline',
  [ReportTemplateFieldType.Signature]: 'mdi-signature-freehand',
  [ReportTemplateFieldType.Text]: 'mdi-text-shadow',
  [ReportTemplateFieldType.Textarea]: 'mdi-text',
  [ReportTemplateFieldType.TextBarcode]: 'mdi-barcode-scan',
  [ReportTemplateFieldType.TextNumber]: 'mdi-numeric',
  [ReportTemplateFieldType.Time]: 'mdi-clock-time-nine-outline',
}
