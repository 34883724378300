import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import ReportTemplateFieldsSorter from '../report-templates/ReportTemplateFieldsSorter.vue'
import ReportFlagTemplatesSortFields from './ReportFlagTemplatesSortFields.vue'
import { Resources } from '../../types'
import { ReportFlagTemplateActions } from './types'

export default {
  install(Vue) {
    Vue.component('ReportTemplateFieldsSorter', ReportTemplateFieldsSorter)
    Vue.component(
      'ReportFlagTemplatesSortFields',
      ReportFlagTemplatesSortFields,
    )

    registerResourceForm(
      Resources.REPORT_FLAG_TEMPLATES,
      'ReportFlagTemplatesSortFields',
      ReportFlagTemplateActions.SortFields,
    )
  },
}
