import { VueWaitInstance } from 'vue-wait'

import { AuthEvents, AuthModule } from '@tracktik/tt-authentication'

import * as WidgetFactoryTypes from '@/tt-widget-factory/types'
import ContextManager from '@/tt-widget-factory/context/ContextManager'
import { AllowEntityOperationsProvider } from '@/types'
import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'
import {
  AuthEventsPayload,
  EventManagerInterface,
} from '@/tt-event-manager/types'
import { LayoutWindowEvent, LayoutWindowEventPayload } from '@/tt-app-layout'
import { Meta } from '@/tt-widget-factory/services/metadata-provider'
import { RegionManager } from '@/tt-region-manager/types'
import { PusherSdk } from '@tracktik/tt-pusher'
import {
  WidgetEditorEventPayload,
  WidgetExploreEventPayload,
} from '@/apps/app.tracktik.insights.studio/types'
import {
  EntityIntentEventPayload,
  WidgetComponentEventPayload,
} from '@/tt-widget-entity-flow'
import { EntityFilterEventPayload } from '@/tt-entity-filter/types'
import { WidgetHookEventsPayload } from '@/tt-widget-components'
import { TTCloudLeaveRequestsIntentTypesPayload } from '@/tt-entity-design/src/components/leave-requests/TTCloudLeaveRequestsActionCreateIntent'
import { TTCloudInjectedViewEventPayload } from '@/tt-widget-views/types'
import { PusherEventPayload } from '@/tt-app-extensions/pusher/events'
import { PresenceTrackingPayload } from '@/tt-app-extensions/presence-tracking/types'

export type AppContextEventManager =
  EventManagerInterface<AppContextEventPayload>
export type AppContextAuth = AuthModule
export type AppContextManager = ContextManager
export type AppContextWidgetServices = WidgetFactoryTypes.WidgetServices
export type AppContextWidgetManager = WidgetFactoryTypes.WidgetManagerInterface
export type AppContextResourceMetaManager =
  WidgetFactoryTypes.ResourceMetaManagerInterface
export type AppContextResourceActionManager =
  WidgetFactoryTypes.ResourceActionManagerInterface
export type AppContextResourceDataManager =
  WidgetFactoryTypes.ResourceDataManagerInterface
export type AppContextTaskManager = VueWaitInstance

export enum TaskManagerEvents {
  ALL_METADATAS_FETCHED = 'task-manager-events.all-metadatas-fetched',
}

export type TaskManagerEventPayload = {
  [TaskManagerEvents.ALL_METADATAS_FETCHED]: Meta
}

export enum TaskManagerItems {
  FETCHING_METADATAS = 'task-manager-items.pending-metadatas',
}

export const AppContextEvents = {
  ...AuthEvents,
  ...LayoutWindowEvent,
}

export interface AppContext extends AllowEntityOperationsProvider {
  eventManager: AppContextEventManager
  authModule: AppContextAuth
  contextManager: AppContextManager
  regionManager: RegionManager
  pusherSdk: PusherSdk
  widgetServices?: AppContextWidgetServices
  entityServices?: AppContextEntityServices
  taskManager: AppContextTaskManager
  isReady: () => boolean
}

export type ProvideAppContextService<T> = (
  context: Partial<AppContext>,
  services: AppContextServices,
) => T

export type AppContextServices = {
  eventManager: ProvideAppContextService<AppContextEventManager>
  authModule: ProvideAppContextService<AppContextAuth>
  regionManager: ProvideAppContextService<RegionManager>
  contextManager: ProvideAppContextService<AppContextManager>
  entityServices: ProvideAppContextService<AppContextEntityServices>
  widgetManager: ProvideAppContextService<AppContextWidgetManager>
  resourceActionManager: ProvideAppContextService<AppContextResourceActionManager>
  resourceMetaManager: ProvideAppContextService<AppContextResourceMetaManager>
  resourceDataManager: ProvideAppContextService<AppContextResourceDataManager>
  widgetServices: ProvideAppContextService<AppContextWidgetServices>
}

export type AppContextEntityServices = {
  persister: EntityPersistRunner
}

export type AppContextUpdater<T> = (
  context: Partial<AppContext>,
  services: AppContextServices,
) => T

export interface AppContextBuilder {
  defaults(update: AppContextUpdater<void>): AppContextBuilder
  events(update: AppContextUpdater<Record<string, Function>>): AppContextBuilder
  build(): AppContext
  taskManager?: AppContextTaskManager
}

export type AppContextEventPayload = LayoutWindowEventPayload &
  WidgetEditorEventPayload &
  WidgetExploreEventPayload &
  WidgetComponentEventPayload &
  EntityIntentEventPayload &
  TaskManagerEventPayload &
  AuthEventsPayload &
  EntityFilterEventPayload &
  WidgetHookEventsPayload &
  TTCloudLeaveRequestsIntentTypesPayload &
  TTCloudInjectedViewEventPayload &
  PusherEventPayload &
  PresenceTrackingPayload
