import Vue, { PropType } from 'vue'

export default Vue.extend({
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (val !== this.model) {
          this.model = val
        }
      },
    },
    model: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
  props: {
    name: String,
    value: String,
    label: String,
    placeholder: String,
    errorMessages: Array as PropType<string[]>,
  },
  created() {
    this.model = this.value
  },
  data() {
    return {
      model: null,
    }
  },
})
