import { Formatters } from '@/tt-widget-components'
import { FieldTypes } from '../../tt-widget-factory/services/resource-meta/types'

export function getDefaultFormat(attr: string): Formatters | null {
  // Enums, we get the translated label
  switch (attr) {
    case FieldTypes.Date:
      return 'asDate'
    case FieldTypes.DateTime:
    case FieldTypes.TimeStampDate:
    case FieldTypes.TimeStampNumber:
      return 'asDateTime'
    case FieldTypes.Time:
      return 'asTime'
    case FieldTypes.CurrencyRate:
    case FieldTypes.Currency:
      return 'asCurrency'
  }
  return null
}
