// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmergencyContactAccountAssignmentsTile from './EmergencyContactAccountAssignmentsTile.vue'
import EmergencyContactAccountAssignmentsColumns from './EmergencyContactAccountAssignmentsColumns'
import EmergencyContactAccountAssignmentsDetail from './EmergencyContactAccountAssignmentsDetail.vue'
import EmergencyContactAccountAssignmentsReference from './EmergencyContactAccountAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmergencyContactAccountAssignmentsTile',
      EmergencyContactAccountAssignmentsTile,
    )
    Vue.component(
      'EmergencyContactAccountAssignmentsDetail',
      EmergencyContactAccountAssignmentsDetail,
    )
    Vue.component(
      'EmergencyContactAccountAssignmentsReference',
      EmergencyContactAccountAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACT_ACCOUNT_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmergencyContactAccountAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACT_ACCOUNT_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmergencyContactAccountAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACT_ACCOUNT_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmergencyContactAccountAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACT_ACCOUNT_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmergencyContactAccountAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
