import { ConditionalColor } from '@/tt-widget-components'

export default class ColorConditionParser {
  public getSuccessCondition(
    conditions: ConditionalColor[],
    value: number,
  ): ConditionalColor {
    ;``
    const list = [...conditions]
    return list.reverse().find((condition: ConditionalColor) => {
      return this.assertCondition(condition, value)
    })
  }

  private assertCondition(condition: ConditionalColor, value: number): boolean {
    switch (condition.operator) {
      case '<':
        return value < condition.value
      case '<=':
        return value <= condition.value
      case '=':
        return value == condition.value
      case '>':
        return value > condition.value
      case '>=':
        return value >= condition.value
      default:
        return false
    }
  }
}
