import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'actionDate',
  'employee.name',
  'employee.jobTitle',
  {
    attributeName: 'mobileSchedule.client.name',
    headerText:
      'tt-entity-design.mobile-schedule-occurrence-logs.attrs.clientName.name',
  },
  'onSiteOn',
  'enRouteOn',
  'lastStatusOn',
  'status',
] as ColumnInputDefinition[]
