<template>
  <v-icon class="mr-1" :color="color" v-bind="$attrs">
    {{ icon }}
    <EntityValuePreload :attributes="['icon', 'color']" />
  </v-icon>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetCategoriesIcon',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    prefix: String,
    defaultIcon: {
      type: String,
      default: 'radio_button_checked',
    },
    defaultColor: {
      type: String,
      default: 'blue darken-2',
    },
    iconAttribute: {
      type: String,
      default: 'icon',
    },
    colorAttribute: {
      type: String,
      default: 'color',
    },
  },
  computed: {
    absolutePrefix(): string {
      const prefix = this.prefix ?? this.relationPrefix ?? false

      return prefix ? `${prefix}.` : ''
    },
    color(): string {
      const color = this.getItemHook().get(
        `${this.absolutePrefix}${this.colorAttribute}`,
      )

      return color && color !== '' ? color : this.defaultColor
    },
    icon(): string {
      const icon = this.getItemHook().get(
        `${this.absolutePrefix}${this.iconAttribute}`,
      )

      return icon && icon !== '' ? icon : this.defaultIcon
    },
  },
  created() {
    this.getItemHook().addAttribute(
      `${this.absolutePrefix}${this.iconAttribute}`,
    )
    this.getItemHook().addAttribute(
      `${this.absolutePrefix}${this.colorAttribute}`,
    )
  },
})
</script>
