<template>
  <v-btn
    :value="value"
    :class="value && 'toggle--active'"
    :ripple="false"
    :disabled="disabled"
    :outlined="outlined"
    small
    depressed
    @click="toggle"
  >
    <v-scroll-x-transition>
      <v-icon v-show="value" x-small class="pr-1" v-text="`mdi-check`" />
    </v-scroll-x-transition>

    <span v-text="text" />
  </v-btn>
</template>

<script lang="ts">
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import Vue from 'vue'

export default Vue.extend({
  name: 'TButtonToggle',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async toggle() {
      await updateDOM()
      const newValue = !this.value

      this.$emit('input', newValue)
    },
  },
})
</script>

<style scoped>
.v-btn.toggle--active {
  color: var(--v-ttPrimary-base) !important;
  background-color: var(--v-ttPrimary-lighten5) !important;
}
</style>
