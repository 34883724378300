<template>
  <v-card color="level1" min-width="300">
    <div class="pa-6">
      <AttributePatchForm
        :value="objValue"
        :attribute="name"
        :resource-name="resource"
        @input="setValue($event)"
      />
    </div>
    <v-toolbar dense short>
      <v-btn
        text
        small
        color="green"
        :loading="loading"
        @click="patch"
        v-text="$t('common.save.btn')"
      />
      <v-btn
        text
        small
        color="grey"
        @click="$emit('cancel')"
        v-text="$t('common.cancel.btn')"
      />
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import AttributePatchForm from '@/tt-widget-entity-action-forms/components/AttributePatchForm.vue'
import i18n from '@/plugins/i18n'
import { ItemHookProvider } from '../types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityAttributeEdit',
  components: {
    AttributePatchForm,
  },
  inject: ['getItemHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      value: {},
    }
  },
  computed: {
    objValue(): any {
      return this.getItemHook().data
    },
    resource(): any {
      return this.getItemHook().entityReference.resourceName
    },
  },
  mounted() {
    this.value = this.getItemHook().data
  },
  methods: {
    setValue(objValue) {
      this.value = objValue
    },
    patch() {
      this.loading = true
      const { entityId, resourceName } = this.getItemHook().entityReference
      this.$appContext.authModule
        .getApi()
        .edit(resourceName, entityId, this.value)
        .then(() => {
          Object.keys(this.value).forEach((k: string) => {
            this.getItemHook().setAttribute(k, this.value[k])
          })
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
            message: i18n.t('common.operation_successful'),
          })
          this.$emit('cancel')
        })
        .catch((err) => {
          this.$crash.captureException(err)
          this.error = 'Could not save attribute'
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
