<template>
  <ProvideResource :resource="resource">
    <template>
      <slot :resource="resource"></slot>
    </template>
  </ProvideResource>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '..'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ProvideDataSetResource',
  inject: ['formHook'],
  computed: {
    resource(): any {
      return this.formHook().getPathValue('dataSet.resource')
    },
  },
})
</script>
