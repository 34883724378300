import { BaseField, FieldName } from '../../base/types'

export const PICTURE = FieldName.Picture
/**
 * @TODO
 * Options are coming from initial implementation.
 * Don't know how relevant they are, to check with API and Mobile.
 */

/**
 * Image options
 */
export type PictureFieldOption = {
  // /**
  //  * Image resolution
  //  */
  // resolution?: 'NORMAL' | 'LOW' | 'HIGH' | 'HIGHEST'
  // /**
  //  * Allow drawing on image
  //  */
  // allowDrawing?: boolean
  // /**
  //  * Allow selecting image from the gallery
  //  */
  // allowGallery?: boolean
  // /**
  //  * Preferred mobile camera
  //  */
  // preferredMobileCamera?: 'BACK' | 'SELFIE'

  /**
   * Use high quality image
   */
  high_quality?: boolean
}

export type PictureFieldType = BaseField & {
  type: typeof PICTURE
  options: PictureFieldOption
}
