// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OperationCentersTile from './OperationCentersTile.vue'
import OperationCentersColumns from './OperationCentersColumns'
import OperationCentersDetail from './OperationCentersDetail.vue'
import OperationCentersMetaCard from './OperationCentersMetaCard.vue'
import OperationCentersReference from './OperationCentersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OperationCentersTile', OperationCentersTile)
    Vue.component('OperationCentersDetail', OperationCentersDetail)
    Vue.component('OperationCentersMetaCard', OperationCentersMetaCard)
    Vue.component('OperationCentersReference', OperationCentersReference)

    modularManager.addResourcePreset(
      Resources.OPERATION_CENTERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OperationCentersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OperationCentersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCentersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCentersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OperationCentersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
