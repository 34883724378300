import { FieldTypes } from '..'
import { FieldTypeTemporal } from '@/tt-entity-filter/temporal-filters/types'

/**
 * We group both "timestamp" ISO fields into one type
 */
// unneeded ?
const TEMPORAL_FIELD_MAP: Record<FieldTypeTemporal, FieldTypeTemporal> = {
  [FieldTypes.TimeStampDate]: FieldTypes.TimeStampDate,
  [FieldTypes.TimeStampNumber]: FieldTypes.TimeStampDate,
  [FieldTypes.DateTime]: FieldTypes.DateTime,
  [FieldTypes.Date]: FieldTypes.Date,
}

export const groupByTemporalType = (type) => TEMPORAL_FIELD_MAP[type]

/**
 * Priority of temporal context filter to show.
 *
 * ie: If a context has 2 widgets registered :
 * - widget 1 is mapping the date context to a "Date" only field
 * - widget 2 is mapping the date context to a "DateTime" field
 *
 * The resulting type that will be used it "DateTime" as it contains more information that just Date.
 */
const TEMPORAL_TYPE_PRIORITY: Record<FieldTypeTemporal, number> = {
  [FieldTypes.TimeStampDate]: 1,
  [FieldTypes.TimeStampNumber]: 1,
  [FieldTypes.DateTime]: 2,
  [FieldTypes.Date]: 3,
}

export const byTemporalTypePriority = (a, b) =>
  TEMPORAL_TYPE_PRIORITY[a] - TEMPORAL_TYPE_PRIORITY[b]
