// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WhitelistedIpsTile from './WhitelistedIpsTile.vue'
import WhitelistedIpsColumns from './WhitelistedIpsColumns'
import WhitelistedIpsDetail from './WhitelistedIpsDetail.vue'
import WhitelistedIpsReference from './WhitelistedIpsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WhitelistedIpsTile', WhitelistedIpsTile)
    Vue.component('WhitelistedIpsDetail', WhitelistedIpsDetail)
    Vue.component('WhitelistedIpsReference', WhitelistedIpsReference)

    modularManager.addResourcePreset(
      Resources.WHITELISTED_IPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WhitelistedIpsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WHITELISTED_IPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WhitelistedIpsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WHITELISTED_IPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WhitelistedIpsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WHITELISTED_IPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WhitelistedIpsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
