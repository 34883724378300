<template>
  <div>
    <v-list-item @click="select">
      <v-list-item-action>
        <span>
          <v-icon size="24" :color="color" v-text="icon"></v-icon>
        </span>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
        <v-list-item-subtitle v-text="item.description" />
      </v-list-item-content>
      <v-list-item-action v-if="item.starred">
        <v-icon color="orange">mdi-star</v-icon>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { WorkSpace } from '@/tt-app-extensions/workspace/type'

export default Vue.extend({
  name: 'WorkspaceLauncherAppTile',
  computed: {
    color(): string {
      return this.item.meta.color
    },
    icon(): string {
      return this.item.meta.icon
    },
  },
  methods: {
    select() {
      window.open(this.item.url, this.item.id)
    },
  },
  props: {
    item: {
      type: Object as PropType<WorkSpace>,
      required: true,
    },
  },
})
</script>
