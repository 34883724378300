<template>
  <div>
    <json-field
      name="vendors"
      :label="vendorsLabel"
      :item-props="{ label: vendorsItemsLabel, placeholder: vendorsItemsLabel }"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ClientsAssignVendors',
  computed: {
    vendorsLabel(): string {
      return this.$tc(
        'tt-entity-design.clients.actions.assign-vendors.fields.vendors.label',
      )
    },
    vendorsItemsLabel(): string {
      return this.$tc(
        'tt-entity-design.clients.actions.assign-vendors.fields.vendors.items.label',
      )
    },
  },
})
</script>
