<template>
  <div>
    <CollapsibleHeader
      v-show="hasCustomFields"
      :title="customFieldsHeader"
      :header-height="24"
    >
      <tt-attr-row-card>
        <t-custom-fields-details :custom-fields="customFields" />
      </tt-attr-row-card>
    </CollapsibleHeader>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { CustomAttributeDisplay } from '../assets/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetTransactionItemsCustomFields',
  inject: ['getItemHook'],
  computed: {
    hasCustomFields(): boolean {
      return this.customFields?.length > 0
    },
    customFieldsHeader(): string {
      return this.$t('tt-entity-design.asset-hub.asset.logs.custom-fields')
    },
    customFields(): CustomAttributeDisplay[] {
      return this.getItemHook().getRawValue('customAttributesDisplay') || []
    },
  },
  created() {
    this.getItemHook().addAttribute('customAttributesDisplay')
  },
})
</script>
