import { PluginFunction } from 'vue'

const SchedulingGroupDoNotPerformButton = () =>
  import(
    /* webpackChunkName: "SchedulingGroupDoNotPerformButton" */
    './components/SchedulingGroupDoNotPerformButton.vue'
  )

export const SchedulingGroupsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component(
    'SchedulingGroupDoNotPerformButton',
    SchedulingGroupDoNotPerformButton,
  )
}
