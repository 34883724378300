// Enter your custom installation here
import {
  addColumnPreset,
  setAttributeView,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import OpenShiftRequestsColumnSkillThreshold from './OpenShiftRequestsColumnSkillThreshold.vue'
import OpenShiftRequestsAttrWeeklyHoursRemaining from './OpenShiftRequestsAttrWeeklyHoursRemaining.vue'
import OpenShiftRequestsAttrOvertimeImpact from './OpenShiftRequestsAttrOvertimeImpact.vue'
import OpenShiftRequestsActionBtn from './OpenShiftRequestsActionBtn.vue'
import OpenShiftRequestsShiftDateTime from './OpenShiftRequestsShiftDateTime.vue'
import { modularManager, PresetItem, PresetTypes } from '@/tt-app-modular'
import OpenShiftRequestsShiftDetails from './OpenShiftRequestsShiftDetails.vue'
import OpenShiftRequestsExtAroundShifts from './OpenShiftRequestsExtAroundShifts.vue'
import OpenShiftRequestBtnActions from './OpenShiftRequestBtnActions.vue'
import OpenShiftRequestsActions from './OpenShiftRequestsActions.vue'
import OpenShiftRequestsApproveForm from './OpenShiftRequestsApproveForm.vue'
import { ComponentNames, CustomColumnNames } from './types'

export default {
  install(Vue) {
    Vue.component(
      ComponentNames.OpenShiftRequestsColumnSkillThreshold,
      OpenShiftRequestsColumnSkillThreshold,
    )

    Vue.component('OpenShiftRequestBtnActions', OpenShiftRequestBtnActions)

    Vue.component(
      ComponentNames.OpenShiftRequestsAttrOvertimeImpact,
      OpenShiftRequestsAttrOvertimeImpact,
    )
    Vue.component(
      ComponentNames.OpenShiftRequestsAttrWeeklyHoursRemaining,
      OpenShiftRequestsAttrWeeklyHoursRemaining,
    )
    Vue.component(
      ComponentNames.OpenShiftRequestsActions,
      OpenShiftRequestsActions,
    )
    Vue.component(
      ComponentNames.OpenShiftRequestsActionBtn,
      OpenShiftRequestsActionBtn,
    )
    Vue.component(
      ComponentNames.OpenShiftRequestsShiftDateTime,
      OpenShiftRequestsShiftDateTime,
    )
    Vue.component(
      ComponentNames.OpenShiftRequestsApproveForm,
      OpenShiftRequestsApproveForm,
    )

    setAttributeView(
      Resources.OPEN_SHIFT_REQUESTS,
      'extensions.weeklyHoursRemaining.hours',
      {
        is: ComponentNames.OpenShiftRequestsAttrWeeklyHoursRemaining,
      },
    )

    addColumnPreset(
      Resources.OPEN_SHIFT_REQUESTS,
      CustomColumnNames.skillThreshold,
      {
        component: {
          is: ComponentNames.OpenShiftRequestsColumnSkillThreshold,
        },
      },
    )

    modularManager.addResourcePreset(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.ACTIONS_CARD,
      {
        title: '',
        data: null,
        hide: true,
      },
    )

    Vue.component(
      'OpenShiftRequestsShiftDetails',
      OpenShiftRequestsShiftDetails,
    )

    Vue.component(
      'OpenShiftRequestsExtAroundShifts',
      OpenShiftRequestsExtAroundShifts,
    )
    const openShiftDetails: PresetItem = {
      title: 'tt-entity-design.open-shift-requests.details',
      data: { is: 'OpenShiftRequestsShiftDetails', opened: false },
      order: 1,
    }
    modularManager.setResourcePresets(
      Resources.OPEN_SHIFT_REQUESTS,
      PresetTypes.DETAILS_CARD,
      [openShiftDetails],
    )
  },
}
