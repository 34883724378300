// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SmsLogsTile from './SmsLogsTile.vue'
import SmsLogsColumns from './SmsLogsColumns'
import SmsLogsDetail from './SmsLogsDetail.vue'
import SmsLogsMetaCard from './SmsLogsMetaCard.vue'
import SmsLogsReference from './SmsLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SmsLogsTile', SmsLogsTile)
    Vue.component('SmsLogsDetail', SmsLogsDetail)
    Vue.component('SmsLogsMetaCard', SmsLogsMetaCard)
    Vue.component('SmsLogsReference', SmsLogsReference)

    modularManager.addResourcePreset(
      Resources.SMS_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SmsLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.SMS_LOGS, PresetTypes.COLUMNS, {
      title: 'Default',
      data: SmsLogsColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.SMS_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SmsLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SMS_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SmsLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SMS_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SmsLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
