<template>
  <div>
    <vue-simplemde ref="markdownEditor" v-model="localValue" />
    <v-btn>
      <span v-text="$t('components.rich_text_input.update')" />
    </v-btn>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import VueSimplemde from 'vue-simplemde'

export default Vue.extend({
  name: 'RichTextInput',
  components: {
    VueSimplemde,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localValue: '',
      linkNode: null,
    }
  },
  computed: {
    themeLink(): string {
      if (this.$vuetify.theme.dark) {
        return 'https://cdn.rawgit.com/xcatliu/simplemde-theme-dark/master/dist/simplemde-theme-dark.min.css'
      }

      return 'https://cdn.jsdelivr.net/simplemde/latest/simplemde.min.css'
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler(val: string) {
        this.$emit('input', val)
      },
    },
    '$vuetify.theme.dark'() {
      this.linkNode.remove()
      this.createAndAppendLinkNode()
    },
  },
  created() {
    this.localValue = this.value
    this.createAndAppendLinkNode()
  },
  beforeDestroy() {
    this.linkNode.remove()
  },
  methods: {
    createAndAppendLinkNode() {
      this.linkNode = document.createElement('link')
      this.linkNode.rel = 'stylesheet'
      this.linkNode.href = this.themeLink
      document.head.appendChild(this.linkNode)
    },
  },
})
</script>
