// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeAccountAssignmentsTile from './EmployeeAccountAssignmentsTile.vue'
import EmployeeAccountAssignmentsColumns from './EmployeeAccountAssignmentsColumns'
import EmployeeAccountAssignmentsDetail from './EmployeeAccountAssignmentsDetail.vue'
import EmployeeAccountAssignmentsReference from './EmployeeAccountAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'EmployeeAccountAssignmentsTile',
      EmployeeAccountAssignmentsTile,
    )
    Vue.component(
      'EmployeeAccountAssignmentsDetail',
      EmployeeAccountAssignmentsDetail,
    )
    Vue.component(
      'EmployeeAccountAssignmentsReference',
      EmployeeAccountAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeAccountAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeAccountAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
