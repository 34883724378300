import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'checkpoint',
  'tourSession.tour',
  'checkpoint.account',
  'scannedBy',
  'scannedOn',
  'latitude',
  'longitude',
] as ColumnInputDefinition[]
