// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportLibraryFieldsTile from './ReportLibraryFieldsTile.vue'
import ReportLibraryFieldsColumns from './ReportLibraryFieldsColumns'
import ReportLibraryFieldsDetail from './ReportLibraryFieldsDetail.vue'
import ReportLibraryFieldsMetaCard from './ReportLibraryFieldsMetaCard.vue'
import ReportLibraryFieldsReference from './ReportLibraryFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportLibraryFieldsTile', ReportLibraryFieldsTile)
    Vue.component('ReportLibraryFieldsDetail', ReportLibraryFieldsDetail)
    Vue.component('ReportLibraryFieldsMetaCard', ReportLibraryFieldsMetaCard)
    Vue.component('ReportLibraryFieldsReference', ReportLibraryFieldsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARY_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportLibraryFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARY_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportLibraryFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARY_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportLibraryFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARY_FIELDS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportLibraryFieldsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARY_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportLibraryFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
