<template>
  <tt-attr-row-card>
    <tt-attr-row name="type" />
    <tt-attr-row name="code" />
    <tt-attr-row name="rate" />
    <tt-attr-row name="isOvertime" />
    <tt-attr-row name="hoursBase" />
    <tt-attr-row name="minHours" :label="minOrMaxHours">
      <EarningsPremiumHoursCustomRow />
    </tt-attr-row>
    <tt-attr-row name="status">
      <ChipLabelText name="status" />
    </tt-attr-row>
    <div v-if="showBillMultipliers">
      <tt-attr-row name="billRegularMultiplier" />
      <tt-attr-row name="billRegularMaxHours" />
      <tt-attr-row name="billOvertimeMultiplier" />
      <tt-attr-row name="billOvertimeMaxHours" />
      <tt-attr-row name="billHolidayMultiplier" />
      <tt-attr-row name="billHolidayMaxHours" />
    </div>
    <div v-if="showPayMultipliers">
      <tt-attr-row name="payrollRegularMultiplier" />
      <tt-attr-row name="payrollRegularMaxHours" />
      <tt-attr-row name="payrollOvertimeMultiplier" />
      <tt-attr-row name="payrollOvertimeMaxHours" />
      <tt-attr-row name="payrollDoubleTimeMultiplier" />
      <tt-attr-row name="payrollDoubleTimeMaxHours" />
      <tt-attr-row name="payrollHolidayMultiplier" />
      <tt-attr-row name="payrollHolidayMaxHours" />
    </div>
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import { PositionPremiumTypes } from '@/tt-widget-views/back-office-reports/types'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'PositionPremiumsDetail',
  inject: ['getItemHook'],

  computed: {
    minHoursText(): string {
      return this.$t('back-office-reports.earnings-premium.minHours')
    },
    maxHoursText(): string {
      return this.$t('back-office-reports.earnings-premium.maxHours')
    },
    attributeValueAddToBaseRate(): boolean {
      return this.getItemHook().getRawValue('isAddToBaseRate')
    },
    attributeValueType(): string {
      return this.getItemHook().getRawValue('type')
    },
    showBillMultipliers(): boolean {
      return (
        this.attributeValueType === PositionPremiumTypes.BILL_ON_TOP &&
        !this.attributeValueAddToBaseRate
      )
    },
    showPayMultipliers(): boolean {
      return (
        this.attributeValueType === PositionPremiumTypes.PAY_ON_TOP &&
        !this.attributeValueAddToBaseRate
      )
    },
    minOrMaxHours(): string {
      return this.attributeValueAddToBaseRate
        ? this.minHoursText
        : this.maxHoursText
    },
  },
})
</script>
