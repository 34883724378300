<template>
  <v-app id="injected-scim-form-view" style="position: relative">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <app-window ref="window" :closable="false">
          <ScimFormView />
        </app-window>
        <AppLayoutUtils :layout-configuration="layoutConfiguration" />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import ScimFormView from './view/ScimFormView.vue'

/**
 * DataLab entry view injected in TTC
 */
export default Vue.extend({
  name: 'ScimFormInjected',
  components: {
    ProvideLayoutManager,
    ScimFormView,
  },
  props: {
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
