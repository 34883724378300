import { ActiveWindowProvider } from '@/tt-app-layout'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import Vue, { VueConstructor } from 'vue'

export type ViewAction = {
  icon: string
  title: string
  onClick: () => Promise<void>
  disabled?: boolean
}

export const MAX_BUTTONS_IN_ROW = 5

export type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook } & ActiveWindowProvider
>
