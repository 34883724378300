import { ResourceSchema } from '../../schema-types'

export type Status =
  keyof ResourceSchema<'alarms'>['attributes']['status']['labels']['list']

export enum AlarmTiming {
  NOW = 'NOW',
  LATER = 'LATER',
}

export type AlarmTimingOption = {
  label: string
  value: AlarmTiming
}
