import i18n from '@/plugins/i18n'

export const getAvailabilitiesText = (
  availabilities: { code: string }[],
): string => {
  const BASE_TRANSLATION = 'res.entity-label-availability-types.attr.code.list'
  if (!availabilities) return i18n.t('entity_labels_views.empty')

  return availabilities
    .map((availability) =>
      i18n.t(`${BASE_TRANSLATION}.${availability.code}.label`),
    )
    .join(', ')
}
