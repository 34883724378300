<template>
  <tt-tile v-bind="$props">
    <ResourceAllowedOperations
      v-slot="{ allowsActions }"
      class="d-flex align-center flex-grow-1"
      :resource-name="resource"
      :style="{ width: '100%' }"
    >
      <div class="flex-grow-1">
        <tt-attr class="font-weight-bold text-body-2" :name="keys.LABEL" />
      </div>
      <div class="flex-grow-1">
        <div v-if="showTimeLabels" class="d-flex align-center justify-end">
          <div class="text-center">
            <div class="time-label text-secondary" v-text="'from'" />
            <tt-attr class="time-value text-h6" :name="keys.START_TIME_KEY" />
          </div>
          <div class="mx-3">-</div>
          <div class="text-center">
            <div class="time-label text-secondary" v-text="'to'" />
            <tt-attr class="time-value text-h6" :name="keys.END_TIME_KEY" />
          </div>
        </div>
        <div v-else class="do-not-perform text-right">
          <span
            class="font-weight-bold text-secondary text-body-2"
            v-text="notPerformText"
          />
        </div>
      </div>
      <div v-if="allowsActions" class="ml-5">
        <v-btn
          icon
          color="level1Control"
          :title="$t(`${memberTranslationScope}.remove-btn`)"
          @click.stop="confirmRemove"
        >
          <v-icon small> mdi-delete-outline </v-icon>
        </v-btn>
      </div>
    </ResourceAllowedOperations>
  </tt-tile>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import {
  rangeTimeKeys,
  exceptionRangeTimeKeys,
} from '@/tt-entity-design/src/components/mobile-schedule-groups/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleGroupRunsheetTile',
  inject: ['getItemHook'],
  computed: {
    showTimeLabels(): boolean {
      return (
        !this.isException ||
        this.getItemHook().getRawValue(exceptionRangeTimeKeys.PERFORM)
      )
    },
    resource(): string {
      return this.getItemHook().getResourceName()
    },
    isException(): boolean {
      return this.resource === Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES
    },
    keys(): Record<string, string> {
      return this.isException ? exceptionRangeTimeKeys : rangeTimeKeys
    },
    notPerformText(): string {
      return this.$t(`${this.exceptionTranslationScope}.not-perform`)
    },
    memberTranslationScope(): string {
      return this.isException
        ? this.exceptionTranslationScope
        : this.scheduleTranslationScope
    },
    scheduleTranslationScope(): string {
      return 'tt-entity-design.mobile-schedules.tile'
    },
    exceptionTranslationScope(): string {
      return 'tt-entity-design.mobile-schedule-exception-types.tile'
    },
  },
  methods: {
    confirmRemove(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: this.$t(
          `${this.memberTranslationScope}.confirm-deletion-dialog`,
        ),
        accept: this.remove,
      })
    },
    remove(): void {
      this.$emit('remove')
    },
  },
})
</script>
<style scoped>
.text-secondary {
  color: var(--v-level1Control-base);
}

.time-label {
  font-size: 13px;
  line-height: 23px;
}

.time-value {
  color: var(--v-primary-base);
  line-height: 23px;
}
</style>
