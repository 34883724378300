export enum Routes {
  HOME = 'home',
  //@todo: can be removed ? (https://tracktik.atlassian.net/browse/LAB-1190)
  BROWSE = 'browse',
  //@todo: can be removed ? (https://tracktik.atlassian.net/browse/LAB-1190)
  BROWSE_RESOURCE = 'browse-resource',
  FORM_KITCHEN_SINK = 'form-kitchen-sink',
  SHARED_WITH_ME = 'shared-with-me',
  SHARED_WITH_ME_VIEW = 'shared-with-me-view',
  ARCHIVES = 'archives',
  EXPLORE = 'explore',
  EXPLORE_DASHBOARDS = 'explore-dashboards',
  //@todo: can be removed ? (https://tracktik.atlassian.net/browse/LAB-1190)
  EXPLORE_STARRED = 'explore-starred',
  EXPLORE_CATEGORY = 'explore-category',
  WIDGET_CATEGORY_VIEW = 'widget-category-view',
  WIDGET_NO_CONTEXT_VIEW = 'widget-view-no-context',
  WIDGET_VIEW = 'widget-view',
  WIDGET_EDIT = 'widget-edit',
  LEARN_DATA_MODEL = 'learn-data-models',
  TQL_PLAYGROUND = 'tql-playground',
  NOT_FOUND = 'not-found',
  PREDEFINED_VIEW = 'predefined_view',
  NEW_WIDGET = 'widget-new',
  PRESENTATION_WIDGET = 'presentation-widget',
  WIDGET_MODULAR_HOOK = 'widget-modular-hook',
  WIDGET_PDF = 'widget-pdf',
}
