import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { LeaveRequestsStatusColorOptions } from './types'

export default [
  'id',
  'employee',
  { attributeName: 'startedOn', format: 'asDate' },
  { attributeName: 'endedOn', format: 'asDate' },
  'requestedDays',
  {
    headerText: 'tt-entity-design.leave-management.leave-requests.sli',
    attributeName: 'serviceLevelImpact',
    component: {
      is: 'ServiceLevelImpactPercentage',
    },
  },
  'leaveType',
  'reason',
  {
    headerText: 'res.leave-requests.attr.status.label',
    attributeName: 'status',
    component: {
      is: 'StatusChip',
      props: {
        statusColorOptions: LeaveRequestsStatusColorOptions,
      },
    },
  },
] as ColumnInputDefinition[]
