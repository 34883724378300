<template>
  <div>
    <DashboardLayoutThumbnail
      :data="layout"
      :box-style="boxStyle"
      :height="height"
      :margin="margin"
      :border="true"
    >
      <template #default="props">
        <div
          class="fill-height text-center align-center"
          style="cursor: pointer"
        >
          <img
            :src="getThumb(props.col)"
            style="max-width: 100%; max-height: 100%"
          />
        </div>
      </template>
    </DashboardLayoutThumbnail>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import DashboardLayoutThumbnail from './DashboardLayoutThumbnail.vue'
import {
  DashboardColumn,
  DashboardWidgetModel,
  DashboardRow,
} from '@/tt-widget-components'

export default Vue.extend({
  name: 'DashboardContentThumbnail',
  components: {
    DashboardLayoutThumbnail,
  },
  props: {
    preview: Boolean,
    boxStyle: Object,
    widget: Object as PropType<DashboardWidgetModel>,
    width: { type: Number, default: 100 },
    height: { type: Number, default: 100 },
  },
  computed: {
    layout(): DashboardRow[] {
      return this.widget?.rows || []
    },
    margin(): number {
      return Math.max(2, 2, (this.width * 1.5) / 100)
    },
  },
  methods: {
    getThumb(col: DashboardColumn) {
      const widgetModel = Array.isArray(col.widgetLookup)
        ? col.widgetLookup[0]
        : col.widgetLookup
      if (!widgetModel || !widgetModel.is) {
        return null
      }

      const widget =
        this.$appContext.widgetServices.widgetManager.getWidgetByName(
          widgetModel.is,
        )
      return widget ? widget.config.thumbnail : null
    },
  },
})
</script>
