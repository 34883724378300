import { reactive } from 'vue'
import { computedGetter } from '@tracktik/tt-helpers/lib/functions/computedGetter'
import isEqual from 'lodash/isEqual'
import { DatePresetValue } from '../date-presets'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Filter } from '@/tt-widget-components'
import { createFilter } from '../../util'
import { State, TemporalFilterManager } from './types'
import { addTimezone } from '../timezone'
import { BETWEEN_SEPARATOR_TEMPORAL, DEFAULT_TIME } from '../contants'
import { Timezone, UTC } from '@/helpers/dates/timezones'
import { DateTime } from '@/helpers/dates/types'
import { parseTemporalFilter } from './utils'
import { createDateTimeString } from '@/helpers/dates/createDateTimeString'

export const createTemporalFilterManager = (
  inputFilter: Filter,
): TemporalFilterManager => {
  const normalizedInputFilter: Filter = {
    ...inputFilter,
    //@ts-ignore
    operator: inputFilter.operator?.toUpperCase() ?? '',
  }

  const state: State = reactive({
    preset: null,
    fromDate: null,
    fromTime: null,
    toDate: null,
    toTime: null,
    timezone: null,
    operator: null,
  })

  const initializeState = () => {
    const filterParsed = parseTemporalFilter(normalizedInputFilter)

    state.preset = filterParsed.preset || null
    state.fromDate = filterParsed.fromDate || null
    state.fromTime = filterParsed.fromTime || null
    state.toDate = filterParsed.toDate || null
    state.toTime = filterParsed.toTime || null
    state.timezone = filterParsed.timezone || null
    state.operator = filterParsed.operator || null
  }

  const getTimezone = computedGetter(() => state.timezone || UTC)

  const isPreset = computedGetter(() => !!state.preset)

  const isExactDate = computedGetter(() => !!(state.fromDate && !state.toDate))

  const isDateRange = computedGetter(() => !!(state.fromDate && state.toDate))

  const isValid = computedGetter(
    () => isPreset() || isExactDate() || isDateRange(),
  )

  const getAttribute = () => inputFilter.attribute

  const getOperator = computedGetter(() =>
    isDateRange()
      ? FilterOperatorType.BETWEEN
      : state.operator ?? FilterOperatorType.EQUAL,
  )

  const getExactDatetime = computedGetter(
    (): DateTime => ({
      date: state.fromDate,
      time: state.fromTime || DEFAULT_TIME,
    }),
  )

  const getValue = computedGetter(() => {
    if (!isValid()) return ''

    if (isPreset()) return state.preset

    if (isExactDate()) {
      const { date, time } = getExactDatetime()
      const dateTimeString = createDateTimeString(date, time)

      return addTimezone(dateTimeString, getTimezone())
    }

    if (isDateRange()) {
      const newRange = getDateTimeRange()
        .map(({ date, time }) => createDateTimeString(date, time))
        .join(BETWEEN_SEPARATOR_TEMPORAL)

      return addTimezone(newRange, getTimezone())
    }
  })

  const getFilter = () => {
    if (!isValid()) return null

    return createFilter(getAttribute(), getOperator(), getValue())
  }

  const hasChanged = computedGetter(() => {
    const originalFilter = {
      attribute: getAttribute(),
      operator: inputFilter.operator,
      value: inputFilter.value,
    }

    return !isEqual(originalFilter, getFilter())
  })

  const getDateTimeRange = computedGetter((): DateTime[] => [
    { date: state.fromDate, time: state.fromTime || DEFAULT_TIME },
    { date: state.toDate, time: state.toTime || DEFAULT_TIME },
  ])

  const setTimezone = (newTimezone: Timezone) => {
    state.timezone = newTimezone
  }

  const getPreset = () => state.preset

  const setPreset = (preset: DatePresetValue) => {
    state.fromDate = null
    state.fromTime = null
    state.toDate = null
    state.toTime = null

    state.preset = preset
  }

  const setDateRange = (newDates: string[]) => {
    state.preset = null

    const [fromDate, toDate] = [...newDates].sort((a, b) => (a < b ? -1 : 1))

    state.fromDate = fromDate
    state.toDate = toDate || null
  }

  const getDraftOperator = () => inputFilter.operator

  const getStartDate = () => state.fromDate || ''

  const setStartDate = (newDate: string) => {
    state.fromDate = newDate
  }

  const getEndDate = () => state.toDate || ''

  const setEndDate = (newDate: string) => {
    state.toDate = newDate
  }

  const getStartTime = () => state.fromTime || DEFAULT_TIME

  const setStartTime = (newTime: string) => {
    state.fromTime = newTime
  }

  const getEndTime = () => state.toTime || DEFAULT_TIME

  const setEndTime = (newTime: string) => {
    state.toTime = newTime
  }

  const isEmpty = computedGetter(() =>
    Object.values(state).every((value) => value === null),
  )

  initializeState()

  return {
    isPreset,
    isDateRange,
    isExactDate,
    isValid,
    hasChanged,
    isEmpty,
    getDateTimeRange,
    getTimezone,
    setTimezone,
    setDateRange,
    setPreset,
    getDraftOperator,
    getPreset,
    getFilter,
    getStartDate,
    setStartDate,
    getEndDate,
    setEndDate,
    getStartTime,
    setStartTime,
    getEndTime,
    setEndTime,
  }
}
