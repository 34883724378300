import hook from './HeatMapWidgetHook'
import HeatMapWidgetEditor from './HeatMapWidgetEditor.vue'
import schema from './schema'
import Vue from 'vue'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(/* webpackChunkName: "HeatMapWidget" */ './HeatMapWidget.vue').catch(
    handleAsyncComponentError,
  )

Vue.component('HeatMapWidgetEditor', HeatMapWidgetEditor)

const HeatMapWidget: WidgetTypeDefinition = {
  name: WidgetName.HEAT_MAP_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    HeatMapWidget: {
      view: {
        is: 'HeatMapWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-grid',
    print: true,
    color: '#d03762',
    thumbnail: require('./heat-map.png'),
  },
}

export default HeatMapWidget
