<template>
  <div class="text-caption text-secondary">
    <v-icon
      color="level1Control"
      class="mr-2"
      medium
      :style="{ marginTop: '-3px' }"
      v-text="'mdi-information-outline'"
    />
    <span
      class="text-subtitle-1"
      v-text="$t('tt-entity-design.exception-groups.actions.unarchive-confirm')"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ExceptionTypeGroupsUnarchive',
})
</script>
