<template>
  <div>
    <slot v-bind="{ exportCSV, isLoading }" />
  </div>
</template>

<script lang="ts">
import uuid from 'uuid/v1'
import Vue, { PropType } from 'vue'

import { CancelableFunction } from 'tracktik-sdk/lib/common/entity-collection'

import { LayoutWindowEvent } from '@/tt-app-layout'
import { ERR_CSV_EMPTY_DATA } from '@/tt-widget-factory'

import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import CollectionWidgetHook from '@/tt-widget-components/base/CollectionWidgetHook'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'

import { WidgetModels } from '../schemas-types'

const REQUEST_CANCEL_MSG = 'Request cancelled by user'

export default Vue.extend({
  name: 'DownloadCSVButton',
  props: {
    hook: {
      required: true,
      type: Object as PropType<
        DataSetWidgetHook<WidgetModels> | CollectionWidgetHook<WidgetModels>
      >,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    containsHavingQL(): boolean {
      // @ts-ignore
      return !!this.hook.widget?.filters?.havingQL
    },
    fileName(): string {
      return this.hook.widget.title
    },
  },
  methods: {
    openConfirmDialog(message: string): Promise<void> {
      return new Promise((resolve, reject) => {
        this.$appContext.eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
          message,
          accept: () => resolve(),
          cancel: () => reject(),
        })
      })
    },
    showDownloadErrorPopUp() {
      const message = this.$t(`widgets.details.download_error`)
      this.showErrorPopUp(message)
    },
    showNoDataErrorPopUp() {
      const message = this.$t(`widgets.details.download_csv_no_data`)
      this.showErrorPopUp(message)
    },
    showErrorPopUp(message: string) {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message },
      )
    },
    openLoadingDialog(
      notificationChipId: string,
      handleCancel: CancelableFunction,
    ) {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_LOADING_OPEN,
        {
          title: this.fileName,
          subTitle: `${this.$t('widgets.details.downloading-file', {
            fileType: 'CSV',
          })}`,
          notificationChipId,
          cancel: () => {
            handleCancel(REQUEST_CANCEL_MSG)
            this.closeLoadingDialog()
          },
        },
      )
    },
    closeLoadingDialog() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_LOADING_CLOSE,
      )
    },
    removeNotificationChip(notificationChipId: string): void {
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.NOTIFICATION_CHIPS_REMOVE,
        { key: notificationChipId },
      )
    },
    async checkDialogs() {
      // if totalData is bigger than the limit, show a confirm dialog
      if (this.hook.totalEntities > TTC_API_MAX_LIMIT) {
        const message = this.$t('widgets.details.csv_limit_message', {
          limitData: `${TTC_API_MAX_LIMIT}`,
          totalData: `${this.hook.totalEntities}`,
        })
        return this.openConfirmDialog(message)
      }

      if (this.containsHavingQL)
        return this.openConfirmDialog(
          this.$t('widgets.details.download_csv_havingQl'),
        )
    },
    async exportCSV() {
      const notificationChipId = uuid()

      try {
        await this.checkDialogs()
      } catch (e) {
        // if the user cancels the dialog, stops the execution
        return
      }

      try {
        this.isLoading = true

        const csvExport = this.hook.exportCSV()

        this.openLoadingDialog(notificationChipId, csvExport.cancel)

        await csvExport.run()

        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.DIALOG_LOADING_COMPLETE,
        )
      } catch (error) {
        // if the request was cancelled, stops the execution
        if (error?.message === REQUEST_CANCEL_MSG) {
          return
        }

        if (error?.message === ERR_CSV_EMPTY_DATA) {
          console.warn('No data to download as CSV.')
          this.showNoDataErrorPopUp()
          this.closeLoadingDialog()
          return
        }

        console.error(error)

        this.showDownloadErrorPopUp()
      } finally {
        // as requested by UX/UI team, it delays a second before closing/removing the loading indicators
        setTimeout(() => {
          this.isLoading = false
          this.removeNotificationChip(notificationChipId)
          this.closeLoadingDialog()
        }, 1000)
      }
    },
  },
})
</script>
