import { modularManager } from '@/tt-app-modular'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import OperationCenterEscalationPoliciesActionBar from './OperationCenterEscalationPoliciesActionBar.vue'
import OperationCenterEscalationPoliciesTimeline from './OperationCenterEscalationPoliciesTimeline.vue'
import OperationCenterEscalationPoliciesForm from './form/OperationCenterEscalationPoliciesForm.vue'
import OperationCentersToEscalateToField from './form/OperationCentersToEscalateToField.vue'
import TicketTypesField from './form/TicketTypesField.vue'

export default {
  install(Vue) {
    Vue.component(
      'OperationCenterEscalationPoliciesForm',
      OperationCenterEscalationPoliciesForm,
    )
    Vue.component(
      'OperationCentersToEscalateToField',
      OperationCentersToEscalateToField,
    )
    Vue.component('TicketTypesField', TicketTypesField)

    registerResourceForm(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      'OperationCenterEscalationPoliciesForm',
    )

    modularManager.registerActionBar(
      Resources.OPERATION_CENTER_ESCALATION_POLICIES,
      {
        is: OperationCenterEscalationPoliciesActionBar,
      },
    )
    addPreviewTab(Resources.OPERATION_CENTER_ESCALATION_POLICIES, {
      title: createI18nResourceKey(
        Resources.OPERATION_CENTER_ESCALATION_POLICIES,
        'escalationRules',
      ),
      is: OperationCenterEscalationPoliciesTimeline,
    })
  },
}
