// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeClassesTile from './EmployeeClassesTile.vue'
import EmployeeClassesColumns from './EmployeeClassesColumns'
import EmployeeClassesDetail from './EmployeeClassesDetail.vue'
import EmployeeClassesMetaCard from './EmployeeClassesMetaCard.vue'
import EmployeeClassesReference from './EmployeeClassesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeClassesTile', EmployeeClassesTile)
    Vue.component('EmployeeClassesDetail', EmployeeClassesDetail)
    Vue.component('EmployeeClassesMetaCard', EmployeeClassesMetaCard)
    Vue.component('EmployeeClassesReference', EmployeeClassesReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeClassesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeClassesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeClassesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASSES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeClassesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeClassesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
