// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ShiftProposalQueueItemsTile from './ShiftProposalQueueItemsTile.vue'
import ShiftProposalQueueItemsColumns from './ShiftProposalQueueItemsColumns'
import ShiftProposalQueueItemsDetail from './ShiftProposalQueueItemsDetail.vue'
import ShiftProposalQueueItemsMetaCard from './ShiftProposalQueueItemsMetaCard.vue'
import ShiftProposalQueueItemsReference from './ShiftProposalQueueItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ShiftProposalQueueItemsTile', ShiftProposalQueueItemsTile)
    Vue.component(
      'ShiftProposalQueueItemsDetail',
      ShiftProposalQueueItemsDetail,
    )
    Vue.component(
      'ShiftProposalQueueItemsMetaCard',
      ShiftProposalQueueItemsMetaCard,
    )
    Vue.component(
      'ShiftProposalQueueItemsReference',
      ShiftProposalQueueItemsReference,
    )

    modularManager.addResourcePreset(
      Resources.SHIFT_PROPOSAL_QUEUE_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ShiftProposalQueueItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PROPOSAL_QUEUE_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ShiftProposalQueueItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PROPOSAL_QUEUE_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftProposalQueueItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PROPOSAL_QUEUE_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ShiftProposalQueueItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SHIFT_PROPOSAL_QUEUE_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ShiftProposalQueueItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
