<template>
  <div class="d-flex align-center">
    <VendorsTile class="w-100" />

    <div class="flex--column justify-content-center pa-2">
      <v-btn
        small
        class="w-100 mb-2 assign--button"
        depressed
        @click="selectVendor"
      >
        <span v-text="$t('common.select')" />
      </v-btn>

      <v-btn
        color="ttPrimary"
        text
        small
        class="w-100"
        style="text-transform: none !important"
        @click="preview"
      >
        <span v-text="$t('common.details')" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActiveWindowProvider } from '@/tt-app-layout/types'
import { OnSelectCallback } from '../types'
import { Resources } from '@/tt-entity-design/src/types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'VendorsTileForDispatchAssign',
  inject: ['getItemHook', 'activeWindow'],
  props: {
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
  },
  computed: {
    entityId(): number {
      return this.getItemHook().getEntityId()
    },
  },
  methods: {
    selectVendor() {
      this.onSelect({ resource: Resources.VENDORS, id: this.entityId })
    },
    preview() {
      const { activeWindow } = this
      this.getItemHook().dispatchPreview({ activeWindow }, false)
    },
  },
})
</script>

<style scoped>
.assign--button {
  color: var(--v-ttPrimary-base);
  background-color: var(--v-ttPrimary-lighten5) !important;
  text-transform: none !important;
}
</style>
