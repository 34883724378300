<template>
  <TThemeProvider
    v-slot="{ colors }"
    :config="{
      textColor,
      backgroundColor,
      themeKey,
    }"
  >
    <v-chip
      :label="isLabelType"
      :small="isSmall"
      class="chip opacity-blended"
      :text-color="colors.text"
      :color="colors.background"
      v-bind="modifiers"
      v-text="translatedText"
    />
  </TThemeProvider>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Modifier, TChipLabelType } from '../types'
import { TrackTikThemeKey } from '@/plugins/vuetify-plugin'

export default Vue.extend({
  name: 'TChipLabel',
  props: {
    textKey: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    themeKey: {
      type: String as PropType<TrackTikThemeKey>,
      default: 'muted',
    },
    modifiers: {
      type: Object as PropType<Modifier>,
      default: () => ({}),
    },
    type: {
      type: String as PropType<TChipLabelType>,
      default: TChipLabelType.Label,
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLabelType(): boolean {
      return this.type === TChipLabelType.Label
    },
    translatedText(): string {
      return this.$t(this.textKey)
    },
  },
})
</script>

<style scoped lang="scss">
@import 'vuetify/src/styles/settings/_variables';

.chip {
  text-transform: uppercase;
  font-weight: bold;
  justify-content: center;

  &:hover:before {
    display: none; /* Remove hover effect */
  }
}
</style>
