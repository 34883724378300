import ExceptionTypesUnassignBtn from './ExceptionTypesUnassignBtn.vue'
import ExceptionTypeGroupItemActionsColumn from './ExceptionTypeGroupItemActionsColumn.vue'
export default {
  install(Vue) {
    Vue.component('ExceptionTypesUnassignBtn', ExceptionTypesUnassignBtn)

    Vue.component(
      'ExceptionTypeGroupItemActionsColumn',
      ExceptionTypeGroupItemActionsColumn,
    )
  },
}
