// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskSiteSettingsTile from './TaskSiteSettingsTile.vue'
import TaskSiteSettingsColumns from './TaskSiteSettingsColumns'
import TaskSiteSettingsDetail from './TaskSiteSettingsDetail.vue'
import TaskSiteSettingsReference from './TaskSiteSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaskSiteSettingsTile', TaskSiteSettingsTile)
    Vue.component('TaskSiteSettingsDetail', TaskSiteSettingsDetail)
    Vue.component('TaskSiteSettingsReference', TaskSiteSettingsReference)

    modularManager.addResourcePreset(
      Resources.TASK_SITE_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskSiteSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskSiteSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskSiteSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_SITE_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskSiteSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
