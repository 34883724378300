import { WidgetStoreInterface } from '@/tt-widget-factory'
import { AclRule } from '@/tt-widget-sharable'

export const CUSTOM_WIDGET_PROVIDER_NAME = 'my-insights'

// Fix a PHP issue
export const fixAclRule = (aclRule: AclRule | any): AclRule => {
  const { grant, deny } = aclRule || {}

  const getValidObject = (value) =>
    !value || Array.isArray(value) ? {} : value

  return {
    grant: getValidObject(grant),
    deny: getValidObject(deny),
  }
}

export const getCategoryName = (categoryId: string | number | null): string => {
  return categoryId && categoryId != ''
    ? `${CUSTOM_WIDGET_PROVIDER_NAME}-category|${categoryId}`
    : `${CUSTOM_WIDGET_PROVIDER_NAME}-category|`
}

export const toWidgetStoreModel = (
  dataView: any,
  { myId }: { myId: number },
): WidgetStoreInterface => {
  const widget =
    typeof dataView.configs == 'string'
      ? JSON.parse(dataView.configs)
      : dataView.configs

  const aclRule = fixAclRule(dataView?.meta?.aclRule)

  return {
    is: dataView.is,
    uid: dataView.uid,
    title: dataView.title,
    meta: { ...dataView.meta, aclRule },
    ownedByMe: dataView?.meta?.ownedBy?.id === myId,
    category: getCategoryName(dataView.category),
    provider: CUSTOM_WIDGET_PROVIDER_NAME,
    widget: widget,
  }
}
