// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTypeTranslationsTile from './AssetTypeTranslationsTile.vue'
import AssetTypeTranslationsColumns from './AssetTypeTranslationsColumns'
import AssetTypeTranslationsDetail from './AssetTypeTranslationsDetail.vue'
import AssetTypeTranslationsReference from './AssetTypeTranslationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetTypeTranslationsTile', AssetTypeTranslationsTile)
    Vue.component('AssetTypeTranslationsDetail', AssetTypeTranslationsDetail)
    Vue.component(
      'AssetTypeTranslationsReference',
      AssetTypeTranslationsReference,
    )

    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TRANSLATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTypeTranslationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TRANSLATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTypeTranslationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TRANSLATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTypeTranslationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TYPE_TRANSLATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTypeTranslationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
