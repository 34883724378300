<template>
  <tt-attr-row name="availabilities">
    <span
      class="text-clickable"
      @click.stop="openPreview"
      v-text="availabilitiesText"
    />
  </tt-attr-row>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { getAvailabilitiesText } from './utils'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityLabelsAvailabilitiesRow',
  inject: ['getItemHook'],
  computed: {
    availabilitiesText(): string {
      const availabilities = this.getItemHook().get('availabilities')

      return getAvailabilitiesText(availabilities)
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  created() {
    this.getItemHook().addAttribute('availabilities.code')
    this.getItemHook().getAttributesToFetch
  },
  methods: {
    openPreview(): void {
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        resourceName:
          this.getItemHook().getAttributeRelationResource('availabilities'),
        entityId: this.getItemHook().getEntityId(),
        entity: this.getItemHook().entityReference,
        itemHook: this.itemHook,
        hasToAddPages: true,
        is: 'EntityRelationListPreview',
      })
    },
  },
})
</script>
