// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeavePoliciesTile from './LeavePoliciesTile.vue'
import LeavePoliciesColumns from './LeavePoliciesColumns'
import LeavePoliciesDetail from './LeavePoliciesDetail.vue'
import LeavePoliciesMetaCard from './LeavePoliciesMetaCard.vue'
import LeavePoliciesReference from './LeavePoliciesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeavePoliciesTile', LeavePoliciesTile)
    Vue.component('LeavePoliciesDetail', LeavePoliciesDetail)
    Vue.component('LeavePoliciesMetaCard', LeavePoliciesMetaCard)
    Vue.component('LeavePoliciesReference', LeavePoliciesReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_POLICIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeavePoliciesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeavePoliciesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePoliciesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePoliciesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeavePoliciesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
