// Enter your custom installation here

import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import MobileScheduleGroupsInstancesTab from './MobileScheduleGroupsInstancesTab.vue'
import MobileScheduleGroupsPeriodicalAssignmentTab from './MobileScheduleGroupsPeriodicalAssignmentTab.vue'
import MobileScheduleGroupsForm from './MobileScheduleGroupsForm.vue'

export default {
  install(Vue) {
    Vue.component(
      'MobileScheduleGroupsInstancesTab',
      MobileScheduleGroupsInstancesTab,
    )
    Vue.component('MobileScheduleGroupsForm', MobileScheduleGroupsForm)
    Vue.component(
      'MobileScheduleGroupsPeriodicalAssignmentTab',
      MobileScheduleGroupsPeriodicalAssignmentTab,
    )
    registerResourceForm('mobile-schedule-groups', 'MobileScheduleGroupsForm')
    addPreviewTab(Resources.MOBILE_SCHEDULE_GROUPS, {
      title: 'mobile_schedule_group.preview.instances_tab',
      is: 'MobileScheduleGroupsInstancesTab',
    })

    addPreviewTab(Resources.MOBILE_SCHEDULE_GROUPS, {
      title: 'mobile_schedule_group.preview.periodical_assignment_tab',
      is: 'MobileScheduleGroupsPeriodicalAssignmentTab',
      condition: ({ itemHook }) => {
        //Show the tab only when there is a periodical assignment
        return !!itemHook.get('periodicSchedule.id')
      },
    })
  },
}
