<template>
  <LeavePolicyRangeField
    :name="accrualRateFieldName"
    :disabled="disabled"
    :response-errors="responseErrors"
    :max="1"
    :min="0.0001"
    hide-input-arrows
    :placeholder="placeholder"
  />
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components/types'
import LeavePolicyRangeField from '../../LeavePolicyRangeField.vue'
import { LeaveManagementApiError } from '../../types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'LeavePolicyItemAccruelRateField',
  components: { LeavePolicyRangeField },
  inject: ['formHook'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    responseErrors: {
      type: Object as PropType<LeaveManagementApiError>,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    accrualRateFieldName(): string {
      return 'accrualRate'
    },
  },
})
</script>
