export * from './NotificationService';
export * from './plugins';
export var NotificationClientProvider;
(function (NotificationClientProvider) {
    NotificationClientProvider["FIREBASE"] = "FIREBASE";
    NotificationClientProvider["PUSHER"] = "PUSHER";
})(NotificationClientProvider || (NotificationClientProvider = {}));
export var NotificationClientOwnership;
(function (NotificationClientOwnership) {
    NotificationClientOwnership["PRIVATE"] = "PRIVATE";
    NotificationClientOwnership["PUBLIC"] = "PUBLIC";
})(NotificationClientOwnership || (NotificationClientOwnership = {}));
export var NotificationCapacities;
(function (NotificationCapacities) {
    NotificationCapacities["FORM"] = "FORM";
    NotificationCapacities["CHOISE"] = "CHOISE";
    NotificationCapacities["MESSAGE"] = "MESSAGE";
    NotificationCapacities["CHAT"] = "CHAT";
    NotificationCapacities["WEB"] = "WEB";
    NotificationCapacities["STREAM"] = "STREAM";
    NotificationCapacities["TALK"] = "TALK";
    NotificationCapacities["FLOW"] = "FLOW";
})(NotificationCapacities || (NotificationCapacities = {}));
export var NotificationPermissionStatus;
(function (NotificationPermissionStatus) {
    NotificationPermissionStatus["GRANTED"] = "GRANTED";
    NotificationPermissionStatus["DENIED"] = "DENIED";
    NotificationPermissionStatus["DEFAULT"] = "DEFAULT";
})(NotificationPermissionStatus || (NotificationPermissionStatus = {}));
export var PushEndpoints;
(function (PushEndpoints) {
    PushEndpoints["REGISTER"] = "reach/push-client/register";
    PushEndpoints["UNREGISTER"] = "reach/push-client/unregister";
    PushEndpoints["PING"] = "reach/push-client/ping";
})(PushEndpoints || (PushEndpoints = {}));
