import { createAuthModule } from '@tracktik/tt-authentication'

import metadataProvider from '@/tt-widget-factory/services/metadata-provider'

import * as Commons from './commons'
import { AppContext, AppContextEvents } from './types'
import { RouteOAuth, RouteSSO } from '../tt-app-builder/core-routes'

export * from './types'

const fetchAllMetadatasActions = async (context: AppContext) => {
  if (metadataProvider.isRemote()) {
    await metadataProvider.fetchAll(context)
  }
}

export const events = (context: AppContext) => ({
  async [AppContextEvents.USER_IS_SIGNED_IN]() {
    await fetchAllMetadatasActions(context)
  },
  async [AppContextEvents.USER_SIGNED_IN]() {
    await fetchAllMetadatasActions(context)
  },
  async [AppContextEvents.SIGN_OUT]() {
    await context.authModule.signOut()
  },
})

export const needsLocalStorage = (location = window.location) => {
  try {
    const url = new URL(location.href)

    /**
     * Modular hook (dashboard in TTC within iFrame) are identified as standalone application,
     * thus they are trying to use the localStorage (which is shared for datalab domain).
     *
     * @TODO: properly turn off the usage of localStorage in DataLab iFrames
     * https://tracktik.atlassian.net/browse/FE-1108
     *
     */
    const shouldUseLocalstorage = [RouteSSO.path, RouteOAuth.path].every(
      (path) => !url.hash.includes(path),
    )

    return shouldUseLocalstorage
  } catch (err) {
    console.error(err)
    console.warn(`URL parse failed needsLocalStorage resolved to false`)
    return false
  }
}

export const builder = () => {
  return Commons.builder({
    events,
    services: {
      authModule(context) {
        return createAuthModule({
          useLocalStorage: needsLocalStorage(),
          dispatchEvent(event, payload) {
            context.eventManager.dispatchEvent(event, payload)
          },
        })
      },
    },
  })
}

export const create = (): AppContext => {
  return builder().build()
}
