// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeavePolicyCarryOverLogsTile from './LeavePolicyCarryOverLogsTile.vue'
import LeavePolicyCarryOverLogsColumns from './LeavePolicyCarryOverLogsColumns'
import LeavePolicyCarryOverLogsDetail from './LeavePolicyCarryOverLogsDetail.vue'
import LeavePolicyCarryOverLogsMetaCard from './LeavePolicyCarryOverLogsMetaCard.vue'
import LeavePolicyCarryOverLogsReference from './LeavePolicyCarryOverLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeavePolicyCarryOverLogsTile', LeavePolicyCarryOverLogsTile)
    Vue.component(
      'LeavePolicyCarryOverLogsDetail',
      LeavePolicyCarryOverLogsDetail,
    )
    Vue.component(
      'LeavePolicyCarryOverLogsMetaCard',
      LeavePolicyCarryOverLogsMetaCard,
    )
    Vue.component(
      'LeavePolicyCarryOverLogsReference',
      LeavePolicyCarryOverLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_CARRY_OVER_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeavePolicyCarryOverLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_CARRY_OVER_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeavePolicyCarryOverLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_CARRY_OVER_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePolicyCarryOverLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_CARRY_OVER_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'LeavePolicyCarryOverLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_POLICY_CARRY_OVER_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeavePolicyCarryOverLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
