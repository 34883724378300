<template>
  <v-card flat>
    <v-subheader class="px-0 text--semibold">
      {{ escalationRulesLabel }}
    </v-subheader>
    <v-timeline dense>
      <EscalationRuleItemField
        v-for="(escalationRule, index) in escalationRules"
        :key="index"
        :index="index"
        :has-remove-button="escalationRules.length > 1"
        :value="escalationRule"
        :disabled-ids="getDisabledIds(index)"
        @input="handleInput(index, $event)"
        @remove="removeRuleItem(index)"
      />
    </v-timeline>
    <div class="d-flex justify-end">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            color="success"
            icon
            x-large
            depressed
            v-on="on"
            @click="addRuleItem"
          >
            <v-icon> mdi-playlist-plus </v-icon>
          </v-btn>
        </template>
        <span>{{
          $t(
            'tt-entity-design.operation-center-escalation-policies.tooltip.add-rule',
          )
        }}</span>
      </v-tooltip>
    </div>
  </v-card>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import EscalationRuleItemField from './EscalationRuleItemField.vue'
import { EscalationRule } from '../types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

const getIdsExceptIndex = (index: number, arr: EscalationRule[]) =>
  arr
    .filter((_, i) => index !== i)
    .map(({ operationCentersToEscalateTo }) => operationCentersToEscalateTo)
    .flat()

const defaultEscalationRule: EscalationRule = {
  operationCentersToEscalateTo: [],
  timeToEscalate: null,
}

export default (Vue as VueWithInjections).extend({
  name: 'EscalationRulesField',
  components: {
    EscalationRuleItemField,
  },
  inject: ['formHook'],
  computed: {
    escalationRulesLabel(): string {
      return this.formHook().getFieldLabel('escalationRules')
    },
    escalationRules: {
      get(): EscalationRule[] {
        return this.formHook().getPathValue('escalationRules') || []
      },
      set(value: EscalationRule[]) {
        this.formHook().setObjectValue('escalationRules', value)
      },
    },
  },
  methods: {
    addRuleItem(): void {
      const newEscalationRules = [
        ...this.escalationRules,
        defaultEscalationRule,
      ]

      this.formHook().setObjectValue('escalationRules', newEscalationRules)
    },
    removeRuleItem(index: number): void {
      const newEscalationRules = [...this.escalationRules]
      newEscalationRules.splice(index, 1)

      this.formHook().setObjectValue('escalationRules', newEscalationRules)
    },
    handleInput(index: number, escalationRule: EscalationRule) {
      const newEscalationRules = [...this.escalationRules]
      newEscalationRules[index] = escalationRule

      this.formHook().setObjectValue('escalationRules', newEscalationRules)
    },
    getDisabledIds(index: number): number[] {
      return [
        this.formHook().getPathValue('operationCenter'),
        ...getIdsExceptIndex(index, this.escalationRules),
      ]
    },
  },
})
</script>
