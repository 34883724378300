<template>
  <div>
    <v-autocomplete
      v-model="model"
      :label="label"
      :placeholder="placeholder"
      dense
      outlined
      :items="items"
      clearable
      v-on="$listeners"
    >
      <template #selection="data">
        <v-icon :color="color" style="margin-right: 20px">
          {{ data.item.value }}
        </v-icon>
      </template>
      <template #item="data">
        <v-list-item-action>
          <v-icon large :color="color">{{ data.item.value }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ data.item.text }}
        </v-list-item-title>
      </template>
    </v-autocomplete>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Icons from '@/config/icons.json'

export default Vue.extend({
  name: 'IconSelector',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  data() {
    return {
      items: Icons.icons.map((item) => {
        return {
          value: item[0],
          text: item[1],
        }
      }),
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(newValue: string): void {
        this.$emit('input', newValue || '')
      },
    },
  },
})
</script>
