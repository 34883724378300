<template>
  <div>
    <json-field name="reason" />
    <v-divider class="pb-6" />
    <TAlert :text="cancelPTOMessage" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LeaveRequestsDeclineForm',
  computed: {
    cancelPTOMessage(): string {
      return this.$t('tt-entity-design.leave-requests.cancel-pto-message')
    },
  },
})
</script>
