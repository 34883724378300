import i18n from '@/plugins/i18n'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { FieldTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { isWhitelistedResourceInvalidField } from '@/tt-widget-entity-flow/helper'
import { modularManager, PresetTypes } from '@/tt-app-modular'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import CollectionWidgetHook from '../../base/CollectionWidgetHook'
import {
  ColumnDefinition,
  DefaultToolbar,
  DataTableWidgetModel,
  FullColumnDefinition,
  Pagination,
} from '../../schemas-types'
import { reactive } from 'vue'
import { EntityId, RowModel } from './types'
import { getColumnAttributeName } from '@/tt-widget-components/helpers'

export type ColumnInputDefinition = ColumnDefinition

const getUniqueIds = (ids: EntityId[]): EntityId[] => [...new Set(ids)]

export default class DataTableWidgetHook extends CollectionWidgetHook<DataTableWidgetModel> {
  enableInfiniteScrolling = false // TODO Implement infinite scrolling with this follow up ticket FE-1121

  private datatableState = reactive({
    /**
     * The entity IDs of the expanded rows
     */
    expandedRowIds: [] as EntityId[],

    /**
     * The entity IDs of the selected rows
     */
    selectedRowIds: [] as EntityId[],

    /**
     * The entity IDs of the disabled rows. They can't be selected / unselected.
     */
    disabledRowIds: [] as EntityId[],
  })

  /**
   * ----------------
   * Expanded rows
   * ----------------
   */

  /**
   * Given an entity ID, check if a row is expanded.
   */
  isRowExpanded(id: EntityId): boolean {
    return this.datatableState.expandedRowIds.includes(id)
  }

  /**
   * Given an entity ID, collapse the corresponding row if it is expanded.
   */
  collapseRow(id: EntityId) {
    this.datatableState.expandedRowIds =
      this.datatableState.expandedRowIds.filter(
        (expandedId) => expandedId !== id,
      )
  }

  /**
   * Collapse all rows.
   */
  collapseAllRows() {
    this.datatableState.expandedRowIds = []
  }

  /**
   * Given an entity ID, expand the corresponding row if it is collapsed.
   */
  expandRow(id: EntityId) {
    this.datatableState.expandedRowIds = [
      ...this.datatableState.expandedRowIds,
      id,
    ]
  }

  /**
   * ----------------
   * Row selection
   * ----------------
   */

  /**
   * Sync selected entities IDs state,
   * regardless of the disabled entities.
   */
  syncSelectedRows(ids: EntityId[]) {
    this.datatableState.selectedRowIds = [...ids]
  }

  /**
   * Select an entity
   */
  selectRow(id: EntityId) {
    if (this.isRowDisabled(id)) return

    this.selectMultipleRows([id])
  }

  /**
   * Deselect an entity
   */
  deselectRow(id: EntityId) {
    if (this.isRowDisabled(id)) return

    this.deselectMultipleRows([id])
  }

  /**
   * Check if an entity is selected
   */
  isRowSelected(id: EntityId): boolean {
    return this.getSelectedRowIds().includes(id)
  }

  /**
   * Toggle the selection of an entity
   */
  toggleRowSelection(id: EntityId) {
    if (this.isRowDisabled(id)) return

    if (this.isRowSelected(id)) {
      this.deselectRow(id)
    } else {
      this.selectRow(id)
    }
  }

  /**
   * Get all selected entity IDs
   */
  getSelectedRowIds(): EntityId[] {
    return [...this.datatableState.selectedRowIds]
  }

  /**
   * Get all entities IDs in the current view that are not disabled (meaning they are selectable).
   */
  getNonDisabledCurrentRows(): EntityId[] {
    const currentViewIds = this.state.entities.map((entity) => entity.id)

    return this.filterOutDisabledRows(currentViewIds)
  }

  /**
   * Deselect all entities in the current view
   */
  deselectCurrentViewRows() {
    const currentViewIds = this.getNonDisabledCurrentRows()

    this.deselectMultipleRows(currentViewIds)
  }

  /**
   * Select multiple entities
   */
  selectMultipleRows(ids: EntityId[]) {
    const newIds = this.filterOutDisabledRows(ids)

    this.datatableState.selectedRowIds = getUniqueIds([
      ...this.getSelectedRowIds(),
      ...newIds,
    ])
  }

  /**
   * Deselect multiple entities
   */
  deselectMultipleRows(ids: EntityId[]) {
    const idsToRemove = this.filterOutDisabledRows(ids)

    this.datatableState.selectedRowIds = this.getSelectedRowIds().filter(
      (id) => !idsToRemove.includes(id),
    )
  }

  /**
   * Select all entities in the current view (that are not disabled)
   */
  selectCurrentViewRows() {
    const currentViewIds = this.getNonDisabledCurrentRows()

    this.selectMultipleRows(currentViewIds)
  }

  /**
   * ----------------
   * Disabled row entities
   * ----------------
   */

  /**
   * Check if an entity is disabled (non selectable)
   */
  isRowDisabled(id: EntityId) {
    return this.datatableState.disabledRowIds.includes(id)
  }

  /**
   * Sync the disabled entities state with the given IDs
   */
  syncDisabledRows(ids: EntityId[]) {
    this.datatableState.disabledRowIds = [...ids]
  }

  /**
   * Given a list of entity IDs, returns a new list without the disabled IDs
   */
  filterOutDisabledRows(ids: EntityId[]) {
    return ids.filter((newId) => !this.isRowDisabled(newId))
  }

  get component() {
    return this.state.widget.component
  }

  /**
   * Set the require fields
   * @param attributes
   */
  set requiredFields(attributes: string[]) {
    const uniqueAttributes = [
      ...new Set(
        this.hasExpandableRow
          ? [...attributes, this.expandableAttribute, 'id']
          : [...attributes, 'id'],
      ),
    ]

    this.attributes = [...uniqueAttributes]
    this.isReadyToFetch = true
    this.update()
  }

  get rootActions() {
    return []
  }

  get batchActions() {
    return []
  }

  get expandableAttribute(): string | null {
    if (!this.widget.expandableAttribute) {
      return null
    }

    const attribute = this.services.resourceMetaManager.getAttribute(
      this.resource,
      this.widget.expandableAttribute,
    )

    if (attribute.type !== FieldTypes.RelationList) {
      console.warn(
        `Expandable attribute '${this.widget.expandableAttribute}' is not a relation list attribute.`,
      )

      return null
    }

    return this.widget.expandableAttribute
  }

  get hasDataSource(): boolean {
    return !!this.entities?.length
  }

  get hasColumnHeaderFilters(): boolean {
    return !!this.widget.component?.columnHeaderFilters
  }

  get hasExpandableRow(): boolean {
    return !!this.widget.expandableAttribute
  }

  set hasInfiniteScrolling(enableInfiniteScrolling: boolean) {
    this.enableInfiniteScrolling = enableInfiniteScrolling
  }

  get hasInfiniteScrolling() {
    return this.enableInfiniteScrolling
  }

  setup() {
    // We need to wait for the UI to tell us what fields to add
    this.isReadyToFetch = false
    // Apply
    this.applyToolbar()
    this.queryManager.limit = this.paginationOptions.pageSize
  }

  private applyToolbar() {
    // Override default toolbar
    this.state.widget.toolbar =
      this.state.widget.toolbar ??
      ({
        show: true,
        displayCounts: true,
        filterOptions: {
          allowSearch: true,
          allowScopes: true,
          filters: [],
        },
      } as DefaultToolbar)
  }

  /**
   * Get the columns
   */
  public get columns(): ColumnInputDefinition[] {
    const filterWhitelistedInvalidFields = (
      columns: ColumnInputDefinition[],
    ): ColumnInputDefinition[] =>
      columns.filter((column) => {
        const attributeName = getColumnAttributeName(column)

        return (
          !attributeName ||
          !isWhitelistedResourceInvalidField({
            attributeName,
            resourceName: this.resource,
            resourceMetaManager: this.services.resourceMetaManager,
          })
        )
      })

    const columns = this.state?.widget?.component?.columns ?? []

    if (Array.isArray(columns) && columns.length > 0) {
      return filterWhitelistedInvalidFields(columns)
    }

    const resourcePreset = modularManager.getResourcePreset(
      this.resource,
      PresetTypes.COLUMNS,
    )?.data

    const resourcePresetDefaults = ['id']

    return (
      filterWhitelistedInvalidFields(resourcePreset) || resourcePresetDefaults
    )
  }

  createExtensionColumn(column: FullColumnDefinition): FullColumnDefinition {
    column.allowSorting = false
    column.headerText = ResourceTranslator.translateAttribute(
      this.resource,
      column.attributeName,
      FormLabelTypes.LABEL,
    )
    column.allowFilters = false

    return column
  }

  prepareColumn(columnDefinition: ColumnInputDefinition): FullColumnDefinition {
    const column =
      typeof columnDefinition === 'string'
        ? { attributeName: columnDefinition }
        : columnDefinition

    // Not an attribute column
    if (!column.attributeName) {
      // Return the raw column
      return column
    }

    return column.attributeName.startsWith('extensions.')
      ? this.createExtensionColumn(column)
      : this.prepareAttributeColumn(column)
  }

  get paginationOptions(): Pagination {
    return {
      pageSize: 20,
      pageSizeOptions: [10, 20, 50, 100],
    }
  }

  getFullDefinitionColumns(): FullColumnDefinition[] {
    return this.columns.map((col) => this.prepareColumn(col))
  }

  getRows(): RowModel[] {
    // if a row is expanded, add another row below with the sub-datatable
    return this.state.entities.reduce((acc: RowModel[], entity) => {
      acc.push({ entityId: entity.id })

      if (this.isRowExpanded(entity.id))
        acc.push({ entityId: entity.id, isExpandedRow: true })

      return acc
    }, [] as RowModel[])
  }

  private prepareAttributeColumn(
    col: FullColumnDefinition,
  ): FullColumnDefinition {
    const attribute = this.services.resourceMetaManager.getAttribute(
      this.resource,
      col.attributeName,
    )

    const disableFiltering =
      !attribute || [FieldTypes.Image, FieldTypes.JSON].includes(attribute.type)

    const disableSorting =
      !attribute || [FieldTypes.Image, FieldTypes.JSON].includes(attribute.type)

    const textAlign = col.style?.textAlign ?? 'Left'

    const getTranslatedAttr = () => {
      const i18nKeys =
        this.services.resourceMetaManager.getAttributePathLabelKeys(
          this.resource,
          col.attributeName,
        )

      return i18nKeys.map((key) => i18n.tc(key)).join(' - ')
    }

    const label = col.headerText ?? getTranslatedAttr()

    return {
      ...col,
      allowFilters: col.allowFilters ?? !disableFiltering,
      allowSorting: col.allowSorting ?? !disableSorting,
      headerText: label,
      style: {
        ...col.style,
        textAlign,
      },
    }
  }
}
