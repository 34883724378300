import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'tag',
    style: { width: '300px' },
  },
  {
    attributeName: 'value',
    component: { is: 'SystemSettingsValue' },
  },
] as ColumnInputDefinition[]
