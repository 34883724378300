import { reactive, watch } from 'vue'

import { AuthModule } from '@tracktik/tt-authentication'

import { Resources } from '@/tt-entity-design/src/types'

import { createFeatureManager } from './FeatureManager'
import { EntityFeatureManager, Feature, RegionId } from './types'

export const createUserRegionFeatureManager = (
  authModule: AuthModule,
): EntityFeatureManager => {
  const { setFeatures, ...baseManager } = createFeatureManager()

  const state = reactive({
    regionId: Number(authModule.getUserProfile().mainRegionId) as RegionId,
    loading: false,
  })

  const handlerError = (err) => {
    console.warn('Could not fetch features', err)

    return []
  }

  const isLoading = () => state.loading

  const fetchFeatures = async () => {
    state.loading = true

    await authModule
      .getApi()
      .get<number, Record<string, Feature[]>>(
        Resources.REGIONS,
        state.regionId,
        {
          include: ['features'],
        },
      )
      .then((response) => {
        setFeatures(response.features)
      })
      .catch(handlerError)
      .finally(() => {
        state.loading = false
      })
  }

  const regionIdWatcher = watch(
    () => state.regionId,
    async () => await fetchFeatures(),
    { immediate: true },
  )

  const destroy = () => {
    regionIdWatcher()
  }

  return { ...baseManager, isLoading, destroy }
}
