import { EventLogByResource } from './types'

export const enum PusherEvents {
  UPDATED_ENTITIES = 'pusher-event.updated-entities',
  CREATED_ENTITIES = 'pusher-event.created-entities',
}

export type PusherEventPayload = {
  [PusherEvents.UPDATED_ENTITIES]: EventLogByResource
  [PusherEvents.CREATED_ENTITIES]: EventLogByResource
}
