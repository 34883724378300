<template>
  <v-col cols="auto" md="12" class="d-flex" style="min-width: 350px">
    <v-row
      v-if="isLoading"
      justify="start"
      align-content="center"
      class="ml-15"
    >
      <v-skeleton-loader type="chip" />
    </v-row>
    <v-row v-else justify="start" align-content="center" class="ml-15">
      <v-col v-for="action in actions" :key="action.actionName" cols="auto">
        <tt-attr-action :action="action.actionName">
          <LeaveRequestsBtnAction
            :action="action.actionName"
            :affected-shifts-total="affectedShiftsTotal"
            @apply-action="applyAction"
          />
        </tt-attr-action>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ApplyActionProvider } from './LeaveRequestsActionBar.vue'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ApplyActionProvider>
).extend({
  name: 'LeaveRequestsBtnGroupAction',
  inject: ['getItemHook', 'applyAction'],
  inheritAttrs: false,
  props: {
    actions: {
      type: Array as () => Action[],
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.getItemHook().loading
    },
    affectedShiftsTotal(): number {
      return this.getItemHook().get('extensions.affectedShifts.total')
    },
  },
  created() {
    this.getItemHook().addAttribute('extensions.affectedShifts.total')
    this.getItemHook().needActions({ force: true })
  },
})
</script>
