// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SystemUsageLogsTile from './SystemUsageLogsTile.vue'
import SystemUsageLogsColumns from './SystemUsageLogsColumns'
import SystemUsageLogsDetail from './SystemUsageLogsDetail.vue'
import SystemUsageLogsReference from './SystemUsageLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SystemUsageLogsTile', SystemUsageLogsTile)
    Vue.component('SystemUsageLogsDetail', SystemUsageLogsDetail)
    Vue.component('SystemUsageLogsReference', SystemUsageLogsReference)

    modularManager.addResourcePreset(
      Resources.SYSTEM_USAGE_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SystemUsageLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_USAGE_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SystemUsageLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_USAGE_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SystemUsageLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_USAGE_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SystemUsageLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
