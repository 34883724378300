// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MessageBoardPostAcknowledgementsTile from './MessageBoardPostAcknowledgementsTile.vue'
import MessageBoardPostAcknowledgementsColumns from './MessageBoardPostAcknowledgementsColumns'
import MessageBoardPostAcknowledgementsDetail from './MessageBoardPostAcknowledgementsDetail.vue'
import MessageBoardPostAcknowledgementsReference from './MessageBoardPostAcknowledgementsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MessageBoardPostAcknowledgementsTile',
      MessageBoardPostAcknowledgementsTile,
    )
    Vue.component(
      'MessageBoardPostAcknowledgementsDetail',
      MessageBoardPostAcknowledgementsDetail,
    )
    Vue.component(
      'MessageBoardPostAcknowledgementsReference',
      MessageBoardPostAcknowledgementsReference,
    )

    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostAcknowledgementsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MessageBoardPostAcknowledgementsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostAcknowledgementsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MessageBoardPostAcknowledgementsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
