<template>
  <div class="flex--column">
    <v-row>
      <v-col>
        <TimeField v-model="startTime" :label="startLabel" />
      </v-col>

      <v-col>
        <TimeField v-model="endTime" :label="endLabel" />
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-btn
        color="success"
        class="pa-3"
        raised
        :loading="loading"
        @click="saveTimings"
      >
        {{ $t('common.save.btn') }}
      </v-btn>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import TimeField from '@/tt-entity-forms/components/TimeField.vue'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Resources } from '@/tt-entity-design/src/types'
import { createSiteTaskScheduleTimingsHelper } from '@/tt-widget-views/site-tasks/helpers/site-task-schedule-timings'

export default Vue.extend({
  name: 'SiteTaskScheduleTimingsNonRecurrent',
  components: {
    TimeField,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      loading: false,
      apiHelper: createSiteTaskScheduleTimingsHelper(this.$auth),
    }
  },
  computed: {
    startLabel(): string {
      return this.$t(
        createI18nResourceKey(
          Resources.SITE_TASK_SCHEDULE_TIMINGS,
          'rangeStartTime',
        ),
      ) as string
    },
    endLabel(): string {
      return this.$t(
        createI18nResourceKey(
          Resources.SITE_TASK_SCHEDULE_TIMINGS,
          'rangeEndTime',
        ),
      ) as string
    },
  },
  async created() {
    this.loading = true
    const timings = await this.fetchTimings()
    this.startTime = timings[0]?.['rangeStartTime'] || ''
    this.endTime = timings[0]?.['rangeEndTime'] || ''
    this.loading = false
  },
  methods: {
    async fetchTimings(): Promise<any[]> {
      const timings = await this.apiHelper.fetchSiteTaskScheduleTimings(
        this.groupId,
      )

      if (timings.length > 1)
        console.warn(
          'More than one timing found for a non recurrent site-task',
          this.groupId,
          timings,
        )

      return timings
    },
    async saveTimings() {
      this.loading = true

      await this.apiHelper.saveSiteTaskScheduleTimings(this.groupId, [
        {
          rangeStartTime: this.startTime,
          rangeEndTime: this.endTime,
        },
      ])

      this.loading = false

      this.$emit('submit:success')
    },
  },
})
</script>
