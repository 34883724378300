<template>
  <div class="time-picker--container flex--row align-center justify-center">
    <div class="d-flex flex-column align-center">
      <v-select
        v-model="selectedHour"
        filled
        append-icon=""
        height="30px"
        hide-details
        dense
        background-color="level1"
        :menu-props="{ contentClass: 'level0', offsetY: true }"
        :items="hoursItems"
        style="position: relative"
      >
        <template #selection="{ item }">
          <div
            class="flex--row align-center justify-center px-1 ttPrimary--text font-weight-bold"
          >
            <span class="text--nobreak text-smaller" v-text="item.text" />
          </div>
        </template>
      </v-select>

      <span class="caption time-picker--label" v-text="'hour'" />
    </div>

    <span class="px-1" v-text="`:`" />

    <div class="d-flex flex-column align-center">
      <v-select
        v-model="selectedMinute"
        filled
        append-icon=""
        height="30px"
        hide-details
        dense
        background-color="level1"
        :items="minutesList"
        :menu-props="{ contentClass: 'level0', offsetY: true }"
      >
        <template #selection="{ item }">
          <div
            class="flex--row align-center justify-center px-1 ttPrimary--text font-weight-bold"
          >
            <span class="text-smaller" v-text="item.text" />
          </div>
        </template>
      </v-select>

      <span class="caption time-picker--label" v-text="'minute'" />
    </div>

    <div class="d-flex flex-column align-center">
      <v-select
        v-if="useAmPm"
        v-model="amPmSelected"
        filled
        append-icon=""
        height="30px"
        hide-details
        dense
        background-color="level1"
        :items="amPmList"
        :menu-props="{ contentClass: 'level0', offsetY: true }"
      >
        <template #selection="{ item }">
          <div
            class="flex--row align-center justify-center px-1 ttPrimary--text font-weight-bold"
          >
            <span class="text-smaller" v-text="item.text" />
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import { getTimeFormat } from '@/tt-entity-forms/components/utils'
import {
  createTimeString,
  convertTo12h,
  convertTo24h,
  AM_PM,
  DropDownItem,
  createHoursFormat12,
  createHoursFormat24,
  createMinuts,
} from './TimePickerDropdown/utils'

export default Vue.extend({
  name: 'TimePickerDropdown',
  props: {
    value: {
      // HH:mm:ss
      type: String,
      default: '00:00:00',
    },
  },
  computed: {
    selectedHour: {
      get(): number {
        return this.useAmPm ? this.selectedHour12 : this.selectedHour24
      },
      async set(newHour: number) {
        await updateDOM()

        if (this.useAmPm) {
          this.selectedHour12 = newHour
        } else {
          this.selectedHour24 = newHour
        }
      },
    },
    /**
     * The source of truth for the selected hour is 24h format
     */
    selectedHour24: {
      get(): number {
        return Number(this.value.split(':')[0])
      },
      set(newHour24: number) {
        const newValue = createTimeString(newHour24, this.selectedMinute)
        this.$emit('input', newValue)
      },
    },
    selectedHour12: {
      get(): number {
        return convertTo12h(this.selectedHour24).hour12
      },
      set(newHour12: number) {
        this.selectedHour24 = convertTo24h(newHour12, this.amPmSelected)
      },
    },
    amPmSelected: {
      get(): AM_PM {
        return convertTo12h(this.selectedHour24).amPm
      },
      set(newAmPm: AM_PM) {
        this.selectedHour24 = convertTo24h(this.selectedHour12, newAmPm)
      },
    },
    selectedMinute: {
      get(): number {
        return Number(this.value.split(':')[1])
      },
      async set(newMinute: number) {
        await updateDOM()

        const newValue = createTimeString(this.selectedHour24, newMinute)
        this.$emit('input', newValue)
      },
    },
    useAmPm(): boolean {
      const format = this.$appContext.authModule.getUserPreferences().timeFormat
      return getTimeFormat(format) === 'ampm'
    },
    amPmList(): DropDownItem[] {
      return ['AM', 'PM'].map((item) => ({
        text: item,
        value: item,
      }))
    },
    hoursItems(): DropDownItem[] {
      return this.useAmPm ? createHoursFormat12() : createHoursFormat24()
    },
    minutesList(): DropDownItem[] {
      return createMinuts()
    },
  },
})
</script>

<style scoped>
.v-select {
  min-width: 0;
  flex: none;
  border-radius: 4px;
}

.v-select >>> .v-input__slot {
  padding: 0 !important;
  /* allow height less than 56px */
  min-height: 0;
  font-size: 14px;
  width: 35px;
  margin-bottom: 0;
}

.v-select >>> .v-text-field__details {
  margin-bottom: 0;
}

.v-select >>> .v-input__control {
  align-items: center;
}

.time-picker--container {
  position: relative;
}

.time-picker--container .time-picker--label {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  position: absolute;
  bottom: -20px;
}

.time-picker--container:hover .time-picker--label {
  opacity: 0.8;
}

.v-select >>> .v-icon {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.v-select:hover >>> .v-icon {
  opacity: 1;
}

.text--nobreak {
  white-space: nowrap;
}

.text-smaller {
  font-size: smaller;
}
</style>
