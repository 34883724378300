import { isValidIsoDatetime } from '@/helpers/dates/datetimeFormatValidator'
import { Timezone, isValidTimezone } from '@/helpers/dates/timezones'
import { ApiDateTimeFormats } from '@/helpers/formats/dates'
import moment from 'moment-timezone'

/**
 * Create a locale `DateTime` string from a date (`YYY-MM-DD`) and time (`HH:mm:ss`) string
 */
export const createDateTimeString = (date: string, time: string): string =>
  `${date} ${time}`

/**
 * Create a locale `DateTime` string from an ISO string and a timezone.
 */
export const createDateTimeFromIso = (
  isoDatetime: string,
  timezone: Timezone,
): string => {
  if (!isValidIsoDatetime(isoDatetime)) {
    console.warn('Invalid ISO datetime string format.', isoDatetime)
  }

  if (!isValidTimezone(timezone)) {
    console.warn('Invalid timezone.', timezone)
  }

  return moment.tz(isoDatetime, timezone).format(ApiDateTimeFormats.datetime)
}
