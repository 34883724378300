<template>
  <v-menu left offset-x close-on-click nudge-left="8">
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item v-for="(action, index) in actions" :key="index" link>
        <FileInlineBtnActions
          :key="index"
          :action="action"
          @action-trigger="actionTrigger"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ActionTriggerProvider } from './ActionTriggerProvider.vue'

export default (Vue as VueConstructor<Vue & ActionTriggerProvider>).extend({
  name: 'FileInlineBtnGroupActions',
  inject: ['actionTrigger'],
  inheritAttrs: false,
  props: {
    actions: {
      type: Array as () => string[],
      required: true,
    },
  },
})
</script>
