<template>
  <v-card class="pa-4" flat>
    <div class="d-flex">
      <v-checkbox
        v-model="isAddress"
        class="ma-0"
        color="ttPrimary"
        :disabled="!hasAddress"
      />
      <SystemExceptionTicketsAddressFields
        :position-address="accountAddress"
        :account="account"
        :has-address="hasAddress"
      />
    </div>
    <v-divider class="my-2" />

    <div class="d-flex">
      <v-checkbox
        v-model="isCoordinates"
        class="ma-0"
        color="ttPrimary"
        :disabled="!hasCoordinates"
      />
      <SystemExceptionTicketsCoordinatesFields
        :last-latitude="lastLatitude"
        :last-longitude="lastLongitude"
        :has-coordinates="hasCoordinates"
      />
    </div>
    <v-divider class="my-2" />

    <div class="d-flex justify-center">
      <v-btn
        class="form--button"
        small
        dark
        color="ttPrimary"
        elevation="0dp"
        :loading="isLoading"
        @click="openDispatchForm"
      >
        <template #loader>
          <TLoadingBar class="h-100" />
        </template>
        <v-icon small v-text="`mdi-arrow-right-circle-outline`" />
        <span
          class="px-2"
          v-text="$t('tt-entity-design.dispatch-tasks.create-dispatch')"
        />
      </v-btn>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { Resources } from '../../types'
import SystemExceptionTicketsCoordinatesFields from './SystemExceptionTicketsCoordinatesFields.vue'
import SystemExceptionTicketsAddressFields from './SystemExceptionTicketsAddressFields.vue'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import { getCreateFormState } from '../../tools/helpers/form-state-generator'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { LocationType } from '../dispatch-tasks/types'
import { AccountType, AddressType } from './type'
import { EntityCreateIntentInterface } from '@/tt-widget-entity-flow'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'

export default Vue.extend({
  name: 'SystemExceptionTicketsFormPrefill',
  components: {
    SystemExceptionTicketsAddressFields,
    SystemExceptionTicketsCoordinatesFields,
  },
  props: {
    mobileDeviceId: {
      type: Number,
      required: true,
    },
    systemExceptionTicketsId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      accountAddress: {} as AddressType,
      account: {} as AccountType,
      lastLatitude: null as number | null,
      lastLongitude: null as number | null,
      isAddress: false,
      isCoordinates: true,
      initialModel: {
        systemExceptionTicket: this.systemExceptionTicketsId,
        startDateTime: null,
        client: null,
        location: null,
        locationType: null,
      } as EntityCreateIntentInterface['initialModel'],
    }
  },
  computed: {
    hasCoordinates(): boolean {
      return !!this.lastLatitude && !!this.lastLongitude
    },
    /***
     * Will want to check if the address has any values
     */
    hasAddress(): boolean {
      const { id, ...address } = this.accountAddress

      for (const key in address) {
        if (address[key]) {
          return true
        }
      }

      return false
    },
  },
  watch: {
    isAddress(value) {
      if (value) {
        this.isCoordinates = false
      }
      this.setPosition()
    },
    isCoordinates(value) {
      if (value) {
        this.isAddress = false
      }
      this.setPosition()
    },
  },
  created() {
    this.getMobileDevice()
  },
  methods: {
    setPosition() {
      if (this.isAddress && this.account?.type === 'CLIENT') {
        const addressModel = {
          client: this.account.id,
          location: null,
          locationType: LocationType.ACCOUNT_ADDRESS,
        }
        this.initialModel = { ...this.initialModel, ...addressModel }
      } else if (this.isAddress) {
        const addressModel = {
          client: null,
          location: {
            ...this.accountAddress,
            name: this.account.name,
            autoGeoCode: true,
            region: this.account.region,
            type: LocationType.ADDRESS,
          },
          locationType: LocationType.ADDRESS,
        }
        this.initialModel = { ...this.initialModel, ...addressModel }
      } else if (this.isCoordinates && this.account?.type === 'CLIENT') {
        const coordinatesModel = {
          client: this.account.id,
          location: {
            latitude: this.lastLatitude,
            longitude: this.lastLongitude,
            autoGeoCode: false,
            region: this.account.region,
            type: LocationType.COORDINATES,
          },
          locationType: LocationType.COORDINATES,
        }
        this.initialModel = { ...this.initialModel, ...coordinatesModel }
      } else if (this.isCoordinates) {
        const coordinatesModel = {
          client: null,
          location: {
            latitude: this.lastLatitude,
            longitude: this.lastLongitude,
            autoGeoCode: false,
            region: this.account.region,
            type: LocationType.COORDINATES,
          },
          locationType: LocationType.COORDINATES,
        }
        this.initialModel = { ...this.initialModel, ...coordinatesModel }
      } else {
        const defaultModel = {
          client: null,
          location: null,
          locationType: null,
        }
        this.initialModel = { ...this.initialModel, ...defaultModel }
      }
    },
    setValues({ account, lastLatitude, lastLongitude }): void {
      const { id, ...address } = account.address

      this.accountAddress = address
      this.account = account
      this.lastLatitude = lastLatitude
      this.lastLongitude = lastLongitude

      if (!this.hasCoordinates) {
        this.isAddress = true
        this.isCoordinates = false
      }

      if (!this.hasAddress && !this.hasCoordinates) {
        this.isAddress = false
        this.isCoordinates = false
      }
      this.setPosition()
    },
    async getMobileDevice(): Promise<void> {
      const options = {
        include: ['account.address'],
      }
      this.$appContext.authModule
        .getApi()
        .get(Resources.MOBILE_DEVICES, this.mobileDeviceId, options)
        .then(this.setValues)
    },
    async openDispatchForm() {
      this.isLoading = true

      await updateDOM()

      const formConfig: EntityCreateIntentInterface = {
        resourceName: Resources.DISPATCH_TASKS,
        title: '', //We want to hide default title
        initialModel: this.initialModel,
        formOptions: {
          fieldErrorRule: FieldErrorRule.ALWAYS,
        },
      }

      const payload = await getCreateFormState(this.$appContext, formConfig)

      this.$emit('back')
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'DispatchTasksForm',
        title: 'tt-entity-design.dispatch-tasks.create-dispatch',
        props: { ...payload },
      })

      this.isLoading = false
    },
  },
})
</script>

<style scoped>
.form--button >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
