import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { FieldMeta, FieldUI, FieldConverter } from '@/tt-fieldset-builder/type'
import { getBaseFieldJsonSchema } from '@/tt-fieldset-builder/base/converter'
import { i18nFieldKey } from '@/tt-fieldset-builder/i18n'

import { PICTURE, PictureFieldType } from './types'
import schema from './schema.json'
import CustomFieldPicture from './CustomFieldPicture.vue'

const ui: FieldUI = {
  name: i18nFieldKey(PICTURE),
  color: 'yellow darken-2',
  icon: 'mdi-file-image',
}

// @ts-ignore
const converter: FieldConverter = (field: PictureFieldType) => ({
  getJsonSchema: () => {
    return {
      ...getBaseFieldJsonSchema(field),
      type: 'number',
    }
  },
  getViewOption: () => {
    return {
      is: CustomFieldPicture,
      props: {
        uploadDeactivated: false,
      },
    }
  },
})

export const PicturenMeta: FieldMeta = {
  field: PICTURE,
  ui,
  converter,
  editSchema: schema as JSONSchema7,
}
