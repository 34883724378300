import { Status } from './types'

const STATUS_COLOR_MAP: Record<Status, string> = {
  COMPLETED: 'success',
  MISSED: 'error',
  EN_ROUTE: 'warning',
  ONSITE: 'orange',
  ASSIGNED: 'blue',
  INITIATED: 'grey',
  TO_REASSIGN: 'indigo',
}

const DEFAULT_STATUS_COLOR = 'grey'

export const getStatusColor = (status: string) =>
  STATUS_COLOR_MAP[status] || DEFAULT_STATUS_COLOR
