<template>
  <div class="fill-height">
    <slot v-if="isReady" />
    <slot v-else name="loading">
      <TLoadingWave />
    </slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'MetadataLoadingGuard',
  computed: {
    isReady(): boolean {
      return (
        this.$appContext.authModule.isAuthenticated() &&
        this.$appContext.isReady()
      )
    },
  },
})
</script>
