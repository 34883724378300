<template>
  <span v-if="showField" v-show="condition">
    <slot />
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { isWhitelistedResourceInvalidField } from '../helper'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'TtIfAttr',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {},
    not: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    condition(): boolean {
      // We are in collection mode
      if (this.getItemHook().entityReference.entityId === 0) {
        return true
      }
      const val = this.conditionCompute
      return this.not ? !val : val
    },
    conditionCompute(): boolean {
      const val = this.getItemHook().getRawValue(this.absoluteName)
      if (Array.isArray(this.value)) {
        return this.value.includes(val)
      }
      return val == this.value
    },
    absoluteName(): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${this.name}`
        : this.name
    },
    showField(): boolean {
      return !isWhitelistedResourceInvalidField({
        attributeName: this.name,
        relationPrefix: this.relationPrefix,
        resourceMetaManager:
          this.$appContext.widgetServices.resourceMetaManager,
        resourceName: this.getItemHook().entityReference.resourceName,
      })
    },
  },
  created() {
    this.getItemHook().addAttribute(this.absoluteName)
  },
})
</script>
