<template>
  <div>
    <json-field
      name="vendors"
      as="DraggableSiteVendorsList"
      :client-id="clientId"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
export default (Vue as VueWithFormHookInjection).extend({
  name: 'ClientsPrioritizeVendors',
  inject: ['formHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    clientId(): number {
      return this.itemHook.getRawValue('id')
    },
  },
})
</script>
