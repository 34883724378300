<template>
  <v-text-field
    ref="field"
    outlined
    dense
    :value="displayValue"
    :prefix="currency"
    :disabled="isReadOnly"
    v-bind="{ ...$attrs, errorMessages, label }"
    v-on="$listeners"
    @input="setModel"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script lang="ts">
import { VueConstructor } from 'vue'
import BaseInput from '@/tt-widget-components/components/BaseInput'
import { FormHookProvider } from '@/tt-widget-components'

const isEmptyOrNaN = (value) => (!value && value !== 0) || isNaN(+value)

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider
>

export default (BaseInput as VueWithInjections).extend({
  name: 'CurrencyField',
  inject: {
    formHook: { default: undefined },
  },
  props: {
    currency: {
      type: String,
      default: '$',
    },
    languageCode: {
      type: String,
      default: 'en-US',
    },
    value: {
      type: [Number, String], // @todo: refactor to use only one type
    },
  },
  data() {
    return {
      displayValue: this.value,
    }
  },
  computed: {
    formattedValue(): string | number {
      const model = this.model

      if (isEmptyOrNaN(model)) return model

      const minimumFractionDigits = Number.isInteger(model) ? 0 : 2

      return model.toLocaleString(this.languageCode, {
        minimumFractionDigits,
        maximumFractionDigits: 2,
      })
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
  },
  methods: {
    onFocus() {
      this.displayValue = this.model
    },
    onBlur() {
      // @ts-ignore
      this.displayValue = this.formattedValue
    },
    setModel(newValue: string) {
      this.model = isEmptyOrNaN(newValue) ? newValue : Number(newValue)
    },
  },
})
</script>
