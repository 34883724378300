<template>
  <WidgetWrapper
    v-if="hook.isReadyToFetch"
    :has-header="false"
    v-bind="$props"
    @rendered="$emit('rendered')"
  >
    <MapWidget
      v-bind="{ hook, width, height, container }"
      @rendered="$emit('rendered')"
    />
  </WidgetWrapper>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import MapWidget from './MapWidget.vue'
import MapWidgetHook from './MapWidgetHook'
import { WidgetContainerInterface } from '@/tt-widget-factory'

export default Vue.extend({
  components: { MapWidget },
  props: {
    width: { type: String, default: null },
    height: { type: String, default: null },
    hook: {
      type: Object as PropType<MapWidgetHook>,
      required: true,
    },
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: null,
    },
  },
})
</script>
