<template>
  <div class="fill-height">
    <WidgetFactory v-if="isWidgetReady" :widget="widget" />
    <div v-if="emptyRequests" class="d-flex justify-center align-center mt-15">
      <span class="font-italic">{{ emptyRequestsMsg }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ListWidgetModel, SortAttribute } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { Filter } from 'tracktik-sdk/lib/common/entity-filters'
import { FutureLeaveRequests, LeavePolicyItem } from '../../types'
import { LeaveRequestsStatus } from '@/tt-entity-design/src/components/leave-requests/types'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'
import {
  ActiveWindowProvider,
  LayoutComponents,
  LayoutWindowEvent,
} from '@/tt-app-layout'
import { filterRequestsByStatus } from '../../utils'

export default (Vue as VueConstructor<Vue & ActiveWindowProvider>).extend({
  name: 'LeaveRequestsTileList',
  inject: {
    activeWindow: { default: undefined },
  },
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    leavePolicyItemId: {
      type: Number,
      required: true,
    },
    requestsStatus: {
      type: String as PropType<LeaveRequestsStatus>,
      required: true,
    },
    isPageActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unsubscribeFn: null as UnsubscribeFunction,
      requests: [] as FutureLeaveRequests[],
      loading: true,
    }
  },
  computed: {
    isSideSheetChild(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
    emptyRequests(): boolean {
      return !this.requests.length && !this.loading
    },
    emptyRequestsMsg(): string {
      const localeStatus = this.requestsStatus.toLowerCase()
      const basePath = 'tt-entity-design.leave-management.employee.overview'
      const requestType = this.$t(`${basePath}.${localeStatus}.request-type`)

      return this.$t(`${basePath}.empty-requests-message`, {
        requestType: requestType.toLowerCase(),
      })
    },
    isWidgetReady(): boolean {
      return this.requests.length && !this.loading
    },
    isPendingRequests(): boolean {
      return (
        this.requestsStatus === LeaveRequestsStatus.PENDING ||
        this.requestsStatus === LeaveRequestsStatus.PENDING_CANCELLATION
      )
    },
    widget(): ListWidgetModel {
      const filters = [
        {
          attribute: 'id',
          operator: FilterOperatorType.IN,
          value: this.requests?.map((request) => request.id).join(','),
        },
      ]

      const sort: SortAttribute = [
        {
          attribute: 'startedOn',
          direction: 'ASC',
        },
      ]

      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: 'leave-requests',
          filters: filters,
          sort: sort,
        },
        listItem: {
          is: 'LeaveRequestsTileListView',
        },
      }
    },
  },
  created() {
    this.fetchFutureRequests()

    // to refresh the list once a leave request is actioned on
    this.unsubscribeFn =
      this.isSideSheetChild &&
      this.$appContext.eventManager.subscribeEvent(
        EntityIntentTypes.RESOURCE_UPDATED,
        ({ resource }) => {
          if (resource === Resources.LEAVE_REQUESTS) {
            this.fetchFutureRequests()
            this.returnToMe()
          }
        },
      )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    returnToMe() {
      if (!this.isPageActive) {
        this.activeWindow.back()
      }
    },
    fetchFutureRequests(): Promise<void> {
      const employeeFilter: Filter = {
        attribute: 'employee',
        operator: FilterOperatorType.EQUAL,
        value: this.employeeId,
      }
      const leavePolicyItemFilter: Filter = {
        attribute: 'id',
        operator: FilterOperatorType.EQUAL,
        value: this.leavePolicyItemId,
      }

      return this.$auth
        .getApi()
        .getAll(Resources.LEAVE_POLICY_ITEMS, {
          filters: [employeeFilter, leavePolicyItemFilter],
          extension: ['futureLeaveRequests', 'pastLeaveRequests'],
        })
        .then(({ items }: EntityCollectionResponse<LeavePolicyItem>) => {
          const policyItem = items[0]
          if (this.isPendingRequests) {
            const pendingRequests = [
              ...policyItem.futureLeaveRequests.pendingRequestsIds,
              ...policyItem.pastLeaveRequests.pendingRequestsIds,
            ]

            this.requests = filterRequestsByStatus(
              pendingRequests,
              this.requestsStatus,
            )
          } else {
            this.requests = policyItem.futureLeaveRequests.approvedRequestsIds
          }
        })
        .catch((error) => {
          const errorResponse = error?.response?.data
          this.$crash.captureException(error)
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message: errorResponse || this.$t('common.error_message'),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
