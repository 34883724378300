<template>
  <!-- VIEW MODE -->
  <div v-if="!editMode" class="fill-height">
    <widget-factory
      v-if="widget && widget.is"
      :widget="widget"
      :container="columnContainer"
      @update:hook="widgetHook = $event"
      @rendered="onRendered"
    />
  </div>

  <!-- EDIT MODE -->
  <div v-else class="fill-height widget-container" @dragenter="onDraggingOver">
    <!-- WIDGET -->
    <template v-if="widget && widget.is">
      <widget-factory :widget="widget" :container="columnContainer" />
    </template>

    <!-- NO WIDGET -->
    <div v-else class="new-button--container">
      <v-btn
        class="new-button"
        @click.stop="hook.create(rowIndex, colIndex, tabIndex)"
      >
        {{ $t('common.new.btn') }}
      </v-btn>
    </div>

    <!-- DROP ZONE -->
    <drop
      :style="{ visibility: incomingDragOver ? 'visible' : 'hidden' }"
      class="overlay-drop-zone"
      @drop="onDrop"
      @dragleave="incomingDragOver = false"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import DashboardWidgetHook from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetHook'
import {
  WidgetContainerInterface,
  WidgetHookInterface,
} from '@/tt-widget-factory/types'
import { WidgetPosition } from './types'
import { WidgetModels } from '@/tt-widget-components'
import { DraggableInterface } from '@/apps/app.tracktik.insights.studio/types'
import DashboardWidgetDragAndDropService from './DashboardWidgetDragAndDropService'
import { DRAG_OVER_DELAY } from '@/helpers/constants'

type VueWithPreviousPosition = VueConstructor<
  Vue & {
    dragAndDropService: () => DashboardWidgetDragAndDropService
  }
>

export default (Vue as VueWithPreviousPosition).extend({
  name: 'DashboardWidgetTabItem',
  inject: {
    dragAndDropService: {
      from: 'dashboardWidgetDragAndDropService',
    },
  },
  props: {
    widget: {
      type: Object as PropType<WidgetModels>,
      required: true,
    },
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      required: true,
    },
    columnContainer: {
      type: Object as PropType<WidgetContainerInterface>,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      incomingDragOver: false,
      widgetHook: null as WidgetHookInterface | null,
    }
  },
  computed: {
    currentPosition(): WidgetPosition {
      return {
        rowIndex: this.rowIndex,
        colIndex: this.colIndex,
        tabIndex: this.tabIndex,
      }
    },
    debounceIncomingDragOver(): DebouncedFunc<() => void> {
      return debounce(() => (this.incomingDragOver = false), DRAG_OVER_DELAY)
    },
  },
  methods: {
    onRendered() {
      this.hook.setWidgetRendered(this.currentPosition)
    },
    onDraggingOver() {
      // if we drag over the current dragging item, we do nothing
      if (
        isEqual(
          this.currentPosition,
          this.dragAndDropService()?.getInitialPosition(),
        )
      )
        return

      this.incomingDragOver = true
      this.debounceIncomingDragOver()
    },
    onDrop(event: DraggableInterface) {
      this.incomingDragOver = false
      this.dragAndDropService()?.dropOverWidget(event, this.currentPosition)
    },
  },
})
</script>

<style scoped>
.widget-container {
  position: relative;
}
.overlay-drop-zone {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 10px dashed var(--v-primary-lighten4);
  background-color: rgba(128, 128, 128, 0.186);
  border-radius: 1em;
}

.new-button--container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.new-button {
  align-self: center;
  justify-self: center;
}
</style>
