// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionBillingSettingsTile from './PositionBillingSettingsTile.vue'
import PositionBillingSettingsColumns from './PositionBillingSettingsColumns'
import PositionBillingSettingsDetail from './PositionBillingSettingsDetail.vue'
import PositionBillingSettingsReference from './PositionBillingSettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PositionBillingSettingsTile', PositionBillingSettingsTile)
    Vue.component(
      'PositionBillingSettingsDetail',
      PositionBillingSettingsDetail,
    )
    Vue.component(
      'PositionBillingSettingsReference',
      PositionBillingSettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_BILLING_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionBillingSettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILLING_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionBillingSettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILLING_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionBillingSettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_BILLING_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionBillingSettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
