<template>
  <div class="fill-height">
    <v-row class="text-center fill-height" align="center" justify="center">
      <v-col>
        <v-icon size="50" color="orange" style="opacity: 0.5">mdi-lock</v-icon>
        <CaptionBox
          ><span v-text="$t(`widget_view.no_permission`)"></span
        ></CaptionBox>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import BaseWidget from '@/tt-widget-components/components/BaseWidget'

export default BaseWidget.extend({
  name: 'WidgetFactoryUnauthorized',
  props: {
    widget: Object as PropType<{ title: string }>,
  },
  computed: {
    widgetTitle(): string {
      return this.widget.title ?? ' '
    },
  },
})
</script>
