<template>
  <div class="text-caption text-secondary">
    <v-icon
      color="level1Control"
      class="mr-2"
      medium
      :style="{ marginTop: '-3px' }"
    >
      mdi-information-outline
    </v-icon>
    <span
      class="text-subtitle-1"
      v-text="
        $t(
          'tt-entity-design.exception-type-dates.actions.remove-occurrences-confirm',
        )
      "
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'ExceptionTypeDateRemoveOccurrence',
})
</script>
