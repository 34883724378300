// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportPreDefinedFieldTemplatesTile from './ReportPreDefinedFieldTemplatesTile.vue'
import ReportPreDefinedFieldTemplatesColumns from './ReportPreDefinedFieldTemplatesColumns'
import ReportPreDefinedFieldTemplatesDetail from './ReportPreDefinedFieldTemplatesDetail.vue'
import ReportPreDefinedFieldTemplatesMetaCard from './ReportPreDefinedFieldTemplatesMetaCard.vue'
import ReportPreDefinedFieldTemplatesReference from './ReportPreDefinedFieldTemplatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportPreDefinedFieldTemplatesTile',
      ReportPreDefinedFieldTemplatesTile,
    )
    Vue.component(
      'ReportPreDefinedFieldTemplatesDetail',
      ReportPreDefinedFieldTemplatesDetail,
    )
    Vue.component(
      'ReportPreDefinedFieldTemplatesMetaCard',
      ReportPreDefinedFieldTemplatesMetaCard,
    )
    Vue.component(
      'ReportPreDefinedFieldTemplatesReference',
      ReportPreDefinedFieldTemplatesReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_PRE_DEFINED_FIELD_TEMPLATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportPreDefinedFieldTemplatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_PRE_DEFINED_FIELD_TEMPLATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportPreDefinedFieldTemplatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_PRE_DEFINED_FIELD_TEMPLATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportPreDefinedFieldTemplatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_PRE_DEFINED_FIELD_TEMPLATES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportPreDefinedFieldTemplatesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_PRE_DEFINED_FIELD_TEMPLATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportPreDefinedFieldTemplatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
