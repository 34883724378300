// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetSuppliersTile from './AssetSuppliersTile.vue'
import AssetSuppliersColumns from './AssetSuppliersColumns'
import AssetSuppliersDetail from './AssetSuppliersDetail.vue'
import AssetSuppliersMetaCard from './AssetSuppliersMetaCard.vue'
import AssetSuppliersReference from './AssetSuppliersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetSuppliersTile', AssetSuppliersTile)
    Vue.component('AssetSuppliersDetail', AssetSuppliersDetail)
    Vue.component('AssetSuppliersMetaCard', AssetSuppliersMetaCard)
    Vue.component('AssetSuppliersReference', AssetSuppliersReference)

    modularManager.addResourcePreset(
      Resources.ASSET_SUPPLIERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetSuppliersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_SUPPLIERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetSuppliersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_SUPPLIERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetSuppliersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_SUPPLIERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetSuppliersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_SUPPLIERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetSuppliersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
