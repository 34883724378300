<template>
  <div
    :class="['fill-height', 'd-flex', smAndDown ? 'flex-column' : 'flex-row']"
  >
    <div
      class="pt-6 pl-md-6 pl-sm-2 pr-2"
      :class="{ 'employee-card-md': !smAndDown }"
    >
      <EmployeeEmploymentProfile :employee-info="employeeInfo" />
    </div>
    <div class="d-flex flex-column flex-grow-1 pt-6 pl-2 pr-md-6 pr-sm-2">
      <WidgetLoader v-if="!isReady" :widget="employeeOverviewWidget">
        <v-skeleton-loader type="table" style="height: 100%" />
      </WidgetLoader>
      <NoPolicyWarning v-else-if="!hasPolicyAssigned" />
      <WidgetFactory
        v-else
        data-cy="employee-overview-widget"
        :widget="employeeOverviewWidget"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  DataTableWidgetModel,
  RelationListResource,
} from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import EmployeeOverviewCustomColumns from './EmployeeOverviewCustomColumns'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { UnsubscribeFunction } from '@/tt-event-manager'
import EmployeeEmploymentProfile from '../employment-profile/EmployeeEmploymentProfile.vue'
import { LeavePolicyEmployeeInfo } from '@/tt-entity-design/src/components/leave-policies/types'
import NoPolicyWarning from '../NoPolicyWarning.vue'

export default Vue.extend({
  name: 'EmployeeOverview',
  components: { EmployeeEmploymentProfile, NoPolicyWarning },
  provide() {
    return {
      getEmployeeInfo: () => this.employeeInfo,
    }
  },
  props: {
    employeeInfo: {
      type: Object as PropType<LeavePolicyEmployeeInfo>,
      required: true,
    },
    currentLeavePolicy: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      unsubscribeFn: null as UnsubscribeFunction,
      loading: true,
    }
  },
  computed: {
    isEmployeeInfoLoaded(): boolean {
      return (
        typeof this.employeeInfo?.id === 'number' &&
        !Number.isNaN(this.employeeInfo?.id)
      )
    },
    hasPolicyAssigned(): boolean {
      return (
        typeof this.currentLeavePolicy === 'number' &&
        !Number.isNaN(this.currentLeavePolicy)
      )
    },
    isReady(): boolean {
      return this.$appContext.isReady() && this.isEmployeeInfoLoaded
    },
    relationListResource(): RelationListResource {
      return {
        resource: Resources.LEAVE_POLICIES,
        id: this.currentLeavePolicy,
        attribute: 'leavePolicyItems',
      }
    },
    employeeOverviewWidget(): DataTableWidgetModel {
      const currentEmployeeFilter = {
        attribute: 'employee',
        operator: FilterOperatorType.EQUAL,
        value: this.employeeInfo.id,
      }

      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_POLICY_ITEMS,
          relationListResource: this.relationListResource,
          filters: [currentEmployeeFilter],
        },
        component: { columns: EmployeeOverviewCustomColumns },
      }
    },
    smAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  created() {
    // to refresh the list once a new leave request is posted for the employee
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.LEAVE_REQUESTS) {
          this.updatePolicyItems()
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    updatePolicyItems(): void {
      this.$appContext.eventManager.dispatchEvent(
        EntityIntentTypes.RESOURCE_UPDATED,
        { resource: Resources.LEAVE_POLICY_ITEMS },
      )
    },
  },
})
</script>
<style scoped>
.employee-card-md {
  width: 23.4285rem;
}
</style>
