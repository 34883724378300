import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'name',
  {
    attributeName: 'isVoluntary',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'isVoluntary',
      },
    },
  },
  {
    attributeName: 'commentRequired',
    component: {
      is: 'ChipLabelBoolean',
      props: {
        attributeName: 'commentRequired',
      },
    },
  },
] as ColumnInputDefinition[]
