<template>
  <span v-if="isAccrued">-</span>
  <tt-attr v-else name="quantity" />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyItemsQuantityColumn',
  inject: ['getItemHook'],
  computed: {
    isAccrued(): boolean {
      return !!this.getItemHook().get('accrualRate')
    },
  },
  created() {
    this.getItemHook().addAttribute('accrualRate')
  },
})
</script>
