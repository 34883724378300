// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import LeaveAccrualsTile from './LeaveAccrualsTile.vue'
import LeaveAccrualsColumns from './LeaveAccrualsColumns'
import LeaveAccrualsDetail from './LeaveAccrualsDetail.vue'
import LeaveAccrualsReference from './LeaveAccrualsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('LeaveAccrualsTile', LeaveAccrualsTile)
    Vue.component('LeaveAccrualsDetail', LeaveAccrualsDetail)
    Vue.component('LeaveAccrualsReference', LeaveAccrualsReference)

    modularManager.addResourcePreset(
      Resources.LEAVE_ACCRUALS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'LeaveAccrualsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_ACCRUALS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: LeaveAccrualsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_ACCRUALS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'LeaveAccrualsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.LEAVE_ACCRUALS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'LeaveAccrualsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
