import {
  CustomFilter,
  ResourceSchema,
} from '@/tt-entity-design/src/schema-types'
import { Resources } from '../../../types'
import { translateScope } from '@/tt-widget-entity-flow/ResourceTranslator'

export const { MOBILE_DEVICES } = Resources
export type Scope = keyof ResourceSchema<typeof MOBILE_DEVICES>['scopes']

export const SITE_DEVICE_SCOPE = 'SITE_DEVICE' satisfies Scope
export const ZONE_DEVICE_SCOPE = 'ZONE_DEVICE' satisfies Scope

export const ACCOUNT_TYPE_SCOPES = [
  SITE_DEVICE_SCOPE,
  ZONE_DEVICE_SCOPE,
] as const

export type AccountTypeScope = (typeof ACCOUNT_TYPE_SCOPES)[number]

export const isNotAccountType = (scope: AccountTypeScope) =>
  !ACCOUNT_TYPE_SCOPES.includes(scope)

export type CustomFilters = CustomFilter<typeof MOBILE_DEVICES>

export const NEAR_COORDINATES = 'nearCoordinates' satisfies CustomFilters
export const SKILL_FOR_TASK_TYPE =
  'skillRequirementsForTaskTypeId' satisfies CustomFilters

export const localizeScope = (scope: string) =>
  translateScope(MOBILE_DEVICES, scope)

export const CLOCKED_IN_SCOPE = 'CLOCKED_IN' satisfies Scope
export const LOGGED_IN_SCOPE = 'LOGGED' satisfies Scope

export const CONNECTED_METHODS = [CLOCKED_IN_SCOPE, LOGGED_IN_SCOPE] as const

export type ConnectedMethodScope = (typeof CONNECTED_METHODS)[number]

export const isNotConnectionMethod = (scope: ConnectedMethodScope) =>
  !CONNECTED_METHODS.includes(scope)
