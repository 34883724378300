// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import OperationCenterRegionsTile from './OperationCenterRegionsTile.vue'
import OperationCenterRegionsColumns from './OperationCenterRegionsColumns'
import OperationCenterRegionsDetail from './OperationCenterRegionsDetail.vue'
import OperationCenterRegionsReference from './OperationCenterRegionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('OperationCenterRegionsTile', OperationCenterRegionsTile)
    Vue.component('OperationCenterRegionsDetail', OperationCenterRegionsDetail)
    Vue.component(
      'OperationCenterRegionsReference',
      OperationCenterRegionsReference,
    )

    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_REGIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'OperationCenterRegionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_REGIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: OperationCenterRegionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_REGIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'OperationCenterRegionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.OPERATION_CENTER_REGIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'OperationCenterRegionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
