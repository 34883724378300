// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmergencyContactsTile from './EmergencyContactsTile.vue'
import EmergencyContactsColumns from './EmergencyContactsColumns'
import EmergencyContactsDetail from './EmergencyContactsDetail.vue'
import EmergencyContactsMetaCard from './EmergencyContactsMetaCard.vue'
import EmergencyContactsReference from './EmergencyContactsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmergencyContactsTile', EmergencyContactsTile)
    Vue.component('EmergencyContactsDetail', EmergencyContactsDetail)
    Vue.component('EmergencyContactsMetaCard', EmergencyContactsMetaCard)
    Vue.component('EmergencyContactsReference', EmergencyContactsReference)

    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmergencyContactsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmergencyContactsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmergencyContactsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmergencyContactsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMERGENCY_CONTACTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmergencyContactsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
