export const getScrollBarWidth = (): number => {
  // Create out-of-screen measurement node
  const scrollDiv = document.createElement('div')
  scrollDiv.className = 'scrollbar-measure'
  scrollDiv.style.width = '100px'
  scrollDiv.style.height = '100px'
  scrollDiv.style.overflow = 'scroll'
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  document.body.appendChild(scrollDiv)

  // Get the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

  // Delete the measurement node
  scrollDiv.remove()
  return scrollbarWidth
}

export const enableDocumentScroll = (): void => {
  document.documentElement.style.overflow = 'auto'
  document.documentElement.style.paddingRight = '0px'
}

export const disableDocumentScroll = (): void => {
  document.documentElement.style.overflow = 'hidden'
  document.documentElement.style.paddingRight = `${getScrollBarWidth()}px`
}
