<template>
  <v-list class="py-2" color="transparent">
    <v-list-item class="d-flex py-3">
      <slot>
        <!-- left column -->
        <v-list-item-action v-if="$slots.action" class="my-0 mr-4">
          <slot name="action" />
        </v-list-item-action>
        <v-list-item-avatar v-if="$slots.avatarText" :size="avatarSize">
          <slot name="avatar-text" />
        </v-list-item-avatar>
        <v-list-item-avatar v-if="$slots.avatar" :size="avatarSize">
          <slot name="avatar" />
        </v-list-item-avatar>
        <v-list-item-action v-if="$slots.icon" class="my-0 mr-4">
          <slot name="icon" />
        </v-list-item-action>
        <!-- / left column -->

        <!-- center column -->
        <v-list-item-content class="py-0">
          <v-list-item-title v-if="$slots.title" class="pb-1">
            <slot name="title" />
          </v-list-item-title>
          <v-list-item-subtitle v-if="$slots.line2" class="pb-1">
            <slot name="line2" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="$slots.line3" class="pb-1">
            <slot name="line3" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="$slots.line4" class="pb-1">
            <slot name="line4" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="$slots.line5" class="pb-1">
            <slot name="line5" />
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- / center column -->

        <!-- right column -->
        <v-list-item-action v-if="$slots.actionRight" class="my-0 mr-4">
          <slot name="actionRight" />
        </v-list-item-action>
        <!-- / right column -->
      </slot>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'EntityTile',
  props: {
    avatarSize: {
      type: Number,
      default: 50,
    },
  },
})
</script>
