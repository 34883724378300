import hook from './MetricTableWidgetHook'
import template from './MetricTableWidget.vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import Vue from 'vue'
import MetricTableWidgetEditor from './MetricTableWidgetEditor.vue'
import { WidgetName } from '@/tt-widget-components/lib/names'

Vue.component('MetricTableWidgetEditor', MetricTableWidgetEditor)

const MetricTableWidget: WidgetTypeDefinition = {
  name: WidgetName.METRIC_TABLE_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    MetricTableWidget: {
      view: {
        is: 'MetricTableWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-table-eye',
    color: '#d572e1',
    print: true,
    thumbnail: require('./table.png'),
    name: 'Summary Table',
  },
}
export default MetricTableWidget
