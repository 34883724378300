// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportLibrariesTile from './ReportLibrariesTile.vue'
import ReportLibrariesColumns from './ReportLibrariesColumns'
import ReportLibrariesDetail from './ReportLibrariesDetail.vue'
import ReportLibrariesMetaCard from './ReportLibrariesMetaCard.vue'
import ReportLibrariesReference from './ReportLibrariesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportLibrariesTile', ReportLibrariesTile)
    Vue.component('ReportLibrariesDetail', ReportLibrariesDetail)
    Vue.component('ReportLibrariesMetaCard', ReportLibrariesMetaCard)
    Vue.component('ReportLibrariesReference', ReportLibrariesReference)

    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportLibrariesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportLibrariesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportLibrariesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportLibrariesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_LIBRARIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportLibrariesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
