<template>
  <div>
    <div class="d-flex align-center">
      <span
        class="subtitle-2 mr-2"
        v-text="$t(translationKey('form-fields.dispatch-timing'))"
      />
      <v-radio-group v-model="selectedDispatchTiming" row>
        <v-radio
          v-for="item in dispatchTimingOptions"
          :key="item.value"
          color="ttPrimary"
          :label="item.label"
          :value="item.value"
        />
      </v-radio-group>
    </div>
    <v-row v-if="selectedDispatchTiming === dispatchTiming.NOW">
      <v-col cols="12">
        <json-field
          name="plannedDurationInMinutes"
          prepend-inner-icon="mdi-timer-outline"
        />
      </v-col>
    </v-row>
    <div v-if="selectedDispatchTiming === dispatchTiming.LATER">
      <v-row no-gutters>
        <v-col cols="12">
          <json-field name="startedOn" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="pr-4">
          <json-field
            name="plannedDurationInMinutes"
            prepend-inner-icon="mdi-timer-outline"
          />
        </v-col>
        <v-col cols="6">
          <json-field
            name="reminderInMinutes"
            prepend-inner-icon="mdi-bell-outline"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

enum DispatchTiming {
  NOW = 'NOW',
  LATER = 'LATER',
}

type DispatchTimingOption = {
  label: string
  value: DispatchTiming
}

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'DispatchTasksFormTimingFields',
  inject: ['formHook'],
  data() {
    return {
      selectedDispatchTiming: DispatchTiming.NOW,
    }
  },
  computed: {
    dispatchTimingOptions(): DispatchTimingOption[] {
      return [
        {
          label: this.$t(this.translationKey('form-radios.now')),
          value: DispatchTiming.NOW,
        },
        {
          label: this.$t(this.translationKey('form-radios.later')),
          value: DispatchTiming.LATER,
        },
      ]
    },
    dispatchTiming(): typeof DispatchTiming {
      return DispatchTiming
    },
  },
  watch: {
    selectedDispatchTiming: {
      immediate: true,
      handler() {
        if (this.selectedDispatchTiming === DispatchTiming.NOW) {
          this.formHook().setObjectValue('startedOn', DispatchTiming.NOW)
        } else if (this.selectedDispatchTiming === DispatchTiming.LATER) {
          this.formHook().setObjectValue('startedOn', null)
        }
      },
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
  },
})
</script>
