import { WidgetSchema } from '@/tt-widget-factory'

export default {
  type: 'object',
  required: ['text', 'is', 'title'],
  properties: {
    is: {
      enum: ['RichTextWidget'],
    },
    title: {
      title: 'Widget Title',
      description: 'Title that represents the widget',
      type: 'string',
    },
    uid: {
      title: 'UID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    text: {
      title: 'Text',
      $ref: '#/definitions/RichText',
    },
  },
} as WidgetSchema
