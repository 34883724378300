<template>
  <TListContentCard style="width: 7em">
    <template #header>
      <tt-attr name="startsOn" format="asTimeWithoutTimezone" />
      -
      <tt-attr name="endsOn" format="asTimeWithoutTimezone" />
    </template>
    <template #sub-header>
      <tt-attr name="startsOn" format="asDate" />
    </template>
  </TListContentCard>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ShiftsDateTime',
})
</script>
