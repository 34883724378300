<template>
  <div :class="{ 'fill-height': hasFillPage }">
    <v-row class="dashboard-row" :class="{ 'fill-height': hasFillPage }">
      <v-col
        v-for="(col, colIndex) in columns"
        :key="colIndex"
        :cols="colSize"
        style="overflow-x: hidden; overflow-y: hidden"
        :style="{ height: dashboardContainer.height }"
      >
        <DashboardWidgetColumn
          :edit-mode="editMode"
          :row-index="rowIndex"
          :col-index="colIndex"
          :hook="hook"
          :dashboard-container="dashboardContainer"
        />
        <v-divider v-if="colSize" />
      </v-col>
    </v-row>
    <DashboardWidgetRowEditToolbar
      v-if="editMode"
      :hook="hook"
      :row="row"
      :row-index="rowIndex"
    />
  </div>
</template>

<script lang="ts">
import { WidgetReference } from '@/tt-widget-factory/types'
import DashboardWidgetColumn from './DashboardWidgetColumn.vue'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { WidgetContainerInterface } from '@/tt-widget-factory/types'
import { DashboardRow } from '@/tt-widget-components'
import Vue, { PropType } from 'vue'
import DashboardWidgetHook from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetHook'
import DashboardWidgetRowEditToolbar from '@/tt-widget-components/widgets/Dashboard/DashboardWidgetRowEditToolbar.vue'

/**
 * Row Size
 */
export default Vue.extend({
  components: {
    DashboardWidgetColumn,
    DashboardWidgetRowEditToolbar,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      default: undefined,
    },
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      default: undefined,
    },
  },
  computed: {
    row(): DashboardRow {
      return this.hook.state.widget.rows[this.rowIndex]
    },
    columns(): any {
      return this.row.columns
    },
    colSize(): number {
      return this.$vuetify.breakpoint.smAndDown
        ? 12
        : // @ts-ignore: 'left' does not exist on type 'DashboardColumn[]'
          12 / this.row.columns.left
    },
    dashboardContainer(): WidgetContainerInterface {
      return {
        // @ts-ignore: unify types
        height: this.row.size ? this.hook.mapSize(this.row.size) : null,
        // @ts-ignore: unify types
        size: this.row.size,
      }
    },
    hasFillPage(): boolean {
      return this.row.size === 'FILL_PAGE'
    },
  },
  methods: {
    place(rowIndex: number, colIndex: number) {
      this.$eventManager.dispatchEvent(WidgetEditorEvents.DASHBOARD_CREATE, {
        success: (widget: WidgetReference) => {
          this.hook.placeDebounced(rowIndex, colIndex, widget)
        },
      })
    },
  },
})
</script>

<style scoped>
@media print {
  .dashboard-row {
    break-inside: avoid;
  }
}
</style>
