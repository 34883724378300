// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagTemplateFieldGroupsTile from './ReportFlagTemplateFieldGroupsTile.vue'
import ReportFlagTemplateFieldGroupsColumns from './ReportFlagTemplateFieldGroupsColumns'
import ReportFlagTemplateFieldGroupsDetail from './ReportFlagTemplateFieldGroupsDetail.vue'
import ReportFlagTemplateFieldGroupsReference from './ReportFlagTemplateFieldGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportFlagTemplateFieldGroupsTile',
      ReportFlagTemplateFieldGroupsTile,
    )
    Vue.component(
      'ReportFlagTemplateFieldGroupsDetail',
      ReportFlagTemplateFieldGroupsDetail,
    )
    Vue.component(
      'ReportFlagTemplateFieldGroupsReference',
      ReportFlagTemplateFieldGroupsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagTemplateFieldGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAG_TEMPLATE_FIELD_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagTemplateFieldGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
