// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AppLicensesTile from './AppLicensesTile.vue'
import AppLicensesColumns from './AppLicensesColumns'
import AppLicensesDetail from './AppLicensesDetail.vue'
import AppLicensesMetaCard from './AppLicensesMetaCard.vue'
import AppLicensesReference from './AppLicensesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AppLicensesTile', AppLicensesTile)
    Vue.component('AppLicensesDetail', AppLicensesDetail)
    Vue.component('AppLicensesMetaCard', AppLicensesMetaCard)
    Vue.component('AppLicensesReference', AppLicensesReference)

    modularManager.addResourcePreset(
      Resources.APP_LICENSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AppLicensesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.APP_LICENSES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AppLicensesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.APP_LICENSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AppLicensesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.APP_LICENSES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AppLicensesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.APP_LICENSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AppLicensesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
