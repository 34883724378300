<template>
  <span
    class="v-chip v-chip--label v-size-small t-chip-value--container"
    :class="computedChipClass"
    v-text="text"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TChipValue',
  props: {
    text: {
      type: String,
      required: true,
    },
    chipClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedChipClass(): string {
      return `${this.chipClass}`
    },
  },
})
</script>

<style scoped>
.t-chip-value--container {
  height: fit-content !important;
  width: fit-content !important;
  font-weight: bold;
  justify-content: center;
  display: block;
  text-overflow: ellipsis;
}
</style>
