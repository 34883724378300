// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowInstanceTimerLogsTile from './WorkflowInstanceTimerLogsTile.vue'
import WorkflowInstanceTimerLogsColumns from './WorkflowInstanceTimerLogsColumns'
import WorkflowInstanceTimerLogsDetail from './WorkflowInstanceTimerLogsDetail.vue'
import WorkflowInstanceTimerLogsMetaCard from './WorkflowInstanceTimerLogsMetaCard.vue'
import WorkflowInstanceTimerLogsReference from './WorkflowInstanceTimerLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'WorkflowInstanceTimerLogsTile',
      WorkflowInstanceTimerLogsTile,
    )
    Vue.component(
      'WorkflowInstanceTimerLogsDetail',
      WorkflowInstanceTimerLogsDetail,
    )
    Vue.component(
      'WorkflowInstanceTimerLogsMetaCard',
      WorkflowInstanceTimerLogsMetaCard,
    )
    Vue.component(
      'WorkflowInstanceTimerLogsReference',
      WorkflowInstanceTimerLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_TIMER_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceTimerLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_TIMER_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowInstanceTimerLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_TIMER_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceTimerLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_TIMER_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceTimerLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCE_TIMER_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowInstanceTimerLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
