<template>
  <component
    :is="tag"
    v-if="avatar"
    :color="isColoredAvatar ? color : ''"
    :size="size"
    small
  >
    <template v-if="isColoredAvatar">
      <v-icon dark :size="size * 0.6">{{ coloredAvatarIcon }}</v-icon>
    </template>
    <template v-else>
      <v-img :src="avatar" />
    </template>
  </component>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TAvatar',
  props: {
    avatar: {
      type: String,
      default: null,
    },
    isColoredAvatar: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'v-avatar',
    },
    size: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: 'green',
    },
    coloredAvatarIcon: {
      type: String,
      default: 'mdi-account-group',
    },
  },
})
</script>
