<template>
  <v-card
    class="container"
    :style="{ backgroundColor: $vuetify.theme.dark ? '#000' : '#f5f5f5' }"
  >
    <div v-if="!widgetFilterInfo.length">
      {{ $t('widget_view.widget_filter_info_modal.na') }}
    </div>
    <div
      v-for="widgetFilterInfoEntry in widgetFilterInfo"
      :key="widgetFilterInfoEntry.widgetTitle"
      class="widget-filter-info-entry"
    >
      <div class="widget-filter-info-entry-title-container">
        <v-icon
          :color="getIconByWidgetName(widgetFilterInfoEntry.widgetType).color"
        >
          {{ getIconByWidgetName(widgetFilterInfoEntry.widgetType).icon }}
        </v-icon>
        <div class="widget-filter-info-entry-title">
          {{ widgetFilterInfoEntry.widgetTitle }}
        </div>
      </div>
      <v-card
        v-for="(
          attributeFilter, index
        ) in widgetFilterInfoEntry.attributeFilters"
        :key="`${attributeFilter}-${index}`"
        class="widget-filter-info-entry-description"
        :style="{
          backgroundColor: $vuetify.theme.dark ? '#111' : '',
          padding: '10px',
        }"
      >
        <v-icon :color="getIconByAttributeName(attributeFilter).color">
          {{ getIconByAttributeName(attributeFilter).icon }}
        </v-icon>
        <div style="padding-left: 12px">
          {{ $t(`attribute.${attributeFilter}`) }}
        </div>
      </v-card>
      <v-card
        v-if="widgetFilterInfoEntry.attributeFilters.length === 0"
        class="widget-filter-info-entry-description"
        :style="{ backgroundColor: $vuetify.theme.dark ? '#111' : '' }"
      >
        <div style="padding-left: 12px">
          {{ $t('attribute.none') }}
        </div>
      </v-card>
    </div>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { getIconByAttributeName } from '@/tt-widget-components/lib/icons'
import { ContextFilterAppliedToWidget, WidgetConfig } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'FiltersAppliedToWidgets',
  props: {
    widgetFilterInfo: Array as PropType<ContextFilterAppliedToWidget[]>,
  },
  methods: {
    getIconByAttributeName,
    getIconByWidgetName(widgetType: string): WidgetConfig {
      // @todo: refactor to use a computed model instead
      return this.$appContext.widgetServices.widgetManager.getWidgetByName(
        widgetType,
      ).config
    },
  },
})
</script>
<style scoped>
.container {
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.widget-filter-info-title {
  font-weight: normal;
  margin-bottom: 12px;
}

.widget-filter-info-entry {
  margin-bottom: 18px;
}

.widget-filter-info-entry:last-child {
  margin-bottom: 0px;
}

.widget-filter-info-entry-title-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.widget-filter-info-entry-title {
  padding-left: 6px;
  margin: 6px 0;
}

.widget-filter-info-entry-description {
  padding: 18px;
  display: flex;
  flex-direction: row;
}
</style>
