import { Model } from '@vuex-orm/core'
import { Module } from 'vuex'

import {
  WidgetStoreInterface,
  WidgetStoreModelMeta,
  WidgetReference,
} from '@/tt-widget-factory/types'

/**
 * Widget model
 */
export class WidgetStoreModel extends Model implements WidgetStoreInterface {
  static entity = 'WidgetStoreModel'
  static primaryKey = 'uid'

  provider: string
  title: string
  uid: string
  is: string
  category: string
  ownedByMe: boolean
  meta: WidgetStoreModelMeta
  isFromMarketplace: boolean

  // Data stores the widget definitions
  widget: WidgetReference

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      is: this.string(null),
      uid: this.string(null),
      title: this.string(null),
      category: this.string(null),
      meta: this.attr({}),
      provider: this.string(''),
      ownedByMe: this.boolean(false),
      widget: this.attr({}),
      isFromMarketplace: this.boolean(false),
    }
  }

  static getNew(): WidgetStoreInterface {
    return WidgetStoreModel.getters('new')
  }

  static setNew(widget: WidgetStoreInterface | null): void {
    WidgetStoreModel.commit((state) => {
      state.new = widget
    })
  }

  static getSelected(): WidgetStoreInterface {
    return WidgetStoreModel.getters('selected')
  }

  static getDebouncingSelected(): boolean {
    return WidgetStoreModel.getters('debouncingSelected')
  }

  static getFetchingStatus(): boolean {
    return WidgetStoreModel.getters('hasBeenFetched')
  }

  static setSelected(widget: WidgetStoreInterface | null): void {
    WidgetStoreModel.commit((state) => {
      state.selected = widget
    })
  }

  static setDebouncingSelected(isDebouncing: boolean) {
    WidgetStoreModel.commit((state) => {
      state.debouncingSelected = isDebouncing
    })
  }
}

interface WidgetsState {
  hasBeenFetched: boolean
  new: WidgetStoreInterface
  selected: WidgetReference
  debouncingSelected: boolean
}

export const WidgetStoreModule: Module<WidgetsState, any> = {
  state: {
    hasBeenFetched: false,
    new: null as WidgetStoreInterface,
    selected: null as WidgetStoreInterface,
    debouncingSelected: false,
  },
  getters: {
    hasBeenFetched: (state) => state.hasBeenFetched,
    new: (state): WidgetStoreInterface => state.new,
    selected: (state) => state.selected,
    debouncingSelected: (state) => state.debouncingSelected,
  },
  actions: {
    finishFetching({ commit }) {
      commit('setHasBeenFetched', true)
    },
  },
  mutations: {
    setHasBeenFetched(state, payload: WidgetsState['hasBeenFetched']) {
      state.hasBeenFetched = payload
    },
  },
}
