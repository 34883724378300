<template>
  <div>
    <TChipLabel v-if="isDefaultLabel === 'YES'" v-bind="chipProps" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { TChipLabelType } from '@/tt-ui/types'

import {
  exceptionTypeGroupsDefaultLabelColorMap,
  ExceptionTypeGroupsStatusColor,
} from './types'
import { VueWithItemHookInjection } from '@/helpers/components/types'

export default (Vue as VueWithItemHookInjection).extend({
  name: 'ExceptionTypeGroupsDefaultAttr',
  inject: ['getItemHook'],
  computed: {
    statusColor(): ExceptionTypeGroupsStatusColor | null {
      return this.isDefaultLabel === 'YES'
        ? exceptionTypeGroupsDefaultLabelColorMap.DEFAULT
        : null
    },
    isDefaultLabel(): string | null {
      return this.getItemHook()?.get('isDefault')?.toUpperCase() ?? null
    },
    chipProps(): Record<string, unknown> {
      return {
        ...this.statusColor,
        modifiers: {
          style: { fontWeight: 'normal', textTransform: 'none' },
        },
        textKey:
          this.isDefaultLabel === 'YES'
            ? this.$t('tt-entity-design.exception-groups.tile.is-default')
            : '',
        type: TChipLabelType.Pill,
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('isDefault')
  },
})
</script>
