// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SiteTaskScheduleTimingsTile from './SiteTaskScheduleTimingsTile.vue'
import SiteTaskScheduleTimingsColumns from './SiteTaskScheduleTimingsColumns'
import SiteTaskScheduleTimingsDetail from './SiteTaskScheduleTimingsDetail.vue'
import SiteTaskScheduleTimingsMetaCard from './SiteTaskScheduleTimingsMetaCard.vue'
import SiteTaskScheduleTimingsReference from './SiteTaskScheduleTimingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('SiteTaskScheduleTimingsTile', SiteTaskScheduleTimingsTile)
    Vue.component(
      'SiteTaskScheduleTimingsDetail',
      SiteTaskScheduleTimingsDetail,
    )
    Vue.component(
      'SiteTaskScheduleTimingsMetaCard',
      SiteTaskScheduleTimingsMetaCard,
    )
    Vue.component(
      'SiteTaskScheduleTimingsReference',
      SiteTaskScheduleTimingsReference,
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_TIMINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleTimingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_TIMINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SiteTaskScheduleTimingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_TIMINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleTimingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_TIMINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleTimingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SITE_TASK_SCHEDULE_TIMINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SiteTaskScheduleTimingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
