<template>
  <div>
    <tt-attr
      prepend-icon="mdi-calendar"
      name="leaveRequest.startedOn"
      format="asDate"
    />
    <tt-attr
      v-show="!isSameDay"
      name="leaveRequest.endedOn"
      format="asDate"
      :prefix="`${nonBreakingSpace}-${nonBreakingSpace}`"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { isSameDate } from '@/helpers/dates/isSameDate'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveHistoryDatesColumn',
  inject: ['getItemHook'],
  computed: {
    isSameDay(): boolean {
      const startedOn = this.getItemHook().get('leaveRequest.startedOn')
      const endedOn = this.getItemHook().get('leaveRequest.endedOn')

      return startedOn && endedOn && isSameDate(startedOn, endedOn)
    },
    nonBreakingSpace(): string {
      // hexcode for &nbsp;
      return '\xa0'
    },
  },
})
</script>
