import { Status } from './types'

const STATUS_COLOR_MAP: Record<Status, string> = {
  INITIATED: 'ttPrimary',
  STARTED: 'info',
  ENDED: 'success',
  ENDED_INCOMPLETED: 'error',
  PAUSED: 'info',
  CANCELLED: 'error',
}

const DEFAULT_STATUS_COLOR = 'grey'

export const getStatusColor = (status: string) =>
  STATUS_COLOR_MAP[status] || DEFAULT_STATUS_COLOR
