<template>
  <div class="flex--column">
    <span v-text="displayTime" />
    <span
      v-if="hasDays"
      class="caption"
      style="line-height: 0"
      v-text="displayDay"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { formatHoursMinutes } from '../utils'

export default Vue.extend({
  name: 'DispatchTasksSlaTime',
  props: {
    timeObject: {
      type: Object as PropType<{
        days: number
        hours: number
        minutes: number
      }>,
      required: true,
    },
  },
  computed: {
    hasDays(): boolean {
      return !!this.timeObject.days
    },
    displayDay(): string {
      return `+ ${this.$tc(
        'tt-entity-design.leave-requests.days-amount',
        this.timeObject.days,
      )}`
    },
    displayTime(): string {
      return formatHoursMinutes(this.timeObject.hours, this.timeObject.minutes)
    },
  },
})
</script>
