// Enter your custom installation here
import { ActionFormTypes, modularManager } from '@/tt-app-modular'
import { Resources } from '../../types'
import AlarmsStatusAttr from './AlarmsStatusAttr.vue'
import AlarmsActionBar from './AlarmsActionBar.vue'
import AlarmsLocationGoogleMapLink from './AlarmsLocationGoogleMapLink.vue'
import AlarmsLocationGoogleMapLinkRow from './AlarmsLocationGoogleMapLinkRow.vue'
import AlarmsFormCreate from './form/AlarmsFormCreate.vue'
import AlarmsFormEdit from './form/AlarmsFormEdit.vue'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

export default {
  install(Vue) {
    Vue.component('AlarmsStatusAttr', AlarmsStatusAttr)
    Vue.component('AlarmsActionBar', AlarmsActionBar)
    Vue.component('AlarmsLocationGoogleMapLink', AlarmsLocationGoogleMapLink)
    Vue.component(
      'AlarmsLocationGoogleMapLinkRow',
      AlarmsLocationGoogleMapLinkRow,
    )
    Vue.component('AlarmsFormCreate', AlarmsFormCreate)
    Vue.component('AlarmsFormEdit', AlarmsFormEdit)

    registerResourceForm('alarms', 'AlarmsFormCreate', ActionFormTypes.CREATE)
    registerResourceForm('alarms', 'AlarmsFormEdit', ActionFormTypes.EDIT)

    modularManager.registerActionBar(Resources.ALARMS, {
      is: AlarmsActionBar,
    })
  },
}
