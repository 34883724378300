<template>
  <div style="min-height: 400px">
    <WidgetFactory :widget="widget" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ListWidgetModel, RelationListResource } from '@/tt-widget-components'

import { Resources } from '../../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskOccurrenceAssetsList',
  inject: ['getItemHook'],
  computed: {
    taskSiteInstructionId(): number {
      return this.getItemHook().get('taskSiteInstruction.id')
    },
    relationListResource(): RelationListResource {
      return {
        resource: 'task-site-instructions',
        id: this.taskSiteInstructionId,
        attribute: 'siteTaskAssets',
      }
    },
    widget(): ListWidgetModel {
      return {
        title: '',
        is: 'ListWidget',
        query: {
          resource: Resources.ASSETS,
          relationListResource: this.relationListResource,
          sort: { attribute: 'id', direction: 'DESC' },
        },
      }
    },
  },
})
</script>
