import hook from '../../base/DataSetWidgetHookChart'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import ColumnChartWidgetEditor from './ColumnChartWidgetEditor.vue'
import Vue from 'vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(
    /* webpackChunkName: "ColumnChartWidget" */ './ColumnChartWidget.vue'
  ).catch(handleAsyncComponentError)

Vue.component('ColumnChartWidgetEditor', ColumnChartWidgetEditor)

const ColumnChartWidget: WidgetTypeDefinition = {
  name: WidgetName.COLUMN_CHART_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    ColumnChartWidget: {
      view: {
        is: 'ColumnChartWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-chart-bar',
    color: '#f4cf28',
    thumbnail: require('./column-chart.png'),
    print: true,
  },
  ttcIgnoredAttrs: ['dataSet.sort'],
}

export default ColumnChartWidget
