<template>
  <v-select
    v-model="selectedMinute"
    filled
    append-icon=""
    hide-details
    dense
    background-color="level1"
    outlined
    :rules="[
      (v) =>
        (v !== null && v !== undefined && v !== '') ||
        $t('common.required_field'),
    ]"
    :label="timeToEscalateLabel"
    :items="minutesList"
    :menu-props="{ offsetY: true }"
    @update:error="handleError"
  >
    <template #selection="{ item }">
      <span class="text-smaller" v-text="item.text" />
    </template>
  </v-select>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'
import { error } from './constants'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

const MINUTE_LIST = 16

export default (Vue as VueWithInjections).extend({
  name: 'TimeToEscalateField',
  inject: ['formHook'],
  props: {
    value: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedMinute: {
      get(): number | null {
        return this.value
      },
      set(newMinute: number) {
        this.$emit('input:timeToEscalate', { timeToEscalate: newMinute })
      },
    },
    // API only accepts 0-15 minutes for escalation time
    minutesList(): { text: string; value: number }[] {
      return [...Array(MINUTE_LIST)].map((_, index) => ({
        text: `${index} ${this.$tc('common.minutes', index)}`,
        value: index,
      }))
    },
    timeToEscalateLabel(): string {
      return (
        this.formHook().getViewComponentProps('escalationRules').itemField
          ?.props?.items['timeToEscalate']?.props?.label || ''
      )
    },
  },
  created(): void {
    if (this.value === null) {
      this.handleError(true)
    }
  },
  beforeDestroy(): void {
    this.handleError(false)
  },
  methods: {
    handleError(hasError: boolean): void {
      if (hasError) {
        this.formHook().setCustomError(`timeToEscalate.${this.index}`, error)
      } else {
        this.formHook().setCustomError(`timeToEscalate.${this.index}`, null)
      }
    },
  },
})
</script>
