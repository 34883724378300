<template>
  <span>
    <v-icon
      x-small
      style="font-size: 13px; opacity: 0.8"
      color="purple"
      class="mr-1"
    >
      mdi-link
    </v-icon>
    {{ fullName }}
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'TenantsReference',
  inject: ['getItemHook'],
  computed: {
    fullName(): string {
      return `${this.getValue('firstName')} ${this.getValue('lastName')}`
    },
  },
  created() {
    this.getItemHook().addAttribute('firstName')
    this.getItemHook().addAttribute('lastName')
  },
  methods: {
    getValue(attr: string): string {
      return this.getItemHook().getRawValue(attr)
    },
  },
})
</script>
