import { NotificationService } from './NotificationService';
export class plugin extends NotificationService {
    static install(Vue) {
        Vue.mixin({
            beforeCreate: function () {
                const { notifications } = this.$root.$options;
                this.$notifications = notifications;
            },
        });
    }
}
