// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EventsSubscriptionLogsTile from './EventsSubscriptionLogsTile.vue'
import EventsSubscriptionLogsColumns from './EventsSubscriptionLogsColumns'
import EventsSubscriptionLogsDetail from './EventsSubscriptionLogsDetail.vue'
import EventsSubscriptionLogsMetaCard from './EventsSubscriptionLogsMetaCard.vue'
import EventsSubscriptionLogsReference from './EventsSubscriptionLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EventsSubscriptionLogsTile', EventsSubscriptionLogsTile)
    Vue.component('EventsSubscriptionLogsDetail', EventsSubscriptionLogsDetail)
    Vue.component(
      'EventsSubscriptionLogsMetaCard',
      EventsSubscriptionLogsMetaCard,
    )
    Vue.component(
      'EventsSubscriptionLogsReference',
      EventsSubscriptionLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTION_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTION_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EventsSubscriptionLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTION_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTION_LOGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionLogsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EVENTS_SUBSCRIPTION_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EventsSubscriptionLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
