import AssetManufacturersForm from './AssetManufacturersForm.vue'
import { Resources } from '@/tt-entity-design/src/types'
import {
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'

export default {
  install(Vue) {
    setIconAndColorResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      'mdi-office-building',
      'orange',
    )

    Vue.component('AssetManufacturersForm', AssetManufacturersForm)
    registerResourceForm(
      Resources.ASSET_MANUFACTURERS,
      'AssetManufacturersForm',
    )
  },
}
