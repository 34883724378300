// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportTemplatesCategoryTile from './ReportTemplatesCategoryTile.vue'
import ReportTemplatesCategoryColumns from './ReportTemplatesCategoryColumns'
import ReportTemplatesCategoryDetail from './ReportTemplatesCategoryDetail.vue'
import ReportTemplatesCategoryMetaCard from './ReportTemplatesCategoryMetaCard.vue'
import ReportTemplatesCategoryReference from './ReportTemplatesCategoryReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportTemplatesCategoryTile', ReportTemplatesCategoryTile)
    Vue.component(
      'ReportTemplatesCategoryDetail',
      ReportTemplatesCategoryDetail,
    )
    Vue.component(
      'ReportTemplatesCategoryMetaCard',
      ReportTemplatesCategoryMetaCard,
    )
    Vue.component(
      'ReportTemplatesCategoryReference',
      ReportTemplatesCategoryReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES_CATEGORY,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesCategoryTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES_CATEGORY,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportTemplatesCategoryColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES_CATEGORY,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesCategoryDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES_CATEGORY,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesCategoryMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_TEMPLATES_CATEGORY,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportTemplatesCategoryReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
