<template>
  <div>
    <v-simple-table v-if="!loading" fixed-header>
      <template #default>
        <thead>
          <tr>
            <th style="width: 50px" />
            <th class="text-left">
              {{
                $t('vendors.tabs.assign-vendors.prioritize-vendors.vendor-name')
              }}
            </th>
            <th class="text-center">
              {{
                $t('vendors.tabs.assign-vendors.prioritize-vendors.priority')
              }}
            </th>
          </tr>
        </thead>
        <draggable v-model="vendors" tag="tbody" @change="handleChange">
          <tr v-for="(vendor, index) in vendors" :key="vendor.id">
            <td><v-icon> mdi-drag </v-icon></td>
            <td>{{ vendor.name }}</td>
            <td class="text-center">
              {{ index + 1 }}
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <div v-else>
      <v-skeleton-loader type="table-heading, list-item-two-line" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { fetchSiteVendors } from './vendors-api-helpers'
import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'DraggableSiteVendorsList',
  components: {
    draggable,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      vendors: [],
    }
  },
  computed: {
    vendorIds(): number[] {
      return this.vendors.map((vendor) => vendor.id)
    },
  },
  async created() {
    try {
      this.loading = true
      const siteVendors = await fetchSiteVendors(
        this.clientId,
        this.$appContext.widgetServices,
      )
      this.vendors = siteVendors.map((siteVendor) => siteVendor.vendor)
      this.$emit('input', this.vendorIds)
    } finally {
      this.loading = false
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.vendorIds)
    },
  },
})
</script>

<style scoped>
thead {
  tr {
    th {
      vertical-align: middle !important;
    }
  }
}
tbody {
  tr {
    cursor: move !important;
    td {
      vertical-align: middle !important;
    }
  }
}
</style>
