// Enter your custom installation here
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import {
  addResourceDocument,
  createDocumentColumn,
} from '@/tt-widget-entity-flow/helper'
import { createI18nPluralResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { WidgetModels } from '@/tt-widget-components'

import ReportDoc from './ReportDoc'
import ReportsCategory from './ReportsCategory.vue'
import ReportsDetailLocation from './ReportsDetailLocation.vue'
import StatusColor from '../StatusColor.vue'
import { Resources } from '../../types'

export const reportDocumentButton = createDocumentColumn(ReportDoc)

export default {
  install(Vue) {
    addResourceDocument(Resources.REPORTS, ReportDoc)

    Vue.component('StatusColor', StatusColor)
    Vue.component('ReportsCategory', ReportsCategory)
    Vue.component('ReportsDetailLocation', ReportsDetailLocation)

    const reportFieldsListWidget: WidgetModels = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.REPORT_FIELDS,
      },
    }
    const reportFields: PreviewTabInterface = {
      title: createI18nPluralResourceKey('report-fields'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'report.id',
        widget: reportFieldsListWidget,
      },
    }
    addPreviewTab(Resources.REPORTS, reportFields)

    const locationDetail: PresetItem = {
      title: 'tt-entity-design.location',
      data: { is: 'ReportsDetailLocation', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.REPORTS,
      PresetTypes.DETAILS_CARD,
      [locationDetail],
    )
  },
}
