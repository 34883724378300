<template>
  <div class="text-clickable">
    <v-icon small color="green">mdi-link-plus</v-icon>
    <span class="pl-1" @click.stop="openPreview" v-text="translatedResource" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { LayoutComponents } from '@/tt-app-layout'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { getResourceName } from '@/tt-widget-components/helpers'

import { VueWithInjections } from './types'

export default (Vue as VueWithInjections).extend({
  name: 'ConflictsAffectedEntityAttr',
  inject: {
    activeWindow: { default: undefined },
    getItemHook: {},
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resourceName(): string {
      return this.getItemHook().getRawValue('affectedEntity.type')
    },
    entityId(): number {
      return this.getItemHook().getRawValue('affectedEntity.object')
    },
    translatedResource(): string {
      if (this.resourceName) {
        return getResourceName(this.$appContext, this.resourceName)
      }

      return ''
    },
    isSideSheetChildren(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
  },
  created() {
    this.getItemHook().addAttribute('affectedEntity')
  },
  methods: {
    openPreview() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        activeWindow: this.activeWindow,
        resourceName: this.resourceName,
        entityId: this.entityId,
        hasToAddPages: this.isSideSheetChildren,
      })
    },
  },
})
</script>

<style scoped>
.text-clickable:hover,
.text-clickable:hover >>> *,
.text-clickable.selected,
.text-clickable.selected >>> * {
  color: #4e69e9 !important;
  cursor: pointer;
}
</style>
