<template>
  <ProvideItemHook v-if="itemHook" :provide="itemHook">
    <tt-preset-view type="listItem" style="width: 350px" />
  </ProvideItemHook>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export default Vue.extend({
  name: 'EntityTilePreview',
  inject: {},
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemHook: new EntityItemHook(this.$appContext, {
        entityId: this.entityId,
        resourceName: this.resourceName,
      }),
    }
  },
  beforeDestroy() {
    this.itemHook.beforeDestroy()
  },
})
</script>
