import ExceptionTypeGroupsDetail from './ExceptionTypeGroupsDetail.vue'

import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '../../types'
import { ListWidgetModel } from '@/tt-widget-components'
import ExceptionTypeGroupsManageForm from './ExceptionTypeGroupsManageForm.vue'
import ExceptionTypeGroupsStatus from './ExceptionTypeGroupsStatus.vue'
import ExceptionTypeGroupsArchive from './ExceptionTypeGroupsArchive.vue'
import ExceptionTypeGroupsUnarchive from './ExceptionTypeGroupsUnarchive.vue'
import { ExceptionTypeGroupsActions } from './types'
import ExceptionTypeGroupsDefaultAttr from './ExceptionTypeGroupsDefaultAttr.vue'
import ExceptionTypeGroupsRelationTab from './ExceptionTypeGroupsRelationTab.vue'

export default {
  install(Vue) {
    Vue.component('ExceptionTypeGroupsDetail', ExceptionTypeGroupsDetail)
    Vue.component('ExceptionTypeGroupsStatus', ExceptionTypeGroupsStatus)
    Vue.component('ExceptionTypeGroupsArchive', ExceptionTypeGroupsArchive)
    Vue.component('ExceptionTypeGroupsUnarchive', ExceptionTypeGroupsUnarchive)

    Vue.component(
      'ExceptionTypeGroupsDefaultAttr',
      ExceptionTypeGroupsDefaultAttr,
    )

    Vue.component(
      'ExceptionTypeGroupsRelationTab',
      ExceptionTypeGroupsRelationTab,
    )

    registerResourceForm(
      Resources.EXCEPTION_TYPE_GROUPS,
      'ExceptionTypeGroupsArchive',
      ExceptionTypeGroupsActions.ARCHIVE,
    )

    registerResourceForm(
      Resources.EXCEPTION_TYPE_GROUPS,
      'ExceptionTypeGroupsUnarchive',
      ExceptionTypeGroupsActions.UN_ARCHIVE,
    )

    Vue.component(
      'ExceptionTypeGroupsManageForm',
      ExceptionTypeGroupsManageForm,
    )

    registerResourceForm(
      Resources.EXCEPTION_TYPE_GROUPS,
      'ExceptionTypeGroupsManageForm',
    )

    const exceptionsListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.EXCEPTION_TYPE_GROUP_ITEMS,
      },
    }

    addPreviewTab(Resources.EXCEPTION_TYPE_GROUPS, {
      title: 'tt-entity-design.exception-groups.preview.exception-types',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'group',
        widget: exceptionsListWidget,
      },
    })

    addPreviewTab(Resources.EXCEPTION_TYPE_GROUPS, {
      title: 'tt-entity-design.exception-groups.preview.zones',
      is: 'ExceptionTypeGroupsRelationTab',
      props: {
        relationListResource: 'subscribedZones',
      },
    })

    addPreviewTab(Resources.EXCEPTION_TYPE_GROUPS, {
      title: 'tt-entity-design.exception-groups.preview.sites',
      is: 'ExceptionTypeGroupsRelationTab',
      props: {
        relationListResource: 'subscribedClients',
      },
    })
  },
}
