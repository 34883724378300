import flow from 'lodash/flow'
import uniq from 'lodash/uniq'
import { flatten } from 'flat'

import { Api } from '@tracktik/tt-authentication'
import {
  EntitiesActionsRequestOptions,
  EntityActionsById,
} from 'tracktik-sdk/lib/common/entity-actions'
import { EntityCollectionResponse } from 'tracktik-sdk/lib/common/entity-collection'
import { FormLabelTypes, JSONSchema7 } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { AppContext, AppContextEventPayload } from '@/tt-app-context'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { EventManagerInterface } from '@/tt-event-manager'
import { getResourceDefinitions } from '@/tt-entity-forms/EntityViewDefinitions'
import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  matchMustacheVariables,
  TTC_RELATION_FILTER,
} from '@/tt-entity-forms/components/utils/RelationField'
import { normalizeName } from '@/helpers/text'
import { ObjectEntries, ObjectEntry } from '@/helpers/types/ObjectEntry'

import { EntityBatchActionIntentInterface } from './types'
import { ResourceTranslator } from '../ResourceTranslator'

export const getEntitiesIds = (entities: any[]): Array<number> => {
  return entities.map(({ id }) => id)
}

export const getEntitiesActions = async (
  api: Api,
  resource: string,
  ids: number[],
): Promise<EntityActionsById[]> => {
  const options: EntitiesActionsRequestOptions & { groupById: true } = {
    ids,
    groupById: true,
  }

  return await api.getEntitiesActions(resource, options)
}

const getEntitiesIdsByActionName = (
  actionName: string,
  entitiesActions: EntityActionsById[],
): Array<number> => {
  return entitiesActions
    .map((entity) => {
      if (entity.actions.hasOwnProperty(actionName)) {
        return entity.id
      }
    })
    .filter((id) => id)
}

export const getBachableEntitites = async (
  api: Api,
  resource: string,
  actionName: string,
  entities: any[],
): Promise<Array<number>> => {
  const ids = getEntitiesIds(entities)
  const entitiesActions = await getEntitiesActions(api, resource, ids)

  return getEntitiesIdsByActionName(actionName, entitiesActions)
}

const parseItemErrorMessages = (
  item: { message: string } | { [field: string]: string[] },
  { actionName, resourceName }: { actionName?: string; resourceName: string },
): string[] | null => {
  if (item == null || typeof item !== 'object') return null

  // handle single message error
  if (item.message && typeof item.message === 'string') {
    return [item.message]
  }

  if (!resourceName) return null

  // handle attribute constraint errors
  const translateAttr = (attr: string): string =>
    actionName
      ? ResourceTranslator.translateActionAttribute(
          resourceName,
          actionName,
          attr,
          FormLabelTypes.LABEL,
        )
      : ResourceTranslator.translateAttribute(
          resourceName,
          attr,
          FormLabelTypes.LABEL,
        )
  const getAttrMessages = (attr: string, messages: string[]): string[] => {
    const attrLabel = attr && translateAttr(attr)

    return messages.map((message) =>
      attrLabel ? `${attrLabel}: ${message}` : message,
    )
  }

  return Object.entries(item)
    .filter(([_, messages]) => Array.isArray(messages))
    .map(([attr, messages]) => getAttrMessages(attr, messages))
    .flat()
}

/**
 * Supports two reponse error formats
 *
 * 1. a single message. E.g.,
 * ```
 * { message: 'Unauthorized' }
 * ```
 * 2. resource attribute constraint errors. E.g.,
 * ```
 * {
 *   name: ['this attribute cannot be blank']
 *   password: ['password must contain a number', 'password must be at least 8 characters long']
 * }
 * ```
 */
export const parseErrorMessages = ({
  actionName,
  error,
  resourceName,
}: {
  actionName?: string
  error: any
  resourceName?: string
}): string[] | null => {
  const { response } = error || {}
  const data = response.data as { message: string } | Record<string, string[]>

  return parseItemErrorMessages(data, { actionName, resourceName })
}

/**
 * Supports same response error formats as parseErrorMessages, but grouped by
 * ID. E.g.
 * ```
 * {
 *   1: { message: 'Unauthorized' },
 *   2: {
 *     name: ['this attribute cannot be blank']
 *     password: ['password must contain a number', 'password must be at least 8 characters long']
 *   }
 * }
 * ```
 */
export const parseBatchActionErrorMessages = <
  Item extends { message: string } | { [field: string]: string[] },
>({
  actionName,
  error,
  resourceName,
}: {
  actionName?: string
  error: any
  resourceName?: string
}): string[] | null => {
  const { response } = error || {}
  const items = Object.values(response.data) as Item[]
  const itemsErrors = items
    .map((item) => parseItemErrorMessages(item, { actionName, resourceName }))
    .filter((errors) => errors != null)

  return itemsErrors.reduce((acc, itemErrors) => [...acc, ...itemErrors], [])
}

/**
 * Show the first message in the list. Fallback to a generic error message.
 * The snack bar component can't display multiples messages at the same time.
 */
export const displayErrorMessages = (
  messages: string[] | null,
  eventManager: EventManagerInterface<AppContextEventPayload>,
): void => {
  const message =
    messages && messages.length > 0
      ? messages[0]
      : i18n.t('common.error_message')

  eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, { message })
}

const createBatchActionsSubmit =
  (
    api: Api,
    resourceName: string,
    batchEntities: number[],
    actionName: string,
  ) =>
  (data): Promise<EntityCollectionResponse<{}>> =>
    api.doBatchActions(resourceName, batchEntities, actionName, data)

export const showEntitiesActions =
  (appContext: AppContext) =>
  ({
    resourceName,
    actionName,
    entities,
    label,
  }: EntityBatchActionIntentInterface) => {
    const { eventManager, authModule, widgetServices } = appContext
    const api = authModule.getApi()

    const batchEntities = getEntitiesIds(entities)
    const rootName = `${normalizeName(resourceName)}${normalizeName(
      actionName,
    )}`
    const translateFn = ResourceTranslator.getFormCallback(
      resourceName,
      rootName,
    )
    const context = { resourceName, actionName }
    const submitFn = createBatchActionsSubmit(
      api,
      resourceName,
      batchEntities,
      actionName,
    )

    const jsonSchema = widgetServices.resourceMetaManager.getFormSchema(
      resourceName,
      actionName,
    )

    if (jsonSchema) {
      new DialogFormBuilder(label, rootName, {
        eventManager,
        i18n,
      })
        .setJsonSchema(jsonSchema)
        .addToContext(context)
        .setTranslateFunction(translateFn)
        .addToDefinitions(getResourceDefinitions(appContext))
        .onSubmit(submitFn)
        .displayDialog()
    } else {
      eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: actionName,
        // @ts-ignore -- to review
        accept: submitFn,
      })
    }
  }

/**
 * Returns the name of the definition schema in the OpenAPI for a given resource action.
 *
 * ie: resource "open-shift-requests" with "post" action -> "OpenShiftRequestsPost"
 */
export const getFormDefinitionName = (resource: string, action: string) =>
  [resource, action].map(normalizeName).join('')

export const getCreateFormName = (resource: string) =>
  getFormDefinitionName(resource, 'post')

export const getEditFormName = (resource: string) =>
  getFormDefinitionName(resource, 'put')

/**
 * Given a JSON Schema for a resource operation or action, returns the list of
 * all variables used in its relation filters.
 */
export const getSchemaRelationFilterVariables = (
  schema: JSONSchema7,
): string[] => {
  const removeSchemaComponents = ({
    components: _,
    ...cleanSchema
  }: JSONSchema7 & { components?: unknown }): JSONSchema7 => cleanSchema

  const relationFilterValuePathRegex = new RegExp(
    `.+\\.${TTC_RELATION_FILTER}\\.(scopes\\.\\d+|.+\\.value)$`,
  )

  const isRelationFilterValue = ([key]: ObjectEntry): boolean =>
    key.match(relationFilterValuePathRegex) !== null

  const keepRelationFilterValueEntries = (
    entries: ObjectEntries,
  ): ObjectEntries => entries.filter(isRelationFilterValue)

  const collectValues = (values: string[], [_, value]: ObjectEntry): string[] =>
    typeof value === 'string' && !!value ? [...values, value] : values

  const collectEntriesValues = (entries: ObjectEntries): string[] =>
    entries.reduce(collectValues, [])

  const extractVariables = (values: string[]): string[] =>
    values
      .map((v) => matchMustacheVariables(v) || [])
      .flat()
      .map((v) => v.trim())

  const getVariables = flow(
    removeSchemaComponents,
    flatten,
    Object.entries,
    keepRelationFilterValueEntries,
    collectEntriesValues,
    extractVariables,
    uniq,
  )

  return getVariables(schema)
}
