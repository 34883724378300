<template>
  <tt-attr name="resultantStatus">
    <TChipLabel
      :text-key="labelKey"
      :background-color="color"
      text-color="white"
    />
  </tt-attr>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { createI18nEnumKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { getStatusColor } from './utils'
import { RESOURCE, STATUS_ATTR } from './constants'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileRunsheetSessionsLogsResultantStatusAttr',
  inject: ['getItemHook'],
  computed: {
    statusKey(): string {
      return this.getItemHook().getRawValue('resultantStatus')
    },
    color(): string {
      return getStatusColor(this.statusKey)
    },
    labelKey(): string {
      return createI18nEnumKey(RESOURCE, STATUS_ATTR, this.statusKey)
    },
  },
})
</script>
