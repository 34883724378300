// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionPremiumAssignmentsTile from './PositionPremiumAssignmentsTile.vue'
import PositionPremiumAssignmentsColumns from './PositionPremiumAssignmentsColumns'
import PositionPremiumAssignmentsDetail from './PositionPremiumAssignmentsDetail.vue'
import PositionPremiumAssignmentsReference from './PositionPremiumAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PositionPremiumAssignmentsTile',
      PositionPremiumAssignmentsTile,
    )
    Vue.component(
      'PositionPremiumAssignmentsDetail',
      PositionPremiumAssignmentsDetail,
    )
    Vue.component(
      'PositionPremiumAssignmentsReference',
      PositionPremiumAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUM_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionPremiumAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUM_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionPremiumAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUM_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionPremiumAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_PREMIUM_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionPremiumAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
