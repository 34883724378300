<template>
  <div ref="container" class="resize-container">
    <div v-show="overflow">
      <slot name="overflow"></slot>
    </div>
    <div v-show="!overflow">
      <span class="d-flex">
        <slot></slot>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'RealTimeOverflowBox',
  props: {
    maxHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      overflow: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    checkOverflow() {
      if (!this.$refs.container) {
        return false
      }

      const containerElement = this.$refs.container as HTMLElement

      this.overflow =
        containerElement.scrollWidth > containerElement.offsetWidth
    },
    onResize() {
      this.overflow = false
      this.$nextTick(() => {
        this.checkOverflow()
      })
    },
  },
})
</script>

<style scoped>
.resize-container {
  display: flex;
  overflow: hidden;
  position: inherit;
}
</style>
