<template>
  <a
    style="color: var(--v-ttPrimary-base)"
    target="_blank"
    :href="link"
    v-text="$t('tt-entity-design.google-map')"
  />
</template>

<script lang="ts">
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import Vue, { VueConstructor } from 'vue'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AlarmsLocationGoogleMapLink',
  inject: ['getItemHook'],
  computed: {
    link(): string {
      const latitude = this.getItemHook().getRawValue('location.latitude')
      const longitude = this.getItemHook().getRawValue('location.longitude')

      return `https://www.google.com/maps/place/${latitude},${longitude}`
    },
  },
  created() {
    this.getItemHook().addAttribute('location')
  },
})
</script>
