import { AuthModule } from '@tracktik/tt-authentication'

import { AppContext } from '@/tt-app-context'

import {
  getResourcePermissions,
  getResourceActionPermissions,
} from './resource-permissions'
import {
  ResourceAuditorInterface,
  ResourceMetaManagerInterface,
  SecurityRuleOperation,
} from './types'

export interface ResourcePermissionAuditorServices {
  authModule: AuthModule
  resourceMetaManager: ResourceMetaManagerInterface
}

export const getResourcePermissionAuditorServices = (
  appContext: AppContext,
): ResourcePermissionAuditorServices => {
  const { authModule, widgetServices } = appContext
  const { resourceMetaManager } = widgetServices

  return { authModule, resourceMetaManager }
}

export default class ResourcePermissionAuditor
  implements ResourceAuditorInterface
{
  /**
   * Get viewable ROOT resource names based on user permissions
   */
  static getViewableRootResources(
    services: ResourcePermissionAuditorServices,
  ): string[] {
    const canViewResource = (resourceName: string) =>
      ResourcePermissionAuditor.canViewResource(services, resourceName)

    return (
      services.resourceMetaManager
        .getRootResourceNames()
        .filter(canViewResource) ?? []
    )
  }

  /**
   * Get viewable resources based on permissions
   */
  static getViewableResources(
    services: ResourcePermissionAuditorServices,
  ): string[] {
    return (
      services.resourceMetaManager
        .getResourceNames()
        .filter((resourceName: string) => {
          return ResourcePermissionAuditor.canViewResource(
            services,
            resourceName,
          )
        }) ?? []
    )
  }

  /**
   * Can view all of resources in a list
   */
  static canViewAllResources(
    services: ResourcePermissionAuditorServices,
    resourceNames: string[],
  ): boolean {
    const canViewResource = (resourceName: string) =>
      ResourcePermissionAuditor.canViewResource(services, resourceName)

    return resourceNames.every(canViewResource)
  }

  static canViewResource(
    { authModule, resourceMetaManager }: ResourcePermissionAuditorServices,
    resourceName: string,
  ): boolean {
    const permissions = getResourcePermissions(
      resourceMetaManager,
      resourceName,
      SecurityRuleOperation.VIEW,
    )

    return authModule.hasPermission(permissions)
  }

  /**
   * Can the user edit any object with resource
   */
  static canEditResource(
    { authModule, resourceMetaManager }: ResourcePermissionAuditorServices,
    resourceName: string,
  ): boolean {
    const permissions = getResourcePermissions(
      resourceMetaManager,
      resourceName,
      SecurityRuleOperation.EDIT,
    )

    return authModule.hasPermission(permissions)
  }

  /**
   * Can the user create a new resource
   */
  static canCreateResource(
    { authModule, resourceMetaManager }: ResourcePermissionAuditorServices,
    resourceName: string,
  ): boolean {
    const permissions = getResourcePermissions(
      resourceMetaManager,
      resourceName,
      SecurityRuleOperation.CREATE,
    )

    return authModule.hasPermission(permissions)
  }

  static canDeleteResource(
    { authModule, resourceMetaManager }: ResourcePermissionAuditorServices,
    resourceName: string,
  ): boolean {
    const permissions = getResourcePermissions(
      resourceMetaManager,
      resourceName,
      SecurityRuleOperation.DELETE,
    )

    return authModule.hasPermission(permissions)
  }

  /**
   * Concatenate the portal, resource name and action name to check if
   * the user has the required permissions to perform the action on the resource
   *
   * I.e. 'client:assets:archive:execute'
   */
  static canExecuteResourceAction(
    { authModule, resourceMetaManager }: ResourcePermissionAuditorServices,
    resourceName: string,
    actionName: string,
  ): boolean {
    const permissions = getResourceActionPermissions(
      resourceMetaManager,
      resourceName,
      actionName,
      SecurityRuleOperation.EXECUTE,
    )

    return authModule.hasPermission(permissions)
  }
}
