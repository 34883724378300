import hook from './PieChartWidgetHook'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import Vue from 'vue'
import PieChartWidgetEditor from './PieChartWidgetEditor.vue'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import(/* webpackChunkName: "PieChartWidget" */ './PieChartWidget.vue').catch(
    handleAsyncComponentError,
  )

Vue.component('PieChartWidgetEditor', PieChartWidgetEditor)

const PieChartWidget: WidgetTypeDefinition = {
  name: WidgetName.PIE_CHART_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    PieChartWidget: {
      view: {
        is: 'PieChartWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-chart-pie',
    color: '#3143cc',
    thumbnail: require('./pie-chart.png'),
    print: true,
  },
}

export default PieChartWidget
