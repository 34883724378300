<template>
  <v-app id="side-sheet-stand-alone">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <AppLayoutUtils />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '../types'
import ProvideLayoutManager from './ProvideLayoutManager.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { AllowEntityOperationsProvider, AllowEntityOption } from '@/types'

export default Vue.extend({
  name: 'AppLayoutSideSheetStandAlone',
  components: {
    ProvideLayoutManager,
  },
  provide(): AllowEntityOperationsProvider {
    return {
      allowEntityActions: this.allowEntityActions,
      allowEntityCreation: this.allowEntityCreation,
      allowEntityDelete: this.allowEntityDelete,
      allowEntityEdit: this.allowEntityEdit,
    }
  },
  props: {
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
    allowEntityActions: {
      type: Boolean as PropType<AllowEntityOption>,
      default: false,
    },
    allowEntityCreation: {
      type: Boolean as PropType<AllowEntityOption>,
      default: false,
    },
    allowEntityDelete: {
      type: Boolean as PropType<AllowEntityOption>,
      default: false,
    },
    allowEntityEdit: {
      type: Boolean as PropType<AllowEntityOption>,
      default: false,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager, this.layoutConfiguration),
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
