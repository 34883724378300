<template>
  <DispatchTasksSlaTime :time-object="timeObject" />
</template>

<script lang="ts">
import Vue from 'vue'
import { formatMinutesToTimeObject } from '@/helpers/dates/formatMinutesToTimeObject'
import { nowTimeStamp } from '@/helpers/dates/convertDateToTimeStamp'
import DispatchTasksSlaTime from './DispatchTasksSlaTime.vue'

export default Vue.extend({
  name: 'DispatchTasksSlaCountdown',
  components: {
    DispatchTasksSlaTime,
  },
  props: {
    leftTimeStamp: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      interval: null,
      nowTimeStamp: nowTimeStamp(),
    }
  },
  computed: {
    timeObject(): { days: number; hours: number; minutes: number } {
      if (this.hasTimeLeft) {
        const diffInMinutes =
          (this.leftTimeStamp - this.nowTimeStamp) / 1000 / 60

        return formatMinutesToTimeObject(diffInMinutes)
      } else {
        this.$emit('update')

        return formatMinutesToTimeObject(0)
      }
    },
    hasTimeLeft(): boolean {
      return this.leftTimeStamp > this.nowTimeStamp
    },
  },
  mounted() {
    if (this.hasTimeLeft) {
      this.startCountdown()
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.hasTimeLeft) {
          this.nowTimeStamp = nowTimeStamp()
        } else {
          this.clearInterval()
        }
      }, 1000)
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
  },
})
</script>
