import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { AggregationQuery } from '@/tt-widget-factory/definitions'

import { DataSetDefinition, Filter } from '../types'

const { EQUAL } = FilterOperatorType

const getDatasetMeasures = ({ measure, measures }: DataSetDefinition) =>
  measures || (measure && [measure]) || null

const getDatasetDimensions = ({ dimension, dimensions }: DataSetDefinition) =>
  dimensions || (dimension && [dimension]) || null

const createAggregationQuery = (
  dataSet: DataSetDefinition,
  filters: Filter[],
): AggregationQuery => ({
  resource: dataSet?.resource,
  filters,
  scope: dataSet?.filters?.scopes,
  measures: getDatasetMeasures(dataSet),
  dimensions: getDatasetDimensions(dataSet),
})

const convertCustomFilterToFilter = ({ filterName, value }): Filter =>
  ({
    attribute: filterName,
    value,
  } as Filter)

const getDataSetFilters = (
  { filters: dataSetFilters = {} }: DataSetDefinition = {
    resource: '',
    filters: {},
  },
): Filter[] => {
  const {
    filters = [],
    customFilters = [],
    whereQL,
    havingQL,
    includeInactive,
  } = dataSetFilters

  const whereQlFilter = {
    attribute: 'whereQL',
    value: whereQL,
    operator: EQUAL,
  }

  const havingQlFilter = {
    attribute: 'havingQL',
    value: havingQL,
    operator: EQUAL,
  }

  const includeInactiveFilter = {
    attribute: 'includeInactive',
    value: 1,
    operator: EQUAL,
  }

  const convertedCustomFilters = customFilters.map(convertCustomFilterToFilter)

  return [
    ...filters,
    ...convertedCustomFilters,
    ...(whereQL ? [whereQlFilter] : []),
    ...(havingQL ? [havingQlFilter] : []),
    ...(includeInactive ? [includeInactiveFilter] : []),
  ]
}

export const dataSetToAggregationQuery = (
  dataSet: DataSetDefinition,
): AggregationQuery => {
  const filters = getDataSetFilters(dataSet)

  return createAggregationQuery(dataSet, filters)
}
