// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TaskTypeSkillsTile from './TaskTypeSkillsTile.vue'
import TaskTypeSkillsColumns from './TaskTypeSkillsColumns'
import TaskTypeSkillsDetail from './TaskTypeSkillsDetail.vue'
import TaskTypeSkillsReference from './TaskTypeSkillsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TaskTypeSkillsTile', TaskTypeSkillsTile)
    Vue.component('TaskTypeSkillsDetail', TaskTypeSkillsDetail)
    Vue.component('TaskTypeSkillsReference', TaskTypeSkillsReference)

    modularManager.addResourcePreset(
      Resources.TASK_TYPE_SKILLS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TaskTypeSkillsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPE_SKILLS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TaskTypeSkillsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPE_SKILLS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TaskTypeSkillsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TASK_TYPE_SKILLS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TaskTypeSkillsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
