<template>
  <div class="exception-type-parent-label">
    <TChipLabel v-if="parentLabel" v-bind="chipProps" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { TChipLabelType } from '@/tt-ui/types'

import {
  ExceptionTypeColor,
  ExceptionTypeId,
  getExceptionTypeColor,
} from './exception-types-parent'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ExceptionTypeParentLabel',
  inject: ['getItemHook', 'relationPrefix'],
  computed: {
    parentColor(): ExceptionTypeColor | null {
      return getExceptionTypeColor(this.parentId)
    },
    parentId(): ExceptionTypeId {
      return this.getItemHook().get(this.parentIdPath)
    },
    parentIdPath(): string {
      return this.attributePath('parent.id')
    },
    parentLabelPath(): string {
      return this.attributePath('parent.label')
    },
    parentLabel(): string {
      return this.getItemHook().get(this.parentLabelPath)
    },
    chipProps(): Record<string, unknown> {
      return {
        ...this.parentColor,
        modifiers: {
          style: { fontWeight: 'normal', textTransform: 'none' },
        },
        textKey: this.parentLabel,
        type: TChipLabelType.Pill,
      }
    },
  },
  created() {
    this.getItemHook().addAttribute(this.parentIdPath)
    this.getItemHook().addAttribute(this.parentLabelPath)
  },
  methods: {
    attributePath(attribute: string): string {
      return this.relationPrefix
        ? `${this.relationPrefix}.${attribute}`
        : attribute
    },
  },
})
</script>
