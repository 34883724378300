import { TTCloudLocale } from '@tracktik/tt-authentication'

import {
  SupportedLocale,
  frontendLocales,
  ttCloudLocales,
  DEFAULT_LANG,
} from './language-options'

/**
 * Order matters. First compatible locale found will be used.
 */
const ttCloudToFrontendLocalesMapping: Record<
  TTCloudLocale,
  SupportedLocale | null
> = {
  EN_US: 'en',
  FR: 'fr-CA',
  'FR-BE': 'fr-BE',
  RO: 'ro-RO',
  ES: 'es-ES',
  DE: 'de-DE',
  NL: 'nl-NL',
  'NL-BE': 'nl-BE',
  PT: 'pt-PT',
  SV: 'sv-SE',
  'ZH-HANS': null,
  TH: null,
  'AR-SA': 'ar-SA',
  'BS-BA': 'bs-BA',
  'CS-CZ': 'cs-CZ',
  'DA-DK': 'da-DK',
  'DE-AT': 'de-AT',
  'EL-GR': 'el-GR',
  'ES-MX': 'es-MX',
  'ET-EE': 'et-EE',
  'FI-FI': 'fi-FI',
  'HI-IN': 'hi-IN',
  'HR-HR': 'hr-HR',
  'HU-HU': 'hu-HU',
  'ID-ID': 'id-ID',
  'IT-IT': 'it-IT',
  'JA-JP': 'ja-JP',
  'KO-KR': 'ko-KR',
  'LV-LV': 'lv-LV',
  'NO-NO': 'no-NO',
  'PL-PL': 'pl-PL',
  'SR-RS': 'sr-RS',
  'TH-TH': 'th-TH',
  'TR-TR': 'tr-TR',
  'VI-VN': 'vi-VN',
  'ZH-CN': 'zh-CN',
  'ZH-HK': 'zh-HK',
  'DE-CH': 'de-CH',
  'FR-CH': 'fr-CH',
  'SK-SK': 'sk-SK',
  'SL-SI': 'sl-SI',
  'ME-ME': 'me-ME',
} as const

const frontendToTtCloudLocalesMapping: Record<
  SupportedLocale,
  TTCloudLocale | null
> = {
  en: 'EN_US',
  'nl-BE': 'NL-BE',
  'nl-NL': 'NL',
  'fr-BE': 'FR-BE',
  'fr-CA': 'FR',
  'fr-FR': 'FR',
  'de-DE': 'DE',
  'pt-PT': 'PT',
  'ro-RO': 'RO',
  'es-ES': 'ES',
  'es-UY': 'ES',
  'sv-SE': 'SV',
  'ar-SA': 'AR-SA',
  'cs-CZ': 'CS-CZ',
  'da-DK': 'DA-DK',
  'de-AT': 'DE-AT',
  'es-MX': 'ES-MX',
  'fi-FI': 'FI-FI',
  'hu-HU': 'HU-HU',
  'id-ID': 'ID-ID',
  'it-IT': 'IT-IT',
  'ja-JP': 'JA-JP',
  'ko-KR': 'KO-KR',
  'no-NO': 'NO-NO',
  'pl-PL': 'PL-PL',
  'sr-RS': 'SR-RS',
  'th-TH': 'TH-TH',
  'tr-TR': 'TR-TR',
  'vi-VN': 'VI-VN',
  'zh-CN': 'ZH-CN',
  'zh-HK': 'ZH-HK',
  'de-CH': 'DE-CH',
  'fr-CH': 'FR-CH',
  'hr-HR': 'HR-HR',
  'sk-SK': 'SK-SK',
  'sl-SI': 'SL-SI',
  'bs-BA': 'BS-BA',
  'et-EE': 'ET-EE',
  'el-GR': 'EL-GR',
  'hi-IN': 'HI-IN',
  'lv-LV': 'LV-LV',
  'me-ME': 'ME-ME',
}

const isSupportedLocale = (locale: string): locale is SupportedLocale =>
  (frontendLocales as Readonly<string[]>).includes(locale)

const isTTCloudLocale = (locale: string): locale is TTCloudLocale =>
  (ttCloudLocales as Readonly<string[]>).includes(locale)

/**
 * Tries to find a compatible supported language in a differente culture.
 * E.g. 'es' and 'es-AR' become 'es-ES'
 */
export const findClosestSupportedLocale = (locale: string): string | null => {
  const internationalLocale = locale.slice(0, 2)
  const regex = new RegExp(`^${internationalLocale}`, 'i')
  const supportedLocales = Object.values(ttCloudToFrontendLocalesMapping)

  return supportedLocales.find((feLocale) => regex.test(feLocale)) ?? null
}

export const normalizeLocale = (locale: string): string =>
  locale
    .replace('_', '-')
    .toUpperCase()
    .replace(/^[A-Z]{2}/, (s) => s.toLowerCase())

const convertTTCloudLocaleToFrontendLocale = (locale = ''): SupportedLocale => {
  const normalizedLocale = normalizeLocale(locale)
  if (isSupportedLocale(normalizedLocale)) return normalizedLocale

  const convertedLocale =
    ttCloudToFrontendLocalesMapping[locale.toUpperCase()] ??
    findClosestSupportedLocale(normalizedLocale)

  if (!convertedLocale) {
    console.warn(
      `Selected locale '${locale}' is not supported. Defaulting to '${DEFAULT_LANG}'.`,
    )

    return DEFAULT_LANG
  }

  return convertedLocale
}

const convertFrontendLocaleToTTCloudLocale = (
  frontendLocale = '',
): TTCloudLocale => {
  if (isTTCloudLocale(frontendLocale)) return frontendLocale

  // @ts-ignore -- Stupid TS
  const convertedLocale: TTCloudLocale | undefined =
    frontendToTtCloudLocalesMapping[normalizeLocale(frontendLocale)]

  if (!convertedLocale) {
    console.warn(
      `Selected locale ${frontendLocale} is not supported. Defaulting to EN_US.`,
    )

    return 'EN_US'
  }

  return convertedLocale
}

/**
 * Frontend locales are different from backend locales,
 * so we get the valid locale format for each.
 */
export const getValidLocale = (
  locale: string,
): {
  frontendLocale: SupportedLocale
  ttCloudLocale: TTCloudLocale
} => {
  const frontendLocale = convertTTCloudLocaleToFrontendLocale(locale ?? '')
  const ttCloudLocale = convertFrontendLocaleToTTCloudLocale(locale ?? '')

  return { frontendLocale, ttCloudLocale }
}
