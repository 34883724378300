import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PresetTypes } from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'

import { Resources } from '../../types'

export default {
  install(Vue) {
    const reportFieldsReport: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.REPORT_FIELDS, 'report'),
      is: 'tt-attr-relation',
      props: {
        name: 'report',
        title: 'Report',
        type: PresetTypes.DETAILS_CARD,
      },
    }

    addPreviewTab(Resources.REPORT_FIELDS, reportFieldsReport)
  },
}
