<template>
  <v-app id="injected-dashboard-view" class="fill-relative-height">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <app-window ref="window" :closable="false">
          <DashboardView />
        </app-window>
        <AppExtensionSlotFactory container-type="div" slot-name="ANYWHERE" />
        <AppLayoutUtils :layout-configuration="layoutConfiguration" />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'

import DashboardView from './components/DashboardView.vue'
import { PortalType } from './types'
import { PortalTypeProvider } from './PortalTypeProvider'

type Providers = {
  isInjectedDashboardTab: boolean
} & PortalTypeProvider

/**
 * DataLab entry view injected in TTC
 */
export default Vue.extend({
  name: 'DashboardInjected',
  components: {
    ProvideLayoutManager,
    DashboardView,
  },
  provide(): Providers {
    return {
      isInjectedDashboardTab: true,
      portalType: this.portalType,
    }
  },
  props: {
    portalType: {
      type: String as PropType<PortalType>,
      /**
       * is required, but we maintain previous behaviour with TTC (no "portal-type" prop) by defaulting to `admin`
       */
      default: (): PortalType => 'admin',
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>

<style scoped>
/* Calculate the height of the injected page */
.fill-relative-height {
  height: calc(100vh - 94px);
  position: inherit;
}

/* Position and resize sidesheet */
#injected-dashboard-view >>> .v-navigation-drawer--fixed {
  top: 94px !important;
  max-height: calc(100vh - 94px) !important;
  margin-right: 15px !important;
}

/* Force dialog to be on the top */
#injected-dashboard-view >>> .v-dialog__content.v-dialog__content--active {
  z-index: 1001 !important;
}

/* Force pivot field list to hide title */
#injected-dashboard-view >>> .e-pivotfieldlist-wrapper .e-field-list-title {
  display: none;
}

#injected-dashboard-view >>> .v-toolbar__title.modal-title {
  font-size: 18px;
  font-weight: normal;
}
</style>
