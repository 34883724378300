// Enter your custom installation here

import {
  addPreviewTab,
  addResourceDocument,
} from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'

import StatusColor from '../StatusColor.vue'
import WorkSessionDoc from './WorkSessionDoc'
import WorkSessionsDetailEmployee from './WorkSessionsDetailEmployee.vue'
import WorkSessionsDetailFile from './WorkSessionsDetailFile.vue'
import WorkSessionsDetailShift from './WorkSessionsDetailShift.vue'
import { Resources } from '../../types'

export default {
  install(Vue) {
    // Add the document
    addResourceDocument(Resources.WORK_SESSIONS, WorkSessionDoc)

    Vue.component('StatusColor', StatusColor)
    Vue.component('WorkSessionsDetailFile', WorkSessionsDetailFile)
    Vue.component('WorkSessionsDetailEmployee', WorkSessionsDetailEmployee)
    Vue.component('WorkSessionsDetailShift', WorkSessionsDetailShift)

    const shiftRelationDetails: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.WORK_SESSIONS, 'shift'),
      is: 'tt-attr-relation',
      props: {
        name: 'shift',
        type: PresetTypes.DETAILS_CARD,
      },
    }

    const accountRelationDetails: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.WORK_SESSIONS, 'account'),
      is: 'tt-attr-relation',
      props: {
        name: 'account',
        type: PresetTypes.DETAILS_CARD,
      },
    }

    addPreviewTab(Resources.WORK_SESSIONS, shiftRelationDetails)
    addPreviewTab(Resources.WORK_SESSIONS, accountRelationDetails)

    // Adding extra detail cards
    const employeeDetail: PresetItem = {
      title: 'tt-entity-design.employee',
      data: { is: 'WorkSessionsDetailEmployee', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    const shiftDetail: PresetItem = {
      title: 'tt-entity-design.shift-details',
      data: { is: 'WorkSessionsDetailShift', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.WORK_SESSIONS,
      PresetTypes.DETAILS_CARD,
      [employeeDetail, shiftDetail],
    )
  },
}
