// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileRunsheetGroupsTile from './MobileRunsheetGroupsTile.vue'
import MobileRunsheetGroupsColumns from './MobileRunsheetGroupsColumns'
import MobileRunsheetGroupsDetail from './MobileRunsheetGroupsDetail.vue'
import MobileRunsheetGroupsReference from './MobileRunsheetGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileRunsheetGroupsTile', MobileRunsheetGroupsTile)
    Vue.component('MobileRunsheetGroupsDetail', MobileRunsheetGroupsDetail)
    Vue.component(
      'MobileRunsheetGroupsReference',
      MobileRunsheetGroupsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileRunsheetGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_RUNSHEET_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileRunsheetGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
