// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BreakRuleConditionsTile from './BreakRuleConditionsTile.vue'
import BreakRuleConditionsColumns from './BreakRuleConditionsColumns'
import BreakRuleConditionsDetail from './BreakRuleConditionsDetail.vue'
import BreakRuleConditionsMetaCard from './BreakRuleConditionsMetaCard.vue'
import BreakRuleConditionsReference from './BreakRuleConditionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BreakRuleConditionsTile', BreakRuleConditionsTile)
    Vue.component('BreakRuleConditionsDetail', BreakRuleConditionsDetail)
    Vue.component('BreakRuleConditionsMetaCard', BreakRuleConditionsMetaCard)
    Vue.component('BreakRuleConditionsReference', BreakRuleConditionsReference)

    modularManager.addResourcePreset(
      Resources.BREAK_RULE_CONDITIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BreakRuleConditionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_CONDITIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BreakRuleConditionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_CONDITIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleConditionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_CONDITIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BreakRuleConditionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BREAK_RULE_CONDITIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BreakRuleConditionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
