import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { EmployeeCustomComponenets } from '../../types'

const baseTranslation = 'tt-entity-design.leave-management.employee'

const TransactionActivityCustomColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'modelId',
    headerText: `${baseTranslation}.overview.id`,
    style: {
      width: 100,
    },
  },
  {
    attributeName: 'leaveType',
    style: {
      cssClass: 'font-weight-bold',
    },
  },
  {
    attributeName: 'type',
    component: {
      is: EmployeeCustomComponenets.TransactionActivityTransactionTypeColumn,
    },
  },
  {
    attributeName: 'effectiveDateTime',
    component: {
      is: EmployeeCustomComponenets.TransactionActivityDateColumn,
      props: {
        tooltipText:
          'tt-entity-design.leave-management.leave-requests.start-time',
      },
    },
  },
  'adjustmentHours',
  'adjustmentDays',
  {
    attributeName: 'approvalUser',
    headerText: `${baseTranslation}.transaction-activity.approved-by`,
  },
]

export default TransactionActivityCustomColumns
