// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportFlagsTile from './ReportFlagsTile.vue'
import ReportFlagsColumns from './ReportFlagsColumns'
import ReportFlagsDetail from './ReportFlagsDetail.vue'
import ReportFlagsMetaCard from './ReportFlagsMetaCard.vue'
import ReportFlagsReference from './ReportFlagsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ReportFlagsTile', ReportFlagsTile)
    Vue.component('ReportFlagsDetail', ReportFlagsDetail)
    Vue.component('ReportFlagsMetaCard', ReportFlagsMetaCard)
    Vue.component('ReportFlagsReference', ReportFlagsReference)

    modularManager.addResourcePreset(
      Resources.REPORT_FLAGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportFlagsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportFlagsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'ReportFlagsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_FLAGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportFlagsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
