<template>
  <v-container fluid>
    <v-row>
      <v-col class="px-4 col-2" align-self="center">
        <v-icon x-large color="blue"> mdi-format-list-bulleted </v-icon>
      </v-col>
      <v-col class="pa-0 col-10">
        <tt-tile>
          <tt-attr slot="title" name="name" />
          <div slot="line2" class="d-flex">
            <span v-text="$t('res.leave-policy-items.attr.leaveType.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="leaveType" />
          </div>
          <div v-if="isAccrued" slot="line3" class="d-flex">
            <span
              v-text="$t('res.leave-policy-items.attr.accrualRate.label')"
            />
            <span class="pr-1">:</span>
            <tt-attr name="accrualRate" />
          </div>
          <div v-else slot="line3" class="d-flex">
            <span v-text="$t('res.leave-policy-items.attr.quantity.label')" />
            <span class="pr-1">:</span>
            <LeavePolicyItemsValueField
              entitlement-value-name="quantity"
              show-entitlement-unit
            />
          </div>
          <div slot="line4" class="d-flex">
            <span v-text="$t('res.leave-policy-items.attr.carryOver.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="carryOver" />
          </div>
          <div slot="line5" class="d-flex">
            <span v-text="$t('res.leave-policy-items.attr.status.label')" />
            <span class="pr-1">:</span>
            <tt-attr name="status" />
          </div>
        </tt-tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import LeavePolicyItemsValueField from '@/tt-entity-design/src/components/leave-policy-items/LeavePolicyItemsValueField.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyItemsTile',
  components: { LeavePolicyItemsValueField },
  inject: ['getItemHook'],
  computed: {
    isAccrued(): boolean {
      return !!this.getItemHook().get('accrualRate')
    },
  },
  created() {
    this.getItemHook().addAttribute('accrualRate')
  },
})
</script>
