import debounce from 'lodash/debounce'

import { BaseWidgetHook, WidgetState } from '@/tt-widget-factory/types'
import { DashboardWidgetRowSizes, WidgetPosition } from './types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

import {
  DashboardColumn,
  DashboardRow,
  DashboardWidgetModel,
  WidgetModels,
} from '@/tt-widget-components'
import i18n from '@/plugins/i18n'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { cloneData } from '@/helpers/cloneData'

type DashboardChangeCallback = (widget: DashboardWidgetModel) => void

type DashboardHookState = {
  status: WidgetState['status']
  /**
   * Flat dictionary to know which widget is rendered.
   * Keys are coordinate of widget position : `${row}-${col}-${tab}`
   *  ie:
   * ```
   * {
   *  "0-0-0": true,
   *  "0-1-0": false
   * }
   * ```
   * Means a dashboard with 1 row and 2 columns, first widget only is rendered
   */
  rendered: Record<string, boolean>
  [k: string]: any
}

export default class DashboardWidgetHook extends BaseWidgetHook<DashboardWidgetModel> {
  state: DashboardHookState
  changeCallbacks: DashboardChangeCallback[] = []
  placeDebounced: Function

  /**
   * @todo Discuss strategy for dashboard permission
   * @param authModule
   */
  hasPermission(): boolean {
    return true
  }

  /**
   * Always valid
   */
  get isValid(): boolean {
    return true
  }

  setWidgetRendered({ rowIndex, colIndex, tabIndex }: WidgetPosition) {
    this.state = {
      ...this.state,
      rendered: {
        ...this.state.rendered,
        [`${rowIndex}-${colIndex}-${tabIndex}`]: true,
      },
    }
  }

  openLibrary() {
    this.services!.contextManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET, {
      is: 'WidgetDragSearch',
      title: 'Widget Finder',
    })
  }

  get data(): DashboardWidgetModel {
    return this.state.widget
  }

  getColumn(
    rowIndex: number,
    colIndex: number,
    dashboard?: DashboardWidgetModel,
  ): DashboardColumn | undefined {
    const row = this.getRow(rowIndex, dashboard)

    return row && row.columns[colIndex]
  }

  getRow(rowIndex: number, dashboard = this.data): DashboardRow | undefined {
    return dashboard.rows[rowIndex]
  }

  getSlotId(
    rowIndex: number,
    colIndex: number,
    tabIndex: number,
    dashboard = this.data,
  ): string {
    return `${dashboard.uid}|${rowIndex}|${colIndex}|${tabIndex}`
  }

  getWidget(
    rowIndex: number,
    colIndex: number,
    tabIndex = 0,
    dashboard?: DashboardWidgetModel,
  ): WidgetModels | undefined {
    const column = this.getColumn(rowIndex, colIndex, dashboard)
    if (!column) return

    return Array.isArray(column.widgetLookup)
      ? column.widgetLookup[tabIndex]
      : column.widgetLookup
  }

  initialize(): void {
    this.placeDebounced = debounce(
      (
        rowIndex: number,
        colIndex: number,
        tabIndex: number,
        widget: WidgetModels,
      ) => {
        this.place(rowIndex, colIndex, tabIndex, widget)
      },
      1000,
    )
  }

  emitChanged(dashboard: DashboardWidgetModel): void {
    this.changeCallbacks.forEach((callback) => {
      callback(cloneData(dashboard))
    })
  }

  onChange(callback: DashboardChangeCallback): UnsubscribeFunction {
    this.changeCallbacks = [...this.changeCallbacks, callback]

    return () => {
      this.changeCallbacks = this.changeCallbacks.filter(
        (callbackItem) => callbackItem !== callback,
      )
    }
  }

  place(
    rowIndex: number,
    colIndex: number,
    tabIndex: number,
    widget: WidgetModels,
  ): void {
    if (!this.columnExists(rowIndex, colIndex)) {
      console.log('Column does not exist')

      return
    }

    const dashboard = this.placeWidget(widget, rowIndex, colIndex, tabIndex)
    this.emitChanged(dashboard)
  }

  placeWidget(
    widget: WidgetModels,
    rowIndex: number,
    colIndex: number,
    tabIndex = 0,
    dashboard = this.data,
  ): DashboardWidgetModel {
    const newDashboard = cloneData(dashboard)
    const column = newDashboard.rows[rowIndex].columns[colIndex]
    const hasTabs = Array.isArray(column.widgetLookup)
    if (hasTabs) {
      column.widgetLookup[tabIndex] = widget
    } else {
      column.widgetLookup = widget
    }

    return newDashboard
  }

  switchWidgets(
    previousPosition: WidgetPosition,
    currentPosition: WidgetPosition,
  ) {
    let newDashboard = cloneData(this.data)
    const currentWidget = this.getWidget(
      currentPosition.rowIndex,
      currentPosition.colIndex,
      currentPosition.tabIndex,
    )
    const prevWidget = this.getWidget(
      previousPosition.rowIndex,
      previousPosition.colIndex,
      previousPosition.tabIndex,
    )
    newDashboard = this.placeWidget(
      prevWidget,
      currentPosition.rowIndex,
      currentPosition.colIndex,
      currentPosition.tabIndex,
      newDashboard,
    )
    newDashboard = this.placeWidget(
      currentWidget,
      previousPosition.rowIndex,
      previousPosition.colIndex,
      previousPosition.tabIndex,
      newDashboard,
    )

    this.updateUI(newDashboard)
  }

  /**
   * Change the size of a row
   * @param rowIndex
   * @param size
   */
  changeRowSize(rowIndex: number, size: DashboardWidgetRowSizes): void {
    const dashboard = cloneData(this.data)
    dashboard.rows[rowIndex].size = size
    this.updateUI(dashboard)
  }

  /**
   *
   * @param rowIndex
   * @param append
   */
  addColumn(rowIndex: number, append: boolean): void {
    const dashboard = cloneData(this.data)

    const widgetLookup = {} as WidgetModels
    const column: DashboardColumn = { widgetLookup }

    dashboard.rows[rowIndex].columns = append
      ? [...dashboard.rows[rowIndex].columns, column]
      : [column, ...dashboard.rows[rowIndex].columns]

    this.updateUI(dashboard)

    this.services.contextManager.dispatchEvent(
      LayoutWindowEvent.SIDE_SHEET_CLOSE,
      {},
    )
  }

  addTab(
    rowIndex: number,
    columnIndex: number,
    widget: WidgetModels = {} as WidgetModels,
  ): number {
    const dashboard = cloneData(this.data)

    const column = this.getColumn(rowIndex, columnIndex, dashboard)
    const widgetLookupList = Array.isArray(column.widgetLookup)
      ? column.widgetLookup
      : [column.widgetLookup]

    column.widgetLookup = [...widgetLookupList, widget]

    this.updateUI(dashboard)

    return column.widgetLookup.length - 1
  }

  get hideHeader() {
    return this.state.widget.hideHeader
  }

  addDraggedWidget(
    previousPosition: WidgetPosition,
    currentPosition: WidgetPosition,
  ) {
    const widget = cloneData(
      this.getWidget(
        previousPosition.rowIndex,
        previousPosition.colIndex,
        previousPosition.tabIndex,
      ),
    )
    const dashboard = cloneData(this.data)

    const currentPositionColumn = this.getColumn(
      currentPosition.rowIndex,
      currentPosition.colIndex,
      dashboard,
    )
    const previousPositionColumn = this.getColumn(
      previousPosition.rowIndex,
      previousPosition.colIndex,
      dashboard,
    )

    const widgetLookupList = Array.isArray(currentPositionColumn.widgetLookup)
      ? currentPositionColumn.widgetLookup
      : [currentPositionColumn.widgetLookup]

    currentPositionColumn.widgetLookup = [...widgetLookupList, widget]

    // Checks if the dashboard has multiple tabs and remove this tab otherwise we add empty widget
    if (Array.isArray(previousPositionColumn.widgetLookup)) {
      previousPositionColumn.widgetLookup =
        previousPositionColumn.widgetLookup.filter(
          (_, index) => index !== previousPosition.tabIndex,
        )
      if (previousPositionColumn.widgetLookup.length === 1) {
        previousPositionColumn.widgetLookup =
          previousPositionColumn.widgetLookup[0]
      }
    } else {
      previousPositionColumn.widgetLookup = [{} as WidgetModels]
    }

    this.updateUI(dashboard)

    return currentPositionColumn.widgetLookup.length - 1
  }

  create(rowIndex: number, columnIndex: number, tabIndex: number): void {
    const uid = this.getSlotId(rowIndex, columnIndex, tabIndex)
    const widget = { uid } as WidgetModels

    this.place(rowIndex, columnIndex, tabIndex, widget)
    this.edit(rowIndex, columnIndex, tabIndex)
  }

  edit(rowIndex: number, columnIndex: number, tabIndex: number): void {
    const widgetType = this.getWidget(rowIndex, columnIndex, tabIndex).is
    const widgetGetter = (dashboard: DashboardWidgetModel): WidgetModels => {
      return this.getWidget(rowIndex, columnIndex, tabIndex, dashboard)
    }

    const widgetSetter = (widget: WidgetModels): DashboardWidgetModel => {
      return this.placeWidget(widget, rowIndex, columnIndex, tabIndex)
    }

    this.services!.contextManager.dispatchEvent(
      WidgetEditorEvents.WIDGET_EDIT,
      { widgetGetter, widgetSetter, widgetType },
    )
  }

  /**
   * Map the size to some height
   * @param size
   */
  mapSize(size: DashboardWidgetRowSizes) {
    const map = {
      EXTRA_SMALL: '124px',
      SMALL: '174px',
      MEDIUM: '324px',
      LARGE: '474px',
      EXTRA_LARGE: '624px',
      WRAP_CONTENT: '174px',
      FILL_PAGE: '100%',
    }

    return size && map[size] ? map[size] : map[DashboardWidgetRowSizes.MEDIUM]
  }

  deleteTab(rowIndex: number, columnIndex: number, tabIndex: number): void {
    this.services!.contextManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message: i18n.tc('widgets.dashboard.delete_tab_q'),
      accept: () => {
        const dashboard = cloneData(this.data)
        const column = this.getColumn(rowIndex, columnIndex, dashboard)

        if (Array.isArray(column.widgetLookup)) {
          column.widgetLookup = column.widgetLookup.filter(
            (_, index) => index !== tabIndex,
          )
          if (column.widgetLookup.length === 1) {
            column.widgetLookup = column.widgetLookup[0]
          }
          this.updateUI(dashboard)
        }
      },
    })
  }

  /**
   * Delete row
   * @param rowIndex
   */
  deleteRow(rowIndex: number): void {
    this.services!.contextManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message: i18n.tc('widgets.dashboard.delete_row_q'),
      accept: () => {
        const dashboard = cloneData(this.data)
        dashboard.rows = dashboard.rows.filter((_, index) => index !== rowIndex)
        this.updateUI(dashboard)
      },
    })
  }

  updateUid(
    rowIndex: number,
    colIndex: number,
    tabIndex: number,
    dashboard: DashboardWidgetModel,
  ): WidgetModels {
    const widget = this.getWidget(rowIndex, colIndex, tabIndex, dashboard)
    if (!widget?.uid) return

    const newUid = this.getSlotId(rowIndex, colIndex, tabIndex, dashboard)

    return { ...widget, uid: newUid }
  }

  updateUI(dashboard: DashboardWidgetModel): void {
    const newDashboard = cloneData(this.data)

    newDashboard.rows = newDashboard.rows.map((row, rowIndex) => {
      row.columns = row.columns.map((column, colInde) => {
        if (Array.isArray(column.widgetLookup)) {
          column.widgetLookup = column.widgetLookup.map(
            (widgetLookup, tabIndex) => {
              return (
                this.updateUid(rowIndex, colInde, tabIndex, newDashboard) ??
                widgetLookup
              )
            },
          )
        } else {
          column.widgetLookup =
            this.updateUid(rowIndex, colInde, 0, newDashboard) ??
            column.widgetLookup
        }

        return column
      })

      return row
    })

    this.emitChanged(dashboard)
  }

  addRow(rowIndex: number): void {
    const dashboard = cloneData(this.data)

    const widgetLookup = {} as WidgetModels
    const row: DashboardRow = {
      size: DashboardWidgetRowSizes.SMALL,
      columns: [{ widgetLookup }],
    }

    dashboard.rows.splice(rowIndex, 0, row)

    this.updateUI(dashboard)
  }

  /**
   * Delete column
   * @param rowIndex
   * @param colIndex
   */
  deleteColumn(rowIndex: number, colIndex: number): void {
    this.services.contextManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message: i18n.tc('widgets.dashboard.delete_column_q'),
      accept: () => {
        const dashboard = cloneData(this.data)
        dashboard.rows[rowIndex].columns.splice(colIndex, 1)
        if (!dashboard.rows[rowIndex].columns.length) {
          dashboard.rows.splice(rowIndex, 1)
        }
        this.updateUI(dashboard)
      },
    })
  }

  /**
   * Column index
   * @param rowIndex
   * @param colIndex
   */
  columnExists(rowIndex: number, colIndex: number): boolean {
    return Boolean(this.getColumn(rowIndex, colIndex))
  }
  /**
   * Verifies if Dashboard has only one row and one column
   */
  hasOnlyOneRowOneCol(): boolean {
    return this.data.rows.length === 1 && this.data.rows[0].columns.length === 1
  }
}
