export const monthsDictionary = {
  '01': 'common.month.january',
  '02': 'common.month.february',
  '03': 'common.month.march',
  '04': 'common.month.april',
  '05': 'common.month.may',
  '06': 'common.month.june',
  '07': 'common.month.july',
  '08': 'common.month.august',
  '09': 'common.month.september',
  '10': 'common.month.october',
  '11': 'common.month.november',
  '12': 'common.month.december',
}

export const dayOfWeekDictionary = {
  '0': 'common.day.sunday',
  '1': 'common.day.monday',
  '2': 'common.day.tuesday',
  '3': 'common.day.wednesday',
  '4': 'common.day.thursday',
  '5': 'common.day.friday',
  '6': 'common.day.saturday',
}
