<template>
  <div class="d-flex flex-column">
    <h5 class="py-3" v-text="label" />

    <json-field :name="showPath" style="height: 2em" />

    <json-field :name="requiredPath" :disabled="!showValue" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetCustomAttributesDisplayOnForm',
  inject: ['formHook'],
  props: {
    basePath: {
      type: String,
      required: true,
    },
  },
  computed: {
    label(): string {
      return this.formHook().getFieldLabel(this.basePath)
    },
    showPath(): string {
      return `${this.basePath}.show`
    },
    requiredPath(): string {
      return `${this.basePath}.required`
    },
    showValue(): boolean {
      return !!this.formHook().getPathValue(this.showPath)
    },
  },
  watch: {
    showValue(show: boolean) {
      if (!show) this.formHook().setObjectValue(this.requiredPath, false)
    },
  },
})
</script>
