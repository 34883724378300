<template>
  <div v-if="hasToDisplayButton">
    <SetContextFiltersButton v-if="isDirty" @save="save" />
    <RemoveContextFiltersButton v-else @remove="remove" />
  </div>
</template>

<script lang="ts">
import isEqual from 'lodash/isEqual'
import Vue, { VueConstructor, PropType } from 'vue'

import SetContextFiltersButton from './SetContextFiltersButton.vue'
import RemoveContextFiltersButton from './RemoveContextFiltersButton.vue'
import { ContextManagerInterface } from '@/tt-widget-factory'
import { Filter } from '../types'

type VueWithContextManager = VueConstructor<
  Vue & { contextManager: ContextManagerInterface }
>

export default (Vue as VueWithContextManager).extend({
  name: 'WidgetContextStateEditor',
  components: {
    SetContextFiltersButton,
    RemoveContextFiltersButton,
  },
  inject: ['contextManager'],
  props: {
    monitoredAttributes: {
      type: Array as PropType<string[]>,
      default() {
        return ['dateRangeAttribute']
      },
    },
    defaultFilterValues: {
      type: Array as PropType<Filter[]>,
      default: () => [],
    },
  },
  computed: {
    isDirty(): boolean {
      return !isEqual(this.widgetDefaultsFiltered, this.filteredContextFilters)
    },
    widgetDefaultsFiltered(): Filter[] {
      return this.defaultFilterValues
        .filter((filter: Filter) => {
          return this.monitoredAttributes.includes(filter.attribute)
        })
        .sort((filter1: Filter, filter2: Filter) => {
          return filter2.attribute > filter1.attribute ? -1 : 1
        })
    },
    filteredContextFilters(): Filter[] {
      return this.modelContextFilters
        .filter((filter: Filter) => {
          return this.monitoredAttributes.includes(filter.attribute)
        })
        .sort((filter1: Filter, filter2: Filter) => {
          return filter2.attribute > filter1.attribute ? -1 : 1
        })
    },
    modelContextFilters(): Filter[] {
      return this.context.getContextFilters()
    },
    context(): ContextManagerInterface {
      return this.contextManager
    },
    hasToDisplayButton(): boolean {
      return (
        !!this.widgetDefaultsFiltered.length ||
        !!this.filteredContextFilters.length
      )
    },
  },
  methods: {
    save() {
      this.$emit('update', this.filteredContextFilters)
    },
    remove() {
      this.$emit('update', [])
    },
  },
})
</script>
