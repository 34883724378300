import dates from './dates'
import number from './numbers'
import rates from './rates'
import distances from './distances'
import {
  FormatterCallbackInterface,
  FormatterInput,
  FormatterOutput,
} from './types'
import texts from './texts'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export function numberWithCommas(x: number | string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Format manager
 */
export class FormatManager {
  static register(list: { [name: string]: FormatterCallbackInterface }) {
    for (const x in list) {
      FormatManager.addFormat(x, list[x])
    }
  }

  static hasFormat(name: string): boolean {
    const hasFormat = !!FormatManager.formats[name]
    if (!hasFormat) console.warn('format not supported:', name)
    return hasFormat
  }

  /**
   * List of formats
   */
  static formats: { [name: string]: FormatterCallbackInterface } = {}

  /**
   * Register formats
   * @param name
   * @param callback
   */
  static addFormat(name: string, callback: FormatterCallbackInterface) {
    this.formats[name] = callback
  }

  /**
   *
   * @param name
   * @param value
   * @param preferences
   */
  static parse(
    name: string,
    value: FormatterInput,
    preferences: UserPreferenceInterface,
  ): FormatterOutput {
    const callback = FormatManager.formats[name]
    if (!callback) {
      console.warn('No formatter registered with name:', name)
      return value
    }
    return callback(value, preferences)
  }
}

// Register
FormatManager.register(dates)
FormatManager.register(number)
FormatManager.register(rates)
FormatManager.register(distances)
FormatManager.register(texts)
