<template>
  <tt-attr-row-card v-bind="$props">
    <TextAreaAttr name="content" />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import TextAreaAttr from '@/tt-entity-design/src/components/TextAreaAttr.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'NotesDetail',
  components: {
    TextAreaAttr,
  },
})
</script>
