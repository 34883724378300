<template>
  <MobileSchedulesTimeline :mobile-runsheet-id="mobileRunsheetId" />
</template>

<script lang="ts">
import Vue from 'vue'
import { VueWithItemHookInjection } from '@/helpers/components/types'
import MobileSchedulesTimeline from '../mobile-schedules/MobileSchedulesTimeline.vue'
export default (Vue as VueWithItemHookInjection).extend({
  name: 'MobileRunsheetsSchedules',
  components: {
    MobileSchedulesTimeline,
  },
  inject: ['getItemHook'],
  computed: {
    mobileRunsheetId(): string {
      return this.getItemHook().getEntityId()
    },
  },
})
</script>
