<template>
  <div>
    <v-container>
      <json-field
        class="logs--status"
        name="status"
        :additional-query-options="queryWorkflowStatuses"
      />
      <json-field name="user" />
      <json-field name="createdOn" />
    </v-container>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery, FormHookProvider } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'WorkflowInstanceLogsFormCreate',
  inject: ['formHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    workflowInstanceId(): number {
      return this.itemHook.getRawValue('workflowInstance.id')
    },
    workflowId(): number {
      return this.itemHook.getRawValue('workflowInstance.workflow.id')
    },
    queryWorkflowStatuses(): Partial<CollectionQuery> {
      return {
        filters: [
          {
            value: this.workflowId,
            operator: FilterOperatorType.EQUAL,
            attribute: 'workflow',
          },
        ],
      }
    },
  },
  created() {
    this.formHook().setObjectValue('workflowInstance', this.workflowInstanceId)
  },
})
</script>
<style scoped>
.logs--status ::v-deep span {
  flex-grow: 0 !important;
}
</style>
