// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import SystemExceptionTicketInstructionsTile from './SystemExceptionTicketInstructionsTile.vue'
import SystemExceptionTicketInstructionsColumns from './SystemExceptionTicketInstructionsColumns'
import SystemExceptionTicketInstructionsDetail from './SystemExceptionTicketInstructionsDetail.vue'
import SystemExceptionTicketInstructionsMetaCard from './SystemExceptionTicketInstructionsMetaCard.vue'
import SystemExceptionTicketInstructionsReference from './SystemExceptionTicketInstructionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'SystemExceptionTicketInstructionsTile',
      SystemExceptionTicketInstructionsTile,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsDetail',
      SystemExceptionTicketInstructionsDetail,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsMetaCard',
      SystemExceptionTicketInstructionsMetaCard,
    )
    Vue.component(
      'SystemExceptionTicketInstructionsReference',
      SystemExceptionTicketInstructionsReference,
    )

    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketInstructionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: SystemExceptionTicketInstructionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketInstructionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketInstructionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'SystemExceptionTicketInstructionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
