// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BillItemsTile from './BillItemsTile.vue'
import BillItemsColumns from './BillItemsColumns'
import BillItemsDetail from './BillItemsDetail.vue'
import BillItemsReference from './BillItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BillItemsTile', BillItemsTile)
    Vue.component('BillItemsDetail', BillItemsDetail)
    Vue.component('BillItemsReference', BillItemsReference)

    modularManager.addResourcePreset(
      Resources.BILL_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BillItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILL_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BillItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILL_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BillItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILL_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BillItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
