<template>
  <div>
    <codemirror v-model="code" :options="cmOptions" style="width: 100%" />

    <v-toolbar flat dense class="toolbar1" v-if="toolbar">
      <template v-if="valid">
        <v-icon color="green">checkbox</v-icon>
      </template>
      <template v-else-if="!isEmpty">
        <v-icon color="red">warning</v-icon>
      </template>
      <div class="error--text pa-6" style="opacity: 0.5; font-size: 0.8em">
        {{ errorsAsText }}
      </div>
      <v-spacer />
      <slot name="toolbar" />
      <v-btn v-if="canClear" small class="mr-2" @click="clear">
        <span v-text="$t('common.clear.btn')" />
      </v-btn>
      <v-btn :disabled="!canApply" :dark="!canApply" small @click="emit">
        <span v-text="$t('common.apply.btn')" />
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import TQLEditorBase from '@/tt-tql-inputs/src/components/TQLEditorBase'

export default TQLEditorBase.extend({
  name: 'TqlEditor',
  props: {
    toolbar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    canClear(): false {
      return false
    },
  },
})
</script>

<style>
.CodeMirror {
  font-size: 14px;
}
</style>
