import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const GaugeChartWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'GaugeChartWidget',
  'Gauge Chart',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    dimension: DataSetWidgetSchemaConfigValue.NONE,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)
GaugeChartWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/GaugeChartWidgetOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadCsvPdf',
  },
})
GaugeChartWidgetBuilder.addDefinitions({
  GaugeChartWidgetOptions: {
    title: 'Customization Options',
    type: 'object',
    required: ['target'],
    properties: {
      target: {
        title: 'Target Value',
        description: 'The target value to reach',
        type: 'number',
      },
      color1: {
        title: 'Active Color',
        description: 'The widget color',
        $ref: '#/definitions/Color',
      },
    },
  },
})

GaugeChartWidgetBuilder.addToRequired('options')

export default GaugeChartWidgetBuilder.schema
