<template>
  <v-tooltip :disabled="isEditable" top>
    <template #activator="{ on }">
      <div v-on="on">
        <tt-tile :class="{ disabled: !isEditable }" v-bind="$props">
          <div class="d-flex align-center" :style="{ width: '100%' }">
            <div class="flex-grow-1">
              <v-list-item-title class="pb-1">
                <tt-attr name="description" />
              </v-list-item-title>
            </div>

            <ResourceAllowedOperations
              v-slot="{ allowsDelete }"
              :resource-name="resourceName"
            >
              <div v-if="isEditable && allowsDelete" class="ml-5">
                <v-btn
                  icon
                  color="level1Control"
                  :title="$t(translationKey('remove-btn'))"
                  @click.stop="confirmDelete"
                >
                  <v-icon small> mdi-delete-outline </v-icon>
                </v-btn>
              </div>
            </ResourceAllowedOperations>
          </div>
        </tt-tile>
      </div>
    </template>
    <span>{{ $t(translationKey('parent-condtion-hover')) }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

import { confirmDeleteEntity } from '../../helpers'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportConditionSetsTile',
  inject: ['getItemHook'],
  props: {
    parentId: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    entityId(): number {
      return this.getItemHook().get('id')
    },
    isInherited(): boolean {
      return this.getItemHook().get('parent.object') !== this.parentId
    },
    resourceName(): string {
      return this.getItemHook().getResourceName()
    },
    isEditable(): boolean {
      return !this.readOnly && (!this.parentId || !this.isInherited)
    },
    translationScope(): string {
      return `tt-entity-design.${this.resourceName}.tile`
    },
  },
  created() {
    this.getItemHook().addAttribute('id')
    this.getItemHook().needActions()
  },
  methods: {
    confirmDelete(): void {
      const { entityServices, eventManager } = this.$appContext
      const { persister } = entityServices

      confirmDeleteEntity(
        {
          entityId: this.entityId,
          message: this.$t(`${this.translationScope}.confirm-deletion-dialog`),
          resourceName: this.resourceName,
        },
        { eventManager, persister },
      )
    },
    translationKey(key: string): string {
      return `${this.translationScope}.${key}`
    },
  },
})
</script>
<style scoped>
.disabled {
  background: #fafafa !important;
}
.disabled >>> .entity-attribute-cell {
  color: #aaa;
}
</style>
