import { AsyncComponentPromise } from 'vue/types/options'
import { VueConstructor } from 'vue'
import {
  BaseWidgetHook,
  HookConstructor,
  WidgetManagerInterface,
  WidgetTypeDefinition,
} from './types'
import { JSONSchema7 } from '@tracktik/tt-json-schema-form'

export type WidgetTemplate =
  | VueConstructor
  | AsyncComponentPromise
  | string
  | any

export class WidgetManager implements WidgetManagerInterface {
  hooks: Map<string, HookConstructor> = new Map<string, HookConstructor>()
  templates: Map<string, WidgetTemplate> = new Map()

  widgets: WidgetTypeDefinition[] = []

  /**
   * Register a template
   * @param widgetName
   * @param templateName
   */
  private registerTemplate(widgetName: string, template: WidgetTemplate) {
    this.templates.set(widgetName, template)
  }

  /**
   *
   * @param widget
   */
  public registerWidget(widget: WidgetTypeDefinition) {
    this.widgets.push(widget)
    this.registerTemplate(widget.name, widget.component.template)
    this.registerHook(widget.name, widget.component.hook)
  }

  getWidgets(): WidgetTypeDefinition[] {
    return this.widgets
  }

  /**
   * Get a widget by name
   * @param name
   */
  getWidgetByName(name: string): WidgetTypeDefinition | undefined {
    return this.widgets.find((widget: WidgetTypeDefinition) => {
      return widget.name === name
    })
  }

  /**
   * Register a hook
   * @param widgetName
   * @param hookName
   */
  private registerHook(widgetName: string, hookName: any) {
    this.hooks.set(widgetName, hookName)
  }

  /**
   * Get the template and hook
   * @param widgetName
   */
  public getTemplate(widgetName: string) {
    if (this.templates.has(widgetName)) {
      return this.templates.get(widgetName)
    }
    return `${widgetName}NotFound`
  }

  getSchemaByName(name: string): JSONSchema7 | null {
    const widget = this.getWidgetByName(name)
    return widget?.schema ?? null
  }

  /**
   * Get the hook class name
   * @param widgetName
   */
  public getHookClassName(widgetName: string): HookConstructor {
    return this.hooks.has(widgetName)
      ? this.hooks.get(widgetName)!
      : BaseWidgetHook
  }

  public getTtcIgnoredAttributes(widgetName: string): string[] {
    return this.getWidgetByName(widgetName)?.ttcIgnoredAttrs || []
  }
}

export default WidgetManager
