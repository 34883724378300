<template>
  <v-chip small pill text-color="#405ce7" color="var(--v-ttIndigo-base)">
    {{ info }}
  </v-chip>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'EmployeeInfoChip',
  props: {
    info: {
      type: String,
      required: true,
    },
  },
})
</script>
