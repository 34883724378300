import { Attribute } from '@/tt-entity-design/src/schema-types'
import { Status } from './types'
import { Resources } from '@/tt-entity-design/src/types'

const MOBILE_RUNSHEETS_STATUS_CLASS: Record<Status, string> = {
  ACTIVE:
    'font-weight-bold text--darken-2 green--text green lighten-5 text-uppercase',
  CANCELLED:
    'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase',
}

const DEFAULT_STATUS_COLOR =
  'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase'

export const getStatusClass = (status: string) =>
  MOBILE_RUNSHEETS_STATUS_CLASS[status] || DEFAULT_STATUS_COLOR

export const getAttr = <T extends Attribute<typeof Resources.MOBILE_RUNSHEETS>>(
  attr: T,
) => attr
