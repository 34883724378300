import isNil from 'lodash/isNil'
import Mustache from 'mustache'
import { unflatten } from 'flat'

import { AuthModule } from '@tracktik/tt-authentication'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { FormHook } from '@tracktik/tt-json-schema-form'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Filter, CustomFilter } from '@/tt-widget-components'
import { RelationFilter } from '@/tt-widget-factory'

/**
 * Grab strings inside double brackets (ie: {{ my_string }})
 */
export const matchMustacheVariables = (string: string): string[] | null =>
  string.match(/[^{{]+(?=}\})/g)

/**
 * Returns true if the string contains a value inside souble brackets
 */
export const hasMustacheVariable = (string: string): boolean =>
  !!matchMustacheVariables(string)

/**
 * special variable to map the current signed-in user ID
 * @TODO: support region and account context filters
 */
const CONTEXT_ME = '$context.me'

/**
 * Variables inside relationFilter can refers either to the entity attributes, or to the current form field values.
 *
 * ```
 * {{ $form.description }} // refers to the 'description' field in the form
 * {{ $entity.id }} // refers to the current entity id
 * ```
 */
const CONTEXT_FORM = '$form'

const CONTEXT_ENTITY = '$entity'

export const isCurrentUserId = (variable) => variable === CONTEXT_ME

/**
 * In previous implementation, there was no context prefix (neither `$form` or `$entity`).
 *
 * So when there is no prefix, the variables are refering to the ENTITY ATTRIBUTES.
 *
 * We need to support this until the API is fully migrated and adds `$entity` prefix to those.
 */
const isMissingContextPrefix = (variable: string) => !variable.startsWith(`$`)

export const isEntityAttribute = (variable) =>
  variable.startsWith(CONTEXT_ENTITY) || isMissingContextPrefix(variable)

export const isFormField = (variable) => variable.startsWith(CONTEXT_FORM)

/**
 * Tracktik custom properties to OpenApi Relation schemas
 */
export const TTC_RELATION_NAME = 'x-resource'
export const TTC_RELATION_FILTER = 'x-ttc-relationFilter'

export const extractMustacheVariables = (
  relationFilters: RelationFilter,
): string[] => {
  const valueToCheck = [
    ...(relationFilters.filters || []).map((f) => f.value),
    ...(relationFilters.customFilters || []).map((f) => f.value),
    ...(relationFilters.scopes || []),
    relationFilters.whereQL || '',
  ]

  const matchedValues: string[] = valueToCheck
    .filter((v) => typeof v === 'string' && !!v)
    .map((v: string) => matchMustacheVariables(v) || [])
    .flat()

  const trimmed = matchedValues.map((v) => v.trim())
  const uniques = [...new Set(trimmed)]

  return uniques
}

type F = Filter | CustomFilter

export const replaceVariables = (
  relationFilters: RelationFilter,
  attributesMap: Record<string, any>,
): RelationFilter => {
  const UNDEFINED = `__UNDEFINED__`
  const replaceNilValueWithUndefinedString = ([key, value]) => [
    key,
    value ?? UNDEFINED,
  ]
  const attributesMapWithUndefined = Object.fromEntries(
    Object.entries(attributesMap).map(replaceNilValueWithUndefinedString),
  )
  /**
   * To make dot notation works with Mustache, we need to convert map like :
   *
   * `{ 'a.b.c': 'value' }` to `{ a: { b: { c: 'value' }}}`
   */
  const attributesMapObject = unflatten(attributesMapWithUndefined)

  const replaceMustacheVariables = (template) =>
    Mustache.render(template, attributesMapObject)

  const processValue = <T extends F>(filter: T): T => ({
    ...filter,
    value: replaceMustacheVariables(filter.value),
  })

  // if a variable value is missing, we remove the filter
  const isValidFilterValue = <T extends F>(filter: T) =>
    typeof filter.value === 'number' ||
    typeof filter.value === 'boolean' ||
    !filter.value.includes(UNDEFINED)

  const filters = relationFilters.filters
    ?.map(processValue)
    .filter(isValidFilterValue)

  const customFilters = relationFilters.customFilters
    ?.map(processValue)
    .filter(isValidFilterValue)

  const processedWhereQL = replaceMustacheVariables(
    relationFilters.whereQL || '',
  )

  /**
   * If WhereQL filter includes an undefined variables,
   * we completely removes the whereQL from the relation filters
   */
  const whereQL = processedWhereQL.includes(UNDEFINED) ? '' : processedWhereQL

  // won't contain variables
  const scopes = relationFilters.scopes
  const includeInactive = !!relationFilters.includeInactive

  return {
    filters,
    customFilters,
    scopes,
    whereQL,
    includeInactive,
  }
}

const convertCustomFilter = (customFilter: CustomFilter): Filter => ({
  attribute: customFilter.filterName,
  operator: FilterOperatorType.EQUAL,
  value: customFilter.value,
})

export const convertRelationFiltersToQueryOptions = (
  relationFilters: RelationFilter,
): EntityCollectionRequestOptions => {
  const { scopes, whereQL, includeInactive } = relationFilters

  const filters = [
    ...(relationFilters.filters || []),
    ...(relationFilters.customFilters || []).map(convertCustomFilter),
  ]

  // @ts-ignore -- type issue with filters
  return {
    ...(filters && { filters }),
    ...(scopes && { scope: scopes }), // <-- notice the different naming
    ...(whereQL && { whereQL }),
    ...(includeInactive && { includeInactive }),
  }
}

/**
 * Return the name of an entity attribute / form property without the prefix
 *
 * `$form.propertyName` -> `propertyName`
 *
 * `$entity.attributeName` -> `attributeName`
 */
export const removeVariablePrefix = (mustacheVariable: string) => {
  if (isFormField(mustacheVariable))
    return mustacheVariable.replace(`${CONTEXT_FORM}.`, ``)

  if (isEntityAttribute(mustacheVariable))
    return mustacheVariable.replace(`${CONTEXT_ENTITY}.`, ``)

  return mustacheVariable
}

export const getEntityAttributeValue = (
  entityAttributeName: string,
  { itemHook }: { itemHook: EntityItemHook },
): unknown => itemHook.getRawValue(entityAttributeName)

export const getFormValue = (
  formFieldName: string,
  namespace: string,
  { formHook }: { formHook: FormHook },
): unknown => {
  const path = [namespace, formFieldName].filter(Boolean).join('.')
  return formHook.getPathValue(path)
}

export const isActionForm = (formHook: FormHook): boolean =>
  !!formHook.getUserContextValue('action')

export const getVariableValue = (
  mustacheVariable: string,
  {
    authModule,
    formHook,
    itemHook,
    namespace,
  }: {
    authModule: AuthModule
    formHook: FormHook
    itemHook: EntityItemHook
    namespace: string
  },
): unknown => {
  // special variables
  if (isCurrentUserId(mustacheVariable)) return authModule.getUserId()

  if (isFormField(mustacheVariable)) {
    const fieldName = removeVariablePrefix(mustacheVariable)
    return getFormValue(fieldName, namespace, { formHook })
  }

  if (isEntityAttribute(mustacheVariable)) {
    const attributeName = removeVariablePrefix(mustacheVariable)
    return getEntityAttributeValue(attributeName, { itemHook })
  }

  /**
   * Fallback to old behaviour to stay retro-compatible (no `$form` or `$entity` prefix).
   * To be removed after this API ticket is deployed : https://tracktik.atlassian.net/browse/API-2089
   */
  return isActionForm(formHook)
    ? // for action forms, we map values against the API entity
      getEntityAttributeValue(mustacheVariable, { itemHook })
    : // for create / edit forms, we map values against the current form fields
      getFormValue(mustacheVariable, namespace, { formHook })
}

export const getVariableValuesMap = (
  relationFilters: RelationFilter,
  dependencies: {
    authModule: AuthModule
    formHook: FormHook
    itemHook: EntityItemHook
    namespace: string
  },
): Record<string, unknown> => {
  const createMapEntries = (fieldName) => [
    fieldName,
    getVariableValue(fieldName, dependencies),
  ]

  return Object.fromEntries(
    extractMustacheVariables(relationFilters).map(createMapEntries),
  )
}

/**
 * Missing entity values (ie: `$entity.id`) won't prevent the UI from sending
 * the request, thus we remove them from the relation filters.
 *
 * The UI will only prevent the request if a `$form.property` value is missing.
 */
export const getMissingVariableValues = (
  relationFilters: RelationFilter,
  dependencies: {
    authModule: AuthModule
    formHook: FormHook
    itemHook: EntityItemHook
    namespace: string
  },
): string[] => {
  const isNotSpecialVariable = ([mustacheVariable, _]: [string, unknown]) =>
    !isCurrentUserId(mustacheVariable) && !isEntityAttribute(mustacheVariable)

  const valueIsMissing = ([_, value]) => isNil(value)

  return Object.entries(getVariableValuesMap(relationFilters, dependencies))
    .filter(isNotSpecialVariable)
    .filter(valueIsMissing)
    .map(([mustacheVariable]) => mustacheVariable)
}
