import Vue from 'vue'
import MenuQuickAddExtension from './MenuQuickAddExtension.vue'
import {
  ExtensionInterface,
  LayoutExtensionSlots,
  modularManager,
} from '@/tt-app-modular'

Vue.component('MenuQuickAddExtension', MenuQuickAddExtension)

export const MENU_QUICK_ADD_EXTENSION = 'extension.menu.quick-add.tracktik.com'

/**
 * Define the user extension
 */
export default {
  name: MENU_QUICK_ADD_EXTENSION,
  component: {
    is: 'MenuQuickAddExtension',
  },
  items: [],
  slots: [LayoutExtensionSlots.TOOLBAR_LEFT],
  enabled: (extension: ExtensionInterface) => {
    return modularManager.hasItems(extension.name)
  },
} as ExtensionInterface
