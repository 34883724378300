// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ReportConditionSetActionsTile from './ReportConditionSetActionsTile.vue'
import ReportConditionSetActionsColumns from './ReportConditionSetActionsColumns'
import ReportConditionSetActionsDetail from './ReportConditionSetActionsDetail.vue'
import ReportConditionSetActionsReference from './ReportConditionSetActionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ReportConditionSetActionsTile',
      ReportConditionSetActionsTile,
    )
    Vue.component(
      'ReportConditionSetActionsDetail',
      ReportConditionSetActionsDetail,
    )
    Vue.component(
      'ReportConditionSetActionsReference',
      ReportConditionSetActionsReference,
    )

    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SET_ACTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetActionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SET_ACTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ReportConditionSetActionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SET_ACTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetActionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.REPORT_CONDITION_SET_ACTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ReportConditionSetActionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
