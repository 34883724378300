import {OperandTypes} from "@/tt-tql-inputs/src/lang/parser";
import {default as tqlLang} from "@/tt-tql-inputs/src/lang/lang.json";
export const lang = tqlLang
export const relativeDates = tqlLang.syntax.constants

export const aggregationFunctions = [
  'AVG',
  'COUNT',
  'DISTINCT',
  'SUM',
  'MAX',
  'MIN',
  'GROUP_CONCAT',
  'BIT_AND',//	Return bitwise AND
  'BIT_OR', //	Return bitwise OR
  'BIT_XOR', //	Return bitwise XOR
  'COUNT', //	Return a count of the number of rows returned
  'JSON_ARRAYAGG', //	Return result set as a single JSON array
  'JSON_OBJECTAGG', //	Return result set as a single JSON object
  'MAX', //	Return the maximum value
  'MIN', //	Return the minimum value
  'STD', //	Return the population standard deviation
  'STDDEV', //	Return the population standard deviation
  'STDDEV_POP', //	Return the population standard deviation
  'STDDEV_SAMP', //	Return the sample standard deviation
  'SUM', //	Return the sum
  'VAR_POP', //	Return the population standard variance
  'VAR_SAMP', //	Return the sample variance
  'VARIANCE', //
]

export const unsupportedOperandTypes = [
  OperandTypes.SubQuery,
  OperandTypes.InSubQueryPredicate,
  OperandTypes.ComparisonSubQueryBooleanPrimary
]

export const allowedFunctions = tqlLang.syntax.functions
