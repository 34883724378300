// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import WorkflowInstancesTile from './WorkflowInstancesTile.vue'
import WorkflowInstancesColumns from './WorkflowInstancesColumns'
import WorkflowInstancesDetail from './WorkflowInstancesDetail.vue'
import WorkflowInstancesReference from './WorkflowInstancesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('WorkflowInstancesTile', WorkflowInstancesTile)
    Vue.component('WorkflowInstancesDetail', WorkflowInstancesDetail)
    Vue.component('WorkflowInstancesReference', WorkflowInstancesReference)

    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'WorkflowInstancesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: WorkflowInstancesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'WorkflowInstancesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.WORKFLOW_INSTANCES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'WorkflowInstancesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
