import Vue, { CreateElement, VueConstructor } from 'vue'
import { EventLifeCycleManager } from '@/tt-widget-entity-flow/EventLifeCycleManager'
import {
  EntityAggregationDetailIntent,
  EntityAggregationIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { ActiveWindow, LayoutWindowEvent } from '@/tt-app-layout'
import { ActiveWindowProvider } from '@/tt-app-layout'
import { ContextAttributeMap } from '../base/contextAttributeMap'

export type VueWithInjections = VueConstructor<Vue & ActiveWindowProvider>
export default (Vue as VueWithInjections).extend({
  name: 'AppWindowAggregationClickSubscriber',
  inject: {
    activeWindow: { default: null },
  },
  data() {
    return {
      eventManager: null as null | EventLifeCycleManager,
    }
  },
  beforeDestroy(): void {
    if (this.eventManager) {
      this.eventManager.destroy()
    }
  },
  created: function (): void {
    const disabledContextFilters: ContextAttributeMap = {
      accountAttribute: false,
      dateRangeAttribute: false,
      regionAttribute: false,
    }
    this.eventManager = new EventLifeCycleManager(this.$eventManager)
    this.eventManager.subscribeTo(
      EntityIntentTypes.AGGREGATION_DETAILS,
      (payload: EntityAggregationIntentInterface) => {
        const window =
          this.activeWindow ?? (this.$parent as unknown as ActiveWindow)
        window.next(
          EntityAggregationDetailIntent.getPage({
            aggregationContext: payload.aggregationContext,
            query: {
              ...payload.query,
              contextFilters: disabledContextFilters,
            },
          }),
        )
        this.closeSidePanel()
      },
    )
  },
  methods: {
    closeSidePanel(): void {
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.SIDE_SHEET_CLOSE,
        () => ({}),
      )
    },
  },
  render(h: CreateElement) {
    return h('span')
  },
})
