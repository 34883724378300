import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'postOrder.subject.name',
  {
    attributeName: 'status',
    component: { is: 'ChipLabelText', props: { name: 'status' } },
  },
  'employee',
  'acknowledgedOn',
] as ColumnInputDefinition[]
