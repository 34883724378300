<template>
  <div class="d-flex align-center text-truncated">
    <tt-attr name="name" :class="[cssClass, 'tt-asset-attr-name']" />
    <tt-if-attr name="isImported" :value="true">
      <v-tooltip :open-delay="tooltipOpenDelay" :nudge-top="tooltipNudge" top>
        <template #activator="{ on, attrs }">
          <v-icon
            :size="iconSize"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            v-text="`$importIcon`"
          />
        </template>
        <span v-text="$t('common.imported')" />
      </v-tooltip>
    </tt-if-attr>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TOOLTIP_OPEN_DELAY, TOOLTIP_NUDGE } from '@/helpers/constants'

export default Vue.extend({
  name: 'AssetNameImportedAttr',
  props: {
    cssClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    tooltipNudge(): number {
      return TOOLTIP_NUDGE
    },
  },
})
</script>

<style lang="scss" scoped>
.tt-asset-attr-name {
  max-width: 100%;
}
</style>
