<template>
  <span>
    <v-icon v-if="hasValue" color="green"> mdi-check-circle </v-icon>
  </span>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { EntityItemHook } from '../EntityItemHook'

type VueWithInjections = VueConstructor<
  Vue & {
    itemHook: EntityItemHook
    getItemHook: () => EntityItemHook
    relationPrefix: string
  }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityCheckbox',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    name: { type: String, required: true },
  },
  computed: {
    hasValue(): boolean {
      return !!this.getItemHook().getRawValue(this.name)
    },
  },
  mounted() {
    this.getItemHook().addAttribute(this.name)
  },
})
</script>
