import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export const EmployeePayRateColumns: ColumnInputDefinition[] = [
  {
    attributeName: 'employmentProfile.employee.name',
    headerText: 'res.employee-account-assignments.attr.employee.label',
  },
  { attributeName: 'hourlyRateType' },
  { attributeName: 'effectiveDate' },
  { attributeName: 'hourlyRate' },
]
