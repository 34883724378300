import { BaseWidgetHook } from '@/tt-widget-factory/types'
import { VimeoWidgetModel } from '@/tt-widget-components'

export default class VimeoWidgetHook extends BaseWidgetHook<VimeoWidgetModel> {
  hasPermission(): boolean {
    return true
  }

  get url(): string {
    const url = this.state.widget.url

    const match = /\d+/gm.exec(url)

    if (!url || !match) {
      return null
    }

    return `https://player.vimeo.com/video/${match[0]}`
  }
}
