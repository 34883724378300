// Enter your custom installation here
import SiteTaskOccurrenceSessionLogType from './SiteTaskOccurrenceSessionLogType.vue'

export default {
  install(Vue) {
    Vue.component(
      'SiteTaskOccurrenceSessionLogType',
      SiteTaskOccurrenceSessionLogType,
    )
  },
}
