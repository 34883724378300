import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'payrollCode.code',
    component: {
      is: 'ChipLabelText',
      props: {
        name: 'payrollCode.code',
        backgroundColor: 'indigo darken-2',
        textColor: 'white',
      },
    },
  },
  'employee',
  'account',
  'rate',
  'eventDate',
] as ColumnInputDefinition[]
