// Enter your custom installation here

import AssetSuppliersForm from '@/tt-entity-design/src/components/asset-suppliers/AssetSuppliersForm.vue'
import {
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

export default {
  install(Vue) {
    Vue.component('AssetSuppliersForm', AssetSuppliersForm)
    setIconAndColorResourcePreset(
      Resources.ASSET_SUPPLIERS,
      'mdi-cart-arrow-right',
      'red',
    )

    registerResourceForm(Resources.ASSET_SUPPLIERS, 'AssetSuppliersForm')
  },
}
