<template>
  <div>
    <WidgetFactory :widget="widget" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import {
  CollectionQuery,
  ListWidgetModel,
  RelationListResource,
} from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { CheckpointsAttr } from './CheckpointTourSessionsRelationListPreview.vue'

export default Vue.extend({
  name: 'CheckpointTourSessionsRelationList',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
  },
  computed: {
    relationListResource(): RelationListResource {
      return {
        resource: this.resourceName,
        id: this.entityId,
        attribute: this.entity,
      }
    },
    relationResourceName(): string | undefined {
      return this.$appContext.widgetServices.resourceMetaManager.getAttribute(
        this.resourceName,
        this.entity,
      )?.relation?.resource
    },
    performedBy(): number {
      return this.itemHook.getRawValue('performedBy.id')
    },
    query(): CollectionQuery {
      const filters =
        this.entity === CheckpointsAttr.CHECKPOINTS_SCANS
          ? [
              {
                attribute: 'scannedBy',
                operator: FilterOperatorType.EQUAL,
                value: this.performedBy,
              },
            ]
          : []

      return {
        resource: this.relationResourceName,
        relationListResource: this.relationListResource,
        filters,
      }
    },
    widget(): ListWidgetModel {
      return {
        title: '',
        is: 'ListWidget',
        query: this.query,
      }
    },
  },
})
</script>
