<template>
  <div class="main-wrapper">
    <template v-if="itemHook.hasFetched">
      <json-field name="sendToPerson" class="" />

      <v-divider />

      <v-tabs v-model="activeTab" fixed-tabs show-arrows>
        <v-tab v-for="({ title, key }, index) in tabs" :key="key">
          <span
            style="font-size: 0.9em"
            :style="activeTab !== index ? 'opacity: 0.7;' : ''"
            v-text="title"
          />

          <v-chip
            x-small
            color="primary"
            class="ml-1"
            :disabled="activeTab !== index"
            :outlined="activeTab !== index"
            v-text="getFormRecipientsCount(key)"
          />
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" style="height: 400px">
        <v-tab-item
          v-for="{ widget, key } in tabs"
          :key="key"
          class="fill-height"
        >
          <WidgetFactory
            :widget="widget"
            :selected-records="getFormRecipients(key)"
            @selection-updated="setFormRecipients(key, $event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-else>
      <v-skeleton-loader type="heading" class="pb-6" />
      <v-skeleton-loader type="image" style="height: 400px" />
    </template>

    <!-- active users disclaimer -->
    <div class="mt-4">
      <v-icon class="mr-1" small v-text="'mdi-information-outline'" />
      <span
        class="text-caption"
        v-text="
          $t(
            'tt-entity-design.asset-hub.asset-notifications.valid-users-disclaimer',
          )
        "
      />
    </div>
    <!-- / active users disclaimer -->
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import {
  Tab,
  ACTION_RECIPIENTS,
  createWidget,
  FormRelationList,
  FORM_KEYS,
  FormKey,
  EXTENSION_RECIPIENTS,
} from './FilterRuleSetAssetsRecipients.util'

import { Resources } from '../../types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'FilterRuleSetAssetsRecipients',
  inject: ['formHook'],
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    entityAccount(): string {
      return this.itemHook.getRawValue('account')
    },
    tabs(): Tab[] {
      const getLabel = (key: string) =>
        this.$t(
          `res.${Resources.FILTER_RULE_SET_ASSETS}.actions.${ACTION_RECIPIENTS}.attr.${key}.label`,
        )

      const contactAndUserFields = ['firstName', 'lastName']
      const employeeFields = [
        'employee.avatar',
        'employee.firstName',
        'employee.lastName',
      ]
      const assetPeopleFields = ['firstName', 'lastName']

      return [
        {
          key: 'siteContacts',
          title: getLabel('siteContacts'),
          widget: createWidget(Resources.ASSET_CONTACTS, contactAndUserFields, [
            {
              filterName: 'forAccount',
              value: this.entityAccount,
            },
            {
              filterName: 'type',
              value: 'SITE-CONTACTS',
            },
            {
              filterName: 'forNotificationByAccount',
              value: this.entityAccount,
            },
          ]),
        },
        {
          key: 'siteEmployees',
          title: getLabel('siteEmployees'),
          widget: createWidget(
            Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
            employeeFields,
            [
              {
                filterName: 'forNotificationByAccount',
                value: this.entityAccount,
              },
            ],
          ),
        },
        {
          key: 'siteClients',
          title: getLabel('siteClients'),
          widget: createWidget(Resources.CLIENT_USERS, contactAndUserFields, [
            {
              filterName: 'forNotificationByAccount',
              value: this.entityAccount,
            },
          ]),
        },
        {
          key: 'zoneEmployees',
          title: getLabel('zoneEmployees'),
          widget: createWidget(
            Resources.EMPLOYEE_ACCOUNT_ASSIGNMENTS,
            employeeFields,
            [
              {
                filterName: 'forNotificationInZonesByAccount',
                value: this.entityAccount,
              },
            ],
          ),
        },
        {
          key: 'assetPersons',
          title: getLabel('assetPersons'),
          widget: createWidget(Resources.ASSET_PERSONS, assetPeopleFields, [
            {
              filterName: 'isImported',
              value: '0',
            },
          ]),
        },
      ]
    },
  },
  watch: {
    'itemHook.hasFetched': {
      handler(hasFetched) {
        if (hasFetched) {
          FORM_KEYS.forEach((key) => this.setFormWithExtensionData(key))
        }
      },
    },
  },
  created() {
    this.itemHook.addAttribute('account')
    this.itemHook.addAttribute(`extensions.${EXTENSION_RECIPIENTS}`)
  },
  methods: {
    setFormWithExtensionData(extensionPath: FormKey) {
      const entityValue = this.itemHook.getRawValue(
        `recipients.${extensionPath}`,
      )
      const getDefaultFormValue = () =>
        this.formHook().getPathValue(extensionPath)

      const newValue = entityValue ?? getDefaultFormValue()

      this.formHook().setObjectValue(extensionPath, newValue)
    },
    getFormRecipients(name: FormRelationList): number[] {
      return this.formHook().getPathValue(name)
    },
    getFormRecipientsCount(name: FormRelationList): number {
      return this.getFormRecipients(name)?.length || 0
    },
    setFormRecipients(name: FormRelationList, value: number[]) {
      this.formHook().setObjectValue(name, value || [])
    },
  },
})
</script>

<style scoped>
.main-wrapper {
  min-height: 400px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
}
</style>
