// Enter your custom installation here
import SystemExceptionTicketsRelationAttr from './SystemExceptionTicketsRelationAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'
import SystemExceptionTicketsActionBar from './SystemExceptionTicketsActionBar.vue'
import SystemExceptionTicketsNotesListWidget from './notes/SystemExceptionTicketsNotesListWidget.vue'
import { modularManager } from '@/tt-app-modular'
import { Resources } from '../../types'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import SystemExceptionTicketsContactsListWidget from '@/tt-entity-design/src/components/system-exception-tickets/contacts/SystemExceptionTicketsContactsListWidget.vue'
import i18n from '@/plugins/i18n'

const SYSTEM_EXCEPTION_TICKETS = Resources.SYSTEM_EXCEPTION_TICKETS

export default {
  install(Vue) {
    Vue.component(
      'SystemExceptionTicketsRelationAttr',
      SystemExceptionTicketsRelationAttr,
    )
    Vue.component(
      'SystemExceptionTicketsStatusAttr',
      SystemExceptionTicketsStatusAttr,
    )
    Vue.component(
      'SystemExceptionTicketsActionBar',
      SystemExceptionTicketsActionBar,
    )

    modularManager.registerActionBar(SYSTEM_EXCEPTION_TICKETS, {
      is: SystemExceptionTicketsActionBar,
    })

    addPreviewTab(SYSTEM_EXCEPTION_TICKETS, {
      title: createI18nResourceKey(
        Resources.SYSTEM_EXCEPTION_TICKETS,
        'comments',
      ),
      is: SystemExceptionTicketsNotesListWidget,
    })

    addPreviewTab(SYSTEM_EXCEPTION_TICKETS, {
      title: i18n.t('tt-entity-design.system-exception-tickets.contacts.title'),
      is: SystemExceptionTicketsContactsListWidget,
    })
  },
}
