// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeClassPaySettingsTile from './EmployeeClassPaySettingsTile.vue'
import EmployeeClassPaySettingsColumns from './EmployeeClassPaySettingsColumns'
import EmployeeClassPaySettingsDetail from './EmployeeClassPaySettingsDetail.vue'
import EmployeeClassPaySettingsMetaCard from './EmployeeClassPaySettingsMetaCard.vue'
import EmployeeClassPaySettingsReference from './EmployeeClassPaySettingsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeClassPaySettingsTile', EmployeeClassPaySettingsTile)
    Vue.component(
      'EmployeeClassPaySettingsDetail',
      EmployeeClassPaySettingsDetail,
    )
    Vue.component(
      'EmployeeClassPaySettingsMetaCard',
      EmployeeClassPaySettingsMetaCard,
    )
    Vue.component(
      'EmployeeClassPaySettingsReference',
      EmployeeClassPaySettingsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASS_PAY_SETTINGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeClassPaySettingsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASS_PAY_SETTINGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeClassPaySettingsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASS_PAY_SETTINGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeClassPaySettingsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASS_PAY_SETTINGS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeClassPaySettingsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_CLASS_PAY_SETTINGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeClassPaySettingsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
