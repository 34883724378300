<template>
  <tt-attr-row name="id" label="tt-entity-design.file">
    <EntityDocumentButton :document="getFile" />
  </tt-attr-row>
</template>

<script lang="ts">
import { DocumentPresetInterface } from '@/tt-app-modular'
import { DocumentInput } from '@/tt-entity-documents/types'
import EntityDocumentButton from '@/tt-widget-entity-flow/components/EntityDocumentButton.vue'
import Vue from 'vue'
import WorkSession from './WorkSessionDoc'

export default Vue.extend({
  name: 'WorkSessionDetailFile',
  components: { EntityDocumentButton },
  computed: {
    getFile(): DocumentPresetInterface<DocumentInput> {
      return WorkSession
    },
  },
})
</script>
