import {
  FilterOperatorType,
  ResourceDataManagerInterface,
} from '@/tt-widget-factory'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { Resources } from '@/tt-entity-design/src/types'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

type Vendor = {
  id: number
  name: string
}

type Site = {
  id: number
}

type SiteVendor = {
  site: Site
  vendor: Vendor
  priority: number
}

export const fetchSiteVendors = async (
  clientId: number,
  {
    resourceDataManager,
  }: { resourceDataManager: ResourceDataManagerInterface },
): Promise<SiteVendor[]> => {
  const filters = [
    {
      attribute: 'site',
      operator: FilterOperatorType.EQUAL,
      value: clientId,
    },
  ]
  const response = await resourceDataManager.getCollection(
    {
      resource: Resources.SITE_VENDORS,
      filters,
      fields: [
        {
          attribute: 'vendor.id',
        },
        {
          attribute: 'vendor.name',
        },
        {
          attribute: 'priority',
        },
      ],
      limit: TTC_API_MAX_LIMIT,
      sort: [
        {
          direction: SortDirectionType.ASC,
          attribute: 'priority',
        },
      ],
    },
    {
      disableCache: true,
    },
  )

  return response.items
}
