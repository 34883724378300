<template>
  <span class="d-flex flex-row mb-6">
    <v-autocomplete
      :data-cy="baseLabel"
      outlined
      dense
      :value="model"
      :items="resources"
      :placeholder="$t('widget_edit.data_source.placeholder')"
      clearable
      :error-messages="errorMessages"
      :label="effectiveLabel"
      :rules="rules"
      :attach="true"
      :filter="customSearchFilter"
      @input="onChange"
    >
      <template #item="data">
        <component :is="itemView" :item="data.item.value" />
      </template>
      <template #selection="data">
        <v-tooltip right nudge-left="20" color="transparent">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" v-text="data.item.text" />
          </template>
          <v-chip x-small
            ><span class="caption" v-text="$t(data.item.value)"
          /></v-chip>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          class="info-icon mb-6 ml-1"
          v-on="on"
          v-text="'mdi-information-outline'"
        ></v-icon>
      </template>
      <span v-if="isSmallViewport">{{ $t('widget_edit.info.mobile') }}</span>
      <span v-else>{{ $t('widget_edit.info') }}</span>
    </v-tooltip>
  </span>
</template>

<script lang="ts">
import set from 'lodash/set'
import { VueConstructor } from 'vue'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'

import BaseInput from './BaseInput'
import ResourceSelectorItem from './ResourceSelectorItem.vue'
import {
  customSearchFilter,
  getResourceName,
  ResourceItemBasic,
} from '../helpers'
import { FormHookProvider, NamespaceProvider } from '../types'

type ResourceSelectorType = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider & NamespaceProvider
>

export default (BaseInput as ResourceSelectorType).extend({
  name: 'ResourceSelector',
  components: {
    ResourceSelectorItem,
  },
  inject: ['formHook', 'namespace'],
  props: {
    itemView: {
      type: String,
      default: 'ResourceSelectorItem',
    },
  },
  computed: {
    baseLabel(): string {
      return this.$t('widget_edit.data_source.label')
    },
    effectiveLabel(): string {
      return this.formHook().getFieldLabel(this.name, this.baseLabel)
    },
    resources(): ResourceItemBasic[] {
      return ResourcePermissionAuditor.getViewableRootResources(
        getResourcePermissionAuditorServices(this.$appContext),
      ).map((resourceName: string) => ({
        text: getResourceName(this.$appContext, resourceName),
        value: resourceName,
      }))
    },
    isSmallViewport(): boolean {
      return this.$vuetify.breakpoint.smAndDown ? true : false
    },
    rules(): ((val) => boolean | string)[] {
      return [
        (val): boolean | string => {
          if (!val || val.length === 0) {
            return this.$t(
              'components.resource_selector.is_required_property',
            ) as string
          }

          return true
        },
      ]
    },
  },
  methods: {
    customSearchFilter,
    onChange(newResource: ResourceItemBasic) {
      // Clean widget and keep only the resource and default values
      const { is, uid, title, description } = this.formHook().value
      const newWidget = { is, uid, title, description }

      // @todo: should be using `namespace` instead of `name`, but namespace is "" for some reason
      const resourcePath = this.name

      set(newWidget, resourcePath, newResource)

      this.formHook().setValue(newWidget)
    },
  },
})
</script>

<style scoped>
.info-icon {
  cursor: pointer;
}
</style>
