// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractOccurrenceLogsTile from './ContractOccurrenceLogsTile.vue'
import ContractOccurrenceLogsColumns from './ContractOccurrenceLogsColumns'
import ContractOccurrenceLogsDetail from './ContractOccurrenceLogsDetail.vue'
import ContractOccurrenceLogsReference from './ContractOccurrenceLogsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ContractOccurrenceLogsTile', ContractOccurrenceLogsTile)
    Vue.component('ContractOccurrenceLogsDetail', ContractOccurrenceLogsDetail)
    Vue.component(
      'ContractOccurrenceLogsReference',
      ContractOccurrenceLogsReference,
    )

    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCE_LOGS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractOccurrenceLogsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCE_LOGS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractOccurrenceLogsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCE_LOGS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractOccurrenceLogsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_OCCURRENCE_LOGS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractOccurrenceLogsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
