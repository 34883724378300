<template>
  <v-card class="relations-card d-flex align-center pa-2">
    <EntityTilePreview :resource-name="relationType" :entity-id="entityId" />
  </v-card>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import EntityTilePreview from '@/tt-widget-entity-flow/components/EntityTilePreview.vue'

export default (Vue as VueConstructor<Vue>).extend({
  name: 'SystemExceptionTicketsRelationsCard',
  components: {
    EntityTilePreview,
  },
  props: {
    relationType: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
  },
})
</script>
<style scoped>
.relations-card {
  position: relative;
  width: 100%;
  min-width: 250px !important;
}
</style>
