<template>
  <EntityItem :entity-id="data.id" :entity="data" :resource-name="resourceName">
    <tt-tile>
      <div class="d-flex align-center flex-grow-1" :style="{ width: '100%' }">
        <div class="flex-grow-1">
          <tt-attr class="font-weight-bold text-body-2" name="label" />
        </div>
        <div class="flex-grow-1">
          <div class="d-flex align-center justify-end" v-if="data.isActive">
            <div class="text-center">
              <div
                class="time-label text-secondary"
                v-text="
                  $t(
                    'tt-entity-design.site-task-schedules.time-adjustments-tile.from',
                  )
                "
              />
              <tt-attr class="time-value text-h6" name="rangeStartTime" />
            </div>
            <div class="mx-3">-</div>
            <div class="text-center">
              <div
                class="time-label text-secondary"
                v-text="
                  $t(
                    'tt-entity-design.site-task-schedules.time-adjustments-tile.to',
                  )
                "
              />
              <tt-attr class="time-value text-h6" name="rangeEndTime" />
            </div>
          </div>
          <div class="d-flex align-center justify-end" v-else>
            <span
              class="text-body-2"
              v-text="
                $t(
                  'tt-entity-design.site-task-schedules.time-adjustments-tile.cancelled',
                )
              "
            />
          </div>
        </div>
      </div>
    </tt-tile>
  </EntityItem>
</template>

<script lang="ts">
import Vue from 'vue'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'SiteTaskSchedulesTimeAdjusmentsTile',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    resourceName() {
      // @ts-ignore
      return Resources.SITE_TASK_SCHEDULE_TIMINGS
    },
  },
})
</script>
