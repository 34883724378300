import { TrackTikThemeKey } from '@/plugins/vuetify-plugin'

/**
 *  ChipLabelBoolean
 */

export const DefaultTruthy: ChipLabelBooleanConfig = {
  textColor: 'white',
  themeKey: 'success',
  textKey: 'common.friendly-booleans.true',
}

export const DefaultFalsy: ChipLabelBooleanConfig = {
  themeKey: 'muted',
  textKey: 'common.friendly-booleans.false',
}

export interface ChipLabelBooleanConfig {
  textKey?: string
  textColor?: string
  backgroundColor?: string
  themeKey: TrackTikThemeKey
}

export type Modifier = { [key: string]: string }

export enum TChipLabelType {
  Label = 'LABEL',
  Pill = 'PILL',
}

export enum AlertType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type AlertConfig = {
  background: string
  text: string
  icon: string
}

export const AlertTypes: Record<AlertType, AlertConfig> = {
  [AlertType.SUCCESS]: {
    background: '#DEF9E4',
    text: '#0E8129',
    icon: 'mdi-checkbox-marked-circle-outline',
  },
  [AlertType.INFO]: {
    background: '#E9EAFF',
    text: '#405CE7',
    icon: 'mdi-information-outline',
  },
  [AlertType.WARNING]: {
    background: '#FFEFE1',
    text: '#DB6A00',
    icon: 'mdi-alert',
  },
  [AlertType.ERROR]: {
    background: '#FFE4E9',
    text: '#D01131',
    icon: 'mdi-alert-circle-outline',
  },
}

export type PriorityConfig = {
  value: string
  icon: string
  color: string
}

export const DispatchTasksPriorityTypes: Record<string, PriorityConfig> = {
  URGENT: {
    value: 'res.task-types.attr.priority.list.URGENT.label',
    icon: 'mdi-chevron-double-up',
    color: '#D61A00',
  },
  HIGH: {
    value: 'res.task-types.attr.priority.list.HIGH.label',
    icon: 'mdi-chevron-up',
    color: '#F7B32B',
  },
  IMPORTANT: {
    value: 'res.task-types.attr.priority.list.IMPORTANT.label',
    icon: 'mdi-chevron-up',
    color: '#F7B32B',
  },
  LOW: {
    value: 'res.task-types.attr.priority.list.LOW.label',
    icon: 'mdi-chevron-down',
    color: '#038764',
  },
}

export type StatusColorType = {
  background: string
  text: string
}
