<template>
  <div>
    <v-menu
      v-if="hasAssignedGroupResource"
      :disabled="onHoverDisabled"
      open-on-hover
      bottom
      offset-y
    >
      <template #activator="{ on }">
        <div class="d-inline text-truncate" v-on="on">
          <v-icon
            x-small
            style="font-size: 13px; opacity: 0.8"
            :color="prependIconData.color"
            class="mr-1"
            v-text="prependIconData.icon"
          />
          <span
            class="assigned-name align-baseline"
            style="cursor: pointer"
            @click.stop="openPreview"
            v-text="assignedGroupResourceName"
          />
        </div>
      </template>
      <DispatchTasksPositionCard
        v-if="
          assignedGroupResourceTypeValue === assignedGroupResourceType.POSITION
        "
      />
      <DispatchTasksAddressCard
        v-if="hasAddress(assignedGroupResourceTypeValue)"
        :client-name="assignedGroupResourceName"
        :client-custom-id="clientCustomId"
        icon-address="mdi-map-marker-outline"
        :address="address"
      />
    </v-menu>
    <TChipNoValue v-else style="width: fit-content" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { ActiveWindowProvider, LayoutComponents } from '@/tt-app-layout'
import { AssignedGroupResourceTypeEnums } from './types'
import {
  ADDRESS_ATTRIBUTE_LIST,
  DEFAULT_ATTRIBUTE_LIST,
  POSITION_ATTRIBUTE_LIST,
  defaultIconData,
  iconDictionnary,
} from './constants'
import DispatchTasksPositionCard from './cards/DispatchTasksPositionCard.vue'
import DispatchTasksAddressCard from './cards/DispatchTasksAddressCard.vue'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActiveWindowProvider>
).extend({
  name: 'DispatchTasksAssignmentGroupResourceAttr',
  components: {
    DispatchTasksPositionCard,
    DispatchTasksAddressCard,
  },
  inject: {
    activeWindow: { default: null },
    getItemHook: {},
  },
  props: {
    onHoverDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prependIconData(): { color: string; icon: string } {
      return (
        iconDictionnary[this.assignedGroupResourceTypeValue] || defaultIconData
      )
    },
    assignedGroupResourceName(): string {
      return this.getItemHook().getRawValue('assignedGroupResource.object.name')
    },
    assignedGroupResourceTypeValue(): string {
      return this.getItemHook().getRawValue('assignedGroupResource.type')
    },
    assignedGroupResourceType(): typeof AssignedGroupResourceTypeEnums {
      return AssignedGroupResourceTypeEnums
    },
    hasAssignedGroupResource(): boolean {
      return !!this.entityId
    },
    isSideSheetChild(): boolean {
      return this.activeWindow?.name === LayoutComponents.sideSheet
    },
    entityId(): number | null {
      return (
        this.getItemHook().getRawValue('assignedGroupResource.object.id') ||
        null
      )
    },
    cityState(): string {
      const value = [
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.city`,
        ),
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.state`,
        ),
      ]

      return value.filter(Boolean).join(', ')
    },
    address(): string[] {
      return [
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.addressLine1`,
        ),
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.addressLine2`,
        ),
        this.cityState,
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.postalCode`,
        ),
        this.getItemHook().getRawValue(
          `assignedGroupResource.object.address.country`,
        ),
      ].filter(Boolean)
    },
    clientCustomId(): number | string {
      return (
        this.getItemHook().getRawValue(
          'assignedGroupResource.object.customId',
        ) || this.entityId
      )
    },
  },
  created() {
    this.addAssignedGroupResourceAttribute()
  },
  methods: {
    hasAddress(type): boolean {
      return (
        type === AssignedGroupResourceTypeEnums.ZONE ||
        type === AssignedGroupResourceTypeEnums.ACCOUNT
      )
    },
    openPreview(): void {
      if (!this.entityId) return

      this.$eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
        activeWindow: this.activeWindow,
        resourceName: this.assignedGroupResourceTypeValue,
        entityId: this.entityId,
        hasToAddPages: this.isSideSheetChild,
      })
    },
    addAssignedGroupResourceAttribute(): void {
      const assignedGroupResourceType: AssignedGroupResourceTypeEnums[] =
        Object.values(AssignedGroupResourceTypeEnums)
      assignedGroupResourceType.forEach((type) => {
        const attribute = this.hasAddress(type)
          ? ADDRESS_ATTRIBUTE_LIST
          : type === AssignedGroupResourceTypeEnums.POSITION
          ? POSITION_ATTRIBUTE_LIST
          : DEFAULT_ATTRIBUTE_LIST

        attribute.forEach((name) => {
          this.getItemHook().addAttribute(
            `assignedGroupResource(${type}).${name}`,
          )
        })
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.assigned-name:hover {
  color: var(--v-ttPrimary-base);
}
</style>
