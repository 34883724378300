<template>
  <tt-attr name="status" :data-cy="`${resource}-status-chip`">
    <TChipLabel v-if="status" v-bind="chipProps" />
  </tt-attr>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { TChipLabelType } from '@/tt-ui/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { UiChipColor } from './types'
import { Resources } from '@/tt-entity-design/src/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'StatusChip',
  inject: ['getItemHook'],
  props: {
    isSmall: {
      type: Boolean,
      default: true,
    },
    resource: {
      type: String as PropType<Resources>,
      default: Resources.LEAVE_REQUESTS,
    },
    statusColorOptions: {
      type: Object as PropType<UiChipColor>,
      default: () => ({ text: '', background: '' }),
    },
  },
  computed: {
    status(): string {
      return this.getItemHook().get('status')
    },
    rawStatus(): string {
      return this.getItemHook().getRawValue('status')
    },
    statusColor(): UiChipColor {
      return this.statusColorOptions[this.rawStatus]
    },
    chipProps(): Record<string, any> {
      return {
        textColor: this.statusColor.text,
        backgroundColor: this.statusColor.background,
        modifiers: {
          style: {
            fontWeight: 'normal',
            textTransform: 'none',
            opacity: 1,
          },
        },
        isSmall: this.isSmall,
        textKey: this.status,
        type: TChipLabelType.Pill,
      }
    },
  },
})
</script>
