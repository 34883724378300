<template>
  <div>
    <template v-if="hasValue">
      <v-icon v-if="isPrevious" small>mdi-chevron-left</v-icon>

      {{ startLabel }}

      <v-icon v-if="isNext" small>mdi-chevron-right</v-icon>
    </template>
    <v-chip v-else x-small label text-color="grey">N/A</v-chip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

type ShiftType = 'previous' | 'next'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'OpenShiftRequestsExtAroundShifts',
  inject: ['getItemHook'],
  props: {
    type: {
      type: String as PropType<ShiftType>,
      required: true,
    },
  },
  computed: {
    isPrevious(): boolean {
      return this.type === 'previous'
    },
    isNext(): boolean {
      return this.type === 'next'
    },
    name(): string {
      return `extensions.aroundShifts.${this.type}.startLabel`
    },
    startLabel(): string {
      return this.getItemHook().get(this.name)
    },
    hasValue(): boolean {
      return this.startLabel !== undefined
    },
  },
  created() {
    this.getItemHook().addAttribute('shift.startDateTime')
    this.getItemHook().addAttribute('shift.endDateTime')
    this.getItemHook().addAttribute(
      'extensions.aroundShifts.previous.startLabel',
    )
    this.getItemHook().addAttribute('extensions.aroundShifts.next.startLabel')
  },
})
</script>
