<template>
  <EntityItem
    :key="resourceName + '-' + entityId"
    v-bind="{ entityId, resourceName }"
    :item-hook="itemHook"
    class="fill-height d-flex flex-column overflow-hidden"
  >
    <v-list v-if="!hideEntityPreviewHeader" two-line class="ma-0">
      <tt-preset-view type="listItem" />
    </v-list>
    <WidgetFactory :widget="widget" />
  </EntityItem>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { ListWidgetModel, RelationListResource } from '@/tt-widget-components'
import { EntityItemHook } from '../EntityItemHook'

export default Vue.extend({
  name: 'EntityRelationListPreview',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
    hideEntityPreviewHeader: {
      type: Boolean,
    },
    disableResourcePreview: {
      type: Boolean,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    allowActions: {
      type: Boolean,
      default: false,
    },
    allowSearch: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    relationListResource(): RelationListResource {
      return {
        resource: this.resourceName,
        id: this.entityId,
        attribute: this.entity,
      }
    },
    relationResourceName(): string | undefined {
      return this.$appContext.widgetServices.resourceMetaManager.getAttribute(
        this.resourceName,
        this.entity,
      )?.relation?.resource
    },
    widget(): ListWidgetModel {
      const datetimeContextAttribute =
        this.$appContext.widgetServices.resourceMetaManager.getResourceModelContext(
          this.relationResourceName,
        )?.dateRangeAttribute

      const sortingAttribute = datetimeContextAttribute || 'id'

      return {
        title: '',
        is: 'ListWidget',
        disableResourcePreviewOnClick: this.disableResourcePreview,
        allowActions: this.allowActions,
        query: {
          resource: this.relationResourceName,
          relationListResource: this.relationListResource,
          sort: {
            attribute: sortingAttribute,
            direction: 'DESC',
          },
        },
        toolbar: {
          show: this.showToolbar,
          displayCounts: true,
          filterOptions: {
            allowSearch: this.allowSearch,
            filters: this.filters,
          },
        },
      }
    },
  },
})
</script>
