<template>
  <div class="flex--row align-center level1">
    <TTooltipIcon
      icon="mdi-earth"
      tooltip-text="Timezone"
      :icon-color="isUtc ? '' : 'ttPrimary'"
    />

    <v-autocomplete
      :value="timeZone"
      :items="timeZones"
      :search-input.sync="searchInput"
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      auto-select-first
      hide-details
      attach
      height="30px"
      class="pl-3 pt-0 mt-0"
      clearable
      @input="onChange"
      @click:clear="onChange(null)"
    >
      <!-- user's default timezone is displayed at the top of the list for selection convenience -->
      <template #prepend-item>
        <v-list-item
          link
          class="default-timezone--container"
          @click="setDefaultTimeZone"
        >
          <span class="default-timezone--text" v-text="defaultTimeZone" />
          <v-divider class="default-timezone--separator" />
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { TIMEZONES, Timezones } from '@/helpers/dates/timezones'
import { UTC } from '@/helpers/dates/timezones'

export default Vue.extend({
  name: 'TimeZonePicker',
  props: {
    timeZone: {
      type: String,
      default: UTC,
    },
    defaultTimeZone: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchInput: '',
  }),
  computed: {
    timeZones(): Timezones {
      return TIMEZONES
    },
    isUtc(): boolean {
      return this.timeZone === UTC
    },
  },
  methods: {
    onChange($event): void {
      /**
       * To avoid a behaviour of v-autocomplete, where if "erasing" the search field, Vuetify triggers an 'input' event with a 'null' value.
       *
       * This has the effect of automatically setting the timezone in UTC, and creates a weird UI behaviour, where the user just wanted to erase the "search" field.
       * Se we disable the event when the search is empty.
       */
      if (!this.searchInput) return

      this.$emit('input', $event)
    },
    setDefaultTimeZone(): void {
      this.onChange(this.defaultTimeZone)
    },
  },
})
</script>

<style scoped>
.v-autocomplete >>> .v-input__slot {
  /* allow height less than 56px */
  min-height: 0;
  font-size: 12px;
}

/* fix scrollbar background transparent */
.v-autocomplete >>> .v-autocomplete__content {
  background-color: var(--v-level0-base);
}

.v-autocomplete >>> .v-select__slot {
  font-weight: bold;
}

.v-autocomplete >>> .v-select__slot > input {
  color: var(--v-ttPrimary-base);
}

.default-timezone--container {
  width: 100%;
  position: relative;
}

.default-timezone--text {
  color: var(--v-ttPrimary-base);
}

.default-timezone--separator {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
