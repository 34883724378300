import { Action, Resource } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'

type ResourceActionBlacklist = {
  [R in Resource]?: Action<R>[]
}

/**
 * This blacklist is used to store actions that we need to manually hide on the
 * FE, while they still exist on the schema coming from the BE.
 * The use case which made us resort to this blacklist is that there are actions
 * that are on the schema and are intended to show for the mobile app but not
 * supposed to show up for the FE.
 * It turned out to be too complex for the BE to build a dynamic schema that
 * shows actions for mobile and hides them for FE.
 */
export const RESOURCE_ACTION_BLACKLIST: ResourceActionBlacklist = {
  [Resources.ASSET_CATEGORIES]: ['delete-asset-category-translation'],
  [Resources.ASSET_TYPES]: ['delete-asset-type-translation'],
  [Resources.ASSETS]: [
    'file-report',
    'remove-assets',
    'write-barcode-with-device',
    'write-nfc-with-device',
  ],
  [Resources.MOBILE_SCHEDULE_GROUPS]: ['add-periodic'],
}

export const isResourceActionBlacklisted = <R extends Resource>({
  resource,
  action,
}: {
  resource: R
  action: Action<R>
}): boolean => RESOURCE_ACTION_BLACKLIST[resource]?.includes(action) ?? false
