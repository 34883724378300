<template>
  <TextAreaAttr
    v-if="isTextArea"
    :name="name"
    :no-value-placeholder="noValuePlaceholder"
    class="pt-3"
  />
  <tt-attr-row v-else :name="name" />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import TextAreaAttr from '@/tt-entity-design/src/components/TextAreaAttr.vue'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'TCustomTextAreaAttr',
  components: { TextAreaAttr },
  inject: ['getItemHook'],
  props: {
    name: { type: String, required: true },
    noValuePlaceholder: { type: String, default: 'N/A' },
  },
  computed: {
    rawValue(): string {
      return this.getItemHook().getRawValue(this.name) ?? ''
    },
    isTextArea(): boolean {
      return (
        this.getItemHook().getAttributeType(this.name).toLowerCase() ===
          'textarea' &&
        this.rawValue !== '' &&
        this.rawValue.length >= 150
      )
    },
  },
})
</script>

<style scoped></style>
