import { DAY_TO_ID, ID_TO_DAY } from './constants'
import { DayID, DayName } from './types'

export const convertDayIdToDayName = (dayId: DayID): DayName => ID_TO_DAY[dayId]

export const convertDayNameToDayId = (dayName: DayName): DayID =>
  DAY_TO_ID[dayName]

export const getNextDayName = (dayName: DayName): DayName => {
  const dayID = convertDayNameToDayId(dayName)
  const nextDayId = ((dayID + 1) % 7) as DayID

  return convertDayIdToDayName(nextDayId)
}
