<template>
  <v-checkbox
    v-model="model"
    v-bind="$attrs"
    :hint="placeholder"
    persistent-hint
  />
</template>

<script lang="ts">
import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import Vue, { VueConstructor } from 'vue'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & NamespaceProvider>
).extend({
  name: 'FieldsetChekbox',
  inject: ['formHook', 'namespace'],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    model: {
      get(): unknown {
        return this.value
      },
      set(newValue: boolean) {
        if (!newValue && this.$attrs?.required) {
          this.$emit('input', undefined)
        } else {
          this.$emit('input', newValue)
        }
      },
    },
  },
})
</script>
