<template>
  <span>{{ translationPolicies }}</span>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeavePolicyItemsPartOfColumn',
  inject: ['getItemHook'],
  computed: {
    translationPolicies(): string {
      const leavePoliciesCount = this.getItemHook().get('leavePolicies')
      return this.$tc(
        'tt-entity-design.leave-management.leave-policy-items.leave-policies-amount',
        leavePoliciesCount,
      )
    },
  },
  created() {
    this.getItemHook().addAttribute('leavePolicies')
  },
})
</script>
