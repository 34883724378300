// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CalendarExceptionGroupDatesTile from './CalendarExceptionGroupDatesTile.vue'
import CalendarExceptionGroupDatesColumns from './CalendarExceptionGroupDatesColumns'
import CalendarExceptionGroupDatesDetail from './CalendarExceptionGroupDatesDetail.vue'
import CalendarExceptionGroupDatesReference from './CalendarExceptionGroupDatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'CalendarExceptionGroupDatesTile',
      CalendarExceptionGroupDatesTile,
    )
    Vue.component(
      'CalendarExceptionGroupDatesDetail',
      CalendarExceptionGroupDatesDetail,
    )
    Vue.component(
      'CalendarExceptionGroupDatesReference',
      CalendarExceptionGroupDatesReference,
    )

    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTION_GROUP_DATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionGroupDatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTION_GROUP_DATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CalendarExceptionGroupDatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTION_GROUP_DATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionGroupDatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CALENDAR_EXCEPTION_GROUP_DATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CalendarExceptionGroupDatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
