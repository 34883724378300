<template>
  <div class="fill-height d-flex flex-column">
    <slot v-if="hasHeader" name="header">
      <WidgetTitleToolbar
        :container="container"
        :hook="hook"
        @update:filters="$emit('update:filters', $event)"
      >
        <template #toolbar-after-filters>
          <slot name="toolbar-after-filters" />
        </template>
      </WidgetTitleToolbar>
    </slot>

    <div v-show="hook.loading">
      <slot name="loader">
        <WidgetLoader :widget="hook.widget" />
      </slot>
    </div>

    <div v-show="!hook.loading" style="height: 100%; min-height: 0">
      <slot v-if="hook.hasErrors" name="error">
        <WidgetErrorDetails :resource="hook.resource" :errors="hook.errors" />
      </slot>
      <slot v-else-if="hook.hasDataSource === false" name="noData">
        <TNoData />
      </slot>
      <slot v-else />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { WidgetContainerInterface } from '@/tt-widget-factory'
import TNoData from '@/tt-ui/components/TNoData.vue'
import { CollectionWidgetHook, DataSetWidgetHook } from '@/tt-widget-components'
import TQLQueryWidgetHook from '@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook'

export default Vue.extend({
  name: 'WidgetWrapper',
  components: {
    TNoData,
  },
  props: {
    container: {
      type: Object as PropType<WidgetContainerInterface>,
      default: null,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hook: {
      type: Object as
        | PropType<CollectionWidgetHook<any>>
        | PropType<DataSetWidgetHook<any>>
        | PropType<TQLQueryWidgetHook>,
      required: true,
    },
  },
  computed: {
    isReady(): boolean {
      return (
        !this.hook.loading && (!this.hook.hasDataSource || this.hook.hasErrors)
      )
    },
  },
  watch: {
    isReady: {
      handler() {
        if (this.isReady) this.$emit('rendered')
      },
      immediate: true,
    },
  },
})
</script>
