import { Action, Resource } from '@/tt-entity-design/src/schema-types'

export const SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS: Resource =
  'system-exception-ticket-instructions'

export const ARCHIVE: Action<typeof SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS> =
  'archive'

export const LOCALIZE_PATH =
  'tt-entity-design.system-exception-ticket-instructions'

export const PATH = ''
