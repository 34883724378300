<template>
  <v-card
    v-if="hasToDisplayCard"
    class="banned-checkbox--card mb-12 px-4"
    flat
    outlined
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
          v-model="model"
          :label="$t(checkboxLabel)"
          color="ttPrimary"
          class="caption"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import { FormHookProvider } from '@/tt-widget-components'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormBannedCheckbox',
  inject: ['formHook', 'service'],
  computed: {
    model: {
      get(): boolean {
        return this.service.getForceSave()
      },
      set(value: boolean) {
        this.service.setForceSave(value)
      },
    },
    isBannedTaskTypes(): boolean {
      return this.service.getIsBannedTaskTypes()
    },
    isDispatchBanned(): boolean {
      return this.service.getIsDispatchBanned()
    },
    hasToDisplayCard(): boolean {
      return this.isBannedTaskTypes || this.isDispatchBanned
    },
    checkboxLabel(): string {
      return this.isBannedTaskTypes
        ? this.translationKey('form-checkboxes.banned-task')
        : this.translationKey('form-checkboxes.banned-client')
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
  },
})
</script>

<style scoped>
.banned-checkbox--card {
  border-color: var(--v-ttWarning-base);
}
</style>
