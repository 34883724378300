import { PropType } from 'vue'

import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import { Attribute } from '@/tt-widget-factory/services/resource-meta/types'
import { AttributeName } from '@/tt-widget-components/lib/names'
import { isEmpty } from '@/helpers/isEmpty'
import { ObjectInput } from '@/tt-widget-components/ObjectInput'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'

import FilterBoolean from './FilterBoolean.vue'
import FilterEnum from './FilterEnum.vue'
import FilterNumber from './FilterNumber.vue'
import FilterRelation from './FilterRelation.vue'
import FilterString from './FilterString.vue'
import FilterValueParser from '../FilterValueParser'
import { isFilterValid } from '../util'
import { getAttributeFilterComponent } from '../type-map'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import { updateDOM } from '../../helpers/dom'

const FilterDateTime = () =>
  import(
    /* webpackChunkName: "FilterDateTime" */ '@/tt-entity-filter/components/FilterDateTime.vue'
  )

const FilterDateTimeAttributeAdapter = () =>
  import(
    /* webpackChunkName: "FilterDateTimeAttributeAdapter" */ '@/tt-entity-filter/components/FilterDateTimeAttributeAdapter.vue'
  )

export default ObjectInput.extend({
  components: {
    FilterString,
    FilterEnum,
    FilterNumber,
    FilterDateTime,
    FilterDateTimeAttributeAdapter,
    FilterRelation,
    FilterBoolean,
  },
  props: {
    as: {
      type: String,
    },
    attributeMeta: {
      required: true,
      type: Object as PropType<Attribute>,
    },
  },
  data() {
    return { menu: 0 }
  },
  methods: {
    async clear() {
      this.model = null

      await updateDOM()

      this.$emit('apply')

      this.close()
    },
    close() {
      this.menu = 0
    },
    async apply() {
      await updateDOM()

      // @TODO: to check, this is probably wrong as `filterValue` is the "display value" as text, not the raw value
      // https://tracktik.atlassian.net/browse/FE-1262
      this.$emit('apply', this.filterValue)

      this.close()
    },
  },
  computed: {
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    attributeLabel(): string | undefined {
      let translatedLabel

      if (this.attributeMeta.resource) {
        translatedLabel = ResourceTranslator.translateAttribute(
          this.attributeMeta.resource,
          this.attributeMeta.name,
          FormLabelTypes.LABEL,
        )
      }

      return (
        translatedLabel ??
        this.attributeMeta.labels?.label ??
        this.model?.attribute
      )
    },
    component(): string {
      return getAttributeFilterComponent(this.attributeMeta)
    },
    hasValue(): boolean {
      return !isEmpty(this.model) && isFilterValid(this.model)
    },
    parsedValue(): string {
      return FilterValueParser.getFilterValue(
        this.attributeMeta,
        this.model,
        this.userPreferences,
      )
    },
    isDateRangeAttribute(): boolean {
      return this.attributeMeta.name === AttributeName.DATE_RANGE_ATTRIBUTE
    },
    filterValue(): string {
      if (this.hasValue || this.parsedValue) {
        const attrLabel = !this.isDateRangeAttribute ? this.attributeLabel : ''
        const value = this.parsedValue

        return `${attrLabel} ${value}`
      }

      return this.attributeLabel
    },
  },
})
