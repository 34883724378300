export type ResizeEventPayload = {
  width: string
  height: string
  clientWidth: number
  clientHeight: number
}

export const createNewPayload = (): ResizeEventPayload => ({
  clientHeight: 0,
  clientWidth: 0,
  height: '',
  width: '',
})
