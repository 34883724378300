export type ExceptionTypeGroupsStatusColor = {
  backgroundColor: string
  textColor: string
}

export type Status = 'ACTIVE' | 'ARCHIVED' | 'DRAFT'

export const exceptionTypeGroupsColorMap: Record<
  Status,
  ExceptionTypeGroupsStatusColor
> = {
  ACTIVE: {
    backgroundColor: '#DEF9E4',
    textColor: '#0E8129',
  },
  DRAFT: {
    backgroundColor: '#FFEFE1',
    textColor: '#DB6A00',
  },
  ARCHIVED: {
    backgroundColor: '#ECEEF0',
    textColor: '#2A2E31',
  },
}

export const exceptionTypeGroupsDefaultLabelColorMap: Record<
  string,
  ExceptionTypeGroupsStatusColor
> = {
  DEFAULT: {
    backgroundColor: '#E9EAFF',
    textColor: '#405CE7',
  },
}
export enum ExceptionTypeGroupsActions {
  ARCHIVE = 'archive',
  UN_ARCHIVE = 'unarchive',
  ASSIGN_TYPES = 'assign-types',
  UN_ASSIGN_TYPES = 'un-assign-types',
}
