<template>
  <div>
    <slot :colors="colors"></slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  TThemeProviderConfig,
  TThemeProviderColors,
  ThemeProvider,
} from '@/tt-ui/ThemeProvider'

export default Vue.extend({
  name: 'TThemeProvider',
  inject: ['theme'],
  props: {
    config: {
      required: true,
      type: Object as PropType<TThemeProviderConfig>,
    },
  },
  data() {
    return {
      themeProvider: new ThemeProvider(
        this.$vuetify.theme,
        this.config.themeKey,
      ),
    }
  },
  computed: {
    colors(): TThemeProviderColors {
      return {
        text: this.config.textColor || this.themeProvider?.contrastColor,
        background: this.config.backgroundColor || this.config.themeKey,
      }
    },
  },
})
</script>
