import { ValueOf } from '@/helpers/types/ValueOf'

export const siteTaskScheduleType = {
  ADHOC: 'ADHOC',
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
} as const

export type SiteTaskScheduleType = ValueOf<typeof siteTaskScheduleType>

export const siteTaskScheduleTypeColor: Partial<
  Record<SiteTaskScheduleType, string>
> = {
  [siteTaskScheduleType.ADHOC]: '#f1d368',
}

export const siteTaskScheduleTypeDefaultColor = '#28a745'

export const getSiteTaskScheduleTypeColor = (
  type: SiteTaskScheduleType | null,
): string => {
  const { [type]: typeColor } = siteTaskScheduleTypeColor

  return typeColor ?? siteTaskScheduleTypeDefaultColor
}
