// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeSenioritiesTile from './EmployeeSenioritiesTile.vue'
import EmployeeSenioritiesColumns from './EmployeeSenioritiesColumns'
import EmployeeSenioritiesDetail from './EmployeeSenioritiesDetail.vue'
import EmployeeSenioritiesMetaCard from './EmployeeSenioritiesMetaCard.vue'
import EmployeeSenioritiesReference from './EmployeeSenioritiesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeSenioritiesTile', EmployeeSenioritiesTile)
    Vue.component('EmployeeSenioritiesDetail', EmployeeSenioritiesDetail)
    Vue.component('EmployeeSenioritiesMetaCard', EmployeeSenioritiesMetaCard)
    Vue.component('EmployeeSenioritiesReference', EmployeeSenioritiesReference)

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_SENIORITIES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeSenioritiesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_SENIORITIES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeSenioritiesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_SENIORITIES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeSenioritiesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_SENIORITIES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeSenioritiesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_SENIORITIES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeSenioritiesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
