<template>
  <div>
    <assets-archive-bundle-warning
      v-if="isBundle"
      :asset-id="assetId"
      @canArchive="cleanCustomError(attrBeContainedIntoBundle)"
      @cannotArchive="setCustomError(attrBeContainedIntoBundle, $event)"
    />

    <json-field name="reason" />
    <json-field name="dateOut" :placeholder="dateOutPlaceholder" />
    <json-field name="comments" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ErrorObject } from 'ajv'

import { FormHookProvider } from '@/tt-widget-components'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import AssetsArchiveBundleWarning from './AssetsArchiveBundleWarning.vue'
import { ATTR_BE_CONTAINED_INTO_BUNDLE } from './constants'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'AssetsArchive',
  components: { AssetsArchiveBundleWarning },
  inject: ['formHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    dateOutPlaceholder(): string {
      return this.$t('tt-entity-design.asset-hub.date-out')
    },
    assetId(): number {
      return this.itemHook.get('id')
    },
    attrBeContainedIntoBundle(): string {
      // adding as a computed so I can also use in the template
      return ATTR_BE_CONTAINED_INTO_BUNDLE
    },
    isBundle(): boolean {
      return this.itemHook.getRawValue('type.isBundle')
    },
  },
  created() {
    this.itemHook.addAttribute('type.isBundle')
  },
  methods: {
    setCustomError(path: string, errors: ErrorObject[]) {
      this.formHook().setCustomError(path, errors)
    },
    cleanCustomError(path: string) {
      this.formHook().setCustomError(path, null)
    },
  },
})
</script>
