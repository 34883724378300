<template>
  <v-menu
    v-model="opened"
    absolute
    v-bind="mousePosition"
    :left="isInjectedDashboardTab"
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <v-card
      :key="selectedKey"
      min-height="100"
      style="position: relative"
      width="350"
      @click="
        selectedItemHook.dispatchPreview()
        opened = false
      "
    >
      <ProvideItemHook v-if="selectedItemHook" :provide="selectedItemHook">
        <tt-preset-view type="listItem" />
      </ProvideItemHook>
      <span
        style="position: absolute; right: 0; top: 0; z-index: 1; display: block"
      >
        <v-btn small icon @click.stop="opened = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </span>
    </v-card>
  </v-menu>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import {
  EntityIntentTypes,
  EntityPreviewAbsoluteIntentInterface,
} from '@/tt-widget-entity-flow'

type VueWithInjections = VueConstructor<
  Vue & { isInjectedDashboardTab: boolean }
>

export default (Vue as VueWithInjections).extend({
  name: 'EntityAbsolutePositionView',
  inject: {
    isInjectedDashboardTab: {
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      mousePosition: {
        positionX: 0,
        positionY: 0,
      },
      selectedItem: null as EntityPreviewAbsoluteIntentInterface,
      unsubscribe: [],
    }
  },
  computed: {
    selectedKey(): string {
      if (!this.selectedItem) {
        return
      }
      return `${this.selectedItemHook.entityReference.resourceName}-${this.selectedItemHook.entityReference.entityId}`
    },
    selectedItemHook(): any {
      if (!this.selectedItem) {
        return
      }

      if (this.selectedItem.itemHook) {
        return this.selectedItem.itemHook
      }

      return new EntityItemHook(this.$appContext, {
        entityId: this.selectedItem.entityId,
        resourceName: this.selectedItem.resourceName,
        entity: { ...this.selectedItem.entity },
      })
    },
  },
  created() {
    this.unsubscribe.push(
      this.$eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW_ABSOLUTE,
        (payload: EntityPreviewAbsoluteIntentInterface) => {
          this.select(payload)
        },
      ),
    )
    this.unsubscribe.push(
      this.$eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW_ABSOLUTE_CLOSE,
        () => {
          this.opened = false
        },
      ),
    )
  },
  beforeDestroy() {
    this.unsubscribe.forEach((unsub) => unsub())
  },
  methods: {
    select(payload: EntityPreviewAbsoluteIntentInterface) {
      this.mousePosition = {
        positionX: payload.position.x,
        positionY: payload.position.y,
      }
      this.selectedItem = payload
      this.opened = true
    },
  },
})
</script>
