<template>
  <v-card
    color="grey lighten-4"
    flat
    class="fill-height d-flex flex-column"
    tile
  >
    <v-toolbar dense elevation="0">
      <v-app-bar-nav-icon @click="exit">
        <v-icon v-text="`arrow_back`" />
      </v-app-bar-nav-icon>
    </v-toolbar>

    <div class="loader--container">
      <TLoadingBar v-if="!isLoaded" />
      <v-divider />
    </div>

    <div
      v-if="hasErrors"
      class="fill-height"
      v-text="$t(`common.error_message`)"
    />
    <iframe v-else :src="iFrameUrl" class="widget--iframe" />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import TLoadingBar from '@/tt-ui/components/TLoadingBar.vue'
import { SsoQueryParams, RouteSSO } from '@/tt-app-builder/core-routes'
import { ContextFilter } from '@/tt-widget-components'

import {
  PDF_PRINT_BASE_PATH,
  DATALAB_IFRAME_URL,
  MOUNTED,
  CLOSE,
} from './constants'
import { convertContextFiltersToQueryParams } from './utils/convertContextFiltersToQueryParams'
import { fetchOtpToken } from './utils/fetchOtpToken'
import { PDF_IFRAME_MESSAGES } from './types'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

/**
 * On TTC we load the WidgetPrint with an iFrame to isolate the PDF rendering from TTC layout
 */
export default Vue.extend({
  name: 'WidgetPrintIframe',
  components: { TLoadingBar },
  props: {
    dataViewUid: {
      type: String,
      required: true,
    },
    contextFilters: {
      type: Array as PropType<ContextFilter[]>,
      required: true,
    },
  },
  data: () => ({
    iFrameUrl: '',
    isLoaded: false,
    hasErrors: false,
    unsubscribe: () => {},
  }),
  async created() {
    this.iFrameUrl = await this.createIframeUrl().catch(this.onError)

    const callback = (event) => this.onMessageReceived(event)
    window.addEventListener('message', callback)
    this.unsubscribe = () => window.removeEventListener('message', callback)
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    async createIframeUrl() {
      this.hasErrors = false

      const contextFilterQuery = convertContextFiltersToQueryParams(
        this.contextFilters,
      )

      const ssoQueryParams: Partial<Record<SsoQueryParams, string>> = {
        // @todo: use OAuth: https://tracktik.atlassian.net/browse/FE-894
        token: await fetchOtpToken(this.$auth),
        url: new URL(this.$auth.getServerUrl()).origin,
        path: `${PDF_PRINT_BASE_PATH}/${this.dataViewUid}?${contextFilterQuery}`,
      }

      const iFrameQueryParams = new URLSearchParams(ssoQueryParams).toString()

      return `${DATALAB_IFRAME_URL}#${RouteSSO.path}?${iFrameQueryParams}`
    },
    onMessageReceived(event: MessageEvent) {
      const message = event.data

      if (!IS_PRODUCTION) {
        console.log('Received postMessage event', message)
      }

      const iframeOrigin = new URL(DATALAB_IFRAME_URL).origin
      const isOriginInvalid = event.origin !== iframeOrigin

      if (isOriginInvalid) {
        console.warn(
          `Ignoring postMessage event : Event origin is different from iFrame origin...`,
          message,
          event.origin,
          iframeOrigin,
        )

        return
      }

      const MessageHandler: Record<PDF_IFRAME_MESSAGES, () => void> = {
        [MOUNTED]: () => (this.isLoaded = true),
        [CLOSE]: () => this.exit(),
      }

      const handler = MessageHandler[message]

      if (!handler) {
        console.warn('unregistered postMessage event received:', message)
        return
      }

      return handler()
    },
    exit() {
      this.$emit('exit')
    },
    onError() {
      this.hasErrors = true
      return ''
    },
  },
})
</script>

<style scoped>
.loader--container {
  position: relative;
  width: 100%;
}

.widget--iframe {
  height: 100%;
  border: 0;
}
</style>
