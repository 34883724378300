// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PayrollPayrunsTile from './PayrollPayrunsTile.vue'
import PayrollPayrunsColumns from './PayrollPayrunsColumns'
import PayrollPayrunsDetail from './PayrollPayrunsDetail.vue'
import PayrollPayrunsMetaCard from './PayrollPayrunsMetaCard.vue'
import PayrollPayrunsReference from './PayrollPayrunsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PayrollPayrunsTile', PayrollPayrunsTile)
    Vue.component('PayrollPayrunsDetail', PayrollPayrunsDetail)
    Vue.component('PayrollPayrunsMetaCard', PayrollPayrunsMetaCard)
    Vue.component('PayrollPayrunsReference', PayrollPayrunsReference)

    modularManager.addResourcePreset(
      Resources.PAYROLL_PAYRUNS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PayrollPayrunsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_PAYRUNS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PayrollPayrunsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_PAYRUNS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollPayrunsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_PAYRUNS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollPayrunsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_PAYRUNS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PayrollPayrunsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
