import {
  exceptionTypeGroupsColorMap,
  ExceptionTypeGroupsStatusColor,
  Status,
} from './types'

export const getExceptionTypeGroupStatusColor = (
  status: Status,
): ExceptionTypeGroupsStatusColor | null => {
  return exceptionTypeGroupsColorMap[status]
}
