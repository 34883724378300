import { reactive } from 'vue'

import { Api } from '@tracktik/tt-authentication'

import { Resources } from '@/tt-entity-design/src/types'

import { createFeatureManager } from './FeatureManager'
import { EntityFeatureManager, Feature } from './types'

export const createClientFeatureManager = (
  clientId: number,
  { api }: { api: Api },
): EntityFeatureManager => {
  const { setFeatures, ...baseManager } = createFeatureManager()

  const state = reactive({
    features: [] as Feature[],
    loading: false,
  })

  const handlerError = (err) => {
    console.warn('Could not fetch features', err)

    return []
  }

  const fetchFeatures = async () => {
    state.loading = true

    await api
      .get<number, Record<string, Feature[]>>(Resources.CLIENTS, clientId, {
        fields: [{ attribute: 'features' }],
      })
      .then((response) => {
        setFeatures(response.features)
      })
      .catch(handlerError)
      .finally(() => {
        state.loading = false
      })
  }

  const isLoading = () => state.loading

  const destroy = () => {}

  // Asynchronously Fetch features on initialization
  fetchFeatures()

  return { ...baseManager, destroy, isLoading }
}
