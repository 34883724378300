<template>
  <div v-if="hasResult" class="fill-height">
    <v-row>
      <v-col
        v-for="(item, index) in dashboardViews"
        :key="`view.${index}.${item.uid}-card`"
        :cols="colSize"
        @click="$emit('click', item)"
      >
        <div>
          <WidgetCard
            :item="item"
            :show-subtitle="showSubtitle"
            :show-action-menu="showActionMenu"
          />
        </div>
      </v-col>
    </v-row>

    <infinite-loading @infinite.passive="$emit('infinite', $event)">
      <span slot="no-more">
        <!-- end-of-result -->
      </span>
      <span slot="no-results" />
    </infinite-loading>
  </div>
  <div v-else>
    <component :is="component" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

import { WidgetStoreInterface } from '@/tt-widget-factory'

import NoResultsDashboardView from './NoResultsDashboardView.vue'
import NoSearchResultsDashboardView from './NoSearchResultsDashboardView.vue'

import { ViewTypes } from '../types'

/**
 * @TODO: review hierarchy, core components should not use app components
 * https://tracktik.atlassian.net/browse/FE-647
 */
import WidgetCard from '@/apps/app.tracktik.insights.studio/components/WidgetCard.vue'

export default Vue.extend({
  name: 'WidgetDashboardResults',
  components: {
    WidgetCard,
    InfiniteLoading,
    NoResultsDashboardView,
    NoSearchResultsDashboardView,
  },
  props: {
    showActionMenu: {
      type: Boolean,
      default: false,
    },
    showSubtitle: {
      type: Boolean,
      default: true,
    },
    dashboardViews: {
      type: Array as PropType<WidgetStoreInterface[]>,
      required: true,
    },
    hasResult: {
      type: Boolean,
      default: false,
    },
    viewTypes: {
      type: String as PropType<ViewTypes>,
      required: true,
    },
  },
  computed: {
    colSize(): number {
      return {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }[this.$vuetify.breakpoint.name]
    },
    component(): string {
      return this.viewTypes === ViewTypes.View
        ? 'NoResultsDashboardView'
        : 'NoSearchResultsDashboardView'
    },
  },
})
</script>
