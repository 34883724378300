import { WidgetModels } from '@/tt-widget-components'
import { GridSelectionMode } from '@/tt-widget-components/widgets/DataTable/types'
import { CustomFilter } from '@/tt-widget-factory'
import { Resources } from '../../types'
import { Resource } from '@/tt-widget-components'
import { Action, Extension, ActionProperty } from '../../schema-types'

/**
 * GET with the extension `recipients`.
 * POST with the action `add-recipients`.
 */
type FILTER_RULE_SET_ASSETS = typeof Resources.FILTER_RULE_SET_ASSETS

export const ACTION_RECIPIENTS: Action<FILTER_RULE_SET_ASSETS> =
  'add-recipients'
export const EXTENSION_RECIPIENTS: Extension<FILTER_RULE_SET_ASSETS> =
  'recipients'

export type FormKey = ActionProperty<
  FILTER_RULE_SET_ASSETS,
  typeof ACTION_RECIPIENTS
>

export type FormRelationList = Extract<
  FormKey,
  | 'siteContacts'
  | 'siteEmployees'
  | 'siteClients'
  | 'zoneEmployees'
  | 'assetPersons'
>

export const FORM_KEYS: FormKey[] = [
  'sendToPerson',
  'siteContacts',
  'siteEmployees',
  'siteClients',
  'zoneEmployees',
  'assetPersons',
]

export const createWidget = (
  resource: Resource,
  fields: string[],
  customFilter?: CustomFilter[],
): WidgetModels => ({
  is: 'DataTableWidget',
  title: '',
  query: {
    resource,
    customFilters: customFilter ? [...customFilter] : [],
  },
  component: { columns: fields },
  selectionMode: GridSelectionMode.MULTIPLE,
})

export type Tab = {
  key: FormRelationList
  title: string
  widget: WidgetModels
}
