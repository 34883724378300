import {
  EntityActionsRequestOptions,
  EntityAllActionsRequestOptions,
} from 'tracktik-sdk/lib/common/entity-actions'

import flatten from 'lodash/flatten'

import {
  Action,
  ResourceActionManagerInterface,
  ResourceActionProviderInterface,
} from './types'

export default class ResourceActionManager
  implements ResourceActionManagerInterface
{
  constructor(providers: ResourceActionProviderInterface[] = []) {
    providers.map((provider) => this.registerProvider(provider))
  }

  /**
   * Gets batch actions
   * Operations for a group or entities (Ex. cancel shifts with id 21,32,44,55)
   */
  async getBatchActions(
    resourceName: string,
    options?: EntityAllActionsRequestOptions,
  ): Promise<Action[]> {
    const rootActions = await Promise.all(
      this.providers.map((provider) =>
        provider.getBatchActions(resourceName, options),
      ),
    )
    return flatten(rootActions)
  }

  /**
   * Gets entity actions
   * Operations for a single entity (Ex. archive break-rule with id 34)
   */
  async getEntityActions(
    resourceName: string,
    entityId: number,
    options?: EntityActionsRequestOptions,
  ): Promise<Action[]> {
    const rootActions = await Promise.all(
      this.providers.map((provider) =>
        provider.getEntityActions(resourceName, entityId, options),
      ),
    )
    return flatten(rootActions)
  }

  /**
   * Register the provider
   */
  registerProvider(provider: ResourceActionProviderInterface) {
    this.providers.push(provider)
  }

  // Private

  /**
   * Registered providers
   */
  private providers: ResourceActionProviderInterface[] = []
}
