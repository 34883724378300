<template>
  <div class="flex--row">
    <v-icon :color="color" v-text="icon" />
    <span class="text-truncate" v-text="value" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TPriorityIcon',
  props: {
    value: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
})
</script>
