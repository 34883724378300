import {
  EntityActions as BaseEntityActions,
  EntityActionsRequestOptions,
  EntityAllActionsRequestOptions,
  EntityJsonSchemaAction,
} from 'tracktik-sdk/lib/common/entity-actions'

import { AuthModule } from '@tracktik/tt-authentication'
import { FormOptions, JSONSchema7 } from '@tracktik/tt-json-schema-form'

import { Action, ResourceActionProviderInterface } from './types'

type EntityActions<Options> = BaseEntityActions<
  Options,
  JSONSchema7,
  FormOptions
>

export default class ResourceActionProvider
  implements ResourceActionProviderInterface
{
  constructor(auth: AuthModule) {
    this.auth = auth
  }

  async getBatchActions<Options extends EntityAllActionsRequestOptions>(
    resourceName: string,
    options?: Options,
  ): Promise<Action[]> {
    const apiActions = await this.auth
      .getApi()
      .getAllActions<Options, EntityActions<Options>>(resourceName, options)

    return ResourceActionProvider.mapApiActions<Options>(apiActions)
  }

  async getEntityActions<Options extends EntityActionsRequestOptions>(
    resourceName: string,
    entityId: number,
    options?: Options,
  ): Promise<Action[]> {
    const apiActions = await this.auth
      .getApi()
      .getActions<number, Options, EntityActions<Options>>(
        resourceName,
        entityId,
        options,
      )

    return ResourceActionProvider.mapApiActions<Options>(apiActions)
  }

  // private
  private auth: AuthModule

  private static mapApiActions<Options>(
    actions: EntityActions<Options>,
  ): Action[] {
    return Object.entries(actions).map(([actionName, action]) => {
      const { labels, batchable, formOptions, schema, values } =
        action as EntityJsonSchemaAction

      const { label = undefined, description = undefined } = labels || {}

      return {
        actionName,
        label,
        description,
        batchable,
        icon: '',
        formOptions,
        schema,
        values,
      }
    })
  }
}
