// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ExceptionTypeDatesTile from './ExceptionTypeDatesTile.vue'
import ExceptionTypeDatesColumns from './ExceptionTypeDatesColumns'
import ExceptionTypeDatesDetail from './ExceptionTypeDatesDetail.vue'
import ExceptionTypeDatesReference from './ExceptionTypeDatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ExceptionTypeDatesTile', ExceptionTypeDatesTile)
    Vue.component('ExceptionTypeDatesDetail', ExceptionTypeDatesDetail)
    Vue.component('ExceptionTypeDatesReference', ExceptionTypeDatesReference)

    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_DATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeDatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_DATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ExceptionTypeDatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_DATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeDatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPE_DATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ExceptionTypeDatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
