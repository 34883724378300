<template>
  <tt-attr name="type">
    <template #default="{ rawValue, value }">
      <v-chip
        v-if="layout === layouts.CHIP"
        class="chip"
        color="grey"
        label
        text-color="white"
        :title="value"
        x-small
        v-text="value"
      />
      <v-avatar v-else-if="layout === layouts.AVATAR" color="grey" size="60">
        <v-icon v-if="!!typeIcon(rawValue)" color="white">
          {{ typeIcon(rawValue) }}
        </v-icon>
        <span v-else style="color: white">{{ initial(value) }}</span>
      </v-avatar>
    </template>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import {
  getSiteTaskOccurrenceSessionLogTypeIcon,
  SiteTaskOccurrenceSessionLogType,
} from './site-task-occurrence-session-log-type'

const layouts = { AVATAR: 'AVATAR', CHIP: 'CHIP' } as const

export default Vue.extend({
  name: 'SiteTaskOccurrenceSessionLogType',
  props: {
    layout: {
      type: String as PropType<keyof typeof layouts>,
      default: layouts.CHIP,
    },
  },
  computed: {
    layouts(): typeof layouts {
      return layouts
    },
  },
  methods: {
    initial(label: string): string {
      const [firstWord] = label.trim().split(/\s/)

      return firstWord ? firstWord[0] : ''
    },
    typeIcon(type: SiteTaskOccurrenceSessionLogType): string {
      return getSiteTaskOccurrenceSessionLogTypeIcon(type)
    },
  },
})
</script>
