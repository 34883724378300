<template>
  <div
    class="d-flex flex-md-column flex-sm-row flex-sm-grow-1 align-self-stretch align-center"
  >
    <v-avatar size="128">
      <img :src="avatar" :alt="name" />
    </v-avatar>
    <div class="d-flex flex-column ml-sm-4 ml-md-0 align-self-stretch my-4">
      <h2
        class="text-h6 font-weight-medium text-sm-left text-md-center"
        v-text="name"
      />
      <span
        class="text-subtitle-1 text-capitalize text-sm-left text-md-center"
        v-text="jobTitle"
      />
      <EmployeeInfoChip
        :info="hoursPerDay"
        class="mt-2 align-self-sm-start align-self-md-center"
      />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import EmployeeInfoChip from './EmployeeInfoChip.vue'
export default Vue.extend({
  name: 'EmployeeBadge',
  components: {
    EmployeeInfoChip,
  },
  props: {
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    hoursPerDay: {
      type: String,
      required: true,
    },
    jobTitle: {
      type: String,
      default: '',
    },
  },
})
</script>
