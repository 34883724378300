// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointQuestionAnswersTile from './CheckpointQuestionAnswersTile.vue'
import CheckpointQuestionAnswersColumns from './CheckpointQuestionAnswersColumns'
import CheckpointQuestionAnswersDetail from './CheckpointQuestionAnswersDetail.vue'
import CheckpointQuestionAnswersReference from './CheckpointQuestionAnswersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'CheckpointQuestionAnswersTile',
      CheckpointQuestionAnswersTile,
    )
    Vue.component(
      'CheckpointQuestionAnswersDetail',
      CheckpointQuestionAnswersDetail,
    )
    Vue.component(
      'CheckpointQuestionAnswersReference',
      CheckpointQuestionAnswersReference,
    )

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_QUESTION_ANSWERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointQuestionAnswersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_QUESTION_ANSWERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointQuestionAnswersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_QUESTION_ANSWERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointQuestionAnswersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_QUESTION_ANSWERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointQuestionAnswersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
