<template>
  <div class="flex--column py-3">
    <v-text-field
      class="px-4"
      :value="search"
      :label="searchVendorLabel"
      dense
      outlined
      color="ttPrimary"
      clearable
      prepend-icon="mdi-magnify"
      @input="debounceSearch"
    />
    <v-text-field
      class="px-4"
      :value="cityName"
      :label="$t('common.city')"
      dense
      outlined
      color="ttPrimary"
      clearable
      prepend-icon="mdi-city"
      @input="debounceCityName"
    />
    <v-text-field
      class="px-4"
      :value="stateName"
      :label="$t('common.state-province')"
      dense
      outlined
      color="ttPrimary"
      clearable
      prepend-icon="mdi-map-marker-radius"
      @input="debounceStateName"
    />

    <WidgetFactory :widget="vendorsModel" skip-validation />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VendorsTileForDispatchAssign from './VendorsTileForDispatchAssign.vue'
import { CustomFilter, WidgetModels } from '@/tt-widget-components/types'
import { Resources } from '../../../types'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { OnSelectCallback } from '../types'

type Debouncer = DebouncedFunc<(newVal: string) => void>

export default Vue.extend({
  name: 'VendorsList',
  props: {
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
    taskTypeId: {
      type: Number,
      required: true,
    },
    initialCityName: {
      type: String,
      required: true,
    },
    initialStateName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      cityName: this.initialCityName,
      stateName: this.initialStateName,
    }
  },
  computed: {
    searchVendorLabel(): string {
      return this.$t(
        'tt-entity-design.dispatch-tasks.assign-action.search-any-vendor',
      )
    },
    debounceSearch(): Debouncer {
      return debounce((newValue) => {
        this.search = newValue || ''
      }, 500)
    },
    debounceCityName(): Debouncer {
      return debounce((newValue) => {
        this.cityName = newValue || ''
      }, 500)
    },
    debounceStateName(): Debouncer {
      return debounce((newValue) => {
        this.stateName = newValue || ''
      }, 500)
    },
    vendorsModel(): WidgetModels {
      const customFilters: CustomFilter[] = [
        this.cityName && {
          filterName: 'servicingCityNameContains',
          value: this.cityName,
        },
        this.stateName && {
          filterName: 'servicingCityStateNameContains',
          value: this.stateName,
        },
        { filterName: 'serviceById', value: this.taskTypeId },
      ].filter(Boolean)

      return {
        title: '',
        is: 'ListWidget',
        disableResourcePreviewOnClick: true,
        listItem: {
          // @ts-ignore
          is: VendorsTileForDispatchAssign,
          props: {
            // @ts-ignore
            onSelect: (payload) => this.onSelect(payload),
          },
        },
        query: {
          resource: Resources.VENDORS,
          customFilters,
          search: this.search,
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            allowSearch: false,
          },
        },
      }
    },
  },
})
</script>
