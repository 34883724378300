<template>
  <v-avatar :class="cssClass" size="60" class="mr-7">
    <tt-attr style="font-size: 0.7em" :name="statusAttr" class="white--text" />
  </v-avatar>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getStatusColor } from './utils'
import { STATUS_ATTR } from './contants'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'MobileScheduleOccurrenceLogStatusAttr',
  inject: ['getItemHook'],
  computed: {
    statusAttr(): string {
      return STATUS_ATTR
    },
    status(): string {
      return this.getItemHook().getRawValue(STATUS_ATTR)
    },
    cssClass(): string {
      return getStatusColor(this.status)
    },
  },
})
</script>
