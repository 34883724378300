// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import BillingAdhocItemsTile from './BillingAdhocItemsTile.vue'
import BillingAdhocItemsColumns from './BillingAdhocItemsColumns'
import BillingAdhocItemsDetail from './BillingAdhocItemsDetail.vue'
import BillingAdhocItemsMetaCard from './BillingAdhocItemsMetaCard.vue'
import BillingAdhocItemsReference from './BillingAdhocItemsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('BillingAdhocItemsTile', BillingAdhocItemsTile)
    Vue.component('BillingAdhocItemsDetail', BillingAdhocItemsDetail)
    Vue.component('BillingAdhocItemsMetaCard', BillingAdhocItemsMetaCard)
    Vue.component('BillingAdhocItemsReference', BillingAdhocItemsReference)

    modularManager.addResourcePreset(
      Resources.BILLING_ADHOC_ITEMS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'BillingAdhocItemsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADHOC_ITEMS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: BillingAdhocItemsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADHOC_ITEMS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'BillingAdhocItemsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADHOC_ITEMS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'BillingAdhocItemsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.BILLING_ADHOC_ITEMS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'BillingAdhocItemsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
