<template>
  <CollapsibleHeader
    v-if="resource && hasCustomFilters"
    :title="$t('components.custom_filter_input.custom_filters')"
    opened
  >
    <v-autocomplete
      v-model="filterSelection"
      v-bind="$attrs"
      prepend-icon="add"
      outlined
      dense
      :label="$t('components.custom_filter_input.select_available_filters')"
      :items="items"
    />
    <div>
      <v-list v-if="selectedFilters.length" dense>
        <span v-for="(item, i) in selectedFilters" :key="`${item.text} - ${i}`">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.text)" />
            </v-list-item-content>

            <v-list-item-icon>
              <v-btn small icon @click="removeFilterAtPosition(i)">
                <v-icon small v-text="`close`" />
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
        </span>
      </v-list>

      <CaptionBox
        v-else
        v-text="$t(`components.custom_filter_input.no_filters_selected`)"
      />
    </div>
  </CollapsibleHeader>
</template>

<script lang="ts">
import { PropType } from 'vue'
import BaseArrayInput from '../../BaseArrayInput'
import { CustomFilterMeta } from '@/tt-widget-factory/services/resource-meta/types'
import isEmpty from 'lodash/isEmpty'

type VuetifyItems = { text: string; value: string }

export default BaseArrayInput.extend({
  name: 'DataToolbarCustomFilterInput',
  inheritAttrs: false,
  props: {
    resource: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      filterSelection: null,
      model: [] as string[],
    }
  },
  watch: {
    filterSelection(filterName) {
      if (!this.resource || !filterName) {
        return
      }

      this.model = [...this.model, filterName]

      this.$nextTick(() => {
        this.filterSelection = null
      })
    },
  },
  methods: {
    removeFilterAtPosition(i: number) {
      const filters = [...this.model]
      filters.splice(i, 1)
      this.model = [...filters]
    },
  },
  computed: {
    customFilters(): Record<string, CustomFilterMeta> {
      return this.$appContext.widgetServices.resourceMetaManager.getCustomFilters(
        this.resource,
      )
    },
    hasCustomFilters(): boolean {
      return !isEmpty(this.customFilters)
    },
    selectedFilters(): VuetifyItems[] {
      const createSelectedItem = (filterName) => {
        const filterMetaLabel = this.customFilters[filterName]?.labels?.label
        return {
          text: filterMetaLabel ?? '',
          value: filterName,
        }
      }

      return (this.model || []).map(createSelectedItem)
    },
    items(): VuetifyItems[] {
      const createSelectableItems = ([name, filterMeta]) => ({
        text: filterMeta.labels?.label || '',
        value: name,
      })

      const isNotSelected = ([name, filterMeta]) =>
        !this.selectedFilters.some(({ value }) => value === name)

      return Object.entries(this.customFilters)
        .filter(isNotSelected)
        .map(createSelectableItems)
    },
  },
})
</script>
