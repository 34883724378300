import DataSetWidgetHook from '@/tt-widget-components/base/DataSetWidgetHook'
import { currencyFormat } from '@/tt-widget-components/syncfusion/helpers'
import {
  DataSetDimension,
  DataSetMeasure,
  DataSetRow,
  WidgetModelsObject,
} from '@/tt-widget-components'
import { MeasureFormat } from '@/tt-widget-components/components/measure-types'

export default class DataSetWidgetHookPercent<
  WidgetModel extends WidgetModelsObject,
> extends DataSetWidgetHook<WidgetModel> {
  private getMeasureSum(seriesIndex: number, measureKey: string): number {
    return this.series[seriesIndex].rows.reduce(
      (acc, val) => acc + val[measureKey as string],
      0,
    )
  }

  protected formatDataSource(
    dataSource: DataSetRow[],
    dataSourceIndex: number,
    dimension: DataSetDimension,
    measure: DataSetMeasure,
    measureFormat: MeasureFormat,
  ): DataSetRow[] {
    let prefix = ''
    if (this.getDefaultMeasureFormat(measure) === currencyFormat) {
      prefix = '$'
    }

    if (!measureFormat) {
      measureFormat = MeasureFormat.ROUND_0
    }

    const measureSum = this.getMeasureSum(dataSourceIndex, measure.key)

    return dataSource.map((dataSourcePoint: DataSetRow) => {
      let measureValue = dataSourcePoint[measure.key]

      // Calculate Percentage
      const percent = ((measureValue / measureSum) * 100).toFixed(0)

      // Get User-Facing Value
      measureValue = this.formatMeasureValue(
        dataSourcePoint[measure.key],
        measureFormat,
      )

      const tooltip = `${
        dataSourcePoint[dimension.key]
      }: <b>${prefix}${measureValue}</b> (${percent}%)`

      return { ...dataSourcePoint, tooltip }
    })
  }
}
