// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetTransactionsTile from './AssetTransactionsTile.vue'
import AssetTransactionsColumns from './AssetTransactionsColumns'
import AssetTransactionsDetail from './AssetTransactionsDetail.vue'
import AssetTransactionsMetaCard from './AssetTransactionsMetaCard.vue'
import AssetTransactionsReference from './AssetTransactionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetTransactionsTile', AssetTransactionsTile)
    Vue.component('AssetTransactionsDetail', AssetTransactionsDetail)
    Vue.component('AssetTransactionsMetaCard', AssetTransactionsMetaCard)
    Vue.component('AssetTransactionsReference', AssetTransactionsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetTransactionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetTransactionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTransactionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetTransactionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_TRANSACTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetTransactionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
