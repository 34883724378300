// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import GeoLocationsTile from './GeoLocationsTile.vue'
import GeoLocationsColumns from './GeoLocationsColumns'
import GeoLocationsDetail from './GeoLocationsDetail.vue'
import GeoLocationsMetaCard from './GeoLocationsMetaCard.vue'
import GeoLocationsReference from './GeoLocationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('GeoLocationsTile', GeoLocationsTile)
    Vue.component('GeoLocationsDetail', GeoLocationsDetail)
    Vue.component('GeoLocationsMetaCard', GeoLocationsMetaCard)
    Vue.component('GeoLocationsReference', GeoLocationsReference)

    modularManager.addResourcePreset(
      Resources.GEO_LOCATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'GeoLocationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_LOCATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: GeoLocationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_LOCATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'GeoLocationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_LOCATIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'GeoLocationsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.GEO_LOCATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'GeoLocationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
