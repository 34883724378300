import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'shift',
  'employee',
  'position.account',
  'position',
  'shift.startDateTime',
  'shift.endDateTime',
  'payHoursPlanned',
  'payTotalPlanned',
  'billHoursPlanned',
  'billHoursActual',
  'billTotalActual',
  'directLaborActual',
] as ColumnInputDefinition[]
