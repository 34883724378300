// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AlarmConnectionsTile from './AlarmConnectionsTile.vue'
import AlarmConnectionsColumns from './AlarmConnectionsColumns'
import AlarmConnectionsDetail from './AlarmConnectionsDetail.vue'
import AlarmConnectionsMetaCard from './AlarmConnectionsMetaCard.vue'
import AlarmConnectionsReference from './AlarmConnectionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AlarmConnectionsTile', AlarmConnectionsTile)
    Vue.component('AlarmConnectionsDetail', AlarmConnectionsDetail)
    Vue.component('AlarmConnectionsMetaCard', AlarmConnectionsMetaCard)
    Vue.component('AlarmConnectionsReference', AlarmConnectionsReference)

    modularManager.addResourcePreset(
      Resources.ALARM_CONNECTIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AlarmConnectionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_CONNECTIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AlarmConnectionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_CONNECTIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmConnectionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_CONNECTIONS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AlarmConnectionsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ALARM_CONNECTIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AlarmConnectionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
