// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PositionExpenseReimbursementsTile from './PositionExpenseReimbursementsTile.vue'
import PositionExpenseReimbursementsColumns from './PositionExpenseReimbursementsColumns'
import PositionExpenseReimbursementsDetail from './PositionExpenseReimbursementsDetail.vue'
import PositionExpenseReimbursementsReference from './PositionExpenseReimbursementsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'PositionExpenseReimbursementsTile',
      PositionExpenseReimbursementsTile,
    )
    Vue.component(
      'PositionExpenseReimbursementsDetail',
      PositionExpenseReimbursementsDetail,
    )
    Vue.component(
      'PositionExpenseReimbursementsReference',
      PositionExpenseReimbursementsReference,
    )

    modularManager.addResourcePreset(
      Resources.POSITION_EXPENSE_REIMBURSEMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PositionExpenseReimbursementsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_EXPENSE_REIMBURSEMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PositionExpenseReimbursementsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_EXPENSE_REIMBURSEMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PositionExpenseReimbursementsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.POSITION_EXPENSE_REIMBURSEMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PositionExpenseReimbursementsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
