<template>
  <div>
    <MapMarkerList
      v-if="mapManager"
      :map-manager="mapManager"
      :markers="formattedMarkers"
      icon="mdi-map-marker"
      color="ttPrimary"
    >
      <template #default="{ markerManager, marker, icon, color }">
        <TMapMarker
          v-bind="{
            markerManager,
            marker,
            icon,
            color,
          }"
        />
      </template>
    </MapMarkerList>
    <MapBaseView
      :style="{ height: `${height}px` }"
      :params="baseMapParams"
      @loaded="saveMapManager"
    />
    <TSearchAddress
      v-if="!hideSearchField"
      class="mt-6"
      @clear="$emit('clear')"
      @input="setCoordinates($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import isEqual from 'lodash/isEqual'
import { isValidCoordinates } from '@/tt-widget-components/components/map/is-valid-coordinates'
import {
  MapBaseView,
  MapMarkerList,
  MapManager,
  MapManagerParams,
} from '@tracktik/tt-maps'
import TMapMarker from '@/tt-ui/components/TMapMarker.vue'
import '@tracktik/tt-maps/style'
import TSearchAddress from './TSearchAddress.vue'
import { Coordinates, Geocoding } from '@tracktik/tt-geo-proxy'
import { Marker } from '@tracktik/tt-maps'

export default Vue.extend({
  name: 'MapCoordinateSelector',
  components: {
    MapBaseView,
    MapMarkerList,
    TSearchAddress,
    TMapMarker,
  },
  props: {
    defaultCoordinates: {
      type: Object as PropType<Coordinates>,
      default: null,
    },
    hideSearchField: {
      type: Boolean,
      default: false,
    },
    preventClick: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      mapManager: null as MapManager | null,
      coordinates: this.defaultCoordinates as Coordinates | null,
    }
  },
  computed: {
    formattedMarkers(): Marker[] {
      if (!isValidCoordinates(this.coordinates)) return []

      return [
        {
          id: Math.random(),
          position: [this.coordinates.longitude, this.coordinates.latitude],
          active: false,
        },
      ]
    },
    baseMapParams(): MapManagerParams {
      return {
        development: false,
      }
    },
  },
  watch: {
    defaultCoordinates(newValue: Coordinates, oldValue: Coordinates) {
      if (!isEqual(newValue, oldValue)) {
        this.coordinates = newValue
        this.zoomToCenter()
      }
    },
  },
  methods: {
    setCoordinates(geocoding: Geocoding) {
      this.coordinates = geocoding.coordinates
      this.zoomToCenter()
      this.$emit('input', geocoding)
    },
    zoomToCenter(animate = false) {
      this.mapManager?.zoomTo(
        {
          coordinates: [this.coordinates.longitude, this.coordinates.latitude],
          type: 'Point',
        },
        { animate },
      )
    },
    onClick() {
      this.mapManager?.map.on('click', ({ lngLat }) => {
        if (!this.preventClick) {
          this.coordinates = {
            latitude: lngLat.lat,
            longitude: lngLat.lng,
          }
          this.zoomToCenter(true)
          this.$emit('input', {
            addressLabel: '',
            address: {},
            coordinates: this.coordinates,
          })
        }
      })
    },
    async saveMapManager(mapManager: MapManager) {
      this.mapManager = mapManager

      if (this.defaultCoordinates) {
        this.zoomToCenter()
      }

      this.onClick()
    },
  },
})
</script>
