// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AddressesTile from './AddressesTile.vue'
import AddressesColumns from './AddressesColumns'
import AddressesDetail from './AddressesDetail.vue'
import AddressesMetaCard from './AddressesMetaCard.vue'
import AddressesReference from './AddressesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AddressesTile', AddressesTile)
    Vue.component('AddressesDetail', AddressesDetail)
    Vue.component('AddressesMetaCard', AddressesMetaCard)
    Vue.component('AddressesReference', AddressesReference)

    modularManager.addResourcePreset(
      Resources.ADDRESSES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AddressesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(Resources.ADDRESSES, PresetTypes.COLUMNS, {
      title: 'Default',
      data: AddressesColumns,
      level: PresetLevels.DEFAULTS,
    })
    modularManager.addResourcePreset(
      Resources.ADDRESSES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AddressesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ADDRESSES,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AddressesMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ADDRESSES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AddressesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
