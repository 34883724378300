// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import PayrollAdhocsTile from './PayrollAdhocsTile.vue'
import PayrollAdhocsColumns from './PayrollAdhocsColumns'
import PayrollAdhocsDetail from './PayrollAdhocsDetail.vue'
import PayrollAdhocsMetaCard from './PayrollAdhocsMetaCard.vue'
import PayrollAdhocsReference from './PayrollAdhocsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('PayrollAdhocsTile', PayrollAdhocsTile)
    Vue.component('PayrollAdhocsDetail', PayrollAdhocsDetail)
    Vue.component('PayrollAdhocsMetaCard', PayrollAdhocsMetaCard)
    Vue.component('PayrollAdhocsReference', PayrollAdhocsReference)

    modularManager.addResourcePreset(
      Resources.PAYROLL_ADHOCS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'PayrollAdhocsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_ADHOCS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: PayrollAdhocsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_ADHOCS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollAdhocsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_ADHOCS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'PayrollAdhocsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.PAYROLL_ADHOCS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'PayrollAdhocsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
