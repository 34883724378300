<template>
  <v-card flat>
    <json-field name="code" />
    <json-field name="label" />
    <json-field name="status" />
  </v-card>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  inject: ['formHook'],
  computed: {
    isNew(): boolean {
      return !this.formHook().getPathValue('id')
    },
  },
  created() {
    if (this.isNew) {
      this.formHook().setObjectValue('status', 'ACTIVE')
    }
  },
})
</script>
