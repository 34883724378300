// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ScheduleOffPeriodsTile from './ScheduleOffPeriodsTile.vue'
import ScheduleOffPeriodsColumns from './ScheduleOffPeriodsColumns'
import ScheduleOffPeriodsDetail from './ScheduleOffPeriodsDetail.vue'
import ScheduleOffPeriodsReference from './ScheduleOffPeriodsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ScheduleOffPeriodsTile', ScheduleOffPeriodsTile)
    Vue.component('ScheduleOffPeriodsDetail', ScheduleOffPeriodsDetail)
    Vue.component('ScheduleOffPeriodsReference', ScheduleOffPeriodsReference)

    modularManager.addResourcePreset(
      Resources.SCHEDULE_OFF_PERIODS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ScheduleOffPeriodsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULE_OFF_PERIODS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ScheduleOffPeriodsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULE_OFF_PERIODS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ScheduleOffPeriodsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.SCHEDULE_OFF_PERIODS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ScheduleOffPeriodsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
