// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ExceptionTypesTile from './ExceptionTypesTile.vue'
import ExceptionTypesColumns from './ExceptionTypesColumns'
import ExceptionTypesDetail from './ExceptionTypesDetail.vue'
import ExceptionTypesReference from './ExceptionTypesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('ExceptionTypesTile', ExceptionTypesTile)
    Vue.component('ExceptionTypesDetail', ExceptionTypesDetail)
    Vue.component('ExceptionTypesReference', ExceptionTypesReference)

    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ExceptionTypesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ExceptionTypesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ExceptionTypesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EXCEPTION_TYPES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ExceptionTypesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
