<template>
  <div class="fill-height d-flex flex-column">
    <WidgetTitle :hook="hook" :container="container" />

    <div v-if="showCurrentMapInfo">
      <span>
        {{ $t('widgets.map.latitude') }}:
        {{ hook.mapManager.currentMapInfo.lat }} ---
      </span>
      <span>
        {{ $t('widgets.map.longitude') }}:
        {{ hook.mapManager.currentMapInfo.lng }} ---
      </span>
      <span>
        {{ $t('widgets.map.zoom') }}:
        {{ hook.mapManager.currentMapInfo.zoom }}
      </span>
    </div>

    <div
      v-if="hook.isInitialized"
      style="position: relative; width: 100%"
      class="fill-height"
    >
      <VerticalStackCard class="map-container" @resize="centerOnPins">
        <div ref="map" style="width: 100%; height: 100%" />
      </VerticalStackCard>

      <div style="position: absolute; top: 0; text-align: right; width: 100%">
        <TEntityToolbarAlert :value="isOverAPILimit" />

        <div style="display: inline-block">
          <EntityToolbar
            v-if="hook.isInitialized"
            :hook="hook"
            class="map--toolbar"
          >
            <template #before>
              <div class="hide-in-print-view">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      text
                      icon
                      v-on="on"
                      @click="
                        hook.mapManager.setZoom(
                          hook.mapManager.currentMapInfo.zoom + 1,
                        )
                      "
                    >
                      <v-icon small :color="satelliteColor"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.zoom_in') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      text
                      icon
                      v-on="on"
                      @click="
                        hook.mapManager.setZoom(
                          hook.mapManager.currentMapInfo.zoom - 1,
                        )
                      "
                    >
                      <v-icon small :color="satelliteColor"> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.zoom_out') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      text
                      icon
                      :loading="loading"
                      v-on="on"
                      @click="hook.mapManager.goToDefaultPosition()"
                    >
                      <v-icon small> mdi-refresh </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.return_to_default_view') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn small text icon v-on="on" @click="centerOnPins">
                      <v-icon small> mdi-image-filter-center-focus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.center_view_on_pins') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn small text icon v-on="on" @click="toggleSatellite">
                      <v-icon small :color="satelliteColor">
                        mdi-satellite-variant
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.show_satellite_map') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn small text icon v-on="on" @click="toggleCluster">
                      <v-icon small :color="clusterControlColor">
                        mdi-chart-bubble
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('widgets.map.group_pins') }}</span>
                </v-tooltip>
              </div>
            </template>
          </EntityToolbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import {
  MapTypes,
  GeoEntityModel,
  CurrentMapInfo,
} from '@/tt-widget-components/widgets/Map/BaseMapManager'
import MapWidgetHook from './MapWidgetHook'

import HereMapManager from './HereMapManager'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { updateDOM } from '@/helpers/dom'
import EntityToolbar from '@/tt-widget-entity-flow/components/EntityToolbar.vue'
import TEntityToolbarAlert from '@/tt-ui/components/TEntityToolbarAlert.vue'

export default BaseWidget.extend({
  props: {
    width: { type: String, default: null },
    height: { type: String, default: null },
    hook: {
      type: Object as PropType<MapWidgetHook>,
      required: true,
    },
  },
  components: { TEntityToolbarAlert, EntityToolbar },
  mounted() {
    this.hook.mapManager.init(
      this.$refs.map as Element,
      this.getBestMapType(false),
    )

    this.setPinsOnMap(this.hook.entities)

    const mapEngine = this.getMapEngine()
    if (mapEngine) {
      mapEngine.addEventListener('render', this.onMapRendered)
    }

    const mapManager = this.hook.mapManager as HereMapManager
    // @ts-ignore
    mapManager.clusterLayer.addEventListener('update', () => {
      this.areMarkersSet = true
    })
  },
  watch: {
    '$vuetify.theme.dark'(val) {
      this.hook.mapManager.setMapType(this.getBestMapType(this.hasSatellite()))
    },
    'hook.entities'(newEntities) {
      this.setPinsOnMap(newEntities)
    },
  },
  computed: {
    debounceRendered(): DebouncedFunc<() => void> {
      // points are added to the map after initial render
      // @todo: handle MapWidget specifically for printing https://tracktik.atlassian.net/browse/LAB-1112
      return debounce(() => {
        this.$emit('rendered')
      }, 1000)
    },
    mapType(): MapTypes {
      const withSat = this.hasSatellite()

      return this.getBestMapType(withSat)
    },
    loading(): boolean {
      return this.hook.loading
    },
    showCurrentMapInfo(): CurrentMapInfo {
      return (
        this.hook.widget.options &&
        this.hook.widget.options.showCurrentMapInfo &&
        this.hook.mapManager.currentMapInfo
      )
    },
    isOverAPILimit(): boolean {
      const isOverAPILimit = this.hook.isOverAPILimit() ?? false

      return !this.hook.loading && isOverAPILimit
    },
  },
  data() {
    return {
      satelliteColor: '',
      clusterControlColor: 'green',
      areMarkersSet: false,
    }
  },
  methods: {
    setPinsOnMap(entities: GeoEntityModel[] = []) {
      this.hook.mapManager.setItems(entities)
      if (entities.length) {
        this.centerOnPins()
      }
    },
    hasSatellite(): boolean {
      return [MapTypes.SATELLITE, MapTypes.SATELLITE_NIGHT].includes(
        this.hook.mapManager.mapType,
      )
    },
    getBestMapType(withSat: boolean) {
      if (withSat) {
        return this.$vuetify.theme.dark
          ? MapTypes.SATELLITE_NIGHT
          : MapTypes.SATELLITE
      }

      return this.$vuetify.theme.dark ? MapTypes.NIGHT : MapTypes.NORMAL
    },
    toggleSatellite() {
      const withSat = !this.hasSatellite()
      this.satelliteColor = withSat ? 'green' : ''
      this.hook.mapManager.setMapType(this.getBestMapType(withSat))
    },
    toggleCluster() {
      if (!this.hook.mapManager.ready) {
        return
      }
      const isCluster = !this.hook.mapManager.isCluster
      this.clusterControlColor = isCluster ? 'green' : ''
      this.hook.mapManager.isCluster = isCluster
    },
    positionMap() {
      this.hook.mapManager?.positionMap()
    },
    async centerOnPins() {
      await Vue.nextTick()
      this.positionMap()
      this.hook.mapManager?.centerMap()
    },
    getMapEngine(): H.map.render.p2d.RenderEngine | null {
      return (this.hook.mapManager as HereMapManager)?.map?.getEngine()
    },
    async onMapRendered(evt: any) {
      const mapEngine = this.getMapEngine()

      if (mapEngine && mapEngine === evt.target) {
        const hasData = !!this.hook.entities.length
        if (hasData && !this.areMarkersSet) {
          return
        }

        await this.$nextTick()
        await updateDOM()

        this.debounceRendered()
      }
    },
  },
  beforeDestroy() {
    const mapEngine = this.getMapEngine()
    if (mapEngine) {
      mapEngine.removeEventListener('render', this.onMapRendered)
    }
  },
})
</script>
<style>
.map--toolbar {
  display: inline-block;
  position: relative;
}

.map-container {
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.theme--dark .map-container canvas {
  background-color: #000000 !important;
}

@media print {
  .hide-in-print-view {
    display: none;
  }
}
</style>
