<template>
  <div style="display: contents">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import DashboardWidgetDragAndDropService, {
  DashboardDragAndDropProvider,
} from './DashboardWidgetDragAndDropService'
import DashboardWidgetHook from './DashboardWidgetHook'

export default Vue.extend({
  name: 'DashboardDragAndDropProvider',
  provide(): DashboardDragAndDropProvider {
    return {
      dashboardWidgetDragAndDropService: () =>
        this.dashboardWidgetDragAndDropService,
    }
  },
  props: {
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      required: true,
    },
  },
  computed: {
    dashboardWidgetDragAndDropService(): DashboardWidgetDragAndDropService {
      return new DashboardWidgetDragAndDropService(this.hook, this.$analytics)
    },
  },
})
</script>
