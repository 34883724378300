// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import TwilioAccountsTile from './TwilioAccountsTile.vue'
import TwilioAccountsColumns from './TwilioAccountsColumns'
import TwilioAccountsDetail from './TwilioAccountsDetail.vue'
import TwilioAccountsMetaCard from './TwilioAccountsMetaCard.vue'
import TwilioAccountsReference from './TwilioAccountsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('TwilioAccountsTile', TwilioAccountsTile)
    Vue.component('TwilioAccountsDetail', TwilioAccountsDetail)
    Vue.component('TwilioAccountsMetaCard', TwilioAccountsMetaCard)
    Vue.component('TwilioAccountsReference', TwilioAccountsReference)

    modularManager.addResourcePreset(
      Resources.TWILIO_ACCOUNTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'TwilioAccountsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TWILIO_ACCOUNTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: TwilioAccountsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TWILIO_ACCOUNTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'TwilioAccountsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TWILIO_ACCOUNTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'TwilioAccountsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.TWILIO_ACCOUNTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'TwilioAccountsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
