<template>
  <EntityRelationListPreview
    :entity="entity"
    :entity-id="entityId"
    :item-hook="itemHook"
    :resource-name="resource"
    :hide-entity-preview-header="true"
    :allow-actions="true"
    :allow-search="false"
    :filters="filters"
  />
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { DefaultValue } from '@/tt-widget-components'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EmployeeNotesTab',
  inject: ['getItemHook'],
  computed: {
    entityId(): number {
      return this.getItemHook().entityReference.entityId
    },
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    resource(): string {
      return Resources.EMPLOYEES
    },
    entity(): string {
      return Resources.NOTES
    },
    filters() {
      const defaultStatusFilter: DefaultValue = {
        operator: FilterOperatorType.EQUAL,
        value: false,
      }

      return [{ attributeName: 'archived', defaultValue: defaultStatusFilter }]
    },
  },
})
</script>
