<template>
  <v-badge
    color="error"
    :content="affectedShiftsTotal"
    :value="showAffectedShifts"
    overlap
  >
    <v-btn
      small
      text
      outlined
      v-bind="$attrs"
      :color="color"
      elevation="0"
      :data-cy="`leave-request-${action}-action`"
      @click.stop="applyAction(action)"
    >
      <span v-text="$t(label)" />
    </v-btn>
  </v-badge>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ApplyActionProvider } from './LeaveRequestsActionBar.vue'
import { SupportedActions } from './types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ApplyActionProvider>
).extend({
  name: 'LeaveRequestsBtnAction',
  inject: ['getItemHook', 'applyAction'],
  inheritAttrs: false,
  props: {
    action: {
      type: String as PropType<SupportedActions>,
      required: true,
    },
    affectedShiftsTotal: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    color(): string {
      const actionColorDictionary: Record<SupportedActions, string> = {
        approve: 'ttPrimary',
        'approve-cancellation': 'ttPrimary',
        cancel: 'error',
        decline: 'error',
        'decline-cancellation': 'error',
        'request-cancellation': 'error',
      }

      return actionColorDictionary[this.action]
    },
    label(): string {
      const labelDictionary: Record<SupportedActions, string> = {
        approve: 'common.approve',
        'approve-cancellation': 'common.approve',
        cancel: 'common.cancel.btn',
        decline: 'common.decline',
        'decline-cancellation': 'common.decline',
        'request-cancellation':
          'tt-entity-design.leave-requests.request-cancellation-btn',
      }

      return labelDictionary[this.action] ?? this.action
    },
    showAffectedShifts(): boolean {
      return this.affectedShiftsTotal && this.action === 'approve'
    },
  },
})
</script>
