<template>
  <CollapsibleHeader :title="$t(titleTranslationKey)" :opened="true">
    <div class="scroll--container">
      <v-skeleton-loader
        v-for="(n, index) in skeletonLoader"
        v-show="loading"
        :key="'skeleton-' + index + n"
        :loading="loading"
        type="list-item-three-line"
      />
      <div v-if="!loading">
        <LinkedUserContactsDetail v-if="userRelation" />
        <AccountContactDetails
          v-if="computedContacts.length > 0"
          class="pt-2"
          :contacts="computedContacts"
          :title="accountContactsTitle"
        />
        <SiteZonesContactsDetail
          v-if="showSitesZoneContacts"
          :zone-contacts="formatedZoneContacts"
          :title="siteZoneTitleTranslationKey"
        />
      </div>
    </div>
  </CollapsibleHeader>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import LinkedUserContactsDetail from '../components/LinkedUserContactsDetail.vue'
import AccountContactDetails from '../components/AccountContactDetails.vue'
import SiteZonesContactsDetail from '../components/SiteZonesContactsDetail.vue'
import {
  ContactApiType,
  ContactType,
  RegionContactType,
  SiteZoneContactResponseType,
  ZONE,
} from '@/tt-cta-contacts/types'
import { formatContactObject } from '@/tt-cta-contacts/utils'
import { FormatedZoneContactType } from '@/tt-entity-design/src/components/system-exception-tickets/type'
import {
  createCtaContactsManager,
  CtaContactsManager,
} from '@/tt-cta-contacts/CtaContacts'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'ContactsList',
  components: {
    LinkedUserContactsDetail,
    AccountContactDetails,
    SiteZonesContactsDetail,
  },
  inject: ['getItemHook'],
  data: () => ({
    titleTranslationKey:
      'tt-entity-design.system-exception-tickets.contacts.title',
    siteZoneTitleTranslationKey: 'contacts.zone-contacts',
    skeletonLoader: [0, 1],
    rawZoneContacts: {} as SiteZoneContactResponseType,
    formatedZoneContacts: [] as FormatedZoneContactType[],
  }),
  computed: {
    userRelation(): string {
      return this.getItemHook().getRawValue('userRelation.id')
    },
    accountType(): string {
      return this.getItemHook().getRawValue('accountRelation.type')
    },
    accountContactsTitle(): string {
      return this.accountType === ZONE
        ? 'contacts.zone-contacts'
        : 'contacts.site-contacts'
    },
    contacts(): ContactApiType[] {
      return this.getItemHook().getRawValue('accountRelation.contacts')
    },
    computedContacts(): Array<ContactType | RegionContactType> {
      const contactArray: Array<ContactType | RegionContactType> = []
      if (this.contacts && this.contacts.length > 0) {
        this.contacts.map((item) => {
          const formattedItem = formatContactObject(item)
          contactArray.push(formattedItem)
        })
      }

      return contactArray
    },
    loading(): boolean {
      return this.computedContacts && this.getItemHook().loading
    },
    showSitesZoneContacts(): boolean {
      return this.accountType !== ZONE
    },
    clientId(): string {
      return !this.showSitesZoneContacts
        ? ''
        : this.getItemHook().getRawValue('accountRelation.id')
    },
    ctaContactsManager(): CtaContactsManager {
      return createCtaContactsManager(this.$auth.getApi())
    },
  },
  watch: {
    async clientId(newValue) {
      if (newValue && newValue !== '') {
        this.rawZoneContacts = await this.fetchSiteZoneContacts()
      }
    },
    rawZoneContacts(newValue: SiteZoneContactResponseType) {
      if (newValue.zones.length > 0) {
        newValue.zones.map((item) => {
          const zoneName = item.name
          const formattedArray = [] as Array<RegionContactType | ContactType>
          item.contacts.map((contact) => {
            formattedArray.push(formatContactObject(contact))
          })
          this.formatedZoneContacts.push({
            zoneName: zoneName,
            contacts: formattedArray,
          })
        })
      }
    },
  },
  async created() {
    if (this.clientId && this.formatedZoneContacts.length === 0) {
      this.rawZoneContacts = await this.fetchSiteZoneContacts()
    }
  },
  methods: {
    async fetchSiteZoneContacts() {
      return this.ctaContactsManager.fetchSiteZonesContact(this.clientId)
    },
  },
})
</script>
<style scoped>
.scroll--container {
  max-height: 50%;
  overflow-y: auto;
  padding-right: 8px;
}
</style>
