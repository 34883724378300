<template>
  <div class="fill-height">
    <div class="d-flex align-center my-3">
      <span
        :class="[
          'font-weight-bold',
          { 'invalid-percentage': totalPercentage > 100 },
        ]"
        v-text="percentageLabel"
      />
    </div>
    <v-row>
      <v-col md="6" lg="4">
        <json-field name="splitSettings" />
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { ClientSplitInvoiceSetting } from './types'
import { VueWithFormHookInjection } from '@/helpers/components/types'

/**
 * This component is adding some validation rules to the split invoice % form
 * the idea is to have those validations as the user inputs data
 * instead of waiting for them to submit the form and find out afterwards that
 * the information provided is not valid.
 *
 * The following checks are performed:
 * 1. Duplicated clients: Each client should be a single entry in the array
 * 2. The total sum of all percentages should not be bigger than 100%
 */
export default (Vue as VueWithFormHookInjection).extend({
  name: 'ClientsSetInvoiceSplitSettings',
  inject: ['formHook'],
  computed: {
    splitSettings(): ClientSplitInvoiceSetting[] {
      return this.formHook().getPathValue('splitSettings')
    },
    totalPercentage(): number {
      return (
        this.splitSettings?.reduce((acc, setting) => {
          if (setting?.splitPercentage) {
            return acc + setting.splitPercentage
          } else {
            return acc
          }
        }, 0) ?? 0
      )
    },
    percentageLabel(): string {
      return this.$tc(
        'tt-entity-design.clients.set-invoice-split-settings.current-percentage',
        this.totalPercentage,
      )
    },
    duplicatedDestinationAccounts(): number[] {
      const existingClients = new Set()
      const duplicatedIndexes = []

      this.splitSettings?.forEach((splitSetting, index) => {
        if (!splitSetting) {
          return
        }
        if (existingClients.has(splitSetting.destinationAccount)) {
          duplicatedIndexes.push(index)
        } else {
          existingClients.add(splitSetting.destinationAccount)
        }
      })

      return duplicatedIndexes
    },
  },
  watch: {
    totalPercentage: {
      handler(percentage: number) {
        if (percentage > 100) {
          this.formHook().setCustomError(`splitSettings`, [
            {
              dataPath: '.splitSettings',
              keyword: 'maximum',
              message: this.$t(
                'tt-entity-design.clients.set-invoice-split-settings.total-percentage-error',
              ),
              params: Object,
              schemaPath: '#/components/schemas/ClientsId',
            },
          ])
        } else {
          this.formHook().setCustomError(`splitSettings`, null)
        }
      },
    },
    duplicatedDestinationAccounts: {
      handler(newIndexes, oldIndexes) {
        oldIndexes.forEach((index) => {
          this.clearDuplicateError(index)
        })

        newIndexes.forEach((index) => {
          this.createDuplicateError(index)
        })
      },
      deep: true,
    },
  },
  methods: {
    clearDuplicateError(index: number) {
      this.formHook().setCustomError(
        `splitSettings.${index}.destinationAccount`,
        null,
      )
    },
    createDuplicateError(index: number) {
      this.formHook().setCustomError(
        `splitSettings.${index}.destinationAccount`,
        [
          {
            dataPath: `.splitSettings[${index}].destinationAccount`,
            keyword: 'uniqueItems',
            message: this.$t(
              'tt-entity-design.clients.set-invoice-split-settings.duplicate',
            ),
            params: Object,
            schemaPath: '#/components/schemas/ClientsId',
          },
        ],
      )
    },
  },
})
</script>
<style scoped>
.invalid-percentage {
  color: var(--v-bg-text-red2-base);
}
</style>
