// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import ContractBillingCalendarDatesTile from './ContractBillingCalendarDatesTile.vue'
import ContractBillingCalendarDatesColumns from './ContractBillingCalendarDatesColumns'
import ContractBillingCalendarDatesDetail from './ContractBillingCalendarDatesDetail.vue'
import ContractBillingCalendarDatesReference from './ContractBillingCalendarDatesReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'ContractBillingCalendarDatesTile',
      ContractBillingCalendarDatesTile,
    )
    Vue.component(
      'ContractBillingCalendarDatesDetail',
      ContractBillingCalendarDatesDetail,
    )
    Vue.component(
      'ContractBillingCalendarDatesReference',
      ContractBillingCalendarDatesReference,
    )

    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDAR_DATES,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarDatesTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDAR_DATES,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: ContractBillingCalendarDatesColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDAR_DATES,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarDatesDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CONTRACT_BILLING_CALENDAR_DATES,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'ContractBillingCalendarDatesReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
