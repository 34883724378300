<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '../types'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'EntityValuePreload',
  inject: ['getItemHook', 'relationPrefix'],
  props: {
    attributes: {
      type: Array as () => string[],
      default: undefined,
    },
  },
  created() {
    this.attributes.forEach((attribute) => {
      this.getItemHook().addAttribute(attribute)
    })
  },
  render(h) {
    return h()
  },
})
</script>
