<template>
  <div>
    <component
      :is="component.is"
      v-for="(component, index) in componentList"
      :key="index + '-' + slotName + '-' + component.is"
      v-bind="getBinding(component)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { ExtensionComponentReference, modularManager } from '@/tt-app-modular'

export default Vue.extend({
  name: 'AppSlot',
  props: {
    propsData: { type: Object, required: true },
    slotName: { type: String, required: true },
  },
  computed: {
    componentList(): ExtensionComponentReference[] {
      return modularManager.getItems(
        this.slotName,
      ) as ExtensionComponentReference[]
    },
  },
  methods: {
    getBinding(item: ExtensionComponentReference): any {
      return { ...this.$props, ...item.props, ...this.propsData }
    },
  },
})
</script>
