<template>
  <div>
    <json-field name="person" />

    <!-- warn user about an upcoming reservation -->
    <upcoming-reservation-warning
      v-if="personFieldValue"
      :person-id="personFieldValue"
      :asset-id="assetId"
      :asset-name="assetName"
    />
    <!-- / warn user about an upcoming reservation -->

    <json-field name="employee" />
    <json-field name="expectedReturnDateTime" :min="today" />
    <json-field name="comments" />
    <json-field name="reservation" />
    <json-field
      v-show="hasCustomAttributes"
      name="customAttributes"
      :show-header="hasCustomAttributes"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import moment from 'moment'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { VueWithFormHookInjection } from '@/helpers/components/types'

import UpcomingReservationWarning from './UpcomingReservationWarning.vue'
import { isEmpty } from 'lodash'

export default (Vue as VueWithFormHookInjection).extend({
  name: 'AssetsCheckOut',
  components: { UpcomingReservationWarning },
  inject: ['formHook'],
  computed: {
    today(): string {
      return moment().format('YYYY-MM-DD')
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    assetName(): string {
      return this.itemHook.get('name')
    },
    customAttributes(): Record<string, unknown> | undefined {
      return this.formHook().getPathValue('customAttributes')
    },
    hasCustomAttributes(): boolean {
      return !isEmpty(this.customAttributes)
    },
    assetId(): number {
      return this.itemHook.getEntityId()
    },
    personFieldValue(): number {
      return this.formHook().getPathValue('person')
    },
  },
  created() {
    this.itemHook.addAttribute('name')
    this.itemHook.addAttribute('type.id')
  },
})
</script>

<style scoped>
.custom-fields-loader >>> .v-skeleton-loader__heading {
  width: 100%;
}
</style>
