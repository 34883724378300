// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileScheduleGroupsTile from './MobileScheduleGroupsTile.vue'
import MobileScheduleGroupsColumns from './MobileScheduleGroupsColumns'
import MobileScheduleGroupsDetail from './MobileScheduleGroupsDetail.vue'
import MobileScheduleGroupsMetaCard from './MobileScheduleGroupsMetaCard.vue'
import MobileScheduleGroupsReference from './MobileScheduleGroupsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('MobileScheduleGroupsTile', MobileScheduleGroupsTile)
    Vue.component('MobileScheduleGroupsDetail', MobileScheduleGroupsDetail)
    Vue.component('MobileScheduleGroupsMetaCard', MobileScheduleGroupsMetaCard)
    Vue.component(
      'MobileScheduleGroupsReference',
      MobileScheduleGroupsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_GROUPS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileScheduleGroupsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_GROUPS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileScheduleGroupsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_GROUPS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleGroupsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_GROUPS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleGroupsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_GROUPS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileScheduleGroupsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
