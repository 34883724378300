<template>
  <span>
    <AssetCategoriesIcon slot="action" size="15" default-icon="mdi-folder" />
    <tt-attr name="name" />
  </span>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AssetCategoriesReference',
})
</script>
