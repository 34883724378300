import { StatusColorType } from '@/tt-ui/types'
import { AssetStatus } from './types'

/**
 * Reservation form fields map
 */
export const RESERVATION_FORM_FIELDS = {
  startDateTime: 'startDateTime',
  endDateTime: 'endDateTime',
  agreement: 'agreement',
}

export const ASSET_TYPE = 'type'
export const ASSET_INSTRUCTIONS = 'instructions'

/**
 * Attributes names
 */
export const ATTR_DATE_IN = 'dateInDateTime'
export const ATTR_ASSET_BUNDLE = 'bundle'
export const ATTR_BE_CONTAINED_INTO_BUNDLE = 'beContainedIntoBundle'
export const ATTR_CUSTOM_ATTRIBUTES = 'customAttributes'
export const ATTR_CUSTOM_ATTRIBUTE_VALUES = 'customAttributesValues'
export const ATTR_CUSTOM_ATTRIBUTE_DISPLAY = 'customAttributesDisplay'
export const CUSTOM_ATTRIBUTE_VALUES = 'customAttributesValues'
export const CUSTOM_ATTRIBUTE_DISPLAY = 'customAttributesDisplay'
export const SERIAL_NUMBER = 'serialNumber'

/**
 * Color map for asset status
 */
export const AssetStatusColorsOptions: Record<AssetStatus, StatusColorType> = {
  [AssetStatus.ACTIVE]: {
    background: 'bg-green1',
    text: 'bg-text-green1',
  },
  [AssetStatus.PENDING]: {
    background: 'bg-orange1',
    text: 'bg-text-orange1',
  },
  [AssetStatus.INACTIVE]: {
    background: 'bg-red1',
    text: 'bg-text-red1',
  },
}
