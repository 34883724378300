<template>
  <TListContentCard style="width: 7em">
    <template #header>
      <tt-attr :name="addParentPath('startTime')" />
      -
      <tt-attr :name="addParentPath('endTime')" />
    </template>
    <template #sub-header>
      <tt-attr :name="addParentPath('beginDate')" />
    </template>
  </TListContentCard>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ShiftTemplateDateTime',
  props: {
    parentPath: {
      type: String,
      default: '',
    },
  },
  methods: {
    addParentPath(attr) {
      return this.parentPath ? `${this.parentPath}.${attr}` : attr
    },
  },
})
</script>
