<template>
  <div style="font-weight: 400">
    <div class="pl-6 pr-6 pt-0">
      <v-row no-gutters class="mt-4 mb-4">
        <v-col :cols="isShareMode ? 9 : 12">
          <SharableFormAutocomplete
            v-model="selected"
            :has-invalid-user="hasInvalidUser"
            :hidden-tags="currentAclRuleTags"
          />
        </v-col>
        <v-col v-if="isShareMode" :cols="3" class="pl-2 pt-3">
          <SharableFormPermissionSelector v-model="selectedRole" />
        </v-col>
      </v-row>
      <div v-if="!isShareMode" style="min-height: 120px">
        <v-list>
          <div
            v-for="rule in aclRuleViewItems"
            :key="`rule-view-item-${rule.tag}`"
          >
            <v-skeleton-loader
              v-if="loading || userIsLoading(rule.tag)"
              type="list-item-avatar-two-line"
            />
            <SharableFormGrantTile
              v-else
              :user-item="userForRule(rule.tag)"
              :rule="rule"
              :read-only="!canBeModified(rule)"
              @remove="removeAndUpdate(rule.tag)"
              @input="editAndUpdate(rule.tag, $event)"
            />
            <v-divider />
          </div>
        </v-list>
      </div>
    </div>
    <v-toolbar flat>
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('intents.share.note')" />
      </v-tooltip>
      <v-btn icon small @click="showRuleState = !showRuleState">
        <v-icon>mdi-code-braces</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-show="isShareMode"
        data-cy="share-add-user-button"
        :loading="loading"
        :disabled="!selectedRole || hasInvalidUser"
        color="success"
        @click="addSelected"
      >
        {{ $t(`common.save_changes.btn`) }}
      </v-btn>
      <v-btn
        v-show="!isShareMode && isModelDirty"
        :loading="loading"
        :disabled="hasInvalidUser"
        color="success"
        @click="emitUpdate"
      >
        {{ $t(`common.save_changes.btn`) }}
      </v-btn>
      <v-btn
        v-show="!isShareMode && !isModelDirty"
        plain
        class="btn-done"
        color="primary"
        :loading="loading"
        @click="$emit('close')"
      >
        {{ $t(`common.done.btn`) }}
      </v-btn>
    </v-toolbar>
    <div v-if="showRuleState">
      {{ model }}
    </div>
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Vue, { PropType } from 'vue'

import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'

import SharableFormAutocomplete from './SharableFormAutocomplete.vue'
import SharableFormGrantTile from './SharableFormGrantTile.vue'
import SharableFormPermissionSelector from './SharableFormPermissionSelector.vue'
import {
  AclRule,
  AclRuleViewItem,
  UserGroupItem,
  UserGroupMap,
  PermissionType,
  OWNER,
  THIS,
} from '../types'
import {
  addPermission,
  createRuleViewItemFromGrant,
  createRuleViewItemFromDenied,
  removePermissions,
  addMultipleNewRules,
  cleanParentRules,
} from './SharableForm.utils'
import { ShareService } from '../ShareService'

export default Vue.extend({
  name: 'SharableForm',
  components: {
    SharableFormAutocomplete,
    SharableFormPermissionSelector,
    SharableFormGrantTile,
  },
  props: {
    loading: { type: Boolean, default: false },
    ownerId: { type: Number, required: true },
    aclRule: {
      type: Object as PropType<AclRule>,
      default: (): AclRule => ({}),
    },
  },
  data() {
    return {
      model: {} as AclRule,
      showRuleState: false,
      selected: [] as UserGroupItem[],
      userGroupsMap: {} as UserGroupMap,
      selectedRole: null as PermissionType,
    }
  },
  computed: {
    ownerTag(): string {
      return this.ownerId ? `employees.id.${this.ownerId}` : null
    },
    grantedRules(): AclRule['grant'] {
      return this.model.grant || {}
    },
    deniedRules(): AclRule['deny'] {
      return this.model.deny || {}
    },
    areAllTagsTranslated(): boolean {
      const translatedTags = Object.keys(this.userGroupsMap)
      const isTranslated = (tag: string) => translatedTags.includes(tag)

      return this.currentAclRuleTags.every(isTranslated)
    },
    aclRuleViewItems(): AclRuleViewItem[] {
      const grantedRules = Object.entries(this.grantedRules).map(
        createRuleViewItemFromGrant,
      )
      const deniedRules = Object.entries(this.deniedRules).map(
        createRuleViewItemFromDenied,
      )
      const ownerRule: AclRuleViewItem = {
        tag: this.ownerTag,
        source: THIS,
        permission: OWNER,
        isLoading: false,
      }

      return [ownerRule, ...grantedRules, ...deniedRules]
    },
    currentAclRuleTags(): string[] {
      return this.aclRuleViewItems.map((item) => item.tag)
    },
    isShareMode(): boolean {
      return this.selected.length > 0
    },
    isModelDirty(): boolean {
      return !isEqual(this.model, this.aclRule)
    },
    hasInvalidUser(): boolean {
      return this.currentAclRuleTags.some(this.userIsInvalid)
    },
  },
  watch: {
    aclRule: {
      handler(aclRule: AclRule) {
        this.setModel(aclRule)

        if (!this.areAllTagsTranslated) this.updateUserGroupsMap(aclRule)
      },
      immediate: true,
    },
  },
  methods: {
    updateUserGroupsMap(aclRule: AclRule) {
      const setUserGroupsMap = (val: UserGroupMap) => {
        this.userGroupsMap = { ...this.userGroupsMap, ...val }
      }

      const onError = (error) => {
        this.$crash.captureException(error)
        const messages = parseErrorMessages({ error })
        displayErrorMessages(messages, this.$eventManager)
      }

      new ShareService(this.$appContext)
        .translateRule(cloneDeep(aclRule), this.ownerTag)
        .then(setUserGroupsMap)
        .catch(onError)
    },
    addSelected() {
      const newAclRule = addMultipleNewRules(
        this.model,
        this.selected,
        this.selectedRole,
      )
      this.setModel(newAclRule)
      this.selected = []
      this.selectedRole = null
      this.emitUpdate()
    },
    emitUpdate() {
      this.$emit('submit', cleanParentRules(this.model))
    },
    setModel(newModel: AclRule) {
      this.model = cloneDeep(newModel)
    },
    canBeModified(rule: AclRuleViewItem) {
      return rule.source === THIS && rule.permission !== OWNER
    },
    removeAndUpdate(tag: string) {
      const cleanAclRule = removePermissions(this.model, tag)
      this.setModel(cleanAclRule)
    },
    editAndUpdate(tag: string, permission: PermissionType) {
      const newAclRule = addPermission(this.model, tag, permission)
      this.setModel(newAclRule)
    },
    /**
     * UserGroupItem -> valid user
     * null          -> invalid user
     * undefined     -> still loading
     */
    userForRule(tag: string): UserGroupItem | null | undefined {
      return this.userGroupsMap[tag]
    },
    userIsInvalid(tag: string): boolean {
      return this.userForRule(tag) === null
    },
    userIsLoading(tag: string): boolean {
      return this.userForRule(tag) === undefined
    },
  },
})
</script>
<style scoped>
.btn-done >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
