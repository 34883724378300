<template>
  <!-- adding a wrapper as the tooltip will render two elements side by side -->
  <div class="topbarbtn--container">
    <v-tooltip v-bind="tooltipConfig">
      <template #activator="{ on }">
        <div class="w-100 h-100" v-on="on" @click="$emit('click', $event)">
          <slot>
            <v-btn
              tile
              text
              small
              :color="color"
              class="transparent opacity-blended topbarbtn--button"
            >
              <v-icon v-text="icon" />
            </v-btn>
          </slot>
        </div>
      </template>

      <span v-text="$t(text)" />
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  TOOLTIP_OPEN_DELAY,
  TOOLTIP_APP_BAR_NUDGE,
  TOOLTIP_APP_BAR_ZINDEX,
} from '@/helpers/constants'

export default Vue.extend({
  name: 'TopBarButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    isRendering: false,
  }),
  computed: {
    tooltipConfig(): Record<string, unknown> {
      return {
        openDelay: TOOLTIP_OPEN_DELAY,
        nudgeBottom: TOOLTIP_APP_BAR_NUDGE,
        zIndex: TOOLTIP_APP_BAR_ZINDEX,
        disabled: this.isRendering,
      }
    },
  },
})
</script>

<style scoped>
.topbarbtn--container {
  aspect-ratio: 1 / 1;
}
.topbarbtn--button {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
</style>
