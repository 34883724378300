// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointTourSessionCommentsTile from './CheckpointTourSessionCommentsTile.vue'
import CheckpointTourSessionCommentsColumns from './CheckpointTourSessionCommentsColumns'
import CheckpointTourSessionCommentsDetail from './CheckpointTourSessionCommentsDetail.vue'
import CheckpointTourSessionCommentsMetaCard from './CheckpointTourSessionCommentsMetaCard.vue'
import CheckpointTourSessionCommentsReference from './CheckpointTourSessionCommentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'CheckpointTourSessionCommentsTile',
      CheckpointTourSessionCommentsTile,
    )
    Vue.component(
      'CheckpointTourSessionCommentsDetail',
      CheckpointTourSessionCommentsDetail,
    )
    Vue.component(
      'CheckpointTourSessionCommentsMetaCard',
      CheckpointTourSessionCommentsMetaCard,
    )
    Vue.component(
      'CheckpointTourSessionCommentsReference',
      CheckpointTourSessionCommentsReference,
    )

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSION_COMMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionCommentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSION_COMMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointTourSessionCommentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSION_COMMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionCommentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSION_COMMENTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionCommentsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_SESSION_COMMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointTourSessionCommentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
