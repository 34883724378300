<template>
  <div class="pb-3">
    <v-tabs v-model="tab" height="45" slider-color="orange">
      <v-tab v-for="item in tabs" :key="item.key">
        <span v-text="item.label" />
      </v-tab>
    </v-tabs>

    <!-- Preset range -->
    <div v-if="isPresetTabSelected" style="height: 200px; overflow-y: scroll">
      <v-list dense>
        <div v-for="preset in presets" :key="preset.value">
          <v-list-item
            :data-preset-date="preset.value"
            @click="setPreset(preset.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(preset.text) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="isPresetSelected(preset)">
              <v-icon color="green"> check </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </div>

    <!-- Custom range -->
    <div v-else-if="isRangeTabSelected" class="d-flex flex-column align-center">
      <TimeZonePicker
        v-if="!hideTimezone"
        :time-zone="timezone"
        :default-time-zone="userPreferences.timeZone"
        class="level1 w-100 px-3 py-1"
        @input="setTimeZone"
      />

      <v-date-picker
        :value="dateRange"
        range
        no-title
        color="ttPrimary"
        :locale="$i18n.locale"
        @input="$emit('new-date-range', $event)"
      />

      <div v-if="startDate" class="details--container w-100">
        <div class="dates--container level0 py-1 subtitle-2">
          <v-chip
            color="var(--v-ttPrimary-lighten5)"
            text-color="ttPrimary"
            v-text="formattedStartDate"
          />

          <v-chip
            v-if="endDate"
            color="var(--v-ttPrimary-lighten5)"
            text-color="ttPrimary"
            v-text="formattedEndDate"
          />
        </div>

        <div v-if="!hideTimePicker" class="w-100 px-3 level1">
          <div class="d-flex align-center w-100">
            <TimePickerDropdown
              :value="timeStart"
              class="px-3"
              @input="$emit('update:time-start', $event)"
            />

            <TimePickerDropdown
              v-if="endDate"
              :value="timeEnd"
              class="px-3"
              @input="$emit('update:time-end', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { UserPreferenceInterface } from '@tracktik/tt-authentication'
import TimeZonePicker from './TimeZonePicker.vue'

import TimePickerDropdown from './TimePickerDropdown.vue'
import {
  DATE_PRESETS,
  DatePresetValue,
  DatePreset,
} from '../temporal-filters/date-presets'
import { Timezone } from '@/helpers/dates/timezones'
import dateFormatter from '@/helpers/formats/dates'
import { FilterDateTimeTab, FilterDateTimeTabs } from '../types'

export default Vue.extend({
  name: 'FilterDateTime',
  components: { TimeZonePicker, TimePickerDropdown },
  props: {
    presetValue: {
      type: String,
      default: null,
    },
    hidePresets: {
      type: Boolean,
      default: false,
    },
    hideTimePicker: {
      type: Boolean,
      default: false,
    },
    hideTimezone: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      default: null,
    },
    isPreset: {
      type: Boolean,
      default: false,
    },
    timeStart: {
      type: String,
      default: undefined,
    },
    timeEnd: {
      type: String,
      default: undefined,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      presets: DATE_PRESETS,
      availableTabs: [
        {
          key: FilterDateTimeTabs.PRESETS,
          label: this.$t('filter.date.preset'),
        },
        { key: FilterDateTimeTabs.RANGE, label: this.$t('filter.date.range') },
      ] as FilterDateTimeTab[],
    }
  },
  computed: {
    dateRange(): string[] {
      return [this.startDate, this.endDate].filter(Boolean)
    },
    tabs(): FilterDateTimeTab[] {
      return this.hidePresets ? this.availableTabs.slice(1) : this.availableTabs
    },
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    formattedStartDate(): string {
      return dateFormatter.asDate(
        this.startDate,
        this.userPreferences,
      ) as string
    },
    formattedEndDate(): string {
      return dateFormatter.asDate(this.endDate, this.userPreferences) as string
    },
    isPresetTabSelected(): boolean {
      return this.tabs[this.tab]?.key === FilterDateTimeTabs.PRESETS
    },
    isRangeTabSelected(): boolean {
      return this.tabs[this.tab]?.key === FilterDateTimeTabs.RANGE
    },
  },
  created() {
    this.tab = this.isPreset || this.isEmpty ? 0 : 1
  },
  methods: {
    setPreset(preset?: DatePresetValue) {
      this.$emit('new-preset', preset)
    },
    setTimeZone(timeZone: Timezone): void {
      this.$emit('update:timezone', timeZone)
    },
    isPresetSelected(preset: DatePreset): boolean {
      return this.presetValue === preset.value
    },
  },
})
</script>

<style scoped>
.dates--container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--v-ttPrimary-base);
  font-weight: bold;
}

.dates--title {
  font-size: 0.7em !important;
}
</style>
