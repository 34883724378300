export enum DashboardWidgetRowSizes {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  WRAP_CONTENT = 'WRAP_CONTENT',
}

export type WidgetPosition = {
  rowIndex: number
  colIndex: number
  tabIndex: number
}
