import Vue from 'vue'
import debounce from 'lodash/debounce'
import merge from 'lodash/merge'
import { flatten } from 'flat'

import { EventLog, PusherSdk } from '@tracktik/tt-pusher'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { AppContext } from '@/tt-app-context'
import { dateWithoutTimeAndTimezone } from '@/tt-widget-factory/helpers/parse-resource-attributes'
import { DevConsole } from '@/plugins/DevConsole'
import { DialogFormInterface, PageInterface } from '@/tt-app-layout'
import { EventLogByResource } from '@/tt-app-extensions/pusher/types'
import { Field, Formatters } from '@/tt-widget-components/schemas-types'
import { FieldTypes, SecurityRuleOperation } from '@/tt-widget-factory'
import { FormatManager } from '@/helpers/formats'
import { getDefaultFormat } from '@/tt-widget-components/helpers/getDefaultFormats'
import {
  getExtensionName,
  getExtensionPathWithoutPrefix,
  isExtension,
  isNotExtension,
} from '@/tt-widget-factory/services/resource-meta/Extensions'
import { isEmpty } from '@/helpers/isEmpty'
import { isResourceWhitelisted } from '@/tt-widget-factory/services/metadata-provider/resource-blacklist'
import { PusherEvents } from '@/tt-app-extensions/pusher/events'

import { createI18nEnumKey, ResourceTranslator } from './ResourceTranslator'
import { ENTITY_ACTION_REMOVE } from './constants'
import {
  EntityPreviewIntent,
  EntityActionIntent,
  EntityActionIntentInterface,
  EntityIntentTypes,
  EntityPreviewIntentInterface,
  EntityEditIntentInterface,
  ResourceUpdatedInterface,
} from './intents'

export interface EntityItemViewInterface {
  resourceName: string
  entityId: number
  entity?: any | undefined
  props?: Record<string, any> | undefined
  availableActions?: string[]
}

const getUniqueItems = (items: any[]) => Array.from(new Set(items))

type InternalState = {
  /**
   * The list of available actions fetched by the EntityItemHook
   */
  availableActions: string[] | null
}

type Events = {
  /**
   * External callback to run when a new list of attributes is required
   */
  onNewRequiredAttributes?: (requiredAttributes: string[]) => void

  /**
   * External callback to run when actions are requested to be fetched
   */
  onActionsNeeded?: () => void

  /**
   * External callback to run when the entity is updated.
   *
   * If used, make sure to call `beforeDestroy()` when the object is released to unbind event listeners.
   */
  onEntityUpdated?: () => void

  /**
   * External callback to run when the entity is deleted.
   *
   * If used, make sure to call `beforeDestroy()` when the object is released to unbind event listeners.
   */
  onEntityDeleted?: () => void
}

type EntityItemHookOptions = {
  fetchEnabled?: boolean
  hasFetched?: boolean
  hasFetchErrors?: boolean
  fetchActions?: boolean
  pusherSdk?: PusherSdk
  events?: Events
}

/**
 * Entity Item Hook
 */
export class EntityItemHook {
  appContext: AppContext
  entityReference: EntityItemViewInterface | undefined

  /**
   * Callbacks to be run on specific events
   */
  private events: Events

  /**
   * Single promise that can be "awaited" by multiple consumer of the itemHook when requesting actions
   */
  private fechingActionPromise: Promise<void> | null = null

  private state: InternalState = {
    availableActions: [],
  }

  private loadedData: any

  /**
   * If it is loading
   */
  public loading: boolean

  /**
   * The required properties
   */
  public required: string[] = []

  /**
   * If required attributes have already been fetched (help to correctly handle undefined values)
   */
  public hasFetched: boolean

  /**
   * If has any errors related with fetching entity information
   */
  public hasFetchErrors: boolean

  /**
   * Attributes that are loading
   */
  private flattenData: any

  /**
   * Prevent fetching the data
   */
  private readonly fetchEnabled: boolean

  private onDestroyCallbacks: (() => void)[] = []

  constructor(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
    options?: EntityItemHookOptions,
  ) {
    this.appContext = appContext

    this.fetchEnabled = options?.fetchEnabled ?? true
    this.hasFetched = options?.hasFetched ?? false
    this.hasFetchErrors = options?.hasFetchErrors ?? false

    this.events = {
      onActionsNeeded: options?.events?.onActionsNeeded,
      onNewRequiredAttributes: options?.events?.onNewRequiredAttributes,
      onEntityUpdated: options?.events?.onEntityUpdated,
      onEntityDeleted: options?.events?.onEntityDeleted,
    }

    this.entityReference = Vue.observable(entityReference)
    this.loadedData = Vue.observable(entityReference.entity || {})
    this.loading = false
    this.data = entityReference.entity || { id: entityReference.entityId }

    if (entityReference.availableActions)
      this.state.availableActions = [...entityReference.availableActions]
    else {
      if (options?.fetchActions) this.needActions()
    }

    if (this.events.onEntityUpdated || this.events.onEntityDeleted) {
      this.registerEntityUpdateEvents()
      this.registerPusherEvents()
    }
  }

  beforeDestroy(): void {
    this.onDestroyCallbacks.forEach((fn) => fn())
  }

  /**
   * Return the resources of the first level attributes that are relations or relation lists.
   */
  private getRelationResources() {
    const attributesMeta =
      this.appContext.widgetServices.resourceMetaManager.getAttributes(
        this.getResourceName(),
        0,
      )
    const isRelation = (attr) =>
      attr.type === FieldTypes.Relation || attr.type === FieldTypes.RelationList

    const resources = Object.values(attributesMeta)
      .filter(isRelation)
      .map((attr) => attr.relation?.resource)

    return [...new Set(resources)]
  }

  /**
   * Register an event listener to call the `onEntityUpdated` and `onEntityDeleted`
   * callbacks when the current entity is updated or deleted.
   *
   * Also calls `onEntityUpdated` when a related resource is updated.
   */
  private registerEntityUpdateEvents(): void {
    const stopListener = this.appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      (payload: ResourceUpdatedInterface) => {
        const isCurrentEntity = (): boolean => {
          return (
            payload.resource === this.getResourceName() &&
            String(payload.entityId) === String(this.getEntityId())
          )
        }

        const wasCurrentEntityDeleted = (): boolean =>
          payload.operation === SecurityRuleOperation.DELETE ||
          payload.actionName === ENTITY_ACTION_REMOVE

        /**
         * Ideally it would check the related entities' ids as well, but
         * `this.entity` is optional and this component doesn't share an
         * entity item hook instance with the presets, therefore we can't
         * trust the local data in that regard.
         */
        const didRelatedResourceChanged = (): boolean =>
          this.getRelationResources().some(
            (relatedResource) => payload.resource === relatedResource,
          )

        if (isCurrentEntity() || didRelatedResourceChanged()) {
          this.loadedData = { id: this.getEntityId() }
          this.flattenData = { id: this.getEntityId() }
          this.hasFetched = false

          this.events.onEntityUpdated?.()
        } else if (wasCurrentEntityDeleted()) {
          this.events.onEntityDeleted?.()
        }
      },
    )

    this.onDestroyCallbacks.push(() => stopListener())
  }

  private registerPusherEvents(): void {
    const stopListener = this.appContext.eventManager.subscribeEvent(
      PusherEvents.UPDATED_ENTITIES,
      (payload: EventLogByResource) => {
        const resourceEvents: EventLog[] = payload[this.getResourceName()] || []
        const isCurrentEntity = resourceEvents.some(
          (event) => event.entityId === String(this.getEntityId()),
        )

        if (!isCurrentEntity) return

        DevConsole.log('Pusher event received for entity', payload)

        this.loadedData = { id: this.getEntityId() }
        this.flattenData = { id: this.getEntityId() }
        this.hasFetched = false

        this.events.onEntityUpdated?.()
      },
    )

    this.onDestroyCallbacks.push(() => stopListener())
  }

  /**
   * Tells the itemHook that it needs to fetch the available actions.
   *
   * It will fetch them only if they were not previously fetched.
   * Use `force` argument to force a new fetch.
   */
  async needActions({ force }: { force?: boolean } = {}): Promise<void> {
    if (force) {
      // TODO: cancel previous request
      this.fechingActionPromise = null
    }

    // fetch if no promise started
    if (!this.fechingActionPromise) {
      this.fechingActionPromise = this.fetchAvailableActions()
    }

    this.events.onActionsNeeded?.()

    return this.fechingActionPromise
  }

  private async fetchAvailableActions(): Promise<void> {
    return this.appContext.widgetServices.resourceActionManager
      .getEntityActions(this.getResourceName(), this.getEntityId())
      .then((actions: Action[]) => {
        this.state.availableActions = actions.map((a) => a.actionName)
      })
      .catch((err) => {
        this.state.availableActions = []
        console.error('Could not fetch entity actions:', err)
      })
  }

  getAvailableActions(): string[] {
    return this.state.availableActions || []
  }

  isActionAvailable(action: string): boolean {
    return this.getAvailableActions().includes(action)
  }

  /**
   * Get the data
   */
  get data(): Record<string, any> {
    return this.loadedData || {}
  }

  getEntityId() {
    return this.loadedData.id
  }

  hasAttribute(fieldName: string): boolean {
    const { resourceMetaManager } = this.appContext.widgetServices
    return !!resourceMetaManager.getAttribute(this.getResourceName(), fieldName)
  }

  /**
   * Set an attribute
   * @param name
   * @param value
   */
  setAttribute(name: string, value: any) {
    this.data = { ...this.data, [name]: value }
  }

  /**
   * Set the data
   * @param newData
   */
  set data(newData) {
    const mergedData = merge({}, this.loadedData, newData)
    this.loadedData = Vue.observable(mergedData)
    this.flattenData = Vue.observable(flatten(mergedData, { safe: true }))
  }

  /**
   * Return a mapvalue
   * @param attribute
   * @param mapOptions
   * @param defaultValue
   */
  mapValue(attribute: string, mapOptions: any, defaultValue?: any) {
    this.addAttribute(attribute)
    const val = this.get(attribute)
    if (val === null || !mapOptions.hasOwnProperty(val)) {
      return defaultValue
    }
    return mapOptions[this.get(attribute)] as any
  }

  /**
   * Add attribute
   * @param attr
   */
  addAttribute(attr: string) {
    const isAlreadyInQueue = this.required.includes(attr)

    /**
     * API returns `null` when no values
     */
    // @TODO: review how to properly check that a JSON attribute has data
    const hasBeenFetched =
      this.flattenData?.[attr] !== undefined || this.data[attr] !== undefined

    if (!isAlreadyInQueue && !hasBeenFetched) {
      this.required.push(attr)
      this.fetchWait()
    }
  }

  /**
   * Function that ensures we don't call many times the fetch
   * when multiple components are rendering
   */
  fetchWait = debounce(async () => {
    await this.fetchIfRequired()
    this.events.onNewRequiredAttributes?.(this.required)
  }, 50)

  /**
   * Format an attribute value
   * @param attr
   * @param format
   * @param defaultValue
   */
  get(
    attr: string,
    format?: string | null | undefined,
    defaultValue?: string | undefined,
  ) {
    const path = getExtensionPathWithoutPrefix(attr)
    if (
      this.flattenData?.[path] === undefined ||
      this.flattenData[path] === null
    ) {
      return defaultValue
    }

    const meta =
      this.appContext.widgetServices.resourceMetaManager.getAttribute(
        this.entityReference.resourceName,
        attr,
      )

    let rawValue = this.getRawValue(path)

    if (meta?.rawType === 'boolean') {
      const key = createI18nEnumKey(meta.resource, meta.name, rawValue)
      return i18n.t(key)
    } else if (meta?.type === 'Date') {
      /**
       * The API returns Date attributes in a DateTime format even though their
       * time and timezone information are absent from the database, therefore
       * filling the blanks with zeros. E.g., `2015-01-15` is served as
       * `2015-01-15T00:00:00+00:00`. Depending on the user's timezone, this
       * causes the date to wrongly shift back one day while parsing or
       * formatting it.
       *
       * This method is an workaround to remove time and timezone information
       * from the date. The actual fix will come from API-1715.
       *
       * @todo: remove this method when API-1715 is done
       */
      rawValue = dateWithoutTimeAndTimezone(rawValue)
    }

    const formatName = format ?? this.getDefaultFormat(path)

    // Formatter was provided
    if (formatName && FormatManager.hasFormat(formatName)) {
      const userPreferences = {
        ...this.appContext.authModule.getUserPreferences(),
        locale: i18n.locale,
      }
      return FormatManager.parse(formatName, rawValue, userPreferences)
    }

    // Parse value by type
    return this.formatValueByType(path, rawValue)
  }

  /**
   * Get default formatters
   *
   * @param attr
   */
  public getDefaultFormat(attr: string): Formatters | null {
    return getDefaultFormat(this.getAttributeType(attr))
  }

  /**
   * Format a value by type
   *
   * @param attr
   * @param value
   */
  public formatValueByType(attr: string, value: any): any {
    const translateValue = (translatable: string): string => {
      const key =
        this.appContext.widgetServices.resourceMetaManager.getAttributeLabelKey(
          this.entityReference.resourceName,
          attr,
          FormLabelTypes.LABEL,
          translatable,
        )
      const translated = i18n.t(key)
      return key === translated ? value : translated
    }

    const matchers = {
      [FieldTypes.CSV]: () => {
        if (Array.isArray(value)) {
          return value.join(', ')
        }
      },
      [FieldTypes.Minutes]: () => {
        return `${Math.round(value)} ${i18n.t('common.minutes.suffix')}`
      },
      [FieldTypes.Hours]: () => {
        return `${value} ${i18n.t('common.hours.suffix')}`
      },
      [FieldTypes.Number]: () => {
        return value
      },
      [FieldTypes.Integer]: () => {
        return value
      },
      [FieldTypes.Decimal]: () => {
        return value
      },
      [FieldTypes.Enum]: () => {
        return Array.isArray(value)
          ? value.map(translateValue).join(', ')
          : translateValue(value)
      },
      [FieldTypes.Boolean]: () => {
        return translateValue(value)
      },
      [FieldTypes.JSON]: () => {
        return JSON.stringify(value)
      },
      default: () => {
        return value
      },
    }

    if (isEmpty(value)) {
      return
    }

    const attrType = this.getAttributeType(attr)
    const matcher =
      attrType in matchers ? matchers[attrType] : matchers['default']

    return matcher()
  }

  /**
   * Fetch the data if required
   */
  fetchIfRequired(): Promise<void> {
    if (!this.loading && this.requiresFetch()) return this.fetch()
  }

  /**
   * Get the raw value
   *
   * @param attr
   * @param defaultValue
   */
  getRawValue(attr: string) {
    return this.flattenData[attr]
  }

  /**
   * If the user can edit the attribute
   * @param name
   */
  canPatchAttribute(name: string) {
    const type = this.getAttributeType(name)
    if (['Relation', 'RelationList'].includes(type)) {
      return false
    }

    if (
      !this.appContext.authModule.hasPermission([
        `admin:${this.entityReference.resourceName}:edit`,
        `staff:${this.entityReference.resourceName}:edit`,
      ])
    ) {
      return false
    }
    const jsonSchema =
      this.appContext.widgetServices.resourceMetaManager.getPatchAttributeFormSchema(
        this.entityReference.resourceName,
        name,
      )

    return !!jsonSchema
  }

  /**
   * Get the attribute Type
   *
   * @param name
   */
  getAttributeType(name: string): FieldTypes {
    const attribute =
      this.appContext.widgetServices.resourceMetaManager.getAttribute(
        this.entityReference.resourceName,
        name,
      )
    return attribute?.type ?? FieldTypes.Label
  }

  getResourceName(): string {
    return this.entityReference.resourceName
  }

  /**
   * Get the relation resource name from a field
   *
   * @param name
   */
  getAttributeRelationResource(name: string): string {
    const attribute =
      this.appContext.widgetServices.resourceMetaManager.getAttribute(
        this.entityReference.resourceName,
        name,
      )
    return attribute?.relation?.resource
  }

  async getActionFormAsPage(
    actionName: string,
    options: Partial<EntityActionIntentInterface> = {},
  ): Promise<PageInterface> {
    const { label: title, description: subTitle } =
      ResourceTranslator.translateActionLabels(
        this.getResourceName(),
        actionName,
      )

    const intent = new EntityActionIntent(this.appContext)

    const formBuilderParams: EntityActionIntentInterface = {
      actionName,
      ...this.entityReference,
      ...options,
    }
    const formBuilder = await intent.getDialogFormBuilder(formBuilderParams)

    const payload: DialogFormInterface = {
      ...formBuilder.getState(),
      title: '',
    }

    return <PageInterface>{
      title,
      subTitle,
      is: 'GenericFormPage',
      props: { payload },
    }
  }

  // @TODO: to review, EntityItemHook should not handle the dispatch of the preview
  dispatchPreview(options: any = {}, includeHook = true) {
    if (options.activeWindow) {
      const intent = new EntityPreviewIntent(this.appContext)
      options.activeWindow.next(
        intent.getPage({
          ...this.entityReference,
          itemHook: includeHook ? this : undefined,
        } as EntityPreviewIntentInterface),
      )
      return
    }

    const getPreviewItemHook = () => {
      const hook = new EntityItemHook(this.appContext, {
        ...this.entityReference,
        availableActions: this.getAvailableActions(),
      })

      // @TODO: review why the required attributes list is needed here
      // it bypasses the normal flow
      hook.required = this.required

      return hook
    }

    this.appContext.eventManager.dispatchEvent(EntityIntentTypes.PREVIEW, {
      itemHook: includeHook ? getPreviewItemHook() : undefined,
      ...this.entityReference,
      ...options,
    })
  }

  dispatchEdit(
    options: Partial<EntityEditIntentInterface> = {},
    includeHook = true,
  ) {
    // add "entityIntent" as a service in the AppContext
    // will run a UI with form and return a promise of the result
    // then, update its own state
    // and dispatch an event for other views to react (like resource_updated event to update an unrelated datatable from the same resource)

    // if(!this.canEdit)
    //   console.warn('user cannot edit entity')

    // this.appContext.entityIntent.edit(this.resource, this.id)
    // .then((newState) => {
    //   this.state = newState
    // })
    // .catch(() => {

    // })

    this.appContext.eventManager.dispatchEvent(EntityIntentTypes.EDIT, {
      itemHook: includeHook ? this : undefined,
      ...this.entityReference,
      ...options,
    })
  }

  /**
   * Compares fields in the flatten data with the list of required
   */
  requiresFetch(): boolean {
    return !!this.getAttributesToFetch().length
  }

  getAttributesToFetch(): string[] {
    const flattenDataKeys = Object.keys(this.flattenData)

    const isNotInFlattenData = (field: string) =>
      !flattenDataKeys.includes(getExtensionPathWithoutPrefix(field))

    return getUniqueItems(this.required).filter(isNotInFlattenData)
  }

  private getUpdatedRelationListAttribute(resource: string, fields: Field[]) {
    return this.appContext.widgetServices.resourceMetaManager.getUpdatedRelationListAttribute(
      resource,
      fields,
    )
  }

  async fetch(): Promise<void> {
    if (!this.fetchEnabled) return

    // reset fetch errors state to false before every fetch
    this.hasFetchErrors = false

    const api = this.appContext.authModule.getApi()
    const list = this.getAttributesToFetch()
    const whitelisted = isResourceWhitelisted(this.getResourceName())

    const createField = (field: string) => ({ attribute: field })

    /**
     * On whitelisted resources, we remove invalid fields before the request.
     * On regular, invalid fields are kept, causing an API error by design so
     * we can capture the error earlier.
     */
    const isntWhitelisted = (field: string) =>
      !whitelisted || this.hasAttribute(field)

    const requiredFieldsString: string[] = list.filter(isNotExtension)

    const fields: Field[] = [...requiredFieldsString, 'id']
      .filter(isntWhitelisted)
      .map(createField)

    const updatedFields: Field[] = this.getUpdatedRelationListAttribute(
      this.getResourceName(),
      fields,
    )
    const extension = list
      .filter(isExtension)
      .filter(isntWhitelisted)
      .map(getExtensionName)

    const { resourceName, entityId } = this.entityReference

    const onError = (error) => {
      this.hasFetchErrors = true
      console.error('Entity item hook error', error)
      this.appContext.eventManager.dispatchEvent(EntityIntentTypes.ERROR, error)
    }

    this.loading = true

    if (resourceName === 'me') {
      this.data = await api.me({ updatedFields }).catch((error) => {
        onError(error)

        return null
      })
    } else {
      this.data = await api
        .get(resourceName, entityId, {
          //@ts-ignore We have 2 sources of truth for the type Field[]
          //the one coming from the SDK, modifier is an enum and the one from the project modifier is a string
          fields: updatedFields,
          extension,
        })
        .catch((error) => {
          onError(error)

          return null
        })
    }

    this.required = []
    this.loading = false
    this.hasFetched = true
  }
}
