// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import CheckpointTourAssignmentsTile from './CheckpointTourAssignmentsTile.vue'
import CheckpointTourAssignmentsColumns from './CheckpointTourAssignmentsColumns'
import CheckpointTourAssignmentsDetail from './CheckpointTourAssignmentsDetail.vue'
import CheckpointTourAssignmentsReference from './CheckpointTourAssignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'CheckpointTourAssignmentsTile',
      CheckpointTourAssignmentsTile,
    )
    Vue.component(
      'CheckpointTourAssignmentsDetail',
      CheckpointTourAssignmentsDetail,
    )
    Vue.component(
      'CheckpointTourAssignmentsReference',
      CheckpointTourAssignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_ASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'CheckpointTourAssignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_ASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: CheckpointTourAssignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_ASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'CheckpointTourAssignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.CHECKPOINT_TOUR_ASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'CheckpointTourAssignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
