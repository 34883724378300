<template>
  <div>
    <TAlert class="mb-4" :text="cancelPaidTimeOffMessage" />
    <json-field name="reason" />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LeaveRequestsApproveCancellation',
  computed: {
    cancelPaidTimeOffMessage(): string {
      return this.$t('tt-entity-design.leave-requests.cancel-pto-message')
    },
  },
})
</script>
