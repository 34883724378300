// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EmployeeExtraRegionsTile from './EmployeeExtraRegionsTile.vue'
import EmployeeExtraRegionsColumns from './EmployeeExtraRegionsColumns'
import EmployeeExtraRegionsDetail from './EmployeeExtraRegionsDetail.vue'
import EmployeeExtraRegionsReference from './EmployeeExtraRegionsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EmployeeExtraRegionsTile', EmployeeExtraRegionsTile)
    Vue.component('EmployeeExtraRegionsDetail', EmployeeExtraRegionsDetail)
    Vue.component(
      'EmployeeExtraRegionsReference',
      EmployeeExtraRegionsReference,
    )

    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTRA_REGIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EmployeeExtraRegionsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTRA_REGIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EmployeeExtraRegionsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTRA_REGIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EmployeeExtraRegionsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.EMPLOYEE_EXTRA_REGIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EmployeeExtraRegionsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
