import {
  ActionMenuConfig,
  ActionMenuDefinitions,
  LayoutWindowEvent,
} from '@/tt-app-layout/types'
import { ComponentReference } from '@/tt-widget-components'

export class NavigationCategory {
  public data = {
    items: [],
    header: {},
  } as ActionMenuConfig

  setLabel(label: string): NavigationCategory {
    this.data.header = {
      text: label,
    }

    return this
  }

  addItem(item: ActionMenuDefinitions) {
    this.data.items.push(item)
  }

  addComponentItem(component: ComponentReference) {
    this.addItem({
      action: {
        type: 'component',
        props: {
          // @ts-ignore
          is: component.is,
          ...component.props,
        },
      },
    })
  }

  addExternalRouteItem(
    label: string,
    href: string,
    icon?: string,
    iconColor?: string,
    openInNewTab = true,
  ) {
    this.addItem({
      icon,
      color: iconColor,
      text: label,
      action: {
        type: 'externalRoute',
        props: {
          href,
          openInNewTab,
        },
      },
    })
  }

  addRouteItem(
    label: string,
    routeName: string,
    icon?: string,
    iconColor?: string,
  ) {
    this.addItem({
      icon,
      color: iconColor,
      text: label,
      action: {
        type: 'route',
        props: {
          name: routeName,
        },
      },
    })
  }

  setNoHeader() {
    delete this.data.header
  }

  setIcon(icon: string, iconColor: string = null): NavigationCategory {
    this.data.header.icon = icon
    this.data.header.color = iconColor

    return this
  }

  toConfig() {
    return this.data
  }

  addDispatchItem(
    label: string,
    event: { payload: {}; eventName: LayoutWindowEvent },
    icon: string,
    iconColor: string,
  ) {
    this.addItem({
      text: label,
      color: iconColor,
      icon,
      action: {
        type: 'dispatch',
        props: event,
      },
    })
  }
}

export class ActionMenuBuilder extends NavigationCategory {
  private categories: NavigationCategory[] = []

  public toNavigation(): ActionMenuConfig[] {
    return this.categories.map((category: NavigationCategory) => {
      return category.toConfig()
    })
  }

  addCategory(
    label: string = null,
    icon: string = null,
    iconColor: string = null,
  ): NavigationCategory {
    const category = new NavigationCategory()
    if (label) {
      category.setLabel(label)
      category.setIcon(icon, iconColor)
    } else {
      category.setNoHeader()
    }

    this.categories.push(category)

    return category
  }
}
