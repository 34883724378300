<template>
  <div>
    <template v-if="entities && entities.length > 0">
      <component
        :is="container"
        v-bind="$attrs"
        class="d-flex flex-column fill-height"
      >
        <template v-for="(item, index) in items">
          <ProvideItemHook
            :key="JSON.stringify(item.itemHook.entityReference)"
            :provide="item.itemHook"
          >
            <slot :item-hook="item.itemHook" :index="index" />
          </ProvideItemHook>
        </template>
      </component>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityRecord } from '../types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

export default Vue.extend({
  name: 'EntityLooper',
  inheritAttrs: false,
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    container: {
      type: String,
      default: 'div',
    },
    actions: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
    entities: {
      type: Array as PropType<EntityRecord[]>,
      default: () => [],
    },
  },
  computed: {
    items(): EntityRecord[] | null {
      if (!this.entities.length) {
        return null
      }

      return this.entities.map((item: EntityRecord) => ({
        ...item,
        itemHook: new EntityItemHook(
          this.$appContext,
          {
            entity: item,
            resourceName: this.resourceName,
            entityId: item.id,
            availableActions: this.actions[item.id],
          },
          {
            fetchEnabled: false,
            hasFetched: true,
          },
        ),
      }))
    },
  },
})
</script>
