<template>
  <v-row class="sticky-toolbar" align="center">
    <v-col cols="auto">
      <v-subheader v-text="$tc('dashboard_views.count', count, { count })" />
    </v-col>
    <v-col class="d-flex justify-end">
      <TSearchInput
        :value="value"
        style="width: 200px"
        track-analytics
        @input="$emit('input', $event)"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'

export default Vue.extend({
  name: 'ToolbarDashboardView',
  components: {
    TSearchInput,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    value: {
      type: Text,
      default: '',
    },
  },
})
</script>
