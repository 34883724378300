import {
  CollectionQuery,
  Field,
  MarkerCollectionModel,
} from '@/tt-widget-components/schemas-types'
import CollectionWidgetHook from '../../base/CollectionWidgetHook'
import {
  Marker,
  MarkerId,
} from '@tracktik/tt-maps/lib/src/MarkerManager/MarkerManager'
import { WidgetHookDependencies } from '@/tt-widget-factory/types'
import { MapManager } from '@tracktik/tt-maps'
import { reactive, watch } from 'vue'
import debounce from 'lodash/debounce'
import { FilterOperatorType } from '@/tt-widget-factory/definitions'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

type MapCollectionDeps = {
  mapManager: MapManager
}

// @ts-ignore -- Type 'MarkerCollectionModel' does not satisfy the constraint 'WidgetModels'.
// https://tracktik.atlassian.net/browse/FE-1411
export default class MarkerCollectionWidgetHook extends CollectionWidgetHook<MarkerCollectionModel> {
  private mapManager: MapManager

  constructor(deps: WidgetHookDependencies, { mapManager }: MapCollectionDeps) {
    super(deps)
    this.mapManager = mapManager
  }

  markersState = reactive({
    activeMarkerIds: [] as MarkerId[],
  })

  private debounceUpdate = debounce(() => {
    this.update()
  }, 100)

  async initialize() {
    super.initialize()
    this.unsubscribeFunctions.push(
      this.services.eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW_CLOSE,
        ({ entityId }) => {
          this.removeActiveMarker(entityId)
        },
      ),
    )
    this.unsubscribeFunctions.push(
      this.services.eventManager.subscribeEvent(
        EntityIntentTypes.PREVIEW,
        ({ entityId }) => {
          this.setActiveMarker(entityId)
        },
      ),
    )
  }

  setup() {
    const boundingBoxWatcher = watch(
      () => this.mapManager.getBoundingBox(),
      () => this.update(),
    )

    this.queryManager.setWhereQL(
      `${this.getLatitudeAttribute()} <> 0 AND ${this.getLongitudeAttribute()} <> 0`,
    )

    this.unsubscribeFunctions.push(boundingBoxWatcher)
  }

  setActiveMarker(id: MarkerId): void {
    this.markersState.activeMarkerIds.push(id)
  }

  removeActiveMarker(id: MarkerId): void {
    const activeMarkerIds = this.markersState.activeMarkerIds.filter(
      (activeMarkerId) => activeMarkerId !== id,
    )
    this.markersState.activeMarkerIds = [...activeMarkerIds]
  }

  getMarkers(): Marker[] {
    const markers = this.entities?.map(
      (entity): Marker =>
        this.markersState.activeMarkerIds.includes(entity.id)
          ? {
              id: entity.id,
              position: [entity.lng, entity.lat],
              active: true,
            }
          : {
              id: entity.id,
              position: [entity.lng, entity.lat],
              active: false,
            },
    )

    return markers ?? []
  }

  getLongitudeAttribute(): string {
    return this.widget.geoMapOptions.longitudeAttribute
  }

  getLatitudeAttribute(): string {
    return this.widget.geoMapOptions.latitudeAttribute
  }

  private applyCoordinatesFilter(): void {
    const boundingBox = this.mapManager.getBoundingBox()

    if (boundingBox) {
      /**
       * If we know the bounding box, we want to fetch only the items that are within it.
       */
      this.queryManager.setCustomFilter({
        attribute: this.getLongitudeAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [boundingBox.southWest[0], boundingBox.northEast[0]],
      })
      this.queryManager.setCustomFilter({
        attribute: this.getLatitudeAttribute(),
        operator: FilterOperatorType.BETWEEN,
        value: [boundingBox.southWest[1], boundingBox.northEast[1]],
      })
    }
  }

  protected get effectiveQuery(): CollectionQuery {
    const fields: Field[] = [
      {
        attribute: 'id',
      },
      {
        attribute: this.getLatitudeAttribute(),
        alias: 'lat',
      },
      {
        attribute: this.getLongitudeAttribute(),
        alias: 'lng',
      },
    ]

    this.applyCoordinatesFilter()

    this.queryManager.setFieldsAndExtensionsFromAttributes(this.getAttributes())

    // @TODO: catch when count is higher than limit, and display a message to the user
    return { ...this.queryManager.query, fields, limit: 1000 }
  }

  destroy(): void {
    this.debounceUpdate.cancel()
    super.destroy()
  }
}
