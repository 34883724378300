<template>
  <v-dialog
    v-model="dialog"
    :max-width="250"
    :scrollable="!$vuetify.breakpoint.xsOnly"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :close-on-content-click="false"
    :close-on-click="false"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card flat class="level1">
      <v-toolbar flat color="level0" short dense>
        <v-toolbar-title
          style="font-size: 0.9em"
          v-text="$t('widgets.dashboard.row_sizes')"
        />
        <v-spacer />
        <v-btn small icon @click="dialog = false">
          <v-icon small v-text="`close`" />
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-radio-group
            :value="selectedSize"
            @change="$emit('change', $event)"
          >
            <v-radio
              v-for="size in sizes"
              :key="size"
              class="py-1"
              :value="size"
            >
              <template #label>
                <span v-text="$t(`widgets.dashboard.sizes.${size}`)" />
              </template>
            </v-radio>
          </v-radio-group>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'RowSizeDialog',
  props: {
    sizes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedSize: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
})
</script>
