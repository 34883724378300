import {
  Attribute,
  AttributeFilterInterface,
} from '@/tt-widget-factory/services/resource-meta/types'
import { attributeTypeIconMap } from '@/tt-widget-components/lib/attribute-icons'
import { sortAttributesNamesObjects } from '@/tt-widget-components/helpers'
import { ResourceMetaManagerInterface } from '@/tt-widget-factory'

export interface ResourceAttributeSelectorItem {
  icon: string
  text: string
  value: string
  opacity: number
  color: string
  description: string
  deprecated: boolean
}

export type ResourceAttributeSelectorFilter = (
  attr: AttributeFilterInterface,
) => boolean

const createFilterObject = ([absoluteName, attribute]: [
  string,
  Attribute,
]): AttributeFilterInterface => ({ absoluteName, attribute })

const createSelectorItem = (
  attributeFilter: AttributeFilterInterface,
): ResourceAttributeSelectorItem => {
  const levels = attributeFilter.absoluteName.split('.').length
  const icon =
    attributeTypeIconMap[attributeFilter.attribute.type] ??
    attributeTypeIconMap['default']

  return {
    icon,
    text: attributeFilter.absoluteName,
    value: attributeFilter.absoluteName,
    opacity: (10 - (levels - 1) * 3) / 10,
    color: levels > 1 ? 'blue' : 'green',
    description: attributeFilter.attribute.labels.description || '',
    deprecated: !!attributeFilter.attribute.deprecated,
  }
}

/**
 * Get a list of resource attribute to be used in a list
 * @param resourceMetaManager
 * @param resource
 * @param filter
 */
export const getResourceAttributesForList = function (
  resourceMetaManager: ResourceMetaManagerInterface,
  resource: string,
  filter: ResourceAttributeSelectorFilter = () => true,
): ResourceAttributeSelectorItem[] {
  const attributesMap = resourceMetaManager.getAttributes(resource, 2)

  if (!attributesMap) {
    return []
  }

  return Object.entries(attributesMap)
    .map(createFilterObject)
    .filter(filter)
    .map(createSelectorItem)
    .sort(sortAttributesNamesObjects)
}
