// Enter your custom installation here

import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'
import { Resources } from '../../types'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'

const createRelationListTab = (attributeName: string): PreviewTabInterface => ({
  title: createI18nResourceKey(Resources.LEAVE_POLICIES, attributeName),
  is: 'EntityRelationListCustomPreviewWrapper',
  props: {
    attributeName,
  },
})

export default {
  install(Vue) {
    const LogTab = createRelationListTab('carryOverLogs')
    addPreviewTab(Resources.LEAVE_POLICIES, LogTab)
  },
}
