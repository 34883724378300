<template>
  <TAlert :text="cancelMessage" />
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LeaveRequestsCancelForm',
  computed: {
    cancelMessage(): string {
      return this.$t(
        'tt-entity-design.leave-requests.cancel-leave-request-message',
      )
    },
  },
})
</script>
