// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetManufacturersTile from './AssetManufacturersTile.vue'
import AssetManufacturersColumns from './AssetManufacturersColumns'
import AssetManufacturersDetail from './AssetManufacturersDetail.vue'
import AssetManufacturersMetaCard from './AssetManufacturersMetaCard.vue'
import AssetManufacturersReference from './AssetManufacturersReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetManufacturersTile', AssetManufacturersTile)
    Vue.component('AssetManufacturersDetail', AssetManufacturersDetail)
    Vue.component('AssetManufacturersMetaCard', AssetManufacturersMetaCard)
    Vue.component('AssetManufacturersReference', AssetManufacturersReference)

    modularManager.addResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetManufacturersTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetManufacturersColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetManufacturersDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'AssetManufacturersMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_MANUFACTURERS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetManufacturersReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
