<template>
  <OverflowBox>
    <EntityToolbarFilterButton
      v-for="filterSetting in filters"
      :key="filterSetting.attributeName"
      v-model="model[filterSetting.attributeName]"
      :attribute-meta="filterSetting.attributeMeta"
      :menu-type="entityToolbarFilterMenuType"
      :is-context-filter="checkContextFilter"
      class="transparent"
      @apply="apply(filterSetting, $event)"
    >
      <template #button-left="{ selected }">
        <v-icon
          class="mr-2"
          small
          :style="{ opacity: selected ? 1 : 0.6 }"
          :color="selected ? 'blue' : 'green'"
          v-text="filterSetting.icon"
        />
      </template>
    </EntityToolbarFilterButton>

    <template #overflow>
      <EntityFilterDialog v-model="model" :filters="filters" @apply="apply">
        <template #activator="{ on, dirtyFilterCount }">
          <v-btn small text v-on="on">
            <v-icon small class="mr-2">mdi-filter-variant</v-icon>
            {{ $t('common.filters') }}
            <v-badge v-if="!!dirtyFilterCount" color="green" dot overlap />
          </v-btn>
        </template>
      </EntityFilterDialog>
    </template>
  </OverflowBox>
</template>

<script lang="ts">
import { PropType } from 'vue'

import EntityFilterDialog from '@/tt-entity-filter/components/EntityFilterDialog.vue'
import EntityToolbarFilterButton from '@/tt-entity-filter/components/EntityToolbarFilterButton.vue'
import FilterValueParser from '@/tt-entity-filter/FilterValueParser'
import {
  ChooseAccountFromTopFilter,
  ChooseDateRangeFromTopFilter,
  ChooseRegionFromTopFilter,
} from '@/plugins/o11n'
import { FilterSetting } from '@/tt-entity-filter/types'

import { AttributeName, WidgetName } from '../lib/names'
import { ObjectInput } from '../ObjectInput'

export default ObjectInput.extend({
  name: 'WidgetContextToolbarFilters',
  components: {
    EntityToolbarFilterButton,
    EntityFilterDialog,
  },
  props: {
    filters: {
      type: Array as PropType<FilterSetting[]>,
    },
    pageName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      analyticsEventsByAttributeName: new Map([
        [AttributeName.ACCOUNT_ATTRIBUTE, ChooseAccountFromTopFilter],
        [AttributeName.DATE_RANGE_ATTRIBUTE, ChooseDateRangeFromTopFilter],
        [AttributeName.REGION_ATTRIBUTE, ChooseRegionFromTopFilter],
      ]),
    }
  },
  computed: {
    entityToolbarFilterMenuType(): 'v-menu' | 'v-dialog' {
      return this.$vuetify.breakpoint.mdAndUp ? 'v-menu' : 'v-dialog'
    },
    defaultModel(): any {
      return this.value
    },
    checkContextFilter(): boolean {
      return this.pageName !== WidgetName.TQL_QUERY_WIDGET
    },
  },
  methods: {
    clear() {
      this.model = {}
      // Let the models propagate
      this.$nextTick(() => {
        this.applyAndClose()
      })
    },
    applyAndClose() {
      this.$emit('update', this.model)
    },
    apply(
      filterSetting: FilterSetting,
      value: ReturnType<(typeof FilterValueParser)['getFilterValue']>,
    ): void {
      // @todo: payload received by EntityFilterDialog @apply is empty
      this.trackChooseEvent(
        filterSetting?.attributeName as AttributeName,
        value,
      )
      this.$emit('update', this.model)
    },
    trackChooseEvent(
      attributeName: AttributeName,
      value: ReturnType<(typeof FilterValueParser)['getFilterValue']>,
    ): void {
      const eventBuilder =
        this.analyticsEventsByAttributeName.get(attributeName)
      if (eventBuilder) {
        const label = value == null ? '' : value.toString()
        const event = eventBuilder.create({ label })
        this.$analytics.track(event)
      }
    },
  },
})
</script>
