<template>
  <div>
    <v-alert
      dense
      text
      type="warning"
      icon="mdi-alert-outline"
      color="orange darken-4"
      class="ma-3"
    >
      <v-row align="center">
        <v-col class="grow">
          <span
            class="pr-1"
            v-text="$t('components.widget_error_new_version_available.text')"
          />
          <a
            class="link-background-title font-weight-bold"
            @click="refresh()"
            v-text="
              $t('components.widget_error_new_version_available.here_button')
            "
          />
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WidgetErrorNewVersionAvailable',
  methods: {
    refresh(): void {
      window.location.reload()
    },
  },
})
</script>

<style scoped>
.link-background-title {
  color: #29b6f6;
  text-decoration: underline;
}
</style>
