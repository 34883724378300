import { RegionResponse } from 'tracktik-sdk/lib/entities/region'
import { UserRegion } from '../tt-widget-components/components/treeview/utils'
import { ApiRegion, ROOT_REGION_ID, RegionId } from './types'
import { Resources } from '../tt-entity-design/src/types'
import { AuthModule } from '@tracktik/tt-authentication'

type RegionsMap = Record<string, UserRegion>
/**
 * Filter out duplicate regions from a given list of regions.
 */
export const getUniqueRegions = (regions: UserRegion[]): UserRegion[] => {
  const byId = (acc: RegionsMap, curr: UserRegion): RegionsMap => ({
    ...acc,
    [curr.id]: curr,
  })

  return Object.values(regions.reduce(byId, {}))
}

export const isActive = (region: UserRegion) => region.status === 'ACTIVE'

/**
 * Creates a filter function to search for a region ID inside a region array.
 *
 * ie:
 * ```
 * const HQ = regions.find(isRegion(2))
 * ```
 */
export const isRegion = (id: RegionId) => (region: UserRegion) =>
  region.id === id

/**
 * Given an API region object, returns its parent region, in front-end `UserRegion` type
 */
export const createRegionFromParent = ({
  parentRegion,
}: ApiRegion): UserRegion => ({
  id: `${parentRegion.id}`,
  name: parentRegion.name,
  status: parentRegion.status,
  parentId: `${parentRegion.parentRegion}`,
})

const handlerError = (err) => {
  console.warn('Could not fetch the missing regions.', err)
  return { items: [] }
}

/**
 * If attribute `parentRegion` is present
 */
const hasParent = (region: ApiRegion) => !!region.parentRegion

/**
 * TTC Api always have a top region ID 1 named "Root" which should not be shown neither used.
 */
const isNotRootRegion = (region: UserRegion) =>
  region.id !== `${ROOT_REGION_ID}`

/**
 * Helper method to fetch regions a user does not have access.
 *
 * ie given this tree of a portal regions:
 * A
 * |__B
 * |__C
 *    |__D
 *
 *
 * If user has access to A,B & D but not C, we will fetch the parent of D to get C.
 */
export const fetchMissingParentRegions = async (
  authModule: AuthModule,
  regionIds: UserRegion['id'][],
) => {
  const { items } = await authModule
    .getApi()
    .getAll<RegionResponse>(Resources.REGIONS, {
      include: ['parentRegion'],
      filters: [{ attribute: 'id', operator: 'IN', value: regionIds }],
    })
    .catch(handlerError)

  const parentRegions = items
    .filter(hasParent)
    .map(createRegionFromParent)
    .filter(isNotRootRegion)

  return getUniqueRegions(parentRegions)
}

/**
 * Returns an array of all sub-regions for a given region
 */
export const getChildrenRegions = (
  regions: UserRegion[],
  parentId: string,
): UserRegion[] => {
  const isDirectChildrenOfParentId = (region) => region.parentId === parentId
  const getRegionAndItsSubregions = (region) => [
    region,
    ...getChildrenRegions(regions, region.id),
  ]

  return regions
    .filter(isDirectChildrenOfParentId)
    .flatMap(getRegionAndItsSubregions)
}
