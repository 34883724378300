<template>
  <tt-attr name="type">
    <template #default="{ rawValue, value }">
      <v-chip
        v-if="layout === layouts.CHIP"
        class="chip"
        :color="typeColor(rawValue)"
        label
        text-color="white"
        :title="label(value)"
        x-small
        v-text="label(value)"
      />
      <v-avatar
        v-else-if="layout === layouts.AVATAR"
        :color="typeColor(rawValue)"
        size="60"
      >
        <v-icon color="white">mdi-playlist-check</v-icon>
      </v-avatar>
    </template>
  </tt-attr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import {
  getSiteTaskScheduleTypeColor,
  SiteTaskScheduleType,
} from './site-task-schedule-type'

const layouts = {
  AVATAR: 'AVATAR',
  CHIP: 'CHIP',
} as const

const localeScope = 'tt-entity-design.site-task-schedules'

export default Vue.extend({
  name: 'SiteTaskScheduleType',
  props: {
    layout: {
      type: String as PropType<keyof typeof layouts>,
      default: layouts.CHIP,
    },
  },
  computed: {
    layouts(): typeof layouts {
      return layouts
    },
  },
  methods: {
    label(typeLabel: string): string {
      return typeLabel || this.$t(`${localeScope}.new`)
    },
    typeColor(type: SiteTaskScheduleType): string {
      return getSiteTaskScheduleTypeColor(type)
    },
  },
})
</script>

<style scoped>
.chip {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
