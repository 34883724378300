// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import MobileScheduleOccurrenceReassignmentsTile from './MobileScheduleOccurrenceReassignmentsTile.vue'
import MobileScheduleOccurrenceReassignmentsColumns from './MobileScheduleOccurrenceReassignmentsColumns'
import MobileScheduleOccurrenceReassignmentsDetail from './MobileScheduleOccurrenceReassignmentsDetail.vue'
import MobileScheduleOccurrenceReassignmentsMetaCard from './MobileScheduleOccurrenceReassignmentsMetaCard.vue'
import MobileScheduleOccurrenceReassignmentsReference from './MobileScheduleOccurrenceReassignmentsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component(
      'MobileScheduleOccurrenceReassignmentsTile',
      MobileScheduleOccurrenceReassignmentsTile,
    )
    Vue.component(
      'MobileScheduleOccurrenceReassignmentsDetail',
      MobileScheduleOccurrenceReassignmentsDetail,
    )
    Vue.component(
      'MobileScheduleOccurrenceReassignmentsMetaCard',
      MobileScheduleOccurrenceReassignmentsMetaCard,
    )
    Vue.component(
      'MobileScheduleOccurrenceReassignmentsReference',
      MobileScheduleOccurrenceReassignmentsReference,
    )

    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceReassignmentsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: MobileScheduleOccurrenceReassignmentsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceReassignmentsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceReassignmentsMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'MobileScheduleOccurrenceReassignmentsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
