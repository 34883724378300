// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import AssetFieldsetFieldsTile from './AssetFieldsetFieldsTile.vue'
import AssetFieldsetFieldsColumns from './AssetFieldsetFieldsColumns'
import AssetFieldsetFieldsDetail from './AssetFieldsetFieldsDetail.vue'
import AssetFieldsetFieldsReference from './AssetFieldsetFieldsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('AssetFieldsetFieldsTile', AssetFieldsetFieldsTile)
    Vue.component('AssetFieldsetFieldsDetail', AssetFieldsetFieldsDetail)
    Vue.component('AssetFieldsetFieldsReference', AssetFieldsetFieldsReference)

    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSET_FIELDS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetFieldsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSET_FIELDS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: AssetFieldsetFieldsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSET_FIELDS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetFieldsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ASSET_FIELDSET_FIELDS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'AssetFieldsetFieldsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
