import Vue, { VNodeData, VueConstructor } from 'vue'

export default (component: VueConstructor | any, props: VNodeData['props']) => {
  return Vue.extend({
    name: `With${component.name}`,
    functional: true,
    render(createElement, context) {
      return createElement(component, {
        ...context,
        on: context.listeners,
        props: {
          ...context.props,
          ...props,
        },
      })
    },
  })
}
