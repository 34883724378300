<template>
  <v-list-item-title v-bind="$attrs" @click.stop="actionTrigger(action)">
    <v-icon left :color="color" small>{{ icon }}</v-icon>
    {{ label }}
  </v-list-item-title>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ActionTriggerProvider } from './ActionTriggerProvider.vue'
import { FileActions } from './types'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ActionTriggerProvider>
).extend({
  name: 'FileInlineBtnActions',
  inject: ['getItemHook', 'actionTrigger'],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    action: {
      type: String as PropType<FileActions>,
      required: true,
    },
  },
  computed: {
    color(): string {
      const actionColorDictionary: Record<FileActions, string> = {
        download: 'blue',
        archive: 'error',
        view: 'red',
      }

      return actionColorDictionary[this.action]
    },
    icon(): string {
      const actionIconDictionary: Record<FileActions, string> = {
        download: 'mdi-download',
        archive: 'mdi-minus-circle-outline',
        view: 'mdi-file-eye',
      }

      return actionIconDictionary[this.action] ?? ''
    },
    label(): string {
      const labelDictionary: Record<FileActions, string> = {
        download: 'common.download.btn',
        archive: 'common.archive.btn',
        view: 'common.view.btn',
      }

      return this.$t(labelDictionary[this.action] ?? this.action)
    },
  },
})
</script>
