import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  'avatar',
  'customId',
  'firstName',
  'lastName',
  'skills',
  'jobTitle',
  'email',
  'primaryPhone',
  'region',
] as ColumnInputDefinition[]
