import {
  DataSetWidgetSchemaConfig,
  DataSetWidgetSchemaConfigValue,
  DataSetWidgetSchemaGenerator,
} from '../../lib/DataSetWidgetSchemaGenerator'

const MetricTableWidgetBuilder = new DataSetWidgetSchemaGenerator(
  'MetricTableWidget',
  'Table',
  {
    count: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    measure: DataSetWidgetSchemaConfigValue.ONE_PLUS,
    dimension: DataSetWidgetSchemaConfigValue.ONE_ONLY,
    toolbar: true,
  } as DataSetWidgetSchemaConfig,
)
MetricTableWidgetBuilder.setSchemaAdditionalProperties({
  options: {
    title: 'Options',
    $ref: '#/definitions/MetricTableOptions',
  },
  allowDownload: {
    $ref: '#/definitions/AllowDownloadCsvPdf',
  },
})
MetricTableWidgetBuilder.addDefinitions({
  MetricTableOptions: {
    title: 'Customization Options',
    type: 'object',
    properties: {
      disableClick: {
        title: 'Disable Click',
        $ref: '#/definitions/DisableClick',
      },
      headerBackgroundColor: {
        title: 'Header Background Color',
        $ref: '#/definitions/Color',
      },
      headerTextColor: {
        title: 'Header Text Color',
        $ref: '#/definitions/Color',
      },
      bodyBackgroundColor: {
        title: 'Body Background Color',
        $ref: '#/definitions/Color',
      },
      bodyTextColor: {
        title: 'Body Text Color',
        $ref: '#/definitions/Color',
      },
    },
  },
})

export default MetricTableWidgetBuilder.schema
