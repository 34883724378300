<template>
  <v-card :class="`${colorText}--text text--darken-2 py-2`" flat>
    <div class="font-weight-bold text-uppercase">
      <span class="mr-1" v-text="'SLA'" />
      <span v-text="formatedSlaMinutes" />
    </div>
    <div
      class="d-flex align-center justify-space-between text-h6 text-capitalize font-weight-bold"
    >
      <component
        :is="component.is"
        v-bind="{ ...component.props }"
        @update="countdownFinished = true"
      />
      <v-icon :color="`${colorText} darken-2`" v-text="slaIcon" />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import DispatchTasksSlaCountdown from './DispatchTasksSlaCountdown.vue'
import DispatchTasksSlaCountup from './DispatchTasksSlaCountup.vue'
import DispatchTasksSlaTime from './DispatchTasksSlaTime.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { convertDateToTimeStamp } from '@/helpers/dates/convertDateToTimeStamp'
import { formatMinutesToTimeObject } from '@/helpers/dates/formatMinutesToTimeObject'
import { formatHoursMinutes } from '../utils'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksSlaAttr',
  components: {
    DispatchTasksSlaCountdown,
  },
  inject: ['getItemHook'],
  data() {
    return {
      countdownFinished: false,
    }
  },
  computed: {
    isSlaCountingDown(): boolean {
      return this.getItemHook().getRawValue('sla.countingDown')
    },
    slaIcon(): string {
      return this.isSlaCountingDown
        ? 'mdi-arrow-down-thin'
        : 'mdi-arrow-up-thin'
    },
    slaStartedOn(): string {
      return this.getItemHook().getRawValue('sla.slaStartedOn') || '0'
    },
    slaMinutes(): number {
      const slaMinutes: number =
        this.getItemHook().getRawValue('sla.slaMinutes')
      if (!slaMinutes) return 0

      return slaMinutes
    },
    currentSla(): number {
      return this.getItemHook().getRawValue('sla.currentSla')
    },
    slaAction(): number {
      return this.getItemHook().getRawValue(
        'workflowInstance.currentStatus.slaAction',
      )
    },
    component(): { is: VueConstructor; props: Record<string, unknown> } {
      if (this.isSlaCountingDown && !this.stopSla) {
        return {
          is: DispatchTasksSlaCountdown,
          props: { leftTimeStamp: this.leftTimeStamp },
        }
      }
      if (!this.isSlaCountingDown && !this.stopSla) {
        return {
          is: DispatchTasksSlaCountup,
          props: { initalTime: this.slaStartedOn, slaMinutes: this.slaMinutes },
        }
      }

      return {
        is: DispatchTasksSlaTime,
        props: { timeObject: this.staticTimeObject },
      }
    },
    formatedSlaMinutes(): string {
      const timeObject = formatMinutesToTimeObject(this.slaMinutes)

      return formatHoursMinutes(timeObject.hours, timeObject.minutes)
    },
    leftTimeStamp(): number {
      const slaMilliseconds = this.slaMinutes * 60 * 1000

      return convertDateToTimeStamp(this.slaStartedOn) + slaMilliseconds
    },
    staticTimeObject(): { days: number; hours: number; minutes: number } {
      return formatMinutesToTimeObject(this.currentSla)
    },
    stopSla(): boolean {
      return this.slaAction === 2
    },
    colorText(): string {
      if (this.isSlaCountingDown && this.currentSla === 0) {
        return 'red'
      }
      if (!this.isSlaCountingDown && this.currentSla >= this.slaMinutes) {
        return 'red'
      }
      if (this.slaAction === 2 && this.slaMinutes > this.currentSla) {
        return 'green'
      }
      if (this.countdownFinished) {
        return 'red'
      }

      return 'grey'
    },
  },
})
</script>
