import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { createDocumentColumn } from '@/tt-widget-entity-flow/createDocumentColumn'
import InvoiceDoc from '@/tt-entity-design/src/components/invoices/InvoiceDoc'
import { TextClass } from '@/helpers/text/typo'

export default [
  createDocumentColumn(InvoiceDoc),
  {
    attributeName: 'documentNumber',
    style: {
      textAlign: 'Center',
      cssClass: TextClass.BOLD,
    },
  },
  'invoiceDate',
  'client',
  'status',
  'subTotal',
  'taxTotal',
  {
    attributeName: 'total',
    style: {
      cssClass: TextClass.BOLD,
    },
  },
  'aging',
] as ColumnInputDefinition[]
