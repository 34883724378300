import Vue, { Component, VueConstructor } from 'vue'
import { Dictionary } from 'vue-router/types/router'
import { TranslateResult } from 'vue-i18n'

import {
  ErrorMap,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'

import { DocumentInput } from '@/tt-entity-documents/types'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { WidgetContainerInterface } from '@/tt-widget-factory'

// @todo: framework modules shouldn't depend on app modules
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

export enum LayoutComponents {
  alertDialog = 'alertDialog',
  bottomSheet = 'bottomSheet',
  dialog = 'dialog',
  dialogForm = 'dialogForm',
  dialogLoading = 'dialogLoading',
  fullPageDialog = 'fullPageDialog',
  leftSheet = 'leftSheet',
  sideMenu = 'sideMenu',
  sideSheet = 'sideSheet',
  snackBar = 'snackBar',
  topBar = 'topBar',
  notificationChips = 'notificationChips',
}

export interface AppLayoutPageComponent {
  state: boolean
  page?: PageInterface
  width?: number | string
  height?: number
}

export interface AppLayoutStatePages {
  state: boolean
  pages: PageInterface[]
  width?: number | string
  height?: number
}

export type LayoutMultiPages = LayoutComponents.sideSheet

export interface AppLayoutState {
  [LayoutComponents.alertDialog]: {
    state: boolean
    payload?: AlertInterface
  }
  [LayoutComponents.bottomSheet]: AppLayoutPageComponent
  [LayoutComponents.dialog]: AppLayoutPageComponent
  [LayoutComponents.dialogForm]: {
    state: boolean
    payload?: DialogFormInterface
  }
  [LayoutComponents.dialogLoading]: {
    state: boolean
    payload?: DialogLoadingInterface
  }
  [LayoutComponents.fullPageDialog]: AppLayoutPageComponent
  [LayoutComponents.leftSheet]: AppLayoutPageComponent
  [LayoutComponents.sideMenu]: {
    state: boolean
  }
  [LayoutComponents.sideSheet]: AppLayoutStatePages
  [LayoutComponents.snackBar]: {
    state: boolean
    payload?: {
      title: string
      message: string
      type: SnackBarTypes
    }
  }
  [LayoutComponents.topBar]: {
    state: boolean
  }
  [LayoutComponents.notificationChips]: {
    state: boolean
    chips: NotificationChipsStateInterface
  }
}

export const defaultAppLayoutState: AppLayoutState = {
  alertDialog: {
    state: false,
  },
  bottomSheet: {
    state: false,
  },
  dialog: {
    state: false,
    width: 800,
  },
  dialogForm: {
    state: false,
  },
  dialogLoading: {
    state: false,
    payload: {
      width: 800,
    },
  },
  fullPageDialog: {
    state: false,
  },
  leftSheet: {
    state: false,
    width: 250,
  },
  sideMenu: {
    state: true,
  },
  sideSheet: {
    state: false,
    pages: [],
  },
  snackBar: {
    state: false,
  },
  topBar: {
    state: true,
  },
  notificationChips: {
    state: true,
    chips: [],
  },
}

export enum LayoutWindowEvent {
  SIDE_SHEET = 'layout.open.sideSheet.page',
  SIDE_SHEET_CLOSE = 'layout.close.sideSheet',
  SIDE_SHEET_CLOSED = 'layout.closed.sideSheet',
  SIDE_SHEET_ADD_PAGE = 'side.sheet.add.page',
  LEFT_SHEET = 'layout.open.leftSheet',
  SIDE_MENU_TOGGLE = 'layout.sideMenu.toggle',
  SIDE_MENU_OPEN = 'layout.sideMenu.open',
  SIDE_MENU_CLOSE = 'layout.sideMenu.close',
  TOP_BAR_OPEN = 'layout.topBar.open',
  TOP_BAR_CLOSE = 'layout.topBar.close',
  FULL_PAGE_DIALOG = 'layout.open.fullPage',
  FULL_PAGE_DIALOG_CLOSED = 'layout.closed.fullPage',
  SIDE_SHEET_EDIT_TITLE = 'layout.edit.title.sideSheet.page',
  DIALOG = 'layout.open.dialog',
  DIALOG_CLOSE = 'layout.close.dialog',
  DIALOG_CLOSED = 'layout.closed.dialog',
  BOTTOM_SHEET = 'layout.open.bottom.sheet',
  DIALOG_FORM = 'layout.open.dialogForm',
  DIALOG_LOADING_OPEN = 'layout.dialogLoading.open',
  DIALOG_LOADING_CLOSE = 'layout.dialogLoading.close',
  DIALOG_LOADING_COMPLETE = 'layout.dialogLoading.complete',
  SNACK_SUCCESS = 'layout.snack.success',
  SNACK_ERROR = 'layout.snack.error',
  SNACK_INFO = 'layout.snack.info',
  ALERT = 'layout.alert',
  CONFIRM = 'layout.confirm',
  SIGN_OUT = 'layout.auth.sign-out',
  GO_TO = 'layout.go.to',
  CONTENT_PAGE = 'layout.content.page', // Not valid for AppLayoutRouter
  SIDE_SHEET_OPEN_PREVIEW = 'layout.open.sideSheet-preview',
  SIDE_SHEET_CLOSE_PREVIEW = 'layout.close.sideSheet-preview',
  VIEW_DOCUMENT = 'layout.view.document',
  NOTIFICATION_CHIPS_ADD = 'layout.notificationChips.add',
  NOTIFICATION_CHIPS_REMOVE = 'layout.notificationChips.remove',
}

export type LayoutWindowEventPayload = {
  [LayoutWindowEvent.SIDE_SHEET]: SideMenuInterface
  [LayoutWindowEvent.SIDE_SHEET_CLOSE]: {}
  [LayoutWindowEvent.SIDE_SHEET_CLOSED]: {}
  [LayoutWindowEvent.SIDE_SHEET_ADD_PAGE]: PageInterface
  [LayoutWindowEvent.LEFT_SHEET]: SideMenuInterface
  [LayoutWindowEvent.SIDE_MENU_TOGGLE]: SideMenuInterface
  [LayoutWindowEvent.SIDE_MENU_OPEN]: {}
  [LayoutWindowEvent.SIDE_MENU_CLOSE]: {}
  [LayoutWindowEvent.FULL_PAGE_DIALOG]: DialogInterface
  [LayoutWindowEvent.FULL_PAGE_DIALOG_CLOSED]: Record<string, never>
  [LayoutWindowEvent.DIALOG]: DialogInterface
  [LayoutWindowEvent.DIALOG_CLOSE]: null
  [LayoutWindowEvent.DIALOG_CLOSED]: null
  [LayoutWindowEvent.SIDE_SHEET_EDIT_TITLE]: {}
  [LayoutWindowEvent.BOTTOM_SHEET]: SideMenuInterface
  [LayoutWindowEvent.DIALOG_FORM]: DialogFormInterface
  [LayoutWindowEvent.DIALOG_LOADING_OPEN]: DialogLoadingInterface
  [LayoutWindowEvent.DIALOG_LOADING_COMPLETE]: null
  [LayoutWindowEvent.DIALOG_LOADING_CLOSE]: null
  [LayoutWindowEvent.SNACK_SUCCESS]: SnackBarInterface
  [LayoutWindowEvent.SNACK_ERROR]: SnackBarInterface
  [LayoutWindowEvent.SNACK_INFO]: SnackBarInterface
  [LayoutWindowEvent.ALERT]: AlertInterface
  [LayoutWindowEvent.CONFIRM]: ConfirmInterface
  [LayoutWindowEvent.SIGN_OUT]: null
  [LayoutWindowEvent.GO_TO]: string
  [LayoutWindowEvent.CONTENT_PAGE]: PageInterface
  [LayoutWindowEvent.SIDE_SHEET_OPEN_PREVIEW]: SideMenuInterface // never use
  [LayoutWindowEvent.SIDE_SHEET_CLOSE_PREVIEW]: SideMenuInterface // never use
  [LayoutWindowEvent.VIEW_DOCUMENT]: DocumentInput
  [LayoutWindowEvent.TOP_BAR_OPEN]: {}
  [LayoutWindowEvent.TOP_BAR_CLOSE]: {}
  [LayoutWindowEvent.NOTIFICATION_CHIPS_ADD]: NotificationChipsInterface
  [LayoutWindowEvent.NOTIFICATION_CHIPS_REMOVE]: NotificationChipsInterface
}

export type SideMenuInterface = {
  width?: string | number
  height?: number
} & PageInterface

export interface DialogInterface extends PageInterface {
  width?: number
  height?: number
}

/**
 * Dialog Form interface
 */
export interface DialogFormInterface {
  title: string
  formOptions?: FormOptions
  rootName: string
  width?: number
  closeOnOutsideClick?: boolean
  userContext?: any
  localizeFunction?: (attrPath: string) => string
  initialModel?: Record<string, any>
  jsonSchema: JSONSchema7
  close?: (vm: Vue & { [key: string]: any }) => void
  submit?: (data: any) => Promise<any>
  success?: (data: any, submitResponse: any) => void
  error?: (error: any, data: any) => void
  cancel?: any
  params?: Record<string, any>
}

/**
 * Dialog with loading indicator interface
 */
export interface DialogLoadingInterface {
  title?: string
  subTitle?: string
  width?: string | number
  height?: number | number
  notificationChipId?: string
  cancel?: () => void
}

export interface ConfirmInterface {
  message: string
  title?: string

  cancel?: () => void
  cancelText?: string

  accept?: () => void
  acceptText?: string
  acceptColor?: string
}

export interface AlertInterface {
  message: string
}

export enum SnackBarTypes {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export const DIALOG_DEFAULT_WIDTH = 800
export const SIDE_SHEET_DEFAULT_WIDTH = 459
export const LEFT_SHEET_DEFAULT_WIDTH = 300

export interface LayoutConfiguration {
  /**
   * Replace the default layout wrapper with a custom component
   */
  is?: Component

  theme?: {
    dark: boolean
  }

  /**
   * Allow disabling CSS transitions from Vuetify (mainly for performance reasons)
   */
  disableCssTransitions?: boolean

  navigation?: ActionMenuConfig[]

  topBar?: {
    /**
     * Replace the default top bar with a custom component
     */
    is?: Component
    hidden?: boolean
  }

  sideMenu?: {
    /**
     * Replace the default side menu with a custom component
     */
    is?: Component
    permanent?: boolean
    hidden?: boolean
  }

  sideSheet?: {
    absolute?: boolean
  }
}

/**
 * Snack bar interface
 */
export interface SnackBarInterface {
  message: string | TranslateResult
  title?: string
  fullPage?: boolean
  icon?: string
  dismissTime?: number
  type?: SnackBarTypes
}

/**
 * Notification chips interfaces (item and state)
 */
export interface NotificationChipsInterface {
  title?: string
  key?: string | number
}

export type NotificationChipsStateInterface = Array<NotificationChipsInterface>

/**
 * Page interface
 */
export interface PageInterface {
  key?: string
  title?: string | TranslateResult | undefined
  subTitle?: string
  closeButton?: boolean
  is: string | VueConstructor
  props?: Record<string, any> | null
}

export interface TabInterface<
  Context extends Record<string, any> = Record<string, unknown>,
> {
  title: string | TranslateResult | undefined
  is: string | VueConstructor
  props?: Record<string, any> | null
  condition?: (context: Context | null) => boolean
}

export type ActionRenderComponent = {
  type: 'component'
  props: {
    is: string
    [k: string]: any
  }
}

export type ActionRoute = {
  type: 'route'
  props: {
    path?: string
    name?: string | null
    hash?: string
    query?: Dictionary<string | (string | null)[]>
    params?: Dictionary<string>
    meta?: any
  }
}

export type ActionExternalRoute = {
  type: 'externalRoute'
  props: {
    href: string
    openInNewTab: boolean
  }
}

export type ActionDispatch = {
  type: 'dispatch'
  props: {
    eventName: WidgetEditorEvents | LayoutWindowEvent | string
    payload: any
  }
}

export type ActionTypes =
  | ActionRoute
  | ActionRenderComponent
  | ActionDispatch
  | ActionExternalRoute

export const isActionDispatch = (
  action: ActionTypes,
): action is ActionDispatch => {
  return (action as ActionDispatch).type === 'dispatch'
}

export const isActionRoute = (action: ActionTypes): action is ActionRoute => {
  return (action as ActionRoute).type === 'route'
}

export const isActionRenderComponent = (
  action: ActionTypes,
): action is ActionRenderComponent => {
  return (action as ActionRenderComponent).type === 'component'
}

export type ActionMenuHeader = {
  text: string
  icon?: string
  color?: string
}

export enum ActionMenuActionTypeEnum {
  COMPONENT = 'component',
  ROUTE = 'route',
  DISPATCH = 'dispatch',
}

export type AssertFunction = (options: {
  availableActions: string[]
}) => boolean

export type ActionMenuItem = {
  id?: string
  icon?: string
  color?: string
  acl?: string | string[]
  assert?: boolean | AssertFunction
  type?: string
  component?: VueConstructor
  text?: string
  action?: ActionTypes
  children?: ActionMenuConfig[]
}

/**
 * @todo: to decouple and make the type a generic
 * https://tracktik.atlassian.net/browse/FE-858
 */
export type ActionMenuName = 'WidgetStore.actions' | 'Categories.actions'

export type ActionMenuItemCallback<T = any> = (
  payload: T,
) => Promise<ActionMenuItem>

export type ActionMenuDefinitions = ActionMenuItemCallback | ActionMenuItem

export type ActionMenuConfig = {
  header?: ActionMenuHeader
  items: ActionMenuDefinitions[]
}

export interface ActiveWindow {
  back: () => void
  goTo: (pageIndex: number) => void
  next: (page: PageInterface) => void
  name: LayoutComponents | undefined
}

export type ActiveWindowProvider = {
  activeWindow: ActiveWindow
}

export interface ActiveWindowPage {
  active: boolean
  index: number
  page: PageInterface
}

export type ActiveWindowPageProvider = {
  activeWindowPage: ActiveWindowPage
}

export type WidgetContainerProvider = {
  container: WidgetContainerInterface
}

export type CompoundFormManagerItemState = {
  errors: ErrorMap
  value: Record<string, unknown>
}

export type CompoundFormManagerState = {
  [formId: string]: Partial<CompoundFormManagerItemState>
}

export type CompoundFormManager<FormValue = Record<string, unknown>> = {
  getCompoundErrors: () => ErrorMap
  getCompoundValue: () => FormValue
  getFormErrors: (formId: string) => ErrorMap
  getFormValue: (formId: string) => FormValue
  onErrorsChange: (callback: (errors: ErrorMap) => void) => UnsubscribeFunction
  onValueChange: (callback: (value: FormValue) => void) => UnsubscribeFunction
  setFormErrors: (formId: string, errors: ErrorMap) => void
  setFormValue: (formId: string, value: FormValue) => void
  clearValue: () => void
}

export type CompoundFormManagerProvider = {
  getFormManager: () => CompoundFormManager
}
