// Enter your custom installation here

import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

import EmployeeNotesTab from './EmployeeNotesTab.vue'

export default {
  install(Vue) {
    Vue.component('EmployeeNotesTab', EmployeeNotesTab)

    // Add the schedule preview in the tabs
    addPreviewTab(Resources.EMPLOYEES, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })

    addPreviewTab(Resources.EMPLOYEES, {
      title: 'tt-entity-design.employee-hub.employee.notes-on-employee',
      is: 'EmployeeNotesTab',
    })
  },
}
