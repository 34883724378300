// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import DataViewModularHooksTile from './DataViewModularHooksTile.vue'
import DataViewModularHooksColumns from './DataViewModularHooksColumns'
import DataViewModularHooksDetail from './DataViewModularHooksDetail.vue'
import DataViewModularHooksMetaCard from './DataViewModularHooksMetaCard.vue'
import DataViewModularHooksReference from './DataViewModularHooksReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('DataViewModularHooksTile', DataViewModularHooksTile)
    Vue.component('DataViewModularHooksDetail', DataViewModularHooksDetail)
    Vue.component('DataViewModularHooksMetaCard', DataViewModularHooksMetaCard)
    Vue.component(
      'DataViewModularHooksReference',
      DataViewModularHooksReference,
    )

    modularManager.addResourcePreset(
      Resources.DATA_VIEW_MODULAR_HOOKS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'DataViewModularHooksTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_MODULAR_HOOKS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: DataViewModularHooksColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_MODULAR_HOOKS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewModularHooksDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_MODULAR_HOOKS,
      PresetTypes.META_CARD,
      {
        title: 'Default',
        data: { is: 'DataViewModularHooksMetaCard' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.DATA_VIEW_MODULAR_HOOKS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'DataViewModularHooksReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
